import React, { useCallback, useState } from 'react';
import {
  TColumnIdsFundingSources,
  TFilterModelFundingSources,
  useFundingSourcesColumns,
} from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/fundingSources/shared/table/useFundingSourcesColumns';
import { PageParamsProvider } from 'bundles/Shared/components/pageParams';
import { usePageParamsTableSorting } from '@/shared/lib/hooks/usePageParamsTableSorting';
import { TSort, ISortParamsField } from 'types/Sort';
import Table from 'bundles/Shared/components/Table/Table';
import { useGetFundingSourcesQuery } from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/fundingSources/api/reconcileFundingSourcesApi';
import { useParams } from '@reach/router';
import { LegalEntity } from '@/entities/core/legalEntity';

export type TPageParamsFundingSources = ISortParamsField<
  | TColumnIdsFundingSources.NAME
  | TColumnIdsFundingSources.AMOUNT
  | TColumnIdsFundingSources.BALANCE
>;

const FundingSourceTable = () => {
  const params = useParams<{
    legalEntityCode: LegalEntity['code'];
  }>();

  const [pageParams, setPageParams] = useState<TPageParamsFundingSources>({
    sort: {
      field: TColumnIdsFundingSources.NAME,
      order: TSort.ASC,
    },
  });

  const { data: fundingSources, isLoading } = useGetFundingSourcesQuery({
    legalEntityCode: params.legalEntityCode,
    pageParams,
  });

  const { settings, setSettings } = usePageParamsTableSorting(
    pageParams,
    setPageParams,
  );
  const { columns } = useFundingSourcesColumns();

  const handleFilterModelChange = useCallback(
    (filterModel: TFilterModelFundingSources) =>
      setPageParams((prevParams) => ({
        ...prevParams,
        // next iteration
        // vendor_ids: filterModel.vendor_ids?.map((item) => item.id),
        amount: filterModel.amount,
      })),
    [],
  );
  return (
    <div className="flex w-full flex-1 flex-col gap-4 bg-light-10 py-6">
      <PageParamsProvider pageParams={pageParams} setPageParams={setPageParams}>
        <Table
          loading={isLoading}
          columns={columns}
          settings={settings}
          setSettings={setSettings}
          onFilterModelChange={handleFilterModelChange}
          items={fundingSources?.items ?? []}
        />
      </PageParamsProvider>
    </div>
  );
};

export default FundingSourceTable;
