import React from 'react';
import { cn } from '@/shared/lib/css/cn';

const CardPart = ({
  children,
  label,
}: React.PropsWithChildren<{
  label: string;
}>) => {
  return (
    <div className="flex flex-col gap-4">
      <div className="font-medium uppercase text-neutral-500">{label}</div>
      <div className="text-sm text-neutral-850">{children}</div>
    </div>
  );
};

export function ReturnInfoCard({
  className,
  children,
}: React.PropsWithChildren & PropsWithClassName) {
  return (
    <div
      className={cn(
        'flex gap-[40px] !rounded-lg bg-neutral-000 px-4 py-3 text-xs',
        className,
      )}
    >
      {children}
    </div>
  );
}

ReturnInfoCard.Part = CardPart;
