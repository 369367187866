import { removeInvoiceLineItem } from 'bundles/Construction/reducers/reconcileInvoiceStateSlice';
import { useAppDispatch } from '@/shared/lib/hooks/redux';
import React, { FC } from 'react';
import { InvoiceLineItem } from '@/shared/types/reconcile/Invoice';
import { cn } from '@/shared/lib/css/cn';
import styles from 'bundles/DrawPackage/InvoicesFlow/Invoices/invoice/Invoice.module.scss';
import LineItemCard from '@/bundles/DrawPackage/InvoicesFlow/Invoices/verify/lineItems/LineItemCard';
import { setEditInvoiceChanging } from 'bundles/DrawPackage/slices/ReconcileInvoicesSlice';

interface ISelectLineItemsProps {
  items: InvoiceLineItem[];
  maxAmount: number;
  editFlow?: boolean;
}

const SelectLineItems: FC<ISelectLineItemsProps> = ({
  items,
  maxAmount,
  editFlow,
}) => {
  const dispatch = useAppDispatch();

  const removeLineItem = (idx: number) => {
    dispatch(setEditInvoiceChanging(false));
    dispatch(removeInvoiceLineItem(idx));
  };

  return (
    <div
      className={cn('flex flex-col gap-1.5', styles.tableBody, {
        [styles.manualLineItemContainer]: !editFlow,
        [styles.editLineItemContainer]: editFlow,
      })}
    >
      {items.map((item, idx) => (
        <LineItemCard
          key={`lineItem${idx}`}
          onRemove={removeLineItem}
          idx={idx}
          maxAmount={maxAmount}
          selectFlow
          {...item}
        />
      ))}
    </div>
  );
};

export default SelectLineItems;
