import React, { FC, useState, useMemo } from 'react';
import GroupForm from 'bundles/Shared/components/GroupForm/GroupForm';
import { directUpload, presignFiles } from 'lib/uploadFiles';
import { Modal } from 'stories/Modals/Modal/Modal';
import getItemsAppModal from '@/bundles/Construction/components/PayApp/initData';

import { formatToDateStringForRequest } from '@/shared/lib/converters';

interface TPayAppFormData {
  number?: string;
  application_date?: string;
  period_to_date?: string;
  files?: any[];
}

interface Props {
  onSubmit: (formData: TPayAppFormData) => void;
  onClose: () => void;
  title: string;
}

const formatPayAppNumber = (number) => {
  const [left, right] = [number.slice(0, 4), number.slice(4)];
  return `${left.padStart(4, '0')}-${right.padEnd(2, '0')}`;
};

const PayAppForm: FC<Props> = ({ onSubmit, onClose, title }) => {
  const [formData, setFormData] = useState<TPayAppFormData>({});
  const initGroupFormItems = useMemo(() => getItemsAppModal(), []);

  const submit = async () => {
    const presignedFiles = await presignFiles([formData.files]);
    await Promise.all(presignedFiles.map((file) => directUpload(file)));

    return onSubmit({
      application_date: formatToDateStringForRequest(formData.application_date),
      period_to_date: formatToDateStringForRequest(formData.period_to_date),
      number: formatPayAppNumber(formData.number.toString()),
      files: presignedFiles.map((f) => ({
        key: f.signedData.fields.key,
        filename: f.file.name,
        content_type: f.file.type,
        size: f.file.size,
      })),
    });
  };
  return (
    <Modal header={title} toggle={onClose}>
      <GroupForm
        groupFormItems={initGroupFormItems}
        onSubmit={submit}
        formData={formData}
        setFormData={setFormData}
        buttonConfig={{
          titleBtn: 'Create Pay App',
        }}
      />
    </Modal>
  );
};

export default PayAppForm;
