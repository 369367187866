import React from 'react';

const ProjectCardList = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren
>(({ children }, ref) => (
  <div
    ref={ref}
    className="flex flex-col gap-0.5 overflow-hidden rounded-2xl bg-light-10"
  >
    {children}
  </div>
));

export default ProjectCardList;
