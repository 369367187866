import { ViewPermissionedUsersModal } from '@/bundles/Shared/components/Permissions/ViewPermissionedUsersModal';
import type { Permitted } from '@/bundles/Shared/entities/permissions';
import { useLazyGetPermissionModalSubjectablesQuery } from '@/shared/api/pemissionModalApi';
import { useModal } from '@/shared/lib/hooks/useModal';
import { mapListToIds } from '@/shared/lib/listHelpers';
import { uniqBy } from 'lodash-es';

export const useViewTemplatePermittedUsers = ({
  permitted,
}: {
  permitted?: Pick<
    Permitted,
    'directUsers' | 'directRoles' | 'directTags' | 'directInvestmentEntities'
  >;
}) => {
  const [fetchPermissionModalSubjectables] =
    useLazyGetPermissionModalSubjectablesQuery();

  const { openModal } = useModal();

  const handleViewPermissions = async () => {
    if (permitted == null) return;

    await openModal(ViewPermissionedUsersModal, {
      fetchPermissionedUsersWithMeta: async () => {
        const res = await fetchPermissionModalSubjectables({});

        if (!('data' in res) && res.data == null) return;

        const { data } = res;

        const permittedRoleIds = mapListToIds(permitted?.directRoles ?? []);
        const permittedTagIds = mapListToIds(permitted?.directTags ?? []);
        const permittedInvestmentEntitiesIds = mapListToIds(
          permitted?.directInvestmentEntities ?? [],
        );

        const indirectUsersFromTags = (data?.users ?? []).filter((u) =>
          u.tags?.some((tag) => permittedTagIds.includes(tag.id)),
        );
        const indirectUsersFromRoles = (data?.users ?? []).filter((u) =>
          u.role ? permittedRoleIds.includes(u.role.id) : false,
        );
        const indirectUsersFromInvestmentEntities = (data?.users ?? []).filter(
          (u) =>
            u.investmentEntities?.some((ie) =>
              permittedInvestmentEntitiesIds.includes(ie.id),
            ),
        );
        const allUsers = uniqBy(
          (permitted?.directUsers ?? [])
            .concat(indirectUsersFromRoles)
            .concat(indirectUsersFromTags)
            .concat(indirectUsersFromInvestmentEntities),
          'id',
        );

        return {
          meta: {
            roles: data?.userRoles,
            tags: data?.userTags,
          },
          users: allUsers,
        };
      },
    });
  };

  return [handleViewPermissions] as const;
};
