import { useDeleteApiCapitalInvestmentContributionsByIdMutation } from '@/entities/return/api/capitalInvestmentObjectsEnhancedApi';
import { formatUnixDate } from '@/shared/lib/formatting/dates';
import { useModal } from '@/shared/lib/hooks/useModal';
import { IconButton } from '@/stories/IconButton/IconButton';
import React from 'react';

export const Transfer = ({
  id,
  createdBy,
  createdAt,
  fromTransferName,
  toTransferNames,
}: {
  id: number;
  createdBy: string;
  fromTransferName: string | undefined;
  createdAt: UnixTime;
  toTransferNames: Array<string>;
}) => {
  const [deleteContribution] =
    useDeleteApiCapitalInvestmentContributionsByIdMutation();

  const { confirm } = useModal();
  const confirmRemove = async () =>
    confirm({
      title: 'Remove Transfer of ownership',
      subtitle:
        'Are you sure you want to remove this transfer of ownership? Both entries (source and receiver) will be deleted.',
      size: '400',
      actions: {
        primaryButton: {
          text: 'Remove',
        },
        secondaryButton: {
          text: 'Cancel',
        },
      },
    });

  return (
    <div className="flex flex-col gap-s">
      <div className="flex justify-between">
        <div className="flex flex-col gap-xs">
          <div className="secondary-semibold text-neutral-850">
            Transfer of ownership
          </div>
          <div className="secondary-regular text-neutral-900">
            {fromTransferName ? (
              <>
                <span className="text-neutral-550">From:&nbsp;</span>
                {fromTransferName}
              </>
            ) : (
              <>
                <span className="text-neutral-550">To:&nbsp;</span>
                {toTransferNames.join(', ')}
              </>
            )}
          </div>
        </div>
        <div>
          <IconButton
            iconName="trash"
            onClick={async () => {
              const result = await confirmRemove();
              if (result) {
                deleteContribution({ id });
              }
            }}
          />
        </div>
      </div>
      <div className="secondary-regular">
        <span className="text-neutral-550">by&nbsp;</span>
        {createdBy}
        <span className="text-neutral-550"> at&nbsp;</span>
        {formatUnixDate(createdAt, 'MMM DD, YYYY [at] LT')}
      </div>
    </div>
  );
};
