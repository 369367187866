import React, { useMemo, useRef } from 'react';
import { useAmchart } from 'lib/amcharts/useAmchart';
import {
  addCenteredLegend,
  addCurrencyValueYAxis,
  addCursor,
  addDateXAxis,
  chartDateMapper,
  COLOR_SET,
  createXYChart,
  getReturnDashboardTheme,
  getSeriesColor,
} from 'lib/amcharts/utils';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import { capitalize } from 'lodash-es';
import am5themesAnimated from '@amcharts/amcharts5/themes/Animated';
import useMediaQuery, {
  MEDIUM_WIDTH_MEDIA_QUERY,
} from '@/shared/lib/hooks/useMediaQuery';
import { DashboardWidgetCard } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/DashboardWidgetCard';
import { GetApiPortfolioApiResponse } from '@/shared/api/portfolioGeneratedApi';
import { getCurrencyTooltipFormat } from '@/shared/lib/formatting/charts';

interface Props {
  data: GetApiPortfolioApiResponse['capitalHistory'];
}

function CapitalHistoryCard({ data }: Props) {
  const ref = useRef(null);
  const items = useMemo(() => data.map(chartDateMapper('date')), [data]);
  const isMediumWidth = useMediaQuery(MEDIUM_WIDTH_MEDIA_QUERY);
  useAmchart(
    ref,
    (root) => {
      const myTheme = getReturnDashboardTheme(root);
      myTheme.rule('ColorSet').set('colors', COLOR_SET);

      root.setThemes([am5themesAnimated.new(root), myTheme]);

      const chart = createXYChart(root, {
        layout: root.verticalLayout,
      });

      const { yAxis } = addCurrencyValueYAxis(root, chart);

      const { xAxis } = addDateXAxis(root, chart, {
        baseInterval: { timeUnit: 'day', count: 10 },
      });

      xAxis.data.setAll(items);
      function createSeries(field: 'contributed' | 'distributed') {
        const series = chart.series.push(
          am5xy.LineSeries.new(root, {
            name: capitalize(field),
            xAxis,
            yAxis,
            valueYField: field,
            valueXField: 'date',
            tooltip: am5.Tooltip.new(root, {
              labelText: `{name}: ${getCurrencyTooltipFormat()}`,
            }),
            stroke: getSeriesColor(field),
            fill: getSeriesColor(field),
          }),
        );

        if (field === 'distributed') {
          series.fills.template.setAll({
            fillOpacity: 0.3,
            visible: true,
          });
          series.strokes.template.setAll({
            strokeWidth: 1,
            strokeOpacity: 1,
          });

          series.bullets.push(() =>
            am5.Bullet.new(root, {
              sprite: am5.Circle.new(root, {
                radius: 4,
                fill: series.get('fill'),
              }),
            }),
          );
        }
        series.data.setAll(items);
      }

      createSeries('contributed');
      createSeries('distributed');

      const cursor = addCursor(root, chart, {
        xAxis,
      });
      cursor.lineY.set('visible', false);

      addCenteredLegend(root, chart, {
        centerY: isMediumWidth ? 20 : 40,
      });
    },
    [items],
  );

  return (
    <DashboardWidgetCard>
      <DashboardWidgetCard.Header>
        <DashboardWidgetCard.Header.Title>
          Capital History
        </DashboardWidgetCard.Header.Title>
      </DashboardWidgetCard.Header>
      <div className="m-4 mt-0 h-[300px]" ref={ref} />
    </DashboardWidgetCard>
  );
}

export default CapitalHistoryCard;
