import React from 'react';
import { cn } from '@/shared/lib/css/cn';
import styles from '@/stories/BudgetAvatar/budgetAvatar.module.scss';

interface Props {
  fullName: string;
}

export const BudgetAvatar = ({ fullName }: Props) => (
  <div>
    <div className={cn(styles.avatar)}>
      {fullName
        .split(' ')
        .splice(0, 2)
        .map((n) => n && n[0].toUpperCase())
        .join('')}
    </div>
  </div>
);

export default BudgetAvatar;
