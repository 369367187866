import { arrayMoveImmutable } from 'array-move';
import React from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { AssetFieldCard } from '@/stories/AssetFieldCard/AssetFieldCard';
import { CollapsableSection } from '@/stories/CollapsableSection/CollapsableSection';
import { IconButton } from '@/stories/IconButton/IconButton';
import { IFieldCard } from 'types/FieldCard';
import { ILegalEntitiable } from 'types/LegalEntitiable';

interface Props {
  className: string;
  canManage: boolean;
  currentInfo: IFieldCard[];
  id: number;
  slug: ILegalEntitiable['slug'];
  objectType: string;
  setCurrentInfo: any;
  onCreate: () => void;
  onEdit: () => void;
  onHide: () => void;
  update: (p: {
    id: number;
    slug: string;
    [key: 'asset' | 'fund']: ILegalEntitiable;
  }) => void;
  removeConfirmAlert: (update, currentInfo) => void;
  openFieldModal: (index: number) => void;
}

export const FieldsCollapsableSection = ({
  canManage,
  currentInfo,
  className,
  id,
  objectType,
  slug,
  setCurrentInfo,
  onCreate,
  onHide,
  onEdit,
  update,
  removeConfirmAlert,
  openFieldModal,
}: Props) => {
  const items = currentInfo.filter((i) => i.visible);
  const hiddenItems = currentInfo.filter((i) => !i.visible);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const visibleSorted = arrayMoveImmutable(
      currentInfo.filter((item) => item.visible),
      oldIndex,
      newIndex,
    );
    const invisible = currentInfo.filter((item) => !item.visible);
    document.body.style.cursor = 'default';
    setCurrentInfo([...visibleSorted, ...invisible]);
    update({
      id,
      slug,
      [objectType]: { info: [...visibleSorted, ...invisible] },
    });
  };

  const SortableItem = SortableElement(({ value }) => (
    <div style={{ flex: '40%', position: 'relative' }}>
      <AssetFieldCard
        onHide={onHide}
        expandedActions={items.length === 1}
        onDelete={() => {
          removeConfirmAlert(
            () =>
              update({
                id,
                slug,
                [objectType]: {
                  info: [...currentInfo.filter((i) => i !== value)],
                },
              }),
            'Field',
          );
        }}
        onEdit={onEdit}
        hideMove
        canManage={canManage}
        key={value.id}
        fieldCard={value}
      />
    </div>
  ));

  const SortableList = SortableContainer(({ items }) => (
    <div className="flex flex-wrap gap-2">
      {items.map((value, index) => (
        <SortableItem
          key={`item-${value.measure_name}`}
          index={index}
          value={value}
        />
      ))}
    </div>
  ));

  return (
    <>
      <CollapsableSection
        className={className}
        canManage={canManage}
        count={items.length}
        content={
          <div className="flex flex-col gap-2">
            <SortableList
              axis="xy"
              items={items}
              onSortStart={() => {
                document.body.style.cursor = 'grabbing';
              }}
              onSortEnd={onSortEnd}
              helperClass="field_drag__active"
              useDragHandle
            >
              {items.map((item, index) => (
                <SortableItem
                  key={item.value + item.measure_name}
                  info={currentInfo}
                  item={item}
                  update={update}
                  openFieldModal={() => openFieldModal(index)}
                  index={index}
                />
              ))}
            </SortableList>
          </div>
        }
        title={`${objectType.toUpperCase()} INFO`}
        actions={
          canManage && (
            <IconButton
              iconName="addSmall"
              onClick={onCreate}
              variant="secondary"
            />
          )
        }
      />
      {hiddenItems.length > 0 && (
        <CollapsableSection
          initialExpanded={false}
          canManage={false}
          count={hiddenItems.length}
          content={
            <div className="flex flex-wrap gap-2">
              {hiddenItems.map((item) => (
                <div
                  key={item.value + item.measure_name}
                  style={{ flex: '40%', position: 'relative' }}
                >
                  <AssetFieldCard
                    expandedActions={items.length === 1}
                    onHide={onHide}
                    onDelete={() => {
                      removeConfirmAlert(
                        () =>
                          update({
                            id,
                            slug,
                            [objectType]: {
                              info: [...currentInfo.filter((i) => i !== item)],
                            },
                          }),
                        'Field',
                      );
                    }}
                    onEdit={onEdit}
                    canManage={canManage}
                    key={item.id}
                    fieldCard={item}
                  />
                </div>
              ))}
            </div>
          }
          title="HIDDEN FIELDS"
        />
      )}
    </>
  );
};

export default FieldsCollapsableSection;
