import * as React from 'react';
import { Icon } from '@/stories/Icon/Icon';
import { Button } from '@/stories/Button/Button';
import { cn } from '@/shared/lib/css/cn';
import styles from '@/bundles/UserManagement/components/errors/errorMessage/ErrorMessage.module.scss';

interface Props {
  text: string;
  actionLabel: string;
  onActionClick: () => void;
  className?: string;
}

function ErrorMessage({ text, actionLabel, onActionClick, className }: Props) {
  return (
    <div className={cn(styles.message, className)}>
      <Icon className={styles.icon} iconName="fire" />
      <div className={styles.text}>
        <p className="dark-60 secondary-regular mb-s">{text}</p>
        <Button onClick={onActionClick} size="xs" className="text-xs uppercase">
          {actionLabel}
        </Button>
      </div>
    </div>
  );
}

export default ErrorMessage;
