import { MONTH_NAMES } from '@/entities/emails/customTemplates/config';
import { useRRuleOptionsContext } from '@/entities/emails/customTemplates/lib/context';
import { Button } from '@/stories/Button/Button';
import { Dropdown } from '@/stories/Dropdown/Dropdown';
import { DropdownItem } from '@/stories/Dropdown/DropdownItem/DropdownItem';
import { capitalize } from 'lodash-es';
import type { ComponentProps } from 'react';

export const MonthDropdown = ({
  disabled,
  onClick,
}: {
  onClick: (monthIndex: number) => void;
} & Pick<ComponentProps<typeof Dropdown>, 'disabled'>) => {
  const rruleOptions = useRRuleOptionsContext();
  return (
    <Dropdown
      appendTo={document.body}
      disabled={disabled}
      items={
        <>
          {MONTH_NAMES.map((month, index) => (
            <DropdownItem key={month} onClick={() => onClick(index + 1)}>
              {capitalize(month)}
            </DropdownItem>
          ))}
        </>
      }
    >
      <Button
        disabled={disabled}
        iconName="bottom"
        size="m"
        variant="secondary"
        iconPosition="right"
      >
        {rruleOptions?.bymonth?.[0]
          ? capitalize(MONTH_NAMES[rruleOptions.bymonth[0] - 1])
          : 'Select Month'}
      </Button>
    </Dropdown>
  );
};
