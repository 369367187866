/* eslint-disable arrow-body-style */
/* eslint-disable react/jsx-wrap-multilines */
import { InvestmentIndex } from '@/entities/return/api/capitalInvestmentObjectsGeneratedApi';
import {
  AllocationContext,
  useAllocation,
} from 'bundles/REconcile/components/AllocationProvider';
import AllocationStatistics from 'bundles/REconcile/components/AllocationStatistics';
import { DistributionKind, ReportLineItem } from 'bundles/REturn/actions/types';
import { ReturnRawTableEditor } from 'bundles/REturn/components/Ownership/modals/capitalInvestmentsEditor/ReturnRawTableEditor';
import { IRowBase } from 'bundles/Shared/components/Table/types';
import { formatDate } from '@/shared/lib/formatting/dates';
import { useItems } from '@/shared/lib/hooks/items/useItems';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import { sumBy, uniqueId } from 'lodash-es';
import { useCallback } from 'react';
import { Button } from 'stories/Button/Button';
import { CurrencyFormatter } from 'stories/ValueFormatters/CurrencyFormatter';
import { Modal } from 'stories/Modals/Modal/Modal';
import { ModalHeaderWithIcon } from 'stories/Modals/ModalHeader/ModalHeaderWithIcon/ModalHeaderWithIcon';
import { MAX_SPLITTED_ENTRY_TRANSACTIONS_COUNT } from '@/bundles/REturn/components/workflows/createEntry/consts';
import { useSplitTxBetweenKindsColumns } from '@/bundles/REturn/components/workflows/createEntry/hooks/columns/useSplitTxBetweenKindsColumns';
import { EntyTxWithReviewInfo } from '@/bundles/REturn/components/workflows/createEntry/types';
import { createEmptyEntryAmountAndKind } from '@/bundles/REturn/components/workflows/createEntry/utils';

import { convertCentsToDollars } from '@/shared/lib/converters';

interface Props extends DialogProps<EntyTxWithReviewInfo[]> {
  transaction: ReportLineItem;
  capitalInvestment: InvestmentIndex;
}

export type EntryAmountAndKind = IRowBase & {
  amount: number;
  kind: DistributionKind | undefined;
};

const SplitTxBetweenKindsTable = (
  props: Parameters<typeof useSplitTxBetweenKindsColumns>[0],
) => {
  const columns = useSplitTxBetweenKindsColumns(props);

  return (
    <ReturnRawTableEditor
      columns={columns}
      items={props.entryAmountAndKindItems}
    />
  );
};

export function SplitTxBetweenKinds({
  onClose,
  transaction,
  capitalInvestment,
  onSubmit,
}: Props) {
  const [
    entryAmountAndKindItems,
    _,
    { onRemoveItemById, onAddItem, onChangeItemById },
  ] = useItems<EntryAmountAndKind>([
    createEmptyEntryAmountAndKind(uniqueId()),
    createEmptyEntryAmountAndKind(uniqueId()),
  ]);
  const sumMapper = useCallback((item: EntryAmountAndKind) => item.amount, []);
  const updateItem = useCallback(
    (index: number, value: number) => {
      onChangeItemById(entryAmountAndKindItems[index].id, { amount: value });
    },
    [entryAmountAndKindItems, onChangeItemById],
  );
  const allocation = useAllocation({
    total: convertCentsToDollars(transaction.amountCents),
    items: entryAmountAndKindItems,
    sumMapper,
    updateItem,
  });
  const { total, allocationFlags, allItemsAreAllocated, allocatedAmount } =
    allocation;

  const { isFullyAllocated } = allocationFlags;
  const canCreateEntry =
    entryAmountAndKindItems.length < MAX_SPLITTED_ENTRY_TRANSACTIONS_COUNT &&
    allItemsAreAllocated;
  const showAddCategoryBtn = !isFullyAllocated && canCreateEntry;

  const handleAddEntryAmountAndKind = () => {
    onAddItem(createEmptyEntryAmountAndKind(uniqueId()));
  };

  const onKindChange = (id: string, kind: DistributionKind | undefined) => {
    onChangeItemById(id, { kind });
  };

  const resolveSubmitDisabled = () => {
    if (!isFullyAllocated) return true;

    return entryAmountAndKindItems.some(({ amount, kind }) => {
      return !amount || !kind;
    });
  };

  const handleSubmit = () => {
    const entryTxs: EntyTxWithReviewInfo[] = entryAmountAndKindItems.map(
      ({ amount, kind }) => ({
        kind,
        amount,
        investmentId: capitalInvestment.id,
        investmentName: capitalInvestment.investmentEntity.name,
        accountingTransactionId: transaction.id,
        totalAmount: capitalInvestment.totalDistributions,
        kindReviewInfo: entryAmountAndKindItems,
        totalTransactionsAmount: sumBy(entryAmountAndKindItems, 'amount'),
      }),
    );
    onSubmit?.(entryTxs);
  };
  return (
    <Modal
      size="600"
      toggle={onClose}
      header={
        <ModalHeaderWithIcon title="Split Transaction Between Categories" />
      }
      actions={
        <>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            disabled={resolveSubmitDisabled()}
            type="submit"
            variant="success"
            form="editSharedFileForm"
          >
            Split Transaction and Continue
          </Button>
        </>
      }
    >
      <div className="-m-6 flex flex-col">
        <div className="grid grid-cols-[94px_220px] items-center gap-x-8 gap-y-4 border-b border-light-10 bg-white px-6 py-4">
          <div className="gap-1.5 flex flex-col">
            <p className="secondary-regular uppercase text-light-60">Amount</p>
            <CurrencyFormatter
              value={convertCentsToDollars(transaction.amountCents)}
            />
          </div>
          <div className="gap-1.5 flex flex-col">
            <p className="secondary-regular uppercase text-light-60">
              Investment Entity
            </p>
            <p className="inline-regular text-dark-60">
              {capitalInvestment.investmentEntity.name}
            </p>
          </div>
          <div className="gap-1.5 flex flex-col">
            <p className="secondary-regular uppercase text-light-60">Date</p>
            <p className="inline-regular text-dark-60">
              {formatDate(transaction.date, 'MMM DD, YYYY')}
            </p>
          </div>
          <div className="gap-1.5 flex flex-col">
            <p className="secondary-regular uppercase text-light-60">
              Legal Entity
            </p>
            <p className="inline-regular text-dark-60">
              {capitalInvestment.legalEntity.name}
            </p>
          </div>
        </div>
        <div className="flex flex-col gap-4 bg-light px-6 py-4">
          <AllocationStatistics
            allocatedAmount={allocatedAmount}
            totalAmount={total ?? 0}
          />

          <div className="gap-1.5 flex flex-col items-start">
            <AllocationContext.Provider value={allocation}>
              <SplitTxBetweenKindsTable
                entryAmountAndKindItems={entryAmountAndKindItems}
                actions={{
                  onKindChange,
                  onRemove: onRemoveItemById,
                }}
              />
            </AllocationContext.Provider>
            {showAddCategoryBtn && (
              <Button
                variant="secondary"
                size="xs"
                onClick={handleAddEntryAmountAndKind}
              >
                Add Category
              </Button>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
}
