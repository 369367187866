/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { cn } from '@/shared/lib/css/cn';
import SortButton from 'bundles/Shared/components/Filters/buttons/sortButton/SortButton';
import {
  IColumn,
  ISortSettings,
  TFilterModel,
} from 'bundles/Shared/components/Table/types';
import React from 'react';
import { TSort } from 'types/Sort';

interface Props<T> {
  column: IColumn<T>;
  className?: string;
  sortSettings?: ISortSettings;
  setSortSettings?: React.Dispatch<React.SetStateAction<ISortSettings>>;
  filterModel?: TFilterModel;
  setFilterModel?: React.Dispatch<React.SetStateAction<TFilterModel>>;
}

function ColumnHeader<T>({
  column,
  className,
  setSortSettings,
  sortSettings,
  filterModel,
  setFilterModel,
}: Props<T>) {
  const FilterComponent = column.filterComponent;
  const onSort = () => {
    const newSettings: ISortSettings = {
      ...sortSettings,
      sortOrder:
        column.dataField === sortSettings?.sortField &&
        sortSettings?.sortOrder === TSort.DESC
          ? TSort.ASC
          : TSort.DESC,
      sortField: column.dataField,
    };
    if (setSortSettings) {
      setSortSettings(newSettings);
    }
  };

  return (
    <th
      className={cn(column.headerClasses, className)}
      key={column.dataField}
      style={column.headerStyle}
    >
      <div
        className={cn('flex items-center', column?.mapClasses?.headerDiv, {
          'justify-start':
            column.headerAlign == null || column.headerAlign === 'start',
          'justify-center': column.headerAlign === 'center',
          'justify-end': column.headerAlign === 'end',
          'justify-between': column.headerAlign === 'between',
        })}
      >
        <div
          className={cn(
            'inline-flex items-center gap-2',
            {
              'cursor-pointer': column.sortable,
            },
            column.textWrapperClasses,
          )}
          onClick={column.sortable ? () => onSort() : undefined}
        >
          <span>{column.text}</span>
          <div className="space-x-1">
            {column.sortable && (
              <SortButton
                sort={
                  sortSettings?.sortField === column.dataField
                    ? sortSettings.sortOrder
                    : null
                }
              />
            )}
            {(column.filter || FilterComponent || column.quickFilter) && (
              <div
                className={cn(
                  'inline-flex items-center gap-2',
                  column.mapClasses?.headerFilterDiv,
                )}
              >
                {/* todo remove after refactoring to filterComponent */}
                {column.filter?.(sortSettings, setSortSettings)}
                {FilterComponent && (
                  <FilterComponent
                    filterModel={filterModel ?? {}}
                    setFilterModel={setFilterModel ?? (() => null)}
                    column={column}
                    {...column.filterComponentParams}
                  />
                )}
                {column.quickFilter}
              </div>
            )}
          </div>
        </div>
      </div>
    </th>
  );
}

export default ColumnHeader;
