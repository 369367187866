import { formatToDateStringForRequest } from '@/shared/lib/converters';
import { FormatDate } from '@/shared/lib/formatting/dates';
import dayjs, { UnitType } from 'dayjs';

type BasicDateUnit = 'day' | 'month' | 'year' | 'quarter' | 'week' | 'date';
type ExtendedDateUnit = UnitType | BasicDateUnit;
type DateUnit = Exclude<BasicDateUnit, 'quarter'>;

export const shiftDateBackward = (
  date: Date,
  duration: number,
  unit: BasicDateUnit,
): Date => dayjs(date).subtract(duration, unit).toDate();

export const shiftDateForward = (
  date: Date,
  duration: number,
  unit: BasicDateUnit,
): Date => dayjs(date).add(duration, unit).toDate();

export const todayDate = (): Date => dayjs().toDate();

export const shiftTodayBackward = (
  duration: number,
  unit: BasicDateUnit,
): Date => shiftDateBackward(todayDate(), duration, unit);

export const shiftTodayForward = (
  duration: number,
  unit: BasicDateUnit,
): Date => shiftDateForward(todayDate(), duration, unit);

export const yearAgoDate = (): Date => shiftTodayBackward(1, 'year');

export const yesterdayDate = (): Date => shiftTodayBackward(1, 'day');

export const get7DaysAgoDate = (): Date => shiftTodayBackward(7, 'day');

export const get90DaysAgoDate = (): Date => shiftTodayBackward(90, 'day');

export const get60DaysAgoDate = (): Date => shiftTodayBackward(60, 'day');

export const get30DaysAgoDate = (): Date => shiftTodayBackward(30, 'day');

export const get6MonthsAgoDate = (): Date => shiftTodayBackward(6, 'month');

export const get1YearAgoDate = (): Date => shiftTodayBackward(1, 'year');
export const getPreviousYearStartDate = (): Date =>
  dayjs(shiftTodayBackward(1, 'year')).startOf('year').toDate();
export const getPreviousYearEndDate = (): Date =>
  dayjs(shiftTodayBackward(1, 'year')).endOf('year').toDate();

export const getStartDateOfPreviousAmountOfQuarters = (amount: number): Date =>
  dayjs(shiftTodayBackward(amount, 'quarter')).startOf('quarter').toDate();

export const getEndDateOfPreviousAmountOfQuarters = (amount: number): Date =>
  dayjs(shiftTodayBackward(amount, 'quarter')).endOf('quarter').toDate();

export const shiftDateYearBackward = (date: Date): Date =>
  shiftDateBackward(date, 1, 'year');

export const shiftDateSixMonthsBackward = (date: Date): Date =>
  shiftDateBackward(date, 6, 'month');

export const tomorrowDate = (): Date => shiftTodayForward(1, 'day');

export const isSameDate = (
  date1: Date,
  date2: Date,
  unit?: BasicDateUnit,
): boolean => dayjs(date1).isSame(date2, unit);

export const isAfterDate = (
  date1: Date,
  date2: Date,
  unit?: BasicDateUnit,
): boolean => dayjs(date1).isAfter(date2, unit);

export const isBeforeDate = (
  date1: Date | string,
  date2: Date | string,
  unit?: BasicDateUnit,
): boolean => dayjs(date1).isBefore(date2, unit);

export const isSameOrBeforeDate = (
  date1: Date,
  date2: Date,
  unit: DateUnit,
): boolean => dayjs(date1).isSameOrBefore(date2, unit);

export const isSameDay = (date1: Date, date2: Date): boolean =>
  isSameDate(date1, date2, 'day');

export const isSameMonth = (date1: Date, date2: Date): boolean =>
  isSameDate(date1, date2, 'month');

export const isSameYear = (date1: Date, date2: Date): boolean =>
  isSameDate(date1, date2, 'year');

export const isSameQuarter = (date1: Date, date2: Date): boolean =>
  isSameDate(date1, date2, 'quarter');

export const endOf = (date: Date, unit: DateUnit): Date =>
  dayjs(date).endOf(unit).toDate();

export const startOf = (date: Date, unit: DateUnit): Date =>
  dayjs(date).startOf(unit).toDate();

export const startOfMonth = (date: Date): Date => startOf(date, 'month');

export const startOfYear = (date: Date): Date => startOf(date, 'year');

export const endOfMonth = (date: Date): Date => endOf(date, 'month');

export const endOfMonthRespectNow = (date: Date): Date => {
  const endOfMonthDate = endOfMonth(date);
  return dayjs().isAfter(endOfMonthDate) ? endOfMonthDate : dayjs().toDate();
};

export const diffDates = (
  date1: Date | string,
  date2: Date | string,
  unit?: ExtendedDateUnit,
): number => dayjs(date1).diff(dayjs(date2), unit);

const unixDateToString = (date: number) =>
  dayjs.unix(date).utc().format().split('T')[0];

export const toDate = (
  date: string | number | null,
  format?: FormatDate,
): Date | null => {
  if (date === null) {
    return null;
  }

  if (typeof date === 'string') {
    return dayjs(date, format).toDate();
  }

  return dayjs(unixDateToString(date)).toDate();
};

export const isValidDate = (
  date: dayjs.ConfigType,
  format?: FormatDate,
): boolean => (date ? dayjs(date, format, true).isValid() : false);

/**
 * Represents a base structure for date ranges with `dateFrom` and `dateTo` properties.
 */
export interface DateRangeBase {
  /**
   * The start date of the range.
   * @description ISO date string
   */
  dateFrom: string | null;

  /**
   * The end date of the range.
   * @description ISO date string
   */
  dateTo: string | null;
}

/**
 * Represents a gap in the date ranges, extending the base date range with an `isGap` flag.
 */
export interface GapRange extends DateRangeBase {
  isGap: boolean;
}

/**
 * Finds missing intervals between date ranges.
 *
 * @param ranges - An array of date range objects. Sorted by dateFrom.
 * @returns An array of date ranges including filled gaps.
 */
export const getMissingIntervals = <T extends DateRangeBase>(
  ranges: T[],
): GapRange[] => {
  if (ranges.length <= 1) {
    return [];
  }

  const filledRanges: GapRange[] = [];

  for (let i = 0; i < ranges.length; i++) {
    const currentRange = ranges[i];

    // Determine the end of the current range
    const currentEnd = currentRange.dateTo ? dayjs(currentRange.dateTo) : null;

    if (i >= ranges.length - 1) {
      return filledRanges;
    }

    const nextRange = ranges[i + 1];
    const nextStart = nextRange.dateFrom ? dayjs(nextRange.dateFrom) : null;

    // Check if there is a gap between the end of the current range and the start of the next range
    if (
      currentEnd &&
      nextStart &&
      currentEnd.add(1, 'day').isBefore(nextStart)
    ) {
      // Create a gap range
      filledRanges.push({
        dateFrom: formatToDateStringForRequest(currentEnd.add(1, 'day')),
        dateTo: formatToDateStringForRequest(nextStart.subtract(1, 'day')),
        isGap: true, // Mark this range as a gap
      });
    }
  }

  return filledRanges;
};
export function convertUTCToLocalDate(date: Date) {
  return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
}
export function convertLocalToUTCDate(date: Date) {
  return new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()),
  );
}
