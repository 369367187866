import { REPORT_BUILDER_GRID_COLS_CLASSNAME } from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/reportBuilder';
import { REPORT_BUILDER_ORIENTATION_CLASSES } from '@/entities/report/reportBuilder';
import type { ReportBuilderTemplateGroupDto } from '@/entities/report/reportBuilder/api/settingsReportBuilderTemplatesGeneratedApi';
import { cn } from '@/shared/lib/css/cn';
import { joinWithDash } from '@/shared/lib/string';

export const PdfUI = (props: LayoutProps) => (
  <article
    className={cn(
      'ml-auto flex min-h-screen flex-col items-center justify-start bg-[#3D3D3D] px-4 py-6 print:m-0 print:min-h-[auto] print:w-full print:items-start print:bg-[initial] print:p-0 ',
      props.className,
    )}
  >
    {props.children}
  </article>
);

PdfUI.WidgetGroup = ({
  id,
  pageOrientation,
  hidePlaceholders,
  ...props
}: LayoutProps & {
  hidePlaceholders: boolean;
} & Pick<ReportBuilderTemplateGroupDto, 'id' | 'pageOrientation'>) => (
  <section
    id={joinWithDash(['section', id])}
    className={cn(
      'grid gap-6 bg-neutral-000 p-4 px-6 print:bg-[initial]',
      REPORT_BUILDER_GRID_COLS_CLASSNAME,
      REPORT_BUILDER_ORIENTATION_CLASSES[pageOrientation].className,
      {
        'print:empty:hidden': hidePlaceholders,
        'page-wide': pageOrientation === 'landscape',
      },
      props.className,
    )}
  >
    {props.children}
  </section>
);
