import { AgGridReactRef } from '@/lib/ag-grid/types';
import { cn } from '@/shared/lib/css/cn';
import {
  ColumnRowGroupChangedEvent,
  RowClassParams,
  RowHeightParams,
  RowNode,
} from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-enterprise';
import { AgGridReact, AgGridReactProps } from 'ag-grid-react';
import GroupRowRenderer from 'bundles/Settings/components/REport/GeneralLedgers/Table/GroupRowRenderer';
import Header from 'bundles/Settings/components/REport/GeneralLedgers/Table/Header';
import LoadingTable from 'bundles/Shared/components/LoadingTable';
import { forwardRef, useState } from 'react';
import { AnimationLoader } from '@/stories/AnimationLoader/AnimationLoader';

const DEFAULT_ROW_HEIGHT = 40;
const AG_GRID_WITH_GROUPING_CLASS = 'ag-with-groups';
const AG_GRID_WITH_CHECKBOX_CLASS = 'ag-with-checkbox-column';

export const groupHasDivider = (node: RowNode) =>
  node.hasChildren() && !node.firstChild && node.level === 0;

const getRowHeight = ({ node }: RowHeightParams) => {
  if (groupHasDivider(node)) return 70;

  if (node.hasChildren()) return 40;

  return DEFAULT_ROW_HEIGHT;
};
const getRowClass = ({ node }: RowClassParams) =>
  cn(
    'group',
    node.firstChild && 'ag-first-group-row',
    node.lastChild && 'ag-last-group-row',
    node.childrenAfterGroup?.some((n) => n.hasChildren()) &&
      'ag-group-has-children',
  );

const DEFAULT_GL_TABLE_COL_DEF = {
  suppressMovable: true,
  suppressMenu: true,
  headerComponent: Header,
  headerClass: 'text-neutral-500',
  // server side sorting
  comparator: () => 0,
  sortable: false,
};

const DEFAULT_GL_TABLE_AUTOGROUP_COL_DEF = {
  headerName: 'Group 1',
  field: 'balanceType',
};

interface Props extends AgGridReactProps, PropsWithClassName {
  loading: boolean;
  fetching: boolean;
}

const GeneralLedgersTable = forwardRef<AgGridReactRef, Props>(
  ({ loading, className, fetching, ...props }, ref) => {
    const [gridClasses, setGridClasses] = useState({});

    const groupChangedListener = (e: ColumnRowGroupChangedEvent) => {
      const groupedColumns = e.api.getColumnState().filter((c) => c.rowGroup);

      setGridClasses((prev) => ({
        ...prev,
        [AG_GRID_WITH_GROUPING_CLASS]: groupedColumns.length > 0,
      }));
    };

    return (
      <div className="w-full h-full ag-grid-30">
        <div
          className={cn(
            'w-full h-full',
            'table-theme-light',
            className,
            AG_GRID_WITH_CHECKBOX_CLASS,
            gridClasses,
          )}
        >
          {loading && <LoadingTable rows={10} cols={7} />}
          {fetching && !loading && (
            <AnimationLoader
              withBg
              className="top-[40px] h-[calc(100%_-_40px)] rounded-[16px]"
            />
          )}
          {!loading && (
            <AgGridReact
              loadingOverlayComponent={AnimationLoader}
              animateRows={false}
              suppressAnimationFrame
              getRowId={({ data }) => data.id}
              suppressMultiSort
              onGridReady={(e) => {
                ref(e);
                groupChangedListener(e);
              }}
              onColumnRowGroupChanged={groupChangedListener}
              groupSelectsChildren
              suppressContextMenu
              groupDefaultExpanded={-1}
              headerHeight={40}
              suppressCellFocus
              rowHeight={DEFAULT_ROW_HEIGHT}
              groupDisplayType="groupRows"
              autoGroupColumnDef={DEFAULT_GL_TABLE_AUTOGROUP_COL_DEF}
              defaultColDef={DEFAULT_GL_TABLE_COL_DEF}
              getRowClass={getRowClass}
              getRowHeight={getRowHeight}
              groupRowRenderer={GroupRowRenderer}
              enableCellTextSelection
              {...props}
            />
          )}
        </div>
      </div>
    );
  },
);

export default GeneralLedgersTable;
