import { apiClient, requireAuthorizationHeaders } from '@/lib/http';
import { DEFAULT_STRINGIFY_OPTIONS } from '@/lib/stringify';
import { stringify } from 'qs';
export * from './helpers';

const plainFetchPreviewReportBuidlerPdf = ({
  path,
  renderPdfHost: render_pdf_host,
}: {
  path: string;
  renderPdfHost: string;
}) => {
  return apiClient.get<Blob>(
    `${path}${stringify(
      {
        render_pdf_host,
      },
      DEFAULT_STRINGIFY_OPTIONS,
    )}`,
    {
      responseType: 'blob',
      headers: requireAuthorizationHeaders({}),
    },
  );
};

export const plainPreviewObjectLevelReportPdf = ({
  reportId,
  assetId,
  renderPdfHost,
}: {
  assetId: string;
  reportId: string;
  renderPdfHost: string;
}) => {
  return plainFetchPreviewReportBuidlerPdf({
    path: `/api/core/assets/${assetId}/report_builder/reports/${reportId}/preview_pdf`,
    renderPdfHost,
  });
};

export const plainPreviewEagleEyeReportPdf = ({
  reportId,
  renderPdfHost,
}: {
  reportId: string;
  renderPdfHost: string;
}) => {
  return plainFetchPreviewReportBuidlerPdf({
    path: `/api/report_builder/eagle_eye_reports/${reportId}/pdf`,
    renderPdfHost,
  });
};
