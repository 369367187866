import { DialogProps } from '@/shared/lib/hooks/useModal';
import { useEffect, useMemo } from 'react';
import { ISelectOption } from '@/stories/FormControls/Select/Select';
import { Button } from '@/stories/Button/Button';
import { Modal } from '@/stories/Modals/Modal/Modal';
import { ModalActions } from '@/stories/Modals/Modal/ModalActions';
import { ModalHeaderWithSubtitle } from '@/stories/Modals/ModalHeader/ModalHeaderWithSubtitle/ModalHeaderWithSubtitle';
import { Select } from '@/stories/FormControls/Select/Select';
import { StepsFormStepv2 } from '@/stories/StepsFormStep/StepsFormStepv2';
import { ForecastingForecast } from '@/entities/reconcile/forecasting/api/reconcileForecastingEntitiesGeneratedApi';
import { useForecastYears } from '@/entities/reconcile/forecasting/features/api/forecast/useForecastYears';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForecastBudgets } from '@/entities/reconcile/forecasting/features/api/forecast/useForecastBudgets';
import { filterSourcesWithData } from '@/entities/reconcile/forecasting/lib';

export const FORECAST_BUDGET_FROM_SOURCE_FORM_SCHEMA = yup.object().shape({
  year: yup.string().required(),
  sourceBudgetId: yup.string().required(),
});

export type ForecastBudgetFromSourceForm = yup.InferType<
  typeof FORECAST_BUDGET_FROM_SOURCE_FORM_SCHEMA
>;

export const CreateForecastingBudgetFromSourceModal = ({
  forecastId,
  defaultValues,
  onClose,
  onSubmit,
}: DialogProps<ForecastBudgetFromSourceForm> & {
  forecastId: ForecastingForecast['id'];
  defaultValues?: Partial<ForecastBudgetFromSourceForm>;
}) => {
  const {
    reset,
    watch,
    setValue,
    trigger,
    handleSubmit,
    formState: { isValid },
  } = useForm<ForecastBudgetFromSourceForm>({
    resolver: yupResolver(FORECAST_BUDGET_FROM_SOURCE_FORM_SCHEMA),
    defaultValues,
  });

  const { budgets: yearsBudgets, isFetching: isFetchingYears } =
    useForecastYears(forecastId);

  const { budgets: sourcesBudgets, isLoading: isLoadingSources } =
    useForecastBudgets(forecastId, Number(watch('year')));

  const yearsOptions: ISelectOption<string>[] = useMemo(
    () =>
      yearsBudgets.map((budget) => {
        const year = budget.year.toString();

        return {
          id: year,
          label: year,
        };
      }),
    [yearsBudgets],
  );

  const sourcesOptions: ISelectOption<string>[] = useMemo(
    () =>
      filterSourcesWithData(sourcesBudgets).map((budget) => ({
        id: budget.id,
        label: budget.legalEntity.name,
      })),
    [sourcesBudgets],
  );

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const values = watch();

  const handleYearSelect = (value: ISelectOption<string>) => {
    if (Array.isArray(value)) return;

    setValue('year', value?.id ?? '');
    trigger('year');
    setValue('sourceBudgetId', '');
  };

  const handleSourceSelect = (value: ISelectOption<string>) => {
    if (Array.isArray(value)) return;

    setValue('sourceBudgetId', value?.id ?? '');
    trigger('sourceBudgetId');
  };

  const selectedYear = useMemo(
    () => yearsOptions.find((o) => o.id === values.year),
    [values.year, yearsOptions],
  );
  const selectedSource = useMemo(
    () => sourcesOptions.find((o) => o.id === values.sourceBudgetId),
    [values.sourceBudgetId, sourcesOptions],
  );

  return (
    <Modal
      classes={{
        body: 'bg-neutral-000',
      }}
      header={
        <ModalHeaderWithSubtitle title="Create Forecasting Budget From Source" />
      }
      actions={
        <ModalActions alignItems="space-between">
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="success"
            type="submit"
            disabled={!isValid}
            // eslint-disable-next-line @typescript-eslint/no-shadow
            onClick={handleSubmit((values) => onSubmit?.(values))}
          >
            Create Budget
          </Button>
        </ModalActions>
      }
      toggle={onClose}
    >
      <form className="flex flex-col gap-2">
        <StepsFormStepv2
          activated={Boolean(values.year)}
          stepNumber={1}
          title="Select Year"
          required
        >
          <Select
            popoverProps={{
              appendToBody: true,
            }}
            placeholder={isFetchingYears ? 'Loading...' : ''}
            disabled={isFetchingYears}
            onSelectedChange={handleYearSelect}
            selected={selectedYear}
            spanContainer="w-full"
            classes={{
              input: 'w-full bg-white',
            }}
            options={yearsOptions}
          />
        </StepsFormStepv2>

        <StepsFormStepv2
          activated={Boolean(values.sourceBudgetId)}
          stepNumber={2}
          required
          title="Select Source"
        >
          <Select
            popoverProps={{
              appendToBody: true,
            }}
            placeholder={isLoadingSources ? 'Loading...' : ''}
            disabled={isLoadingSources}
            onSelectedChange={handleSourceSelect}
            selected={selectedSource}
            spanContainer="w-full"
            classes={{
              input: 'w-full bg-white',
            }}
            options={sourcesOptions}
          />
        </StepsFormStepv2>
      </form>
    </Modal>
  );
};
