import { useCustomer } from 'lib/customers';
import { permissionsFor } from 'lib/permissions';
import { startCase } from 'lodash-es';
import PropTypes from 'prop-types';

const PermissionsFor = ({ selectedRole }) => {
  let { products } = useCustomer();

  if (selectedRole) products = Object.keys(selectedRole.productsPermissions);

  return (
    products && (
      <table className="curved-border no-margin-bottom no-border-spacing striped-table table">
        <thead>
          <tr className="bg-light">
            <th className="font-12 light-90 font-weight-500">
              symmetRE Modules
            </th>
            <th className="font-12 light-90 font-weight-500 w-[120px] text-center">
              View
            </th>
            <th className="font-12 light-90 font-weight-500 w-[120px] border-l border-light-20 text-center">
              Manage
            </th>
            <th className="font-12 light-90 font-weight-500 w-[120px] border-l border-light-20 text-center">
              Configure
            </th>
          </tr>
        </thead>
        {products.map((productName) => (
          <tr key={productName}>
            <td className="font-14 light-90">{startCase(productName)}</td>
            <td className="width-108 text-center">
              <div
                className={
                  permissionsFor(selectedRole, productName, 'can_view')
                    ? 'sre-icon-check green'
                    : 'sre-icon-dot'
                }
              />
            </td>
            <td className="width-108 border-l border-light-20 text-center">
              <div
                className={
                  permissionsFor(selectedRole, productName, 'can_manage')
                    ? 'sre-icon-check green'
                    : 'sre-icon-dot'
                }
              />
            </td>
            <td className="width-108 border-l border-light-20 text-center">
              <div
                className={
                  permissionsFor(selectedRole, productName, 'can_configure')
                    ? 'sre-icon-check green'
                    : 'sre-icon-dot'
                }
              />
            </td>
          </tr>
        ))}
      </table>
    )
  );
};

PermissionsFor.propTypes = {
  selectedRole: PropTypes.shape({
    value: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
    permissions: PropTypes.shape(),
  }).isRequired,
};

export default PermissionsFor;
