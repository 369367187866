import React from 'react';
import { IColumn } from 'bundles/Shared/components/Table/types';
import CellTitle from '@/bundles/Settings/components/REport/TableSegment/cellFormatters/title';
import CellCreatedBy from '@/bundles/Settings/components/REport/TableSegment/cellFormatters/createdBy';
import CreatedAtCell from '@/bundles/Settings/components/REport/TableSegment/cellFormatters/createdAt';
import StatusCell from '@/bundles/Settings/components/REport/TableSegment/cellFormatters/status';
import ActionsCell from '@/bundles/Settings/components/REport/TableSegment/cellFormatters/actions';
import { ReportSegment } from 'bundles/Shared/entities/segment/model';

export interface IReportTableSegmentActions {
  destroyReportTableSegment: (id: number) => Promise<void> | void;
  editReportTableSegment: (id: number, payload: ReportSegment) => void;
  editAccessMembers: () => void;
}

export const buildReportTableSegmentColumns = (
  actions: IReportTableSegmentActions,
): IColumn<ReportSegment>[] => [
  {
    sortable: true,
    text: 'Segment details',
    dataField: 'title',
    formatter: ({ row: segment }) => <CellTitle segment={segment} />,
    headerStyle: { width: '25%' },
  },
  {
    sortable: true,
    text: 'Created by',
    dataField: 'created_by',
    formatter: ({ row: segment }) => <CellCreatedBy segment={segment} />,
    headerStyle: { width: '27%' },
  },
  {
    sortable: true,
    text: 'Created at',
    dataField: 'created_at',
    formatter: ({ row: segment }) => <CreatedAtCell segment={segment} />,
    headerStyle: { width: '27%' },
  },
  {
    sortable: true,
    text: 'Status',
    dataField: 'state',
    formatter: ({ row: segment }) => <StatusCell segment={segment} />,
    headerStyle: { width: '15%' },
  },
  {
    text: 'Actions',
    dataField: 'actions',
    formatter: ({ row: segment }) => (
      <ActionsCell actions={actions} segment={segment} />
    ),
    headerStyle: { width: '6%' },
  },
];
