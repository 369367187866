import { HighlightedText } from '@/bundles/Settings/components/REport/CategoriesList/SearchableTree/HighlightedText';
import NoDataOverlay from '@/bundles/Shared/components/NoDataOverlay';
import type {
  ReportDashboardEagleEyeAsset,
  ReportDashboardLegalEntity,
} from '@/bundles/Shared/entities/dashboard/model/types/types';
import type { LegalEntity } from '@/entities/core/legalEntity';
import { includesInLowerCase, mapListToIds } from '@/shared/lib/listHelpers';
import { Button } from '@/stories/Button/Button';
import { Field } from '@/stories/Field/Field';
import { Icon } from '@/stories/Icon/Icon';
import { IconButton } from '@/stories/IconButton/IconButton';
import { SearchInput } from '@/stories/FormControls/Inputs/SearchInput/SearchInput';
import { ThinTabGroup } from '@/stories/Tabs/ThinTabGroup/ThinTabGroup';
import { SidePanel } from '@/stories/Modals/Modal/Modal';
import SkeletonBlock from '@/stories/ProjectCard/SkeletonBlock';
import type { IAsset } from '@/types/Asset';
import { capitalize } from 'lodash-es';
import pluralize from 'pluralize';
import { PropsWithChildren, useState, type ComponentProps } from 'react';
import { useTabs } from 'stories/Tabs/useTabs';

const DICT = {
  excluded: {
    firstForm: 'exclude',
    thirdForm: 'excluded',
  },
  included: {
    firstForm: 'include',
    thirdForm: 'included',
  },
} as const;

export function LegalEntitiesSelectionModal({
  selectedAssets: assets,
  onChangeAll,
  onIncludeAsset,
  onIncludeLegalEntity,
  onExcludeAsset,
  onExcludeLegalEntity,
  onExcludeAssetAndFilterOutExcludedLegalEntities,
  includedLegalEntities,
  excludedLegalEntityIds,
  onClose,
  disableAllButtons,
  sidePanelProps,
}: {
  onChangeAll: (type: 'included' | 'excluded') => void;
  onIncludeAsset: (
    assetId: IAsset['id'],
    assetExcludedLegalEntityIds: LegalEntity['id'][],
  ) => void;
  onIncludeLegalEntity: (legalEntityId: LegalEntity['id']) => void;
  onExcludeAsset: (assetId: IAsset['id']) => void;
  onExcludeLegalEntity: (legalEntityId: LegalEntity['id']) => void;
  onExcludeAssetAndFilterOutExcludedLegalEntities: (
    assetId: IAsset['id'],
    legalEntities: LegalEntity['id'][],
  ) => void;
  selectedAssets: Pick<IAsset, 'id' | 'legalEntities' | 'name'>[];
  excludedLegalEntityIds: LegalEntity['id'][];
  includedLegalEntities: Pick<
    LegalEntity,
    'id' | 'name' | 'classification' | 'code'
  >[];
  onClose: () => void;
  disableAllButtons?: boolean;
  sidePanelProps?: Partial<
    Pick<ComponentProps<typeof SidePanel>, 'position' | 'size'>
  >;
}) {
  const [query, setSearchText] = useState('');

  const tabs = [
    {
      counter: excludedLegalEntityIds.length,
      id: 'excluded',
      label: `Excluded`,
    },
    {
      counter: includedLegalEntities.length,
      id: 'included',
      label: 'Included',
    },
  ] as const;

  const { tab, thinTabGroupProps } = useTabs(tabs, tabs[1]);

  const isExcludedTab = tab!.id === 'excluded';
  const dict = DICT[tab!.id];
  const opositeDict = DICT[isExcludedTab ? 'included' : 'excluded'];

  const filterLE = (le: ReportDashboardLegalEntity) => {
    if (isExcludedTab) {
      return excludedLegalEntityIds.includes(le.id);
    }
    return !excludedLegalEntityIds.includes(le.id);
  };

  const filterAsset = (asset: ReportDashboardEagleEyeAsset) => {
    const filteredByTab = asset.legalEntities.filter(filterLE);
    if (filteredByTab.length === 0) return false;

    return (
      includesInLowerCase(asset.name, query) ||
      filteredByTab.some((le) => {
        return includesInLowerCase(le.name, query);
      })
    );
  };

  const filteredAssets = assets.filter(filterAsset);

  return (
    <SidePanel
      closeOnOutsideClick
      header="Legal Entities"
      toggle={onClose}
      classes={{
        body: 'flex flex-col gap-4 bg-neutral-050',
      }}
      {...sidePanelProps}
    >
      <div className="flex flex-col gap-4">
        <p className="inline-regular text-neutral-550">
          You can manage which entities are displayed on the dashboard via the
          Excluded and Included tab.
        </p>
        <ThinTabGroup
          {...thinTabGroupProps}
          classes={{
            item: 'grow justify-center',
            itemContainer: 'grow',
          }}
        />
        <div className="flex items-center justify-between gap-4">
          <SearchInput
            className="grow"
            size="s"
            placeholder="Asset of Legal Entity"
            value={query}
            onChange={(e) => setSearchText(e.target.value)}
          />
          <Button
            variant="secondary"
            size="s"
            onClick={() => {
              onChangeAll(tab!.id);
            }}
            loading={disableAllButtons}
            disabled={disableAllButtons}
          >
            {capitalize(opositeDict.firstForm)} All
          </Button>
        </div>
        {filteredAssets.length > 0 ? (
          filteredAssets.map((asset) => {
            const filteredLegalEntities = asset.legalEntities.filter(filterLE);

            return (
              <div
                className="group/object flex flex-col gap-2 rounded-[8px] bg-neutral-000 p-2"
                key={asset.id}
              >
                <div className="flex items-start justify-between p-1">
                  <div className="flex flex-col gap-0">
                    <div className="flex items-center gap-2">
                      <Icon className="text-neutral-500" iconName="asset" />
                      <p className="inline-semibold text-neutral-999">
                        <HighlightedText
                          {...HighlightedText.getProps({
                            query,
                            text: asset.name,
                          })}
                        />
                      </p>
                    </div>
                    <p className="secondary-semibold text-neutral-550">
                      {filteredLegalEntities.length} of{' '}
                      {asset.legalEntities.length}{' '}
                      {pluralize('Legal Entity', asset.legalEntities.length)}{' '}
                      {pluralize('is', asset.legalEntities.length)}{' '}
                      {dict.thirdForm}
                    </p>
                  </div>
                  {filteredLegalEntities.length > 0 && (
                    <Button
                      disabled={disableAllButtons}
                      className="opacity-0 transition-opacity group-hover/object:opacity-100"
                      onClick={() => {
                        if (isExcludedTab) {
                          onIncludeAsset(
                            asset.id,
                            mapListToIds(filteredLegalEntities),
                          );
                          return;
                        }
                        onExcludeAssetAndFilterOutExcludedLegalEntities(
                          asset.id,
                          mapListToIds(asset.legalEntities),
                        );
                      }}
                      variant="secondary"
                      size="xs"
                    >
                      {capitalize(opositeDict.firstForm)} Asset
                    </Button>
                  )}
                </div>
                {filteredLegalEntities.length > 0 && (
                  <div className="flex flex-col">
                    {filteredLegalEntities.map((le, _, self) => (
                      <div
                        key={le.id}
                        className="group inline-regular flex items-center justify-between rounded-[10px] py-1 pl-2 pr-1 text-neutral-800 hover:bg-neutral-100"
                      >
                        <p>
                          <HighlightedText
                            {...HighlightedText.getProps({
                              query,
                              text: le.name,
                            })}
                          />
                        </p>
                        <Button
                          disabled={disableAllButtons}
                          size="xs"
                          variant="danger"
                          className="opacity-0 transition-opacity group-hover:opacity-100"
                          onClick={() => {
                            if (isExcludedTab) {
                              return onIncludeLegalEntity(le.id);
                            }
                            if (asset.legalEntities.length === 1) {
                              return onExcludeAsset(asset.id);
                            }
                            if (self.length === 1) {
                              return onExcludeAssetAndFilterOutExcludedLegalEntities(
                                asset.id,
                                mapListToIds(asset.legalEntities),
                              );
                            }
                            onExcludeLegalEntity(le.id);
                          }}
                        >
                          {capitalize(opositeDict.firstForm)}
                        </Button>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            );
          })
        ) : (
          <NoDataOverlay title="No Assets Found" />
        )}
      </div>
    </SidePanel>
  );
}

LegalEntitiesSelectionModal.Wrapper = ({
  legalEntitiesCounter,
  children,
  isLoading,
  toggleLegalEntitiesModalOpened,
}: {
  legalEntitiesCounter: number;
  toggleLegalEntitiesModalOpened: () => void;
  isLoading?: boolean;
} & PropsWithChildren) => {
  return (
    <div className="col-span-3 flex flex-col gap-4 rounded-[8px] bg-neutral-100 p-4">
      <div className="flex flex-col gap-2">
        <Field.Label required>Access check of Legal Entities</Field.Label>
        <p className="secondary-regular text-neutral-550">
          This list of legal entities based on the objects selected above
        </p>
      </div>

      <div className="relative flex items-center justify-start gap-3">
        {isLoading ? (
          <SkeletonBlock className="h-[40px] w-full" />
        ) : (
          <>
            <div className="flex h-8 w-8 items-center justify-center rounded-lg bg-neutral-000">
              <Icon iconName="entities" />
            </div>
            <p className="inline-semibold text-neutral-800">
              {legalEntitiesCounter} Legal{' '}
              {pluralize('Entity', legalEntitiesCounter)}
            </p>
            <IconButton
              onClick={toggleLegalEntitiesModalOpened}
              iconName="edit"
            />
          </>
        )}
      </div>
      {children}
    </div>
  );
};
