import React from 'react';
import { cn } from '@/shared/lib/css/cn';
import { IUserRole } from 'types/User';
import { ListOption } from 'stories/Checkbox/CheckList';
import { sortBy } from 'lodash-es';
import { Field } from 'stories/Field/Field';
import { IconButton } from 'stories/IconButton/IconButton';
import { Tumbler } from 'stories/Tumbler/Tumbler';
import styles from '@/bundles/Settings/components/REport/TableBuilder/Rules/sharedStyles.module.scss';
import { IPermissionRuleState } from '@/bundles/Settings/components/REport/TableBuilder/types';
import { DEFAULT_PERIOD_LIMITATION_RULE_ID } from '@/bundles/Settings/components/REport/TableBuilder/helpers/constants';
import { SharedSelect } from 'bundles/Shared/components/GroupForm/FormItems/new/SharedSelect';
import { SharedInput } from 'bundles/Shared/components/GroupForm/FormItems/new/SharedInput';

interface Props {
  onRemove: (id: IPermissionRuleState['id']) => void;
  onToggle: (id: IPermissionRuleState['id'], checked: boolean) => void;
  onUserRoleChange: (
    id: IPermissionRuleState['id'],
    userRoleId: IUserRole['id'],
  ) => void;
  onPeriodChange: (id: IPermissionRuleState['id'], val: string) => void;
  allRoleOptions: ListOption[];
  notSelectedRoleOptions: ListOption[];
  rule: IPermissionRuleState;
  className?: string;
}

export const PeriodLimitationEntry = ({
  onRemove,
  onToggle,
  onUserRoleChange,
  onPeriodChange,
  allRoleOptions,
  notSelectedRoleOptions,
  rule,
  className,
}: Props) => {
  const selectedRoleOption = allRoleOptions.find(
    ({ value }) => rule.userRoleId === value,
  );

  const disabled = rule.limit === null;

  const sortedRoleOptions = sortBy(
    notSelectedRoleOptions,
    (item) => item.id !== DEFAULT_PERIOD_LIMITATION_RULE_ID,
    'label',
  );

  return (
    <div className={cn(styles.wrapper, className)}>
      <div className={styles.topRow}>
        <Field labelText="Role" required newGen>
          <SharedSelect
            options={sortedRoleOptions}
            value={selectedRoleOption}
            onChange={(selectedType) => {
              if (!selectedType || Array.isArray(selectedType)) return;
              onUserRoleChange(rule.id, selectedType.id);
            }}
            placeholder="Select Role"
            newGen
          />
        </Field>
        <IconButton
          variant="secondary"
          iconName="closeSmall"
          onClick={() => onRemove(rule.id)}
        />
      </div>
      <Field labelText="Role" required newGen>
        <div className="flex flex-col gap-1.5">
          <div className="label-regular">Number of Months back to display</div>
          <div className="flex w-full items-center gap-4">
            <SharedInput
              min={1}
              classNameWrapper="w-[60%]"
              required
              type="number"
              name="limitation"
              disabled={disabled}
              value={disabled ? '' : String(rule.limit)}
              onChange={(e) => onPeriodChange(rule.id, e.target.value)}
              placeholder="Text Value"
            />
            <Tumbler
              checked={disabled}
              onChange={(e) => onToggle(rule.id, e.target.checked)}
              className="align-self-center"
            >
              No limiting
            </Tumbler>
          </div>
        </div>
      </Field>
    </div>
  );
};
