import React from 'react';
import PermissionList from '@/bundles/Shared/components/PermissionList';
import DocumentItem from '@/bundles/Shared/components/DocumentItem';

const FeedMessageAttachment = ({
  id,
  filename,
  fileSize,
  fileExtension,
  permissions,
  onRemove,
  progress,
  withLink,
}) => {
  const item = (
    <div className="card card-light flex-row justify-between border-radius-[0.5rem] p-[0.5rem] mb-s">
      <DocumentItem
        className="ml-s"
        filename={filename}
        fileSize={fileSize}
        fileExtension={fileExtension}
        permissions={
          <PermissionList
            permissions={permissions}
            hideIcon
            mainListClasses="secondary-regular light-90"
          />
        }
        onRemove={() => onRemove(id)}
        progress={progress}
      />
    </div>
  );

  if (withLink) {
    return (
      <a
        href={`/shared-files/${id}?disposition=inline`}
        target="_blank"
        rel="noreferrer"
      >
        {item}
      </a>
    );
  }

  return item;
};

export default FeedMessageAttachment;
