import {
  AgCartesianSeriesTooltipRendererParams,
  ICellRendererParams,
} from 'ag-grid-community';

import { NumericValueDisplayOptions } from '@/shared/lib/formatting/displayOptions';
import { WidgetDataRowHistoryColumnModel } from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/history';
import {
  convertValueByDisplayType,
  createAgGridTableFormattedColDef,
} from '@/shared/lib/formatting/table';
import {
  WidgetDataHistoryRow,
  WidgetDataRowHistoryEntry,
} from '@/bundles/Shared/widgets/dashboard/widgets/model';
import { formatDateRangeForPeriodType } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/table/lib';
import {
  TrailingPeriodType,
  TimePeriodType,
} from '@/bundles/Shared/widgets/dashboard/widgets/common/config';
import { getCssVariableByAccessor } from '@/lib/helpers';
import { CssVar } from '@/shared/config/cssVar';
import { nameOf } from '@/types/utils';
import { formatNumberByDisplayOptions } from '@/shared/lib/formatting/number';
import { SPARKLINE_TYPE } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/fields/SparklineTypeField';
const DEFAULT_PERIOD_TYPE_FOR_SPARKLINE_TOOLTIP = {
  period_type: 'month',
} as const;

export type SparklineCellRendererConfigParams = {
  type: 'sparkline';
  params: {
    type: (typeof SPARKLINE_TYPE)[keyof typeof SPARKLINE_TYPE];
    stroke?: string | null;
    fill?: string | null;
  };
};

export const getSparklineCellRendererComponentObject = ({
  params,
  displayOptions,
  column,
  columnConfig,
  config,
}: {
  params: ICellRendererParams;
  displayOptions: NumericValueDisplayOptions;
  column?: {
    key: number;
  };
  columnConfig?: {
    period_type?: TimePeriodType | TrailingPeriodType;
  };
  config: SparklineCellRendererConfigParams;
}) => {
  const row = params.data as WidgetDataHistoryRow;
  const columnHistory = WidgetDataRowHistoryColumnModel.getHistoryForColumn(
    row,
    column?.key ?? 0,
  );
  const hasMoreThanOneNonNullEntry =
    row != null &&
    WidgetDataRowHistoryColumnModel.hasMoreThanOneNonNullEntry(
      WidgetDataRowHistoryColumnModel.getHistoryForColumn(
        row,
        column?.key ?? 0,
      ),
    );

  if (!hasMoreThanOneNonNullEntry) {
    const { cellRenderer } = createAgGridTableFormattedColDef({
      type: 'number',
    });

    return {
      component: cellRenderer,
      params: {
        ...params,
        // don't show border for empty sparkline cell renderer (we set borders in cellStyle)
        styles: {
          border: 0,
        },
        // don't show value for sparkline cell renderer
        value: null,
      },
    };
  }

  const tooltipRenderer = (
    tooltipParams: AgCartesianSeriesTooltipRendererParams,
  ) => {
    const { yValue, xValue } = tooltipParams;

    return {
      title: xValue,
      content: formatNumberByDisplayOptions(yValue, displayOptions),
    };
  };
  const sparklineData = WidgetDataRowHistoryColumnModel.orderByDateFromAsc(
    columnHistory,
  ).map((entry) => ({
    ...entry,
    // can't get entry in tooltip renderer, so we need to format the date manually
    // TODO: move it to the tooltip renderer after ag-grid update
    dateFrom: formatDateRangeForPeriodType(
      entry,
      columnConfig ?? DEFAULT_PERIOD_TYPE_FOR_SPARKLINE_TOOLTIP,
    ),
    value: convertValueByDisplayType(entry.value, displayOptions.type),
  }));

  return {
    component: 'agSparklineCellRenderer',
    params: {
      value: sparklineData,
      sparklineOptions: {
        type: config.params.type ?? 'line',
        xKey: nameOf<WidgetDataRowHistoryEntry>('dateFrom'),
        yKey: nameOf<WidgetDataRowHistoryEntry>('value'),
        line: {
          strokeWidth: 2,
          stroke:
            config.params.stroke ?? getCssVariableByAccessor(CssVar.info090),
        },
        fill: config.params.fill ?? getCssVariableByAccessor(CssVar.info030),
        highlightStyle: {
          fill:
            config.params.stroke ?? getCssVariableByAccessor(CssVar.info090),
        },
        tooltip: {
          container: document.body,
          renderer: tooltipRenderer,
        },
      },
    },
  };
};
