import React, { useState, useCallback } from 'react';
import { navigate, Router, useParams } from '@reach/router';
import { useAppSelector } from '@/shared/lib/hooks/redux';
import { usePageParamsTableSorting } from '@/shared/lib/hooks/usePageParamsTableSorting';
import {
  PageParamsPagination,
  PageParamsProvider,
  PageParamsSearch,
} from 'bundles/Shared/components/pageParams';
import Invoice from 'bundles/DrawPackage/InvoicesFlow/Invoices/Invoice';
import Table from 'bundles/Shared/components/Table/Table';
import { IPageParams } from 'bundles/Construction/types';
import { useGetDrawsQuery } from 'bundles/Construction/api/invoices';
import { useNavigation } from '@/shared/lib/hooks/useNavigation';
import { AnimationLoader } from 'stories/AnimationLoader/AnimationLoader';
import { Button } from 'stories/Button/Button';
import DrawCreateModal from '@/bundles/DrawPackage/DrawPage/DrawCreateModal';
import {
  drawRequestColumns,
  TFilterModelDrawRequest,
} from '@/bundles/DrawPackage/DrawPage/components/useColumns';
import DrawPageInvoices from '@/bundles/DrawPackage/DrawPage/DrawPageInvoices';

export interface IPageParamsDrawRequest extends IPageParams {
  status: 'created';
}

const initialPageParams: IPageParamsDrawRequest = {
  amount: {
    from: '',
    to: '',
  },
};

const DrawRequest = () => {
  const legalEntity = useAppSelector(
    (store) => store.developmentBudget.legalEntity,
  );

  const params = useParams();
  const [pageParams, setPageParams] = useState(initialPageParams);

  const { getUrl } = useNavigation();

  const { settings, setSettings } = usePageParamsTableSorting(
    pageParams,
    setPageParams,
  );

  const handleFilterModelChange = useCallback(
    (filterModel: TFilterModelDrawRequest) =>
      setPageParams((prevParams) => {
        return {
          ...prevParams,
          status: filterModel.status,
          page: 1,
          vendor_ids: filterModel.vendorName?.map((item) => item.id),
          amount: filterModel.amount,
        };
      }),
    [],
  );

  const { data, isLoading } = useGetDrawsQuery({
    legalEntityCode: params?.legalEntityCode || legalEntity?.code,
    pageParams,
  });

  if (isLoading) return <AnimationLoader className="static min-h-[360px]" />;

  const createDraw = () => {
    navigate('draws/new');
  };

  return (
    <div className="custom-container">
      <h2 className="font-weight-700 mb-l mt-xl">Draw Requests</h2>
      <PageParamsProvider pageParams={pageParams} setPageParams={setPageParams}>
        <div className="flex items-center justify-between mb-m">
          <div>
            <PageParamsPagination
              loading={isLoading}
              totalSize={data.meta.totalSize}
              sizePerPage={data.meta.perPage}
            />
          </div>
          <div className="flex items-center">
            <PageParamsSearch />
            <Button
              data-cy="invoices"
              className="ml-m"
              variant="primary"
              onClick={createDraw}
            >
              Create Draw Request
            </Button>
          </div>
        </div>
      </PageParamsProvider>
      <div>
        <Table
          loading={isLoading}
          settings={settings}
          setSettings={setSettings}
          columns={drawRequestColumns(getUrl, params?.legalEntityCode)}
          items={data?.items || []}
          onFilterModelChange={handleFilterModelChange}
        />
      </div>
      <Router primary={false}>
        <DrawCreateModal path="new" />
        <DrawPageInvoices
          path=":drawId/invoices"
          backRoute="RECONCILE_DEVELOPMENT_LEGAL_ENTITY_DRAWS"
        />
        <Invoice
          backRoute="RECONCILE_DEVELOPMENT_LEGAL_ENTITY_DRAWS_INVOICES"
          path=":drawId/invoices/:id"
          hideActionButton
        />
      </Router>
    </div>
  );
};

export default DrawRequest;
