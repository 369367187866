import React from 'react';
import { cn } from '@/shared/lib/css/cn';
import '@/stories/SelectableMultilineButton/selectableMultilineButton.css';

interface Props
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  title: string;
  selected?: boolean;
  text?: string;
  active?: boolean;
  styles?: {
    button: string;
  };
}

export const SelectableMultilineButton = ({
  className,
  title,
  text,
  selected,
  styles,
  active,
  ...props
}: Props) => (
  <button
    className={cn(
      'selectable-multiline-button selectable-multiline-button__content',
      {
        _selected: selected,
      },
      className,
      active,
    )}
    type="button"
    {...props}
  >
    <h5 className="selectable-multiline-button__title inline-semibold">
      {title}
    </h5>
    <p className="selectable-multiline-button__text secondary-regular">
      {text}
    </p>
  </button>
);

export default SelectableMultilineButton;
