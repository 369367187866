import { cn } from '@/shared/lib/css/cn';
import { SreAvatar } from '@/stories/SreAvatar/SreAvatar';
import React from 'react';

export const UserInfoSection = ({
  roleName,
  fullName,
  email,
  size = 's',
}: {
  roleName: string;
  fullName: string;
  email: string;
  size?: 'xs' | 's';
}) => {
  const fontSize = size === 'xs' ? 'text-xs' : 'text-base';
  return (
    <>
      <span className={cn('font-weight-400 inline-regular', fontSize)}>
        {roleName}
      </span>
      <SreAvatar
        text={fullName}
        width="56px"
        height="56px"
        color="var(--light)"
        backgroundColor="var(--green)"
        borderRadius="1rem"
        fontSize="1.25rem"
        fontWeight="700"
        className="my-[1rem]"
      />
      <h5 className="light-90 mb-[0.5rem]">{fullName}</h5>
      <span className={cn('light-60 font-weight-400 inline-regular', fontSize)}>
        {email}
      </span>
    </>
  );
};
