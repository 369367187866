import { GetApiSettingsReportRentRollNonRevenueConfigApiResponse } from '@/shared/api/settingsReportNonRevenueConfigGeneratedApi';

type NonRevenueUnit =
  GetApiSettingsReportRentRollNonRevenueConfigApiResponse['sections'][number]['items'][number];

const filterNonRevenueUnits = (items: NonRevenueUnit[]) => {
  return items.filter((item) => item.nonRevenue) ?? [];
};

export const transformNonRevenueConfigDtoToForm = (
  data: GetApiSettingsReportRentRollNonRevenueConfigApiResponse,
) => {
  return Object.fromEntries(
    data.sections.map((section) => [
      section.erpSystem,
      filterNonRevenueUnits(section.items).map((item) => item.key) ?? [],
    ]),
  );
};
export const transofrmNonRevenueConfigDtoToEmptyForm = (
  data: GetApiSettingsReportRentRollNonRevenueConfigApiResponse,
) => {
  return Object.fromEntries(
    data.sections.map((section) => [section.erpSystem, []]),
  );
};
export const transformFormToNonRevenueConfigDto = (
  data: GetApiSettingsReportRentRollNonRevenueConfigApiResponse,
  values: Record<string, string[]>,
) => {
  return data.sections.map((section) => ({
    erp_system: section.erpSystem,
    items: section.items.map((item) => ({
      key: item.key,
      non_revenue: values[section.erpSystem]?.includes(item.key) ?? false,
    })),
  }));
};
