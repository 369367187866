import { EAGLE_EYE_DASHBOARD_SECTION_TYPE } from 'bundles/Shared/entities/dashboard/model/types/types';
import type {
  PeriodShiftConfig,
  ReportEagleEyeDashboardSection,
} from 'bundles/Shared/entities/dashboard/model/types/types';
import { KpiTableWidgetDto } from '@/shared/api/dashboardsGeneratedApi';
import { TableVizConfig } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/model';
import { ValueDisplayOptions } from '@/shared/lib/formatting/displayOptions';
import { KpiTableWidgetConfigDto } from '@/shared/api/dashboardsSettingsGeneratedApi';
import { WidgetTableColumnSettingsColumnType } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/form';
import { AdjustmentExpressionName } from '@/bundles/Shared/widgets/dashboard/widgets/common/features/adjustments/config/base';
import { TotalCalculationStrategy } from '@/bundles/Shared/widgets/dashboard/widgets/common/config';
type AvailablePeriodShiftConfig = Pick<
  PeriodShiftConfig,
  'days' | 'weeks' | 'months'
>;

export const KPI_TABLE_GROUPING_TYPES = {
  SEGMENTS: 'segments',
  ASSETS: 'assets',
} as const;

export const KPI_TABLE_PERIOD_TYPES = {
  WEEK: 'week',
  DAY_TO_DAY: 'day_to_day',
  MONTH_TO_MONTH: 'month_to_month',
  YEAR_TO_DATE: 'year_to_date',
  MONTH: 'month',
} as const;

export type KpiTableGroupingType =
  (typeof KPI_TABLE_GROUPING_TYPES)[keyof typeof KPI_TABLE_GROUPING_TYPES];
export type KpiTablePeriodType =
  (typeof KPI_TABLE_PERIOD_TYPES)[keyof typeof KPI_TABLE_PERIOD_TYPES];
export type KpiTableWidgetConfigColumn = {
  key: number;
  label: string;
  type: WidgetTableColumnSettingsColumnType;
  expression: string;
  value_display_options?: ValueDisplayOptions;
  period_shift?: AvailablePeriodShiftConfig;
  adjustment_expression?: AdjustmentExpressionName;
  history_size: number;
  total_calculation_strategy?: TotalCalculationStrategy;
};

export interface KpiTableWidgetConfig {
  columns: KpiTableWidgetConfigColumn[];
  period_types: KpiTablePeriodType[];
  mtd_ytd_toggle: boolean;
  default_options: {
    grouping_type: KpiTableGroupingType;
    period_type: KpiTablePeriodType;
    date: {
      period_shift?: AvailablePeriodShiftConfig;
    };
  };
  viz_type: 'table';
  viz_config: TableVizConfig;
}

export type KpiTableWidgetSection = ReportEagleEyeDashboardSection & {
  widgetType: typeof EAGLE_EYE_DASHBOARD_SECTION_TYPE.KPI_TABLE;
  widgetConfig: KpiTableWidgetConfigDto;
  defaultOptions: {
    groupingType: KpiTableGroupingType;
    date: DateString;
    periodType: KpiTablePeriodType;
    availablePeriodTypes: KpiTablePeriodType[];
  };
};
export type KpiTableWidgetDataRow = NonNullable<
  KpiTableWidgetDto['data']
>[number] & {
  subLabel?: string;
};
export type KpiTableWidgetDataColumn = {
  key: number;
  label: string;
  values?: {
    key: number;
    value: number | null;
  }[];
  valueDisplayOptions: ValueDisplayOptions;
};
export type KpiTableWidgetDataTotalRow = KpiTableWidgetDataRow & {
  type: 'total';
};

export const isKpiTableWidgetSection = (
  section: ReportEagleEyeDashboardSection,
): section is KpiTableWidgetSection =>
  section.widgetType === EAGLE_EYE_DASHBOARD_SECTION_TYPE.KPI_TABLE;
export const isKpiTableRowTotal = (
  row: KpiTableWidgetDataRow,
): row is KpiTableWidgetDataTotalRow => row.type === 'total';
export const isKpiTableRowSegment = (
  row: KpiTableWidgetDataRow,
): row is KpiTableWidgetDataRow & { type: 'segment' } => row.type === 'segment';
export const isKpiTableRowAsset = (
  row: KpiTableWidgetDataRow,
): row is KpiTableWidgetDataRow & { type: 'asset' } => row.type === 'asset';
export const isKpiTableRowLegalEntity = (
  row: KpiTableWidgetDataRow,
): row is KpiTableWidgetDataRow & { type: 'legal_entity' } =>
  row.type === 'legal_entity';
