import { generateUrl, ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import { useModal } from '@/shared/lib/hooks/useModal';
import { SreUnitType } from 'bundles/Shared/entities/sreUnitType/model';
import { UnitTypeSettingsModal } from 'bundles/Shared/entities/sreUnitType/ui/UnitTypeModal';

export const generateUrlSettingsUnitTypes = (mapped = true) =>
  generateUrl(ROUTES_ROOT.settings.report.unitTypes.fullPath, {
    queryParams: {
      mapped,
    },
  });

export function isSreUnitTypeNameValid(name: string) {
  return /^[a-z_0-9]+$/g.test(name);
}

export const useSreUnitTypeSettingsModal = () => {
  const { openModal } = useModal();

  return (unitType: Partial<Pick<SreUnitType, 'id' | 'name' | 'label'>>) =>
    openModal(UnitTypeSettingsModal, {
      formProps: {
        defaultValues: {
          name: unitType.name,
          label: unitType.label,
        },
      },
    });
};
