import React, { useState } from 'react';
import { Button } from 'stories/Button/Button';
import { Textarea } from 'stories/Textarea/Textarea';

const RejectForm = ({ onCancel, onReject }) => {
  const [comment, setComment] = useState<string | undefined>();
  const handleSubmit = () => {
    if (comment) {
      onReject(comment);
    } else {
      setComment('');
    }
  };

  return (
    <div className="w-full">
      <div className="dark-60 inline-semibold mb-s">
        Complete this form to Reject
      </div>
      <div className="mb-l">
        <Textarea
          placeholder="Leave a comment to explain the rejection reason"
          onChange={(e) => setComment(e.target.value)}
          value={comment}
        />
        {comment === '' && (
          <span className="red secondary-regular mt-s">
            Please, leave a comment
          </span>
        )}
      </div>

      <div className="flex justify-between w-full gap-l">
        <Button variant="secondary" size="l" onClick={onCancel} fluid>
          Cancel
        </Button>
        <Button variant="danger" size="l" onClick={handleSubmit} fluid>
          Reject
        </Button>
      </div>
    </div>
  );
};

export default RejectForm;
