import React from 'react';
import { cn } from '@/shared/lib/css/cn';
import styles from '@/bundles/Construction/components/Shared/ProgressBar/styles.module.scss';

interface Props {
  value: number;
  classes?: {
    wrapper?: string;
    value?: string;
  };
}

export default function ProgressBar({ value, classes, ...props }: Props) {
  const width = () => {
    const MIN_BAR_WIDTH = 3;
    const res = Math.ceil(parseFloat(value));

    if (res < MIN_BAR_WIDTH) return 3;

    if (Number.isNaN(res)) return 1;

    return res;
  };
  return (
    <div className={cn(styles.progressWrapper, classes?.wrapper)} {...props}>
      <div
        className={cn(styles.progressValue, classes?.value)}
        style={{
          width: `${width()}%`,
        }}
      />
    </div>
  );
}
