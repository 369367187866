import {
  CapitalInvestmentEntryType,
  DistributionKind,
  ReportLineItem,
} from 'bundles/REturn/actions/types';
import { ICapitalInvestment } from 'bundles/REturn/types';
import { SingleDistributionKind } from '@/bundles/REturn/components/workflows/createEntry/types';
import { WorkflowItems } from '@/bundles/REturn/components/workflows/createEntry/utils/classes/workflowItems';

export function createWorkflowFlags({
  entryType,
  selectedTxs,
  selectedCapitalInvestments,
  stepIdx,
  singleDistributionKind,
}: {
  entryType: CapitalInvestmentEntryType;
  selectedCapitalInvestments: ICapitalInvestment[];
  selectedTxs: ReportLineItem[];
  stepIdx: number;
  singleDistributionKind: SingleDistributionKind;
}) {
  const transactions = new WorkflowItems(selectedTxs);
  const capitalInvestments = new WorkflowItems(selectedCapitalInvestments);

  const kinds = {
    isSimpleLinking: transactions.isSingle && capitalInvestments.isSingle,
    isSplitting: transactions.isSingle && capitalInvestments.isMultiple,
    isBulkLinking: transactions.isMultiple && capitalInvestments.isSingle,
  } as const;

  function selectedOneKind(
    kind: SingleDistributionKind,
  ): kind is DistributionKind {
    return kind !== 'split_transaction_between_categories' && kind !== null;
  }

  const isDistributionWorkflow = entryType === 'distributions';
  const shouldSelectKindForSingleTx =
    kinds.isSimpleLinking && isDistributionWorkflow;
  const shouldSelectOneKindForMultipleTx =
    transactions.isMultiple && isDistributionWorkflow;

  const showExtendedGoForwardHintText =
    (stepIdx === 0 && shouldSelectOneKindForMultipleTx) ||
    (stepIdx === 1 && shouldSelectKindForSingleTx);

  const steps = {
    transactions: {
      isCurrent: stepIdx === 0,
      get isDisabledToGoForward() {
        if (shouldSelectOneKindForMultipleTx) {
          return singleDistributionKind === null || !transactions.isSelected;
        }
        return !transactions.isSelected;
      },
    },
    invEntities: {
      isCurrent: stepIdx === 1,
      get isDisabledToGoForward() {
        if (shouldSelectKindForSingleTx) {
          return (
            singleDistributionKind === null || !capitalInvestments.isSelected
          );
        }
        return !capitalInvestments.isSelected;
      },
    },
    review: {
      isCurrent: stepIdx === 2,
    },
  } as const;

  return {
    transactions,
    capitalInvestments,
    kinds,
    steps,
    shouldSelectKindForSingleTx,
    shouldSelectOneKindForMultipleTx,
    showExtendedGoForwardHintText,
    selectedOneKind,
  };
}
