import { useAppDispatch } from '@/shared/lib/hooks/redux';
import type { ISharedFolder } from '@/types/SharedFolder';
import EntityContext from 'bundles/Shared/EntityContext';
import { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from 'stories/Button/Button';
import { Input } from 'stories/FormControls/Inputs/Input/Input';
import { Modal } from 'stories/Modals/Modal/Modal';
import { createFolder, updateFolder } from '@/bundles/Assets/actions';

const NewAlbumModal = ({
  setOpened,
  folder,
  fetchAlbums,
  parentId,
}: {
  setOpened: (opened: boolean) => void;
  folder: ISharedFolder;
  fetchAlbums: () => void;
  parentId: string;
}) => {
  const dispatch = useAppDispatch();
  const investmentEntity = useContext(EntityContext);

  const {
    handleSubmit,
    register,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: folder?.title,
      description: folder?.description,
    },
  });

  const onSubmit = async (formData: FormData) => {
    const data = {
      folder: {
        ...formData,
        id: folder?.id,
        folderable_id: investmentEntity.entity.id,
        folderable_type: investmentEntity.type,
        folder_type: 'media',
        parent_id: parentId,
      },
    };
    if (folder) {
      await dispatch(updateFolder(data));
    } else {
      await dispatch(createFolder(data));
    }
    fetchAlbums();
    setOpened(false);
  };

  const fields = watch();

  return (
    <Modal
      toggle={() => setOpened(false)}
      header={`${folder ? 'Edit' : 'Create'} Album`}
      actions={
        <Button
          type="submit"
          variant="success"
          fluid
          onClick={handleSubmit(onSubmit)}
        >
          {folder ? 'Update' : 'Create'} Album
        </Button>
      }
      classes={{
        header: 'bg-light',
        body: 'bg-light',
        footer: 'bg-light',
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <label>
          Album Title
          <span className="red">*</span>
        </label>
        <Input
          size="l"
          {...register('title', { required: true })}
          value={fields.title}
          onChange={(e) => setValue('title', e.target.value)}
          placeholder="Enter Album Title"
          className={`${errors.title ? 'is-invalid' : ''} bg-white`}
        />
        <div className="invalid-feedback">Please enter the Album Title</div>

        <label className="mt-4">Description </label>
        <Input
          size="l"
          value={fields.description}
          onChange={(e) => setValue('description', e.target.value)}
          placeholder="Enter description"
          className={`${errors.description ? 'is-invalid' : ''} bg-white`}
        />
      </form>
    </Modal>
  );
};

export default NewAlbumModal;
