import { ColorField } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/fields/ColorField';
import { GroupingTypeField } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/fields/GroupingTypeFIeld';
import { RecapPageField } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/fields/RecapPageField';
import { commonTableWidgetUpdaters } from '@/bundles/Shared/widgets/dashboard/widgets/common/updaters';
import { Field } from '@/stories/Field/Field';
import { EAGLE_EYE_DASHBOARD_SECTION_TYPE } from 'bundles/Shared/entities/dashboard/model/types/types';
import { DefaultColumnsTab } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/DefaultColumnsTab';
import { PeriodShiftField } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/fields/PeriodShiftField';
import { UpdateWidgetTitle } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/UpdateWidgetTitle';
import {
  useUpdateWidgetConfigEffect,
  useWidgetConfig,
} from 'bundles/Shared/widgets/dashboard/widgets/common/lib/config';
import { HideTotalField } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/fields/HideTotalField';
import { BulkGroupConfigModal } from 'bundles/Shared/widgets/dashboard/widgets/kpiTableSingleDate/config/bulk-group';
import { KpiTableSingleDateWidgetConfigColumnPanel } from 'bundles/Shared/widgets/dashboard/widgets/kpiTableSingleDate/config/column';
import {
  KpiTableWidgetSingleDateConfigForm,
  transformKpiTableSingleDateWidgetConfigToForm,
} from 'bundles/Shared/widgets/dashboard/widgets/kpiTableSingleDate/config/component.form';
import {
  moveColumnAndSyncGroup,
  updateDefaultOptions,
} from 'bundles/Shared/widgets/dashboard/widgets/kpiTableSingleDate/config/updaters';
import { FormProvider, useForm } from 'react-hook-form';
import { FieldsContainer } from 'stories/Field/Field';
import ThinTabGroup, {
  IThinTabItem,
} from 'stories/Tabs/ThinTabGroup/ThinTabGroup';
import { useTabs } from 'stories/Tabs/useTabs';

const TABS = [
  {
    id: 'basic',
    label: 'Basic',
  },
  {
    id: 'columns',
    label: 'Columns',
  },
] as const satisfies readonly IThinTabItem[];

const BasicTab = () => {
  const { widget } =
    useWidgetConfig<
      typeof EAGLE_EYE_DASHBOARD_SECTION_TYPE.KPI_TABLE_SINGLE_DATE
    >();
  const methods = useForm<KpiTableWidgetSingleDateConfigForm>({
    defaultValues: {
      ...transformKpiTableSingleDateWidgetConfigToForm(widget.widgetConfig),
    },
  });

  const { control } = methods;

  useUpdateWidgetConfigEffect<
    KpiTableWidgetSingleDateConfigForm,
    typeof EAGLE_EYE_DASHBOARD_SECTION_TYPE.KPI_TABLE_SINGLE_DATE
  >(methods, updateDefaultOptions);

  return (
    <FormProvider {...methods}>
      <FieldsContainer>
        <UpdateWidgetTitle />
        <Field labelText="Period Shift">
          <PeriodShiftField control={control} name="period_shift" />
        </Field>
        <HideTotalField
          fieldType="row"
          control={control}
          name="hide_total_row"
        />
        <GroupingTypeField
          availableGroupingTypes={['assets', 'segments']}
          control={control}
          name="groupingType"
        />
        <ColorField
          component="Field"
          labelText="Header Background"
          name="header_background"
          control={control}
        />
        <RecapPageField />
      </FieldsContainer>
    </FormProvider>
  );
};

export function KpiTableSingleDateWidgetConfigComponent() {
  const { tab, thinTabGroupProps } = useTabs(TABS, TABS[0]);
  return (
    <div className="flex flex-col gap-4">
      <ThinTabGroup fullWidth {...thinTabGroupProps} />
      <div className="p-1">
        {tab?.id === 'basic' && <BasicTab />}
        {tab?.id === 'columns' && (
          <DefaultColumnsTab
            BulkGroupModal={BulkGroupConfigModal}
            ColumnModal={KpiTableSingleDateWidgetConfigColumnPanel}
            widgetType={EAGLE_EYE_DASHBOARD_SECTION_TYPE.KPI_TABLE_SINGLE_DATE}
            actions={{
              ...commonTableWidgetUpdaters,
              moveColumn: moveColumnAndSyncGroup,
            }}
          />
        )}
      </div>
    </div>
  );
}
