import { formatDate } from '@/shared/lib/formatting/dates';
import useEvent from '@/shared/lib/hooks/useEvent';
import { IconButton } from '@/stories/IconButton/IconButton';
import { Select } from '@/stories/FormControls/Select/Select';
import { Tumbler } from '@/stories/Tumbler/Tumbler';
import Table from 'bundles/Shared/components/Table/Table';
import { useMemo, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import InvestmentEntityColumn, {
  InvestmentEntityClassesBadges,
} from '@/bundles/REturn/components/Ownership/ownershipTable/formatters/InvestmentEntityColumn';
import styles from '@/bundles/REturn/components/Ownership/modals/capitalInvestmentsEditor/CapitaInvestmentEditor.module.scss';
import { STATIC_DATE_PICKER_PROPS } from '@/bundles/REturn/components/Ownership/modals/consts';
import { CapitalInvestment } from '@/entities/return';
import { ISelectOption } from '@/stories/FormControls/Select/Select';
import { DEFAULT_DATE_FALLBACK } from '@/shared/lib/formatting/fallbacks';

const options = [
  {
    id: 'monthly',
    label: 'Monthly',
  },
  {
    id: 'quarterly',
    label: 'Quarterly',
  },
] as const;

const SameValueForAllToggle = ({
  value,
  onChange,
  text,
}: {
  value: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  text: string;
}) => (
  <Tumbler onChange={onChange} value={value}>
    {text}
  </Tumbler>
);

export const firstContributionDateColumn = () => {
  return {
    text: 'First contrib. date',
    dataField: 'firstContributionDate',
    headerStyle: {
      width: 160,
    },
    formatter: ({ row }) => {
      if (
        !row.firstClassAContributionDate &&
        !row.firstClassBContributionDate
      ) {
        return DEFAULT_DATE_FALLBACK;
      }

      return (
        <div className="flex flex-col gap-s">
          {row.firstClassAContributionDate && (
            <div className="grid grid-cols-[1fr_auto]">
              <div>
                {formatDate(row.firstClassAContributionDate, 'MMM DD, YYYY')}
              </div>
              <div>
                <InvestmentEntityClassesBadges
                  capitalInvestmentClasses={['a']}
                />
              </div>
            </div>
          )}

          {row.firstClassBContributionDate && (
            <div className="grid grid-cols-[1fr_auto]">
              <div>
                {formatDate(row.firstClassBContributionDate, 'MMM DD, YYYY')}
              </div>
              <div>
                <InvestmentEntityClassesBadges
                  capitalInvestmentClasses={['b']}
                />
              </div>
            </div>
          )}
        </div>
      );
    },
  };
};

export const AccruedValuesEditor = ({
  selectedCapitalInvestments,
  setSelectedCapitalInvestments,
}: {
  selectedCapitalInvestments: CapitalInvestment[];
  setSelectedCapitalInvestments: (value: CapitalInvestment[]) => void;
}) => {
  const [sameStartDate, setSameStartDate] = useState(false);
  const [sameEndDate, setSameEndDate] = useState(false);
  const [samePeriod, setSamePeriod] = useState(false);

  const handleDateChange = useEvent(
    ({ date, rowId, fieldForUpdate, updateAll }) => {
      if (updateAll) {
        setSelectedCapitalInvestments(
          selectedCapitalInvestments.map((item) => ({
            ...item,
            [fieldForUpdate]: date,
          })),
        );
      } else {
        setSelectedCapitalInvestments(
          selectedCapitalInvestments.map((item) => {
            if (item.id === rowId) {
              return {
                ...item,
                [fieldForUpdate]: date,
              };
            }
            return item;
          }),
        );
      }
    },
  );

  const updateAllFields = (field: string, value: string | null) => {
    setSelectedCapitalInvestments((prev) =>
      prev.map((entry) => ({
        ...entry,
        [field]: value,
      })),
    );
  };

  const updatePeriod = (
    row: CapitalInvestment,
    option: ISelectOption | undefined,
  ) => {
    if (samePeriod) {
      updateAllFields('periodType', option?.id);
    } else {
      setSelectedCapitalInvestments((prev) => {
        return prev.map((entry) => {
          if (entry.id === row.id) {
            return {
              ...entry,
              periodType: option?.id,
            };
          }
          return entry;
        });
      });
    }
  };

  const columns = useMemo(() => {
    const dateForm = ({
      row,
      fieldForUpdate,
      updateAll,
      maxDate = undefined,
    }: {
      row: CapitalInvestment;
      fieldForUpdate: string;
      updateAll: boolean;
      maxDate?: Date;
    }) => (
      <ReactDatePicker
        maxDate={maxDate}
        {...STATIC_DATE_PICKER_PROPS}
        selected={row[fieldForUpdate]}
        onChange={(date) =>
          handleDateChange({ date, rowId: row.id, fieldForUpdate, updateAll })
        }
      />
    );

    return [
      {
        text: 'Investment Entity',
        dataField: 'investmentEntity',
        headerStyle: {
          width: 190,
        },
        formatter: ({ row: item }) => (
          <div>
            <InvestmentEntityColumn
              capitalInvestment={item}
              withIcon={false}
              withUsers={false}
              withLegalEntity
            />
          </div>
        ),
      },
      firstContributionDateColumn(),
      {
        text: (
          <div>
            <SameValueForAllToggle
              value={sameStartDate}
              onChange={(e) => {
                if (e.target.checked) {
                  updateAllFields('startDate', null);
                }
                setSameStartDate(e.target.checked);
              }}
              text="SAME DATE FOR ALL"
            />
            <div className="mt-s flex items-center gap-s">
              Start Date
              <IconButton
                iconName="reset"
                onClick={() => updateAllFields('startDate', null)}
              />
            </div>
          </div>
        ),
        dataField: 'startDate',
        headerStyle: {
          width: 220,
        },
        formatter: ({ row }) =>
          dateForm({
            row,
            fieldForUpdate: 'startDate',
            updateAll: sameStartDate,
            maxDate: new Date(),
          }),
      },
      {
        text: (
          <div>
            <SameValueForAllToggle
              value={sameEndDate}
              onChange={(e) => {
                if (e.target.checked) {
                  updateAllFields('endDate', null);
                }
                setSameEndDate(e.target.checked);
              }}
              text="SAME DATE FOR ALL"
            />
            <div className="mt-s flex items-center gap-s">
              End Date (Optional)
              <IconButton
                iconName="reset"
                onClick={() => updateAllFields('endDate', null)}
              />
            </div>
          </div>
        ),
        dataField: 'endDate',
        headerStyle: {
          width: 220,
        },
        formatter: ({ row }) =>
          dateForm({
            row,
            fieldForUpdate: 'endDate',
            updateAll: sameEndDate,
          }),
      },
      {
        text: (
          <div>
            <SameValueForAllToggle
              value={samePeriod}
              onChange={(e) => {
                if (e.target.checked) {
                  updateAllFields('periodType', null);
                }
                setSamePeriod(e.target.checked);
              }}
              text="SAME PERIOD FOR ALL"
            />
            <div className="mt-s flex items-center gap-s">
              Period
              <IconButton
                iconName="reset"
                onClick={() => updateAllFields('periodType', null)}
              />
            </div>
          </div>
        ),
        dataField: 'Period',
        headerStyle: {
          width: 220,
        },
        formatter: ({ row }) => {
          return (
            <Select
              placeholder="Select Period"
              size="m"
              options={options}
              selected={{
                id: row.periodType,
                label: options.find((option) => option.id === row?.periodType)
                  ?.label,
              }}
              onSelectedChange={(option) => {
                updatePeriod(row, option);
              }}
            />
          );
        },
      },
    ];
  }, [sameStartDate, sameEndDate, samePeriod]);

  return (
    <>
      <Table
        borderLessOutside
        classes={{
          table: styles.table,
          container: styles.container,
          th: 'align-bottom',
        }}
        defaultColumn={{
          headerClasses: 'bg-white pt-2 light-60',
        }}
        items={selectedCapitalInvestments}
        columns={columns}
      />
    </>
  );
};
