import { PermissionListModal } from '@/bundles/Shared/components/Permissions/PermissionListModal';
import { transformPermissionsToRequest } from '@/bundles/Shared/entities/permissions';
import { usePostApiCoreAssetsByAssetIdReportBuilderReportsAndIdPublishMutation } from '@/entities/report/reportBuilder';
import { reportingEnhancedApi } from '@/entities/reporting/api/reportingEnhancedApi';
import { useReportingEntityKindContext } from '@/entities/reporting/context/entityKind';
import { REPORT_PUBLISH_CONFIRMATION_TEXT } from '@/features/report/report/publishReport/config';
import { IS_STAGING } from '@/lib/http';
import { useHostedUrl } from '@/shared/lib/hooks/useHostedUrl';
import { useModal } from '@/shared/lib/hooks/useModal';

export const usePublishReport = ({
  reportId,
  assetId,
  onSuccess,
}: {
  reportId: string;
  assetId: number;
  onSuccess?: () => void;
}) => {
  const hostedUrl = useHostedUrl();
  const reportKind = useReportingEntityKindContext();
  const [publishReport, objectLevelOptions] =
    usePostApiCoreAssetsByAssetIdReportBuilderReportsAndIdPublishMutation();
  const [saveEagleEyeReportPdf, saveOptions] =
    reportingEnhancedApi.usePostApiReportBuilderEagleEyeReportsByEagleEyeReportIdPdfMutation();

  const options =
    reportKind === 'object_level' ? objectLevelOptions : saveOptions;

  const { openModal, confirm } = useModal();

  return [
    async () => {
      if (reportKind === 'eagle_eye') {
        const bool = await confirm({
          title: 'Are you sure you want to proceed?',
          subtitle:
            'This action cannot be reversed. Generated Report cannot be edited.',
          actions: {
            primaryButton: {
              variant: 'success',
              text: 'Generate',
            },
            secondaryButton: {
              variant: 'secondary',
              text: 'Cancel',
            },
          },
        });

        if (!bool) return;

        const res = await saveEagleEyeReportPdf({
          eagleEyeReportId: reportId,
          body: { render_pdf_host: hostedUrl },
        });

        if ((res != null && 'error' in res) || res == null) {
          console.error(res.error);
          return;
        }

        return onSuccess?.();
      }
      const res = await openModal(PermissionListModal, {
        investmentObject: {
          type: 'Asset',
          entity: {
            id: assetId,
          },
        },
        title: 'Who can see your Report?',
        initialState: {
          directInvestmentEntities: [],
          directRoles: [],
          directTags: [],
          directUsers: [],
          indirectUsers: [],
          isPublic: false,
        },
      });

      if (res == null) return;

      const answer = await confirm({
        title: REPORT_PUBLISH_CONFIRMATION_TEXT.title,
        subtitle: REPORT_PUBLISH_CONFIRMATION_TEXT.subtitle,
        actions: {
          primaryButton: {
            variant: 'success',
            text: 'Publish',
          },
          secondaryButton: {
            text: 'Cancel',
            variant: 'secondary',
          },
        },
      });

      if (!answer) return;

      const response = await publishReport({
        id: reportId,
        assetId,
        body: {
          shared_file: transformPermissionsToRequest(res),
          ...(IS_STAGING && {
            render_pdf_host: hostedUrl,
          }),
        },
      });

      if ((response != null && 'error' in response) || response == null) {
        console.error(response.error);
        return;
      }

      return onSuccess?.();
    },
    options,
  ] as const;
};
