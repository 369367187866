import React, { useMemo } from 'react';
import { ReportDashboardType } from 'bundles/Shared/entities/dashboard/model/types/types';
import {
  IThinTabItem,
  ThinTabGroup,
} from 'stories/Tabs/ThinTabGroup/ThinTabGroup';
import { currentUserCanSeeComparisonPage } from '@/pages/comparisonDashboards/permissions';

const ALL_TAB_ID = 'all';
const TABS = [
  {
    id: ALL_TAB_ID,
    label: 'All',
  },
  {
    id: ReportDashboardType.EAGLE_EYE,
    label: 'Eagle Eye',
  },
  {
    id: ReportDashboardType.OBJECT,
    label: 'Object',
  },
  {
    id: ReportDashboardType.COMPARISON_MODE,
    label: 'Compare',
    resolveHidden: () => !currentUserCanSeeComparisonPage(),
  },
] as const satisfies readonly IThinTabItem[];

export type PageParamsDashboardFilterByType = {
  type: FlattenEnum<ReportDashboardType> | null;
};

type TabId = FlattenEnum<(typeof TABS)[number]['id']>;

type Props = {
  type: PageParamsDashboardFilterByType['type'];
  onChange: (status: PageParamsDashboardFilterByType['type']) => void;
} & (
  | {
      tabWhitelist?: TabId[];
      excludeTabList?: never;
    }
  | {
      excludeTabList?: TabId[];
      tabWhitelist?: never;
    }
);

export function DashboardFilterByTypeTabs({
  type,
  onChange,
  tabWhitelist,
  excludeTabList,
}: Props) {
  const filteredTabs = useMemo(() => {
    return (tabWhitelist ?? excludeTabList)
      ? TABS.filter((tab) => {
          if (tabWhitelist) {
            return tabWhitelist.includes(tab.id);
          }
          if (excludeTabList) {
            return !excludeTabList.includes(tab.id);
          }
          return true;
        })
      : TABS;
  }, []);

  return (
    <div className="flex items-center gap-2">
      <span className="inline-regular text-neutral-500">Type</span>
      <ThinTabGroup<TabId>
        items={filteredTabs}
        selectedItem={TABS.find((tab) => tab.id === type) ?? TABS[0]}
        onSelectedItemChange={(tab) =>
          onChange(tab.id === ALL_TAB_ID ? null : tab.id)
        }
      />
    </div>
  );
}
