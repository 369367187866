import {
  SETTINGS_REPORT_THRESHOLDS_LEGAL_ENTITY_OVERRIDES_TAG,
  SETTINGS_REPORT_THRESHOLDS_TAG,
  settingsReportThresholdsEnhancedApi,
} from '@/shared/api/settingsReportThresholdsEnhancedApi';
import { exportFile } from '@/shared/lib/exportFile';
import useBoolean from '@/shared/lib/hooks/useBoolean';
import { ReportThreshold } from '@/entities/report/threshold/model';
import { importFile } from '@/shared/lib/importFile';
import { ACCEPT_XLSX_FILE_EXTENSION } from '@/shared/lib/browser/config';
import useFileDialog from '@/shared/lib/hooks/useFileDialog';
import { useAppDispatch } from '@/shared/lib/hooks/redux';

export const useExportThresholdWithOverrides = (threshold: ReportThreshold) => {
  const {
    value: isLoading,
    setTrue: enableLoading,
    setFalse: disableLoading,
  } = useBoolean();
  const handleExport = () => {
    exportFile({
      url: `/settings/report/thresholds/${threshold.kind}.xlsx`,
      filePostfix: 'thresholds',
      enableLoading,
      disableLoading,
    });
  };

  return [handleExport, { isLoading }] as const;
};

export const useImportThresholdWithOverrides = (threshold: ReportThreshold) => {
  const { openFileDialog } = useFileDialog({
    multiple: false,
    accept: ACCEPT_XLSX_FILE_EXTENSION,
  });
  const dispatch = useAppDispatch();
  const {
    value: isLoading,
    setTrue: enableLoading,
    setFalse: disableLoading,
  } = useBoolean();

  const handleImport = async () => {
    const fileList = await openFileDialog();

    if (fileList === null || fileList.length < 1) return;

    const [file] = fileList;

    await importFile({
      url: `/api/settings/report/thresholds/${threshold.kind}/imports`,
      file,
      disableLoading,
      enableLoading,
    });

    dispatch(
      settingsReportThresholdsEnhancedApi.util.invalidateTags([
        SETTINGS_REPORT_THRESHOLDS_TAG,
        SETTINGS_REPORT_THRESHOLDS_LEGAL_ENTITY_OVERRIDES_TAG,
      ]),
    );
  };

  return [handleImport, { isLoading }] as const;
};
