import { DasbhoardCardTypeLabel } from '@/bundles/Shared/entities/dashboard/ui/card/DasbhoardCardTypeLabel';
import { EntityTagLabel } from '@/bundles/Shared/entities/tag';
import type { ReportBuilderTemplatePreviewDto } from '@/entities/report/reportBuilder/api/settingsReportBuilderTemplatesGeneratedApi';
import { useReportBuilderTemplateDelete } from '@/features/report/report/deleteReportBuilderTemplate/lib';
import { useReportBuilderTemplateDuplicate } from '@/features/report/report/duplicateReportBuilderTemplate/lib';
import { cn } from '@/shared/lib/css/cn';
import { useFavoriteItemIds } from '@/shared/lib/hooks/useFavoriteItemIds';
import { generateUrl, ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import { AssetsInUsePopover } from '@/shared/ui/AssetsInUsePopover';
import { BaseCard } from '@/shared/ui/BaseCard';
import FavoriteIconButton from '@/stories/ProjectCard/FavoriteIconButton';

import { useNavigate } from '@reach/router';
import { ReactComponent as FileIcon } from 'fonts/sre-icons/Icons/file.svg';

export const ReportBuilderTemplateCard = ({
  children,
  template,
  ...props
}: LayoutProps & {
  template: Pick<
    ReportBuilderTemplatePreviewDto,
    'id' | 'name' | 'templateTags' | 'assets' | 'kind'
  >;
}) => {
  const { isItemFavorite, toggleItemFavorite } = useFavoriteItemIds(
    'reportBuilderTemplateIds',
  );
  const [duplicateTemplate, duplicateOptions] =
    useReportBuilderTemplateDuplicate();
  const [deleteTemplate, deleteOptions] = useReportBuilderTemplateDelete();
  const isLoading = duplicateOptions.isLoading || deleteOptions.isLoading;
  const navigate = useNavigate();
  const isFavorite = isItemFavorite(template.id);

  const handleClick = () => {
    switch (template.kind) {
      case 'eagle_eye': {
        return navigate(
          generateUrl(
            ROUTES_ROOT.reportBuilderTemplates.eagleEye.reportBuilderTemplate
              .fullPath,
            {
              pathParams: {
                templateId: template.id,
              },
            },
          ),
        );
      }
      case 'object_level':
        return navigate(
          generateUrl(
            ROUTES_ROOT.reportBuilderTemplates.reportBuilderTemplate.fullPath,
            {
              pathParams: {
                templateId: template.id,
              },
            },
          ),
        );
      default:
        return toastr.warning('Template kind not supported');
    }
  };

  return (
    <BaseCard
      iconSlot={<FileIcon className="h-[48px] w-[48px]" />}
      imgWrapperProps={{
        onClick: handleClick,
      }}
      {...props}
    >
      <div className="flex flex-col justify-between gap-2">
        <div className="flex">
          <DasbhoardCardTypeLabel type={template.kind} />
        </div>
        <BaseCard.Title className="inline-flex items-start justify-between gap-2">
          <p>{template.name}</p>
        </BaseCard.Title>
        {template.assets.length > 0 && (
          <AssetsInUsePopover assets={template.assets} />
        )}
        <BaseCard.OverlayActions
          className={cn(isFavorite && 'visible')}
          isLoading={isLoading}
        >
          <BaseCard.DropdownActions
            className="hidden group-hover:inline-flex"
            isLoading={isLoading}
            items={[
              {
                text: 'Duplicate',
                onAction: () => {
                  duplicateTemplate(template);
                },
              },
              {
                text: 'Delete',
                onAction: () => {
                  deleteTemplate(template);
                },
              },
            ]}
          />
          <FavoriteIconButton
            size="m"
            selected={isFavorite}
            onClick={() => toggleItemFavorite(template.id)}
          />
        </BaseCard.OverlayActions>
        {template.templateTags.length > 0 && (
          <EntityTagLabel.List>
            {template.templateTags.map((tag) => (
              <EntityTagLabel label={tag.name} key={tag.id} />
            ))}
          </EntityTagLabel.List>
        )}
      </div>
      {children}
    </BaseCard>
  );
};
