import { useLoadedCapitalUnitPrices } from '@/bundles/REturn/hooks/useLoadedUnitPrices';
import { Contribution, Distribution } from '@/entities/return';
import {
  usePutApiCapitalInvestmentContributionsByIdMutation,
  usePutApiCapitalInvestmentDistributionsByIdMutation,
} from '@/entities/return/api/capitalInvestmentObjectsEnhancedApi';
import {
  InvestmentIndex,
  SplitItem,
} from '@/entities/return/api/capitalInvestmentObjectsGeneratedApi';
import type * as Type from 'bundles/REturn/actions/types';
import {
  useAddManualContributionSettingsModal,
  useAddManualContributionWithUnitPricesSettingsModal,
} from 'bundles/REturn/components/Ownership/modals/manageCapitalInvestmentModal/hooks/useAddManualContributionSettingsModal';
import { useAddManualDistributionSettingsModal } from 'bundles/REturn/components/Ownership/modals/manageCapitalInvestmentModal/hooks/useAddManualDistributionSettingsModal';
import { SplitDistributionEditModal } from 'bundles/REturn/components/Ownership/modals/manageCapitalInvestmentModal/modals/SplitDistributionEditModal';
import { useLoadedInvestmentObject } from 'bundles/REturn/hooks/useLoadedInvestmentObject';
import { useModal } from '@/shared/lib/hooks/useModal';
import { assertsType } from 'lib/typeHelpers/assertsType';
import { useCallback } from 'react';
import { convertDollarsToCents } from '@/shared/lib/converters';

interface Params {
  type: Type.CapitalInvestmentEntryType;
  capitalInvestment: InvestmentIndex;
}

export const isDistribution = (row: Contribution): row is Distribution =>
  row._type === 'distribution';

export function useEditManualEntry({ type, capitalInvestment }: Params) {
  const { data: capitalInvestmentObject } = useLoadedInvestmentObject();
  const { data: unitPrices } = useLoadedCapitalUnitPrices();

  const { openModal } = useModal();

  const [updateDistribution] =
    usePutApiCapitalInvestmentDistributionsByIdMutation();
  const [updateContribution] =
    usePutApiCapitalInvestmentContributionsByIdMutation();
  const addManualDistributionModal = useAddManualDistributionSettingsModal({
    capitalInvestment,
  });

  const addManualContributionWithUnitPricesModal =
    useAddManualContributionWithUnitPricesSettingsModal({
      capitalInvestment,
    });
  const addManualContributionModal = useAddManualContributionSettingsModal({
    capitalInvestment,
  });

  const hasUnitPrices = unitPrices.length > 0;

  const onEditManualEntry = useCallback(
    async (row: Contribution) => {
      if (isDistribution(row)) {
        if (row.splitItems.length > 0) {
          const res = await openModal(SplitDistributionEditModal, {
            ...row,
          });
          if (res == null) return;

          await updateDistribution({
            id: row.id,
            body: {
              ...res,
            },
          });

          return;
        }

        assertsType<SplitItem[number]['kind']>(
          row.kind,
          (i) => i !== 'split_items',
        );

        const res = await addManualDistributionModal({
          initialForm: {
            amount: Number(row.amount),
            date: row.date,
            period: row.period,
            period_type: row.periodType,
            kind: row.kind,
            note: row.note,
          },
          modalHeaderProps: {
            title: 'Edit Distribution',
            subtitle: capitalInvestment.investmentEntity.name,
          },
          submitButtonText: 'Edit Distribution',
        });
        if (res == null) return;

        await updateDistribution({
          id: row.id,
          body: res,
        });
      } else {
        const contributionModal = hasUnitPrices
          ? addManualContributionWithUnitPricesModal
          : addManualContributionModal;

        const res = await contributionModal({
          initialForm: {
            amount: row.amount,
            date: row.date as DateString,
            investmentClass: row.investmentClass,
            signedDate: row.commitment?.signedDate as DateString | undefined,
            unitsQuantity: row.unitsQuantity,
          },
          modalHeaderProps: {
            title: 'Edit Contribution',
            subtitle: capitalInvestment.investmentEntity.name,
          },
          submitButtonText: 'Edit Contribution',
        });
        if (res == null) return;

        await updateContribution({
          id: row.id,
          body: {
            amount_cents: convertDollarsToCents(res.amount),
            commitment_id: res.commitmentId,
            date: res.date,
            investment_class: res.investmentClass,
            signed_date: res.signedDate,
            units_quantity: res.unitsQuantity || null,
          },
        });
      }
    },
    [capitalInvestment, capitalInvestmentObject, type],
  );

  return onEditManualEntry;
}
