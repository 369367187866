export function safelyParseJSON<T>(json: string | null | undefined): T | null {
  if (json == null) {
    return null;
  }

  try {
    const parsed = JSON.parse(json);

    return parsed;
  } catch (e) {
    console.error(e);
    return null;
  }
}
