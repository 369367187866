import React from 'react';
import pluralize from 'pluralize';
import { Icon } from 'stories/Icon/Icon';
import { SreAvatar } from 'stories/SreAvatar/SreAvatar';
import { Button } from 'stories/Button/Button';
import { Popover } from 'stories/Popover/Popover';
import { EmailLink } from 'bundles/Shared/shared/EmailLink';
import { PhoneLink } from 'bundles/Shared/shared/PhoneLink';

const AdviseeCardHeader = ({
  userId,
  userFullName,
  phone,
  email,
  investmentEntities,
  children,
  impersonationModeEntryHandler,
}) => {
  const separator = <span className="d-none d-md-inline">{' • '}</span>;

  return (
    <div>
      <div className="flex justify-between items-center mb-4">
        <div className="flex flex-wrap items-center">
          <SreAvatar
            text={userFullName}
            width="3rem"
            height="3rem"
            className="mr-m"
          />
          <div>
            <button
              className="header-5 text-blue cursor-pointer mb-0.5 border-0 bg-transparent p-0 text-left"
              onClick={impersonationModeEntryHandler}
            >
              {userFullName}
            </button>
            <div className="flex-break md:hidden mt-2" />
            <div>
              <div className="hidden md:flex">
                <span className="text-muted advisee-card__subtitle-text">
                  {email}
                  {phone && (
                    <>
                      {separator}
                      {phone}
                    </>
                  )}
                  {investmentEntities.length > 0 && (
                    <>
                      {separator}
                      <Popover
                        template={
                          <>
                            <div className="light-60 secondary-regular">
                              <span className="sre-icon-businessman" />
                              <span> Entities: </span>
                            </div>
                            <div className="dark-60 secondary-regular mt-2">
                              {investmentEntities
                                .map((ie) => ie.name)
                                .join(', ')}
                            </div>
                          </>
                        }
                      >
                        <span
                          className="dashed-border-bottom cursor-pointer"
                          id={`user-investment-entities-${userId}`}
                        >
                          {`${investmentEntities.length} ${pluralize(
                            'Entity',
                            investmentEntities.length,
                          )}`}
                        </span>
                      </Popover>
                    </>
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className="flex-break mt-2" />
          <div className="d-md-none d-block items-center mt-2">
            {phone && (
              <div className="flex items-center mb-2">
                <div className="advisee-card__details-badge mr-2">
                  <Icon iconName="phone" />
                </div>
                <div className="flex flex-col">
                  <PhoneLink phone={phone} className="inline-regular">
                    Call
                  </PhoneLink>
                  <div className="light-60 font-weight-500 secondary-regular">
                    {phone}
                  </div>
                </div>
              </div>
            )}
            <div className="flex items-center mb-2">
              <div className="advisee-card__details-badge mr-2">
                <Icon iconName="email" />
              </div>
              <div className="flex flex-col">
                <EmailLink email={email} className="inline-regular">
                  Write Email
                </EmailLink>
                <div className="light-60 font-weight-500 secondary-regular">
                  {email}
                </div>
              </div>
            </div>
            <div className="flex items-center mb-2">
              <div className="advisee-card__details-badge mr-2">
                <Icon iconName="eye" />
              </div>
              <div className="flex flex-col">
                <div
                  className="sre-text-blue inline-regular cursor-pointer"
                  onClick={impersonationModeEntryHandler}
                >
                  View Portal
                </div>
              </div>
            </div>
          </div>
        </div>
        <Button
          size="s"
          variant="primary"
          className="d-none d-md-flex self-start"
          onClick={impersonationModeEntryHandler}
          iconName="eye"
          iconPosition="right"
        >
          View as
        </Button>
      </div>
      {children}
    </div>
  );
};

export default AdviseeCardHeader;
