import React from 'react';
import { Button } from '@/stories/Button/Button';
import { usePutApiUserProfileUserMutation } from '@/widgets/userProfile/api/userProfileEnhancedApi';
import { ResetPasswordLink } from '@/widgets/userProfile/ui/ResetPasswordLink';
import { SectionField } from '@/stories/Field/FieldsWrappers';
import PasswordInput from '@/stories/FormControls/Inputs/PasswordInput/PasswordInput';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useBoolean } from 'usehooks-ts';
import { PASSWORD_VALIDATION_SCHEMA } from '@/bundles/Registration/ResetPassword';
import {
  getAllRequirements,
  PASSWORD_VALIDATION_MESSAGE,
} from '@/bundles/Registration/PasswordFields';
import { PasswordValidationBadges } from '@/bundles/Registration/PasswordValidationBadges';
import { cn } from '@/shared/lib/css/cn';
import BadgeWithStatus from '@/bundles/Shared/components/BadgeWithStatus';
import { HorizontalEditableField } from '@/stories/Field/HorizontalEditableField';

const CHANGE_PASSWORD_VALIDATION_SCHEMA = PASSWORD_VALIDATION_SCHEMA.shape({
  currentPassword: yup.string().required('Current password is required'),
});

type FormValues = yup.InferType<typeof CHANGE_PASSWORD_VALIDATION_SCHEMA>;

export const PasswordSection = () => {
  const {
    value: isEditing,
    setTrue: startEditing,
    setFalse: stopEditing,
  } = useBoolean(false);
  const [updateUser, { isLoading }] = usePutApiUserProfileUserMutation();

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { isValid, errors },
  } = useForm<FormValues>({
    resolver: yupResolver(CHANGE_PASSWORD_VALIDATION_SCHEMA),
    defaultValues: {
      currentPassword: '',
      password: '',
      passwordConfirmation: '',
    },
    mode: 'onChange',
    criteriaMode: 'all',
  });

  const formValues = watch();

  const handleCancel = () => {
    reset();
    stopEditing();
  };

  const onSubmit = async (formData: FormValues) => {
    const result = await updateUser({
      body: {
        user: {
          password: formData.password,
          current_password: formData.currentPassword,
        },
      },
    });

    if ('data' in result) {
      handleCancel();
    }
  };

  return (
    <SectionField
      labelText="Password"
      button={
        <>
          {isEditing ? (
            <div className="flex gap-2">
              <Button size="xs" variant="secondary" onClick={handleCancel}>
                Cancel
              </Button>
              <Button
                size="xs"
                variant="success"
                onClick={handleSubmit(onSubmit)}
                loading={isLoading}
                disabled={!isValid}
              >
                Save Changes
              </Button>
            </div>
          ) : (
            <Button size="xs" variant="secondary" onClick={startEditing}>
              Change
            </Button>
          )}
        </>
      }
    >
      {isEditing && (
        <div>
          <div className="-mx-4 flex flex-col gap-2">
            <HorizontalEditableField
              label="Current Password"
              value={formValues.currentPassword}
              editable={isEditing}
              required
              className={isEditing ? 'py-2' : 'py-4'}
            >
              <PasswordInput {...register('currentPassword')} required />
            </HorizontalEditableField>

            <HorizontalEditableField
              label="New Password"
              value={formValues.password}
              editable={isEditing}
              required
              className={cn('items-baseline', {
                'py-2': isEditing,
                'py-4': !isEditing,
              })}
            >
              <div>
                <div className="mb-2 text-xs text-neutral-500">
                  Please create a secure password including the following
                  criteria below
                </div>
                <PasswordInput
                  {...register('password')}
                  required
                  error={Boolean(errors.password)}
                />
                <div
                  className={cn('invalid-feedback', {
                    block: errors.password,
                  })}
                >
                  {PASSWORD_VALIDATION_MESSAGE}
                </div>
                <PasswordValidationBadges
                  allRequirements={getAllRequirements(errors?.password?.types)}
                  errors={errors}
                  value={watch('password') ?? ''}
                  className="mt-2"
                />
              </div>
            </HorizontalEditableField>

            <HorizontalEditableField
              label="Confirm Password"
              value={formValues.passwordConfirmation}
              editable={isEditing}
              required
              className={cn('items-baseline', {
                'py-2': isEditing,
                'py-4': !isEditing,
              })}
            >
              <PasswordInput
                {...register('passwordConfirmation')}
                required
                error={Boolean(errors.passwordConfirmation)}
              />
              <BadgeWithStatus className="mt-4" isSuccessful={isValid}>
                Passwords must match
              </BadgeWithStatus>
            </HorizontalEditableField>
          </div>
          <div className="grid grid-cols-[150px_1fr] items-center gap-2">
            <ResetPasswordLink className="col-start-2" />
          </div>
        </div>
      )}
    </SectionField>
  );
};
