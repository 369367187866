import React from 'react';
import dayjs from 'dayjs';
import { usePageParamsContext } from 'bundles/Shared/components/pageParams';
import { ConnectionFilterByDatePageParams } from 'bundles/Shared/features/connection/filter/byDate/model';
import { formatToDateStringForRequest } from '@/shared/lib/converters';
import { CalendarRangeSelector } from '@/stories/Calendar/RangeSelector/CalendarRangeSelector';

export function ConnectionFilterByDate() {
  const { pageParams, setPageParams } =
    usePageParamsContext<ConnectionFilterByDatePageParams>();
  return (
    <CalendarRangeSelector
      value={
        pageParams.createdAtRange.from && pageParams.createdAtRange.to
          ? [
              dayjs(pageParams.createdAtRange.from).toDate(),
              dayjs(pageParams.createdAtRange.to).toDate(),
            ]
          : undefined
      }
      onChange={(periods) => {
        setPageParams({
          ...pageParams,
          createdAtRange: {
            from: formatToDateStringForRequest(periods[0]),
            to: formatToDateStringForRequest(periods[1]),
          },
          page: 1,
        });
      }}
    />
  );
}
