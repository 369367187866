import { ReportingEntityKindContext } from '@/entities/reporting/context/entityKind';
import { ReportingEntityStageContext } from '@/entities/reporting/context/entityStage';
import { ReportBuilderTemplateViewPdf } from '@/widgets/reportBuilder/reportBuillderTemplate/viewPdf';
import { type RouteComponentProps } from '@reach/router';
import { type FC } from 'react';

export const ReportBuilderTemplateViewPage: FC<RouteComponentProps> = () => {
  return (
    <ReportingEntityKindContext.Provider value="object_level">
      <ReportingEntityStageContext.Provider value="template">
        <ReportBuilderTemplateViewPdf />
      </ReportingEntityStageContext.Provider>
    </ReportingEntityKindContext.Provider>
  );
};
