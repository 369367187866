import { useLocalStorageValue } from '@/shared/lib/hooks/useLocalStorageValue';
import { isEqual } from 'lodash-es';

export const useFavoriteComparisonDashboardAssets = () => {
  const [favouriteItems, setFavouriteItems] = useLocalStorageValue(
    'reportComparisonDashboard',
    [],
  );

  const toggleItemFavorite = (favItem: {
    dashboardId: string;
    assetId: number;
  }) => {
    setFavouriteItems(
      favouriteItems.some((f) => isEqual(f, favItem))
        ? favouriteItems.filter((itemX) => !isEqual(itemX, favItem))
        : [...favouriteItems, favItem],
    );
  };

  const isItemFavorite = (favItem: { dashboardId: string; assetId: number }) =>
    favouriteItems.some((f) => isEqual(f, favItem));

  return {
    toggleItemFavorite,
    isItemFavorite,
    favouriteItems,
  };
};
