import FileInfo from 'bundles/Assets/components/SharedFiles/FileInfo';
import React from 'react';
import { Popover } from 'stories/Popover/Popover';
import { Tooltip } from 'stories/Tooltip/Tooltip';
import { cn } from '@/shared/lib/css/cn';
import FileExtensionIcon from '@/bundles/Shared/components/fileExtensionIcon/FileExtensionIcon';
import styles from '@/bundles/Shared/components/DocumentsPopover/DocumentsPopover.module.scss';
import { ISharedDocument } from 'types/SharedDocument';

interface Props {
  files: ISharedDocument[];
  children?: React.ReactNode;
}

const DocumentsPopover = ({ files, children }: Props) => (
  <Popover
    style={{
      maxHeight: '300px',
      overflow: 'auto',
    }}
    template={
      <div className="font-weight-normal">
        <div className={cn({ [styles.double_column]: files.length > 3 })}>
          {files.map((file) => (
            <Tooltip
              key={file.id}
              titleText={(file.title || file?.name)?.replace(/\.[^/.]+$/, '')}
              placement="top"
              arrowPosition="center"
            >
              <div className={cn('flex mr-l px-xs pt-xs', styles.document)}>
                <div className="mr-m">
                  <div>
                    <FileExtensionIcon
                      className={styles.extension_icon}
                      extension={file.extension}
                    />
                  </div>
                </div>
                <div className="mnw-0">
                  <div className={cn('text-[1rem] text-ellipsis')}>
                    {file.title || file.name}
                  </div>
                  <div>
                    <FileInfo
                      size={file.size}
                      extension={file.extension.toUpperCase()}
                    />
                  </div>
                </div>
              </div>
            </Tooltip>
          ))}
        </div>
      </div>
    }
    maxWidth="40rem"
    trigger="mouseenter"
    touch={false}
    hideOnExpandedAreaClick
  >
    {children}
  </Popover>
);

export default DocumentsPopover;
