import { clipboardWriteText } from '@/shared/lib/browser/clipboardApi';
import useBoolean from '@/shared/lib/hooks/useBoolean';
import { DialogProps, useModal } from '@/shared/lib/hooks/useModal';
import {
  COLOR_BOX_SIZE_CLASSNAME,
  STANDARD_COLOR_BOXES,
} from '@/shared/widgets/colorPicker/config';
import {
  getColorBoxesCount,
  GRADIENT_SATURATION_TAB_SWITCHER_BG_CLASSNAME,
} from '@/shared/widgets/colorPicker/lib';
import {
  INITIAL_COLOR,
  useColorSlots,
} from '@/shared/widgets/colorPicker/lib/useColorSlots';
import { Color } from '@/shared/widgets/colorPicker/model';
import ColorButton from '@/shared/widgets/colorPicker/ui/ColorButton';
import { Button } from '@/stories/Button/Button';
import { Icon } from '@/stories/Icon/Icon';
import { IconButton } from '@/stories/IconButton/IconButton';
import { Modal } from '@/stories/Modals/Modal/Modal';
import { uniqBy } from 'lodash-es';
import { ComponentProps, useMemo } from 'react';
import { HexColorPicker } from 'react-colorful';
import styles from '@/shared/widgets/colorPicker/index.module.scss';
import SkeletonBlock from '@/stories/ProjectCard/SkeletonBlock';

import { cn } from '@/shared/lib/css/cn';

type SubmitResult = {
  colors: Color[];
  selectedColor: Color | null;
};

const ColorPickerModal = ({
  options,
  onSubmit,
  onClose,
}: {
  initialColor: Color['value'] | undefined;
  options: Color[] | readonly Color[];
} & DialogProps<SubmitResult>) => {
  const {
    value: isPaletteTab,
    setTrue: setPaletteTab,
    setFalse: setSaturationTab,
  } = useBoolean(true);

  const [
    colorSlotsState,
    selectedSlot,
    {
      onSelectedSlotChange,
      onSlotUpdate,
      onSlotRemove,
      getColorSlot,
      resolveIsSelectedSlotResolverByIndex,
    },
  ] = useColorSlots({
    initialColorSlots: options.map((c, idx) => ({
      ...c,
      id: idx,
      colorValue: c.value,
    })),
  });

  const colorSlotsWithColorValues = useMemo(() => {
    return colorSlotsState.slots.filter((slot) => slot.colorValue !== null);
  }, [colorSlotsState.slots]);

  const onManualChangeColor: React.ChangeEventHandler<HTMLInputElement> = (
    e,
  ) => {
    onSlotUpdate({ colorValue: e.target.value });
  };
  const onColor: (c: Color['value']) => void = (c) => {
    onSlotUpdate({ colorValue: c });
  };

  const maxSlots = useMemo(() => {
    return colorSlotsState.slots.length;
  }, [colorSlotsState.slots.length]);

  const handleSubmitColor = () => {
    const uniqueColors = uniqBy(colorSlotsWithColorValues, 'colorValue').map(
      (slot) => ({
        id: String(slot.id),
        entityId: slot.entityId,
        value: slot.colorValue,
        isDefault: Boolean(slot.isDefault),
      }),
    ) as Color[];

    onSubmit?.({
      colors: uniqueColors,
      selectedColor:
        uniqueColors.find((c) => c.value === selectedSlot?.colorValue) ?? null,
    });
  };
  return (
    <Modal
      toggle={onClose}
      size="272"
      classes={{
        body: 'flex flex-col gap-4 overflow-y-auto !p-4 bg-neutral-000',
      }}
    >
      <div className="flex flex-col gap-2">
        <div className="flex items-center justify-between">
          <p className="inline-semibold text-neutral-999">Custom Colors</p>
          <IconButton iconName="closeSmall" onClick={onClose} />
        </div>
        <div className="flex gap-1">
          <ColorButton.Switcher
            onClick={setPaletteTab}
            className="bg-neutral-650"
            selected={isPaletteTab}
          />
          <ColorButton.Switcher
            onClick={setSaturationTab}
            className={GRADIENT_SATURATION_TAB_SWITCHER_BG_CLASSNAME}
            selected={!isPaletteTab}
          />
        </div>
        {isPaletteTab ? (
          <ColorButton.List>
            {STANDARD_COLOR_BOXES.map(({ value }) => (
              <ColorButton.Cell
                selected={selectedSlot?.colorValue === value}
                grouped
                key={value}
                onClick={() => onColor(value)}
                color={value ?? ''}
              />
            ))}
          </ColorButton.List>
        ) : (
          <div className="relative flex">
            <HexColorPicker
              className={styles['sy-color-picker']}
              color={selectedSlot?.colorValue ?? ''}
              onChange={onColor}
            />
            <div
              style={{
                background: selectedSlot?.colorValue ?? '',
              }}
              className="absolute bottom-0 left-0 flex h-[24px] w-[24px] rounded-[8px] border  border-neutral-200"
            />
          </div>
        )}
      </div>
      <div className="flex gap-2">
        <div className="flex w-max items-center gap-2 rounded-[8px] bg-neutral-100 pl-3 pr-2">
          <p className="inline-semibold text-neutral-700">HEX</p>
          <input
            value={selectedSlot?.colorValue ?? ''}
            type="text"
            disabled={selectedSlot?.isDefault}
            onChange={onManualChangeColor}
            className="h-[32px] max-w-[162px] grow rounded-[8px] border-2 border-neutral-200 px-3"
          />
          <Icon
            onClick={(e) => {
              e.stopPropagation();
              if (selectedSlot?.colorValue)
                clipboardWriteText(selectedSlot?.colorValue);
            }}
            className="cursor-pointer"
            iconName="copyAlt"
          />
        </div>
      </div>
      <div className="flex flex-col gap-2">
        <p className="inline-semibold text-neutral-999">Table Colors</p>

        <ColorButton.List>
          {Array.from(
            {
              length: getColorBoxesCount(maxSlots),
            },
            (_, idx) => {
              const isSelectedSlot = resolveIsSelectedSlotResolverByIndex(idx);
              const existingSlot = getColorSlot(idx);

              const getColor = () => {
                if (existingSlot) return existingSlot.colorValue;
                return INITIAL_COLOR;
              };

              return (
                <ColorButton.Cell
                  key={idx}
                  selected={isSelectedSlot}
                  isRemovable={!existingSlot?.isDefault}
                  onRemoveCell={() => {
                    onSlotRemove(existingSlot);
                  }}
                  onClick={() => onSelectedSlotChange(idx)}
                  color={getColor() ?? INITIAL_COLOR}
                />
              );
            },
          )}
        </ColorButton.List>
      </div>
      <Button onClick={handleSubmitColor} variant="success" size="s">
        Save
      </Button>
    </Modal>
  );
};

export const ColorPickerList = (props: {
  value: Color['value'];
  onSubmit: (c: SubmitResult) => void;
  options: readonly Color[] | Color[];
  onChange: (c: Color['value']) => void;
  isFetching?: boolean;
}) => {
  const { openModal } = useModal();

  if (props.isFetching) {
    return <ColorPickerList.Fetching />;
  }
  return (
    <ColorPickerList.Wrapper>
      {props.options.map((color) => (
        <ColorButton.Switcher
          showSelectedIcon
          selected={color.value === props.value}
          color={color.value}
          onClick={() => props.onChange(color.value)}
          key={color.id}
        />
      ))}
      <ColorPickerList.AddButton
        onClick={async () => {
          const res = await openModal(ColorPickerModal, {
            initialColor: props.value,
            ...props,
          });
          if (res == null) return;

          props.onSubmit(res);
        }}
      />
    </ColorPickerList.Wrapper>
  );
};

ColorPickerList.AddButton = (
  props: Omit<ComponentProps<typeof IconButton>, 'iconName'>,
) => <IconButton iconName="addSmall" {...props} />;

ColorPickerList.Wrapper = (props: LayoutProps) => (
  <div className={cn('flex flex-wrap items-center gap-2', props.className)}>
    {props.children}
  </div>
);

ColorPickerList.Fetching = () => (
  <ColorPickerList.Wrapper>
    {Array.from({ length: 4 }, (_, idx) => (
      <SkeletonBlock
        key={idx}
        className={cn(COLOR_BOX_SIZE_CLASSNAME['24'], ' rounded-[8px]')}
      />
    ))}
    <ColorPickerList.AddButton disabled isLoading />
  </ColorPickerList.Wrapper>
);
