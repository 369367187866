import React, { FC } from 'react';
import { ChangeEventInfoSection } from 'stories/ChangeEventInfoSection/ChangeEventInfoSection';
import { accountingNumberFormat } from '@/shared/lib/formatting/number';

export type TReconcileStatus = 'DRAFT' | 'PENDING' | 'APPROVED' | 'COMPLETED';

export interface IInfoBadgeProps {
  title: string;
  status: TReconcileStatus;
  amount: string;
  onIconClick: VoidFunction;
}

const STATUSES = [
  {
    key: 'DRAFT',
    label: 'Draft',
    color: 'light-60',
  },
  {
    key: 'PENDING',
    label: 'Pending',
    color: 'yellow-dark-3',
  },
  {
    key: 'APPROVED',
    label: 'Approved',
    color: 'green',
  },
  {
    key: 'COMPLETED',
    label: 'Completed',
    color: 'green-dark-4',
  },
];

const InfoBadge: FC<IInfoBadgeProps> = ({
  title,
  status,
  amount,
  onIconClick,
}) => {
  const infoData = STATUSES.find((statusItem) => statusItem.key === status);

  return (
    <div className="mb-m">
      <ChangeEventInfoSection
        title={title}
        amount={accountingNumberFormat(amount)}
        selected={infoData ?? []}
        options={infoData ?? []}
        onIconClick={onIconClick}
      />
    </div>
  );
};
export default InfoBadge;
