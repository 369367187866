import { emptySplitApi as api } from '@/app/stores/api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiSettingsReportTableConfigsRowsConfigSourceTables: build.query<
      GetApiSettingsReportTableConfigsRowsConfigSourceTablesApiResponse,
      GetApiSettingsReportTableConfigsRowsConfigSourceTablesApiArg
    >({
      query: () => ({
        url: `/api/settings/report/table_configs/rows_config_source_tables`,
      }),
    }),
    getApiSettingsReportTableConfigsByIdWidgetConfiguration: build.query<
      GetApiSettingsReportTableConfigsByIdWidgetConfigurationApiResponse,
      GetApiSettingsReportTableConfigsByIdWidgetConfigurationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/report/table_configs/${queryArg.id}/widget_configuration`,
      }),
    }),
    getApiSettingsReportTableConfigs: build.query<
      GetApiSettingsReportTableConfigsApiResponse,
      GetApiSettingsReportTableConfigsApiArg
    >({
      query: () => ({ url: `/api/settings/report/table_configs` }),
    }),
    postApiSettingsReportTableConfigs: build.mutation<
      PostApiSettingsReportTableConfigsApiResponse,
      PostApiSettingsReportTableConfigsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/report/table_configs`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    getApiSettingsReportTableConfigsByTableConfigId: build.query<
      GetApiSettingsReportTableConfigsByTableConfigIdApiResponse,
      GetApiSettingsReportTableConfigsByTableConfigIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/report/table_configs/${queryArg.tableConfigId}`,
      }),
    }),
    putApiSettingsReportTableConfigsByTableConfigId: build.mutation<
      PutApiSettingsReportTableConfigsByTableConfigIdApiResponse,
      PutApiSettingsReportTableConfigsByTableConfigIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/report/table_configs/${queryArg.tableConfigId}`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    deleteApiSettingsReportTableConfigsByTableConfigId: build.mutation<
      DeleteApiSettingsReportTableConfigsByTableConfigIdApiResponse,
      DeleteApiSettingsReportTableConfigsByTableConfigIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/report/table_configs/${queryArg.tableConfigId}`,
        method: 'DELETE',
      }),
    }),
    postApiSettingsReportTableConfigsRowsPreview: build.mutation<
      PostApiSettingsReportTableConfigsRowsPreviewApiResponse,
      PostApiSettingsReportTableConfigsRowsPreviewApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/report/table_configs/rows_preview`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    putApiSettingsReportTableConfigsByTableConfigIdSetPosition: build.mutation<
      PutApiSettingsReportTableConfigsByTableConfigIdSetPositionApiResponse,
      PutApiSettingsReportTableConfigsByTableConfigIdSetPositionApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/report/table_configs/${queryArg.tableConfigId}/set_position`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as reportTableConfigApi };
export type GetApiSettingsReportTableConfigsRowsConfigSourceTablesApiResponse =
  /** status 200 success */ {
    items?: ReportTableConfigRowsConfigSourceTableDto[];
  };
export type GetApiSettingsReportTableConfigsRowsConfigSourceTablesApiArg = void;
export type GetApiSettingsReportTableConfigsByIdWidgetConfigurationApiResponse =
  /** status 200 success */ {
    config: {
      viz_type: string;
      viz_config: {
        rows: any;
      };
      rows: any;
    };
  };
export type GetApiSettingsReportTableConfigsByIdWidgetConfigurationApiArg = {
  /** Id or slug */
  id: string;
};
export type GetApiSettingsReportTableConfigsApiResponse =
  /** status 200 success */ {
    items?: ReportTableConfigDto[];
  };
export type GetApiSettingsReportTableConfigsApiArg = void;
export type PostApiSettingsReportTableConfigsApiResponse = unknown;
export type PostApiSettingsReportTableConfigsApiArg = {
  body: {
    name: string;
    position?: number;
    rows_config_source_table_id?: number | null;
    periods_type?:
      | 'mtd'
      | 'mtd_and_total'
      | 'single_mtd'
      | 'single_mtd_and_ytd'
      | 'single_mtd_qtd_and_ytd'
      | 'single_mtd_qtd_and_itd'
      | 'single_mtd_qtd_and_uw_itd'
      | 'single_qtd_and_ytd'
      | 'single_qtd_ytd_and_itd'
      | 'single_qtd_ytd_and_uw_itd'
      | 'single_t3_and_t12'
      | 'total'
      | 'year_mtd'
      | 'all_time_to_date'
      | 'variance_report';
    public_permission_attributes?: {};
    directly_permitted_user_ids?: string[];
    directly_permitted_user_role_ids?: string[];
    directly_permitted_user_tag_ids?: number[];
    columns_config?: string[];
    rows_config?: object[];
    periods_limitations?: {
      default?: number | null;
      [key: string]: number | null;
    };
    user_financial_category_rules?: {
      user_id?: string;
      access_type?: 'detailed' | 'partial' | 'summary';
    };
    user_role_financial_category_rules?: {
      user_role_id?: string;
      access_type?: 'detailed' | 'partial' | 'summary';
    };
  };
};
export type GetApiSettingsReportTableConfigsByTableConfigIdApiResponse =
  /** status 200 success */ ReportTableConfigDto;
export type GetApiSettingsReportTableConfigsByTableConfigIdApiArg = {
  tableConfigId: string;
};
export type PutApiSettingsReportTableConfigsByTableConfigIdApiResponse =
  /** status 200 success */ ReportTableConfigDto;
export type PutApiSettingsReportTableConfigsByTableConfigIdApiArg = {
  tableConfigId: string;
  body: {
    name?: string;
    rows_config_source_table_id?: number | null;
    periods_type?:
      | 'mtd'
      | 'mtd_and_total'
      | 'single_mtd'
      | 'single_mtd_and_ytd'
      | 'single_mtd_qtd_and_ytd'
      | 'single_mtd_qtd_and_itd'
      | 'single_mtd_qtd_and_uw_itd'
      | 'single_qtd_and_ytd'
      | 'single_qtd_ytd_and_itd'
      | 'single_qtd_ytd_and_uw_itd'
      | 'single_t3_and_t12'
      | 'total'
      | 'year_mtd'
      | 'all_time_to_date'
      | 'variance_report';
    public_permission_attributes?: {
      _destroy?: boolean;
    };
    directly_permitted_user_ids?: string[];
    directly_permitted_user_role_ids?: string[];
    directly_permitted_user_tag_ids?: number[];
    columns_config?: string[];
    rows_config?: object[];
    periods_limitations?: {
      default?: number | null;
      [key: string]: number | null;
    };
    user_financial_category_rules?: {
      user_id?: string;
      access_type?: 'detailed' | 'partial' | 'summary';
    };
    user_role_financial_category_rules?: {
      user_role_id?: string;
      access_type?: 'detailed' | 'partial' | 'summary';
    };
  };
};
export type DeleteApiSettingsReportTableConfigsByTableConfigIdApiResponse =
  unknown;
export type DeleteApiSettingsReportTableConfigsByTableConfigIdApiArg = {
  tableConfigId: string;
};
export type PostApiSettingsReportTableConfigsRowsPreviewApiResponse =
  /** status 200 success */ {
    rows?: object[];
  };
export type PostApiSettingsReportTableConfigsRowsPreviewApiArg = {
  body: {
    rows_config?: object[];
  };
};
export type PutApiSettingsReportTableConfigsByTableConfigIdSetPositionApiResponse =
  /** status 200 success */ {
    success?: boolean;
  };
export type PutApiSettingsReportTableConfigsByTableConfigIdSetPositionApiArg = {
  tableConfigId: string;
  body: {
    position: number;
  };
};
export type ReportTableConfigRowsConfigSourceTableDto = {
  id?: number;
  name?: string;
  slug?: string;
  rowsConfig?: object[];
};
export type ReportTableConfigDto = {
  id: number;
  name: string;
  slug: string;
  periodsType:
    | 'mtd'
    | 'mtd_and_total'
    | 'single_mtd'
    | 'single_mtd_and_ytd'
    | 'single_mtd_qtd_and_ytd'
    | 'single_mtd_qtd_and_itd'
    | 'single_mtd_qtd_and_uw_itd'
    | 'single_qtd_and_ytd'
    | 'single_qtd_ytd_and_itd'
    | 'single_qtd_ytd_and_uw_itd'
    | 'single_t3_and_t12'
    | 'total'
    | 'year_mtd'
    | 'all_time_to_date'
    | 'variance_report';
  position: number;
  columnsConfig: string[];
  rowsConfig: object[];
  rowsConfigSourceTable?: ReportTableConfigRowsConfigSourceTableDto | null;
  periodsLimitations: {
    default?: number | null;
    [key: string]: number | null;
  };
  userFinancialCategoryRules: {
    user?: {
      id?: string;
      fullName?: string;
      role?: {
        id?: string;
        name?: string;
      };
    };
    categoryPath?: string;
    accessType?: 'detailed' | 'partial' | 'summary';
  }[];
  userRoleFinancialCategoryRules: {
    userRole?: {
      id?: string;
      name?: string;
    };
    categoryPath?: string;
    accessType?: 'detailed' | 'partial' | 'summary';
  }[];
  permitted: {
    public: boolean;
    directUsers: {
      id?: string;
      fullName?: string;
      email?: string;
      phone?: string | null;
      initials?: string;
      role?: {
        id?: string;
        category?: string;
        group?: string | null;
        name?: string;
      };
      tags?: {
        id?: number;
        name?: string;
      }[];
    }[];
    directRoles: {
      id?: string;
      category?: string;
      group?: string | null;
      name?: string;
    }[];
    directTags: {
      id?: number;
      name?: string;
    }[];
    allUsers: {
      id?: string;
      fullName?: string;
      email?: string;
      phone?: string | null;
      initials?: string;
      role?: {
        id?: string;
        category?: string;
        group?: string | null;
        name?: string;
      };
      tags?: {
        id?: number;
        name?: string;
      }[];
    }[];
  };
  updatedAt: number;
};
export const {
  useGetApiSettingsReportTableConfigsRowsConfigSourceTablesQuery,
  useGetApiSettingsReportTableConfigsByIdWidgetConfigurationQuery,
  useLazyGetApiSettingsReportTableConfigsByIdWidgetConfigurationQuery,
  useGetApiSettingsReportTableConfigsQuery,
  usePostApiSettingsReportTableConfigsMutation,
  useGetApiSettingsReportTableConfigsByTableConfigIdQuery,
  usePutApiSettingsReportTableConfigsByTableConfigIdMutation,
  useDeleteApiSettingsReportTableConfigsByTableConfigIdMutation,
  usePostApiSettingsReportTableConfigsRowsPreviewMutation,
  usePutApiSettingsReportTableConfigsByTableConfigIdSetPositionMutation,
} = injectedRtkApi;
