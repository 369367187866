import { useRef } from 'react';
import { IconsId } from 'types/sre-icons';
import { cn } from '@/shared/lib/css/cn';
import { Icon } from '@/stories/Icon/Icon';
import { Tooltip } from '@/stories/Tooltip/Tooltip';
import { useIsTextTruncated } from '@/shared/lib/hooks/useIsTextTruncated';
import objectStyles from '@/stories/Objects/Objects.module.scss';
import styles from '@/stories/Objects/InlineObject/InlineObject.module.scss';

interface Props {
  iconName?: IconsId;
  object?: React.ReactNode;
  emptyText?: string;
  onSetObject?: () => void;
  className?: string;
  hideTooltipWhenOverflow?: boolean;
  fallbackNode?: React.ReactNode;
}

export function InlineObject({
  iconName,
  object,
  onSetObject,
  className,
  emptyText = 'Set Category',
  hideTooltipWhenOverflow,
  fallbackNode = null,
}: Props) {
  const container = useRef(null);
  // todo move it to separate component
  const isOverflowed = useIsTextTruncated(container, object);
  if (object === undefined) {
    if (fallbackNode) return <>{fallbackNode}</>;

    return (
      <div
        className={cn(
          objectStyles.container,
          objectStyles.container_empty,
          styles.container,
        )}
      >
        <span
          className="secondary-semibold whitespace-nowrap"
          onClick={onSetObject}
        >
          {emptyText}
        </span>
      </div>
    );
  }

  return (
    <>
      <div
        ref={container}
        className={cn(objectStyles.container, styles.container, className)}
      >
        {iconName && (
          <Icon className="mr-xs text-light-60" iconName={iconName} />
        )}
        <div className={cn('secondary-regular', styles.text)}>{object}</div>
      </div>
      {!hideTooltipWhenOverflow && (
        <Tooltip
          reference={container}
          mainText={object}
          disabled={!isOverflowed}
        />
      )}
    </>
  );
}

export default InlineObject;
