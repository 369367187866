import { RUM_SUBDOMAIN, SYMMETRE_DOMAIN } from '@/lib/http';
import { useLocation } from '@reach/router';

export const useHostedUrl = () => {
  const { origin } = useLocation();

  if (import.meta.env.NODE_ENV !== 'production') {
    return `https://${RUM_SUBDOMAIN}.${SYMMETRE_DOMAIN}.email`;
  }

  return origin;
};
