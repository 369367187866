import PropTypes from 'prop-types';

const PermissionsFor = ({ objects, canViewIds, canManageIds, type }) => (
  <>
    <table className="w-100">
      <thead>
        <tr>
          <th>
            <h6 className="dark-60 mb-[0.5rem] mt-4">{type}</h6>
          </th>
          <th className="font-12 light-90 font-weight-500 w-[120px] text-center">
            View
          </th>
          <th className="font-12 light-90 font-weight-500 w-[120px] text-center">
            Manage
          </th>
        </tr>
      </thead>
    </table>
    <table className="curved-border no-margin-bottom no-border-spacing striped-table table">
      {objects.map((object) => {
        if (
          canViewIds.includes(object.id) ||
          canManageIds.includes(object.id)
        ) {
          return (
            <tr key={object.id}>
              <td className="font-14 light-90">{object.name}</td>
              <td className="w-[120px] text-center">
                <div
                  className={
                    canViewIds.includes(object.id)
                      ? 'sre-icon-check green'
                      : 'sre-icon-dot'
                  }
                />
              </td>
              <td className="w-[120px] border-l border-light-20 text-center">
                <div
                  className={
                    canManageIds.includes(object.id)
                      ? 'sre-icon-check green'
                      : 'sre-icon-dot'
                  }
                />
              </td>
            </tr>
          );
        }
        return false;
      })}
    </table>
  </>
);

PermissionsFor.propTypes = {
  objects: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  canViewIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  canManageIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  type: PropTypes.string.isRequired,
};

export default PermissionsFor;
