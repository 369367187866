import { CapitalInvestment } from '@/entities/return';
import {
  AllocationContext,
  useAllocation,
} from 'bundles/REconcile/components/AllocationProvider';
import AllocationStatistics from 'bundles/REconcile/components/AllocationStatistics';
import CapitalInvestmentsEditor, {
  TEditableCapitalInvestments,
} from 'bundles/REturn/components/Ownership/modals/capitalInvestmentsEditor/CapitalInvestmentsEditor';
import { formatDate } from '@/shared/lib/formatting/dates';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import { useCallback, useState } from 'react';
import { Button } from 'stories/Button/Button';
import { CurrencyFormatter } from 'stories/ValueFormatters/CurrencyFormatter';
import { Modal } from 'stories/Modals/Modal/Modal';
import { ModalHeaderWithIcon } from 'stories/Modals/ModalHeader/ModalHeaderWithIcon/ModalHeaderWithIcon';
import { InvestmentEntityClassesBadges } from '@/bundles/REturn/components/Ownership/ownershipTable/formatters/InvestmentEntityColumn';
import { useCapitalUnitPricesByInvestmentClass } from '@/bundles/REturn/hooks/useLoadedUnitPrices';

interface Props extends DialogProps<[]> {
  selectedCapitalInvestment: CapitalInvestment;
  amountToTransfer: number;
  receivers: TEditableCapitalInvestments[];
  transferDate: Date;
  selectedClass: string;
}

export function SetReceiversModal({
  selectedCapitalInvestment,
  amountToTransfer,
  unitsToTransfer,
  onClose,
  onSubmit,
  receivers,
  transferDate,
  dateForUnitPrice,
  selectedClass,
  unitPrice,
}: Props) {
  const [items, setItems] = useState(receivers);

  if (!items) return null;

  const updateItem = useCallback((index, amount) => {
    setItems((prev) =>
      prev.map((prevItem, i) => {
        if (index === i) {
          return {
            ...prevItem,
            amount: amount ?? 0,
          };
        }
        return prevItem;
      }),
    );
  }, []);

  const sumMapper = useCallback(
    (item: TEditableCapitalInvestments) =>
      unitPrice?.priceCents == 0
        ? (item.unitsQuantity ?? 0)
        : (item.amount ?? 0),
    [],
  );

  const allocation = useAllocation({
    total: unitPrice?.priceCents == 0 ? unitsToTransfer : amountToTransfer,
    items,
    sumMapper,
    updateItem,
  });
  const { total, allItemsAreAllocated, allocationFlags, allocatedAmount } =
    allocation;

  const resolveIsSubmitDisabled = () =>
    !allItemsAreAllocated ||
    !allocationFlags.isFullyAllocated ||
    items.some((item) => !item.date);

  const handleSubmit = () => {
    onSubmit?.(items);
  };

  const { capitalUnitPricesPartitionByInvestmentClass } =
    useCapitalUnitPricesByInvestmentClass({
      investmentClass: selectedClass,
    });

  const unitPrices = unitPrice
    ? [unitPrice]
    : capitalUnitPricesPartitionByInvestmentClass[0];

  return (
    <Modal
      size="900"
      toggle={onClose}
      header={<ModalHeaderWithIcon title="Split Transfer Amounts" />}
      actions={
        <>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            disabled={resolveIsSubmitDisabled()}
            type="submit"
            variant="success"
          >
            Next
          </Button>
        </>
      }
    >
      <div className="-m-6 flex flex-col">
        <div className="gap-y-1.5 flex items-center gap-x-8 border-b border-light-10 bg-white px-6 py-4">
          <div className="flex flex-col gap-xs">
            <p className="secondary-regular uppercase text-light-60">
              Investment Entity
            </p>
            <div>{selectedCapitalInvestment.investmentEntity.name}</div>
          </div>
          <div className="flex flex-col gap-xs">
            <p className="secondary-regular uppercase text-neutral-500">
              Class
            </p>
            <div className="flex gap-xs">
              <InvestmentEntityClassesBadges
                capitalInvestmentClasses={
                  selectedCapitalInvestment.investmentClasses
                }
              />
            </div>
          </div>
          <div className="flex flex-col gap-xs">
            <p className="secondary-regular uppercase text-light-60">Amount</p>
            <CurrencyFormatter value={amountToTransfer} />
          </div>
          {selectedCapitalInvestment.totalUnits > 0 && (
            <div className="flex flex-col gap-xs">
              <p className="secondary-regular uppercase text-light-60">Units</p>
              <div>{selectedCapitalInvestment.totalUnits}</div>
            </div>
          )}
          <div className="flex flex-col gap-xs">
            <p className="secondary-regular uppercase text-light-60">Date</p>
            <p className="inline-regular text-dark-60">
              {formatDate(dateForUnitPrice || transferDate, 'MMM DD, YYYY')}
            </p>
          </div>
        </div>
        <div className="flex flex-col gap-4 bg-light px-6 py-4">
          <AllocationStatistics
            allocatedAmount={allocatedAmount}
            totalAmount={total ?? 0}
            notDollarAmount={unitPrice?.priceCents == 0}
          />
          <AllocationContext.Provider value={allocation}>
            <CapitalInvestmentsEditor
              onCapitalInvestmentsChange={setItems}
              capitalInvestments={items}
              unitPrices={unitPrices}
              selectedUnitPrice={unitPrice}
              investmentClass={selectedClass}
            />
          </AllocationContext.Provider>
        </div>
      </div>
    </Modal>
  );
}
