import { useAppDispatch } from '@/shared/lib/hooks/redux';
import { Link } from '@reach/router';
import { heavyUpdateFund, plainFetchFund } from 'bundles/Funds/actions/funds';
import SetAssetModal from 'bundles/Funds/components/FundCard/SetAssetModal';
import FundFormModal from 'bundles/Funds/components/FundFormModal';
import { getFundStatusColor } from 'lib/fund';
import { capitalize } from 'lodash-es';
import { useState } from 'react';
import { Badge } from 'stories/Badges/Badge/Badge';
import { Icon } from 'stories/Icon/Icon';
import { IconButton } from 'stories/IconButton/IconButton';
import { OverlaySpinner } from 'stories/OverlaySpinner/OverlaySpinner';
import { SmallAddingButton } from 'stories/SmallAddingButton/SmallAddingButton';
import { IFund } from 'types/Fund';
import AssetsTable from '@/bundles/Assets/components/Table/AssetsTable';
import CardHorizontal from '@/bundles/Shared/components/CardHorizontal';
import CardHorizontalCollapse from '@/bundles/Shared/components/CardHorizontal/CardHorizontalCollapse';

interface Props {
  fund: IFund;
  funds?: IFund[];
  setFunds?: (funds: IFund[]) => void;
  editable?: boolean;
}

const FundCardHorizontal = ({ fund, editable, funds, setFunds }: Props) => {
  const { id, name, slug, assets, aasmState } = fund;

  const [isLoading, setIsLoading] = useState(false);
  const [isSetAssetModalOpen, setIsAssetModalOpen] = useState(false);
  const [isFundFormOpen, setIsFundFormOpen] = useState(false);
  const dispatch = useAppDispatch();

  const handleFetchFund = async () => {
    try {
      setIsLoading(true);
      const fundJson = await plainFetchFund(id);
      setFunds?.(funds?.map((fund) => (fund.id === id ? fundJson : fund)));
    } catch (error) {
      toastr.error(`Unable to fetch fund with id ${id}`);
    } finally {
      setIsLoading(false);
    }
  };

  const toggleSetAssetModal = () => setIsAssetModalOpen((opened) => !opened);
  const toggleFundFormModal = () => setIsFundFormOpen((opened) => !opened);

  return (
    <CardHorizontal>
      <div className="card-horizontal__header">
        {isLoading ? (
          <OverlaySpinner size="small" />
        ) : (
          <div className="flex">
            <div
              className="icon mr-m"
              style={{
                backgroundColor: 'var(--violet-light-5)',
                color: 'var(--violet)',
                width: '4rem',
                height: '4rem',
              }}
            >
              <Icon iconName="funds" className="text-[2rem]" />
            </div>
            <div className="my-xs flex flex-col justify-between">
              <div className="flex items-center">
                <Link to={`/funds/${slug}`}>
                  <h5 className="blue header5-regular">{name}</h5>
                </Link>
                {editable && !isLoading && (
                  <IconButton
                    onClick={toggleFundFormModal}
                    iconName="edit"
                    className="ml-s"
                    variant="white"
                  />
                )}
              </div>
              <div className="flex items-center">
                <Badge
                  backgroundColor={getFundStatusColor(aasmState)}
                  className="mr-4"
                >
                  {capitalize(aasmState)}
                </Badge>
              </div>
            </div>
          </div>
        )}
        {editable && !isLoading && (
          <div className="mt-[0.7rem] flex items-center">
            <SmallAddingButton onClick={toggleSetAssetModal}>
              Set Assets
            </SmallAddingButton>
            {isSetAssetModalOpen && (
              <SetAssetModal
                fundId={id}
                fundName={name}
                fundAssets={assets}
                onClose={toggleSetAssetModal}
                onSubmit={handleFetchFund}
              />
            )}
          </div>
        )}
        {isFundFormOpen && (
          <FundFormModal
            fund={fund}
            onSubmit={async (data) => {
              await dispatch(heavyUpdateFund(data));
              handleFetchFund();
            }}
            onClose={toggleFundFormModal}
          />
        )}
      </div>
      {assets.length > 0 && (
        <div className="card-horizontal__body">
          <CardHorizontalCollapse
            header={
              <div className="flex">
                {isLoading ? (
                  <OverlaySpinner overlayClasses="ml-4" size="small" inline />
                ) : (
                  <h6 className="header-6 mr-4">{assets.length} Assets</h6>
                )}
              </div>
            }
          >
            <AssetsTable
              assets={assets}
              borderLessOutside
              classes={{
                container: 'w-full mt-4',
              }}
              columns={{
                externalUserCount: {
                  hidden: true,
                },
                internalUserCount: {
                  hidden: true,
                },
                actions: {
                  hidden: true,
                },
              }}
            />
          </CardHorizontalCollapse>
        </div>
      )}
    </CardHorizontal>
  );
};

export default FundCardHorizontal;
