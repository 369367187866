import {
  HEADER_BACKGROUND_SCHEMA,
  PERIOD_SHIFT_SCHEMA,
} from '@/bundles/Shared/widgets/dashboard/widgets/common/config';
import { HistoricalReviewTableWidgetConfigDto } from '@/shared/api/dashboardsSettingsGeneratedApi';
import { transformEmptyObjectToUndefined } from '@/shared/lib/yup';
import { transformPeriodShiftDtoToForm } from 'bundles/Shared/widgets/dashboard/widgets/common/lib/config';
import * as yup from 'yup';

export const HISTORICAL_TABLE_WIDGET_CONFIG_SCHEMA = yup
  .object()
  .concat(HEADER_BACKGROUND_SCHEMA)
  .shape({
    period_shift: PERIOD_SHIFT_SCHEMA.nullable()
      .optional()
      .transform(transformEmptyObjectToUndefined)
      .default(undefined),
  });

export type HistoricalTableWidgetConfigForm = yup.InferType<
  typeof HISTORICAL_TABLE_WIDGET_CONFIG_SCHEMA
>;

export const transformHistoricalTableWidgetConfigToForm = (
  config: HistoricalReviewTableWidgetConfigDto,
): HistoricalTableWidgetConfigForm => {
  return {
    period_shift: {
      ...transformPeriodShiftDtoToForm(
        config.default_options?.date?.period_shift,
      ),
    },
    header_background: config.viz_config.header_background,
  };
};
