import { cn } from '@/shared/lib/css/cn';
import { isSameDay, isSameMonth, todayDate } from '@/shared/lib/date';
import dayjs from 'dayjs';
import * as React from 'react';
import { HTMLAttributes } from 'react';
import { getWeeks } from 'stories/Calendar/lib';
import {
  CalendarDay,
  CalendarDayElementProps,
} from '@/stories/Calendar/Day/CalendarDay';
import styles from '@/stories/Calendar/Days/CalendarDays.module.scss';

export interface CalendarDaysProps
  extends Omit<HTMLAttributes<HTMLDivElement>, 'onChange'> {
  value?: Date | Array<Date | null>;
  viewDate: Date;
  weekStartsOn: 0 | 1 | 2 | 3 | 4 | 5 | 6;
  showNeighboringMonth?: boolean;
  dayProps?: CalendarDayElementProps;
  listenDayChangesForUpdate?: boolean;
  onDayChange(value: Date): void;
  isDayDisabled(value: Date): boolean;
  isDaySelectionStart(value: Date, dayOfWeek: number): boolean;
  isDaySelectionEnd(value: Date, dayOfWeek: number): boolean;
  isHintedDaySelectionStart?(value: Date, dayOfWeek: number): boolean;
  isHintedDaySelectionEnd?(value: Date, dayOfWeek: number): boolean;
  isDayActive(value: Date): boolean;
  isDayHinted?(value: Date): boolean;
  isDaySelected?(value: Date): boolean;
  isDayFocused(value: Date): boolean;
  onDayEnter?(value: Date): void;
  onDayLeave?(value: Date): void;
}

export const CalendarDays = ({
  viewDate,
  value,
  weekStartsOn,
  onDayChange,
  isDaySelected,
  isDayActive,
  isDaySelectionEnd,
  isDaySelectionStart,
  onDayEnter,
  onDayLeave,
  isDayHinted,
  isHintedDaySelectionStart,
  isHintedDaySelectionEnd,
  isDayFocused,
  isDayDisabled,
  showNeighboringMonth = false,
  dayProps,
  listenDayChangesForUpdate = false,
  ...props
}: CalendarDaysProps) => {
  const now = todayDate();

  const weeks = React.useMemo(
    () => getWeeks(viewDate, weekStartsOn),
    [weekStartsOn, viewDate],
  );

  const daysNames = React.useMemo(() => dayjs.weekdaysShort(true), []);

  const handleDayChange = React.useCallback(
    (date: Date) => {
      onDayChange(date);
    },
    [onDayChange],
  );

  return (
    <div {...props} className={styles.CalendarDays}>
      <div className={cn(styles.CalendarDays__row)}>
        {daysNames.map((dayName) => (
          <span key={dayName} className={styles.CalendarDays__weekday}>
            {dayName}
          </span>
        ))}
      </div>

      {weeks.map((week, i) => (
        <div className={cn(styles.CalendarDays__row)} key={i}>
          {week.map((day, ix) => {
            const sameMonth = isSameMonth(day, viewDate);
            return (
              <CalendarDay
                key={day.toISOString()}
                day={day}
                today={isSameDay(day, now)}
                active={isDayActive(day)}
                onChange={handleDayChange}
                hidden={!showNeighboringMonth && !sameMonth}
                disabled={isDayDisabled(day)}
                selectionStart={isDaySelectionStart(day, ix)}
                selectionEnd={isDaySelectionEnd(day, ix)}
                hintedSelectionStart={isHintedDaySelectionStart?.(day, ix)}
                hintedSelectionEnd={isHintedDaySelectionEnd?.(day, ix)}
                selected={isDaySelected?.(day)}
                focused={isDayFocused(day)}
                onEnter={onDayEnter}
                onLeave={onDayLeave}
                hinted={isDayHinted?.(day)}
                sameMonth={sameMonth}
                {...dayProps}
              />
            );
          })}
        </div>
      ))}
    </div>
  );
};
