import { useModal } from '@/shared/lib/hooks/useModal';
import { currentUserIsSreAdmin } from 'lib/permissions';
import { IconButton } from '@/stories/IconButton/IconButton';
import {
  useGetApiSettingsReportRentRollUnitTypesTreeQuery,
  usePutApiSettingsReportRentRollUnitTypesTreeUpdateMutation,
} from '@/entities/report/unitTypes/api';
import { pick, startCase } from 'lodash-es';
import { transformTree } from '@/lib/treeHelpers';
import { UnitTypeManageTreeModal } from '@/widgets/report/unitTypeManage/ui/UnitTypeManageTreeModal';
import { ReportRentRollUnitTypeTreeDto } from '@/entities/report/unitTypes/api/settingsReportOperationalUnitTypesGeneratedApi';

export function UnitTypesManageTreeButton() {
  const { openModal } = useModal();
  const { data, isFetching } =
    useGetApiSettingsReportRentRollUnitTypesTreeQuery();
  const [updateCategoriesTree] =
    usePutApiSettingsReportRentRollUnitTypesTreeUpdateMutation();

  const handleClick = async () => {
    const preprocessedTree = transformTree<ReportRentRollUnitTypeTreeDto>(
      { children: data?.tree ?? [] },
      (node) => ({
        ...node,
        title: node.label,
      }),
    );
    const updatedTree = await openModal(UnitTypeManageTreeModal, {
      unitTypes: preprocessedTree.children,
    });
    if (updatedTree != null) {
      const treeToUpdate = transformTree(
        { children: updatedTree },
        (node, _, index) => ({
          ...pick(node, ['children', 'id', 'name']),
          label: startCase(node.name),
          position: node.isNew ? 1 : index + 1,
        }),
      );
      await updateCategoriesTree({
        body: {
          tree: treeToUpdate.children,
        },
      });
    }
  };

  return (
    currentUserIsSreAdmin() && (
      <IconButton
        isLoading={isFetching}
        disabled={isFetching}
        onClick={handleClick}
        iconName="settings"
      />
    )
  );
}
