import React from 'react';
import { Badge } from 'stories/Badges/Badge/Badge';
import { RadioButton } from 'stories/RadioButton/RadioButton';
import { cn } from '@/shared/lib/css/cn';
import { IAssetStage } from 'types/AssetStage';
import styles from '@/bundles/Assets/components/StepperStages/StepperStages.module.scss';

interface Props {
  selectedStage: string;
  onChange: (name: IAssetStage['name']) => void;
  assetStages: IAssetStage[];
}

const StepperStages = ({ selectedStage, onChange, assetStages }: Props) => {
  const gridRowCount = Math.ceil(assetStages.length / 2);

  return (
    <div
      className={styles.stage_container}
      style={{ gridTemplateRows: `repeat(${gridRowCount}, auto)` }}
    >
      {assetStages.map((stage, index) => (
        <div key={stage.id} className="inline-regular flex items-center">
          <div className="mr-s">
            <Badge
              classes={{
                value: cn({
                  'bg-blue-20 white': stage.name === selectedStage,
                  'bg-light-20 dark-60': stage.name !== selectedStage,
                }),
              }}
            >
              <div className={styles.item_index}>{index + 1}</div>
            </Badge>
          </div>
          <div className="flex items-center">
            <RadioButton
              checked={stage.name === selectedStage}
              onChange={onChange && (() => onChange(stage.name))}
            >
              <div className="dark-60">{stage.name}</div>
            </RadioButton>
          </div>
        </div>
      ))}
    </div>
  );
};
export default StepperStages;
