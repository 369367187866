import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { cn } from '@/shared/lib/css/cn';
import pluralize from 'pluralize';

import { Tab } from 'stories/Tab/Tab';
import { TabItem } from 'stories/TabItem/TabItem';
import { useModal } from '@/shared/lib/hooks/useModal';
import CardHorizontal from 'bundles/Shared/components/CardHorizontal';
import CardHorizontalCollapse from 'bundles/Shared/components/CardHorizontal/CardHorizontalCollapse';
import impersonationConfirm from '@/entities/impersonation/ui/impersonationConfirm';
import AssetsTable from 'bundles/Assets/components/Table/AssetsTable';
import NameCell from 'bundles/Assets/components/Table/cellFormatters/name/NameCell';
import AdviseeCardHeader from '@/bundles/AdvisorDashboard/components/AdviseeCard/AdviseeCardHeader';

function AssetsCardHorizontalCollapse({
  assets,
  header,
  impersonationModeEntryHandler,
}) {
  return (
    <CardHorizontalCollapse
      collapseButtonHidden={assets.length === 0}
      header={header}
    >
      <AssetsTable
        assets={assets}
        borderLessOutside
        classes={{
          container: 'w-full mt-4',
        }}
        columns={{
          name: {
            formatter: ({ row: asset }) => (
              <a
                className="cursor-pointer"
                onClick={impersonationModeEntryHandler(`/assets/${asset.slug}`)}
              >
                <NameCell asset={asset} />
              </a>
            ),
          },
          externalUserCount: {
            hidden: true,
          },
          internalUserCount: {
            hidden: true,
          },
          actions: {
            hidden: true,
          },
        }}
      />
    </CardHorizontalCollapse>
  );
}
AssetsCardHorizontalCollapse.propTypes = {
  assets: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  header: PropTypes.node.isRequired,
  impersonationModeEntryHandler: PropTypes.func.isRequired,
};

const ASSETS_TAB_ID = '1';
const FUNDS_TAB_ID = '2';

function AdviseeCard({
  id,
  fullName,
  phone,
  email,
  assets,
  funds,
  investmentEntities,
}) {
  const defaultActiveTab = assets.length > 0 ? ASSETS_TAB_ID : FUNDS_TAB_ID;
  const [activeTab, setActiveTab] = useState(defaultActiveTab);
  const { confirm } = useModal();

  const isAssetsTabActive = (tab) => tab === ASSETS_TAB_ID;
  const isFundsTabActive = (tab) => tab === FUNDS_TAB_ID;

  const impersonationModeEntryHandler = (redirectUrl) => async (e) => {
    e.preventDefault();
    impersonationConfirm({ user: { id, fullName }, redirectUrl, confirm });
  };

  return (
    <CardHorizontal>
      <div className="card-horizontal__header">
        <AdviseeCardHeader
          userId={id}
          phone={phone}
          email={email}
          userFullName={fullName}
          investmentEntities={investmentEntities}
          impersonationModeEntryHandler={impersonationModeEntryHandler('')}
        >
          <Tab>
            <TabItem
              variant="circle"
              active={isAssetsTabActive(activeTab)}
              onClick={() => setActiveTab(ASSETS_TAB_ID)}
            >
              Assets {assets.length}
            </TabItem>
            <TabItem
              variant="circle"
              active={isFundsTabActive(activeTab)}
              onClick={() => setActiveTab(FUNDS_TAB_ID)}
            >
              Funds {funds.length}
            </TabItem>
          </Tab>
        </AdviseeCardHeader>
      </div>
      {(assets.length > 0 || funds.length > 0) && (
        <div className="card-horizontal__body">
          {activeTab === ASSETS_TAB_ID && (
            <AssetsCardHorizontalCollapse
              assets={assets}
              impersonationModeEntryHandler={impersonationModeEntryHandler}
              header={
                <h6 className="header-6 mr-4">
                  {assets.length} {pluralize('Asset', assets.length)}
                </h6>
              }
            />
          )}
          {activeTab === FUNDS_TAB_ID &&
            funds.map((fund, i) => (
              <div
                className={cn({ 'mb-[0.75rem]': i !== funds.length - 1 })}
                key={fund.id}
              >
                <AssetsCardHorizontalCollapse
                  assets={fund.assets}
                  impersonationModeEntryHandler={impersonationModeEntryHandler}
                  header={
                    <div className="flex flex-col">
                      <h6 className="header-6 mr-4">{fund.name}</h6>
                      <span className="card-horizontal__subtitle">
                        {fund.assets.length}{' '}
                        {pluralize('Asset', fund.assets.length)}
                      </span>
                    </div>
                  }
                />
              </div>
            ))}
        </div>
      )}
    </CardHorizontal>
  );
}
AdviseeCard.propTypes = {
  id: PropTypes.number.isRequired,
  fullName: PropTypes.string.isRequired,
  phone: PropTypes.string,
  email: PropTypes.string.isRequired,
  assets: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  funds: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  investmentEntities: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};
AdviseeCard.defaultProps = {
  phone: null,
};

export default AdviseeCard;
