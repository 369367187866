import React, { useMemo } from 'react';
import { Button } from 'stories/Button/Button';
import { Dropdown } from 'stories/Dropdown/Dropdown';
import { DropdownItem } from 'stories/Dropdown/DropdownItem/DropdownItem';

function QuickFilterDropdown({ filterModel, setFilterModel, column, options }) {
  const onSelectedChange = (option: string) => {
    setFilterModel({
      ...filterModel,
      [column.dataField]: option === 'all' ? undefined : option,
    });
  };

  const handleItemClick = (key: string) => {
    onSelectedChange(key);
  };

  const currentOption =
    options.find((o) => o.key === filterModel[column.dataField]) ?? options[0];

  const selected = useMemo(
    () => filterModel[column.dataField] ?? 'all',
    [filterModel, column.dataField],
  );

  return (
    <div className="ml-s">
      <Dropdown
        items={options.map((o: { key: string; label: string }) => (
          <DropdownItem
            key={o.key}
            active={selected === o.key}
            onClick={() => handleItemClick(o.key)}
          >
            {o.label}
          </DropdownItem>
        ))}
        maxWidth="12rem"
      >
        <Button
          iconPosition="right"
          iconName="bottom"
          size="xs"
          variant="secondary"
        >
          {currentOption?.label}
        </Button>
      </Dropdown>
    </div>
  );
}

export default QuickFilterDropdown;
