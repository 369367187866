import {
  InvestmentObjectShow,
  InvestmentObjectUnitPrice,
} from '@/entities/return/api/capitalInvestmentObjectsGeneratedApi';
import { assertsType } from '@/lib/typeHelpers/assertsType';
import { PercentageInputNumber } from 'bundles/Shared/components/GroupForm/FormItems/PercentageInputNumber';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import { capitalize } from 'lodash-es';
import { useState } from 'react';
import { Button } from 'stories/Button/Button';
import { Modal } from 'stories/Modals/Modal/Modal';
import { ModalActions } from 'stories/Modals/Modal/ModalActions';
import { ModalHeaderWithSubtitle } from 'stories/Modals/ModalHeader/ModalHeaderWithSubtitle/ModalHeaderWithSubtitle';

type State = {
  preferredReturnPercent: number;
};

export function PreferredReturnSettingsModal({
  capitalInvestmentObject,
  onClose,
  preferredReturnPercent,
  investmentClass,
  onSubmit,
}: {
  capitalInvestmentObject: InvestmentObjectShow;
  investmentClass: InvestmentObjectUnitPrice['investmentClass'];
} & Partial<State> &
  DialogProps<State>) {
  const [form, setForm] = useState<Partial<State>>({
    preferredReturnPercent,
  });

  return (
    <Modal
      size="400"
      toggle={onClose}
      classes={{
        body: 'flex gap-4 items-center',
      }}
      actions={
        <ModalActions alignItems="space-between">
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="success"
            disabled={form.preferredReturnPercent == null}
            onClick={() => {
              assertsType<State, Partial<State>>(
                form,
                (i) => i.preferredReturnPercent != null,
              );

              onSubmit?.(form);
            }}
          >
            Save Updates
          </Button>
        </ModalActions>
      }
      header={
        <ModalHeaderWithSubtitle
          title="Preferred Return"
          subtitle={capitalInvestmentObject.object.name}
          order="subtitle-title"
        />
      }
    >
      <label className="flex grow flex-col gap-2">
        <p className="label-regular text-neutral-900">
          Class {capitalize(investmentClass)}{' '}
          <span className="text-danger-060">*</span>
        </p>
        <PercentageInputNumber
          selectOnFocus
          size="m"
          allowNegative={false}
          value={String(form.preferredReturnPercent)}
          onValueChange={(maybeValue) => {
            setForm(() => ({
              preferredReturnPercent: maybeValue
                ? Number(maybeValue)
                : undefined,
            }));
          }}
        />
      </label>
    </Modal>
  );
}
