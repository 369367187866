import { emptySplitApi as api } from '@/app/stores/api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiSettingsReportRentRollNonRevenueConfig: build.query<
      GetApiSettingsReportRentRollNonRevenueConfigApiResponse,
      GetApiSettingsReportRentRollNonRevenueConfigApiArg
    >({
      query: () => ({
        url: `/api/settings/report/rent_roll/non_revenue_config`,
      }),
    }),
    putApiSettingsReportRentRollNonRevenueConfig: build.mutation<
      PutApiSettingsReportRentRollNonRevenueConfigApiResponse,
      PutApiSettingsReportRentRollNonRevenueConfigApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/report/rent_roll/non_revenue_config`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as settingsReportNonRevenueConfigApi };
export type GetApiSettingsReportRentRollNonRevenueConfigApiResponse =
  /** status 200 success */ {
    sections: ReportRentRollNonRevenueConfigSectionDto[];
  };
export type GetApiSettingsReportRentRollNonRevenueConfigApiArg = void;
export type PutApiSettingsReportRentRollNonRevenueConfigApiResponse = unknown;
export type PutApiSettingsReportRentRollNonRevenueConfigApiArg = {
  body: {
    sections: {
      erp_system: string;
      items: {
        key: string;
        non_revenue: boolean;
      }[];
    }[];
  };
};
export type ReportRentRollNonRevenueConfigSectionDto = {
  erpSystem: string;
  label: string;
  items: {
    key: string;
    label: string;
    nonRevenue: boolean;
  }[];
};
