import {
  PageParamsProvider,
  PageParamsSearch,
} from '@/bundles/Shared/components/pageParams';
import { ShownInfoLine } from '@/bundles/Shared/components/Table/pagination/TablePagination';
import Table from '@/bundles/Shared/components/Table/Table';
import type { IColumn } from '@/bundles/Shared/components/Table/types';
import { reportingEnhancedApi } from '@/entities/reporting/api/reportingEnhancedApi';
import type {
  GetApiReportBuilderReportGroupsByReportGroupIdEagleEyeReportsApiArg,
  PreviewReportDto,
} from '@/entities/reporting/api/reportingGeneratedApi';
import { ReportingEntityKindContext } from '@/entities/reporting/context/entityKind';
import { ReportingEntityStageContext } from '@/entities/reporting/context/entityStage';
import { useEagleEyeReportGroupIdParam } from '@/entities/reporting/lib/eagleEye';
import { AuthorColumnFormatter } from '@/entities/reporting/ui/AuthorColumnFormatter';
import { ReportBuilderTemplateIconButton } from '@/entities/reporting/ui/ReportBuilderTemplateIconButton';
import { useDeleteReport } from '@/features/report/report/deleteReport/lib';
import { useDeleteReportGroup } from '@/features/report/report/deleteReportGroup/lib';
import { useCreateEagleEyeReport } from '@/features/report/report/eagleEye/createReport/lib';
import { useReportBuilderNavigateToEagleEyeReport } from '@/features/report/report/navigateToReport/lib';
import { MODULE_LABELS } from '@/lib/dictionaries';
import {
  currentUserAllowedToConfigureReportingPage,
  currentUserAllowedToManageReportingPage,
} from '@/pages/reporting/permissions';
import { CssVar } from '@/shared/config/cssVar';
import { formatDate } from '@/shared/lib/formatting/dates';
import { useNavigateBack } from '@/shared/lib/hooks/navigation';
import { ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import { usePageParamsTableSorting } from '@/shared/lib/hooks/usePageParamsTableSorting';
import { GrowDiv } from '@/shared/ui/GrowDiv';
import { AnimationLoader } from '@/stories/AnimationLoader/AnimationLoader';
import { Button } from '@/stories/Button/Button';
import { GeneralSettingsNavigationSection } from '@/stories/GeneralSettingsNavigationSection/GeneralSettingsNavigationSection';
import { IconButton } from '@/stories/IconButton/IconButton';
import { Label } from '@/stories/Label/Label';
import type { IQueryParams } from '@/types/Pagination';
import type { ISortParamsField } from '@/types/Sort';
import { type RouteComponentProps } from '@reach/router';
import pluralize from 'pluralize';
import { useMemo, useState, type FC } from 'react';

// entities/reporting/config
const REPORT_STATUS_LABEL_COLOR_MAP = {
  draft: {
    colorVar: CssVar.neutral500,
  },
  generated: {
    colorVar: CssVar.success055,
  },
} as const satisfies Record<
  PreviewReportDto['status'],
  {
    colorVar: string;
  }
>;

type SortField = NonNullable<
  NonNullable<
    GetApiReportBuilderReportGroupsByReportGroupIdEagleEyeReportsApiArg['sort']
  >['field']
>;

const Component = () => {
  const reportGroupId = useEagleEyeReportGroupIdParam();
  const { navigateBack } = useNavigateBack({
    fallbackUrl: ROUTES_ROOT.reporting.fullPath,
  });
  const [pageParams, setPageParams] = useState<
    IQueryParams &
      ISortParamsField<SortField> & {
        status: GetApiReportBuilderReportGroupsByReportGroupIdEagleEyeReportsApiArg['status'];
      }
  >({
    query: '',
    sort: {
      field: 'created_at',
      order: 'desc',
    },
    status: 'generated',
  });

  const handleChangePageParam = <
    N extends keyof typeof pageParams,
    V extends (typeof pageParams)[N],
  >(
    name: N,
    value: V,
  ) => {
    setPageParams((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const [handleDeleteReportGroup, deleteReportGroupOptions] =
    useDeleteReportGroup(navigateBack);
  const [handleDelete, deleteReportOptions] = useDeleteReport();

  const { settings, setSettings } = usePageParamsTableSorting(
    pageParams,
    setPageParams,
  );

  const {
    data,
    isFetching: isDataFetchin,
    isLoading: isDataLoading,
  } = reportingEnhancedApi.useGetApiReportBuilderReportGroupsByReportGroupIdEagleEyeReportsQuery(
    {
      reportGroupId,
      ...pageParams,
    },
  );
  const reportGroup = data?.meta?.reportGroup;

  const handleNavigateToReport = useReportBuilderNavigateToEagleEyeReport();

  const [handleCreateReport, createOptions] = useCreateEagleEyeReport(
    handleNavigateToReport,
  );

  const isLoading = isDataLoading;
  const isFetching =
    isDataFetchin ||
    deleteReportGroupOptions.isLoading ||
    deleteReportOptions.isLoading ||
    createOptions.isLoading;

  const columnDefs = useMemo<
    IColumn<NonNullable<NonNullable<typeof data>['items']>[number]>[]
  >(
    () => [
      {
        sortable: true,
        dataField: 'name',
        classes: 'w-[20%] max-w-[500px]',
        text: 'Report Name',
        formatter: ({ row }) => (
          <div className="flex flex-col gap-2">
            <div className="flex items-center justify-between gap-2">
              <p className="inline-semibold text-ellipsis text-neutral-850">
                {row.name}
              </p>

              {(row.status === 'generated' ||
                currentUserAllowedToManageReportingPage()) && (
                <Button
                  onClick={() =>
                    handleNavigateToReport(
                      row.id,
                      reportGroupId,
                      row.status === 'generated',
                    )
                  }
                  className="invisible w-max group-hover:visible"
                  size="xs"
                >
                  Open
                </Button>
              )}
            </div>
          </div>
        ),
      },
      {
        dataField: 'status',
        classes: 'w-[20%]',
        text: 'Status',
        formatter: ({ row }) => (
          <div className="flex">
            <Label
              color={
                REPORT_STATUS_LABEL_COLOR_MAP[row.status ?? 'draft'].colorVar
              }
            >
              {row.status}
            </Label>
            <GrowDiv />
          </div>
        ),
      },
      {
        sortable: true,
        classes: 'w-[20%]',
        dataField: 'report_date',
        text: 'Report Date',
        formatter: ({ row }) => (
          <div className="flex flex-col gap-2">
            <p>{formatDate(row.date, 'MMM DD, YYYY')}</p>
          </div>
        ),
      },
      {
        sortable: true,
        classes: 'w-[20%]',
        dataField: 'created_at',
        text: 'Created',
        formatter: ({ row }) => (
          <AuthorColumnFormatter
            name={row.createdBy.name}
            unixTime={row.createdAt}
          />
        ),
      },
      {
        sortable: true,
        classes: 'w-[20%]',
        dataField: 'updated_at',
        text: 'Modified',
        formatter: ({ row }) => (
          <AuthorColumnFormatter
            name={row.updatedBy?.name}
            unixTime={row.updatedAt}
          />
        ),
      },
      {
        dataField: 'actions',
        classes: 'w-[24px]',
        formatter: ({ row }) => (
          <div className="flex items-center gap-2">
            {row.status === 'generated' && (
              <a
                href={row.fileUrl}
                download={row.name}
                target="_blank"
                rel="noopener noreferrer"
              >
                <IconButton iconName="exportDownload" />
              </a>
            )}
            {currentUserAllowedToManageReportingPage() && (
              <>
                <IconButton
                  iconName="share"
                  tooltipProps={{
                    mainText: 'Coming soon',
                  }}
                  disabled
                />
                <IconButton
                  onClick={() => handleDelete(row.id, reportGroupId)}
                  iconName="trash"
                />
              </>
            )}
          </div>
        ),
      },
    ],
    [reportGroup],
  );

  if (isLoading) return <AnimationLoader className="relative min-h-[80vh]" />;

  if (reportGroup == null || data == null) return;

  return (
    <PageParamsProvider pageParams={pageParams} setPageParams={setPageParams}>
      <div className="flex flex-col">
        <div className="flex w-full items-center gap-4 bg-neutral-000 px-6 py-4">
          <IconButton
            onClick={() => navigateBack()}
            iconName="arrowLeft"
            className="h-[70px]"
          />
          <div className="flex flex-col gap-1">
            {/* TODO update when Object Level Reports are migrated */}
            <p className="secondary-semibold text-neutral-550">
              {MODULE_LABELS.Reporting} • One Off • Eagle Eye
            </p>
            <p className="header6-bold text-neutral-850">{reportGroup.name}</p>
            {currentUserAllowedToConfigureReportingPage() && (
              <ReportBuilderTemplateIconButton
                template={reportGroup.template}
              />
            )}
          </div>
          {currentUserAllowedToConfigureReportingPage() && (
            <div className="flex items-center gap-2">
              <IconButton
                onClick={() => handleDeleteReportGroup(reportGroupId)}
                iconName="trash"
              />
            </div>
          )}
        </div>

        <div className="flex flex-col gap-6 p-6">
          {currentUserAllowedToManageReportingPage() && (
            <div className="flex items-center gap-4">
              <GeneralSettingsNavigationSection
                isLoading={isLoading}
                disabled={isFetching}
                title={`${
                  reportGroup.reports.generatedCount
                } Generated ${pluralize(
                  'Report',
                  reportGroup.reports.generatedCount,
                )}`}
                active={pageParams.status === 'generated'}
                onClick={() => {
                  handleChangePageParam('status', 'generated');
                }}
              />
              <GeneralSettingsNavigationSection
                isLoading={isLoading}
                disabled={isFetching}
                title={`${reportGroup.reports.draftCount} Draft ${pluralize(
                  'Report',
                  reportGroup.reports.draftCount,
                )}`}
                active={pageParams.status === 'draft'}
                onClick={() => {
                  handleChangePageParam('status', 'draft');
                }}
              />
            </div>
          )}

          <div className="flex items-center gap-4">
            <ShownInfoLine totalSize={data.meta.totalSize} />
            <GrowDiv />
            <PageParamsSearch placeholder="Report Name" />
            {currentUserAllowedToManageReportingPage() && (
              <Button
                variant="primary"
                size="s"
                onClick={() => {
                  handleCreateReport(reportGroup);
                }}
              >
                New Report
              </Button>
            )}
          </div>

          <Table
            settings={settings}
            setSettings={setSettings}
            loading={isFetching}
            items={data.items ?? []}
            columns={columnDefs}
          />
        </div>
      </div>
    </PageParamsProvider>
  );
};

export const ReportingEagleEyeReportGroupPage: FC<RouteComponentProps> = () => (
  <ReportingEntityKindContext.Provider value="eagle_eye">
    <ReportingEntityStageContext.Provider value="report">
      <Component />
    </ReportingEntityStageContext.Provider>
  </ReportingEntityKindContext.Provider>
);
