// todo: rename to more common

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import SearchableMultiSelect from '@/bundles/Shared/components/SearchableMultiSelect';

const SetAdvisors = ({ setValue, defaultValues, placeholder, url, params }) => {
  const userToOption = (userData) => ({
    value: userData.id,
    label: `${userData.fullName} (${userData.email}) - ${userData.role.name}`,
  });

  const [users, setUsers] = useState(
    defaultValues.map((defaultValue) => userToOption(defaultValue)),
  );

  // todo: dry tag form
  const getUsersOptions = (usersList) =>
    usersList.map(({ fullName, role, id }) => ({
      label: `${fullName} - ${role?.name}`,
      value: id,
    }));

  return (
    <div className="mb-4">
      <SearchableMultiSelect
        url={url}
        value={users}
        placeholder={placeholder}
        setValue={(options) => {
          setValue(options.map(({ value }) => value));
          setUsers(options);
        }}
        getOptions={getUsersOptions}
        params={params}
      />
    </div>
  );
};

SetAdvisors.propTypes = {
  setValue: PropTypes.func.isRequired,
};

export default SetAdvisors;
