import { ValueSetterParams } from 'ag-grid-community';
import { IOperationalBudgetsLineItemRequest } from 'bundles/REconcile/actions/operationalBudgetsTypes';
import { IGlRow } from 'bundles/Shared/components/ReportTable/types';
import { useCallback } from 'react';
import { IAutoSavingStatus } from '@/bundles/REconcile/components/operational/OperationalBudget';
import { getUpdatedRowData } from '@/bundles/REconcile/components/operational/editor/helpers/getUpdatedRowData';

export type HandleCellChange = (
  params: ValueSetterParams & {
    columnKey: string;
  },
) => void;

const COMMA_SYMBOL = ',';
const EMPTY_SYMBOL = '';

export function useCellChangeHandler(params: {
  setUpdatedLineItems: React.Dispatch<
    React.SetStateAction<IOperationalBudgetsLineItemRequest[]>
  >;
  setAutoSavingStatus: React.Dispatch<React.SetStateAction<IAutoSavingStatus>>;
}): HandleCellChange {
  const callbackDependencies = Object.values(params);

  const handler = useCallback(
    (({ newValue, node, data, api, columnKey }) => {
      if (!node) return;
      let preparedNewValue = newValue as string | null;

      if (typeof newValue === 'string' && newValue.includes(COMMA_SYMBOL)) {
        preparedNewValue = newValue.replaceAll(COMMA_SYMBOL, EMPTY_SYMBOL);
      }

      const row = data as IGlRow;

      params.setAutoSavingStatus({
        value: 'edited',
      });

      params.setUpdatedLineItems((prev) => {
        const updatedLineItem = prev.find((lineItem) => {
          const columnKeyMatches = lineItem.column_key === columnKey;
          const glIdMatches =
            lineItem.general_ledger_id === row.generalLedger.id;
          const exactMatch = columnKeyMatches && glIdMatches;

          return exactMatch;
        });

        let prevLineItems = [...prev];

        if (updatedLineItem) {
          prevLineItems = prev.filter((lineItem) => {
            const columnKeyMatches =
              lineItem.column_key === updatedLineItem.column_key;
            const glIdMatches =
              lineItem.general_ledger_id === updatedLineItem.general_ledger_id;
            const exactMatch = columnKeyMatches && glIdMatches;

            return !exactMatch;
          });
        }

        return [
          ...prevLineItems,
          {
            column_key: columnKey,
            value: preparedNewValue,
            general_ledger_id: row.generalLedger.id,
          },
        ];
      });

      const updatedRowData = getUpdatedRowData({
        row,
        columnKey,
        newValue: preparedNewValue ?? '',
      });

      node.setData({
        ...row,
        data: updatedRowData,
      });
      api.refreshClientSideRowModel('aggregate');
    }) satisfies HandleCellChange,
    callbackDependencies,
  );

  return handler;
}
