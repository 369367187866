import { Button } from 'stories/Button/Button';
import { cn } from '@/shared/lib/css/cn';
import React from 'react';

const SubmitMessageButton = ({ disabled, feedMessage }) => {
  const buttonText = () => {
    if (feedMessage) return 'Update Post';

    return 'Create Feed Message';
  };

  return (
    <div className="flex flex-col items-center">
      <p className="light-60 label-regular mb-[0.75rem]">
        {disabled
          ? 'Complete all required steps to create a new feed message'
          : 'You can now create a new feed message!'}
      </p>
      <div id="submit-feed-message" className="w-100 submit-button-container">
        <Button
          variant="success"
          type="submit"
          className={cn('w-100', { disabled })}
        >
          {buttonText()}
        </Button>
      </div>
    </div>
  );
};

export default SubmitMessageButton;
