import React, { ComponentProps } from 'react';
import { Popover } from 'stories/Popover/Popover';
import CheckList from 'stories/Checkbox/CheckList';
import GroupByButton from 'bundles/Shared/components/Filters/buttons/groupByButton/GroupByButton';

type Props = Pick<
  ComponentProps<typeof CheckList>,
  'value' | 'items' | 'onChange'
> &
  Pick<ComponentProps<typeof Popover>, 'classes'> & {
    disabled?: boolean;
  };

function GroupByPopover({ items, onChange, value, disabled }: Props) {
  return (
    <Popover
      trigger="click"
      maxWidth={335}
      hiddenArrow
      offset={[value.length > 0 ? -30 : 0, 2]}
      placement="bottom-end"
      disabled={disabled}
      className="p-m"
      template={<CheckList items={items} value={value} onChange={onChange} />}
    >
      {({ triggered }) => (
        <GroupByButton
          clicked={triggered}
          filtered={value.length > 0}
          onClose={() => onChange([])}
          disabled={disabled}
        >
          {value.length > 0 &&
            value.map((item) => item.shorthand ?? item.label).join(', ')}
        </GroupByButton>
      )}
    </Popover>
  );
}

export default GroupByPopover;
