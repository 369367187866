import * as React from 'react';
import { useMemo } from 'react';
import { AgGridReact } from 'ag-grid-react';
import {
  IDevelopmentBudgetData,
  IDevelopmentBudgetDataJccRow,
  IDevelopmentBudgetDataRow,
  isBudgetJccRow,
  isBudgetSovcRow,
} from 'bundles/REconcile/types/BudgetRows';
import useGroupWithConnectorColDef from 'bundles/Shared/components/AgGrid/GroupWithConnector/useGroupWithConnectorColDef';
import { treeDF } from 'lib/treeHelpers';
import { ColDef, RowNode, ValueSetterParams } from 'ag-grid-community';
import {
  getCellRendererSelectorWithHideTotals,
  isNodeTotalFooter,
} from 'lib/ag-grid/utils';
import { cn } from '@/shared/lib/css/cn';
import CellInput from 'bundles/Shared/components/ReportTable/cellInput/CellInput';
import {
  autoGroupColumnFormatter,
  customAggSumFunc,
  getRevisionValueGetter,
  isCellEditable,
  parseData,
  PLACEHOLDER_COLUMN,
} from 'bundles/REconcile/components/development/editor/utils';
import { updateDevelopmentBudgetsLineItems } from 'bundles/REconcile/actions/developmentBudgets';
import { useGridDefaultParams } from 'lib/ag-grid/useGridDefaultParams';
import { AUTO_GROUP_COLUMN_KEY } from 'lib/ag-grid/constants';
import LinkButton from 'stories/LinkButton/LinkButton';
import { CurrencyFormatter } from '@/stories/ValueFormatters/CurrencyFormatter';

interface Props {
  rows: IDevelopmentBudgetData['rows'];
  columns: IDevelopmentBudgetData['columns'];
  budgetId: number;
}

interface IDevelopmentBudgetDataRowExtended extends IDevelopmentBudgetDataRow {
  path: string[];
}

function DevelopmentBudgetEditorTable({ rows, columns, budgetId }: Props) {
  const { onGridReady } = useGridDefaultParams({
    enableExpandOnClick: true,
    autosizeGroupColumnOnExpand: true,
    redrawNodeOnExpand: true,
  });

  const rowData = useMemo<IDevelopmentBudgetDataRowExtended>(() => {
    const data: IDevelopmentBudgetDataRowExtended[] = [];
    treeDF(rows, (r, path) => {
      const newRow: IDevelopmentBudgetDataRowExtended = {
        ...r,
        path: path.map((p) => p.key),
      };
      if ((isBudgetSovcRow(newRow) || isBudgetJccRow(newRow)) && newRow.data) {
        newRow.data = parseData(newRow.data);
      }

      data.push(newRow);
    });

    return data;
  }, [rows]);

  const handleRevisionValueUpdate =
    (columnKey: string) =>
    async ({ node, data: updatedRow, newValue, api }: ValueSetterParams) => {
      let jccRow: IDevelopmentBudgetDataJccRow = updatedRow;
      let jccNode: RowNode = node;
      if (isBudgetSovcRow(updatedRow)) {
        jccRow = node.parent.data;
        jccNode = node.parent;
      }

      const result = await updateDevelopmentBudgetsLineItems(budgetId, {
        column_key: columnKey,
        job_cost_code: {
          id: jccRow.jobCostCode.id,
          value: isBudgetJccRow(updatedRow) ? newValue : jccRow.data[columnKey],
        },
        schedule_of_value_codes: isBudgetJccRow(updatedRow)
          ? null
          : jccRow.children.map((sovcRow) => ({
              id: sovcRow.scheduleOfValueCode.id,
              value:
                updatedRow.scheduleOfValueCode.id ===
                sovcRow.scheduleOfValueCode.id
                  ? newValue
                  : sovcRow.data[columnKey],
            })),
      });

      if (result === undefined) {
        return;
      }

      jccNode.setData({
        ...result,
        data: parseData(result.data),
      });
      jccNode.childrenAfterGroup.forEach((sovcNode) => {
        const newSovcRow = result.children.find(
          (sovc) =>
            sovc.scheduleOfValueCode.id ===
            sovcNode.data.scheduleOfValueCode.id,
        );
        sovcNode.setData({
          ...newSovcRow,
          data: parseData(newSovcRow.data),
        });
      });
      api.refreshClientSideRowModel('aggregate');
    };

  const defaultColDef = useMemo<ColDef>(
    () => ({
      cellClass: ({ node, colDef, data: row }) =>
        cn({
          'cursor-pointer': !node.footer && node.hasChildren(),
          'text-right': colDef.colId !== AUTO_GROUP_COLUMN_KEY,
          'bg-light-10':
            !isNodeTotalFooter(node) &&
            !isCellEditable({ node, colDef, data: row }),
          'light-70 secondary-semibold':
            !isNodeTotalFooter(node) && node.footer,
          'bg-light-80 white  secondary-semibold': isNodeTotalFooter(node),
        }),
      suppressMenu: true,
      resizable: true,
      sortable: false,
    }),
    [],
  );

  const autoGroupColumnDef = useGroupWithConnectorColDef(
    {
      headerClass:
        'bg-dark-60 ag-header-left secondary-semibold font-weight-600',
      headerName: 'Job Cost Codes',
      pinned: true,
      valueFormatter: autoGroupColumnFormatter,
    },
    [],
  );

  const colDefs = useMemo<ColDef[]>(
    () => [
      ...columns.map((c) => ({
        headerName: c.label,
        children: c.children.map(
          (cx) =>
            ({
              headerName: cx.label,
              field: cx.key,
              valueGetter: getRevisionValueGetter(cx.key),

              valueSetter: (params) => {
                handleRevisionValueUpdate(cx.key)(params);
                return false;
              },

              cellRendererSelector: getCellRendererSelectorWithHideTotals(
                ({ node, value }) => {
                  if (value == null) {
                    // todo replace it with button
                    return {
                      component: () => (
                        <LinkButton className="secondary-regular">
                          Set Budget
                        </LinkButton>
                      ),
                    };
                  }
                  return {
                    component: CurrencyFormatter,
                    params: {
                      inheritColor: isNodeTotalFooter(node),
                      classes: {
                        wrapper: 'h-full',
                      },
                    },
                  };
                },
              ),

              editable: isCellEditable,
              cellEditor: CellInput,
              aggFunc: customAggSumFunc,
            }) as ColDef,
        ),
      })),
      {
        colId: PLACEHOLDER_COLUMN,
        flex: 1,
      },
    ],
    [],
  );

  return (
    <div className="h-full ag-grid-30">
      <AgGridReact
        className="ag-theme-alpine dark"
        groupDefaultExpanded={-1}
        suppressContextMenu
        singleClickEdit
        headerHeight={24}
        groupHeaderHeight={20}
        groupIncludeTotalFooter
        groupIncludeFooter
        defaultColDef={defaultColDef}
        onGridReady={onGridReady}
        autoGroupColumnDef={autoGroupColumnDef}
        getDataPath={(data: IDevelopmentBudgetDataRowExtended) => data.path}
        treeData
        rowData={rowData}
        columnDefs={colDefs}
        rowHeight={32}
      />
    </div>
  );
}

export default DevelopmentBudgetEditorTable;
