import { ReportManualVariable } from '@/entities/report/manualVariable';
import { deleteReportManualVariable } from '@/features/report/manualVariable/deleteVariable/lib';
import { useModal } from '@/shared/lib/hooks/useModal';
import { ROUTES_ROOT, generateUrl } from '@/shared/lib/hooks/useNavigation';
import { IconButton } from '@/stories/IconButton/IconButton';
import { navigate } from '@reach/router';

export function DeleteManualVariable({
  manualVariableId,
}: {
  manualVariableId: ReportManualVariable['id'];
}) {
  const { confirm } = useModal();
  const [deleteVar, options] = deleteReportManualVariable();

  const handleDeleteManVar = async () => {
    const res = await confirm();

    if (!res) return;

    deleteVar({ id: manualVariableId });

    navigate(generateUrl(ROUTES_ROOT.settings.report.manualVariables.fullPath));
  };
  return (
    <IconButton
      isLoading={options.isLoading}
      iconName="trash"
      onClick={handleDeleteManVar}
    />
  );
}
