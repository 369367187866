import { Editor } from '@monaco-editor/react';
import { ComponentProps } from 'react';

export const DEFAULT_JSON_CODE_EDITOR_PROPS = {
  language: 'json',
  defaultLanguage: 'json',
  defaultValue: '',
  theme: 'vs-dark',
  width: '100%',
  height: '100%',
  options: {
    minimap: {
      enabled: false,
    },
    fontSize: 14,
    fontWeight: '500',
    tabSize: 2,
    lineNumbers: 'on',
    bracketPairColorization: {
      enabled: true,
      independentColorPoolPerBracketType: true,
    },
    guides: {
      bracketPairs: 'active',
      indentation: false,
    },
    wordWrap: 'on',
    roundedSelection: false,
    scrollBeyondLastLine: false,
    readOnly: false,
    theme: 'vs-dark',
  },
} satisfies ComponentProps<typeof Editor>;
