import { WidgetSettingsListLayout } from '@/bundles/Shared/components/layouts/dashboard/WidgetLayout';
import type { ComparisonDashboardSectionTypes } from 'bundles/Shared/entities/dashboard/model/types/types';
import {
  UNGROUPED_GROUP_HEADER_NAME,
  UNGROUPED_GROUP_KEY,
} from 'bundles/Shared/entities/dashboard/model/slices/shared';
import { financialTableUpdaters } from '@/bundles/Shared/widgets/dashboard/widgets/common/financialTable/config/updaters';
import { FinancialTableWidgetActions } from '@/bundles/Shared/widgets/dashboard/widgets/common/financialTable/ui/financialTableWidgetColumnGroups/model';
import {
  useUpdateWidgetConfig,
  useWidgetConfig,
} from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/config';
import {
  createMapByKey,
  sortByOrder,
} from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/utils';
import {
  TableVizConfig,
  TableVizConfigColumn,
  TableVizConfigColumnGroup,
} from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/table/model';
import { FinancialTableSingleDateWidgetSection } from '@/bundles/Shared/widgets/dashboard/widgets/financialTableSingeDate/model';
import { FinancialTableSinglePeriodWidgetSection } from '@/bundles/Shared/widgets/dashboard/widgets/financialTableSingePeriod/model';
import {
  GROUPS_DROPPABLE_ID,
  buildDraggableId,
  getIdFromDraggableId,
} from '@/bundles/Shared/widgets/dashboard/widgets/kpiTable';
import { cn } from '@/shared/lib/css/cn';
import { Dropdown } from '@/stories/Dropdown/Dropdown';
import { Icon } from '@/stories/Icon/Icon';
import { IconButton } from '@/stories/IconButton/IconButton';
import {
  Draggable,
  DraggableProvided,
  OnDragEndResponder,
} from '@hello-pangea/dnd';
import styles from 'bundles/Shared/components/layouts/dashboard/WidgetLayout.module.scss';
import { groupBy, orderBy } from 'lodash-es';
import React, { useCallback } from 'react';
import { DEFAULT_GROUP_TEXT_CLASS } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/table/lib';

export const getFinancialTableWidgetDefaultUngroupedGroup = (
  args: Partial<
    TableVizConfigColumnGroup & {
      children: TableVizConfigColumn[];
    }
  >,
): TableVizConfigColumnGroup & {
  children: TableVizConfigColumn[];
} => ({
  group_id: UNGROUPED_GROUP_KEY,
  header_name: UNGROUPED_GROUP_HEADER_NAME,
  order: 0,
  background: 'transparent',
  border_color: 'transparent',
  icon: null,
  children: [],
  ...args,
});

export const useFinancialTableVizConfig = <
  Section extends
    | FinancialTableSingleDateWidgetSection
    | FinancialTableSinglePeriodWidgetSection,
>({
  columns,
  vizConfig,
}: {
  columns: Section['widgetConfig']['columns'];
  vizConfig: TableVizConfig;
}) => {
  type VizConfigColumnGroups = {
    columnGroups: (TableVizConfigColumnGroup & {
      children: (Section['widgetConfig']['columns'][number] &
        TableVizConfigColumn)[];
    })[];
    groupedByGroupId: Record<string, TableVizConfigColumn[]>;
  };

  return React.useMemo<VizConfigColumnGroups>(() => {
    const vizConfigColumnsMap = createMapByKey(vizConfig?.columns ?? []);

    const dataAndVizConfigColumns = columns.map((col) => {
      if (!vizConfigColumnsMap.has(String(col.key))) return col;
      return {
        ...col,
        ...vizConfigColumnsMap.get(String(col.key))!,
      };
    });

    const groupedColumns = groupBy(
      dataAndVizConfigColumns ?? [],
      (c) => c.group_id ?? UNGROUPED_GROUP_KEY,
    );

    const columnGroups =
      orderBy(vizConfig?.column_groups ?? [], 'order').map((g) => {
        const children = groupedColumns[g.group_id] ?? [];
        return {
          ...g,
          children,
        };
      }) ?? [];

    return {
      columnGroups,
      groupedByGroupId: groupedColumns,
    };
  }, [vizConfig, columns]);
};

export const useDragEndResponder = () => {
  const { widget } = useWidgetConfig<ComparisonDashboardSectionTypes>();
  const [updateConfig] = useUpdateWidgetConfig(widget.widgetType);

  return React.useCallback<OnDragEndResponder>(
    ({ source, destination, draggableId }) => {
      if (!destination) return;
      const config =
        source.droppableId === GROUPS_DROPPABLE_ID
          ? financialTableUpdaters.moveGroup(
              {
                fromIndex: source.index,
                toIndex: destination.index,
              },
              widget.widgetConfig,
            )
          : financialTableUpdaters.moveColumn(
              {
                fromIndex: source.index,
                toIndex: destination.index,
                sourceGroupId: source.droppableId,
                targetGroupId: destination.droppableId,
                columnId: getIdFromDraggableId(draggableId),
              },
              widget.widgetConfig,
            );
      updateConfig({
        config,
      });
    },
    [widget],
  );
};

export const useRenderGroupCallback = <
  Section extends
    | FinancialTableSingleDateWidgetSection
    | FinancialTableSinglePeriodWidgetSection,
>(
  actions: FinancialTableWidgetActions,
) => {
  type VizConfig = ReturnType<typeof useFinancialTableVizConfig<Section>>;

  const getRenderGroupContent = useCallback(
    (group: VizConfig['columnGroups'][number]) => {
      const groupId = group.group_id.toString();
      const isUngroupedGroup = groupId === UNGROUPED_GROUP_KEY;
      const vizConfigGroupId = isUngroupedGroup ? undefined : groupId;
      const hasNoChildren = group.children.length === 0;
      const renderGroupActions = () => {
        return (
          <WidgetSettingsListLayout.ListItem.ActionsPanel>
            {!isUngroupedGroup && (
              <Dropdown
                className="sre-dropdown-v2"
                items={
                  <>
                    <Dropdown.Item
                      iconName="edit"
                      onClick={() => {
                        actions.group.onEdit({
                          groupId,
                          headerName: group.header_name,
                        });
                      }}
                    >
                      Edit
                    </Dropdown.Item>
                    <Dropdown.Item
                      iconName="copy"
                      onClick={() => {
                        actions.group.onClone({ groupId });
                      }}
                    >
                      Clone
                    </Dropdown.Item>
                    {!isUngroupedGroup && (
                      <Dropdown.Item
                        iconName="trash"
                        onClick={() => {
                          actions.group.onRemove({
                            groupId,
                          });
                        }}
                      >
                        Remove
                      </Dropdown.Item>
                    )}
                  </>
                }
              >
                <IconButton iconName="more" />
              </Dropdown>
            )}
            <IconButton
              onClick={() => {
                actions.column.onAdd({
                  groupId: vizConfigGroupId,
                });
              }}
              iconName="addSmall"
            />
          </WidgetSettingsListLayout.ListItem.ActionsPanel>
        );
      };
      return (provided?: DraggableProvided) => (
        <WidgetSettingsListLayout.SortableList droppableId={groupId}>
          <WidgetSettingsListLayout.ListItem
            checked={hasNoChildren ? undefined : !group?.hidden}
            onChange={
              hasNoChildren
                ? undefined
                : () => {
                    actions.group.onHide({ groupId });
                  }
            }
          >
            {provided && (
              <Icon
                className={styles.sortableItem__dragHandle}
                iconName="move"
                {...provided.dragHandleProps}
              />
            )}
            <span className={cn('inline-semibold', DEFAULT_GROUP_TEXT_CLASS)}>
              {group.header_name}
            </span>
            <div className="grow" />
            {renderGroupActions()}
          </WidgetSettingsListLayout.ListItem>
          {group.children
            .filter((c) => c != null)
            .toSorted(sortByOrder)
            .map((column, colIndex) => (
              <WidgetSettingsListLayout.SortableListItem
                draggableId={buildDraggableId(column.key.toString(), 'column')}
                index={colIndex}
                listIndex={colIndex}
                key={column.key}
                checked={!column.hidden}
                onChange={() => {
                  actions.column.onHide({
                    columnId: column.col_id,
                  });
                }}
              >
                <WidgetSettingsListLayout.ListItem.Text>
                  {column.label}
                </WidgetSettingsListLayout.ListItem.Text>
                <div className="grow" />
                <WidgetSettingsListLayout.ListItem.ActionsPanel>
                  <IconButton
                    iconName="edit"
                    onClick={() => {
                      actions.column.onEdit({
                        columnConfig: column,
                        columnSettings: column,
                        groupId: vizConfigGroupId,
                      });
                    }}
                  />
                  <IconButton
                    iconName="copy"
                    onClick={() => {
                      actions.column.onClone({
                        columnKey: column.key.toString(),
                        groupId: vizConfigGroupId,
                      });
                    }}
                  />
                  <IconButton
                    iconName="trash"
                    onClick={() => {
                      actions.column.onRemove({
                        columnKey: column.key.toString(),
                        groupId: vizConfigGroupId,
                      });
                    }}
                  />
                </WidgetSettingsListLayout.ListItem.ActionsPanel>
              </WidgetSettingsListLayout.SortableListItem>
            ))}
        </WidgetSettingsListLayout.SortableList>
      );
    },
    [actions],
  );

  return useCallback(
    (group: VizConfig['columnGroups'][number], index: number) => {
      const renderGroupContent = getRenderGroupContent(group);
      const groupId = group.group_id.toString();
      const isUngrouped = groupId === UNGROUPED_GROUP_KEY;

      return isUngrouped ? (
        renderGroupContent()
      ) : (
        <Draggable
          key={groupId}
          draggableId={buildDraggableId(groupId, 'group')}
          index={index}
        >
          {(provided) => (
            <div ref={provided.innerRef} {...provided.draggableProps}>
              {renderGroupContent(provided)}
            </div>
          )}
        </Draggable>
      );
    },
    [getRenderGroupContent],
  );
};
