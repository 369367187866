import { Link, useParams } from '@reach/router';
import React, { useMemo } from 'react';
import { MultilineAlert } from 'stories/MultilineAlert/MultilineAlert';
import CEStatusMessageTitle from '@/bundles/Construction/components/ChangeOrderEvent/MessageBox/components/CEStatusMessageTitle';
import { useNavigation } from '@/shared/lib/hooks/useNavigation';
import { useAppSelector } from '@/shared/lib/hooks/redux';
import { selectChangeEvent } from 'bundles/Construction/reducers/ReconcileChangeEventSlice';

const ChangeEventStatusMessage = () => {
  const changeEvent = useAppSelector(selectChangeEvent);
  const params = useParams();
  const status = changeEvent?.status;

  const { getUrl } = useNavigation();
  const dataAlert = useMemo(() => {
    if (
      changeEvent?.startedApprovalAt &&
      (status === 'PENDING-NOT PROCEEDING' ||
        status === 'PENDING-PROCEEDING') &&
      !changeEvent?.availableActions?.includes('approve')
    ) {
      return {
        message: (
          <div>
            <CEStatusMessageTitle>
              You made a successful request for approval
            </CEStatusMessageTitle>
            <div>We'll get back to you once Approver decides.</div>
          </div>
        ),
        status: 'info',
        icon: 'clock',
        show: true,
      };
    }
    if (status === 'APPROVED') {
      return {
        message: (
          <CEStatusMessageTitle>COR has been Approved</CEStatusMessageTitle>
        ),
        status: 'success',
        icon: 'acceptAlt',
        show: true,
      };
    }
    if (status === 'COMPLETED') {
      return {
        message: (
          <CEStatusMessageTitle>
            <span className="mr-xs">This COR included into</span>
            <Link
              to={getUrl('RECONCILE_DEVELOPMENT_LEGAL_ENTITY_CHANGE_ORDER', {
                changeOrderId: changeEvent.changeOrder.id,
                legalEntityCode: params.legalEntityCode,
              })}
              className="second-link-color"
            >
              CO #{changeEvent.changeOrder.number}
            </Link>
          </CEStatusMessageTitle>
        ),
        status: 'success',
        icon: 'acceptAlt',
        show: true,
      };
    }
    if (status === 'REJECTED') {
      return {
        message: (
          <CEStatusMessageTitle>
            This COR has been Rejected
          </CEStatusMessageTitle>
        ),
        status: 'danger',
        icon: 'nodeOpen',
        show: true,
      };
    }
    return {
      message: '',
      status: 'success',
      icon: 'info',
      show: false,
    };
  }, [changeEvent]);
  return (
    <>
      {dataAlert.show && (
        <div className="mb-m">
          <MultilineAlert
            message={dataAlert?.message}
            status={dataAlert?.status}
            flexibleWidth
            icon={dataAlert?.icon}
          />
        </div>
      )}
    </>
  );
};

export default ChangeEventStatusMessage;
