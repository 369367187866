import { CheckboxCellRenderer } from '@/bundles/Settings/components/REport/GeneralLedgers/Table/CheckboxCellRenderer';
import { Checkbox } from '@/stories/Checkbox/Checkbox';
import { ColDef } from 'ag-grid-community';
import { ComponentProps } from 'react';

export function getCheckboxAggridTableColumn<T>({
  onClickAll,
  onClick,
  checked,
  allChecked,
}: {
  onClickAll: VoidFunction;
  allChecked: boolean;
  onClick: (
    item: T,
    options?: {
      withShift?: boolean;
    },
  ) => void;
  checked: T[];
}): ColDef {
  return {
    flex: 0,
    width: 36,
    cellClass: 'ag-checkbox-column',
    headerClass: 'ag-checkbox-header align-self-center !pr-0',
    sortable: false,
    headerComponent: Checkbox,
    headerComponentParams: {
      indeterminate: checked.length > 0,
      checked: allChecked,
      onClick: onClickAll,
    } satisfies ComponentProps<typeof Checkbox>,
    cellRenderer: CheckboxCellRenderer,
    cellRendererParams: {
      labelClassName: 'align-self-center',
      selectedRows: checked,
      handleChange: onClick,
    },
  };
}
