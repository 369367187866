import React, { useState } from 'react';
import EmailListSwitches from '@/bundles/Settings/components/EmailSettings/EmailCampaign/CampaignEmailCard/EmailListSwitches';
import CampaignCardEmailList from '@/bundles/Settings/components/EmailSettings/EmailCampaign/CampaignEmailCard/CampaignCardEmailList';

const OpenedEmailsList = ({ openedEmails, totalRecipientsCount }) => {
  const switches = ['All'];

  const openedUnsubscribedMessages = openedEmails.filter((em) => {
    return em.events.some((e) => e.kind === 'unsubscribed');
  });
  const openedComplainedMessages = openedEmails.filter((em) => {
    return em.events.some((e) => e.kind === 'complained');
  });

  const tabs = {
    Unsubscribed: openedUnsubscribedMessages,
    Complained: openedComplainedMessages,
    Opened: openedEmails,
  };

  if (openedUnsubscribedMessages.length > 0) switches.push('Unsubscribed');
  if (openedComplainedMessages.length > 0) switches.push('Complained');

  const [selectedSwitch, setSelectedSwitch] = useState(switches[0]);

  return (
    <div>
      {switches.length > 1 && (
        <EmailListSwitches
          switchNames={switches}
          isActive={(switchName) => selectedSwitch === switchName}
          onClick={(switchName) => setSelectedSwitch(switchName)}
        />
      )}
      {selectedSwitch === 'All' ? (
        <div>
          {Object.entries(tabs).map(([name, messages]) => (
            <CampaignCardEmailList
              emailMessages={messages}
              title={name}
              totalRecipientsCount={totalRecipientsCount}
            />
          ))}
        </div>
      ) : (
        <CampaignCardEmailList
          emailMessages={tabs[selectedSwitch]}
          title={selectedSwitch}
          totalRecipientsCount={totalRecipientsCount}
        />
      )}
    </div>
  );
};

export default OpenedEmailsList;
