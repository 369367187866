import React, { useState } from 'react';
import { useParams } from '@reach/router';
import { useGetInvoiceQuery } from 'bundles/Construction/api/invoices';
import { CurrencyFormatter } from 'stories/ValueFormatters/CurrencyFormatter';
import { IconButton } from 'stories/IconButton/IconButton';
import pluralize from 'pluralize';
import { sum } from 'lodash-es';
import { cn } from '@/shared/lib/css/cn';
import styles from 'bundles/DrawPackage/InvoicesFlow/Invoices/invoice/Invoice.module.scss';
import LineItemSimpleCard from '@/bundles/DrawPackage/InvoicesFlow/Invoices/approve/components/LineItemSimpleCard';

const LineItemsList = () => {
  const [open, setOpen] = useState(false);
  const params = useParams();
  const { data: invoice } = useGetInvoiceQuery({
    legalEntityCode: params?.legalEntityCode,
    invoiceId: params.invoiceId,
  });

  return (
    <div className="relative">
      <div className="flex justify-between rounded-lg border border-light-10 bg-light p-4">
        <div className="inline-regular text-light-90">{`${
          invoice?.lineItems.length
        } Line ${pluralize('item', invoice?.lineItems.length)}`}</div>
        <div className="flex items-center gap-4">
          <CurrencyFormatter
            value={sum(invoice?.lineItems.map((item) => Number(item.amount)))}
            classes={{
              value: 'inline-semibold text-dark-60',
              startSymbol: 'inline-semibold text-light-60',
              fractions: 'inline-semibold text-light-60',
            }}
          />
          <IconButton
            onClick={() => setOpen(!open)}
            variant="white"
            iconName={open ? 'arrowTop' : 'arrowBottom'}
          />
        </div>
      </div>
      {open && (
        <div className="absolute left-0 right-0 top-[102%] z-10 rounded-lg border  border-light-10 bg-white">
          <div className={cn(styles.tableBody, styles.listLineItems)}>
            {invoice?.lineItems.map((lineItem, idx) => (
              <LineItemSimpleCard key={`lineItem${idx}`} {...lineItem} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default LineItemsList;
