import React, { ComponentProps } from 'react';
import { cn } from '@/shared/lib/css/cn';
import Tag from '@/stories/Tags/Tag/Tag';

type Props = Omit<ComponentProps<typeof Tag>, 'startIcon' | 'endIcon'>;

export const ClassicTag = ({ className, ...props }: Props) => (
  <Tag className={cn(className)} {...props} />
);

export default ClassicTag;
