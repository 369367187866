import React, { useState } from 'react';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import { Button } from 'stories/Button/Button';
import { Modal } from 'stories/Modals/Modal/Modal';
import FormComponent from 'bundles/Shared/components/GroupForm/FormComponent/FormComponent';
import { FieldType } from 'bundles/Shared/components/GroupForm/types/types';

interface Props extends DialogProps<number | null> {
  field: string;
  fieldLabel: string;
  value: number;
}

function EditFieldModal({
  value: initialValue,
  field,
  fieldLabel,
  onClose,
  onSubmit,
}: Props) {
  const [formData, setFormData] = useState({ [field]: initialValue });

  return (
    <Modal
      header={`Edit ${fieldLabel}`}
      toggle={onClose}
      actions={
        <Button
          fluid
          variant="success"
          onClick={() =>
            onSubmit?.(formData[field] != null ? Number(formData[field]) : null)
          }
        >
          Submit
        </Button>
      }
    >
      <FormComponent
        viewMode
        styles={{
          wrapper__item: 'mb-0',
        }}
        formItems={[
          {
            fieldName: field,
            title: 'Value',
            type: FieldType.INPUT_NUMBER,
            placeholder: '0',
            leftIcon: 'symbol-usd',
            styles: {
              wrapper: 'form-item_white',
              size: 'm',
              nomb: true,
            },
            thousandSeparator: true,
          },
        ]}
        formData={formData}
        setFormData={setFormData}
      />
    </Modal>
  );
}

export default EditFieldModal;
