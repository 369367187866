import React from 'react';
import { LinkButton } from 'stories/LinkButton/LinkButton';
import { hideAllNotifications } from 'bundles//Notifications/actions';
import NotificationsList from 'bundles/Notifications/components/NotificationsList';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';

interface Props {
  onAction?: () => void;
}

const MAX_NOTIFICATIONS_COUNT = 5;

const NotificationsCard = ({ onAction }: Props) => {
  const dispatch = useAppDispatch();
  const notifications = useAppSelector((state) => state.notifications.unread);

  if (!notifications) return null;

  const markAllReadHandler = () => {
    dispatch(hideAllNotifications());
    onAction?.();
  };

  return (
    <div className="w-full flex flex-col mb-n05rem">
      <div className="flex justify-between border-bottom p-s border-light-30">
        <span className="dark-60 font-weight-500 pl-s">Notifications</span>
        {notifications.length > 0 && (
          <LinkButton
            className="text-transform-initial"
            onClick={markAllReadHandler}
          >
            Mark all as read
          </LinkButton>
        )}
      </div>
      <div
        onClick={() => {
          onAction?.();
        }}
        className="flex flex-col px-m"
      >
        <NotificationsList
          notifications={notifications.slice(0, MAX_NOTIFICATIONS_COUNT)}
          viewAll
        />
      </div>
    </div>
  );
};

export default NotificationsCard;
