import { createAction } from '@reduxjs/toolkit';
import type {
  ReportDashboardFilterSet,
  ReportEagleEyeDashboard,
} from 'bundles/Shared/entities/dashboard/model/types/types';

export const setDashboardFilterSet = createAction(
  'reportDashboardSettings/setDashboardFilterSet',
  (payload: {
    filterSet: ReportDashboardFilterSet | null;
    dashboard: ReportEagleEyeDashboard;
  }) => ({
    payload,
  }),
);
