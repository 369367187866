import {
  DialogProps,
  ModalContext,
  TConfirmFunc,
  TConfirmWithReasonFunc,
  TOpenDialog,
} from '@/shared/lib/hooks/useModal';
import React, {
  ComponentProps,
  useCallback,
  useMemo,
  useState,
  type PropsWithChildren,
} from 'react';
import ConfirmationModal from 'stories/Modals/ConfirmationModal/ConfirmationModal';

type ModalEntry = [React.ComponentType<DialogProps>, DialogProps];

export type ModalConfigOptions = {
  topLevelRender: boolean;
};

export type ModalConfigState = {
  modalList: ModalEntry[];
  config: ModalConfigOptions;
};

const DEFAULT_MODAL_CONFIG = {
  topLevelRender: true,
} as const satisfies ModalConfigState['config'];

function ModalProvider({ children }: PropsWithChildren) {
  const [modalConfig, setModalConfig] = useState<ModalConfigState>({
    modalList: [],
    config: DEFAULT_MODAL_CONFIG,
  });

  const removeModalFromConfig = useCallback((modalKey: string) => {
    setModalConfig((prev) => ({
      ...prev,
      modalList: prev.modalList.filter(
        ([_, modalProps]) => modalProps.key !== modalKey,
      ),
    }));
  }, []);

  const addModalToConfig = useCallback(
    (modal: ModalEntry, config?: ModalConfigOptions) => {
      setModalConfig((prev) => ({
        config: {
          ...prev.config,
          ...config,
        },
        modalList: [...prev.modalList, modal],
      }));
    },
    [],
  );

  const openModal = useCallback<TOpenDialog>((component, props, options) => {
    const modalKey = Date().toString();

    return new Promise((resolve) => {
      const modal: ModalEntry = [
        component,
        {
          ...props,
          key: modalKey,
          onClose: () => {
            removeModalFromConfig(modalKey);
            resolve();
          },
          onSubmit: (res) => {
            removeModalFromConfig(modalKey);
            resolve(res);
          },
        },
      ];
      addModalToConfig(modal, options ?? DEFAULT_MODAL_CONFIG);
    });
  }, []);

  const resetAllModals = useCallback(() => {
    setModalConfig((prev) => ({
      ...prev,
      modalList: [],
    }));
  }, []);

  const confirm = useCallback<TConfirmFunc>((props) => {
    const modalKey = Date().toString();

    return new Promise((resolve) => {
      const confirmation = [
        ConfirmationModal,
        {
          key: modalKey,
          ...props,
          handleResolve: (res: boolean) => {
            removeModalFromConfig(modalKey);
            resolve(res);
          },
        } as unknown as ComponentProps<typeof ConfirmationModal>,
      ];
      addModalToConfig(confirmation);
    });
  }, []);

  const confirmWithReason = useCallback<TConfirmWithReasonFunc>((props) => {
    const modalKey = Date().toString();

    return new Promise((resolve) => {
      const confirmation = [
        ConfirmationModal,
        {
          key: modalKey,
          ...props,
          reason: true,
          handleResolve: (res: boolean, reason?: string) => {
            removeModalFromConfig(modalKey);
            resolve({ result: res, reason });
          },
        } as unknown as ComponentProps<typeof ConfirmationModal>,
      ];
      addModalToConfig(confirmation);
    });
  }, []);

  const value = useMemo(
    () => ({
      openModal,
      confirm,
      confirmWithReason,
      resetAllModals,
      modalList: modalConfig.modalList.map(([Component, props], idx) => (
        <Component {...props} key={`${Component.name}${idx}`} />
      )),
    }),
    [modalConfig],
  );

  return (
    <ModalContext.Provider value={value}>
      {children}
      {modalConfig.config.topLevelRender && value.modalList}
    </ModalContext.Provider>
  );
}

export default ModalProvider;
