import React, { useEffect, useState } from 'react';
import { Button } from 'stories/Button/Button';
import { SearchInput } from 'stories/FormControls/Inputs/SearchInput/SearchInput';

import { TSort } from 'types/Sort';
import {
  IFetchReportTableSegmentsParams,
  plainDestroyReportTableSegment,
  plainFetchSegments,
} from 'bundles/Settings/actions/report_table_segments';
import TablePagination from 'bundles/Shared/components/Table/pagination/TablePagination';
import Table from 'bundles/Shared/components/Table/Table';

import { buildReportTableSegmentColumns } from '@/bundles/Settings/components/REport/TableSegment/reportTableSegmentColumns';
import SegmentFormModal from '@/bundles/Settings/components/REport/TableSegment/SegmentFormModal';
import ReportSettingsScreenLayout from 'bundles/Settings/shared/ReportSettingsScreenLayout';
import { ReportSegment } from 'bundles/Shared/entities/segment/model';

const ReportTableSegments = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [segments, setSegments] = useState<ReportSegment[]>([]);
  const [isSegmentModalOpen, setIsSegmentModalOpen] = useState(false);
  const [totalSize, setTotalSize] = useState(0);
  const [pageParams, setPageParams] = useState({
    page: 1,
    perPage: 10,
    query: '',
    sortOrder: TSort.DESC,
    sortField: 'state',
    // state: 'draft' || 'public',
  });

  const fetchSegments = async () => {
    setIsLoading(true);
    const preparedParams: IFetchReportTableSegmentsParams = {
      query: pageParams.query,
      page: pageParams.page,
      per_page: pageParams.perPage,
      // state: 'draft',
      sort: {
        field: pageParams.sortField,
        order: pageParams.sortOrder,
      },
    };
    const data = await plainFetchSegments(preparedParams);
    if (data) {
      setSegments(data.items);
      setTotalSize(data.meta.totalSize);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchSegments();
  }, [pageParams]);

  const destroySegmentV2 = async (id) => {
    setIsLoading(true);
    await plainDestroyReportTableSegment(id);
    fetchSegments();
  };

  const handleAfterTableSegmentUpdate = () => {
    fetchSegments();
  };

  const setSortingCreatedAt = () => {
    setPageParams((prevParams) => ({
      ...prevParams,
      page: 1,
      sortField: 'created_at',
      sortOrder: TSort.DESC,
    }));
  };

  const resolveAfterCreateSegment = () => {
    setIsSegmentModalOpen(false);
    setSortingCreatedAt();
  };

  const handleCreateSegmentButtonClick = () => {
    setIsSegmentModalOpen(true);
  };

  const setQuery = (query: string) => {
    setPageParams({ ...pageParams, query });
  };

  const setPagination = (perPage: number, page: number) => {
    setPageParams({ ...pageParams, page, perPage });
  };

  const setCurrentPage = (page: number, perPage: number) => {
    setPageParams({
      ...pageParams,
      page,
      perPage: perPage ?? pageParams.perPage,
    });
  };
  return (
    <ReportSettingsScreenLayout.Content>
      <div className="flex items-center justify-between">
        <div>
          <TablePagination
            loading={isLoading}
            currentPage={pageParams.page}
            setCurrentPage={setCurrentPage}
            sizePerPage={pageParams.perPage}
            totalSize={totalSize}
            onSizePerPageChange={setPagination}
          />
        </div>
        <div className="w-220p ml-auto mr-m">
          <SearchInput
            placeholder="Search"
            value={pageParams.query}
            size="s"
            resetValue={() => setQuery('')}
            onChange={({ target }) => setQuery(target.value)}
          />
        </div>
        <Button
          className="align-right"
          onClick={handleCreateSegmentButtonClick}
          variant="primary"
          size="s"
        >
          Create New Segment
        </Button>
        {isSegmentModalOpen && (
          <SegmentFormModal
            onClose={() => {
              setIsSegmentModalOpen(false);
            }}
            onSubmit={resolveAfterCreateSegment}
          />
        )}
      </div>
      <Table
        settings={pageParams}
        setSettings={setPageParams}
        items={segments}
        loading={isLoading}
        columns={buildReportTableSegmentColumns({
          destroyReportTableSegment: destroySegmentV2,
          editReportTableSegment: handleAfterTableSegmentUpdate,
          editAccessMembers: handleAfterTableSegmentUpdate,
        })}
      />
    </ReportSettingsScreenLayout.Content>
  );
};

export default ReportTableSegments;
