import type { FilterOption } from '@/entities/report/reportBuilder/config';
import { ObjectFilterOptionWithCounters } from '@/entities/report/reportBuilder/ui/ObjectFilterOptionWithCounters';
import { Select } from '@/stories/FormControls/Select/Select';

export const ReportBuilderTemplateEagleEyeDashboardDropdown = ({
  options,
  selected,
  onChange,
  disabled,
}: {
  options: FilterOption[];
  selected: FilterOption | null;
  onChange: (id: FilterOption['value']) => void;
  onReset: () => void;
  disabled?: boolean;
}) => (
  <Select
    disabled={disabled}
    options={options}
    selected={selected}
    onSelectedChange={(o) => {
      if (o == null || Array.isArray(o)) return;
      onChange(o.value);
    }}
    getSelectOptionProps={(o) => ({
      label: <ObjectFilterOptionWithCounters {...o} />,
    })}
    inputProps={{
      hasReset: false,
    }}
  />
);
