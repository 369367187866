import { capitalize } from 'lodash-es';
import React from 'react';
import * as Types from '@/stories/FlexibleFilterByPeriods/types';
import * as Util from '@/stories/FlexibleFilterByPeriods/utils';
import dayjs from 'dayjs';

interface Props {
  periodItems: Types.IPeriodItem[];
  disableOnlyLabel?: boolean;
  fromToMode?: boolean;
}

export const GrayColorSpan = ({ text = '' }) => (
  <span className="text-light-60">{text}</span>
);

export const FromSpan = ({ idx = 0 }) => (
  <GrayColorSpan text={idx === 0 ? capitalize('from') : 'from'} />
);

export const FromToLabel = ({
  from,
  to,
}: {
  from: string;
  to: string | undefined;
}) => {
  const toPeriod = to;
  const isSameYear = dayjs(from).isSame(toPeriod, 'year');
  const isSameMonthOfSameYear =
    dayjs(from).isSame(toPeriod, 'month') && isSameYear;

  return (
    <div>
      {isSameMonthOfSameYear ? (
        Util.preparePeriodForMonthlyForLabel(from)
      ) : (
        <>
          <GrayColorSpan text="From " />
          {Util.preparePeriodForMonthlyForLabel(from)}
          <GrayColorSpan text=" to " />
          {toPeriod && Util.preparePeriodForMonthlyForLabel(toPeriod)}
        </>
      )}
    </div>
  );
};

export function Monthly({ periodItems, disableOnlyLabel, fromToMode }: Props) {
  const arrOfPeriodSequences =
    Util.createArrOfSequencesForMonthlyPeriodType(periodItems);

  if (arrOfPeriodSequences.length > 1 && fromToMode === false) {
    return <>Custom Period</>;
  }

  const [[fromPeriod]] = arrOfPeriodSequences;

  if (arrOfPeriodSequences[0].length === 1 && fromToMode === false) {
    return (
      <div>
        {!disableOnlyLabel && <GrayColorSpan text="Only " />}
        {Util.preparePeriodForMonthlyForLabel(fromPeriod)}
      </div>
    );
  }

  const toPeriod = fromToMode
    ? arrOfPeriodSequences.at(-1)?.at(-1)
    : arrOfPeriodSequences[0].at(-1);

  return <FromToLabel from={fromPeriod} to={toPeriod} />;
}
