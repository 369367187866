import React, { useState } from 'react';
import { stringify } from 'qs';
import http from 'lib/http';
import { Icon } from 'stories/Icon/Icon';
import EmailMessageSnapshotModal from '@/bundles/UserManagement/components/EmailMessageSnapshotModal';

interface Props {
  userId: number;
  feedMessageId: number;
  emailBody: string;
  subject: string;
  selectedDocuments: any[];
  selectedMedia: any[];
}

const EmailPreviewCell = ({
  userId,
  feedMessageId,
  emailBody,
  subject,
  selectedDocuments,
  selectedMedia,
}: Props) => {
  const [previewOpened, setPreviewOpened] = useState(false);

  const fetchFeedMessagePreview = () => {
    const payload = {
      feed_message_id: feedMessageId,
      text: emailBody,
      user_id: userId,
      subject,
      attached_document_ids: selectedDocuments.map(({ id }) => id),
      attached_media_ids: selectedMedia.map(({ id }) => id),
    };

    return http.post('/feed_message_previews', payload);
  };

  return (
    <>
      <div
        role="link"
        className="blue-dark-2 flex items-center cursor-pointer"
        onClick={() => setPreviewOpened(true)}
      >
        <Icon iconName="email" className="light-60 mr-xs" />
        <span className="font-weight-400 inline-regular label-regular">
          Preview
        </span>
      </div>
      {previewOpened && (
        <EmailMessageSnapshotModal
          fetchSnapshot={fetchFeedMessagePreview}
          subject={subject}
          onClose={() => setPreviewOpened(false)}
        />
      )}
    </>
  );
};

export default EmailPreviewCell;
