import React from 'react';

export const HeaderInfo = ({
  infoName,
  value,
}: {
  infoName: React.ReactNode;
  value: React.ReactNode;
}) => (
  <div className="flex flex-col gap-1.5">
    <p className="label-regular uppercase text-light-60">{infoName}</p>
    <p className="inline-regular text-light-90">{value}</p>
  </div>
);
