import { useEffect } from 'react';
import { useAppDispatch } from '@/shared/lib/hooks/redux';
import { resetFormulasState } from 'bundles/Shared/entities/formula';
import { useWorkspaceContext } from '@/bundles/Shared/widgets/formula/panel/ui/FormulasAndVariablesWorkspace';

export const useResetFormulasSettingsOnUnmount = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    return () => {
      dispatch(resetFormulasState());
    };
  }, []);
};

/**
 * Bottom panel is rendered in a portal, so we need to manually close it
 * when the component that renders it unmounts to prevent orphaned portals.
 * This hook ensures cleanup by closing the bottom panel on component unmount.
 */

export const useCloseBottomPanelOnUnmount = () => {
  const { closeBottomPanel } = useWorkspaceContext();

  useEffect(() => {
    return () => {
      closeBottomPanel();
    };
  }, []);
};
