import React from 'react';
import Table from '@/bundles/Shared/components/Table/Table';
import { ISortSettings } from '@/bundles/Shared/components/Table/types';
import { AccountingTransaction } from '@/entities/accounting/transaction/model';
import { useTransactionTableColumns } from '@/widgets/accounting/transaction/detailsModal/ui/useTransactionTableColumns';

interface Props {
  settings: ISortSettings;
  isLoading: boolean;
  setSettings: (settings: ISortSettings) => void;
  transactions: AccountingTransaction[];
}

const TransactionsTable = ({
  transactions,
  isLoading,
  settings,
  setSettings,
}: Props) => {
  const columns = useTransactionTableColumns();

  return (
    <Table
      loading={isLoading}
      classes={{
        table: 'fixed-layout',
      }}
      onFilterModelChange={(filterModel: TFilterModel) =>
        setSettings({
          ...settings,
          transactionType: filterModel.transaction_value,
        })
      }
      settings={settings}
      setSettings={setSettings}
      items={transactions}
      columns={columns}
    />
  );
};
export default TransactionsTable;
