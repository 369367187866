import React, { FC } from 'react';
import { Icon } from 'stories/Icon/Icon';
import { IconsId } from 'types/sre-icons';
import styles from '@/bundles/REconcile/components/development/requisitions/headers/CountBadge/CountBadge.module.scss';

interface ICountBadgeProps {
  count: string;
  iconName: IconsId;
  onClick: () => void;
}

const CountBadge: FC<ICountBadgeProps> = ({ count, iconName, onClick }) => (
  <div onClick={onClick} className={styles.wrapper}>
    <Icon iconName={iconName} />
    <div className={styles.title}>{count}</div>
  </div>
);

export default CountBadge;
