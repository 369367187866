import React from 'react';
import { cn } from '@/shared/lib/css/cn';
import styles from '@/stories/Label/Label.module.scss';
import { CssVar } from '@/shared/config/cssVar';

interface Props {
  children: React.ReactNode;
  color: string;
  size?: 's' | 'm';
  backgroundColor?: string;
  outline?: boolean;
  className?: string;
}

export const Label = ({
  children,
  color,
  size = 'm',
  backgroundColor = CssVar.white,
  outline = false,
  className,
}: Props) => (
  <div
    className={cn(styles.container, 'd-inline-flex items-center', className, {
      [styles.containerOutline]: outline,
      [styles.containerSmall]: size === 's',
    })}
    style={{
      borderColor: color,
      color,
      backgroundColor: outline ? backgroundColor : 'transparent',
    }}
  >
    {!outline && (
      <div className={styles.dot} style={{ backgroundColor: color }} />
    )}
    {children}
  </div>
);

export default Label;
