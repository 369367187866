import { SavedSvg } from '@/bundles/REconcile/components/operational/dumbJSX';
import { Icon } from '@/stories/Icon/Icon';

export const SaveStatus = ({
  saved,
  saving,
}: Partial<Record<'saved' | 'saving', boolean>>) => (
  <div className="header-6 flex items-center">
    {saved && (
      <span className="label-regular inline-flex items-center gap-2 text-neutral-500">
        <SavedSvg />
        <span>Changes Saved</span>
      </span>
    )}
    {saving && (
      <span className="label-regular inline-flex items-center gap-2 text-neutral-500">
        <Icon iconName="sync" className="animate-spin" />
        <span>Saving</span>
      </span>
    )}
  </div>
);
