import React, { FC, Dispatch, SetStateAction } from 'react';
import FormTumbler from 'bundles/Shared/components/GroupForm/FormItems/FormTumbler';
import FormFiles from '@/bundles/Shared/components/GroupForm/FormItems/files/FormFiles';
import FormDate from '@/bundles/Shared/components/GroupForm/FormItems/FormDate';
import FormInput from '@/bundles/Shared/components/GroupForm/FormItems/FormInput';
import FormInputNumber from '@/bundles/Shared/components/GroupForm/FormItems/FormInputNumber';
import FormOptionalSection from '@/bundles/Shared/components/GroupForm/FormItems/FormOptionalSection';
import FormSelect from '@/bundles/Shared/components/GroupForm/FormItems/FormSelect';
import FormTextArea from '@/bundles/Shared/components/GroupForm/FormItems/FormTextArea';
import FormSelectedButtons from '@/bundles/Shared/components/GroupForm/FormItems/selected-buttons/FormSelectedButtons';
import {
  FieldType,
  IFormItem,
} from '@/bundles/Shared/components/GroupForm/types/types';
import FormCustom from '@/bundles/Shared/components/GroupForm/FormItems/FormCustom';
import FormListSelect from '@/bundles/Shared/components/GroupForm/FormItems/FormListSelect';
import FormTreeSelect from '@/bundles/Shared/components/GroupForm/FormItems/FormTreeSelect';
import FormCreatableSelect from 'bundles/Shared/components/GroupForm/FormItems/FormCreatableSelect';

interface Props {
  formItem: IFormItem;
  value: any;
  formData: object;
  setFormData: Dispatch<SetStateAction<object>>;
  loading: string;
  validate: object;
  setValidate: SetStateAction<object>;
}

const FormSwitcher: FC<Props> = ({
  formItem,
  value,
  formData,
  setFormData,
  loading,
  validate,
  setValidate,
}) => {
  const configFormItem = {
    ...formItem,
    value,
    formData,
    setFormData,
    loading,
    validate,
    setValidate,
  };
  switch (formItem.type) {
    case FieldType.INPUT_TEXT:
      return <FormInput {...configFormItem} />;

    case FieldType.INPUT_TEXTAREA:
      return <FormTextArea {...configFormItem} />;

    case FieldType.INPUT_NUMBER:
      return <FormInputNumber {...configFormItem} />;

    case FieldType.INPUT_DATE:
      return <FormDate {...configFormItem} />;

    case FieldType.LIST_SELECT:
      return <FormListSelect {...configFormItem} />;

    case FieldType.SELECT:
      return <FormSelect {...configFormItem} />;

    case FieldType.TREE_SELECT:
      return <FormTreeSelect {...configFormItem} />;

    case FieldType.CREATABLE_SELECT:
      return <FormCreatableSelect {...configFormItem} />;

    case FieldType.FILES:
      return <FormFiles {...configFormItem} />;

    case FieldType.OPTIONAL_SECTION:
      return <FormOptionalSection {...configFormItem} />;

    case FieldType.SELECTED_BUTTONS:
      return <FormSelectedButtons {...configFormItem} />;

    case FieldType.TUMBLER:
      return <FormTumbler {...configFormItem} />;

    case FieldType.CUSTOM:
      return <FormCustom {...configFormItem} />;
    default:
      return <div>Unknow type of item</div>;
  }
};
export default FormSwitcher;
