import React from 'react';
import BoardCard from 'bundles/DrawPackage/InvoicesFlow/Invoices/invoice/kanban/cards/BoardCard';
import { useGetInvoicesKanbanQuery } from 'bundles/Construction/api/invoices';
import { Icon } from 'stories/Icon/Icon';
import { LinkItem } from 'bundles/DrawPackage/InvoicesFlow/Invoices/layouts/LinkItem';
import CompletedLog from 'bundles/DrawPackage/InvoicesFlow/Invoices/invoice/kanban/final/completed/CompletedLog';
import { useModal } from '@/shared/lib/hooks/useModal';

const FinalColumn = ({ legalEntityCode }) => {
  const { data: invoice } = useGetInvoicesKanbanQuery({
    legalEntityCode,
  });

  const { openModal } = useModal();

  const completedInvoiceLength = invoice?.completed.items.length;
  const archivedInvoiceLength = invoice?.archived.items.length;

  const openCompletedInvoice = () => {
    if (completedInvoiceLength) {
      openModal(CompletedLog, {
        legalEntityCode,
      });
    }
  };

  return (
    <BoardCard>
      <div className="flex flex-col gap-4">
        <LinkItem
          onClick={openCompletedInvoice}
          className={completedInvoiceLength ? 'cursor-pointer' : ''}
        >
          <div className="flex items-center gap-1.5 text-light-90">
            <div className="inline-semibold">Completed</div>
            <div className="inline-regular">{completedInvoiceLength}</div>
          </div>
          {Boolean(completedInvoiceLength) && (
            <Icon
              iconName="arrowRight"
              className="flex items-center text-light-60"
            />
          )}
        </LinkItem>
        <LinkItem className={archivedInvoiceLength ? 'cursor-pointer' : ''}>
          <div className="flex items-center gap-1.5 text-light-90">
            <div className="inline-semibold">Archived</div>
            <div className="inline-regular">{archivedInvoiceLength}</div>
          </div>
          {Boolean(archivedInvoiceLength) && (
            <Icon
              iconName="arrowRight"
              className="flex items-center text-light-60"
            />
          )}
        </LinkItem>
      </div>
    </BoardCard>
  );
};

export default FinalColumn;
