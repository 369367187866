import React, { useCallback, useEffect, useState } from 'react';
import Table from 'bundles/Shared/components/Table/Table';
import { DialogProps, useModal } from '@/shared/lib/hooks/useModal';
import {
  IPeriodBatch,
  TReportDataPeriodState,
} from 'bundles/Settings/types/ReportDataPeriodBulk';
import { Button } from 'stories/Button/Button';
import { Modal } from 'stories/Modals/Modal/Modal';
import { useLocalStorageValue } from '@/shared/lib/hooks/useLocalStorageValue';
import { isEmpty } from 'lodash-es';
import PreviousSelectionLabel from 'bundles/Settings/components/REport/DataPeriods/PublishLegalEntitiesModal/PreviousSelectionLabel';
import { ITableProps } from 'bundles/Shared/components/Table/types';
import { ConfirmModal } from '@/bundles/Settings/components/REport/DataPeriods/PublishLegalEntitiesModal/ConfirmModal';
import { GetApiSettingsReportDataPeriodBatchesApiArg } from 'bundles/Settings/actions/report/dataPeriodsApi';
import { usePageParamsTableSorting } from '@/shared/lib/hooks/usePageParamsTableSorting';
import {
  PageParamsProvider,
  PageParamsSearch,
} from 'bundles/Shared/components/pageParams';
import { mapLegalEntitiablesToRequestParams } from 'bundles/Shared/helpers/legalEntitiables';
import {
  FilterModel,
  useColumns,
} from 'bundles/Settings/components/REport/DataPeriods/PublishLegalEntitiesModal/useColumns';
import {
  useGetApiSettingsReportDataPeriodBatchesQuery,
  usePutApiSettingsReportDataPeriodBatchesBulkMutation,
} from 'bundles/Settings/actions/report/dataPeriodsApiExtended';

interface Props extends DialogProps<boolean> {
  dialogType: TReportDataPeriodState;
  period: string;
}

export const TEST_IDS = {
  submit: 'submit-button',
  confirmSubmit: 'confirm-submit-button',
  cancelConfirm: 'cancel-confirm-button',
  hideNextTime: 'hide-text-time-checkbox',
};

const getDialogHeader = (
  dialogType: TReportDataPeriodState,
  legalEntitiesCount: number,
) => {
  const mapping: Record<TReportDataPeriodState, string> = {
    draft: 'Publish Legal Entities',
    unpublished: `${legalEntitiesCount} Unpublished Legal entities`,
    published: `${legalEntitiesCount} Published Legal entities`,
  };
  return mapping[dialogType];
};

const mapToItemWithId = (item: IPeriodBatch) => ({
  ...item,
  id: item.legalEntityId,
});

function PublishLegalEntitiesModal({
  dialogType,
  period,
  onSubmit,
  onClose,
}: Props) {
  const [selectedLegalEntities, setSelectedLegalEntities] = useState<
    IPeriodBatch[]
  >([]);
  const [publishedRecentlyLegalEntities, setPublishedRecentlyLegalEntities] =
    useState<IPeriodBatch[] | null>(null);
  const [updateDataPeriodBulk] =
    usePutApiSettingsReportDataPeriodBatchesBulkMutation();
  const [hideConfirmModal, setHideConfirmModal] = useLocalStorageValue(
    'hidePublishLegalEntitiesConfirmModal',
    false,
  );
  const { openModal } = useModal();

  const [pageParams, setPageParams] =
    useState<GetApiSettingsReportDataPeriodBatchesApiArg>({
      state: dialogType,
      period,
    });
  const { settings, setSettings } = usePageParamsTableSorting(
    pageParams,
    setPageParams,
  );
  const { data: periodBatchesData, publishedRecently } =
    useGetApiSettingsReportDataPeriodBatchesQuery(pageParams, {
      selectFromResult: (result) => ({
        ...result,
        publishedRecently:
          result.data?.items?.filter((item) => item.publishedRecently) ?? [],
      }),
    });
  const periodBatches = periodBatchesData?.items ?? [];

  useEffect(() => {
    if (publishedRecentlyLegalEntities == null) {
      setPublishedRecentlyLegalEntities(publishedRecently);
      setSelectedLegalEntities(publishedRecently);
    }
  }, [publishedRecentlyLegalEntities, publishedRecently]);

  const handleSetSelectedLegalEntities = (items: IPeriodBatch[]) => {
    if (!isEmpty(publishedRecentlyLegalEntities)) {
      setPublishedRecentlyLegalEntities([]);
    }
    setSelectedLegalEntities(items);
  };

  const handleUpdate = async () => {
    await updateDataPeriodBulk({
      body: {
        data_period_batches: selectedLegalEntities.map((le) => ({
          legal_entity_id: le.legalEntityId,
          state: 'published',
          period,
        })),
      },
    });
  };

  const handlePublish = async () => {
    if (!hideConfirmModal) {
      const res = await openModal(ConfirmModal, {});

      if (!res) return;

      setHideConfirmModal(res.hideNextTime);
    }
    await handleUpdate();
    onSubmit?.(true);
  };

  const columns = useColumns({
    period,
    dialogType,
  });

  const handleClose = () => {
    onClose();
  };

  const handleFilterModelChange = useCallback<
    NonNullable<ITableProps<IPeriodBatch>['onFilterModelChange']>
  >((filterModel: FilterModel) => {
    setPageParams((prevParams) => ({
      ...prevParams,
      legalEntitiables: mapLegalEntitiablesToRequestParams(
        'type',
        filterModel.legalEntitiables,
      ),
    }));
  }, []);

  return (
    <Modal
      size={dialogType === 'draft' ? '600' : '900'}
      header={getDialogHeader(dialogType, periodBatches.length)}
      toggle={handleClose}
      classes={{
        body: 'bg-light',
      }}
      actions={
        dialogType === 'draft' && (
          <>
            <Button onClick={handleClose} variant="secondary">
              Cancel
            </Button>
            <Button
              data-testid={TEST_IDS.submit}
              onClick={handlePublish}
              variant="success"
              disabled={selectedLegalEntities.length === 0}
            >
              Publish {selectedLegalEntities.length} of {periodBatches.length}{' '}
              Legal Entities
            </Button>
          </>
        )
      }
    >
      <div
        style={{
          minHeight: '25rem',
        }}
        className="flex flex-col gap-2"
      >
        <PageParamsProvider
          pageParams={pageParams}
          setPageParams={setPageParams}
        >
          <PageParamsSearch />
        </PageParamsProvider>
        {!isEmpty(publishedRecentlyLegalEntities) && dialogType === 'draft' && (
          <PreviousSelectionLabel
            count={publishedRecentlyLegalEntities!.length}
            onClear={() => {
              setSelectedLegalEntities([]);
              setPublishedRecentlyLegalEntities([]);
            }}
          />
        )}
        <Table
          onFilterModelChange={handleFilterModelChange}
          borderLessOutside
          selectedRows={selectedLegalEntities.map(mapToItemWithId)}
          setSelectedRows={
            dialogType === 'draft' ? handleSetSelectedLegalEntities : undefined
          }
          settings={settings}
          setSettings={setSettings}
          columns={columns}
          items={periodBatches.map(mapToItemWithId)}
        />
      </div>
    </Modal>
  );
}

export default PublishLegalEntitiesModal;
