import { useReportBuilderTemplateContext } from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/reportBuilderTemplateContext';
import {
  usePutApiSettingsReportBuilderEagleEyeTemplatesByEagleEyeTemplateIdGroupsAndGroupIdWidgetSectionsIdMutation,
  usePutApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndGroupIdWidgetSectionsIdMutation,
} from '@/entities/report/reportBuilder/api/settingsReportBuilderTemplatesEnhancedApi';
import type { PutApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndGroupIdWidgetSectionsIdApiArg } from '@/entities/report/reportBuilder/api/settingsReportBuilderTemplatesGeneratedApi';
import { useReportingEntityKindContext } from '@/entities/reporting/context/entityKind';

export const useUpdateReportBuilderTemplateWidget = () => {
  const [updateTemplateWidget, updateOptions] =
    usePutApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndGroupIdWidgetSectionsIdMutation();
  const [updateEagleEyeTemplateWidget, updateOptionsEagleEye] =
    usePutApiSettingsReportBuilderEagleEyeTemplatesByEagleEyeTemplateIdGroupsAndGroupIdWidgetSectionsIdMutation();
  const templateCtx = useReportBuilderTemplateContext()!;
  const templateKind = useReportingEntityKindContext();

  const handleUpdateTemplateWidget = (
    args: PutApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndGroupIdWidgetSectionsIdApiArg,
  ) => {
    switch (templateKind) {
      case 'eagle_eye':
        return updateEagleEyeTemplateWidget({
          ...args,
          eagleEyeTemplateId: templateCtx.templateId,
        });
      default:
        return updateTemplateWidget({
          ...args,
          templateId: templateCtx.templateId,
        });
    }
  };
  const getOptions = () => {
    switch (templateKind) {
      case 'eagle_eye':
        return updateOptionsEagleEye;
      default:
        return updateOptions;
    }
  };

  return [handleUpdateTemplateWidget, getOptions()] as const;
};
