/* eslint-disable max-len */
import { emptySplitApi as api } from '@/app/stores/api';

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiSettingsReconcileLegalEntitiesByLegalEntityIdChangeOrderAdditionalRecipients:
      build.query<
        GetApiSettingsReconcileLegalEntitiesByLegalEntityIdChangeOrderAdditionalRecipientsApiResponse,
        GetApiSettingsReconcileLegalEntitiesByLegalEntityIdChangeOrderAdditionalRecipientsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/settings/reconcile/legal_entities/${queryArg.legalEntityId}/change_order_additional_recipients`,
        }),
      }),
    postApiSettingsReconcileLegalEntitiesByLegalEntityIdChangeOrderAdditionalRecipients:
      build.mutation<
        PostApiSettingsReconcileLegalEntitiesByLegalEntityIdChangeOrderAdditionalRecipientsApiResponse,
        PostApiSettingsReconcileLegalEntitiesByLegalEntityIdChangeOrderAdditionalRecipientsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/settings/reconcile/legal_entities/${queryArg.legalEntityId}/change_order_additional_recipients`,
          method: 'POST',
          body: queryArg.body,
        }),
      }),
    putApiSettingsReconcileLegalEntitiesByLegalEntityIdChangeOrderAdditionalRecipientsAndId:
      build.mutation<
        PutApiSettingsReconcileLegalEntitiesByLegalEntityIdChangeOrderAdditionalRecipientsAndIdApiResponse,
        PutApiSettingsReconcileLegalEntitiesByLegalEntityIdChangeOrderAdditionalRecipientsAndIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/settings/reconcile/legal_entities/${queryArg.legalEntityId}/change_order_additional_recipients/${queryArg.id}`,
          method: 'PUT',
          body: queryArg.body,
        }),
      }),
    deleteApiSettingsReconcileLegalEntitiesByLegalEntityIdChangeOrderAdditionalRecipientsAndId:
      build.mutation<
        DeleteApiSettingsReconcileLegalEntitiesByLegalEntityIdChangeOrderAdditionalRecipientsAndIdApiResponse,
        DeleteApiSettingsReconcileLegalEntitiesByLegalEntityIdChangeOrderAdditionalRecipientsAndIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/settings/reconcile/legal_entities/${queryArg.legalEntityId}/change_order_additional_recipients/${queryArg.id}`,
          method: 'DELETE',
        }),
      }),
    getApiSettingsReconcileLegalEntitiesByLegalEntityIdChangeOrderApprovers:
      build.query<
        GetApiSettingsReconcileLegalEntitiesByLegalEntityIdChangeOrderApproversApiResponse,
        GetApiSettingsReconcileLegalEntitiesByLegalEntityIdChangeOrderApproversApiArg
      >({
        query: (queryArg) => ({
          url: `/api/settings/reconcile/legal_entities/${queryArg.legalEntityId}/change_order_approvers`,
        }),
      }),
    postApiSettingsReconcileLegalEntitiesByLegalEntityIdChangeOrderApprovers:
      build.mutation<
        PostApiSettingsReconcileLegalEntitiesByLegalEntityIdChangeOrderApproversApiResponse,
        PostApiSettingsReconcileLegalEntitiesByLegalEntityIdChangeOrderApproversApiArg
      >({
        query: (queryArg) => ({
          url: `/api/settings/reconcile/legal_entities/${queryArg.legalEntityId}/change_order_approvers`,
          method: 'POST',
          body: queryArg.body,
        }),
      }),
    putApiSettingsReconcileLegalEntitiesByLegalEntityIdChangeOrderApproversAndId:
      build.mutation<
        PutApiSettingsReconcileLegalEntitiesByLegalEntityIdChangeOrderApproversAndIdApiResponse,
        PutApiSettingsReconcileLegalEntitiesByLegalEntityIdChangeOrderApproversAndIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/settings/reconcile/legal_entities/${queryArg.legalEntityId}/change_order_approvers/${queryArg.id}`,
          method: 'PUT',
          body: queryArg.body,
        }),
      }),
    deleteApiSettingsReconcileLegalEntitiesByLegalEntityIdChangeOrderApproversAndId:
      build.mutation<
        DeleteApiSettingsReconcileLegalEntitiesByLegalEntityIdChangeOrderApproversAndIdApiResponse,
        DeleteApiSettingsReconcileLegalEntitiesByLegalEntityIdChangeOrderApproversAndIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/settings/reconcile/legal_entities/${queryArg.legalEntityId}/change_order_approvers/${queryArg.id}`,
          method: 'DELETE',
        }),
      }),
    putApiSettingsReconcileLegalEntitiesByLegalEntityIdChangeOrderApproversAndIdSwap:
      build.mutation<
        PutApiSettingsReconcileLegalEntitiesByLegalEntityIdChangeOrderApproversAndIdSwapApiResponse,
        PutApiSettingsReconcileLegalEntitiesByLegalEntityIdChangeOrderApproversAndIdSwapApiArg
      >({
        query: (queryArg) => ({
          url: `/api/settings/reconcile/legal_entities/${queryArg.legalEntityId}/change_order_approvers/${queryArg.id}/swap`,
          method: 'PUT',
          body: queryArg.body,
        }),
      }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as reconcileWorkflowApproversApi };
export type GetApiSettingsReconcileLegalEntitiesByLegalEntityIdChangeOrderAdditionalRecipientsApiResponse =
  /** status 200 Success */ {
    items: LegalEntityConfigChangeOrderAdditionalRecipient[];
    meta: {
      userRoles: {
        id: string;
        name: string;
      }[];
      users: {
        id: string;
        fullName: string;
        userRoleId: string;
      }[];
    };
  };
export type GetApiSettingsReconcileLegalEntitiesByLegalEntityIdChangeOrderAdditionalRecipientsApiArg =
  {
    legalEntityId: string;
  };
export type PostApiSettingsReconcileLegalEntitiesByLegalEntityIdChangeOrderAdditionalRecipientsApiResponse =
  /** status 200 Success */ LegalEntityConfigChangeOrderAdditionalRecipient;
export type PostApiSettingsReconcileLegalEntitiesByLegalEntityIdChangeOrderAdditionalRecipientsApiArg =
  {
    legalEntityId: string;
    body: {
      user_id: string;
      user_role_id: string;
    };
  };
export type PutApiSettingsReconcileLegalEntitiesByLegalEntityIdChangeOrderAdditionalRecipientsAndIdApiResponse =
  /** status 200 Success */ LegalEntityConfigChangeOrderAdditionalRecipient;
export type PutApiSettingsReconcileLegalEntitiesByLegalEntityIdChangeOrderAdditionalRecipientsAndIdApiArg =
  {
    legalEntityId: string;
    id: string;
    body: {
      user_id?: string;
      user_role_id?: string;
    };
  };
export type DeleteApiSettingsReconcileLegalEntitiesByLegalEntityIdChangeOrderAdditionalRecipientsAndIdApiResponse =
  /** status 200 Additional recipient deleted */ undefined;
export type DeleteApiSettingsReconcileLegalEntitiesByLegalEntityIdChangeOrderAdditionalRecipientsAndIdApiArg =
  {
    legalEntityId: string;
    id: string;
  };
export type GetApiSettingsReconcileLegalEntitiesByLegalEntityIdChangeOrderApproversApiResponse =
  /** status 200 success */ {
    items: LegalEntityConfigChangeOrderApprover[];
    meta: {
      userRoles: {
        id: string;
        name: string;
      }[];
      companies: {
        id: number;
        name: string;
      }[];
      users: {
        id: string;
        fullName: string;
        userRoleId: string;
      }[];
      signatureKinds: string[];
    };
  };
export type GetApiSettingsReconcileLegalEntitiesByLegalEntityIdChangeOrderApproversApiArg =
  {
    legalEntityId: string;
  };
export type PostApiSettingsReconcileLegalEntitiesByLegalEntityIdChangeOrderApproversApiResponse =
  /** status 200 success */ LegalEntityConfigChangeOrderApprover;
export type PostApiSettingsReconcileLegalEntitiesByLegalEntityIdChangeOrderApproversApiArg =
  {
    legalEntityId: string;
    body: {
      user_id: string;
      user_role_id: string;
      company_id: number;
      priority?: number;
    };
  };
export type PutApiSettingsReconcileLegalEntitiesByLegalEntityIdChangeOrderApproversAndIdApiResponse =
  /** status 200 approver updated */ undefined;
export type PutApiSettingsReconcileLegalEntitiesByLegalEntityIdChangeOrderApproversAndIdApiArg =
  {
    id: number;
    legalEntityId: string;
    body: {
      user_id?: string;
      user_role_id?: string;
      company_id?: number;
      priority?: number;
    };
  };
export type DeleteApiSettingsReconcileLegalEntitiesByLegalEntityIdChangeOrderApproversAndIdApiResponse =
  unknown;
export type DeleteApiSettingsReconcileLegalEntitiesByLegalEntityIdChangeOrderApproversAndIdApiArg =
  {
    id: number;
    legalEntityId: string;
  };
export type PutApiSettingsReconcileLegalEntitiesByLegalEntityIdChangeOrderApproversAndIdSwapApiResponse =
  /** status 200 approvers priorities updated */ {
    items: LegalEntityConfigChangeOrderApprover[];
  };
export type PutApiSettingsReconcileLegalEntitiesByLegalEntityIdChangeOrderApproversAndIdSwapApiArg =
  {
    id: number;
    legalEntityId: string;
    body: {
      swap_with_id: number;
    };
  };
export type PutApiSettingsReconcileLegalEntitiesByLegalEntityIdChangeOrderApproversUpdateListApiResponse =
  /** status 200 success */ LegalEntityConfigChangeOrderApprover[];
export type PutApiSettingsReconcileLegalEntitiesByLegalEntityIdChangeOrderApproversUpdateListApiArg =
  {
    legalEntityId: string;
    body: {
      id: number;
      priority: number;
      company_id: number;
      user_role_id: string;
      user_id: string;
      _destroy?: boolean;
    };
  };
export type LegalEntityConfigChangeOrderAdditionalRecipient = {
  id: number;
  legalEntityConfigId: number;
  userRole: {
    id: string;
    name: string;
  };
  user: {
    id: string;
    fullName: string;
    email: string;
    phone?: string;
  };
  roleType: string;
};
export type Forbidden = {
  errors?: string[];
};
export type UnprocessableEntity = {
  errors?: string[];
};
export type LegalEntityConfigChangeOrderApprover = {
  id: number;
  priority: number;
  legalEntityConfigId: number;
  userRole: {
    id: string;
    name: string;
  };
  company: {
    id: number;
    name: string;
  };
  user: {
    id: string;
    fullName: string;
  };
  roleType: string;
};
