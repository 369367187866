import { ERPSystem } from '@/entities/erpsystem/model';
import { cn } from '@/shared/lib/css/cn';
import { ComponentProps, memo } from 'react';

import { ReactComponent as AnyoneHome } from '@/entities/erpsystem/shared/assets/anyone_home.svg';
import { ReactComponent as AppFolio } from '@/entities/erpsystem/shared/assets/app_folio.svg';
import { ReactComponent as AvidXchange } from '@/entities/erpsystem/shared/assets/avid_xchange.svg';
import { ReactComponent as Buildium } from '@/entities/erpsystem/shared/assets/buildium.svg';
import { ReactComponent as Cyma } from '@/entities/erpsystem/shared/assets/cyma.svg';
import { ReactComponent as Entrata } from '@/entities/erpsystem/shared/assets/entrata.svg';
import { ReactComponent as HelloDataAi } from '@/entities/erpsystem/shared/assets/hello_data_ai.svg';
import { ReactComponent as M3 } from '@/entities/erpsystem/shared/assets/m3.svg';
import { ReactComponent as MriSoftware } from '@/entities/erpsystem/shared/assets/mri_software.svg';
import { ReactComponent as Nexus } from '@/entities/erpsystem/shared/assets/nexus.svg';
import { ReactComponent as OneSite } from '@/entities/erpsystem/shared/assets/one_site.svg';
import { ReactComponent as Quickbooks } from '@/entities/erpsystem/shared/assets/quickbooks.svg';
import { ReactComponent as Radix } from '@/entities/erpsystem/shared/assets/radix.svg';
import { ReactComponent as Realpage } from '@/entities/erpsystem/shared/assets/realpage.svg';
import { ReactComponent as Rentvine } from '@/entities/erpsystem/shared/assets/rentvine.svg';
import { ReactComponent as Resman } from '@/entities/erpsystem/shared/assets/resman.svg';
import { ReactComponent as Symmetre } from '@/entities/erpsystem/shared/assets/symmetre.svg';
import { ReactComponent as Xero } from '@/entities/erpsystem/shared/assets/xero.svg';
import { ReactComponent as Yardi } from '@/entities/erpsystem/shared/assets/yardi.svg';
import { ReactComponent as Zoho } from '@/entities/erpsystem/shared/assets/zoho.svg';

// TODO: FE-3617 SVG dynamic import not working
export type IERPSystemIcon = {
  system: ERPSystem;
} & ComponentProps<'svg'>;

export const ERPSystemIcon = memo(
  ({ system, className, ...otherProps }: IERPSystemIcon) => {
    const props = {
      className: cn('size-4', className),
      ...otherProps,
    };

    return (() => {
      switch (system) {
        case 'anyone_home':
          return <AnyoneHome {...props} />;
        case 'app_folio':
          return <AppFolio {...props} />;
        case 'avid_xchange':
          return <AvidXchange {...props} />;
        case 'buildium':
          return <Buildium {...props} />;
        case 'cyma':
          return <Cyma {...props} />;
        case 'entrata':
          return <Entrata {...props} />;
        case 'hello_data_ai':
          return <HelloDataAi {...props} />;
        case 'm3':
          return <M3 {...props} />;
        case 'mri_software':
          return <MriSoftware {...props} />;
        case 'nexus':
          return <Nexus {...props} />;
        case 'one_site':
          return <OneSite {...props} />;
        case 'quickbooks':
          return <Quickbooks {...props} />;
        case 'radix':
          return <Radix {...props} />;
        case 'realpage':
          return <Realpage {...props} />;
        case 'rentvine':
          return <Rentvine {...props} />;
        case 'resman':
          return <Resman {...props} />;
        case 'symmetre':
          return <Symmetre {...props} />;
        case 'xero':
          return <Xero {...props} />;
        case 'yardi':
          return <Yardi {...props} />;
        case 'zoho':
          return <Zoho {...props} />;
        default:
          return <Symmetre {...props} />;
      }
    })();
  },
);
