import React from 'react';
import { Button } from 'stories/Button/Button';
import { Icon } from 'stories/Icon/Icon';

interface Props {
  isDirty: boolean;
  onClick: VoidFunction;
}

export function DashboardHasChangesButton({ isDirty, onClick }: Props) {
  return (
    <div className="flex gap-2">
      <div className="secondary-regular flex items-center gap-1 text-neutral-500">
        {isDirty && <Icon iconName="check" className="text-success-055" />}
        {!isDirty ? 'Changes detected' : 'No new changes detected'}
      </div>
      {!isDirty && (
        <Button variant="success" onClick={onClick}>
          Save Changes
        </Button>
      )}
    </div>
  );
}
