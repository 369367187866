import React from 'react';
import GroupedRadioButtons from 'bundles/Shared/components/groupedSelection/GroupedRadioButtons';
import { LegalEntity } from '@/entities/core/legalEntity';

interface Props {
  legalEntities: Pick<LegalEntity, 'id' | 'name'>[];
  selected: Pick<LegalEntity, 'id' | 'name'> | null;
  onSelectedChange: (newLegalEntity: Pick<LegalEntity, 'id' | 'name'>) => void;
}

function SelectLegalEntitiesStep({
  legalEntities,
  onSelectedChange,
  selected,
}: Props) {
  return (
    <>
      {legalEntities.length === 0 && (
        <p className="text-center">No investment entities found</p>
      )}
      {legalEntities.length > 0 && (
        <div className="flex flex-col gap-4">
          <div className="inline-semibold text-neutral-850">
            Select the Legal Entity you wish to work with
          </div>

          <GroupedRadioButtons
            items={legalEntities}
            getLabelFromItem={(le) => le.name}
            selected={selected}
            setSelected={onSelectedChange}
            withSearch
            searchEntityLabel="Legal Entity"
          />
        </div>
      )}
    </>
  );
}

export default SelectLegalEntitiesStep;
