import React from 'react';
import NumberFormat from 'react-number-format';
import _ from 'lodash-es';
import { Button } from 'stories/Button/Button';
import { Field } from 'stories/Field/Field';
import { Input } from 'stories/FormControls/Inputs/Input/Input';
import { Modal } from 'stories/Modals/Modal/Modal';
import { useForm } from 'react-hook-form';
import addHTTP from '@/bundles/Shared/addHTTP';
import { cn } from '@/shared/lib/css/cn';

const InvestmentObjectInfoModal = ({
  close,
  object,
  items,
  currentItemIndex,
  update,
  newFieldTemplate,
  fields,
  dataName,
  actionText,
  header,
}) => {
  const current =
    currentItemIndex !== undefined ? items[currentItemIndex] : newFieldTemplate;
  const {
    handleSubmit,
    register,
    watch,
    setValue,
    formState: { errors, isSubmitted },
    trigger,
  } = useForm({ defaultValues: current, mode: 'onChange' });

  const watchFields = watch();

  const onSubmit = (formData) => {
    const data = { ...formData };
    if (data.website) data.website = addHTTP(data.website);
    items[currentItemIndex !== undefined ? currentItemIndex : items.length] =
      data;

    update({
      id: object.id,
      slug: object.slug,
      [object.objectType]: { [dataName]: items },
    });
    close();
  };

  const onChange = (e, field) => {
    if (isSubmitted) trigger();
    setValue(field.name, e.target.value);
  };

  return (
    <Modal
      toggle={close}
      header={header}
      size="400"
      actions={
        <div className="w-full flex justify-between">
          <Button variant="secondary" onClick={close}>
            Cancel
          </Button>
          <Button variant="success" type="submit" form={dataName}>
            {actionText}
          </Button>
        </div>
      }
    >
      <form
        className="flex flex-col gap-4"
        id={dataName}
        onSubmit={handleSubmit(onSubmit)}
      >
        {fields.map((field, index) => {
          const placeholder =
            field.placeholder || `Enter ${_.capitalize(field.name)}`;
          const label = field.label || _.capitalize(field.name) || placeholder;

          return (
            <div key={field.name}>
              <Field
                note={
                  field.hint && (
                    <p className="light-60 secondary-regular">{field.hint}</p>
                  )
                }
                labelText={label}
              >
                {field.name === 'phone' && (
                  <NumberFormat
                    format="+(###) ###-####"
                    mask="_"
                    {...register(
                      field.name,
                      field.validations && field.validations(watchFields),
                    )}
                    value={watchFields[field.name]}
                    placeholder={placeholder}
                    onChange={(e) => onChange(e, field)}
                    className={cn('sre-input__field input-light', {
                      'is-invalid': errors[field.name],
                    })}
                    id={`field-${index}`}
                  />
                )}
                {field.name !== 'phone' && (
                  <Input
                    {...register(
                      field.name,
                      field.validations && field.validations(watchFields),
                    )}
                    onChange={(e) => onChange(e, field)}
                    value={watchFields[field.name]}
                    placeholder={placeholder}
                    className={cn({ 'is-invalid': errors[field.name] })}
                    size="l"
                    id={`field-${index}`}
                  />
                )}
              </Field>
            </div>
          );
        })}
      </form>
    </Modal>
  );
};

export default InvestmentObjectInfoModal;
