import { FC } from 'react';
import { cn } from '@/shared/lib/css/cn';
import { SelectableMultilineButton } from '@/stories/SelectableMultilineButton/SelectableMultilineButton';

export interface SelectButton {
  title: string;
  text: string;
  value: string;
  active?: boolean;
  disabled?: boolean;
}

interface SelectableButtonGroupProps {
  onSelect: (value: string) => void;
  buttons: SelectButton[];
  disabled?: boolean;
  styles?: {
    button: string;
  };
}

export const SelectableButtonGroup: FC<SelectableButtonGroupProps> = ({
  buttons = [],
  onSelect,
  disabled,
  styles,
}) => {
  const changeHandle = (value: string) => {
    onSelect(value);
  };
  return (
    <div className={cn('flex items-center justify-between gap-2')}>
      {buttons.map((button, idx) => (
        <SelectableMultilineButton
          key={`btn${idx}`}
          selected={button.active}
          onClick={() => changeHandle(button.value)}
          disabled={disabled ?? button.disabled}
          className={styles?.button}
          {...button}
        />
      ))}
    </div>
  );
};
