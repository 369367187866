import VerticalSeparator from 'bundles/Shared/components/VerticalSeparator/VerticalSeparator';
import { useState, useCallback, useEffect } from 'react';
import { AnimationLoader } from 'stories/AnimationLoader/AnimationLoader';
import { TSort } from 'types/Sort';
import { IPeriodItem } from 'stories/FlexibleFilterByPeriods/types';
import { createMonthPeriodItem } from 'stories/FlexibleFilterByPeriods/utils';
import FromToFilter from 'bundles/REport/components/financials/filter/FromToFilter';
import Table from 'bundles/Shared/components/Table/Table';
import { usePageParamsTableSorting } from '@/shared/lib/hooks/usePageParamsTableSorting';
import {
  useGetLinkableTransactionsQuery,
  useGetInvoiceQuery,
} from 'bundles/Construction/api/invoices';
import { useParams } from '@reach/router';
import {
  TColumnIdsLinkableTransactions,
  useTransactionColumns,
} from 'bundles/DrawPackage/InvoicesFlow/Invoices/pay/components/markAsPaid/useColumns';
import {
  PageParamsPagination,
  PageParamsProvider,
  PageParamsSearch,
} from 'bundles/Shared/components/pageParams';
import { IDevelopmentLegalEntityInvoiceTransaction } from 'bundles/DrawPackage/InvoicesFlow/Invoices/pay/MarkAsPaidInvoice';
import { TRouteParams } from '@/shared/lib/hooks/useNavigation';
import { PageParamsDrawPackage } from '@/shared/types/reconcile/Invoice';
import dayjs from 'dayjs';
import {
  subtractAndFormatDate,
  createPossiblePeriods,
} from 'bundles/REturn/components/Ownership/modals/linkTxModals/linkTxToManuallyAdded/utils';
import { ReportLineItemType } from 'bundles/REturn/actions/types';

import { DATE_STRING_FORMAT } from '@/shared/lib/converters';

export interface TFilterModelLinkableTransaction {
  valueType: ReportLineItemType;
  vendor_ids: {
    id: string;
  }[];
  value: number;
}

export interface TPageParamsTransactions
  extends PageParamsDrawPackage<
    TColumnIdsLinkableTransactions.DATE | TColumnIdsLinkableTransactions.AMOUNT
  > {
  vendor_ids: {
    id: string;
  }[];
  valueType?: ReportLineItemType;
}

export function SelectTransactionTable({
  selectedTransaction,
  setSelectedTransaction,
}: {
  selectedTransaction: IDevelopmentLegalEntityInvoiceTransaction | null;
  setSelectedTransaction: (
    tx: IDevelopmentLegalEntityInvoiceTransaction,
  ) => void;
}) {
  const nowDate = dayjs()
    .startOf('month')
    .add(1, 'month')
    .format(DATE_STRING_FORMAT);

  const initialFromDate = subtractAndFormatDate(nowDate, 3);
  const possiblePeriods = createPossiblePeriods(nowDate, 36);

  const [periods, setPeriods] = useState<IPeriodItem['period'][]>([
    initialFromDate,
    nowDate,
  ]);

  const params =
    useParams<
      TRouteParams['RECONCILE_DEVELOPMENT_LEGAL_ENTITY_INVOICES_PAYMENT']
    >();

  const { data: invoice, isLoading: isLoadingInvoice } = useGetInvoiceQuery({
    legalEntityCode: params?.legalEntityCode,
    invoiceId: params.invoiceId,
  });

  const [pageParams, setPageParams] = useState<TPageParamsTransactions>({
    page: 1,
    per_page: 50,
    vendor_ids: [],
    sort: {
      order: TSort.ASC,
      field: TColumnIdsLinkableTransactions.DATE,
    },
  });
  const { settings, setSettings } = usePageParamsTableSorting(
    pageParams,
    setPageParams,
  );

  const { data: transactions, isLoading } = useGetLinkableTransactionsQuery({
    legalEntityCode: params.legalEntityCode,
    periods,
    pageParams,
  });

  // validation: min value for transaction - invoice amount
  useEffect(() => {
    setPageParams({
      ...pageParams,
      amount: {
        from: invoice?.amount ?? '',
        to: '',
      },
    });
  }, [invoice]);

  const handleFilterModelChange = useCallback(
    (filterModel: TFilterModelLinkableTransaction) =>
      setPageParams((prevParams) => ({
        ...prevParams,
        valueType: filterModel.valueType,
        vendor_ids: filterModel.vendor_ids?.map((item) => item.id),
        amount: filterModel.value,
      })),
    [],
  );

  if (isLoading && isLoadingInvoice && !transactions)
    return <AnimationLoader />;

  const columns = useTransactionColumns();

  return (
    <div className="mr-[480px] flex w-full flex-col gap-4 bg-light-10 p-6">
      <div className="flex flex-col gap-2.5">
        <h6 className="header6-regular">Transactions</h6>
      </div>
      <PageParamsProvider pageParams={pageParams} setPageParams={setPageParams}>
        <div>
          <div className="flex items-center justify-between mb-m">
            <div>
              <PageParamsPagination
                loading={isLoading}
                totalSize={transactions?.meta.totalSize || 1}
                sizePerPage={transactions?.meta.perPage || 1}
              />
            </div>
            <div className="flex items-center gap-2">
              <FromToFilter
                filterByPeriodsType="mtd"
                periodItems={periods.map(createMonthPeriodItem)}
                possiblePeriods={possiblePeriods}
                initialFrom={[createMonthPeriodItem(periods[0])]}
                initialTo={[createMonthPeriodItem(periods.at(-1)!)]}
                onUpdatePeriodItems={(newPeriodItems) => {
                  const newPeriods = newPeriodItems.map(({ period }) => period);
                  setPeriods(newPeriods);
                }}
              />
              <VerticalSeparator />
              <PageParamsSearch />
            </div>
          </div>
          <div>
            <Table
              loading={isLoading}
              columns={columns}
              settings={settings}
              setSettings={setSettings}
              onFilterModelChange={handleFilterModelChange}
              items={transactions?.items ?? []}
              singleSelectedRow={selectedTransaction}
              setSingleSelectedRow={setSelectedTransaction}
            />
          </div>
        </div>
      </PageParamsProvider>
    </div>
  );
}
