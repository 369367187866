import type { GetApiSettingsReportBuilderGotenbergTemplatesMetaApiResponse } from '@/entities/report/reportBuilder/api/settingsReportBuilderTemplatesGeneratedApi';
import { useAssertNullableContext } from '@/shared/lib/hooks/context/useAssertNullableContext';
import { createContext } from 'react';

type ReportBuilderTemplateMetaContextType =
  GetApiSettingsReportBuilderGotenbergTemplatesMetaApiResponse;
export const ReportBuilderTemplateMetaContext =
  createContext<ReportBuilderTemplateMetaContextType | null>(null);

export const useReportBuilderTemplateMetaContext = useAssertNullableContext(
  ReportBuilderTemplateMetaContext,
);
