import React from 'react';
import onUserImpersonation from '@/shared/lib/mirrorMode';
import { SreAvatar } from 'stories/SreAvatar/SreAvatar';
import { IUser } from 'types/User';
import { TConfirmFunc } from '@/shared/lib/hooks/useModal';

interface Params {
  user: IUser;
  confirm: TConfirmFunc;
  onClose?: () => void;
  redirectUrl?: string;
}

const impersonationConfirm = async ({
  onClose,
  user,
  redirectUrl,
  confirm,
}: Params) => {
  const handleImpersonate = async () => {
    await onUserImpersonation(user.id);
    window.location.href = redirectUrl || '/'; // hard reset as there might stale some data in stores
  };

  const result = await confirm({
    title: 'Mirror Mode',
    subtitle: (
      <>
        <p className="light-60 inline-regular mb-l">
          Are you sure you want to view the portal as the following user?
        </p>
        <div className="flex flex-col w-full items-center">
          <SreAvatar
            text={user.fullName}
            width="2.5rem"
            height="2.5rem"
            fontSize="1rem"
            fontWeight="600"
          />
          <span className="blue inline-regular mt-s">{user.fullName}</span>
        </div>
      </>
    ),
    actions: {
      primaryButton: {
        variant: 'success',
        text: 'Yes, continue',
      },
      secondaryButton: {
        variant: 'secondary',
        text: 'Cancel',
      },
    },
    size: '400',
  });
  if (result) {
    handleImpersonate();
  } else if (onClose) {
    onClose();
  }
};

export default impersonationConfirm;
