import React, { FC, useState } from 'react';
import { Button } from 'stories/Button/Button';
import FormDate from 'bundles/Shared/components/GroupForm/FormItems/FormDate';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import {
  selectReallocation,
  updateReallocation,
} from 'bundles/Construction/reducers/ReconcileReallocationSlice';
import { changeReconcileActiveAction } from 'bundles/Construction/reducers/ReconcileSlice';

const ApprovalForm: FC = () => {
  const reallocation = useAppSelector(selectReallocation);
  const dispatch = useAppDispatch();

  const [formData, setFormData] = useState({
    approved_at: '',
  });

  const approveHandle = () => {
    dispatch(
      updateReallocation({
        reallocationId: reallocation.id,
        data: {
          approved_at: formData?.approved_at,
          action_type: 'approve',
          status: 'COMPLETED',
        },
      }),
    );
    dispatch(changeReconcileActiveAction(null));
  };

  const chooseValue = (e, fieldName) => {
    setFormData({
      ...formData,
      [fieldName]: e[fieldName],
    });
  };

  return (
    <div>
      <div className="">
        <div className="available-action__line px-m pt-m">
          <div className="mb-m">
            <FormDate
              title="Approve Date"
              fieldName="approved_at"
              formData={formData}
              setFormData={(e) => chooseValue(e, 'approved_at')}
              styles={{
                wrapper: 'w-full form-item_white',
                size: 'm',
                nomb: true,
              }}
              placeholder="Date"
              format="MM-DD-YYYY"
              formatView="MM-dd-yy"
              leftIcon="calendar"
              maxDate={new Date()}
            />
          </div>
        </div>
        <div className="px-m py-m">
          <Button
            variant="success"
            iconName="acceptAlt"
            onClick={approveHandle}
            className="w-full"
            disabled={!formData.approved_at}
          >
            Approve
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ApprovalForm;
