import { ColumnState } from 'ag-grid-community';
import { AgGridReactRef } from '@/lib/ag-grid/types';
import { useEffect, useMemo } from 'react';

export const useAgGridApplyColumnStateEffect = ({
  grid,
  columnState,
}: {
  grid: AgGridReactRef | null;
  columnState: ColumnState[] | undefined;
}) => {
  const columnStateStringify = useMemo(
    () => JSON.stringify(columnState) ?? '',
    [columnState],
  );

  useEffect(() => {
    const applyState = () => {
      grid?.api?.applyColumnState({
        state: columnState,
      });
    };

    applyState();
  }, [columnStateStringify]);
};
