import { formatToDateStringForRequest } from '@/shared/lib/converters';
import {
  get1YearAgoDate,
  todayDate,
  shiftTodayBackward,
} from '@/shared/lib/date';

export const DEFAULT_EXTRACTIONS_PER_PAGE = 10;

export const DEFAULT_DATE_RANGE_FOR_ERP_EXTRACTIONS = {
  from: formatToDateStringForRequest(shiftTodayBackward(1, 'month')),
  to: formatToDateStringForRequest(todayDate()),
};

export const DEFAULT_DATE_RANGE_FOR_MANUAL_EXTRACTIONS = {
  from: formatToDateStringForRequest(get1YearAgoDate()),
  to: formatToDateStringForRequest(todayDate()),
};
