import { AssetKeyDealTeamContactCard } from '@/stories/AssetKeyDealTeamContactCard/AssetKeyDealTeamContactCard';
import { CollapsableSection } from '@/stories/CollapsableSection/CollapsableSection';
import { IconButton } from '@/stories/IconButton/IconButton';

interface IAssetContact {
  id: number;
  name: string;
  phone: string;
  role: string;
  email: string;
}

interface Props {
  className: string;
  canManage: boolean;
  contacts: IAssetContact[];
  onCreate: () => void;
  onEdit: (contact: IAssetContact) => void;
  onDelete: (contact: IAssetContact) => void;
}

export const KeyDealTeamContactsCollapsableSection = ({
  className,
  canManage,
  contacts,
  onCreate,
  onEdit,
  onDelete,
}: Props) => (
  <CollapsableSection
    canManage={canManage}
    className={className}
    count={contacts.length}
    content={
      <div className="flex flex-col gap-2">
        {contacts.map((contact) => (
          <AssetKeyDealTeamContactCard
            key={contact.id}
            canManage={canManage}
            keyDealTeamContact={contact}
            onEdit={() => {
              onEdit(contact);
            }}
            onDelete={() => {
              onDelete(contact);
            }}
          />
        ))}
      </div>
    }
    title="KEY DEAL TEAM CONTACTS"
    actions={
      canManage && (
        <IconButton
          iconName="addSmall"
          onClick={onCreate}
          variant="secondary"
        />
      )
    }
  />
);

export default KeyDealTeamContactsCollapsableSection;
