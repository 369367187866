import React, { ComponentProps } from 'react';
import { Popover } from 'stories/Popover/Popover';
import { cn } from '@/shared/lib/css/cn';
import styles from '@/bundles/REconcile/components/comments/Popover/CommentFlyFormPopover.module.scss';
import { isMentionRelated } from '@symmetre-web/text-editor';

type Props = ComponentProps<typeof Popover>;

function CommentFlyFormPopover(
  { children, visible, onClickOutside, ...props }: Props,
  ref,
) {
  const handleClickOutside: Props['onClickOutside'] = (_a, e) => {
    if (isMentionRelated(e)) return;

    onClickOutside?.(_a, e);
  };

  return (
    <Popover
      trigger="click"
      ref={ref}
      visible={visible}
      onHidden={onClickOutside}
      onClickOutside={handleClickOutside}
      className={cn(
        styles.popover,
        props.theme === 'light' && styles.popover_light,
        'shadow-m-light',
      )}
      maxWidth="20rem"
      hiddenArrow
      {...props}
    />
  );
}

export default React.forwardRef(CommentFlyFormPopover);
