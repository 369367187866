import { useHoverSaveArea } from '@/bundles/Shared/components/LeftSidebar/hooks/useHoverSaveArea';
import { CreateNewDashboardButton } from '@/bundles/Shared/components/LeftSidebar/menuItems/ReportPortfolioMenuItem';
import { useGenericData } from '@/bundles/Shared/GenericContext';
import { cn } from '@/shared/lib/css/cn';
import useMediaQuery, {
  MEDIUM_WIDTH_MEDIA_QUERY,
} from '@/shared/lib/hooks/useMediaQuery';
import { generateUrl, ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import { useMatch } from '@reach/router';
import { usePopoverTriggered } from 'bundles/Shared/components/LeftSidebar/hooks/usePopoverTriggered';
import MenuItemPopover from 'bundles/Shared/components/LeftSidebar/items/MenuItemPopover';
import { DashboardIcon } from 'bundles/Shared/entities/dashboard/ui/DashboardIcon';
import { LeftSidebarMenu } from 'bundles/Shared/shared/ui';
import { useRef } from 'react';
import { Icon } from 'stories/Icon/Icon';
import { DEFAULT_DROPDOWN_OFFSET } from 'stories/Popover/Popover';
import SecondaryMenuItem from '@/bundles/Shared/components/LeftSidebar/items/SecondaryMenuItem';

export const ReportComparisonDashboardsMenuItem = () => {
  const { triggered, ...handlers } = usePopoverTriggered();
  const { genericData } = useGenericData();
  const isMobile = useMediaQuery('(pointer:coarse)');
  const isMediumWidth = useMediaQuery(MEDIUM_WIDTH_MEDIA_QUERY);
  const { mainNavigation } = genericData!;
  const { fullPath } = ROUTES_ROOT.report.comparisonDashboards;
  const menuItemRef = useRef<HTMLAnchorElement>(null);
  const { classes: hoverClasses, onMouseMove } = useHoverSaveArea({
    popoverPlacement: 'start',
  });
  const isMatch = useMatch(ROUTES_ROOT.report.comparisonDashboards.fullPath);

  const items = mainNavigation.comparisonDashboards.filter(
    (d) => d.status === 'published',
  );

  if (items.length === 0) return null;

  const menu = (
    <LeftSidebarMenu panel={<CreateNewDashboardButton />} title="Dashboards">
      {items.map((item) => (
        <LeftSidebarMenu.Item
          to={generateUrl(fullPath, {
            pathParams: {
              dashboardId: item.slug,
            },
          })}
          key={item.label}
        >
          <DashboardIcon iconName="eagleEyeDashboard" /> {item.label}
        </LeftSidebarMenu.Item>
      ))}
    </LeftSidebarMenu>
  );

  if (isMobile) return null;

  return (
    <>
      <SecondaryMenuItem
        className={cn(
          triggered && 'left-sidebar__second-menu-item_hovered',
          hoverClasses,
        )}
        ref={menuItemRef}
        onLinkClick={(e) => {
          e.preventDefault();
        }}
        linkProps={{
          onMouseMove,
        }}
        rightIcon={<Icon iconName="arrowRight" className="pr-3" />}
        label="Compare"
        isActive={() => isMatch!}
        path=""
      />
      {isMediumWidth && (
        <MenuItemPopover
          {...handlers}
          reference={menuItemRef}
          offset={DEFAULT_DROPDOWN_OFFSET}
          template={menu}
          placement="right-start"
          hideOnExpandedAreaClick
          maxWidth="fit-content"
        />
      )}
    </>
  );
};
