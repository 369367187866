import { cn } from '@/shared/lib/css/cn';
import { SortableHandle } from 'react-sortable-hoc';
import { Icon } from 'stories/Icon/Icon';
import { IconButton } from 'stories/IconButton/IconButton';
import { Tooltip } from 'stories/Tooltip/Tooltip';
import { Tumbler } from 'stories/Tumbler/Tumbler';

// TODO: move it to story component
const CardWithToggle = ({
  item,
  onEdit,
  onToggle,
  footer,
  onDelete,
  disabled,
  tumblerText,
  trashText,
  dragHandle,
  className,
}) => {
  const cardTumbler = (
    <Tumbler
      defaultChecked={item.active}
      onChange={onToggle}
      disabled={disabled}
    />
  );

  const TrashIcon = (
    <IconButton disabled={disabled} iconName="trash" onClick={onDelete} />
  );

  const DragHandle = SortableHandle(() => (
    <Icon iconName="move" className="cursor-pointer" aria-hidden="true" />
  ));

  return (
    <div
      className={cn(
        'mr-4 mb-4 p-4 w-[306px] min-h-[140px] shadow-[0px_4px_24px_8px_#3E4A630D] bg-transparent rounded-2xl flex flex-col',
        className,
      )}
    >
      <div className="flex justify-between">
        {Boolean(onToggle) && (
          <>
            {disabled && (
              <Tooltip
                arrowPosition="start"
                mainText={tumblerText}
                placement="top-start"
              >
                {cardTumbler}
              </Tooltip>
            )}
            {!disabled && cardTumbler}
          </>
        )}
        <div className="gap-2 flex items-center">
          {dragHandle && <DragHandle />}
          {onEdit && <IconButton iconName="edit" onClick={onEdit} />}
          {onDelete && (
            <>
              {disabled && (
                <Tooltip
                  arrowPosition="start"
                  mainText={trashText}
                  placement="top-start"
                >
                  {TrashIcon}
                </Tooltip>
              )}
              {!disabled && TrashIcon}
            </>
          )}
        </div>
      </div>
      <div
        className={cn('font-25 mt-auto', item.active ? 'dark-60' : 'light-60')}
      >
        {item.name || item.title}
      </div>
      {footer && footer()}
    </div>
  );
};

export default CardWithToggle;
