import { prettyJsonStringify } from '@/lib/stringify';
import { safelyParseJSON } from '@/lib/typeHelpers/safelyParseJSON';
import { JSONEditor } from '@/shared/ui/CodeEditor/component';
import { Button } from '@/stories/Button/Button';
import {
  useUpdateWidgetConfig,
  useWidgetConfig,
} from 'bundles/Shared/widgets/dashboard/widgets/common/lib/config';
import { useMemo, useState } from 'react';

export function SettingsReportWidgetConfigEditor() {
  const { widget } = useWidgetConfig();
  const [updateConfig] = useUpdateWidgetConfig(widget.widgetType);
  const stringifiedWidgetConfig = useMemo(
    () =>
      prettyJsonStringify({
        title: widget.title,
        widgetConfig: widget.widgetConfig,
      }),
    [widget.title, widget.widgetConfig],
  );
  const [widgetConfig, setWidgetConfig] = useState(stringifiedWidgetConfig);
  const isDirty = widgetConfig !== stringifiedWidgetConfig;

  const handleSave = () => {
    const config = safelyParseJSON<{
      title: string;
      widgetConfig: Record<string, unknown>;
    }>(widgetConfig);

    if (config == null) {
      return;
    }

    updateConfig({
      config: config.widgetConfig,
      title: config.title,
    });
  };

  return (
    <div className="flex h-full w-[500px] min-w-[500px] flex-col gap-4">
      <Button disabled={!isDirty} onClick={handleSave} variant="success">
        Save Updates
      </Button>
      <JSONEditor
        onChange={(value) => {
          setWidgetConfig(value!);
        }}
        value={widgetConfig}
      />
    </div>
  );
}
