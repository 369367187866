import React from 'react';
import { useForm } from 'react-hook-form';
import { isEqual } from 'lodash-es';
import { AnimationLoader } from 'stories/AnimationLoader/AnimationLoader';
import { Button } from 'stories/Button/Button';
import BasicInfo from '@/bundles/UserManagement/components/UserForm/BasicInfo';
import { WizardPage } from '@/bundles/Shared/components/WizardForm';
import userFormDefaultValues from '@/bundles/UserManagement/components/helpers/userFormDefaultValues';
import userFormTabs from '@/bundles/UserManagement/components/helpers/userFormTabs';
import userFormRequest from '@/bundles/UserManagement/components/helpers/userFormRequest';
import SelectUserRole from '@/bundles/UserManagement/components/UserForm/SelectUserRole';
import SelectUserTag from '@/bundles/UserManagement/components/UserForm/SelectUserTag';
import FundAccess from '@/bundles/UserManagement/components/UserForm/FundAccess';
import AssetAccess from '@/bundles/UserManagement/components/UserForm/AssetAccess';
import SetInvestmentEntities from '@/bundles/UserManagement/components/UserForm/SetInvestmentEntities';
import SetAdvisors from '@/bundles/UserManagement/components/UserForm/SetAdvisors';
import PermissionsFor from '@/bundles/UserManagement/components/UserForm/PermissionsFor';
import { updateUser } from '@/bundles/UserManagement/actions/User';
import { IUserWithAccessLevel } from 'bundles/UserManagement/components/EditUserModalComponents/types';
import { IFund } from 'types/Fund';
import { IAsset } from 'types/Asset';

const EditUserForm = ({
  user,
  onClose,
  afterUpdateCallback,
  funds,
  tab,
  assets,
}: {
  user: IUserWithAccessLevel;
  onClose: () => void;
  afterUpdateCallback: () => void;
  tab: string;
  funds?: IFund[];
  assets?: IAsset[];
}) => {
  const defaultFields = userFormDefaultValues({
    user,
    assets: assets ?? [],
    funds: funds ?? [],
    tags: user.tags ?? [],
  });

  const {
    watch,
    setValue,
    register,
    formState: { errors },
  } = useForm({ defaultValues: defaultFields, mode: 'onChange' });

  const fields = watch();
  const isInvalid =
    isEqual(fields, defaultFields) || Object.keys(errors).length > 0;
  const tabs = userFormTabs(fields.userRole);

  if (!funds || !assets)
    return <AnimationLoader className="static min-h-[120px]" />;

  return (
    <>
      {tabs.includes('Info') && tab === 'Info' && (
        <WizardPage
          title="Info"
          isValid={fields.firstName && fields.lastName && fields.email}
          withoutActions
        >
          <BasicInfo
            fields={fields}
            setValue={setValue}
            register={register}
            errors={errors}
          />
        </WizardPage>
      )}

      {tabs.includes('Role') && tab === 'Role' && (
        <WizardPage title="Role" isValid={fields.userRole} withoutActions>
          {tab === 'Role' && (
            <SelectUserRole fields={fields} setValue={setValue} />
          )}
          {fields.userRole && (
            <div className="mb-4">
              <span className="label-regular text-dark-60">Permissions</span>
              <PermissionsFor selectedRole={fields.userRole} />
            </div>
          )}
        </WizardPage>
      )}

      {tabs.includes('Entities') && tab === 'Entities' && (
        <WizardPage title="Entities" isValid withoutActions>
          <SetInvestmentEntities setValue={setValue} fields={fields} />
        </WizardPage>
      )}

      {tabs.includes('Advisees') && tab === 'Advisees' && (
        <WizardPage title="Advisees" isValid withoutActions>
          <SetAdvisors
            setValue={(data) => setValue('adviseeIds', data)}
            defaultValues={user.advisees || []}
            placeholder="Choose one or more advisee"
            url="/users"
            params={{ not_advisors: true }}
          />
        </WizardPage>
      )}

      {tabs.includes('Advisors') && tab === 'Advisors' && (
        <WizardPage title="Advisors" isValid withoutActions>
          <SetAdvisors
            setValue={(data) => setValue('advisorIds', data)}
            defaultValues={user.advisors || []}
            placeholder="Choose one or more advisor"
            url="/users"
            params={{ advisors: true }}
          />
        </WizardPage>
      )}

      {tabs.includes('Tags') && tab === 'Tags' && (
        <WizardPage title="Tags" isValid={fields.userTags} withoutActions>
          <SelectUserTag fields={fields} setValue={setValue} />
        </WizardPage>
      )}

      {tabs.includes('Funds') && tab === 'Funds' && (
        <WizardPage
          title="Funds"
          withoutActions
          isValid={
            (fields.canViewFundIds || fields.canViewAllFunds) &&
            (fields.userRole?.category !== 'external'
              ? fields.canManageFundIds || fields.canManageAllFunds
              : true)
          }
        >
          <FundAccess fields={fields} setValue={setValue} funds={funds} />
        </WizardPage>
      )}

      {tabs.includes('Assets') && tab === 'Assets' && (
        <WizardPage
          title="Assets"
          withoutActions
          isValid={
            (fields.canViewAssetIds || fields.canViewAll) &&
            (fields.userRole?.category !== 'external'
              ? fields.canManageAssetIds || fields.canManageAll
              : true)
          }
        >
          <AssetAccess
            fields={fields}
            setValue={setValue}
            assets={assets}
            funds={funds}
          />
        </WizardPage>
      )}

      <div className="mt-4 flex w-full justify-between">
        <Button id="wizard-step-cancel" variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          id="wizard-step-continue"
          disabled={isInvalid}
          variant="success"
          onClick={() => {
            updateUser(userFormRequest(fields)).then(() => {
              onClose();
              afterUpdateCallback();
            });
          }}
          className="flex items-center"
        >
          Save Updates
        </Button>
      </div>
    </>
  );
};

export default EditUserForm;
