import React, { ReactNode } from 'react';
import { cn } from '@/shared/lib/css/cn';
import Field from '@/stories/Field/Field';
import { DEFAULT_STRING_FALLBACK } from '@/shared/lib/formatting/fallbacks';
export const HorizontalEditableField = ({
  label,
  value,
  editable,
  children,
  className,
  required = false,
}: {
  label: string;
  value: string | ReactNode;
  editable: boolean;
  required?: boolean;
} & React.PropsWithChildren &
  PropsWithClassName) => {
  const renderValue = () => {
    if (React.isValidElement(value)) {
      return value;
    }

    if (typeof value === 'string' && value.length > 0) {
      return <span className="ml-3.5">{value}</span>;
    }

    return DEFAULT_STRING_FALLBACK;
  };

  return (
    <div
      className={cn(
        'grid grid-cols-[150px_1fr] items-center gap-2 px-4',
        className,
      )}
    >
      <div className="inline-regular text-neutral-550">
        {label} {required && <Field.RequiredMark />}
      </div>
      <div className="inline-regular text-neutral-850">
        {editable ? children : renderValue()}
      </div>
    </div>
  );
};
