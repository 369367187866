import { useDeleteApiRecapPagesByIdMutation } from '@/shared/api/documentationEnhancedApi';
import { useModal } from '@/shared/lib/hooks/useModal';
import { useCallback } from 'react';

export const useDeletePage = () => {
  const [deletePage, options] = useDeleteApiRecapPagesByIdMutation();
  const { confirm } = useModal();
  const deleteHandler = useCallback(
    async (pageId: string) => {
      const res = await confirm({
        title: 'Delete page',
        subtitle: 'Are you sure you want to delete this page?',
      });

      if (!res) {
        return false
      }

      return deletePage({ id: pageId });
    },
    [deletePage],
  );
  return [deleteHandler, options] as const;
};
