import { AssetLocationCard } from '@/stories/AssetLocationCard/AssetLocationCard';
import { CollapsableSection } from '@/stories/CollapsableSection/CollapsableSection';
import { IconButton } from '@/stories/IconButton/IconButton';

interface Props {
  canManage?: boolean;
  latitude: string;
  longitude: string;
  address: string;
  onCreate?: () => void;
  onDelete?: () => void;
  onEdit?: () => void;
}

export const LocationCollapsableSection = ({
  onDelete,
  onEdit,
  onCreate,
  address,
  longitude,
  latitude,
  canManage = false,
}: Props) => (
  <CollapsableSection
    canManage={canManage}
    count={address ? 1 : 0}
    singleCount
    content={
      address && (
        <AssetLocationCard
          canManage={canManage}
          onEdit={onEdit}
          onDelete={onDelete}
          latitude={latitude}
          longitude={longitude}
          address={address}
        />
      )
    }
    title="ADDRESS"
    actions={
      !address &&
      canManage && (
        <IconButton
          iconName="addSmall"
          onClick={onCreate}
          variant="secondary"
        />
      )
    }
  />
);

export default LocationCollapsableSection;
