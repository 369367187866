import React, { useEffect } from 'react';
import LoadingTable from 'bundles/Shared/components/LoadingTable';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import { fetchDevelopmentBudgetVariance } from 'bundles/REconcile/reducers/developmentBudgetVarianceSlice';
import { ROUTES_ROOT, TRouteParams } from '@/shared/lib/hooks/useNavigation';
import RequisitionsHeader from 'bundles/REconcile/components/development/requisitions/widgets/RequisitionsHeader';
import { navigate, Router, useParams } from '@reach/router';
import LineItemsTable from 'bundles/REconcile/components/development/lineItems/LineItemsTable';
import { RequisitionsTable } from '@/bundles/REconcile/components/development/requisitions/widgets/RequisitionsTable';

type Props = TRouteParams['RECONCILE_DEVELOPMENT_LEGAL_ENTITY_REQUISITIONS'];

function Requisitions({ legalEntityCode }: Props) {
  const params = useParams();
  const dispatch = useAppDispatch();
  const status = useAppSelector(
    (state) => state.developmentBudgetVariance.data.status,
  );
  const isLoading = status === 'loading';

  useEffect(() => {
    dispatch(fetchDevelopmentBudgetVariance(legalEntityCode));
  }, [legalEntityCode]);

  return (
    <>
      <RequisitionsHeader />
      <div className="h-page-difference-footer">
        {isLoading && <LoadingTable rows={10} cols={5} />}
        {status === 'succeeded' && (
          <RequisitionsTable legalEntityCode={legalEntityCode} />
        )}
      </div>
      <Router primary={false} basepath="/">
        <LineItemsTable
          legalEntityCode={params?.legalEntityCode ?? legalEntityCode}
          path={
            ROUTES_ROOT.reconcile.development.legalEntity.requisitions
              .requisition.lineItems.fullPath
          }
          onClose={() => navigate('.')}
        />
      </Router>
    </>
  );
}

export default Requisitions;
