import * as d3 from 'd3';
import { truncate } from 'lodash-es';
import { getComponentSelector } from '@/stories/TreeView/TreeView';
import type { DatumNode, Selection } from '@/stories/TreeView/types';
import styles from '@/stories/TreeView/TreeView.module.scss';

export const LINKS_POPOVER_SELECTOR = 'linksPopover';

const MAX_WIDTH = 280;
const MAX_HEIGHT = 300;
const MAX_SHOWN_LINKED_ITEMS = 5;
const MAX_CHARACTERS_PER_LINKED_ITEM = 36;
const POPOVER_TITLE = 'Related General Ledgers';

interface ILinksPopoverConfig {
  items: {
    label: string;
  }[];
  data: any;
  d: d3.HierarchyNode<DatumNode>;
  handleOpenMoreLinkedItems: (d: d3.HierarchyNode<DatumNode>) => void;
  position: {
    x: number;
    y: number;
  };
}

export function LinksPopover(
  selection: Selection<SVGSVGElement>,
  { items, data, handleOpenMoreLinkedItems, d, position }: ILinksPopoverConfig,
) {
  d3.select(getComponentSelector(LINKS_POPOVER_SELECTOR)).remove();

  const tooManyLinkedItems = items.length > MAX_SHOWN_LINKED_ITEMS;
  const showLinkedItems = tooManyLinkedItems
    ? items.slice(0, MAX_SHOWN_LINKED_ITEMS)
    : items;
  const MORE_LINKED_ITEMS_TEXT = `+${
    items.slice(MAX_SHOWN_LINKED_ITEMS).length
  } more`;

  const linksPopoverGroup = selection
    .append('foreignObject')
    .attr('data-component', LINKS_POPOVER_SELECTOR)
    .attr('class', styles.linksPopover)
    .attr('width', MAX_WIDTH)
    .attr('height', MAX_HEIGHT)
    .attr('transform', () => `translate(${position.x}, ${position.y})`);

  const linksPopoverChildrenWrapper = linksPopoverGroup
    .append('xhtml:div')
    .attr('class', styles['linksPopover__linked-items-wrapper'])
    .on('mouseleave', () => {
      // TODO improve this event to fire properly
      d3.select(getComponentSelector(LINKS_POPOVER_SELECTOR)).remove();
    });

  linksPopoverChildrenWrapper
    .append('xhtml:p')
    .text(POPOVER_TITLE)
    .attr('class', 'secondary-semibold w-full');

  showLinkedItems.forEach((item) => {
    linksPopoverChildrenWrapper
      .append('xhtml:p')
      .text(truncate(item.label, { length: MAX_CHARACTERS_PER_LINKED_ITEM }))
      .attr('class', styles['linksPopover__linked-item']);
  });

  if (tooManyLinkedItems) {
    linksPopoverChildrenWrapper
      .append('xhtml:p')
      .text(MORE_LINKED_ITEMS_TEXT)
      .attr('class', styles['linksPopover__more-linked-items-button'])
      .on('click', () => {
        handleOpenMoreLinkedItems(d);
      });
  }

  d3.select('body').on('click', (e) => {
    const outside = d3
      .select(getComponentSelector(LINKS_POPOVER_SELECTOR))
      .filter(function (_e, _a, b) {
        return this == e.target;
      })
      .empty();

    if (outside) {
      const linksPopover = d3.select(
        getComponentSelector(LINKS_POPOVER_SELECTOR),
      );
      linksPopover.remove();
    }
  });
}

export default LinksPopover;
