import React from 'react';
import { Field, FieldsContainer } from 'stories/Field/Field';
import { Input } from '@/stories/FormControls/Inputs/Input/Input';
import { Controller, useFormContext } from 'react-hook-form';
import { EditDocumentationPageForm } from '@/features/documentation/editPage/config';
import { DocumentationBlockEditor } from '@/widgets/documentation/blockEditor';
import { OutputData } from '@editorjs/editorjs';

export function EditDocumentationPage({ readOnly }: { readOnly?: boolean }) {
  const methods = useFormContext<EditDocumentationPageForm>();
  const { register, control, watch } = methods;
  const title = watch('title');

  if (readOnly) {
    return (
      <div className="flex flex-col gap-4">
        <span className="header3-regular text-neutral-999">{title}</span>
        <DocumentationBlockEditor
          config={{
            readOnly,
          }}
          value={methods.getValues('content') as OutputData}
        />
      </div>
    );
  }

  return (
    <FieldsContainer>
      <Field labelText="Title">
        {/* register doesn't update form state in parent component for some
        reason, use controller instead */}
        <Controller
          render={({ field }) => <Input {...field} />}
          name="title"
          control={control}
        />
      </Field>
      <Field labelText="Content">
        <Controller
          render={({ field }) => (
            <DocumentationBlockEditor
              config={{
                readOnly,
              }}
              value={field.value as OutputData}
              onChange={(next) => field.onChange(next)}
            />
          )}
          name="content"
          control={control}
        />
      </Field>
    </FieldsContainer>
  );
}
