import { CssVar } from '@/shared/config/cssVar';

export const CURRENCY_FORMATTER_CLASSES = {
  wrapper: 'inline-flex text-light-90 inline-semibold',
  value: 'text-light-90',
  startSymbol: 'text-light-90',
} as const;

export const STAT_LINES_COLORS = [
  CssVar.green,
  CssVar.blue,
  CssVar.yellow,
] as const;

export const REJECT_STAT_LINE = {
  reject: {
    value: 100,
    color: CssVar.red,
  },
} as const;
