import {
  useDeleteApiSettingsReportThirdPartyExtractionsConnectionsByConnectionIdEmailExtractionsAndIdMutation as useDeleteEmailExtraction,
  useDeleteApiSettingsReportThirdPartyExtractionsConnectionsByConnectionIdManualUploadsAndIdMutation as useDeleteManualUpload,
  useDeleteApiSettingsReportThirdPartyExtractionsConnectionsByConnectionIdSftpExtractionsAndIdMutation as useDeleteSftpExtraction,
} from '@/shared/api/settingsReportConnectionsEnhancedApi';
import { IConnection } from '@/bundles/Shared/entities/connection/model';
import { DELETE_EXTRACTION_CONFIRM_MODAL_SUBTITLE } from '@/features/report/connections/config';
import { MethodExtractions } from '@/pages/settings/report/connections/connection';
import { useModal } from '@/shared/lib/hooks/useModal';

export const useDeleteExtraction = ({
  extractionType,
}: {
  extractionType: IConnection['type'] | `${MethodExtractions.MANUAL}`;
}) => {
  const [deleteSftp, optionsSftp] = useDeleteSftpExtraction();
  const [deleteManual, optionsManual] = useDeleteManualUpload();
  const [deleteEmail, optionsEmail] = useDeleteEmailExtraction();

  const resolveAction = () => {
    switch (extractionType) {
      case 'sftp': {
        return [deleteSftp, optionsSftp] as const;
      }
      case 'manual': {
        return [deleteManual, optionsManual] as const;
      }
      case 'email': {
        return [deleteEmail, optionsEmail] as const;
      }
    }
  };

  const [deleteExtraction, options] = resolveAction();

  const { confirm } = useModal();
  const handleDelete = async (params: Parameters<typeof deleteSftp>[0]) => {
    const res = await confirm({
      subtitle: DELETE_EXTRACTION_CONFIRM_MODAL_SUBTITLE,
    });

    if (!res) return;

    deleteExtraction(params);
  };

  return [handleDelete, options] as const;
};
