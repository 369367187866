import React, { FC, ReactNode, useState } from 'react';
import { ConfirmationModal } from 'stories/Modals/ConfirmationModal/ConfirmationModal';
import { Icon } from 'stories/Icon/Icon';
import { IconsId } from 'types/sre-icons';
import AvailableActionsItem from '@/bundles/Construction/components/ChangeOrderEvent/AvailableActions/AvailableActionsItem';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import { selectReconcileActiveAction } from 'bundles/Construction/reducers/ReconcileSlice';
import FormInput from 'bundles/Shared/components/GroupForm/FormItems/FormInput';
import { destroyChangeOrderEvent } from '@/bundles/Construction/actions/changeOrderEvents';
import { TRouteParams } from '@/shared/lib/hooks/useNavigation';
import { useParams } from '@reach/router';
import { useModal } from '@/shared/lib/hooks/useModal';
import {
  revertChangeEvent,
  selectChangeEvent,
} from '@/bundles/Construction/reducers/ReconcileChangeEventSlice';
import { TChangeOrderStatus } from '@/bundles/Construction/types';
import { currentUserAllowedTo, TProductNames } from '@/lib/permissions';

interface IAction {
  title: string;
  description: string;
  iconName: IconsId;
  show: boolean;
  content: ReactNode;
}

interface IAvailableActionsProps {
  actionsData: IAction[];
  destroyCE?: boolean;
  onCloseModal: () => void;
}

export const REVERTABLE_STATUSES = [
  'PENDING',
  'PENDING-PROCEEDING',
  'PENDING-NOT PROCEEDING',
  'APPROVED',
] as const satisfies TChangeOrderStatus[];

const AvailableActions: FC<IAvailableActionsProps> = ({
  actionsData,
  destroyCE,
  onCloseModal,
}) => {
  const changeEvent = useAppSelector(selectChangeEvent);
  const dispatch = useAppDispatch();
  const { confirm } = useModal();
  const { id, legalEntityCode } =
    useParams<
      TRouteParams['RECONCILE_DEVELOPMENT_LEGAL_ENTITY_CHANGE_EVENT']
    >();
  const handleDestroyAction = () =>
    dispatch(
      destroyChangeOrderEvent({
        legalEntityCode,
        id,
      }),
    );
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [comment, setComment] = useState({
    value: '',
  });
  const activeAction = useAppSelector(selectReconcileActiveAction);

  const showRevert =
    currentUserAllowedTo('configure', TProductNames.CHANGE_MANAGEMENT) &&
    changeEvent?.status &&
    REVERTABLE_STATUSES.includes(changeEvent.status) &&
    changeEvent.verified;
  const handleDestroy = async (status: boolean) => {
    if (status) {
      await handleDestroyAction();
      onCloseModal();
    }
    setShowConfirmModal(false);
  };
  const handleRevert = async () => {
    const result = await confirm({
      actions: {
        secondaryButton: {
          text: 'Cancel',
        },
        primaryButton: {
          text: 'Revert',
        },
      },
      title: 'Revert Change Event',
      subtitle:
        'Are you sure you want to Revert Change Event? This COR will be reverted to Unverified state & all attached Documents will be deleted',
    });
    if (result) {
      dispatch(revertChangeEvent({ legalEntityCode, id }));
    }
  };
  return (
    <>
      {!activeAction && Boolean(actionsData.length) && (
        <div className="inline-semibold mb-s">Available Actions</div>
      )}
      <div className="change-event-scroll change-event-scroll_actions">
        {activeAction && (
          <div>
            {actionsData
              .filter((action) => action.title === activeAction)
              .map(
                (item, idx) =>
                  item.show && (
                    <AvailableActionsItem key={`action${idx}`} {...item} />
                  ),
              )}
          </div>
        )}
        {!activeAction ? (
          <div>
            {actionsData.map(
              (item, idx) =>
                item.show && (
                  <AvailableActionsItem key={`action${idx}`} {...item} />
                ),
            )}
          </div>
        ) : (
          activeAction &&
          Boolean(actionsData.length) && (
            <div>
              <div className="inline-semibold mb-s">Other Actions</div>
              {actionsData
                .filter((action) => action.title !== activeAction)
                .map(
                  (item, idx) =>
                    item.show && (
                      <AvailableActionsItem key={`action${idx}`} {...item} />
                    ),
                )}
            </div>
          )
        )}

        {destroyCE && (
          <div
            className="inline-semibold mt-s flex cursor-pointer items-center gap-2 text-neutral-550"
            onClick={() => setShowConfirmModal(true)}
          >
            <Icon
              className="bg-neutral-250 p-1 text-neutral-500"
              iconName="trash"
            />
            Remove
          </div>
        )}
        {showRevert && (
          <div
            className="inline-semibold mt-s flex cursor-pointer  items-center gap-2 text-neutral-550"
            onClick={handleRevert}
          >
            <Icon
              className="bg-neutral-250 p-1 text-neutral-500"
              iconName="reset"
            />
            Revert
          </div>
        )}
      </div>

      {showConfirmModal && (
        <ConfirmationModal
          title="Remove Change Event"
          subtitle={
            <div className="flex flex-col gap-2">
              <span>To confirm deletion, enter "Delete" in the field</span>
              <FormInput
                fieldName="value"
                setFormData={setComment}
                value={comment.value}
                styles={{
                  nomb: true,
                  input__item: 'comment-in-modal',
                }}
              />
            </div>
          }
          actions={{
            primaryButton: {
              text: 'Yes',
              variant: 'danger',
              disabled: comment.value !== 'Delete',
            },
            secondaryButton: {
              text: 'No',
              variant: 'secondary',
            },
          }}
          handleResolve={handleDestroy}
        />
      )}
    </>
  );
};

export default AvailableActions;
