import { ListOption } from 'stories/Checkbox/CheckList';
import * as yup from 'yup';

export const ADJUSTMENTS = {
  OP_VARIANCE: 'op_variance',
  OP_VARIANCE_PERCENT: 'op_variance_percent',
  OP_VARIANCE_FLIPPED: 'op_variance_flipped',
  OP_VARIANCE_FLIPPED_PERCENT: 'op_variance_flipped_percent',
  OP_BUDGET: 'op_budget',
  UW_VARIANCE: 'uw_variance',
  UW_VARIANCE_PERCENT: 'uw_variance_percent',
  UW_VARIANCE_FLIPPED: 'uw_variance_flipped',
  UW_VARIANCE_FLIPPED_PERCENT: 'uw_variance_flipped_percent',
  UW_BUDGET: 'uw_budget',
  ANNUALIZED: 'annualized',
  OP_BUDGET_ANNUALIZED: 'op_budget_annualized',
  UW_BUDGET_ANNUALIZED: 'uw_budget_annualized',
  FINANCIAL_T1A: 'financial_t1a',
  FINANCIAL_T3A: 'financial_t3a',
  FINANCIAL_BEGINNING_BALANCE: 'financial_beginning_balance',
  FINANCIAL_ENDING_BALANCE: 'financial_ending_balance',
  FINANCIAL_DEBIT: 'financial_debit',
  FINANCIAL_CREDIT: 'financial_credit',
  PERIOD_TO_PERIOD_VARIANCE: 'period_to_period_variance',
  PERIOD_TO_PERIOD_VARIANCE_PERCENT: 'period_to_period_variance_percent',
  PERIOD_TO_PERIOD_VARIANCE_FLIPPED: 'period_to_period_variance_flipped',
  PERIOD_TO_PERIOD_VARIANCE_FLIPPED_PERCENT:
    'period_to_period_variance_flipped_percent',
  YEAR_TO_YEAR_VARIANCE: 'year_to_year_variance',
  YEAR_TO_YEAR_VARIANCE_PERCENT: 'year_to_year_variance_percent',
  YEAR_TO_YEAR_VARIANCE_FLIPPED: 'year_to_year_variance_flipped',
  YEAR_TO_YEAR_VARIANCE_FLIPPED_PERCENT:
    'year_to_year_variance_flipped_percent',
  FC_BUDGET: 'fc_budget',
  FC_VARIANCE: 'fc_variance',
  FC_VARIANCE_PERCENT: 'fc_variance_percent',
  FC_BUDGET_ANNUALIZED: 'fc_budget_annualized',
} as const;
export const OVERRIDE_SOURCES = [
  'actual',
  'budget',
  'underwriting_budget',
] as const;
export type OverrideSource = (typeof OVERRIDE_SOURCES)[number];
export type AdjustmentExpressionName =
  (typeof ADJUSTMENTS)[keyof typeof ADJUSTMENTS];
export type AdjustmentExpression = {
  name: AdjustmentExpressionName;
  source_overrides?: {
    source: OverrideSource;
    override_to: string;
  }[];
};
export const ADJUSTMENT_EXPRESSION_VALUE_ACTUAL = 'actual';
export type ADJUSTMENT_EXPRESSION_OPTIONS = ListOption<
  Values<typeof ADJUSTMENTS> | typeof ADJUSTMENT_EXPRESSION_VALUE_ACTUAL | null
>[];
export const ADJUSTMENT_EXPRESSION_VALUE_NO_ADJUSTMENT = 'no_adjustment';
export const ADJUSTMENT_EXPRESSION_OPTIONS_MAP = {
  [ADJUSTMENT_EXPRESSION_VALUE_ACTUAL]: {
    label: 'Actual',
    value: ADJUSTMENT_EXPRESSION_VALUE_ACTUAL,
  },
  [ADJUSTMENT_EXPRESSION_VALUE_NO_ADJUSTMENT]: {
    label: 'No Adjustment',
    value: null,
  },
  [ADJUSTMENTS.OP_VARIANCE]: {
    label: 'Operational Budget Variance',
    value: 'op_variance',
  },
  [ADJUSTMENTS.OP_VARIANCE_PERCENT]: {
    label: 'Operational Budget Variance Percent',
    value: 'op_variance_percent',
  },
  [ADJUSTMENTS.OP_VARIANCE_FLIPPED]: {
    value: 'op_variance_flipped',
    label: 'Operational Budget Variance Flipped',
  },
  [ADJUSTMENTS.OP_VARIANCE_FLIPPED_PERCENT]: {
    value: 'op_variance_flipped_percent',
    label: 'Operational Budget Variance Flipped Percent',
  },
  [ADJUSTMENTS.OP_BUDGET]: {
    label: 'Operational Budget',
    value: 'op_budget',
  },
  [ADJUSTMENTS.UW_VARIANCE]: {
    label: 'Underwriting Budget Variance',
    value: 'uw_variance',
  },
  [ADJUSTMENTS.UW_VARIANCE_PERCENT]: {
    label: 'Underwriting Budget Variance Percent',
    value: 'uw_variance_percent',
  },
  [ADJUSTMENTS.UW_VARIANCE_FLIPPED]: {
    value: 'uw_variance_flipped',
    label: 'Underwriting Budget Variance Flipped',
  },
  [ADJUSTMENTS.UW_VARIANCE_FLIPPED_PERCENT]: {
    value: 'uw_variance_flipped_percent',
    label: 'Underwriting Budget Variance Flipped Percent',
  },
  [ADJUSTMENTS.UW_BUDGET]: {
    value: 'uw_budget',
    label: 'Underwriting Budget',
  },
  [ADJUSTMENTS.ANNUALIZED]: {
    value: 'annualized',
    label: 'Annualized',
  },
  [ADJUSTMENTS.OP_BUDGET_ANNUALIZED]: {
    value: 'op_budget_annualized',
    label: 'Operational Budget Annualized',
  },
  [ADJUSTMENTS.UW_BUDGET_ANNUALIZED]: {
    value: 'uw_budget_annualized',
    label: 'Underwriting Budget Annualized',
  },
  [ADJUSTMENTS.FINANCIAL_T1A]: {
    value: 'financial_t1a',
    label: 'Financial T1A',
  },
  [ADJUSTMENTS.FINANCIAL_T3A]: {
    value: 'financial_t3a',
    label: 'Financial T3A',
  },
  [ADJUSTMENTS.FINANCIAL_BEGINNING_BALANCE]: {
    value: 'financial_beginning_balance',
    label: 'Financial Beginning Balance',
  },
  [ADJUSTMENTS.FINANCIAL_ENDING_BALANCE]: {
    value: 'financial_ending_balance',
    label: 'Financial Ending Balance',
  },
  [ADJUSTMENTS.FINANCIAL_DEBIT]: {
    value: 'financial_debit',
    label: 'Financial Debit',
  },
  [ADJUSTMENTS.FINANCIAL_CREDIT]: {
    value: 'financial_credit',
    label: 'Financial Credit',
  },
  [ADJUSTMENTS.PERIOD_TO_PERIOD_VARIANCE]: {
    value: 'period_to_period_variance',
    label: 'Period To Period Variance',
  },
  [ADJUSTMENTS.PERIOD_TO_PERIOD_VARIANCE_PERCENT]: {
    value: 'period_to_period_variance_percent',
    label: 'Period To Period Variance Percent',
  },
  [ADJUSTMENTS.PERIOD_TO_PERIOD_VARIANCE_FLIPPED]: {
    value: 'period_to_period_variance_flipped',
    label: 'Period To Period Variance Flipped',
  },
  [ADJUSTMENTS.PERIOD_TO_PERIOD_VARIANCE_FLIPPED_PERCENT]: {
    value: 'period_to_period_variance_flipped_percent',
    label: 'Period To Period Variance Flipped Percent',
  },
  [ADJUSTMENTS.YEAR_TO_YEAR_VARIANCE]: {
    value: 'year_to_year_variance',
    label: 'Year To Year Variance',
  },
  [ADJUSTMENTS.YEAR_TO_YEAR_VARIANCE_PERCENT]: {
    value: 'year_to_year_variance_percent',
    label: 'Year To Year Variance Percent',
  },
  [ADJUSTMENTS.YEAR_TO_YEAR_VARIANCE_FLIPPED]: {
    value: 'year_to_year_variance_flipped',
    label: 'Year To Year Variance Flipped',
  },
  [ADJUSTMENTS.YEAR_TO_YEAR_VARIANCE_FLIPPED_PERCENT]: {
    value: 'year_to_year_variance_flipped_percent',
    label: 'Year To Year Variance Flipped Percent',
  },
  [ADJUSTMENTS.FC_BUDGET]: {
    value: 'fc_budget',
    label: 'Forecasting Budget',
  },
  [ADJUSTMENTS.FC_VARIANCE]: {
    value: 'fc_variance',
    label: 'Forecasting Variance',
  },
  [ADJUSTMENTS.FC_VARIANCE_PERCENT]: {
    value: 'fc_variance_percent',
    label: 'Forecasting Variance Percent',
  },
  [ADJUSTMENTS.FC_BUDGET_ANNUALIZED]: {
    value: 'fc_budget_annualized',
    label: 'Forecasting Budget Annualized',
  },
} as const satisfies Record<
  | Values<typeof ADJUSTMENTS>
  | typeof ADJUSTMENT_EXPRESSION_VALUE_ACTUAL
  | typeof ADJUSTMENT_EXPRESSION_VALUE_NO_ADJUSTMENT,
  {
    value:
      | Values<typeof ADJUSTMENTS>
      | typeof ADJUSTMENT_EXPRESSION_VALUE_ACTUAL
      | null;
    label: string;
  }
>;
export const ADJUSTMENT_EXPRESSION_OPTIONS = [
  ADJUSTMENT_EXPRESSION_OPTIONS_MAP.no_adjustment,
  ADJUSTMENT_EXPRESSION_OPTIONS_MAP.op_budget,
  ADJUSTMENT_EXPRESSION_OPTIONS_MAP.op_variance,
  ADJUSTMENT_EXPRESSION_OPTIONS_MAP.op_variance_percent,
  ADJUSTMENT_EXPRESSION_OPTIONS_MAP.op_budget_annualized,
  ADJUSTMENT_EXPRESSION_OPTIONS_MAP.op_variance_flipped,
  ADJUSTMENT_EXPRESSION_OPTIONS_MAP.op_variance_flipped_percent,
  ADJUSTMENT_EXPRESSION_OPTIONS_MAP.uw_budget,
  ADJUSTMENT_EXPRESSION_OPTIONS_MAP.uw_variance,
  ADJUSTMENT_EXPRESSION_OPTIONS_MAP.uw_variance_percent,
  ADJUSTMENT_EXPRESSION_OPTIONS_MAP.uw_budget_annualized,
  ADJUSTMENT_EXPRESSION_OPTIONS_MAP.uw_variance_flipped,
  ADJUSTMENT_EXPRESSION_OPTIONS_MAP.uw_variance_flipped_percent,
  ADJUSTMENT_EXPRESSION_OPTIONS_MAP.annualized,
  ADJUSTMENT_EXPRESSION_OPTIONS_MAP.financial_t1a,
  ADJUSTMENT_EXPRESSION_OPTIONS_MAP.financial_t3a,
  ADJUSTMENT_EXPRESSION_OPTIONS_MAP.financial_beginning_balance,
  ADJUSTMENT_EXPRESSION_OPTIONS_MAP.financial_ending_balance,
  ADJUSTMENT_EXPRESSION_OPTIONS_MAP.financial_debit,
  ADJUSTMENT_EXPRESSION_OPTIONS_MAP.financial_credit,
  ADJUSTMENT_EXPRESSION_OPTIONS_MAP.period_to_period_variance,
  ADJUSTMENT_EXPRESSION_OPTIONS_MAP.period_to_period_variance_percent,
  ADJUSTMENT_EXPRESSION_OPTIONS_MAP.period_to_period_variance_flipped,
  ADJUSTMENT_EXPRESSION_OPTIONS_MAP.period_to_period_variance_flipped_percent,
  ADJUSTMENT_EXPRESSION_OPTIONS_MAP.year_to_year_variance,
  ADJUSTMENT_EXPRESSION_OPTIONS_MAP.year_to_year_variance_percent,
  ADJUSTMENT_EXPRESSION_OPTIONS_MAP.year_to_year_variance_flipped,
  ADJUSTMENT_EXPRESSION_OPTIONS_MAP.year_to_year_variance_flipped_percent,
] satisfies ADJUSTMENT_EXPRESSION_OPTIONS;
const OVERRIDE_SOURCE_SCHEMA = yup.object().shape({
  source: yup.string().oneOf(OVERRIDE_SOURCES),
  override_to: yup.string(),
});
export const ADJUSTMENT_EXPRESSION_SCHEMA = yup.object().shape({
  name: yup.string().oneOf(Object.values(ADJUSTMENTS)).nullable(),
  source_overrides: yup
    .array()
    .of(OVERRIDE_SOURCE_SCHEMA)
    .optional()
    .nullable(),
});
export const ADJUSTMENT_EXPRESSION_ONE_SCHEMA = yup
  .string()
  .oneOf([...Object.values(ADJUSTMENTS), ADJUSTMENT_EXPRESSION_VALUE_ACTUAL]);

export type AdjustmentExpressionForm = yup.InferType<
  typeof ADJUSTMENT_EXPRESSION_SCHEMA
>;
