/* eslint-disable @typescript-eslint/no-unsafe-return */
import { useMemo, useState } from 'react';
import { useReportTableRowData } from 'bundles/Shared/components/ReportTable/helpers/hooks/useReportTableRowData';
import { ColDef } from 'ag-grid-community';
import { CurrencyCellRenderer } from 'bundles/Shared/components/AgGrid/Table/cellComponents/CurrencyCellRenderer';
import {
  BasicHeaderComponent,
  HeaderComponent,
} from 'bundles/Shared/components/AgGrid/Table/cellComponents/HeaderComponent';
import { autoGroupValueFormatter } from 'bundles/Shared/components/ReportTable/utils';
import { SymmetreAgGridTable } from '@/bundles/Shared/components/AgGrid/Table/SymmetreAgGridTable';
import { getEmptyCellRendererParams } from 'bundles/Shared/components/AgGrid/Table/utils/getCurrencyCellRendererProps';
import { useAppSelector } from '@/shared/lib/hooks/redux';
import {
  selectExpandAccountsPayable,
  selectExpandBudget,
  selectExpandFundedRequisitions,
} from '@/bundles/REconcile/components/development/requisitions/shared/requisitionsTable/slices/RequisitionsTableSlice';
import {
  getCollapseBudgetColumns,
  getExpandBudgetColumns,
} from '@/bundles/REconcile/components/development/requisitions/shared/requisitionsTable/columns/budgetColumns';
import {
  getCollapseAccountsPayableColumns,
  getExpandAccountsPayableColumns,
} from '@/bundles/REconcile/components/development/requisitions/shared/requisitionsTable/columns/accountsPayableColumns';
import { getVarianceColumns } from '@/bundles/REconcile/components/development/requisitions/shared/requisitionsTable/columns/varianceColumns';
import {
  getTotalFundedRequisitionColumn,
  useRequisitionColumns,
} from '@/bundles/REconcile/components/development/requisitions/shared/requisitionsTable/columns/requisitionsColumns';
import { useParams } from '@reach/router';
import { useGridDefaultParams } from 'lib/ag-grid/useGridDefaultParams';
import { LegalEntity } from '@/entities/core/legalEntity';
import { CustomAutoGroupCellRenderer } from 'bundles/Shared/components/AgGrid/Table/cellComponents/BasicAutoGroupCellRenderer';
import {
  isRootNodeFooter,
  getNodeFooterParent,
  useAgGridRef,
} from 'lib/ag-grid/utils';

const MIN_COL_WIDTH = 124;
const MIN_AUTO_GROUP_WIDTH = 280;

export const ContextActionsMenu = ({
  actions,
}: {
  actions: {
    label: string;
    onClick: VoidFunction;
    hidden?: boolean;
  }[];
}) => (
  <div className="label-regular flex flex-col gap-1.5 p-2">
    <div className="uppercase text-neutral-500">Actions</div>
    <div className="flex flex-col gap-1">
      {actions
        .filter((action) => !action.hidden)
        .map((action) => (
          <div
            key={action.label}
            className="cursor-pointer text-neutral-900"
            onClick={action.onClick}
          >
            {action.label}
          </div>
        ))}
    </div>
  </div>
);

export function RequisitionsTable({
  legalEntityCode,
}: {
  legalEntityCode: LegalEntity['code'];
}) {
  const routeParams = useParams();
  const gridRef = useAgGridRef();
  const expandBudget = useAppSelector(selectExpandBudget);
  const expandAccountsPayable = useAppSelector(selectExpandAccountsPayable);
  const expandFundedRequisitions = useAppSelector(
    selectExpandFundedRequisitions,
  );
  const [showAccountPayable, setShowAccountPayable] = useState(false);

  const requisitions = useAppSelector(
    (state) => state.developmentBudgetVariance.data.requisitions,
  );

  const rows = useAppSelector(
    (state) => state.developmentBudgetVariance.data.rows,
  );

  const { onGridReady } = useGridDefaultParams({
    enableExpandOnClick: true,
  });

  const autoGroupColumnDef = useMemo<ColDef>(
    () => ({
      flex: 1,
      pinned: true,
      resizable: true,
      headerName: 'Categories',
      suppressMenu: true,
      minWidth: MIN_AUTO_GROUP_WIDTH,
      initialWidth: MIN_AUTO_GROUP_WIDTH,
      headerComponent: (params) => (
        <BasicHeaderComponent {...params}>
          <div className="flex h-full w-full items-end py-2">Categories</div>
        </BasicHeaderComponent>
      ),
      headerComponentParams: {
        subHeaderName: '',
      },
      cellRenderer: CustomAutoGroupCellRenderer,
      cellRendererParams: {
        classes: {
          footerClassName:
            'uppercase font-semibold text-neutral-500 border-neutral-100',
          parentItemClassName:
            'uppercase text-neutral-900 font-semibold border-neutral-100',
          mainTotalClassName:
            'bg-green border-transparent text-white font-semibold uppercase',
        },
      },
      valueGetter: (params) => {
        const { node } = params;

        if (node?.footer) {
          if (isRootNodeFooter(node)) return 'Total';
          const parent = getNodeFooterParent(node);
          if (!parent) return '';
          return `Total ${parent.data?.label}`;
        }

        return autoGroupValueFormatter(params);
      },
    }),
    [],
  );

  const rowData = useReportTableRowData(rows);

  const totalFundedRequisitionsColums = getTotalFundedRequisitionColumn();
  const fundedRequisitionsColumns = useRequisitionColumns(
    requisitions?.filter((requisition) => requisition.funded) ?? [],
    routeParams?.legalEntityCode ?? legalEntityCode,
  );

  const requisitionsColumns = useRequisitionColumns(
    requisitions?.filter((requisition) => !requisition.funded) ?? [],
    routeParams?.legalEntityCode ?? legalEntityCode,
  );

  const columnDefs = () => [
    expandBudget ? getExpandBudgetColumns() : getCollapseBudgetColumns(),
    ...(expandFundedRequisitions
      ? fundedRequisitionsColumns
      : [totalFundedRequisitionsColums]),
    ...requisitionsColumns,
    {
      headerName: '',
      flex: 1,
    },
    expandAccountsPayable
      ? getExpandAccountsPayableColumns()
      : getCollapseAccountsPayableColumns(legalEntityCode),
    getVarianceColumns(showAccountPayable, setShowAccountPayable),
  ];

  const defaultColDef = useMemo<ColDef>(
    () => ({
      flex: 1,
      cellRenderer: CurrencyCellRenderer,
      cellRendererParams: getEmptyCellRendererParams,
      headerComponent: HeaderComponent,
      suppressMovable: true,
      suppressMenu: true,
      minWidth: MIN_COL_WIDTH,
      width: MIN_COL_WIDTH,
    }),
    [],
  );

  return (
    <SymmetreAgGridTable
      onGridReady={onGridReady}
      groupDefaultExpanded={2}
      ref={gridRef}
      defaultColDef={defaultColDef}
      rowData={rowData}
      columnDefs={columnDefs()}
      autoGroupColumnDef={autoGroupColumnDef}
      headerHeight={48}
    />
  );
}
