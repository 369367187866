import { TextEditor } from '@symmetre-web/text-editor';
import { TOOLBAR_GROUPS_VARIABLES_AND_ALIGNMENTS } from '@/bundles/Settings/components/EmailSettings/utils/consts';

interface Variable {
  id: number;
  name_in_text: string;
  variable_id: number;
}

interface ToolbarGroupConfig {
  name: string;
  items: (
    | 'text'
    | 'color'
    | 'fontSize'
    | 'heading'
    | 'link'
    | 'list'
    | 'indent'
    | 'align'
    | 'rule'
    | 'blockquote'
    | 'undoRedo'
    | 'variable'
  )[];
}
interface ToolbarGroupConfig {
  name: string;
  items: string[];
}

const TemplateEditor: React.FC<{
  text: string | undefined;
  setText: (text: string) => void;
  variables: Variable[];
  placeholder: string;
  onReady?: (editor: unknown) => void;
  toolbarGroups?: ToolbarGroupConfig[];
  onChangeStart?: (editor: unknown) => void;
  usedVariables?: Variable[];
  setUsedVariables?: (vars: Variable[]) => void;
  forceHeading?: boolean;
}> = ({
  text,
  setText,
  variables,
  placeholder,
  toolbarGroups = TOOLBAR_GROUPS_VARIABLES_AND_ALIGNMENTS,
  forceHeading = false,
}) => {
  return (
    <div>
      {variables.length > 0 && (
        <TextEditor
          placeholder={placeholder}
          value={text || ''}
          onChange={setText}
          toolbarGroups={toolbarGroups}
          variables={variables}
          disableStylingKeystrokes
          forceHeading={forceHeading}
        />
      )}
    </div>
  );
};

export default TemplateEditor;
