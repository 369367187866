/* eslint-disable max-len */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import {
  PAGES_EXCEPTION_WITH_MAIN_MENU,
  PAGES_WITHOUT_MAIN_MENU,
} from '@/app/config';
import { isPathReportBuilderView } from '@/app/lib/reportBuilderPages';
import { UseDesktopVersionScreen } from '@/app/ui/UseDesktopVersionScreen';
import Footer from '@/app/ui/footer/Footer';
import Impersonation from '@/entities/impersonation/ui/Impersonation';
import AssetDashboardPage from '@/pages/assets/page';
import { ComparisonModePage } from '@/pages/comparisonDashboards/page';
import { currentUserCanSeeComparisonPage } from '@/pages/comparisonDashboards/permissions';
import DocumentationPage from '@/pages/documentation';
import DocumentsPage from '@/pages/documents';
import HomePage from '@/pages/page';
import { PortfolioPage } from '@/pages/portfolio/Portfolio';
import ForecastingBudgetPage from '@/pages/reconcile/forecasting/budget';
import ForecastingBudgetsPage from '@/pages/reconcile/forecasting/budgets';
import ForecastPage from '@/pages/reconcile/forecasting/forecast';
import ForecastingPage from '@/pages/reconcile/forecasting/page';
import { ReportObjectLevelDashboard } from '@/pages/report/dashboards/[dashboardId]';
import { ReportBuilderTemplatePage } from '@/pages/reportBuilderTemplate/[reportBuilderTemplateId]/page';
import { currentUserAllowedToSeeReporingTemplatePage } from '@/pages/reportBuilderTemplate/[reportBuilderTemplateId]/permissions';
import { ReportBuilderTemplateViewPage } from '@/pages/reportBuilderTemplate/[reportBuilderTemplateId]/view/page';
import { SettingsReportBuilderTemplateWidgetPage } from '@/pages/reportBuilderTemplate/[reportBuilderTemplateId]/widgets/[widgetId]/page';
import { ReportBuilderTemplateEagleEyePage } from '@/pages/reportBuilderTemplate/eagleEye/[templateId]/page';
import { ReportBuilderTemplateEagleEyeViewPage } from '@/pages/reportBuilderTemplate/eagleEye/[templateId]/view/page';
import { SettingsReportBuilderTemplateEagleEyeWidgetPage } from '@/pages/reportBuilderTemplate/eagleEye/[templateId]/widgets/[widgetId]/page';
import { ReportingEagleEyeReportGroupPage } from '@/pages/reporting/eagleEye/[reportGroupId]/page';
import { CreateNewReportGroupPage } from '@/pages/reporting/new/page';
import { ReportingPage } from '@/pages/reporting/page';
import { currentUserAllowedToSeeReportingPage } from '@/pages/reporting/permissions';
import { EagleEyeGeneratedReportPage } from '@/pages/reports/report/eagleEye/generated/page';
import {
  EagleEyeReportPage,
  ObjectLevelReportPage,
} from '@/pages/reports/report/page';
import {
  EagleEyeReportViewPage,
  ObjectLevelReportViewPage,
} from '@/pages/reports/report/view/page';
import ScoreboardsPage from '@/pages/scoreboards';
import { SettingsReportConnectionPage } from '@/pages/settings/report/connections/connection/page';
import { currentUserAllowedToSeeConnectionsPages } from '@/pages/settings/report/connections/permissions';
import { ReportingTemplatesPage } from '@/pages/settings/report/reportTemplates/page';
import { currentUserAllowedToSeeReportTemplatesPage } from '@/pages/settings/report/reportTemplates/permissions';
import SharedFiles from '@/pages/shared-files';
import { cn } from '@/shared/lib/css/cn';
import { useAppSelector } from '@/shared/lib/hooks/redux';
import useBoolean from '@/shared/lib/hooks/useBoolean';
import useMediaQuery, {
  LARGE_WIDTH_MEDIA_QUERY,
} from '@/shared/lib/hooks/useMediaQuery';
import {
  allSubPathMatches,
  ROUTES_ROOT,
} from '@/shared/lib/hooks/useNavigation';
import AssetDashboardContent from '@/widgets/core/assets/AssetDashboardContent';
import { Spotlight } from '@/widgets/spotlight';
import {
  globalHistory,
  navigate,
  History as ReachRouterHistory,
  Redirect,
  Router,
} from '@reach/router';
import ChangeOrder from 'bundles/Construction/components/ChangeOrder';
import ChangeOrderExternal from 'bundles/Construction/components/ChangeOrder/ChangeOrderExternal';
import ChangeOrderEvent from 'bundles/Construction/components/ChangeOrderEvent';
import ApproveReject from 'bundles/Construction/components/ChangeOrderEvent/Mobile/ApproveReject/ApproveReject';
import Reallocation from 'bundles/Construction/components/Reallocation/Reallocation';
import Invoice from 'bundles/DrawPackage/InvoicesFlow/Invoices/Invoice';
import ApproveInvoice from 'bundles/DrawPackage/InvoicesFlow/Invoices/approve/ApproveInvoice';
import EditInvoice from 'bundles/DrawPackage/InvoicesFlow/Invoices/edit/EditInvoice';
import MarkAsPaidInvoice from 'bundles/DrawPackage/InvoicesFlow/Invoices/pay/MarkAsPaidInvoice';
import MarkForPaymentInvoice from 'bundles/DrawPackage/InvoicesFlow/Invoices/pay/MarkForPaymentInvoice';
import PayInvoice from 'bundles/DrawPackage/InvoicesFlow/Invoices/pay/PayInvoice';
import VerifyInvoice from 'bundles/DrawPackage/InvoicesFlow/Invoices/verify/VerifyInvoice';
import InvoicesWithRequisition from 'bundles/DrawPackage/InvoicesWithRequistion/InvoicesWithRequisition';
import PayableInvoices from 'bundles/DrawPackage/PayableInvoices/PayableInvoices';
import PayableInvoicesCategory from 'bundles/DrawPackage/PayableInvoicesCategory/PayableInvoicesCategory';
import { NotFound } from 'bundles/Errors';
import FireStationContainer from 'bundles/FireStation/containers/FireStationContainer';
import Notifications from 'bundles/Notifications/components/Notifications';
import DevelopmentBudgetEditor from 'bundles/REconcile/components/development/editor/DevelopmentBudgetEditor';
import ReconcileDevelopmentDashboard from 'bundles/REconcile/components/development/home/dashboard/ReconcileDevelopmentDashboard';
import LegalEntityReconcileSettingsModal from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/LegalEntityReconcileSettingsModal';
import LegalEntityReconcileSettingsWorkflowApproversPage from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/workflow/approvers/LegalEntityReconcileSettingsWorkflowApproversPage';
import ReconcileDevelopmentSettingsModal from 'bundles/REconcile/components/development/home/settingsModal/ReconcileDevelopmentSettingsModal';
import TransactionLink from 'bundles/REconcile/components/development/requisitions/transactions/TransactionsLink';
import DevelopmentLegalEntity from 'bundles/REconcile/components/development/screen/DevelopmentLegalEntity';
import OperationalBudget from 'bundles/REconcile/components/operational/OperationalBudget';
import { ReconcileOperationalDashboard } from 'bundles/REconcile/components/operational/ReconcileOperationalDashboard';
import { BudgetComponent } from 'bundles/REconcile/underwritting/BudgetComponent';
import UnderwritingBudgetPage from 'bundles/REconcile/underwritting/UnderwritingBudgetPage';
import { ReportFinancials } from 'bundles/REport/components/financials';
import ReturnHomePage from 'bundles/REturn/components/Home/ReturnHomePage';
import { OwnershipContainer } from 'bundles/REturn/routes/OwnershipContainer';
import ResetPassword from 'bundles/Registration/ResetPassword';
import { DealItem } from 'bundles/Scoreboard/Pipeline/components/DealItem/DealItem';
import FundDashboardPage from 'bundles/Settings/components/Portal/FundDashboard';
import { FundDashboardContent } from 'bundles/Settings/components/Portal/FundDashboard/FundDashboardContent';
import Settings from 'bundles/Settings/containers/Settings';
import { useGenericData } from 'bundles/Shared/GenericContext';
import { useMenuItemsPermissions } from 'bundles/Shared/components/LeftSidebar/hooks/useMenuItemsPermissions';
import { isLeftSidebarPinned } from 'bundles/Shared/components/LeftSidebar/reducers/leftSidebarSlice';
import MainNav from 'bundles/Shared/components/Nav/MainNav';
import UserManagement from 'bundles/UserManagement/containers/UserManagement';
import UserManagementActivityPage from 'bundles/UserManagement/pages/UserManagementActivityPage';
import { initHotjarUser } from 'lib/hotjar';
import {
  ASSET_PORTAL_PRODUCT_NAME,
  currentUserAllowedTo,
  currentUserIsAdvisor,
  currentUserIsExternal,
  currentUserIsSreAdmin,
  FUND_PORTAL_PRODUCT_NAME,
  TProductNames,
  USER_MANAGEMENT_PRODUCT_NAME,
} from 'lib/permissions';
import { RouterComponentWithoutWrapper } from 'lib/reach-router';
import { asserts } from 'lib/typeHelpers/assertsType';
import { useEffect, useMemo, useState } from 'react';

const LoggedInApp = () => {
  const { genericData } = useGenericData();
  asserts(genericData, 'Generic data should be loaded before');

  const { currentUser, customer, impersonating } = genericData;
  const [path, setPath] = useState(window.location.pathname);
  const leftSidebarPinned = useAppSelector(isLeftSidebarPinned);
  const permissions = useMenuItemsPermissions();
  const isLargeWidth = useMediaQuery(LARGE_WIDTH_MEDIA_QUERY);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { value: allowedToSeeDesktop, setTrue: allowToSeeDesktop } =
    useBoolean(isLargeWidth);

  useEffect(() => {
    // todo replace with useLocation
    const listener: Parameters<ReachRouterHistory['listen']>[0] = ({
      location,
    }) => {
      setPath(location.pathname);
    };
    globalHistory.listen(listener);
    initHotjarUser({ currentUser, customer });

    // temporarly disable redirect for pdf generation
    // if (allowedToSeeDesktop) return;

    // if (pathIncludesPages(PAGES_WITH_SMALL_SCREEN_SUPPORT)) return;

    // navigate(generateUrl(ROUTES.root.useDesktopVersion.fullPath));
  }, []);

  const pathIncludesPages = (pages: readonly [...string[]]): boolean =>
    pages.some((pageRoute) => path.includes(pageRoute));

  const mainMenuIsVisible = useMemo(() => {
    if (pathIncludesPages(PAGES_EXCEPTION_WITH_MAIN_MENU)) {
      return true;
    }
    return !pathIncludesPages(PAGES_WITHOUT_MAIN_MENU);
  }, [path]);

  const canViewReconcileDevelopmentDashboard =
    currentUserAllowedTo('view', TProductNames.DEVELOPMENT_BUDGET) ||
    currentUserAllowedTo('view', TProductNames.CHANGE_MANAGEMENT);

  if (isPathReportBuilderView(path)) {
    return (
      <Router component={RouterComponentWithoutWrapper}>
        <ObjectLevelReportViewPage
          path={ROUTES_ROOT.reports.report.view.fullPath}
        />
        <EagleEyeReportViewPage
          path={ROUTES_ROOT.reports.eagleEye.report.view.fullPath}
        />
        <ReportBuilderTemplateViewPage
          path={
            ROUTES_ROOT.reportBuilderTemplates.reportBuilderTemplate.view
              .fullPath
          }
        />
        <ReportBuilderTemplateEagleEyeViewPage
          path={
            ROUTES_ROOT.reportBuilderTemplates.eagleEye.reportBuilderTemplate
              .view.fullPath
          }
        />
      </Router>
    );
  }

  return (
    <>
      {mainMenuIsVisible && <MainNav />}
      <div
        className={cn(
          'main-content flex-1 print:h-auto print:!min-h-[auto] print:p-0',
          {
            'pl-14': mainMenuIsVisible && !leftSidebarPinned,
            'main-content_pinned-sidebar':
              mainMenuIsVisible && leftSidebarPinned,
          },
        )}
      >
        <div
          className={cn(
            {
              'sm:content-wrap': mainMenuIsVisible,
            },
            'print:min-h-[auto]',
          )}
        >
          {currentUserIsSreAdmin() && <Spotlight />}
          <Router component={RouterComponentWithoutWrapper}>
            <ResetPassword
              path={ROUTES_ROOT.users.password.edit.fullPath}
              loggedIn
            />

            <DocumentationPage
              path={allSubPathMatches(ROUTES_ROOT.documentation.fullPath)}
            />
            <NotFound default />
            {/* NOTE: next line usign for old emails, should be deleted in 2-3 months */}
            <Redirect from="/user_impersonations/new/*" to="/" />
            <Redirect from="/users/*" to="/" />
            <UseDesktopVersionScreen
              path={ROUTES_ROOT.useDesktopVersion.fullPath}
              onViewDesktopVersionClick={() => {
                allowToSeeDesktop();
                navigate('/');
              }}
            />
            <HomePage path="/" />
            {currentUserIsExternal() && !currentUserIsAdvisor() && (
              <PortfolioPage path={ROUTES_ROOT.portfolio.fullPath} />
            )}

            <AssetDashboardPage path={ROUTES_ROOT.assets.fullPath}>
              <AssetDashboardContent
                path={allSubPathMatches(ROUTES_ROOT.assets.asset.path)}
              />
            </AssetDashboardPage>

            {currentUserAllowedToSeeReporingTemplatePage() && (
              <>
                <ReportBuilderTemplatePage
                  path={
                    ROUTES_ROOT.reportBuilderTemplates.reportBuilderTemplate
                      .fullPath
                  }
                />
                <ReportBuilderTemplateEagleEyePage
                  path={
                    ROUTES_ROOT.reportBuilderTemplates.eagleEye
                      .reportBuilderTemplate.fullPath
                  }
                />
                <SettingsReportBuilderTemplateWidgetPage
                  path={
                    ROUTES_ROOT.reportBuilderTemplates.reportBuilderTemplate
                      .widgets.widget.fullPath
                  }
                />
                <SettingsReportBuilderTemplateEagleEyeWidgetPage
                  path={
                    ROUTES_ROOT.reportBuilderTemplates.eagleEye
                      .reportBuilderTemplate.widgets.widget.fullPath
                  }
                />
              </>
            )}
            {currentUserAllowedToSeeReportingPage() && (
              <>
                <ReportingPage path={ROUTES_ROOT.reporting.fullPath} />
                <CreateNewReportGroupPage
                  path={ROUTES_ROOT.reporting.new.fullPath}
                />
                <ReportingEagleEyeReportGroupPage
                  path={ROUTES_ROOT.reporting.eagleEye.reportGroup.fullPath}
                />
                <EagleEyeGeneratedReportPage
                  path={ROUTES_ROOT.reports.eagleEye.report.generated.fullPath}
                />

                <ObjectLevelReportPage
                  path={ROUTES_ROOT.reports.report.fullPath}
                />
                <EagleEyeReportPage
                  path={ROUTES_ROOT.reports.eagleEye.report.fullPath}
                />
              </>
            )}

            {currentUserAllowedTo('view', FUND_PORTAL_PRODUCT_NAME) && (
              <FundDashboardPage path={ROUTES_ROOT.funds.fullPath}>
                <FundDashboardContent
                  path={allSubPathMatches(ROUTES_ROOT.funds.fund.path)}
                />
              </FundDashboardPage>
            )}

            <SharedFiles path="shared-files/:id" />
            <SharedFiles path="shared-files/trash/:id" showTrash />

            {currentUserAllowedTo('manage', ASSET_PORTAL_PRODUCT_NAME) && (
              <Settings
                path={allSubPathMatches(ROUTES_ROOT.settings.fullPath)}
              />
            )}

            {currentUserAllowedToSeeReportTemplatesPage() && (
              <ReportingTemplatesPage
                path={ROUTES_ROOT.settings.reporting.templates.fullPath}
              />
            )}
            {currentUserAllowedTo('manage', USER_MANAGEMENT_PRODUCT_NAME) && (
              <UserManagement path="user-management/*" />
            )}
            {currentUserIsSreAdmin() && (
              <UserManagementActivityPage
                path={ROUTES_ROOT.userManagement.activity.fullPath}
              />
            )}
            <DocumentsPage path={ROUTES_ROOT.documents.fullPath} />
            {currentUserAllowedTo('view', TProductNames.RETURN) &&
              !currentUserIsExternal() && (
                <ReturnHomePage path={ROUTES_ROOT.return.fullPath} />
              )}
            <OwnershipContainer
              path={allSubPathMatches(ROUTES_ROOT.return.object.fullPath)}
            />
            <Notifications path="notifications" />
            <ScoreboardsPage path="scoreboards/*" />
            {currentUserAllowedTo(
              'configure',
              TProductNames.CHANGE_MANAGEMENT,
            ) && (
              <>
                <LegalEntityReconcileSettingsModal
                  path={
                    ROUTES_ROOT.reconcile.development.legalEntity.settings
                      .fullPath
                  }
                />
                <LegalEntityReconcileSettingsWorkflowApproversPage
                  path={
                    ROUTES_ROOT.reconcile.development.legalEntity.settings
                      .approvers.fullPath
                  }
                />
              </>
            )}
            {permissions.reconcileVisible && (
              <>
                <ReconcileDevelopmentDashboard
                  path={ROUTES_ROOT.reconcile.development.fullPath}
                />
                <ReconcileOperationalDashboard
                  path={allSubPathMatches(
                    ROUTES_ROOT.reconcile.operational.fullPath,
                  )}
                />
              </>
            )}
            {currentUserIsSreAdmin() && (
              <>
                <UnderwritingBudgetPage
                  path={ROUTES_ROOT.reconcile.underwriting.fullPath}
                >
                  <BudgetComponent
                    path={ROUTES_ROOT.reconcile.underwriting.legalEntity.path}
                  />
                </UnderwritingBudgetPage>
                <ForecastingPage
                  path={ROUTES_ROOT.reconcile.forecasting.fullPath}
                >
                  <ForecastPage
                    path={ROUTES_ROOT.reconcile.forecasting.forecast.path}
                  />
                </ForecastingPage>
                <ForecastingBudgetsPage
                  path={ROUTES_ROOT.reconcile.forecasting.budgets.fullPath}
                >
                  <ForecastingBudgetPage
                    path={ROUTES_ROOT.reconcile.forecasting.budgets.budget.path}
                  />
                </ForecastingBudgetsPage>
              </>
            )}
            {currentUserAllowedTo(
              'configure',
              TProductNames.CHANGE_MANAGEMENT,
            ) && (
              <ReconcileDevelopmentSettingsModal
                path={ROUTES_ROOT.reconcile.developmentSettings.fullPath}
              />
            )}
            {canViewReconcileDevelopmentDashboard && (
              <DevelopmentLegalEntity
                path={allSubPathMatches(
                  ROUTES_ROOT.reconcile.development.legalEntity.fullPath,
                )}
              />
            )}
            {currentUserAllowedTo(
              'manage',
              TProductNames.DEVELOPMENT_BUDGET,
            ) && (
              <DevelopmentBudgetEditor
                path={
                  ROUTES_ROOT.reconcile.development.legalEntity.budgetEditor
                    .fullPath
                }
              />
            )}
            {currentUserAllowedTo(
              'manage',
              TProductNames.OPERATIONAL_BUDGET,
            ) && <OperationalBudget path="reconcile/operational/:id" />}
            <ReportObjectLevelDashboard
              path={ROUTES_ROOT.report.dashboards.fullPath}
            />
            {permissions.reportFinancialsVisible && (
              <ReportFinancials path={ROUTES_ROOT.report.financials.fullPath} />
            )}
            {currentUserCanSeeComparisonPage() && (
              <ComparisonModePage
                path={ROUTES_ROOT.report.comparisonDashboards.fullPath}
              />
            )}
            {permissions.fireStationVisible && (
              <FireStationContainer path="fire-station" />
            )}
            {currentUserAllowedTo('view', TProductNames.CHANGE_MANAGEMENT) && (
              <>
                <ChangeOrderEvent
                  path={
                    ROUTES_ROOT.reconcile.development.legalEntity.changeEvent
                      .fullPath
                  }
                />
                <ChangeOrder
                  path={
                    ROUTES_ROOT.reconcile.development.legalEntity.changeOrder
                      .fullPath
                  }
                />
                <Reallocation
                  path={
                    ROUTES_ROOT.reconcile.development.legalEntity.reallocation
                      .fullPath
                  }
                />
                <ApproveReject
                  path={
                    ROUTES_ROOT.reconcile.development.legalEntity.changeEvent
                      .mobile.fullPath
                  }
                />
              </>
            )}
            <ChangeOrderExternal
              path={
                ROUTES_ROOT.reconcile.development.legalEntity.changeOrder
                  .approve.fullPath
              }
            />
            {currentUserAllowedTo(
              'manage',
              TProductNames.DEVELOPMENT_BUDGET,
            ) && (
              <>
                <ApproveInvoice
                  path={
                    ROUTES_ROOT.reconcile.development.legalEntity.invoices
                      .approve.fullPath
                  }
                />
                <PayInvoice
                  path={
                    ROUTES_ROOT.reconcile.development.legalEntity.invoices.pay
                      .fullPath
                  }
                />
                <MarkForPaymentInvoice
                  path={
                    ROUTES_ROOT.reconcile.development.legalEntity.invoices.pay
                      .markForPayment.fullPath
                  }
                />
                <MarkAsPaidInvoice
                  path={
                    ROUTES_ROOT.reconcile.development.legalEntity.invoices.pay
                      .markAsPaid.fullPath
                  }
                />
                <EditInvoice
                  path={
                    ROUTES_ROOT.reconcile.development.legalEntity.invoices.edit
                      .fullPath
                  }
                />
                <VerifyInvoice
                  path={
                    ROUTES_ROOT.reconcile.development.legalEntity.invoices
                      .verify.fullPath
                  }
                />

                <PayableInvoices
                  path={allSubPathMatches(
                    ROUTES_ROOT.reconcile.development.legalEntity
                      .payableInvoices.fullPath,
                  )}
                >
                  <Invoice
                    requisitionFlow
                    payableFlow
                    backRoute="RECONCILE_DEVELOPMENT_LEGAL_ENTITY_PAYABLE_INVOICES"
                    path="/:id"
                  />
                </PayableInvoices>
                <InvoicesWithRequisition
                  primary={false}
                  path={allSubPathMatches(
                    ROUTES_ROOT.reconcile.development.legalEntity
                      .requisitionInvoices.requisition.fullPath,
                  )}
                >
                  <Invoice
                    requisitionFlow
                    routingV2
                    backRoute={
                      ROUTES_ROOT.reconcile.development.legalEntity
                        .requisitionInvoices.requisition.fullPath
                    }
                    path="/:id"
                  />
                </InvoicesWithRequisition>
                <PayableInvoicesCategory
                  path={allSubPathMatches(
                    ROUTES_ROOT.reconcile.development.legalEntity
                      .payableInvoices.category.fullPath,
                  )}
                >
                  <Invoice
                    requisitionFlow
                    payableFlow
                    backRoute="RECONCILE_DEVELOPMENT_LEGAL_ENTITY_PAYABLE_INVOICES_CATEGORY"
                    path="/:id"
                  />
                </PayableInvoicesCategory>
                <TransactionLink
                  path={allSubPathMatches(
                    ROUTES_ROOT.reconcile.development.legalEntity.requisitions
                      .requisition.lineItems.transactions.fullPath,
                  )}
                />
              </>
            )}
            <DealItem path={ROUTES_ROOT.deals.fullPath} />
            {currentUserAllowedTo(
              'manage',
              TProductNames.DEVELOPMENT_BUDGET,
            ) && (
              <>
                <ApproveInvoice
                  path={
                    ROUTES_ROOT.reconcile.development.legalEntity.invoices
                      .approve.fullPath
                  }
                />
                <PayInvoice
                  path={
                    ROUTES_ROOT.reconcile.development.legalEntity.invoices.pay
                      .fullPath
                  }
                />
                <MarkForPaymentInvoice
                  path={
                    ROUTES_ROOT.reconcile.development.legalEntity.invoices.pay
                      .markForPayment.fullPath
                  }
                />
                <MarkAsPaidInvoice
                  path={
                    ROUTES_ROOT.reconcile.development.legalEntity.invoices.pay
                      .markAsPaid.fullPath
                  }
                />
                <EditInvoice
                  path={
                    ROUTES_ROOT.reconcile.development.legalEntity.invoices.edit
                      .fullPath
                  }
                />
                <VerifyInvoice
                  path={
                    ROUTES_ROOT.reconcile.development.legalEntity.invoices
                      .verify.fullPath
                  }
                />

                <PayableInvoices
                  path={allSubPathMatches(
                    ROUTES_ROOT.reconcile.development.legalEntity
                      .payableInvoices.fullPath,
                  )}
                >
                  <Invoice
                    requisitionFlow
                    payableFlow
                    backRoute="RECONCILE_DEVELOPMENT_LEGAL_ENTITY_PAYABLE_INVOICES"
                    path="/:id"
                  />
                </PayableInvoices>
                <InvoicesWithRequisition
                  primary={false}
                  path={allSubPathMatches(
                    ROUTES_ROOT.reconcile.development.legalEntity
                      .requisitionInvoices.requisition.fullPath,
                  )}
                >
                  <Invoice
                    requisitionFlow
                    routingV2
                    backRoute={
                      ROUTES_ROOT.reconcile.development.legalEntity
                        .requisitionInvoices.requisition.fullPath
                    }
                    path="/:id"
                  />
                </InvoicesWithRequisition>
                <PayableInvoicesCategory
                  path={allSubPathMatches(
                    ROUTES_ROOT.reconcile.development.legalEntity
                      .payableInvoices.category.fullPath,
                  )}
                >
                  <Invoice
                    requisitionFlow
                    payableFlow
                    backRoute="RECONCILE_DEVELOPMENT_LEGAL_ENTITY_PAYABLE_INVOICES_CATEGORY"
                    path="/:id"
                  />
                </PayableInvoicesCategory>
                <TransactionLink
                  path={allSubPathMatches(
                    ROUTES_ROOT.reconcile.development.legalEntity.requisitions
                      .requisition.lineItems.transactions.fullPath,
                  )}
                />
              </>
            )}
            <DealItem path={ROUTES_ROOT.deals.fullPath} />
            {currentUserAllowedToSeeConnectionsPages() && (
              <SettingsReportConnectionPage
                path={
                  ROUTES_ROOT.settings.report.connections.connection.fullPath
                }
              />
            )}
          </Router>
        </div>
        <Footer />
      </div>
      {impersonating && <Impersonation currentUser={currentUser} />}
    </>
  );
};

export default LoggedInApp;
