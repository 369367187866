import { useNavigateBack } from '@/shared/lib/hooks/navigation';
import useDebounce from '@/shared/lib/hooks/useDebounce';
import { useModal } from '@/shared/lib/hooks/useModal';
import { ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import { BuilderUI } from '@/shared/ui/BuilderUI';
import { GrowDiv } from '@/shared/ui/GrowDiv';
import { useNavigate, type RouteComponentProps } from '@reach/router';
import { plainFetchReplyRecipients } from 'bundles/Settings/actions/customTemplates';
import { useEffect, useState, useMemo } from 'react';
import { AnimationLoader } from 'stories/AnimationLoader/AnimationLoader';
import { Button } from 'stories/Button/Button';
import { IUserRole } from 'types/User';
import {
  createInvitationTemplate,
  getInvitationTemplate,
  invitationTemplatePreviews,
  invitationTemplateRoles,
  invitationTemplateVariables,
  removeInvitationTemplate,
  updateInvitationTemplate,
} from '@/bundles/Settings/actions/invitationTemplates';
import InvitationTemplateForm from '@/bundles/Settings/components/EmailSettings/forms/InvitationTemplateForm';
import TemplatePreview from '@/bundles/Settings/components/EmailSettings/TemplatePreview';
import { canSaveTemplate } from '@/bundles/Settings/components/EmailSettings/utils/canSaveTemplate';
import { initInvitationTemplateVars } from '@/bundles/Settings/components/EmailSettings/utils/consts';
import * as Type from '@/bundles/Settings/components/EmailSettings/editor/types';
import { extractVariables } from '@/bundles/Settings/components/EmailSettings/utils/utils';
interface Props {
  invitationTemplateId?: number;
}

const InvitationTemplateEdit = ({
  invitationTemplateId,
}: RouteComponentProps<Props>) => {
  const navigate = useNavigate();
  const [template, setTemplate] = useState<Type.InvitationTemplateState | null>(
    null,
  );
  const { confirm } = useModal();
  const [isDefault, setIsDefault] = useState(true);
  const [roles, setRoles] = useState<IUserRole[]>([]);
  const [selectedRoles, setSelectedRoles] = useState<IUserRole[]>([]);
  const [replyRecipients, setReplyRecipients] = useState<Type.ReplyRecipient[]>(
    [],
  );
  const [variables, setVariables] = useState(initInvitationTemplateVars);
  const [isLoading, setIsLoading] = useState(false);
  const [isPreviewLoading, setIsPreviewLoading] = useState(false);
  const [templatePreview, setTemplatePreview] = useState<RawHTML | undefined>();
  const debouncedTemplate = useDebounce(template, 500);

  const { navigateBack } = useNavigateBack({
    fallbackUrl: ROUTES_ROOT.settings.emails.templates.invitations.fullPath,
  });

  useEffect(() => {
    const fetch = async () => {
      setIsLoading(true);

      const [
        invitationTemplateJson,
        rolesJson,
        variablesJson,
        replyRecipientsJson,
      ] = await Promise.all([
        invitationTemplateId !== undefined
          ? getInvitationTemplate(invitationTemplateId)
          : null,
        invitationTemplateRoles(invitationTemplateId),
        invitationTemplateVariables(),
        plainFetchReplyRecipients(),
      ]);

      setReplyRecipients(replyRecipientsJson);

      if (invitationTemplateJson !== null) {
        setTemplate({
          id: invitationTemplateJson.id,
          subject: invitationTemplateJson.subject,
          greeting: invitationTemplateJson.rawGreeting,
          body: invitationTemplateJson.rawBody,
          acceptInvitation: invitationTemplateJson.rawAcceptInvitation,
          additionalInfo: invitationTemplateJson.rawAdditionalInfo,
          replyRecipients: invitationTemplateJson.replyRecipients,
        });
        setIsDefault(invitationTemplateJson.default);

        setSelectedRoles(invitationTemplateJson.selectedRoles);
      }
      setRoles(rolesJson ?? []);
      setVariables({
        greeting: variablesJson.greeting,
        body: variablesJson.body,
        acceptInvitation: variablesJson.accept_invitation,
        additionalInfo: variablesJson.additional_info,
      });
      setIsLoading(false);
    };
    fetch();
  }, []);

  const fetchUsedVariables = (t: Type.InvitationTemplateState) => {
    const greetingVars = extractVariables(t.greeting, variables.greeting);
    const bodyVars = extractVariables(t.body, variables.body);
    const acceptInvitationVars = extractVariables(
      t.acceptInvitation,
      variables.acceptInvitation,
    );
    const additionalInfoVars = extractVariables(
      t.additionalInfo,
      variables.additionalInfo,
    );

    return {
      acceptInvitation: acceptInvitationVars,
      body: bodyVars,
      greeting: greetingVars,
      additionalInfo: additionalInfoVars,
    };
  };

  const usedVariables = useMemo(() => {
    if (template === null) return initInvitationTemplateVars;

    return fetchUsedVariables(template);
  }, [template]);

  useEffect(() => {
    if (template === null) return;

    const fetch = async () => {
      setIsPreviewLoading(true);
      const previewJSON = await invitationTemplatePreviews({
        template,
        invitationTemplateId,
        usedVariables,
        selectedRolesIds: selectedRoles.map((r) => r.id),
      });
      setTemplatePreview(previewJSON);
      setIsPreviewLoading(false);
    };
    fetch();
  }, [debouncedTemplate]);

  const handleSubmit = async () => {
    if (template === null) return;
    const params: Parameters<typeof updateInvitationTemplate>[0] = {
      template,
      invitationTemplateId,
      usedVariables,
      selectedRolesIds: selectedRoles.map((r) => r.id),
    };
    const invitationTemplate = invitationTemplateId
      ? await updateInvitationTemplate(params)
      : await createInvitationTemplate(params);

    if (invitationTemplate.errors === undefined) {
      navigate(-1);
    }
  };

  const isPreviewReady = () => {
    if (isLoading || isPreviewLoading) return false;

    if (invitationTemplateId) {
      return debouncedTemplate != null && templatePreview != null;
    }

    return true;
  };

  const saveButtonEnabled = canSaveTemplate({
    isLoading,
    template,
    initialFieldsToCheck: ['acceptInvitation', 'greeting', 'subject', 'body'],
  });

  const handleNavigateBack = () => {
    navigateBack();
  };

  const handleDeleteTemplate = async (id: number) => {
    const bool = await confirm({
      title: 'Delete Invitation Template',
      subtitle: 'Are you sure you want to delete this template?',
    });
    if (!bool) return;

    await removeInvitationTemplate({ id });
    navigateBack();
  };

  return (
    <BuilderUI>
      <BuilderUI.Settings>
        <BuilderUI.Settings.Header>
          <BuilderUI.Settings.Header.BackIconButton
            disabled={isLoading}
            onClick={handleNavigateBack}
          />
          <BuilderUI.Settings.Header.TitleInfo
            subtitle="Invitations"
            title={template?.subject ?? 'Invitation Subject'}
          />
          {template?.id != null && !isDefault && (
            <BuilderUI.Settings.Header.Actions>
              <BuilderUI.Settings.Header.DeleteIconButton
                disabled={isLoading}
                onClick={() => handleDeleteTemplate(template.id)}
              />
            </BuilderUI.Settings.Header.Actions>
          )}
        </BuilderUI.Settings.Header>
        <BuilderUI.Settings.Main>
          <InvitationTemplateForm
            isLoading={isLoading}
            isDefault={isDefault}
            invitationTemplateId={invitationTemplateId}
            roles={roles}
            selectedRoles={selectedRoles}
            replyRecipients={replyRecipients}
            variables={variables}
            template={template ?? {}}
            onSelectedRolesChange={(newRoles) => setSelectedRoles(newRoles)}
            onTemplateChange={(newTemplate) => setTemplate(newTemplate)}
          />
        </BuilderUI.Settings.Main>
        <BuilderUI.Settings.Footer>
          <Button onClick={() => handleNavigateBack()}>Cancel</Button>
          <GrowDiv />
          <Button
            variant="success"
            size="m"
            disabled={!saveButtonEnabled}
            onClick={() => handleSubmit()}
            className="inline-semibold"
          >
            Save
          </Button>
        </BuilderUI.Settings.Footer>
      </BuilderUI.Settings>
      <BuilderUI.PdfPreview>
        {isPreviewReady() ? (
          <TemplatePreview
            subject={debouncedTemplate?.subject ?? 'Invitation'}
            templatePreview={templatePreview}
          />
        ) : (
          <AnimationLoader />
        )}
      </BuilderUI.PdfPreview>
    </BuilderUI>
  );
};

export default InvitationTemplateEdit;
