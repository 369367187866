import React, { useCallback, useMemo, useState } from 'react';
import { Input } from 'stories/FormControls/Inputs/Input/Input';
import { Popover } from 'stories/Popover/Popover';
import { DEFAULT_DROPDOWN_OFFSET } from 'stories/Popover/Popover';
import SplittedCheckList from 'stories/Checkbox/SplittedCheckList';
import { ListOption } from 'stories/Checkbox/CheckList';
import { includesInLowerCase } from '@/shared/lib/listHelpers';

interface Props<T> {
  items: T[];
  selectedItems: T[];
  onSelectedChange: (items: T[]) => void;
  getItemLabel: (item: T) => string;
  getItemId: (item: T) => string;
  inputProps?: Omit<React.ComponentProps<typeof Input>, 'onChange' | 'value'>;
  menuStyle?: React.CSSProperties;
  popoverProps?: Omit<
    React.ComponentProps<typeof Popover>,
    'template' | 'trigger'
  >;
  noItemsPlaceholder?: React.ReactNode;
}

function Autocomplete<T>({
  items,
  selectedItems,
  onSelectedChange,
  inputProps,
  getItemLabel,
  getItemId,
  menuStyle,
  popoverProps,
  noItemsPlaceholder,
}: Props<T>) {
  const [search, setSearch] = useState('');

  const mapToOption = useCallback(
    (item: T): ListOption => ({
      value: getItemId(item),
      label: getItemLabel(item),
    }),
    [getItemId, getItemLabel],
  );

  const filteredItems = useMemo(
    () =>
      items
        .map(mapToOption)
        .filter((o) => includesInLowerCase(o.label, search)),
    [items, search],
  );

  const template = useMemo(() => {
    if (filteredItems.length === 0) {
      return (
        noItemsPlaceholder ?? (
          <div className="light-60 inline-regular">No Items</div>
        )
      );
    }
    return (
      <div
        style={{
          maxHeight: '350px',
          overflow: 'auto',
          minWidth: 200,
          ...menuStyle,
        }}
      >
        <SplittedCheckList
          value={selectedItems.map(mapToOption)}
          onChange={(newItems) =>
            onSelectedChange(
              newItems.map(
                (o) => items.find((item) => getItemId(item) === o.value)!,
              ),
            )
          }
          items={filteredItems}
        />
      </div>
    );
  }, [filteredItems, mapToOption]);

  return (
    <Popover
      trigger="click"
      hiddenArrow
      maxWidth="auto"
      placement="bottom-start"
      offset={DEFAULT_DROPDOWN_OFFSET}
      template={template}
      {...popoverProps}
    >
      <Input
        rightIcon="search"
        placeholder="Search"
        size="s"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        {...inputProps}
      />
    </Popover>
  );
}

export default Autocomplete;
