import { uniqueId } from 'lodash-es';
import { BaseCategoryRule, IAllRules } from 'types/ReportTableConfig';
import { IUser, IUserRole } from 'types/User';
import {
  RoleRule,
  UserRule,
} from '@/bundles/Settings/components/REport/TableBuilder/CategoryRules/types';
import { AccessType } from '@/bundles/Settings/components/REport/TableBuilder/types';
import { ACCESS_TYPE_OPTIONS } from '@/bundles/Settings/components/REport/TableBuilder/helpers/constants';

export function updateCategoryRules<
  T extends Pick<BaseCategoryRule, 'categoryPath'>,
>(prevRules: T[], newRules: T[], categoryPath: string) {
  const didCategoryHaveRules = Boolean(
    prevRules.find((rule) => rule.categoryPath === categoryPath),
  );

  if (!didCategoryHaveRules) {
    return [...prevRules, ...newRules];
  }

  const filteredRules = prevRules.filter(
    (rule) => rule.categoryPath !== categoryPath,
  );
  return [...filteredRules, ...newRules];
}

export const findAccessType = (type: AccessType | null) =>
  ACCESS_TYPE_OPTIONS.find(({ id }) => type === id);

export const createRoleRuleId = () => uniqueId('user-role-rule-id');
export const createUserRuleId = () => uniqueId('user-rule-id');

export const createRoleRuleItemState = (): RoleRule => ({
  id: createRoleRuleId(),
  roleId: null,
  accessType: null,
});

export const createUserRuleItemState = (): UserRule => ({
  id: createUserRuleId(),
  userId: null,
  accessType: null,
});

export function createRoleRulesState(
  rules: IAllRules['roleRules'],
): RoleRule[] {
  return rules.map((rule) => ({
    id: createRoleRuleId(),
    roleId: rule.userRole.id,
    accessType: rule.accessType,
  }));
}

export function createUserRulesState(
  rules: IAllRules['userRules'],
): UserRule[] {
  return rules.map((rule) => ({
    id: createUserRuleId(),
    userId: rule.user.id,
    accessType: rule.accessType,
  }));
}

const getRoleIdAndName = ({
  id,
  name,
}: IUserRole): Pick<IUserRole, 'id' | 'name'> => ({
  id,
  name,
});

const getUserIdAndFullName = ({
  id,
  fullName,
}: IUser): Pick<IUser, 'id' | 'fullName'> => ({
  id,
  fullName,
});

export function prepareRoleRulesState(
  roleRules: RoleRule[],
  allRoles: IUserRole[],
  categoryPath: string,
): IAllRules['roleRules'] {
  return roleRules.map((rule) => ({
    categoryPath,
    userRole: getRoleIdAndName(
      allRoles.find((role) => role.id === rule.roleId)!,
    ),
    accessType: rule.accessType!,
  }));
}

export function prepareUserRulesState(
  userRules: UserRule[],
  allUsers: IUser[],
  categoryPath: string,
): IAllRules['userRules'] {
  return userRules.map((rule) => ({
    categoryPath,
    user: getUserIdAndFullName(
      allUsers.find((user) => user.id === rule.userId)!,
    ),
    accessType: rule.accessType!,
  }));
}
