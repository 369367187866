import {
  AllWidgetTypes,
  EAGLE_EYE_DASHBOARD_SECTION_TYPE,
  OBJECT_DASHBOARD_SECTION_TYPE,
} from 'bundles/Shared/entities/dashboard/model/types/types';
import { useId } from 'react';
import { ReactComponent as WidgetSearchPreviewSvg } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/previews/Search.svg';
import { ReactComponent as WidgetSearchPreviewSvgHovered } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/previews/Search_hover.svg';
import { ReactComponent as Circle01 } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/previews/circle_01.svg';
import { ReactComponent as Circle01Hovered } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/previews/circle_02.svg';
import { ReactComponent as RactA01Hovered } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/previews/rect_A_02-1.svg';
import { ReactComponent as RactA01 } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/previews/rect_A_02.svg';
import { ReactComponent as RectB01 } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/previews/rect_B_01.svg';
import { ReactComponent as RectB01Hovered } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/previews/rect_B_02.svg';

import { ReactComponent as BarChartWidgetPreview } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/previews/Bar_Chart.svg';
import { ReactComponent as BarChartWidgetPreviewHovered } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/previews/Bar_Chart_hover.svg';
import { ReactComponent as HistoricalTableWidgetPreview } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/previews/Historical_Table.svg';
import { ReactComponent as HistoricalTableWidgetPreviewHovered } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/previews/Historical_Table_hover.svg';
import { ReactComponent as KpiCardWidgetPreview } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/previews/KPI_Card.svg';
import { ReactComponent as KpiCardWidgetPreviewHovered } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/previews/KPI_Card_hover.svg';
import { ReactComponent as LineChartWidgetPreview } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/previews/Line_Chart.svg';
import { ReactComponent as LineChartWidgetPreviewHovered } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/previews/Line_Chart_hover.svg';

import { cn } from '@/shared/lib/css/cn';

const WIDGET_PREVIEW_TABLE_SVGS = [
  HistoricalTableWidgetPreview,
  HistoricalTableWidgetPreviewHovered,
] as const;

const BAR_CHART_SVGS = [
  BarChartWidgetPreview,
  BarChartWidgetPreviewHovered,
] as const;

const LINE_CHART_SVGS = [
  LineChartWidgetPreview,
  LineChartWidgetPreviewHovered,
] as const;

const WIDGET_PREVIEW_SVGS_MAP = {
  [OBJECT_DASHBOARD_SECTION_TYPE.HISTORICAL_REVIEW_TABLE]:
    WIDGET_PREVIEW_TABLE_SVGS,
  [OBJECT_DASHBOARD_SECTION_TYPE.RECENT_RENT_ROLL_TABLE]:
    WIDGET_PREVIEW_TABLE_SVGS,
  [OBJECT_DASHBOARD_SECTION_TYPE.DELINQUENCY_TRACKER_TABLE]:
    WIDGET_PREVIEW_TABLE_SVGS,
  [OBJECT_DASHBOARD_SECTION_TYPE.DELINQUENCY_AVERAGE_COLUMN_CHART]:
    BAR_CHART_SVGS,
  [OBJECT_DASHBOARD_SECTION_TYPE.XY_CHART]: LINE_CHART_SVGS,
  [OBJECT_DASHBOARD_SECTION_TYPE.AVERAGE_ACTUAL_RENT_CHART]: LINE_CHART_SVGS,
  [OBJECT_DASHBOARD_SECTION_TYPE.DELINQUENCY_SNAPSHOT_COLUMN_CHART]:
    BAR_CHART_SVGS,
  [OBJECT_DASHBOARD_SECTION_TYPE.KPI_CARD]: [
    KpiCardWidgetPreview,
    KpiCardWidgetPreviewHovered,
  ],
  [OBJECT_DASHBOARD_SECTION_TYPE.FINANCIAL_TABLE_SINGLE_DATE]:
    WIDGET_PREVIEW_TABLE_SVGS,
  [OBJECT_DASHBOARD_SECTION_TYPE.MASTER_UNIT_TABLE]: WIDGET_PREVIEW_TABLE_SVGS,
  [EAGLE_EYE_DASHBOARD_SECTION_TYPE.KPI_TABLE]: WIDGET_PREVIEW_TABLE_SVGS,
  [EAGLE_EYE_DASHBOARD_SECTION_TYPE.KPI_TABLE_SINGLE_DATE]:
    WIDGET_PREVIEW_TABLE_SVGS,
} as const satisfies Partial<
  Record<AllWidgetTypes, typeof WIDGET_PREVIEW_TABLE_SVGS>
>;

export const WidgetSearchPreview = ({ onClick }: { onClick?: () => void }) => (
  <div onClick={onClick} className="group relative cursor-pointer">
    <RactA01 className="absolute -top-1.5 right-0 group-hover:hidden" />
    <RectB01 className="absolute -bottom-2 left-2 group-hover:hidden" />
    <Circle01 className="absolute left-2 top-1 group-hover:hidden" />
    <RactA01Hovered
      style={{
        filter: 'drop-shadow(0px 4px 4px rgba(254, 190, 70, 0.30))',
      }}
      className="absolute -right-2 -top-1.5 hidden group-hover:block"
    />
    <RectB01Hovered
      style={{
        filter: 'drop-shadow(0px 4px 4px rgba(44, 174, 76, 0.30))',
      }}
      className="absolute -bottom-2 -left-1.5 hidden group-hover:block"
    />
    <Circle01Hovered
      style={{
        filter: 'drop-shadow(0px 4px 4px rgba(0, 146, 238, 0.3))',
      }}
      className="absolute -top-1.5 left-0 hidden group-hover:block "
    />
    <WidgetSearchPreviewSvgHovered className="hidden overflow-hidden rounded-[14px] group-hover:block" />
    <WidgetSearchPreviewSvg className="block overflow-hidden rounded-[14px] group-hover:hidden" />
  </div>
);

export function WidgetPreview({
  type,
  classes,
}: {
  type?: AllWidgetTypes;
  classes?: {
    hovered?: string;
    default?: string;
  };
}) {
  const [Svg, HoveredSvg] =
    WIDGET_PREVIEW_SVGS_MAP[type] ?? WIDGET_PREVIEW_TABLE_SVGS;
  const id = useId();
  return (
    <div className="group relative">
      <HoveredSvg
        className={cn(
          'absolute top-0 rounded-[14px] opacity-0 transition-all duration-500 group-hover:opacity-100',
          classes?.hovered,
        )}
      />
      <Svg
        id={id}
        className={cn(
          'overflow-hidden rounded-[14px] transition-all',
          classes?.default,
        )}
      />
    </div>
  );
}
