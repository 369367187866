import React, { useState } from 'react';
import CostBreakdownDetails from 'bundles/Construction/components/ChangeOrderEvent/CostBreakdownDetails';
import { useAppSelector } from '@/shared/lib/hooks/redux';
import { selectChangeEvent } from 'bundles/Construction/reducers/ReconcileChangeEventSlice';
import {
  IActionsHints,
  IVerifySteps,
} from '@/bundles/Construction/components/ChangeOrderEvent';

interface Props {
  verifyStep: IVerifySteps;
  actionsHints: IActionsHints;
}

function CostBreakdown({ verifyStep, actionsHints }: Props) {
  const changeEvent = useAppSelector(selectChangeEvent);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const editOnly = changeEvent?.costBreakdownConfirmed;
  return (
    <div className="change-card overflow-hidden">
      <div className="change-card__header change-card__header_line">
        <div className="change-card__title header6-bold pb-m">
          Cost Breakdown
        </div>
        {!editOnly && (
          <div
            className="change-card__button inline-regular"
            onClick={() => {
              setShowModalEdit('workCosts');
            }}
          >
            Edit
          </div>
        )}
      </div>
      <CostBreakdownDetails
        showModalEdit={showModalEdit}
        setShowModalEdit={setShowModalEdit}
        verifyStep={verifyStep}
        actionsHints={actionsHints}
      />
    </div>
  );
}

export default CostBreakdown;
