import { useModal } from '@/shared/lib/hooks/useModal';
import { Button } from 'stories/Button/Button';
import { IPeriodItem } from 'stories/FlexibleFilterByPeriods/types';
import { LegalEntity } from '@/entities/core/legalEntity';
import { IReportTableConfig } from 'types/ReportTableConfig';
import ExportModal from '@/bundles/REport/components/financials/ExportModal';

export const ExportButtonWithModal = ({
  reportTableConfig,
  legalEntities,
  showUnpublished,
  periodItems,
}: {
  reportTableConfig: IReportTableConfig;
  legalEntities: LegalEntity[];
  showUnpublished: boolean;
  periodItems: IPeriodItem[];
}) => {
  const { openModal } = useModal();

  return (
    <Button
      variant="primary"
      size="s"
      onClick={() => {
        openModal(ExportModal, {
          reportTableConfig,
          legalEntities,
          showUnpublished,
          periodItems,
        });
      }}
    >
      Export
    </Button>
  );
};
