import { useParams } from '@reach/router';
import { StatisticRowList } from 'bundles/Construction/components/cards/StatisticsRow/StatisticsRow';
import React from 'react';
import { Button } from 'stories/Button/Button';
import { CurrencyFormatter } from 'stories/ValueFormatters/CurrencyFormatter';
import { PercentFormatter } from 'stories/ValueFormatters/PercentFormatter';
import { StatisticLine } from 'stories/filterCard/statistic/StatisticLine';
import { LegalEntity } from '@/entities/core/legalEntity';
import { useGetFundingSourcesQuery } from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/fundingSources/api/reconcileFundingSourcesApi';
import { useFundingStatistic } from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/fundingSources/shared/infoSection/utils';
import { PercentFundingStatisticRow } from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/fundingSources/features/infoSection/PercentFundingStatisticRow';
import { useModal } from '@/shared/lib/hooks/useModal';
import FundingSourceModal from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/fundingSources/features/FundingSourceModal';
import { FUNDING_SOURCE_KINDS } from '@/shared/types/reconcile/FundingSource';
import { camelCase } from 'lodash-es';

const InfoCard = ({ title, amount }: { title: string; amount: number }) => (
  <div className="flex-1 rounded-lg bg-light p-4">
    <CurrencyFormatter
      classes={{
        allParts: 'blockquote-semibold',
        value: 'text-dark-60',
      }}
      value={amount}
    />
    <div className="label-regular">{title}</div>
  </div>
);

const InfoSection = () => {
  const params = useParams<{
    legalEntityCode: LegalEntity['code'];
  }>();

  const { data: fundingSources } = useGetFundingSourcesQuery({
    legalEntityCode: params.legalEntityCode,
    pageParams: {},
  });

  const meta = fundingSources?.meta;

  const { statistics, sumKinds } = useFundingStatistic({
    sources: meta?.stats.sources,
  });

  const { openModal } = useModal();

  const openFundingSourceModal = () => {
    openModal(FundingSourceModal, {
      type: 'create',
      legalEntityCode: params.legalEntityCode,
      header: (
        <span className="header6-bold text-dark-60">Create Funding Source</span>
      ),
      submitText: 'Create Source',
    });
  };

  return (
    <div className="flex h-fit w-[400px] flex-col gap-6 rounded-2xl bg-white p-6">
      <div className="flex flex-col gap-4">
        <div>
          <div className="header5-bold text-dark-60">Funding Sources</div>
        </div>
        <div className="flex gap-2">
          <InfoCard amount={meta?.stats.toFund ?? 0} title="To Fund" />
          <InfoCard
            amount={meta?.stats.totalBudget ?? 0}
            title="Total Budget"
          />
        </div>
      </div>
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-1.5">
          <CurrencyFormatter
            classes={{
              allParts: 'header3-bold',
              value: 'text-dark-60',
            }}
            value={meta?.stats.totalFunded ?? 0}
          />
          <StatisticLine className="mt-2" statistics={statistics} />
          {Boolean(sumKinds) && (
            <PercentFormatter
              value={sumKinds ?? 0}
              classes={{
                allParts: 'label-regular text-light-60',
              }}
            />
          )}
        </div>
        <StatisticRowList>
          {Object.values(FUNDING_SOURCE_KINDS).map((key) => (
            <PercentFundingStatisticRow
              key={key}
              typeKind={key}
              value={meta?.stats.sources[camelCase(key)]}
            />
          ))}
        </StatisticRowList>
      </div>
      <div>
        <Button
          className="w-full"
          variant="primary"
          onClick={openFundingSourceModal}
        >
          Create Funding Source
        </Button>
      </div>
    </div>
  );
};

export default InfoSection;
