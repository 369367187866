import type { _ObjectableType } from '@/bundles/REport/reducers/financialsSlice';
import type { ObjectTypeAndId } from '@/bundles/REport/reducers/model';
import { joinWithDash, splitWithDash } from '@/shared/lib/string';
import { ObjAndStateProps } from '@/bundles/REport/components/financials/types';
import { areReportFinancialsObjectsEqual } from '@/bundles/REport/components/financials/utils';

export const changeObjSelected = (
  obj: ObjAndStateProps,
  _selected: boolean,
): ObjAndStateProps => ({
  ...obj,
  _selected,
  legalEntities: obj.legalEntities.map((le) => ({ ...le, _selected })),
});

export function updateObjSelection(
  objects: ObjAndStateProps[],
  object: ObjAndStateProps,
  _selected: boolean,
) {
  return objects.map((o) => {
    if (!areReportFinancialsObjectsEqual(object, o)) return o;

    return changeObjSelected(o, _selected);
  });
}

export const stringifyObjTypeAndId = (
  object: Pick<ObjAndStateProps, '_type' | 'id'>,
) => joinWithDash([object._type, String(object.id)]) as ObjectTypeAndId;

export const parseObjTypeAndId = (objectTypeAndId: ObjectTypeAndId) =>
  splitWithDash(objectTypeAndId) as [
    ObjAndStateProps['_type'],
    ObjAndStateProps['id'],
  ];

export type ObjectsFromEntries = Partial<
  Record<_ObjectableType, ObjectTypeAndId[]>
>;
