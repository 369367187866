import React from 'react';
import { cn } from '@/shared/lib/css/cn';

export const ActionsContainer = ({
  className,
  children,
}: React.PropsWithChildren<PropsWithClassName>) => (
  <div
    className={cn(
      'flex flex-col gap-1 rounded-lg bg-white py-1',
      className,
    )}
  >
    {children}
  </div>
);
