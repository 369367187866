import React from 'react';
import { Button } from '@/stories/Button/Button';
import { Input } from '@/stories/FormControls/Inputs/Input/Input';
import { PasswordInput } from '@/stories/FormControls/Inputs/PasswordInput/PasswordInput';
import { usePutApiUserProfileUserMutation } from '@/widgets/userProfile/api/userProfileEnhancedApi';
import { useLoadedUserProfile } from '@/widgets/userProfile/hooks/useLoadedUserProfile';
import { SectionField } from '@/stories/Field/FieldsWrappers';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useBoolean } from 'usehooks-ts';
import { ResetPasswordLink } from '@/widgets/userProfile/ui/ResetPasswordLink';
import { HorizontalEditableField } from '@/stories/Field/HorizontalEditableField';

const validationSchema = yup.object({
  email: yup
    .string()
    .required('Email is required')
    .email('Invalid email format'),
  currentPassword: yup.string().required('Password is required'),
});

type FormValues = yup.InferType<typeof validationSchema>;

export const EmailSection = () => {
  const {
    value: isEditing,
    setTrue: startEditing,
    setFalse: stopEditing,
  } = useBoolean(false);
  const { data: userProfile } = useLoadedUserProfile();
  const [updateUser, { isLoading }] = usePutApiUserProfileUserMutation();

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { isDirty, isValid },
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      email: userProfile?.email ?? '',
      currentPassword: '',
    },
    mode: 'onChange',
  });

  const formValues = watch();

  const handleCancel = () => {
    reset();
    stopEditing();
  };

  const onSubmit = async (formData: FormValues) => {
    const result = await updateUser({
      body: {
        user: {
          email: formData.email,
          current_password: formData.currentPassword,
        },
      },
    });

    if ('data' in result) {
      reset({
        email: result.data.email,
        currentPassword: '',
      });
      stopEditing();
    }
  };

  return (
    <SectionField
      labelText="Email address"
      button={
        isEditing ? (
          <div className="flex gap-2">
            <Button size="xs" variant="secondary" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              size="xs"
              variant="success"
              onClick={handleSubmit(onSubmit)}
              loading={isLoading}
              disabled={!isDirty || !isValid}
            >
              Save Changes
            </Button>
          </div>
        ) : (
          <Button size="xs" variant="secondary" onClick={startEditing}>
            Change
          </Button>
        )
      }
    >
      <div className="-mx-4 divide-y divide-solid divide-neutral-200">
        <HorizontalEditableField
          label="Email"
          value={formValues.email}
          editable={isEditing}
          required={isEditing}
          className={isEditing ? 'py-2' : 'py-4'}
        >
          <Input {...register('email')} />
        </HorizontalEditableField>

        {isEditing && (
          <>
            <HorizontalEditableField
              label="Current Password"
              value={formValues.currentPassword}
              editable={isEditing}
              required={isEditing}
              className={isEditing ? 'py-2' : 'py-4'}
            >
              <PasswordInput {...register('currentPassword')} required />
            </HorizontalEditableField>
            <div className="grid grid-cols-[150px_1fr] items-center gap-6">
              <ResetPasswordLink className="col-start-2" />
            </div>
          </>
        )}
      </div>
    </SectionField>
  );
};
