import React from 'react';
import { FieldValues, Path } from 'react-hook-form/dist/types';
import { Control } from 'react-hook-form';
import * as yup from 'yup';
import { TumblerController } from '@/stories/Tumbler/Tumbler';
export const DEFAULT_WIDGET_DRILLDOWN = false;

export const DRILLDOWN_SIZE_SCHEMA = yup.boolean().required();

export const DEFAULT_DRILLDOWN_FIELD_SCHEMA = yup.object().shape({
  enable_drill_down: DRILLDOWN_SIZE_SCHEMA,
});

export function DrilldownField<TFieldValues extends FieldValues = FieldValues>({
  control,
  name = 'enable_drill_down',
}: {
  control: Control<TFieldValues>;
  name?: string;
}) {
  return (
    <TumblerController control={control} name={name as Path<TFieldValues>}>
      Enable Drilldown
    </TumblerController>
  );
}
