import * as React from 'react';
import styles from '@/stories/Calendar/Day/CalendarDay.module.scss';
import { cn } from '@/shared/lib/css/cn';
import { useHovered } from '@/shared/lib/hooks/useHovered';
import { useIntersectionObserver } from '@/shared/lib/hooks/useIntersectionObserver';

export type CalendarDayElementProps = Omit<
  React.AllHTMLAttributes<HTMLElement>,
  'onChange' | 'size' | 'disabled' | 'selected' | 'ref'
>;

export interface CalendarDayProps extends CalendarDayElementProps {
  day: Date;
  today?: boolean;
  selected?: boolean;
  selectionStart?: boolean;
  selectionEnd?: boolean;
  hintedSelectionStart?: boolean;
  hintedSelectionEnd?: boolean;
  active?: boolean;
  hidden?: boolean;
  disabled?: boolean;
  focused?: boolean;
  hinted?: boolean;
  sameMonth?: boolean;
  onChange(value: Date): void;
  onEnter?(value: Date): void;
  onLeave?(value: Date): void;
}

export const CalendarDay = React.memo(
  ({
    day,
    today,
    selected,
    onChange,
    hidden,
    disabled,
    active,
    selectionStart,
    selectionEnd,
    focused,
    onEnter,
    onLeave,
    hinted,
    hintedSelectionStart,
    hintedSelectionEnd,
    sameMonth,
    className,
    children,
    ...restProps
  }: CalendarDayProps) => {
    const { hovered, onMouseLeave, onMouseOver, setHovered } =
      useHovered(false);
    const ref = React.useRef<HTMLElement>(null);
    const entry = useIntersectionObserver(ref, {});
    const onClick = React.useCallback(() => onChange(day), [day, onChange]);
    const handleEnter = React.useCallback(() => {
      onEnter?.(day);
      onMouseOver();
    }, [day, onEnter]);
    const handleLeave = React.useCallback(() => {
      onLeave?.(day);
      onMouseLeave();
    }, [day, onLeave]);

    const label = new Intl.DateTimeFormat('en-EN', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    }).format(day);

    React.useEffect(() => {
      if (focused && ref.current) {
        ref.current.focus();
      }
    }, [focused]);

    React.useEffect(() => {
      if (entry != null && !entry.isIntersecting) {
        setHovered(false);
      }
    }, [entry?.isIntersecting]);

    if (hidden) {
      return <div className={styles.CalendarDay__hidden} />;
    }

    return (
      <button
        className={cn(
          styles.CalendarDay,
          today && styles['CalendarDay--today'],
          selected && !disabled && styles['CalendarDay--selected'],
          selectionStart && styles['CalendarDay--selection-start'],
          selectionEnd && styles['CalendarDay--selection-end'],
          disabled && styles['CalendarDay--disabled'],
          !sameMonth && styles['CalendarDay--not-same-month'],
          active ? '' : hovered && styles['CalendarDay--hover'],
          className,
        )}
        onClick={onClick}
        disabled={disabled}
        tabIndex={0}
        onPointerEnter={handleEnter}
        onPointerLeave={handleLeave}
        ref={ref}
        {...restProps}
      >
        <div
          className={cn(
            styles.CalendarDay__hinted,
            hinted && styles['CalendarDay__hinted--active'],
            hintedSelectionStart &&
              styles['CalendarDay__hinted--selection-start'],
            hintedSelectionEnd && styles['CalendarDay__hinted--selection-end'],
          )}
        >
          <div
            className={cn(
              styles.CalendarDay__inner,
              active && !disabled && styles['CalendarDay__inner--active'],
            )}
          >
            <div className={styles['CalendarDay__day-number']}>
              <span className="hidden">{children ?? label}</span>
              <span aria-hidden>{day.getDate()}</span>
            </div>
          </div>
        </div>
      </button>
    );
  },
);

CalendarDay.displayName = 'CalendarDay';
