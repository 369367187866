import React from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { TransactionDetailsModal } from '@/widgets/accounting/transaction/detailsModal/ui/TransactionDetailsModal';
import { useModal } from '@/shared/lib/hooks/useModal';
import { LinkButton } from 'stories/LinkButton/LinkButton';

type Props = ICellRendererParams;

function LineItemsCountCell({ value, data: row }: Props) {
  const { openModal } = useModal();

  const handleClick = () => {
    openModal(TransactionDetailsModal, {
      generalLedger: row,
      showUnpublished: true,
    });
  };

  return (
    <div className="flex items-center gap-1">
      <LinkButton onClick={handleClick}>{value}</LinkButton>
    </div>
  );
}

export default LineItemsCountCell;
