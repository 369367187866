import { ImportButton } from '@/shared/ui/ImportButton';

import { useImportThresholdWithOverrides } from '@/features/report/threshold/export/api';
import { ReportThreshold } from '@/entities/report/threshold/model';

export const ImportThresholdWithOverridesButton = ({
  threshold,
}: {
  threshold: ReportThreshold;
}) => {
  const [handleImport, options] = useImportThresholdWithOverrides(threshold);

  return (
    <ImportButton
      onClick={handleImport}
      loading={options.isLoading}
      disabled={options.isLoading}
    />
  );
};
