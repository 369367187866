import {
  OBJECT_DASHBOARD_WIDGETS_CONFIG_MAP,
  ObjectDashboardWidgetStateIntersection,
} from '@/bundles/Shared/widgets/dashboard/widgets/config';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import { useGetApiReportObjectDashboardsByObjectDashboardIdWidgetSectionsAndIdQuery } from '@/shared/api/dashboardSettingsEnhancedApi';
import { WidgetProps } from 'bundles/Shared/widgets/dashboard/widgets/model';
import { useCallback, useMemo } from 'react';

import { RtkQueryErrorLoggerMiddlewareArgs } from '@/app/stores/errorHandling';
import { useWidgetInView } from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/useWidgetInView';
import { cn } from '@/shared/lib/css/cn';
import {
  buildLayoutsId,
  updateDashboardWidgetState,
} from 'bundles/Shared/entities/dashboard/model/slices/shared';
import { ReportDashboardType } from 'bundles/Shared/entities/dashboard/model/types/types';
import type {
  ReportDashboardAsset,
  ReportObjectDashboardSection,
} from 'bundles/Shared/entities/dashboard/model/types/types';
import {
  selectReportDashboardSelectedObjectLegalEntityIds,
  selectReportObjectDashboardMetadataById,
} from 'bundles/Shared/entities/dashboard/model/slices/objectSlice';
import { WidgetErrorBoundary } from 'bundles/Shared/widgets/dashboard/widgetsHelpers/ui/WidgetErrorBoundary';
import { UnknownRecord } from 'type-fest/source/internal';
import { GetApiReportObjectDashboardsByObjectDashboardIdWidgetSectionsAndIdApiArg } from '@/shared/api/dashboardsGeneratedApi';

type Props = Pick<
  WidgetProps<unknown, ReportObjectDashboardSection>,
  'mode' | 'dashboardId' | 'widgetSection' | 'boardId'
> & {
  assets: Pick<ReportDashboardAsset, 'id' | 'name'>[];
  selectedAssetId?: number;
};

export type ObjectDashboardWidgetContext = {
  objectLegalEntityIds: string[];
  selectedAsset: ReportDashboardAsset | undefined;
};

export function ObjectDashboardWidget(props: Props) {
  const { ref, inView } = useWidgetInView();
  const dispatch = useAppDispatch();
  const { selectedAssetId, dashboardId, widgetSection, boardId, assets } =
    props;
  const widgetId = widgetSection.id;
  const { widgetsState } = useAppSelector((state) =>
    selectReportObjectDashboardMetadataById(
      state,
      buildLayoutsId({
        dashboardId,
        boardId,
      }),
    ),
  )!;
  const objectLegalEntityIds = useAppSelector((state) =>
    selectReportDashboardSelectedObjectLegalEntityIds(state, {
      dashboardId,
      selectedObjectId: selectedAssetId,
    }),
  );
  const state = widgetsState[
    widgetId
  ] as unknown as ObjectDashboardWidgetStateIntersection;

  const context = useMemo<ObjectDashboardWidgetContext>(
    () => ({
      objectLegalEntityIds,
      selectedAsset: assets.find((asset) => asset.id === selectedAssetId),
      columnVisibilityEnabled: true,
    }),
    [objectLegalEntityIds, assets, selectedAssetId],
  );

  const handleStateChange = useCallback(
    (newState: UnknownRecord) => {
      dispatch(
        updateDashboardWidgetState({
          boardId,
          id: widgetId,
          widgetState: newState,
          dashboardId,
        }),
      );
    },
    [dashboardId, widgetId],
  );

  const getRequestArgs = () => {
    if (!state) {
      return {
        id: widgetId,
        legalEntityIds: objectLegalEntityIds,
        objectDashboardId: dashboardId,
      } as GetApiReportObjectDashboardsByObjectDashboardIdWidgetSectionsAndIdApiArg;
    }
    return {
      legalEntityIds: objectLegalEntityIds,
      id: widgetId,
      date: state.date,
      dateTo: state.dateTo,
      dateFrom: state.dateFrom,
      granularity: state.granularity,
      objectDashboardId: dashboardId,
      page: state.page,
      perPage: state.perPage,
      query: state.query,
      filters: state.filters ? JSON.stringify(state.filters) : undefined,
      useLtoThresholds: state.useLtoThresholds,
      disableToast: true,
    } as GetApiReportObjectDashboardsByObjectDashboardIdWidgetSectionsAndIdApiArg &
      RtkQueryErrorLoggerMiddlewareArgs;
  };
  const { data, isLoading, isFetching, isError, error, isUninitialized } =
    useGetApiReportObjectDashboardsByObjectDashboardIdWidgetSectionsAndIdQuery(
      getRequestArgs(),
      {
        skip: !inView,
      },
    );

  const { Component } =
    OBJECT_DASHBOARD_WIDGETS_CONFIG_MAP[widgetSection.widgetType] ?? {};

  if (Component == null) {
    return null;
  }

  return (
    <WidgetErrorBoundary
      widgetSection={widgetSection}
      dashboardType={ReportDashboardType.OBJECT}
      mode={props.mode}
    >
      <div className={cn('h-full')} ref={ref}>
        <Component
          isLoading={isLoading}
          isError={isError}
          isFetching={isFetching}
          isUninitialized={isUninitialized}
          onStateChange={handleStateChange}
          data={data}
          state={state ?? {}}
          context={context}
          dashboardType={ReportDashboardType.OBJECT}
          errorMessage={(error?.data as ErrorResponse)?.errors}
          {...props}
        />
      </div>
    </WidgetErrorBoundary>
  );
}
