import { cn } from '@/shared/lib/css/cn';
import { Icon } from '@/stories/Icon/Icon';
import '@/stories/TabItem/tabItem.css';

interface Props
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  variant?: 'overline' | 'underline' | 'circle';
  classes?: {
    active?: string;
  };
  children?: React.ReactNode;
  active?: boolean;
  icon?: boolean;
  boldBorder?: boolean;
}

export const TabItem = ({
  className,
  icon,
  boldBorder,
  variant = 'underline',
  children,
  active,
  classes,
  ...props
}: Props) => (
  <button
    type="button"
    className={cn('sre-tab-item', `sre-tab-item_${variant}`, className, {
      [cn('_active', classes?.active)]: active,
      'sre-tab-item_bold-border': boldBorder,
    })}
    {...props}
  >
    {children}
    {icon && <Icon className="dark-60" iconName="arrowBottom" />}
  </button>
);

export default TabItem;
