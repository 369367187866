import React, { useState } from 'react';
import { DndWrapper } from 'bundles/Assets/components/SharedFiles/BulkUploaderModal';
import { Modal } from 'stories/Modals/Modal/Modal';
import { Button } from 'stories/Button/Button';
import { Icon } from 'stories/Icon/Icon';
import { LinkButton } from 'stories/LinkButton/LinkButton';
import { directUpload } from 'lib/uploadFiles';
import { presignFilesWithFolder } from 'lib/presignFilesWithFolder';
import SharedFileDropzone from 'bundles/Shared/components/SharedFileDropzone';
import Dropzone from 'react-dropzone';
import TableUpload from 'bundles/DrawPackage/InvoicesFlow/Invoices/invoice/kanban/upload/TableUploader';
import { useUploadInvoicesMutation } from 'bundles/Construction/api/invoices';
import { cn } from '@/shared/lib/css/cn';
import useFileDialog from '@/shared/lib/hooks/useFileDialog';
import useDropWrapperClasses from '@/shared/lib/hooks/useDropWrapperClasses';
import { LegalEntity } from '@/entities/core/legalEntity';
import { DialogProps } from '@/shared/lib/hooks/useModal';

export type UploadingStateType = 'notStarted' | 'started' | 'finished';

interface Props extends DialogProps {
  legalEntityCode: LegalEntity['code'];
}

const InvoiceUploadModal = ({ onClose, legalEntityCode }: Props) => {
  const { files, openFileDialog, setFiles } = useFileDialog({
    fileMode: 'replace',
  });
  const {
    dropWrapperClasses,
    setDropWrapperClasses,
    onDrop,
    onDragOver,
    onDragExit,
  } = useDropWrapperClasses();

  const [uploadingState, setUploadingState] =
    useState<UploadingStateType>('notStarted');
  const [uploadInvoices] = useUploadInvoicesMutation();

  const [dropMode, setDropMode] = useState(true);

  const setInitialProgress = (filesList) => {
    setFiles(
      filesList.map(
        (f) =>
          ({
            ...f,
            progress: 0,
          }) as IFile,
      ),
    );
  };

  const uploadFiles = async (filesToSubmit) => {
    const presignedFiles = await presignFilesWithFolder(filesToSubmit);
    await Promise.all(
      presignedFiles.map((data) =>
        directUpload(data, {
          onUploadProgress: (progressEvent) =>
            onUploadProgress(
              data.file,
              data.signedData,
              progressEvent,
              setFiles,
            ),
        }),
      ),
    );

    return presignedFiles as IFile[];
  };

  const generateRequestData = (presignedFiles) => ({
    documents: presignedFiles.map((f) => ({
      key: f.signedData.fields.key,
      filename: f.file.name,
      title: f.file.name,
      content_type: 'application/pdf',
      size: f.file.size,
    })),
  });

  const onSubmit = async () => {
    setUploadingState('started');
    const filesToSubmit = [...files];
    await Promise.resolve(setInitialProgress(filesToSubmit));

    const presignedFiles = await uploadFiles(filesToSubmit);

    const data = generateRequestData(presignedFiles);
    await Promise.resolve(
      uploadInvoices({
        data,
        legalEntityCode,
      }),
    );
    setFiles(
      files.map((file) => ({
        ...file,
        uploaded: true,
      })),
    );
    setUploadingState('finished');
  };

  const onDropzoneChange = (filesList) => {
    setFiles([
      ...files,
      ...filesList.map((f) => ({
        file: Object.assign(f, { droppedAt: new Date().getTime() / 1000 }),
        name: f.name,
        path: f.path,
        size: f.size,
        type: f.type,
        extension: f.name.split('.').pop(),
        droppedAt: new Date().getTime() / 1000,
      })),
    ]);
    setDropMode(false);
  };

  return (
    <DndWrapper
      setClasses={setDropWrapperClasses}
      uploadingState={uploadingState}
      files={files}
    >
      <Modal
        toggle={onClose}
        size="xl"
        header="Bulk Uploader"
        classes={{
          header: 'bg-white',
          body: 'bg-light-10 h-80vh p-0',
        }}
        disabledClose={uploadingState === 'started'}
        actions={
          <div className="flex justify-between w-full">
            <div>
              {uploadingState === 'notStarted' && (
                <Button onClick={onClose} variant="secondary">
                  Close
                </Button>
              )}
            </div>
            <div className="flex gap-2">
              {uploadingState !== 'finished' && (
                <Button
                  onClick={onSubmit}
                  variant="success"
                  disabled={files.length < 1 || uploadingState === 'started'}
                >
                  Upload Documents
                  {uploadingState === 'started' && (
                    <Icon
                      className="form-button-loading mr-s"
                      iconName="sync"
                    />
                  )}
                </Button>
              )}
              {uploadingState === 'finished' && (
                <Button
                  onClick={() => {
                    setFiles([]);
                    setUploadingState('notStarted');
                  }}
                  variant="success"
                  className="mr-[0.5rem]"
                >
                  Upload More
                </Button>
              )}
              {uploadingState !== 'notStarted' && (
                <Button
                  onClick={onClose}
                  variant="secondary"
                  disabled={uploadingState === 'started'}
                >
                  Close
                </Button>
              )}
            </div>
          </div>
        }
      >
        {(files.length === 0 || dropMode) && (
          <div className="p-[1rem] h-full">
            <SharedFileDropzone
              label="Drag your invoices here to start uploading"
              onChange={onDropzoneChange}
            />
            <div className="dropzone-type-doc inline-regular flex gap-1">
              <span>Supported file type:</span>
              <span className="font-semibold">pdf</span>
            </div>
          </div>
        )}
        {files.length > 0 && !dropMode && (
          <div className="flex h-full flex-col justify-between p-4">
            <div className="pb-8">
              <div
                className={cn('dropzone-invisible-area', dropWrapperClasses)}
                onDrop={onDrop}
                onDragOver={onDragOver}
                onDragExit={onDragExit}
              >
                <Dropzone onDrop={onDropzoneChange} useFsAccessApi={false}>
                  {({ getRootProps, getInputProps }) => (
                    <div
                      className="h-full opacity-100"
                      id="dragAndDropContainer"
                    >
                      <div {...getRootProps({ className: 'h-full' })}>
                        <input {...getInputProps()} />
                        <div className="h-full" />
                      </div>
                    </div>
                  )}
                </Dropzone>
              </div>
              <TableUpload
                files={files}
                setFiles={setFiles}
                loading={uploadingState === 'started'}
              />
            </div>

            {uploadingState === 'notStarted' && (
              <div className="continue-drag inline-regular flex justify-center gap-1 bg-light py-2 text-center text-dark-60">
                <Icon iconName="magic" />
                <span>
                  Continue to drag & drop your documents above documents or
                </span>
                <LinkButton fontWeight="bold" onClick={openFileDialog}>
                  Browse documents
                </LinkButton>
              </div>
            )}
          </div>
        )}
      </Modal>
    </DndWrapper>
  );
};

export default InvoiceUploadModal;
