import React from 'react';
import { ListOption } from 'stories/Checkbox/CheckList';
import { capitalize } from 'lodash-es';
import { Field } from 'stories/Field/Field';
import { IconButton } from 'stories/IconButton/IconButton';
import { ACCESS_TYPE_OPTIONS } from '@/bundles/Settings/components/REport/TableBuilder/helpers/constants';
import { AccessType } from '@/bundles/Settings/components/REport/TableBuilder/types';
import styles from '@/bundles/Settings/components/REport/TableBuilder/Rules/sharedStyles.module.scss';
import { findAccessType } from '@/bundles/Settings/components/REport/TableBuilder/helpers/categoryRulesUtils';
import {
  RoleRule,
  UserRule,
} from '@/bundles/Settings/components/REport/TableBuilder/CategoryRules/types';
import { SharedSelect } from 'bundles/Shared/components/GroupForm/FormItems/new/SharedSelect';

type Rule = UserRule | RoleRule;
interface Props {
  type: 'role' | 'user';
  rule: Rule;
  onRemove: () => void;
  allUserOptions: ListOption[];
  notSelectedUserOptions: ListOption[];
  onItemIdChange: (id: Rule['id'], itemId: string) => void;
  onAccessTypeChange: (id: Rule['id'], accessType: AccessType) => void;
}

const RULES = {
  user: {
    idKey: 'userId',
  },
  role: {
    idKey: 'roleId',
  },
};

export function CategoryRulesEntry({
  type = 'user',
  rule,
  onRemove,
  allUserOptions,
  notSelectedUserOptions,
  onItemIdChange,
  onAccessTypeChange,
}: Props) {
  const selectedItem = allUserOptions.find(
    ({ value }) => rule[RULES[type].idKey] === value,
  );

  return (
    <div className={styles.wrapper}>
      <div className="flex gap-4">
        <Field labelText={capitalize(type)} required newGen className="flex-1">
          <SharedSelect
            placeholder="Select Role"
            options={notSelectedUserOptions}
            value={selectedItem}
            onChange={(selectedType) => {
              if (!selectedType || Array.isArray(selectedType)) return;
              onItemIdChange(rule.id, selectedType.id);
            }}
            newGen
          />
        </Field>
        <Field className="flex-1" labelText="Rule" required newGen>
          <SharedSelect
            placeholder="Select Rule"
            options={ACCESS_TYPE_OPTIONS}
            value={findAccessType(rule.accessType)}
            onChange={(selectedType) => {
              if (!selectedType || Array.isArray(selectedType)) return;
              onAccessTypeChange(rule.id, selectedType.id);
            }}
            newGen
          />
        </Field>
        <IconButton variant="white" iconName="closeSmall" onClick={onRemove} />
      </div>
    </div>
  );
}
