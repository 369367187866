import React from 'react';
import { GeneralSettingsNavigationSection } from 'stories/GeneralSettingsNavigationSection/GeneralSettingsNavigationSection';
import { ObjectsSettingsNavigationSection } from 'stories/ObjectsSettingsNavigationSection/ObjectsSettingsNavigationSection';
import { useGetApiSettingsReportGeneralLedgersMetaQuery } from 'bundles/Settings/actions/report/generalLedgersApiEnhanced';

export type TGeneralLedgerScope =
  | 'active-and-mapped'
  | 'active-and-not-mapped'
  | 'excluded'
  | 'inactive';

interface Props {
  onCardClick: (scopeName: TGeneralLedgerScope) => void;
  generalLedgersScope: TGeneralLedgerScope;
}

function GeneralLedgerScopeCardList({
  onCardClick,
  generalLedgersScope,
}: Props) {
  const { data: meta } = useGetApiSettingsReportGeneralLedgersMetaQuery();

  return (
    <div className="flex gap-2" role="tablist">
      <GeneralSettingsNavigationSection
        active={generalLedgersScope === 'active-and-mapped'}
        onClick={() => onCardClick('active-and-mapped')}
        title={meta ? `${meta.mappedCategoriesSize.toString()} Categories` : ''}
        subtitle={
          meta ? (
            <>
              Mapped with{' '}
              <span className="light-90 secondary-semibold">
                {meta.mappedSize} Ledgers
              </span>
            </>
          ) : (
            ''
          )
        }
      />
      <GeneralSettingsNavigationSection
        active={generalLedgersScope === 'active-and-not-mapped'}
        onClick={() => onCardClick('active-and-not-mapped')}
        title={meta ? `${meta.notMappedSize.toString()} Active Ledgers` : ''}
        subtitle="without Category"
      />
      <ObjectsSettingsNavigationSection
        title="Other"
        tabs={[
          {
            title: 'Excluded',
            onClick: () => onCardClick('excluded'),
            active: generalLedgersScope === 'excluded',
          },
          {
            title: 'Inactive',
            onClick: () => onCardClick('inactive'),
            active: generalLedgersScope === 'inactive',
          },
        ]}
      />
    </div>
  );
}

export default GeneralLedgerScopeCardList;
