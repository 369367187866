import { IconButton } from '@/stories/IconButton/IconButton';

import Dropdown from '@/stories/Dropdown/Dropdown';
import {
  useDeleteThreshold,
  useUpdateThreshold,
} from '@/features/report/threshold/crud/lib';
import { ReportThreshold } from '@/entities/report/threshold/model';
import { canUserConfigureThresholds } from '@/entities/report/threshold/lib';

export const ReportThresholdsActionsDropdown = ({
  threshold,
}: {
  threshold: ReportThreshold;
}) => {
  const [updateThreshold, { isLoading: isUpdating }] =
    useUpdateThreshold(threshold);
  const [deleteThreshold, { isLoading: isDeleting }] =
    useDeleteThreshold(threshold);

  const isDisabled = isDeleting || isUpdating;
  return (
    <Dropdown
      disabled={!canUserConfigureThresholds()}
      className="sre-dropdown-v2"
      classNameContainer="absolute right-4 top-2"
      placement="bottom-end"
      items={
        <>
          <Dropdown.Item
            disabled={isDisabled}
            iconName="trash"
            onClick={deleteThreshold}
          >
            Remove
          </Dropdown.Item>
          <Dropdown.Item
            disabled={isDisabled}
            iconName="edit"
            onClick={updateThreshold}
          >
            Edit
          </Dropdown.Item>
        </>
      }
    >
      <IconButton
        tooltipProps={{
          disabled: canUserConfigureThresholds(),
          titleText: 'To change this setting, please contact symmetRE',
        }}
        disabled={!canUserConfigureThresholds()}
        iconName="settings"
      />
    </Dropdown>
  );
};
