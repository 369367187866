import { isNodeLocked } from 'stories/TreeView/Checkbox';
import { getComponentSelector } from '@/stories/TreeView/TreeView';
import styles from '@/stories/TreeView/TreeView.module.scss';

export const NODE_TEXT_COMPONENT = 'NodeText';

export function NodeText(selection, lifecycle: 'create' | 'update') {
  if (lifecycle === 'create') {
    selection
      .append('text')
      .attr('data-component', NODE_TEXT_COMPONENT)
      .attr('class', styles.nodeText);
  }

  selection
    .select(getComponentSelector(NODE_TEXT_COMPONENT))
    .attr('dominant-baseline', 'middle')
    .attr('data-locked', (d) => (isNodeLocked(d) ? 'true' : 'false'))
    .attr('text-anchor', (d) => 'start')
    .text((d) => d.data.name)
    .attr('x', (d) => d.checkBox?.width ?? 0)
    .each(function (d) {
      const bound = this.getBBox();
      d.textBox = {
        height: bound.height,
        width: (d.checkBox?.width ?? 0) + bound.width,
      };
    });
}
