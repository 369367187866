import pluralize from 'pluralize';
import React, { useState } from 'react';
import { cn } from '@/shared/lib/css/cn';
import Table from 'bundles/Shared/components/Table/Table';
import { CardWrapper } from 'bundles/Shared/components/Wrappers/CardWrapper';
import { LinkButton } from 'stories/LinkButton/LinkButton';
import { tableColumns } from '@/bundles/UserManagement/components/EditUserModalComponents/tableColumns';
import {
  ILightLegalEntitiable,
  ObjectType,
} from '@/bundles/UserManagement/components/EditUserModalComponents/types';

interface Props {
  objects: ILightLegalEntitiable[];
  title: React.ReactNode;
  isAll: boolean;
  type: ObjectType;
}

export const CardObjectsList = ({ objects, title, isAll, type }: Props) => {
  const [expanded, setExpanded] = useState(objects.length === 1);

  if (objects.length === 0) return null;

  return (
    <CardWrapper className="rounded-[0.5rem]">
      <div className="light-90 secondary-regular font-weight-500 bg-light-10 px-4 py-2">
        {title}
      </div>
      <div className="overflow-hidden  px-4 py-0">
        <ul className="inline-regular light-10 striped-ul w-full">
          {objects.length > 1 && (
            <li className="flex justify-between dark-60 bg-light">
              <p className="font-weight-600">
                {isAll
                  ? `All ${pluralize(type)}`
                  : `${objects.length} ${pluralize(type, objects.length)}`}
              </p>
              <LinkButton
                className="flex items-center"
                onClick={() => setExpanded(!expanded)}
              >
                {expanded
                  ? `Hide ${pluralize(type)}`
                  : `Show ${pluralize(type)}`}
                <span
                  className={cn('ml-[0.5rem] text-[1rem]', {
                    'sre-icon-bottom': !expanded,
                    'sre-icon-top': expanded,
                  })}
                />
              </LinkButton>
            </li>
          )}
          {expanded && (
            <li className="bg-light p-0 pt-5">
              <Table
                columns={tableColumns(type)}
                items={objects}
                borderLessOutside
                tableStriped
              />
            </li>
          )}
        </ul>
      </div>
    </CardWrapper>
  );
};
