import { reportingEnhancedApi } from '@/entities/reporting/api/reportingEnhancedApi';
import type { ReportGroupDto } from '@/entities/reporting/api/reportingGeneratedApi';
import { formatToDateStringForRequest } from '@/shared/lib/converters';
import { yesterdayDate } from '@/shared/lib/date';

export const useCreateEagleEyeReport = (
  onSuccess?: (
    groupId: ReportGroupDto['id'],
    group: ReportGroupDto['id'],
  ) => void,
) => {
  const [createReport, options] =
    reportingEnhancedApi.usePostApiReportBuilderReportGroupsByReportGroupIdEagleEyeReportsMutation();

  const handleCreate = async (
    group: Pick<ReportGroupDto, 'id' | 'name' | 'template'>,
  ) => {
    const response = await createReport({
      reportGroupId: group.id,
      body: {
        name: group.template.name,
        template_id: group.template.id,
        date: formatToDateStringForRequest(yesterdayDate()),
        date_format: 'day',
        asset_ids: [],
        segment_ids: [],
      },
    });

    if ((response != null && 'error' in response) || response == null) {
      console.error(response.error);
      return;
    }

    return onSuccess?.(response.data.id, group.id);
  };

  return [handleCreate, options] as const;
};
