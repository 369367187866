import { useGetApiReconcileForecastingForecastsQuery } from '@/entities/reconcile/forecasting/api/reconcileForecastingEntitiesEnchancedApi';
import { generateUrl, ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';

export const useForecasts = () =>
  useGetApiReconcileForecastingForecastsQuery(
    {},
    {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      selectFromResult: ({ data, isFetching, isLoading }) => ({
        forecasts: data?.items?.map((item) => ({
          ...item,
          pageViewUrl: generateUrl(
            ROUTES_ROOT.reconcile.forecasting.forecast.fullPath,
            {
              pathParams: {
                forecastName: item.name,
              },
            },
          ),
        })),
        isLoading,
        isFetching,
        forecastsCount: data?.items?.length ?? 0,
      }),
    },
  );
