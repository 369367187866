import { BaseFinancialTableWidgetConfigBaseColumnDto } from 'bundles/Shared/shared/api/dashboardsSettingsGeneratedApi';
import {
  ADJUSTMENT_EXPRESSION_ONE_SCHEMA,
  ADJUSTMENT_EXPRESSION_OPTIONS,
  ADJUSTMENT_EXPRESSION_OPTIONS_MAP,
  ADJUSTMENT_EXPRESSION_VALUE_ACTUAL,
  AdjustmentExpressionName,
} from 'bundles/Shared/widgets/dashboard/widgets/common/features/adjustments/config/base';
import * as yup from 'yup';
import { ListOption } from 'stories/Checkbox/CheckList';
import { isForecastAdjustment } from 'bundles/Shared/widgets/dashboard/widgets/common/features/adjustments/lib';

export const FINANCIAL_TABLE_WIDGET_ADJUSTMENT_EXPRESSION_OPTIONS = [
  ADJUSTMENT_EXPRESSION_OPTIONS_MAP.actual,
  ADJUSTMENT_EXPRESSION_OPTIONS_MAP.op_budget,
  ADJUSTMENT_EXPRESSION_OPTIONS_MAP.op_variance,
  ADJUSTMENT_EXPRESSION_OPTIONS_MAP.op_variance_percent,
  ADJUSTMENT_EXPRESSION_OPTIONS_MAP.op_budget_annualized,
  ADJUSTMENT_EXPRESSION_OPTIONS_MAP.uw_budget,
  ADJUSTMENT_EXPRESSION_OPTIONS_MAP.uw_variance,
  ADJUSTMENT_EXPRESSION_OPTIONS_MAP.uw_variance_percent,
  ADJUSTMENT_EXPRESSION_OPTIONS_MAP.uw_budget_annualized,
  ADJUSTMENT_EXPRESSION_OPTIONS_MAP.annualized,
  ADJUSTMENT_EXPRESSION_OPTIONS_MAP.financial_t1a,
  ADJUSTMENT_EXPRESSION_OPTIONS_MAP.financial_t3a,
  ADJUSTMENT_EXPRESSION_OPTIONS_MAP.financial_beginning_balance,
  ADJUSTMENT_EXPRESSION_OPTIONS_MAP.financial_ending_balance,
  ADJUSTMENT_EXPRESSION_OPTIONS_MAP.financial_debit,
  ADJUSTMENT_EXPRESSION_OPTIONS_MAP.financial_credit,
  ADJUSTMENT_EXPRESSION_OPTIONS_MAP.period_to_period_variance,
  ADJUSTMENT_EXPRESSION_OPTIONS_MAP.period_to_period_variance_percent,
  ADJUSTMENT_EXPRESSION_OPTIONS_MAP.year_to_year_variance,
  ADJUSTMENT_EXPRESSION_OPTIONS_MAP.year_to_year_variance_percent,
  ADJUSTMENT_EXPRESSION_OPTIONS_MAP.fc_budget,
  ADJUSTMENT_EXPRESSION_OPTIONS_MAP.fc_variance,
  ADJUSTMENT_EXPRESSION_OPTIONS_MAP.fc_variance_percent,
  ADJUSTMENT_EXPRESSION_OPTIONS_MAP.fc_budget_annualized,
] satisfies ADJUSTMENT_EXPRESSION_OPTIONS;

export const ADJUSTMENT_TYPES = {
  SQFT: 'sqft',
  UNIT: 'unit',
  UNIT_AVAILABLE: 'unit_available',
  UNIT_OCCUPIED: 'unit_occupied',
  UNIT_PRELEASED: 'unit_preleased',
  UNIT_VACANT: 'unit_vacant',
} as const satisfies Record<
  string,
  BaseFinancialTableWidgetConfigBaseColumnDto['adjustment_average_per']
>;
export const ADJUSTMENT_TYPE_VALUE_TOTAL = 'total';
export const ADJUSTMENT_TYPE_ONE_SCHEMA = yup
  .string()
  .oneOf([...Object.values(ADJUSTMENT_TYPES), ADJUSTMENT_TYPE_VALUE_TOTAL]);
export const ADJUSTMENT_TYPE_OPTIONS = [
  {
    label: 'Total',
    value: ADJUSTMENT_TYPE_VALUE_TOTAL,
  },
  {
    label: 'Per SQFT',
    value: 'sqft',
  },
  {
    label: 'Per Unit (Total)',
    value: 'unit',
  },
  {
    label: 'Per Unit (Occupied)',
    value: 'unit_occupied',
  },
  {
    label: 'Per Unit (Vacant)',
    value: 'unit_vacant',
  },
  {
    label: 'Per Unit (Available)',
    value: 'unit_available',
  },
  {
    label: 'Per Unit (Pre-leased)',
    value: 'unit_preleased',
  },
] satisfies ListOption<Values<typeof ADJUSTMENT_TYPES>>[];

export const FINANCIAL_TABLE_ADJUSTMENT_SCHEMA = yup.object().shape({
  adjustment_expression: ADJUSTMENT_EXPRESSION_ONE_SCHEMA.nullable().optional(),
  adjustment_type: ADJUSTMENT_TYPE_ONE_SCHEMA.nullable().optional(),
  forecast_name: yup.string().when('adjustment_expression', {
    is: (val: AdjustmentExpressionName) => val && isForecastAdjustment(val),
    then: (schema) => schema.required('Forecast value override is required'),
    otherwise: (schema) => schema.nullable().optional(),
  }),
});

export type FinancialTableAdjustmentForm = yup.InferType<
  typeof FINANCIAL_TABLE_ADJUSTMENT_SCHEMA
>;
export const DEFAULT_FINANCIAL_TABLE_ADJUSTMENT_FORM: FinancialTableAdjustmentForm =
  {
    adjustment_expression: ADJUSTMENT_EXPRESSION_VALUE_ACTUAL,
    adjustment_type: ADJUSTMENT_TYPE_VALUE_TOTAL,
  };
