/* eslint-disable linebreak-style */

import React from 'react';
import { historyStatusTypes } from 'lib/dictionaries';
import { HistoryItem } from 'stories/HistoryItem/HistoryItem';
import {
  IChangeOrderEvent,
  IChangeOrderEventLog,
} from 'bundles/Construction/types';
import { capitalize } from 'lodash-es';
import LoadingPanel from 'bundles/Shared/components/LoadingPanel';

interface Props {
  currentObjectState: IChangeOrderEvent;
  logItem: IChangeOrderEventLog;
  lastItem?: boolean;
}

function HistoryElem({ currentObjectState, logItem, lastItem }: Props) {
  const { data, createdBy, createdAt, title, documents, eventComment } =
    logItem;
  if (!logItem) return <LoadingPanel />;
  const config = historyStatusTypes(data)[data.type || 'default'];
  const showDocument = (doc: File) => {
    window.open(`/shared-files/${doc.id}?disposition=inline`, '_blank');
  };

  const generateLabel = () =>
    `${capitalize(title ?? config?.label ?? 'created')}
    ${currentObjectState?.number ? `RE ${currentObjectState?.number}` : ''}
    ${config?.content ?? ''}`;

  return (
    <div>
      <HistoryItem
        label={generateLabel()}
        createdAt={createdAt}
        createdBy={createdBy}
        icon={{
          iconName: config?.icon,
          iconColor: config?.color,
        }}
        emailDetails={eventComment}
        documents={documents || data?.shared_files}
        disabledDocument={config?.label === 'removed documents from'}
        onClickDoc={showDocument}
        lastItem={lastItem}
      />
    </div>
  );
}

export default HistoryElem;
