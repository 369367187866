import type {
  PreviewReportDto,
  ReportGroupDto,
} from '@/entities/reporting/api/reportingGeneratedApi';
import { generateUrl, ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import { useLocation, useNavigate } from '@reach/router';

export const useReportBuilderNavigateToEagleEyeReport = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleNavigateToReport = (
    id: PreviewReportDto['id'] | undefined,
    groupId: ReportGroupDto['id'] | undefined,
    isGenerated?: boolean,
  ) => {
    if (groupId == null || id == null) return;

    if (isGenerated) {
      return navigate(
        generateUrl(ROUTES_ROOT.reports.eagleEye.report.generated.fullPath, {
          pathParams: {
            reportId: id,
          },
        }),
        {
          state: {
            from: location.pathname,
          },
        },
      );
    }

    navigate(
      generateUrl(ROUTES_ROOT.reports.eagleEye.report.fullPath, {
        pathParams: {
          reportId: id,
          reportGroupId: groupId,
        },
      }),
      {
        state: {
          from: location.pathname,
        },
      },
    );
  };

  return handleNavigateToReport;
};
