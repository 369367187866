import { CoreAsset } from 'bundles/Settings/components/Portal/AssetDashboard/api/coreAssetsApi';
import { CoreFund } from 'bundles/Settings/components/Portal/FundDashboard/api/coreFundsApi';
import { UsersList } from 'bundles/Shared/components/ModalWithSideTabs/Lists';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import pluralize from 'pluralize';
import { Modal } from 'stories/Modals/Modal/Modal';
import { ModalHeaderWithSubtitle } from 'stories/Modals/ModalHeader/ModalHeaderWithSubtitle/ModalHeaderWithSubtitle';
import { IUser } from 'types/User';

export const ExternalUsersListModal = ({
  onClose,
  object,
  externalUsers,
}: {
  object: CoreAsset | CoreFund;
  externalUsers: Pick<
    IUser,
    'id' | 'fullName' | 'phone' | 'email' | 'initials' | 'external'
  >[];
} & DialogProps<boolean>) => (
  <Modal
    header={
      <ModalHeaderWithSubtitle
        title={object.name}
        subtitle="External users"
        order="subtitle-title"
      />
    }
    toggle={onClose}
    size="lg"
  >
    <UsersList
      members={externalUsers}
      title={`${externalUsers.length} ${pluralize(
        'member',
        externalUsers.length,
      )}`}
    />
  </Modal>
);

export default ExternalUsersListModal;
