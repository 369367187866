import { usePutApiCoreAssetsByAssetIdReportBuilderReportsAndReportIdWidgetSectionsWidgetSectionIdSnapshotMutation } from '@/entities/report/reportBuilder';
import { reportingEnhancedApi } from '@/entities/reporting/api/reportingEnhancedApi';
import { useReportingEntityKindContext } from '@/entities/reporting/context/entityKind';
import { useMemo } from 'react';

export const useUpdateReportWidgetSnapshot = () => {
  const reportKind = useReportingEntityKindContext();
  const updateObjectLevelWidgetSnapshotMutation =
    usePutApiCoreAssetsByAssetIdReportBuilderReportsAndReportIdWidgetSectionsWidgetSectionIdSnapshotMutation();

  const updateEagleEyeWidgetSnapshotMutation =
    reportingEnhancedApi.usePutApiReportBuilderEagleEyeReportsByEagleEyeReportIdWidgetSectionsAndWidgetSectionIdSnapshotMutation();

  const mutation = useMemo(() => {
    switch (reportKind) {
      case 'eagle_eye': {
        return updateEagleEyeWidgetSnapshotMutation;
      }
      default:
      case 'object_level': {
        return updateObjectLevelWidgetSnapshotMutation;
      }
    }
  }, [reportKind]);

  return mutation;
};
