import React, { FC } from 'react';
import { IChangeOrder } from 'bundles/Construction/types';
import TableCEItem from '@/bundles/Construction/components/ChangeOrder/IncludesChangeEvents/TableCEItem';

interface ITableCEProps {
  changeOrder: IChangeOrder;
}

const TableCE: FC<ITableCEProps> = ({ changeOrder }) => (
  <div className="table-included-co">
    {changeOrder?.changeOrderEvents?.map((changeEvent) => (
      <div className="table-included-co__item">
        <TableCEItem {...changeEvent} />
      </div>
    ))}
  </div>
);

export default TableCE;
