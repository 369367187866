import { TLegalEntity } from '@/bundles/REturn/components/Ownership/ownershipTable/columns';
import InvestmentEntityColumn from '@/bundles/REturn/components/Ownership/ownershipTable/formatters/InvestmentEntityColumn';
import QuickFilterCheckList, {
  getDefaultMappingToOption,
  TQuickFilterCheckListProps,
} from '@/bundles/Shared/components/Table/filters/QuickFilterCheckList';
import { CapitalInvestment, CapitalInvestmentClass } from '@/entities/return';
import { InvestmentIndex } from '@/entities/return/api/capitalInvestmentObjectsGeneratedApi';
import { formatDate } from '@/shared/lib/formatting/dates';
import { DEFAULT_DATE_FALLBACK } from '@/shared/lib/formatting/fallbacks';
import { CAPITAL_INVESTMENT_CLASS_OPTIONS } from 'bundles/REturn/components/Ownership/selectInvestmentEntity/SelectInvestmentEntity';
import QuickFilterDropdown from 'bundles/Shared/components/Table/filters/QuickFilterDropdown';
import { IColumn } from 'bundles/Shared/components/Table/types';
import { useMemo } from 'react';
import { CurrencyFormatter } from 'stories/ValueFormatters/CurrencyFormatter';

interface Params {
  capitalInvestments: InvestmentIndex[];
  filterModel: {
    investmentEntity?: CapitalInvestmentClass | 'all';
  };
  setFilterModel: (data: unknown) => void;
  legalEntities: CapitalInvestment['legalEntity'][];
}

export function useInvestmentEntitiesColumns({
  capitalInvestments,
  filterModel,
  setFilterModel,
  legalEntities,
  sortSettings,
  setSortSettings,
}: Params) {
  const columns = useMemo<IColumn<CapitalInvestment>[]>(
    () => [
      {
        dataField: 'investmentEntity',
        text: 'Investment Entity',
        formatter: ({ row }) => (
          <InvestmentEntityColumn
            withIcon={false}
            capitalInvestment={row}
            investmentClasses={row.investmentClasses}
          />
        ),
        headerClasses: 'min-w-[320px]',
        filterComponent: QuickFilterDropdown,
        filterComponentParams: {
          options: CAPITAL_INVESTMENT_CLASS_OPTIONS,
          filterModel,
          setFilterModel,
        },
      },
      {
        dataField: 'legalEntity.name',
        text: 'Legal Entity',
        headerClasses: 'min-w-[250px]',
        formatter: ({ row }) => (
          <span className="dark-60 secondary-regular">
            {row.legalEntity.name}
          </span>
        ),
        filterComponent:
          legalEntities.length > 1 ? QuickFilterCheckList : undefined,
        filterComponentParams: {
          items: legalEntities,
          ...getDefaultMappingToOption<TLegalEntity>((le) => le.name),
          filterModel,
          setFilterModel,
        } as TQuickFilterCheckListProps<TLegalEntity>,
        sortable: legalEntities.length > 1,
        filterModel,
        setFilterModel,
        settings: sortSettings,
        setSettings: setSortSettings,
      },
      {
        dataField: 'firstContributionDate',
        text: 'First Contribution Date',
        formatter: ({ row }) => (
          <span className="dark-60 secondary-regular">
            {formatDate(row.firstContributionDate, 'MMM DD, YYYY') ||
              DEFAULT_DATE_FALLBACK}
          </span>
        ),
      },
      {
        dataField: 'contributions',
        text: 'Contributions',
        formatter: ({ row }) => (
          <div className="flex items-center justify-end">
            <CurrencyFormatter
              classes={{ value: 'inline-regular text-dark-60' }}
              value={row.totalContributionsDollars}
            />
          </div>
        ),
        headerClasses: 'w-[180px]',
      },
      {
        dataField: 'units',
        text: 'Units',
        formatter: ({ row }) => (
          <div className="flex items-center justify-end">
            {row.totalContributionUnits}
          </div>
        ),
      },
    ],
    [capitalInvestments],
  );

  return columns;
}
