import React from 'react';
import { Button } from '@/stories/Button/Button';
import { Logo } from 'bundles/Shared/components/LeftSidebar/Logo/Logo';

function ErrorBoundaryPage() {
  return (
    <div
      role="alert"
      className="flex h-screen flex-col justify-center text-center"
    >
      <div className="space-y-12 p-5">
        <div className="space-y-6">
          <Logo variant="large" className="h-full max-h-16 w-auto" />
          <h5>Sorry, we are experiencing technical difficulties.</h5>
          <div>
            <p>Our team is working to resolve the issue as soon as possible.</p>
            <p>Please try again later.</p>
          </div>
        </div>

        <Button
          variant="primary"
          onClick={() => window.location.replace('/')}
          size="m"
        >
          Go To Homepage
        </Button>
      </div>
    </div>
  );
}

export default ErrorBoundaryPage;
