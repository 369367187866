import React from 'react';
import UniversalFilterButton from 'bundles/Shared/components/Filters/buttons/universalFilterButton/UniversalFilterButton';
import LoadingPanel from 'bundles/Shared/components/LoadingPanel';
import { Popover } from 'stories/Popover/Popover';
import CheckList from 'stories/Checkbox/CheckList';
import {
  getMapOptionsToItemsHandler,
  mapItemsToListOption,
} from '@/shared/lib/listHelpers';

type CategoryItem = 'COR' | 'PCO' | 'IPC';

interface Props {
  items: CategoryItem[];
  value: CategoryItem[];
  onChange: (selectedObjects: CategoryItem[]) => void;
  resetFilter: () => void;
}

const QuickFilterByCategory = ({
  items,
  value,
  onChange,
  resetFilter,
}: Props) => {
  return (
    <Popover
      template={
        items ? (
          <CheckList
            items={mapItemsToListOption(items)}
            value={mapItemsToListOption(value)}
            onChange={getMapOptionsToItemsHandler(items, onChange)}
          />
        ) : (
          <LoadingPanel styles={{ minHeight: '15rem', minWidth: '15rem' }} />
        )
      }
      maxWidth="37.5rem"
      placement="bottom-start"
      hiddenArrow
      trigger="click"
      offset={[0, 2]}
    >
      {({ triggered }) => (
        <UniversalFilterButton
          clicked={triggered}
          filtered={value.length > 0}
          onClose={resetFilter}
        />
      )}
    </Popover>
  );
};

export default QuickFilterByCategory;
