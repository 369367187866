import { useGetApiReconcileForecastingForecastsQuery } from '@/entities/reconcile/forecasting/api/reconcileForecastingEntitiesEnchancedApi';

export const useForecastByName = (forecastName: string | undefined) => {
  return useGetApiReconcileForecastingForecastsQuery(
    {},
    {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      selectFromResult: ({ data: result, isLoading, isFetching }) => ({
        forecast:
          result?.items?.find((item) => item.name === forecastName) ?? null,
        isLoading,
        isFetching,
      }),
      skip: !forecastName,
    },
  );
};
