import { DialogProps } from '@/shared/lib/hooks/useModal';
import { useForm } from 'react-hook-form';
import { Button } from 'stories/Button/Button';
import { Modal } from 'stories/Modals/Modal/Modal';
import { Textarea } from 'stories/Textarea/Textarea';

const EditMediaModal = ({
  media,
  onClose,
  onSubmit,
}: DialogProps<{ description: string }>) => {
  const {
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      description: media?.description,
    },
  });

  const _handleSubmit = async (formData) => {
    onSubmit?.({ shared_file: { id: media.id, ...formData } });
  };

  const fields = watch();

  return (
    <Modal
      toggle={onClose}
      header="Edit Media"
      actions={
        <Button type="submit" variant="success" form="editMedia" fluid>
          Update
        </Button>
      }
      classes={{
        header: 'bg-light',
        body: 'bg-light',
        footer: 'bg-light',
      }}
    >
      <form id="editMedia" onSubmit={handleSubmit(_handleSubmit)}>
        <label htmlFor={`media-description-${media?.id}`}>Description</label>
        <Textarea
          id={`media-description-${media?.id}`}
          value={fields.description}
          onChange={(e) => setValue('description', e.target.value)}
          placeholder="Enter description"
          className={`${errors.description ? 'is-invalid' : ''}`}
        />
      </form>
    </Modal>
  );
};

export default EditMediaModal;
