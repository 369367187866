/* eslint-disable arrow-body-style */
import React, { FC, useMemo } from 'react';
import UserActivitySessionChart from 'bundles/Shared/widgets/user/activity/ui/UserActivitySessionChart';
import UserActivityCardsPanel from 'bundles/Shared/widgets/user/activity/ui/UserActivityCardsPanel';
import UserActivityFiltersBar from 'bundles/Shared/widgets/user/activity/ui/UserActivityFilters';
import { cn } from '@/shared/lib/css/cn';
import { values } from 'lodash-es';
import { useAppSelector } from '@/shared/lib/hooks/redux';
import {
  GetApiSettingsUserManagementUserActivitiesSummaryApiArg,
  useGetApiSettingsUserManagementUserActivitiesSummaryQuery,
} from 'bundles/Shared/entities/user/api/userActivityApi';
import { selectUserActivitySummary } from 'bundles/Shared/entities/user/slices/userActivitySlice';
import NoDataOverlay from 'bundles/Shared/components/NoDataOverlay';
import { AnimationLoader } from 'stories/AnimationLoader/AnimationLoader';
import { formatToDateStringForRequest } from '@/shared/lib/converters';

interface IUserActivityPanelProps {
  className?: string;
}

export function useHasUserActivitySummary() {
  const { fromDate, toDate, roleIds } = useAppSelector(
    selectUserActivitySummary,
  );

  const { data: summary, isFetching: isSummaryFetching } =
    useGetApiSettingsUserManagementUserActivitiesSummaryQuery({
      fromDate: formatToDateStringForRequest(fromDate),
      toDate: formatToDateStringForRequest(toDate),
      userRoleIds:
        roleIds as GetApiSettingsUserManagementUserActivitiesSummaryApiArg['userRoleIds'],
    });

  const hasSummary = useMemo(() => {
    return values(summary).some((value) => {
      if (Array.isArray(value)) {
        return value.length > 0;
      }

      return Boolean(value);
    });
  }, [summary]);

  return {
    hasUsersSummary: hasSummary,
    isSummaryFetching,
  };
}

const UserActivityPanel: FC<IUserActivityPanelProps> = ({ className }) => {
  const { hasUsersSummary, isSummaryFetching } = useHasUserActivitySummary();

  return (
    <div className={cn('relative flex flex-col gap-6', className)}>
      <UserActivityFiltersBar />
      {isSummaryFetching && (
        <AnimationLoader className="absolute -bottom-[48px] top-[48px] h-[calc(100%_-_48px)] bg-neutral-150/50" />
      )}
      {hasUsersSummary ? (
        <>
          <UserActivityCardsPanel />
          <UserActivitySessionChart />
        </>
      ) : (
        isSummaryFetching && <div className="flex min-h-[570px]" />
      )}
      {!isSummaryFetching && !hasUsersSummary && (
        <NoDataOverlay title="No data yet" />
      )}
    </div>
  );
};

export default UserActivityPanel;
