import React, { FC } from 'react';
import Select from 'react-select';
import { Field } from 'stories/Field/Field';
import { cn } from '@/shared/lib/css/cn';
import { IFormItem } from '@/bundles/Shared/components/GroupForm/types/types';

export interface IReactSelectOption {
  value: string | number;
  label: string;
}

interface IFormSelectProps extends IFormItem {
  options: IReactSelectOption[];
  menuPlacement: 'top' | 'bottom' | 'auto';
  menuPosition?: 'fixed';
  isMulti?: boolean;
  isGroupSelect?: boolean;
  inputId?: string;
  shortList?: boolean;
  required?: boolean;
}

const FormSelect: FC<IFormSelectProps> = ({
  title,
  placeholder,
  formData,
  setFormData,
  fieldName,
  required,
  hint,
  options,
  styles,
  isMulti,
  isGroupSelect,
  disabled = false,
  menuPlacement = 'bottom',
  menuPosition = undefined,
  inputId = fieldName,
  shortList,
}) => {
  const handleChange = (event: { value: string; label: string }): void => {
    setFormData({
      ...formData,
      [fieldName]: isMulti ? event : event.value,
    });
  };

  const valueGenerator = () => {
    if (isMulti) return formData[fieldName];
    if (isGroupSelect) {
      const allOptions = [];
      options.forEach((optionsGroup) => {
        optionsGroup.options.forEach((optionsGroupItem) => {
          allOptions.push(optionsGroupItem);
        });
      });
      return allOptions.filter(
        (opt) =>
          opt.value === (formData[fieldName]?.value || formData[fieldName]),
      )[0];
    }
    return options.filter((opt) => opt.value === formData[fieldName]);
  };

  return (
    <div
      className={cn(
        'form-item',
        'form-item__select',
        isMulti ? 'form-item__select_multi' : '',
        styles?.wrapper,
        styles?.nomb && 'form-item_nomb',
        shortList && 'short-list',
      )}
    >
      <Field
        labelFor={fieldName}
        labelText={title}
        required={required}
        noLabel={!title}
        hint={hint}
      >
        <Select
          inputId={inputId}
          placeholder={placeholder}
          menuShouldBlockScroll
          menuPosition={menuPosition}
          options={options}
          onChange={handleChange}
          classNamePrefix="react-select"
          value={valueGenerator()}
          isMulti={isMulti}
          isDisabled={disabled}
          menuPlacement={menuPlacement}
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              backgroundColor: styles?.input
                ? `var(--${styles.input})`
                : 'transparent',
            }),
          }}
        />
      </Field>
    </div>
  );
};

export default FormSelect;
