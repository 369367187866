import React, { ComponentProps } from 'react';
import buildingImg from 'images/building.svg';
import { cn } from '@/shared/lib/css/cn';
import { Icon } from 'stories/Icon/Icon';
import styles from '@/bundles/InvestmentObjects/components/investmentObjectImage/InvestmentObjectImage.module.scss';
import { Img } from 'bundles/Shared/components/layouts/screenWithListNavigationLayout/NavigationCardList';

interface Props
  extends PropsWithClassName,
    Pick<ComponentProps<typeof Img>, 'fallbackNode'> {
  pictureUrl?: string | null;
  onUpload?: () => void;
  onRemove?: () => void;
  imagePlaceholder?: string;
}

const InvestmentObjectImage = ({
  pictureUrl,
  onRemove,
  onUpload,
  className,
  fallbackNode,
  imagePlaceholder = buildingImg,
}: Props) => (
  <div
    className={cn(
      'label-semibold',
      styles.objectImageContainer,
      cn({
        [styles.placeHolder]: pictureUrl == null,
        'cursor-pointer': onUpload ?? onRemove,
      }),
      className,
    )}
  >
    <Img
      fallbackNode={fallbackNode}
      alt="investment object"
      src={pictureUrl ?? imagePlaceholder}
      className={cn(styles.image)}
    />
    {onUpload && (
      <div
        className={cn(styles.cover)}
        onClick={!pictureUrl ? onUpload : () => {}}
      >
        {pictureUrl && (
          <>
            <div
              className={cn('gap-2', styles.coverContentItem)}
              onClick={onRemove}
            >
              <Icon iconName="trash" style={{ fontSize: '1.5rem' }} />
              <div>Clear</div>
            </div>
            <div
              className={cn('gap-2', styles.coverContentItem)}
              onClick={onUpload}
            >
              <Icon iconName="uploadCloud" style={{ fontSize: '1.5rem' }} />
              <div>Upload</div>
            </div>
          </>
        )}
        {!pictureUrl && (
          <div className={cn(styles.coverContentItem)}>
            <Icon iconName="uploadCloud" style={{ fontSize: '2.5rem' }} />
            <div>Upload Cover</div>
          </div>
        )}
      </div>
    )}
  </div>
);

export default InvestmentObjectImage;
