import SourcesModal from '@/bundles/REturn/components/Ownership/ownershipsHeader/sourcesModal/SourcesModal';
import {
  useInvalidateCapitalTransactionSources,
  useLoadedCapitalTransactionSources,
} from '@/bundles/REturn/hooks/useLoadedCapitalTransactionSources';
import {
  useInvalidateCapitalInvestments,
  useLoadedInvestmentObject,
} from '@/bundles/REturn/hooks/useLoadedInvestmentObject';
import { TransactionSource } from '@/entities/return/api/capitalInvestmentObjectsGeneratedApi';
import { useAppDispatch } from '@/shared/lib/hooks/redux';
import { useModal } from '@/shared/lib/hooks/useModal';
import { Button } from '@/stories/Button/Button';
import FireIcon from 'bundles/FireStation/components/FireIcon/FireIcon';
import { TFireStationPriority } from 'bundles/FireStation/types';
import { createCapitalTransactionSource } from 'bundles/REturn/reducers/investmentObjectSlice';
import { useCustomer } from 'lib/customers';
import { REPORT_PRODUCT_NAME } from 'lib/permissions';
import React from 'react';
import {
  MANUAL_ENTRY_BADGE_JSX,
  SET_SOURCE_REMINDER_JSX,
} from '@/bundles/REturn/components/Ownership/modals/manageCapitalInvestmentModal/Columns/dumbJSX';
import { DistributionNoteTooltip } from '@/bundles/REturn/components/Ownership/modals/manageCapitalInvestmentModal/Columns/DistributionNoteTooltip';
import { Contribution, Distribution } from '@/entities/return';

export function HasNoSources({
  createInfo,
  type: kind,
  actionDropdown,
  entry,
}: {
  createInfo: React.ReactNode;
  type: TransactionSource['kind'];
  actionDropdown: React.ReactNode;
  entry: Contribution | Distribution;
}) {
  const { openModal } = useModal();
  const dispatch = useAppDispatch();
  const { data: capitalInvestmentObject } = useLoadedInvestmentObject();

  const {
    legalEntities,
    fromSourceEnabled,
    object: investmentObject,
  } = capitalInvestmentObject;
  const currentCustomerHasReportProduct =
    useCustomer().products?.includes(REPORT_PRODUCT_NAME);
  const { data: capitalTransactionSources } =
    useLoadedCapitalTransactionSources();
  const invalidateInvestments = useInvalidateCapitalInvestments();
  const invalidateTransactionSources = useInvalidateCapitalTransactionSources();

  // TODO: use select sources modal instead of copypaste
  const handleSelectSources = async () => {
    const res = await openModal(SourcesModal, {
      kind,
      capitalTransactionSources,
      legalEntitiesIds: legalEntities.map((le) => le.id),
    });
    if (res) {
      const objectInfo = {
        investment_object_id: investmentObject.id,
        investment_object_type: investmentObject.objectType,
      };

      const contributionsToastr =
        res.contributions.length > 0 || res.distributions.length > 0;
      const distributionsToastr = res.contributions.length == 0;

      if (res.contributions.length > 0) {
        await dispatch(
          createCapitalTransactionSource({
            ...objectInfo,
            kind: 'contribution',
            data: res.contributions,
            enableToastr: contributionsToastr,
          }),
        );
      }

      if (res.distributions.length > 0) {
        await dispatch(
          createCapitalTransactionSource({
            ...objectInfo,
            kind: 'distribution',
            data: res.distributions,
            enableToastr: distributionsToastr,
          }),
        );
      }

      invalidateTransactionSources();
      invalidateInvestments();
    }
  };

  const reportProductAndFromSource =
    currentCustomerHasReportProduct && fromSourceEnabled;

  return (
    <div className="flex flex-col gap-2">
      <div className="flex items-center justify-between gap-2">
        {reportProductAndFromSource && (
          <span className="secondary-regular grow text-light-60">
            No Tx. yet
          </span>
        )}
        {MANUAL_ENTRY_BADGE_JSX}
        <DistributionNoteTooltip note={entry.note} classes="d-flex mr-5" />
        {actionDropdown}
      </div>
      {createInfo}
      {reportProductAndFromSource && SET_SOURCE_REMINDER_JSX}
      {reportProductAndFromSource && (
        <div className="flex items-center gap-1">
          <Button
            variant="secondary"
            size="xs"
            onClick={handleSelectSources}
            className="self-start"
          >
            Select Sources
          </Button>
          <FireIcon priority={TFireStationPriority.HIGH} />
        </div>
      )}
    </div>
  );
}
