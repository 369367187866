import React, { useEffect, useRef } from 'react';
import { Modal } from 'stories/Modals/Modal/Modal';
import { TRouteParams, useNavigation } from '@/shared/lib/hooks/useNavigation';
import { navigate, useLocation, useParams } from '@reach/router';
import ReconcileDevelopmentSettingsNavigation from 'bundles/REconcile/components/development/home/settingsModal/ReconcileDevelopmentSettingsNavigation';
import CompaniesCrud from 'bundles/REconcile/components/development/home/settingsModal/companies/CompaniesCrud';
import ContactsCrud from 'bundles/REconcile/components/development/home/settingsModal/contacts/ContactsCrud';
import SovcsCrud from 'bundles/REconcile/components/development/home/settingsModal/sovcs/SovcsCrud';
import ChangeOrderReasonsCrud from 'bundles/REconcile/components/development/home/settingsModal/changeOrderReasons/ChangeOrderReasonsCrud';
import JCCCrud from '@/bundles/REconcile/components/development/home/settingsModal/jccs/JCCCrud';

function ReconcileDevelopmentSettingsModal() {
  const location = useLocation();
  const historyCount = useRef(0);
  const navigation = useNavigation();
  const { tab: currentTab } =
    useParams<TRouteParams['RECONCILE_DEVELOPMENT_SETTINGS']>();

  // todo replace with new router
  useEffect(() => {
    // remember referer page
    historyCount.current += 1;
  }, [location.pathname]);

  const getTabs = (): Record<
    Exclude<TRouteParams['RECONCILE_DEVELOPMENT_SETTINGS']['tab'], undefined>,
    React.ReactNode
  > => ({
    companies: <CompaniesCrud />,
    cors: <ChangeOrderReasonsCrud />,
    contacts: <ContactsCrud />,
    sovcs: <SovcsCrud />,
    jccs: <JCCCrud />,
  });

  return (
    <Modal
      size="huge"
      toggle={() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        navigate(
          window.history.length === 1
            ? navigation.getUrl('RECONCILE_DEVELOPMENT')
            : -historyCount.current,
        );
      }}
      classes={{
        body: 'bg-light-10',
      }}
      header={<h6 className="header6-bold">REconcile Settings</h6>}
    >
      <div className="flex flex-col gap-m">
        <ReconcileDevelopmentSettingsNavigation />
        {currentTab !== undefined && getTabs()[currentTab]}
      </div>
    </Modal>
  );
}

export default ReconcileDevelopmentSettingsModal;
