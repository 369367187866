import { useDeleteApiCoreAssetsByAssetIdReportBuilderReportsAndIdMutation } from '@/entities/report/reportBuilder';
import { DELETE_REPORT_CONFIRM_PROPS } from '@/features/report/report/deleteReport/lib';
import { useModal } from '@/shared/lib/hooks/useModal';
import { IAsset } from '@/types/Asset';

export const useDeleteObjectLevelReport = () => {
  const [deleteReport, options] =
    useDeleteApiCoreAssetsByAssetIdReportBuilderReportsAndIdMutation();
  const { confirm } = useModal();

  const handleDelete = async (reportId: string, assetId: IAsset['id']) => {
    const answer = await confirm(DELETE_REPORT_CONFIRM_PROPS);

    if (!answer) return;

    const response = await deleteReport({
      id: reportId,
      assetId,
    });

    return response;
  };

  return [handleDelete, options] as const;
};
