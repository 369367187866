import React, { ReactNode } from 'react';
import { formatDate } from '@/shared/lib/formatting/dates';
import { IFormatterProps } from 'bundles/Shared/components/Table/types';
import { VendorPopover } from '@/entities/accounting/transaction/ui/VendorPopover';
import { VendorEntity } from 'bundles/Shared/entities/investmentEntities/model';
import { Icon } from 'stories/Icon/Icon';
import { InlineObject } from 'stories/Objects/InlineObject/InlineObject';
import { RemarkCell } from '@/entities/accounting/transaction/ui/RemarkCell';
import {
  DISABLE_CURRENCY_SYMBOL_OPTIONS,
  formatAmount,
} from '@/shared/lib/formatting/number';
import { capitalize } from 'lodash-es';
import { cn } from '@/shared/lib/css/cn';
import { DEFAULT_STRING_FALLBACK } from '@/shared/lib/formatting/fallbacks';
import { CLASS_NAMES_BY_TRANSACTION_TYPE } from '@/entities/accounting/transaction/config';
import { AccountingTransactionType } from '@/entities/accounting/transaction/model';

export const getTransactionDateFormatter =
  <T,>(dateGetter: (item: T) => string | number) =>
  ({ row }: IFormatterProps<T>) =>
    formatDate(dateGetter(row), 'LL');

export const getAmountFormatter =
  <T,>(amountGetter: (item: T) => string | number) =>
  ({ row }: IFormatterProps<T>) => (
    <>
      <span className="light-60 inline-regular mr-1">$</span>
      {formatAmount(amountGetter(row), DISABLE_CURRENCY_SYMBOL_OPTIONS)}
    </>
  );

export const getVendorEntityFormatter =
  <T,>(vendorGetter: (item: T) => Pick<VendorEntity, 'name' | 'code'> | null) =>
  ({ row }: IFormatterProps<T>) =>
    vendorGetter(row) ? (
      <VendorPopover vendor={vendorGetter(row)}>
        <InlineObject
          iconName="bag"
          object={vendorGetter(row)!.name}
          hideTooltipWhenOverflow
        />
      </VendorPopover>
    ) : (
      DEFAULT_STRING_FALLBACK
    );

export const getRemarkFormatter =
  <T,>(remarkGetter: (item: T) => ReactNode | undefined | null) =>
  ({ row }: IFormatterProps<T>) => <RemarkCell text={remarkGetter(row)} />;

export const getTransactionTypeFormatter = (transaction: {
  type: AccountingTransactionType;
}) => (
  <div className="flex items-center gap-2">
    <Icon
      className={cn(
        'rounded-lg p-1',
        CLASS_NAMES_BY_TRANSACTION_TYPE[transaction.type],
      )}
      iconName={transaction.type}
    />
    <span className="secondary-regular text-light-60">
      {capitalize(transaction.type)}
    </span>
  </div>
);
