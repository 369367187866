import { sortBy, uniqBy } from 'lodash-es';
import { useMemo } from 'react';
import { IAccessListItem } from '@/bundles/Shared/components/Permissions/types';
import { Permitted } from 'bundles/Shared/entities/permissions';

interface Params {
  permissions: Permitted;
}

interface Return {
  accessList: IAccessListItem[];
}

function mapToAccessListByName(
  items: { name: string }[],
  type: IAccessListItem['type'],
): IAccessListItem[] {
  return items.map((item) => ({ value: item.name, type }));
}

export function useAccessList({ permissions }: Params): Return {
  const {
    directUsers = [],
    directRoles = [],
    directTags = [],
    indirectUsers = [],
    directInvestmentEntities = [],
  } = permissions;

  const accessList = useMemo<IAccessListItem[]>(() => {
    const allUsers = uniqBy([...directUsers, ...indirectUsers], 'id');

    const list: IAccessListItem[] = [
      ...allUsers.map(
        (user): IAccessListItem => ({
          value: user.fullName,
          type: 'member',
          role: user.role?.name,
        }),
      ),
      ...mapToAccessListByName(directRoles, 'role'),
      ...mapToAccessListByName(directTags, 'tag'),
      ...mapToAccessListByName(directInvestmentEntities, 'entity'),
    ];

    return sortBy(list, (item) => item.value.toLowerCase());
  }, [permissions]);

  return {
    accessList,
  };
}
