import { useReportingEntityKindContext } from '@/entities/reporting/context/entityKind';
import { useNavigateBack } from '@/shared/lib/hooks/navigation';
import { ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';

export const useReportBuilderNavigateBackFromBuilder = () => {
  const reportKind = useReportingEntityKindContext();

  const { navigateBack } = useNavigateBack({
    fallbackUrl:
      reportKind === 'eagle_eye'
        ? ROUTES_ROOT.reporting.fullPath
        : ROUTES_ROOT.assets.fullPath,
  });
  return navigateBack;
};
