import React from 'react';
import { Checkbox } from 'stories/Checkbox/Checkbox';
import { Icon } from 'stories/Icon/Icon';
import { IconsId } from 'types/sre-icons';
import styles from '@/bundles/Shared/components/BulkActionsPanel/BulkActionsPanel.module.scss';
import FlyBottomPanelBarButton from 'bundles/Settings/components/REport/CategoriesList/PathBar/FlyBottomPanelBarButton';

export interface IBulkActions {
  title: string;
  hidden?: boolean;
  handleClick?: () => void;
  href?: string;
  tooltip?: string;
  icon?: IconsId;
}

interface Props<T> {
  selectedRows: T[];
  setSelectedRows: (rows: T[]) => void;
  actions: IBulkActions[];
}
const ACTION_CLASSES = 'font-weight-600 light no-borders flex items-center';

const DUMB_NO_AVAILABLE_ACTIONS = (
  <span className="light inline-semibold flex h-[2rem] items-center">
    No Available Bulk Actions
  </span>
);

// TODO: replace with FlyBottomPanel
export function BulkActionsPanel<T = any>({
  selectedRows,
  setSelectedRows,
  actions,
}: Props<T>) {
  const visibleActions = actions.filter(
    (action) =>
      !(action?.hidden && action.hidden === true) && action?.handleClick,
  );

  return (
    <div className={styles.panel}>
      <div className="flex items-center gap-3">
        <Checkbox
          onChange={() => setSelectedRows([])}
          defaultChecked
          labelClassName="align-self-center mr-m"
        >
          <div className="light inline-semibold">
            {selectedRows.length} selected
          </div>
        </Checkbox>
        <div className={styles.separator} />
        <div className="flex gap-1">
          {actions
            .filter((action) => !(action?.hidden && action.hidden === true))
            .map((action) => (
              <React.Fragment key={action.title}>
                {action.href && (
                  <a
                    href={action.href}
                    className={`${ACTION_CLASSES} btn btn-outline-dark`}
                  >
                    <Icon iconName="transferFile" className="mr-xs" />
                    {action.title}
                  </a>
                )}
                {action.handleClick && (
                  <FlyBottomPanelBarButton
                    onClick={action.handleClick}
                    iconName={action.icon}
                    tooltipProps={
                      action.tooltip
                        ? {
                            mainText: action.tooltip,
                          }
                        : undefined
                    }
                  >
                    {action.title}
                  </FlyBottomPanelBarButton>
                )}
              </React.Fragment>
            ))}

          {visibleActions.length === 0 && DUMB_NO_AVAILABLE_ACTIONS}
        </div>
      </div>
    </div>
  );
}

export default BulkActionsPanel;
