import React, { useState } from 'react';
import { IUser } from 'types/User';
import { AnchorBadge } from 'stories/Badges/anchorBadge/AnchorBadge';
import { IconButton } from 'stories/IconButton/IconButton';
import { cn } from '@/shared/lib/css/cn';
import pluralize from 'pluralize';
import UserActivityLogModal from '@/bundles/UserManagement/components/UserActivityLogModal';
import ErrorMessage from '@/bundles/UserManagement/components/errors/errorMessage/ErrorMessage';
import * as cellStyles from '@/bundles/UserManagement/components/table/cellFormatters/CellFormatters.module.scss';
import styles from '@/bundles/UserManagement/components/table/cellFormatters/userTableActivityCell/UserTableActivityCell.module.scss';
import { IUserTableColumnsActions } from '@/bundles/UserManagement/components/table/userTableColumns';

interface Props {
  user: IUser;
  action: IUserTableColumnsActions['invite'];
}

function UserTableActivityCell({ user, action }: Props) {
  const [displayActivityLog, setDisplayActivityLog] = useState(false);

  return (
    <div
      className={cn('p-s', {
        [cellStyles.fired]: user.lastInvitationFailed,
      })}
    >
      {displayActivityLog && (
        <UserActivityLogModal
          userId={user.id}
          userName={user.fullName}
          inviteStatus={user.inviteStatus}
          lastSeenAt={user.lastSeenAt}
          lastSignInAt={user.lastSignInAt}
          onClose={() => setDisplayActivityLog(false)}
        />
      )}
      <div
        role="link"
        className={cn('flex items-center pb-s pl-s pt-s', {
          'cursor-pointer': user.emailMessagesCount > 0,
        })}
        onClick={() =>
          user.emailMessagesCount > 0 && setDisplayActivityLog(true)
        }
      >
        <AnchorBadge
          className={cn(styles.anchorBadge, 'mr-s')}
          classes={{
            badgeContent: cn('bg-pumpkin', {
              'd-none': !user.lastInvitationFailed,
            }),
          }}
        >
          <IconButton
            className={cn(user.emailMessagesCount > 0 && 'bg-white')}
            disabled={user.emailMessagesCount === 0}
            iconName="log"
          />
        </AnchorBadge>

        <span
          className={cn('inline-regular inline-regular', {
            blue: user.emailMessagesCount > 0,
            'light-60': user.emailMessagesCount === 0,
          })}
        >
          {user.emailMessagesCount > 0
            ? `${user.emailMessagesCount} ${pluralize(
                'Email',
                user.emailMessagesCount,
              )}`
            : 'No emails'}
        </span>
      </div>
      {user.lastInvitationFailed && (
        <ErrorMessage
          className="mt-s"
          text="There is a problem with sending an email"
          actionLabel="Invite again"
          onActionClick={() => action(user)}
        />
      )}
    </div>
  );
}

export default UserTableActivityCell;
