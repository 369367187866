import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import type * as Types from 'bundles/UserManagement/components/roles/types';
import { IUserRole } from 'types/User';
import {
  newPlainFetchUserRoles,
  plainCreateUserRole,
  plainDeleteUserRole,
  plainFecthAllUserRoleActivityLog,
  plainUpdateUserRole,
} from '@/bundles/UserManagement/actions/UserRole';
import { camelKeysToSnake } from '@/bundles/UserManagement/components/roles/utils';

type Position =
  | 'idle'
  | 'loading'
  | 'loaded'
  | 'error'
  | 'create-role'
  | 'all-user-roles-actvityLog';

interface IUserRolesState {
  roles: IUserRole[];
  position: Position;
  activityLog: Types.IUserRoleActivityLogItem[];
  activeRoleId: IUserRole['id'] | null;
}

const errorToast = () => window.toastr.error('Something went wrong');

const initialState: IUserRolesState = {
  roles: [],
  position: 'idle',
  activityLog: [],
  activeRoleId: null,
};

export const fetchUserRoleActivityLog = createAsyncThunk(
  'userRoles/fetchActivityLog',
  async () => {
    const data: Types.IUserRoleActivityLogItem[] =
      await plainFecthAllUserRoleActivityLog();
    return data;
  },
);

export const fetchUserRoles = createAsyncThunk('userRoles/fetch', async () => {
  const data: IUserRole[] = await newPlainFetchUserRoles();
  return data;
});

export const deleteUserRole = createAsyncThunk(
  'userRoles/delete',
  async (id: IUserRole['id']) => {
    await plainDeleteUserRole(id);
    return id;
  },
);

export const createUserRole = createAsyncThunk(
  'userRoles/create',
  async (data: Types.ICreateRoleFormData) => {
    data.products = data.products.map((p) => camelKeysToSnake(p));
    const res: IUserRole = await plainCreateUserRole(data);
    return res;
  },
);

export const updateUserRole = createAsyncThunk(
  'userRoles/update',
  async ({
    id,
    data,
  }: {
    data: Partial<Pick<IUserRole, 'group' | 'name' | 'products'>>;
    id: IUserRole['id'];
  }) => {
    const res: IUserRole = await plainUpdateUserRole({ id, data });
    return res;
  },
);

const userRolesSlice = createSlice({
  name: 'userRoles',
  initialState,
  reducers: {
    changePagePosition(state, action: PayloadAction<Position>) {
      state.position = action.payload;
    },
    changeActiveRoleId(state, action: PayloadAction<IUserRole['id'] | null>) {
      state.activeRoleId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserRoles.pending, (state) => {
        state.position = 'loading';
      })
      .addCase(
        fetchUserRoles.fulfilled,
        (state, action: PayloadAction<IUserRole[]>) => {
          state.position = 'loaded';
          state.roles = action.payload;
        },
      )
      .addCase(fetchUserRoles.rejected, (state) => {
        state.position = 'error';
        state.roles = [];
        errorToast();
      });

    builder
      .addCase(
        createUserRole.fulfilled,
        (state, action: PayloadAction<IUserRole>) => {
          state.roles.push(action.payload);
          state.activeRoleId = action.payload.id;
          state.position = 'idle';
          window.toastr.success('The role has been created');
        },
      )
      .addCase(createUserRole.rejected, (state) => {
        state.position = 'error';
        errorToast();
      });

    builder
      .addCase(
        updateUserRole.fulfilled,
        (state, action: PayloadAction<IUserRole>) => {
          state.roles = [
            ...state.roles.filter((r) => r.id !== action.payload.id),
            action.payload,
          ];
          window.toastr.success('Modifications saved');
        },
      )
      .addCase(updateUserRole.rejected, () => {
        errorToast();
      });

    builder
      .addCase(
        deleteUserRole.fulfilled,
        (state, action: PayloadAction<IUserRole['id']>) => {
          state.roles = state.roles.filter((r) => r.id !== action.payload);
          window.toastr.success('The role has been removed');
        },
      )
      .addCase(deleteUserRole.rejected, (state) => {
        state.position = 'error';
        errorToast();
      });

    builder.addCase(
      fetchUserRoleActivityLog.fulfilled,
      (state, action: PayloadAction<Types.IUserRoleActivityLogItem[]>) => {
        state.activityLog = action.payload;
      },
    );
  },
});

export const { changeActiveRoleId, changePagePosition } =
  userRolesSlice.actions;
export default userRolesSlice.reducer;
