import React, { FC, useRef, useState } from 'react';
import { CategoryObject } from 'stories/Objects/CategoryObject/CategoryObject';
import { useEffect } from 'react';

const SAFE_WIDTH_COLUMN = 240;

const CategoryLineItem: FC<ICategoryLineItemProps> = ({ item }) => {
  const [longText, setLongText] = useState(false);
  const categoryRef = useRef(null);
  useEffect(() => {
    if (categoryRef.current.clientWidth > SAFE_WIDTH_COLUMN) {
      setLongText(true);
    }
  }, [item]);
  return (
    <div
      style={{
        width: 'fit-content',
      }}
      ref={categoryRef}
    >
      <CategoryObject
        path={item?.developmentCategory?.path}
        longText={longText}
      />
    </div>
  );
};

export default CategoryLineItem;
