import React, { useState } from 'react';
import { IconButton } from '@/stories/IconButton/IconButton';
import { Modal } from '@/stories/Modals/Modal/Modal';
import ReportSettingsNavigation from '@/bundles/Settings/shared/ReportSettingsNavigation';
import { MyDetailsTab } from '@/widgets/userProfile/ui/tabs/MyDetailsTab';
import { UserInfoSection } from '@/bundles/Shared/entities/user/ui/UserInfoSection';
import { useLoadedUserProfile } from '@/widgets/userProfile/hooks/useLoadedUserProfile';
import { AccountSecurityTab } from '@/widgets/userProfile/ui/tabs/AccountSecurityTab';

const TABS = [
  {
    key: 'myDetails',
    label: 'My Details',
  },
  {
    key: 'accountSecurity',
    label: 'Account Security',
  },
  // {
  //   key: 'investmentEntities',
  //   label: 'Investment Entities',
  // },
  // {
  //   key: 'preferences',
  //   label: 'Preferences',
  // },
] as const;

type TabKey = (typeof TABS)[number]['key'];

// TODO: Move to shared and rename
export const TabHeader = ({
  label,
  activeTab,
  tabs,
  onClose,
}: {
  label: string;
  activeTab: TabKey;
  tabs: typeof TABS;
  onClose: () => void;
}) => {
  return (
    <div className="flex w-full items-center justify-between">
      <div>
        <span className="text-xs text-neutral-550">{label}</span>

        <h3 className="text-xl font-bold text-neutral-850">
          {tabs.find((tab) => tab.key === activeTab)?.label}
        </h3>
      </div>
      <IconButton
        iconName="close"
        size="l"
        onClick={onClose}
        variant="secondary"
        className="space-0 text-light-6"
      />
    </div>
  );
};

// TODO: Move to shared and rename
export const TabNavigation = ({
  activeTab,
  setActiveTab,
  tabs,
}: {
  activeTab: TabKey;
  setActiveTab: (tab: TabKey) => void;
  tabs: typeof TABS;
}) => {
  return (
    <ReportSettingsNavigation>
      <ReportSettingsNavigation.List title="">
        {tabs
          .filter((tab) => !tab?.disabled)
          .map((tab) => (
            <div
              onClick={() => setActiveTab(tab.key)}
              className="cursor-pointer"
              key={tab.key}
            >
              <ReportSettingsNavigation.Item active={activeTab === tab.key}>
                {tab.label}
              </ReportSettingsNavigation.Item>
            </div>
          ))}
      </ReportSettingsNavigation.List>
    </ReportSettingsNavigation>
  );
};

export const UpdateUserModal = ({ onClose }: { onClose: () => void }) => {
  const { data: currentUser } = useLoadedUserProfile();
  const [activeTab, setActiveTab] = useState<TabKey>('myDetails');

  if (!currentUser) return null;

  return (
    <Modal
      toggle={onClose}
      size="1000"
      classes={{
        body: 'flex min-h-[600px]',
      }}
    >
      <div className="w-[250px] border-r bg-light-5 pr-12">
        <div className="light-60 inline-regular flex flex-col items-center text-center">
          <UserInfoSection
            roleName={currentUser.userRole.name}
            fullName={currentUser.fullName}
            email={currentUser.email}
            size="xs"
          />
        </div>
        <hr className="w-full" />

        <TabNavigation
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          tabs={TABS}
        />
      </div>

      <div className="relative flex-1 pl-l">
        <TabHeader
          activeTab={activeTab}
          onClose={onClose}
          label="Profile"
          tabs={TABS}
        />

        {activeTab === 'myDetails' && <MyDetailsTab />}
        {activeTab === 'accountSecurity' && <AccountSecurityTab />}
      </div>
    </Modal>
  );
};

export default UpdateUserModal;
