import {
  usePutApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdMutation,
  usePutApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdMutation,
  usePutApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdMutation,
} from '@/shared/api/dashboardSettingsEnhancedApi';
import { useCallback, useMemo } from 'react';
import { ReportDashboardType } from 'bundles/Shared/entities/dashboard/model/types/types';
import type { EagleEyeBoard } from 'bundles/Shared/entities/dashboard/model/types/types';
import { useDashboardContext } from 'bundles/Shared/entities/dashboard/lib';

export const useMoveBoards = () => {
  const { dashboardId, dashboardType } = useDashboardContext();

  const objectMutation =
    usePutApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdMutation();
  const eagleEyeMutation =
    usePutApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdMutation();
  const comparisonMutation =
    usePutApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdMutation();

  const [updateBoard] = useMemo(() => {
    switch (dashboardType) {
      case ReportDashboardType.OBJECT: {
        return objectMutation;
      }
      case ReportDashboardType.EAGLE_EYE: {
        return eagleEyeMutation;
      }
      case ReportDashboardType.COMPARISON_MODE: {
        return comparisonMutation;
      }
    }
  }, [dashboardType]);

  const moveBoards = useCallback(
    async ({ boards }: { boards: Pick<EagleEyeBoard, 'id'>[] }) => {
      await Promise.all(
        boards.map((board, index) => {
          return updateBoard({
            eagleEyeDashboardId: dashboardId,
            objectDashboardId: dashboardId,
            comparisonDashboardId: dashboardId,
            boardId: board.id,
            body: {
              order: index,
            },
          });
        }),
      );
      toastr.success('Boards reordered successfully');
    },
    [updateBoard],
  );

  return moveBoards;
};
