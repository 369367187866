import React, { useState } from 'react';
import { Modal } from '@/stories/Modals/Modal/Modal';
import { ModalHeaderWithSubtitle } from '@/stories/Modals/ModalHeader/ModalHeaderWithSubtitle/ModalHeaderWithSubtitle';
import { IGeneralLedgerOnTransactionsIndex } from 'types/GeneralLedger';
import TransactionsTable from '@/widgets/accounting/transaction/detailsModal/ui/TransactionsTable';
import { TSort } from 'types/Sort';
import {
  PageParamsPagination,
  PageParamsProvider,
} from '@/bundles/Shared/components/pageParams';
import { IPaginationParams } from '@/types/Pagination';
import { ReckonerPeriod } from '@/shared/lib/reckoner/model';
import { useAccountingTransactions } from '@/entities/accounting/transaction/api';

interface Props {
  generalLedger: Pick<
    IGeneralLedgerOnTransactionsIndex,
    'id' | 'accTitle' | 'accNumber'
  >;
  onClose: () => void;
  showUnpublished?: boolean;
  periods?: ReckonerPeriod;
}

export function TransactionDetailsModal({
  generalLedger,
  onClose,
  showUnpublished,
  periods,
}: Props) {
  const [pageParams, setPageParams] = useState<IPaginationParams>({
    page: 1,
    perPage: 10,
  });

  const [settings, setSettings] = useState({
    sortOrder: TSort.DESC,
    sortField: 'date',
  });

  const { transactions, meta, isLoading } = useAccountingTransactions({
    ...settings,
    showUnpublished,
    generalLedgerIds: [generalLedger.id],
    page: pageParams.page,
    perPage: pageParams.perPage,
    period: periods,
    sort: {
      field: settings.sortField,
      order: settings.sortOrder,
    },
  });

  return (
    <Modal
      header={
        <ModalHeaderWithSubtitle
          order="title-subtitle"
          title={generalLedger.accTitle}
          subtitle={generalLedger.accNumber}
        />
      }
      size="huge"
      toggle={onClose}
      backdrop={false}
      classes={{
        body: 'bg-light-10 gap-4 flex-col flex',
      }}
    >
      <PageParamsProvider pageParams={pageParams} setPageParams={setPageParams}>
        <h6 className="header-6 ">Transactions</h6>
        <PageParamsPagination totalSize={meta?.totalSize ?? 0} />

        <TransactionsTable
          isLoading={isLoading}
          setSettings={setSettings}
          settings={settings}
          transactions={transactions ?? []}
        />
      </PageParamsProvider>
    </Modal>
  );
}
