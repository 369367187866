import { formatAmount } from '@/shared/lib/formatting/number';
import { IWorkCost } from '@/bundles/Construction/components/ChangeOrderEvent/CostBreakdownDetails/components/CostOfWork';
import { AVAILABLE_CUSHION_PERCENT } from 'bundles/Construction/components/ChangeOrderEvent/CostBreakdownDetails/consts';
import { inRange } from 'lodash-es';
import {
  IFee,
  IMarkup,
} from 'bundles/Construction/components/ChangeOrderEvent/CostBreakdownDetails/types';

export const calcAllowedMarkupValue = (
  markups: IMarkup['changeOrderMarkup'][],
  markupItem: IMarkup,
  invalidAmount: number,
) => {
  let textMessage: string | null = null;

  const markup = markups.find(
    (markup) => markup.id === markupItem.changeOrderMarkup,
  );
  const value = (markup?.percent * invalidAmount) / 100;

  if (invalidAmount || invalidAmount === 0) textMessage = formatAmount(value);
  else textMessage = 'Please choose Cost of Work';

  return [value, textMessage];
};

export const calcAllowedFeeValue = (
  fees: IFee['changeOrderFee'][],
  feeItem: IFee,
  invalidAmount: number,
) => {
  let textMessage: string | null = null;

  const fee = fees.find((fee) => fee.id === feeItem.changeOrderFee);
  const value = (fee?.percent * invalidAmount) / 100;

  if (invalidAmount || invalidAmount === 0) textMessage = formatAmount(value);
  else textMessage = 'Please choose Cost of Work';

  return [value, textMessage];
};

export const compareValuesCostBreakDown = (first: string, second: string) =>
  Number(first.toFixed(2)) === Number(second);

export const withinCushionedAmount = (amount: number, corAmount: number) => {
  const cushion = (AVAILABLE_CUSHION_PERCENT / 100) * corAmount;

  return inRange(amount, corAmount - cushion, corAmount + cushion);
};

export const validateCostBreakDown = (
  workCostData: IWorkCost[],
  markupData: IMarkup[],
  feeData: IFee[],
  changeOrderMarkups: IMarkup['changeOrderMarkup'][],
  changeOrderFees: IFee['changeOrderFee'][],
  invalidAmount: number,
  invalidFeeAmount: number,
) => {
  const validWorkCost = workCostData.filter((workCost: IWorkCost) =>
    Boolean(
      workCost.changeOrderReason &&
        workCost.scheduleOfValueCode &&
        (workCost.amount || workCost.amount === 0),
    ),
  );

  const validMarkups = markupData.filter((markup: IMarkup) => {
    const [markupValue] = calcAllowedMarkupValue(
      changeOrderMarkups,
      markup,
      invalidAmount,
    );

    const coincidenceMarkupValue = withinCushionedAmount(
      markup?.amount,
      markupValue,
    );

    return Boolean(
      (markup.amount || markup.amount === 0) &&
        markup.changeOrderMarkup &&
        (coincidenceMarkupValue ? true : markup.comment),
    );
  });

  const validFees = feeData.filter((fee: IFee) => {
    const [feeValue] = calcAllowedFeeValue(
      changeOrderFees,
      fee,
      invalidFeeAmount,
    );

    const coincidenceFeeValue = withinCushionedAmount(fee?.amount, feeValue);

    return Boolean(
      (fee.amount || fee.amount === 0) &&
        fee.changeOrderFee &&
        Boolean(coincidenceFeeValue ? true : fee.comment),
    );
  });

  return Boolean(
    (workCostData.length || (!markupData.length && !feeData.length)) &&
      validMarkups.length === markupData.length &&
      validFees.length === feeData.length &&
      validWorkCost.length === workCostData.length,
  );
};

export const checkAmount = (item: IMarkup | IFee, invalidAmount: number) => {
  const defaultValue = (invalidAmount * Number(item.costCode.percent)) / 100;
  const cushion = (defaultValue * AVAILABLE_CUSHION_PERCENT) / 100;

  const min = defaultValue - cushion;
  const max = defaultValue + cushion;

  const amount = Number(item.amount);
  if (amount < min || amount > max) {
    return Math.floor(defaultValue);
  }
};
