import {
  PostApiCoreAssetsByAssetIdReportBuilderReportsAndIdPublishApiResponse,
  PutApiCoreAssetsByAssetIdReportBuilderReportsAndIdApiResponse,
  coreAssetsReportBuilderReportsGeneratedApi,
  type PutApiCoreAssetsByAssetIdReportBuilderReportsAndReportIdWidgetSectionsWidgetSectionIdSnapshotApiResponse,
} from '@/entities/report/reportBuilder/api/coreAssetsReportBuilderReportsGeneratedApi';
import { createApiTagType } from '@/shared/lib/api/lib';
import { EntityToastrCreator } from '@/shared/lib/toastr/entityToastrCreator';

const t = new EntityToastrCreator('Report');

const createReportTag = (id: string) => createApiTagType('Report', id);
const createWidgetSectionTag = (id: string) =>
  createApiTagType('WidgetSection', id);

export const coreAssetsReportBuilderReportsEnhancedApi =
  coreAssetsReportBuilderReportsGeneratedApi.enhanceEndpoints({
    addTagTypes: [
      'ReportList',
      'Report',
      'ReportWidget',
      'WidgetSection',
      'ReportSnapshot',
    ],
    endpoints: {
      getApiCoreAssetsByAssetIdReportBuilderReports: {
        providesTags: ['ReportList'],
      },
      postApiCoreAssetsByAssetIdReportBuilderReports: {
        invalidatesTags: ['ReportList'],
      },
      getApiCoreAssetsByAssetIdReportBuilderReportsAndId: {
        providesTags: (result) => {
          if (result?.report == null) return [];

          return [createReportTag(result.report.id)];
        },
      },
      deleteApiCoreAssetsByAssetIdReportBuilderReportsAndId: {
        invalidatesTags: ['ReportList'],
        transformResponse: (
          response: PutApiCoreAssetsByAssetIdReportBuilderReportsAndIdApiResponse,
        ) => {
          toastr.success(t.delete().toString());
          return response;
        },
      },
      postApiCoreAssetsByAssetIdReportBuilderReportsAndReportIdWidgetSectionsWidgetSectionIdSnapshot:
        {
          providesTags: (_, __, args) => {
            return [
              createWidgetSectionTag(args.widgetSectionId),
              createApiTagType('ReportSnapshot', args.reportId),
            ];
          },
        },
      getApiCoreAssetsByAssetIdReportBuilderReportsAndReportIdWidgetSectionsWidgetSectionIdSnapshot:
        {
          providesTags: (_, __, args) => {
            return [
              createWidgetSectionTag(args.widgetSectionId),
              createApiTagType('ReportSnapshot', args.reportId),
            ];
          },
        },
      putApiCoreAssetsByAssetIdReportBuilderReportsAndId: {
        transformResponse: (
          response: PutApiCoreAssetsByAssetIdReportBuilderReportsAndIdApiResponse,
        ) => {
          toastr.success(t.update().toString());
          return response;
        },
        invalidatesTags: (_, __, args) => {
          const reportId = args.id;

          const tags = [
            'ReportList',
            createApiTagType('ReportSnapshot', reportId),
            createReportTag(reportId),
          ] as const;

          return tags;
        },
      },
      postApiCoreAssetsByAssetIdReportBuilderReportsAndIdPublish: {
        invalidatesTags: ['ReportList', 'Report'],
        transformResponse: (
          response: PostApiCoreAssetsByAssetIdReportBuilderReportsAndIdPublishApiResponse,
        ) => {
          toastr.success(t.publish().toString());
          return response;
        },
      },
      putApiCoreAssetsByAssetIdReportBuilderReportsAndReportIdWidgetSectionsWidgetSectionIdSnapshot:
        {
          transformResponse: (
            response: PutApiCoreAssetsByAssetIdReportBuilderReportsAndReportIdWidgetSectionsWidgetSectionIdSnapshotApiResponse,
          ) => {
            toastr.success(t.update().toString());
            return response;
          },
          invalidatesTags: (_, __, args) => {
            return [
              createReportTag(args.reportId),
              createApiTagType('ReportSnapshot', args.reportId),
              createWidgetSectionTag(args.widgetSectionId),
            ] as const;
          },
        },
    },
  });

export const {
  // Object level Reports
  useGetApiCoreAssetsByAssetIdReportBuilderReportsQuery,
  usePostApiCoreAssetsByAssetIdReportBuilderReportsMutation,
  useDeleteApiCoreAssetsByAssetIdReportBuilderReportsAndIdMutation,
  usePutApiCoreAssetsByAssetIdReportBuilderReportsAndIdMutation,
  useGetApiCoreAssetsByAssetIdReportBuilderReportsAndIdQuery,

  // Snapshot management
  usePutApiCoreAssetsByAssetIdReportBuilderReportsAndReportIdWidgetSectionsWidgetSectionIdSnapshotMutation,
  usePostApiCoreAssetsByAssetIdReportBuilderReportsAndReportIdWidgetSectionsWidgetSectionIdSnapshotQuery,
  useGetApiCoreAssetsByAssetIdReportBuilderReportsAndReportIdWidgetSectionsWidgetSectionIdSnapshotQuery,

  usePostApiCoreAssetsByAssetIdReportBuilderReportsAndIdPublishMutation,
} = coreAssetsReportBuilderReportsEnhancedApi;
