import React, { useMemo } from 'react';
import { Button } from 'stories/Button/Button';
import { Icon } from 'stories/Icon/Icon';
import { InlineObject } from 'stories/Objects/InlineObject/InlineObject';
import { StatisticLine } from 'stories/filterCard/statistic/StatisticLine';
import { formatDate } from '@/shared/lib/formatting/dates';
import { formatAmount } from '@/shared/lib/formatting/number';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import {
  IDevelopmentLegalEntityRequisitionTransaction,
  ILineItem,
} from 'bundles/REconcile/types/ILineItem';
import { VendorPopover } from '@/entities/accounting/transaction/ui/VendorPopover';
import Table from 'bundles/Shared/components/Table/Table';
import { IColumn } from 'bundles/Shared/components/Table/types';
import pluralize from 'pluralize';
import { getRemarkFormatter } from '@/entities/accounting/transaction/ui/Formatters';
import { sumBy } from 'lodash-es';
import { SidePanel } from 'stories/Modals/Modal/Modal';

type Props = DialogProps &
  Pick<
    IDevelopmentLegalEntityRequisitionTransaction,
    'period' | 'note' | 'vendorEntity' | 'date'
  > & {
    linkedLineItems: Pick<ILineItem, 'id' | 'amount' | 'remark' | 'category'>[];
  };

export const TransactionLinkToLineItemModal = ({
  onClose,
  onSubmit,
  vendorEntity,
  linkedLineItems,
  period,
  date,
  note,
}: Props) => {
  const columns: IColumn<ILineItem>[] = useMemo(
    () => [
      {
        text: 'JCC/SOVC',
        dataField: 'development_category_id',
        formatter: ({ row }) => row.category?.name,
      },
      {
        text: 'Remark',
        dataField: 'description',
        formatter: getRemarkFormatter((item) => item.remark),
      },
      {
        text: 'Amount',
        dataField: 'amount',
        formatter: ({ row }) => formatAmount(row.amount),
        classes: 'text-right whitespace-nowrap',
        headerAlign: 'end',
      },
    ],
    [],
  );

  const total = sumBy(linkedLineItems, (i: ILineItem) => Number(i.amount));
  return (
    <div>
      <SidePanel toggle={onClose} header="Link Transaction" bodyPadding="0">
        <div className="flex h-full flex-col">
          <div className="px-6 py-4">
            <div className="mb-4 flex">
              <div className="w-[100px]">
                <small className="mb-1.5 text-light-60">TX DATE</small>
                <div className="light-90 inline-regular">
                  {formatDate(date, 'MMM DD, YYYY')}
                </div>
              </div>
              <div>
                <small className="mb-1 text-light-60">VENDOR</small>
                <div>
                  {vendorEntity ? (
                    <VendorPopover vendor={vendorEntity}>
                      <InlineObject
                        iconName="bag"
                        object={vendorEntity.name}
                        hideTooltipWhenOverflow
                      />
                    </VendorPopover>
                  ) : (
                    <> - </>
                  )}
                </div>
              </div>
            </div>
            <div className="mb-4 flex">
              <div className="w-[100px]">
                <small className="mb-1.5 text-light-60">PERIOD</small>
                <div className="light-90 inline-regular">
                  {formatDate(period, 'MMM-YYYY')}
                </div>
              </div>
              <div>
                <small className="mb-1.5 text-light-60">DESCRIPTION</small>
                <div className="light-90 inline-regular">{note}</div>
              </div>
            </div>
          </div>
          <div className="flex flex-1 flex-col bg-light px-6 py-4">
            <div className="mb-4">
              <StatisticLine
                statistics={[{ color: 'var(--green)', value: total }]}
              />
              <div className="mt-2 flex items-center justify-center">
                <Icon color="green" iconName="check" />
                <strong className="light-90 mx-2">{formatAmount(total)}</strong>
                <span className="light-70">is fully allocated</span>
              </div>
            </div>
            <div className="flex-1">
              <div className="mb-1.5">
                <h6 className="dark-60">
                  {linkedLineItems.length}{' '}
                  {pluralize('Line Item', linkedLineItems.length)}
                </h6>
              </div>
              <Table columns={columns} items={linkedLineItems} />
              <div className="pr-4 pt-2 text-right">
                <span className="light-60 mr-1 text-[14px]">Total:</span>
                <span className="dark-60 text-[14px]">
                  {formatAmount(total)}
                </span>
              </div>
            </div>
            <Button
              variant="success"
              className="mt-4 w-full"
              onClick={() => onSubmit(true)}
            >
              Link Transaction
            </Button>
          </div>
        </div>
      </SidePanel>
    </div>
  );
};

export default TransactionLinkToLineItemModal;
