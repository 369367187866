import { useReportingEntityKindContext } from '@/entities/reporting/context/entityKind';
import { usePublishReport } from '@/features/report/report/publishReport/lib';
import { useNavigateBack } from '@/shared/lib/hooks/navigation';
import { ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import { Button } from '@/stories/Button/Button';
import type { ComponentProps } from 'react';

export const PublishReportButton = (
  props: {
    reportId: string;
    assetId: number;
  } & Pick<
    ComponentProps<typeof Button>,
    'disabled' | 'loading' | 'tooltipProps'
  >,
) => {
  const reportKind = useReportingEntityKindContext();
  // TODO features/report/navigateToReportGroup
  const { navigateBack } = useNavigateBack({
    fallbackUrl:
      reportKind === 'eagle_eye'
        ? ROUTES_ROOT.reporting.fullPath
        : ROUTES_ROOT.assets.fullPath,
  });
  const [publish, options] = usePublishReport({
    onSuccess: navigateBack,
    ...props,
  });

  return (
    <Button
      loading={Boolean(props.loading) || options.isLoading}
      disabled={Boolean(props.disabled) || options.isLoading}
      variant="success"
      className="w-max"
      onClick={publish}
      tooltipProps={props.tooltipProps}
    >
      {reportKind === 'eagle_eye' ? 'Generate' : 'Publish'}
    </Button>
  );
};
