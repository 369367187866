import { emptySplitApi as api } from '@/app/stores/api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiAccountingTransactions: build.query<
      GetApiAccountingTransactionsApiResponse,
      GetApiAccountingTransactionsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/accounting/transactions`,
        params: {
          page: queryArg.page,
          per_page: queryArg.perPage,
          within_published_month: queryArg.withinPublishedMonth,
          general_ledger_ids: queryArg.generalLedgerIds,
          period: queryArg.period,
          transaction_type: queryArg.transactionType,
          sort: queryArg.sort,
        },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as accountingTransactionsApi };
export type GetApiAccountingTransactionsApiResponse =
  /** status 200 success */ {
    items: TransactionDto[];
    meta: PaginationDto;
  };
export type GetApiAccountingTransactionsApiArg = {
  page?: number;
  perPage?: number;
  /** Hides TXs outside published financial month. Enabled automatically for external users */
  withinPublishedMonth?: boolean;
  generalLedgerIds?: number[];
  /** Period object (see ReportScoreboardPeriod schema) */
  period?: {
    type?: string;
    [key: string]: any;
  };
  transactionType?: 'debit' | 'credit';
  sort?: {
    field:
      | 'date'
      | 'period'
      | 'value'
      | 'legal_entity_code'
      | 'vendor_entity_code'
      | 'vendor_entity_name';
    order: 'asc' | 'desc';
  };
};
export type TransactionDto = {
  id: string;
  creditCents: number;
  debitCents: number;
  date: string;
  period: string;
  description: string | null;
  generalLedger: {
    id: number;
    accNumber: string;
    accTitle: string;
    category: {
      pathCodes: string[];
      path: string;
      rootCategoryCode: string;
      normalBalance: 'credit' | 'debit';
      accountingType: 'balance' | 'income';
    } | null;
  };
  legalEntity: {
    id: string;
    code: string;
    name: string;
    legalEntitiable: {
      id: number;
      slug: string;
      name: string;
      aasmState: 'published' | 'draft' | 'archived';
      objectType: 'Asset' | 'Fund';
      pictureUrl: string | null;
    };
  };
  vendorEntity: {
    id: number;
    code: string;
    name: string;
  } | null;
  managedObject: {
    id: string;
    erpSystem: string;
    externalIdentifier: string;
    propertyManagementCompanyName: string;
  };
};
export type PaginationDto = {
  perPage: number;
  totalSize: number;
};
