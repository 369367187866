import React from 'react';
import {
  currentUserAllowedTo,
  FUND_PORTAL_PRODUCT_NAME,
} from 'lib/permissions';
import { IUser } from 'types/User';
import { LinkButton } from 'stories/LinkButton/LinkButton';
import {
  getAvailableObjectsCount,
  IUserTableColumnsActions,
} from '@/bundles/UserManagement/components/table/userTableColumns';
import { DEFAULT_NUMBER_FALLBACK } from '@/shared/lib/formatting/fallbacks';
import { UserAccessLevels } from '@/entities/impersonation/api';
interface Props {
  userAccessLevels: UserAccessLevels;
  user: IUser & { advisorIds: number[]; adviseeIds: number[] };
  actions: IUserTableColumnsActions;
}

function ViewCell({ userAccessLevels, user, actions }: Props) {
  const userAccessLevel = userAccessLevels[user.id];
  const canViewAssetsCount = userAccessLevel?.canViewAssetsCount;
  const canViewFundsCount = userAccessLevel?.canViewFundsCount;

  const availableAssetsCount = getAvailableObjectsCount(
    userAccessLevel.canViewAllAssets,
    canViewAssetsCount,
  );

  const availableFundsCount = getAvailableObjectsCount(
    userAccessLevel.canViewAllFunds,
    canViewFundsCount,
  );

  return (
    <>
      {user.role.name !== 'Advisor' && (
        <>
          <div>
            {availableAssetsCount !== false ? (
              <LinkButton
                className="line-height-21px"
                onClick={() => actions.edit(user, 'assets')}
              >
                {availableAssetsCount}
              </LinkButton>
            ) : (
              DEFAULT_NUMBER_FALLBACK
            )}
          </div>
          {currentUserAllowedTo('view', FUND_PORTAL_PRODUCT_NAME) && (
            <div>
              {availableFundsCount !== false ? (
                <LinkButton onClick={() => actions.edit(user, 'funds')}>
                  {availableFundsCount}
                </LinkButton>
              ) : (
                DEFAULT_NUMBER_FALLBACK
              )}
            </div>
          )}
        </>
      )}

      {user.role.name === 'Advisor' && (
        <div className="light-60">
          {user?.adviseeIds?.length > 0 ? (
            <LinkButton onClick={() => actions.edit(user, 'advisees')}>
              {user?.adviseeIds?.length}
            </LinkButton>
          ) : (
            DEFAULT_NUMBER_FALLBACK
          )}
        </div>
      )}

      {user?.advisorIds.length > 0 && (
        <div className="light-60">
          {user?.advisorIds?.length > 0 ? (
            <LinkButton onClick={() => actions.edit(user, 'advisors')}>
              {user?.advisorIds?.length}
            </LinkButton>
          ) : (
            DEFAULT_NUMBER_FALLBACK
          )}
        </div>
      )}
    </>
  );
}

export default ViewCell;
