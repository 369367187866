import type {
  AllWidgetTypes,
  ReportDashboardSectionPosition,
  ReportDashboardSectionPositionLayouts,
  ReportDashboardSectionPositionWithId,
} from 'bundles/Shared/entities/dashboard/model/types/types';

import { buildLayoutsId } from 'bundles/Shared/entities/dashboard/model/slices/shared';
import { EntityState } from '@reduxjs/toolkit';
import {
  dashboardLayoutChanged,
  updateSectionColor,
} from 'bundles/Shared/features/dashboard/sectionActions';
import { ActionReducerMapBuilder } from '@reduxjs/toolkit/src/mapBuilders';
import { NoInfer } from '@reduxjs/toolkit/src/tsHelpers';
import { TRootState } from '@/app/stores';
import {
  differenceWith,
  isEmpty,
  isEqual,
  omit,
  pick,
  sortBy,
} from 'lodash-es';

export type LayoutsState<T> = T & {
  layouts?: ReportDashboardSectionPositionLayouts;
  initialLayouts?: ReportDashboardSectionPositionLayouts;
};

export const addLayoutsReducersToSlice = <T>(
  builder: ActionReducerMapBuilder<NoInfer<EntityState<LayoutsState<T>>>>,
) => {
  builder.addCase(dashboardLayoutChanged, (state, action) => {
    const { dashboardId, boardId } = action.payload;
    const id = buildLayoutsId({
      dashboardId,
      boardId,
    });
    const board = state.entities[id];

    if (
      board == null ||
      board.layouts == null ||
      // don't set responsive layouts
      Object.keys(action.payload.allLayouts).length > 1 ||
      !Object.keys(action.payload.allLayouts).includes('lg')
    ) {
      return;
    }

    board.layouts.lg = action.payload.layout.map((item) => {
      const originalItem = board.layouts?.lg.find(
        (section) => section.i === item.i,
      );
      return {
        ...item,
        color: originalItem?.color ?? null,
        type: originalItem?.type ?? item.type,
      };
    });
  });
  builder.addCase(updateSectionColor, (state, action) => {
    const { dashboardId, boardId } = action.payload;
    const id = buildLayoutsId({
      dashboardId,
      boardId,
    });
    const board = state.entities[id];
    if (board == null || board.layouts == null) {
      return;
    }

    const { sectionId, color } = action.payload;
    const section = board.layouts.lg.find((item) => item.i === sectionId);
    if (section != null) {
      section.color = color;
    }
  });
};

export const buildLayoutItem = <
  T extends {
    id: string | number;
    position: ReportDashboardSectionPosition;
    color?: string | null;
    widgetType: AllWidgetTypes;
  },
>(
  item: T,
): ReportDashboardSectionPositionWithId => ({
  ...omit(item.position, ['maxH']),
  color: item.color ?? null,
  i: item.id.toString(),
  type: item.widgetType,
});

export const buildLayout = <
  T extends {
    id: string | number;
    position: ReportDashboardSectionPosition;
    widgetType: AllWidgetTypes;
    color?: string | null;
  },
>(
  section: T[],
): ReportDashboardSectionPositionLayouts => {
  return {
    lg: section.map(buildLayoutItem),
  };
};

export const selectReportDashboardLayoutDirty =
  (
    {
      dashboardId,
      boardId,
    }: {
      dashboardId: string;
      boardId: string;
    },
    selector: (
      state: TRootState,
      dashboardId: string,
    ) => LayoutsState<unknown> | undefined,
    { withoutColor } = {
      withoutColor: false,
    },
  ) =>
  (state: TRootState) => {
    const dashboard = selector(
      state,
      buildLayoutsId({
        dashboardId,
        boardId,
      }),
    );
    const { layouts, initialLayouts } = dashboard ?? {};

    if (layouts == null || initialLayouts == null) {
      return false;
    }
    const diffLayout = differenceWith(
      sortBy(layouts.lg, 'i'),
      sortBy(initialLayouts.lg, 'i'),
      (a, b) => {
        const pickProperties = (item: ReportDashboardSectionPosition) => {
          const properties: (keyof ReportDashboardSectionPosition | 'i')[] = [
            'i',
            'x',
            'y',
            'w',
            'h',
          ];
          if (!withoutColor) {
            properties.push('color');
          }
          return pick(item, properties);
        };
        return isEqual(pickProperties(a), pickProperties(b));
      },
    );

    return isEmpty(diffLayout);
  };
