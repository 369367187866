/* eslint-disable no-param-reassign */
import { getComponentSelector } from '@/stories/TreeView/TreeView';
import styles from '@/stories/TreeView/TreeView.module.scss';
import type {
  DatumNode,
  IGetBBox,
  Params,
  Selection,
} from '@/stories/TreeView/types';
import { isEmpty } from 'lodash-es';
import * as d3 from 'd3';
import { NODE_BACKGROUND_COMPONENT } from 'stories/TreeView/NodeBackground';

export const NODE_INFO_COMPONENT = 'NodeInfo';

const PADDING_LEFT = 5;
const PADDING_RIGHT = 6;
const ICON_WIDTH = 14;

const [ICON_X, ICON_Y] = [4, -8];
const [RECT_BORDER_RADIUS, RECT_Y] = [6, -10];
const GROUP_Y = -10;

function expandIconAttrs(iconSelection: Selection<SVGSVGElement>) {
  iconSelection.attr('x', ICON_X).attr('y', ICON_Y);
}

function infoRectAttrs(rectSelection: Selection<SVGRectElement>) {
  const resolveWidth = (d: d3.HierarchyNode<DatumNode>) =>
    PADDING_LEFT + ICON_WIDTH + PADDING_RIGHT;

  rectSelection
    .attr('class', styles.nodeLinksRect)
    .attr('y', RECT_Y)
    .attr('rx', RECT_BORDER_RADIUS)
    .attr('width', resolveWidth);
}

export function NodeInfo(
  selection: Selection<SVGGElement>,
  lifecycle: 'create' | 'update',
  params: Params,
) {
  function appendComponents(internalSelection: Selection<SVGGElement>) {
    const group = internalSelection
      .select(getComponentSelector(NODE_BACKGROUND_COMPONENT))
      .append('g')
      .attr('data-component', NODE_INFO_COMPONENT)
      .attr('y', GROUP_Y)
      .attr('class', styles.nodeInfoGroup);

    group.append('rect').lower();

    group.nodes().forEach((n) => {
      n?.append(params.leftIcon.documentElement.cloneNode(true));
    });
  }
  if (lifecycle === 'create') {
    appendComponents(selection);
  }
  if (lifecycle === 'update') {
    selection
      .filter(function (d) {
        return (
          !isEmpty(d.data?.classifications) &&
          d3
            .select(this)
            .select(getComponentSelector(NODE_INFO_COMPONENT))
            .empty()
        );
      })
      .call(appendComponents);

    selection
      .filter((d) => {
        if (d.nodeInfoBox) {
          d.nodeInfoBox.width = 0;
        }

        return isEmpty(d.data?.classifications);
      })
      .select(getComponentSelector(NODE_INFO_COMPONENT))
      .remove();
  }

  selection
    .select(getComponentSelector(NODE_INFO_COMPONENT))
    .attr(
      'transform',
      (d) =>
        `translate(${
          (d.textBox?.width ?? 0) +
          (d?.nodeLinksBox?.width ?? 0) +
          PADDING_RIGHT
        }, 0)`,
    );

  selection
    .select(getComponentSelector(NODE_INFO_COMPONENT))
    .select('svg')
    .call(expandIconAttrs);

  selection
    .select(getComponentSelector(NODE_INFO_COMPONENT))
    .select('rect')
    .call(infoRectAttrs);

  selection
    .select(getComponentSelector(NODE_INFO_COMPONENT))
    .each(function (d) {
      const bound: IGetBBox = this.getBBox();
      d.nodeInfoBox = {
        height: bound.height,
        width: bound.width + PADDING_RIGHT,
      };
    });
}
