/* eslint-disable max-len */
import {
  DeleteApiSettingsReportThirdPartyExtractionsConnectionsByConnectionIdAttachmentsAndIdApiResponse,
  DeleteApiSettingsReportThirdPartyExtractionsConnectionsByConnectionIdEmailExtractionsAndIdApiResponse,
  DeleteApiSettingsReportThirdPartyExtractionsConnectionsByConnectionIdManualUploadsAndIdApiResponse,
  DeleteApiSettingsReportThirdPartyExtractionsConnectionsByConnectionIdSftpExtractionsAndIdApiResponse,
  DeleteApiSettingsReportThirdPartyExtractionsConnectionsByIdApiResponse,
  PostApiSettingsReportThirdPartyExtractionsConnectionsApiResponse,
  PostApiSettingsReportThirdPartyExtractionsConnectionsByConnectionIdManualUploadsApiResponse,
  PutApiSettingsReportThirdPartyExtractionsConnectionsByIdApiResponse,
  settingsReportConnectionsGeneratedApi,
} from '@/shared/api/settingsReportConnectionsGeneratedApi';

enum TAGS {
  CONNECTIONS = 'Connections',
  CONNECTION = 'Connection',
  EMAIL_EXTRACTIONS = 'EmailExtractions',
  SFTP_EXTRACTIONS = 'SftpExtractions',
  MANUAL_EXTRACTIONS = 'ManualExtractions',
}

export const settingsReportConnectionsEnhancedApi =
  settingsReportConnectionsGeneratedApi.enhanceEndpoints({
    addTagTypes: [
      TAGS.CONNECTIONS,
      TAGS.CONNECTION,
      TAGS.EMAIL_EXTRACTIONS,
      TAGS.SFTP_EXTRACTIONS,
      TAGS.MANUAL_EXTRACTIONS,
    ],
    endpoints: {
      getApiSettingsReportThirdPartyExtractionsConnections: {
        providesTags: [TAGS.CONNECTIONS],
      },
      getApiSettingsReportThirdPartyExtractionsConnectionsById: {
        providesTags: [TAGS.CONNECTION],
      },
      getApiSettingsReportThirdPartyExtractionsConnectionsByConnectionIdEmailExtractions:
        {
          providesTags: [TAGS.EMAIL_EXTRACTIONS],
        },
      getApiSettingsReportThirdPartyExtractionsConnectionsByConnectionIdSftpExtractions:
        {
          providesTags: [TAGS.SFTP_EXTRACTIONS],
        },
      getApiSettingsReportThirdPartyExtractionsConnectionsByConnectionIdManualUploads:
        {
          providesTags: [TAGS.MANUAL_EXTRACTIONS],
        },
      postApiSettingsReportThirdPartyExtractionsConnections: {
        invalidatesTags: [TAGS.CONNECTIONS],
        transformResponse: (
          response: PostApiSettingsReportThirdPartyExtractionsConnectionsApiResponse,
        ) => {
          toastr.success('Connection Created');
          return response;
        },
      },
      putApiSettingsReportThirdPartyExtractionsConnectionsById: {
        invalidatesTags: [TAGS.CONNECTIONS, TAGS.CONNECTION],
        transformResponse: (
          response: PutApiSettingsReportThirdPartyExtractionsConnectionsByIdApiResponse,
        ) => {
          toastr.success('Connection Updated');
          return response;
        },
      },
      postApiSettingsReportThirdPartyExtractionsConnectionsByConnectionIdAttachmentsParse:
        {
          invalidatesTags: [
            TAGS.MANUAL_EXTRACTIONS,
            TAGS.EMAIL_EXTRACTIONS,
            TAGS.SFTP_EXTRACTIONS,
          ],
          transformResponse: (
            response: PutApiSettingsReportThirdPartyExtractionsConnectionsByIdApiResponse,
          ) => {
            toastr.success('Parse extractions has been started');
            return response;
          },
        },

      postApiSettingsReportThirdPartyExtractionsConnectionsByConnectionIdManualUploads:
        {
          invalidatesTags: [TAGS.MANUAL_EXTRACTIONS],
          transformResponse: (
            response: PostApiSettingsReportThirdPartyExtractionsConnectionsByConnectionIdManualUploadsApiResponse,
          ) => {
            toastr.success('Uploaded Extractions');
            return response;
          },
        },

      deleteApiSettingsReportThirdPartyExtractionsConnectionsByConnectionIdAttachmentsAndId:
        {
          invalidatesTags: [
            TAGS.CONNECTIONS,
            TAGS.CONNECTION,
            TAGS.MANUAL_EXTRACTIONS,
            TAGS.EMAIL_EXTRACTIONS,
            TAGS.SFTP_EXTRACTIONS,
          ],
          transformResponse: (
            response: DeleteApiSettingsReportThirdPartyExtractionsConnectionsByConnectionIdAttachmentsAndIdApiResponse,
          ) => {
            toastr.success('Extraction(s) and data has been deleted.');
            return response;
          },
        },
      deleteApiSettingsReportThirdPartyExtractionsConnectionsByConnectionIdEmailExtractionsAndId:
        {
          invalidatesTags: [
            TAGS.CONNECTIONS,
            TAGS.CONNECTION,
            TAGS.EMAIL_EXTRACTIONS,
          ],
          transformResponse: (
            response: DeleteApiSettingsReportThirdPartyExtractionsConnectionsByConnectionIdEmailExtractionsAndIdApiResponse,
          ) => {
            toastr.success('Extraction(s) and data has been deleted.');
            return response;
          },
        },
      deleteApiSettingsReportThirdPartyExtractionsConnectionsByConnectionIdManualUploadsAndId:
        {
          invalidatesTags: [
            TAGS.CONNECTIONS,
            TAGS.CONNECTION,
            TAGS.MANUAL_EXTRACTIONS,
          ],
          transformResponse: (
            response: DeleteApiSettingsReportThirdPartyExtractionsConnectionsByConnectionIdManualUploadsAndIdApiResponse,
          ) => {
            toastr.success('Extraction(s) and data has been deleted.');
            return response;
          },
        },
      deleteApiSettingsReportThirdPartyExtractionsConnectionsByConnectionIdSftpExtractionsAndId:
        {
          invalidatesTags: [
            TAGS.CONNECTIONS,
            TAGS.CONNECTION,
            TAGS.SFTP_EXTRACTIONS,
          ],
          transformResponse: (
            response: DeleteApiSettingsReportThirdPartyExtractionsConnectionsByConnectionIdSftpExtractionsAndIdApiResponse,
          ) => {
            toastr.success('Extraction(s) and data has been deleted.');
            return response;
          },
        },
      deleteApiSettingsReportThirdPartyExtractionsConnectionsById: {
        invalidatesTags: [
          TAGS.CONNECTIONS,
          TAGS.CONNECTION,
          TAGS.SFTP_EXTRACTIONS,
          TAGS.MANUAL_EXTRACTIONS,
          TAGS.EMAIL_EXTRACTIONS,
        ],
        transformResponse: (
          response: DeleteApiSettingsReportThirdPartyExtractionsConnectionsByIdApiResponse,
        ) => {
          toastr.success('Extraction(s) and data has been deleted.');
          return response;
        },
      },
    },
  });

export const {
  // Extraction attachment
  usePostApiSettingsReportThirdPartyExtractionsConnectionsByConnectionIdAttachmentsParseMutation,
  useDeleteApiSettingsReportThirdPartyExtractionsConnectionsByConnectionIdAttachmentsAndIdMutation,

  // Connection
  useGetApiSettingsReportThirdPartyExtractionsConnectionsQuery,
  usePostApiSettingsReportThirdPartyExtractionsConnectionsMutation,
  useGetApiSettingsReportThirdPartyExtractionsConnectionsByIdQuery,
  usePutApiSettingsReportThirdPartyExtractionsConnectionsByIdMutation,
  useDeleteApiSettingsReportThirdPartyExtractionsConnectionsByIdMutation,

  // Email extraction
  useGetApiSettingsReportThirdPartyExtractionsConnectionsByConnectionIdEmailExtractionsQuery,
  useDeleteApiSettingsReportThirdPartyExtractionsConnectionsByConnectionIdEmailExtractionsAndIdMutation,

  // Manual extraction
  useGetApiSettingsReportThirdPartyExtractionsConnectionsByConnectionIdManualUploadsQuery,
  usePostApiSettingsReportThirdPartyExtractionsConnectionsByConnectionIdManualUploadsMutation,
  useDeleteApiSettingsReportThirdPartyExtractionsConnectionsByConnectionIdManualUploadsAndIdMutation,

  // SFTP extraction
  useGetApiSettingsReportThirdPartyExtractionsConnectionsByConnectionIdSftpExtractionsQuery,
  useDeleteApiSettingsReportThirdPartyExtractionsConnectionsByConnectionIdSftpExtractionsAndIdMutation,
} = settingsReportConnectionsEnhancedApi;
