import React from 'react';
import { Card } from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/workflow/checkboxes/cards/Card';
import { isEmpty } from 'lodash-es';
import { CssVar } from '@/shared/config/cssVar';
import { WaitingLabel } from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/workflow/checkboxes/waitingLabel/WaitingLabel';
import { IWorkflowConfig } from 'bundles/REconcile/actions/workflowConfig';
import { useAppSelector } from '@/shared/lib/hooks/redux';
import { Badge } from 'stories/Badges/Badge/Badge';
import { Tooltip } from 'stories/Tooltip/Tooltip';
import { Tumbler } from 'stories/Tumbler/Tumbler';
import { TOOLTIP_TEXT } from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/workflow/checkboxes/constants';

interface Props {
  valueKey: keyof Pick<
    IWorkflowConfig,
    | 'contingencyThresholdEnabled'
    | 'timingImpactThresholdEnabled'
    | 'individualCorAmountThresholdEnabled'
  >;
  label: React.ReactNode;
  waitingApprovalKey: keyof IWorkflowConfig['corsAwaitingApproval'];
  onChange: React.ComponentProps<typeof Tumbler>['onChange'];
}

function ThresholdTypeCard({
  label,
  waitingApprovalKey,
  valueKey,
  onChange,
}: Props) {
  const legalEntity = useAppSelector(
    (state) => state.developmentLegalEntity.data,
  );
  const workflowSettings = useAppSelector(
    (state) => state.developmentLegalEntity.workflow,
  );

  const corsAwaitingApprovalValue =
    workflowSettings?.corsAwaitingApproval?.[waitingApprovalKey];
  const disabled =
    !workflowSettings?.corApprovalRequired ||
    (corsAwaitingApprovalValue != null && corsAwaitingApprovalValue.length > 0);
  return (
    <div className="flex flex-col gap-s">
      <Card
        label={
          <label className="inline-semibold text-dark-60" htmlFor={valueKey}>
            {label}
          </label>
        }
      >
        <Tooltip disabled={!disabled} mainText={TOOLTIP_TEXT}>
          <Tumbler
            id={valueKey}
            disabled={disabled}
            checked={workflowSettings?.[valueKey]}
            onChange={onChange}
          />
        </Tooltip>
        {!isEmpty(corsAwaitingApprovalValue) && (
          <Badge
            classes={{
              value: 'mnw-25p text-center',
            }}
            backgroundColor={CssVar.black}
          >
            {corsAwaitingApprovalValue!.length}
          </Badge>
        )}
      </Card>
      {!isEmpty(corsAwaitingApprovalValue) && (
        <WaitingLabel
          legalEntityCode={legalEntity!.code}
          items={corsAwaitingApprovalValue!}
        />
      )}
    </div>
  );
}

export default ThresholdTypeCard;
