import { cn } from '@/shared/lib/css/cn';
import { Field } from '@/stories/Field/Field';
import { Icon } from '@/stories/Icon/Icon';
import { Tooltip } from '@/stories/Tooltip/Tooltip';
import React, { type ComponentProps } from 'react';

export const SectionField = ({
  children,
  className,
  note,
  required,
  labelText,
  button,
  classes,
  tooltipProps = {
    disabled: true,
  },
  ...props
}: React.PropsWithChildren<
  PropsWithClassName &
    Pick<React.ComponentProps<typeof Field>, 'note' | 'required' | 'labelText'>
> & {
  classes?: {
    labelContainer?: string;
    itemsContainer?: string;
  };
  button?: React.ReactNode;
  tooltipProps?: ComponentProps<typeof Tooltip>;
}) => (
  <Field.Container
    flexVersion
    className={cn(
      'flex flex-col gap-3 rounded-[8px] bg-neutral-000 p-4 shadow-[0px_2px_2px_0px_rgba(12,25,54,0.08)]',
      className,
    )}
    {...props}
  >
    <Field.LabelContainer>
      <Field.Label
        className={cn('inline-semibold self-center ', classes?.labelContainer)}
        required={required}
      >
        {labelText}
      </Field.Label>
      {!tooltipProps.disabled && (
        <Tooltip {...tooltipProps}>
          <Icon iconName="info" />
        </Tooltip>
      )}
      <div className="grow" />
      {button}
    </Field.LabelContainer>
    {note && <Field.Note>{note}</Field.Note>}
    {children != null &&
      React.Children.toArray(children).some((c) => React.isValidElement(c)) && (
        <Field.ItemsContainer
          className={cn(
            'flex flex-col gap-2',
            classes?.itemsContainer,
            note != null && 'mt-1.5',
          )}
        >
          {children}
        </Field.ItemsContainer>
      )}
  </Field.Container>
);

export const SectionFieldsContainer = ({
  children,
  className,
}: React.PropsWithChildren<PropsWithClassName>) => (
  /* eslint-disable-next-line */
  <div
    className={cn(
      'group flex flex-col gap overflow-hidden rounded-lg bg-transparent [&>*]:!rounded-none',
      className,
    )}
  >
    {children}
  </div>
);
