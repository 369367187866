import { emptySplitApi as api } from '@/app/stores/api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiSettingsCoreFiltersUsers: build.query<
      GetApiSettingsCoreFiltersUsersApiResponse,
      GetApiSettingsCoreFiltersUsersApiArg
    >({
      query: () => ({ url: '/api/settings/core/filters/users' }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as coreFiltersUsersSettingsApi };
export type GetApiSettingsCoreFiltersUsersApiResponse =
  /** status 200 success */ CoreFiltersUsers[];
export type GetApiSettingsCoreFiltersUsersApiArg = void;
export type CoreFiltersUsers = {
  id: string;
  email: string;
  fullName: string;
  role: {
    id: string;
    name: string;
  };
};

export const { useGetApiSettingsCoreFiltersUsersQuery } = injectedRtkApi;
