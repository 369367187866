import React from 'react';
import { Modal } from 'stories/Modals/Modal/Modal';
import { PeriodShiftField } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/fields/PeriodShiftField';
import { PeriodTypeField } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/fields/PeriodTypeField';
import {
  useUpdateWidgetConfig,
  useWidgetConfig,
} from 'bundles/Shared/widgets/dashboard/widgets/common/lib/config';
import { Field, FieldsContainer } from 'stories/Field/Field';
import { Button } from '@/stories/Button/Button';
import {
  SectionField,
  SectionFieldsContainer,
} from 'stories/Field/FieldsWrappers';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { EAGLE_EYE_DASHBOARD_SECTION_TYPE } from 'bundles/Shared/entities/dashboard/model/types/types';
import {
  KPI_TABLE_BULK_GROUP_SCHEMA,
  KpiTableBulkGroupForm,
  transformConfigToGroupForm,
} from 'bundles/Shared/widgets/dashboard/widgets/kpiTableSingleDate/config/bulk-group.form';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import { bulkUpdateGroup } from 'bundles/Shared/widgets/dashboard/widgets/kpiTableSingleDate/config/updaters';
import { TumblerController } from 'stories/Tumbler/Tumbler';
import { ColumnHeaderField } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/fields/ColumnHeaderField';

type Props = DialogProps & {
  groupId: string;
  widgetType:
    | typeof EAGLE_EYE_DASHBOARD_SECTION_TYPE.KPI_TABLE_SINGLE_DATE
    | typeof EAGLE_EYE_DASHBOARD_SECTION_TYPE.GLOBAL_LEASE_TABLE;
};

export function BulkGroupConfigModal({ onClose, groupId, widgetType }: Props) {
  const { widget } = useWidgetConfig<typeof widgetType>();
  const group = widget.widgetConfig.viz_config.column_groups.find(
    (cg) => cg.group_id === groupId,
  )!;

  const resolver = yupResolver(KPI_TABLE_BULK_GROUP_SCHEMA);
  const methods = useForm<KpiTableBulkGroupForm>({
    resolver,
    mode: 'all',
    defaultValues: {
      ...transformConfigToGroupForm({
        group,
        widgetConfig: widget.widgetConfig,
      }),
    },
  });
  const {
    control,
    formState: { isValid, isDirty, isSubmitting },
    handleSubmit,
  } = methods;

  const [updateConfig] = useUpdateWidgetConfig(widgetType);

  const onSubmit = handleSubmit((data) => {
    updateConfig({
      config: bulkUpdateGroup(groupId, data, widget.widgetConfig),
    });
    onClose();
  });

  return (
    <FormProvider {...methods}>
      <Modal
        header="Group Configuration"
        toggle={onClose}
        actions={
          <>
            <Button onClick={onClose} variant="secondary">
              Cancel
            </Button>
            <Button
              disabled={!isValid || !isDirty || isSubmitting}
              onClick={onSubmit}
              variant="success"
            >
              Save Updates
            </Button>
          </>
        }
      >
        <FieldsContainer>
          <SectionFieldsContainer>
            <SectionField labelText="Specify Period">
              <PeriodTypeField control={control} name="period_type" />
              <Field labelText="Period shift">
                <PeriodShiftField control={control} name="period_shift" />
              </Field>
            </SectionField>
            <ColumnHeaderField name="header" control={control} />
            <SectionField
              button={
                <TumblerController name="can_override" control={control} />
              }
              labelText="Lock Period Settings for Columns"
            />
          </SectionFieldsContainer>
        </FieldsContainer>
      </Modal>
    </FormProvider>
  );
}
