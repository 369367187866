export const CHANGE_ORDER_EVENT_STATUSES = [
  {
    value: 'PENDING-PROCEEDING',
    description:
      'Change is being priced/negotiated; work in question is proceeding',
  },
  {
    value: 'PENDING-NOT PROCEEDING',
    description: (
      <p>
        Change is being priced/negotiated; work in question is <u>on hold</u>{' '}
        until approved
      </p>
    ),
  },
  {
    value: 'DRAFT',
    description: (
      <p>
        Change is internally forecasted or not complete yet; will <u>not</u>{' '}
        appear in external reports
      </p>
    ),
  },
  {
    value: 'VOID',
    description: 'Withdrawn by contractor or otherwise no longer applicable',
  },
  {
    value: 'REJECTED',
    description:
      'Change has been rejected by Subtext and the Contractor has accepted our position on the issue and removed from their list of outstanding Change Requests',
  },
  {
    value: 'DISPUTED',
    description:
      'Change has been rejected by Subtext, but Contractor does not accept our position and continues to show as outstanding',
  },
  {
    value: 'APPROVED',
    description:
      'Subtext has approved the Change and it should be included in the next Change Order to be executed',
  },
];

export const historyStatusTypes = (data: unknown) => {
  const status = data?.additionalDetails?.find(
    (elem) => elem.attribute === 'status',
  );
  return {
    create: { label: 'created', icon: 'add', color: 'blue' },
    update: { label: 'edited', icon: 'edit', color: 'warning' },
    destroy: { label: 'deleted', icon: 'trash', color: 'warning' },
    promote: { label: 'promoted to', icon: 'upgrade', color: 'blue' },
    rollback: {
      label: 'Rolled back to Unverified',
      icon: 'reset',
      color: 'warning',
    },
    verification_pending: {
      label: 'saved a pending verification',
      icon: 'edit',
      color: 'danger',
    },
    verify: { label: 'verified', icon: 'checkDouble', color: 'success' },
    esign_placements_updated: {
      label: 'esign placements updated',
      icon: 'edit',
      color: 'warning',
    },
    internal_approval_granted: {
      label: 'internal approval granted',
      icon: 'check',
      color: 'success',
    },
    external_approval_granted: {
      label: 'external approval granted',
      icon: 'check',
      color: 'success',
    },
    symmetre_approval_granted: {
      label: 'symmetre approval granted',
      icon: 'check',
      color: 'success',
    },
    revise: { label: 'revised', icon: 'sync', className: 'bg-pumpkin-light-3' },
    approve: { label: 'approved', icon: 'acceptAlt', color: 'success' },
    start_approval: {
      label: 'started approval workflow',
      icon: 'bell',
      color: 'red',
    },
    reject: { label: 'rejected', icon: 'close', color: 'danger' },
    rejected: { label: 'rejected', icon: 'close', color: 'danger' },
    status_changed: {
      label: 'changed status of',
      icon: 'changing',
      color: 'light-60',
      content:
        status && `from ${status?.value.old} to ${status?.value.current}`,
    },
    status_change: {
      label: 'changed status of',
      icon: 'changing',
      color: 'light-60',
      content:
        status && `from ${status?.value.old} to ${status?.value.current}`,
    },
    included_in_reallocation: {
      label: 'added',
      icon: 'link',
      color: 'primary',
      content: 'to reallocation',
    },
    reallocation_approved: {
      label: 'completed',
      icon: 'flag',
      color: 'success',
      content: 'by approving linked reallocation',
    },
    default: { label: 'approved', icon: 'arrowTop', color: 'success' },
    generate: { label: 'generated', icon: 'arrowTop', color: 'primary' },
    uploaded: {
      label: 'attached documents to',
      icon: 'link',
      color: 'primary',
    },
    removed_document: {
      label: 'removed documents from',
      icon: 'trash',
      color: 'danger',
    },
    store_verification: {
      label: 'verified store',
      icon: 'checkDouble',
      color: 'green',
    },
    approved_email: {
      label: 'approval email sent',
      icon: 'email',
      color: 'red',
    },
    requires_external_approval_email: {
      label: 'requires external approval email sent',
      icon: 'email',
      color: 'red',
    },
    requires_approval_email: {
      label: 'requires approval email sent',
      icon: 'email',
      color: 'red',
    },
    cost_breakdown_confirmed: {
      label: 'confirmed cost breakdown',
      icon: 'check',
      color: 'green',
    },
    cost_breakdown_edited: {
      label: 'modified cost breakdown',
      icon: 'edit',
      color: 'light-60',
    },
    substantial_completion_date_updated: {
      label: 'modified substantial completion date',
      icon: 'edit',
      color: 'light-60',
    },
    included_events_updated: {
      label: 'modified included change events',
      icon: 'edit',
      color: 'light-60',
    },
    included_in_co: {
      label: '',
      icon: 'add',
      color: 'success',
      content: 'included in CO',
    },
    change_order_approved: {
      label: 'Completed',
      icon: 'flag',
      color: 'success',
    },
    supporting_document_set: {
      label: 'Set supporting document in',
      icon: 'link',
      color: 'success',
    },
  };
};

export const budgetTypesMissingWarning = `Configuration of Main Budget Category Types required in order
  to proceed. Please define specific Job Cost Code as 'Hard Cost Construction Cost Code',
  'Hard Cost Contingency Code' and 'Soft Cost Contingency Code'`;

export const snapshotSettingsMissingWarning = (
  <div>
    Some of the crucial settings are missing. Please check the following:
    <br />
    <br />
    {
      "1. 'Hard Construction Cost' and 'Hard Cost Contingency' budget values are specified."
    }
    <br />
    {`2. Specific Job Cost Codes are defined as 'Hard Cost Construction Cost Code',
          'Hard Cost Contingency Code' and 'Soft Cost Contingency Code'.`}
    <br />
    <br />
  </div>
);

export const corNumberRegEx = /(^\d{1,4})-?([a-zA-Z]{1})?$/i;

export enum MODULE_LABELS {
  Return = 'REturn',
  Reporting = 'REporting Module',
}
