import {
  useGetApiSettingsReportRentRollNonRevenueConfigQuery,
  usePutApiSettingsReportRentRollNonRevenueConfigMutation,
} from '@/shared/api/settingsReportNonRevenueConfigGeneratedApiEnhancedApi';
import { Button } from '@/stories/Button/Button';
import { CheckListController } from '@/stories/Checkbox/CheckList';
import {
  SectionField,
  SectionFieldsContainer,
} from '@/stories/Field/FieldsWrappers';
import SkeletonBlock from '@/stories/ProjectCard/SkeletonBlock';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  transformFormToNonRevenueConfigDto,
  transformNonRevenueConfigDtoToForm,
  transofrmNonRevenueConfigDtoToEmptyForm,
} from '@/pages/settings/report/operational/nonRevenueUnits/lib';
import {
  ERP_NON_REVENUE_STATUSES_TEXT_MAP,
  NON_REVENUE_UNITS_INFO_TEXT,
} from '@/pages/settings/report/operational/nonRevenueUnits/config';
import { ERPSystem } from '@/entities/erpsystem';

export const SettingsReportOperationalNonRevenueUnitsPage = () => {
  const { data, isLoading, isUninitialized } =
    useGetApiSettingsReportRentRollNonRevenueConfigQuery();
  const [updateNonRevenueConfig, { isLoading: isUpdating }] =
    usePutApiSettingsReportRentRollNonRevenueConfigMutation();
  const methods = useForm({
    mode: 'all',
    defaultValues: {
      ...(data && transformNonRevenueConfigDtoToForm(data)),
    },
  });

  const {
    control,
    formState: { isDirty },
  } = methods;

  useEffect(() => {
    if (data != null) {
      methods.reset(transformNonRevenueConfigDtoToForm(data));
    }
  }, [data, isUninitialized]);

  const handleReset = async () => {
    const emptyForm = transofrmNonRevenueConfigDtoToEmptyForm(data!);
    const res = await updateNonRevenueConfig({
      body: {
        sections: transformFormToNonRevenueConfigDto(data!, emptyForm),
      },
    });

    if ('error' in res) {
      return;
    }

    methods.reset(emptyForm);
  };

  const handleSave = methods.handleSubmit(async (values) => {
    const res = await updateNonRevenueConfig({
      body: {
        sections: transformFormToNonRevenueConfigDto(data!, values),
      },
    });

    if ('error' in res) {
      return;
    }

    methods.reset(values);
  });

  return (
    <div className="mx-auto flex w-[800px] flex-col gap-4 p-6">
      <p className="inline-regular text-neutral-850">
        {NON_REVENUE_UNITS_INFO_TEXT}
      </p>
      <div className="flex gap-2">
        <Button
          disabled={!isDirty || isLoading || isUpdating}
          variant="success"
          size="s"
          onClick={handleSave}
        >
          Save
        </Button>
        <Button variant="secondary" size="s" onClick={handleReset}>
          Reset
        </Button>
      </div>
      <FormProvider {...methods}>
        <SectionFieldsContainer>
          {isLoading && (
            <>
              {[...Array(5)].map((_, index) => (
                <SkeletonBlock key={index} className="h-[100px] w-full" />
              ))}
            </>
          )}
          {data?.sections.map((section) => (
            <SectionField
              key={section.erpSystem}
              labelText={section.label}
              note={
                section.erpSystem in ERP_NON_REVENUE_STATUSES_TEXT_MAP
                  ? ERP_NON_REVENUE_STATUSES_TEXT_MAP[
                      section.erpSystem as ERPSystem
                    ]
                  : undefined
              }
            >
              <CheckListController
                selectAll={false}
                name={section.erpSystem}
                items={section.items.map((item) => ({
                  label: item.label,
                  value: item.key,
                }))}
                control={control}
              />
            </SectionField>
          ))}
        </SectionFieldsContainer>
      </FormProvider>
    </div>
  );
};
