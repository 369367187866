import { useReportBuilderTemplateContext } from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/reportBuilderTemplateContext';
import {
  usePostApiSettingsReportBuilderEagleEyeTemplatesByEagleEyeTemplateIdGroupsMutation,
  usePostApiSettingsReportBuilderTemplatesByTemplateIdGroupsMutation,
} from '@/entities/report/reportBuilder/api/settingsReportBuilderTemplatesEnhancedApi';
import { useReportingEntityKindContext } from '@/entities/reporting/context/entityKind';

export const useCreateReportBuilderTemplateGroup = () => {
  const [createTemplateGroupMutation, createOptions] =
    usePostApiSettingsReportBuilderTemplatesByTemplateIdGroupsMutation();
  const [createEagleEyeTemplateGroupMutation, createOptionsEagleEye] =
    usePostApiSettingsReportBuilderEagleEyeTemplatesByEagleEyeTemplateIdGroupsMutation();
  const templateCtx = useReportBuilderTemplateContext()!;
  const templateKind = useReportingEntityKindContext();

  const handleCreateTemplateGroup = () => {
    switch (templateKind) {
      case 'eagle_eye':
        return createEagleEyeTemplateGroupMutation({
          eagleEyeTemplateId: templateCtx.templateId,
          body: {
            page_orientation: 'portrait',
          },
        });
      default:
        return createTemplateGroupMutation({
          templateId: templateCtx.templateId,
          body: {
            page_orientation: 'portrait',
          },
        });
    }
  };
  const options =
    templateKind === 'eagle_eye' ? createOptionsEagleEye : createOptions;

  return [handleCreateTemplateGroup, options] as const;
};
