import { DistributionKind } from 'bundles/REturn/actions/types';
import {
  DISTRIBUTION_LABEL_MAPPINGS,
  SelectedInvestmentEntity,
} from 'bundles/REturn/components/Ownership/modals/addDistributionsModal/AddDistributionsModal';
import { StepData } from 'bundles/REturn/components/Ownership/modals/addDistributionsModal/EnterDistributionsStep';
import { StatusIndicator } from 'bundles/REturn/components/Ownership/modals/addDistributionsModal/StatusIndicator';
import { AutofillWrapper } from 'bundles/REturn/components/Ownership/modals/addDistributionsModal/ui/AutofillWrapper';
import { ReturnRawTableEditor } from 'bundles/REturn/components/Ownership/modals/capitalInvestmentsEditor/ReturnRawTableEditor';
import { getSuggestedValue } from 'bundles/REturn/components/Ownership/modals/capitalInvestmentsEditor/utils';
import CurrencyInputNumber from 'bundles/Shared/components/GroupForm/FormItems/CurrencyInputNumber';
import {
  IColumn,
  TableBottomColumn,
} from 'bundles/Shared/components/Table/types';
import { formatDate } from '@/shared/lib/formatting/dates';
import {
  numDiff,
  parseIntOrFloat,
  parseNumberFromCurrencyInput,
} from '@/shared/lib/formatting/number';
import { capitalize, startCase, sumBy } from 'lodash-es';
import React, { ComponentProps, useEffect, useMemo, useState } from 'react';
import { Button } from 'stories/Button/Button';
import { CurrencyFormatter } from 'stories/ValueFormatters/CurrencyFormatter';
import { IconButton } from 'stories/IconButton/IconButton';
import { formatDateByPeriodType } from '@/entities/return/capital/lib';
import { Input } from '@/stories/FormControls/Inputs/Input/Input';

const EMPTY_FIXED_COLS = [
  {
    id: 'legal_entity_name',
  },
  {
    id: 'total_contributions',
  },
  {
    id: 'note',
  },
] satisfies TableBottomColumn[];

const EMPTY_CURRENT_COLS = [
  {
    id: 'legal_entity_name',
    className: '!bg-neutral-850',
  },
  {
    id: 'total_contributions',
    className: '!bg-neutral-850',
  },
  {
    id: 'note',
    className: '!bg-neutral-850',
  },
] satisfies TableBottomColumn[];

export function Step3Distributions({
  step2Data,
  investmentEntitiesWithDistributions:
    initialInvestmentEntitiesWithDistributions,
  setUpdateInvestmentEntities,
}: {
  step2Data: StepData;
  investmentEntitiesWithDistributions: SelectedInvestmentEntity[];
  setUpdateInvestmentEntities: React.Dispatch<
    React.SetStateAction<SelectedInvestmentEntity[]>
  >;
}) {
  const { remainingDistribution, preferred } = step2Data;
  const [
    investmentEntitiesWithDistributions,
    setInvestmentEntitiesWithDistributions,
  ] = useState(initialInvestmentEntitiesWithDistributions);

  const validateErrors = (entities: SelectedInvestmentEntity[]) => {
    const errors = remainingDistribution.map((distro) => {
      const total = parseIntOrFloat(
        sumBy(
          entities,
          (inv) => inv._remainingDistributionRecord?.[distro.kind]?.amount ?? 0,
        ),
      );
      return {
        kind: distro.kind,
        total,
        valid: total === distro.amount,
        diff: parseIntOrFloat(numDiff(total, distro.amount, { abs: false })),
      };
    });

    const preferredTotal = parseIntOrFloat(
      sumBy(
        entities,
        (inv) => inv._remainingDistributionRecord?.preferred?.amount ?? 0,
      ),
    );

    const preferredErrors = [
      {
        kind: 'preferred',
        total: preferredTotal,
        valid: preferred === preferredTotal,
        diff: parseIntOrFloat(
          numDiff(preferred, preferredTotal, { abs: false }),
        ),
      },
    ];
    const entries = [...preferredErrors, ...errors].map(
      ({ kind, ...item }) => [kind, item] as const,
    ) as Entries<DistributionKind, Omit<(typeof errors)[number], 'kind'>>;
    return Object.fromEntries(entries) as Partial<
      Record<DistributionKind, Omit<(typeof errors)[number], 'kind'>>
    >;
  };

  const handleValue = (
    invId: SelectedInvestmentEntity['id'],
    kind: DistributionKind,
    value: number,
  ) => {
    setInvestmentEntitiesWithDistributions((prev) => {
      const withCorrectValue = prev.map((item) => {
        if (item.id !== invId) return item;

        return {
          ...item,
          _remainingDistributionRecord: {
            ...(item._remainingDistributionRecord ?? {}),
            [kind]: {
              ...item._remainingDistributionRecord?.[kind],
              amount: value,
            },
          } satisfies SelectedInvestmentEntity['_remainingDistributionRecord'],
        } satisfies SelectedInvestmentEntity;
      });

      const errorsRecord = validateErrors(withCorrectValue);

      const res = prev.map((item) => {
        const isInvToUpdate = item.id === invId;

        return {
          ...item,
          _remainingDistributionRecord: {
            ...(item._remainingDistributionRecord ?? {}),
            [kind]: {
              ...item._remainingDistributionRecord?.[kind],
              amount: isInvToUpdate
                ? value
                : (item._remainingDistributionRecord?.[kind]?.amount ?? 0),
              valid: errorsRecord[kind]?.valid ?? false,
              currentTotalAmount: errorsRecord[kind]?.total ?? 0,
              diff: errorsRecord[kind]?.diff ?? 0,
            },
          } satisfies SelectedInvestmentEntity['_remainingDistributionRecord'],
        } satisfies SelectedInvestmentEntity;
      });
      return res;
    });
  };

  const hasPreferred = preferred > 0;

  const bottomRows = useMemo(() => {
    const fixedPreferredCol = hasPreferred
      ? ([
          {
            id: 'preferred',
            text: (
              <CurrencyFormatter
                classes={{ allParts: 'text-neutral-500' }}
                value={step2Data.preferred}
              />
            ),
          },
        ] satisfies TableBottomColumn[])
      : [];

    const resolveCurrentPreferredTotalAmount = () =>
      parseIntOrFloat(
        sumBy(
          investmentEntitiesWithDistributions,
          (inv) => inv?._remainingDistributionRecord?.preferred?.amount ?? 0,
        ),
      );
    const resolveCurrentPreferredCol = () => {
      if (!hasPreferred) return [];

      const totalAmount = resolveCurrentPreferredTotalAmount();

      // dry
      const matches = step2Data.preferred === totalAmount;
      const resolveVariant = (): ComponentProps<
        typeof StatusIndicator
      >['variant'] => {
        if (matches) return 'success';
        if (totalAmount > step2Data.preferred) return 'too_many';
        if (totalAmount === 0) return 'empty';
        return 'in_progress';
      };

      return [
        {
          id: 'preferred',
          text: (
            <div className="flex items-center justify-between">
              <CurrencyFormatter
                classes={{ allParts: 'text-neutral-000 font-semibold' }}
                value={totalAmount}
              />
              <StatusIndicator variant={resolveVariant()} />
            </div>
          ),
          className: '!bg-neutral-850',
        },
      ] satisfies TableBottomColumn[];
    };

    const fixedRemainingDistributionCols = remainingDistribution.map(
      (item) =>
        ({
          id: item.kind,
          text: (
            <CurrencyFormatter
              classes={{ allParts: 'text-neutral-500' }}
              value={item.amount}
            />
          ),
        }) satisfies TableBottomColumn,
    );

    const currentRemainingDistributionCols = remainingDistribution.map(
      (distro) => {
        const totalAmount = parseIntOrFloat(
          sumBy(
            investmentEntitiesWithDistributions,
            (inv) =>
              inv?._remainingDistributionRecord?.[distro.kind]?.amount ?? 0,
          ),
        );

        const matches = distro.amount === totalAmount;
        const resolveVariant = (): ComponentProps<
          typeof StatusIndicator
        >['variant'] => {
          if (matches) return 'success';
          if (totalAmount > distro.amount) return 'too_many';
          if (totalAmount === 0) return 'empty';
          return 'in_progress';
        };

        return {
          id: distro.kind,
          text: (
            <div className="flex items-center justify-between">
              <CurrencyFormatter
                classes={{ allParts: 'text-neutral-000 font-semibold' }}
                value={totalAmount}
              />
              <StatusIndicator variant={resolveVariant()} />
            </div>
          ),
          className: '!bg-neutral-850',
        } satisfies TableBottomColumn;
      },
    );

    const fixedTotal = [
      {
        id: 'total',
        text: 'Total',
        className: 'font-semibold text-neutral-500',
      },
      ...EMPTY_FIXED_COLS,
      ...fixedPreferredCol,
      ...fixedRemainingDistributionCols,
    ] satisfies TableBottomColumn[];

    const currentTotal = [
      {
        id: 'total',
        text: 'Current Total',
        className: '!bg-neutral-850 text-neutral-000 font-semibold',
      },
      ...EMPTY_CURRENT_COLS,
      ...resolveCurrentPreferredCol(),
      ...currentRemainingDistributionCols,
    ] satisfies TableBottomColumn[];

    return [fixedTotal, currentTotal];
  }, [preferred, remainingDistribution, investmentEntitiesWithDistributions]);

  const handleAllReset = () => {
    setInvestmentEntitiesWithDistributions((prev) =>
      prev.map((item) => ({
        ...item,
        _remainingDistributionRecord: {
          ...Object.fromEntries(
            Object.entries(item._remainingDistributionRecord ?? {}).map(
              ([key, prevItem]) => [
                key,
                {
                  ...prevItem,
                  diff: prevItem.expectedTotalAmount,
                  amount: 0,
                  currentTotalAmount: 0,
                  valid: false,
                },
              ],
            ),
          ),
        },
      })),
    );
  };

  const handleReset = (kind: DistributionKind) => {
    setInvestmentEntitiesWithDistributions((prev) =>
      prev.map((item) => ({
        ...item,
        _remainingDistributionRecord: {
          ...(item._remainingDistributionRecord ?? {}),
          [kind]: {
            ...item._remainingDistributionRecord[kind],
            amount: 0,
            currentTotalAmount: 0,
            diff:
              item._remainingDistributionRecord[kind]?.expectedTotalAmount ?? 0,
            valid: false,
          },
        },
      })),
    );
  };

  const columns = useMemo<IColumn<SelectedInvestmentEntity>[]>(
    () => [
      {
        text: 'Investment Entity',
        dataField: 'investmentEntity.name',
        formatter: ({ row: item }) => (
          <span className="dark-60 inline-regular">
            {item.investmentEntity.name}
          </span>
        ),
      },
      {
        text: 'Legal Entity',
        dataField: 'legalEntity.name',
        formatter: ({ row: item }) => (
          <span className="dark-60 inline-regular">
            {item.legalEntity.name}
          </span>
        ),
      },
      {
        text: 'Contribution',
        dataField: 'totalContributionsCents',
        headerStyle: { width: 135 },
        formatter: ({ row: item }) => (
          <CurrencyFormatter value={item.totalContributionsDollars} />
        ),
      },
      {
        text: 'Note',
        dataField: 'note',
        formatter: ({ row }) => (
          <Input
            value={row.note}
            onChange={(e) => {
              setInvestmentEntitiesWithDistributions((prev) => {
                const res = prev.map((item) => {
                  if (item.id !== row.id) return item;

                  return {
                    ...item,
                    note: e.target.value,
                  } satisfies SelectedInvestmentEntity;
                });

                return res;
              });
            }}
            placeholder="Add note"
          />
        ),
      },
      {
        text: DISTRIBUTION_LABEL_MAPPINGS.preferred,
        dataField: 'preferred',
        hidden: !hasPreferred,
        formatter: ({ row }) => {
          const { _remainingDistributionRecord: record } = row;
          const currentRecord = record.preferred!;
          const suggestedValue = getSuggestedValue(
            currentRecord as Required<typeof currentRecord>,
          );

          return (
            <AutofillWrapper
              amountToFill={suggestedValue}
              disabled={suggestedValue < 0 || currentRecord?.valid}
              onClick={() => {
                handleValue(row.id, 'preferred', suggestedValue);
              }}
            >
              <CurrencyInputNumber
                selectOnFocus
                allowNegative={false}
                value={currentRecord?.amount}
                error={!currentRecord?.valid}
                onChange={(e) => {
                  handleValue(
                    row.id,
                    'preferred',
                    parseNumberFromCurrencyInput(e.target.value),
                  );
                }}
              />
            </AutofillWrapper>
          );
        },
        quickFilter: (
          <IconButton
            size="m"
            variant="secondary"
            iconName="reset"
            className="ml-2"
            onClick={() => handleReset('preferred')}
          />
        ),
      },
      ...step2Data.remainingDistribution.map(
        (distro) =>
          ({
            text: startCase(capitalize(distro.kind)),
            dataField: distro.id,
            formatter: ({ row }) => {
              const { _remainingDistributionRecord: record } = row;
              const currentRecord = record[distro.kind]!;
              const suggestedValue = getSuggestedValue(
                currentRecord as Required<typeof currentRecord>,
              );

              const handleLocalValue = (value: number) => {
                handleValue(row.id, distro.kind, value);
              };

              return (
                <AutofillWrapper
                  amountToFill={suggestedValue}
                  disabled={suggestedValue === -1 || currentRecord?.valid}
                  onClick={() => {
                    handleLocalValue(suggestedValue);
                  }}
                >
                  <CurrencyInputNumber
                    selectOnFocus
                    value={record?.[distro.kind]?.amount}
                    error={!record?.[distro.kind]?.valid}
                    allowNegative={false}
                    onChange={(e) => {
                      handleLocalValue(
                        parseNumberFromCurrencyInput(e.target.value),
                      );
                    }}
                  />
                </AutofillWrapper>
              );
            },
            quickFilter: (
              <IconButton
                size="m"
                variant="secondary"
                iconName="reset"
                className="ml-2"
                onClick={() => handleReset(distro.kind)}
              />
            ),
          }) satisfies IColumn<SelectedInvestmentEntity>,
      ),
    ],
    [],
  );

  useEffect(() => {
    setUpdateInvestmentEntities(investmentEntitiesWithDistributions);
  }, [investmentEntitiesWithDistributions]);

  return (
    <>
      <div className="flex gap-4 border-b border-neutral-150 bg-white px-6 py-4">
        <div className="flex w-[120px] flex-col gap-1">
          <p className="secondary-regular uppercase text-neutral-500">Date</p>
          <p className="inline-regular text-neutral-850">
            {formatDate(step2Data.date as DateString, 'MMM DD, YYYY')}
          </p>
        </div>
        <div className="flex w-[120px] flex-col gap-1">
          <p className="secondary-regular uppercase text-neutral-500">Period</p>
          <p className="inline-regular text-neutral-850">
            {formatDateByPeriodType(
              step2Data.periodType,
              step2Data.period as DateString,
            )}
          </p>
        </div>
        <div className="flex w-[120px] flex-col gap-1">
          <p className="secondary-regular uppercase text-neutral-500">
            Total Distribution
          </p>
          <p className="inline-regular text-neutral-850">
            <CurrencyFormatter value={step2Data.total} />
          </p>
        </div>
      </div>
      <div className="flex flex-col gap-2 px-6 py-4">
        <div className="flex items-center justify-between">
          <p className="body-semibold text-neutral-900">
            Enter your Distributions below
            <span className="text-danger-055"> *</span>
          </p>
          <Button size="xs" variant="secondary" onClick={handleAllReset}>
            Reset
          </Button>
        </div>
        <ReturnRawTableEditor
          columns={columns}
          items={investmentEntitiesWithDistributions}
          bottomRows={bottomRows}
        />
      </div>
    </>
  );
}
