import React, { FC, Dispatch, SetStateAction, useState } from 'react';
import FormButtons from '@/bundles/Shared/components/GroupForm/FormButtons/FormButtons';
import FormComponent from '@/bundles/Shared/components/GroupForm/FormComponent/FormComponent';
import { cn } from '@/shared/lib/css/cn';
import {
  IFormButton,
  IGroupFormItem,
} from '@/bundles/Shared/components/GroupForm/types/types';

export interface IGroupFormConfig {
  steps?: boolean;
  withoutNumberStep?: boolean;
  reviewFlow?: boolean;
  styles?: {
    containerClassName: string;
  };
}

interface Props {
  groupFormItems: IGroupFormItem[];
  onSubmit: (data?: object) => void;
  formData: object;
  setFormData: Dispatch<SetStateAction<object>>;
  buttonConfig: IFormButton;
  groupFormConfig?: IGroupFormConfig;
  viewModeSwitchable?: boolean;
}

const GroupForm: FC<Props> = ({
  groupFormItems,
  onSubmit,
  formData,
  setFormData,
  buttonConfig,
  groupFormConfig,
  viewModeSwitchable = true,
}) => {
  const [viewMode, setViewMode] = useState(false);
  const viewModeSwitcher = () => setViewMode(!viewMode);
  const [loading, setLoading] = useState(false);
  const [validate, setValidate] = useState({});
  const onLoading = (status: boolean) => {
    setLoading(status);
  };

  return (
    <div>
      <div
        className={cn(
          'flex flex-wrap',
          groupFormConfig?.styles?.containerClassName,
        )}
      >
        {groupFormItems.map((elem, idx) => (
          <FormComponent
            {...elem}
            key={`formData${idx}`}
            formData={formData}
            setFormData={setFormData}
            loading={loading}
            steps={groupFormConfig?.steps}
            compId={idx + 1}
            lastCompId={groupFormItems.length}
            viewMode={viewMode}
            viewModeSwitcher={viewModeSwitchable ? viewModeSwitcher : undefined}
            validate={validate}
            setValidate={setValidate}
            groupFormConfig={groupFormConfig}
          />
        ))}
      </div>
      <FormButtons
        groupFormItems={groupFormItems}
        formData={formData}
        onSubmit={onSubmit}
        loading={loading}
        onLoading={onLoading}
        buttonConfig={buttonConfig}
        view={groupFormConfig?.steps}
        groupFormConfig={groupFormConfig}
        viewMode={viewMode}
        viewModeSwitcher={viewModeSwitcher}
        validate={validate}
      />
    </div>
  );
};
export default GroupForm;
