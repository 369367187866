import React, { useMemo, useRef } from 'react';
import { cn } from '@/shared/lib/css/cn';
import { AgGridReact } from 'ag-grid-react';
import { ColDef } from 'ag-grid-community';
import useElementSize from '@/shared/lib/hooks/useElementSize';
import { handleSizeColumnsToFit } from 'lib/ag-grid/utils';
import {
  IAllRules,
  IUserFinancialCategoryRule,
  IUserRoleFinancialCategoryRule,
} from 'types/ReportTableConfig';
import {
  autoGroupValueFormatter,
  getRowHeight,
} from '@/bundles/Shared/components/ReportTable/utils';
import { DEFAULT_CONNECTOR_GROUP_CELL_STYLE } from '@/bundles/Shared/components/AgGrid/GroupWithConnector/GroupWithConnector';
import { GroupRenderer } from '@/bundles/Shared/components/ReportTable/groupRenderer/PreviewTable/GroupRenderer';
import {
  isRowDivider,
  isRowHeader,
  TRow,
} from '@/bundles/Shared/components/ReportTable/types';
import {
  AUTO_GROUP_COLUMN_DEF_STATIC_FIELDS,
  autoGroupColumnDefCellClassResolverForPreview,
} from '@/bundles/Shared/components/ReportTable/helpers/autoGroupColumnDef';
import { CellRenderer } from '@/bundles/Shared/components/ReportTable/cellRenderer/PreviewTable/CellRenderer';
import styles from '@/bundles/Shared/components/ReportTable/ReportTable.module.scss';
import { useReportTableRowData } from '@/bundles/Shared/components/ReportTable/helpers/hooks/useReportTableRowData';
import { SHARED_REPORT_TABLE_STATIC_PROPS } from '@/bundles/Shared/components/ReportTable/helpers/shared/consts';

interface Props {
  rows: TRow[];
  allRules: IAllRules;
  handleRoleRulesChange: (
    newRules: IUserRoleFinancialCategoryRule[],
    categoryPath: string,
  ) => void;
  handleUserRulesChange: (
    newRules: IUserFinancialCategoryRule[],
    categoryPath: string,
  ) => void;
  handleHasChanges: (hasChanges: boolean) => void;
}

export function PreviewReportTable({
  rows,
  allRules,
  handleRoleRulesChange,
  handleUserRulesChange,
  handleHasChanges,
}: Props) {
  const gridContainer = useRef(null);

  const { width } = useElementSize(gridContainer);
  const rowData = useReportTableRowData(rows);
  const previewColumn = useMemo<ColDef>(
    () => ({
      cellRenderer: CellRenderer,
      cellRendererParams: {
        allRules,
        handleRoleRulesChange,
        handleUserRulesChange,
        handleHasChanges,
      },
      cellClass: autoGroupColumnDefCellClassResolverForPreview,
    }),
    [allRules],
  );

  const autoGroupColumnDef = useMemo<ColDef>(
    () => ({
      ...AUTO_GROUP_COLUMN_DEF_STATIC_FIELDS,
      cellClass: autoGroupColumnDefCellClassResolverForPreview,
      cellStyle: DEFAULT_CONNECTOR_GROUP_CELL_STYLE,
      cellRenderer: GroupRenderer,
      valueFormatter: autoGroupValueFormatter,
    }),
    [],
  );

  return (
    <div className={cn(styles.grid, 'ag-grid-30')}>
      <div ref={gridContainer} className={cn('ag-theme-alpine preview dark')}>
        <AgGridReact
          {...SHARED_REPORT_TABLE_STATIC_PROPS}
          rowData={rowData}
          getRowId={(row) => row.data.key}
          getDataPath={(row) => row.path}
          autoGroupColumnDef={autoGroupColumnDef}
          columnDefs={[previewColumn]}
          getRowHeight={getRowHeight}
          rowClassRules={{
            [styles.dividerRow]: ({ node }) => isRowDivider(node.data),
            [styles.headerRow]: ({ node }) => isRowHeader(node.data),
          }}
          onFirstDataRendered={(e) => {
            handleSizeColumnsToFit(e, width);
          }}
          onColumnEverythingChanged={(e) => {
            handleSizeColumnsToFit(e, width);
          }}
        />
      </div>
    </div>
  );
}
