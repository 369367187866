import { currentUserIsSreAdmin } from '@/lib/permissions';
import {
  SpecifiedReportThreshold,
  ReportThreshold,
} from '@/entities/report/threshold/model';
import { useGetApiSettingsReportThresholdsQuery } from '@/shared/api/settingsReportThresholdsEnhancedApi';

export const isThresholdSpecified = (
  threshold: ReportThreshold,
): threshold is SpecifiedReportThreshold => threshold.globalValue != null;

export const isThresholdOverridable = (threshold: ReportThreshold): boolean =>
  threshold.allowedOverrides;

export const canUserConfigureThresholds = () => {
  return currentUserIsSreAdmin();
};

export const useReportThresholds = () => {
  return useGetApiSettingsReportThresholdsQuery(undefined, {
    selectFromResult: ({ data, isLoading, isFetching }) => ({
      thresholds: data?.items ?? [],
      isLoading,
      isFetching,
    }),
  });
};
