import * as React from 'react';
import { ComponentProps } from 'react';
import { cn } from '@/shared/lib/css/cn';
import { Icon } from 'stories/Icon/Icon';
import { TFireStationPriority } from 'bundles/FireStation/types';
import FireIcon, {
  ICON_COLORS,
} from 'bundles/FireStation/components/FireIcon/FireIcon';
import styles from '@/bundles/Shared/components/Filters/buttons/userFireStationFilterButton/UserFireStationFilterButton.module.scss';
import FilterButton from '@/bundles/Shared/components/Filters/buttons/filterButton/FilterButton';

type Props = ComponentProps<typeof FilterButton> & {
  priority?: TFireStationPriority | `${TFireStationPriority}`;
};

function UserFireStationFilterButton({
  filtered,
  className,
  priority = TFireStationPriority.HIGH,
  ...props
}: Props) {
  return (
    <FilterButton
      className={cn(
        'secondary-semibold',
        styles.indicator,
        // todo replace with common priority colors classes
        styles[`indicator_priority_${priority}`],
        !filtered && ICON_COLORS[priority].textClassname,
        className,
      )}
      classes={{
        container: styles.container,
        filtered: cn(styles.indicator_filtered),
      }}
      filtered={filtered}
      {...props}
    >
      <FireIcon priority={priority} />
      {props.children}
    </FilterButton>
  );
}

export default UserFireStationFilterButton;
