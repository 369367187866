import React, { useMemo } from 'react';
import { TransactionTypeCell } from '@/entities/accounting/transaction/ui/TransactionTypeCell';
import SourceTypeColumn from 'bundles/Shared/components/SourceType/SourceTypeColumn/SourceTypeColumn';
import { IColumn } from 'bundles/Shared/components/Table/types';
import QuickFilterColorDropdown, {
  TQuickFilterColorDropdownProps,
} from 'bundles/Shared/components/Table/filters/QuickFilterColorDropdown';
import {
  TRANSACTION_TYPE_COLORS,
  TRANSACTION_TYPE_LABELS,
} from '@/entities/accounting/transaction/config';
import {
  getRemarkFormatter,
  getVendorEntityFormatter,
} from '@/entities/accounting/transaction/ui/Formatters';

import { convertCentsToDollars } from '@/shared/lib/converters';
import {
  AccountingTransaction,
  AccountingTransactionType,
} from '@/entities/accounting/transaction/model';
import { formatDate } from '@/shared/lib/formatting/dates';
import { GeneralLedgerType } from '@/types/GeneralLedger';

export const useTransactionTableColumns = () =>
  useMemo<IColumn<AccountingTransaction>[]>(
    () => [
      {
        dataField: 'legalEntity.name',
        text: 'Legal Entity',
        sortable: true,
        formatter: ({ row: transaction }) => transaction.legalEntity.name,
      },
      {
        dataField: 'legalEntity.legalEntitiable.name',
        text: 'Asset',
        sortable: true,
        formatter: ({ row: transaction }) =>
          transaction.legalEntity.legalEntitiable.name,
      },
      {
        dataField: 'date',
        text: 'Date',
        sortable: true,
        formatter: ({ row: transaction }) =>
          formatDate(transaction.date, 'MMMM DD, YYYY'),
        headerClasses: 'w-[170px]',
      },
      {
        dataField: 'period',
        text: 'Period',
        formatter: ({ row: transaction }) =>
          formatDate(transaction.period, 'MMM-YY'),
        headerClasses: 'w-[100px]',
      },
      {
        dataField: '',
        text: 'Source',
        headerStyle: {
          width: 170,
        },
        formatter: ({ row: transaction }) => (
          <SourceTypeColumn sourceType={transaction.managedObject.erpSystem} />
        ),
      },
      {
        dataField: 'transaction_value',
        text: 'Debit',
        sortable: true,
        headerStyle: {
          width: 240,
        },
        formatter: ({ row: transaction }) => (
          <TransactionTypeCell
            generalLedgerType={
              transaction.generalLedger.category
                ?.rootCategoryCode as GeneralLedgerType
            }
            debit={convertCentsToDollars(transaction.debitCents)}
            credit={convertCentsToDollars(transaction.creditCents)}
          />
        ),
        filterComponent: QuickFilterColorDropdown,
        filterComponentParams: {
          options: Object.keys(TRANSACTION_TYPE_LABELS).map(
            (k: AccountingTransactionType | 'all') => ({
              key: k,
              color: k === 'all' ? '' : TRANSACTION_TYPE_COLORS[k],
              label: TRANSACTION_TYPE_LABELS[k],
            }),
          ),
        } as TQuickFilterColorDropdownProps,
      },
      {
        dataField: 'vendor_entity_code',
        text: 'Vendor',
        sortable: true,
        formatter: getVendorEntityFormatter<AccountingTransaction>(
          (item) => item.vendorEntity,
        ),
        headerStyle: {
          minWidth: 200,
        },
      },
      {
        dataField: 'description',
        text: 'Description',
        formatter: getRemarkFormatter<AccountingTransaction>(
          (item) => item.description,
        ),
      },
    ],
    [],
  );
