import { DashboardIcon } from 'bundles/Shared/entities/dashboard/ui/DashboardIcon';
import { ReportDashboardType } from 'bundles/Shared/entities/dashboard/model/types/types';
import { ComponentProps } from 'react';

import { DashboardCardLabel } from '@/bundles/Shared/entities/dashboard/ui/card/DashboardCardLabel';

interface Props extends PropsWithClassName {
  type: ReportDashboardType | `${ReportDashboardType}`;
}

const DASHBOARD_TYPE_CARD_LABEL_MAP = {
  comparison_mode: {
    iconName: 'compare',
    label: 'Comparison',
  },
  eagle_eye: {
    iconName: 'eagleEyeDashboard',
    label: 'Eagle Eye',
  },
  object_level: {
    iconName: 'objectLevelDashboard',
    label: 'Object Level',
  },
  report_builder_template: {
    iconName: 'objectLevelDashboardGreyscale',
    label: 'Report Builder Template',
  },
  report_builder_template_eagle_eye: {
    iconName: 'eagleEyeDashboardGreyscale',
    label: 'Report Builder Template',
  },
} as const satisfies Record<
  ReportDashboardType,
  {
    label: string;
    iconName: ComponentProps<typeof DashboardIcon>['iconName'];
  }
>;

export function DasbhoardCardTypeLabel({ type }: Props) {
  const option = DASHBOARD_TYPE_CARD_LABEL_MAP[type];
  if (option == null) return null;

  return (
    <DashboardCardLabel>
      <DashboardIcon iconName={option.iconName} />
      <span className="secondary-semibold text-neutral-900">
        {option.label}
      </span>
    </DashboardCardLabel>
  );
}
