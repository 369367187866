import React from 'react';
import { Button } from 'stories/Button/Button';
import { Modal } from 'stories/Modals/Modal/Modal';
import { Input } from 'stories/FormControls/Inputs/Input/Input';
import { Field } from 'stories/Field/Field';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { cn } from '@/shared/lib/css/cn';
import * as yup from 'yup';
import addHTTP from '@/bundles/Shared/addHTTP';
import { urlPattern } from '@/lib/regexpPatterns';

const AddressModal = ({ object, close, update }) => {
  const schema = yup.object().shape({
    website: yup
      .string()
      .nullable()
      .matches(
        urlPattern,
        'Url has incorrect format. (e.g. http://symmetre.com)',
      )
      .required(),
  });
  const website = object.website || '';
  const websiteTitle = object.websiteTitle || '';
  const isCreate = object.website === null;

  const {
    handleSubmit,
    register,
    watch,
    setValue,
    formState: { errors, submitCount },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { website, website_title: websiteTitle },
  });

  const fields = watch();

  const onSubmit = (formData) => {
    if (formData.website) formData.website = addHTTP(formData.website);
    update({
      id: object.id,
      slug: object.slug,
      [object.objectType]: { ...formData },
    });
    close();
  };

  return (
    <Modal
      toggle={close}
      header={isCreate ? 'Add Website' : 'Edit Website'}
      size="400"
      actions={
        <div className="w-100 flex justify-between">
          <Button variant="secondary" onClick={close}>
            Cancel
          </Button>
          <Button variant="success" form="websiteInfo" type="submit">
            {isCreate ? 'Add Website' : 'Save Updates'}
          </Button>
        </div>
      }
    >
      <form id="websiteInfo" onSubmit={handleSubmit(onSubmit)}>
        <Field labelText="Title">
          <Input
            {...register('website_title')}
            value={fields.website_title}
            size="l"
            placeholder="Enter Title"
            onChange={(e) => {
              setValue('website_title', e.target.value, {
                shouldValidate: submitCount > 0,
              });
            }}
            className="input-light"
          />
        </Field>

        <Field className="mt-m" labelText="Link">
          <Input
            {...register('website')}
            value={fields.website}
            placeholder="Enter Link"
            size="l"
            onChange={(e) => {
              setValue('website', e.target.value, {
                shouldValidate: submitCount > 0,
              });
            }}
            className={cn({ 'is-invalid': errors.website })}
          />
        </Field>
        <div className={cn('invalid-feedback', { 'd-block': errors.website })}>
          {errors.website?.message}
        </div>
      </form>
    </Modal>
  );
};

export default AddressModal;
