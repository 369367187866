import React, { FC } from 'react';
import { cn } from '@/shared/lib/css/cn';
import { Icon } from '@/stories/Icon/Icon';
import { Badge } from '@/stories/Badges/Badge/Badge';
import styles from '@/stories/ChangeableStatusBadge/ChangeableStatusBadge.module.scss';
import StatusOptionsDropdown, {
  TStatusOption,
} from '@/stories/ChangeableStatusBadge/components/StatusOptionsDropdown';

interface IChangeableStatusBadgeProps {
  selected: TStatusOption;
  options: TStatusOption[];
  handleItemClick: (st: TStatusOption) => void;
  badgeLabel?: string;
}

export const ChangeableStatusBadge: FC<IChangeableStatusBadgeProps> = ({
  selected,
  handleItemClick,
  options,
  badgeLabel = 'Status',
}) => (
  <div className="">
    {options.length > 1 ? (
      <StatusOptionsDropdown
        classes={{
          dropdown: styles.dropdown,
          item: styles.item,
          itemWrapper: styles.itemWrapper,
          itemWrapperActive: styles.itemWrapperActive,
          circle: styles.circle,
        }}
        options={options}
        selected={selected}
        handleItemClick={(statusOption) => {
          const clickedSameStatus = statusOption.key === selected.key;
          if (clickedSameStatus) return;
          handleItemClick(statusOption);
        }}
      >
        <Badge label={badgeLabel} backgroundColor={`var(--${selected.color})`}>
          <div className={cn(styles.status)}>
            <div>{selected.label}</div>
            {options.length > 1 && <Icon iconName="bottom" />}
          </div>
        </Badge>
      </StatusOptionsDropdown>
    ) : (
      <Badge label={badgeLabel} backgroundColor={`var(--${selected.color})`}>
        <div>
          <div>{selected.label}</div>
          {options.length > 1 && <Icon iconName="bottom" />}
        </div>
      </Badge>
    )}
  </div>
);
