/* eslint-disable @typescript-eslint/no-implied-eval */
/* eslint-disable prefer-spread */
/* eslint-disable max-params */
/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable no-multi-assign */
/* eslint-disable no-param-reassign */
import React, { ReactNode } from 'react';
import ReactDom from 'react-dom';
import PropTypes from 'prop-types';
import _PropTypes from '@/stories/Modals/Modal/ModalOrigins/utils/PropTypes';

function forceReflow(node: Element) {
  return node.scrollTop;
}

type RefHandler<
  RefElement extends undefined | HTMLElement,
  ImplicitRefHandler extends (node: HTMLElement, ...args: unknown[]) => void,
  ExplicitRefHandler extends (...args: unknown[]) => void,
> = {
  implicit: ImplicitRefHandler;
  explicit: ExplicitRefHandler;
}[RefElement extends undefined ? 'implicit' : 'explicit'];

export type EndHandler<RefElement extends undefined | HTMLElement> = RefHandler<
  RefElement,
  (node: HTMLElement, done: () => void) => void,
  (done: () => void) => void
>;

export type EnterHandler<RefElement extends undefined | HTMLElement> =
  RefHandler<
    RefElement,
    (node: HTMLElement, isAppearing: boolean) => void,
    (isAppearing: boolean) => void
  >;

export type ExitHandler<E extends undefined | HTMLElement> = RefHandler<
  E,
  (node: HTMLElement) => void,
  () => void
>;

export const UNMOUNTED = 'unmounted';
export const EXITED = 'exited';
export const ENTERING = 'entering';
export const ENTERED = 'entered';
export const EXITING = 'exiting';

export interface TransitionActions {
  /**
   * Normally a component is not transitioned if it is shown when the
   * `<Transition>` component mounts. If you want to transition on the first
   * mount set  appear to true, and the component will transition in as soon
   * as the `<Transition>` mounts. Note: there are no specific "appear" states.
   * appear only adds an additional enter transition.
   */
  appear?: boolean | undefined;

  /**
   * Enable or disable enter transitions.
   */
  enter?: boolean | undefined;

  /**
   * Enable or disable exit transitions.
   */
  exit?: boolean | undefined;
}

interface BaseTransitionProps<RefElement extends undefined | HTMLElement> {
  /**
   * Show the component; triggers the enter or exit states
   */
  in?: boolean | undefined;

  /**
   * By default the child component is mounted immediately along with the
   * parent Transition component. If you want to "lazy mount" the component on
   * the first `in={true}` you can set `mountOnEnter`. After the first enter
   * transition the component will stay mounted, even on "exited", unless you
   * also specify `unmountOnExit`.
   */
  mountOnEnter?: boolean | undefined;

  /**
   * By default the child component stays mounted after it reaches the
   * 'exited' state. Set `unmountOnExit` if you'd prefer to unmount the
   * component after it finishes exiting.
   */
  unmountOnExit?: boolean | undefined;

  /**
   * Callback fired before the "entering" status is applied. An extra
   * parameter `isAppearing` is supplied to indicate if the enter stage is
   * occurring on the initial mount
   */
  onEnter?: EnterHandler<RefElement> | undefined;

  /**
   * Callback fired after the "entering" status is applied. An extra parameter
   * isAppearing is supplied to indicate if the enter stage is occurring on
   * the initial mount
   */
  onEntering?: EnterHandler<RefElement> | undefined;

  /**
   * Callback fired after the "entered" status is applied. An extra parameter
   * isAppearing is supplied to indicate if the enter stage is occurring on
   * the initial mount
   */
  onEntered?: EnterHandler<RefElement> | undefined;

  /**
   * Callback fired before the "exiting" status is applied.
   */
  onExit?: ExitHandler<RefElement> | undefined;

  /**
   * Callback fired after the "exiting" status is applied.
   */
  onExiting?: ExitHandler<RefElement> | undefined;

  /**
   * Callback fired after the "exited" status is applied.
   */
  onExited?: ExitHandler<RefElement> | undefined;

  /**
   * A function child can be used instead of a React element. This function is
   * called with the current transition status ('entering', 'entered',
   * 'exiting',  'exited', 'unmounted'), which can be used to apply context
   * specific props to a component.
   * ```jsx
   *    <Transition in={this.state.in} timeout={150}>
   *        {state => (
   *            <MyComponent className={`fade fade-${state}`} />
   *        )}
   *    </Transition>
   * ```
   */
  children?: TransitionChildren | undefined;

  /**
   * A React reference to DOM element that need to transition: https://stackoverflow.com/a/51127130/4671932
   * When `nodeRef` prop is used, node is not passed to callback functions (e.g. onEnter) because user already has direct access to the node.
   * When changing `key` prop of `Transition` in a `TransitionGroup` a new `nodeRef` need to be provided to `Transition` with changed `key`
   * prop (@see https://github.com/reactjs/react-transition-group/blob/master/test/Transition-test.js).
   */
  nodeRef?: React.Ref<RefElement> | undefined;

  [prop: string]: any;
}

export type TransitionStatus =
  | typeof ENTERING
  | typeof ENTERED
  | typeof EXITING
  | typeof EXITED
  | typeof UNMOUNTED;
export type TransitionChildren =
  | ReactNode
  | ((status: TransitionStatus) => ReactNode);

export interface TimeoutProps<RefElement extends undefined | HTMLElement>
  extends BaseTransitionProps<RefElement> {
  timeout:
    | number
    | {
        appear?: number | undefined;
        enter?: number | undefined;
        exit?: number | undefined;
      };
  addEndListener?: EndHandler<RefElement> | undefined;
}

export interface EndListenerProps<Ref extends undefined | HTMLElement>
  extends BaseTransitionProps<Ref> {
  addEndListener: EndHandler<Ref>;
  timeout?:
    | number
    | {
        appear?: number | undefined;
        enter?: number | undefined;
        exit?: number | undefined;
      }
    | undefined;
}

export type TransitionProps<
  RefElement extends undefined | HTMLElement = undefined,
> = TimeoutProps<RefElement> | EndListenerProps<RefElement>;

const _config = {
  disabled: false,
} as const;

const TransitionGroupContext = React.createContext(null);

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  const target = {};
  const sourceKeys = Object.keys(source);
  let key, i;
  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }
  return target;
}

function _inheritsLoose(subClass, superClass) {
  subClass.prototype = Object.create(superClass.prototype);
  subClass.prototype.constructor = subClass;
  subClass.__proto__ = superClass;
}

export const Transition = (function (_React$Component) {
  _inheritsLoose(Transition, _React$Component);

  function Transition(props, context) {
    let _this;

    _this = _React$Component.call(this, props, context) || this;
    const parentGroup = context; // In the context of a TransitionGroup all enters are really appears

    const appear =
      parentGroup && !parentGroup.isMounting ? props.enter : props.appear;
    let initialStatus;
    _this.appearStatus = null;

    if (props.in) {
      if (appear) {
        initialStatus = EXITED;
        _this.appearStatus = ENTERING;
      } else {
        initialStatus = ENTERED;
      }
    } else {
      if (props.unmountOnExit || props.mountOnEnter) {
        initialStatus = UNMOUNTED;
      } else {
        initialStatus = EXITED;
      }
    }

    _this.state = {
      status: initialStatus,
    };
    _this.nextCallback = null;
    return _this;
  }

  Transition.getDerivedStateFromProps = function getDerivedStateFromProps(
    _ref,
    prevState,
  ) {
    const nextIn = _ref.in;

    if (nextIn && prevState.status === UNMOUNTED) {
      return {
        status: EXITED,
      };
    }

    return null;
  };
  const _proto = Transition.prototype;

  _proto.componentDidMount = function componentDidMount() {
    this.updateStatus(true, this.appearStatus);
  };

  _proto.componentDidUpdate = function componentDidUpdate(prevProps) {
    let nextStatus: string | null = null;

    if (prevProps !== this.props) {
      const { status } = this.state;

      if (this.props.in) {
        if (status !== ENTERING && status !== ENTERED) {
          nextStatus = ENTERING;
        }
      } else {
        if (status === ENTERING || status === ENTERED) {
          nextStatus = EXITING;
        }
      }
    }

    this.updateStatus(false, nextStatus);
  };

  _proto.componentWillUnmount = function componentWillUnmount() {
    this.cancelNextCallback();
  };

  _proto.getTimeouts = function getTimeouts() {
    const { timeout } = this.props;
    let enter, appear;

    const exit = (enter = appear = timeout);

    if (timeout != null && typeof timeout !== 'number') {
      appear = timeout.appear !== undefined ? timeout.appear : timeout.enter;
    }

    return {
      exit,
      enter,
      appear,
    };
  };

  _proto.updateStatus = function updateStatus(mounting, nextStatus) {
    if (mounting === void 0) {
      mounting = false;
    }

    if (nextStatus !== null) {
      // nextStatus will always be ENTERING or EXITING.
      this.cancelNextCallback();

      if (nextStatus === ENTERING) {
        if (this.props.unmountOnExit || this.props.mountOnEnter) {
          const node = this.props.nodeRef
            ? this.props.nodeRef.current
            : ReactDom.findDOMNode(this); // https://github.com/reactjs/react-transition-group/pull/749
          // With unmountOnExit or mountOnEnter, the enter animation should happen at the transition between `exited` and `entering`.
          // To make the animation happen,  we have to separate each rendering and avoid being processed as batched.

          if (node) (0, forceReflow)(node);
        }

        this.performEnter(mounting);
      } else {
        this.performExit();
      }
    } else if (this.props.unmountOnExit && this.state.status === EXITED) {
      this.setState({
        status: UNMOUNTED,
      });
    }
  };

  _proto.performEnter = function performEnter(mounting) {
    const _this2 = this;

    const { enter } = this.props;
    const appearing = this.context ? this.context.isMounting : mounting;

    const _ref2 = this.props.nodeRef
        ? [appearing]
        : [ReactDom.findDOMNode(this), appearing],
      [maybeNode, maybeAppearing] = _ref2;

    const timeouts = this.getTimeouts();
    const enterTimeout = appearing ? timeouts.appear : timeouts.enter; // no enter animation skip right to ENTERED
    // if we are mounting and running this it means appear _must_ be set

    if ((!mounting && !enter) || _config.disabled) {
      this.safeSetState(
        {
          status: ENTERED,
        },
        function () {
          _this2.props.onEntered(maybeNode);
        },
      );
      return;
    }

    this.props.onEnter(maybeNode, maybeAppearing);
    this.safeSetState(
      {
        status: ENTERING,
      },
      function () {
        _this2.props.onEntering(maybeNode, maybeAppearing);

        _this2.onTransitionEnd(enterTimeout, function () {
          _this2.safeSetState(
            {
              status: ENTERED,
            },
            function () {
              _this2.props.onEntered(maybeNode, maybeAppearing);
            },
          );
        });
      },
    );
  };

  _proto.performExit = function performExit() {
    const _this3 = this;

    const { exit } = this.props;
    const timeouts = this.getTimeouts();
    const maybeNode = this.props.nodeRef
      ? undefined
      : ReactDom.findDOMNode(this); // no exit animation skip right to EXITED

    if (!exit || _config.disabled) {
      this.safeSetState(
        {
          status: EXITED,
        },
        function () {
          _this3.props.onExited(maybeNode);
        },
      );
      return;
    }

    this.props.onExit(maybeNode);
    this.safeSetState(
      {
        status: EXITING,
      },
      function () {
        _this3.props.onExiting(maybeNode);

        _this3.onTransitionEnd(timeouts.exit, function () {
          _this3.safeSetState(
            {
              status: EXITED,
            },
            function () {
              _this3.props.onExited(maybeNode);
            },
          );
        });
      },
    );
  };

  _proto.cancelNextCallback = function cancelNextCallback() {
    if (this.nextCallback !== null) {
      this.nextCallback.cancel();
      this.nextCallback = null;
    }
  };

  _proto.safeSetState = function safeSetState(nextState, callback) {
    // This shouldn't be necessary, but there are weird race conditions with
    // setState callbacks and unmounting in testing, so always make sure that
    // we can cancel any pending setState callbacks after we unmount.
    callback = this.setNextCallback(callback);
    this.setState(nextState, callback);
  };

  _proto.setNextCallback = function setNextCallback(callback) {
    const _this4 = this;

    let active = true;

    this.nextCallback = function (event) {
      if (active) {
        active = false;
        _this4.nextCallback = null;
        callback(event);
      }
    };

    this.nextCallback.cancel = function () {
      active = false;
    };

    return this.nextCallback;
  };

  _proto.onTransitionEnd = function onTransitionEnd(timeout, handler) {
    this.setNextCallback(handler);
    const node = this.props.nodeRef
      ? this.props.nodeRef.current
      : ReactDom.findDOMNode(this);
    const doesNotHaveTimeoutOrListener =
      timeout == null && !this.props.addEndListener;

    if (!node || doesNotHaveTimeoutOrListener) {
      setTimeout(this.nextCallback, 0);
      return;
    }

    if (this.props.addEndListener) {
      const _ref3 = this.props.nodeRef
          ? [this.nextCallback]
          : [node, this.nextCallback],
        [maybeNode, maybeNextCallback] = _ref3;

      this.props.addEndListener(maybeNode, maybeNextCallback);
    }

    if (timeout != null) {
      setTimeout(this.nextCallback, timeout);
    }
  };

  _proto.render = function render() {
    const { status } = this.state;

    if (status === UNMOUNTED) {
      return null;
    }

    const _this$props = this.props,
      { children } = _this$props,
      childProps = _objectWithoutPropertiesLoose(_this$props, [
        'children',
        'in',
        'mountOnEnter',
        'unmountOnExit',
        'appear',
        'enter',
        'exit',
        'timeout',
        'addEndListener',
        'onEnter',
        'onEntering',
        'onEntered',
        'onExit',
        'onExiting',
        'onExited',
        'nodeRef',
      ]);

    return (
      /* #__PURE__*/
      // allows for nested Transitions
      React.createElement(
        TransitionGroupContext.Provider,
        {
          value: null,
        },
        typeof children === 'function'
          ? children(status, childProps)
          : React.cloneElement(React.Children.only(children), childProps),
      )
    );
  };

  return Transition;
})(React.Component);

Transition.contextType = TransitionGroupContext;
Transition.propTypes = import.meta.env.DEV
  ? {
      nodeRef: PropTypes.shape({
        current:
          typeof Element === 'undefined'
            ? PropTypes.any
            : function (
                propValue,
                key,
                componentName,
                location,
                propFullName,
                secret,
              ) {
                const value = propValue[key];
                return PropTypes.instanceOf(
                  value && 'ownerDocument' in value
                    ? value.ownerDocument.defaultView.Element
                    : Element,
                )(
                  propValue,
                  key,
                  componentName,
                  location,
                  propFullName,
                  secret,
                );
              },
      }),
      children: PropTypes.oneOfType([
        PropTypes.func.isRequired,
        PropTypes.element.isRequired,
      ]).isRequired,
      in: PropTypes.bool,
      mountOnEnter: PropTypes.bool,
      unmountOnExit: PropTypes.bool,
      appear: PropTypes.bool,
      enter: PropTypes.bool,
      exit: PropTypes.bool,
      timeout: function timeout(props) {
        let pt = _PropTypes.timeoutsShape;
        if (!props.addEndListener) pt = pt.isRequired;

        const _len = arguments.length;
        const args = new Array(_len > 1 ? _len - 1 : 0);

        for (let _key = 1; _key < _len; _key++) {
          args[_key - 1] = arguments[_key];
        }

        return pt.apply(void 0, [props].concat(args));
      },
      addEndListener: PropTypes.func,
      onEnter: PropTypes.func,
      onEntering: PropTypes.func,
      onEntered: PropTypes.func,
      onExit: PropTypes.func,
      onExiting: PropTypes.func,
      onExited: PropTypes.func,
    }
  : {};

function noop() {}

Transition.defaultProps = {
  in: false,
  mountOnEnter: false,
  unmountOnExit: false,
  appear: false,
  enter: true,
  exit: true,
  onEnter: noop,
  onEntering: noop,
  onEntered: noop,
  onExit: noop,
  onExiting: noop,
  onExited: noop,
};
Transition.UNMOUNTED = UNMOUNTED;
Transition.EXITED = EXITED;
Transition.ENTERING = ENTERING;
Transition.ENTERED = ENTERED;
Transition.EXITING = EXITING;
