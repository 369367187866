import { ReportBuilderEagleEyeLegalEntitiesField } from '@/bundles/Shared/features/dashboard/create/ui/LegalEntitiesField';
import { useReportBuilderTemplateContext } from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/reportBuilderTemplateContext';
import type { ReportBuilderEagleEyeTemplateDto } from '@/entities/report/reportBuilder/api/settingsReportBuilderTemplatesGeneratedApi';
import { useReportBuilderEagleEyeTemplateMeta } from '@/entities/report/reportBuilder/eagleEye/lib';
import {
  useReportBuilderTemplateEagleEyeObjectSettingsSchemaFormContext,
  type ReportBuilderTemplateEagleEyeObjectSettingsSchema,
} from '@/entities/report/reportBuilder/lib/reportBuilderTemplateEagleEyeObjectSettingsConfig';
import { ObjectFilterOptionWithCounters } from '@/entities/report/reportBuilder/ui/ObjectFilterOptionWithCounters';
import { ReportBuilderTemplateEagleEyeDashboardDropdown } from '@/entities/report/reportBuilder/ui/ReportBuilderTemplateEagleEyeDashboardDropdown';
import { ReportBuilderTemplateEagleEyeFilterDropdown } from '@/entities/report/reportBuilder/ui/ReportBuilderTemplateEagleEyeFilterDropdown';
import { reportingEnhancedApi } from '@/entities/reporting/api/reportingEnhancedApi';
import { useEagleEyeReportIdParam } from '@/entities/reporting/lib/eagleEye';
import { useReportBuilderTemplateUpdate } from '@/features/report/report/updateTemplate/lib';
import { EagleEyeObjectSelectionItem } from '@/features/report/reportBuilderTemplate/objectSelectionItem/ui/component';
import { xorLegalEntityIds } from '@/shared/lib/legalEntitiy/lib';
import { mapListToIds } from '@/shared/lib/listHelpers';
import { useFormOnSetValue } from '@/shared/lib/reactHookForm/lib';
import { BuilderUI } from '@/shared/ui/BuilderUI';
import { Button } from '@/stories/Button/Button';
import { Field } from '@/stories/Field/Field';
import { intersection } from 'lodash-es';
import { useEffect, useMemo, type ComponentProps } from 'react';
import type { SubmitHandler } from 'react-hook-form';

const ReportingObjectsForm = ({
  submitOptions,
  onSubmit,
}: {
  submitOptions: { isLoading: boolean };
  onSubmit: SubmitHandler<ReportBuilderTemplateEagleEyeObjectSettingsSchema>;
}) => {
  const { dashboardsFilterObjects, filterSetsFilterObjects } =
    useReportBuilderEagleEyeTemplateMeta();

  const methods =
    useReportBuilderTemplateEagleEyeObjectSettingsSchemaFormContext();

  const dashboardIdWatch = methods.watch('copied_from_dashboard_id');
  const filterIdWatch = methods.watch('applied_filter_set_id');

  const onSetValue = useFormOnSetValue(methods);

  const onFilterIdChange = (id: string | undefined) => {
    onSetValue('applied_filter_set_id', id);
  };

  const onDashboardIdChange = (id: string | undefined) => {
    onSetValue('copied_from_dashboard_id', id);
  };

  const handleCopyStructureFromDashboard = (id: string) => {
    const dashboard = dashboardsFilterObjects?.find((d) => d.id === id);
    if (dashboard == null) return;

    onDashboardIdChange(id);
  };

  const handleApplyFilterSet = (id: string) => {
    const filter = filterSetsFilterObjects.find((d) => d.id === id);
    if (filter == null) return;

    onFilterIdChange(id);
  };

  const handleResetFilterSet = () => {
    onFilterIdChange(undefined);
  };

  const handleResetDashboard = () => {
    onDashboardIdChange(undefined);
    onFilterIdChange(undefined);
  };

  // TODO consider moving to a separate hook
  useEffect(() => {
    if (dashboardIdWatch == null && filterIdWatch == null) {
      return;
    }
    if (dashboardIdWatch == null) {
      onFilterIdChange(undefined);
      return;
    }

    const dashboard = dashboardsFilterObjects?.find(
      (d) => d.id === dashboardIdWatch,
    );

    if (dashboard == null) return;

    if (filterIdWatch != null) {
      const filter = filterSetsFilterObjects?.find(
        (d) => d.id === filterIdWatch,
      );

      if (filter == null) return;

      const intersectionAssetIds = intersection(
        dashboard.assetIds,
        filter.assetIds,
      );
      const intersectionSegmentIds = intersection(
        dashboard.segmentIds,
        filter.segmentIds,
      );
      const xorExcludedLegalEntityIds = xorLegalEntityIds(
        dashboard.excludedLegalEntityIds,
        filter.excludedLegalEntityIds,
      );

      onSetValue('asset_ids', intersectionAssetIds);
      onSetValue('segment_ids', intersectionSegmentIds);
      onSetValue('excluded_legal_entity_ids', xorExcludedLegalEntityIds);
      return;
    }

    onSetValue('asset_ids', dashboard.assetIds);
    onSetValue('segment_ids', dashboard.segmentIds);
    onSetValue('excluded_legal_entity_ids', dashboard.excludedLegalEntityIds);
  }, [dashboardIdWatch, filterIdWatch]);

  const dashboardOptions = useMemo(() => {
    return dashboardsFilterObjects.map((d) => ({
      ...d,
      label: d.name,
      value: d.id,
      id: d.id,
    }));
  }, [dashboardsFilterObjects]);

  const filterOptions = useMemo(() => {
    return filterSetsFilterObjects.map((d) => ({
      label: <ObjectFilterOptionWithCounters {...d} />,
      name: d.name,
      value: d.id,
    }));
  }, [filterSetsFilterObjects]);

  const selectedDashboardOption = useMemo(() => {
    const dashboard = dashboardOptions.find(
      (d) => d.value === dashboardIdWatch,
    );
    if (dashboard == null) return null;
    return dashboard;
  }, [dashboardOptions, dashboardIdWatch]);

  const selectedFilterOption = useMemo(() => {
    const filter = filterOptions.find((f) => f.value === filterIdWatch);
    if (filter == null) return null;
    return filter;
  }, [filterOptions, filterIdWatch]);

  const handleSaveObjects = methods.handleSubmit(onSubmit);

  return (
    <BuilderUI.Settings.Main.Basic
      label="Objects"
      actions={
        <div className="flex">
          <Button
            size="xs"
            variant="success"
            disabled={!methods.formState.isDirty || submitOptions.isLoading}
            onClick={handleSaveObjects}
            loading={submitOptions.isLoading}
          >
            Save Updates
          </Button>
        </div>
      }
    >
      <Field labelText="Copy Dashboard structure (optional)">
        <ReportBuilderTemplateEagleEyeDashboardDropdown
          options={dashboardOptions}
          selected={selectedDashboardOption}
          onChange={handleCopyStructureFromDashboard}
          onReset={handleResetDashboard}
          disabled={submitOptions.isLoading}
        />
      </Field>
      <div className="grid grid-cols-2 items-center justify-between gap-2">
        <Field labelText="Apply Filter (optional)">
          <ReportBuilderTemplateEagleEyeFilterDropdown
            options={filterOptions}
            selected={selectedFilterOption}
            onChange={handleApplyFilterSet}
            onReset={handleResetFilterSet}
            disabled={selectedDashboardOption == null}
          />
        </Field>
      </div>
      <div className="grid grid-cols-3 gap-2">
        <EagleEyeObjectSelectionItem type="asset" />
        <EagleEyeObjectSelectionItem type="fund" />
        <EagleEyeObjectSelectionItem type="segment" />
      </div>

      <ReportBuilderEagleEyeLegalEntitiesField />
    </BuilderUI.Settings.Main.Basic>
  );
};

export const ReportBuilderTemplateEagleEyeObjectsForm = () => {
  const templateCtx = useReportBuilderTemplateContext()!;
  const methods =
    useReportBuilderTemplateEagleEyeObjectSettingsSchemaFormContext();

  const [handleUpdateTemplate, updateOptions] =
    useReportBuilderTemplateUpdate();

  const handleSubmit: ComponentProps<
    typeof ReportingObjectsForm
  >['onSubmit'] = async (values) => {
    try {
      const res = await handleUpdateTemplate({
        id: templateCtx.templateId,
        body: {
          ...values,
        },
      });
      if ('error' in res) {
        throw new Error('Failed to update template');
      }
      const data = res.data as ReportBuilderEagleEyeTemplateDto;

      methods.reset({
        asset_ids: mapListToIds(data.assets),
        excluded_legal_entity_ids: mapListToIds(data.excludedLegalEntities),
        segment_ids: mapListToIds(data.segments),
      });
    } catch {
      methods.reset();
    }
  };
  return (
    <ReportingObjectsForm
      onSubmit={handleSubmit}
      submitOptions={updateOptions}
    />
  );
};

export const ReportBuilderReportEagleEyeObjectsForm = () => {
  const reportId = useEagleEyeReportIdParam();
  const methods =
    useReportBuilderTemplateEagleEyeObjectSettingsSchemaFormContext();

  const [handleUpdateReport, updateOptions] =
    reportingEnhancedApi.usePutApiReportBuilderEagleEyeReportsByIdMutation();

  const handleSubmit: ComponentProps<
    typeof ReportingObjectsForm
  >['onSubmit'] = async (values) => {
    try {
      const res = await handleUpdateReport({
        id: reportId,
        body: {
          ...values,
        },
      });
      if ('error' in res) {
        throw new Error('Failed to update template');
      }

      methods.reset(values);
    } catch {
      methods.reset();
    }
  };
  return (
    <ReportingObjectsForm
      onSubmit={handleSubmit}
      submitOptions={updateOptions}
    />
  );
};
