import { type ReportBuilderTemplate } from '@/entities/report/reportBuilder';
import { AvailableTemplatesModal } from '@/entities/report/reportBuilder/ui/AvailableTemplateModal';
import { reportingEnhancedApi } from '@/entities/reporting/api/reportingEnhancedApi';
import type { DialogProps } from '@/shared/lib/hooks/useModal';

export const ReportBuilderGroupTemplatesModal = (
  props: DialogProps<ReportBuilderTemplate>,
) => {
  const { data, isFetching } =
    reportingEnhancedApi.useGetApiReportBuilderTemplatesQuery();

  return (
    <AvailableTemplatesModal
      {...props}
      isFetching={isFetching}
      reportBuilderTemplates={data ?? []}
    />
  );
};
