import { heavyUpdateAsset } from '@/bundles/Assets/actions';
import AssetFormModal from '@/bundles/Assets/components/AssetFormModal';
import { useAppDispatch } from '@/shared/lib/hooks/redux';
import { useModal } from '@/shared/lib/hooks/useModal';
import { ActionColumn } from 'bundles/Assets/components/Assets';
import { IconButton } from 'stories/IconButton/IconButton';
import { IAsset } from 'types/Asset';

type ActionColumnFormatterParams = ActionColumn['formatterParams'];

interface Props extends ActionColumnFormatterParams {
  row: IAsset;
}

function ActionsCell(props: Props) {
  const { row: asset, loadAssets } = props;
  const dispatch = useAppDispatch();
  const { openModal } = useModal();

  const handleEdit = async () => {
    const res = await openModal(AssetFormModal, {
      asset,
    });
    if (res == null) return;
    await dispatch(heavyUpdateAsset(res));
    await loadAssets();
  };

  return (
    <IconButton iconName="edit" onClick={handleEdit} className="bg-white" />
  );
}

export default ActionsCell;
