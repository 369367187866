import * as React from 'react';
import ActionsDropdown from 'bundles/REconcile/components/yearCard/ActionsDropdown';
import IconButton from 'stories/IconButton/IconButton';
import Icon from 'stories/Icon/Icon';
import { cn } from '@/shared/lib/css/cn';
import styles from '@/stories/filterCard/FilterCard.module.scss';

interface Props
  extends Pick<
    React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLDivElement>,
      HTMLDivElement
    >,
    'onMouseOver' | 'onMouseLeave'
  > {
  header: React.ReactNode;
  content: React.ReactNode;
  hoverContent?: React.ReactNode;
  actions?: {
    label: string;
    handler: () => void;
  }[];
  filtered?: boolean;
  filterText?: string;
  onFilter?: () => void;
  className?: string;
  classes?: {
    hoverPart?: string;
    header?: string;
    filter?: string;
    content?: string;
    body?: string;
  };
}

export function FilterCard({
  header,
  content,
  hoverContent,
  filtered,
  onFilter,
  filterText,
  actions,
  classes,
  className,
  ...props
}: Props) {
  return (
    <div
      className={cn(
        styles.filterCard,
        {
          [styles.filterCard_withHoverPart]: hoverContent != null,
        },
        className,
      )}
      {...props}
    >
      <div className={cn(styles.body, classes?.body)}>
        {onFilter && filtered && (
          <div className={cn('label-semibold', styles.filter, classes?.filter)}>
            <Icon iconName="filtersFilled" />
            {filterText}
            <Icon iconName="closeSmall" onClick={onFilter} />
          </div>
        )}
        <div className={cn(styles.header, classes?.header)}>
          {header}
          <div className="flex gap-2">
            {onFilter && !filtered && (
              <IconButton
                onClick={onFilter}
                className="bg-white"
                iconName="filtersFilled"
              />
            )}
            {actions && <ActionsDropdown actions={actions} />}
          </div>
        </div>
        <div className={classes?.content}>{content}</div>
        {hoverContent && (
          <div className={cn(styles.hoverPart, classes?.hoverPart)}>
            {hoverContent}
          </div>
        )}
      </div>
    </div>
  );
}

export default FilterCard;
