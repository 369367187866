import * as React from 'react';
import { ListOption } from 'stories/Checkbox/CheckList';
import { Button } from '@/stories/Button/Button';
import { Dropdown } from '@/stories/Dropdown/Dropdown';
import { DropdownItem } from '@/stories/Dropdown/DropdownItem/DropdownItem';
import { cn } from '@/shared/lib/css/cn';
import { omit } from 'lodash-es';
import { getYears } from 'stories/Calendar/lib';
import dayjs from 'dayjs';

export const PickerDropdown = ({
  options,
  value,
  onChange,
  children,
  buttonProps,
}: React.PropsWithChildren<{
  options: ListOption<number>[];
  value: number;
  onChange: (value: number) => void;
  buttonProps?: React.ComponentProps<typeof Button>;
}>) => {
  return (
    <Dropdown
      className={cn('max-h-[200px] min-w-[100px] overflow-auto')}
      items={
        <>
          {options.map((option) => (
            <DropdownItem
              key={option.value}
              active={value === option.value}
              onClick={() => onChange(option.value)}
            >
              {option.label}
            </DropdownItem>
          ))}
        </>
      }
    >
      <Button
        iconPosition="right"
        iconName="bottom"
        size="xs"
        variant="secondary"
        className={cn(
          'border-0 text-neutral-550 shadow-none',
          buttonProps?.className,
        )}
        {...omit(buttonProps, 'className')}
      >
        {children}
      </Button>
    </Dropdown>
  );
};

export const YearPickerDropdown = ({
  value,
  onChange,
  viewDate,
  maxDate,
  minDate,
}: {
  value: number;
  onChange: (value: number) => void;
  viewDate: Date;
  maxDate?: Date;
  minDate?: Date;
}) => {
  const currentYear = viewDate.getFullYear();
  const years = React.useMemo(
    () =>
      getYears({
        currentYear,
        range: 3,
        maxYear: maxDate?.getFullYear(),
        minYear: minDate?.getFullYear(),
      }),
    [currentYear],
  );
  return (
    <PickerDropdown options={years} value={value} onChange={onChange}>
      {value}
    </PickerDropdown>
  );
};

export const MonthPickerDropdown = ({
  value,
  onChange,
}: {
  value: number;
  onChange: (value: number) => void;
}) => {
  const months = React.useMemo(
    () =>
      dayjs
        .localeData()
        .months()
        .map((value, index) => ({
          value: index,
          label: value,
        })),
    [],
  );

  return (
    <PickerDropdown
      buttonProps={{
        className: 'text-neutral-900',
      }}
      options={months}
      value={value}
      onChange={onChange}
    >
      {months[value].label}
    </PickerDropdown>
  );
};
