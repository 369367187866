import { useAssertedReportBuilderTemplateContext } from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/reportBuilderTemplateContext';
import {
  usePutApiSettingsReportBuilderEagleEyeTemplatesByEagleEyeTemplateIdGroupsAndIdMutation,
  usePutApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndIdMutation,
} from '@/entities/report/reportBuilder/api/settingsReportBuilderTemplatesEnhancedApi';
import type { PutApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndIdApiArg } from '@/entities/report/reportBuilder/api/settingsReportBuilderTemplatesGeneratedApi';
import { useReportingEntityKindContext } from '@/entities/reporting/context/entityKind';

export const useUpdateReportBuilderTemplateGroup = () => {
  const [updateTemplateGroup, createOptions] =
    usePutApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndIdMutation();
  const [updateEagleEyeTemplateGroup, createOptionsEagleEye] =
    usePutApiSettingsReportBuilderEagleEyeTemplatesByEagleEyeTemplateIdGroupsAndIdMutation();
  const templateCtx = useAssertedReportBuilderTemplateContext();
  const templateKind = useReportingEntityKindContext();

  const handleUpdateTemplateGroup = (
    args: Pick<
      PutApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndIdApiArg,
      'id'
    > &
      Pick<
        PutApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndIdApiArg['body'],
        'page_orientation' | 'position' | 'display_header'
      >,
  ) => {
    switch (templateKind) {
      case 'eagle_eye':
        return updateEagleEyeTemplateGroup({
          ...args,
          id: args.id,
          eagleEyeTemplateId: templateCtx.templateId,
          body: {
            position: args.position,
            page_orientation: args.page_orientation,
            display_header: args.display_header,
          },
        });
      default:
        return updateTemplateGroup({
          ...args,
          id: args.id,
          templateId: templateCtx.templateId,
          body: {
            position: args.position,
            page_orientation: args.page_orientation,
            display_header: args.display_header,
          },
        });
    }
  };
  const getOptions = () => {
    switch (templateKind) {
      case 'eagle_eye':
        return createOptionsEagleEye;
      default:
        return createOptions;
    }
  };

  return [handleUpdateTemplateGroup, getOptions()] as const;
};
