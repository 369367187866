import React, { useEffect, useMemo } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { fetchChangeOrderBadges } from 'bundles/Construction/actions/changeOrderEvents';
import ChangeManagementCOFilterCard, {
  TCOFilterCardType,
} from 'bundles/Construction/components/cards/ChangeManagementFilterCard/ChangeManagementCOFilterCard';
import { IPageParams } from 'bundles/Construction/types';
import COLogsInfoCard from 'bundles/Construction/components/cards/COLogsInfoCard/COLogsInfoCard';
import { NavigateFn, useParams } from '@reach/router';
import { cn } from '@/shared/lib/css/cn';
import styles from '@/bundles/Construction/components/ChangeOrder/BadgesBar/BadgesBar.module.scss';
import { useAppSelector } from '@/shared/lib/hooks/redux';
import { AnimationLoader } from 'stories/AnimationLoader/AnimationLoader';

const FILTER_CARDS: TCOFilterCardType[] = [
  'completed',
  'approved',
  'pending',
  'draft',
];
const FILTER_CARDS_PARAMS_MAPPING: Record<
  TCOFilterCardType,
  Partial<IPageParams>
> = {
  completed: {
    statusList: ['COMPLETED'],
  },
  approved: {
    statusList: ['APPROVED'],
  },
  pending: {
    statusList: ['PENDING'],
  },
  draft: {
    statusList: ['DRAFT'],
  },
};

interface Props extends PropsFromRedux {
  pageParams: IPageParams;
  setPageParams: (pageParams: IPageParams) => void;
  navigate: NavigateFn;
  legalEntityCode: string;
}

const BadgesBar = (props: Props) => {
  const { legalEntityCode, pageParams, setPageParams, navigate, badgesInfo } =
    props;

  const params = useParams();
  const asset = useAppSelector((state) => state.currentAsset);

  const filteredCards = useMemo(
    () =>
      Object.keys(FILTER_CARDS_PARAMS_MAPPING).filter(
        (key) =>
          pageParams.statusList === FILTER_CARDS_PARAMS_MAPPING[key].statusList,
      ),
    [pageParams],
  );

  useEffect(() => {
    props.fetchChangeOrderBadges(
      params?.legalEntityCode ||
        asset?.legalEntities[0].code ||
        legalEntityCode,
    );
  }, [params?.legalEntityCode]);

  const handleFilter = (card: TCOFilterCardType) => {
    setPageParams(
      filteredCards.includes(card)
        ? {
            ...pageParams,
            statusList: [],
          }
        : {
            ...pageParams,
            ...FILTER_CARDS_PARAMS_MAPPING[card],
          },
    );
  };

  if (badgesInfo == null) {
    return <AnimationLoader className="static min-h-[100px]" />;
  }

  return (
    <div className={cn(styles.container, 'mb-l mt-m')}>
      <COLogsInfoCard
        currentContractSum={badgesInfo.currentContractSum}
        impact={badgesInfo.timingImpact}
        onImpactClick={(id) => {
          navigate(id.toString());
        }}
      />
      {FILTER_CARDS.map((c) => (
        <ChangeManagementCOFilterCard
          key={c}
          onFilter={() => handleFilter(c)}
          filtered={filteredCards.includes(c)}
          stats={badgesInfo.stats}
          type={c}
        />
      ))}
    </div>
  );
};

const mapStateToProps = ({ changeOrderManagement }) => ({
  badgesInfo: changeOrderManagement.badgesInfo,
});

const dispatchToProps = {
  fetchChangeOrderBadges,
};

const connector = connect(mapStateToProps, dispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(BadgesBar);
