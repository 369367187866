export const OPERATIONAL_MAPPING_GROUP_BY_MAP = {
  erp: {
    label: 'ERP System',
    shorthand: 'ERP',
    id: 'erp',
  },
  pmc: {
    label: 'Property Management Company (PMC)',
    shorthand: 'PMC',
    id: 'pmc',
  },
} as const satisfies Record<
  'pmc' | 'erp',
  {
    id: 'pmc' | 'erp';
    label: string;
    shorthand: string;
  }
>;

export const SRE_UNIT_TYPE_LABEL_FIELD_NOTE =
  'We suggest grouping the Units by floor plan (1x1, 2x2, or 3x2 etc.) for the best results';

export const OPERATIONAL_MAPPING_GROUP_BY_LIST = Object.values(
  OPERATIONAL_MAPPING_GROUP_BY_MAP,
).map((group) => ({
  label: group.label,
  value: group.id,
})) as readonly {
  label: string;
  value: 'pmc' | 'erp';
}[];
