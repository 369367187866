import React, { useState, useEffect } from 'react';
import { Dropdown } from 'stories/Dropdown/Dropdown';
import { DropdownItem } from 'stories/Dropdown/DropdownItem/DropdownItem';
import FormInput from 'bundles/Shared/components/GroupForm/FormItems/FormInput';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import {
  selectMarkForPaymentData,
  setMarkForPaymentData,
} from 'bundles/DrawPackage/slices/ReconcileInvoicesSlice';
import { TextEditor } from '@symmetre-web/text-editor';
import {
  useCreatePaybleTeamContactMutation,
  useGetPayableTeamContactsQuery,
  useGetPaymentEmailTemplateQuery,
} from 'bundles/Construction/api/invoices';
import {
  PayableTeamContact,
  SoftwareProvider,
} from '@/shared/types/reconcile/Invoice';
import { useModal } from '@/shared/lib/hooks/useModal';
import PayableTeamModal from '@/bundles/DrawPackage/InvoicesFlow/Invoices/pay/components/markForPayment/PayableTeamModal';
import styles from 'bundles/DrawPackage/InvoicesFlow/Invoices/invoice/Invoice.module.scss';
import { cn } from '@/shared/lib/css/cn';

const EmailBlock = () => {
  const [searchingPayableTeam, setSearchingPayableTeam] = useState(false);
  const [payableTeam, setPayableTeam] = useState('');

  const { data: payableTeams } = useGetPayableTeamContactsQuery('payableTeam');
  const [createPaybleTeam] = useCreatePaybleTeamContactMutation();

  const markForPaymentData = useAppSelector(selectMarkForPaymentData);
  const dispatch = useAppDispatch();

  const { openModal } = useModal();

  const { data: emailTemplates } = useGetPaymentEmailTemplateQuery(
    {
      contactId: markForPaymentData.payableTeam?.id ?? '',
    },
    {
      skip: !markForPaymentData.payableTeam?.id,
    },
  );

  useEffect(() => {
    if (emailTemplates?.name) {
      dispatch(
        setMarkForPaymentData({
          body: emailTemplates.body,
          subject: emailTemplates.subject,
        }),
      );
    }
  }, [emailTemplates]);

  const choosePayableTeamByList = (team: PayableTeamContact) => {
    setSearchingPayableTeam(false);
    setPayableTeam(team.name);
    dispatch(
      setMarkForPaymentData({
        ...markForPaymentData,
        payableTeam: team,
      }),
    );
  };

  const searchPayableTeam = (teamItem: PayableTeamContact): boolean => {
    if (searchingPayableTeam) {
      return (
        teamItem.email.toLowerCase().includes(payableTeam.toLowerCase()) ||
        teamItem.name.toLowerCase().includes(payableTeam.toLowerCase())
      );
    }
    return true;
  };

  const handleCreatePaybleTeam = async (
    data: Omit<PayableTeamContact, 'id'>,
  ) => {
    const newPayableTeam = await createPaybleTeam({
      body: data,
    });

    if (newPayableTeam.data) {
      setPayableTeam(newPayableTeam.data.name);
      dispatch(
        setMarkForPaymentData({
          ...markForPaymentData,
          payableTeam: newPayableTeam.data,
        }),
      );
      return true;
    }
  };

  const openCreatePayableTeamModal = () => {
    openModal(PayableTeamModal, {
      email: payableTeam,
      softwareProviders: payableTeams?.meta.softwareProviders,
      handleCreatePaybleTeam,
    });
  };

  const generateSoftwareProvider = () =>
    payableTeams?.meta.softwareProviders.find(
      (providerItem) =>
        providerItem.key === markForPaymentData.payableTeam?.softwareProvider,
    ) as SoftwareProvider;

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-2">
        <div className="label-regular text-dark-60">
          Accounts Payable Team Contact <span className="text-red">*</span>
        </div>
        <Dropdown
          maxWidth="32rem"
          classNameContainer="w-full"
          items={
            <div className={cn('h-[350px] overflow-auto', styles.tableBody)}>
              {payableTeams?.items
                .filter((item) => searchPayableTeam(item))
                .map((team) => (
                  <DropdownItem
                    key={team.email}
                    onClick={() => choosePayableTeamByList(team)}
                    className="w-[490px] bg-light"
                  >
                    <div className="text-dark-60">
                      <div className="inline-semibold">{team.name}</div>
                      <div className="secondary-regular">{team.email}</div>
                    </div>
                  </DropdownItem>
                ))}
              {!payableTeams?.items.filter((item) => searchPayableTeam(item))
                .length && (
                <DropdownItem
                  onClick={openCreatePayableTeamModal}
                  className="w-[490px] bg-light"
                >
                  <div className="inline-regular flex gap-1 py-2">
                    <div className="text-light-60">{payableTeam}</div>
                    <div className="text-blue">Add as new</div>
                  </div>
                </DropdownItem>
              )}
            </div>
          }
        >
          <div className="flex-1">
            <FormInput
              fieldName="payableTeam"
              styles={{
                size: 'm',
              }}
              setFormData={(data) => {
                setSearchingPayableTeam(true);
                setPayableTeam(data.payableTeam);
              }}
              value={payableTeam}
              placeholder="Enter or select contact"
            />
          </div>
        </Dropdown>
        {markForPaymentData.payableTeam && (
          <div className="flex items-center gap-1.5 py-1">
            <img
              src={generateSoftwareProvider().logoUrl}
              alt={generateSoftwareProvider().name}
            />
            <div className="secondary-regular">
              {generateSoftwareProvider().name}
            </div>
          </div>
        )}
      </div>
      <FormInput
        title="Email Subject"
        fieldName="subject"
        styles={{
          size: 'm',
        }}
        value={markForPaymentData.subject}
        formData={markForPaymentData}
        setFormData={(data) => {
          dispatch(setMarkForPaymentData(data));
        }}
        placeholder="Enter Invoice Notification"
        required
      />
      <div className="flex flex-col gap-2 text-sm">
        <div className="label-regular text-dark-60">
          Email Body <span className="text-red">*</span>
        </div>
        <TextEditor
          value={markForPaymentData.body}
          onChange={(e: string) => {
            dispatch(
              setMarkForPaymentData({
                body: e,
              }),
            );
          }}
        />
      </div>
    </div>
  );
};

export default EmailBlock;
