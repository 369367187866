import React, { useState } from 'react';
import { CardWrapper } from 'bundles/Shared/components/Wrappers/CardWrapper';
import { Button } from 'stories/Button/Button';
import { Modal } from 'stories/Modals/Modal/Modal';
import { connect } from 'react-redux';
import Select from 'react-select';
import { useForm } from 'react-hook-form';
import { updateDealPathTeam } from '@/bundles/Settings/actions/dealPathSettings';
import CardWithToggle from '@/bundles/Settings/components/DealPath/CardWithToggle';

const DealTeams = (props) => {
  const [selectedTeam, setSelectedTeam] = useState();

  const { setValue, handleSubmit } = useForm({ defaultValues: selectedTeam });

  const submit = async (data) => {
    await props.update(selectedTeam.id, data);
    setSelectedTeam(null);
  };

  return (
    <div>
      <CardWrapper className="bg-light">
        <div className="py-3 px-5">
          <h3>Teams</h3>
        </div>
        <div className="px-5 py-5">
          <div className="flex flex-wrap">
            {props.teams.map((team) => (
              <CardWithToggle
                key={team.id}
                item={team}
                onToggle={() => props.update(team.id, { active: !team.active })}
                onEdit={() => setSelectedTeam(team)}
                footer={() =>
                  team.aliases.length > 0 && (
                    <>
                      <div className="font-12">
                        <span className="dark-60 mr-1">Linked with:</span>
                        <span>
                          {team.userRoles.map((ur) => ur.name).join(', ')}
                        </span>
                      </div>
                    </>
                  )
                }
              />
            ))}
          </div>
        </div>
      </CardWrapper>
      {selectedTeam && (
        <Modal
          header="Assign roles to team"
          toggle={() => setSelectedTeam(null)}
          actions={
            <Button form="dealTeamForm" variant="success" fluid type="submit">
              Submit
            </Button>
          }
          classes={{
            header: 'bg-light',
            body: 'bg-light',
            footer: 'bg-light',
          }}
        >
          <form id="dealTeamForm" onSubmit={handleSubmit(submit)}>
            <Select
              menuPosition="fixed"
              menuShouldBlockScroll
              defaultValue={selectedTeam.userRoles.map(({ name, dpId }) => ({
                value: dpId,
                label: name,
              }))}
              onChange={(values) =>
                setValue(
                  'aliases',
                  values?.map((v) => v.value),
                )
              }
              isMulti
              placeholder="Select Related roles"
              options={props.userRoles.map(({ name, dpId }) => ({
                value: dpId,
                label: name,
              }))}
            />
          </form>
        </Modal>
      )}
    </div>
  );
};

const mapStateToProps = ({ dealPathSettings }) => ({
  teams: dealPathSettings.teams,
  userRoles: dealPathSettings.userRoles,
});

const mapActionCreators = {
  update: updateDealPathTeam,
};

export default connect(mapStateToProps, mapActionCreators)(DealTeams);
