import { useNavigateBack } from '@/shared/lib/hooks/navigation';
import useDebounce from '@/shared/lib/hooks/useDebounce';
import { ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import { BuilderUI } from '@/shared/ui/BuilderUI';
import { GrowDiv } from '@/shared/ui/GrowDiv';
import { useNavigate, type RouteComponentProps } from '@reach/router';
import { plainFetchReplyRecipients } from 'bundles/Settings/actions/customTemplates';
import { useEffect, useState, useMemo } from 'react';
import { AnimationLoader } from 'stories/AnimationLoader/AnimationLoader';
import { Button } from 'stories/Button/Button';
import {
  createForgotPasswordTemplate,
  forgotPasswordTemplatePreviews,
  getForgotPasswordTemplate,
  getForgotPasswordTemplateVariables,
  IForgotPasswordTemplateParams,
  updateForgotPasswordTemplate,
} from '@/bundles/Settings/actions/forgotPasswordTemplates';
import ForgotPasswordTemplateForm from '@/bundles/Settings/components/EmailSettings/forms/ForgotPasswordTemplateForm';
import TemplatePreview from '@/bundles/Settings/components/EmailSettings/TemplatePreview';
import { canSaveTemplate } from '@/bundles/Settings/components/EmailSettings/utils/canSaveTemplate';
import { initForgotPasswordTemplateVars } from '@/bundles/Settings/components/EmailSettings/utils/consts';
import type * as Type from '@/bundles/Settings/components/EmailSettings/editor/types';
import { extractVariables } from '@/bundles/Settings/components/EmailSettings/utils/utils';

interface Props {
  forgotPasswordTemplateId?: number;
}

const ForgotPasswordTemplateEdit = ({
  forgotPasswordTemplateId,
}: RouteComponentProps<Props>) => {
  const navigate = useNavigate();
  const [template, setTemplate] =
    useState<Type.ForgotPasswordTemplateState | null>(null);
  const [variables, setVariables] = useState(initForgotPasswordTemplateVars);
  const [replyRecipients, setReplyRecipients] = useState<Type.ReplyRecipient[]>(
    [],
  );
  const [isPreviewLoading, setIsPreviewLoading] = useState(false);
  const [templatePreview, setTemplatePreview] = useState();
  const [_, setIsDefault] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const { navigateBack } = useNavigateBack({
    fallbackUrl: ROUTES_ROOT.settings.emails.templates.forgotPassword.fullPath,
  });

  const debouncedTemplate = useDebounce(template, 500);

  useEffect(() => {
    const fetch = async () => {
      setIsLoading(true);

      const [templateJson, variablesJson, replyRecipientsJson] =
        await Promise.all([
          forgotPasswordTemplateId
            ? getForgotPasswordTemplate(forgotPasswordTemplateId)
            : null,
          getForgotPasswordTemplateVariables(),
          plainFetchReplyRecipients(),
        ]);

      setReplyRecipients(replyRecipientsJson);

      setTemplate({
        subject: templateJson?.subject,
        greeting: templateJson?.rawGreeting,
        body: templateJson?.rawBody,
        callToAction: templateJson?.rawCallToAction,
        additionalInfo: templateJson?.rawAdditionalInfo,
        replyRecipients: templateJson?.replyRecipients,
      });

      setVariables({
        greeting: variablesJson.greeting,
        body: variablesJson.body,
        callToAction: variablesJson.call_to_action,
        additionalInfo: variablesJson.additional_info,
      });
      setIsDefault(Boolean(templateJson?.default));

      setIsLoading(false);
    };

    fetch();
  }, []);

  const fetchUsedVariables = (t: Type.ForgotPasswordtState) => {
    const greetingVars = extractVariables(t.greeting, variables.greeting);
    const callToActionVars = extractVariables(
      t.callToAction,
      variables.callToAction,
    );
    const bodyVars = extractVariables(t.body, variables.body);
    const additionalInfoVars = extractVariables(
      t.additionalInfo,
      variables.additionalInfo,
    );

    return {
      additionalInfo: additionalInfoVars,
      body: bodyVars,
      callToAction: callToActionVars,
      greeting: greetingVars,
    };
  };

  const usedVariables = useMemo(() => {
    if (template === null) return initForgotPasswordTemplateVars;

    return fetchUsedVariables(template);
  }, [template]);

  useEffect(() => {
    if (template === null) return;

    const fetchPreview = async () => {
      setIsPreviewLoading(true);

      const previewJson = await forgotPasswordTemplatePreviews({
        forgotPasswordTemplateId,
        template,
        usedVariables,
      });

      setTemplatePreview(previewJson.body);
      setIsPreviewLoading(false);
    };
    fetchPreview();
  }, [debouncedTemplate]);

  const handleSubmit = async () => {
    if (template === null) return;

    const params: IForgotPasswordTemplateParams = {
      template,
      forgotPasswordTemplateId,
      usedVariables,
    };
    const forgotPasswordTemplate = forgotPasswordTemplateId
      ? await updateForgotPasswordTemplate(params)
      : await createForgotPasswordTemplate(params);

    if (forgotPasswordTemplate.errors === undefined) {
      navigate(-1);
    }
  };

  const isPreviewReady = () => {
    if (isLoading || isPreviewLoading) return false;

    if (forgotPasswordTemplateId) {
      return debouncedTemplate !== null;
    }

    return true;
  };

  const saveButtonEnabled = canSaveTemplate({
    isLoading,
    template,
    initialFieldsToCheck: ['subject', 'greeting', 'callToAction'],
  });

  const handleNavigateBack = () => {
    navigateBack();
  };

  return (
    <BuilderUI>
      <BuilderUI.Settings>
        <BuilderUI.Settings.Header>
          <BuilderUI.Settings.Header.BackIconButton
            disabled={isLoading}
            onClick={handleNavigateBack}
          />
          <BuilderUI.Settings.Header.TitleInfo title={'Forgot Password'} />
        </BuilderUI.Settings.Header>
        <BuilderUI.Settings.Main>
          <ForgotPasswordTemplateForm
            template={template ?? {}}
            variables={variables}
            replyRecipients={replyRecipients}
            onTemplateChange={(newTemplate) => setTemplate(newTemplate)}
            isLoading={isLoading}
          />
        </BuilderUI.Settings.Main>
        <BuilderUI.Settings.Footer>
          <Button onClick={() => handleNavigateBack()}>Cancel</Button>
          <GrowDiv />
          <Button
            variant="success"
            size="m"
            disabled={!saveButtonEnabled}
            onClick={() => handleSubmit()}
            className="inline-semibold"
          >
            Save
          </Button>
        </BuilderUI.Settings.Footer>
      </BuilderUI.Settings>
      <BuilderUI.PdfPreview>
        {isPreviewReady() ? (
          <TemplatePreview
            subject={debouncedTemplate?.subject ?? 'Forgot Password'}
            templatePreview={templatePreview}
          />
        ) : (
          <AnimationLoader />
        )}
      </BuilderUI.PdfPreview>
    </BuilderUI>
  );
};

export default ForgotPasswordTemplateEdit;
