import { treeDFS } from 'lib/treeHelpers';
import { useGetApiReportFinancialCategoriesTreeQuery } from '@/shared/api/reportFinancialCategoriesApiEnhanced';
import { useMemo } from 'react';
import { FinancialCategory } from '@/entities/finanicalCategory/model';

export const getCategoryPathFromTreeById = <T extends FinancialCategory>(
  id: number,
  tree: T[],
): T[] => treeDFS(tree, (c) => c.id === id) ?? [];

export const useCategoryPathById = <T extends FinancialCategory>(
  id: number | null,
  tree: T[],
) =>
  useMemo<T[]>(
    () => (id ? getCategoryPathFromTreeById(id, tree) : []),
    [tree, id],
  );

export const useCategoryPathFromFinancialCategoriesById = (
  id: number | null,
) => {
  const { data } = useGetApiReportFinancialCategoriesTreeQuery();
  const categories = data?.tree ?? [];
  return useCategoryPathById(id, categories);
};

export const mapCategoryPathToCodes = <T extends FinancialCategory>(
  path: T[],
): string[] => path.map((c) => c.code);
