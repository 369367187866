import {
  ALL_VIEW_PARAM_TYPE,
  type _ObjectableType,
  type ViewParamType,
} from '@/bundles/REport/reducers/financialsSlice';
import { Icon } from '@/stories/Icon/Icon';
import type { IThinTabItem } from '@/stories/Tabs/ThinTabGroup/ThinTabGroup';

export const OBJECTABLE_TYPES_TAB_ITEMS = [
  {
    id: ALL_VIEW_PARAM_TYPE,
    label: 'All',
  },
  {
    id: 'asset',
    label: <Icon iconName="asset" className="header6-regular" />,
    tooltipProps: {
      mainText: 'Assets',
    },
  },
  {
    id: 'fund',
    label: <Icon iconName="funds" className="header6-regular" />,
    tooltipProps: {
      mainText: 'Funds',
    },
  },
  {
    id: 'segment',
    label: <Icon iconName="segments" className="header6-regular" />,
    tooltipProps: {
      mainText: 'Segments',
    },
  },
  {
    id: 'favorite',
    label: <Icon iconName="favoriteFilled" className="header6-regular" />,
    tooltipProps: {
      mainText: 'Favorites',
    },
  },
] as const satisfies readonly IThinTabItem<ViewParamType>[];

export const OBJECTABLE_TYPES = [
  'asset',
  'fund',
  'segment',
] as const satisfies _ObjectableType[];
