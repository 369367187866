import React from 'react';
import InfoSection from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/fundingSources/widgets/InfoSection/InfoSection';
import FundingSourceTable from '@/bundles/REconcile/components/development/home/legalEntitySettingsModal/fundingSources/widgets/Table/FundingSourceTable';

const FundingSourcesPage = () => (
  <div className="flex gap-6">
    <InfoSection />
    <FundingSourceTable />
  </div>
);

export default FundingSourcesPage;
