import React, { useEffect, useState } from 'react';
import { Button } from '@/stories/Button/Button';
import { CurrencyFormatter } from '@/stories/ValueFormatters/CurrencyFormatter';
import { Dropdown } from '@/stories/Dropdown/Dropdown';
import { DropdownItem } from '@/stories/Dropdown/DropdownItem/DropdownItem';
import { HistoryItem } from '@/stories/HistoryItem/HistoryItem';
import { LinkButton } from '@/stories/LinkButton/LinkButton';
import { LoadMoreButton } from '@/stories/LoadMoreButton/LoadMoreButton';
import { formatUnixDate } from '@/shared/lib/formatting/dates';
import { useGetApiPortfolioActivityLogItemsQuery } from '@/shared/api/portfolioEnhancedApi';
import {
  GetApiPortfolioActivityLogItemsApiArg,
  GetApiPortfolioActivityLogItemsApiResponse,
} from '@/shared/api/portfolioGeneratedApi';
import { ListOption } from 'stories/Checkbox/CheckList';
import { uniqBy } from 'lodash-es';
import SkeletonBlock from 'stories/ProjectCard/SkeletonBlock';
import { useModal } from '@/shared/lib/hooks/useModal';
import { FeedMessageModal } from '@/widgets/core/feedMessageModal';
import { PortfolioTransactionDetailsModal } from '@/widgets/portfolio';
import {
  getDistributionKinds,
  getIdForActivityLogItem,
  isFeedMessageActivityLogItem,
  isTransactionActivityLogItem,
} from '@/widgets/portfolio/lib';
import { convertCentsToDollars } from '@/shared/lib/converters';

const Entry = ({
  label,
  children,
}: React.PropsWithChildren<{
  label: string;
}>) => (
  <div className="flex gap-1 text-neutral-550">
    <span className="secondary-regular">{label}: </span>
    {children}
  </div>
);

const TransactionHistoryItem = ({
  item,
  ...props
}: Pick<React.ComponentProps<typeof HistoryItem>, 'lastItem'> & {
  item: GetApiPortfolioActivityLogItemsApiResponse['items'][number];
}) => {
  const { openModal } = useModal();
  const { objectName, amountCents, kinds } =
    item.distribution! ?? item.contribution!;
  const kind = item.distribution ? 'distribution' : 'contribution';
  const id = item.distribution?.id ?? item.contribution?.id;
  const type = item.contribution ? 'Contribution' : 'Distribution';
  return (
    <HistoryItem
      {...props}
      icon={{
        iconName: 'symbolUSD',
        backgroundColor: 'var(--neutral-100)',
      }}
      insertAdjasentJSX={{
        afterBegin: (
          <div className="light-60 secondary-regular">
            {formatUnixDate(item.createdAt as UnixTime, 'MM-DD-YYYY')}
          </div>
        ),
      }}
      label={
        <>
          <span className="secondary-regular">
            New <span className="secondary-semibold">{type}</span> has been
            added in <span className="secondary-semibold">{objectName}</span>
          </span>
          <div className="secondary-regular flex flex-col text-neutral-550">
            <Entry label="Amount">
              <CurrencyFormatter
                classes={{
                  allParts: 'secondary-regular text-neutral-550',
                  value: 'secondary-semibold',
                }}
                value={convertCentsToDollars(amountCents)}
              />
            </Entry>

            {kinds && (
              <Entry label="Kind">
                <span className="secondary-semibold">
                  {getDistributionKinds(item)}
                </span>
              </Entry>
            )}
          </div>
          <LinkButton
            className="label-regular"
            onClick={() =>
              openModal(PortfolioTransactionDetailsModal, {
                id,
                kind,
              })
            }
          >
            See Details
          </LinkButton>
        </>
      }
    />
  );
};

const FeedMessageItem = ({
  item,
  ...props
}: Pick<React.ComponentProps<typeof HistoryItem>, 'lastItem'> & {
  item: GetApiPortfolioActivityLogItemsApiResponse['items'][number];
}) => {
  const { openModal } = useModal();
  const { feedType, createdAt, objectName } = item.feedMessage!;
  return (
    <HistoryItem
      {...props}
      icon={{
        iconName: 'feedMessageAlt',
        backgroundColor: 'var(--neutral-100)',
      }}
      insertAdjasentJSX={{
        afterBegin: (
          <div className="light-60 secondary-regular">
            {formatUnixDate(createdAt as UnixTime, 'MM-DD-YYYY')}
          </div>
        ),
      }}
      label={
        <>
          <span className="secondary-semibold">Feed Message</span> created for{' '}
          <span className="secondary-semibold">{objectName}</span>
          <Entry label="Type">
            <span className="secondary-semibold">{feedType.title}</span>
          </Entry>
          <LinkButton
            className="label-regular"
            onClick={() =>
              openModal(FeedMessageModal, {
                id: item.feedMessage!.id,
              })
            }
          >
            See Details
          </LinkButton>
        </>
      }
    />
  );
};

const TYPE_OPTIONS = [
  {
    value: null,
    label: 'All',
  },
  {
    value: 'transactions',
    label: 'Transactions',
  },
  {
    value: 'feed_messages',
    label: 'Feed Messages',
  },
] as const satisfies readonly ListOption<null | NonNullable<
  GetApiPortfolioActivityLogItemsApiArg['type']
>>[];

export const PortfolioActivityLog = ({
  onTypeChange,
  type,
}: {
  type: GetApiPortfolioActivityLogItemsApiArg['type'] | null;
  onTypeChange: (
    type: GetApiPortfolioActivityLogItemsApiArg['type'] | null | undefined,
  ) => void;
}) => {
  const [currentFeedMessageList, setCurrentFeedMessageList] = useState<
    GetApiPortfolioActivityLogItemsApiResponse['items']
  >([]);
  const [page, setPage] = useState(1);
  const { data, isLoading, isFetching } =
    useGetApiPortfolioActivityLogItemsQuery({
      type: type == null ? undefined : type,
      perPage: 10,
      page,
    });

  useEffect(() => {
    if (data == null) return;

    setCurrentFeedMessageList((prev) =>
      page !== 1
        ? uniqBy([...prev, ...data.items], getIdForActivityLogItem)
        : data.items,
    );
  }, [data]);

  if (isLoading)
    return (
      <div className="flex flex-col gap-3">
        {Array.from({ length: 5 }).map((_, i) => (
          <SkeletonBlock key={i} className="h-[40px] w-full" />
        ))}
      </div>
    );
  if (!data) return null;

  return (
    <div className="flex flex-col gap-3">
      <div className="flex items-center justify-between px-2">
        <span className="secondary-semibold uppercase text-neutral-500">
          Recent Activity
        </span>
        <Dropdown
          items={TYPE_OPTIONS.map((option) => (
            <DropdownItem
              key={option.value}
              onClick={() => onTypeChange(option.value)}
              active={type == option.value}
            >
              {option.label}
            </DropdownItem>
          ))}
        >
          <Button
            size="xs"
            iconPosition="right"
            iconName="bottom"
            variant="secondary"
          >
            {TYPE_OPTIONS.find((option) => option.value == type)?.label}
          </Button>
        </Dropdown>
      </div>
      <div className="flex flex-col">
        {currentFeedMessageList.map((notif, i, arr) => {
          if (isFeedMessageActivityLogItem(notif)) {
            return (
              <FeedMessageItem
                lastItem={i === arr.length - 1}
                key={notif.createdAt}
                item={notif}
              />
            );
          }
          if (isTransactionActivityLogItem(notif)) {
            return (
              <TransactionHistoryItem
                lastItem={i === arr.length - 1}
                key={notif.createdAt}
                item={notif}
              />
            );
          }
          return null;
        })}
        {data && currentFeedMessageList.length < data.meta.totalSize && (
          <LoadMoreButton
            isLoading={isFetching}
            size="m"
            fluid
            onClick={() => setPage((prev) => prev + 1)}
          >
            Load More
          </LoadMoreButton>
        )}
      </div>
    </div>
  );
};
