import { AgGridReactRef } from '@/lib/ag-grid/types';

export const setGroupedColumns = (
  { colIds }: { colIds: string[] },
  { api }: AgGridReactRef,
) => {
  const columnState = api.getColumnState() ?? [];
  api.applyColumnState({
    state: columnState.map((c) => ({
      ...c,
      rowGroup: colIds.includes(c.colId),
      rowGroupIndex: colIds.includes(c.colId) ? c.rowGroupIndex : null,
      hide: colIds.includes(c.colId),
    })),
  });
};
