import { ICellRendererParams } from 'ag-grid-community';
import React from 'react';
import { Icon } from '@/stories/Icon/Icon';
import { cn } from '@/shared/lib/css/cn';
import { BasicAutoGroupCellRenderer } from 'bundles/Shared/components/AgGrid/Table/cellComponents/AutoGroupCellRenderer';
import { IconsId } from '@/types/sre-icons';

export const GroupCellRenderer = (
  params: ICellRendererParams & {
    subLabel?: string;
    icon?: IconsId;
  },
) => {
  const { subLabel, icon } = params;

  if (icon || subLabel) {
    return (
      <BasicAutoGroupCellRenderer {...params}>
        <div className={cn('flex flex-col')}>
          <div className="flex items-center gap-1">
            {icon && <Icon className="text-neutral-450" iconName={icon} />}
            <span className="inline-semibold text-neutral-900">
              {params.value}
            </span>
          </div>
          {subLabel && (
            <p className="secondary-regular pl-5 text-neutral-700">
              {subLabel}
            </p>
          )}
        </div>
      </BasicAutoGroupCellRenderer>
    );
  }
  return (
    <BasicAutoGroupCellRenderer {...params}>
      {params.value}
    </BasicAutoGroupCellRenderer>
  );
};
