import React from 'react';
import { Button } from 'stories/Button/Button';
import { IconButton } from 'stories/IconButton/IconButton';
import * as Type from '@/bundles/Settings/components/EmailSettings/editor/types';
import { RecipientsListPopover } from '@/bundles/Settings/components/EmailSettings/RecipientsListPopover';

interface Props {
  replyRecipients: Type.ReplyRecipient[];
  onSet: () => void;
  onReset: () => void;
}

function ReplyRecipientsSection({ replyRecipients, onReset, onSet }: Props) {
  return (
    <div className="-mx-4 mt-4 flex flex-col items-start justify-start gap-2 border-t border-light-20 px-4 pt-4">
      <span className="label-regular text-dark-60">
        Where should we send replies?
      </span>
      {replyRecipients.length ? (
        <div className="flex items-center gap-2">
          <span className="inline-regular text-light-60">Recipients:</span>
          <RecipientsListPopover users={replyRecipients} onSet={onSet} />
          <IconButton onClick={onSet} variant="white" iconName="edit" />
          <Button variant="secondary" size="xs" onClick={onReset}>
            Reset
          </Button>
        </div>
      ) : (
        <Button
          onClick={onSet}
          size="s"
          variant="secondary"
          iconName="edit"
          className="text-light-90"
          iconClasses="text-light-60"
          iconPosition="right"
        >
          Set Recipients
        </Button>
      )}
    </div>
  );
}

export default ReplyRecipientsSection;
