import React from 'react';
import { cn } from '@/shared/lib/css/cn';
import '@/stories/Tab/tab.css';

interface Props
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  fluid?: boolean;
  equalWidth?: boolean;
  children: React.ReactNode | React.ReactNode[];
}

export const Tab = ({
  className,
  fluid,
  children,
  equalWidth,
  ...props
}: Props) => (
  <div
    className={cn('sre-tabs', className, {
      'sre-tabs_circle': children[0]?.props?.variant === 'circle',
      'sre-tabs_fluid': fluid,
      'sre-tabs_equal-width': equalWidth,
    })}
    {...props}
  >
    {children}
  </div>
);

export default Tab;
