import {
  FOXTROT_SUBDOMAIN,
  GREENSTREET_SUBDOMAIN,
  RUM_SUBDOMAIN,
  SUBTEXT_SUBDOMAIN,
  TANGO_SUBDOMAIN,
  UNIFORM_SUBDOMAIN,
  WHISKEY_SUBDOMAIN,
} from '@/lib/http';
import { currentUserAllowedToSeeReportingPage } from '@/pages/reporting/permissions';
import { useGenericData } from 'bundles/Shared/GenericContext';
import {
  ASSET_PORTAL_PRODUCT_NAME,
  currentUserAllowedTo,
  currentUserIsAdvisor,
  currentUserIsExternal,
  currentUserIsSreAdmin,
  currentUserIsSuperAdmin,
  FUND_PORTAL_PRODUCT_NAME,
  PIPELINE_PRODUCT_NAME,
  REPORT_PRODUCT_NAME,
  TProductNames,
  USER_MANAGEMENT_PRODUCT_NAME,
} from 'lib/permissions';
import { asserts } from 'lib/typeHelpers/assertsType';
import { useMemo } from 'react';

const AVAILABLE_SUBDOMAINS_FOR_RECONCILE_DEVELOPMENT = [
  WHISKEY_SUBDOMAIN,
  RUM_SUBDOMAIN,
  TANGO_SUBDOMAIN,
  FOXTROT_SUBDOMAIN,
  UNIFORM_SUBDOMAIN,
  SUBTEXT_SUBDOMAIN,
  GREENSTREET_SUBDOMAIN,
] as const;

export const useScoreBoardMenuItemPermissions = () => {
  const { genericData } = useGenericData();
  const pipelineVisible = currentUserAllowedTo('view', TProductNames.PIPELINE);
  const reconcileVisible =
    !currentUserIsExternal() &&
    (currentUserAllowedTo('view', TProductNames.CHANGE_MANAGEMENT) ||
      currentUserAllowedTo('view', TProductNames.DEVELOPMENT_BUDGET));
  const returnVisible =
    currentUserIsExternal() &&
    currentUserAllowedTo('view', TProductNames.RETURN);
  const { mainNavigation } = genericData!;

  const scoreboardVisible =
    pipelineVisible ||
    returnVisible ||
    // NOTE: external user always have portfolio page
    mainNavigation.scoreboardsSubItems.length > 0 ||
    currentUserIsExternal();

  return {
    scoreboardVisible,
    pipelineVisible,
    reconcileVisible,
    returnVisible,
  };
};

export const useSettingsMenuItemPermissions = () => {
  const subItemsPermissions = {
    userManagementVisible: currentUserAllowedTo(
      'manage',
      USER_MANAGEMENT_PRODUCT_NAME,
    ),
    coreVisible: currentUserAllowedTo('configure', ASSET_PORTAL_PRODUCT_NAME),
    pipelineVisible: currentUserAllowedTo('configure', PIPELINE_PRODUCT_NAME),
    reportVisible: currentUserAllowedTo('configure', REPORT_PRODUCT_NAME),
    emailVisible: currentUserAllowedTo('manage', USER_MANAGEMENT_PRODUCT_NAME),
    reportingModuleVisible: currentUserAllowedTo('configure', 'report_builder'),
  };

  return {
    ...subItemsPermissions,
    settingsVisible: Object.values(subItemsPermissions).some((value) => value),
  };
};

export const useMenuItemsPermissions = () => {
  const fireStationVisible = useMemo(
    () =>
      currentUserIsSreAdmin() ||
      currentUserIsSuperAdmin() ||
      currentUserAllowedTo('manage', TProductNames.DEVELOPMENT_BUDGET),
    [],
  );

  const { genericData } = useGenericData();
  asserts(genericData, 'Generic data should be loaded before');
  const { currentUser, mainNavigation, impersonating } = genericData;
  const { impersonatableUsers, advisees } = mainNavigation;
  const scoreboardItemPermissions = useScoreBoardMenuItemPermissions();
  const settingsItemPermissions = useSettingsMenuItemPermissions();

  const permissions = useMemo(() => {
    const { settingsVisible } = settingsItemPermissions;
    const documentationVisible = currentUserIsSreAdmin();
    const mirrorModeVisible =
      !impersonating &&
      impersonatableUsers.length > 0 &&
      currentUser.role?.name !== 'Advisor';
    const adviseesVisible = advisees.length > 0 && currentUserIsAdvisor();
    const reportFinancialsVisible = currentUserAllowedTo(
      'view',
      TProductNames.REPORT,
    );
    const reportOperationalVisible = currentUserAllowedTo(
      'view',
      TProductNames.REPORT,
    );

    const reportVisible = reportFinancialsVisible || reportOperationalVisible;
    const operationalBudgetVisible =
      !currentUserIsExternal() &&
      currentUserAllowedTo('view', TProductNames.OPERATIONAL_BUDGET);

    const developmentBudgetVisible =
      !currentUserIsExternal() &&
      (currentUserAllowedTo('view', TProductNames.CHANGE_MANAGEMENT) ||
        currentUserAllowedTo('view', TProductNames.DEVELOPMENT_BUDGET)) &&
      AVAILABLE_SUBDOMAINS_FOR_RECONCILE_DEVELOPMENT.includes(
        genericData.customer.subdomain,
      );
    const reconcileVisible =
      developmentBudgetVisible || operationalBudgetVisible;
    const returnVisible =
      currentUserAllowedTo('view', TProductNames.RETURN) &&
      !currentUserIsExternal();
    const assetsVisible = currentUserAllowedTo(
      'view',
      ASSET_PORTAL_PRODUCT_NAME,
    );
    const fundsVisible = currentUserAllowedTo('view', FUND_PORTAL_PRODUCT_NAME);
    const coreVisible = assetsVisible || fundsVisible;
    const documentsVisible = currentUserAllowedTo(
      'view',
      ASSET_PORTAL_PRODUCT_NAME,
    );
    const adviseesListVisible = currentUserIsAdvisor();

    return {
      fireStationVisible,
      settingsVisible,
      mirrorModeVisible,
      adviseesVisible,
      scoreboardItemPermissions,
      reportVisible,
      reportFinancialsVisible,
      reporting: currentUserAllowedToSeeReportingPage(),
      reportOperationalVisible,
      reconcileVisible,
      operationalBudgetVisible,
      developmentBudgetVisible,
      returnVisible,
      assetsVisible,
      fundsVisible,
      coreVisible,
      documentsVisible,
      adviseesListVisible,
      documentationVisible,
    };
  }, [
    currentUser,
    mainNavigation,
    impersonating,
    impersonatableUsers,
    advisees,
    settingsItemPermissions,
    scoreboardItemPermissions,
  ]);

  return permissions;
};
