import { ICellRendererParams } from 'ag-grid-community';
import { GroupedByTableActions } from 'bundles/Settings/components/REport/GeneralLedgers/Table/useColumnDefs';
import { useModal } from '@/shared/lib/hooks/useModal';
import { IconButton } from 'stories/IconButton/IconButton';
import { Tooltip } from 'stories/Tooltip/Tooltip';

export function ActionsCell({
  data: row,
  actions,
}: ICellRendererParams & { actions: GroupedByTableActions }) {
  const { confirm } = useModal();
  return (
    <div className="flex">
      <Tooltip
        arrowPosition="center"
        mainText={row.excluded ? 'Include' : 'Exclude'}
      >
        <IconButton
          className="self-start"
          onClick={async () => {
            const result = await confirm({
              title: 'Warning',
              subtitle: 'Are you sure?',
            });
            if (result) {
              actions.onExcludeClick(row);
            }
          }}
          data-tip="include-button"
          iconName={row.excluded ? 'eye' : 'eyeSlash'}
        />
      </Tooltip>
    </div>
  );
}
