import { DEFAULT_WIDGET_GROUPING_TYPE } from '@/bundles/Shared/widgets/dashboard/widgets/common/config';
import { getReckonerPeriodByPeriodTypeAndDate } from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/utils';
import type {
  ObjectDashboardWidgetStateMap,
  ReportBuilderTemplateWidgetStateMap,
} from '@/bundles/Shared/widgets/dashboard/widgets/config';
import type { KpiTableWidgetState } from '@/bundles/Shared/widgets/dashboard/widgets/kpiTable';
import type { XYChartWidgetState } from '@/bundles/Shared/widgets/dashboard/widgets/xyChart/widget';
import { LegalEntity } from '@/entities/core/legalEntity';
import type { ReportBuilderTemplateGroupWidgetSectionDto } from '@/entities/report/reportBuilder/api/settingsReportBuilderTemplatesGeneratedApi';
import { formatToDateStringForRequest } from '@/shared/lib/converters';
import type { IAsset } from '@/types/Asset';
import { DEFAULT_LAYOUT_PROPS } from 'bundles/Shared/widgets/dashboard/layout';
import dayjs, { type Dayjs } from 'dayjs';
import { currentUserAllowedTo, TProductNames } from 'lib/permissions';
import { cloneDeep, maxBy, uniqBy } from 'lodash-es';
import { createContext, useContext } from 'react';
import { Layout, utils } from 'react-grid-layout';
import type { UnionToIntersection, ValueOf } from 'type-fest';
import {
  COMPARISON_DASHBOARD_SECTION_TYPE,
  DashboardFilterObjectTypes,
  EagleEyeBoard,
  OBJECT_DASHBOARD_SECTION_TYPE,
  ReportComparisonDashboardSection,
  ReportDashboardLegalEntity,
  ReportDashboardSectionPosition,
  ReportDashboardSectionPositionWithId,
  ReportDashboardType,
  ReportObjectDashboardSection,
  type ObjectDashboardSectionTypes,
  type ReportBuilderTemplateWidgetSectionTypes,
  type ReportObjectDashboardSections,
} from '@/bundles/Shared/entities/dashboard/model/types/types';
import { getDateWithGranularityOffset } from '@/bundles/Shared/entities/dashboard/model/slices/objectSlice';

export const DEFAULT_SECTION_LAYOUT = {
  minW: 1,
  maxW: 2,
  minH: 12,
  maxH: 12,
  w: 1,
  h: 12,
} as const satisfies Omit<ReportDashboardSectionPosition, 'x' | 'y'>;

const getSectionXPositionByIndex = (index: number) =>
  index % DEFAULT_LAYOUT_PROPS.cols!.lg;
const getSectionYPositionByIndex = (index: number) =>
  DEFAULT_SECTION_LAYOUT.h * Math.floor(index / DEFAULT_LAYOUT_PROPS.cols!.lg);

export const getSectionLayoutByIndex = (
  index: number,
): ReportDashboardSectionPosition => ({
  x: getSectionXPositionByIndex(index),
  y: getSectionYPositionByIndex(index),
  ...DEFAULT_SECTION_LAYOUT,
});

export const isObjectDashboardSectionEditable = (
  section: ReportObjectDashboardSection,
): boolean =>
  section.widgetType ===
    OBJECT_DASHBOARD_SECTION_TYPE.HISTORICAL_REVIEW_TABLE ||
  section.widgetType ===
    OBJECT_DASHBOARD_SECTION_TYPE.FINANCIAL_TABLE_SINGLE_DATE ||
  section.widgetType === OBJECT_DASHBOARD_SECTION_TYPE.KPI_CARD ||
  section.widgetType === OBJECT_DASHBOARD_SECTION_TYPE.XY_CHART ||
  section.widgetType ===
    OBJECT_DASHBOARD_SECTION_TYPE.AVERAGE_ACTUAL_RENT_CHART ||
  section.widgetType === OBJECT_DASHBOARD_SECTION_TYPE.UNIT_TYPE_TABLE ||
  section.widgetType === OBJECT_DASHBOARD_SECTION_TYPE.MASTER_UNIT_TABLE;

export const isComparisonDashboardSectionEditable = (
  section: ReportComparisonDashboardSection,
): boolean =>
  section.widgetType ===
    COMPARISON_DASHBOARD_SECTION_TYPE.FINANCIAL_TABLE_SINGLE_DATE ||
  section.widgetType ===
    COMPARISON_DASHBOARD_SECTION_TYPE.FINANCIAL_TABLE_SINGLE_PERIOD;

export const getSectionExpandedLayout = (
  layout: ReportDashboardSectionPosition,
): ReportDashboardSectionPosition => ({
  ...layout,
  w:
    layout.w === DEFAULT_LAYOUT_PROPS.cols!.lg
      ? 1
      : DEFAULT_LAYOUT_PROPS.cols!.lg,
});

export const getLegalEntitiesFromAssets = (
  assets: (Pick<IAsset, 'id' | 'name'> & {
    legalEntities: Pick<LegalEntity, 'id' | 'name' | 'classification'>[];
  })[],
): ReportDashboardLegalEntity[] =>
  uniqBy(
    assets.flatMap((asset) => asset.legalEntities),
    'id',
  );

export const filterIncludedLegalEntities = <L extends Pick<LegalEntity, 'id'>>(
  le: L,
  excludedLegalEnitityIds: L['id'][],
): boolean => {
  return !excludedLegalEnitityIds.includes(le.id);
};

const compactLayout = (layout: Layout[]): Layout[] => {
  // use clone deep to avoid mutation
  return utils.compact(cloneDeep(layout), 'vertical', 2).map((s) => {
    const initialLayoutItem = layout.find((l) => l.i === s.i);
    return {
      ...initialLayoutItem,
      ...s,
    };
  });
};

/**
 * @param layout - the layout of the dashboard
 * @param sections - the sections to add to the layout
 * @param options - the options for the layout
 * @param [options.toTheEnd=true] - if true, the sections will be added to the end of the layout
 * @returns the new layout
 */
export const addSectionsToLayout = (
  layout: ReportDashboardSectionPositionWithId[],
  sections: (Omit<ReportDashboardSectionPositionWithId, 'x' | 'y'> &
    Partial<Pick<ReportDashboardSectionPositionWithId, 'x' | 'y'>>)[],
  {
    toTheEnd = true,
  }: {
    toTheEnd?: boolean;
  } = {},
): ReportDashboardSectionPositionWithId[] => {
  const maxY = maxBy(layout, (item) => item.y)?.y ?? -1;
  const endPosition = toTheEnd
    ? {
        x: 0,
        y: maxY + 1,
      }
    : {};
  // use clone deep to avoid mutation
  // compact util deletes custom properties, so we need to add them back
  return compactLayout([
    ...layout,
    ...sections.map((s) => ({
      ...s,
      ...endPosition,
    })),
  ] as Layout[]) as ReportDashboardSectionPositionWithId[];
};

export const removeSectionFromLayout = (
  layout: ReportDashboardSectionPositionWithId[],
  sectionId: string,
): ReportDashboardSectionPositionWithId[] =>
  compactLayout(
    layout.filter((l) => l.i !== sectionId),
  ) as ReportDashboardSectionPositionWithId[];

export type DashboardContextValue = {
  dashboardId: string;
  dashboardSlug: string;
  dashboardType: FlattenEnum<ReportDashboardType>;
  boardId: string;
  boardSlug: string;
};

export const DashboardContext = createContext<DashboardContextValue>({
  dashboardId: '',
  dashboardSlug: '',
  dashboardType: ReportDashboardType.OBJECT,
  boardId: '',
  boardSlug: '',
});

export const useDashboardContext = () => {
  return useContext(DashboardContext);
};

export const DASHBOARD_OBJECT_TYPES = [
  'asset',
  'fund',
  'segment',
] as const satisfies DashboardFilterObjectTypes[];

export const canConfigureDashboard = () => {
  return currentUserAllowedTo('configure', TProductNames.REPORT);
};

export const getInitialStateForReportChartWidget = (
  widgetSection:
    | ReportObjectDashboardSections
    | ReportBuilderTemplateGroupWidgetSectionDto,
  dashboardDate: string | Dayjs,
) => {
  const { dateTo, dateFrom, granularity } = widgetSection.defaultOptions;

  return {
    dateFrom,
    dateTo: dateTo ?? formatToDateStringForRequest(dashboardDate),
    granularity: granularity ?? 'day',
  } satisfies XYChartWidgetState;
};

export const getInitialStateForReportKpiTableSingleDateWidget = (
  widgetSection:
    | ReportObjectDashboardSections
    | ReportBuilderTemplateGroupWidgetSectionDto,
  date: string | Dayjs,
) => ({
  groupingType:
    widgetSection?.defaultOptions?.groupingType ?? DEFAULT_WIDGET_GROUPING_TYPE,
  date:
    widgetSection?.snapshotContext?.date ??
    widgetSection?.defaultOptions?.date ??
    date,
});
export const getInitialStateForReportKpiTableWidget = (
  widgetSection:
    | ReportObjectDashboardSections
    | ReportBuilderTemplateGroupWidgetSectionDto,
  date: string | Dayjs,
) => {
  const defaultDate =
    widgetSection?.snapshotContext?.date ??
    widgetSection?.defaultOptions?.date ??
    date;
  return {
    groupingType:
      widgetSection?.defaultOptions?.groupingType ??
      DEFAULT_WIDGET_GROUPING_TYPE,
    date: defaultDate,
    period: getReckonerPeriodByPeriodTypeAndDate(
      widgetSection?.defaultOptions?.periodType ?? 't3',
      defaultDate,
    ),
  } satisfies KpiTableWidgetState;
};

export const getUpdatedWidgetStateForReportChartWidget = (
  widgetState:
    | (UnionToIntersection<ValueOf<ObjectDashboardWidgetStateMap>> & {
        type: ObjectDashboardSectionTypes;
      })
    | (UnionToIntersection<ValueOf<ReportBuilderTemplateWidgetStateMap>> & {
        type: ReportBuilderTemplateWidgetSectionTypes;
      }),
  dateTo: string,
) => {
  const granularity = widgetState.granularity ?? 'day';
  return {
    dateFrom: formatToDateStringForRequest(
      getDateWithGranularityOffset(dayjs(dateTo), granularity),
    ),
    dateTo,
    granularity,
  };
};

export const findBoardByIdOrSlug = <
  B extends Pick<EagleEyeBoard, 'id' | 'slug'>,
>(
  boards: B[],
  boardId: string,
) => boards.find((b) => b.id === boardId || b.slug === boardId);
