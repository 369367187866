import React, { FC, ReactNode } from 'react';
import { useAppSelector, useAppDispatch } from '@/shared/lib/hooks/redux';
import {
  changeReconcileActiveAction,
  selectReconcileActiveAction,
} from 'bundles/Construction/reducers/ReconcileSlice';
import { ActionCard } from 'stories/ActionCard/ActionCard';
import { ActionCardHeader } from 'stories/ActionCardHeader/ActionCardHeader';
import { IconsId } from 'types/sre-icons';

interface IAvailableActionsItemProps {
  title: string;
  description: string;
  iconName: IconsId;
  content: ReactNode;
}

const AvailableActionsItem: FC<IAvailableActionsItemProps> = ({
  title,
  description,
  iconName,
  content,
}) => {
  const activeAction = useAppSelector(selectReconcileActiveAction);
  const dispatch = useAppDispatch();

  return (
    <div className="mb-s">
      {activeAction === title ? (
        <div className="available-action">
          <div className="available-action__header available-action__line">
            <ActionCardHeader
              title={title}
              description={description}
              icon={iconName}
              onClick={() => dispatch(changeReconcileActiveAction(null))}
            />
          </div>
          {content}
        </div>
      ) : (
        <ActionCard
          title={title}
          description={description}
          icon={iconName}
          onClick={() => dispatch(changeReconcileActiveAction(title))}
        />
      )}
    </div>
  );
};

export default AvailableActionsItem;
