import { GetApiSettingsReportThirdPartyExtractionsConnectionsApiArg } from '@/shared/api/settingsReportConnectionsGeneratedApi';
import TablePagination from '@/bundles/Shared/components/Table/pagination/TablePagination';
import { useModal } from '@/shared/lib/hooks/useModal';
import { useGetApiSettingsReportThirdPartyExtractionsConnectionsQuery } from '@/shared/api/settingsReportConnectionsEnhancedApi';
import ConnectionsTable from 'bundles/Settings/components/REport/Connections/widgets/ConnectionsTable';
import ConnectionsTiles from 'bundles/Settings/components/REport/Connections/widgets/ConnectionsTiles';
import ReportSettingsScreenLayout from 'bundles/Settings/shared/ReportSettingsScreenLayout';
import VerticalSeparator from 'bundles/Shared/components/VerticalSeparator/VerticalSeparator';
import { PageParamsProvider } from 'bundles/Shared/components/pageParams';
import ConnectionStatusFilter from 'bundles/Shared/entities/connection/ui/ConnectionStatusFilter';
import { ConnectionCrudModal } from 'bundles/Shared/features/connection/crud';
import { useMemo, useState } from 'react';
import { Button } from 'stories/Button/Button';
import { Icon } from 'stories/Icon/Icon';
import { ThinTabGroup } from 'stories/Tabs/ThinTabGroup/ThinTabGroup';

enum DisplayViewType {
  TILES = 'tiles',
  TABLE = 'table',
}

const TABS: {
  iconName: 'tableView' | 'tileView';
  value: FlattenEnum<DisplayViewType>;
}[] = [
  {
    iconName: 'tileView',
    value: DisplayViewType.TILES,
  },
  {
    iconName: 'tableView',
    value: DisplayViewType.TABLE,
  },
];

export const SettingsReportConnectionsPage = () => {
  const [pageParams, setPageParams] =
    useState<GetApiSettingsReportThirdPartyExtractionsConnectionsApiArg>({
      active: 'true',
    });
  const [displayViewType, setDisplayViewType] = useState<
    FlattenEnum<DisplayViewType>
  >(DisplayViewType.TABLE);

  const { openModal } = useModal();

  const {
    data: connections,
    isLoading,
    isFetching,
  } = useGetApiSettingsReportThirdPartyExtractionsConnectionsQuery({
    ...pageParams,
  });

  const openConnectionModal = () => {
    openModal(ConnectionCrudModal, {});
  };

  const onChangeFilter = (
    activeStatus: GetApiSettingsReportThirdPartyExtractionsConnectionsApiArg['active'],
  ) => {
    setPageParams({
      ...(activeStatus
        ? {
            active: activeStatus,
          }
        : {}),
    });
  };

  const resolvedJSX = useMemo(() => {
    if (displayViewType === DisplayViewType.TILES) {
      if (isFetching) {
        return <ConnectionsTiles.Fetching />;
      }
      if (isLoading) {
        return <ConnectionsTiles.Loading />;
      }
      return <ConnectionsTiles />;
    }

    if (displayViewType === DisplayViewType.TABLE) {
      if (isFetching) {
        return <ConnectionsTable.Fetching />;
      }
      if (isLoading) {
        return <ConnectionsTable.Loading />;
      }
      return <ConnectionsTable />;
    }

    return null;
  }, [displayViewType, isLoading, isFetching]);

  return (
    <ReportSettingsScreenLayout.Content>
      <PageParamsProvider pageParams={pageParams} setPageParams={setPageParams}>
        <div className="flex items-center justify-between">
          {connections?.companies.length && (
            <TablePagination count={connections.companies.length} word="item" />
          )}
          <div className="grow" />
          <div className="flex items-center gap-4">
            <ConnectionStatusFilter onChange={onChangeFilter} />
            <div className="flex items-center gap-2">
              <Button
                variant="primary"
                iconName="addSmall"
                onClick={openConnectionModal}
                size="s"
              >
                Create Connection
              </Button>
              <VerticalSeparator />
              <ThinTabGroup
                selectedItem={displayViewType}
                onSelectedItemChange={(newItem) =>
                  setDisplayViewType(newItem.id)
                }
                items={TABS.map((tab) => ({
                  label: <Icon className="flex" iconName={tab.iconName} />,
                  id: tab.value,
                }))}
              />
            </div>
          </div>
        </div>
        {resolvedJSX}
      </PageParamsProvider>
    </ReportSettingsScreenLayout.Content>
  );
};
