import { useReportBuilderTemplateContext } from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/reportBuilderTemplateContext';
import {
  useDeleteApiSettingsReportBuilderEagleEyeTemplatesByEagleEyeTemplateIdGroupsAndGroupIdWidgetSectionsIdMutation,
  useDeleteApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndGroupIdWidgetSectionsIdMutation,
} from '@/entities/report/reportBuilder/api/settingsReportBuilderTemplatesEnhancedApi';
import type { ReportBuilderTemplateDto } from '@/entities/report/reportBuilder/api/settingsReportBuilderTemplatesGeneratedApi';
import { useReportingEntityKindContext } from '@/entities/reporting/context/entityKind';
import { useModal } from '@/shared/lib/hooks/useModal';

export const useDeleteReportBuilderTemplateWidget = () => {
  const [deleteTemplateWidget, deleteOptions] =
    useDeleteApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndGroupIdWidgetSectionsIdMutation();
  const [deleteEagleEyeTemplateWidget, deleteEagleEyeOptions] =
    useDeleteApiSettingsReportBuilderEagleEyeTemplatesByEagleEyeTemplateIdGroupsAndGroupIdWidgetSectionsIdMutation();
  const { confirm } = useModal();
  const templateCtx = useReportBuilderTemplateContext()!;
  const templateKind = useReportingEntityKindContext();

  const handleDeleteWidget = async (args: {
    widgetId: ReportBuilderTemplateDto['groups'][number]['widgetSections'][number]['id'];
    groupId: ReportBuilderTemplateDto['groups'][number]['id'];
  }) => {
    const bool = await confirm({
      title: 'Are you sure you want to delete a widget from this template',
    });
    if (!bool) return;

    if (templateKind === 'eagle_eye') {
      deleteEagleEyeTemplateWidget({
        id: args.widgetId,
        groupId: args.groupId,
        eagleEyeTemplateId: templateCtx.templateId,
      });
      return;
    }

    deleteTemplateWidget({
      id: args.widgetId,
      groupId: args.groupId,
      templateId: templateCtx.templateId,
    });
  };
  const isLoading = deleteEagleEyeOptions.isLoading || deleteOptions.isLoading;

  return [
    handleDeleteWidget,
    {
      isLoading,
    },
  ] as const;
};
