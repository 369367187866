import { emptySplitApi as api } from '@/app/stores/api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    postApiSettingsReportThresholdsByThresholdKindImports: build.mutation<
      PostApiSettingsReportThresholdsByThresholdKindImportsApiResponse,
      PostApiSettingsReportThresholdsByThresholdKindImportsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/report/thresholds/${queryArg.thresholdKind}/imports`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    getApiSettingsReportThresholdsByThresholdKindLegalEntityOverrides:
      build.query<
        GetApiSettingsReportThresholdsByThresholdKindLegalEntityOverridesApiResponse,
        GetApiSettingsReportThresholdsByThresholdKindLegalEntityOverridesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/settings/report/thresholds/${queryArg.thresholdKind}/legal_entity_overrides`,
          params: { search: queryArg.search },
        }),
      }),
    postApiSettingsReportThresholdsByThresholdKindLegalEntityOverrides:
      build.mutation<
        PostApiSettingsReportThresholdsByThresholdKindLegalEntityOverridesApiResponse,
        PostApiSettingsReportThresholdsByThresholdKindLegalEntityOverridesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/settings/report/thresholds/${queryArg.thresholdKind}/legal_entity_overrides`,
          method: 'POST',
          body: queryArg.body,
          params: { search: queryArg.search },
        }),
      }),
    putApiSettingsReportThresholdsByThresholdKindLegalEntityOverridesAndId:
      build.mutation<
        PutApiSettingsReportThresholdsByThresholdKindLegalEntityOverridesAndIdApiResponse,
        PutApiSettingsReportThresholdsByThresholdKindLegalEntityOverridesAndIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/settings/report/thresholds/${queryArg.thresholdKind}/legal_entity_overrides/${queryArg.id}`,
          method: 'PUT',
          body: queryArg.body,
        }),
      }),
    deleteApiSettingsReportThresholdsByThresholdKindLegalEntityOverridesAndId:
      build.mutation<
        DeleteApiSettingsReportThresholdsByThresholdKindLegalEntityOverridesAndIdApiResponse,
        DeleteApiSettingsReportThresholdsByThresholdKindLegalEntityOverridesAndIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/settings/report/thresholds/${queryArg.thresholdKind}/legal_entity_overrides/${queryArg.id}`,
          method: 'DELETE',
        }),
      }),
    getApiSettingsReportThresholds: build.query<
      GetApiSettingsReportThresholdsApiResponse,
      GetApiSettingsReportThresholdsApiArg
    >({
      query: () => ({ url: `/api/settings/report/thresholds` }),
    }),
    getApiSettingsReportThresholdsByKindXlsx: build.query<
      GetApiSettingsReportThresholdsByKindXlsxApiResponse,
      GetApiSettingsReportThresholdsByKindXlsxApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/report/thresholds/${queryArg.kind}.xlsx`,
      }),
    }),
    putApiSettingsReportThresholdsByKind: build.mutation<
      PutApiSettingsReportThresholdsByKindApiResponse,
      PutApiSettingsReportThresholdsByKindApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/report/thresholds/${queryArg.kind}`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    deleteApiSettingsReportThresholdsByKind: build.mutation<
      DeleteApiSettingsReportThresholdsByKindApiResponse,
      DeleteApiSettingsReportThresholdsByKindApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/report/thresholds/${queryArg.kind}`,
        method: 'DELETE',
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as settingsReportThresholdsApi };
export type PostApiSettingsReportThresholdsByThresholdKindImportsApiResponse =
  /** status 200 success */ ThresholdDto;
export type PostApiSettingsReportThresholdsByThresholdKindImportsApiArg = {
  thresholdKind: string;
  body: {
    file: Blob;
  };
};
export type GetApiSettingsReportThresholdsByThresholdKindLegalEntityOverridesApiResponse =
  /** status 200 successful */ {
    items?: ThresholdDto2[];
  };
export type GetApiSettingsReportThresholdsByThresholdKindLegalEntityOverridesApiArg =
  {
    /** threshold_kind */
    thresholdKind: string;
    /** Search by legal entity name */
    search?: string;
  };
export type PostApiSettingsReportThresholdsByThresholdKindLegalEntityOverridesApiResponse =
  /** status 200 successful */ {
    items?: ThresholdDto2[];
  };
export type PostApiSettingsReportThresholdsByThresholdKindLegalEntityOverridesApiArg =
  {
    /** threshold_kind */
    thresholdKind: string;
    /** Search by legal entity name */
    search?: string;
    body: {
      legal_entity_overrides?: {
        legal_entity_id: string;
        value: number;
      }[];
    };
  };
export type PutApiSettingsReportThresholdsByThresholdKindLegalEntityOverridesAndIdApiResponse =
  /** status 200 successful */ {
    example?: ThresholdDto2;
  };
export type PutApiSettingsReportThresholdsByThresholdKindLegalEntityOverridesAndIdApiArg =
  {
    /** threshold_kind */
    thresholdKind: string;
    /** id */
    id: string;
    body: {
      value: number;
    };
  };
export type DeleteApiSettingsReportThresholdsByThresholdKindLegalEntityOverridesAndIdApiResponse =
  /** status 200 successful */ {
    id?: string;
  };
export type DeleteApiSettingsReportThresholdsByThresholdKindLegalEntityOverridesAndIdApiArg =
  {
    /** threshold_kind */
    thresholdKind: string;
    /** id */
    id: string;
  };
export type GetApiSettingsReportThresholdsApiResponse =
  /** status 200 success */ {
    items?: ThresholdDto[];
  };
export type GetApiSettingsReportThresholdsApiArg = void;
export type GetApiSettingsReportThresholdsByKindXlsxApiResponse = unknown;
export type GetApiSettingsReportThresholdsByKindXlsxApiArg = {
  kind: string;
};
export type PutApiSettingsReportThresholdsByKindApiResponse =
  /** status 200 success */ ThresholdDto;
export type PutApiSettingsReportThresholdsByKindApiArg = {
  kind: string;
  body: {
    global_value: number;
  };
};
export type DeleteApiSettingsReportThresholdsByKindApiResponse =
  /** status 200 success */ ThresholdDto;
export type DeleteApiSettingsReportThresholdsByKindApiArg = {
  kind: string;
};
export type ThresholdDto = {
  kind: string;
  title: string;
  description: string;
  allowedOverrides: boolean;
  valueType: 'number' | 'date' | 'currency';
  globalValue: number | null;
};
export type ThresholdDto2 = {
  id: string;
  legalEntityId: string;
  valueType: 'number' | 'date' | 'currency';
  value: number | null;
};
