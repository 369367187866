import { Field } from '@/stories/Field/Field';
import { InputNumberController } from 'bundles/Shared/components/GroupForm/FormItems/InputNumber';
import { SharedSelectController } from 'bundles/Shared/components/GroupForm/FormItems/new/SharedSelect';
import {
  PERIOD_TYPE_OPTIONS,
  PeriodTypeForm,
  TIME_PERIOD_TYPE_OPTIONS,
  TimePeriodType,
  TRAILING_PERIOD_TYPES,
  TrailingPeriodType,
} from 'bundles/Shared/widgets/dashboard/widgets/common/config';
import { Control, useWatch } from 'react-hook-form';
import { FieldPath } from 'react-hook-form/dist/types/path/eager';
import { RadioGroupController } from 'stories/RadioButton/RadioGroup';

interface Props<
  TFieldValues extends Record<string, unknown> = Record<string, unknown>,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> {
  control: Control<TFieldValues>;
  name: TName;
  availableTrailingPeriodTypes?: TrailingPeriodType[];
  availableTimePeriodTypes?: TimePeriodType[];
  disabled?: boolean;
}

export function PeriodTypeField<
  TFieldValues extends Record<string, unknown> = Record<string, unknown>,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  availableTrailingPeriodTypes,
  availableTimePeriodTypes,
  control,
  name,
  disabled,
}: Props<TFieldValues, TName>) {
  const getFieldName = (fieldName: FieldPath<PeriodTypeForm>) => {
    return `${name}.${fieldName}` as FieldPath<TFieldValues>;
  };

  const periodType = useWatch({
    control,
    name: getFieldName('periodType'),
  });

  const trailingPeriodTypeOptions = TIME_PERIOD_TYPE_OPTIONS.filter((o) =>
    TRAILING_PERIOD_TYPES.filter(
      (t) =>
        availableTrailingPeriodTypes == null ||
        availableTrailingPeriodTypes?.includes(t),
    ).includes(o.value),
  );

  const timePeriodTypeOptions = TIME_PERIOD_TYPE_OPTIONS.filter(
    (o) =>
      availableTimePeriodTypes == null ||
      availableTimePeriodTypes?.includes(o.value),
  );

  return (
    <>
      <Field labelText="Type" required>
        <RadioGroupController
          control={control}
          name={getFieldName('periodType')}
          options={PERIOD_TYPE_OPTIONS}
          getItemProps={() => ({
            disabled,
          })}
        />
      </Field>
      {periodType === 'time' ? (
        <SharedSelectController
          options={timePeriodTypeOptions}
          control={control}
          name={getFieldName('value')}
          menuPortalTarget={document.body}
          disabled={disabled}
        />
      ) : (
        <div className="grid w-full grid-cols-2 gap-2">
          <Field required labelText="Trailing Period">
            <SharedSelectController
              control={control}
              options={trailingPeriodTypeOptions}
              name={getFieldName('value.type')}
              menuPortalTarget={document.body}
              disabled={disabled}
            />
          </Field>
          <Field required labelText="Trailing Amount">
            <InputNumberController
              control={control}
              name={getFieldName('value.amount')}
              size="m"
              disabled={disabled}
            />
          </Field>
        </div>
      )}
    </>
  );
}
