import React from 'react';
import { cn } from '@/shared/lib/css/cn';
import PropTypes from 'prop-types';

const FeedMessageFormStep = ({
  stepNumber,
  isActive,
  isWarning,
  borderless,
}) => (
  <div className={
    cn(
      'vertical-step-container mr-4',
      { 'vertical-step-container_borderless': borderless },
    )
  }
  >
    <div
      className={cn(
        'vertical-step',
        {
          'vertical-step_active': isActive,
          'vertical-step_warning': isWarning,
        },
      )}
    >
      {stepNumber}
    </div>
  </div>
);

FeedMessageFormStep.propTypes = {
  stepNumber: PropTypes.oneOf([PropTypes.string, PropTypes.number]).isRequired,
  isActive: PropTypes.bool,
  isWarning: PropTypes.bool,
  borderless: PropTypes.bool,
};

FeedMessageFormStep.defaultProps = {
  isActive: false,
  isWarning: false,
  borderless: false,
};

export default FeedMessageFormStep;
