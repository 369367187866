import React, { FC } from 'react';
import { IconButton } from 'stories/IconButton/IconButton';
import { IconsId } from 'types/sre-icons';

interface IFormFilesActions {
  onViewItem: () => void;
  disabled?: boolean;
  onRemoveItem?: () => void;
}

export const FormFilesActions: FC<IFormFilesActions> = ({
  onRemoveItem,
  onViewItem,
  disabled,
}) => {
  const buttons: { icon: IconsId; handleFunc: VoidFunction }[] = [
    {
      icon: 'eye',
      handleFunc: onViewItem,
    },
  ];
  if (onRemoveItem) {
    buttons.push({
      icon: 'closeSmall',
      handleFunc: onRemoveItem,
    });
  }
  return (
    <div className="flex items-center">
      {buttons.map((btn, idx) => (
        <IconButton
          iconName={btn.icon}
          className="ml-s"
          key={`btn${idx}`}
          onClick={btn.handleFunc}
          disabled={disabled}
        />
      ))}
    </div>
  );
};
