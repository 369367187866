import React, { FC, SetStateAction, useEffect, useMemo } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { IChangeOrderEvent } from 'bundles/Construction/types';
import { formatAmount } from '@/shared/lib/formatting/number';
import { Modal } from 'stories/Modals/Modal/Modal';
import { Button } from 'stories/Button/Button';
import SelectCO from '@/bundles/Construction/components/ChangeOrder/FormComponents/SelectCO';
import { getChangeEventForInclusionCO } from '@/bundles/Construction/actions/changeOrders';

interface IIncludedCEModalProps extends PropsFromRedux {
  setShowModalEdit: SetStateAction<boolean>;
  saveUpdates: () => void;
  changeOrderEvents: IChangeOrderEvent[];
  changeOrdersInclusion: IChangeOrderEvent[];
  formData: {
    changeOrderEventIds: IChangeOrderEvent[];
    value: number;
  };
  setFormData: SetStateAction<IChangeOrderEvent>;
  legalEntityCode: string;
}

const IncludedCEModal: FC<IIncludedCEModalProps> = ({
  setShowModalEdit,
  saveUpdates,
  changeOrderEvents,
  changeOrdersInclusion = [],
  formData,
  setFormData,
  getChangeEvent,
  legalEntityCode,
}) => {
  const COE = useMemo(
    () => [...changeOrderEvents, ...changeOrdersInclusion],
    [changeOrdersInclusion],
  );
  useEffect(() => {
    getChangeEvent(legalEntityCode);
  }, []);
  return (
    <Modal
      header="Included Change Events"
      size="lg"
      classes={{
        body: 'reconcile-common-modal-create',
      }}
      maxHeight
      toggle={setShowModalEdit}
      additionalActions={
        <div className="flex justify-between w-full">
          <Button variant="secondary" onClick={() => setShowModalEdit(false)}>
            Cancel
          </Button>
          <Button
            variant="success"
            onClick={saveUpdates}
            disabled={!formData?.changeOrderEvents?.length}
          >
            Save Updates
          </Button>
        </div>
      }
      bodyPadding="0"
    >
      <div className="table-included-co">
        <SelectCO
          COE={COE}
          selectable
          formData={formData}
          setFormData={setFormData}
        />
      </div>
      <div className="secondary-regular px-l pb-l text-right">
        Total: <span className="dark-60">{formatAmount(formData?.value)}</span>
      </div>
    </Modal>
  );
};

const mapStateToProps = ({
  changeOrderManagement: { changeOrdersInclusion },
}) => ({
  changeOrdersInclusion,
});

const mapActionCreators = {
  getChangeEvent: getChangeEventForInclusionCO,
};

const connector = connect(mapStateToProps, mapActionCreators);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(IncludedCEModal);
