import React, { ComponentProps, FC } from 'react';
import { IconButton } from '@/stories/IconButton/IconButton';
import { Button } from '@/stories/Button/Button';
import { cn } from '@/shared/lib/css/cn';
import classes from '@/stories/ActionCardHeader/action-card-header.module.scss';
import { IconsId } from 'types/sre-icons';

interface IActionCardHeaderProps {
  title: string;
  description: string;
  icon?: IconsId;
  variant?: ComponentProps<typeof Button>['variant'];
  onClick: () => void;
}

export const ActionCardHeader: FC<IActionCardHeaderProps> = ({
  title,
  description,
  icon,
  variant,
  onClick,
}) => {
  return (
    <div className={classes['sre-action-card-header']}>
      <div className={classes['sre-action-card-header__body']}>
        <IconButton
          size="l"
          iconName={icon ?? 'percent'}
          variant={variant ?? 'primary'}
          className={classes['sre-action-card-header__icon']}
          offHoverStyles
        />
        <div>
          <div
            className={cn(
              'inline-semibold',
              classes['sre-action-card-header__title'],
            )}
          >
            {title}
          </div>
          <div
            className={cn(
              'secondary-regular',
              classes['sre-action-card-header__description'],
            )}
          >
            {description}
          </div>
        </div>
      </div>
      <IconButton
        iconName="closeSmall"
        onClick={onClick}
        className={classes['sre-action-card-header__closeIcon']}
      />
    </div>
  );
};
