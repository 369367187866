import React, {
  ComponentProps,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { AnimationLoader } from 'stories/AnimationLoader/AnimationLoader';
import { SearchInput } from 'stories/FormControls/Inputs/SearchInput/SearchInput';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { cn } from '@/shared/lib/css/cn';
import { debounce, sortBy } from 'lodash-es';
import { includesInLowerCase } from '@/shared/lib/listHelpers';
import type { BaseId, ListOption } from '@/stories/Checkbox/CheckList';

interface Props<T extends ListOption<BaseId>> {
  items: T[];
  onSelectedItemChange: (item: T) => void;
  getMainFieldTitle: (item: T) => ReactNode;
  sortBySelected?: boolean;
  getSecondaryFieldTitle?: (item: T) => string;
  maxHeight?: number | string;
  mask?: string;
  selectedItems?: T[];
  searchInputProps?: Pick<
    ComponentProps<typeof SearchInput>,
    'placeholder' | 'size'
  >;
  classes?: {
    itemTitle?: string;
    item?: string;
  };
  className?: string;
  loading?: boolean;
  query?: string;
  onQueryChange?: (text: string) => void;
  minSearchableOptions?: number;
}

const ItemFilterList = <T extends ListOption<BaseId>>(props: Props<T>) => {
  const {
    items,
    selectedItems,
    onSelectedItemChange,
    sortBySelected,
    maxHeight,
    mask = 'items',
    getMainFieldTitle,
    getSecondaryFieldTitle,
    searchInputProps,
    classes,
    className,
    loading,
    query = '',
    onQueryChange,
    minSearchableOptions,
  } = props;
  const [internalQuery, setInternalQuery] = useState('');
  const filter = onQueryChange ? query : internalQuery;
  const debouncedChangeHandler = useCallback(
    debounce((text) => onQueryChange?.(text), 500),
    [],
  );

  const handleFilterTextChange = (text: string) => {
    if (onQueryChange) {
      debouncedChangeHandler(text);
    } else {
      setInternalQuery(text);
    }
  };
  const isItemSelected = (item: T) => {
    return selectedItems?.some((selectedItem) => {
      const isId = 'id' in selectedItem && 'id' in item;

      return isId
        ? selectedItem.id === item.id
        : selectedItem.value === item.value;
    });
  };

  const filteredItems = useMemo(() => {
    const filtered =
      items?.filter(
        (item) =>
          includesInLowerCase(String(getMainFieldTitle(item)), filter) ||
          includesInLowerCase(getSecondaryFieldTitle?.(item) ?? '', filter),
      ) ?? [];

    const sorted = sortBy(filtered, (item) => {
      if (sortBySelected) {
        return isItemSelected(item) ? 0 : 1;
      }
      return 0;
    });

    return sorted;
  }, [filter, items, selectedItems, sortBySelected]);

  const isSearchable =
    !minSearchableOptions || items.length >= minSearchableOptions;

  return (
    <div
      style={{ maxHeight }}
      className={cn('radius008 flex flex-col overflow-hidden', className)}
    >
      {isSearchable && (
        <SearchInput
          onChange={(e) => handleFilterTextChange(e.target.value)}
          placeholder="Search..."
          className="m-2"
          {...searchInputProps}
          onReset={() => handleFilterTextChange('')}
        />
      )}

      {loading ? (
        <div style={{ minHeight: maxHeight ?? '20rem', minWidth: '17.5rem' }}>
          <AnimationLoader />
        </div>
      ) : (
        <OverlayScrollbarsComponent role="menu">
          {filteredItems.map((item, index) => (
            <div
              role="menuitem"
              key={`${item.value}_item_${index}`}
              onClick={() => onSelectedItemChange(item)}
              className={cn(
                'cursor-pointer border-b-light-10 px-4 py-2 text-neutral-850 hover:bg-bl hover:text-light-10',
                isItemSelected(item) && 'bg-bl text-white',
                classes?.item,
              )}
              tabIndex={0}
            >
              <div className="inline-semibold">{getMainFieldTitle(item)}</div>

              {getSecondaryFieldTitle?.(item) && (
                <span className={cn('secondary-regular', classes?.itemTitle)}>
                  {getSecondaryFieldTitle?.(item)}
                </span>
              )}
            </div>
          ))}
          {filteredItems.length === 0 && (
            <div className="my-s text-center">No {mask} found</div>
          )}
        </OverlayScrollbarsComponent>
      )}
    </div>
  );
};

export default ItemFilterList;
