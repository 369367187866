import { TRootState } from '@/app/stores';
import { getInitialComparisonDashboardState } from '@/bundles/Shared/entities/dashboard/model/slices/comparisonState';
import { dashboardSettingsApiEnhanced } from '@/shared/api/dashboardSettingsEnhancedApi';
import { ReportScoreboardWidgetSectionDto } from '@/shared/api/dashboardsSettingsGeneratedApi';
import { resolveFinancialTableWidgetDefaultOptionsDate } from '@/bundles/Shared/widgets/dashboard/widgets/common/financialTable/lib';
import { ComparisonDashboardWidgetStateMap } from '@/bundles/Shared/widgets/dashboard/widgets/config';
import { formatToDateStringForRequest } from '@/shared/lib/converters';
import { yesterdayDate } from '@/shared/lib/date';
import { mapListToIds } from '@/shared/lib/listHelpers';
import {
  createEntityAdapter,
  createSlice,
  isAnyOf,
  PayloadAction,
} from '@reduxjs/toolkit';

import {
  buildLayoutsId,
  getOverridableState,
  layoutsIdHasBoard,
  updateDashboardWidgetState,
  updateWidgetStateReducer,
} from 'bundles/Shared/entities/dashboard/model/slices/shared';

import type {
  ComparisonDashboardSectionTypes,
  ReportComparisonDashboardSection,
} from 'bundles/Shared/entities/dashboard/model/types/types';
import { filterIncludedLegalEntities } from 'bundles/Shared/entities/dashboard/lib';
import {
  addLayoutsReducersToSlice,
  buildLayout,
  LayoutsState,
} from 'bundles/Shared/entities/dashboard/model/slices/layouts';

export type ComparisonDashboardState = LayoutsState<{
  id: string;
  objectLegalEntityIds: Record<number, string[]>;
  date: string;
  widgetsState: Record<
    string,
    {
      type: ComparisonDashboardSectionTypes;
    } & ComparisonDashboardWidgetStateMap[keyof ComparisonDashboardWidgetStateMap]
  >;
}>;

const dashboardsAdapter = createEntityAdapter<ComparisonDashboardState>();

const initialState = dashboardsAdapter.getInitialState();

export const getInitialStateForComparisonDashboardWidget = (
  widgetSection: ReportComparisonDashboardSection,
) => {
  return getInitialComparisonDashboardState(
    widgetSection.widgetType,
    widgetSection,
  );
};

export const comparisonDashboardSlice = createSlice({
  name: 'comparisonDashboard',
  initialState,
  reducers: {
    updateReportComparisonObjectLegalEntityIds: (
      state,
      action: PayloadAction<{
        objectId: number;
        legalEntityIds: string[];
        dashboardId: string;
      }>,
    ) => {
      const { dashboardId, objectId, legalEntityIds } = action.payload;
      if (!state.entities[dashboardId]) {
        return;
      }
      state.entities[dashboardId].objectLegalEntityIds[objectId] =
        legalEntityIds;
    },
    updateReportComparisonDashboardDate: (
      state,
      action: PayloadAction<{
        date: string;
        dashboardId: string;
      }>,
    ) => {
      const { dashboardId, date } = action.payload;
      if (state.entities[dashboardId] == null) {
        return;
      }
      state.entities[dashboardId].date = date;

      for (const entityId in state.entities) {
        if (layoutsIdHasBoard(entityId)) {
          state.entities[entityId]!.date = date;

          for (const widgetStateId in state.entities[entityId]?.widgetsState) {
            state.entities[entityId].widgetsState[widgetStateId].date = date;
          }
        }
      }
    },
  },
  extraReducers(builder) {
    addLayoutsReducersToSlice(builder);
    builder.addCase(updateDashboardWidgetState, updateWidgetStateReducer);
    builder.addMatcher(
      dashboardSettingsApiEnhanced.endpoints
        .getApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdWidgetSectionsId
        .matchFulfilled,
      (state, action) => {
        const {
          id: widgetId,
          defaultOptions,
          dashboardId,
          boardId,
        } = action.payload;
        const dashboard = state.entities[dashboardId];
        const board = state.entities[buildLayoutsId({ boardId, dashboardId })];
        if (dashboard != null || board != null) {
          return;
        }

        dashboardsAdapter.upsertOne(state, {
          id: dashboardId,
          date: resolveFinancialTableWidgetDefaultOptionsDate(
            defaultOptions?.date,
          ),
          objectLegalEntityIds: {},
          widgetsState: {},
          layouts: { lg: [] },
          initialLayouts: { lg: [] },
        } as ComparisonDashboardState);

        dashboardsAdapter.upsertOne(state, {
          id: buildLayoutsId({ boardId, dashboardId }),
          objectLegalEntityIds: {},
          date: resolveFinancialTableWidgetDefaultOptionsDate(
            defaultOptions?.date,
          ),
          widgetsState: {
            [widgetId]: {
              type: action.payload.widgetType,
              ...getInitialStateForComparisonDashboardWidget(action.payload),
              ...getOverridableState(action.payload),
            },
          },
          layouts: { lg: [] },
          initialLayouts: { lg: [] },
        } as ComparisonDashboardState);
      },
    );
    builder.addMatcher(
      isAnyOf(
        dashboardSettingsApiEnhanced.endpoints
          .getApiReportComparisonDashboardsById.matchFulfilled,
        dashboardSettingsApiEnhanced.endpoints
          .getApiSettingsReportComparisonDashboardsById.matchFulfilled,
      ),
      (state, action) => {
        const {
          id: dashboardId,
          assets,
          widgetSections,
          excludedLegalEntities,
          boards,
        } = action.payload;

        const dashboardState = state.entities[dashboardId];

        const layouts = buildLayout(
          (widgetSections ?? []) as ReportScoreboardWidgetSectionDto[],
        );

        const excludedLegalEntityIds = mapListToIds(
          excludedLegalEntities ?? [],
        );

        const buildWidgetsState = (
          sections: ReportScoreboardWidgetSectionDto[],
        ) => {
          return Object.fromEntries(
            sections.map((widgetSection) => [
              widgetSection.id,
              {
                type: widgetSection.widgetType,
                ...getInitialStateForComparisonDashboardWidget(widgetSection),
                ...dashboardState?.widgetsState?.[widgetSection.id],
                ...getOverridableState(widgetSection),
              },
            ]),
          );
        };
        dashboardsAdapter.upsertOne(state, {
          id: dashboardId,
          objectLegalEntityIds: Object.fromEntries(
            assets.map((asset) => [
              asset.id,
              asset.legalEntities
                ?.filter((le) =>
                  filterIncludedLegalEntities(le, excludedLegalEntityIds),
                )
                ?.map((legalEntity) => legalEntity.id) ?? [],
            ]),
          ),
          date:
            dashboardState?.date ??
            formatToDateStringForRequest(yesterdayDate()),
          widgetsState: buildWidgetsState(
            (widgetSections ?? []) as ReportScoreboardWidgetSectionDto[],
          ),
          layouts,
          initialLayouts: layouts,
        } as ComparisonDashboardState);

        boards.forEach((board) => {
          const boardId = board.id;
          const boardLayoutsId = buildLayoutsId({ boardId, dashboardId });
          const boardLayouts = buildLayout(
            (board.sections ?? []) as ReportScoreboardWidgetSectionDto[],
          );
          dashboardsAdapter.upsertOne(state, {
            id: boardLayoutsId,
            objectLegalEntityIds: {},
            date:
              dashboardState?.date ??
              formatToDateStringForRequest(yesterdayDate()),
            widgetsState: buildWidgetsState(
              (board.sections ?? []) as ReportScoreboardWidgetSectionDto[],
            ),
            layouts: boardLayouts,
            initialLayouts: boardLayouts,
          } as ComparisonDashboardState);
        });
      },
    );
  },
});

export const {
  updateReportComparisonObjectLegalEntityIds,
  updateReportComparisonDashboardDate,
} = comparisonDashboardSlice.actions;

export const { selectById: selectReportComparisonDashboardMetadataById } =
  dashboardsAdapter.getSelectors(
    (state: TRootState) => state.reportComparisonDashboard,
  );

export const selectReportComparisonDashboardSelectedObjectLegalEntityIds = (
  state: TRootState,
  {
    dashboardId,
    selectedObjectId,
  }: {
    dashboardId: string;
    selectedObjectId: number | null | undefined;
  },
) => {
  const dashboard = selectReportComparisonDashboardMetadataById(
    state,
    dashboardId,
  );
  if (dashboard == null || selectedObjectId == null) {
    return [];
  }
  return dashboard.objectLegalEntityIds[selectedObjectId] ?? [];
};
