import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAsset } from 'types/Asset';
import { IAssetStage } from 'types/AssetStage';
import { TAssetStatuses } from 'types/AssetStatus';
import { plainFetchAssetsWithMeta } from '@/bundles/Assets/actions/assets';

export interface FetchAssetsWithMetaResponse {
  assets: IAsset[];
  meta: { stages: IAssetStage[]; totalSize: number };
}
const initialState: FetchAssetsWithMetaResponse & {
  selectedStageNames: IAssetStage['name'][];
  selectedStatusNames: TAssetStatuses[];
  pageParams: {
    page: number;
    perPage: number;
  };
} = {
  assets: [],
  meta: {
    stages: [],
    totalSize: 0,
  },
  selectedStageNames: [],
  selectedStatusNames: [],
  pageParams: {
    page: 1,
    perPage: 50,
  },
};

export const fetchAssetsWithMeta = createAsyncThunk(
  'assets/fetch',
  async (params: Parameters<typeof plainFetchAssetsWithMeta>[0]) => {
    const data = await plainFetchAssetsWithMeta(params);
    return data;
  },
);

const assetsSlice = createSlice({
  name: 'assets',
  initialState,
  reducers: {
    updateSelectedStageNames(
      state,
      action: PayloadAction<(typeof initialState)['selectedStageNames']>,
    ) {
      state.pageParams.page = 1;
      state.selectedStageNames = action.payload;
    },
    updateSelectedStatusNames(
      state,
      action: PayloadAction<(typeof initialState)['selectedStatusNames']>,
    ) {
      state.pageParams.page = 1;
      state.selectedStatusNames = action.payload;
    },
    updatePageParams(
      state,
      action: PayloadAction<(typeof initialState)['pageParams']>,
    ) {
      state.pageParams = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAssetsWithMeta.fulfilled, (state, { payload }) => {
      state.assets = payload.assets;
      state.meta = payload.meta;
    });
  },
});

export const {
  updateSelectedStageNames,
  updateSelectedStatusNames,
  updatePageParams,
} = assetsSlice.actions;

export default assetsSlice.reducer;
