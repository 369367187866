import { useSearchParams } from '@/shared/lib/hooks/navigation/useSearchParams';
import useElementSize, {
  ElementSizeContext,
} from '@/shared/lib/hooks/useElementSize';
import NoDataOverlay from 'bundles/Shared/components/NoDataOverlay';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { useEffect, useRef } from 'react';
import { ProjectCardSkeleton } from 'stories/ProjectCard/ProjectCard';
import ProjectCardList from 'stories/ProjectCard/ProjectCardList';
import ReconcileOperational from '@/bundles/REconcile/components/operational/ReconcileOperational';
import { BudgetYear } from '@/bundles/REconcile/components/operational/types';
import {
  resolveTotal,
  resolveTotalPublishedPercentage,
} from '@/bundles/REconcile/components/operational/utils';

interface Props {
  budgets: BudgetYear[];
  yearIdFromQuery: string | undefined;
  isLoading: boolean;
}

export function CardList({ budgets, yearIdFromQuery, isLoading }: Props) {
  const listRef = useRef(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const size = useElementSize(listRef);

  const onYearChange = (yearId: string) => {
    setSearchParams((prev) => {
      prev.set('yearId', yearId);
      return prev;
    });
  };

  useEffect(() => {
    if (budgets.length <= 0) return;
    const yearId = Number(searchParams.get('yearId'));

    const allYears = budgets.map((b) => b.year);

    if (allYears.includes(yearId)) return;

    const latestYear = budgets.at(0);
    if (latestYear == null) return;

    onYearChange(String(latestYear.year));
  }, [budgets]);

  if (isLoading) {
    return (
      <ProjectCardList>
        {Array.from({ length: 5 }).map((_, i) => (
          <ProjectCardSkeleton key={i} />
        ))}
      </ProjectCardList>
    );
  }

  if (budgets.length === 0) {
    return <NoDataOverlay title="Nothing Found" subTitle="" />;
  }

  return (
    <OverlayScrollbarsComponent>
      <ProjectCardList ref={listRef}>
        <ElementSizeContext.Provider value={size}>
          {budgets.map((b) => (
            <ReconcileOperational.BudgetYearCard
              key={b.year}
              onClick={() => {
                onYearChange(String(b.year));
              }}
              budget={b}
              total={resolveTotal(b)}
              totalPublishedPercentage={resolveTotalPublishedPercentage(b)}
              currentYearId={yearIdFromQuery}
            />
          ))}
        </ElementSizeContext.Provider>
      </ProjectCardList>
    </OverlayScrollbarsComponent>
  );
}
