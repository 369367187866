import React from 'react';
import { cn } from '@/shared/lib/css/cn';
import { CurrencyFormatter } from 'stories/ValueFormatters/CurrencyFormatter';
import { Icon } from 'stories/Icon/Icon';

interface Props {
  past: number;
  present: number;
}

export function AmountComparison({ past, present }: Props) {
  const isIncreased = past < present;
  const difference = isIncreased ? present - past : past - present;
  const sign = isIncreased ? '+' : '-';
  const textClassName = isIncreased ? 'text-green-dark-2' : 'text-red-dark-2';

  return (
    <div className="grid grid-cols-[repeat(3,_max-content)] grid-rows-2 items-center gap-x-6 pr-6">
      <CurrencyFormatter
        classes={{
          wrapper: 'inline-regular leading-5 inline-flex',
          value: 'inline-semibold',
        }}
        value={past}
      />
      <Icon
        iconName="arrowRightAlt"
        className={cn('leading-none', textClassName)}
      />
      <CurrencyFormatter
        classes={{
          wrapper: 'inline-regular text-dark-60 leading-5 inline-flex',
          value: 'inline-semibold text-dark-60',
        }}
        value={present}
      />
      <span
        className={cn(
          'secondary-regular col-span-3 text-center leading-none',
          textClassName,
        )}
      >
        {sign}{' '}
        <CurrencyFormatter
          classes={{
            wrapper: 'inline-flex',
            startSymbol: textClassName,
            value: 'secondary-semibold',
            fractions: textClassName,
          }}
          value={difference}
        />
      </span>
    </div>
  );
}
