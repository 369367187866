import { Button } from '@/stories/Button/Button';
import { Modal } from '@/stories/Modals/Modal/Modal';
import { ModalActions } from '@/stories/Modals/Modal/ModalActions';
import { Textarea } from '@/stories/Textarea/Textarea';
import React, { ComponentProps, ReactNode, useEffect, useState } from 'react';
import ModalAdditionalActions from '@/stories/Modals/Modal/ModalAdditionalActions';
import '@/stories/Modals/ConfirmationModal/confirmation-modal.css';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Field, { FieldsContainer } from '@/stories/Field/Field';

type ButtonProps = Pick<
  ComponentProps<typeof Button>,
  'variant' | 'disabled'
> & { text: string };

interface Props {
  handleResolve: (
    resolved: boolean,
    reason?: string,
  ) => Promise<void> | boolean;
  subtitle?: ReactNode | ReactNode[];
  title?: React.ReactNode;
  actions?: {
    primaryButton: ButtonProps;
    secondaryButton?: ButtonProps;
  };
  maxWidth?: ComponentProps<typeof Modal>['maxWidth'];
  additionalActions?: {
    buttonText: string;
    description?: string;
    onClick?: () => void;
  };
  onClose?: () => void;
  size?: ComponentProps<typeof Modal>['size'];
  reason?: boolean;
}

export const DEFAULT_ACTIONS = {
  primaryButton: {
    text: 'Yes',
    variant: 'danger',
  },
  secondaryButton: {
    text: 'Cancel',
    variant: 'secondary',
  },
} as const satisfies Props['actions'];

const Title = ({ children }: React.PropsWithChildren) => (
  <span className="confirmation-modal-title">{children}</span>
);

const Subtitle = ({ children }: React.PropsWithChildren) => (
  <span className="confirmation-modal-subtitle">{children}</span>
);

const ReasonForm = ({
  value,
  onChange,
}: {
  value: string;
  onChange: (text: string) => void;
}) => {
  const {
    control,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      reason: value,
    },
    resolver: yupResolver(
      yup.object({
        reason: yup.string().required('Reason is required'),
      }),
    ),
    mode: 'onChange',
  });

  const reason = watch('reason');

  useEffect(() => {
    onChange(reason);
  }, [reason, onChange]);

  return (
    <div className="mt-6 space-y-2">
      {/* TODO: Textarea not have autoFocus prop */}
      <FieldsContainer>
        <Field
          labelText="If so, please leave a comment here for future reference"
          labelFor="reason-comment"
          error={errors.reason?.message || ''}
          required
        >
          <Controller
            control={control}
            name="reason"
            render={({ field }) => (
              <Textarea
                id="reason-comment"
                placeholder="Enter"
                rows={3}
                autoComplete="off"
                autoCorrect="off"
                value={field.value}
                onChange={(e) => field.onChange(e.target.value)}
              />
            )}
          />
        </Field>
      </FieldsContainer>
    </div>
  );
};

export const CONFIRMATION_MODAL_CLASSES = {
  footer: 'p-6',
  footerAdditionalActions: 'border-top',
  body: 'pt-8 pb-2',
};

export const ConfirmationModal = ({
  title = 'Are you sure?',
  subtitle,
  actions = DEFAULT_ACTIONS,
  handleResolve,
  additionalActions,
  size,
  maxWidth,
  onClose,
  reason,
  ...props
}: Props) => {
  const [disabled, setDisabled] = useState(false);
  const [reasonText, setReasonText] = useState('');

  const clickHandle = async () => {
    setDisabled(true);
    const res = await handleResolve(true, reasonText);
    if (res) {
      onClose?.();
    } else {
      setDisabled(false);
    }
  };

  return (
    <Modal
      toggle={() => {
        handleResolve(false);
        onClose?.();
      }}
      maxWidth={maxWidth}
      size={size}
      showDivider={false}
      actions={
        <ModalActions>
          {actions.secondaryButton && (
            <Button
              data-testid="confirmation-cancel"
              onClick={() => {
                handleResolve(false);
                onClose?.();
              }}
              fluid
              {...DEFAULT_ACTIONS.secondaryButton}
              {...actions?.secondaryButton}
            >
              {actions.secondaryButton.text}
            </Button>
          )}
          {actions.primaryButton && (
            <Button
              data-testid="confirmation-confirm"
              onClick={clickHandle}
              fluid
              {...DEFAULT_ACTIONS.primaryButton}
              {...actions?.primaryButton}
              disabled={
                disabled ||
                actions.primaryButton.disabled ||
                (reason && !reasonText.length)
              }
            >
              {actions.primaryButton.text}
            </Button>
          )}
        </ModalActions>
      }
      classes={CONFIRMATION_MODAL_CLASSES}
      additionalActions={
        additionalActions && (
          <ModalAdditionalActions
            description={additionalActions.description}
            buttonText={additionalActions.buttonText}
            onClick={() => {
              if (additionalActions.onClick) additionalActions.onClick();
              handleResolve(false);
            }}
          />
        )
      }
      {...props}
    >
      <div className="flex flex-col">
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>

        {/* TODO: FIX rerenders FE-3896 */}
        {reason && <ReasonForm value={reasonText} onChange={setReasonText} />}
      </div>
    </Modal>
  );
};

ConfirmationModal.Title = Title;
ConfirmationModal.Subtitle = Subtitle;
ConfirmationModal.ReasonForm = ReasonForm;

export default ConfirmationModal;
