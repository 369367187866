import React from 'react';
import { cn } from '@/shared/lib/css/cn';
import styles from 'bundles/DrawPackage/InvoicesFlow/Invoices/invoice/Invoice.module.scss';
import { StatusItem } from 'stories/StatusItem/StatusItem';
import { useParams } from '@reach/router';
import { useGetInvoiceQuery } from 'bundles/Construction/api/invoices';
import { generateTypeStatusItem } from '@/bundles/DrawPackage/InvoicesFlow/Invoices/invoice/kanban/utils';

const StatusesInvoice = () => {
  const params = useParams();
  const { data: invoice } = useGetInvoiceQuery({
    legalEntityCode: params?.legalEntityCode,
    invoiceId: params.invoiceId,
  });

  const approvalSettings = generateTypeStatusItem({
    type: 'approval',
    approval: invoice?.approval,
  });

  const fundingSettings = generateTypeStatusItem({
    type: 'funding',
    funding: invoice?.funding,
  });

  const paymentSettings = generateTypeStatusItem({
    type: 'payment',
    payment: invoice?.payment,
  });

  return (
    <div className="flex justify-center rounded-2xl bg-dark">
      <div className="flex-1 px-4 py-2">
        <StatusItem
          title="Approval"
          subTitle={approvalSettings.title}
          type={approvalSettings.type}
          classes={{
            title: 'secondary-regular text-light-30',
            subtitle: 'label-regular text-white',
          }}
        />
      </div>
      <div className={cn('flex-1 px-4 py-2', styles.paymentStatusInvoice)}>
        <StatusItem
          title="Payment"
          subTitle={paymentSettings.title}
          type={paymentSettings.type}
          classes={{
            title: 'secondary-regular text-light-30',
            subtitle: 'label-regular text-white',
          }}
        />
      </div>
      <div className="flex-1 px-4 py-2">
        <StatusItem
          title="Funding"
          subTitle={fundingSettings.title}
          type={fundingSettings.type}
          classes={{
            title: 'secondary-regular text-light-30',
            subtitle: 'label-regular text-white',
          }}
        />
      </div>
    </div>
  );
};

export default StatusesInvoice;
