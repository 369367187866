// TODO: will be removed after AlbumCard will be implemented
import React from 'react';
import { IconsId } from 'types/sre-icons';
import '@/stories/ImageCard/imageCard.css';
import Checkbox from '@/stories/Checkbox/Checkbox';
import IconButton from '@/stories/IconButton/IconButton';

interface Props {
  image?: string;
  video?: string;
  actions?: {
    icon: IconsId;
    onClick: () => void;
  }[];
  bottomContent?: React.ReactNode;
  title?: string;
  subtitle?: string;
  onSelect?: () => void;
  handleClick?: (e: React.MouseEventHandler<HTMLInputElement>) => void;
  defaultIcon?: React.ReactNode;
  actionsVisabilityMode?: 'hover' | 'always';
  checkboxDisabled?: boolean;
  selected: boolean;
}

export const ImageCard = ({
  image,
  defaultIcon,
  onSelect,
  actions = [],
  bottomContent,
  title,
  subtitle,
  handleClick,
  video,
  actionsVisabilityMode = 'hover',
  checkboxDisabled = false,
  selected = false,
}: Props) => {
  const onClick = (e) => {
    if (e.target !== e.currentTarget) return;
    handleClick(e);
  };

  return (
    <div className="image-card__wrapper">
      <div className="image-card__container">
        <div className="image-card__image-container">
          {image && !video && (
            <img
              src={image}
              className="image-card__image"
              onClick={onClick}
              alt={image}
            />
          )}
          {video && (
            <video
              src={video}
              height={200}
              className="image-card__image"
              controls
            />
          )}
          <div
            className="p-s flex justify-between flex-col w-full"
            onClick={onClick}
          >
            <div
              className={
                actionsVisabilityMode === 'hover'
                  ? 'image-card__hoverable-actions'
                  : 'flex justify-between'
              }
              onClick={onClick}
            >
              {onSelect ? (
                <Checkbox
                  onChange={() => onSelect()}
                  checked={selected}
                  outline
                  disabled={checkboxDisabled}
                />
              ) : (
                <div>&nbsp;</div>
              )}
              <div>
                {actions.map((action) => (
                  <IconButton
                    onClick={action.onClick}
                    size="l"
                    offHoverStyles
                    className={`${
                      action.classes !== undefined
                        ? action.classes
                        : 'sre-icon-button_color-dark-60'
                    } position-relative ml-s`}
                    iconName={action.icon}
                  />
                ))}
              </div>
            </div>
            {!image && defaultIcon && (
              <div onClick={handleClick}>{defaultIcon}</div>
            )}
            {bottomContent}
          </div>
        </div>
        {(title || subtitle) && (
          <div className="image-card__text">
            <div className="image-card__text_title" onClick={onClick}>
              {title}
            </div>
            <div className="image-card__text_subtitle" onClick={onClick}>
              {subtitle}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ImageCard;
