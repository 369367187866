import { ItemTableCounter } from '@/shared/ui/ItemTableCounter';
import { createReportTableConfig } from 'bundles/Settings/actions/report/tableConfigs';
import { useReportTableConfigColumns } from 'bundles/Settings/components/REport/TableBuilder/hooks/useReportTableConfigColumns';
import ReportSettingsScreenLayout from 'bundles/Settings/shared/ReportSettingsScreenLayout';
import { ViewPermissionedUsersModal } from 'bundles/Shared/components/Permissions/ViewPermissionedUsersModal';
import Table from 'bundles/Shared/components/Table/Table';
import { PageParamsProvider } from 'bundles/Shared/components/pageParams';
import { fetchUserRoles } from 'bundles/UserManagement/actions/UserRole';
import { useAppDispatch } from '@/shared/lib/hooks/redux';
import { useModal } from '@/shared/lib/hooks/useModal';
import { useEffect, useState } from 'react';
import { Button } from 'stories/Button/Button';
import { IReportTableConfig } from 'types/ReportTableConfig';
import ReportTableConfigModal from '@/bundles/Settings/components/REport/TableBuilder/ReportTableConfigModal';
import { getReportTablePermissions } from '@/bundles/Settings/components/REport/TableBuilder/api/actions';
import { usePutApiSettingsReportTableConfigsByTableConfigIdSetPositionMutation } from '@/bundles/Settings/components/REport/TableBuilder/api/reportTableConfigGeneratedApi';
import { useReportTableConfigs } from '@/bundles/Settings/components/REport/TableBuilder/hooks/useReportTableConfigs';

const ReportTableConfigs = () => {
  const [pageParams, setPageParams] = useState({});
  const { openModal } = useModal();

  const dispatch = useAppDispatch();

  const [setPosition] =
    usePutApiSettingsReportTableConfigsByTableConfigIdSetPositionMutation();

  const { loading, enableLoader, loadTableConfigs, reportTableConfigs } =
    useReportTableConfigs();

  const handleNewTable = async () => {
    const res = await openModal(ReportTableConfigModal, {});
    if (!res) return;
    enableLoader();
    await createReportTableConfig(res);
    loadTableConfigs();
  };

  const handleViewPermissions = async (tableConfig: IReportTableConfig) => {
    await openModal(ViewPermissionedUsersModal, {
      fetchPermissionedUsersWithMeta: () =>
        getReportTablePermissions(tableConfig.id),
    });
  };

  const { columns } = useReportTableConfigColumns(handleViewPermissions);

  const onDragEnd = async (index: number, item: IReportTableConfig) => {
    enableLoader();
    await setPosition({
      body: {
        position: index,
      },
      tableConfigId: item.id,
    });
    await loadTableConfigs();
  };

  useEffect(() => {
    dispatch(fetchUserRoles());
  }, []);

  return (
    <ReportSettingsScreenLayout.Content>
      <div className="flex items-center justify-between">
        <ItemTableCounter count={reportTableConfigs.length} word="item" />
        <Button size="s" variant="primary" onClick={handleNewTable}>
          Create New
        </Button>
      </div>
      <PageParamsProvider pageParams={pageParams} setPageParams={setPageParams}>
        <Table
          loading={loading}
          columns={columns}
          classes={{
            container: 'table-gen-2',
          }}
          items={reportTableConfigs ?? []}
          onDragEnd={onDragEnd}
        />
      </PageParamsProvider>
    </ReportSettingsScreenLayout.Content>
  );
};

export default ReportTableConfigs;
