import { cn } from '@/shared/lib/css/cn';
import { Button } from '@/stories/Button/Button';
import { FieldsContainer } from '@/stories/Field/Field';
import type { ComponentProps } from 'react';

export const CreateNewUI = (props: LayoutProps) => (
  <div className={cn('relative h-screen w-screen p-4', props.className)}>
    {props.children}
  </div>
);

CreateNewUI.BackButton = (
  props: Omit<ComponentProps<typeof Button>, 'variant'>,
) => (
  <Button
    variant="secondary"
    {...props}
    className={cn('t-4 l-4 absolute', props.className)}
  >
    Back
  </Button>
);

CreateNewUI.Container = (props: LayoutProps) => (
  <div className={cn('flex w-full justify-center', props.className)}>
    {props.children}
  </div>
);

CreateNewUI.Form = (
  props: LayoutProps & {
    title: string;
    submitSlot: React.ReactNode;
  },
) => (
  <div className={cn('flex flex-col gap-4', props.className)}>
    <div className="flex flex-col items-center rounded-2xl bg-neutral-250">
      <span className="inline-semibold py-2 text-neutral-700">
        New {props.title}
      </span>
      {props.children}
    </div>
    <div className="flex flex-col gap-2">{props.submitSlot}</div>
  </div>
);

CreateNewUI.FieldsContainer = (props: LayoutProps) => (
  <FieldsContainer
    className={cn('w-[532px] rounded-2xl bg-neutral-100 p-4', props.className)}
  >
    {props.children}
  </FieldsContainer>
);
