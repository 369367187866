import { cn } from '@/shared/lib/css/cn';
import { Icon } from '@/stories/Icon/Icon';
import styles from '@/stories/SmallAddingButton/SmallAddingButton.module.scss';

interface Props
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  withoutIcon?: boolean;
}

export const SmallAddingButton = ({
  children,
  onClick,
  withoutIcon = false,
  className,
  ...props
}: Props) => (
  <button
    className={cn(
      styles.button,
      props.disabled && '!cursor-default !bg-light-10 !text-light-60',
      className,
    )}
    onClick={onClick}
    type="button"
    {...props}
  >
    <div className="flex">
      {!withoutIcon && <Icon iconName="addSmall" className="mr-xs" />}
      {children}
    </div>
  </button>
);

export default SmallAddingButton;
