import React, { useEffect, useMemo, useState } from 'react';
import { cn } from '@/shared/lib/css/cn';
import { AnimationLoader } from 'stories/AnimationLoader/AnimationLoader';
import { IconButton } from 'stories/IconButton/IconButton';
import { Tumbler } from 'stories/Tumbler/Tumbler';
import { useAppSelector } from '@/shared/lib/hooks/redux';
import { useModal } from '@/shared/lib/hooks/useModal';
import GanttChartSettingsModal from 'bundles/Settings/components/DealPath/cards/GanttChartSettingsModal';
import http from 'lib/http';
import Table from 'bundles/Shared/components/Table/Table';
import { IPipelineCard } from 'bundles/Settings/components/DealPath/PipelineCards';
import { IColumn } from 'bundles/Shared/components/Table/types';
import { updatePipelineCard } from 'bundles/Settings/components/DealPath/actions';

const tableColumns = ({ toggleActive, onClick }): IColumn<any>[] => [
  {
    dataField: 'active',
    text: 'Active',
    formatter: ({ row }) => (
      <Tumbler
        className={cn(
          'font-weight-500 font-16',
          row.active ? 'dark-60' : 'light-60',
        )}
        defaultChecked={row.active}
        onChange={() => toggleActive(row)}
      />
    ),
  },
  {
    text: 'Category',
    dataField: 'category',
  },
  {
    text: 'Start field',
    dataField: 'startDate',
  },
  {
    text: 'End field',
    dataField: 'endDate',
  },
  {
    text: 'Actions',
    dataField: 'actions',
    formatter: ({ row }) => (
      <IconButton iconName="edit" onClick={() => onClick(row)} />
    ),
  },
];

interface Props {
  card: IPipelineCard;
}

const GanttChartSettings = ({ card }: Props) => {
  const [fields, setFields] = useState();
  const [schema, setSchema] = useState(card.schema || {});
  const categories = useAppSelector(
    (state) => state.dealPathSettings.categories,
  );
  const { openModal } = useModal();

  useEffect(() => {
    http
      .get('/deal_path_fields')
      .then((res) => res.json())
      .then((json) => setFields(json));
  }, []);

  const data = () =>
    categories.map((c) => {
      const item = schema[c.name];
      if (!item) return { id: c.id, category: c.name };

      return {
        id: c.id,
        active: item.active,
        startDate: item.startDate,
        endDate: item.endDate,
        category: c.name,
      };
    });

  const options = useMemo(
    () => fields?.map((f) => ({ label: f.name, value: f.key })),
    [fields],
  );

  const handleEditClick = async (row) => {
    const res = (await openModal(GanttChartSettingsModal, {
      row,
      options,
    })) as object;
    if (res) updateSchema(row.category, res);
  };

  const updateSchema = (category, params) => {
    const newSchema = { ...schema, [category]: { active: true, ...params } };
    updatePipelineCard(card, { schema: newSchema }).then((json) =>
      setSchema(json.schema),
    );
  };

  if (!fields) return <AnimationLoader className="static min-h-[360px]" />;

  return (
    <div className="p-xl rounded bg-light">
      <div className="table-wrap">
        <div className="table-container table-responsive">
          <Table
            items={data()}
            columns={tableColumns({
              toggleActive: (row) =>
                updateSchema(row.category, { active: !row.active }),
              onClick: handleEditClick,
            })}
          />
        </div>
      </div>
    </div>
  );
};

export default GanttChartSettings;
