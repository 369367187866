import React from 'react';
import NotificationsList from 'bundles/Notifications/components/NotificationsList';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import LinkButton from 'stories/LinkButton/LinkButton';
import { hideAllNotifications } from 'bundles/Notifications/actions';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import { SidePanel } from 'stories/Modals/Modal/Modal';

type Props = DialogProps;

function NotificationsModal({ onClose }: Props) {
  const dispatch = useAppDispatch();
  const notifications = useAppSelector((state) => state.notifications.unread);

  return (
    <SidePanel toggle={onClose} size="md" header="Notifications">
      {notifications && notifications.length > 0 && (
        <LinkButton
          className="mb-4 text-transform-initial"
          onClick={() => {
            dispatch(hideAllNotifications());
          }}
        >
          Mark all as read
        </LinkButton>
      )}
      <NotificationsList notifications={notifications!} />
    </SidePanel>
  );
}

export default NotificationsModal;
