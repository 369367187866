import React, { useState } from 'react';
import { Button } from 'stories/Button/Button';
import { Modal } from 'stories/Modals/Modal/Modal';
import { ModalActions } from 'stories/Modals/Modal/ModalActions';
import { Tumbler } from 'stories/Tumbler/Tumbler';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import { SIGNATURE_FIELDS } from '@/bundles/REconcile/components/development/home/legalEntitySettingsModal/workflow/consts';

interface Props extends DialogProps {
  signatureFields: {
    signature?: boolean;
    company?: boolean;
    initials?: boolean;
    date?: boolean;
  };
}

const SignatureFieldsModal = ({
  signatureFields,
  onClose,
  onSubmit,
}: Props) => {
  const [data, setData] = useState(signatureFields);

  return (
    <Modal
      header="Signature Fields"
      toggle={onClose}
      classes={{ body: 'px-6 bg-light' }}
      actions={
        <ModalActions alignItems="space-between">
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="success" onClick={() => onSubmit(data)}>
            Save Approvers
          </Button>
        </ModalActions>
      }
    >
      <div>
        <div className="flex justify-between">
          <div className="light-60 secondary-regular p-4">FIELD</div>
          <div className="light-60 secondary-regular p-4">REQUIRED</div>
        </div>
        <div className="flex flex-col gap overflow-hidden rounded-lg">
          {SIGNATURE_FIELDS.map(({ kind, title }) => (
            <div
              key={kind}
              className="flex items-center justify-between bg-white"
            >
              <div className="inline-semibold p-4 text-dark-60">{title}</div>
              <div className="mr-8">
                <Tumbler
                  checked={data[kind]}
                  onChange={(e) =>
                    setData({ ...data, [kind]: e.target.checked })
                  }
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default SignatureFieldsModal;
