import React, { FC, useState } from 'react';
import { CollapsedCard } from 'stories/CollapsedCard/CollapsedCard';
import { IChangeOrderEvent } from 'bundles/Construction/types';
import {
  formatUnixDate,
  formatUnixDateFromNow,
} from '@/shared/lib/formatting/dates';
import { formatAmount } from '@/shared/lib/formatting/number';
import { useNavigation } from '@/shared/lib/hooks/useNavigation';
import { Link } from '@reach/router';

const TableCEItem: FC<IChangeOrderEvent> = ({
  id,
  legalEntityCode,
  title,
  value,
  receivedDate,
  approvedDate,
  category,
  displayNumber,
  description,
}) => {
  const [open, setOpen] = useState(false);
  const { getUrl } = useNavigation();
  const url = getUrl('RECONCILE_DEVELOPMENT_LEGAL_ENTITY_CHANGE_EVENT', {
    id: id.toString(),
    legalEntityCode,
  });
  return (
    <CollapsedCard
      title={<Link to={url}>{title}</Link>}
      subtitle={
        <Link to={url}>
          {category} {displayNumber} • {formatAmount(value)} • Received:{' '}
          {formatUnixDate(receivedDate, 'MMM DD, YYYY')}
        </Link>
      }
      approvalStatus={{
        text: formatUnixDateFromNow(receivedDate),
        tooltipText: `Approved: ${formatUnixDate(approvedDate, 'MM-DD-YYYY')}`,
        iconName: 'acceptAlt',
      }}
      content={description}
      activated={open}
      activatedHandle={() => setOpen(!open)}
    />
  );
};

export default TableCEItem;
