import { useAppDispatch } from '@/shared/lib/hooks/redux';
import { useModal } from '@/shared/lib/hooks/useModal';
import { IFeedType } from '@/types/CustomerConfig';
import { ColorPickerWidget } from '@/widgets/core/sreColors/settings/ui/ColorPickerWidget';
import { CardWrapper } from 'bundles/Shared/components/Wrappers/CardWrapper';
import React, { useState } from 'react';
import { Button } from 'stories/Button/Button';
import { Input } from 'stories/FormControls/Inputs/Input/Input';
import { LinkButton } from 'stories/LinkButton/LinkButton';
import { Modal } from 'stories/Modals/Modal/Modal';
import {
  createFeedType,
  deleteFeedType,
  updateFeedType,
} from '@/bundles/Settings/actions/feedType';
import CardWithToggle from '@/bundles/Settings/components/DealPath/CardWithToggle';

const FeedTypes = ({ feedTypes }: { feedTypes: IFeedType[] }) => {
  const [selectedFeedType, setSelectedFeedType] = useState<
    IFeedType | null | 'new'
  >();
  const dispatch = useAppDispatch();
  const { confirm } = useModal();

  const feedTypeColor =
    selectedFeedType === 'new' ? '' : (selectedFeedType?.color ?? '');
  const setFeedTypeColor = (color: IFeedType['color']) => {
    setSelectedFeedType((prev) =>
      prev != null && typeof prev === 'object' ? { ...prev, color } : null,
    );
  };

  const handleChangeColor = (color: React.CSSProperties['color']) => {
    setFeedTypeColor(color);
  };

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();

    if (selectedFeedType === 'new')
      throw new Error('wrong type of `selectedFeedType`');

    const data = {
      title: (e.target as unknown as { title: HTMLInputElement }).title.value,
      color: feedTypeColor,
    };

    if (selectedFeedType?.id) {
      await dispatch(updateFeedType(selectedFeedType.id, data));
    } else {
      await dispatch(createFeedType(data));
    }
    setSelectedFeedType(null);
  };

  const handleDelete = async (id: IFeedType['id']) => {
    const result = await confirm({
      title: 'Remove feed type',
      subtitle: 'Are you sure you want to remove feed type?',
    });
    if (result) dispatch(deleteFeedType(id));
  };

  return (
    <div>
      <CardWrapper className="mt-50 bg-light">
        <div className="px-5 py-3">
          <div className="flex justify-between">
            <h3 className="text-3xl font-medium">Feed Types</h3>
            <LinkButton onClick={() => setSelectedFeedType('new')} uppercase>
              Add New Feed Type
            </LinkButton>
          </div>
        </div>
        <div className="px-5 py-5">
          <div className="flex flex-wrap">
            {feedTypes
              .filter((ft) => !ft.internal)
              .map((feedType) => (
                <CardWithToggle
                  key={feedType.id}
                  item={feedType}
                  onToggle={() => {
                    dispatch(
                      updateFeedType(feedType.id, { active: !feedType.active }),
                    );
                  }}
                  onEdit={() => setSelectedFeedType(feedType)}
                  onDelete={() => handleDelete(feedType.id)}
                  footer={() =>
                    feedType.color && (
                      <div className="font-12">
                        <span className="dark-60 mr-1">Color:</span>
                        <span style={{ color: feedType.color }}>
                          {feedType.color}
                        </span>
                      </div>
                    )
                  }
                />
              ))}
          </div>
        </div>
      </CardWrapper>
      {selectedFeedType && (
        <Modal
          header={`${
            typeof selectedFeedType === 'string' ? 'New' : 'Edit'
          } Feed Type`}
          toggle={() => setSelectedFeedType(null)}
          actions={
            <Button
              disabled={!feedTypeColor}
              variant="success"
              fluid
              type="submit"
              form="feedTypesForm"
            >
              {typeof selectedFeedType === 'string' ? 'Create' : 'Submit'}
            </Button>
          }
          classes={{
            header: 'bg-light',
            body: 'bg-light',
            footer: 'bg-light',
          }}
        >
          <form id="feedTypesForm" onSubmit={handleSubmit}>
            <div className="from-group mb-2">
              <label htmlFor="name">Title</label>
              <Input
                maxLength={20}
                defaultValue={
                  typeof selectedFeedType === 'string'
                    ? ''
                    : selectedFeedType.title
                }
                id="title"
              />
            </div>
            <div className="from-group mb-2">
              <label htmlFor="color">Color</label>
              <ColorPickerWidget
                onChange={handleChangeColor}
                value={feedTypeColor || undefined}
              />
            </div>
          </form>
        </Modal>
      )}
    </div>
  );
};

export default FeedTypes;
