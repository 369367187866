import { useGetApiReconcileUnderwritingBudgetsSourceLegalEntitiesQuery } from 'bundles/REconcile/underwritting/api/reconcileUnderwritingBudgetEnhancedApi';
import {
  GetApiReconcileUnderwritingBudgetsSourceLegalEntitiesApiResponse,
  LegalEntityUnderwritingBudget,
  PostApiReconcileUnderwritingBudgetsApiArg,
} from 'bundles/REconcile/underwritting/api/reconcileUnderwritingBudgetGeneratedApi';
import dayjs from 'dayjs';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import { asserts } from 'lib/typeHelpers/assertsType';
import { useMemo, useState } from 'react';
import { IPeriodItem } from 'stories/FlexibleFilterByPeriods/types';
import { genPossiblePeriods } from 'stories/FlexibleFilterByPeriods/utils';
import { ISelectOption } from 'stories/FormControls/Select/Select';
import { assertIsSelectOption } from 'stories/FormControls/Select/utils';
import { Button } from 'stories/Button/Button';
import { FlexibleFilterByPeriods } from 'stories/FlexibleFilterByPeriods/FlexibleFilterByPeriods';
import { Modal } from 'stories/Modals/Modal/Modal';
import { ModalActions } from 'stories/Modals/Modal/ModalActions';
import { ModalHeaderWithSubtitle } from 'stories/Modals/ModalHeader/ModalHeaderWithSubtitle/ModalHeaderWithSubtitle';
import { Select } from 'stories/FormControls/Select/Select';
import { SelectableButtonGroup } from 'stories/SelectableButtonGroup/SelectableButtonGroup';
import { StepsFormStepv2 } from 'stories/StepsFormStep/StepsFormStepv2';

type ReturnForm =
  | Omit<PostApiReconcileUnderwritingBudgetsApiArg['body'], 'legal_entity_id'>
  | {
      source_legal_entity_id: string;
    };

export const CreateBudgetModal = ({
  legalEntityId,
  onClose,
  onSubmit,
}: DialogProps<ReturnForm> & {
  legalEntityId: LegalEntityUnderwritingBudget['id'];
}) => {
  const [mode, setMode] = useState<'manual' | 'from_source'>('manual');
  const [periods, setPeriods] = useState<IPeriodItem[]>([]);
  const [selectedSourceId, setSelectedSourceId] =
    useState<
      GetApiReconcileUnderwritingBudgetsSourceLegalEntitiesApiResponse['items'][number]['id']
    >();
  const { data, isFetching } =
    useGetApiReconcileUnderwritingBudgetsSourceLegalEntitiesQuery(
      {},
      {
        refetchOnMountOrArgChange: true, // can be avoided with invalidation after any api update
      },
    );

  const leOptions = useMemo<
    ISelectOption<
      GetApiReconcileUnderwritingBudgetsSourceLegalEntitiesApiResponse['items'][number]['id']
    >[]
  >(() => {
    const res = data?.items.filter((le) => le.id !== legalEntityId) ?? [];
    return res.map((le) => ({
      id: le.id,
      label: le.name,
      value: le.id,
    }));
  }, [data]);

  const disabled = useMemo(() => {
    if (mode === 'manual') {
      return periods.length === 0;
    }
    return selectedSourceId == null;
  }, [mode, periods.length, selectedSourceId]);

  const currentLE = data?.items.find((le) => le.id === legalEntityId);

  return (
    <Modal
      classes={{
        body: 'bg-neutral-000',
      }}
      header={
        <ModalHeaderWithSubtitle
          title="Create Underwriting Budget"
          subtitle={currentLE?.name}
          order="subtitle-title"
        />
      }
      actions={
        <ModalActions alignItems="space-between">
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="success"
            onClick={() => {
              if (mode === 'from_source') {
                asserts(selectedSourceId);

                onSubmit?.({
                  source_legal_entity_id: selectedSourceId,
                });
                return;
              }

              onSubmit?.({
                date_from: periods[0].period,
                date_to: periods.at(-1)!.period,
              });
            }}
            disabled={disabled}
          >
            Create Budget
          </Button>
        </ModalActions>
      }
      toggle={onClose}
    >
      <div className="flex flex-col gap-2">
        <StepsFormStepv2
          activated
          stepNumber={1}
          title="Choose how to create the budget"
          required
        >
          <SelectableButtonGroup
            buttons={[
              {
                title: 'Manually',
                text: 'Set Custom Dates',
                value: 'manual',
                active: mode === 'manual',
              },
              {
                title: 'From Source',
                text: 'Copy Structure',
                value: 'from_source',
                active: mode === 'from_source',
              },
            ]}
            onSelect={(v) => {
              setMode(v as typeof mode);
            }}
          />
        </StepsFormStepv2>

        {mode === 'manual' && (
          <StepsFormStepv2 stepNumber={2} required title="Set Dates">
            <FlexibleFilterByPeriods
              possiblePeriods={genPossiblePeriods()}
              popoverProps={{
                placement: 'bottom-start',
              }}
              periodItems={periods}
              filterByPeriodsType="mtd"
              onUpdatePeriodItems={(v) => {
                setPeriods(v);
              }}
              initialOpenedYear={dayjs().year().toString()}
              isSingleSelection
              fromToMode
            />
          </StepsFormStepv2>
        )}
        {mode === 'from_source' && (
          <StepsFormStepv2 stepNumber={2} required title="Select Source">
            <Select
              popoverProps={{
                appendToBody: true,
              }}
              placeholder={isFetching ? 'Loading...' : ''}
              disabled={isFetching}
              onSelectedChange={(s) => {
                if (s == null) return;

                assertIsSelectOption(s);

                setSelectedSourceId(s.id);
              }}
              selected={leOptions.find((o) => o.id === selectedSourceId)}
              spanContainer="w-full"
              classes={{
                input: 'w-full bg-white',
              }}
              options={leOptions}
            />
          </StepsFormStepv2>
        )}
      </div>
    </Modal>
  );
};
