import { settingsCoreLegalEntitiesGeneratedApi } from '@/entities/core/legalEntity/api/settingsCoreLegalEntitiesGeneratedApi';

export const settingsCoreLegalEntitiesEnhancedApi =
  settingsCoreLegalEntitiesGeneratedApi.enhanceEndpoints({
    addTagTypes: [
      'SettingsCoreLegalEntities',
      'SettingsCoreLegalEntitiesMeta',
      'SettingsCoreLegalEntitiesById',
      'SettingsCoreLegalEntitiesAvailableRawDataPeriods',
    ],
    endpoints: {
      getApiSettingsCoreLegalEntities: {
        providesTags: ['SettingsCoreLegalEntities'],
      },
      getApiSettingsCoreLegalEntitiesMeta: {
        providesTags: ['SettingsCoreLegalEntitiesMeta'],
      },
      getApiSettingsCoreLegalEntitiesById: {
        providesTags: ['SettingsCoreLegalEntitiesById'],
      },
      getApiSettingsCoreLegalEntitiesAvailableRawDataPeriods: {
        providesTags: ['SettingsCoreLegalEntitiesAvailableRawDataPeriods'],
      },
      postApiSettingsCoreLegalEntities: {
        invalidatesTags: ['SettingsCoreLegalEntities'],
      },
      putApiSettingsCoreLegalEntitiesById: {
        invalidatesTags: [
          'SettingsCoreLegalEntities',
          'SettingsCoreLegalEntitiesById',
        ],
      },
      postApiSettingsCoreLegalEntitiesByIdExclude: {
        invalidatesTags: [
          'SettingsCoreLegalEntities',
          'SettingsCoreLegalEntitiesById',
        ],
      },
      putApiSettingsCoreLegalEntitiesByIdManagementPeriodGroupsAndType: {
        invalidatesTags: [
          'SettingsCoreLegalEntities',
          'SettingsCoreLegalEntitiesMeta',
          'SettingsCoreLegalEntitiesById',
          'SettingsCoreLegalEntitiesAvailableRawDataPeriods',
        ],
      },
      postApiSettingsCoreLegalEntitiesAvailableRawDataPeriodsByIdExclusion: {
        invalidatesTags: [
          'SettingsCoreLegalEntitiesMeta',
          'SettingsCoreLegalEntitiesAvailableRawDataPeriods',
        ],
      },
      deleteApiSettingsCoreLegalEntitiesAvailableRawDataPeriodsByIdExclusion: {
        invalidatesTags: [
          'SettingsCoreLegalEntitiesMeta',
          'SettingsCoreLegalEntitiesAvailableRawDataPeriods',
        ],
      },
    },
  });

export const {
  useGetApiSettingsCoreLegalEntitiesLegalEntitiablesQuery,
  useGetApiSettingsCoreLegalEntitiesQuery,
  useGetApiSettingsCoreLegalEntitiesByIdQuery,
  useGetApiSettingsCoreLegalEntitiesMetaQuery,
  useGetApiSettingsCoreLegalEntitiesByIdPermissionsQuery,
  useGetApiSettingsCoreLegalEntitiesAvailableRawDataPeriodsQuery,
  useGetApiSettingsCoreLegalEntitiesAvailableRawDataPeriodsMetaQuery,
  usePostApiSettingsCoreLegalEntitiesMutation,
  usePutApiSettingsCoreLegalEntitiesByIdMutation,
  usePostApiSettingsCoreLegalEntitiesByIdExcludeMutation,
  usePutApiSettingsCoreLegalEntitiesByIdManagementPeriodGroupsAndTypeMutation,
  usePostApiSettingsCoreLegalEntitiesAvailableRawDataPeriodsByIdExclusionMutation,
  useDeleteApiSettingsCoreLegalEntitiesAvailableRawDataPeriodsByIdExclusionMutation,
} = settingsCoreLegalEntitiesEnhancedApi;
