/* eslint-disable no-param-reassign */
import { getComponentSelector } from '@/stories/TreeView/TreeView';
import styles from '@/stories/TreeView/TreeView.module.scss';
import type {
  DatumNode,
  IGetBBox,
  Params,
  Selection,
} from '@/stories/TreeView/types';

export const NODE_LINKS_COMPONENT = 'NodeLinks';

const PADDING_LEFT = 5;
const PADDING_RIGHT = 6;
const GAP = 5;
const ICON_WIDTH = 14;
const TEXT_Y = 4.5;

const [ICON_X, ICON_Y] = [4, -8];
const [RECT_BORDER_RADIUS, RECT_Y] = [6, -10];
const GROUP_Y = -10;

function expandIconAttrs(iconSelection: Selection<SVGSVGElement>) {
  iconSelection.attr('x', ICON_X).attr('y', ICON_Y);
}

function expandRectAttrs(rectSelection: Selection<SVGRectElement>) {
  const resolveWidth = (d: d3.HierarchyNode<DatumNode>) =>
    PADDING_LEFT +
    ICON_WIDTH +
    GAP +
    (d?.nodeLinksBox?.linksText?.width || 0) +
    PADDING_RIGHT;

  rectSelection
    .attr('class', styles.nodeLinksRect)
    .attr('y', RECT_Y)
    .attr('rx', RECT_BORDER_RADIUS)
    .attr('width', resolveWidth);
}

function expandTextAttrs(textSelection: Selection<SVGRectElement>) {
  textSelection
    .attr('class', styles.nodeLinksLen)
    .attr('y', TEXT_Y)
    .attr('x', () => PADDING_LEFT + ICON_WIDTH + GAP)
    .text((d) => d.data?.linkedItems?.length);
}

export function NodeLinks(
  selection: Selection<SVGGElement>,
  lifecycle: 'create' | 'update',
  params: Params,
) {
  if (lifecycle === 'create') {
    const group = selection
      .append('g')
      .attr('data-component', NODE_LINKS_COMPONENT)
      .attr('y', GROUP_Y)
      .attr('class', styles.nodeLinksGroup);

    group.append('text');

    group.append('rect').lower();

    group.nodes().forEach((n) => {
      n?.append(params.leftIcon.documentElement.cloneNode(true));
    });
  }
  if (lifecycle === 'update') {
    selection
      .filter((d) => {
        if (d.nodeLinksBox) {
          d.nodeLinksBox.width = 0;
        }

        return (d.data?.linkedItems?.length || 0) === 0;
      })
      .select(getComponentSelector(NODE_LINKS_COMPONENT))
      .remove();
  }

  selection
    .select(getComponentSelector(NODE_LINKS_COMPONENT))
    .attr(
      'transform',
      (d) => `translate(${(d.textBox?.width ?? 0) + PADDING_RIGHT}, 0)`,
    )
    .each(function (d) {
      const bound: IGetBBox = this.getBBox();
      d.nodeLinksBox = {
        height: bound.height,
        width: bound.width + PADDING_RIGHT,
      };
    });

  selection
    .select(getComponentSelector(NODE_LINKS_COMPONENT))
    .select('svg')
    .call(expandIconAttrs);

  selection
    .select(getComponentSelector(NODE_LINKS_COMPONENT))
    .select('text')
    .call(expandTextAttrs)
    .each(function (d) {
      d.nodeLinksBox.linksText = this.getBBox();
    });

  selection
    .select(getComponentSelector(NODE_LINKS_COMPONENT))
    .select('rect')
    .call(expandRectAttrs);
}
