import { emptySplitApi as api } from '@/app/stores/api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiReconcileForecastingForecastsByForecastIdBudgetsAndBudgetIdFinancialCategories:
      build.query<
        GetApiReconcileForecastingForecastsByForecastIdBudgetsAndBudgetIdFinancialCategoriesApiResponse,
        GetApiReconcileForecastingForecastsByForecastIdBudgetsAndBudgetIdFinancialCategoriesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/reconcile/forecasting/forecasts/${queryArg.forecastId}/budgets/${queryArg.budgetId}/financial_categories`,
        }),
      }),
    putApiReconcileForecastingForecastsByForecastIdBudgetsAndBudgetIdFinancialCategories:
      build.mutation<
        PutApiReconcileForecastingForecastsByForecastIdBudgetsAndBudgetIdFinancialCategoriesApiResponse,
        PutApiReconcileForecastingForecastsByForecastIdBudgetsAndBudgetIdFinancialCategoriesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/reconcile/forecasting/forecasts/${queryArg.forecastId}/budgets/${queryArg.budgetId}/financial_categories`,
          method: 'PUT',
          body: queryArg.body,
        }),
      }),
    getApiReconcileForecastingForecastsByForecastIdBudgetsAndBudgetIdMetrics:
      build.query<
        GetApiReconcileForecastingForecastsByForecastIdBudgetsAndBudgetIdMetricsApiResponse,
        GetApiReconcileForecastingForecastsByForecastIdBudgetsAndBudgetIdMetricsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/reconcile/forecasting/forecasts/${queryArg.forecastId}/budgets/${queryArg.budgetId}/metrics`,
          params: { query: queryArg.query },
        }),
      }),
    postApiReconcileForecastingForecastsByForecastIdBudgetsAndBudgetIdMetrics:
      build.mutation<
        PostApiReconcileForecastingForecastsByForecastIdBudgetsAndBudgetIdMetricsApiResponse,
        PostApiReconcileForecastingForecastsByForecastIdBudgetsAndBudgetIdMetricsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/reconcile/forecasting/forecasts/${queryArg.forecastId}/budgets/${queryArg.budgetId}/metrics`,
          method: 'POST',
          body: queryArg.body,
        }),
      }),
    deleteApiReconcileForecastingForecastsByForecastIdBudgetsAndBudgetIdMetricsBudgetMetricId:
      build.mutation<
        DeleteApiReconcileForecastingForecastsByForecastIdBudgetsAndBudgetIdMetricsBudgetMetricIdApiResponse,
        DeleteApiReconcileForecastingForecastsByForecastIdBudgetsAndBudgetIdMetricsBudgetMetricIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/reconcile/forecasting/forecasts/${queryArg.forecastId}/budgets/${queryArg.budgetId}/metrics/${queryArg.budgetMetricId}`,
          method: 'DELETE',
        }),
      }),
    putApiReconcileForecastingForecastsByForecastIdBudgetsAndBudgetIdValuesBulk:
      build.mutation<
        PutApiReconcileForecastingForecastsByForecastIdBudgetsAndBudgetIdValuesBulkApiResponse,
        PutApiReconcileForecastingForecastsByForecastIdBudgetsAndBudgetIdValuesBulkApiArg
      >({
        query: (queryArg) => ({
          url: `/api/reconcile/forecasting/forecasts/${queryArg.forecastId}/budgets/${queryArg.budgetId}/values/bulk`,
          method: 'PUT',
          body: queryArg.body,
        }),
      }),
    getApiReconcileForecastingForecastsByForecastIdBudgets: build.query<
      GetApiReconcileForecastingForecastsByForecastIdBudgetsApiResponse,
      GetApiReconcileForecastingForecastsByForecastIdBudgetsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/reconcile/forecasting/forecasts/${queryArg.forecastId}/budgets`,
        params: { year: queryArg.year },
      }),
    }),
    putApiReconcileForecastingForecastsByForecastIdBudgetsAndId: build.mutation<
      PutApiReconcileForecastingForecastsByForecastIdBudgetsAndIdApiResponse,
      PutApiReconcileForecastingForecastsByForecastIdBudgetsAndIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/reconcile/forecasting/forecasts/${queryArg.forecastId}/budgets/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    deleteApiReconcileForecastingForecastsByForecastIdBudgetsAndId:
      build.mutation<
        DeleteApiReconcileForecastingForecastsByForecastIdBudgetsAndIdApiResponse,
        DeleteApiReconcileForecastingForecastsByForecastIdBudgetsAndIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/reconcile/forecasting/forecasts/${queryArg.forecastId}/budgets/${queryArg.id}`,
          method: 'DELETE',
        }),
      }),
    getApiReconcileForecastingForecastsByForecastIdBudgetsAndId: build.query<
      GetApiReconcileForecastingForecastsByForecastIdBudgetsAndIdApiResponse,
      GetApiReconcileForecastingForecastsByForecastIdBudgetsAndIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/reconcile/forecasting/forecasts/${queryArg.forecastId}/budgets/${queryArg.id}`,
      }),
    }),
    putApiReconcileForecastingForecastsByForecastIdBudgetsAndBudgetIdCopyFromSource:
      build.mutation<
        PutApiReconcileForecastingForecastsByForecastIdBudgetsAndBudgetIdCopyFromSourceApiResponse,
        PutApiReconcileForecastingForecastsByForecastIdBudgetsAndBudgetIdCopyFromSourceApiArg
      >({
        query: (queryArg) => ({
          url: `/api/reconcile/forecasting/forecasts/${queryArg.forecastId}/budgets/${queryArg.budgetId}/copy_from_source`,
          method: 'PUT',
          body: queryArg.body,
        }),
      }),
    putApiReconcileForecastingForecastsByForecastIdBudgetsAndBudgetIdClean:
      build.mutation<
        PutApiReconcileForecastingForecastsByForecastIdBudgetsAndBudgetIdCleanApiResponse,
        PutApiReconcileForecastingForecastsByForecastIdBudgetsAndBudgetIdCleanApiArg
      >({
        query: (queryArg) => ({
          url: `/api/reconcile/forecasting/forecasts/${queryArg.forecastId}/budgets/${queryArg.budgetId}/clean`,
          method: 'PUT',
          params: { line_items_only: queryArg.lineItemsOnly },
        }),
      }),
    getApiReconcileForecastingForecastsByForecastIdBudgetsAndBudgetIdExport:
      build.query<
        GetApiReconcileForecastingForecastsByForecastIdBudgetsAndBudgetIdExportApiResponse,
        GetApiReconcileForecastingForecastsByForecastIdBudgetsAndBudgetIdExportApiArg
      >({
        query: (queryArg) => ({
          url: `/api/reconcile/forecasting/forecasts/${queryArg.forecastId}/budgets/${queryArg.budgetId}/export`,
        }),
      }),
    postApiReconcileForecastingForecastsByForecastIdBudgetsAndBudgetIdImport:
      build.mutation<
        PostApiReconcileForecastingForecastsByForecastIdBudgetsAndBudgetIdImportApiResponse,
        PostApiReconcileForecastingForecastsByForecastIdBudgetsAndBudgetIdImportApiArg
      >({
        query: (queryArg) => ({
          url: `/api/reconcile/forecasting/forecasts/${queryArg.forecastId}/budgets/${queryArg.budgetId}/import`,
          method: 'POST',
          body: queryArg.body,
        }),
      }),
    getApiReconcileForecastingForecasts: build.query<
      GetApiReconcileForecastingForecastsApiResponse,
      GetApiReconcileForecastingForecastsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/reconcile/forecasting/forecasts`,
        params: {
          created_at: queryArg.createdAt,
          updated_at: queryArg.updatedAt,
          creator: queryArg.creator,
        },
      }),
    }),
    postApiReconcileForecastingForecasts: build.mutation<
      PostApiReconcileForecastingForecastsApiResponse,
      PostApiReconcileForecastingForecastsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/reconcile/forecasting/forecasts`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    getApiReconcileForecastingForecastsById: build.query<
      GetApiReconcileForecastingForecastsByIdApiResponse,
      GetApiReconcileForecastingForecastsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/reconcile/forecasting/forecasts/${queryArg.id}`,
      }),
    }),
    putApiReconcileForecastingForecastsById: build.mutation<
      PutApiReconcileForecastingForecastsByIdApiResponse,
      PutApiReconcileForecastingForecastsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/reconcile/forecasting/forecasts/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    deleteApiReconcileForecastingForecastsById: build.mutation<
      DeleteApiReconcileForecastingForecastsByIdApiResponse,
      DeleteApiReconcileForecastingForecastsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/reconcile/forecasting/forecasts/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    getApiReconcileForecastingForecastsByForecastIdYears: build.query<
      GetApiReconcileForecastingForecastsByForecastIdYearsApiResponse,
      GetApiReconcileForecastingForecastsByForecastIdYearsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/reconcile/forecasting/forecasts/${queryArg.forecastId}/years`,
      }),
    }),
    postApiReconcileForecastingForecastsByForecastIdYears: build.mutation<
      PostApiReconcileForecastingForecastsByForecastIdYearsApiResponse,
      PostApiReconcileForecastingForecastsByForecastIdYearsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/reconcile/forecasting/forecasts/${queryArg.forecastId}/years`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as reconcileForecastingEntitiesGeneratedApi };
export type GetApiReconcileForecastingForecastsByForecastIdBudgetsAndBudgetIdFinancialCategoriesApiResponse =
  /** status 200 success */ {
    tree: ForecastingBudgetFinancialCategoriesTreeNode[];
  };
export type GetApiReconcileForecastingForecastsByForecastIdBudgetsAndBudgetIdFinancialCategoriesApiArg =
  {
    forecastId: string;
    budgetId: string;
  };
export type PutApiReconcileForecastingForecastsByForecastIdBudgetsAndBudgetIdFinancialCategoriesApiResponse =
  unknown;
export type PutApiReconcileForecastingForecastsByForecastIdBudgetsAndBudgetIdFinancialCategoriesApiArg =
  {
    forecastId: string;
    budgetId: string;
    body: {
      financial_category_ids: number[];
    };
  };
export type GetApiReconcileForecastingForecastsByForecastIdBudgetsAndBudgetIdMetricsApiResponse =
  /** status 200 success */ {
    items: ForecastingBudgetMetric[];
  };
export type GetApiReconcileForecastingForecastsByForecastIdBudgetsAndBudgetIdMetricsApiArg =
  {
    forecastId: string;
    budgetId: string;
    query?: string;
  };
export type PostApiReconcileForecastingForecastsByForecastIdBudgetsAndBudgetIdMetricsApiResponse =
  unknown;
export type PostApiReconcileForecastingForecastsByForecastIdBudgetsAndBudgetIdMetricsApiArg =
  {
    forecastId: string;
    budgetId: string;
    body: {
      metric_id: string;
    };
  };
export type DeleteApiReconcileForecastingForecastsByForecastIdBudgetsAndBudgetIdMetricsBudgetMetricIdApiResponse =
  unknown;
export type DeleteApiReconcileForecastingForecastsByForecastIdBudgetsAndBudgetIdMetricsBudgetMetricIdApiArg =
  {
    forecastId: string;
    budgetId: string;
    budgetMetricId: string;
  };
export type PutApiReconcileForecastingForecastsByForecastIdBudgetsAndBudgetIdValuesBulkApiResponse =
  /** status 200 success */ ForecastingBudget;
export type PutApiReconcileForecastingForecastsByForecastIdBudgetsAndBudgetIdValuesBulkApiArg =
  {
    forecastId: string;
    budgetId: string;
    body: (
      | {
          period_key: string;
          budget_financial_category_id: string;
          cents: number | null;
        }
      | {
          period_key: string;
          budget_metric_id: string;
          value: number | null;
        }
    )[];
  };
export type GetApiReconcileForecastingForecastsByForecastIdBudgetsApiResponse =
  /** status 200 success */ {
    items?: ForecastingBudgetPreview[];
  };
export type GetApiReconcileForecastingForecastsByForecastIdBudgetsApiArg = {
  forecastId: string;
  year?: number;
};
export type PutApiReconcileForecastingForecastsByForecastIdBudgetsAndIdApiResponse =
  /** status 200 Budget updated successfully */ ForecastingBudget;
export type PutApiReconcileForecastingForecastsByForecastIdBudgetsAndIdApiArg =
  {
    forecastId: string;
    id: string;
    body: {
      budget: {
        state: 'draft' | 'published';
      };
    };
  };
export type DeleteApiReconcileForecastingForecastsByForecastIdBudgetsAndIdApiResponse =
  unknown;
export type DeleteApiReconcileForecastingForecastsByForecastIdBudgetsAndIdApiArg =
  {
    forecastId: string;
    id: string;
  };
export type GetApiReconcileForecastingForecastsByForecastIdBudgetsAndIdApiResponse =
  /** status 200 success */ ForecastingBudget;
export type GetApiReconcileForecastingForecastsByForecastIdBudgetsAndIdApiArg =
  {
    forecastId: string;
    id: string;
  };
export type PutApiReconcileForecastingForecastsByForecastIdBudgetsAndBudgetIdCopyFromSourceApiResponse =
  /** status 200 successfully copied from source budget */ ForecastingBudget;
export type PutApiReconcileForecastingForecastsByForecastIdBudgetsAndBudgetIdCopyFromSourceApiArg =
  {
    forecastId: string;
    budgetId: string;
    body: {
      source_budget_id: string;
    };
  };
export type PutApiReconcileForecastingForecastsByForecastIdBudgetsAndBudgetIdCleanApiResponse =
  /** status 200 successfully cleaned up budget */ ForecastingBudget;
export type PutApiReconcileForecastingForecastsByForecastIdBudgetsAndBudgetIdCleanApiArg =
  {
    forecastId: string;
    budgetId: string;
    /** If true removes line_items but keeps metrics and financial categories */
    lineItemsOnly?: boolean;
  };
export type GetApiReconcileForecastingForecastsByForecastIdBudgetsAndBudgetIdExportApiResponse =
  unknown;
export type GetApiReconcileForecastingForecastsByForecastIdBudgetsAndBudgetIdExportApiArg =
  {
    forecastId: string;
    budgetId: string;
  };
export type PostApiReconcileForecastingForecastsByForecastIdBudgetsAndBudgetIdImportApiResponse =
  /** status 200 success */ ForecastingBudget;
export type PostApiReconcileForecastingForecastsByForecastIdBudgetsAndBudgetIdImportApiArg =
  {
    forecastId: string;
    budgetId: string;
    body: {
      file: Blob;
    };
  };
export type GetApiReconcileForecastingForecastsApiResponse =
  /** status 200 success */ {
    items?: ForecastingForecast[];
  };
export type GetApiReconcileForecastingForecastsApiArg = {
  createdAt?: {
    from?: number | null;
    to?: number | null;
  };
  updatedAt?: {
    from?: number | null;
    to?: number | null;
  };
  creator?: string;
};
export type PostApiReconcileForecastingForecastsApiResponse =
  /** status 200 success */ ForecastingForecast;
export type PostApiReconcileForecastingForecastsApiArg = {
  body: {
    name: string;
    label?: string;
    description?: string;
  };
};
export type GetApiReconcileForecastingForecastsByIdApiResponse =
  /** status 200 success */ ForecastingForecast;
export type GetApiReconcileForecastingForecastsByIdApiArg = {
  id: string;
};
export type PutApiReconcileForecastingForecastsByIdApiResponse =
  /** status 200 success */ ForecastingForecast;
export type PutApiReconcileForecastingForecastsByIdApiArg = {
  id: string;
  body: {
    label?: string;
    description?: string;
  };
};
export type DeleteApiReconcileForecastingForecastsByIdApiResponse = unknown;
export type DeleteApiReconcileForecastingForecastsByIdApiArg = {
  id: string;
};
export type GetApiReconcileForecastingForecastsByForecastIdYearsApiResponse =
  /** status 200 years found */ {
    items?: ForecastingForecastYear[];
  };
export type GetApiReconcileForecastingForecastsByForecastIdYearsApiArg = {
  forecastId: string;
};
export type PostApiReconcileForecastingForecastsByForecastIdYearsApiResponse =
  unknown;
export type PostApiReconcileForecastingForecastsByForecastIdYearsApiArg = {
  forecastId: string;
  body: {
    year: number;
  };
};
export type ForecastingBudgetFinancialCategoriesTreeNode = {
  id: number;
  code: string;
  parentId: number | null;
  path: string;
  classifications: (
    | 'multifamily'
    | 'retail'
    | 'hospitality'
    | 'office'
    | 'senior_living'
    | 'student'
    | 'mixed_use'
    | 'spv'
    | 'op_co'
    | 'commercial'
  )[];
  classificationMismatch: boolean;
  budgetFinancialCategory: {
    id: string;
  } | null;
  children: ForecastingBudgetFinancialCategoriesTreeNode[];
};
export type BudgetMetric = {
  id: string;
  label: string;
  name: string;
  valueType: 'number' | 'percentage' | 'currency' | 'sqft' | 'metric' | 'date';
  periodAggregationStrategy: 'first' | 'last' | 'sum';
  description: string | null;
};
export type ForecastingBudgetMetric = BudgetMetric & {
  budgetMetric: {
    id: string;
  } | null;
};
export type ForecastingBudgetFinancialCategoryRow = {
  id: string | null;
  categoryId: number;
  label: string;
  values: {
    [key: string]: number;
  };
  children: ForecastingBudgetFinancialCategoryRow[];
};
export type ForecastingBudget = {
  id: string;
  forecast: {
    id: string;
    name: string;
    label: string;
  };
  legalEntity: {
    id: string;
    code: string;
    name: string;
    assetName: string;
  };
  year: number;
  state: 'no_budget' | 'draft' | 'published';
  periods: {
    key: string;
    date: string;
  }[];
  budgetFinancialCategoryRows: ForecastingBudgetFinancialCategoryRow[];
  budgetMetricRows: {
    id: string;
    label: string;
    valueType:
      | 'number'
      | 'percentage'
      | 'currency'
      | 'sqft'
      | 'metric'
      | 'date';
    values: {
      [key: string]: number;
    };
  }[];
};
export type UnprocessableEntity = {
  errors?: string[];
};
export type ForecastingBudgetPreview = {
  id: string;
  forecast: {
    id: string;
    name: string;
    label: string;
  };
  legalEntity: {
    id: string;
    code: string;
    name: string;
    assetName: string;
  };
  year: number;
  state: 'no_budget' | 'draft' | 'published';
  updatedAt: number;
  periods: {
    key: string;
    date: string;
  }[];
};
export type Forbidden = {
  errors?: string[];
};
export type ForecastingForecast = {
  id: string;
  name: string;
  label: string;
  description: string | null;
  years?: number[];
  createdBy: {
    id: string;
    fullName: string;
  };
  lastBudgetsUpdatedAt?: number | null;
  createdAt: number;
  updatedAt: number;
};
export type ForecastingForecastYear = {
  year: number;
  noBudgetCount: number;
  draftCount: number;
  publishedCount: number;
  createdAt: number;
  updatedAt: number;
  forecast: {
    id: string;
    name: string;
    label: string;
  };
};
