import { KpiTableWidgetColumnType } from '@/bundles/Shared/widgets/dashboard/widgets/kpiTable';
import {
  DEFAULT_NUMBER_VALUE_DISPLAY_OPTIONS,
  DEFAULT_TEXT_VALUE_DISPLAY_OPTIONS,
} from '@/shared/lib/formatting/displayOptions';
import { transformEmptyObjectToUndefined } from '@/shared/lib/yup';
import {
  PERIOD_SHIFT_SCHEMA,
  PERIOD_TYPE_SCHEMA,
  TOTAL_CALCULATION_STRATEGIES,
} from 'bundles/Shared/widgets/dashboard/widgets/common/config';
import {
  transformPeriodShiftDtoToForm,
  transformPeriodTypeDtoToForm,
} from 'bundles/Shared/widgets/dashboard/widgets/common/lib/config';
import {
  NUMERIC_COLUMN_SETTINGS_FORM_SCHEMA,
  SPARKLINE_COLUMN_SETTINGS_FORM_SCHEMA,
  TEXT_COLUMN_SETTINGS_FORM_SCHEMA,
  transformColumnSettingsToForm,
  WIDGET_TABLE_WIDGET_COLUMN_TYPES,
} from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/form';
import { TableVizConfigColumn } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/model';
import {
  KpiTableSingleDateWidgetConfig,
  KpiTableSingleDateWidgetConfigColumn,
} from 'bundles/Shared/widgets/dashboard/widgets/kpiTableSingleDate';
import { findFirstColumnSettingsInGroup } from 'bundles/Shared/widgets/dashboard/widgets/kpiTableSingleDate/config/updaters';
import { pick } from 'lodash-es';
import * as yup from 'yup';

import { ADJUSTMENT_EXPRESSION_SCHEMA } from 'bundles/Shared/widgets/dashboard/widgets/common/features/adjustments/config/base';

export type KpiTableSingleDateWidgetConfigColumnForm = yup.InferType<
  typeof KPI_TABLE_SINGLE_DATE_WIDGET_CONFIG_NUMERIC_COLUMN_SCHEMA
>;

export const DEFAULT_KPI_TABLE_SINGLE_DATE_WIDGET_CONFIG_NUMERIC_COLUMN: Partial<KpiTableSingleDateWidgetConfigColumnForm> =
  {
    type: 'numeric',
    value_display_options: DEFAULT_NUMBER_VALUE_DISPLAY_OPTIONS,
    adjustment: null,
    total_calculation_strategy: 'evaluate',
  };

const DEFAULT_KPI_TABLE_SINGLE_DATE_WIDGET_CONFIG_TEXT_COLUMN: Partial<KpiTableSingleDateWidgetConfigColumnForm> =
  {
    type: 'text',
    value_display_options: DEFAULT_TEXT_VALUE_DISPLAY_OPTIONS,
  };

const DEFAULT_KPI_TABLE_SINGLE_DATE_WIDGET_CONFIG_SPARKLINE_COLUMN: Partial<KpiTableSingleDateWidgetConfigColumnForm> =
  {
    type: 'sparkline',
    value_display_options: DEFAULT_NUMBER_VALUE_DISPLAY_OPTIONS,
    adjustment: null,
  };

const KPI_TABLE_SINGLE_DATE_WIDGET_CONFIG_BASE_COLUMN_SCHEMA = yup.object({
  key: yup.number(),
  label: yup.string().required(),
  type: yup
    .string()
    .oneOf(Object.values(WIDGET_TABLE_WIDGET_COLUMN_TYPES))
    .required(),
  expression: yup.string().required(),
  period_type: PERIOD_TYPE_SCHEMA.required(),
  period_shift: PERIOD_SHIFT_SCHEMA.nullable()
    .optional()
    .transform(transformEmptyObjectToUndefined)
    .default(undefined),
});

export const KPI_TABLE_SINGLE_DATE_WIDGET_CONFIG_NUMERIC_COLUMN_SCHEMA =
  NUMERIC_COLUMN_SETTINGS_FORM_SCHEMA.concat(
    KPI_TABLE_SINGLE_DATE_WIDGET_CONFIG_BASE_COLUMN_SCHEMA,
  ).shape({
    adjustment: ADJUSTMENT_EXPRESSION_SCHEMA.nullable().optional(),
    total_calculation_strategy: yup
      .string()
      .oneOf(Object.values(TOTAL_CALCULATION_STRATEGIES))
      .nullable()
      .optional()
      .default(undefined),
  });

export const KPI_TABLE_SINGLE_DATE_WIDGET_CONFIG_TEXT_COLUMN_SCHEMA =
  TEXT_COLUMN_SETTINGS_FORM_SCHEMA.concat(
    KPI_TABLE_SINGLE_DATE_WIDGET_CONFIG_BASE_COLUMN_SCHEMA,
  );

export const KPI_TABLE_SINGLE_DATE_WIDGET_CONFIG_SPARKLINE_COLUMN_SCHEMA =
  SPARKLINE_COLUMN_SETTINGS_FORM_SCHEMA.concat(
    KPI_TABLE_SINGLE_DATE_WIDGET_CONFIG_BASE_COLUMN_SCHEMA,
  );

export const getKpiTableSingleDateResolver = (
  columnType: KpiTableWidgetColumnType,
) => {
  if (columnType === 'numeric') {
    return KPI_TABLE_SINGLE_DATE_WIDGET_CONFIG_NUMERIC_COLUMN_SCHEMA;
  }
  if (columnType === 'text') {
    return KPI_TABLE_SINGLE_DATE_WIDGET_CONFIG_TEXT_COLUMN_SCHEMA;
  }
  return KPI_TABLE_SINGLE_DATE_WIDGET_CONFIG_SPARKLINE_COLUMN_SCHEMA;
};

export const getKpiTableSingleDateDefaultColumn = (
  columnType: KpiTableWidgetColumnType,
) => {
  if (columnType === 'numeric') {
    return DEFAULT_KPI_TABLE_SINGLE_DATE_WIDGET_CONFIG_NUMERIC_COLUMN;
  }
  if (columnType === 'text') {
    return DEFAULT_KPI_TABLE_SINGLE_DATE_WIDGET_CONFIG_TEXT_COLUMN;
  }
  return DEFAULT_KPI_TABLE_SINGLE_DATE_WIDGET_CONFIG_SPARKLINE_COLUMN;
};

export const transformColumnConfigToForm = (
  columnConfig: KpiTableSingleDateWidgetConfigColumn,
  columnSettings: TableVizConfigColumn,
): KpiTableSingleDateWidgetConfigColumnForm => {
  return {
    key: columnConfig.key,
    label: columnConfig.label,
    expression: columnConfig.expression,
    adjustment: columnConfig.adjustment_expression,
    period_shift: transformPeriodShiftDtoToForm(columnConfig.period_shift),
    period_type: transformPeriodTypeDtoToForm(columnConfig.period_type),
    total_calculation_strategy: columnConfig.total_calculation_strategy,
    history_size: columnConfig.history_size,
    ...transformColumnSettingsToForm(columnSettings),
  };
};

const pickColumnFieldsFromParentGroup = ({
  vizConfig,
  groupId,
  columnsConfig,
}: {
  vizConfig: KpiTableSingleDateWidgetConfig['viz_config'];
  columnsConfig: KpiTableSingleDateWidgetConfigColumn[];
  groupId: string;
}) => {
  const group = vizConfig.column_groups.find((cg) => cg.group_id === groupId);
  const cantOverridePeriod = group?.child_can_override_period === false;
  const { firstColumn, firstColumnSettingsInGroup } =
    findFirstColumnSettingsInGroup({
      columnsConfig,
      groupId: groupId ?? '',
      vizConfig,
    });

  return group && cantOverridePeriod && firstColumnSettingsInGroup
    ? pick(
        transformColumnConfigToForm(firstColumn, firstColumnSettingsInGroup),
        ['period_shift', 'period_type'],
      )
    : {};
};

export const transformConfigToColumnForm = ({
  widgetConfig,
  columnConfig,
  groupId,
  columnSettings,
  columnType,
}: {
  widgetConfig: KpiTableSingleDateWidgetConfig;
  columnType: KpiTableWidgetColumnType;
  groupId?: string;
  columnConfig?: KpiTableSingleDateWidgetConfigColumn;
  columnSettings?: TableVizConfigColumn;
}): KpiTableSingleDateWidgetConfigColumnForm => {
  return {
    ...getKpiTableSingleDateDefaultColumn(columnType),
    ...(groupId &&
      pickColumnFieldsFromParentGroup({
        vizConfig: widgetConfig.viz_config,
        groupId,
        columnsConfig: widgetConfig.columns,
      })),
    ...(columnConfig &&
      columnSettings &&
      transformColumnConfigToForm(columnConfig, columnSettings)),
  };
};
