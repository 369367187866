import { ComponentProps } from 'react';
import { Modal } from 'stories/Modals/Modal/Modal';
import { EMPTY_PERMISSIONS } from '@/bundles/Shared/components/Permissions/helpers/constants';
import { MetaState } from '@/bundles/Shared/components/Permissions/EditPermissionsModal/types';

export const MODAL_PROPS = {
  header: 'Configure permissions',
  scrollable: false,
  classes: {
    body: '!p-0 bg-light relative',
    footer: 'justify-end pt-4 modal-footer_shadow',
  },
  bodyPadding: '0px',
  contentClassName: 'modal-content-h-700',
} as const satisfies Partial<ComponentProps<typeof Modal>>;

export const EMPTY_MODAL_STATE: MetaState = {
  initialState: EMPTY_PERMISSIONS,
  userOptions: [],
  tagOptions: [],
  roleOptions: [],
  investementEntityOptions: [],
};

export const SIDE_TAB_ITEM_ACTIVE_CSS_CLASS =
  'after:content-[""] after:bg-blue after:absolute after:top-0 after:w-[4px] after:h-full after:left-0 after:bottom-0 relative';
