import React, { FC, useEffect, useMemo, useState } from 'react';
import { orderBy } from 'lodash-es';
import { nameOf } from 'types/utils';
import Table from 'bundles/Shared/components/Table/Table';
import { Modal } from 'stories/Modals/Modal/Modal';
import LoadingPanel from 'bundles/Shared/components/LoadingPanel';
import { PayApp } from '@/shared/types/reconcile/PayApp';
import dayjs from 'dayjs';
import { IColumn, ISortSettings } from 'bundles/Shared/components/Table/types';
import SearchPayAppInput from '@/bundles/Construction/components/PayApp/SearchPayApp/components/SearchPayAppInput';
import UploadColumn from '@/bundles/Construction/components/PayApp/SearchPayApp/components/UploadColumn';
import ContractColumn from '@/bundles/Construction/components/PayApp/SearchPayApp/components/ContractColumn';
import NumberColumn from '@/bundles/Construction/components/PayApp/SearchPayApp/components/NumberColumn';
import ResetSearchPayApp from '@/bundles/Construction/components/PayApp/SearchPayApp/components/ResetSearchPayApp';

type TPayAppTableColumnNames = keyof Pick<
  PayApp,
  | 'number'
  | 'applicationDate'
  | 'periodToDate'
  | 'contractSumToDate'
  | 'uploadedAt'
>;

interface Props {
  onClose: () => void;
  onSubmit: () => void;
  sPayApps: any;
  handleChoosePayApp: (id: number) => void;
  countPayApp: number;
}

const SearchPayApp: FC<Props> = ({
  onClose,
  onSubmit,
  sPayApps,
  handleChoosePayApp,
  countPayApp,
}) => {
  useEffect(() => {
    onSubmit();
  }, []);
  const [sortSettings, setSortSettings] = useState<ISortSettings<PayApp>>({});

  const assetsWithExtraFields = useMemo(
    () =>
      sPayApps &&
      sPayApps.map((a) => ({
        contractSumToDate: a?.payAppSummary?.contractSumToDate ?? 0,
        ...a,
      })),
    [sPayApps],
  );

  const sortedAssets = useMemo(
    () =>
      sortSettings.sortField != null
        ? orderBy(
            assetsWithExtraFields,
            (payapp) => payapp[sortSettings.sortField],
            sortSettings.sortOrder,
          )
        : assetsWithExtraFields,
    [assetsWithExtraFields, sortSettings],
  );

  const columns = useMemo<IColumn<PayApp>[]>(
    () => [
      {
        dataField: nameOf<TPayAppTableColumnNames>('number'),
        text: 'Number',
        sortable: true,
        formatter: ({ row: payapp }) => (
          <NumberColumn {...payapp} handleChoosePayApp={handleChoosePayApp} />
        ),
      },
      {
        dataField: nameOf<TPayAppTableColumnNames>('applicationDate'),
        text: 'Application Date',
        sortable: true,
        formatter: ({ row }) => dayjs(row.applicationDate).format('DD.MM.YYYY'),
      },
      {
        dataField: nameOf<TPayAppTableColumnNames>('periodToDate'),
        text: 'Period To',
        sortable: true,
        formatter: ({ row }) => dayjs(row.periodToDate).format('DD.MM.YYYY'),
      },
      {
        dataField: nameOf<TPayAppTableColumnNames>('contractSumToDate'),
        text: 'Contract sum',
        sortable: true,
        formatter: ({ row: payapp }) => <ContractColumn {...payapp} />,
      },
      {
        dataField: nameOf<TPayAppTableColumnNames>('uploadedAt'),
        text: 'Uploaded',
        sortable: true,
        formatter: ({ row: payapp }) => <UploadColumn {...payapp} />,
      },
    ],
    [],
  );

  return (
    <Modal
      size="lg"
      header={<SearchPayAppInput onSubmit={onSubmit} />}
      toggle={onClose}
    >
      {sPayApps ? (
        <Table
          items={sortedAssets}
          settings={sortSettings}
          borderLessOutside
          setSettings={(settings) => {
            setSortSettings(settings);
          }}
          defaultColumn={{
            classes: 'align-middle',
          }}
          columns={columns}
          customDocumentsComponent={
            <ResetSearchPayApp
              onSubmit={onSubmit}
              countPayApp={countPayApp}
              sPayApps={sPayApps}
            />
          }
          countRows={countPayApp}
        />
      ) : (
        <LoadingPanel />
      )}
    </Modal>
  );
};

export default SearchPayApp;
