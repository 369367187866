import { ReportDashboardType } from '@/bundles/Shared/entities/dashboard/model/types/types';
import {
  usePostApiSettingsReportBuilderEagleEyeTemplatesByEagleEyeTemplateIdCopyableWidgetSectionsCopyToGroupMutation,
  usePostApiSettingsReportBuilderTemplatesByTemplateIdCopyableWidgetSectionsCopyToGroupMutation,
  usePostApiSettingsReportBuilderTemplatesByTemplateIdObjectDashboardsCopyableWidgetSectionsCopyToGroupMutation,
} from '@/entities/report/reportBuilder/api/settingsReportBuilderTemplatesEnhancedApi';
import { useReportingEntityKindContext } from '@/entities/reporting/context/entityKind';

export const useCopyReportBuilderTemplateWidget = () => {
  const templateKind = useReportingEntityKindContext();
  const [copyWidgetFromTemplateMutation, copyOptions] =
    usePostApiSettingsReportBuilderTemplatesByTemplateIdCopyableWidgetSectionsCopyToGroupMutation();
  const [copyEagleEyeWidgetFromTemplateMutation, copyEagleEyeOptions] =
    usePostApiSettingsReportBuilderEagleEyeTemplatesByEagleEyeTemplateIdCopyableWidgetSectionsCopyToGroupMutation();
  const [copyWidgetFromObjectDashboardMutation, copyOptionsFromObject] =
    usePostApiSettingsReportBuilderTemplatesByTemplateIdObjectDashboardsCopyableWidgetSectionsCopyToGroupMutation();

  const isLoading =
    copyOptions.isLoading ||
    copyOptionsFromObject.isLoading ||
    copyEagleEyeOptions.isLoading;

  const handleCopy = async (params: {
    groupId: string;
    templateId: string;
    sectionsIds: string[];
    source?:
      | ReportDashboardType.OBJECT
      | ReportDashboardType.REPORT_BUILDER_TEMPLATE;
  }) => {
    const getUpdateBySource = () => {
      if (params.source === ReportDashboardType.OBJECT) {
        return copyWidgetFromObjectDashboardMutation;
      }

      if (templateKind === 'eagle_eye') {
        return copyEagleEyeWidgetFromTemplateMutation;
      }
      return copyWidgetFromTemplateMutation;
    };
    return getUpdateBySource()({
      eagleEyeTemplateId: params.templateId,
      templateId: params.templateId,
      body: {
        group_id: params.groupId,
        sections_to_copy_ids: params.sectionsIds,
      },
    });
  };

  return [handleCopy, { isLoading }] as const;
};
