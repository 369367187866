import {
  PERIOD_SHIFT_SCHEMA,
  PERIOD_TYPE_SCHEMA,
  TOTAL_CALCULATION_STRATEGIES,
} from '@/bundles/Shared/widgets/dashboard/widgets/common/config';
import {
  transformPeriodShiftDtoToForm,
  transformPeriodTypeDtoToForm,
} from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/config';
import {
  NUMERIC_COLUMN_SETTINGS_FORM_SCHEMA,
  SPARKLINE_COLUMN_SETTINGS_FORM_SCHEMA,
  transformColumnSettingsToForm,
  WidgetTableColumnSettingsColumnType,
} from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/table/form';
import { TableVizConfigColumn } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/table/model';
import { UnitTypeTableWidgetConfigColumn } from '@/bundles/Shared/widgets/dashboard/widgets/unitTypeTable/model';
import { yupResolver } from '@hookform/resolvers/yup';
import { transformEmptyObjectToUndefined } from '@/shared/lib/yup';
import * as yup from 'yup';
import { DEFAULT_NUMBER_VALUE_DISPLAY_OPTIONS } from '@/shared/lib/formatting/displayOptions';
import { DEFAULT_WIDGET_HISTORY_SIZE } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/fields/HistorySizeField';
export const UNIT_TYPE_TABLE_WIDGET_CONFIG_NUMERIC_COLUMN_SCHEMA =
  NUMERIC_COLUMN_SETTINGS_FORM_SCHEMA.shape({
    key: yup.number(),
    label: yup.string().required(),
    expression: yup.string().required(),
    period_type: PERIOD_TYPE_SCHEMA.required(),
    period_shift: PERIOD_SHIFT_SCHEMA.nullable()
      .optional()
      .transform(transformEmptyObjectToUndefined)
      .default(undefined),
    unit_type_calculation_strategy: yup
      .string()
      .oneOf(Object.values(TOTAL_CALCULATION_STRATEGIES))
      .nullable()
      .optional()
      .default(undefined),
    total_calculation_strategy: yup
      .string()
      .oneOf(Object.values(TOTAL_CALCULATION_STRATEGIES))
      .nullable()
      .optional()
      .default(undefined),
  });

export type UnitTypeTableWidgetConfigNumericColumnForm = yup.InferType<
  typeof UNIT_TYPE_TABLE_WIDGET_CONFIG_NUMERIC_COLUMN_SCHEMA
>;

export const UNIT_TYPE_TABLE_WIDGET_CONFIG_SPARKLINE_COLUMN_SCHEMA =
  UNIT_TYPE_TABLE_WIDGET_CONFIG_NUMERIC_COLUMN_SCHEMA.concat(
    SPARKLINE_COLUMN_SETTINGS_FORM_SCHEMA,
  );

export type UnitTypeTableWidgetConfigSparklineColumnForm = yup.InferType<
  typeof UNIT_TYPE_TABLE_WIDGET_CONFIG_SPARKLINE_COLUMN_SCHEMA
>;

export type UnitTypeTableWidgetConfigColumnForm =
  | UnitTypeTableWidgetConfigNumericColumnForm
  | UnitTypeTableWidgetConfigSparklineColumnForm;

const DEFAULT_UNIT_TYPE_TABLE_WIDGET_CONFIG_NUMERIC_COLUMN: Partial<UnitTypeTableWidgetConfigNumericColumnForm> =
  {
    type: 'numeric',
    value_display_options: DEFAULT_NUMBER_VALUE_DISPLAY_OPTIONS,
    unit_type_calculation_strategy: 'evaluate',
    total_calculation_strategy: 'evaluate',
  };

const DEFAULT_UNIT_TYPE_TABLE_WIDGET_CONFIG_SPARKLINE_COLUMN: Partial<UnitTypeTableWidgetConfigSparklineColumnForm> =
  {
    type: 'sparkline',
    value_display_options: DEFAULT_NUMBER_VALUE_DISPLAY_OPTIONS,
    unit_type_calculation_strategy: 'none',
    total_calculation_strategy: 'none',
    history_size: DEFAULT_WIDGET_HISTORY_SIZE,
    header: {
      hide_title: false,
      hide_subtitle: true,
    },
  };

export const getUnitTypeTableDefaultColumnForm = (
  columnType: WidgetTableColumnSettingsColumnType,
) => {
  if (columnType === 'sparkline') {
    return DEFAULT_UNIT_TYPE_TABLE_WIDGET_CONFIG_SPARKLINE_COLUMN;
  }
  return DEFAULT_UNIT_TYPE_TABLE_WIDGET_CONFIG_NUMERIC_COLUMN;
};

export const getUnitTypeTableResolver = (
  columnType: WidgetTableColumnSettingsColumnType,
) => {
  if (columnType === 'sparkline') {
    return yupResolver(UNIT_TYPE_TABLE_WIDGET_CONFIG_SPARKLINE_COLUMN_SCHEMA);
  }
  return yupResolver(UNIT_TYPE_TABLE_WIDGET_CONFIG_NUMERIC_COLUMN_SCHEMA);
};

export const transformColumnConfigToForm = (
  columnConfig: UnitTypeTableWidgetConfigColumn,
  columnSettings: TableVizConfigColumn,
): UnitTypeTableWidgetConfigNumericColumnForm => {
  return {
    key: columnConfig.key,
    label: columnConfig.label,
    expression: columnConfig.expression,
    period_shift: transformPeriodShiftDtoToForm(columnConfig.period_shift),
    period_type: transformPeriodTypeDtoToForm(columnConfig.period_type),
    unit_type_calculation_strategy:
      columnConfig.unit_type_calculation_strategy === 'evaluate'
        ? undefined
        : columnConfig.unit_type_calculation_strategy,
    total_calculation_strategy:
      columnConfig.total_calculation_strategy === 'evaluate'
        ? undefined
        : columnConfig.total_calculation_strategy,
    history_size: columnConfig.history_size,
    ...transformColumnSettingsToForm(columnSettings),
  };
};
