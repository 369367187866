import React, {
  useCallback,
  useId,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import EditorJS, { EditorConfig, OutputData } from '@editorjs/editorjs';
import styles from '@/widgets/documentation/blockEditor/ui/DocumentationBlockEditor.module.css';
import { EDITOR_JS_TOOLS } from '@/widgets/documentation/blockEditor';
import useEvent from '@/shared/lib/hooks/useEvent';
import { cn } from '@/shared/lib/css/cn';

export const DocumentationBlockEditor = ({
  value,
  onChange,
  config,
}: {
  value: OutputData;
  onChange?: (data: OutputData) => void;
  config?: Omit<EditorConfig, 'value' | 'onChange'>;
}) => {
  const [internalValue, setInternalValue] = useState<OutputData>();
  const editorId = useId();
  const ref = useRef<EditorJS>();

  const syncValue = useEvent(() => {
    const isReady = ref.current?.render != null;
    const isChanged = JSON.stringify(value) !== JSON.stringify(internalValue);
    if (isReady && isChanged) {
      if (value?.blocks == null) {
        ref.current?.clear();
        return;
      }
      ref.current?.render({ blocks: value.blocks });
    }
  });

  const handleDivRef = useCallback(
    (node: HTMLDivElement) => {
      if (node == null) {
        return;
      }
      const editor = new EditorJS({
        ...config,
        holder: editorId,
        tools: EDITOR_JS_TOOLS,
        async onChange(api, event) {
          if (api.readOnly.isEnabled) {
            return;
          }
          const data = await api.saver.save();

          data.blocks = data.blocks.map((block) => {
            if (block.type === 'paragraph') {
              const div = document.createElement('div');
              div.innerHTML = block.data.text;

              div.querySelectorAll('a').forEach((link) => {
                link.setAttribute('target', '_blank');
              });

              block.data.text = div.innerHTML;
            }
            return block;
          });

          setInternalValue(data);
          onChange?.(data);
        },
        onReady() {
          syncValue();
        },
      });
      ref.current = editor;
    },
    [editorId],
  );

  useLayoutEffect(() => {
    ref.current?.readOnly?.toggle(config?.readOnly);
  }, [config?.readOnly]);

  useLayoutEffect(() => {
    syncValue();
  }, [value]);

  useLayoutEffect(() => {
    return () => {
      if (ref.current?.destroy) {
        ref.current.destroy();
      }
    };
  }, []);

  return (
    <div
      className={cn(
        styles.documentationBlockEditor,
        config?.readOnly && styles.readOnly,
      )}
      id={editorId}
      ref={handleDivRef}
    />
  );
};
