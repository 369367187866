import React, { FC } from 'react';
import '@/stories/Tumbler/tumbler.css';
import { cn } from '@/shared/lib/css/cn';
import {
  Control,
  FieldPath,
  FieldValues,
  useController,
} from 'react-hook-form';

interface Props
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  reverse?: boolean;
  classes?: {
    input?: string;
    text?: string;
  };
}

export const Tumbler: FC<Props> = ({
  reverse,
  children,
  className,
  classes,
  ...props
}) => (
  <label
    className={cn('sre-tumbler flex', className, {
      'sre-tumbler_reverse': reverse,
    })}
  >
    <input
      className={cn('sre-tumbler__input', classes?.input)}
      type="checkbox"
      disabled={!props.onChange}
      {...props}
    />
    <div className="sre-tumbler__content flex items-center">
      <div className="sre-tumbler__switcher" />
      {children && (
        <span className={cn('sre-tumbler__text', classes?.text)}>
          {children}
        </span>
      )}
    </div>
  </label>
);

export const TumblerController = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  name,
  control,
  onChange,
  ...props
}: Omit<Props, 'checked'> & {
  control: Control<TFieldValues>;
  name: TName;
}) => {
  const { field } = useController({
    name,
    control,
  });
  return (
    <Tumbler
      checked={field.value as boolean}
      onChange={(e) =>
        onChange ? onChange(e) : field.onChange(e.target.checked)
      }
      {...props}
    />
  );
};

export default Tumbler;
