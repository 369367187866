import { navigate } from '@reach/router';
import FireIcon from 'bundles/FireStation/components/FireIcon/FireIcon';
import { OPEN_OBJECT_MODAL_SEARCH_PARAM } from 'bundles/InvestmentObjects/components/helpers/utils';
import { ROUTES_ROOT, generatePath } from '@/shared/lib/hooks/useNavigation';
import { currentUserIsExternal } from 'lib/permissions';
import * as React from 'react';
import { ComponentProps } from 'react';
import { Badge } from 'stories/Badges/Badge/Badge';
import { LinkButton } from 'stories/LinkButton/LinkButton';
import { IAsset } from 'types/Asset';

interface Props {
  asset: IAsset;
  badgeProps?: Omit<ComponentProps<typeof Badge>, 'children'>;
}

function AssetStageBadge({ asset, badgeProps }: Props) {
  const { stage } = asset;

  if (stage) {
    return (
      <Badge backgroundColor={stage.color} {...badgeProps}>
        {stage.name}
      </Badge>
    );
  }

  if (currentUserIsExternal()) return null;

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const assetUrl = generatePath(ROUTES_ROOT.assets.asset.fullPath, {
      assetSlug: asset.slug,
    });
    // todo navigation move to TRouteQueryParams
    const searchParam = OPEN_OBJECT_MODAL_SEARCH_PARAM;

    navigate(`${assetUrl}?${searchParam}`);
  };

  return (
    <div className="flex items-center gap-s">
      <LinkButton
        fontWeight="bold"
        variant="secondary"
        className="rounded-[8px] bg-blue-light-4 px-s py-xs"
        onClick={handleClick}
      >
        Set Stage
      </LinkButton>
      <FireIcon priority="medium" />
    </div>
  );
}

export default AssetStageBadge;
