import React from 'react';
import getBadgeColor from '@/bundles/UserManagement/components/helpers/getBadgeColor';
import styles from '@/bundles/UserManagement/components/inviteStatusDot/UserInviteStatusDot.module.scss';
import { TUserInviteStatus } from '@/types/User';

interface Props {
  inviteStatus?: TUserInviteStatus;
}

const BORDER_COLORS: Record<TUserInviteStatus, string> = {
  [TUserInviteStatus.INVITED_LONG_TIME_AGO]: 'var(--yellow-light-4)',
  [TUserInviteStatus.CREATED]: 'var(--light-10)',
  [TUserInviteStatus.ACTIVE]: 'var(--green-light-3)',
  [TUserInviteStatus.DEACTIVATED]: 'var(--red-light-4)',
  [TUserInviteStatus.INVITED]: 'var(--violet-light-4)',
};

const UserInviteStatusDot = ({ inviteStatus }: Props) => (
  <div
    className={styles.dot}
    style={{
      color: getBadgeColor(inviteStatus),
      borderColor:
        inviteStatus != null ? BORDER_COLORS[inviteStatus] : 'lightgreen',
    }}
  >
    <span
      className="sre-icon-status-dot"
      style={{ backgroundColor: getBadgeColor(inviteStatus) }}
    />
  </div>
);

export default UserInviteStatusDot;
