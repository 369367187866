import React from 'react';
import { useAppSelector } from '@/shared/lib/hooks/redux';
import {
  activityLogEventFormatter,
  getActivityLogEventIconParams,
} from 'bundles/REconcile/components/development/requisitions/activityLog/utils';
import CommentsPanel from 'bundles/REconcile/components/comments/CommentsPanel/CommentsPanel';
import { IconButton } from 'stories/IconButton/IconButton';
import { HistoryItem } from 'stories/HistoryItem/HistoryItem';
import { CssVar } from '@/shared/config/cssVar';
import { formatUnixDate } from '@/shared/lib/formatting/dates';

interface Props {
  onClose: () => void;
}

function ActivityLogPanel({ onClose }: Props) {
  const activity = useAppSelector(
    (state) => state.developmentBudgetVariance.data.activityLog,
  );

  return (
    <CommentsPanel
      classes={{
        comments: 'p-m',
      }}
      header={
        <div className="flex justify-between w-full items-center">
          <span className="dark-60 inline-semibold">Activity Log</span>
          <IconButton size="l" iconName="close" onClick={onClose} />
        </div>
      }
      comments={
        <div className="flex flex-col">
          {activity.map((a, idx, arr) => (
            <HistoryItem
              key={a.updatedAt}
              icon={{
                ...getActivityLogEventIconParams(a.kind),
                backgroundColor: CssVar.light,
              }}
              insertAdjasentJSX={{
                afterBegin: (
                  <span className="light-60 secondary-regular">
                    {formatUnixDate(a.updatedAt, 'LLL')}
                  </span>
                ),
                afterLabel: (
                  <div className="secondary-regular mt-xxs text-light-60">
                    by {a.whoDoneIt.fullName}
                  </div>
                ),
              }}
              lastItem={idx === arr.length - 1}
              label={
                <span className="dark-60 secondary-regular">
                  {activityLogEventFormatter(a)}
                </span>
              }
            />
          ))}
        </div>
      }
    />
  );
}

export default ActivityLogPanel;
