import React, { SVGProps, useRef } from 'react';
import {
  FORMULA_REFERENCING_ENTITY_TYPES,
  FormulaReferencedInEntity,
  FormulaReferencingEntityType,
} from 'bundles/Shared/entities/formula';
import { groupBy } from 'lodash-es';
import Popover, { PopoverProps } from 'stories/Popover/Popover';
import { IconsId } from 'types/sre-icons';
import Icon from 'stories/Icon/Icon';
import { cn } from '@/shared/lib/css/cn';

import { twMergeExtended } from '@/shared/lib/css/tw-merge';
import { useIsTextTruncated } from '@/shared/lib/hooks/useIsTextTruncated';
import Tooltip from 'stories/Tooltip/Tooltip';

const UsedSvg = ({
  used,
  ...props
}: {
  used: boolean;
} & SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle
      cx="12"
      cy="12"
      r="7.5"
      fill={used ? 'var(--success-030)' : 'var(--success-000)'}
    />
    <circle cx="12" cy="12" r="3" fill="var(--success-055)" />
  </svg>
);

const FORMULA_REFERENCED_IN_ENTITY_TYPE_MAP = {
  formula: {
    label: 'Formula',
    iconName: 'inspectFunction',
  },
  table: {
    label: 'Table',
    iconName: 'table',
  },
  dashboardWidget: {
    label: 'Dashboard Widget',
    // TODO: FE-2170 replace with real icon (complex icons are not supported yet)
    iconName: 'table',
  },
  dashboard: {
    label: 'Dashboard',
    // TODO: FE-2170 replace with real icon (complex icons are not supported yet)
    iconName: 'eagleEyeDashboard',
  },
  pdf: {
    label: 'PDF',
    iconName: 'docPdf',
  },
} as const satisfies Record<
  FormulaReferencingEntityType,
  {
    label: string;
    iconName: IconsId;
  }
>;

export const FormulaReferencingEntityGroupHeader = ({
  type,
  count,
  className,
  children,
}: React.PropsWithChildren<
  {
    type: FormulaReferencingEntityType;
    count: number;
  } & PropsWithClassName
>) => (
  <div
    className={twMergeExtended(
      'secondary-semibold flex items-center gap-1',
      className,
    )}
  >
    <Icon
      className={cn('text-violet-055', count === 0 && 'text-neutral-500')}
      iconName={FORMULA_REFERENCED_IN_ENTITY_TYPE_MAP[type].iconName}
    />
    <span className={cn('text-neutral-800', count === 0 && 'text-neutral-550')}>
      {FORMULA_REFERENCED_IN_ENTITY_TYPE_MAP[type].label}
    </span>
    <div
      className={cn(
        'secondary-regular rounded-md bg-neutral-150 px-1 text-neutral-650',
        count === 0 && 'bg-neutral-050 text-neutral-400',
      )}
    >
      {count}
    </div>
    {children}
  </div>
);

const Label = ({
  count,
  triggered,
  className,
  classes,
}: {
  count: number;
  triggered?: boolean;
  classes?: {
    label?: string;
  };
} & PropsWithClassName) => {
  const entitiesNotEmpty = count > 0;
  return (
    <span
      className={twMergeExtended(
        'flex items-center gap-2 rounded-lg p-1 pr-2',
        entitiesNotEmpty && 'hover:bg-neutral-000',
        triggered && 'bg-neutral-100',
        className,
      )}
    >
      <UsedSvg used={entitiesNotEmpty} />
      {entitiesNotEmpty && (
        <span
          className={cn(
            'secondary-semibold',
            triggered ? 'text-neutral-800' : 'text-info-055',
            classes?.label,
          )}
        >
          {count}
        </span>
      )}
    </span>
  );
};

export const FormulaReferencingEntityGroupList = ({
  children,
}: React.PropsWithChildren) => (
  <div className="flex-col gap bg-neutral-100">{children}</div>
);

export const FormulaReferencingEntityGroup = ({
  children,
  className,
}: React.PropsWithChildren<PropsWithClassName>) => (
  <div
    className={cn('flex flex-col gap-2 bg-neutral-000 p-2', className)}
  >
    {children}
  </div>
);

export const FormulaReferencingEntityLabel = ({ label }: { label: string }) => {
  const ref = useRef<HTMLSpanElement>(null);
  const isTextTruncated = useIsTextTruncated(ref, label);
  return (
    <>
      <span
        ref={ref}
        className="secondary-regular text-ellipsis whitespace-nowrap rounded-lg bg-neutral-050 px-2 py-1 text-neutral-850"
      >
        {label}
      </span>
      <Tooltip reference={ref} disabled={!isTextTruncated} mainText={label} />
    </>
  );
};

export const FormulaReferencingEntityLabelList = ({
  items,
}: {
  items: FormulaReferencedInEntity[];
}) => (
  <div className="flex flex-wrap gap-1">
    {items.map((entity) => (
      <FormulaReferencingEntityLabel key={entity.id} label={entity.name} />
    ))}
  </div>
);

FormulaReferencingEntityGroupList.Item = FormulaReferencingEntityGroup;
FormulaReferencingEntityGroup.Header = FormulaReferencingEntityGroupHeader;

const PopoverComponent = ({
  referencedInEntities,
  hideIfEmpty,
  ...props
}: Omit<PopoverProps, 'template'> & {
  referencedInEntities: FormulaReferencedInEntity[];
  hideIfEmpty?: boolean;
  groupActions?: (entity: FormulaReferencingEntityType) => React.ReactNode;
}) => {
  const groupedEntities = groupBy(referencedInEntities, 'type');
  const entitiesNotEmpty = referencedInEntities.length > 0;

  return (
    <Popover
      trigger="mouseenter"
      placement={'bottom-start'}
      arrowPosition="start"
      className="w-[300px]"
      maxWidth={300}
      disabled={!entitiesNotEmpty}
      template={
        <FormulaReferencingEntityGroupList>
          {FORMULA_REFERENCING_ENTITY_TYPES.map(
            (key) =>
              (!hideIfEmpty || groupedEntities[key]?.length > 0) && (
                <FormulaReferencingEntityGroupList.Item key={key}>
                  <FormulaReferencingEntityGroup.Header
                    type={key}
                    count={groupedEntities[key]?.length ?? 0}
                  />
                  {groupedEntities[key] && (
                    <FormulaReferencingEntityLabelList
                      items={groupedEntities[key]}
                    />
                  )}
                </FormulaReferencingEntityGroupList.Item>
              ),
          )}
        </FormulaReferencingEntityGroupList>
      }
      {...props}
    />
  );
};

export const FormulaInUse = {
  Label,
  UsedSvg,
  Popover: PopoverComponent,
};
