import { MOVE_WIDGET_TO_BOARD_BUTTON_TOOLTIP } from '@/bundles/Settings/components/REport/Dashboards/Dashboard/config';
import { IconButton } from '@/stories/IconButton/IconButton';
import { Popover } from '@/stories/Popover/Popover';
import type { EagleEyeBoard } from 'bundles/Shared/entities/dashboard/model/types/types';
import { useMoveToBoard } from 'bundles/Shared/features/dashboard/moveToBoard';

interface Props {
  boards: Pick<EagleEyeBoard, 'id' | 'name'>[];
  sectionId: string;
  boardId: string;
}

export function MoveToBoardButton({ boardId, sectionId, boards }: Props) {
  const moveToBoard = useMoveToBoard();
  return (
    <Popover
      hiddenArrow
      hideOnExpandedAreaClick
      trigger="click"
      placement="bottom-end"
      className="min-w-[160px] p-1 pt-2"
      zIndex={3}
      appendTo={
        document.getElementById(`section-${sectionId}`) ?? document.body
      }
      template={
        <>
          <span className="secondary-semibold px-2 text-neutral-550">
            Move to
          </span>
          <ul className="flex flex-col gap-1">
            {boards
              .filter((b) => b.id !== boardId)
              .map((b) => (
                // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                <li
                  key={b.id}
                  onClick={() =>
                    moveToBoard({
                      fromBoardId: boardId,
                      toBoardId: b.id,
                      sectionId,
                    })
                  }
                  className="inline-regular cursor-pointer px-2 py-1 text-neutral-850"
                >
                  {b.name}
                </li>
              ))}
          </ul>
        </>
      }
    >
      <IconButton
        size="s"
        tooltipProps={{
          mainText: MOVE_WIDGET_TO_BOARD_BUTTON_TOOLTIP,
        }}
        iconName="relocate"
      />
    </Popover>
  );
}
