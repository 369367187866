import React from 'react';
import { CurrencyFormatter } from 'stories/ValueFormatters/CurrencyFormatter';
import { Icon } from 'stories/Icon/Icon';
import { StatisticLine } from 'stories/filterCard/statistic/StatisticLine';
import FireIcon from 'bundles/FireStation/components/FireIcon/FireIcon';
import { CssVar } from '@/shared/config/cssVar';
import { cn } from '@/shared/lib/css/cn';
import { round } from 'lodash-es';

interface Props extends PropsWithClassName {
  totalAmount: number;
  allocatedAmount: number;
  notDollarAmount: boolean;
}

export const getAllocationFlags = ({
  allocatedAmount,
  totalAmount,
}: {
  totalAmount: number;
  allocatedAmount: number;
}) => {
  const isFullyAllocated = allocatedAmount === totalAmount;
  const isExtraAllocated = allocatedAmount > totalAmount;
  const isNotFullyAllocated = allocatedAmount < totalAmount;

  return {
    isFullyAllocated,
    isExtraAllocated,
    isNotFullyAllocated,
  };
};

export const getAllocationParameters = ({
  allocatedAmount,
  totalAmount,
  precision = 2 as Integer,
}: {
  totalAmount: number;
  allocatedAmount: number;
  precision?: Integer;
}) => {
  const notAllocatedAmount = round(totalAmount - allocatedAmount, precision);

  return {
    ...getAllocationFlags({ allocatedAmount, totalAmount }),
    notAllocatedAmount,
  };
};

function AllocationStatistics({
  totalAmount,
  allocatedAmount,
  className,
  notDollarAmount,
}: Props) {
  const {
    isFullyAllocated,
    isExtraAllocated,
    isNotFullyAllocated,
    notAllocatedAmount,
  } = getAllocationParameters({ totalAmount, allocatedAmount });

  const statistics = [
    {
      color: isExtraAllocated ? CssVar.red : CssVar.green,
      value: allocatedAmount,
    },
    { value: notAllocatedAmount },
  ];

  return (
    <div className={cn('flex flex-col gap-2', className)}>
      <StatisticLine statistics={statistics} />
      <div className="flex items-center justify-center gap-1">
        {isFullyAllocated ? (
          <Icon color="green" iconName="check" />
        ) : (
          <FireIcon priority="high" />
        )}
        {!isFullyAllocated && (
          <span className="text-light-70">
            {!isExtraAllocated &&
              (allocatedAmount > 0
                ? 'You have to allocate the remaining'
                : 'You have to allocate')}
            {isExtraAllocated && "You can't allocate more than"}
          </span>
        )}
        {notDollarAmount ? (
          <>
            {isNotFullyAllocated ? totalAmount - allocatedAmount : totalAmount}
          </>
        ) : (
          <CurrencyFormatter
            classes={{
              wrapper: 'text-dark-60 inline-semibold',
            }}
            value={
              isNotFullyAllocated ? totalAmount - allocatedAmount : totalAmount
            }
          />
        )}
        {isFullyAllocated && (
          <span className="inline-regular text-dark-60">
            is fully allocated
          </span>
        )}
      </div>
    </div>
  );
}

export default AllocationStatistics;
