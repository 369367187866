import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Button } from 'stories/Button/Button';
import { Field } from 'stories/Field/Field';
import { Input } from 'stories/FormControls/Inputs/Input/Input';
import { Modal } from 'stories/Modals/Modal/Modal';
import { cn } from '@/shared/lib/css/cn';

const AddressModal = ({ object, close, update }) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        address_1: yup.string().required('Complete field to proceed!'),
        address_2: yup.string(),
        city: yup.string().required('Complete field to proceed!'),
        state: yup.string().required('Complete field to proceed!'),
        zip: yup.string().required('Complete field to proceed!'),
      }),
    ),
  });
  const { address1, address2, city, state, zip } = object;

  const fields = [address1, address2, city, state, zip];

  const isCreate = fields.every((info) => !info);

  const onSubmit = (formData) => {
    const address = Object.values(formData).join(', ');

    const data = {
      id: object.id,
      slug: object.slug,
      [object.objectType]: { ...formData, address },
    };
    update(data);
    close();
  };

  return (
    <Modal
      toggle={close}
      header={isCreate ? 'Add Address' : 'Edit Address'}
      size="400"
      actions={
        <div className="w-full flex justify-between">
          <Button variant="secondary" onClick={close}>
            Cancel
          </Button>
          <Button variant="success" form="Address" type="submit">
            {isCreate ? 'Add Address' : 'Save Updates'}
          </Button>
        </div>
      }
    >
      <form
        className="flex flex-col gap-4"
        id="Address"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Field labelText="Address 1">
          <Input
            {...register('address_1')}
            size="l"
            defaultValue={address1}
            placeholder="Enter Address 1"
            className={cn({ 'is-invalid': errors.address_1 })}
          />
        </Field>
        <Field labelText="Address 2">
          <Input
            {...register('address_2')}
            size="l"
            defaultValue={address2}
            placeholder="Enter Address 2"
            className={cn({ 'is-invalid': errors.address_2 })}
          />
        </Field>
        <Field labelText="City">
          <Input
            {...register('city')}
            size="l"
            defaultValue={city}
            placeholder="Enter City"
            className={cn({ 'is-invalid': errors.city })}
          />
        </Field>
        <Field labelText="State">
          <Input
            {...register('state')}
            size="l"
            defaultValue={state}
            placeholder="Enter State"
            className={cn({ 'is-invalid': errors.state })}
          />
        </Field>
        <Field labelText="Zip">
          <Input
            {...register('zip')}
            size="l"
            defaultValue={zip}
            placeholder="Enter Zip"
            className={cn({ 'is-invalid': errors.zip })}
          />
        </Field>
      </form>
    </Modal>
  );
};

export default AddressModal;
