import React, { useState } from 'react';
import type {
  AllWidgetTypes,
  ReportDashboardSectionPositionWithId,
} from 'bundles/Shared/entities/dashboard/model/types/types';
import { WIDGETS_CONFIG_MAP } from '@/bundles/Shared/widgets/dashboard/widgets/config';

export const useDashboardWidgetsBarDroppableItem = () => {
  const [droppableItem, setDroppableItem] =
    useState<ReportDashboardSectionPositionWithId | null>(null);

  const droppableItemPlaceholderProps = {
    'data-grid': {
      ...droppableItem,
      x: 0,
      y: 0,
    },
    key: droppableItem?.i,
  } as const;

  const handleDragStart = (e: React.DragEvent<HTMLDivElement>) => {
    const itemType = e.dataTransfer.getData('text/plain') as AllWidgetTypes;
    const { layout } = WIDGETS_CONFIG_MAP[itemType];

    setDroppableItem({
      ...layout,
      x: 0,
      y: 0,
      i: itemType,
    });
  };

  const handleDrop = () => {
    setDroppableItem(null);
  };

  return {
    droppableItem,
    setDroppableItem,
    handleDragStart,
    handleDrop,
    droppableItemPlaceholderProps,
  };
};
