import React, { useEffect, useState } from 'react';
import { WebsitesCollapsableSection } from 'stories/WebsitesCollapsableSection/WebsitesCollapsableSection';
import { LocationCollapsableSection } from 'stories/LocationCollapsableSection/LocationCollapsableSection';
import { FieldsCollapsableSection } from 'stories/FieldsCollapsableSection/FieldsCollapsableSection';
import canManageObject from 'lib/InvestmentObject';

import InvestmentObjectInfoModal from '@/bundles/InvestmentObjects/components/Overview/InvestmentObjectInfo/InvestmentObjectInfoModal';
import AddressModal from '@/bundles/InvestmentObjects/components/Overview/InvestmentObjectInfo/AddressModal';
import WebsiteModal from '@/bundles/InvestmentObjects/components/Overview/InvestmentObjectInfo/WebsiteModal';
import { ILegalEntitiable } from 'types/LegalEntitiable';

const InvestmentObjectDetails = ({
  object,
  withoutAddress,
  update,
  heavyUpdateAddress,
  removeConfirmAlert,
}: {
  object: ILegalEntitiable;
  update: (obj: {
    id: number;
    slug: string;
    [key: 'asset' | 'fund']: ILegalEntitiable;
  }) => void;
  heavyUpdateAddress: (obj: {
    id: number;
    slug: string;
    [key: 'asset' | 'fund']: ILegalEntitiable;
  }) => void;
  removeConfirmAlert: (onRemove: () => void, object: string) => void;
  withoutAddress?: boolean;
}) => {
  const {
    id,
    info,
    latitude,
    longitude,
    objectType,
    address,
    website,
    websiteTitle,
  } = object;
  const canManage = canManageObject(object);

  const [infoFieldModalIsOpen, setInfoFieldModalIsOpen] = useState(false);
  const [editableFieldIndex, setEditableFieldIndex] = useState(null);
  const [addressModalIsOpen, setAddressModalIsOpen] = useState(false);
  const [websiteModalIsOpen, setWebsiteModalIsOpen] = useState(false);
  const [currentInfo, setCurrentInfo] = useState(info);
  useEffect(() => setCurrentInfo(info), [info]);

  const toggleFieldModal = (idx: number) => {
    setEditableFieldIndex(idx);
    setInfoFieldModalIsOpen(!infoFieldModalIsOpen);
  };

  const resetAddress = () => {
    heavyUpdateAddress({
      id,
      slug: object.slug,
      [objectType]: {
        address: '',
        address_1: null,
        address_2: null,
        city: null,
        state: null,
        zip: null,
      },
    });
  };

  const resetWebsite = () => {
    update({
      id,
      slug: object.slug,
      [objectType]: { website: null, website_title: null },
    });
  };

  return (
    <div>
      <div className="collapse-opened-lg">
        <FieldsCollapsableSection
          className="rounded-top-[1rem]"
          canManage={canManage}
          currentInfo={currentInfo}
          objectType={objectType}
          id={id}
          slug={object.slug}
          removeConfirmAlert={removeConfirmAlert}
          openFieldModal={(index) => toggleFieldModal(index)}
          update={update}
          setCurrentInfo={setCurrentInfo}
          onHide={(item) => {
            const newInfo = [...info];
            const idx = newInfo.findIndex((itm) => itm === item);
            newInfo[idx] = { ...newInfo[idx], visible: !newInfo[idx].visible };
            const data = {
              id: object.id,
              slug: object.slug,
              [object.objectType]: { info: newInfo },
            };

            update(data);
          }}
          onCreate={() => toggleFieldModal(undefined)}
          onEdit={(item) => toggleFieldModal(info.findIndex((i) => i === item))}
        />
        {!withoutAddress && (
          <LocationCollapsableSection
            onDelete={() => removeConfirmAlert(resetAddress, 'Address')}
            onEdit={() => setAddressModalIsOpen(true)}
            onCreate={() => setAddressModalIsOpen(true)}
            address={address}
            longitude={longitude}
            latitude={latitude}
            canManage={canManage}
          />
        )}
        <WebsitesCollapsableSection
          onCreate={() => setWebsiteModalIsOpen(true)}
          onEdit={() => setWebsiteModalIsOpen(true)}
          onDelete={() => removeConfirmAlert(resetWebsite, 'Website')}
          website={website}
          websiteTitle={websiteTitle}
          canManage={canManage}
        />
      </div>

      {infoFieldModalIsOpen && (
        <InvestmentObjectInfoModal
          isOpen
          close={() => toggleFieldModal(undefined)}
          header={
            editableFieldIndex !== undefined ? 'Edit Field' : 'Add New Field'
          }
          object={object}
          items={[...info]}
          currentItemIndex={editableFieldIndex}
          update={update}
          newFieldTemplate={{ value: '', visible: true, measure_name: '' }}
          fields={[
            {
              name: 'value',
              hint: 'ex: 250, $50,000,000, etc.',
              validations: () => ({ required: true }),
            },
            {
              name: 'measure_name',
              label: 'Description',
              placeholder: 'Enter Description',
              hint: 'ex: Units, Budget, etc.',
              validations: () => ({ required: true }),
            },
          ]}
          dataName="info"
          actionText={
            editableFieldIndex !== undefined ? 'Save Updated' : 'Add New Field'
          }
        />
      )}

      {!withoutAddress && heavyUpdateAddress && addressModalIsOpen && (
        <AddressModal
          close={() => setAddressModalIsOpen(false)}
          object={object}
          update={heavyUpdateAddress}
        />
      )}

      {websiteModalIsOpen && (
        <WebsiteModal
          object={object}
          close={() => setWebsiteModalIsOpen(false)}
          update={update}
        />
      )}
    </div>
  );
};

export default InvestmentObjectDetails;

InvestmentObjectDetails.propTypes = {};
