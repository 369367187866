import React, { useState } from 'react';
import { Input } from '@/stories/FormControls/Inputs/Input/Input';
import { Icon } from '@/stories/Icon/Icon';
import { cn } from '@/shared/lib/css/cn';

export const PasswordInput = React.forwardRef<
  HTMLInputElement,
  React.ComponentProps<typeof Input>
>(({ className, error, ...props }, ref) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Input
      {...props}
      classes={{
        ...props.classes,
        input: cn('form-control auth__input', {
          'is-invalid': error,
        }),
      }}
      ref={ref}
      type={showPassword ? 'text' : 'password'}
      rightIcon={
        <Icon
          iconName={showPassword ? 'eye' : 'eyeSlash'}
          onClick={() => setShowPassword(!showPassword)}
        />
      }
    />
  );
});

PasswordInput.displayName = 'PasswordInput';

export default PasswordInput;
