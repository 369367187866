import { useEffect } from 'react';

const FULL_WIDTH_PERCENTAGE = 100;
const SHIFT_WIDTH_PERCENTAGE = 2;

/**
 * Calculate media width based on selected files length
 * In order to fit all selected files in the media container
 * we need to subtract `SHIFT_WIDTH` from the calculated value
 * because of the gap between the files
 */
const calculateMediaWidth = (selectedFilesLen: number) =>
  Math.round(FULL_WIDTH_PERCENTAGE / selectedFilesLen) - SHIFT_WIDTH_PERCENTAGE;

export const useUpdateMediaWidthBySelectedFilesLenEffect = (
  selectedFilesLen: number,
  callback: (newValue: number) => void,
) => {
  useEffect(() => {
    let newValue = calculateMediaWidth(selectedFilesLen);

    if (selectedFilesLen === 1) {
      newValue = FULL_WIDTH_PERCENTAGE;
    }

    callback(newValue);
  }, [selectedFilesLen]);
};
