import React from 'react';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import {
  selectInvoiceESignData,
  setESingData,
  changeInvoiceDocumentLink,
} from 'bundles/DrawPackage/slices/ReconcileInvoicesSlice';
import { RadioButton } from 'stories/RadioButton/RadioButton';
import { cloneDeep } from 'lodash-es';
import { useParams } from '@reach/router';
import { generateInvoiceESign } from 'bundles/DrawPackage/InvoicesFlow/Invoices/verify/utils';

const PlacementControlSection = () => {
  const eSignData = useAppSelector(selectInvoiceESignData);
  const allPages = eSignData.involved_pages === 'all';

  const dispatch = useAppDispatch();
  const params = useParams<{
    legalEntityCode: string;
    invoiceId: string;
  }>();

  const changeInvolvedESignData = (type: 'first' | 'all') => {
    const newESignData = cloneDeep(eSignData);

    newESignData.involved_pages = type;
    dispatch(setESingData(newESignData));
    dispatch(
      changeInvoiceDocumentLink({
        eSignData: newESignData,
        params,
      }),
    );
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="body-semibold text-dark-60">Placement</div>
      <div className="flex flex-col gap-2">
        <div className="flex w-full items-center justify-between">
          <div className="inline-regular text-dark-60">First Page Only</div>
          <div className="flex items-center justify-center">
            <RadioButton
              checked={!allPages}
              onClick={() => changeInvolvedESignData('first')}
            />
          </div>
        </div>
        <div className="flex w-full items-center justify-between">
          <div className="inline-regular text-dark-60">All Pages</div>
          <div className="flex items-center justify-center">
            <RadioButton
              checked={allPages}
              onClick={() => changeInvolvedESignData('all')}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlacementControlSection;
