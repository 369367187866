import React, { useState } from 'react';
import GroupForm from 'bundles/Shared/components/GroupForm/GroupForm';
import {
  FieldType,
  IGroupFormItem,
} from 'bundles/Shared/components/GroupForm/types/types';
import { Modal } from '@/stories/Modals/Modal/Modal';
import { PipelineField } from 'types/PipelineField';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import { StaticCardField } from 'bundles/Scoreboard/Pipeline/types';
import { IOption } from 'types/Option';

const initGroupFormItems = (selectOptions: IOption[]): IGroupFormItem[] => [
  {
    title: '',
    required: true,
    activated: true,
    open: true,
    formItems: [
      {
        fieldName: 'value',
        type: selectOptions ? FieldType.SELECT : FieldType.INPUT_TEXT,
        options: selectOptions,
        title: 'Value',
        note: (
          <p className="light-60 secondary-regular mb-s">
            ex: 250, 50, 1000, etc
          </p>
        ),
        placeholder: 'Enter value',
        required: true,
      },
      {
        fieldName: 'label',
        type: FieldType.INPUT_TEXT,
        note: (
          <p className="light-60 secondary-regular mb-s">
            ex: Units, Budget, etc
          </p>
        ),
        title: 'Description',
        placeholder: 'Enter Description',
        required: true,
      },
      {
        fieldName: 'main',
        type: FieldType.TUMBLER,
        title: 'Main field',
        optional: false,
      },
    ],
  },
];

interface Props extends DialogProps<PipelineField | StaticCardField> {
  field?: PipelineField | StaticCardField;
  selectOptions?: object[];
}

const FieldModal = ({ onSubmit, onClose, field, selectOptions }: Props) => {
  const [formData, setFormData] = useState<PipelineField | StaticCardField>(
    field ?? {},
  );

  return (
    <Modal header={field ? 'Edit Field' : 'Add New Field'} toggle={onClose}>
      <GroupForm
        groupFormItems={initGroupFormItems(selectOptions)}
        onSubmit={() => onSubmit?.(formData)}
        formData={formData}
        setFormData={setFormData}
        buttonConfig={{
          titleBtn: field ? 'Edit Field' : 'Add New Field',
          withoutCancel: false,
          funcCancel: onClose,
        }}
      />
    </Modal>
  );
};

export default FieldModal;
