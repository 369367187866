import {
  maxIdGenerator,
  transformPeriodShiftFormToDto,
  transformPeriodTypeFormToDto,
} from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/config';
import { DEFAULT_WIDGET_HISTORY_SIZE } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/fields/HistorySizeField';
import { upsertColumnSettings } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/table/updaters';
import { commonTableWidgetUpdaters } from '@/bundles/Shared/widgets/dashboard/widgets/common/updaters';
import { UnitTypeTableWidgetConfigColumnForm } from '@/bundles/Shared/widgets/dashboard/widgets/unitTypeTable/config/column.form';
import { UnitTypeTableWidgetConfigForm } from '@/bundles/Shared/widgets/dashboard/widgets/unitTypeTable/config/component.form';
import { UnitTypeTableWidgetConfigDto } from '@/shared/api/dashboardsSettingsGeneratedApi';
import { produce } from 'immer';

const upsertColumn = (
  column: UnitTypeTableWidgetConfigColumnForm,
  config: UnitTypeTableWidgetConfigDto,
  {
    groupId,
  }: {
    groupId?: string;
  },
) => {
  return produce(config, (draft) => {
    let columnIndex = draft.columns.findIndex((c) => c.key === column?.key);
    if (columnIndex === -1) {
      columnIndex = draft.columns.length;
    }

    draft.columns[columnIndex] = {
      label: column.label,
      expression: column.expression,
      period_shift: transformPeriodShiftFormToDto(
        column.period_shift ?? undefined,
      ),
      period_type: transformPeriodTypeFormToDto(column.period_type),
      key: column?.key ?? maxIdGenerator(draft.columns, 'key'),
      value_display_options: column.value_display_options,
      unit_type_calculation_strategy:
        column.unit_type_calculation_strategy ?? 'evaluate',
      total_calculation_strategy:
        column.total_calculation_strategy ?? 'evaluate',
      history_size: column.history_size ?? DEFAULT_WIDGET_HISTORY_SIZE,
    };

    const colId = draft.columns[columnIndex].key.toString();
    upsertColumnSettings(column, draft, {
      key: colId,
      groupId,
    });
  });
};

const updateDefaultOptions = (
  form: UnitTypeTableWidgetConfigForm,
  widgetConfig: UnitTypeTableWidgetConfigDto,
) => {
  const periodShift = transformPeriodShiftFormToDto(form.period_shift);
  return produce(widgetConfig, (draft) => {
    draft.hide_total_row = form.hide_total_row ?? false;
    draft.viz_config.header_background = form.header_background ?? undefined;
    draft.hide_floorplan_rows = form.hide_floorplan_rows ?? false;
    if (periodShift != null) {
      draft.default_options.date = {
        period_shift: periodShift,
      };
    } else {
      draft.default_options = undefined;
    }
  });
};

export const unitTypeTableUpdaters = {
  ...commonTableWidgetUpdaters,
  updateDefaultOptions,
  upsertColumn,
};
