import { ReportLineItem } from 'bundles/REturn/actions/types';
import TransactionCollapsedList from 'bundles/REturn/components/TransactionCollapsedList/TransactionCollapsedList';
import { startCase } from 'lodash-es';
import { PropsWithChildren, ReactNode } from 'react';
import { Label } from 'stories/Label/Label';
import { CssVar } from '@/shared/config/cssVar';
import {
  SECOND_STEP_HINT_INFO_JSX,
  SELECT_INV_ENTITY_TEXT_JSX,
} from '@/bundles/REturn/components/workflows/createEntry/dumbJSX';
import { SingleDistributionKind } from '@/bundles/REturn/components/workflows/createEntry/types';
import { createWorkflowFlags } from '@/bundles/REturn/components/workflows/createEntry/utils/createWorkflowFlags';

interface Props extends PropsWithChildren {
  workflowFlags: ReturnType<typeof createWorkflowFlags>;
  kindSelector: ReactNode;
  singleDistributionKind: SingleDistributionKind;
  selectedTxs: ReportLineItem[];
}
export function InvestmentEntityStep({
  kindSelector,
  workflowFlags,
  children,
  singleDistributionKind,
  selectedTxs,
}: Props) {
  const { transactions, selectedOneKind, capitalInvestments } = workflowFlags;
  return (
    <div className="flex flex-col gap-6 p-6">
      <div className="flex flex-col gap-2">
        <div className="flex items-center justify-between">
          <p className="body-semibold text-dark-60">Transactions</p>
          {transactions.isMultiple &&
            selectedOneKind(singleDistributionKind) && (
              <Label outline color={CssVar.blue}>
                {startCase(singleDistributionKind)}
              </Label>
            )}
        </div>
        <TransactionCollapsedList items={selectedTxs} />
      </div>
      {capitalInvestments.isSelected ? (
        <div className="flex flex-col gap-4">
          {SELECT_INV_ENTITY_TEXT_JSX}
          {children}
        </div>
      ) : (
        SECOND_STEP_HINT_INFO_JSX
      )}

      {workflowFlags.shouldSelectKindForSingleTx && kindSelector}
    </div>
  );
}
