import { IJobCostCode } from 'types/JobCostCode';
import { FETCH_JCC_FOR_REALLOCATIONS } from '@/bundles/Construction/actions';

const initialState: null | IJobCostCode[] = null;

export default function (
  state = initialState,
  { type, payload },
): IJobCostCode[] | null {
  switch (type) {
    case FETCH_JCC_FOR_REALLOCATIONS:
      return payload;

    default:
      return state;
  }
}
