import React, { ComponentProps } from 'react';
import { Link } from '@reach/router';
import { Button } from '@/stories/Button/Button';

type Props = React.PropsWithChildren<
  ComponentProps<typeof Button> & {
    link: string;
  }
>;

export const LinkAsButton = ({ children, link, ...props }: Props) => (
  <Link to={link}>
    <Button {...props}>{children}</Button>
  </Link>
);

export default LinkAsButton;
