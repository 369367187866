import { cn } from '@/shared/lib/css/cn';
import Tooltip from '@/stories/Tooltip/Tooltip';
import React, { ComponentProps, FC } from 'react';
import '@/stories/Checkbox/checkbox.css';

interface Props
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  outline?: boolean;
  labelClassName?: string;
  inverted?: boolean;
  labelId?: string;
  uncheckedAltStyle?: boolean;
  indeterminate?: boolean;
  classes?: {
    children?: string;
  };
  tooltipProps?: ComponentProps<typeof Tooltip>;
  noInnerLabel?: boolean;
}

export const Checkbox: FC<Props> = ({
  outline = false,
  children,
  labelClassName,
  labelId,
  classes,
  inverted,
  indeterminate,
  uncheckedAltStyle,
  tooltipProps = {
    disabled: true,
  },
  noInnerLabel = false,
  ...props
}) => {
  const ButtonWrapTag = noInnerLabel ? 'div' : 'label';
  const rawCheckbox = (
    <ButtonWrapTag
      className={cn(
        'sre-checkbox flex items-center',
        {
          'sre-checkbox_outline': outline,
          'sre-checkbox_children-node':
            children && typeof children === 'object',
          'sre-checkbox_inverted': inverted,
        },
        labelClassName,
      )}
      id={labelId}
    >
      <input
        className="sre-checkbox__input"
        type="checkbox"
        data-indeterminate={indeterminate}
        {...props}
      />
      <div className={cn('sre-checkbox__content sre-icon-check')}>
        {children && (
          <div
            className={cn(
              'sre-checkbox__children',
              classes?.children,
              uncheckedAltStyle &&
                !props.checked &&
                '!text-neutral-550 line-through',
            )}
          >
            {children}
          </div>
        )}
      </div>
    </ButtonWrapTag>
  );

  if (tooltipProps.disabled) return rawCheckbox;

  return (
    <Tooltip placement="bottom-start" arrow={false} {...tooltipProps}>
      {rawCheckbox}
    </Tooltip>
  );
};

export default Checkbox;
