import {
  getTableCellBackgroundStyle,
  getTableCellFontSizeStyle,
} from '@/bundles/Shared/components/AgGrid/Table/utils/getTableCellStyle';

// TODO replace with ColGroupStyleBuilder in FE-3921
export const buildTableCellStyles = (
  displayOptions: Pick<React.CSSProperties, 'background' | 'fontSize'>,
): Pick<React.CSSProperties, 'background' | 'fontSize' | 'borderColor'> => {
  return {
    ...displayOptions,
    ...(displayOptions.background != null
      ? getTableCellBackgroundStyle(
          displayOptions.background as React.CSSProperties['color'],
        )
      : {}),
    ...(displayOptions.fontSize != null
      ? getTableCellFontSizeStyle(displayOptions.fontSize as number)
      : {}),
  };
};
