import { PUBLICATION_DATASETS } from 'bundles/REturn/components/Ownership/ownershipsHeader/dumbJSX';
import { TPublishableLegalEntity } from 'bundles/REturn/types';
import { DEFAULT_DROPDOWN_OFFSET } from 'stories/Popover/Popover';
import { Button } from 'stories/Button/Button';
import { Icon } from 'stories/Icon/Icon';
import { Popover } from 'stories/Popover/Popover';
import { Tumbler } from 'stories/Tumbler/Tumbler';
import { cn } from '@/shared/lib/css/cn';
import { LegalEntity } from '@/entities/core/legalEntity';

interface Props {
  legalEntities: (TPublishableLegalEntity & Pick<LegalEntity, 'name'>)[];
  onChange: (legalEntities: TPublishableLegalEntity) => void;
  hideLabel?: boolean;
}

function CapitalInvestmentStatus({ legalEntities, onChange }: Props) {
  const legalEntitiesCount = legalEntities.length;
  const publishedCount = legalEntities.filter((le) => le.isPublished).length;
  const allLEPublished = publishedCount === legalEntitiesCount;

  const handleChange = (le: TPublishableLegalEntity) => {
    onChange({
      ...le,
      isPublished: !le.isPublished,
    });
  };

  const multipleLEContent = (
    <div className="flex flex-col gap-m">
      <span className="light-60 secondary-regular ">
        For which LE are you turning on REturn dashboards?
      </span>
      <div className="flex flex-col gap-2">
        {legalEntities.map((le) => (
          <Tumbler
            key={le.id}
            checked={le.isPublished}
            classes={{ text: 'label-semibold' }}
            onChange={() => handleChange(le)}
          >
            {le.name}
          </Tumbler>
        ))}
      </div>
    </div>
  );

  const singleLEContent = (
    <div className="flex h-full flex-col items-center justify-between gap-4 text-center">
      <span className="light-60 inline-regular">Legal Entity</span>
      <div className="flex flex-col items-center gap-2">
        <Icon
          className="light-60 rounded-2xl bg-light-10 p-2"
          iconName="entities"
        />
        <span className="dark-60 inline-regular text-center">
          {legalEntities[0].name}
        </span>
      </div>
      <Button
        size="s"
        variant={legalEntities[0].isPublished ? 'danger' : 'success'}
        onClick={() => handleChange(legalEntities[0])}
      >
        Set {legalEntities[0].isPublished ? 'Invisible' : 'Visible'}
      </Button>
    </div>
  );

  const template = (
    <div className="grid grid-cols-2 bg-light-5">
      <div className="flex-1 bg-white p-4">{PUBLICATION_DATASETS}</div>
      <div className="flex flex-1 flex-col gap-4 bg-light p-4">
        {legalEntitiesCount > 1 && multipleLEContent}
        {legalEntitiesCount === 1 && singleLEContent}
      </div>
    </div>
  );

  return (
    <Popover
      trigger="click"
      className="overflow-hidden p-0"
      placement="bottom-start"
      hiddenArrow
      offset={DEFAULT_DROPDOWN_OFFSET}
      template={template}
      maxWidth={480}
    >
      {({ triggered }) => (
        <Button
          size="s"
          variant="secondary"
          iconName="public"
          className={cn(
            allLEPublished && 'w-[32px]',
            triggered && 'border-neutral-200 bg-neutral-200',
          )}
          iconClasses={
            !allLEPublished ? 'text-attention-070' : 'text-green-dark-2'
          }
        >
          {allLEPublished ? null : (
            <>
              {publishedCount} of {legalEntitiesCount} LEs
            </>
          )}
        </Button>
      )}
    </Popover>
  );
}

export default CapitalInvestmentStatus;
