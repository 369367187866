import React from 'react';
import { Button } from 'stories/Button/Button';
import { useGetApiSettingsReportGeneralLedgersQuickMapSuggestionsQuery } from 'bundles/Settings/actions/report/generalLedgersApiEnhanced';
import pluralize from 'pluralize';
import { useModal } from '@/shared/lib/hooks/useModal';
import QuickMappingModal from 'bundles/Settings/components/REport/GeneralLedgers/quickMapping/QuickMappingModal';

function QuickMappingButton() {
  const { openModal } = useModal();
  const { data } =
    useGetApiSettingsReportGeneralLedgersQuickMapSuggestionsQuery({
      page: 1,
      perPage: 10,
    });

  if (data == null || data.meta.totalSize === 0) {
    return null;
  }

  return (
    <Button
      variant="warning"
      size="m"
      iconName="link"
      onClick={() => openModal(QuickMappingModal, {})}
    >
      {data.meta.totalSize} {pluralize('Account', data.meta.totalSize)} for
      Quick Mapping
    </Button>
  );
}

export default QuickMappingButton;
