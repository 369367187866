import { InvestmentIndex } from '@/entities/return/api/capitalInvestmentObjectsGeneratedApi';
import EnterCommitmentsStep from 'bundles/REturn/components/Ownership/modals/bulkCommitmentModal/EnterCommitmentsStep';
import { TEditableCapitalInvestments } from 'bundles/REturn/components/Ownership/modals/capitalInvestmentsEditor/CapitalInvestmentsEditor';
import SelectInvestmentEntity from 'bundles/REturn/components/Ownership/selectInvestmentEntity/SelectInvestmentEntity';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import pluralize from 'pluralize';
import React, { useMemo, useState } from 'react';
import { Button } from 'stories/Button/Button';
import { Modal } from 'stories/Modals/Modal/Modal';
import { ModalActions } from 'stories/Modals/Modal/ModalActions';
import { ModalHeaderWithSubtitle } from 'stories/Modals/ModalHeader/ModalHeaderWithSubtitle/ModalHeaderWithSubtitle';

interface Props extends DialogProps<TEditableCapitalInvestments[]> {
  capitalInvestments: InvestmentIndex[];
}

const BulkCommitmentModal = ({
  onClose,
  onSubmit,
  capitalInvestments,
}: Props) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedInvestmentEntities, setSelectedInvestmentEntities] = useState<
    InvestmentIndex[]
  >([]);
  const [data, setData] = useState<TEditableCapitalInvestments[]>([]);
  const [step2Valid, setStep2Valid] = useState(false);

  const step1Content = useMemo(
    () => (
      <SelectInvestmentEntity
        capitalInvestments={capitalInvestments}
        selectedItems={selectedInvestmentEntities}
        setSelectedItems={(newEntities) => {
          setSelectedInvestmentEntities(newEntities);
          setData(
            newEntities.map((item) => ({
              id: item.id,
              investmentEntity: item.investmentEntity,
              legalEntity: item.legalEntity,
            })),
          );
        }}
      />
    ),
    [selectedInvestmentEntities],
  );

  const step2Content = useMemo(
    () => (
      <EnterCommitmentsStep
        capitalInvestments={data}
        onCapitalInvestmentsChange={setData}
        onValidChange={setStep2Valid}
      />
    ),
    [data],
  );

  const steps: Record<string, React.JSX.Element> = {
    1: step1Content,
    2: step2Content,
  };

  const stepsCount = Object.keys(steps).length;

  const stepsValid: Record<string, boolean> = {
    1: selectedInvestmentEntities.length !== 0,
    2: step2Valid,
  };
  const pluralizedSelectedInvestmentEntities = `${
    selectedInvestmentEntities.length
  } ${pluralize('Investment Entity', selectedInvestmentEntities.length)}`;
  const stepsSubmitText: Record<string, string> = {
    1: `Continue ${
      selectedInvestmentEntities.length > 0
        ? `with ${pluralizedSelectedInvestmentEntities}`
        : ''
    }`,
    2: `Add Commitments for ${pluralizedSelectedInvestmentEntities}`,
  };

  const handleSubmit =
    currentStep === stepsCount
      ? () => onSubmit?.(data)
      : () => setCurrentStep(currentStep + 1);

  return (
    <Modal
      toggle={() => onClose()}
      size="900"
      bodyHeight={600}
      classes={{
        body: '!p-0',
      }}
      header={
        <ModalHeaderWithSubtitle
          title="Bulk Adding of Commitments Values"
          subtitle={`Step ${currentStep} of ${stepsCount}`}
          order="subtitle-title"
        />
      }
      actions={
        <ModalActions alignItems="space-between">
          <Button
            variant="secondary"
            onClick={
              currentStep === 1
                ? onClose
                : () => setCurrentStep(currentStep - 1)
            }
          >
            {currentStep === 1 ? 'Cancel' : 'Back to Selection'}
          </Button>
          <Button
            variant="success"
            onClick={handleSubmit}
            disabled={!stepsValid[currentStep]}
          >
            {stepsSubmitText[currentStep]}
          </Button>
        </ModalActions>
      }
    >
      {steps[currentStep]}
    </Modal>
  );
};

export default BulkCommitmentModal;
