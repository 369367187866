import React from 'react';
import { ActionItem } from '@/stories/Table/Actions/ActionItem';
import { GroupContainer } from '@/stories/Table/Actions/GroupContainer';

export const GroupContainerItem = ({
  title,
  children,
  className,
}: React.PropsWithChildren<
  {
    title: string;
  } & PropsWithClassName
>) => (
  <ActionItem className={className}>
    <GroupContainer title={title}>{children}</GroupContainer>
  </ActionItem>
);
