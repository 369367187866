import * as yup from 'yup';

export const EAGLE_EYE_OBJECT_SELECTION_SCHEMA = yup
  .object()
  .required()
  .shape({
    asset_ids: yup.array().of(yup.number().required()).required(),
    segment_ids: yup.array().of(yup.number().required()).required(),
    excluded_legal_entity_ids: yup
      .array()
      .of(yup.string().required())
      .required(),
  });

export type EagleEyeObjectSelectionForm = yup.InferType<
  typeof EAGLE_EYE_OBJECT_SELECTION_SCHEMA
>;

const EAGLE_EYE_DASHBOARD_UPDATE_SCHEMA = yup
  .object()
  .shape({
    name: yup.string().required(),
  })
  .concat(EAGLE_EYE_OBJECT_SELECTION_SCHEMA);

export type EagleEyeDashboardUpdateForm = yup.InferType<
  typeof EAGLE_EYE_DASHBOARD_UPDATE_SCHEMA
>;
