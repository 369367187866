import QuickFilterFireButton, {
  TQuickFilterFireButtonProps,
} from 'bundles/Shared/components/Table/filters/QuickFilterFireButton';
import { IColumn } from 'bundles/Shared/components/Table/types';
import {
  IPageParams,
  TUserTrackerTab,
} from 'bundles/UserManagement/components/UserTracker/UserTracker';
import AccessCell from 'bundles/UserManagement/components/table/cellFormatters/AccessCell';
import ActionsCell from 'bundles/UserManagement/components/table/cellFormatters/ActionsCell';
import ManageCell from 'bundles/UserManagement/components/table/cellFormatters/ManageCell';
import ViewCell from 'bundles/UserManagement/components/table/cellFormatters/ViewCell';
import RolesFilter from 'bundles/UserManagement/components/table/filters/RolesFilter';
import StatusFilter from 'bundles/UserManagement/components/table/filters/StatusFilter';
import { IUser, IUserRole, TUserInviteStatus } from 'types/User';
import BasicInfoCell from '@/bundles/UserManagement/components/table/cellFormatters/BasicInfoCell';
import UserStatusCellFormatter from '@/bundles/UserManagement/components/table/cellFormatters/userStatusCell/UserStatusCell';
import UserTableActivityCell from '@/bundles/UserManagement/components/table/cellFormatters/userTableActivityCell/UserTableActivityCell';
import { UserAccessLevels } from '@/entities/impersonation/api';
export const getAvailableObjectsCount = (allAvailable, availableLength) => {
  if (allAvailable) return 'All';
  if (!allAvailable && availableLength) return availableLength;
  return false;
};

export interface IUserTableColumnsActions {
  edit: (user: IUser, tab: TUserTrackerTab) => void;
  filterByFireStatus: () => void;
  invite: (user: IUser) => void;
  deactivate: (user: IUser) => void;
}

export interface IFireStationOptions {
  totals: {
    inviteStatuses: number;
    failedInvites: number;
  };
}

export type TFilterModel = {
  last_seen_at_fire?: boolean;
  inviteStatus?: TUserInviteStatus;
  inviteStatus_fire?: boolean;
  emailMessagesCount_fire?: boolean;
};

const userColumns = ({
  fireStationOptions,
  actions,
  userAccessLevels,
  userRoles,
  isUserExpanded,
  onToggleExpand,
}: {
  actions: IUserTableColumnsActions;
  userAccessLevels: UserAccessLevels;
  fireStationOptions: IFireStationOptions;
  userRoles: IUserRole[];
  onToggleExpand: (userId: IUser['id']) => void;
  isUserExpanded: (userId: IUser['id']) => boolean;
}): IColumn<IUser>[] => [
  {
    dataField: 'fullName',
    text: 'Basic Info',
    headerStyle: { width: '35%' },
    classes: 'position-relative mnw-150p',
    formatter: ({ row: user }) => (
      <BasicInfoCell
        isUserExpanded={isUserExpanded}
        onToggleExpand={onToggleExpand}
        user={user}
        actions={actions}
      />
    ),
    sortable: true,
  },
  {
    dataField: 'role',
    text: 'Role',
    headerStyle: { width: '10%' },
    filter: (settings: IPageParams, setSettings) => (
      <RolesFilter
        roles={userRoles}
        settings={settings}
        setSettings={setSettings}
      />
    ),
    formatter: ({ row: user }) => (
      <>
        <div className="label-regular text-neutral-550">{user.role?.group}</div>
        <div>{user.role?.name}</div>
      </>
    ),
    sortable: true,
  },
  {
    dataField: 'access',
    text: 'Access',
    headerStyle: { width: '6%' },
    classes: 'light-border',
    formatter: ({ row: user }) => <AccessCell user={user} />,
  },
  {
    dataField: 'view',
    text: 'View',
    headerClasses: 'no-border',
    classes: 'light-border',
    headerStyle: { width: '5%', borderLeft: '1px solid #e0e3e4' },
    formatter: ({ row: user }) => (
      <ViewCell
        user={user}
        actions={actions}
        userAccessLevels={userAccessLevels}
      />
    ),
  },
  {
    dataField: 'manage',
    text: 'Manage',
    classes: 'light-border-last',
    headerStyle: { width: '5%' },
    formatter: ({ row: user }) => (
      <ManageCell
        user={user}
        actions={actions}
        userAccessLevels={userAccessLevels}
      />
    ),
  },
  {
    dataField: 'inviteStatus',
    text: 'Status',
    headerStyle: { width: '25%' },
    filterComponent: StatusFilter,
    filterComponentParams: {
      count: fireStationOptions.totals.inviteStatuses,
    },
    classes: '!p-0',
    formatter: ({ row: user }) => (
      <UserStatusCellFormatter user={user} action={actions.invite} />
    ),
  },
  {
    dataField: 'emailMessagesCount',
    text: 'Campaigns',
    headerStyle: { width: '15%' },
    classes: '!p-0',
    filterComponent: QuickFilterFireButton,
    filterComponentParams: {
      count: fireStationOptions.totals.failedInvites,
    } as TQuickFilterFireButtonProps,
    formatter: ({ row: user }) => (
      <UserTableActivityCell action={actions.invite} user={user} />
    ),
    sortable: true,
  },
  {
    dataField: 'actions',
    text: 'Actions',
    headerStyle: { width: '10%' },
    formatter: ({ row: user }) => <ActionsCell user={user} actions={actions} />,
  },
];

export default userColumns;
