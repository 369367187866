import { fetchGenericData } from '@/app/reducers/actions';
import NoDataOverlay from 'bundles/Shared/components/NoDataOverlay';
import { useAppDispatch } from '@/shared/lib/hooks/redux';
import { stringify } from 'qs';
import React, { useState } from 'react';
import { ColorTag } from '@/stories/Tags/ColorTag/ColorTag';
import { IFireAlertCard } from 'types/FireAlertCard';
import http from '@/lib/http';
import { navigate } from '@reach/router';
import FireAlertCard from '@/bundles/FireStation/components/FireAlertCard/FireAlertCard';
import FireTag from '@/bundles/FireStation/components/FireTag/FireTag';
import {
  MODULES,
  PRIORITIES,
  normalizeModuleTagLabel,
} from '@/bundles/FireStation/components/utils/utils';
import { TFireStationPriority } from '@/bundles/FireStation/types';
import { generateUrl, ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';

export const FireStation = () => {
  const dispatch = useAppDispatch();
  const [selectedFireTag, setSelectedFireTag] = useState();
  const [selectedModuleTag, setSelectedModuleTag] = useState();
  const [fireEventCards, setFireEventCards] = useState<IFireAlertCard[] | null>(
    null,
  );
  async function fetchFireEventCards() {
    const queryParams = stringify(
      {
        filter_by_modules: [selectedModuleTag],
        filter_by_priorities: [selectedFireTag],
      },
      { arrayFormat: 'brackets' },
    );
    const res = await http.get(`/fire_station/alert_cards?${queryParams}`);
    const resData = await res.json();
    setFireEventCards(resData);
  }
  async function fireAlertCardResolver(fireAlertCard: IFireAlertCard) {
    const { kind } = fireAlertCard;

    if (kind === 'pending_change_order_approval') {
      const { changeOrderToApprove } =
        fireAlertCard.reconcilePendingChangeOrderApprovalAlert;
      navigate(
        generateUrl(
          ROUTES_ROOT.reconcile.development.legalEntity.changeOrder.fullPath,
          {
            pathParams: {
              legalEntityCode: changeOrderToApprove.legalEntity.code,
              changeOrderId: changeOrderToApprove.id,
            },
          },
        ),
      );
      return;
    }
    if (kind === 'pending_change_order_event_approval') {
      const { changeOrderEventToApprove } =
        fireAlertCard.reconcilePendingChangeOrderEventApprovalAlert;
      navigate(
        generateUrl(
          ROUTES_ROOT.reconcile.development.legalEntity.changeEvent.fullPath,
          {
            pathParams: {
              legalEntityCode: changeOrderEventToApprove.legalEntity.code,
              id: changeOrderEventToApprove.id,
            },
          },
        ),
      );
      return;
    }

    const res = await http.post(`/fire_station/alert_cards/${kind}/resolve`);
    const resData = await res.json();
    if (resData.success) {
      fetchFireEventCards();
      dispatch(fetchGenericData());
    }
  }
  React.useEffect(() => {
    fetchFireEventCards();
  }, [selectedFireTag, selectedModuleTag]);
  return (
    <>
      <div className="mb-m flex flex-wrap  gap-l">
        <div className="flex items-center gap-s">
          <span className="light-60 label-regular">PRIORITY:</span>
          <div className="flex flex-wrap gap-xs">
            {PRIORITIES.map((priority) => (
              <FireTag
                key={priority}
                priority={priority as TFireStationPriority}
                selected={selectedFireTag === priority}
                onClick={() => {
                  setSelectedFireTag(
                    selectedFireTag === priority ? undefined : priority,
                  );
                }}
              />
            ))}
          </div>
        </div>
        <div className="flex items-center gap-s">
          <span className="light-60 label-regular">MODULES:</span>
          <div className="flex flex-wrap gap-xs">
            {Object.entries(MODULES).map(([key, module]) => (
              <ColorTag
                key={key}
                variant={module.color}
                label={normalizeModuleTagLabel(key)}
                selected={selectedModuleTag === key}
                onClick={() => {
                  setSelectedModuleTag(
                    selectedModuleTag === key ? undefined : key,
                  );
                }}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-s">
        {fireEventCards?.map((fireAlertCard) => (
          <FireAlertCard
            key={fireAlertCard.kind}
            fireAlertCard={fireAlertCard}
            fireAlertCardResolver={fireAlertCardResolver}
          />
        ))}
        {fireEventCards?.length === 0 && (
          <NoDataOverlay
            title="No Fire tasks yet"
            subTitle="Reset filters to see all notifications"
          />
        )}
      </div>
    </>
  );
};

export default FireStation;
