import React from 'react';
import { Icon } from '@/stories/Icon/Icon';
import { BoardModal } from 'bundles/Shared/features/dashboard/boardCRUD/ui/BoardModal';
import useBoolean from '@/shared/lib/hooks/useBoolean';
import { EagleEyeBoard } from '@/bundles/Shared/entities/dashboard/model/types/types';

export function CreateBoardButton({
  onBoardCreate,
}: {
  onBoardCreate?: (board: EagleEyeBoard) => void;
}) {
  const { value: isModalOpen, toggle: toggleModal } = useBoolean();
  const handleSubmit = (board: EagleEyeBoard) => {
    toggleModal();
    onBoardCreate?.(board);
  };
  return (
    <>
      <Icon onClick={toggleModal} iconName="addSmall" />
      {isModalOpen && (
        <BoardModal
          board={null}
          onClose={toggleModal}
          onSubmit={handleSubmit}
        />
      )}
    </>
  );
}
