import React from 'react';
import { navigate, useParams } from '@reach/router';
import { useGetInvoiceQuery } from 'bundles/Construction/api/invoices';
import FilePreview from 'bundles/Shared/components/FilePreviews/FilePreview';
import { useModal } from '@/shared/lib/hooks/useModal';
import { generatePath, ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import { AnimationLoader } from 'stories/AnimationLoader/AnimationLoader';
import { Button } from 'stories/Button/Button';
import { CurrencyFormatter } from 'stories/ValueFormatters/CurrencyFormatter';
import { Icon } from 'stories/Icon/Icon';
import { IconButton } from 'stories/IconButton/IconButton';
import { StatusTrackerItem } from 'stories/StatusTrackerItem/StatusTrackerItem';
import { formatDate } from '@/shared/lib/formatting/dates';
import StatusesInvoice from 'bundles/DrawPackage/InvoicesFlow/Invoices/approve/components/StatusesInvoice';
import InvoiceRequisitionModal from 'bundles/DrawPackage/InvoicesFlow/Invoices/requisition/InvoiceRequisitionModal';
import LineItemsList from 'bundles/DrawPackage/InvoicesFlow/Invoices/approve/components/LineItemsList';
import { cn } from '@/shared/lib/css/cn';
import styles from 'bundles/DrawPackage/InvoicesFlow/Invoices/invoice/Invoice.module.scss';
import {
  INFO_STATUS_TRACKER,
  NEUTRAL_STATUS_TRACKER,
  StatusesTrackerItem,
} from 'stories/StatusTrackerItem/StatusTrackerItem';
import KeyDetailsItem from 'bundles/DrawPackage/InvoicesFlow/Invoices/layouts/KeyDetailsItem';
import { GapBlock } from 'bundles/DrawPackage/InvoicesFlow/Invoices/layouts/GapBlock';
import { generateRequisitionDates } from 'bundles/DrawPackage/utils';
import LinkedTransaction from 'bundles/DrawPackage/InvoicesFlow/Invoices/pay/widgets/payInvoice/LinkedTransaction';
import { convertToMilliseconds } from '@/shared/lib/converters';

const PayInvoice = () => {
  const params = useParams();
  const { openModal } = useModal();

  const { data: invoice, isLoading } = useGetInvoiceQuery({
    legalEntityCode: params?.legalEntityCode,
    invoiceId: params.invoiceId,
  });

  const inRequisition = invoice?.requisition;
  const inDraw = invoice?.funding === 'in_draw';
  const isMarkForPayment = invoice?.payment === 'marked_for_payment';
  const isMarkAsPaid = invoice?.payment === 'paid';
  const emailIsSending = Boolean(
    invoice?.paymentRequest?.lastEmailMessage?.events.sent?.date,
  );
  const emailIsDelivering = Boolean(
    invoice?.paymentRequest?.lastEmailMessage?.events.delivered?.date,
  );
  const emailIsOpened = Boolean(
    invoice?.paymentRequest?.lastEmailMessage?.events.opened?.date,
  );
  const isLinkedTransaction = Boolean(invoice?.linkedTransactions?.length);

  const handleClose = () => {
    navigate(
      generatePath(
        ROUTES_ROOT.reconcile.development.legalEntity.invoices.fullPath,
        {
          legalEntityCode: params.legalEntityCode,
        },
      ),
    );
  };

  const requisitionHandle = () => {
    openModal(InvoiceRequisitionModal, {
      params: {
        ...params,
        id: params?.invoiceId,
      },
      type: inRequisition ? 'remove' : 'add',
    });
  };

  const markAsPaid = () => {
    navigate(
      generatePath(
        ROUTES_ROOT.reconcile.development.legalEntity.invoices.pay.markAsPaid
          .fullPath,
        {
          legalEntityCode: params.legalEntityCode,
          invoiceId: params.invoiceId,
        },
      ),
    );
  };

  const markForPayment = () => {
    navigate(
      generatePath(
        ROUTES_ROOT.reconcile.development.legalEntity.invoices.pay
          .markForPayment.fullPath,
        {
          legalEntityCode: params.legalEntityCode,
          invoiceId: params.invoiceId,
        },
      ),
    );
  };

  const openEmail = () => {
    // next iteration
  };

  if (isLoading && !invoice) return <AnimationLoader />;

  return (
    <div>
      <FilePreview
        file={{
          url: invoice?.document.url,
        }}
        handleClose={handleClose}
        withoutHeader
      >
        <div className="relative flex h-screen flex-col bg-white">
          <div className="absolute right-5 top-5 flex gap-2">
            <IconButton
              iconName="close"
              size="l"
              onClick={handleClose}
              variant="secondary"
            />
          </div>
          <div className="flex-1">
            <div className="flex w-full justify-center py-4">
              <CurrencyFormatter
                value={invoice?.amount}
                classes={{
                  allParts: 'header2-regular text-dark-60',
                }}
              />
            </div>
            <div className="body-regular flex justify-center gap-1">
              <div>{formatDate(invoice?.date, 'MMM DD, YYYY')}</div>
              <div>•</div>
              <div>{invoice?.vendor.name}</div>
            </div>
            <div
              className={cn(
                'flex flex-grow flex-col gap-6',
                styles.tableBody,
                styles.markForPaymentContainer,
                {
                  [styles.pageContainer]: isLinkedTransaction,
                },
              )}
            >
              <div>
                <div className="px-6 pb-3 pt-4">
                  <StatusesInvoice />
                </div>
                <div className="px-6">
                  <div className="rounded-lg bg-light">
                    <KeyDetailsItem title="Invoice">
                      {invoice?.document.title}
                    </KeyDetailsItem>
                    <KeyDetailsItem title="Number">
                      {invoice?.number}
                    </KeyDetailsItem>
                    {inRequisition && (
                      <KeyDetailsItem title="Requisition">
                        <div className="flex items-center gap-4">
                          <div className="inline-regular text-light-60">
                            {`(${generateRequisitionDates(
                              invoice?.requisition.beginningDate,
                              invoice?.requisition.endingDate,
                            )})`}
                          </div>
                          {!inDraw && (
                            <Icon
                              iconName="closeSmall"
                              onClick={requisitionHandle}
                            />
                          )}
                        </div>
                      </KeyDetailsItem>
                    )}
                  </div>
                </div>
                {!inRequisition && (
                  <div className="px-6 pt-4">
                    <Button
                      variant="secondary"
                      className="w-full"
                      onClick={requisitionHandle}
                    >
                      <div className="inline-semibold text-light-90">
                        Add to Requisition
                      </div>
                    </Button>
                  </div>
                )}
              </div>
              {isLinkedTransaction && (
                <GapBlock>
                  <LinkedTransaction />
                </GapBlock>
              )}
              <GapBlock>
                <div className="body-semibold text-dark-60">
                  Development Line Items
                </div>
                <div>
                  <LineItemsList />
                </div>
              </GapBlock>
              {invoice?.paymentRequest && !isMarkAsPaid && (
                <div className="px-6">
                  <div className="rounded-lg border-2 border-blue bg-blue-light-5 p-4">
                    <div className="flex flex-col gap-6">
                      <div className="flex justify-between">
                        <div className="inline-semibold text-dark-60">
                          This Invoice is Marked for Payment
                        </div>
                        <Button
                          variant="secondary"
                          size="xs"
                          onClick={openEmail}
                        >
                          Show Email
                        </Button>
                      </div>
                      <div className="flex flex-col gap-2">
                        <div className="label-regular">Accounts Team</div>
                        <div className="flex flex-col gap-0.5">
                          <div className="inline-regular text-dark-60">
                            {invoice?.paymentRequest.contact.name}
                          </div>
                          <div className="label-regular text-light-90">
                            {invoice?.paymentRequest.contact.email}
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col gap-2">
                        <div className="label-regular">Delivery status</div>
                        <div className="flex flex-col gap-2">
                          <StatusTrackerItem
                            icon="sendAlt"
                            styles={INFO_STATUS_TRACKER}
                            progress={
                              emailIsSending
                                ? StatusesTrackerItem.DONE
                                : StatusesTrackerItem.IN_PROGRESS
                            }
                          >
                            <div className="flex flex-col gap-0.5 pb-2">
                              <div className="label-semibold text-dark-60">
                                {emailIsSending ? 'Sent' : 'Sending'}
                              </div>
                              <div className="secondary-regular text-light-80">
                                {formatDate(
                                  convertToMilliseconds(
                                    invoice?.paymentRequest?.lastEmailMessage
                                      ?.events.sent?.date,
                                  ),
                                  'MMM DD, YYYY',
                                )}
                              </div>
                            </div>
                          </StatusTrackerItem>
                          <StatusTrackerItem
                            icon="mailAlt"
                            styles={
                              emailIsDelivering
                                ? INFO_STATUS_TRACKER
                                : NEUTRAL_STATUS_TRACKER
                            }
                            progress={StatusesTrackerItem.IN_PROGRESS}
                          >
                            <div className="flex flex-col gap-0.5 pb-2">
                              <div className="label-semibold text-dark-60">
                                {emailIsDelivering ? 'Delivered' : 'Delivering'}
                              </div>
                              <div className="secondary-regular text-light-80">
                                {formatDate(
                                  convertToMilliseconds(
                                    // eslint-disable-next-line max-len
                                    invoice?.paymentRequest?.lastEmailMessage
                                      ?.events?.delivered?.date,
                                  ),
                                  'MMM DD, YYYY',
                                )}
                              </div>
                            </div>
                          </StatusTrackerItem>
                          <StatusTrackerItem
                            icon="draftsAlt"
                            styles={
                              emailIsOpened
                                ? INFO_STATUS_TRACKER
                                : NEUTRAL_STATUS_TRACKER
                            }
                            progress={
                              emailIsOpened
                                ? StatusesTrackerItem.DONE
                                : StatusesTrackerItem.IN_PROGRESS
                            }
                          >
                            <div className="flex flex-col gap-0.5 pb-2">
                              <div className="label-semibold text-dark-60">
                                {emailIsDelivering ? 'Opened' : 'Opening'}
                              </div>
                              <div className="secondary-regular text-light-80">
                                {formatDate(
                                  convertToMilliseconds(
                                    // eslint-disable-next-line max-len
                                    invoice?.paymentRequest?.lastEmailMessage
                                      ?.events?.opened?.date,
                                  ),
                                  'MMM DD, YYYY',
                                )}
                              </div>
                            </div>
                          </StatusTrackerItem>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          {isMarkForPayment && (
            <div className="bg-white px-6 pb-6">
              <Button
                variant="success"
                className="w-full"
                onClick={markAsPaid}
                iconName="checkSmall"
              >
                Mark as Paid
              </Button>
            </div>
          )}
          {!isMarkForPayment && !isMarkAsPaid && (
            <div className="bg-white px-6 pb-6">
              <div className="flex flex-col gap-3">
                <div className="flex gap-4">
                  <Button
                    variant="secondary"
                    className="w-full"
                    onClick={markAsPaid}
                    iconName="checkSmall"
                  >
                    Mark as Paid
                  </Button>
                  <Button
                    variant="success"
                    className="w-full"
                    onClick={markForPayment}
                    iconName="email"
                  >
                    Mark for Payment
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>
      </FilePreview>
    </div>
  );
};

export default PayInvoice;
