import { FinancialTableRowsTab } from '@/bundles/Shared/widgets/dashboard/widgets/common/financialTable/config/rows';
import { ColorField } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/fields/ColorField';
import { DrilldownField } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/fields/DrilldownField';
import {
  FinansialTableSingleDateBasicTabForm,
  transformFinansialTableSingleDateConfigToForm,
} from '@/bundles/Shared/widgets/dashboard/widgets/financialTableSingeDate/config/component.form';
import { Field } from '@/stories/Field/Field';
import { ThinTabGroup } from '@/stories/Tabs/ThinTabGroup/ThinTabGroup';
import { COMPARISON_DASHBOARD_SECTION_TYPE } from 'bundles/Shared/entities/dashboard/model/types/types';
import type { ComparisonDashboardSectionTypes } from 'bundles/Shared/entities/dashboard/model/types/types';
import { PeriodShiftField } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/fields/PeriodShiftField';
import { UpdateWidgetTitle } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/UpdateWidgetTitle';
import {
  useUpdateWidgetConfig,
  useUpdateWidgetConfigEffect,
  useWidgetConfig,
} from 'bundles/Shared/widgets/dashboard/widgets/common/lib/config';
import { FINANCIAL_TABLE_CONFIGURATION_TABS } from 'bundles/Shared/widgets/dashboard/widgets/common/financialTable/config';
import { FinancialTableWidgetColumnGroups } from 'bundles/Shared/widgets/dashboard/widgets/common/financialTable/ui/financialTableWidgetColumnGroups/component';
import { FinancialTableSingleDateWidgetConfigColumnPanel } from 'bundles/Shared/widgets/dashboard/widgets/financialTableSingeDate/config/column';
import {
  FinancialTableSingleDateWidgetConfigColumnGroup,
  FinancialTableSingleDateWidgetConfigColumnGroupPropsProps,
} from 'bundles/Shared/widgets/dashboard/widgets/financialTableSingeDate/config/group';
import { financialTableSingleDateUpdaters } from 'bundles/Shared/widgets/dashboard/widgets/financialTableSingeDate/config/updaters';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTabs } from 'stories/Tabs/useTabs';

const BasicTab = () => {
  const { widget } =
    useWidgetConfig<
      (typeof COMPARISON_DASHBOARD_SECTION_TYPE)['FINANCIAL_TABLE_SINGLE_DATE']
    >();

  const methods = useForm<FinansialTableSingleDateBasicTabForm>({
    defaultValues: {
      ...transformFinansialTableSingleDateConfigToForm(widget.widgetConfig),
    },
  });
  const { control } = methods;

  useUpdateWidgetConfigEffect<
    FinansialTableSingleDateBasicTabForm,
    typeof COMPARISON_DASHBOARD_SECTION_TYPE.FINANCIAL_TABLE_SINGLE_DATE
  >(methods, financialTableSingleDateUpdaters.updateDefaultOptions);

  return (
    <FormProvider {...methods}>
      <div className="flex flex-col gap-4">
        <UpdateWidgetTitle />
        <DrilldownField control={control} />
        <Field labelText="Period Shift">
          <PeriodShiftField control={control} name="period_shift" />
        </Field>
        <ColorField
          component="Field"
          labelText="Header Background"
          name="header_background"
          control={control}
        />
      </div>
    </FormProvider>
  );
};

const DefaultColumnsTab = () => {
  const { widget } = useWidgetConfig<ComparisonDashboardSectionTypes>();
  const [updateConfig] = useUpdateWidgetConfig(widget.widgetType);

  const [columnGroupModalState, setColumnGroupModalState] = useState<Omit<
    FinancialTableSingleDateWidgetConfigColumnGroupPropsProps,
    'onClose'
  > | null>(null);

  const [columnModalState, setColumnModalState] = useState<Omit<
    React.ComponentProps<
      typeof FinancialTableSingleDateWidgetConfigColumnPanel
    >,
    'onClose'
  > | null>(null);

  return (
    <>
      <FinancialTableWidgetColumnGroups
        actions={{
          column: {
            onHideAll: (newValue) => {
              const config =
                financialTableSingleDateUpdaters.toggleAllColumnsHidden(
                  newValue,
                  widget.widgetConfig,
                );
              updateConfig({ config });
            },
            onHide: ({ columnId }) => {
              const config =
                financialTableSingleDateUpdaters.toggleColumnHidden(
                  { columnId },
                  widget.widgetConfig,
                );
              updateConfig({ config });
            },
            onAdd: (args) => {
              setColumnModalState({
                type: 'create',
                ...args,
              });
            },
            onClone: (args) => {
              updateConfig({
                config: financialTableSingleDateUpdaters.cloneColumn(
                  args,
                  widget.widgetConfig,
                ),
              });
            },
            onEdit: (args) => {
              setColumnModalState({
                type: 'edit',
                ...args,
              });
            },
            onRemove: (args) => {
              updateConfig({
                config: financialTableSingleDateUpdaters.removeColumn(
                  args,
                  widget.widgetConfig,
                ),
              });
            },
          },
          group: {
            onHide: (args) => {
              const config = financialTableSingleDateUpdaters.toggleGroupHidden(
                args,
                widget.widgetConfig,
              );
              updateConfig({ config });
            },
            onAdd: () => {
              setColumnGroupModalState({
                type: 'create',
              });
            },
            onEdit: (args) => {
              setColumnGroupModalState({
                type: 'edit',
                group: {
                  group_id: args.groupId,
                  header_name: args.headerName,
                },
              });
            },
            onClone: (args) => {
              updateConfig({
                config: financialTableSingleDateUpdaters.cloneGroup(
                  args,
                  widget.widgetConfig,
                ),
              });
            },
            onRemove: (args) => {
              updateConfig({
                config: financialTableSingleDateUpdaters.removeGroup(
                  args,
                  widget.widgetConfig,
                ),
              });
            },
          },
        }}
      />
      {columnModalState != null && (
        <FinancialTableSingleDateWidgetConfigColumnPanel
          {...columnModalState}
          onClose={() => setColumnModalState(null)}
          onSubmit={(res) => {
            const { groupId } = columnModalState;
            setColumnModalState(null);
            if (res?.createNew) {
              setTimeout(() => {
                setColumnModalState({
                  type: 'create',
                  groupId,
                });
              });
            }
          }}
        />
      )}
      {columnGroupModalState != null && (
        <FinancialTableSingleDateWidgetConfigColumnGroup
          {...columnGroupModalState}
          onClose={() => setColumnGroupModalState(null)}
        />
      )}
    </>
  );
};

export function FinancialTableSingleDateWidgetConfig() {
  const { tab, thinTabGroupProps } = useTabs(
    FINANCIAL_TABLE_CONFIGURATION_TABS,
    0,
  );
  return (
    <div className="flex flex-col gap-4">
      <ThinTabGroup fullWidth {...thinTabGroupProps} />
      <div className="p-1">
        {tab?.id === 'basic' && <BasicTab />}
        {tab?.id === 'columns' && <DefaultColumnsTab />}
        {tab?.id === 'rows' && (
          <FinancialTableRowsTab
            widgetType={
              COMPARISON_DASHBOARD_SECTION_TYPE.FINANCIAL_TABLE_SINGLE_DATE
            }
          />
        )}
      </div>
    </div>
  );
}
