import React, { useCallback, useRef } from 'react';
import { Popover } from 'stories/Popover/Popover';
import { PopoverProps, PopoverRef } from 'stories/Popover/Popover';

interface ContextMenuCellProps {
  children: React.ReactElement;
  contextMenu: React.ReactElement;
  popoverProps: PopoverProps;
}

export const ContextMenuCell = ({
  children,
  contextMenu,
  popoverProps,
  ...columnProps
}: ContextMenuCellProps) => {
  const ContextMenu = contextMenu;
  const ref = useRef<PopoverRef>(null);

  const hidePopover = useCallback(() => {
    ref.current?.hide();
  }, []);

  return (
    <Popover
      zIndex={999}
      ref={ref}
      trigger="click"
      classes={{
        spanContainer: 'flex w-full h-full justify-between cursor-pointer',
      }}
      className="p-0"
      maxWidth={300}
      appendToBody
      hiddenArrow
      template={
        ContextMenu && (
          <ContextMenu hidePopover={hidePopover} {...columnProps} />
        )
      }
      placement="bottom-end"
      {...popoverProps}
    >
      {children}
    </Popover>
  );
};
