import { DEFAULT_MODAL_Z_INDEX } from '@/bundles/Shared/constants';
import { cn } from '@/shared/lib/css/cn';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import { IconButton } from '@/stories/IconButton/IconButton';
import { Modal } from '@/stories/Modals/Modal/Modal';
import { ResizeHandle } from '@/stories/Modals/Modal/ResizableSidePanel';
import { RouteComponentProps } from '@reach/router';
import { addListener } from '@reduxjs/toolkit';
import {
  Formula,
  closeAllFormulas,
  closeFormula,
  openFormula,
  resetFormulasState,
  selectCurrentFormulaIndex,
  selectFormulasSettingsIsOpened,
  selectOpenedFormulas,
  selectOpenedFormulasCount,
  updateCurrentFormulaIndex,
  updateFormulaByIndex,
  useReportFormulasQueryById,
} from 'bundles/Shared/entities/formula';
import {
  EditFormulaForm,
  isFormulaFormEquals,
} from 'bundles/Shared/features/formula/editFormula';
import { useResetFormulasSettingsOnUnmount } from 'bundles/Shared/widgets/formula/panel';
import { EditFormula } from 'bundles/Shared/widgets/formula/panel/ui/EditFormula';
import { FormulaAndVariablesPanel } from 'bundles/Shared/widgets/formula/panel/ui/FormulaAndVariablesPanel';
import { FormulaTabs } from 'bundles/Shared/widgets/formula/panel/ui/FormulaTabs';
import { debounce } from 'lodash-es';
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import { ResizableBox, ResizableProps } from 'react-resizable';

type FormulaFormInTab = EditFormulaForm &
  Pick<Formula, 'referencedInEntities' | 'invalidReferences'>;

type Props = Partial<DialogProps> & {
  initialOpen?: boolean;
  editMode?: boolean;
  initialFormula?: Formula;
  open?: boolean;
};

const FormulaTab = ({
  formula,
  index,
}: {
  formula: EditFormulaForm;
  index: number;
}) => {
  const dispatch = useAppDispatch();
  const { formula: originalFormula } = useReportFormulasQueryById(
    formula.id ?? null,
  );
  const isDirty =
    originalFormula != null && isFormulaFormEquals(originalFormula, formula);
  const currentFormulaIndex = useAppSelector(selectCurrentFormulaIndex);

  return (
    <FormulaTabs.Tab
      onClick={() => dispatch(updateCurrentFormulaIndex(index))}
      onClose={() => dispatch(closeFormula(index))}
      isActive={index === currentFormulaIndex}
      key={formula.reference}
    >
      <FormulaTabs.Tab.Label className="flex items-center gap-2">
        {formula.label}
        {(!isDirty || formula.id == null) && (
          <span className="h-[8px] w-[8px] rounded-full bg-attention-055" />
        )}
      </FormulaTabs.Tab.Label>
    </FormulaTabs.Tab>
  );
};

const FormulasTabs = ({ className }: PropsWithClassName) => {
  const dispatch = useAppDispatch();
  const openedFormulas = useAppSelector(selectOpenedFormulas);
  const currentFormulaIndex = useAppSelector(selectCurrentFormulaIndex);
  const update = useCallback(
    debounce((index: number, updated: FormulaFormInTab) => {
      dispatch(
        updateFormulaByIndex({
          formula: updated,
          index,
        }),
      );
    }, 300),
    [],
  );

  return (
    <div className={cn('flex h-full grow flex-col p-6', className)}>
      <FormulaTabs>
        {openedFormulas.map((formula, index) => (
          <FormulaTab key={formula.id} formula={formula} index={index} />
        ))}
      </FormulaTabs>
      {openedFormulas.map((formula, index) => (
        <EditFormula
          index={index}
          update={update}
          key={formula.id}
          formula={formula}
          visible={index === currentFormulaIndex}
        />
      ))}
    </div>
  );
};

const BlackIconButton = ({
  ...props
}: React.ComponentProps<typeof IconButton>) => (
  <IconButton
    variant="secondary"
    offHoverStyles
    style={{
      transform: 'translateY(-50%)',
    }}
    className="border-0 bg-neutral-800 text-neutral-000"
    {...props}
  />
);
const TabsWrapper = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<
    {
      onClose: () => void;
      resizable?: boolean;
      onFullScreenToggle?: () => void;
    } & PropsWithClassName
  >
>((props, ref) => {
  const { children, resizable, onFullScreenToggle, className, onClose } = props;
  const resizeHandle = useCallback<
    Exclude<NonNullable<ResizableProps['handle']>, ReactNode>
  >((_, handleRef) => {
    return (
      <ResizeHandle
        className="opacity-0 transition-opacity group-hover:opacity-100"
        position="top"
        ref={handleRef}
      />
    );
  }, []);
  if (!resizable) {
    return (
      <div ref={ref} className="flex flex-col">
        <Modal.DefaultHeader className="flex h-[65px] items-center border-b border-neutral-200 bg-neutral-100 px-6 py-4">
          Editor
          <div className="grow" />
          {onFullScreenToggle && (
            <IconButton onClick={onFullScreenToggle} iconName="collapse" />
          )}
        </Modal.DefaultHeader>
        {children}
      </div>
    );
  }
  return (
    <ResizableBox
      resizeHandles={['n']}
      height={600}
      axis="y"
      handle={resizeHandle}
      className={cn(
        'group col-start-1 row-start-2  mt-0 flex p-2',
        'rounded-2xl border-4  border-neutral-800',
        className,
      )}
    >
      <div className="absolute right-8 top-0 z-10 flex gap-2 opacity-0 transition-opacity group-hover:opacity-100">
        {onFullScreenToggle && (
          <BlackIconButton iconName="expand" onClick={onFullScreenToggle} />
        )}
        <BlackIconButton onClick={onClose} iconName="closeSmall" />
      </div>
      {children}
    </ResizableBox>
  );
});

const SIDE_PANEL_Z_INDEX = DEFAULT_MODAL_Z_INDEX - 1;

type WorkspaceContextType = {
  rightPanelOpen: boolean;
  bottomPanelOpen: boolean;
  toggleRightPanel: () => void;
  toggleBottomPanel: () => void;
  rightPanelRef: React.RefObject<HTMLDivElement> | null;
  bottomPanelRef: React.RefObject<HTMLDivElement> | null;
  renderRightPanel: (node: React.ReactNode) => React.ReactNode;
  renderBottomPanel: (node: React.ReactNode) => React.ReactNode;
  closeRightPanel: () => void;
  closeBottomPanel: () => void;
  openBottomPanel: () => void;
  openRightPanel: () => void;
};

const WorkspaceContext = createContext<WorkspaceContextType>({
  rightPanelOpen: false,
  bottomPanelOpen: false,
  renderRightPanel: (node) => node,
  renderBottomPanel: (node) => node,
  toggleRightPanel: () => {},
  toggleBottomPanel: () => {},
  openBottomPanel: () => {},
  openRightPanel: () => {},
  closeRightPanel: () => {},
  closeBottomPanel: () => {},
  rightPanelRef: null,
  bottomPanelRef: null,
});

export const useWorkspaceContext = () => {
  const context = useContext(WorkspaceContext);
  if (!context) {
    throw new Error(
      'useWorkspaceContext must be used within a WorkspaceProvider',
    );
  }
  return context;
};

export const Workspace = ({
  children,
  ...props
}: React.PropsWithChildren<Props> &
  RouteComponentProps & {
    rightPanelOpen?: boolean;
    bottomPanelOpen?: boolean;
  }) => {
  const rightPanelRef = useRef<HTMLDivElement>(null);
  const bottomPanelRef = useRef<HTMLDivElement>(null);
  const [fullScreen] = useState(false);
  const withChildren = children != null;

  const [rightPanelOpen, setRightPanelOpen] = useState(props.rightPanelOpen);
  const [bottomPanelOpen, setBottomPanelOpen] = useState(props.bottomPanelOpen);

  const toggleRightPanel = useCallback(() => {
    setRightPanelOpen((prev) => !prev);
  }, []);

  const openRightPanel = useCallback(() => {
    setRightPanelOpen(true);
  }, []);

  const closeRightPanel = useCallback(() => {
    setRightPanelOpen(false);
  }, []);

  const toggleBottomPanel = useCallback(() => {
    setBottomPanelOpen((prev) => !prev);
  }, []);

  const openBottomPanel = useCallback(() => {
    setBottomPanelOpen(true);
  }, []);

  const closeBottomPanel = useCallback(() => {
    setBottomPanelOpen(false);
  }, []);

  const contextValue = useMemo(
    () => ({
      rightPanelOpen,
      bottomPanelOpen,
      renderRightPanel: (node) => {
        if (!rightPanelRef.current || !rightPanelOpen) return null;
        return createPortal(node, rightPanelRef.current);
      },
      renderBottomPanel: (node) => {
        if (!bottomPanelRef.current || !bottomPanelOpen) return null;
        return createPortal(node, bottomPanelRef.current);
      },
      toggleRightPanel,
      toggleBottomPanel,
      rightPanelRef,
      bottomPanelRef,
      closeRightPanel,
      closeBottomPanel,
      openRightPanel,
      openBottomPanel,
    }),
    [rightPanelOpen, bottomPanelOpen, toggleRightPanel, toggleBottomPanel],
  );

  return (
    <WorkspaceContext.Provider value={contextValue}>
      <div
        className={cn(
          'grid h-screen transition-all',
          rightPanelOpen && 'grid-cols-[minmax(0,auto),600px]',
          bottomPanelOpen && 'grid-rows-[auto,minmax(min-content,max-content)]',
        )}
      >
        {children && (
          <div className={cn('h-full overflow-auto', fullScreen && 'hidden')}>
            {children}
          </div>
        )}
        <TabsWrapper
          className={cn(!bottomPanelOpen && 'col-start-1 row-start-2 hidden')}
          onClose={closeBottomPanel}
          resizable={withChildren && !fullScreen}
        >
          <div className="h-full w-full" ref={bottomPanelRef} />
        </TabsWrapper>
        <div
          ref={rightPanelRef}
          className={cn(
            'col-start-2 row-span-2 h-full overflow-auto',
            !rightPanelOpen && 'hidden',
          )}
        />
      </div>
    </WorkspaceContext.Provider>
  );
};

export const WidgetFormulasAndVariablesWorkspace = () => {
  const dispatch = useAppDispatch();
  const openedFormulasCount = useAppSelector(selectOpenedFormulasCount);

  const {
    bottomPanelOpen,
    renderRightPanel,
    renderBottomPanel,
    openBottomPanel,
    closeBottomPanel,
    openRightPanel,
    closeRightPanel,
  } = useWorkspaceContext();
  const handleClose = () => {
    dispatch(resetFormulasState());
    closeRightPanel();
    closeBottomPanel();
  };

  useEffect(() => {
    const unsubscribe = dispatch(
      addListener({
        actionCreator: openFormula,
        effect: () => {
          openBottomPanel();
          openRightPanel();
        },
      }),
    );
    return unsubscribe;
  }, []);

  useEffect(() => {
    if (openedFormulasCount > 0 && !bottomPanelOpen) {
      closeBottomPanel();
      closeRightPanel();
      dispatch(closeAllFormulas());
    }
  }, [openedFormulasCount, bottomPanelOpen]);

  return (
    <>
      {openedFormulasCount > 0 && renderBottomPanel(<FormulasTabs />)}
      {renderRightPanel(
        <FormulaAndVariablesPanel
          zIndex={SIDE_PANEL_Z_INDEX}
          onClose={handleClose}
        />,
      )}
    </>
  );
};

export function FormulasAndVariablesWorkspace({
  onClose,
  editMode,
  initialOpen,
  children,
}: React.PropsWithChildren<Props> & RouteComponentProps) {
  const [fullScreen, setFullScreen] = useState(false);
  const dispatch = useAppDispatch();
  const opened = useAppSelector(selectFormulasSettingsIsOpened) ?? initialOpen;
  const withChildren = children != null;

  useResetFormulasSettingsOnUnmount();

  const openedFormulasCount = useAppSelector(selectOpenedFormulasCount);
  const isNotEditMode = openedFormulasCount === 0 && !editMode;
  const handleClose = () => {
    dispatch(resetFormulasState());
    setFullScreen(false);
    onClose?.();
  };

  return (
    <div
      className={cn(
        'grid h-screen transition-all',
        opened && 'grid-cols-[minmax(0,auto),600px]',
        !isNotEditMode && 'grid-rows-[auto,minmax(min-content,max-content)]',
      )}
    >
      {children && (
        <div className={cn('h-full overflow-auto', fullScreen && 'hidden')}>
          {children}
        </div>
      )}
      {!isNotEditMode && (
        <TabsWrapper
          onFullScreenToggle={
            editMode ? undefined : () => setFullScreen(!fullScreen)
          }
          onClose={() => {
            dispatch(closeAllFormulas());
          }}
          resizable={withChildren && !fullScreen}
        >
          <FormulasTabs
            className={cn(
              withChildren && !fullScreen && 'h-full grow overflow-auto p-0',
            )}
          />
        </TabsWrapper>
      )}
      {opened && (
        <FormulaAndVariablesPanel
          zIndex={SIDE_PANEL_Z_INDEX}
          onClose={handleClose}
        />
      )}
    </div>
  );
}
