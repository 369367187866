import { useModal } from '@/shared/lib/hooks/useModal';
import RecipientsModal from 'bundles/Shared/components/RecipientsModal/RecipientsModal';
import { Input } from 'stories/FormControls/Inputs/Input/Input';
import EmailTemplateFormInputGroup from '@/bundles/EmailBuilder/components/EmailTemplateFormInputGroup';
import TemplateEditor from '@/bundles/Settings/components/EmailSettings/editor/TemplateEditor';
import * as Type from '@/bundles/Settings/components/EmailSettings/editor/types';
import ReplyRecipientsSection from '@/bundles/Settings/components/EmailSettings/ReplyRecipientsSection';
import {
  confirmResetingReplyRecipients,
  TOOLBAR_GROUPS_VARIABLES,
  TEMPLATE_DESCRIPTION,
} from '@/bundles/Settings/components/EmailSettings/utils/consts';

interface Props {
  template: Type.ForgotPasswordTemplateState;
  variables: Type.ForgotPasswordTemplateVars;
  replyRecipients: Type.ReplyRecipient[];
  onTemplateChange: (template: Type.ForgotPasswordTemplateState) => void;
  isLoading?: boolean;
}

const ForgotPasswordTemplateForm = ({
  template,
  variables,
  replyRecipients,
  onTemplateChange,
  isLoading,
}: Props) => {
  const { confirm, openModal } = useModal();
  const setTemplateField = <K extends keyof Type.ForgotPasswordTemplateState>(
    field: K,
    value: Type.ForgotPasswordTemplateState[K],
  ) => {
    onTemplateChange({
      ...template,
      [field]: value,
    });
  };

  const handleSetReplyRecipients = async () => {
    const res = await openModal(RecipientsModal, {
      replyRecipientOptions: replyRecipients,
      initSelectedRecipients: template.replyRecipients ?? [],
    });
    if (!res) return;

    setTemplateField('replyRecipients', res);
  };

  const handleReset = async () => {
    const result = await confirm(confirmResetingReplyRecipients);

    if (!result) return;

    setTemplateField('replyRecipients', []);
  };

  return (
    <form>
      <div className="flex flex-col gap-4">
        <div>
          <EmailTemplateFormInputGroup isLoading={isLoading}>
            <label
              className="dark-60 label-regular secondary-regular mb-2 text-dark-60"
              htmlFor="subject"
            >
              Email Subject
            </label>
            <Input
              id="subject"
              onChange={(e) => setTemplateField('subject', e.target.value)}
              value={template.subject}
              placeholder="Enter Subject"
            />
            <ReplyRecipientsSection
              replyRecipients={template.replyRecipients ?? []}
              onReset={handleReset}
              onSet={handleSetReplyRecipients}
            />
          </EmailTemplateFormInputGroup>
        </div>
        <div>
          <EmailTemplateFormInputGroup
            title="Template"
            description={TEMPLATE_DESCRIPTION}
            isLoading={isLoading}
          >
            <div className="label-regular mb-2 text-dark-60">
              Greeting Line<span className="red">*</span>
            </div>
            <TemplateEditor
              placeholder="Enter Greeting Line"
              text={template.greeting}
              setText={(text: RawHTML) => setTemplateField('greeting', text)}
              variables={variables.greeting}
              forceHeading
            />
            <div className="label-regular mb-2 mt-4 text-dark-60">
              Body<span className="red">*</span>
            </div>
            <TemplateEditor
              placeholder="Enter Main Text"
              text={template.body}
              setText={(text: RawHTML) => setTemplateField('body', text)}
              variables={variables.body}
            />
            <div className="label-regular mb-2 mt-4 text-dark-60">
              Reset Password Button
              <span className="red">*</span>
            </div>
            <TemplateEditor
              placeholder="Enter Button Text"
              text={template.callToAction}
              setText={(text: RawHTML) =>
                setTemplateField('callToAction', text)
              }
              variables={variables.callToAction}
              toolbarGroups={TOOLBAR_GROUPS_VARIABLES}
            />
            <div className="label-regular mb-2 mt-4 text-dark-60">
              Additional Info
            </div>
            <TemplateEditor
              placeholder="Enter Additional Info"
              text={template.additionalInfo}
              setText={(text: RawHTML) =>
                setTemplateField('additionalInfo', text)
              }
              variables={variables.additionalInfo}
              toolbarGroups={TOOLBAR_GROUPS_VARIABLES}
            />
          </EmailTemplateFormInputGroup>
        </div>
      </div>
    </form>
  );
};

export default ForgotPasswordTemplateForm;
