import React, { useEffect, useMemo, useState } from 'react';
import { Badge } from 'stories/Badges/Badge/Badge';
import { Button } from 'stories/Button/Button';
import { Icon } from 'stories/Icon/Icon';
import { Modal } from 'stories/Modals/Modal/Modal';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import { IDeal } from 'types/Deal';
import { AgGridReact } from 'ag-grid-react';
import { Link } from '@reach/router';
import LoadingPanel from 'bundles/Shared/components/LoadingPanel';
import { ColDef } from 'ag-grid-community';
import HeaderComponent from 'bundles/Scoreboard/REconcile/components/scoREcard/HeaderComponent';
import { fetchDashboard } from 'bundles/Scoreboard/Pipeline/actions';
import LoadingTable from 'bundles/Shared/components/LoadingTable';
import CardScopeDropdown from 'bundles/Shared/components/CardScopeDropdown';

interface IActiveBucket {
  name: string;
  deals: IDeal[];
}

// TODO: refactor this once we setup new pipeline dashboard V2
const PipelineTable = () => {
  const dispatch = useAppDispatch();

  const propertyTypes = useAppSelector((state) => state.deals.propertyTypes);
  const dashboard = useAppSelector((state) => state.deals.dashboard);

  const [activeBucket, setActiveBucket] = useState<IActiveBucket>('');
  const [missingDealsModalIsOpen, setMissingDealsModalIsOpen] = useState(false);

  const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);
  const gridStyle = useMemo(() => ({ height: '60vh', width: '100%' }), []);

  const defaultColDef = useMemo<ColDef>(
    () => ({
      resizable: true,
      minWidth: 110,
      width: 132,
      sortable: true,
      headerComponentParams: {
        enableMenu: false,
      },
      headerComponent: HeaderComponent,
      suppressMovable: true,
      unSortIcon: true,
    }),
    [],
  );

  const transformTeam = (team) =>
    team
      .replace(']', ',')
      .replace(/[^a-zA-Z, ]/g, '')
      .trim();

  const [columnDefs] = useState([
    {
      field: 'name',
      headerName: 'Deal name',
      cellRenderer: (params) => (
        <Link color="link" to={`/deals/${params.data.id}`}>
          {params.data.name}
        </Link>
      ),
    },
    {
      field: 'deal_sub_type',
      headerName: 'Sub Type',
    },
    {
      field: 'deal_team',
      headerName: 'Team',
      cellRenderer: (params) => transformTeam(params.data.deal_team),
    },
    {
      field: 'deal_created_at',
      headerName: 'Created At',
    },
    {
      field: 'delivery_year',
      headerName: 'Delivery Year',
    },
    {
      field: 'pipeline_year',
      headerName: 'Pipieline Year',
    },
    {
      field: 'last_updated',
      headerName: 'Last Update',
    },
    {
      field: 'upcoming_critical_date',
      headerName: 'Upcoming Critical Date',
    },
  ]);

  useEffect(() => {
    dispatch(fetchDashboard('Consolidated'));
  }, []);

  const setDealSubType = (dealSubType) => {
    dispatch(fetchDashboard(dealSubType));
  };

  const closeModal = () => {
    setActiveBucket('');
  };

  const closeMissingDealsModal = () => {
    setMissingDealsModalIsOpen(false);
  };

  if (!dashboard) return <LoadingTable />;
  const { buckets } = dashboard;
  const missingDeals = buckets
    .reduce((acc, bucket) => acc.concat(bucket.deals), [])
    .filter((deal) => !deal.pipeline_year);

  return (
    <div className="mt-0 row">
      <div className="col-lg-12">
        <div className="card">
          <div className="card-header card-header-action">
            <div>
              Pipeline
              <h4 className="dark-60">Goal Detail</h4>
            </div>
            <div className="flex items-center card-action-wrap">
              {missingDeals && missingDeals.length > 0 && (
                <Button
                  onClick={() => setMissingDealsModalIsOpen(true)}
                  variant="danger"
                  className="mr-4"
                >
                  Missing Deals
                  <Badge
                    backgroundColor="var(--light)"
                    textColor="var(--light-60)"
                    className="ml-xs"
                  >
                    {missingDeals.length}
                  </Badge>
                </Button>
              )}
              <CardScopeDropdown
                onChange={(propertyType) => setDealSubType(propertyType)}
                items={['Consolidated', ...propertyTypes]}
                className="ml-m"
              />
            </div>
          </div>
          <div className="p-0 card-body">
            <div className="col-sm-12">
              <div className="table-wrap">
                <div className="table-responsive">
                  <table className="table table-bordered">
                    <tbody>
                      <tr>
                        <td className="text-center green-header" rowSpan="2">
                          Name
                        </td>

                        {buckets[0]?.goals?.map((item, index) => (
                          <td
                            key={index}
                            className="text-center green-header"
                            colSpan="3"
                          >
                            {item.name}
                          </td>
                        ))}
                      </tr>

                      <tr>
                        {buckets[0]?.goals?.map((item, index) => (
                          <React.Fragment key={item?.name ?? index}>
                            <td className="text-center green-header">Actual</td>
                            <td className="text-center green-header">
                              Adj. Goal
                            </td>
                            <td className="text-center green-header">Status</td>
                          </React.Fragment>
                        ))}
                      </tr>

                      {buckets.map((item, index) => (
                        <tr key={index}>
                          <td>
                            <a
                              href="bundles/Scoreboard/DealPath/components/Pipeline/PipelineTable"
                              onClick={(e) => {
                                e.preventDefault();
                                setActiveBucket(item);
                              }}
                              className="icon-label"
                            >
                              <Icon iconName="search" />{' '}
                              <span className="btn-text">{item.name}</span>
                            </a>
                          </td>

                          {item.goals?.map((goal, idx) => (
                            <React.Fragment key={goal?.name ?? idx}>
                              <td>{goal.actual}</td>
                              <td>
                                {Math.sign(goal.adj_goal) <= 0 && (
                                  <span>-</span>
                                )}
                                {Math.sign(goal.adj_goal) > 0 && (
                                  <span>{goal.adj_goal}</span>
                                )}
                              </td>
                              <td>
                                {Math.sign(goal.needed) <= 0 && (
                                  <Badge
                                    backgroundColor="var(--green-light-3)"
                                    textColor="var(--green-dark-2)"
                                    className="ml-xs"
                                  >
                                    On Track
                                  </Badge>
                                )}
                                {Math.sign(goal.needed) > 0 && (
                                  <Badge backgroundColor="#d6221c">
                                    {Math.abs(goal.needed)} deal(s) short
                                  </Badge>
                                )}
                              </td>
                            </React.Fragment>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {missingDealsModalIsOpen && (
                    <Modal
                      toggle={closeMissingDealsModal}
                      header="Missing Deals"
                      size="900"
                    >
                      {missingDeals && missingDeals.length > 0 && (
                        <div style={containerStyle} className="ag-grid-30">
                          <div
                            className="ag-theme-alpine scorecard-table"
                            style={gridStyle}
                          >
                            <AgGridReact
                              rowData={missingDeals}
                              columnDefs={columnDefs}
                              defaultColDef={defaultColDef}
                              loadingOverlayComponent={LoadingPanel}
                            />
                          </div>
                        </div>
                      )}
                    </Modal>
                  )}
                  {activeBucket && (
                    <Modal
                      toggle={closeModal}
                      header={`${activeBucket.name} Deals`}
                      size="xl"
                    >
                      <div style={containerStyle} className="ag-grid-30">
                        <div
                          className="ag-theme-alpine scorecard-table"
                          style={gridStyle}
                        >
                          <AgGridReact
                            rowData={activeBucket.deals}
                            columnDefs={columnDefs}
                            defaultColDef={defaultColDef}
                            loadingOverlayComponent={LoadingPanel}
                          />
                        </div>
                      </div>
                    </Modal>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PipelineTable;
