import { UNSUPPORTED_DASHBOARD_INFO_TEXT } from '@/bundles/Settings/components/REport/Dashboards/Dashboard/config';
import { useModal } from '@/shared/lib/hooks/useModal';
import { mapLayoutToLayoutDto } from 'bundles/Shared/entities/dashboard/api';
import {
  removeSectionFromLayout,
  useDashboardContext,
} from 'bundles/Shared/entities/dashboard/lib';
import { ReportDashboardType } from 'bundles/Shared/entities/dashboard/model/types/types';
import type { ReportDashboardSectionPositionWithId } from 'bundles/Shared/entities/dashboard/model/types/types';

import {
  useDeleteApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdWidgetSectionsIdMutation,
  useDeleteApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdWidgetSectionsIdMutation,
  useDeleteApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdWidgetSectionsIdMutation,
  usePutApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdWidgetSectionsMoveMutation,
  usePutApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdWidgetSectionsMoveMutation,
  usePutApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdWidgetSectionsMoveMutation,
} from '@/shared/api/dashboardSettingsEnhancedApi';

import { useCallback, useMemo } from 'react';

export const useDashboardRemoveSection = () => {
  const { dashboardId, dashboardType } = useDashboardContext();
  const { confirm } = useModal();
  const [removeObjectWidget] =
    useDeleteApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdWidgetSectionsIdMutation();
  const [removeEagleEyeWidget] =
    useDeleteApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdWidgetSectionsIdMutation();

  const [removeComparison] =
    useDeleteApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdWidgetSectionsIdMutation();

  const removeWidgetSection = useMemo(() => {
    switch (dashboardType) {
      case ReportDashboardType.OBJECT: {
        return removeObjectWidget;
      }
      case ReportDashboardType.EAGLE_EYE: {
        return removeEagleEyeWidget;
      }
      case ReportDashboardType.COMPARISON_MODE: {
        return removeComparison;
      }
      default: {
        return () => {
          toastr.warning(UNSUPPORTED_DASHBOARD_INFO_TEXT);
        };
      }
    }
  }, [removeObjectWidget, removeEagleEyeWidget, removeComparison]);

  return useCallback(
    async ({
      widgetId,
      boardId,
      layout,
    }: {
      widgetId: string;
      layout: ReportDashboardSectionPositionWithId[];
      boardId?: string;
    }) => {
      if (!boardId) return;

      const res = await confirm({
        title: 'Delete Widget',
        subtitle:
          'Are you sure you want to delete this widget? This cannot be undone',
      });
      if (!res) {
        return;
      }
      const removeSection = removeWidgetSection;

      return await removeSection({
        id: widgetId,
        eagleEyeDashboardId: dashboardId,
        objectDashboardId: dashboardId,
        comparisonDashboardId: dashboardId,
        boardId,
        body: {
          dashboard_layout: mapLayoutToLayoutDto(
            removeSectionFromLayout(layout, widgetId),
          ),
        },
      });
    },
    [dashboardId],
  );
};

export const useDashboardUpdateLayout = () => {
  const { dashboardType, dashboardId } = useDashboardContext();
  const [objectMutation] =
    usePutApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdWidgetSectionsMoveMutation();
  const [eagleEyeMutation] =
    usePutApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdWidgetSectionsMoveMutation();
  const [comparisonMutation] =
    usePutApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdWidgetSectionsMoveMutation();

  const moveDashboardLayoutMutation = useMemo(() => {
    switch (dashboardType) {
      case ReportDashboardType.OBJECT: {
        return objectMutation;
      }
      case ReportDashboardType.EAGLE_EYE: {
        return eagleEyeMutation;
      }
      case ReportDashboardType.COMPARISON_MODE: {
        return comparisonMutation;
      }
      default: {
        return () => {
          toastr.warning(UNSUPPORTED_DASHBOARD_INFO_TEXT);
        };
      }
    }
  }, [objectMutation, eagleEyeMutation, comparisonMutation]);

  return useCallback(
    async ({
      layout,
      boardId,
    }: {
      layout: ReportDashboardSectionPositionWithId[];
      boardId?: string;
    }) => {
      if (boardId == null) return;

      const res = await moveDashboardLayoutMutation({
        objectDashboardId: dashboardId,
        eagleEyeDashboardId: dashboardId,
        comparisonDashboardId: dashboardId,
        boardId,
        body: mapLayoutToLayoutDto(layout),
      });
      return res;
    },
    [dashboardId],
  );
};
