import { AgGridReact, AgGridReactProps } from 'ag-grid-react';
import { ColDef } from 'ag-grid-community';
import React, { FC, useEffect, useRef, useState } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { formatAmount } from '@/shared/lib/formatting/number';
import CustomHeader from '@/bundles/Shared/components/GridTable/CustomHeader';
import '@/bundles/Shared/components/GridTable/styles/grid.scss';
import GridRowActions from '@/bundles/Shared/components/GridTable/GridRowActions';
import { useAgGridRef } from '@/lib/ag-grid/utils';
type TColumnDefsItem = {
  field: string;
  title: string;
  description: string;
  width: number;
  align?: string;
  currency?: boolean;
  formula?: string | boolean;
  deleted?: boolean;
  editable?: boolean;
  error?: boolean;
};

interface TGenColumnDefsItem extends ColDef {
  headerComponentParams: {
    title: string;
    description: string;
    formula?: string | boolean;
  };
  valueFormatter: any;
}

interface TConfigGridProps {
  editCells?: boolean;
  deleteRow?: boolean;
}

interface Props {
  data: any[];
  initialColumnDefs: TColumnDefsItem[];
  initialRows: boolean[];
  agGridProps: AgGridReactProps;
  height?: number | string;
  afterSaveCell?: (newValue: string, rowId: number, field: string) => void;
  onDestroyAction?: (itemId: number) => boolean;
  configGridProps: TConfigGridProps;
}

const GridTable: FC<Props> = ({
  data,
  initialColumnDefs = [],
  initialRows,
  agGridProps,
  configGridProps,
  afterSaveCell,
  onDestroyAction,
}) => {
  const gridRef = useAgGridRef();
  const wrapperGridRef = useRef<HTMLDivElement>();

  const [rowData] = useState(data);
  const [columnDefs, setColumnDefs] = useState<TGenColumnDefsItem[]>();
  useEffect(() => {
    let widthAll = 0;
    const widthWrapper = wrapperGridRef?.current.offsetWidth;
    initialColumnDefs.forEach((el) => (widthAll = widthAll + el.width));
    const percentWidth = widthAll < widthWrapper;
    const columns = initialColumnDefs.map(
      (t): TGenColumnDefsItem => ({
        field: t.field,
        headerComponent: CustomHeader,
        headerComponentParams: {
          title: t.title,
          description: t.description,
          formula: t?.formula,
        },
        width: percentWidth ? t.width * (widthWrapper / widthAll) : t.width,
        wrapText: true,
        cellClass: `cellCustom_${t.align} ${t.error ? 'error' : ''}`,
        valueFormatter: t.currency
          ? ({ value }) =>
              formatAmount(value, {
                minimumFractionDigits: 2,
              })
          : false,
        editable: t.editable === false ? t.editable : true,
        cellRenderer:
          configGridProps.deleteRow && t.deleted ? GridRowActions : undefined,
        cellRendererParams: (params) => ({
          values: params.data.id,
          onDestroyAction,
          gridRef,
        }),
      }),
    );
    setColumnDefs(columns);
  }, [initialColumnDefs]);

  const onCellValueChanged = ({ newValue, data, colDef }) => {
    afterSaveCell(newValue, data.id, colDef.field);
  };

  const getRowClass = (params) => {
    if (!initialRows[params.node.rowIndex]) return 'error';
  };

  const onCellEditingStarted = ({ data }) => {
    if (data.recalculated) gridRef.current.api.stopEditing();
  };
  return (
    <div
      ref={wrapperGridRef}
      className="ag-grid-30"
      style={{ height: '100%', width: '100%' }}
    >
      {columnDefs && (
        <AgGridReact
          ref={gridRef}
          className="ag-theme-cor"
          rowData={rowData}
          columnDefs={columnDefs}
          onCellEditingStarted={onCellEditingStarted}
          onCellValueChanged={onCellValueChanged}
          getRowClass={getRowClass}
          {...agGridProps}
        />
      )}
    </div>
  );
};

export default GridTable;
