import React from 'react';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import SettingsCard from '@/bundles/Shared/components/LeftSidebar/cards/SettingsCard';
import { SidePanel } from 'stories/Modals/Modal/Modal';

type Props = DialogProps;

const SettingsModal = ({ onClose }: Props) => (
  <SidePanel size="md" toggle={onClose} header="Settings">
    <SettingsCard />
  </SidePanel>
);

export default SettingsModal;
