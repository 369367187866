import { useCapitalInvestments } from '@/bundles/REturn/hooks/useCapitalInvestments';
import {
  usePostApiCapitalInvestmentContributionsBulkMutation,
  usePostApiCapitalInvestmentDistributionsBulkMutation,
} from '@/entities/return/api/capitalInvestmentObjectsEnhancedApi';
import { InvestmentIndex } from '@/entities/return/api/capitalInvestmentObjectsGeneratedApi';
import { cn } from '@/shared/lib/css/cn';
import { RouteComponentProps } from '@reach/router';
import {
  DISTRIBUTION_KINDS,
  ReportLineItem,
} from 'bundles/REturn/actions/types';
import { useInvalidateCapitalInvestments } from 'bundles/REturn/hooks/useLoadedInvestmentObject';
import dayjs from 'dayjs';
import { useItems } from '@/shared/lib/hooks/items/useItems';
import { useNavigateBack } from '@/shared/lib/hooks/navigation';
import { useModal } from '@/shared/lib/hooks/useModal';
import {
  ExtractParams,
  generateUrl,
  ROUTES_ROOT,
  TRouteQueryParams,
  useQueryParams,
} from '@/shared/lib/hooks/useNavigation';
import { startCase } from 'lodash-es';
import pluralize from 'pluralize';
import { FC, useEffect, useState } from 'react';
import { IPeriodItem } from 'stories/FlexibleFilterByPeriods/types';
import { Button } from 'stories/Button/Button';
import { Icon } from 'stories/Icon/Icon';
import HeaderForStepperModal from '@/bundles/REturn/components/Ownership/modals/HeaderForStepperModal/HeaderForStepperModal';
import {
  createPossiblePeriods,
  subtractAndFormatDate,
} from '@/bundles/REturn/components/Ownership/modals/linkTxModals/linkTxToManuallyAdded/utils';
import { CAPITAL_INVESTMENT_ENTRY_AMOUNT_KEY_MAP } from '@/bundles/REturn/components/Ownership/modals/manageCapitalInvestmentModal/consts';
import {
  CONFIRM_CLOSING_WORKFLOW_PROPS,
  CREATE_ENTRY_WORKFLOW_STEPS,
  SPLIT_TRANSACTION_OPTION,
} from '@/bundles/REturn/components/workflows/createEntry/consts';
import {
  StepIdx,
  useWorkflowSteps,
} from '@/bundles/REturn/components/workflows/createEntry/hooks/useWorkflowSteps';
import { DistributionKindSelector } from '@/bundles/REturn/components/workflows/createEntry/rightPanel/DistributionKindSelector';
import { InvestmentEntityStep } from '@/bundles/REturn/components/workflows/createEntry/rightPanel/InvestmentEntityStep';
import { TransactionStep } from '@/bundles/REturn/components/workflows/createEntry/rightPanel/TransactionStep';
import { SelectedInvEntities } from '@/bundles/REturn/components/workflows/createEntry/selectedItems/SelectedInvEntities';
import { SelectedTransactions } from '@/bundles/REturn/components/workflows/createEntry/selectedItems/SelectedTransactions';
import { SplitContribution } from '@/bundles/REturn/components/workflows/createEntry/splitModal/SplitContribution';
import { SplitDistribution } from '@/bundles/REturn/components/workflows/createEntry/splitModal/SplitDistributions';
import { ReviewStep } from '@/bundles/REturn/components/workflows/createEntry/steps/ReviewStep';
import { SelectInvestmentEntityStep } from '@/bundles/REturn/components/workflows/createEntry/steps/SelectInvestmentEntityStep';
import { SelectTransactionStep } from '@/bundles/REturn/components/workflows/createEntry/steps/SelectTransactionsStep';
import {
  DistributionKindOption,
  EntyTxWithReviewInfo,
  SingleDistributionKind,
} from '@/bundles/REturn/components/workflows/createEntry/types';
import {
  createConfirmSuccessModalProps,
  createEntryWorkflowMap,
  getEntryTypeFromSearchParams,
} from '@/bundles/REturn/components/workflows/createEntry/utils';
import { createWorkflowFlags } from '@/bundles/REturn/components/workflows/createEntry/utils/createWorkflowFlags';
import { bulkLinkingReviewEntryTxs } from '@/bundles/REturn/components/workflows/createEntry/utils/goForward/bulkLinking';
import { simpleLinkingReviewEntryTx } from '@/bundles/REturn/components/workflows/createEntry/utils/goForward/simpleLinking';
import { DATE_STRING_FORMAT } from '@/shared/lib/converters';

export const CreateEntryWorkflow: FC<
  Required<
    RouteComponentProps<
      ExtractParams<typeof ROUTES_ROOT.return.object.createEntry.fullPath>
    >
  >
> = ({ objectId }) => {
  const [createBulkContributions] =
    usePostApiCapitalInvestmentContributionsBulkMutation();
  const [createBulkDistributions] =
    usePostApiCapitalInvestmentDistributionsBulkMutation();

  const nowDate = dayjs().startOf('month').format(DATE_STRING_FORMAT);
  const invalidateCapitalInvestments = useInvalidateCapitalInvestments();

  const initialFromDate = subtractAndFormatDate(nowDate, 3);
  const possiblePeriods = createPossiblePeriods(nowDate, 36);
  const [periods, setPeriods] = useState<IPeriodItem['period'][]>([
    initialFromDate,
    nowDate,
  ]);

  const { entryType: entryTypeFromSearchParams, capitalInvestmentId } =
    useQueryParams<
      TRouteQueryParams[typeof ROUTES_ROOT.return.object.createEntry.fullPath]
    >();
  const entryType = getEntryTypeFromSearchParams(entryTypeFromSearchParams);

  const { data: capitalInvestmentsData } = useCapitalInvestments();

  const capitalInvestments = capitalInvestmentsData ?? [];

  const [creatingEntries, setCreatingEntries] = useState(false);
  const [singleDistributionKind, setSingleDistributionKind] =
    useState<SingleDistributionKind>(null);
  const { confirm, openModal, modalList } = useModal();
  const [txsLen, setTxsLen] = useState(0);
  const [
    selectedTxs,
    setSelectedTxs,
    { onRemoveItemById: unSelectTransaction, onReset: resetSelectedTxs },
  ] = useItems<ReportLineItem>();

  const [
    selectedCapitalInvestments,
    setSelectedInvEntities,
    { onRemoveItemById: unSelectInvEntity, onReset: resetSelectedInvEntities },
  ] = useItems<InvestmentIndex>();

  useEffect(() => {
    if (capitalInvestmentId) {
      const capitalInvestment = capitalInvestments.find(
        (ci) => ci.id == capitalInvestmentId,
      );
      if (capitalInvestment) {
        setSelectedInvEntities([capitalInvestment]);
      }
    }
  }, []);

  const [entryTxWithReviewInfoItems, setEntryTxWithReviewInfoItems] = useState<
    EntyTxWithReviewInfo[]
  >([]);

  const [fullWidth, setFullWidth] = useState(false);
  const { navigateBack } = useNavigateBack({
    fallbackUrl: generateUrl(ROUTES_ROOT.return.object.fullPath, {
      queryParams: {},
      pathParams: { objectId },
    }),
  });
  const {
    stepIdx,
    onBackward,
    onForward,
    onReset: resetStepIdx,
  } = useWorkflowSteps();

  const workflowFlags = createWorkflowFlags({
    selectedTxs,
    selectedCapitalInvestments,
    stepIdx,
    entryType,
    singleDistributionKind,
  });

  const workflowMap = createEntryWorkflowMap({ stepIdx, entryType });

  const resetWorkflow = () => {
    resetSelectedTxs();
    resetSelectedInvEntities();
    resetStepIdx();
    setEntryTxWithReviewInfoItems([]);
    setSingleDistributionKind(null);
    setFullWidth(false);
  };

  const resolveGoForwardDisabled = () => {
    if (workflowFlags.steps.transactions.isCurrent) {
      return workflowFlags.steps.transactions.isDisabledToGoForward;
    }

    if (workflowFlags.steps.invEntities.isCurrent) {
      return workflowFlags.steps.invEntities.isDisabledToGoForward;
    }

    return creatingEntries;
  };

  const resolveGoBackward = () => {
    switch (true) {
      case workflowFlags.steps.invEntities.isCurrent: {
        return () => {
          resetSelectedInvEntities();
          setSingleDistributionKind(null);
          onBackward();
        };
      }

      case workflowFlags.steps.review.isCurrent: {
        return () => {
          onBackward();
          setFullWidth(false);
        };
      }
      default: {
        throw new Error(
          'Should not happen. Step index should be in range of 1-2',
        );
      }
    }
  };

  const resolveGoForward = () => {
    const totalEntryAmountKeyName =
      CAPITAL_INVESTMENT_ENTRY_AMOUNT_KEY_MAP[
        pluralize(
          entryType,
          1,
        ) as keyof typeof CAPITAL_INVESTMENT_ENTRY_AMOUNT_KEY_MAP
      ];

    switch (true) {
      case workflowFlags.steps.transactions.isCurrent: {
        return () => {
          onForward();
        };
      }

      case workflowFlags.steps.invEntities.isCurrent: {
        return async () => {
          let newEntryTxs: typeof entryTxWithReviewInfoItems = [];

          if (workflowFlags.kinds.isSimpleLinking) {
            const result = await simpleLinkingReviewEntryTx({
              selectedTxs,
              workflowFlags,
              selectedCapitalInvestments,
              singleDistributionKind,
              totalEntryAmountKeyName,
              openModal,
            });

            if (!result) return;

            newEntryTxs = result;
          }

          if (workflowFlags.kinds.isSplitting) {
            const SplitComponent =
              entryType === 'contributions'
                ? SplitContribution
                : SplitDistribution;

            const entryTxs = await openModal(
              SplitComponent,
              {
                transaction: selectedTxs[0],
                capitalInvestments: selectedCapitalInvestments,
              },
              {
                topLevelRender: false,
              },
            );

            if (!entryTxs) return;
            newEntryTxs = entryTxs;
          }

          if (workflowFlags.kinds.isBulkLinking) {
            newEntryTxs = bulkLinkingReviewEntryTxs({
              selectedTxs,
              workflowFlags,
              selectedCapitalInvestments,
              singleDistributionKind,
              totalEntryAmountKeyName,
            });
          }

          setEntryTxWithReviewInfoItems(newEntryTxs);
          onForward();
          setFullWidth(true);
        };
      }

      case workflowFlags.steps.review.isCurrent: {
        // TODO: move somewhere else
        const prepareRawDataToRequest = (rawData) =>
          rawData.map((transaction) => ({
            amount: transaction.amount,
            investment_id: transaction.investmentId,
            accounting_transaction_id: transaction.accountingTransactionId,
            kind: transaction.kind,
          }));

        return async () => {
          setCreatingEntries(true);

          if (entryType === 'contributions') {
            createBulkContributions({
              body: prepareRawDataToRequest(entryTxWithReviewInfoItems),
            });
          } else {
            createBulkDistributions({
              body: prepareRawDataToRequest(entryTxWithReviewInfoItems),
            });
          }
          setCreatingEntries(false);

          const unfilledTxsLen = txsLen - selectedTxs.length;
          const areUnfilledTxLeft = unfilledTxsLen > 0;
          const itemsText = pluralize(entryType, unfilledTxsLen);

          const answer = await confirm(
            createConfirmSuccessModalProps({
              entryType,
              areUnfilledTxLeft,
              itemsText,
              unfilledTxsLen,
            }),
          );

          invalidateCapitalInvestments();

          if (areUnfilledTxLeft && answer) return resetWorkflow();
          return navigateBack();
        };
      }
      default: {
        throw new Error('Should not happen. Step index should in range of 0-2');
      }
    }
  };

  const distributionKindOptions: DistributionKindOption[] =
    DISTRIBUTION_KINDS.map((value) => ({
      value,
      label: startCase(value),
    }));

  const distributionKindOptionsWithSplitOption: DistributionKindOption[] = [
    ...distributionKindOptions,
    SPLIT_TRANSACTION_OPTION,
  ];

  const confirmClosingWorkflow = async () => {
    if (
      workflowFlags.transactions.isSelected ||
      workflowFlags.capitalInvestments.isSelected
    ) {
      const res = await confirm(CONFIRM_CLOSING_WORKFLOW_PROPS);
      if (!res) return;
    }
    navigateBack();
  };

  const rightPanelStepsComponentMap: Record<StepIdx, JSX.Element> = {
    0: (
      <TransactionStep
        workflowFlags={workflowFlags}
        kindSelector={
          <DistributionKindSelector
            kind={singleDistributionKind}
            setKind={setSingleDistributionKind}
            options={distributionKindOptions}
          />
        }
      >
        <SelectedTransactions
          onRemoveItem={unSelectTransaction}
          totalCountLabel="Transaction"
          transactions={selectedTxs}
        />
      </TransactionStep>
    ),

    1: (
      <InvestmentEntityStep
        selectedTxs={selectedTxs}
        singleDistributionKind={singleDistributionKind}
        workflowFlags={workflowFlags}
        kindSelector={
          <DistributionKindSelector
            kind={singleDistributionKind}
            setKind={setSingleDistributionKind}
            options={distributionKindOptionsWithSplitOption}
          />
        }
      >
        <SelectedInvEntities
          onRemoveItem={unSelectInvEntity}
          totalCountLabel="Investment Entity"
          invEntities={selectedCapitalInvestments}
        />
      </InvestmentEntityStep>
    ),

    2: (
      <ReviewStep
        entryType={pluralize(entryType, 1) as 'contribution' | 'distribution'}
        entryTxWithReviewInfoItems={entryTxWithReviewInfoItems}
        capitalInvestments={selectedCapitalInvestments}
        transactions={selectedTxs}
        bulkLinkingReview={workflowFlags.kinds.isBulkLinking}
      />
    ),
  } as const;

  const currentRightPanelComponent = rightPanelStepsComponentMap[stepIdx];

  return (
    <div className="relative flex min-h-screen">
      {modalList}
      {workflowFlags.steps.transactions.isCurrent && (
        <SelectTransactionStep
          entryType={entryType}
          setTxsLen={setTxsLen}
          selectedTransactions={selectedTxs}
          setSelectedTransactions={setSelectedTxs}
          possiblePeriods={possiblePeriods}
          periods={periods}
          setPeriods={setPeriods}
        />
      )}

      {(workflowFlags.steps.invEntities.isCurrent ||
        workflowFlags.steps.review.isCurrent) && (
        <SelectInvestmentEntityStep
          entryType={entryType}
          singleSelection={workflowFlags.transactions.isMultiple}
          selectedRows={selectedCapitalInvestments}
          setSelectedRows={setSelectedInvEntities}
        />
      )}

      <div
        className={cn(
          'fixed bottom-[0] right-0 top-0 z-10 flex h-max min-h-screen flex-col bg-white transition-all',
          {
            'w-[480px]': !fullWidth,
            'w-full': fullWidth,
          },
        )}
      >
        <HeaderForStepperModal
          currentIdx={stepIdx}
          steps={CREATE_ENTRY_WORKFLOW_STEPS}
          onClose={confirmClosingWorkflow}
          heading={workflowMap.entryCreationText}
          disableCloseBtn={creatingEntries}
        />

        {currentRightPanelComponent}

        <div
          className={cn('gap-2.5 mt-auto flex flex-col bg-white p-6', {
            'pt-4': fullWidth,
            'pt-2': !fullWidth,
          })}
        >
          {workflowMap.goForwardHintTextJSX(
            workflowFlags.showExtendedGoForwardHintText,
          )}

          <div
            className={cn('grid max-w-[440px] items-center gap-3', {
              'grid-cols-2': !workflowFlags.steps.transactions.isCurrent,
              'w-[466px] self-center': fullWidth,
            })}
          >
            {!workflowFlags.steps.transactions.isCurrent && (
              <Button
                onClick={resolveGoBackward()}
                disabled={creatingEntries}
                variant="secondary"
              >
                {workflowMap.goBackwardButtonTextJSX}
              </Button>
            )}
            <Button
              onClick={resolveGoForward()}
              disabled={resolveGoForwardDisabled()}
              variant="success"
            >
              {creatingEntries ? (
                <Icon iconName="sync" className="animate-spin" />
              ) : (
                workflowMap.goForwardButtonText
              )}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
