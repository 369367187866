/* eslint-disable func-names */
/* eslint-disable no-underscore-dangle */
import * as d3 from 'd3';
import { DatumNode, getComponentSelector } from '@/stories/TreeView/TreeView';
import styles from '@/stories/TreeView/TreeView.module.scss';

function selectedSelfAndOneOfChildren(
  d: d3.HierarchyNode<DatumNode>,
  atLeastOneActive = false,
) {
  const isOneActive = d._children?.filter((n) => n.selected).length > 0;
  if (atLeastOneActive) return isOneActive;

  return d.selected && isOneActive;
}

function childrenCountTextAttrs(
  selection: d3.Selection<
    SVGTextElement,
    d3.HierarchyNode<DatumNode>,
    SVGGElement,
    unknown
  >,
) {
  selection
    .attr('class', styles.badgeText)
    .text((d) => {
      const selectedCount = d._children?.filter((n) => n.selected).length;
      const selectedLabel =
        selectedCount && selectedCount > 0 ? `${selectedCount} of ` : '';

      return `${selectedLabel}${d._children?.length}`;
    })
    .attr('text-anchor', 'start')
    .attr('x', 0)
    .attr('y', 5)
    .attr('fill', (d) =>
      selectedSelfAndOneOfChildren(d, true)
        ? 'var(--white)'
        : 'var(--light-60)',
    );
}

function chidlrenCountBgAttrs(
  selection: d3.Selection<
    SVGRectElement,
    d3.HierarchyNode<DatumNode>,
    SVGGElement,
    unknown
  >,
) {
  const [PADDING_Y, PADDING_X] = [2, 4];

  selection
    .attr('fill', (d) =>
      selectedSelfAndOneOfChildren(d, true) ? 'var(--bl)' : 'var(--light-10)',
    )
    .attr('width', (d) => d.childrenTextBox.width + PADDING_X * 2)
    .attr('height', (d) => d.childrenTextBox.height + PADDING_Y * 2)
    .attr('rx', 5)
    .attr('x', -PADDING_X)
    .attr('y', (d) => -d.childrenTextBox.height / 2 - PADDING_Y);
}

export const CHILDREN_BADGE_GROUP_COMPONENT = 'ChildrenBadgeGroup';

export function Badge(
  selection: d3.Selection<
    SVGRectElement,
    d3.HierarchyNode<DatumNode>,
    SVGGElement,
    unknown
  >,
  lifecycle: 'create' | 'update',
  {
    leftMargin,
  }: {
    leftMargin: number;
  },
) {
  const appendComponents = (internalSelection) => {
    const childrenBadgeGroup = internalSelection
      .append('g')
      .attr('data-component', CHILDREN_BADGE_GROUP_COMPONENT);

    childrenBadgeGroup.append('text');

    childrenBadgeGroup.append('rect').lower();
  };

  if (lifecycle === 'create') {
    appendComponents(selection);
  }

  if (lifecycle === 'update') {
    selection
      .filter(function (d) {
        return (
          d._children?.length > 0 &&
          d3
            .select(this)
            .select(getComponentSelector(CHILDREN_BADGE_GROUP_COMPONENT))
            .empty()
        );
      })
      .call(appendComponents);

    selection
      .filter((d) => d._children == null)
      .select(getComponentSelector(CHILDREN_BADGE_GROUP_COMPONENT))
      .remove();
  }

  const childrenBadgeGroup = selection
    .select(getComponentSelector(CHILDREN_BADGE_GROUP_COMPONENT))
    .attr(
      'transform',
      (d) =>
        `translate(${
          d.textBox.width +
          (d?.nodeLinksBox?.width || 0) +
          (d.nodeInfoBox?.width ?? 0) +
          leftMargin * 2 +
          d.circleWidth
        }, 0)`,
    )
    .attr('display', (d) =>
      d._children != null && d.children != null ? 'none' : 'block',
    );

  childrenBadgeGroup
    .select('text')
    .call(childrenCountTextAttrs)
    .each(function (d) {
      d.childrenTextBox = this.getBBox();
    });

  childrenBadgeGroup.select('rect').call(chidlrenCountBgAttrs);
}
