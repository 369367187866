import React from 'react';
import { FieldPath, FieldValues } from 'react-hook-form/dist/types';
import { Control, useController } from 'react-hook-form';
import { SectionField } from 'stories/Field/FieldsWrappers';
import { SharedSelect } from 'bundles/Shared/components/GroupForm/FormItems/new/SharedSelect';
import { TableVizConfigTextDisplayStrategy } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/table/model';
import * as yup from 'yup';
export const TEXT_DISPLAY_STRATEGIES: Record<
  Uppercase<TableVizConfigTextDisplayStrategy>,
  TableVizConfigTextDisplayStrategy
> = {
  TEXT_DEFAULT: 'text_default',
  TEXT_CURRENT_VALUE: 'text_current_value',
  TEXT_FULL_HISTORY: 'text_full_history',
} as const;

export const TEXT_DISPLAY_STRATEGY_OPTIONS = [
  {
    label: 'Display all values with truncation and tooltip',
    value: TEXT_DISPLAY_STRATEGIES.TEXT_DEFAULT,
  },
  {
    label: 'Display latest value and rest on hover',
    value: TEXT_DISPLAY_STRATEGIES.TEXT_CURRENT_VALUE,
  },
];

export const TEXT_DISPLAY_STRATEGY_DEFAULT_FIELD_SCHEMA = yup.object().shape({
  text_display_strategy: yup
    .string()
    .oneOf(Object.values(TEXT_DISPLAY_STRATEGIES)),
});

export function TextDisplayStrategyField<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  name = 'text_display_strategy' as TName,
}: {
  control: Control<TFieldValues>;
  name?: TName;
}) {
  const { field } = useController({ name, control });
  return (
    <SectionField labelText="Text Display strategy">
      <SharedSelect
        isMulti={false}
        menuPortalTarget={document.body}
        value={TEXT_DISPLAY_STRATEGY_OPTIONS.find(
          (option) => option.value === field.value,
        )}
        options={TEXT_DISPLAY_STRATEGY_OPTIONS}
        onChange={(o) => field.onChange(o?.value)}
      />
    </SectionField>
  );
}
