import { useGetApiCapitalInvestmentObjectSummariesQuery } from '@/entities/return/api/capitalInvestmentObjectsEnhancedApi';
import { GetApiCapitalInvestmentObjectSummariesApiArg } from '@/entities/return/api/capitalInvestmentObjectsGeneratedApi';
import { cn } from '@/shared/lib/css/cn';
import { typedLocalStorage } from '@/shared/lib/localStorage';
import { useNavigate } from '@reach/router';
import { exportCapitalInvestmentEntities } from 'bundles/REturn/actions/capitalInvestments';
import InvestmentObjectDetailsModal from 'bundles/REturn/components/Home/investmentObjectDetailsModal/InvestmentObjectDetailsModal';
import { useReturnColumns } from 'bundles/REturn/components/Home/useColumns';
import {
  fetchImpersonationUserRoles,
  fetchImpersonationUsers,
} from '@/entities/impersonation/api';
import ContainerWithAppBar from 'bundles/Shared/components/Containers/ContainerWithAppBar';
import AppBar from 'bundles/Shared/components/Containers/appBar/AppBar';
import ShownAsButton from 'bundles/Shared/components/ShownAsButton';
import Table from 'bundles/Shared/components/Table/Table';
import {
  PageParamsPagination,
  PageParamsProvider,
  PageParamsSearch,
} from 'bundles/Shared/components/pageParams';
import {
  ROUTES_ROOT,
  TRouteQueryParams,
  generateUrl,
  useQueryParams,
} from '@/shared/lib/hooks/useNavigation';
import { usePageParamsTableSorting } from '@/shared/lib/hooks/usePageParamsTableSorting';
import onUserImpersonation from '@/shared/lib/mirrorMode';
import { useEffect, useState } from 'react';
import { AnimationLoader } from 'stories/AnimationLoader/AnimationLoader';
import { Button } from 'stories/Button/Button';
import { IUser, IUserRole } from 'types/User';
import styles from '@/bundles/REturn/components/Home/ReturnHomePage.module.scss';

const SIZE_PER_PAGE = 50;
const VISIBLE_ROLE_NAME = 'Equity Partner - LP';

const RETURN_ROUTE = ROUTES_ROOT.return.fullPath;

const ReturnHomePage = () => {
  const { detailsId, tab } =
    useQueryParams<TRouteQueryParams[typeof RETURN_ROUTE]>();
  const navigate = useNavigate();

  const [pageParams, setPageParams] =
    useState<GetApiCapitalInvestmentObjectSummariesApiArg>({
      page: 1,
      perPage: SIZE_PER_PAGE,
      published: false,
      objectType: undefined,
      sort: {
        order: 'asc',
        field: 'objectName',
      },
    });
  const { settings, setSettings } = usePageParamsTableSorting(
    pageParams,
    setPageParams,
  );
  const columns = useReturnColumns({ pageParams, setPageParams });
  const [users, setUsers] = useState<IUser[]>();
  const [userRoles, setUserRoles] = useState<IUserRole[]>([]);
  const equityPartnerLPRole = userRoles.find(
    ({ name }) => name === VISIBLE_ROLE_NAME,
  );

  useEffect(() => {
    fetchImpersonationUserRoles().then((data) => {
      setUserRoles(data.items);
      fetchImpersonationUsers({
        with_active_capital_investments: true,
        user_role_id: data.items.find(({ name }) => name === VISIBLE_ROLE_NAME)
          ?.id,
      }).then((a) => setUsers(a.items));
    });
  }, []);

  const { data: response, isFetching } =
    useGetApiCapitalInvestmentObjectSummariesQuery(pageParams);

  const investmentObjects = response?.investmentObjects;
  const totalSize = response?.meta?.totalSize;

  if (!investmentObjects) return <AnimationLoader />;

  return (
    <ContainerWithAppBar appBar={<AppBar title="REturn" />}>
      <PageParamsProvider pageParams={pageParams} setPageParams={setPageParams}>
        <div className="flex items-center justify-between">
          <div>
            <PageParamsPagination loading={isFetching} totalSize={totalSize} />
          </div>
          <div className="flex items-center gap-2">
            {investmentObjects.length > 0 && (
              <Button
                onClick={() => exportCapitalInvestmentEntities()}
                variant="secondary"
                iconName="docExcel"
                size="s"
                iconClasses="green-dark-1"
                tooltipProps={{
                  mainText: 'Export as xlsx',
                }}
              >
                Export
              </Button>
            )}
            {users && users.length > 0 && (
              <ShownAsButton
                users={users}
                setShownAsUser={async (user) => {
                  await onUserImpersonation(user.id);
                  equityPartnerLPRole
                    ? typedLocalStorage.setItem(
                        'currentMirrorModeRole',
                        equityPartnerLPRole,
                      )
                    : typedLocalStorage.removeItem('currentMirrorModeRole');
                  window.location.href = '/';
                }}
              />
            )}
            <PageParamsSearch />
          </div>
        </div>
        <div className="mt-m">
          <Table
            classes={{
              // workaround for height 100% div in td
              table: cn('h-1p', styles.table),
            }}
            borderLessColumns
            items={investmentObjects}
            loading={isFetching}
            columns={columns}
            settings={settings}
            setSettings={setSettings}
          />
        </div>
        {detailsId !== undefined && (
          <InvestmentObjectDetailsModal
            id={detailsId}
            tab={tab}
            investmentObjectIds={investmentObjects.map(({ id }) => id)}
            onClose={() =>
              navigate(
                generateUrl(RETURN_ROUTE, {
                  pathParams: {},
                  queryParams: {
                    detailsId: undefined,
                  },
                }),
              )
            }
          />
        )}
      </PageParamsProvider>
    </ContainerWithAppBar>
  );
};

export default ReturnHomePage;
