import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import dayjs from 'dayjs';

import { THEME_COLORS } from '@symmetre-web/tailwind-config/config';
import { getCssVariableByAccessor } from 'lib/helpers';
import { CssVar } from '@/shared/config/cssVar';
import {
  DEFAULT_AMCHART_DATE_FORMATS,
  getAmchartNumberFormatByDisplayOptions,
} from '@/shared/lib/formatting/charts';

export function createXYChart(
  root: am5.Root,
  setting: am5xy.IXYChartSettings = {},
) {
  return root.container.children.push(
    am5xy.XYChart.new(root, {
      layout: root.verticalLayout,
      panY: false,
      width: am5.percent(100),
      height: am5.percent(100),
      ...setting,
    }),
  );
}

export function configChartColors(chart: am5xy.XYChart, colorList: string[]) {
  const colorsToSet = colorList.map((color) => am5.color(color));
  chart.get('colors')?.set('colors', colorsToSet);
}

export function convertToChatDate(date: string | number, format?: string) {
  return dayjs(date, format).toDate().getTime();
}

// eslint-disable-next-line max-params
export function addDateXAxis(
  root: am5.Root,
  chart: am5xy.XYChart,
  settings: Omit<am5xy.IDateAxisSettings<am5xy.AxisRenderer>, 'renderer'>,
  rendererSettings?: am5xy.IAxisRendererXSettings,
) {
  const renderer = am5xy.AxisRendererX.new(root, {
    minGridDistance: 50,
    ...rendererSettings,
  });
  const xAxis = chart.xAxes.push(
    am5xy.DateAxis.new(root, {
      ...settings,
      dateFormats: DEFAULT_AMCHART_DATE_FORMATS,
      renderer,
    }),
  );
  return {
    renderer,
    xAxis,
  };
}

type AddValueYAxisFunc = {
  (
    root: am5.Root,
    chart: am5xy.XYChart,
    settings?: Omit<am5xy.IValueAxisSettings<am5xy.AxisRenderer>, 'renderer'>,
    rendererSettings?: am5xy.IAxisRendererYSettings,
  ): {
    renderer: am5xy.AxisRendererY;
    yAxis: am5xy.ValueAxis<am5xy.AxisRenderer>;
  };
};

export const addValueYAxis: AddValueYAxisFunc = (
  root,
  chart,
  settings,
  rendererSettings,
  // eslint-disable-next-line max-params
) => {
  const renderer = am5xy.AxisRendererY.new(root, {
    ...rendererSettings,
  });
  const yAxis = chart.yAxes.push(
    am5xy.ValueAxis.new(root, {
      ...settings,
      renderer,
    }),
  );

  return {
    renderer,
    yAxis,
  };
};

export const addCurrencyValueYAxis: AddValueYAxisFunc = (
  root,
  chart,
  settings,
  rendererSettings,
  // eslint-disable-next-line max-params
) =>
  addValueYAxis(
    root,
    chart,
    {
      numberFormat: getAmchartNumberFormatByDisplayOptions({
        type: 'currency',
        precision: 2,
      }),
      ...settings,
    },
    rendererSettings,
  );

export function addCursor(
  root: am5.Root,
  chart: am5xy.XYChart,
  settings: am5xy.IXYCursorSettings,
) {
  return chart.set(
    'cursor',
    am5xy.XYCursor.new(root, {
      behavior: 'zoomXY',
      ...settings,
    }),
  );
}

// eslint-disable-next-line max-params
export function addLabelToYAxis(
  root: am5.Root,
  yAxis: am5xy.ValueAxis<am5xy.AxisRenderer>,
  settings: am5.ILabelSettings = {},
  position: 'before' | 'after' = 'after',
) {
  const label = am5.Label.new(root, {
    y: am5.p50,
    centerX: am5.p50,
    rotation: -90,
    [position === 'before' ? 'paddingBottom' : 'paddingTop']: 16,
    ...settings,
  });
  if (position === 'after') {
    yAxis.children.push(label);
  } else {
    yAxis.children.unshift(label);
  }
}

export const chartDateMapper =
  <T, Field extends keyof T>(field: Field, format?: string) =>
  (item: T): T & { [P in Field]: number } => ({
    ...item,
    [field]: convertToChatDate(item[field] as string | number, format),
  });

export const conditionallyShowRange = (showRange: boolean) =>
  showRange
    ? '{name}: [bold]{valueY}[/]\n{open.valueX.formatDate("MMM dd")} - {close.valueX.formatDate("MMM dd")}'
    : '{name}: [bold]{valueY}[/]';

export const COLOR_SET = [
  am5.color('#128141'),
  am5.color('#009567'),
  am5.color('#00a88f'),
  am5.color('#00bab6'),
  am5.color('#00ccdc'),
  am5.color('#00dcff'),
  am5.color('#00b1de'),
  am5.color('#0087b9'),
  am5.color('#0d6091'),
  am5.color('#133b68'),
  am5.color('#0d1a3f'),
];
export const getReturnDashboardTheme = (root: am5.Root) => {
  const myTheme = am5.Theme.new(root);

  myTheme.rule('Label').setAll({
    fontSize: 12,
  });

  return myTheme;
};
export const getSeriesColor = (series: 'contributed' | 'distributed') => {
  if (series === 'contributed') {
    return COLOR_SET[0];
  }
  return COLOR_SET.at(-1);
};
export const addCenteredLegend = (
  root: am5.Root,
  chart: am5xy.XYChart,
  settings?: am5.ILegendSettings,
) => {
  const legend = chart.children.push(
    am5.Legend.new(root, {
      y: am5.p100,
      centerY: 20,
      x: am5.p50,
      centerX: am5.percent(40),
      ...settings,
    }),
  );
  legend.labels.template.setAll({
    fontSize: 12,
  });
  legend.data.setAll(chart.series.values);
  return legend;
};

export function hideGrid(theme: am5.Theme) {
  theme.rule('Grid').setAll({
    strokeWidth: 0,
    strokeOpacity: 0,
  });
}

export const getAmchartRef = (ref: string) => `#${ref}`;
export const getDefaultLabelStyle = (): am5.ILabelSettings => ({
  fontWeight: '600',
  fill: am5.color(getCssVariableByAccessor(CssVar.light60)),
});
export const getDefaultLabelStyleInJson = () => ({
  fontWeight: '600',
  fill: {
    type: 'Color',
    value: THEME_COLORS.light['60'],
  },
});
