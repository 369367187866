import { GrowDiv } from '@/shared/ui/GrowDiv';
import { Icon } from '@/stories/Icon/Icon';
import { IconButton } from '@/stories/IconButton/IconButton';

export const ExportSettingsField = ({
  label,
  onChange,
  onRemove,
  isLoading,
}: {
  label: string;
  onChange: VoidFunction;
  onRemove: VoidFunction;
  isLoading: boolean;
}) => {
  return (
    <div className="flex h-[32px] items-center gap-1 rounded-[8px] border border-neutral-100 pl-2 pr-1 hover:bg-neutral-100">
      <Icon iconName="link" />
      <p className="inline-semibold overflow-hidden text-ellipsis text-nowrap text-neutral-700">
        {label}
      </p>
      <GrowDiv />
      <IconButton isLoading={isLoading} onClick={onChange} iconName="edit" />
      <IconButton isLoading={isLoading} onClick={onRemove} iconName="trash" />
    </div>
  );
};
