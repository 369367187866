import React from 'react';
import styles from 'bundles/Construction/styles.module.scss';
import { Button } from 'stories/Button/Button';
import { CurrencyFormatter } from 'stories/ValueFormatters/CurrencyFormatter';
import { Icon } from 'stories/Icon/Icon';
import { Tooltip } from 'stories/Tooltip/Tooltip';
import { cn } from '@/shared/lib/css/cn';
import { ICompany } from 'bundles/Construction/types';
import {
  Invoice,
  Vendor,
  TStatusInvoice,
} from '@/shared/types/reconcile/Invoice';
import { IColumn } from 'bundles/Shared/components/Table/types';
import QuickFilterCheckList, {
  TQuickFilterCheckListProps,
} from 'bundles/Shared/components/Table/filters/QuickFilterCheckList';
import QuickFilterAmount from 'bundles/Shared/components/Table/filters/QuickFilterAmount';
import { getVendorFormatter } from 'bundles/Shared/components/Table/formatters/drawPackage';

export enum TColumnIdsPayableInvoices {
  NUMBER = 'number',
  VENDOR = 'vendor_ids',
  AMOUNT = 'amount',
  DATE = 'date',
  LINE_ITEMS = 'line_items',
  STATUS = 'status',
  DOCUMENT = 'document',
}

export type TFilterModelPayableInvoices = {
  [TColumnIdsPayableInvoices.STATUS]?: TStatusInvoice;
  [TColumnIdsPayableInvoices.VENDOR]?: string[];
  [TColumnIdsPayableInvoices.AMOUNT]?: {
    from: string;
    to: string;
  };
};

export const payableInvoicesColumns = (
  vendors: ICompany[],
  showDetails: () => void,
): IColumn<Invoice>[] => [
  {
    sortable: true,
    text: '#',
    dataField: TColumnIdsPayableInvoices.NUMBER,
    classes: styles.invoiceNumber,
    headerClasses: styles.invoiceNumberHeader,
    headerStyle: {
      minWidth: 50,
      maxWidth: 70,
    },
  },
  {
    sortable: true,
    text: 'Vendor',
    dataField: TColumnIdsPayableInvoices.VENDOR,
    formatter: ({ row: object }) => getVendorFormatter(object?.vendor),
    filterComponent: QuickFilterCheckList,
    filterComponentParams: {
      items: vendors,
      getLabel: (item) => item.name,
      getId: (item) => item.id,
    } as TQuickFilterCheckListProps<Vendor>,
    headerStyle: {
      minWidth: 120,
      maxWidth: 220,
    },
  },
  {
    sortable: true,
    text: 'Date',
    dataField: TColumnIdsPayableInvoices.DATE,
    formatter: ({ row: object }) => (
      <span className="light-90">{object.date}</span>
    ),
    headerStyle: {
      minWidth: 150,
    },
  },
  {
    sortable: true,
    text: 'Amount',
    dataField: TColumnIdsPayableInvoices.AMOUNT,
    formatter: ({ row: object }) => (
      <div className={styles.amount}>
        <CurrencyFormatter value={object.amount} />
      </div>
    ),
    filterComponent: QuickFilterAmount,
    classes: styles.invoiceAmount,
    headerClasses: styles.invoiceAmountHeader,
    headerStyle: {
      minWidth: 150,
    },
  },
  {
    text: 'Invoice',
    dataField: TColumnIdsPayableInvoices.DOCUMENT,
    formatter: ({ row: object }) =>
      object.document && (
        <Tooltip titleText={object.document.title}>
          <a
            target="blank"
            href={object.document.url}
            className={styles.invoiceDocument}
          >
            <Icon
              className={cn('red', styles.invoiceCompanyIcon)}
              iconName="docPdf"
            />
            <span className="secondary-regular text-ellipsis">
              {object.document.title}
            </span>
          </a>
        </Tooltip>
      ),
    classes: styles.invoiceDocumentColumn,
    headerStyle: {
      minWidth: 120,
      maxWidth: 180,
    },
  },
  {
    text: 'Actions',
    dataField: 'actions',
    formatter: ({ row: object }) => (
      <div className="flex justify-center">
        <Button
          variant="secondary"
          size="xs"
          onClick={() => showDetails(object.id)}
        >
          Show Details
        </Button>
      </div>
    ),
    headerStyle: {
      width: 130,
    },
  },
];
