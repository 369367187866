import React, { useCallback, useMemo, useState } from 'react';
import { Button } from 'stories/Button/Button';
import { Modal } from 'stories/Modals/Modal/Modal';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import AllocationStatistics from 'bundles/REconcile/components/AllocationStatistics';
import { IColumn } from 'bundles/Shared/components/Table/types';
import Table from 'bundles/Shared/components/Table/Table';
import {
  getEntityRevised,
  getRevisedColumn,
  isRevisedValid,
  getCurrentAmountColumn,
} from 'bundles/Shared/components/SpecifySourcesModal/utils';
import styles from 'bundles/REturn/components/Ownership/modals/capitalInvestmentsEditor/CapitaInvestmentEditor.module.scss';
import { SELECTION_COLUMN_CLASSES } from 'bundles/Assets/components/consts';
import CurrencyInputNumber from 'bundles/Shared/components/GroupForm/FormItems/CurrencyInputNumber';
import { useAllocation } from 'bundles/REconcile/components/AllocationProvider';

export type TSpecifyReallocationAmount = {
  reallocation: Float;
};

type TRequiredFieldInEntity = {
  id: number;
  amount: RawFloat;
};
export type TEntity<T> = T & TRequiredFieldInEntity;
export type TEntityWithReallocation<T> = T &
  TRequiredFieldInEntity &
  TSpecifyReallocationAmount;

type Props<T> = DialogProps<T[]> &
  Pick<React.ComponentProps<typeof Modal>, 'header'> & {
    revisedAmount: number;
    initialEntities: TEntity<T>[];
    entityConfig: {
      columns: {
        nameColumn: Pick<
          IColumn<TEntity<T>>,
          'formatter' | 'dataField' | 'text'
        >;
        currentAmountColumn: Pick<IColumn<TEntity<T>>, 'text' | 'dataField'>;
        amountColumn: Pick<IColumn<TEntity<T>>, 'text' | 'dataField'>;
      };
    };
    entityName: string;
    destination?: 'from' | 'to';
    editedEntities?: TEntityWithReallocation<T>[];
  };

function SpecifySourcesModal<T>({
  onSubmit,
  initialEntities,
  editedEntities = [],
  destination = 'from',
  entityName,
  revisedAmount,
  entityConfig,
  onClose,
}: Props<T>) {
  const reallocationAmountColumn = entityConfig.columns.amountColumn;
  const [selectedRows, setSelectedRows] = useState<
    TEntityWithReallocation<T>[]
  >(editedEntities ?? []);
  const [rows, setRows] = useState<TEntityWithReallocation<T>[]>(
    initialEntities.map((entity) => ({
      ...entity,
      reallocation: (editedEntities?.find((es) => es.id === entity.id)
        ?.reallocation ?? 0) as Float,
    })),
  );
  const editedSelectedRows: TEntityWithReallocation<T>[] = rows.filter((r) =>
    selectedRows.find((sr) => sr.id === r.id),
  );
  const updateItem = useCallback((index: number, value: Float) => {
    setRows((prev) =>
      prev.map((r, i) =>
        index === i
          ? {
              ...r,
              reallocation: value,
            }
          : r,
      ),
    );
  }, []);
  const {
    allItemsAreAllocated,
    allocatedAmount,
    total,
    allocationFlags,
    inputProps,
  } = useAllocation({
    total: revisedAmount,
    items: editedSelectedRows,
    sumMapper: (r) => r[reallocationAmountColumn.dataField] as Float,
    updateItem,
  });
  const entitiesInvalid = editedSelectedRows.some(
    (entity) =>
      !isRevisedValid(
        getEntityRevised(entity.amount, entity.reallocation, destination),
      ),
  );
  // eslint-disable-next-line max-len
  const submitDisabled =
    !allocationFlags.isFullyAllocated ||
    !allItemsAreAllocated ||
    entitiesInvalid;

  const columns = useMemo<IColumn<TEntity<T>>[]>(
    () => [
      entityConfig.columns.nameColumn,
      getCurrentAmountColumn<T>(
        entityConfig.columns.currentAmountColumn.text,
        entityConfig.columns.currentAmountColumn.dataField,
      ),
      {
        text: reallocationAmountColumn.text,
        dataField: reallocationAmountColumn.dataField,
        headerStyle: {
          width: 200,
        },
        formatter: ({ row, rowIndex, selectedRows: currentSelected }) => (
          <CurrencyInputNumber
            defaultValue={row[reallocationAmountColumn.dataField]}
            allowNegative={false}
            disabled={
              currentSelected?.find((r) => r.id === row.id) === undefined
            }
            onBlur={inputProps.handleBlur(rowIndex)}
            error={inputProps.error}
          />
        ),
      },
      getRevisedColumn<T>(
        destination,
        entityName,
        entityConfig.columns.currentAmountColumn.dataField,
      ),
    ],
    [inputProps, entityConfig],
  );

  const handleSubmit = () => {
    onSubmit?.(
      editedSelectedRows.filter(
        (r) => r[reallocationAmountColumn.dataField] > 0,
      ),
    );
  };

  return (
    <Modal
      size="900"
      toggle={onClose}
      header={`Set ${entityName} Values`}
      classes={{
        body: 'bg-light',
      }}
      actions={
        <>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            disabled={submitDisabled}
            onClick={handleSubmit}
          >
            Confirm
          </Button>
        </>
      }
    >
      <div className="flex flex-col gap-4">
        <AllocationStatistics
          allocatedAmount={allocatedAmount}
          totalAmount={total ?? 0}
        />
        <Table
          classes={{
            table: styles.table,
            container: styles.container,
          }}
          selectionColumn={SELECTION_COLUMN_CLASSES}
          borderLessOutside
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          columns={columns}
          items={rows}
        />
      </div>
    </Modal>
  );
}

export default SpecifySourcesModal;
