import React, { FC, RefObject, SetStateAction, useMemo, useState } from 'react';
import { Button } from 'stories/Button/Button';
import { CheckpointsSection } from 'stories/CheckpointsSection/CheckpointsSection';
import {
  IActionsHints,
  IVerifySteps,
  TStepVerifyCE,
} from '@/bundles/Construction/components/ChangeOrderEvent';
import {
  selectChangeEvent,
  verifyChangeEvent,
} from 'bundles/Construction/reducers/ReconcileChangeEventSlice';
import { useParams } from '@reach/router';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import { useModal } from '@/shared/lib/hooks/useModal';

interface Props {
  verifyStep: IVerifySteps;
  actionsHints: IActionsHints;
  setActionsHints: SetStateAction<IActionsHints>;
  scrollToFunc: (step: TStepVerifyCE) => void;
}

const VerificationForm: FC<Props> = ({
  verifyStep,
  actionsHints,
  setActionsHints,
  scrollToFunc,
}) => {
  const { confirm } = useModal();
  const changeEvent = useAppSelector(selectChangeEvent);

  const dispatch = useAppDispatch();
  const params = useParams();
  const [loading, setLoading] = useState(false);

  const weightedAmount = changeEvent?.weightedAmount;

  const submitVerification = async () => {
    // https://linear.app/symmetre/issue/FE-2714/add-a-confirmation-step-to-cor-with-external-approver-and-threshold
    if (
      changeEvent?.threshold &&
      changeEvent.threshold.externalApproverIds.length > 0
    ) {
      const res = await confirm({
        title: 'Confirmation',
        subtitle: 'Does your documentation include external approval?',
      });

      if (!res) return;
    }
    setLoading(true);
    await dispatch(
      verifyChangeEvent({
        legalEntityCode: params.legalEntityCode,
        id: params.id,
      }),
    );
    setLoading(false);
  };

  const checkVerifyFunc = (
    refBtn: RefObject<HTMLDivElement>,
    value: TStepVerifyCE,
  ) => {
    setActionsHints({
      ...actionsHints,
      [value]: [refBtn.current],
    });
    scrollToFunc(value);
    if (!actionsHints[value].length) {
      setTimeout(() => {
        refBtn.current.click();
      });
    }
  };
  const dataCheckSection = [
    {
      label: 'Change Event Details:',
      checkpoint: [
        {
          title: 'Check Timing Impact',
          value: 'checkTimingImpact',
          check: verifyStep.checkTimingImpact,
          onClick: checkVerifyFunc,
        },
      ],
    },
    {
      label: 'COST BREAKDOWN:',
      checkpoint: [
        {
          title: 'Complete fields',
          value: 'completeFields',
          check: verifyStep.completeFields,
          onClick: checkVerifyFunc,
        },
        {
          title: 'Confirm value',
          value: 'confirmValue',
          check: verifyStep.confirmValue,
          onClick: checkVerifyFunc,
        },
      ],
    },
    {
      label: 'Documents:',
      checkpoint: [
        {
          title: 'Add docs',
          value: 'addDocs',
          check: verifyStep.addDocs,
          onClick: checkVerifyFunc,
        },
        {
          title: 'Select Final',
          value: 'selectFinal',
          check: verifyStep.selectFinal,
          onClick: checkVerifyFunc,
        },
        changeEvent?.esignEnabled && {
          title: 'Define E-sign Placement',
          value: 'eSign',
          check: verifyStep.eSign,
          onClick: checkVerifyFunc,
        },
      ].filter(Boolean),
    },
  ];

  const COUNT_STEP_VERIFY = dataCheckSection.reduce(
    (acc, section) => acc + section.checkpoint.length,
    0,
  );
  let total = weightedAmount;
  if (changeEvent) {
    const workCosts = changeEvent?.workCosts;
    const markups = changeEvent?.markups;
    const fees = changeEvent?.fees;
    const workCostsSubtotal = () =>
      workCosts && workCosts.reduce((acc, wc) => acc + Number(wc.amount), 0);
    const markupsIncludedInFeeSubtotal = () =>
      markups
        ?.filter((m) => m?.costCode?.includedInFee)
        .reduce((acc, m) => acc + Number(m.amount), 0);
    const markupsNotIncludedInFeeSubtotal = () =>
      markups
        ?.filter((m) => !m?.changeOrderMarkup?.includedInFee)
        .reduce((acc, m) => acc + Number(m.amount), 0);
    const markupsSubtotal = () =>
      markupsIncludedInFeeSubtotal() + markupsNotIncludedInFeeSubtotal();
    const feesSubtotal = () =>
      fees?.reduce((acc, f) => acc + Number(f.amount), 0);
    total = markupsSubtotal() + workCostsSubtotal() + feesSubtotal();
  }

  const canVerify = useMemo(() => {
    const can = Object.values(verifyStep).filter((i) => i).length;
    return can === COUNT_STEP_VERIFY;
  }, [verifyStep]);

  return (
    <div>
      <div className="available-action__line px-m py-m">
        <CheckpointsSection
          title="Complete the following steps:"
          data={dataCheckSection}
        />
      </div>
      <div className="px-m py-m">
        {total != weightedAmount && verifyStep.confirmValue && (
          <div className="available-action__line px-m py-m">
            <div className="inline-semibold mb-xs text-red">WARNING:</div>
            <div className="secondary-regular">
              Verifying this COR will force an override of the original amount
              according to the cost breakdown workflow
            </div>
          </div>
        )}
        <Button
          variant="success"
          iconName={loading ? 'sync' : 'checkDouble'}
          onClick={submitVerification}
          disabled={!canVerify || loading}
          className="w-full"
          iconClasses={loading ? 'form-button-loading' : ''}
        >
          Verify
        </Button>
      </div>
    </div>
  );
};

export default VerificationForm;
