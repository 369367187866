import { NavigateFn, RouteComponentProps } from '@reach/router';
import {
  createAndGetReportTableConfigRowsPreview,
  destroyReportTableConfig,
  updateReportTableConfig,
} from 'bundles/Settings/actions/report/tableConfigs';
import LoadingTable from 'bundles/Shared/components/LoadingTable';
import NoDataOverlay from 'bundles/Shared/components/NoDataOverlay';
import { PermissionList } from 'bundles/Shared/components/Permissions/PermissionList';
import { PreviewReportTable } from 'bundles/Shared/components/ReportTable/PreviewReportTable/PreviewReportTable';
import { TRow } from 'bundles/Shared/components/ReportTable/types';
import {
  DynamicValuesButton,
  FormulasAndVariablesWorkspace,
} from 'bundles/Shared/widgets/formula/panel';
import { fetchUserRoles } from 'bundles/UserManagement/actions/UserRole';
import { useAppDispatch } from '@/shared/lib/hooks/redux';
import useDebounce from '@/shared/lib/hooks/useDebounce';
import { useModal } from '@/shared/lib/hooks/useModal';
import { usePermissions } from '@/app/lib';
import { REPORT_PRODUCT_NAME } from 'lib/permissions';
import { useEffect, useState } from 'react';
import { AnimationLoader } from 'stories/AnimationLoader/AnimationLoader';
import { Button } from 'stories/Button/Button';
import { Icon } from 'stories/Icon/Icon';
import { IconButton } from 'stories/IconButton/IconButton';
import { ThinTabGroup } from 'stories/Tabs/ThinTabGroup/ThinTabGroup';
import {
  IReportTableConfig,
  IUserFinancialCategoryRule,
  IUserRoleFinancialCategoryRule,
} from 'types/ReportTableConfig';
import { PeriodLimitations } from '@/bundles/Settings/components/REport/TableBuilder/PeriodLimitation/PeriodLimitation';
import styles from '@/bundles/Settings/components/REport/TableBuilder/ReportTableConfig.module.scss';
import ReportTableConfigModal from '@/bundles/Settings/components/REport/TableBuilder/ReportTableConfigModal';
import { updateCategoryRules } from '@/bundles/Settings/components/REport/TableBuilder/helpers/categoryRulesUtils';
import {
  COLUMNS_TAB,
  ROWS_TAB,
  TABLE_ITEMS,
} from '@/bundles/Settings/components/REport/TableBuilder/helpers/constants';
import { getUpdateReportTableConfigPayload } from '@/bundles/Settings/components/REport/TableBuilder/helpers/getUpdateReportTableConfigPayload';
import {
  isReportTableConfigValid,
  parseColumnConfigForPreview,
} from '@/bundles/Settings/components/REport/TableBuilder/helpers/utils';
import {
  ActionType,
  useReportTableConfigReducer,
} from '@/bundles/Settings/components/REport/TableBuilder/hooks/useReportTableConfigReducer';
import { ReportTableConfigEditor } from 'bundles/Settings/components/REport/TableBuilder/ReportTableConfigEditor';
import { prettyJsonStringify } from '@/lib/stringify';
interface Props extends RouteComponentProps<{ tableSlug: string }> {
  navigate: NavigateFn;
}

const divider = <div className={styles.divider} />;

const ReportTableConfig = ({ tableSlug = '', navigate }: Props) => {
  const {
    state: {
      tableConfig,
      periodsLimitations,
      permissions,
      rowsConfig,
      columnsConfig,
      userFinancialCategoryRules: userRules,
      userRoleFinancialCategoryRules: roleRules,
      hasChanges,
    },
    dispatch: pageDispatch,
    dispatchRefreshState,
    isLoading,
    setIsLoading,
  } = useReportTableConfigReducer(tableSlug);

  const dispatch = useAppDispatch();

  const [selectedTab, setSelectedTab] = useState(ROWS_TAB);
  const { canConfigureTableBuilder } = usePermissions();
  const shownRowsEditorConfig = tableConfig?.rowsConfigSourceTable
    ? prettyJsonStringify(tableConfig?.rowsConfigSourceTable.rowsConfig)
    : rowsConfig;
  const debouncedRowsEditorConfig = useDebounce(shownRowsEditorConfig, 2500);
  const [previewConfigRows, setPreviewConfigRows] = useState<TRow[] | null>(
    null,
  );
  const [isPreviewLoading, setIsPreviewLoading] = useState(false);

  const { openModal, confirm } = useModal();

  const goBack = () => navigate('..');

  const handleDelete = async () => {
    if (!tableConfig) return;

    const result = await confirm({
      title: 'Delete Table Configuration',
      subtitle: `Are you sure you want to delete ${tableConfig.name}?`,
    });

    if (!result) return;

    setIsLoading(true);

    await destroyReportTableConfig(tableConfig.id);

    goBack();
  };

  const handleSave = async () => {
    if (!tableConfig) return;

    if (!isReportTableConfigValid(columnsConfig)) {
      window.toastr.error('Invalid columns JSON array!');
      return;
    }
    if (!isReportTableConfigValid(rowsConfig)) {
      window.toastr.error('Invalid rows JSON array!');
      return;
    }
    setIsLoading(true);

    const payload = getUpdateReportTableConfigPayload({
      periodsLimitations,
      columnsConfig,
      rowsConfig,
      permitted: permissions,
      userRules,
      roleRules,
    });

    const updatedReportTableConfig = (await updateReportTableConfig(
      tableConfig.id,
      payload,
    )) as IReportTableConfig;

    dispatchRefreshState(updatedReportTableConfig);
    setIsLoading(false);
  };

  const loadRowsPreview = async () => {
    const debouncedParsedRowsEditorConfig = JSON.parse(
      debouncedRowsEditorConfig,
    ) as TRow[];

    if (
      !Array.isArray(debouncedParsedRowsEditorConfig) ||
      debouncedParsedRowsEditorConfig.length === 0
    )
      return;

    setIsPreviewLoading(true);

    const data = await createAndGetReportTableConfigRowsPreview(
      debouncedParsedRowsEditorConfig,
    );

    if (data.errors) {
      setPreviewConfigRows(null);
    } else {
      setPreviewConfigRows(data.rows);
    }
    setIsPreviewLoading(false);
  };

  useEffect(() => {
    loadRowsPreview();
  }, [debouncedRowsEditorConfig]);

  useEffect(() => {
    dispatch(fetchUserRoles());
  }, []);

  const handleUserRulesChange = (
    newRules: IUserFinancialCategoryRule[],
    categoryPath: string,
  ) => {
    pageDispatch({
      type: ActionType.UserFinancialCategoryRules,
      payload: updateCategoryRules(userRules, newRules, categoryPath),
    });
  };

  const handleRoleRulesChange = (
    newRules: IUserRoleFinancialCategoryRule[],
    categoryPath: string,
  ) => {
    pageDispatch({
      type: ActionType.UserRoleFinancialCategoryRules,
      payload: updateCategoryRules(roleRules, newRules, categoryPath),
    });
  };

  const handleHasChanges = (newHasChanges: boolean) => {
    pageDispatch({
      type: ActionType.HasChanges,
      payload: newHasChanges,
    });
  };

  const renderPreviewRowsTable = () => {
    if (isPreviewLoading) return <LoadingTable rows={10} cols={2} />;

    const noPreview =
      previewConfigRows === null ||
      (Array.isArray(previewConfigRows) && previewConfigRows.length === 0);

    if (noPreview) return <NoDataOverlay title="Nothing to show" />;

    return (
      <PreviewReportTable
        allRules={{ userRules, roleRules }}
        rows={previewConfigRows}
        handleRoleRulesChange={handleRoleRulesChange}
        handleUserRulesChange={handleUserRulesChange}
        handleHasChanges={handleHasChanges}
      />
    );
  };

  const openUpdateModal = async () => {
    if (!tableConfig) return;

    const res = await openModal(ReportTableConfigModal, {
      tableConfig,
    });

    if (!res) return;

    setIsLoading(true);
    const updatedReportTableConfig = (await updateReportTableConfig(
      tableConfig.id,
      res,
    )) as IReportTableConfig;

    dispatchRefreshState(updatedReportTableConfig);
    setIsLoading(false);
  };

  const handleConfig = (
    payload: string | undefined,
    type: ActionType.ColumnsConfig | ActionType.RowsConfig,
  ) => {
    if (payload === undefined || !isReportTableConfigValid(payload ?? ''))
      return;
    pageDispatch({ type, payload });
  };

  const handleRowsConfig = (str?: string) => {
    if (!hasChanges) handleHasChanges(true);
    handleConfig(str, ActionType.RowsConfig);
  };
  const handleColumnsConfig = (str?: string) => {
    if (!hasChanges) handleHasChanges(true);
    handleConfig(str, ActionType.ColumnsConfig);
  };

  return (
    <FormulasAndVariablesWorkspace>
      <div className="flex h-full flex-col">
        {isLoading && <AnimationLoader />}
        <div className="flex w-full items-center justify-between gap-4 bg-white px-6 py-4">
          <div className="flex items-center gap-4">
            <IconButton
              iconName="arrowLeft"
              onClick={goBack}
              variant="secondary"
            />
            <div>
              <div className="label-semibold uppercase">Table Builder</div>
              <div className="header5-bold text-neutral-800">
                {tableConfig?.name ?? ''}
              </div>
            </div>
            <div className="flex items-center gap-2">
              <IconButton
                iconName="edit"
                onClick={openUpdateModal}
                variant="secondary"
                size="l"
              />
              <PermissionList
                type="table"
                // @ts-ignore
                permitted={permissions}
                whiteListedTabs={['users', 'roles', 'tags']}
                hideActions={false}
                onSubmit={(newPerms) => {
                  pageDispatch({
                    type: ActionType.Permission,
                    // @ts-ignore
                    payload: newPerms,
                  });
                  handleHasChanges(true);
                }}
                productName={REPORT_PRODUCT_NAME}
                className="inline-regular flex"
              />
              {divider}
              <IconButton
                iconName="trash"
                onClick={handleDelete}
                variant="secondary"
                size="l"
              />
            </div>
          </div>
          <div>
            {hasChanges && (
              <Button
                size="s"
                className="ml-auto"
                variant="success"
                onClick={handleSave}
              >
                Save Updates
              </Button>
            )}
            {!hasChanges && (
              <div className="flex items-center gap-1">
                <Icon iconName="checkSmall" className="text-success-055" />
                <div className="label-regular">No new changes detected</div>
              </div>
            )}
          </div>
        </div>
        {!canConfigureTableBuilder && (
          <div className={styles.previewContainer}>
            <p className="dark-60 align-self-center header6-bold">Preview</p>
            <div className={styles.preview}>{renderPreviewRowsTable()}</div>
          </div>
        )}
        {canConfigureTableBuilder && tableConfig && (
          <div className={styles.container}>
            <div className={styles.editor}>
              {selectedTab === ROWS_TAB && (
                <ReportTableConfigEditor
                  defaultValue={
                    tableConfig?.rowsConfigSourceTable
                      ? `"'${tableConfig.rowsConfigSourceTable.name}' is set as a source table"`
                      : rowsConfig
                  }
                  onChange={
                    tableConfig?.rowsConfigSourceTable !== null
                      ? undefined
                      : handleRowsConfig
                  }
                />
              )}
              {selectedTab === COLUMNS_TAB && (
                <ReportTableConfigEditor
                  value={columnsConfig}
                  onChange={handleColumnsConfig}
                />
              )}
            </div>
            <div className="flex flex-col gap-4">
              <div className="flex gap-2">
                <PeriodLimitations
                  periodLimitations={periodsLimitations}
                  onChange={(newPeriod) => {
                    handleHasChanges(true);
                    pageDispatch({
                      type: ActionType.PeriodLimitaions,
                      payload: newPeriod,
                    });
                  }}
                />
                <DynamicValuesButton size="s" />
                <div className="grow" />
                <ThinTabGroup
                  className="w-max"
                  items={TABLE_ITEMS}
                  selectedItem={selectedTab}
                  onSelectedItemChange={(selected) => setSelectedTab(selected)}
                />
              </div>
              <div className={styles.preview}>
                {selectedTab === ROWS_TAB && renderPreviewRowsTable()}
                {selectedTab === COLUMNS_TAB && (
                  <ul className={styles.columsList}>
                    {parseColumnConfigForPreview(columnsConfig).map((col) => (
                      <li className="inline-semibold" key={col}>
                        {col}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </FormulasAndVariablesWorkspace>
  );
};

export default ReportTableConfig;
