import React from 'react';
import { LinkButton } from 'stories/LinkButton/LinkButton';
import pluralize from 'pluralize';

interface Props {
  count: number;
  onClear: () => void;
}

function PreviousSelectionLabel({ count, onClear }: Props) {
  return (
    <div className="flex justify-start">
      <span className="inline-regular mb-m rounded-[0.5rem] border border-light-10 bg-white px-[0.75rem] py-xs">
        <span className="inline-semibold">
          {count} {pluralize('Legal Entity', count)}
        </span>{' '}
        selected by previous session
        <LinkButton className="ml-s" onClick={onClear}>
          Clear Selection
        </LinkButton>
      </span>
    </div>
  );
}

export default PreviousSelectionLabel;
