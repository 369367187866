import { getClosestUnitPrice } from '@/bundles/REturn/components/Ownership/modals/capitalInvestmentsEditor/utils';
import { useCapitalUnitPricesByInvestmentClass } from '@/bundles/REturn/hooks/useLoadedUnitPrices';
import { InvestmentIndex } from '@/entities/return/api/capitalInvestmentObjectsGeneratedApi';
import { STATIC_DATE_PICKER_PROPS } from 'bundles/REturn/components/Ownership/modals/consts';
import { UnitsInputNumber } from 'bundles/REturn/components/Ownership/modals/disAndContriubutionsModals/TotalWithUnits';
import CurrencyInputNumber from 'bundles/Shared/components/GroupForm/FormItems/CurrencyInputNumber';
import {
  SettingsModal,
  SettingsModalField,
} from 'bundles/Shared/components/SettingsModal/SettingsModal';
import dayjs from 'dayjs';
import { useModal } from '@/shared/lib/hooks/useModal';
import {
  parseIntOrFloat,
  parseNumberFromCurrencyInput,
} from '@/shared/lib/formatting/number';
import { exists } from 'lib/typeHelpers';
import { ComponentProps } from 'react';
import ReactDatePicker from 'react-datepicker';
import { createPeriodFromDate } from 'stories/FlexibleFilterByPeriods/utils';
import { Input } from 'stories/FormControls/Inputs/Input/Input';
import { convertCentsToDollars } from '@/shared/lib/converters';

type CommitmentForm = {
  amount: number | undefined;
  date: string | undefined;
};

type ReturnForm = {
  amount: number | undefined;
  date: string | undefined;
};

type Params = {
  capitalInvestment: InvestmentIndex;
  initialForm?: CommitmentForm;
} & Partial<
  Pick<
    ComponentProps<typeof SettingsModal>,
    'modalHeaderProps' | 'submitButtonText'
  >
>;

export function useCommitmentSettingsModalWithUnitPrices() {
  const { openModal } = useModal();
  const { capitalUnitPricesPartitionByInvestmentClass } =
    useCapitalUnitPricesByInvestmentClass({
      investmentClass: 'b',
    });
  const [unitPrices] = capitalUnitPricesPartitionByInvestmentClass;

  return async ({
    initialForm = {
      amount: 0,
      date: undefined,
    },
    modalHeaderProps,
    submitButtonText,
    capitalInvestment,
  }: Params) => {
    const hasUnitPrices = unitPrices.length > 0;

    const fields: SettingsModalField<CommitmentForm>[] = [
      {
        fieldProps: { className: 'w-full', required: false },
        name: 'Signed date',
        renderer({ form, setForm }) {
          return (
            <ReactDatePicker
              {...STATIC_DATE_PICKER_PROPS}
              selected={form.date ? dayjs(form.date).toDate() : undefined}
              isClearable
              clearButtonClassName="mr-4 z-index-100"
              onChange={(newDate) => {
                setForm((prev) => ({
                  ...prev,
                  date: newDate ? createPeriodFromDate(newDate) : undefined,
                }));
              }}
              customInput={
                <Input leftIcon="calendar" rightIcon="arrowBottom" size="m" />
              }
            />
          );
        },
      },
      {
        fieldProps: {
          className: hasUnitPrices ? 'w-[48%]' : 'w-full',
          required: false,
        },
        name: 'value',
        renderer({ form, setForm }) {
          return (
            <CurrencyInputNumber
              selectOnFocus
              size="m"
              value={form.amount}
              allowNegative={false}
              onValueChange={(value) => {
                setForm((prev) => ({
                  ...prev,
                  amount: value === '' ? undefined : value,
                }));
              }}
            />
          );
        },
      },
    ];

    if (hasUnitPrices) {
      fields.push({
        name: 'Class B Units',
        fieldProps: {
          className: 'w-[48%] ml-auto',
          required: false,
        },
        renderer({ form, setForm }) {
          const unitsDisabledToChange = !exists(form.date);

          const closestUnitPrice =
            form.date && hasUnitPrices
              ? convertCentsToDollars(
                  getClosestUnitPrice({
                    date: form.date,
                    unitPrices,
                  }).priceCents,
                )
              : 0;

          return (
            <UnitsInputNumber
              selectOnFocus
              classes={{
                outer: 'w-full',
              }}
              placeholder="0"
              disabled={unitsDisabledToChange}
              value={parseIntOrFloat(
                (Number(form.amount) ?? '') / closestUnitPrice,
              )}
              onChange={(e) => {
                const amount = e.target.value
                  ? (parseNumberFromCurrencyInput(e.target.value) ?? 0) *
                    closestUnitPrice
                  : undefined;
                setForm((prev) => ({
                  ...prev,
                  amount,
                }));
              }}
            />
          );
        },
      });
    }

    const res = await openModal(SettingsModal<CommitmentForm, ReturnForm>, {
      initialForm,
      modalHeaderProps: modalHeaderProps ?? {
        title: 'Edit Commitment',
        subtitle: capitalInvestment.investmentEntity.name,
      },
      submitButtonText: submitButtonText ?? 'Edit Commitment',
      isDisabled: (f) => f.date == null && !f.amount,
      classes: {
        fieldsContainer: 'flex-wrap flex-row',
      },
      fields,
    });

    return res;
  };
}
