import { cn } from '@/shared/lib/css/cn';
import { TagWithIcon } from '@/stories/Tags/TagWithIcon/TagWithIcon';
import { Icon } from '@/stories/Icon/Icon';
import { IconsId } from 'types/sre-icons';
import styles from '@/stories/SmallDocumentCard/SmallDocumentCard.module.scss';

interface Props
  extends Omit<
    React.ComponentProps<typeof TagWithIcon>,
    'variant' | 'startIcon'
  > {
  iconName: IconsId;
  iconColorClassName: string;
}

export const SmallDocumentCard = ({
  className,
  disabled,
  iconName,
  iconColorClassName,
  ...props
}: Props) => (
  <TagWithIcon
    startIcon={
      <Icon
        iconName={iconName}
        className={disabled ? 'light-60' : iconColorClassName}
      />
    }
    className={cn(styles['sre-small-document-card'], className)}
    offHoverStyles
    disabled={disabled}
    {...props}
  />
);

export default SmallDocumentCard;
