import React, { FC, ReactNode } from 'react';
import { IFormItem } from '@/bundles/Shared/components/GroupForm/types/types';
import { cn } from '@/shared/lib/css/cn';

interface Props extends IFormItem {
  content: ReactNode;
}

const FormCustom: FC<Props> = ({ styles, content }) => (
  <div className={cn('form-item', styles?.wrapper ?? 'w-full')}>
    <div>{content}</div>
  </div>
);
export default FormCustom;
