import { Control, useWatch } from 'react-hook-form';
import { FieldValues } from 'react-hook-form/dist/types';
import { FieldPath } from 'react-hook-form/dist/types/path/eager';
import {
  SectionField,
  SectionFieldsContainer,
} from 'stories/Field/FieldsWrappers';
import { RadioGroupController } from 'stories/RadioButton/RadioGroup';
import { isForecastAdjustment } from '@/bundles/Shared/widgets/dashboard/widgets/common/features/adjustments/lib';
import { useForecasts } from '@/entities/reconcile/forecasting/features/api/forecast/useForecasts';
import { SharedSelectController } from '@/bundles/Shared/components/GroupForm/FormItems/new/SharedSelect';
import {
  ADJUSTMENT_TYPE_OPTIONS,
  FINANCIAL_TABLE_WIDGET_ADJUSTMENT_EXPRESSION_OPTIONS,
} from 'bundles/Shared/widgets/dashboard/widgets/common/features/adjustments/config/financial';

export function FinancialTableAdjustmentFields<
  FV extends FieldValues = FieldValues,
>({ control }: { control: Control<FV> }) {
  const getFieldName = (
    name: 'adjustment_expression' | 'adjustment_type' | 'forecast_name',
  ) => name as FieldPath<FV>;

  const adjustmentExpression = useWatch({
    control,
    name: getFieldName('adjustment_expression'),
  });
  const { forecasts = [] } = useForecasts();
  const forecastOptions = forecasts.map((forecast) => ({
    label: forecast.name,
    value: forecast.name,
  }));

  return (
    <SectionFieldsContainer>
      <SectionField labelText="Column Type">
        <RadioGroupController
          control={control}
          name={getFieldName('adjustment_expression')}
          options={FINANCIAL_TABLE_WIDGET_ADJUSTMENT_EXPRESSION_OPTIONS}
        />
      </SectionField>
      {adjustmentExpression != null &&
        isForecastAdjustment(adjustmentExpression) && (
          <SectionField labelText="Select Forcasting Budget">
            <SharedSelectController
              menuPortalTarget={document.body}
              control={control}
              name={getFieldName('forecast_name')}
              options={forecastOptions}
            />
          </SectionField>
        )}
      <SectionField labelText="Adjustment Type">
        <RadioGroupController
          control={control}
          name={getFieldName('adjustment_type')}
          options={ADJUSTMENT_TYPE_OPTIONS}
        />
      </SectionField>
    </SectionFieldsContainer>
  );
}
