import * as React from 'react';
import { ReactNode } from 'react';
import { cn } from '@/shared/lib/css/cn';
import styles from '@/bundles/Shared/components/Containers/appBar/AppBar.module.scss';

interface Props {
  title: string | ReactNode;
  classes?: {
    title: string;
  };
  className?: string;
  children?: ReactNode[] | ReactNode;
}

function AppBar({ title, classes, className, children }: Props) {
  return (
    <div className={cn(styles.appBar, className)}>
      <h1 className={cn('dark-60 header1-bold', classes?.title)}>{title}</h1>
      {children}
    </div>
  );
}

export default AppBar;
