import { cn } from '@/shared/lib/css/cn';
import UniversalFilterButton from 'bundles/Shared/components/Filters/buttons/universalFilterButton/UniversalFilterButton';
import { capitalize, isEmpty } from 'lodash-es';
import { useState } from 'react';
import { Input } from '@/stories/FormControls/Inputs/Input/Input';
import { Popover } from '@/stories/Popover/Popover';

export interface IQuickFilterAmountModel {
  from?: string;
  to?: string;
}

interface IProps {
  amount?: IQuickFilterAmountModel;
  resetFilter: () => void;
  handleChange: (newValue: IQuickFilterAmountModel) => void;
  classes?: {
    container?: string;
  };
  transformValue?: (value: string) => number;
}

export const QuickFilterByAmount = ({ amount, classes, ...props }: IProps) => {
  const [internalState, setInternalState] = useState<IQuickFilterAmountModel>(
    {},
  );
  const state = amount ?? internalState;

  const handleChange = (
    name: 'from' | 'to',
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (amount == null) {
      const newState = {
        ...internalState,
        [name]: props.transformValue
          ? props.transformValue(e.target.value)
          : e.target.value,
      };
      setInternalState({
        ...newState,
      });
      props.handleChange(newState);
      return;
    }
    props.handleChange({
      ...state,
      [name]: e.target.value,
    });
  };

  const handleClose = () => {
    setInternalState({
      from: '',
      to: '',
    });
    props.resetFilter();
  };

  const amountProps = ['from', 'to'] as const;
  const commonProps = (name: 'from' | 'to') =>
    ({
      type: 'number',
      min: 0,
      name,
      placeholder: capitalize(name),
      size: 'l',
      leftIcon: 'paid',
      value: state[name],
      onChange: (e) => handleChange(name, e),
    }) as const satisfies React.ComponentProps<typeof Input>;

  const popover = (
    <div className="w-[320px] rounded-[8px] bg-white">
      <p className="dark-60 inline-semibold mb-s text-left">Amount</p>
      <div className="flex gap-s">
        {amountProps.map((item) => (
          <Input key={item} {...commonProps(item)} />
        ))}
      </div>
    </div>
  );

  return (
    <div className={cn('flex flex-col', classes?.container)}>
      <Popover
        maxWidth="auto"
        placement="bottom-start"
        offset={[0, 2]}
        hiddenArrow
        trigger="click"
        className="p-4"
        template={popover}
      >
        {({ triggered }) => (
          <UniversalFilterButton
            clicked={triggered}
            filtered={!isEmpty(state.from) || !isEmpty(state.to)}
            onClose={handleClose}
          />
        )}
      </Popover>
    </div>
  );
};

export default QuickFilterByAmount;
