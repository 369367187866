import { useDeleteBulkFoldersMutation } from 'bundles/Assets/components/Media/api/objectFoldersApi';
import { useModal } from '@/shared/lib/hooks/useModal';
import { isEqual } from 'lodash-es';
import { SetStateAction, useMemo, useState } from 'react';
import { TreeItem, getTreeFromFlatData } from 'react-sortable-tree';
import { Button } from 'stories/Button/Button';
import { Modal } from 'stories/Modals/Modal/Modal';
import { TreeFolderNavigation } from 'stories/TreeFolderNavigation/TreeFolderNavigation';
import { IInvestmentObject } from 'types/IInvestmentObject';
import { ISharedFolder } from 'types/SharedFolder';
import { generateSortableTreeButtons } from '@/bundles/Assets/components/AssetDocuments';
import NewFolderModal from '@/bundles/Assets/components/Folders/NewFolderModal';
import ChooseFolderTooltip from '@/bundles/Assets/components/SharedFiles/ChooseFolderTooltip';

interface Props {
  folders: ISharedFolder[];
  folder: ISharedFolder;
  setFolder: (folder: number) => void;
  onClose: () => void;
  files: File[];
  setFiles: (files: File[]) => void;
  setFolders: SetStateAction<string>;
  currentFile?: File;
  investmentObject: IInvestmentObject;
}

const SetFolderModal = ({
  folders,
  setFolders,
  folder,
  setFolder,
  onClose,
  files,
  setFiles,
  currentFile,
  investmentObject,
}: Props) => {
  const [deleteBulkFolder] = useDeleteBulkFoldersMutation();
  const [currentFolder, setCurrentFolder] = useState(folder);
  const [newFolderModalOpened, setNewFolderModalOpened] = useState(false);
  const { confirm } = useModal();
  const tree = useMemo<TreeItem<ISharedFolder>[]>(() => {
    const orderedFolders = folders
      .sort((a, b) => a?.title?.localeCompare(b.title) || b.id - a.id)
      .filter(({ internal }) => !internal);
    return getTreeFromFlatData({
      flatData: orderedFolders.map((folder) => ({
        ...folder,
        expanded: folder.id === currentFolder?.id,
      })),
      rootKey: null,
    });
  }, [folders]);

  const onSubmit = () => {
    setFolder(currentFolder.id);
    if (currentFile) {
      const newFiles = [...files];
      const index = newFiles.findIndex((f) => isEqual(f, currentFile));
      newFiles[index] = {
        ...currentFile,
        folder_id: currentFolder.id,
        folder: currentFolder,
      };
      setFiles(newFiles);
    }
    onClose();
  };

  // TODO: copypaste from AssetDocuments.jsx
  const onDelete = async (f) => {
    const confirmed = await confirm({
      title: 'Delete folder',
      subtitle: 'Are you sure you want to delete folder?',
    });

    if (confirmed) {
      await deleteBulkFolder({ ids: [f.id] });
      if (f.id === currentFolder?.id) setCurrentFolder(null);
    }
  };

  const [folderToEdit, setFolderToEdit] = useState();
  const [parentId, setParentId] = useState();

  const openFolderFormModal = (f, newParentId) => {
    setNewFolderModalOpened(false);
    setFolderToEdit(f);
    setParentId(newParentId);
    setNewFolderModalOpened(true);
  };

  return (
    <Modal
      toggle={onClose}
      header="Set Folder"
      actions={
        <div className="flex w-full flex-col items-center justify-center">
          {!currentFolder && (
            <div className="light-60 secondary-regular mb-s block text-center">
              Select a folder to continue
            </div>
          )}
          <Button
            variant="success"
            onClick={onSubmit}
            fluid
            disabled={!currentFolder}
          >
            Set Folder
          </Button>
        </div>
      }
    >
      {tree.length > 0 ? (
        <div>
          <div className="mb-1.5 flex items-center justify-between">
            <div className="dark-60 inline-semibold">
              Where do you want upload files?
            </div>
            <div>
              <ChooseFolderTooltip />
            </div>
          </div>
          <TreeFolderNavigation
            treeData={tree}
            selected={currentFolder}
            onSelectedChange={(selected) => setCurrentFolder(selected)}
            additionalNodeProps={(node) => ({
              actions: generateSortableTreeButtons(
                node,
                folders,
                true,
                openFolderFormModal,
                onDelete,
              ),
              isClickable: true,
            })}
            rootNode={{
              title: investmentObject.entity.name,
            }}
          />
        </div>
      ) : (
        <div className="flex flex-col items-center">
          <div className=" light-60 inline-semibold font-normal">
            This Asset has no Folders yet
          </div>
          <Button
            className="mt-m"
            variant="primary"
            onClick={() => {
              setNewFolderModalOpened(true);
            }}
          >
            New Folder
          </Button>
        </div>
      )}
      {newFolderModalOpened && (
        <NewFolderModal
          setOpened={setNewFolderModalOpened}
          investmentObject={investmentObject}
          folders={folders}
          folder={folderToEdit}
          setFolders={setFolders}
          parentId={parentId}
        />
      )}
    </Modal>
  );
};

export default SetFolderModal;
