import React, { PropsWithChildren } from 'react';
import { navigate } from '@reach/router';
import { Button } from 'stories/Button/Button';
import { Tooltip } from 'stories/Tooltip/Tooltip';

interface Props extends PropsWithChildren {
  disabled: boolean;
  title: string;
  content: string;
  settingsLink: string;
}

const ESignActionTooltip = ({
  children,
  disabled,
  title,
  content,
  settingsLink,
}: Props) => (
  <Tooltip
    theme="light"
    variant="explanation"
    maxWidth={270}
    placement="top"
    disabled={disabled}
    style={{
      backgroundColor: 'white',
    }}
    actionText={
      <Button
        variant="secondary"
        size="xs"
        className="mt-2"
        onClick={() => navigate(settingsLink)}
      >
        Project Settings
      </Button>
    }
    titleText={<span className="secondary-semibold text-dark-60">{title}</span>}
    mainText={content}
  >
    {children}
  </Tooltip>
);

export default ESignActionTooltip;
