import {
  DEFAULT_MEDIA_WIDGET_FIGURE_STYLES,
  DEFAULT_MEDIA_WIDGET_DISPLAY_OPTIONS as defaultDisplayOptions,
  type MediaWidgetDisplayOptions,
} from '@/bundles/Shared/widgets/dashboard/widgets/media/config';
import type { CSSProperties } from 'react';

export const getTextClassByAlignment = (
  alignment: 'left' | 'center' | 'right',
) => ({
  'text-center': alignment === 'center',
  'text-left': alignment === 'left',
  'text-right': alignment === 'right',
});

export const getObjectFitClass = (objectFit: CSSProperties['objectFit']) => ({
  'object-cover': objectFit === 'cover',
  'object-contain': objectFit === 'contain',
  'object-none': objectFit === 'none',
  'object-fill': objectFit === 'fill',
  'object-scale-down': objectFit === 'scale-down',
});

export const getObjectPositionClass = (
  objectPosition: CSSProperties['objectPosition'],
) => ({
  'object-left': objectPosition === 'left',
  'object-center': objectPosition === 'center',
  'object-right': objectPosition === 'right',
  'object-top': objectPosition === 'top',
  'object-bottom': objectPosition === 'bottom',
  'object-left-top': objectPosition === 'left-top',
  'object-right-top': objectPosition === 'right-top',
  'object-left-bottom': objectPosition === 'left-bottom',
  'object-right-bottom': objectPosition === 'right-bottom',
});

export const getContainerBorderClass = (bool = false) => ({
  'rounded-[8px] border-2 border-dashed border-neutral-400': bool,
});

export const getMediaWidgetFigureStyle = (
  displayOptions: MediaWidgetDisplayOptions,
): CSSProperties =>
  ({
    ...DEFAULT_MEDIA_WIDGET_FIGURE_STYLES,
    width: `${displayOptions.widthPercentage}%`,
    height: displayOptions.isFullHeight ? '100%' : displayOptions.height,
  }) as const;

export const getMediaWidgetDisplayOptions = (
  d?: MediaWidgetDisplayOptions,
): MediaWidgetDisplayOptions => ({
  height: d?.height ?? defaultDisplayOptions.height,
  widthPercentage: d?.widthPercentage ?? defaultDisplayOptions.widthPercentage,
  objectFit: d?.objectFit ?? defaultDisplayOptions.objectFit,
  objectPosition: d?.objectPosition ?? defaultDisplayOptions.objectPosition,
  isFullHeight: d?.isFullHeight ?? defaultDisplayOptions.isFullHeight,
});
