import { useGetApiSettingsReportThirdPartyExtractionsConnectionsByIdQuery } from '@/shared/api/settingsReportConnectionsEnhancedApi';

export const useConnectionById = (
  id: number,
  options?: Parameters<
    typeof useGetApiSettingsReportThirdPartyExtractionsConnectionsByIdQuery
  >[1],
) => {
  return useGetApiSettingsReportThirdPartyExtractionsConnectionsByIdQuery(
    {
      id,
    },
    options,
  );
};
