import {
  HEADER_BACKGROUND_SCHEMA,
  PERIOD_SHIFT_SCHEMA,
} from '@/bundles/Shared/widgets/dashboard/widgets/common/config';
import { GROUPING_TYPE_OPTIONS } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/fields/GroupingTypeFIeld';
import { KpiTableSingleDateWidgetConfigDto } from '@/shared/api/dashboardsSettingsGeneratedApi';
import { getOptionsValues } from '@/shared/lib/listHelpers';
import { transformEmptyObjectToUndefined } from '@/shared/lib/yup';
import { transformPeriodShiftDtoToForm } from 'bundles/Shared/widgets/dashboard/widgets/common/lib/config';
import * as yup from 'yup';

export const KPI_TABLE_SINGLE_DATE_WIDGET_CONFIG_SCHEMA = yup
  .object()
  .concat(HEADER_BACKGROUND_SCHEMA)
  .shape({
    period_shift: PERIOD_SHIFT_SCHEMA.nullable()
      .optional()
      .transform(transformEmptyObjectToUndefined)
      .default(undefined),
    hide_total_row: yup.boolean().optional().nullable(),
    groupingType: yup
      .string()
      .oneOf(getOptionsValues(GROUPING_TYPE_OPTIONS))
      .optional()
      .nullable()
      .default(undefined),
  });

export type KpiTableWidgetSingleDateConfigForm = yup.InferType<
  typeof KPI_TABLE_SINGLE_DATE_WIDGET_CONFIG_SCHEMA
>;

export const transformKpiTableSingleDateWidgetConfigToForm = (
  config: KpiTableSingleDateWidgetConfigDto,
): KpiTableWidgetSingleDateConfigForm => {
  return {
    period_shift: {
      key: 'weeks',
      value: 0,
      ...transformPeriodShiftDtoToForm(
        config.default_options.date?.period_shift,
      ),
    },
    hide_total_row: config.hide_total_row,
    groupingType: config.default_options.grouping_type,
    header_background: config.viz_config?.header_background,
  };
};
