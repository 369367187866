import React, { useMemo } from 'react';
import {
  IFetchMarkupsForReconcileBudgetSettingsRequest,
  IReconcileBudgetsSettingsMarkup,
} from 'bundles/REconcile/actions/markupsConfig';
import { nameOf } from 'types/utils';
import { CssVar } from '@/shared/config/cssVar';
import { Label } from 'stories/Label/Label';
import ColorOptionsDropdown from 'bundles/Shared/components/Filters/common/dropdown/ColorOptionsDropdown';
import { upperCase } from 'lodash-es';
import {
  DEFAULT_CODE_COLUMN,
  DEFAULT_NAME_COLUMN,
} from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/crud/constants';
import { IColumn } from 'bundles/Shared/components/Table/types';
import QuickFilterColorDropdown, {
  TQuickFilterColorDropdownProps,
} from 'bundles/Shared/components/Table/filters/QuickFilterColorDropdown';

const INCLUDED_IN_FEE_SETTING: Record<
  `${boolean}`,
  { color: string; label: string }
> = {
  true: {
    color: CssVar.green,
    label: 'Included',
  },
  false: { color: CssVar.light60, label: 'Not Included' },
};

export type TFilterModel = {
  includedInFee?: 'true' | 'false';
};

export function useMarkupsColumns() {
  return useMemo<IColumn<IReconcileBudgetsSettingsMarkup>[]>(
    () => [
      DEFAULT_CODE_COLUMN,
      DEFAULT_NAME_COLUMN,
      {
        dataField: nameOf<IReconcileBudgetsSettingsMarkup>('percent'),
        text: 'Percent',
        headerStyle: {
          minWidth: 100,
        },
        sortable: true,
      },
      {
        dataField: nameOf<IReconcileBudgetsSettingsMarkup>('includedInFee'),
        text: 'Included in Fee',
        headerStyle: {
          minWidth: 280,
        },
        formatter: ({ row }) => (
          <Label
            color={
              INCLUDED_IN_FEE_SETTING[Boolean(row.includedInFee).toString()]
                .color
            }
          >
            {
              INCLUDED_IN_FEE_SETTING[Boolean(row.includedInFee).toString()]
                .label
            }
          </Label>
        ),
        filterComponent: QuickFilterColorDropdown,
        filterComponentParams: {
          options: [
            {
              label: upperCase(INCLUDED_IN_FEE_SETTING.true.label),
              color: INCLUDED_IN_FEE_SETTING.true.color,
              key: 'true',
            },
            {
              label: upperCase(INCLUDED_IN_FEE_SETTING.false.label),
              color: INCLUDED_IN_FEE_SETTING.false.color,
              key: 'false',
            },
          ],
        } as TQuickFilterColorDropdownProps,
      },
    ],
    [],
  );
}
