import { ErrorResponse } from '@/app/stores/errorHandling';
import { usePostApiCoreAssetsByAssetIdReportBuilderReportsAndReportIdWidgetSectionsWidgetSectionIdSnapshotQuery } from '@/entities/report/reportBuilder';
import { AssetsReportBuilderReportWidgetDto } from '@/entities/report/reportBuilder/api/coreAssetsReportBuilderReportsGeneratedApi';
import { selectWidgetById } from '@/entities/report/reportBuilder/model';
import { reportingEnhancedApi } from '@/entities/reporting/api/reportingEnhancedApi';
import {
  isWidgetWithObjectsConfig,
  useEagleEyeReportIdParam,
} from '@/entities/reporting/lib/eagleEye';
import { useAssetIdSearchParam } from '@/shared/lib/hooks/navigation/useAssetIdSearchParam';
import { useAppSelector } from '@/shared/lib/hooks/redux';
import { ReportingEagleEyeWidgetObjectsConfig } from '@/widgets/reporting/widgetObjectsConfig/component';
import { useParams } from '@reach/router';
import {
  REPORT_BUILDER_EAGLE_EYE_WIDGETS_CONFIG_MAP,
  REPORT_BUILDER_WIDGETS_CONFIG_MAP,
} from 'bundles/Shared/widgets/dashboard/widgets/config';
import { asserts } from 'lib/typeHelpers/assertsType';

type ReportWidgetSnapshotLoaderParams = {
  reportId: string;
  widgetId: string;
  refetchOnMountOrArgChange?: boolean;
};

const useObjectLevelReportWidgetSnapshotLoader = ({
  reportId,
  widgetId,
  refetchOnMountOrArgChange,
}: ReportWidgetSnapshotLoaderParams) => {
  const widget = useAppSelector(selectWidgetById(widgetId));
  const context = widget?.context ?? {};
  const assetId = useAssetIdSearchParam();

  return usePostApiCoreAssetsByAssetIdReportBuilderReportsAndReportIdWidgetSectionsWidgetSectionIdSnapshotQuery(
    {
      assetId: assetId!,
      reportId,
      widgetSectionId: widgetId,
      body: {
        context: {
          date: context.date,
          granularity: context.granularity,
          date_from: context.dateFrom,
          date_to: context.dateTo,
          per_page: context.perPage,
          page: context.page,
        },
      },
    },
    {
      skip: assetId == null,
      refetchOnMountOrArgChange,
    },
  );
};

/**
 * Loads Snapshots at 'Basic' tab. So redundant pdf loads don't happen
 */
export const ObjectLevelReportWidgetSnapshotLoader = (
  params: Pick<ReportWidgetSnapshotLoaderParams, 'reportId' | 'widgetId'>,
) => {
  useObjectLevelReportWidgetSnapshotLoader({
    ...params,
    refetchOnMountOrArgChange: true,
  });
  return <></>;
};
export const ObjectLevelReportWidgetConfigCard = ({
  widgetSection,
  isLoading,
  disabled,
}: {
  widgetSection: AssetsReportBuilderReportWidgetDto;
  index: number;
  isLoading: boolean;
  disabled?: boolean;
}) => {
  const assetId = useAssetIdSearchParam();
  const params = useParams();
  const { title, CardConfigComponent } =
    REPORT_BUILDER_WIDGETS_CONFIG_MAP[widgetSection.widgetType] ?? {};

  const { data, isError, error } = useObjectLevelReportWidgetSnapshotLoader({
    assetId: assetId!,
    reportId: params.id,
    widgetId: widgetSection.id,
  });

  asserts(
    CardConfigComponent,
    `Every Config inside REPORT_BUILDER_WIDGETS_CONFIG_MAP needs to have CardConfigComponent ${widgetSection.widgetType}`,
  );

  if (!CardConfigComponent) {
    return null;
  }

  return (
    <CardConfigComponent
      data={data!}
      disabled={disabled}
      assetId={Number(assetId)}
      isLoading={isLoading}
      isError={isError}
      errorMessage={(error?.data as ErrorResponse)?.errors}
      title={widgetSection.title ?? title}
      widgetSection={widgetSection}
      reportId={params.id}
    />
  );
};

const useEagleEyeReportWidgetSnapshotLoader = ({
  reportId,
  widgetId,
  refetchOnMountOrArgChange,
}: ReportWidgetSnapshotLoaderParams) => {
  const widget = useAppSelector(selectWidgetById(widgetId));
  const context = widget?.context ?? {};

  return reportingEnhancedApi.usePostApiReportBuilderEagleEyeReportsByEagleEyeReportIdWidgetSectionsAndWidgetSectionIdSnapshotQuery(
    {
      eagleEyeReportId: reportId,
      widgetSectionId: widgetId,
      body: {
        context: {
          date: context.date,
          granularity: context.granularity,
          grouping_type: context.groupingType,
          period: context.period,
          date_from: context.dateFrom,
          date_to: context.dateTo,
          per_page: context.perPage,
          page: context.page,

          asset_ids: context.assetIds,
          segment_ids: context.segmentIds,
          excluded_legal_entity_ids: context.excludedLEIds,
        },
      },
    },
    {
      refetchOnMountOrArgChange,
    },
  );
};

/**
 * Loads Snapshots at 'Basic' tab. So redundant pdf loads don't happen
 */
export const EagleEyeReportWidgetSnapshotLoader = (
  params: Pick<ReportWidgetSnapshotLoaderParams, 'reportId' | 'widgetId'>,
) => {
  useEagleEyeReportWidgetSnapshotLoader({
    ...params,
    refetchOnMountOrArgChange: true,
  });
  return <></>;
};

// TODO merge with ObjectLevelReportWidgetConfigCard
export const EagleEyeReportWidgetConfigCard = ({
  widgetSection,
  isLoading,
  disabled,
}: {
  widgetSection: AssetsReportBuilderReportWidgetDto;
  index: number;
  isLoading: boolean;
  disabled?: boolean;
}) => {
  const reportId = useEagleEyeReportIdParam();
  const { title, CardConfigComponent } =
    REPORT_BUILDER_EAGLE_EYE_WIDGETS_CONFIG_MAP[widgetSection.widgetType] ?? {};

  const { data, isError, error } = useEagleEyeReportWidgetSnapshotLoader({
    reportId,
    widgetId: widgetSection.id,
  });

  asserts(
    CardConfigComponent,
    `Every Config inside REPORT_BUILDER_WIDGETS_CONFIG_MAP needs to have CardConfigComponent ${widgetSection.widgetType}`,
  );

  if (!CardConfigComponent) {
    return null;
  }

  return (
    <CardConfigComponent
      data={data!}
      disabled={disabled}
      isLoading={isLoading}
      isError={isError}
      errorMessage={(error?.data as ErrorResponse)?.errors}
      title={widgetSection.title ?? title}
      widgetSection={widgetSection}
      reportId={reportId}
      widgetObjectsConfig={
        isWidgetWithObjectsConfig(widgetSection.widgetType) && (
          <ReportingEagleEyeWidgetObjectsConfig />
        )
      }
    />
  );
};
