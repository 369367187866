import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import {
  ROUTES_ROOT,
  allSubPathMatches,
  generateUrl,
} from '@/shared/lib/hooks/useNavigation';
import { usePrevious } from '@/shared/lib/hooks/usePrevious';
import { Button } from '@/stories/Button/Button';
import { LinkButton } from '@/stories/LinkButton/LinkButton';
import { ThinTabGroup } from '@/stories/Tabs/ThinTabGroup/ThinTabGroup';
import { navigate, useMatch } from '@reach/router';
import { FireIconAlternative } from 'bundles/FireStation/components/FireIcon/FireIcon';
import {
  FORMULA_REFERENCING_ENTITY_TYPES,
  Formula,
  FormulaCard,
  FormulaReferencingEntityGroup,
  FormulaReferencingEntityGroupList,
  FormulaReferencingEntityLabelList,
  TEXT_VARIABLE_REFERENCE_PREFIX,
  closeFormula,
  formulaHasInvalidReference,
  openFormula,
  openFormulas,
  selectCurrentFormulaIndex,
  selectOpenedFormulas,
  updateCurrentFormulaIndex,
  useReportFormulasQuery,
} from 'bundles/Shared/entities/formula';
import { FORMULA_AND_VARIABLES_TABS } from 'bundles/Shared/entities/formula/config';
import { DEFAULT_NEW_FORMULA_VALUES } from 'bundles/Shared/features/formula/editFormula';
import { FormulaList, VariableList } from 'bundles/Shared/widgets/formula/list';
import { groupBy } from 'lodash-es';
import pluralize from 'pluralize';
import React, { useEffect } from 'react';
import { SidePanel } from 'stories/Modals/Modal/Modal';
import Popover from 'stories/Popover/Popover';
import { useTabs } from 'stories/Tabs/useTabs';

export const FormulaPopover = <
  F extends Pick<
    Formula,
    'label' | 'referencedInEntities' | 'invalidReferences'
  >,
>({
  formula,
  onOpen,
  children,
}: React.PropsWithChildren<{
  formula: F;
  onOpen?: VoidFunction;
}>) => {
  const groupedEntities = groupBy(formula.referencedInEntities, 'type');
  const entitiesNotEmpty = formula.referencedInEntities.length > 0;
  const hasErrors = formulaHasInvalidReference(formula);
  const errorsCount = formula.invalidReferences?.length;

  return (
    <Popover
      trigger="mouseenter"
      placement={'bottom-start'}
      arrowPosition="start"
      className="w-[350px] p-4"
      maxWidth={350}
      disabled={!(entitiesNotEmpty || hasErrors)}
      appendToBody
      template={
        <div className="flex flex-col gap-2">
          {hasErrors && (
            <div className="flex items-center gap-1.5">
              <FireIconAlternative withMarker />
              <span className="secondary-regular text-neutral-800">
                {errorsCount} {pluralize(' Error', errorsCount)}
              </span>
            </div>
          )}
          <span className="body-semibold text-neutral-800">
            {formula.label}
          </span>
          <span className="secondary-semibold text-neutral-550">In use:</span>
          <FormulaReferencingEntityGroupList>
            {FORMULA_REFERENCING_ENTITY_TYPES.map((key, index) => (
              <>
                <FormulaReferencingEntityGroupList.Item
                  className="px-0"
                  key={key}
                >
                  <FormulaReferencingEntityGroup.Header
                    type={key}
                    count={groupedEntities[key]?.length ?? 0}
                  >
                    {onOpen != null &&
                      key === 'formula' &&
                      groupedEntities[key]?.length > 0 && (
                        <>
                          <div className="grow" />
                          <LinkButton onClick={onOpen}>Open In Tabs</LinkButton>
                        </>
                      )}
                  </FormulaReferencingEntityGroup.Header>
                  {groupedEntities[key] && (
                    <FormulaReferencingEntityLabelList
                      items={groupedEntities[key]}
                    />
                  )}
                </FormulaReferencingEntityGroupList.Item>
                {index < FORMULA_REFERENCING_ENTITY_TYPES.length - 1 && (
                  <div className="border-b border-neutral-100" />
                )}
              </>
            ))}
          </FormulaReferencingEntityGroupList>
        </div>
      }
    >
      {children}
    </Popover>
  );
};

export const FormulaAndVariablesPanel = ({
  onClose,
  onSubmit,
  ...props
}: DialogProps<Formula['reference']> &
  Omit<React.ComponentProps<typeof SidePanel>, 'toggle' | 'children'>) => {
  const { thinTabGroupProps, tab: selectedTab } = useTabs(
    FORMULA_AND_VARIABLES_TABS,
    FORMULA_AND_VARIABLES_TABS[0],
  );
  const dispatch = useAppDispatch();
  const openedFormulas = useAppSelector(selectOpenedFormulas);
  const formulasMatch = useMatch(
    allSubPathMatches(ROUTES_ROOT.settings.report.financialFormulas.fullPath),
  );
  const formulaMatch = useMatch(
    allSubPathMatches(
      ROUTES_ROOT.settings.report.financialFormulas.formula.fullPath,
    ),
  );
  const currentFormulaIndex = useAppSelector(selectCurrentFormulaIndex);
  const currentlyOpenedFormula =
    currentFormulaIndex != null ? openedFormulas[currentFormulaIndex] : null;
  const { formulas } = useReportFormulasQuery();
  const isFormulaCreating = openedFormulas.some((f) => f.id == null);
  const previouslyOpenedFormula = usePrevious(currentlyOpenedFormula);

  useEffect(() => {
    if (!formulasMatch) return;

    if (currentlyOpenedFormula) {
      const path =
        ROUTES_ROOT.settings.report.financialFormulas.formula.fullPath;
      navigate(
        generateUrl(path, {
          pathParams: {
            formulaSlug: currentlyOpenedFormula.slug ?? 'new',
          },
        }),
        {
          replace: true,
        },
      );
      return;
    }

    if (previouslyOpenedFormula) {
      const path = ROUTES_ROOT.settings.report.financialFormulas.fullPath;
      navigate(path);
      return;
    }

    const formula = formulas.find((f) => f.slug === formulaMatch?.formulaSlug);

    if (formula == null) return;

    dispatch(openFormula(formula));
    const path = ROUTES_ROOT.settings.report.financialFormulas.formula.fullPath;

    navigate(
      generateUrl(path, {
        pathParams: {
          formulaSlug: formula.slug,
        },
      }),
      {
        replace: true,
      },
    );
  }, [currentlyOpenedFormula, formulas]);

  const FormulaActions = ({ formula }: { formula: Formula }) => {
    const formulaIndex = openedFormulas.findIndex((f) => f.id === formula.id);
    const handleOpenFormula = () => {
      if (formulaIndex !== -1) {
        dispatch(updateCurrentFormulaIndex(formulaIndex));
        return;
      }
      dispatch(openFormula(formula));
    };
    const handleOpenFormulas = () => {
      const referencedFormulas = formulas.filter((f) =>
        formula.referencedInEntities.some((e) => e.id === f.id),
      );
      dispatch(openFormulas(referencedFormulas));
    };
    const opened = openedFormulas.some((f) => f.id === formula.id);

    return (
      <>
        <FormulaPopover onOpen={handleOpenFormulas} formula={formula}>
          <FormulaCard.FormulaControl
            error={formulaHasInvalidReference(formula)}
            onOpen={handleOpenFormula}
            onClose={
              opened ? () => dispatch(closeFormula(formulaIndex)) : undefined
            }
            opened={opened}
            referencedInEntitiesCount={formula.referencedInEntities.length}
          />
        </FormulaPopover>
        <div className="grow" />
        {onSubmit && (
          <Button
            disabled={formulaHasInvalidReference(formula)}
            variant="secondary"
            className="invisible group-hover:!visible"
            size="xs"
            onClick={() => onSubmit?.(formula.reference)}
          >
            Select
          </Button>
        )}
      </>
    );
  };

  return (
    <SidePanel
      toggle={onClose}
      header="Formulas & Variables"
      classes={{
        body: '!p-0',
      }}
      {...props}
    >
      <div className="flex max-h-[calc(100vh_-_66px)] min-h-[calc(100vh_-_66px)] flex-col gap-4 overflow-auto bg-neutral-100 p-6">
        <ThinTabGroup fullWidth {...thinTabGroupProps} />
        {selectedTab?.id === 'formulas' && (
          <FormulaList
            searchPanel={
              <Button
                className="gap-1"
                size="s"
                variant="success"
                disabled={isFormulaCreating}
                onClick={() => {
                  dispatch(openFormula(DEFAULT_NEW_FORMULA_VALUES));
                }}
                tooltipProps={{
                  disabled: !isFormulaCreating,
                  mainText: 'You can create only one formula at a time',
                }}
              >
                New
              </Button>
            }
            formulaCardProps={(formula) => ({
              selected: currentlyOpenedFormula?.id == formula.id,
              labelPanel: <FormulaActions formula={formula} />,
            })}
          />
        )}
        {selectedTab?.id === 'variables' && (
          <VariableList
            excludeNamespaces={[TEXT_VARIABLE_REFERENCE_PREFIX]}
            onSelect={onSubmit}
          />
        )}
      </div>
    </SidePanel>
  );
};
