import { cn } from '@/shared/lib/css/cn';
import { AnimationLoader } from '@/stories/AnimationLoader/AnimationLoader';
import SkeletonBlock from '@/stories/ProjectCard/SkeletonBlock';
import React, { forwardRef, PropsWithChildren } from 'react';

const EntityItemListWrapper = (props: LayoutProps) => (
  <div
    className={cn(
      'flex max-h-screen min-h-screen flex-col border-r border-neutral-200 bg-neutral-000',
      props.className,
    )}
  >
    {props.children}
  </div>
);

const EntityItemListHeader = (props: LayoutProps) => (
  <div className={cn('flex flex-col gap-4 px-4 pb-3 pt-5', props.className)}>
    {props.children}
  </div>
);

const EntityItemListTitle = (props: LayoutProps) => (
  <p className={cn('header5-bold text-neutral-900', props.className)}>
    {props.children}
  </p>
);

const EntityItemListSkeletons = () =>
  Array.from({ length: 5 }, (_, idx) => (
    <div key={idx} className="flex bg-neutral-000 p-4">
      <SkeletonBlock className="h-[52px] w-full" />
    </div>
  ));

type EntityItemListProps = PropsWithChildren &
  PropsWithClassName & {
    isLoading: boolean;
    isFetching: boolean;
  };

export const EntityItemList = forwardRef<HTMLDivElement, EntityItemListProps>(
  ({ children, isFetching, isLoading, className }, ref) => {
    return (
      <div
        ref={ref}
        className={cn(
          'relative flex flex-col gap bg-neutral-000 py-[1px]',
          className,
        )}
      >
        {isFetching && !isLoading && <AnimationLoader withBg />}
        {isLoading ? <EntityItemListSkeletons /> : children}
      </div>
    );
  },
) as {
  Wrapper: typeof EntityItemListWrapper;
  Header: typeof EntityItemListHeader;
  Title: typeof EntityItemListTitle;
} & React.ForwardRefExoticComponent<EntityItemListProps>;

EntityItemList.Wrapper = EntityItemListWrapper;
EntityItemList.Header = EntityItemListHeader;
EntityItemList.Title = EntityItemListTitle;

EntityItemList.displayName = 'EntityItemList';
