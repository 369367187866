/* eslint-disable */
import React, { useState } from 'react';
import TreeView, { TreeViewProps } from 'stories/TreeView/TreeView';
import type { NodeType } from 'stories/TreeView/types';
import { DatumNode } from 'stories/TreeView/types';
import RelatedGLModal from '@/bundles/Settings/components/REport/CategoriesList/RelatedGLModal/RelatedGLModal';
import { SearchableTree } from 'bundles/Settings/components/REport/CategoriesList/SearchableTree/SearchableTree';
import { Icon } from '@/stories/Icon/Icon';
import {
  ASSET_CLASS_HAS_SET_TEXT,
  getClassificationsTooltipText,
} from '@/bundles/Shared/entities/leClasssification/config';
import { isEmpty } from 'lodash-es';
import Tooltip from '@/stories/Tooltip/Tooltip';
import { DataNodeAndExtraProps } from '@/bundles/Settings/components/REport/CategoriesList/SearchableTree/utils';

export interface IRelatedGLState {
  active: boolean;
  linkedItems: {
    label: string;
  }[];
}

function TreeViewWithNavigation<T extends DatumNode = DatumNode>({
  readonlyNavigation,
  ...props
}: { readonlyNavigation?: boolean } & TreeViewProps<T>) {
  const [relatedGLState, setRelatedGLState] = useState<IRelatedGLState>({
    active: false,
    linkedItems: [],
  });

  const handleToggle = (active: boolean) =>
    setRelatedGLState((prev) => ({ ...prev, active }));

  const handleOpenMoreLinkedItems = (node: NodeType) => {
    setRelatedGLState({
      active: true,
      linkedItems: node?.data?.linkedItems ?? [],
    });
  };

  const itemActions = (item: DataNodeAndExtraProps) => {
    if (
      isEmpty(item.classifications) ||
      props.handleManageAssetClasses == null
    ) {
      return undefined;
    }
    return (
      <Tooltip
        maxWidth={300}
        variant="explanation"
        actionText="Manage"
        actionHandler={() => props.handleManageAssetClasses?.(item)}
        titleText={ASSET_CLASS_HAS_SET_TEXT}
        mainText={getClassificationsTooltipText(item.classifications)}
      >
        <Icon iconName="info" />
      </Tooltip>
    );
  };

  return (
    <>
      <div className="grid h-full w-full grid-cols-[450px_1fr]">
        <SearchableTree
          data={props.data}
          selectedId={props.selected?.id}
          onSelect={(id) => props.onSelectedChange?.(id ? { id } : undefined)}
          readonly={readonlyNavigation}
          itemActions={itemActions}
        />
        <TreeView
          {...props}
          handleOpenMoreLinkedItems={handleOpenMoreLinkedItems}
        />
      </div>
      {relatedGLState.active && (
        <RelatedGLModal toggle={handleToggle} relatedGLState={relatedGLState} />
      )}
    </>
  );
}

export default TreeViewWithNavigation;
