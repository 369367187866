import type { ReportBuilderTemplate } from '@/entities/report/reportBuilder';
import { useAssertNullableContext } from '@/shared/lib/hooks/context/useAssertNullableContext';
import { createContext, useContext } from 'react';

export type ReportingEntityKindContextType = ReportBuilderTemplate['kind'] &
  ReportBuilderTemplate['kind'];

export const ReportingEntityKindContext =
  createContext<ReportingEntityKindContextType | null>(null);

export const useReportingEntityKindContext = useAssertNullableContext(
  ReportingEntityKindContext,
);
export const useNullableReportingEntityKindContext = () =>
  useContext(ReportingEntityKindContext);
