import React from 'react';
import { cn } from '@/shared/lib/css/cn';
import styles from '@/bundles/REconcile/components/comments/CommentsList/CommentsList.module.scss';

interface Props {
  className?: string;
}

function CommentsList({ className, children }: React.PropsWithChildren<Props>) {
  return <div className={cn(styles.list, className)}>{children}</div>;
}

export default CommentsList;
