import {
  usePostApiSettingsReportThirdPartyExtractionsConnectionsMutation,
  usePutApiSettingsReportThirdPartyExtractionsConnectionsByIdMutation,
} from '@/shared/api/settingsReportConnectionsEnhancedApi';

export const useUpdateConnection = () => {
  return usePutApiSettingsReportThirdPartyExtractionsConnectionsByIdMutation();
};

export const useCreateConnection = () => {
  return usePostApiSettingsReportThirdPartyExtractionsConnectionsMutation();
};
