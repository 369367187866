import { ReportingEntityKindContext } from '@/entities/reporting/context/entityKind';
import { ReportingEntityStageContext } from '@/entities/reporting/context/entityStage';
import { ReportBuilderTemplateSettingsWidget } from '@/widgets/reportBuilder/reportBuillderTemplate/settingsWidget';
import { type RouteComponentProps } from '@reach/router';
import { type FC } from 'react';

export const SettingsReportBuilderTemplateWidgetPage: FC<
  RouteComponentProps
> = () => {
  return (
    <ReportingEntityKindContext.Provider value="object_level">
      <ReportingEntityStageContext.Provider value="template">
        <ReportBuilderTemplateSettingsWidget />
      </ReportingEntityStageContext.Provider>
    </ReportingEntityKindContext.Provider>
  );
};
