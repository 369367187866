import React, { FC, useMemo, useState } from 'react';
import { ConfirmationModal } from 'stories/Modals/ConfirmationModal/ConfirmationModal';
import { Modal } from 'stories/Modals/Modal/Modal';
import FormButtons from 'bundles/Shared/components/GroupForm/FormButtons/FormButtons';
import FormComponent from 'bundles/Shared/components/GroupForm/FormComponent/FormComponent';
import { IChangeEvent } from 'bundles/Construction/types';
import { useAppSelector } from '@/shared/lib/hooks/redux';
import { selectChangeEvent } from 'bundles/Construction/reducers/ReconcileChangeEventSlice';
import {
  selectReasons,
  selectCompanies,
  selectContacts,
} from 'bundles/Construction/reducers/ReconcileSlice';
import getItemsCEPromoteModal from '@/bundles/Construction/components/ChangeOrderEvent/PromoteModal/initData';
import { useGroupedFilteredItems } from '@/bundles/Construction/components/helpers';
import { optionFor, optionReconcileFor } from 'lib/helpers';
import { convertToMilliseconds } from '@/shared/lib/converters';

interface IPromoteCEModalProps {
  setShowModal: () => void;
  promoteFunc: () => void;
  categoryChange: IChangeEvent['category'] | 'revise' | false;
  assetName: string;
}

const PromoteReviseCEModal: FC<IPromoteCEModalProps> = ({
  setShowModal,
  promoteFunc,
  categoryChange,
}) => {
  const changeEvent = useAppSelector(selectChangeEvent);
  const reasons = useAppSelector(selectReasons);
  const companies = useAppSelector(selectCompanies);
  const contacts = useAppSelector(selectContacts);

  const isIPC = changeEvent?.category === 'IPC';
  const [formData, setFormData] = useState({
    ...changeEvent,
    ...(!isIPC && {
      number: changeEvent?.code
        ? `${changeEvent.number}-${changeEvent.code}`
        : changeEvent?.number,
    }),
    status: changeEvent?.status,
    reconcileCompany: isIPC
      ? null
      : optionReconcileFor(changeEvent?.reconcileCompany),
    reconcileContact: optionReconcileFor(changeEvent?.reconcileContact),
    receivedDate: convertToMilliseconds(changeEvent?.receivedDate),
    contractorDate: convertToMilliseconds(changeEvent?.contractorDate),
    sentToDesignerOfRecordDate: changeEvent?.sentToDorDate
      ? convertToMilliseconds(changeEvent?.sentToDorDate)
      : null,
    changeOrderReasons: changeEvent?.changeOrderReasons.map((reason) => ({
      label: reason.name,
      id: reason.id,
    })),
    reallocationJccId: changeEvent?.reallocation
      ? changeEvent?.reallocation.jccId
      : null,
    probability_comment: changeEvent?.probabilityComment
      ? changeEvent?.probabilityComment
      : null,
  });
  const [validate, setValidate] = useState({
    number: true,
  });
  const [loading, setLoading] = useState(false);
  const [showConfirmModal, setConfirmModal] = useState(false);

  const filteredReasons = useGroupedFilteredItems(reasons);

  const initGroupFormItems = useMemo(
    () =>
      getItemsCEPromoteModal(
        formData,
        setFormData,
        companies,
        contacts,
        filteredReasons,
        true,
        null,
        categoryChange,
      ),
    [formData, setFormData],
  );

  const onLoading = () => {
    setLoading(true);
  };

  const closeCOR = () => {
    setConfirmModal(true);
  };

  const confirmModalHandle = (status: boolean) => {
    if (status) {
      setShowModal(false);
    }
    setConfirmModal(false);
  };
  return (
    <>
      <Modal
        header={
          <div>
            <div className="dark header6-bold">
              {categoryChange === 'revise'
                ? 'Revise Change Event'
                : 'Promote Change Event'}
            </div>
          </div>
        }
        additionalActions={
          <FormButtons
            formData={formData}
            groupFormItems={initGroupFormItems}
            onSubmit={() =>
              promoteFunc(
                formData,
                categoryChange === 'revise' ? 'revise' : null,
              )
            }
            buttonConfig={{
              titleBtn: categoryChange !== 'revise' ? 'Promote' : 'Revise',
              ...(!loading && {
                iconName: categoryChange !== 'revise' ? 'upgrade' : 'sync',
              }),
              iconPosition: 'left',
              funcCancel: closeCOR,
              withoutCancel: categoryChange !== 'revise',
              width: categoryChange !== 'revise' ? '100%' : 'auto',
              message:
                categoryChange !== 'revise'
                  ? 'Please fill out all the required fields'
                  : '',
            }}
            loading={loading}
            onLoading={onLoading}
          />
        }
        size="lg"
        classes={{
          body: 'reconcile-common-modal-create',
        }}
        maxHeight
        toggle={closeCOR}
        bodyPadding="0 1.5rem"
      >
        {initGroupFormItems.map((formItem, idx) => (
          <div key={formItem.title}>
            <FormComponent
              {...formItem}
              compId={idx + 1}
              title={formItem.customTitle}
              formData={formData}
              setFormData={setFormData}
              styles={{
                wrapper__item: 'group-form__step-item_transparent',
              }}
              steps
              validate={validate}
              setValidate={setValidate}
            />
          </div>
        ))}
      </Modal>
      {showConfirmModal && (
        <ConfirmationModal
          title="Are you sure that you want to quit?"
          subtitle="The changed data will be lost."
          actions={{
            primaryButton: {
              text: 'Yes',
              variant: 'danger',
            },
            secondaryButton: {
              text: 'No',
              variant: 'secondary',
            },
          }}
          handleResolve={confirmModalHandle}
        />
      )}
    </>
  );
};

export default PromoteReviseCEModal;
