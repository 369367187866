/* eslint-disable max-params */
import { stringify } from 'qs';
import { getToken } from 'lib/currentUser';
import { formatUnixDate } from '@/shared/lib/formatting/dates';
import { defaultHeader, initPDFBuilder } from '@/lib/pdfBuilder';
import {
  accountingNumberFormat,
  percentNumberFormat,
} from '@/shared/lib/formatting/number';
import { apiClient } from '@/lib/http';
import saveFileOnUserDevice from '@/shared/lib/browser/saveFileOnUserDevice';
import { fetchImage } from '@/lib/helpers';
import {
  CE_HEADERS,
  CO_HEADERS,
  numberDesc,
  REALLOCATION_HEADERS,
} from 'bundles/Construction/utils';
import { getAbsoluteLogoPath } from '@/shared/lib/ui/logo';

export const buildWaterfallData = ({ type, report }) => {
  const {
    currentContingency,
    currentConstructionCost,
    projectedContingency,
    projectedConstructionCost,
    hardCostContingency,
    hardConstructionCost,
    approvedCor,
    approvedCo,
    pendingCor,
    pendingPco,
    ipcWeighted,
    disputed,
    otherReallocations,
    internalHcContingency,
    summaryWithProjected,
    summaryWithInternal,
  } = report;

  let data;

  if (type === 'contingency') {
    data = [
      {
        name: 'Initial',
        value: Number(hardCostContingency),
        hardBreak: true,
        color: '#0d1a3e',
      },
      {
        name: 'OCOs (Completed)',
        value: -Number(approvedCo),
        color: '#6f6a6a',
      },
      {
        name: 'CORs (Approved)',
        value: -Number(approvedCor),
        color: '#9e9e9e',
      },
      {
        name: 'Other Alloc.',
        value: -Number(otherReallocations),
        color: '#105989',
      },
      {
        name: 'Current',
        value: Number(currentContingency),
        hardBreak: true,
        color: '#0d1a3e',
      },
    ];
    if (summaryWithProjected) {
      data = data.concat([
        {
          name: 'CORs (Pending)',
          value: -Number(pendingCor),
          color: '#c60118',
        },
        {
          name: 'PCOs (Submitted)',
          value: -Number(pendingPco),
          color: '#ffa344',
        },
        { name: 'CORs (Disputed)', value: -Number(disputed), color: '#b71539' },
        {
          name: 'Projected',
          value: Number(projectedContingency),
          hardBreak: true,
          color: '#0d1a3e',
        },
      ]);
    }

    if (summaryWithInternal) {
      data = data.concat([
        { name: 'IPC', value: -Number(ipcWeighted), color: '#ffdfbe' },
        {
          name: 'Pro-Forma',
          value: internalHcContingency,
          hardBreak: true,
          color: '#128141',
        },
      ]);
    }
    return data;
  }

  if (type === 'construction') {
    data = [
      {
        name: 'Initial',
        value: Number(hardConstructionCost),
        hardBreak: true,
        color: '#0d1a3e',
      },
      {
        name: 'OCOs (Completed)',
        value: -Number(approvedCo),
        color: '#6f6a6a',
      },
      { name: 'CORs (Approved)', value: Number(approvedCor), color: '#9e9e9e' },
      {
        name: 'Other Alloc.',
        value: -Number(otherReallocations),
        color: '#105989',
      },
      {
        name: 'Current',
        value: Number(currentConstructionCost),
        hardBreak: true,
        color: '#0d1a3e',
      },
    ];
    if (summaryWithProjected) {
      data = data.concat([
        { name: 'CORs (Pending)', value: Number(pendingCor), color: '#c60118' },
        {
          name: 'PCO (Submitted)',
          value: Number(pendingPco),
          color: '#ffa344',
        },
        { name: 'CORs (Disputed)', value: Number(disputed), color: '#b71539' },
        {
          name: 'Projected',
          value: Number(projectedConstructionCost),
          hardBreak: true,
          color: '#0d1a3e',
        },
      ]);
    }

    if (summaryWithInternal) {
      data = data.concat([
        { name: 'IPC', value: Number(ipcWeighted), color: '#ffdfbe' },
        {
          name: 'Pro-Forma',
          value: Number(projectedConstructionCost) + Number(ipcWeighted),
          hardBreak: true,
          color: '#128141',
        },
      ]);
    }
    return data;
  }
};

export const buildExecutiveSummaryReport = (report) => {
  const {
    approvedCo,
    approvedCor,
    pendingPco,
    disputed,
    hardCostContingency,
    pendingCor,
    otherReallocations,
    hardConstructionCost,
    remainingGmp,
    remainingContingency,
    constructionCompletePercent,
    hcContingencyUsedPercent,
    siteworkContingency,
    completedAndStored,
    retainage,
    previousCertifiedWorks,
    summaryWithProjected,
  } = report;

  const currentExecContingency =
    Number(hardCostContingency) -
    Number(approvedCo) -
    Number(otherReallocations);
  const generalSummary = {
    originalGmp: {
      label: 'Original GMP Contract Sum',
      amount: accountingNumberFormat(hardConstructionCost, {
        maximumFractionDigits: 0,
      }),
    },
    netChangeByApproved: {
      label: 'Net Change by Approved Change Orders',
      amount: accountingNumberFormat(approvedCo, { maximumFractionDigits: 0 }),
    },
    siteworkContingency: {
      label: 'Sitework Contingency',
      amount: accountingNumberFormat(siteworkContingency, {
        maximumFractionDigits: 0,
      }),
    },
    balanceOfHardCostContingency: {
      label: 'Balance of Hard Cost Contingency',
      amount: accountingNumberFormat(currentExecContingency, {
        maximumFractionDigits: 0,
      }),
    },
    adjustedDirectCost: {
      label: 'Adjusted Direct Cost Budget',
      amount: accountingNumberFormat(
        Number(hardConstructionCost) +
          Number(approvedCo) +
          Number(siteworkContingency) +
          Number(currentExecContingency),
        { maximumFractionDigits: 0 },
      ),
    },
  };
  const constructionBudgetSummary = {
    completedAndStored: {
      label: 'Total Completed & Stored to Date',
      amount: accountingNumberFormat(completedAndStored, {
        maximumFractionDigits: 0,
      }),
    },
    retainage: {
      label: 'Less: Total Retainage',
      amount: accountingNumberFormat(-retainage, { maximumFractionDigits: 0 }),
    },
    totalEarned: {
      label: 'Total Earned, Less Retainage',
      amount: accountingNumberFormat(completedAndStored - retainage, {
        maximumFractionDigits: 0,
      }),
    },
    previousCertifiedWorks: {
      label: 'Less: Previous Certifications for Payment',
      amount: accountingNumberFormat(-previousCertifiedWorks, {
        maximumFractionDigits: 0,
      }),
    },
    currentConstructionRequest: {
      label: 'Current Construction Request',
      amount: accountingNumberFormat(
        completedAndStored - retainage - previousCertifiedWorks,
        { maximumFractionDigits: 0 },
      ),
    },
  };

  let changeEventsSummary = {
    approvedCo: {
      label: 'Approved Owner Change Orders (OCOs)',
      amount: accountingNumberFormat(approvedCo, { maximumFractionDigits: 0 }),
    },
    approvedCor: {
      label: 'Pending OCOs & Approved Change Order Requests (CORs)',
      amount: accountingNumberFormat(approvedCor, { maximumFractionDigits: 0 }),
    },
  };

  let totalChangeEvents = Number(approvedCo) + Number(approvedCor);

  if (summaryWithProjected) {
    changeEventsSummary = {
      ...changeEventsSummary,
      pendingCor: {
        label: 'Pending CORs',
        amount: accountingNumberFormat(pendingCor, {
          maximumFractionDigits: 0,
        }),
      },
      pendingPco: {
        label: 'Potential Change Order Requests (PCOs)',
        amount: accountingNumberFormat(pendingPco, {
          maximumFractionDigits: 0,
        }),
      },
      disputed: {
        label: 'Disputed CORs',
        amount: accountingNumberFormat(disputed, { maximumFractionDigits: 0 }),
      },
    };
    totalChangeEvents +=
      Number(pendingCor) + Number(pendingPco) + Number(disputed);
  }

  changeEventsSummary.total = {
    label: 'Total Change Events',
    amount: accountingNumberFormat(totalChangeEvents, {
      maximumFractionDigits: 0,
    }),
  };

  let contingencySummary = {
    hardCostContingency: {
      label: 'Initial Hard Cost Contingency Budget',
      amount: accountingNumberFormat(hardCostContingency, {
        maximumFractionDigits: 0,
      }),
    },
    remainingContingency: {
      label: 'Remaining Contingency after Approved OCOs',
      amount: accountingNumberFormat(remainingContingency, {
        maximumFractionDigits: 0,
      }),
    },
    remainingMinusApproved: {
      label: 'Remaining Contingency after Approved OCOs & CORs',
      amount: accountingNumberFormat(
        remainingContingency - Number(approvedCor),
        { maximumFractionDigits: 0 },
      ),
    },
  };

  if (summaryWithProjected) {
    contingencySummary = {
      ...contingencySummary,
      approvedAndPending: {
        label: 'Remaining Contingency after ALL Approved & Pending',
        amount: accountingNumberFormat(
          remainingContingency -
            Number(approvedCor) -
            Number(pendingCor) -
            Number(pendingPco),
          { maximumFractionDigits: 0 },
        ),
      },
      approvedAndPendingAndDisputed: {
        label: 'Remaining Contingency after ALL Approved & Pending & Disputed',
        amount: accountingNumberFormat(
          remainingContingency -
            Number(approvedCor) -
            Number(pendingCor) -
            Number(pendingPco) -
            Number(disputed),
          { maximumFractionDigits: 0 },
        ),
      },
    };
  }

  const constructionBalance = {
    remainingGmp: {
      label: 'Balance to Complete Contract',
      amount: accountingNumberFormat(Number(remainingGmp), {
        maximumFractionDigits: 0,
      }),
      styles: { bold: true },
    },
    constructionCompletePercent: {
      label: 'Completed',
      amount: percentNumberFormat(constructionCompletePercent),
    },
  };

  const contingencyBalance = {
    remainingContingency: {
      label: 'Hard Cost Contingency Budget',
      amount: accountingNumberFormat(remainingContingency, {
        maximumFractionDigits: 0,
      }),
    },
    hcContingencyUsedPercent: {
      label: 'Used',
      amount: percentNumberFormat(hcContingencyUsedPercent),
    },
  };

  return {
    generalSummary,
    constructionBudgetSummary,
    changeEventsSummary,
    contingencySummary,
    constructionBalance,
    contingencyBalance,
  };
};

export const saveAsPDF = async ({
  contingencyChart,
  changeOrderEvents,
  changeOrders,
  reallocations,
  report,
}) => {
  // TODO: trick to remove amchart logo from charts. Remove it once we've bought a licence
  document.querySelectorAll('title').forEach((node) => {
    node.textContent.includes('amCharts') && node.parentNode.remove();
  });

  const contingencyImage = await contingencyChart.exporting.export('png', {
    scale: 2,
  });
  const logoUrl = getAbsoluteLogoPath({
    client: window.symmetreConfig.customer.subdomain,
    extension: 'png',
  });
  const logo = await fetchImage(logoUrl);
  // eslint-disable-next-line max-len
  const builder = initPDFBuilder({
    header: defaultHeader(report.legalEntityName, logo),
    content: [],
  });

  // First page - Summary & Graphs
  const summaryHeaders = [
    { key: 'label' },
    { key: 'amount', width: 100, alignment: 'right' },
  ];

  // eslint-disable-next-line max-len
  const itemFilterByStatus = (
    item,
    baseExcludedStatuses,
    projectedStatuses,
    internalStatuses = [],
  ) => {
    let excludedStatuses = baseExcludedStatuses;
    if (!report.summaryWithProjected) {
      excludedStatuses = [...excludedStatuses, ...projectedStatuses];
    }
    if (!report.summaryWithInternal) {
      excludedStatuses = [...excludedStatuses, ...internalStatuses];
    }
    return !excludedStatuses.includes(item.status);
  };

  const executiveSummaryReports = buildExecutiveSummaryReport(report);

  builder.doc.content.push({
    columns: [
      {
        stack: [
          {
            columns: [
              {
                stack: [builder.buildSubHeader('Construction Budget')],
              },
              {
                stack: [
                  {
                    text:
                      report.budgetUpdatedAt &&
                      `As of: ${formatUnixDate(
                        report.budgetUpdatedAt,
                        'MM-DD-YYYY',
                      )}`,
                    fontSize: 9,
                    color: '#C1C6C8',
                    margin: [114, 10, 0, 0],
                  },
                ],
              },
            ],
          },
          builder.buildSmallTable(
            summaryHeaders,
            executiveSummaryReports.generalSummary,
          ),
        ],
        width: '48%',
      },
      {
        stack: [
          {
            columns: [
              {
                stack: [builder.buildSubHeader('Change Events')],
              },
              {
                stack: [
                  {
                    text:
                      report.updatedAt &&
                      `As of: ${formatUnixDate(
                        report.updatedAt,
                        'MM-DD-YYYY',
                      )}`,
                    fontSize: 9,
                    color: '#C1C6C8',
                    margin: [129, 10, 0, 0],
                  },
                ],
              },
            ],
          },
          builder.buildSmallTable(
            summaryHeaders,
            executiveSummaryReports.changeEventsSummary,
          ),
        ],
        width: '52%',
      },
    ],
    columnGap: 10,
    margin: [0, 0, 0, 0],
  });

  builder.doc.content.push({
    columns: [
      {
        stack: [
          builder.buildSmallTable(
            summaryHeaders,
            executiveSummaryReports.constructionBudgetSummary,
          ),
        ],
        width: '48%',
      },
      {
        stack: [
          builder.buildSmallTable(
            summaryHeaders,
            executiveSummaryReports.contingencySummary,
          ),
        ],
        width: '52%',
      },
    ],
    columnGap: 10,
  });

  builder.doc.content.push({
    columns: [
      {
        stack: [
          builder.buildSmallTable(
            summaryHeaders,
            executiveSummaryReports.constructionBalance,
          ),
        ],
        width: '48%',
      },
      {
        stack: [
          builder.buildSmallTable(
            summaryHeaders,
            executiveSummaryReports.contingencyBalance,
          ),
          {
            text:
              'NOTE: Hard Cost Contingency values shown above include ' +
              'reallocations from other budget line items',
            fontSize: 9,
            italics: true,
            color: '#98A0A3',
            margin: [0, -15, 0, 0],
          },
        ],
        width: '52%',
      },
    ],
    columnGap: 10,
    margin: [0, 0, 0, 10],
  });

  builder.doc.content.push(
    builder.buildSubHeader('Hard Cost Contingency - Waterfall', {
      margin: [0, -10, 0, 3],
    }),
  );
  builder.addImage(contingencyImage, { margin: [45, 0, 5, 0], width: 700 });

  if (!window.symmetreConfig.currentUser.external) {
    builder.addPageBreak();

    // Second page - Change event logs
    builder.addHeader('Change Event Log');

    const changeEventStatusFilter = (item) => {
      const excluded = ['VOID', 'REJECTED'];
      const projected = [
        'PENDING-PROCEEDING',
        'PENDING-NOT PROCEEDING',
        'DRAFT',
      ];
      return itemFilterByStatus(item, excluded, projected);
    };

    const changeEventInternalFilter = (item) =>
      report.summaryWithInternal ? true : item.category !== 'IPC';

    builder.addTable(
      CE_HEADERS,
      changeOrderEvents
        .filter(changeEventStatusFilter)
        .filter(changeEventInternalFilter),
    );
    builder.addPageBreak();

    // Change Order Log
    builder.addHeader('Change Order Log');

    const changeOrderFilter = (item) =>
      itemFilterByStatus(item, [], ['PENDING-PROCEEDING', 'DRAFT']);

    builder.addTable(
      CO_HEADERS,
      changeOrders.filter(changeOrderFilter).sort(numberDesc),
    );

    builder.addPageBreak();

    // Reallocation Log
    builder.addHeader('Reallocation Log');

    builder.addTable(REALLOCATION_HEADERS, [...reallocations].sort(numberDesc));
  }
  return builder.print();
};

export const generateXLSX = async (legalEntityCode, params = {}) => {
  const query = stringify(params, { arrayFormat: 'brackets' });
  const { data } = await apiClient.get(
    `/api/reconcile/legal_entities/${legalEntityCode}/snapshots/report.xlsx?${query}`,
    {
      headers: { Authorization: `Bearer ${getToken()}` },
      responseType: 'blob',
    },
  );
  saveFileOnUserDevice(
    data,
    `${window.symmetreConfig.customer.subdomain}-construction-report.xlsx`,
  );
};
