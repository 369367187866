import React from 'react';
import { cn } from '@/shared/lib/css/cn';
import styles from '@/stories/UnitNum/unitNum.module.scss';

interface Props
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLElement>,
    HTMLElement
  > {
  required?: boolean;
  activated?: boolean;
}

export const UnitNum: React.FC<Props> = ({
  required,
  activated,
  children,
  className,
  ...props
}) => (
  <div
    aria-disabled={!activated}
    className={cn(styles.unitNum, className, {
      [styles.unitNum_required]: required,
      [styles.unitNum_activated]: activated,
    })}
    {...props}
  >
    {children}
  </div>
);

export default UnitNum;
