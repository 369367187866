/* eslint-disable max-len */
import React from 'react';

const HINTS = {
  category: {
    content: (
      <>
        COR – Requested changes formally submitted by the contractor
        <br />
        <br />
        PСO – Potential changes submitted by the contractor
        <br />
        <br />
        IPC – Forecasted or potential changes identified by internal teams
      </>
    ),
    visibleTrigger: true,
  },
  submittedByCompany: {
    content:
      'Select your company for Change Events originating from the Contractor',
  },
  submittedByContact: {
    content:
      'Enter or select the name of the person that is submitting the Change Event',
  },
  number: {
    content:
      'For contractor originated Change Events use Contractor assignet number',
  },
  contractorDate: {
    content: 'Enter date that is shown on the Contractor’s Event  document',
  },
  receivedDate: {
    content: (
      <>
        Enter date that notification of the Change Event was received from the
        Contractor
        <br />
        <br />
        This may be the same as the date shown on the Contractor’s Change Event
        document.
      </>
    ),
  },
  amount: {
    content: (
      <>
        Enter the current best estimate for the value <br />
        <br />
        If a value has been provided by the Contractor, that is the value that
        should be entered
      </>
    ),
  },
  sentToDesignerOfRecordDate: {
    content: (
      <>
        Enter date that change event was forwarded to the Designer of Record for
        validation&
        <br />
        <br />
        If date is nit known, enter the same date as the Received Date of the
        Change Event
      </>
    ),
  },
  probability: {
    content:
      'Enter probability of this Change Event based on current information. The $$ amount will be weighted by the persentage you enter within the Change Event Log',
  },
  reallocationJccId: {
    content:
      'Fund this Change Event from another categoty (outside of Hard Cost Category)',
  },
};

export default HINTS;
