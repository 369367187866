import { exportReportTable } from 'bundles/REport/actions';
import React, { useState } from 'react';
import { IPeriodItem } from 'stories/FlexibleFilterByPeriods/types';
import { Button } from 'stories/Button/Button';
import { Checkbox } from 'stories/Checkbox/Checkbox';
import { Modal } from 'stories/Modals/Modal/Modal';
import { LegalEntity } from '@/entities/core/legalEntity';
import { IReportTableConfig } from 'types/ReportTableConfig';

interface Settings {
  detailedBreakdown: boolean;
  subtotals: boolean;
  detailedReportHeader: boolean;
}

interface Props {
  onClose: () => void;
  reportTableConfig: IReportTableConfig;
  legalEntities: LegalEntity[];
  showUnpublished: boolean;
  periodItems: IPeriodItem[];
}

const mapSettingToLabel = {
  detailedBreakdown: 'Detailed breakdown (includes Account # and Name)',
  subtotals: 'Include subtotals',
  detailedReportHeader: 'Include detailed report header',
};

export const ExportModal = ({
  onClose,
  reportTableConfig,
  legalEntities,
  showUnpublished,
  periodItems,
}: Props) => {
  const [settings, setSettings] = useState<Settings>({
    detailedReportHeader: false,
    detailedBreakdown: false,
    subtotals: false,
  });
  const [isExporting, setIsExporting] = useState(false);

  const onExport = async () => {
    if (isExporting) return;

    setIsExporting(true);

    await exportReportTable(
      {
        legal_entity_ids: legalEntities.map((le) => le.id),
        report_table_config_id: reportTableConfig.id,
        show_unpublished: showUnpublished,
        periods: periodItems,
        with_detailed_breakdown: settings.detailedBreakdown ? true : undefined,
        with_subtotals: settings.subtotals ? true : undefined,
        with_detailed_report_header: settings.detailedReportHeader
          ? true
          : undefined,
      },
      reportTableConfig.name,
    );

    setIsExporting(false);
  };

  return (
    <Modal toggle={onClose} header="Export to Excel">
      <div className="flex flex-col gap-m">
        <div>
          {Object.keys(settings).map((setting) => (
            <div key={setting} className={setting == 'subtotals' ? 'ml-l' : ''}>
              <Checkbox
                checked={settings[setting as keyof Settings]}
                disabled={setting == 'subtotals' && !settings.detailedBreakdown}
                onChange={(e) => {
                  const updatedSettings = {
                    ...settings,
                    [setting]: e.target.checked,
                  };
                  if (setting == 'detailedBreakdown' && !e.target.checked) {
                    updatedSettings.subtotals = false;
                  }

                  setSettings(updatedSettings);
                }}
              >
                <div className="inline-regular">
                  {mapSettingToLabel[setting as keyof typeof mapSettingToLabel]}
                </div>
              </Checkbox>
            </div>
          ))}
        </div>
        <Button
          variant="success"
          size="m"
          onClick={onExport}
          disabled={isExporting}
        >
          {isExporting ? 'Exporting...' : 'Export'}
        </Button>
      </div>
    </Modal>
  );
};

export default ExportModal;
