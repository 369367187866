import { Badge } from 'stories/Badges/Badge/Badge';
import { CurrencyFormatter } from 'stories/ValueFormatters/CurrencyFormatter';
import { Icon } from 'stories/Icon/Icon';
import { IconButton } from 'stories/IconButton/IconButton';
import { Popover } from 'stories/Popover/Popover';
import { Tag } from 'stories/Tags/Tag/Tag';
import React from 'react';
import { formatUnixDate } from '@/shared/lib/formatting/dates';
import { REPORT_PRODUCT_NAME } from 'lib/permissions';
import { useCustomer } from 'lib/customers';
import AddItemFromList from 'bundles/Shared/components/AddItemFromList';
import UniversalFilterButton from 'bundles/Shared/components/Filters/buttons/universalFilterButton/UniversalFilterButton';
import { IUserWithAccessLevel } from 'bundles/UserManagement/components/EditUserModalComponents/types';
import { IColumn } from 'bundles/Shared/components/Table/types';
import { InvestmentEntity } from 'bundles/Settings/components/Portal/InvestmentEntities/api/investmentEntitiesApi';
import { CoreFiltersUsers } from 'bundles/UserManagement/api/settingsCoreLegalEntitiesApi';
import { VendorEntity } from 'bundles/Shared/entities/investmentEntities/model';

const onUserSelect = (user, settings, setSettings) => {
  const users = !settings.filters.users.some((v) => v.id === user.id)
    ? [...settings.filters.users, user]
    : [...settings.filters.users].filter(({ id }) => id !== user.id);

  setSettings({
    ...settings,
    filters: { ...settings.filters, users },
  });
};

const onVendorSelect = (vendor, settings, setSettings) => {
  const vendorEntities = !settings.filters.vendorEntities.some(
    (v) => v.id === vendor.id,
  )
    ? [...settings.filters.vendorEntities, vendor]
    : [...settings.filters.vendorEntities].filter(({ id }) => id !== vendor.id);

  setSettings({
    ...settings,
    filters: { ...settings.filters, vendorEntities },
  });
};

const onResetUserFilter = (settings, setSettings) => {
  setSettings({
    ...settings,
    filters: { ...settings.filters, users: [] },
  });
};

const onResetVendorEntities = (settings, setSettings) => {
  setSettings({
    ...settings,
    filters: { ...settings.filters, vendorEntities: [] },
  });
};

function hasObjects(inv: InvestmentEntity) {
  return inv.capitalAssets.length > 0 || inv.capitalFunds.length > 0;
}

function hasBothObjectTypes(inv: InvestmentEntity) {
  return inv.capitalAssets.length > 0 && inv.capitalFunds.length > 0;
}

function ObjectList({
  label,
  items,
}: {
  label: string;
  items: { id: number; name: string }[];
}) {
  return (
    <div className="flex flex-col gap-2 bg-white">
      <p className="font-semibold text-neutral-900">{label}</p>
      <div className="flex flex-col gap-1">
        {items.map((item) => (
          <div key={item.id} className="flex">
            {item.name}
          </div>
        ))}
      </div>
    </div>
  );
}

type Params = {
  actions: {
    edit: (item: InvestmentEntity) => void;
    destroy: (item: InvestmentEntity) => void;
    onUserClick: (user: IUserWithAccessLevel) => void;
  };
  userOptions: {
    users: CoreFiltersUsers[];
    isLoading: boolean;
  };
  vendorOptions: {
    vendorEntities: VendorEntity[];
  };
};

const investmentEntityColumns = ({
  actions,
  userOptions: { users, isLoading: isUsersLoading },
  vendorOptions: { vendorEntities },
}: Params): IColumn<InvestmentEntity>[] => [
  {
    dataField: 'name',
    text: 'Name',
    headerStyle: { width: '20%' },
    formatter: ({ row: investmentEntity }) => (
      <Popover
        trigger="click"
        hiddenArrow
        placement="bottom-start"
        maxWidth={420}
        template={
          hasObjects(investmentEntity) ? (
            <div className="flex">
              <div className="md grid grid-cols-[max-content_1px_max-content] gap-3">
                {investmentEntity.capitalAssets.length > 0 && (
                  <ObjectList
                    label="Assets"
                    items={investmentEntity.capitalAssets}
                  />
                )}
                {hasBothObjectTypes(investmentEntity) && (
                  <div className="w-1 bg-neutral-150" />
                )}
                {investmentEntity.capitalFunds.length > 0 && (
                  <ObjectList
                    label="Funds"
                    items={investmentEntity.capitalFunds}
                  />
                )}
              </div>
            </div>
          ) : (
            <div className="inline-regular flex text-neutral-900">
              No active investments
            </div>
          )
        }
      >
        <div className="flex cursor-pointer items-center gap-2">
          <Icon className="text-neutral-500" iconName="bag" />
          <span className="text-info-055 transition-colors hover:text-info-040">
            {investmentEntity.name}
          </span>
        </div>
      </Popover>
    ),
    sortable: true,
  },
  {
    dataField: 'users',
    text: 'Linked Members',
    headerStyle: { width: '16%' },
    formatter: ({ row: investmentEntity }) => (
      <div>
        {investmentEntity.users.length > 0 ? (
          <div className="flex flex-wrap gap-1">
            {investmentEntity.users.map((user) => (
              <Tag
                onClick={() => actions.onUserClick(user)}
                key={user.id}
                label={user.fullName}
              />
            ))}
          </div>
        ) : (
          <div className="inline-regular text-neutral-450">No Users Yet</div>
        )}
      </div>
    ),
    filter: (settings, setSettings) => (
      <div className="ml-s">
        <AddItemFromList
          className="p-0"
          allItems={users}
          selectedItems={settings.filters.users}
          onSelect={(user) => onUserSelect(user, settings, setSettings)}
          mask="users"
          getMainFieldTitle={(user) => user.fullName}
          getSecondaryFieldTitle={(user) => user.role.name}
          areItemsLoading={isUsersLoading}
        >
          <UniversalFilterButton
            filtered={settings.filters.users.length > 0}
            onClose={() => onResetUserFilter(settings, setSettings)}
          />
        </AddItemFromList>
      </div>
    ),
  },
  {
    dataField: 'total_contributions',
    text: 'Total Contributions',
    headerStyle: { width: '5%' },
    formatter: ({ row: investmentEntity }) => (
      <CurrencyFormatter value={investmentEntity.totalContributions} />
    ),
    sortable: true,
  },
  {
    dataField: 'total_distributions',
    text: 'Total Distributions',
    headerStyle: { width: '5%' },
    formatter: ({ row: investmentEntity }) => (
      <CurrencyFormatter value={investmentEntity.totalDistributions} />
    ),
    sortable: true,
  },
  {
    dataField: 'vendor_entities',
    text: 'Linked Vendors',
    headerStyle: { width: '16%' },
    hidden: !useCustomer().products?.includes(REPORT_PRODUCT_NAME),
    filter: (settings, setSettings) => (
      <div className="ml-s">
        <AddItemFromList
          className="p-0"
          allItems={vendorEntities}
          selectedItems={settings.filters.vendorEntities}
          onSelect={(vendor) => onVendorSelect(vendor, settings, setSettings)}
          mask="vendors"
          getMainFieldTitle={(vendor) => vendor.name}
          getSecondaryFieldTitle={(vendor) => vendor.code}
        >
          <UniversalFilterButton
            filtered={settings.filters.vendorEntities.length > 0}
            onClose={() => onResetVendorEntities(settings, setSettings)}
          />
        </AddItemFromList>
      </div>
    ),
    formatter: ({ row: investmentEntity }) => (
      <div>
        {investmentEntity.vendorEntities.length > 0 ? (
          <div className="flex flex-wrap gap-1">
            {investmentEntity.vendorEntities.map((vendor) => (
              <Tag key={vendor.id} label={vendor.name} />
            ))}
          </div>
        ) : (
          <div className="inline-regular text-neutral-450">No Vendors Yet</div>
        )}
      </div>
    ),
  },
  {
    dataField: 'user',
    text: 'Updated By',
    headerStyle: { width: '16%' },
    formatter: ({ row: investmentEntity }) => (
      <Tag label={investmentEntity.updatedBy.fullName} />
    ),
  },
  {
    dataField: 'updated_at',
    text: 'Updated At',
    headerStyle: { width: '16%' },
    formatter: ({ row: investmentEntity }) => (
      <div className="light-60">
        {formatUnixDate(investmentEntity.updatedAt, 'LL')}
      </div>
    ),
    sortable: true,
  },

  {
    dataField: 'actions',
    text: 'Actions',
    headerStyle: { width: '10%' },
    formatter: ({ row: investmentEntity }) => (
      <div className="flex items-center">
        <Badge
          className="mr-s cursor-pointer whitespace-nowrap"
          onClick={() => actions.edit(investmentEntity)}
          backgroundColor="var(--blue-light-4)"
          textColor="var(--bl)"
        >
          Edit
        </Badge>
        <IconButton
          className="mr-10"
          onClick={() => actions.destroy(investmentEntity)}
          disabled={!investmentEntity.canBeDeleted}
          tooltipProps={
            !investmentEntity.canBeDeleted
              ? {
                  mainText:
                    'This investment entity is linked to users or REturn and cannot be deleted',
                  placement: 'left',
                }
              : undefined
          }
          iconName="trash"
        />
      </div>
    ),
  },
];

export default investmentEntityColumns;
