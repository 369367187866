import { ReportDashboardType } from 'bundles/Shared/entities/dashboard/model/types/types';
import type { ReportDashboardSectionPosition } from 'bundles/Shared/entities/dashboard/model/types/types';

import type {
  ColumSizeWidgetState,
  DateWidgetState,
  DisplayedGroupsWidgetContext,
  DisplayedGroupsWidgetState,
} from 'bundles/Shared/widgets/dashboard/widgets/common/model';

import {
  DashboardWidgetCard,
  DashboardWidgetTableCard,
} from 'bundles/Shared/widgets/dashboard/widgets/common/ui/DashboardWidgetCard';
import { isWidgetSectionPositionWidthFullSize } from 'bundles/Shared/widgets/dashboard/widgets/common/lib/reportBuilder';
import { WidgetStateColumns } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/state/WidgetStateColumns';
import {
  showLegalEntitiesDropdownByWidgetContext,
  useFinancialTableWidgetAutoGroupColumDef,
  useFinancialTableWidgetColDef,
  useFinancialTableWidgetDefaultColDef,
  useFinancialTableWidgetRowData,
} from 'bundles/Shared/widgets/dashboard/widgets/common/financialTable/lib';
import { FinancialTableWidgetStateDate } from 'bundles/Shared/widgets/dashboard/widgets/common/financialTable/state/FinancialTableWidgetStateDate';
import { useWidgetFlags } from 'bundles/Shared/widgets/dashboard/widgets/common/lib/useWidgetFlags';
import {
  useWidgetFullScreen,
  WidgetStateFullScreenIconButton,
} from 'bundles/Shared/widgets/dashboard/widgets/common/ui/WidgetFullScreen';
import { WidgetStateLegalEntities } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/state/WidgetStateLegalEntities';
import { ExcelStyleBuilder } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/ColumnDefsBuilder';
import {
  WidgetTable,
  WidgetTablePlaceholder,
} from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/WidgetTable';
import { TableVizConfig } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/model';
import { FinancialTableSingeDateWidgetNS } from 'bundles/Shared/widgets/dashboard/widgets/financialTableSingeDate/model';
import {
  WidgetConfigProps,
  WidgetProps,
  WidgetStateProps,
} from 'bundles/Shared/widgets/dashboard/widgets/model';
import { LegalEntity } from '@/entities/core/legalEntity';
import { useAgGridRef, useLoadingOverlayEffect } from '@/lib/ag-grid/utils';
import { addOrRemoveItemInList } from '@/shared/lib/listHelpers/addOrRemoveItemInList';
import { IAsset } from '@/types/Asset';
import { useObjectDashboardWidgetTableExport } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/useTableWidgetExportFeature';
import { useRef } from 'react';
import {
  useFinancialWidgetColumnsVisibilityState,
  useWidgetTableColumnSize,
} from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/table/tableStateSync';
import { useModal } from '@/shared/lib/hooks/useModal';

type FinancialTableSingleDateWidgetProps = {
  id: string;
  position: ReportDashboardSectionPosition;
  widgetType: 'financial_table_single_date';
  title: string;
  widgetConfig: {
    viz_config: TableVizConfig;
  };
  fontSize?: number | null;
};

export type FinancialTableSingeDateWidgetContext =
  DisplayedGroupsWidgetContext & {
    assets?: IAsset[];
  };
export type FinancialTableSingeDateWidgetState = DateWidgetState &
  DisplayedGroupsWidgetState &
  ColumSizeWidgetState & {
    excludedLegalEntityIds?: LegalEntity['id'][];
  };

export const FinancialTableSingeDateWidget = (
  props: WidgetProps<
    FinancialTableSingeDateWidgetNS.SnapshotData,
    FinancialTableSingleDateWidgetProps
  > &
    WidgetStateProps<FinancialTableSingeDateWidgetState> &
    WidgetConfigProps,
) => {
  const {
    data,
    mode,
    isLoading,
    isFetching,
    widgetSection,
    state,
    onStateChange,
    dashboardType,
    context,
  } = props;
  const { openModal } = useModal();
  const { shouldDisplayPlaceholder, shouldDisplayData } = useWidgetFlags(props);

  const wrapperDivRef = useRef<HTMLDivElement>(null);
  const gridRef = useAgGridRef();

  const widgetStateFullScreenProps = useWidgetFullScreen(wrapperDivRef);

  useLoadingOverlayEffect({
    isLoading: isFetching,
    grid: gridRef.current,
  });

  const columnSizeFeature = useWidgetTableColumnSize({
    gridRef,
    state,
    onStateChange,
  });

  const vizConfig = widgetSection.widgetConfig.viz_config;
  const headerBackground = vizConfig?.header_background;
  const hideHeader = data?.hideHeader ?? false;
  const widgetSectionFontSize = widgetSection.fontSize ?? undefined;
  const excludedLegalEntityIds = state?.excludedLegalEntityIds ?? [];

  const columnVisibilityFeature = useFinancialWidgetColumnsVisibilityState({
    data,
    grid: gridRef.current,
    state,
    onStateChange,
  });

  const exportFeature = useObjectDashboardWidgetTableExport({
    mode,
    gridRef,
    widgetTitle: widgetSection.title,
    widgetId: widgetSection.id,
    state,
    context,
  });

  const autoColumnDef = useFinancialTableWidgetAutoGroupColumDef({
    headerBackground,
    widgetSectionFontSize,
    data,
    cellClass: exportFeature.autoGroupColumnDef.cellClass,
  });

  const columnDefs = useFinancialTableWidgetColDef({
    data,
    mode,
    widgetSection,
    vizConfig,
    dashboardType,
  });

  const defaultColDef = useFinancialTableWidgetDefaultColDef({
    isCompactSelected: state.selectedColumnSize === 'compact',
  });

  const rowData = useFinancialTableWidgetRowData({
    data,
  });
  const excelStyleBuilder = new ExcelStyleBuilder({
    vizConfig,
    mode,
  });
  const excelStyles = excelStyleBuilder
    .withIndentationStyles()
    .withSource('columns-rows')
    .buildExcelStyles();

  return (
    <DashboardWidgetTableCard {...props} ref={wrapperDivRef}>
      {mode !== 'pdf' && (
        <DashboardWidgetCard.Header className="justify-normal gap-4">
          <DashboardWidgetCard.Header.Title>
            {widgetSection.title}
          </DashboardWidgetCard.Header.Title>
          <div className="grow" />
          <FinancialTableWidgetStateDate
            state={state}
            onStateChange={onStateChange}
          />
          {!widgetStateFullScreenProps.isFullscreen &&
            context.columnVisibilityEnabled && (
              <WidgetStateColumns
                onColumnSizeChange={columnSizeFeature.handleColumnSizeChange}
                isDataLoading={Boolean(isLoading)}
                state={state}
                onColumnStateChange={columnVisibilityFeature.onChange}
                vizConfig={vizConfig}
                columns={data?.columns ?? []}
              />
            )}
          {showLegalEntitiesDropdownByWidgetContext({
            ...context,
            dashboardType,
          }) && (
            <WidgetStateLegalEntities
              context={context}
              state={state}
              onStateChange={(leId) => {
                onStateChange({
                  excludedLegalEntityIds: addOrRemoveItemInList(
                    leId,
                    excludedLegalEntityIds,
                  ),
                });
              }}
            />
          )}
          <exportFeature.ExportButtonComponent />
          <WidgetStateFullScreenIconButton {...widgetStateFullScreenProps} />
        </DashboardWidgetCard.Header>
      )}
      {mode === 'pdf' &&
        widgetSection.title &&
        isWidgetSectionPositionWidthFullSize(widgetSection.position) && (
          <DashboardWidgetCard.PDFHeader>
            {widgetSection.title}
          </DashboardWidgetCard.PDFHeader>
        )}
      {shouldDisplayPlaceholder && <WidgetTablePlaceholder />}
      {shouldDisplayData && (
        <WidgetTable
          context={{
            ...context,
            mode,
            openModal,
          }}
          defaultColGroupDef={undefined}
          domLayout={
            dashboardType === ReportDashboardType.OBJECT || mode === 'pdf'
              ? 'autoHeight'
              : 'normal'
          }
          onFirstDataRendered={columnVisibilityFeature.onFirstDataRendered}
          ref={gridRef}
          headerBackground={headerBackground}
          hideHeader={hideHeader}
          mode={mode}
          columnDefs={columnDefs}
          autoGroupColumnDef={autoColumnDef}
          rowData={rowData}
          defaultColDef={defaultColDef}
          excelStyles={excelStyles}
          expensivePropSuppressColumnVirtualisation
          defaultParams={{
            sizeColumnsToFit: true,
          }}
          onRowDataUpdated={columnSizeFeature.handleRowDataUpdated}
        />
      )}
    </DashboardWidgetTableCard>
  );
};
