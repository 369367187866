import type {
  ReportBuilderEagleEyeTemplateDto,
  ReportBuilderTemplateDto,
} from '@/entities/report/reportBuilder/api/settingsReportBuilderTemplatesGeneratedApi';
import { useAssertNullableContext } from '@/shared/lib/hooks/context/useAssertNullableContext';
import { createContext } from 'react';

type Widget =
  | ReportBuilderEagleEyeTemplateDto['groups'][number]['widgetSections'][number]
  | ReportBuilderTemplateDto['groups'][number]['widgetSections'][number];

type ReportBuilderTemplateWidgetContextType = Widget;

export const ReportBuilderTemplateWidgetContext =
  createContext<ReportBuilderTemplateWidgetContextType | null>(null);

export const useReportBuilderTemplateWidgetContext = useAssertNullableContext(
  ReportBuilderTemplateWidgetContext,
);
