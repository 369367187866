import {
  FieldType,
  generateTypeFormItem,
} from 'bundles/Shared/components/GroupForm/types/types';
import { IGeneralLedger } from 'types/GeneralLedger';

export const getJCCItems = (
  generalLedgers?: IGeneralLedger[],
  indiviualSettings?: boolean,
) => {
  const optionsGL = generalLedgers?.map((gl) => ({
    value: gl.id,
    label: `${gl.accNumber} - ${gl.accTitle}`,
  }));
  return {
    title: '',
    formItems: [
      ...(!indiviualSettings
        ? [
            generateTypeFormItem<FieldType.INPUT_TEXT>({
              fieldName: 'name',
              type: FieldType.INPUT_TEXT,
              title: 'Name',
              placeholder: 'Enter name',
              required: true,
              styles: {
                wrapper: 'w-full form-item_white',
              },
            }),
            generateTypeFormItem<FieldType.INPUT_TEXT>({
              fieldName: 'code',
              type: FieldType.INPUT_TEXT,
              title: 'Code',
              placeholder: 'Enter code',
              required: true,
              styles: {
                wrapper: 'w-full form-item_white mb-6',
              },
            }),
          ]
        : []),
      ...(indiviualSettings && optionsGL
        ? [
            generateTypeFormItem<FieldType.SELECT>({
              fieldName: 'general_ledgers',
              type: FieldType.SELECT,
              title: 'General Ledgers',
              required: true,
              options: optionsGL,
              styles: {
                wrapper: 'w-full mb-6',
                input: 'white',
              },
              isMulti: true,
              shortList: true,
            }),
          ]
        : []),
    ],
  };
};
