import { BuilderUI } from '@/shared/ui/BuilderUI';
import React from 'react';
import { AnimationLoader } from 'stories/AnimationLoader/AnimationLoader';
import { IconButton } from 'stories/IconButton/IconButton';

interface Props {
  children: React.ReactNode;
  title?: React.ReactNode;
  description?: string;
  isLoading?: boolean;
  onClose?: () => void;
  onMoveUp?: () => void;
  onMoveDown?: () => void;
}

const SHOW_MOVING_BUTTONS = false;

const EmailTemplateFormInputGroup = ({
  title,
  description,
  children,
  isLoading,
  onClose,
  onMoveUp,
  onMoveDown,
}: Props) => (
  <BuilderUI.Settings.WidgetLayout.WidgetGroup className="flex-col gap-4 p-4">
    {(title ?? SHOW_MOVING_BUTTONS ?? onMoveUp ?? onMoveDown ?? onClose) && (
      <div className="flex justify-between">
        <span className="text-uppercase font-weight-700 text-[1rem]">
          {title}
        </span>
        <div className="flex items-center gap-2">
          {SHOW_MOVING_BUTTONS && onMoveDown && (
            <IconButton iconName="bottom" onClick={onMoveDown} />
          )}
          {SHOW_MOVING_BUTTONS && onMoveUp && (
            <IconButton iconName="top" onClick={onMoveUp} />
          )}
          {onClose && <IconButton iconName="trash" onClick={onClose} />}
        </div>
      </div>
    )}
    <div className="flex flex-col">
      {description && (
        <span className="font-weight-400 light-60 secondary-regular mb-4">
          {description}
        </span>
      )}
      {isLoading && <AnimationLoader className="static min-h-[200px]" />}
      {!isLoading && children}
    </div>
  </BuilderUI.Settings.WidgetLayout.WidgetGroup>
);

export default EmailTemplateFormInputGroup;
