import { asserts } from '@/lib/typeHelpers/assertsType';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import { SendDataButton } from 'bundles/DrawPackage/components/SendDataButton/SendDataButton';
import { SharedInput } from 'bundles/Shared/components/GroupForm/FormItems/new/SharedInput';
import { IConnection } from 'bundles/Shared/entities/connection/model';
import { usePutApiPropertyManagementCompaniesMutation } from '@/entities/propertyManagementCompany/api/settingPropertyManagementCompanyApi';
import { IPropertyManagementCompany } from '@/entities/propertyManagementCompany/model';
import { FC, useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button } from 'stories/Button/Button';
import { Modal } from 'stories/Modals/Modal/Modal';
import Field, { FieldsContainer } from 'stories/Field/Field';
import { useConnectionById } from '@/bundles/Shared/entities/connection/api';

interface IConnectionModalProps extends DialogProps {
  connectionId?: IConnection['id'];
}

export type EditablePMCProps = Pick<IPropertyManagementCompany, 'keyInfo'>;

const PMCModal: FC<IConnectionModalProps> = ({ onClose, connectionId }) => {
  const { data: connectionData, refetch: refetchConnection } =
    useConnectionById(connectionId!, {
      skip: !connectionId,
    });

  const connection = connectionData?.item as IConnection | undefined;

  const [
    updatePMC,
    { isLoading: isLoadingUpdate, isSuccess: isSuccessUpdating },
  ] = usePutApiPropertyManagementCompaniesMutation();

  if (isSuccessUpdating) {
    onClose();
    refetchConnection();
  }

  const resolver = useCallback((values: EditablePMCProps) => {
    let errors: Partial<EditablePMCProps> & { address?: string } = {};

    const keyInfoFieldFill = Object.keys(values.keyInfo)
      .filter((item) => item !== 'id')
      .find((key) => values.keyInfo[key as keyof typeof values.keyInfo]);

    if (keyInfoFieldFill) {
      errors = {};
    } else {
      errors.address = 'Field is required';
    }

    return {
      values,
      errors,
    };
  }, []);

  const { keyInfo } = connection?.company ?? {};

  const {
    control,
    getValues,
    formState: { isValid },
  } = useForm<EditablePMCProps>({
    mode: 'onChange',
    defaultValues: {
      keyInfo,
    },
    resolver,
  });

  const onSubmit = () => {
    const values = getValues();
    asserts(connection);
    updatePMC({
      id: connection.company.id,
      body: {
        key_info_attributes: {
          id: connection?.company?.keyInfo?.id,
          ...values?.keyInfo,
        },
      },
    });
  };

  return (
    <Modal
      toggle={onClose}
      header="Company Info"
      classes={{
        body: 'bg-neutral-100',
      }}
      actions={
        <>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <SendDataButton
            variant="success"
            onClick={onSubmit}
            isLoading={isLoadingUpdate}
            disabled={!isValid || isLoadingUpdate}
          >
            {connectionId ? 'Update' : 'Add'} Company Info
          </SendDataButton>
        </>
      }
    >
      <FieldsContainer>
        <div className="flex flex-col gap-4">
          <div className="inline-semibold uppercase">Key Info</div>
          <Field labelText="Address" newGen>
            <Controller
              control={control}
              name="keyInfo.address"
              render={({ field }) => (
                <SharedInput<IPropertyManagementCompany['keyInfo']['address']>
                  value={field.value}
                  onChange={field.onChange}
                  placeholder="Enter Address"
                  newGen
                />
              )}
            />
          </Field>
          <Field labelText="Site" newGen>
            <Controller
              control={control}
              name="keyInfo.site"
              render={({ field }) => (
                <SharedInput<IPropertyManagementCompany['keyInfo']['site']>
                  value={field.value}
                  onChange={field.onChange}
                  placeholder="https://"
                  newGen
                />
              )}
            />
          </Field>
          <Field labelText="Email" newGen>
            <Controller
              control={control}
              name="keyInfo.email"
              render={({ field }) => (
                <SharedInput<IPropertyManagementCompany['keyInfo']['email']>
                  value={field.value}
                  onChange={field.onChange}
                  placeholder="Enter email"
                  type="email"
                  newGen
                />
              )}
            />
          </Field>
          <Field labelText="Phone" newGen>
            <Controller
              control={control}
              name="keyInfo.phone"
              render={({ field }) => (
                <SharedInput<IPropertyManagementCompany['keyInfo']['phone']>
                  value={field.value}
                  onChange={field.onChange}
                  placeholder="+1"
                  newGen
                />
              )}
            />
          </Field>
        </div>
      </FieldsContainer>
    </Modal>
  );
};

export default PMCModal;
