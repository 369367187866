import React, { FC, ReactNode } from 'react';
import { Button } from 'stories/Button/Button';
import { Icon } from 'stories/Icon/Icon';
import { IButton } from 'stories/Button/Button';
import { IconsId } from 'types/sre-icons';
import { cn } from '@/shared/lib/css/cn';

interface ISendDataButtonProps extends IButton {
  isLoading: boolean;
  children: ReactNode;
  iconLoaderPosition?: 'left' | 'right';
  disappearIconName?: IconsId;
}

export const SendDataButton: FC<ISendDataButtonProps> = ({
  children,
  isLoading,
  disappearIconName,
  ...props
}) => (
  <Button {...props}>
    <div
      className={cn('flex gap-2', {
        ['flex-row-reverse']: props?.iconLoaderPosition === 'right',
      })}
    >
      {!isLoading && disappearIconName && (
        <Icon
          className={
            isLoading || props.disabled ? 'text-light-60' : 'text-white'
          }
          iconName={disappearIconName}
        />
      )}
      {isLoading && (
        <Icon className="form-button-loading text-light-60" iconName="sync" />
      )}
      <div
        className={isLoading || props.disabled ? 'text-light-60' : 'text-white'}
      >
        {children}
      </div>
    </div>
  </Button>
);
