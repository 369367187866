import { cn } from '@/shared/lib/css/cn';
import useBoolean from '@/shared/lib/hooks/useBoolean';
import { useCounter } from '@/shared/lib/hooks/useCounter';
import { useEventListener } from '@/shared/lib/hooks/useEventListener';
import { navigate } from '@reach/router';
import useOutsideClick from '@/shared/lib/hooks/useOutsideClick';
import { useRef, useState } from 'react';
import { useSpotlightGroupedResults } from '@/features/spotlight';
import { SearchInput } from '@/stories/FormControls/Inputs/SearchInput/SearchInput';
import onUserImpersonation from '@/shared/lib/mirrorMode';

export const Spotlight = () => {
  const {
    setFalse: closeSpotlight,
    toggle: toggleSpotlight,
    value: openedSpotlight,
  } = useBoolean();
  const [query, setQuery] = useState('');
  const searchRef = useRef(null);
  const { count: focusedIndex, decrement, increment, reset } = useCounter();

  const { groups, maxIndex, mapOfResults } = useSpotlightGroupedResults({
    query,
  });

  useOutsideClick(searchRef, closeSpotlight);

  const handleMirrorMode = async (userId: string) => {
    await onUserImpersonation(userId);
    window.location.href = '/';
  };

  const handleNavigateAssets = (slug: string) => navigate(`/assets/${slug}`);
  const handleNavigateFunds = (slug: string) => navigate(`/funds/${slug}`);
  const handleNavigateExternalLink = (url: string) =>
    window.location.replace(url);
  const handleNavigateInternalLink = (url: string) => navigate(url);

  const onClick = (data: any) => {
    if (data.category === 'mirror_mode') handleMirrorMode(data.data.id);
    if (data.category === 'assets') handleNavigateAssets(data.data.slug);
    if (data.category === 'funds') handleNavigateFunds(data.data.slug);
    if (data.category == 'customers') handleNavigateExternalLink(data.data.url);
    if (data.category == 'settings') handleNavigateInternalLink(data.url);
    if (data.category == 'return') {
      handleNavigateInternalLink(
        `/return/${data?.data?.id ? data.data.id : ''}`,
      );
    }

    closeSpotlight();
  };

  const handleOpenFocusedItem = (event: KeyboardEvent) => {
    if (event.key === 'Enter' && openedSpotlight) {
      onClick(mapOfResults.get(focusedIndex));
    }
  };

  const handleExitSpotlight = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      event.preventDefault();
      closeSpotlight();
      reset();
      setQuery('');
    }
  };

  const handleToggleSpotlight = (event: KeyboardEvent) => {
    if (event.shiftKey && event.key === 'Enter') toggleSpotlight();
  };

  const scrollIntoFocused = () => {
    const element = document.querySelector('.spotlight-result-item.focused');

    element?.scrollIntoView({ block: 'center' });
  };

  const handleArrowUp = (ev: KeyboardEvent) => {
    if (ev.key === 'ArrowUp') {
      scrollIntoFocused();
      decrement(0);
    }
  };
  const handleArrowDown = (ev: KeyboardEvent) => {
    if (ev.key === 'ArrowDown') {
      scrollIntoFocused();
      increment(maxIndex);
    }
  };

  useEventListener('keydown', handleExitSpotlight);
  useEventListener('keydown', handleOpenFocusedItem);
  useEventListener('keydown', handleArrowUp);
  useEventListener('keydown', handleArrowDown);
  useEventListener('keydown', handleToggleSpotlight);

  const handleQuery = (q: string) => {
    setQuery(q);
    reset();
  };

  if (!openedSpotlight) return null;

  return (
    <div className="spotlight" ref={searchRef}>
      <SearchInput
        className="flex-grow search-input"
        inputClassName="border-0 focus:border-none"
        size="l"
        placeholder="Search for mirror mode users, assets, customers..."
        value={query}
        onChange={(e) => handleQuery(e.target.value)}
        autoFocus
      />
      <div className="spotlight-result-container">
        <div className="spotlight-result">
          {groups.length === 0 && (
            <div className="spotlight-no-results">
              Nothing found for <strong>{query}</strong>
            </div>
          )}
          {groups.map(([category, values]) => (
            <div className="spotlight-result-category" key={category}>
              <div className="spotlight-result-category-title">
                {category.replace('_', ' ').toUpperCase()}
              </div>
              <div className="spotlight-result-category-items">
                {values.map((result) => (
                  <div
                    key={`category:${category}_label:${result.label}`}
                    className={cn('spotlight-result-item', {
                      focused: result.indexInGroups === focusedIndex,
                    })}
                    onClick={() => onClick(result)}
                  >
                    {result.label}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
