import React, { useMemo, useState } from 'react';
import { Popover } from '@/stories/Popover/Popover';
import { SearchInput } from '@/stories/FormControls/Inputs/SearchInput/SearchInput';
import { cn } from '@/shared/lib/css/cn';
import {
  IInvestmentObject,
  TInvestmentObjectClass,
} from 'types/IInvestmentObject';
import SplittedCheckList from 'stories/Checkbox/SplittedCheckList';
import { ListOption } from 'stories/Checkbox/CheckList';
import { mapToListOption } from '@/bundles/REport/components/financials/filter/utils';

interface Props {
  investmentObjects: IInvestmentObject[];
  selectedInvestmentObjects: IInvestmentObject[];
  onSelectedInvestmentObjectsChange: (
    selectedInvestmentObjects: IInvestmentObject[],
  ) => void;
  withoutPopover?: boolean;
  maxHeight?: string | number;
}

const DEFAULT_MAX_HEIGHT = 350;

function InvestmentObjectSelect({
  selectedInvestmentObjects,
  investmentObjects,
  onSelectedInvestmentObjectsChange,
  withoutPopover,
  maxHeight = DEFAULT_MAX_HEIGHT,
}: Props) {
  const [filterText, setFilterText] = useState('');

  const selectedFunds = useMemo(
    () => selectedInvestmentObjects.filter((o) => o.klass === 'Fund'),
    [selectedInvestmentObjects],
  );

  const selectedAssets = useMemo(
    () => selectedInvestmentObjects.filter((o) => o.klass === 'Asset'),
    [selectedInvestmentObjects],
  );

  const filteredInvestmentObjects = useMemo(
    () =>
      investmentObjects.filter((o) =>
        o.name.toLowerCase().includes(filterText.toLowerCase()),
      ),
    [investmentObjects, filterText],
  );

  const filteredAssets = useMemo<ListOption[]>(
    () =>
      filteredInvestmentObjects
        .filter((o) => o.klass === 'Asset')
        .map(mapToListOption),
    [filteredInvestmentObjects],
  );

  const filteredFunds = useMemo<ListOption[]>(
    () =>
      filteredInvestmentObjects
        .filter((o) => o.klass === 'Fund')
        .map(mapToListOption),
    [filteredInvestmentObjects],
  );

  const handleInvestmentObjectCheckedItemsChanged = (
    objectClass: TInvestmentObjectClass,
    items: ListOption[],
  ) => {
    const newSelectedInvestmentObjects = [
      ...selectedInvestmentObjects.filter((o) => o.klass !== objectClass),
      ...items.map(
        (item) => investmentObjects.find((o) => o.uniqId === item.value)!,
      ),
    ];

    onSelectedInvestmentObjectsChange(newSelectedInvestmentObjects);
  };

  const lists = (
    <div
      className={cn('flex w-[31.25rem] gap-2 overflow-auto', {
        'mt-s': withoutPopover,
      })}
      style={{ maxHeight: `${maxHeight}px` }}
    >
      <div
        className={cn('w-1/2 border-r border-light-20', {
          'pl-s': withoutPopover,
        })}
      >
        <p className="sticky top-0 z-10 mb-2 bg-white font-semibold">Assets</p>
        <SplittedCheckList
          value={selectedAssets.map(mapToListOption)}
          onChange={(items) =>
            handleInvestmentObjectCheckedItemsChanged('Asset', items)
          }
          items={filteredAssets}
        />
      </div>
      <div className="w-1/2">
        <p className="sticky top-0 z-10 mb-2 bg-white font-semibold">Funds</p>
        <SplittedCheckList
          value={selectedFunds.map(mapToListOption)}
          onChange={(items) =>
            handleInvestmentObjectCheckedItemsChanged('Fund', items)
          }
          items={filteredFunds}
        />
      </div>
    </div>
  );

  const input = (
    <SearchInput
      onChange={(e) => setFilterText(e.target.value)}
      placeholder="Assets & Funds"
      value={filterText}
      resetValue={() => setFilterText('')}
      size="s"
    />
  );

  return !withoutPopover ? (
    <div className="flex flex-col">
      <Popover
        maxWidth="auto"
        placement="bottom-start"
        offset={[0, 2]}
        hiddenArrow
        trigger="click"
        template={lists}
      >
        {input}
      </Popover>
    </div>
  ) : (
    <div className="flex flex-col overflow-auto">
      {input}
      {lists}
    </div>
  );
}

export default InvestmentObjectSelect;
