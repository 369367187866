import * as React from 'react';
import { Icon } from 'stories/Icon/Icon';
import { cn } from '@/shared/lib/css/cn';
import { capitalize } from 'lodash-es';
import { GeneralLedgerType } from 'types/GeneralLedger';
import { formatAmount } from '@/shared/lib/formatting/number';
import { getExpenseTypeByGlType } from '@/entities/accounting/transaction/lib';
import { IconsId } from 'types/sre-icons';

interface Props {
  debit: number;
  credit: number;
  generalLedgerType: GeneralLedgerType | null | undefined;
}

export function TransactionTypeCell({
  debit,
  credit,
  generalLedgerType,
}: Props) {
  const transactionType = credit > 0 ? 'credit' : 'debit';
  const amount = formatAmount(transactionType === 'debit' ? debit : credit);

  const getIconColors = () => {
    if (generalLedgerType == null) {
      return 'bg-light-30 light-90';
    }
    return getExpenseTypeByGlType(transactionType, generalLedgerType) ===
      'income'
      ? 'bg-green-light-5 green'
      : 'bg-red-light-5 red';
  };

  const getIconName = (): IconsId => {
    if (generalLedgerType === null) {
      return 'question';
    }
    return getExpenseTypeByGlType(transactionType, generalLedgerType) ===
      'income'
      ? 'debit'
      : 'credit';
  };

  return (
    <div className="flex justify-between">
      <div className="flex items-center">
        <Icon
          className={cn('mr-s', getIconColors())}
          padding="xs"
          iconName={getIconName()}
        />
        <span className="light-60">{capitalize(transactionType)}</span>
      </div>
      <span>{amount}</span>
    </div>
  );
}
