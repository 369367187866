import Banner from '@/bundles/Settings/components/Portal/shared/ui/Banner';
import useBoolean from '@/shared/lib/hooks/useBoolean';
import { Icon } from '@/stories/Icon/Icon';
import { IconButton } from '@/stories/IconButton/IconButton';

export const ReportBuilderTemplateInUseBanner = () => {
  const { value, setFalse } = useBoolean(true);

  if (!value) return null;

  return (
    <Banner className="flex items-center gap-2">
      <Icon iconName="info" className="text-attention-090" />
      <p className="secondary-regular">
        This template is used. Any changes made to this template will be applied
        to all related reports in Draft status
      </p>
      <IconButton
        onClick={() => setFalse()}
        iconName="close"
        variant="ghost"
        size="xs"
        isDarkText
      />
    </Banner>
  );
};
