import React from 'react';
import { TUserInviteStatus } from 'types/User';
import getBadgeColor from '@/bundles/UserManagement/components/helpers/getBadgeColor';

interface IStatistics {
  active: number;
  created: number;
  deactivated: number;
  invited: number;
  invited_long_time_ago: number;
}

interface Props {
  total: number;
  status: TUserInviteStatus;
  statistics: IStatistics;
}

export function StatisticsVerticalLine({ status, statistics, total }: Props) {
  const barPercentage = (s: TUserInviteStatus) => {
    const percentage = () => {
      if (total > 0) return (statistics[s] / total) * 100 || 0;
      return 0;
    };
    return {
      colored: `${percentage()}%`,
      background: `${100 - percentage()}%`,
    };
  };

  return (
    <div
      className="flex w-[1rem] flex-col items-center secondary-regular weight-500 mx-s text-center"
      style={{ color: getBadgeColor(status) }}
      key={status}
    >
      <div
        className="h-45p um-statistics-bar"
        style={{
          height: '100%',
          backgroundColor: 'var(--light-30)',
          borderRadius: 'var(--spacing-xs)',
        }}
      >
        <div
          style={{
            height: barPercentage(status).background,
            backgroundColor: 'var(--light-30)',
            borderRadius: 'var(--spacing-xs)',
          }}
        />
        <div
          style={{
            height: barPercentage(status).colored,
            backgroundColor: getBadgeColor(status),
            borderRadius: '0 0 var(--spacing-xs) var(--spacing-xs)',
          }}
        />
      </div>
      {statistics[status] || '0'}
    </div>
  );
}
