import React from 'react';
import { cn } from '@/shared/lib/css/cn';
import styles from '@/stories/UnitQuestionAnswer/unitQuestionAnswer.module.scss';

interface Props
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  variant: 'yes' | 'no';
  chosen?: boolean;
}

export const UnitQuestionAnswer = ({
  variant,
  className,
  chosen,
  ...props
}: Props) => (
  <button
    className={cn(
      styles.unitQuestionAnswer,
      styles[`unitQuestionAnswer_${variant}`],
      className,
      {
        [styles.unitQuestionAnswer_chosen]: chosen,
      },
    )}
    type="button"
    {...props}
  >
    {variant.charAt(0).toUpperCase() + variant.slice(1)}
  </button>
);

export const YesUnitQuestionAnswer = (props: Omit<Props, 'variant'>) => (
  <UnitQuestionAnswer variant="yes" {...props} />
);

export const NoUnitQuestionAnswer = (props: Omit<Props, 'variant'>) => (
  <UnitQuestionAnswer variant="no" {...props} />
);

export default UnitQuestionAnswer;
