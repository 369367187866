import { IUserRole } from 'types/User';
import { uniqueId } from 'lodash-es';
import pluralize from 'pluralize';
import { PeriodsLimitationsValue } from '@/bundles/Settings/components/REport/TableBuilder/PeriodLimitation/PeriodLimitationModal';
import { IPermissionRuleState } from '@/bundles/Settings/components/REport/TableBuilder/types';
import {
  DEFAULT_PERIOD_LIMITATION_RULE_ID,
  DEFAULT_PERIOD_LIMITATION_RULE_LABEL,
} from '@/bundles/Settings/components/REport/TableBuilder/helpers/constants';

export const createId = () => uniqueId('period-limitation-rule-');

export const createRulesStateFromLimitation = (
  lim: PeriodsLimitationsValue = {},
): IPermissionRuleState[] => {
  const { ...rules } = lim;
  const entriesIdAndMonth = Object.entries({ ...rules });
  return entriesIdAndMonth.map(([userRoleId, limit]) => ({
    id: createId(),
    userRoleId,
    limit,
  }));
};

export function findRuleAndIndex<T extends { id: string }>(
  rules: T[],
  ruleId: T['id'],
): [T | undefined, number | null] {
  let ruleIndex: number | null = null;
  const ruleToUpdate = rules.find((rule, idx) => {
    const fits = rule.id === ruleId;
    if (fits) {
      ruleIndex = idx;
    }
    return fits;
  });
  return [ruleToUpdate, ruleIndex];
}

export function insertWithoutBreakingOrder<T>(
  rules: T[],
  item: T,
  itemIdx: number,
) {
  if (itemIdx >= rules.length) return rules;

  const start = rules.slice(0, itemIdx);
  const end = rules.slice(itemIdx + 1);

  return [...start, item, ...end];
}

export function updatedRules<T extends { id: string }>(
  rules: T[],
  ruleId: T['id'],
  keyToUpdate: keyof Omit<T, 'id'>,
  value: number | string | null,
) {
  const [ruleToUpdate, ruleIndex] = findRuleAndIndex(rules, ruleId);

  if (ruleToUpdate === undefined || ruleIndex === null) {
    return rules;
  }

  const updatedRule = {
    ...ruleToUpdate,
    [keyToUpdate]: value,
  };

  return insertWithoutBreakingOrder(rules, updatedRule, ruleIndex);
}

export const preparePeriodLimitationRules = (rules: IPermissionRuleState[]) => {
  const entries = rules.map((rule) => [rule.userRoleId, rule.limit]);
  const res: Record<string, number | null> = Object.fromEntries(entries);
  return res;
};

export const getUserRoleNameById = (roles: IUserRole[], id: IUserRole['id']) =>
  roles.find((role) => role.id === id)?.name ?? null;

export const resolvePeriodLimitText = (limit: number | null) => {
  if (limit === null || limit === 0) return 'No limits';
  return `${limit} ${pluralize('months', limit)}`;
};

export const resolvePeriodLimitRoleLabel = (
  userRoles: IUserRole[],
  rule: IPermissionRuleState,
) => {
  if (rule.userRoleId === DEFAULT_PERIOD_LIMITATION_RULE_ID) {
    return DEFAULT_PERIOD_LIMITATION_RULE_LABEL;
  }
  return getUserRoleNameById(userRoles, rule.userRoleId) ?? '';
};
