/* eslint-disable @typescript-eslint/no-explicit-any */
import { cn } from '@/shared/lib/css/cn';
import { ReactComponent as InnerLine } from 'images/treeConnectors/innerLine.svg';
import { ReactComponent as LastLine } from 'images/treeConnectors/lastLine.svg';
import { ReactComponent as LongLine } from 'images/treeConnectors/longLine.svg';
import { getConnectorType } from 'lib/connectors';
import { useIsTextTruncated } from '@/shared/lib/hooks/useIsTextTruncated';
import { isEmpty } from 'lodash-es';
import * as React from 'react';
import { NodeRendererProps, TreeItem } from 'react-sortable-tree';
import { Icon } from 'stories/Icon/Icon';
import { RadioButton } from 'stories/RadioButton/RadioButton';
import { Tooltip } from 'stories/Tooltip/Tooltip';
import { FAKE_ROOT_NODE_ID } from 'stories/TreeFolderNavigation/TreeFolderNavigation';
import styles from '@/bundles/Shared/components/GroupForm/FormItems/tree-select/TreeSelect.module.scss';

export type AdditionalProps<T> = {
  selectable?: boolean;
  isSelected?: boolean;
  current?: boolean;
  onSelectedChange?: (node: TreeItem<T>) => void;
  onNodeClick?: (node: TreeItem<T>) => void;
  isClickable?: boolean;
  actions?: React.ReactNode;
  isDisabled?: boolean;
  disableIcons?: boolean;
  classes?: {
    radio?: string;
    item?: string;
    focus?: string;
  };
};

type Props<T> = NodeRendererProps<T> & AdditionalProps<T>;

const START_DEPTH = 1;
const NODE_LEFT_PADDING_BASE = 23;
const OFFSET_TO_CENTER_EXPANDER = 1;
const OFFSET_VERTICAL_TO_EXPANDER = 3;

function TreeSelectItem<T extends { id: number | string } = any>(
  props: Props<T>,
) {
  const {
    path,
    treeIndex,
    toggleChildrenVisibility,
    node,
    parentNode,
    lowerSiblingCounts,
    selectable,
    onNodeClick,
    isClickable,
    isDisabled,
    isSelected,
    current,
    classes,
    isSearchMatch,
  } = props;
  const hasParent = parentNode != null;
  const lastChild = hasParent && parentNode.children?.at(-1) === node;
  const depth = path.length;
  const connectorsCount = depth - 1;
  const isRoot = node.id === FAKE_ROOT_NODE_ID;
  const textRef = React.useRef(null);
  const overflowedByWidth = useIsTextTruncated(textRef, node.title);
  const getConnectorComponent = (i: number) => {
    const connectorType = getConnectorType({
      index: i,
      depth,
      lastChild,
      startDepth: START_DEPTH,
    });
    const style: React.CSSProperties = {
      position: 'absolute',
      left:
        (i + 1) * NODE_LEFT_PADDING_BASE -
        NODE_LEFT_PADDING_BASE -
        OFFSET_TO_CENTER_EXPANDER,
      bottom: OFFSET_VERTICAL_TO_EXPANDER,
    };

    let Component = LongLine;

    if (connectorType === '|_') {
      Component = LastLine;
    }
    if (connectorType === '|-') {
      Component = InnerLine;
    }

    return <Component style={style} />;
  };

  const hasMoreThanOneChildrenAtDepth = (i: number) =>
    depth !== i + 1 + START_DEPTH && lowerSiblingCounts[i + 1] < 1;

  if (!isSearchMatch && isEmpty(node.children) && !isSelected) {
    return <div />;
  }

  return (
    <div
      key={node.id}
      style={{
        paddingLeft: (depth - 1) * NODE_LEFT_PADDING_BASE,
        height: 24,
      }}
      className={cn(styles.item, classes?.item, classes?.focus)}
    >
      <div
        onClick={() => {
          if (!node.isCategory && !node.isSubCategory) {
            onNodeClick?.(node);
          }
        }}
        className={cn('flex w-full min-w-0 items-center', {
          'cursor-pointer': !node.isCategory && !node.isSubCategory,
        })}
      >
        <div className="self-start">
          {Array.from({ length: connectorsCount }).map(
            (_, i) =>
              !hasMoreThanOneChildrenAtDepth(i) && (
                <React.Fragment key={`connector-${i}`}>
                  {getConnectorComponent(i)}
                </React.Fragment>
              ),
          )}
        </div>
        {node.children && node.children.length > 0 && !isRoot && (
          <Icon
            iconName={node.expanded ? 'bottom' : 'right'}
            className="mr-2"
            onClick={(e) => {
              e.stopPropagation();
              toggleChildrenVisibility?.({
                node,
                path,
                treeIndex,
              });
            }}
          />
        )}
        <div
          className={cn('flex w-full min-w-0 items-center', {
            'cursor-pointer': isClickable,
          })}
        >
          <span
            className={cn(
              'text-ellipsis',
              {
                'dark-60 secondary-semibold':
                  node.isCategory || node.isSubCategory || current,
                'secondary-regular': !current,
              },
              styles.label,
              styles.itemWidth,
            )}
            ref={textRef}
          >
            {node.title}
          </span>
          <Tooltip
            mainText={node.title}
            arrowPosition="center"
            reference={textRef}
            disabled={!overflowedByWidth}
            classes={{
              spanContainer: `d-inline ${styles.itemWidth}`,
            }}
          />
        </div>
        {selectable && (
          <RadioButton
            key={`${node.id}-radio`}
            classes={{
              label: cn('d-inline-flex mr-2', classes?.radio, {
                'opacity-100': isSelected,
              }),
            }}
            disabled={isDisabled}
            checked={isSelected}
          />
        )}
      </div>
    </div>
  );
}

export default TreeSelectItem;
