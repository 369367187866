import React, { useState } from 'react';
import canManageObject from 'lib/InvestmentObject';
import { KeyDealTeamContactsCollapsableSection } from 'stories/KeyDealTeamContactsCollapsableSection/KeyDealTeamContactsCollapsableSection';
import { emailPattern } from 'lib/regexpPatterns';
import InvestmentObjectInfoModal from '@/bundles/InvestmentObjects/components/Overview/InvestmentObjectInfo/InvestmentObjectInfoModal';

const KeyDealTeamContacts = ({ object, update, removeConfirmAlert }) => {
  const canManage = canManageObject(object);
  const { id, keyDealTeamContacts } = object;
  const [contactModalIsOpen, setContactModalIsOpen] = useState(false);
  const [editableFieldIndex, setEditableFieldIndex] = useState();

  const toggleContactModal = (idx) => {
    setEditableFieldIndex(idx);
    setContactModalIsOpen(!contactModalIsOpen);
  };

  return (
    <>
      <KeyDealTeamContactsCollapsableSection
        className="border-radius-bottom-[1rem]"
        contacts={keyDealTeamContacts}
        canManage={canManage}
        onCreate={() => toggleContactModal(undefined)}
        onEdit={(contact) => {
          toggleContactModal(
            keyDealTeamContacts.findIndex((c) => c === contact),
          );
        }}
        onDelete={(contact) => {
          removeConfirmAlert(
            () =>
              update({
                id,
                [object.objectType]: {
                  key_deal_team_contacts: keyDealTeamContacts.filter(
                    (i) => i !== contact,
                  ),
                },
              }),
            'Key Deal Team Contact',
          );
        }}
      />

      {contactModalIsOpen && (
        <InvestmentObjectInfoModal
          isOpen
          close={() => toggleContactModal(undefined)}
          object={object}
          header={
            editableFieldIndex !== undefined
              ? 'Edit Key Deal Team Contact'
              : 'Add Key Deal Team Contact'
          }
          items={[...keyDealTeamContacts]}
          currentItemIndex={editableFieldIndex}
          update={update}
          newFieldTemplate={{
            name: '',
            role: '',
            email: '',
            phone: '',
          }}
          fields={[
            { name: 'name', validations: () => ({ required: true }) },
            { name: 'role', validations: () => ({ required: true }) },
            {
              name: 'email',
              validations: (fields) => ({
                required: fields.phone === '' || !fields.phone,
                pattern: emailPattern,
              }),
            },
            {
              name: 'phone',
              validations: (fields) => ({
                required: fields.email === '' || !fields.email,
              }),
            },
          ]}
          dataName="key_deal_team_contacts"
          actionText={
            editableFieldIndex !== undefined ? 'Save Updates' : 'Add Contact'
          }
        />
      )}
    </>
  );
};

export default KeyDealTeamContacts;

KeyDealTeamContacts.propTypes = {};
