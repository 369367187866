/* eslint-disable no-confusing-arrow */
import React from 'react';
import { Link } from '@reach/router';
import { formatDate, formatUnixDate } from '@/shared/lib/formatting/dates';
import { accountingNumberFormat } from '@/shared/lib/formatting/number';
import { Badge } from 'stories/Badges/Badge/Badge';
import styles from 'bundles/Construction/styles.module.scss';
import DocumentsPreview from 'bundles/Construction/components/DocumentsPreview';
import { DEFAULT_NUMBER_FALLBACK } from '@/shared/lib/formatting/fallbacks';

const statusColor = {
  COMPLETED: '#179237',
  APPROVED: '#69c982',
  'PARTIALLY APPROVED': '#69c982',
  PENDING: '#ffbf36',
  'PENDING-PROCEEDING': '#ffbf36',
  'PENDING-NOT PROCEEDING': '#ff9528',
  DRAFT: '#304e5a',
  REJECTED: '#8b0c12',
  DISPUTED: '#ed1622',
  VOID: '#cecece',
};

export const changeOrderColumns = [
  {
    sortable: true,
    text: '#',
    dataField: 'number',
    formatter: ({ row: object }) => object.number,
    classes: styles.orderNumber,
    headerStyle: {
      width: 100,
    },
  },
  {
    sortable: true,
    text: 'CO DEtails',
    dataField: 'description',
    formatter: ({ row: object }) => object.description,
    classes: styles.orderDetails,
    headerStyle: {
      width: 200,
    },
  },
  {
    sortable: true,
    text: 'CO Amount',
    dataField: 'amount',
    formatter: ({ row: object }) => accountingNumberFormat(object.amount),
    classes: styles.orderAmount,
    headerStyle: {
      width: 138,
    },
  },
  {
    sortable: true,
    text: 'New Sum',
    dataField: 'newContractSum',
    formatter: ({ row: object }) =>
      accountingNumberFormat(object.newContractSum),
    classes: styles.orderAmount,
    headerStyle: {
      width: 138,
    },
  },
  {
    sortable: true,
    text: 'Impact',
    dataField: 'timingImpactDays',
    formatter: ({ row: object }) =>
      object.timingImpactDays ? (
        <>
          <span>{object.timingImpactDays} </span>
          <span className={styles.secondaryText}>d</span>
        </>
      ) : (
        DEFAULT_NUMBER_FALLBACK
      ),
    classes: styles.orderImpactDays,
    headerStyle: {
      width: 110,
    },
  },
  {
    sortable: true,
    text: 'Status',
    dataField: 'status',
    formatter: ({ row: object }) => (
      <Badge
        className="white-space-nowrap"
        backgroundColor={statusColor[object.status]}
        textColor="var(--light)"
      >
        {object.status}
      </Badge>
    ),
    headerStyle: {
      width: 110,
    },
  },
  {
    sortable: true,
    text: 'CO Date',
    dataField: 'date',
    formatter: ({ row: object }) => formatUnixDate(object.date, 'L'),
    headerStyle: {
      width: 132,
    },
  },
  {
    sortable: true,
    text: 'Approved',
    dataField: 'approvedAt',
    formatter: ({ row: object }) => formatDate(object.approvedAt, 'L'),
    headerStyle: {
      width: 132,
    },
  },
  {
    sortable: true,
    text: 'Realloc. Date',
    dataField: 'reallocationDate',
    formatter: ({ row: object }) =>
      formatUnixDate(object.reallocationDate, 'L'),
    headerStyle: {
      width: 148,
    },
  },
  {
    sortable: true,
    text: 'Realloc.',
    dataField: 'inReallocation',
    formatter: ({ row: object }) =>
      object.inReallocation ? (
        <Link
          to={`../reallocations/${object.reallocationId}`}
          className={styles.link}
        >
          #{object.inReallocation}
        </Link>
      ) : (
        DEFAULT_NUMBER_FALLBACK
      ),
    headerStyle: {
      width: 115,
    },
  },
  {
    sortable: true,
    text: 'Files',
    dataField: 'documents',
    formatter: ({ row: object }) =>
      object.documents?.length ? (
        <DocumentsPreview sharedFiles={object.documents} />
      ) : (
        ''
      ),
    headerStyle: {
      width: 80,
    },
  },
];
