import React from 'react';
import { Dropdown } from 'stories/Dropdown/Dropdown';
import { DropdownItem } from 'stories/Dropdown/DropdownItem/DropdownItem';
import { Icon } from 'stories/Icon/Icon';
import { IconButton } from 'stories/IconButton/IconButton';
import { navigate, useParams } from '@reach/router';
import { generatePath, ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';

const ApproveActions = () => {
  const params = useParams();

  const editHandle = () => {
    navigate(
      generatePath(
        ROUTES_ROOT.reconcile.development.legalEntity.invoices.edit.fullPath,
        {
          legalEntityCode: params.legalEntityCode,
          invoiceId: params.invoiceId,
        },
      ),
    );
  };

  return (
    <div>
      <Dropdown
        maxWidth="30rem"
        classNameContainer="w-full"
        items={
          <div>
            <DropdownItem key="edit" onClick={editHandle}>
              <div className="flex items-center gap-2">
                <Icon iconName="editAlt" />
                Edit
              </div>
            </DropdownItem>
          </div>
        }
      >
        <IconButton iconName="moreVertical" size="l" variant="secondary" />
      </Dropdown>
    </div>
  );
};

export default ApproveActions;
