import React from 'react';
import { useModal } from '@/shared/lib/hooks/useModal';
import { TransactionDetailsModal } from '@/widgets/accounting/transaction/detailsModal/ui/TransactionDetailsModal';
import GLTableModal from 'bundles/Shared/widgets/generalLedger/ui/GLTableModal';
import VerticalSeparator from 'bundles/Shared/components/VerticalSeparator/VerticalSeparator';
import pluralize from 'pluralize';
import { IGeneralLedgerOnIndex } from 'types/GeneralLedger';

interface Props {
  selectedGLs: IGeneralLedgerOnIndex[];
}

const Item = ({
  onClick,
  title,
  children,
}: React.PropsWithChildren<{
  onClick: VoidFunction;
  title: string;
}>) => (
  <div>
    <div className="font-bold text-neutral-900">{title}</div>
    <div
      className="label-semibold cursor-pointer text-info-055"
      onClick={onClick}
    >
      {children}
    </div>
  </div>
);

export function SetCategoryWithGLsHeader({ selectedGLs }: Props) {
  const { openModal } = useModal();

  const showGLWindow = () => {
    openModal(TransactionDetailsModal, {
      generalLedger: selectedGLs[0],
      showUnpublished: true,
    });
  };

  const showGLsWindow = () => {
    openModal(GLTableModal, {
      glsData: selectedGLs,
    });
  };

  return (
    <div className="flex items-center gap-4">
      <div className="header6-bold text-neutral-900">Set Category</div>
      {Boolean(selectedGLs?.length) && <VerticalSeparator />}
      {selectedGLs?.length > 1 && (
        <SetCategoryWithGLsHeader.Item
          onClick={showGLsWindow}
          title="General Ledgers"
        >
          {selectedGLs?.length} {pluralize('account', selectedGLs?.length)}
        </SetCategoryWithGLsHeader.Item>
      )}
      {selectedGLs?.length === 1 && (
        <SetCategoryWithGLsHeader.Item
          onClick={showGLWindow}
          title={selectedGLs[0].accTitle}
        >
          {selectedGLs[0].accNumber}
        </SetCategoryWithGLsHeader.Item>
      )}
    </div>
  );
}

SetCategoryWithGLsHeader.Item = Item;
