import React from 'react';
import { cn } from '@/shared/lib/css/cn';

import { IconButton } from 'stories/IconButton/IconButton';
import CircleProgress from '@/bundles/Shared/components/CircleProgress';
import FileInfo from '@/bundles/Assets/components/SharedFiles/FileInfo';
import FileExtensionIcon from '@/bundles/Shared/components/fileExtensionIcon/FileExtensionIcon';

const DocumentItem = ({
  id,
  filename,
  fileSize,
  fileExtension,
  onRemove,
  progress,
  withLink,
  downloadButtonUrl,
  className,
  filenameClassName,
  permissions,
  disabled = false,
}) => {
  const filenameComponent = withLink ? (
    <a
      className={cn('inline-semibold sre-link', filenameClassName)}
      href={`/shared-files/${id}?disposition=inline`}
      target="_blank"
      rel="noreferrer"
    >
      {filename}
    </a>
  ) : (
    <span className={cn('inline-semibold dark-60', filenameClassName)}>
      {filename}
    </span>
  );

  return (
    <div className="flex items-center justify-between w-100">
      <div className={`flex items-center text-break w-100 ${className}`}>
        <div className="mr-s">
          <FileExtensionIcon extension={fileExtension} />
        </div>
        <div>
          {filenameComponent}
          <FileInfo
            size={fileSize}
            extension={fileExtension}
            permissions={permissions}
          />
        </div>
        <div className="ml-auto">
          {progress === 100 && <div className="sre-icon-check green" />}
          {progress && progress < 100 && <CircleProgress progress={progress} />}
          {!progress && onRemove && !disabled && (
            <IconButton
              iconName="closeSmall"
              className="light-60"
              onClick={(e) => {
                e.preventDefault();
                onRemove(id);
              }}
            />
          )}
        </div>
      </div>
      {downloadButtonUrl && (
        <a href={downloadButtonUrl}>
          <IconButton iconName="download" />
        </a>
      )}
    </div>
  );
};

export default DocumentItem;
