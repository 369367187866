import { emptySplitApi as api } from '@/app/stores/api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiSettingsReportRentRollSourceChargeCodes: build.query<
      GetApiSettingsReportRentRollSourceChargeCodesApiResponse,
      GetApiSettingsReportRentRollSourceChargeCodesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/report/rent_roll/source_charge_codes`,
        params: {
          page: queryArg.page,
          per_page: queryArg.perPage,
          legal_entity_ids: queryArg.legalEntityIds,
          with_charge_code: queryArg.withChargeCode,
          without_charge_code: queryArg.withoutChargeCode,
          query: queryArg.query,
          group: queryArg.group,
          sort: queryArg.sort,
          sre_charge_codes: queryArg.sreChargeCodeIds.map((item) => item.id),
          erps: queryArg.erps,
          pmc_ids: queryArg.pmcIds,
        },
      }),
    }),
    putApiSettingsReportRentRollSourceChargeCodesSetChargeCode: build.mutation<
      PutApiSettingsReportRentRollSourceChargeCodesSetChargeCodeApiResponse,
      PutApiSettingsReportRentRollSourceChargeCodesSetChargeCodeApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/report/rent_roll/source_charge_codes/set_charge_code`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    getApiSettingsReportRentRollSourceChargeCodesExportCsv: build.query<
      GetApiSettingsReportRentRollSourceChargeCodesExportCsvApiResponse,
      GetApiSettingsReportRentRollSourceChargeCodesExportCsvApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/report/rent_roll/source_charge_codes/export.csv`,
        params: {
          legal_entity_ids: queryArg.legalEntityIds,
          with_charge_code: queryArg.withChargeCode,
          without_charge_code: queryArg.withoutChargeCode,
          query: queryArg.query,
          group: queryArg.group,
          sort: queryArg.sort,
        },
      }),
    }),
    postApiSettingsReportRentRollSourceChargeCodesImport: build.mutation<
      PostApiSettingsReportRentRollSourceChargeCodesImportApiResponse,
      PostApiSettingsReportRentRollSourceChargeCodesImportApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/report/rent_roll/source_charge_codes/import`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as settingsReportOperationalChargeCodesApi };
export type GetApiSettingsReportRentRollSourceChargeCodesApiResponse =
  /** status 200 success */ {
    items: ReportRentRollSourceChargeCodeDto[];
    meta: PaginationDto & {
      legalEntities: {
        id: string;
        name: string;
        code: string;
      }[];
      withoutChargeCodeSize: number;
      withChargeCodeSize: number;
      usedSreChargeCodesSize: number;
    };
  };
export type GetApiSettingsReportRentRollSourceChargeCodesApiArg = {
  page?: number;
  perPage?: number;
  legalEntityIds?: string[];
  withChargeCode?: boolean;
  withoutChargeCode?: boolean;
  query?: string;
  group?: ('erp' | 'pmc' | 'le')[];
  sort?: {
    field?: 'legal_entity';
    order?: 'asc' | 'desc';
  };
};
export type PutApiSettingsReportRentRollSourceChargeCodesSetChargeCodeApiResponse =
  unknown;
export type PutApiSettingsReportRentRollSourceChargeCodesSetChargeCodeApiArg = {
  body: {
    source_charge_code_ids: string[];
    charge_code: 'rent' | 'one_time_fee' | 'recurring_fee' | 'concessions';
  };
};
export type GetApiSettingsReportRentRollSourceChargeCodesExportCsvApiResponse =
  unknown;
export type GetApiSettingsReportRentRollSourceChargeCodesExportCsvApiArg = {
  legalEntityIds?: string[];
  withChargeCode?: boolean;
  withoutChargeCode?: boolean;
  query?: string;
  group?: ('erp' | 'pmc' | 'le')[];
  sort?: {
    field?: 'legal_entity';
    order?: 'asc' | 'desc';
  };
};
export type PostApiSettingsReportRentRollSourceChargeCodesImportApiResponse =
  unknown;
export type PostApiSettingsReportRentRollSourceChargeCodesImportApiArg = {
  body: string;
};
export type ReportRentRollSourceChargeCodeLegalEntityDto = {
  id: string;
  name: string;
  classification: string | null;
  code: string;
  asset: {
    id: number;
    name: string;
  } | null;
};
export type ReportRentRollSourceChargeCodeDto = {
  id: string;
  name: string;
  legalEntity: ReportRentRollSourceChargeCodeLegalEntityDto;
  chargeCode: string | null;
  erpSystem: string;
  propertyManagementCompany: {
    id: number;
    name: string;
  };
};
export type PaginationDto = {
  perPage: number;
  totalSize: number;
};
