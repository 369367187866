import {
  usePostApiSettingsReportBuilderEagleEyeTemplatesMutation,
  usePostApiSettingsReportBuilderTemplatesMutation,
} from '@/entities/report/reportBuilder/api/settingsReportBuilderTemplatesEnhancedApi';
import { CreateReportBuilderTemplateModal } from '@/entities/report/reportBuilder/ui/CreateReportBuilderTemplateModal';
import { useModal } from '@/shared/lib/hooks/useModal';

export const useCreateReportBuilderTemplate = () => {
  const [createTemplate, createTemplateOptions] =
    usePostApiSettingsReportBuilderTemplatesMutation();
  const [createEagleEyeTemplate, createEagleEyeTemplateOptions] =
    usePostApiSettingsReportBuilderEagleEyeTemplatesMutation();
  const { openModal } = useModal();

  const handleCreateTemplate = async () => {
    const res = await openModal(CreateReportBuilderTemplateModal, {});

    if (res == null) return;

    if (res.kind === 'eagle_eye') {
      createEagleEyeTemplate({
        body: {
          name: res.name,
        },
      });
      return;
    }

    createTemplate({
      body: {
        name: res.name,
        kind: 'object_level',
      },
    });
  };
  const isLoading =
    createTemplateOptions.isLoading || createEagleEyeTemplateOptions.isLoading;

  return [
    handleCreateTemplate,
    {
      isLoading,
    },
  ] as const;
};
