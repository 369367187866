import { useReportBuilderTemplateContext } from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/reportBuilderTemplateContext';
import {
  useDeleteApiSettingsReportBuilderEagleEyeTemplatesByEagleEyeTemplateIdGroupsAndIdMutation,
  useDeleteApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndIdMutation,
} from '@/entities/report/reportBuilder/api/settingsReportBuilderTemplatesEnhancedApi';
import type { ReportBuilderTemplateDto } from '@/entities/report/reportBuilder/api/settingsReportBuilderTemplatesGeneratedApi';
import { useReportingEntityKindContext } from '@/entities/reporting/context/entityKind';
import { useModal } from '@/shared/lib/hooks/useModal';

export const useDeleteReportBuilderTemplateGroup = () => {
  const [deleteTemplateGroup, deleteOptions] =
    useDeleteApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndIdMutation();
  const [deleteEagleEyeTemplateGroup, deleteEagleEyeOptions] =
    useDeleteApiSettingsReportBuilderEagleEyeTemplatesByEagleEyeTemplateIdGroupsAndIdMutation();
  const { confirm } = useModal();
  const templateCtx = useReportBuilderTemplateContext()!;
  const templateKind = useReportingEntityKindContext();

  const handleDelete = async (
    groupId: ReportBuilderTemplateDto['groups'][number]['id'],
  ) => {
    const bool = await confirm({
      title: 'Are you sure you want to delete a group from this template',
    });
    if (!bool) return;

    if (templateKind === 'eagle_eye') {
      deleteEagleEyeTemplateGroup({
        id: groupId,
        eagleEyeTemplateId: templateCtx.templateId,
      });
      return;
    }

    deleteTemplateGroup({
      id: groupId,
      templateId: templateCtx.templateId,
    });
  };
  const isLoading = deleteEagleEyeOptions.isLoading || deleteOptions.isLoading;

  return [
    handleDelete,
    {
      isLoading,
    },
  ] as const;
};
