import { ICellRendererParams, RowGroupOpenedEvent } from 'ag-grid-community';
import { cn } from '@/shared/lib/css/cn';
import { CELL_CLASS_NAMES } from '@/bundles/Shared/components/AgGrid/Table/classNames';
import { ConnectorList } from '@/bundles/Shared/components/AgGrid/Table/Connector';
import { useCallback, useEffect, useState } from 'react';
import { expandAllGroupChildren } from 'lib/ag-grid/utils';
import { IconButton } from 'stories/IconButton/IconButton';
import styles from '@/bundles/Shared/components/AgGrid/Table/cellComponents/styles.module.scss';
import { getNodeFlags } from '@/bundles/Shared/components/AgGrid/Table/utils/getNodeFlags';

const {
  AutoGroupCell: { wrapper: WRAPPER_CLASSNAMES, inner: INNER_CLASSNAMES },
} = CELL_CLASS_NAMES;

export function BasicAutoGroupCellRenderer({
  children,
  withoutFooter,
  classes,
  ...params
}) {
  const { node, api } = params;
  const [isExpanded, setIsExpanded] = useState(node.expanded);
  const isExpandable = node.isExpandable();

  useEffect(() => {
    const handleExpandEvent = (e: RowGroupOpenedEvent) => {
      if (e.node.id === node.id && !node.footer) {
        setIsExpanded(node.expanded);
      }
    };

    api.addEventListener('rowGroupOpened', handleExpandEvent);

    return () => {
      api.removeEventListener('rowGroupOpened', handleExpandEvent);
    };
  }, []);

  const handleDeepExpand = useCallback(() => {
    expandAllGroupChildren(node, !isExpanded);
  }, [node, isExpanded]);

  return (
    <div
      className={cn(
        CELL_CLASS_NAMES.AutoGroupCell.wrapper.default,
        isExpandable && 'cursor-pointer',
        classes?.wrapper ?? WRAPPER_CLASSNAMES.basic,
      )}
    >
      {isExpandable && (
        <IconButton
          onClick={handleDeepExpand}
          iconName={isExpanded ? 'doubleArrowBottom' : 'doubleArrowTop'}
          className={cn(styles.expandAllGroupChildrenBtn)}
        />
      )}
      <div
        className={cn(
          INNER_CLASSNAMES.default,
          classes?.inner ?? INNER_CLASSNAMES.basic,
        )}
      >
        <ConnectorList
          node={node}
          expanded={isExpanded}
          withoutFooter={withoutFooter}
        />
        {children}
      </div>
    </div>
  );
}

export const CustomAutoGroupCellRenderer = ({
  classes,
  ...params
}: {
  params: ICellRendererParams;
  classes?: {
    defaultWrapperClassName?: string;
    defaultInnerClassName?: string;
    footerClassName?: string;
    parentItemClassName?: string;
    mainTotalClassName?: string;
  };
}) => {
  const { node, value } = params;
  const rowBooleans = getNodeFlags(node);
  const { isFooter, isRoot, isGrandTotal } = rowBooleans;

  const wrapperClass: string =
    classes?.defaultWrapperClassName ?? 'border-neutral-100 text-neutral-900';
  let innerClass: string =
    classes?.defaultInnerClassName ?? 'border-transparent text-neutral-900';

  if (isFooter) {
    innerClass = classes?.footerClassName ?? innerClass;
  }

  if (isRoot && !isFooter) {
    innerClass = classes?.parentItemClassName ?? innerClass;
  }

  if (isGrandTotal) {
    innerClass = classes?.mainTotalClassName ?? innerClass;
  }
  return (
    <BasicAutoGroupCellRenderer
      classes={{
        wrapper: wrapperClass,
        inner: innerClass,
      }}
      {...params}
    >
      {value}
    </BasicAutoGroupCellRenderer>
  );
};
