import { syncsEnhancedApi } from '@/entities/report/syncs/api/syncsEnhancedApi';
import { useSyncKindContext } from '@/entities/report/syncs/lib/syncKindContext';
import { useModal } from '@/shared/lib/hooks/useModal';
import { Button } from '@/stories/Button/Button';

export const CreateSync = () => {
  const kind = useSyncKindContext();
  const { confirm } = useModal();
  const [createSync, options] =
    syncsEnhancedApi.usePostApiCustomerBridgeSyncsMutation();

  const handleCreate = async () => {
    const res = await confirm({
      title: 'New Sync',
      subtitle:
        'Please confirm you would like to start a new sync, note it can take up to 1 hour. You will receive a confirmation email once the sync is completed',
      actions: {
        primaryButton: {
          variant: 'success',
          text: 'Start a New Sync',
        },
        secondaryButton: {
          text: 'Cancel',
          variant: 'secondary',
        },
      },
    });

    if (res === false) return;

    createSync({
      body: {
        kind,
      },
    });
  };
  return (
    <Button
      onClick={() => handleCreate()}
      disabled={options.isLoading}
      loading={options.isLoading}
      size="s"
      variant="primary"
    >
      New Sync
    </Button>
  );
};
