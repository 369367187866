import React from 'react';
import { InvestmentEntityDto } from '@/shared/api/investmentEntityGeneratedApi';
import { usePutApiInvestmentEntitiesByIdMutation } from '@/shared/api/investmentEntityEnhancedApi';
import { SectionField } from '@/stories/Field/FieldsWrappers';
import { Button } from '@/stories/Button/Button';
import { Input } from '@/stories/FormControls/Inputs/Input/Input';
import { Select } from '@/stories/FormControls/Select/Select';
import { HorizontalEditableField } from '@/stories/Field/HorizontalEditableField';
import { useBoolean } from 'usehooks-ts';
import { useForm } from 'react-hook-form';
import { cn } from '@/shared/lib/css/cn';
import { DEFAULT_STRING_FALLBACK } from '@/shared/lib/formatting/fallbacks';
const PAYMENT_METHODS = [
  { id: 'wire', label: 'Wire' },
  { id: 'ach', label: 'ACH' },
  { id: 'check', label: 'Check' },
];

const ACCOUNT_TYPES = [
  { id: 'checking', label: 'Checking' },
  { id: 'savings', label: 'Savings' },
];

export const BankDetailsTab = ({ item }: { item: InvestmentEntityDto }) => {
  const {
    value: isEditing,
    setTrue: startEditing,
    setFalse: stopEditing,
  } = useBoolean(false);

  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { isValid },
  } = useForm({
    defaultValues: {
      accountName: item?.bankDetails?.accountName ?? '',
      accountNumber: item?.bankDetails?.accountNumber ?? '',
      bankName: item?.bankDetails?.bankName ?? '',
      forCreditTo: item?.bankDetails?.forCreditTo ?? '',
      method: item?.bankDetails?.paymentMethod ?? '',
      paymentReference: item?.bankDetails?.paymentReference ?? '',
      routingNumber: item?.bankDetails?.routingNumber ?? '',
      accountType: item?.bankDetails?.accountType ?? '',
    },
    mode: 'onChange',
  });

  const formValues = watch();

  const [updateInvestmentEntity, { isLoading: isUpdating }] =
    usePutApiInvestmentEntitiesByIdMutation();

  const handleCancel = () => {
    reset();
    stopEditing();
  };

  const handleSave = handleSubmit(async (data) => {
    const result = await updateInvestmentEntity({
      id: item.id,
      body: {
        investment_entity: {
          bank_details_attributes: {
            account_name: data.accountName,
            account_number: data.accountNumber,
            bank_name: data.bankName,
            for_credit_to: data.forCreditTo,
            payment_method: data.method,
            payment_reference: data.paymentReference,
            routing_number: data.routingNumber,
            account_type: data.accountType,
          },
        },
      },
    });

    if ('data' in result) {
      stopEditing();
    }
  });

  return (
    <SectionField
      labelText="Bank Details"
      button={
        isEditing ? (
          <div className="flex gap-2">
            <Button size="xs" variant="secondary" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              size="xs"
              variant="success"
              onClick={handleSave}
              loading={isUpdating}
              disabled={!isValid}
            >
              Save Changes
            </Button>
          </div>
        ) : (
          <Button size="xs" variant="secondary" onClick={startEditing}>
            Change
          </Button>
        )
      }
    >
      <div className="-mx-4 divide-y divide-solid divide-neutral-200">
        <HorizontalEditableField
          label="Payment Method"
          value={formValues.method || DEFAULT_STRING_FALLBACK}
          editable={isEditing}
          className={cn(isEditing ? 'py-2' : 'py-4')}
          required={isEditing}
        >
          <Select
            spanContainer="w-full"
            onSelectedChange={(value) => {
              if (value && 'label' in value) {
                setValue('method', value.id);
              }
            }}
            selected={PAYMENT_METHODS.find(
              (option) => option.id === formValues.method,
            )}
            options={PAYMENT_METHODS}
            placeholder="Select Payment Method"
          />
        </HorizontalEditableField>

        <HorizontalEditableField
          label="Account Name"
          value={formValues.accountName || DEFAULT_STRING_FALLBACK}
          editable={isEditing}
          className={cn(isEditing ? 'py-2' : 'py-4')}
          required={isEditing}
        >
          <Input
            {...register('accountName', { required: true })}
            placeholder="Enter Account Name"
          />
        </HorizontalEditableField>

        <HorizontalEditableField
          label="Account Number"
          value={formValues.accountNumber || DEFAULT_STRING_FALLBACK}
          editable={isEditing}
          className={cn(isEditing ? 'py-2' : 'py-4')}
          required={isEditing}
        >
          <Input
            {...register('accountNumber', { required: true })}
            placeholder="Enter Account Number"
          />
        </HorizontalEditableField>

        <HorizontalEditableField
          label="Routing Number"
          value={formValues.routingNumber || DEFAULT_STRING_FALLBACK}
          editable={isEditing}
          className={cn(isEditing ? 'py-2' : 'py-4')}
          required={isEditing}
        >
          <Input
            {...register('routingNumber', { required: true })}
            placeholder="Enter Routing Number"
          />
        </HorizontalEditableField>

        <HorizontalEditableField
          label="Bank Name"
          value={formValues.bankName || DEFAULT_STRING_FALLBACK}
          editable={isEditing}
          className={cn(isEditing ? 'py-2' : 'py-4')}
          required={isEditing}
        >
          <Input
            {...register('bankName', { required: true })}
            placeholder="Enter Bank Name"
          />
        </HorizontalEditableField>

        <HorizontalEditableField
          label="Account Type"
          value={formValues.accountType || DEFAULT_STRING_FALLBACK}
          editable={isEditing}
          className={cn(isEditing ? 'py-2' : 'py-4')}
          required={isEditing}
        >
          <Select
            spanContainer="w-full"
            onSelectedChange={(value) => {
              if (value && 'label' in value) {
                setValue('accountType', value.id);
              }
            }}
            selected={ACCOUNT_TYPES.find(
              (option) => option.id === formValues.accountType,
            )}
            options={ACCOUNT_TYPES}
            placeholder="Select Account Type"
          />
        </HorizontalEditableField>

        <HorizontalEditableField
          label="Payment Reference"
          value={formValues.paymentReference || DEFAULT_STRING_FALLBACK}
          editable={isEditing}
          className={cn(isEditing ? 'py-2' : 'py-4')}
        >
          <Input
            {...register('paymentReference')}
            placeholder="Enter Payment Reference"
          />
        </HorizontalEditableField>

        <HorizontalEditableField
          label="For Credit To"
          value={formValues.forCreditTo || DEFAULT_STRING_FALLBACK}
          editable={isEditing}
          className={cn(isEditing ? 'py-2' : 'py-4')}
          required={isEditing} // remove when BE ready
        >
          <Input
            {...register('forCreditTo', { required: true })}
            placeholder="Enter For Credit To"
          />
        </HorizontalEditableField>
      </div>
    </SectionField>
  );
};
