import { useGetApiSettingsReportFormulaTagsQuery } from '@/shared/api/settingsReportFormulasEnhanced';
import { useMemo } from 'react';
import { createSelector } from '@reduxjs/toolkit';
import { GetApiSettingsReportFormulaTagsApiResponse } from '@/shared/api/settingsReportFormulasGeneratedApi';
import { partition } from 'lodash-es';
import {
  EntityTag,
  TAG_ENTITIES,
  TagEntity,
} from 'bundles/Shared/entities/tag/model';
import { useGetApiSettingsReportManualVariableTagsQuery } from '@/entities/report/manualVariable';
import { GetApiSettingsReportManualVariableTagsApiResponse } from '@/entities/report/manualVariable/api/settingsReportManualVariableTagsGeneratedApi';
import { isTagSystem } from 'bundles/Shared/entities/tag/lib';

const emptyTags: EntityTag[] = [];
export const useEntityTagsQuery = <T extends TagEntity>(
  entity: T,
  args?: Parameters<typeof useGetApiSettingsReportFormulaTagsQuery>[0],
  options?: Omit<
    Parameters<typeof useGetApiSettingsReportFormulaTagsQuery>[1],
    'selectFromResult'
  >,
) => {
  const query = useMemo(
    () =>
      entity === TAG_ENTITIES.FORMULA
        ? useGetApiSettingsReportFormulaTagsQuery
        : useGetApiSettingsReportManualVariableTagsQuery,
    [entity],
  );

  const selectSystemTagsPartition = useMemo(() => {
    return createSelector(
      (res: {
        data:
          | GetApiSettingsReportFormulaTagsApiResponse
          | GetApiSettingsReportManualVariableTagsApiResponse
          | undefined;
      }) => res.data?.items ?? emptyTags,
      (data) => partition(data, isTagSystem),
    );
  }, []);

  return query(args ?? {}, {
    selectFromResult: ({ data, ...result }) => ({
      ...result,
      meta: data?.meta?.systemSync,
      tags: data?.items ?? emptyTags,
      systemTagsPartition: selectSystemTagsPartition({ data }),
    }),
    ...options,
  });
};
