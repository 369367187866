import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash-es';
import { RadioGroup } from 'stories/RadioButton/RadioGroup';
import { mapItemsToListOption } from '@/shared/lib/listHelpers';

const selectCategoryNameBy = (category) =>
  ({
    external: 'External',
    internal: 'Internal',
    symmetre: 'sRE Admins',
  })[category] || 'Unknown';

const SelectRoleGroup = ({
  userRoles,
  userCategory,
  fields,
  setValue,
  setExpanded,
}) => (
  <div className="flex flex-col gap-1">
    <div className="light-90 mb-[0.75rem]">
      {selectCategoryNameBy(userCategory)}
    </div>

    {_.uniq(userRoles.map((role) => role.group)).map((roleGroup) => (
      <React.Fragment key={roleGroup}>
        {roleGroup && (
          <div className="light-60 secondary-regular mb-[0.4rem] mt-[0.6rem]">
            {roleGroup}
          </div>
        )}
        <RadioGroup
          options={mapItemsToListOption(
            userRoles.filter((userRole) => userRole.group === roleGroup),
            'name',
          )}
          value={fields.userRole?.id}
          onChange={(o) => {
            setValue(
              'userRole',
              userRoles.find((role) => role.id === o.value),
            );
            setExpanded(false);
          }}
          getItemProps={() => ({
            classes: {
              label: 'font-normal text-xs',
            },
          })}
        />
      </React.Fragment>
    ))}
  </div>
);

export default SelectRoleGroup;

SelectRoleGroup.propTypes = {
  userRoles: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  userCategory: PropTypes.string.isRequired,
  fields: PropTypes.shape().isRequired,
  setValue: PropTypes.func.isRequired,
  setExpanded: PropTypes.func.isRequired,
};
