import React from 'react';
import BadgeSwitch from '@/bundles/Shared/components/BadgeSwitch';

const EmailListSwitches = ({ switchNames, onClick, isActive }) => (
  <div>
    <h4 className="header4-bold dark-60 text-[1.5rem] mt-[1.25rem] mb-4">
      Delivered Emails
    </h4>
    <div className="flex flex-wrap items-center">
      <span className="mr-[0.5rem]">Statuses:</span>
      {switchNames.map((switchName) => (
        <BadgeSwitch
          key={switchName}
          label={switchName}
          color="var(--bl)"
          onClick={() => onClick(switchName)}
          active={isActive(switchName)}
        />
      ))}
    </div>
  </div>
);

export default EmailListSwitches;
