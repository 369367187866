import { ReportDashboardType } from '@/bundles/Shared/entities/dashboard/model/types/types';
import { useNavigateBack } from '@/shared/lib/hooks/navigation';
import { useModal } from '@/shared/lib/hooks/useModal';
import { generateUrl, ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import { CreateNewUI } from '@/shared/ui/CreateNewUI';
import { Button } from '@/stories/Button/Button';
import { useNavigate } from '@reach/router';
import {
  DashboardCreationForm,
  useCreateDashboard,
} from 'bundles/Shared/features/dashboard/create';
import { useCreateDashboardForm } from 'bundles/Shared/features/dashboard/create/lib';
import { FormProvider } from 'react-hook-form';

export function SettingsReportDashboardsNew() {
  const navigate = useNavigate();
  const { navigateBack } = useNavigateBack({
    fallbackUrl: ROUTES_ROOT.settings.report.dashboards.fullPath,
  });
  const { confirm } = useModal();
  const methods = useCreateDashboardForm({
    dashboard_type: ReportDashboardType.EAGLE_EYE,
    excluded_legal_entity_ids: [],
    segment_ids: [],
    asset_ids: [],
  });
  const {
    handleSubmit,
    formState: { isValid, isDirty },
  } = methods;
  const [createDashboard, { isLoading: isSubmitting }] = useCreateDashboard();

  const onSubmit = handleSubmit(async (data) => {
    const res = await createDashboard(data);
    if ('error' in res) {
      return;
    }
    navigate(
      generateUrl(ROUTES_ROOT.settings.report.dashboards.dashboard.fullPath, {
        pathParams: {
          dashboardId: res.data.id,
        },
      }),
    );
  });
  const onBack = async () => {
    const res = await confirm({
      title: 'Do you really want to cancel this process?',
    });
    if (!res) {
      return;
    }
    navigateBack();
  };
  const submitSlot = (
    <>
      {!isDirty && (
        <span className="inline-regular text-center text-neutral-550">
          You need to fill all fields to continue
        </span>
      )}
      <Button
        disabled={!isValid || isSubmitting}
        variant="success"
        size="l"
        onClick={onSubmit}
      >
        Start Building
      </Button>
    </>
  );
  return (
    <CreateNewUI>
      <CreateNewUI.BackButton onClick={onBack} />
      <CreateNewUI.Container>
        <CreateNewUI.Form title="Dashboard" submitSlot={submitSlot}>
          <FormProvider {...methods}>
            <DashboardCreationForm />
          </FormProvider>
        </CreateNewUI.Form>
      </CreateNewUI.Container>
    </CreateNewUI>
  );
}
