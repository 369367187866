import React from 'react';
import pluralize from 'pluralize';
import { cn } from '@/shared/lib/css/cn';
import { Button } from 'stories/Button/Button';
import { Icon } from 'stories/Icon/Icon';

interface Props {
  ruleName: string;
  rulesLen: number;
  onAddRule: () => void;
}
function RulesHeader({ ruleName = 'Rule', rulesLen, onAddRule }: Props) {
  return (
    <div
      className={cn('flex items-center justify-between', {
        'mb-m': rulesLen > 0,
      })}
    >
      <p
        className={cn('font-bold', {
          'text-neutral-900': rulesLen,
          'text-neutral-500': !rulesLen,
        })}
      >
        {!rulesLen && `No ${pluralize(ruleName, 2)} yet`}
        {Boolean(rulesLen) && `${rulesLen} ${pluralize(ruleName, rulesLen)}`}
      </p>
      <Button
        size="s"
        variant="secondary"
        onClick={onAddRule}
        iconName="add"
        className="font-medium"
        iconClasses="text-neutral-450 label-regular"
      >
        Add Rule
      </Button>
    </div>
  );
}

export default RulesHeader;
