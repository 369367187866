import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import http from 'lib/http';
import { formatUnixDate } from '@/shared/lib/formatting/dates';
import { uniqBy } from 'lodash-es';
import { AnimationLoader } from 'stories/AnimationLoader/AnimationLoader';
import { Modal } from 'stories/Modals/Modal/Modal';
import CampaignEmailCard from '@/bundles/Settings/components/EmailSettings/EmailCampaign/CampaignEmailCard';
import useBoolean from '@/shared/lib/hooks/useBoolean';
import { DialogProps } from '@/shared/lib/hooks/useModal';

const EmailCampaignModal = ({
  feedMessageId,
  headerSubtitle,
  onClose,
}: {
  feedMessageId: number;
  headerSubtitle: string;
} & DialogProps) => {
  const {
    value: isFetching,
    setTrue: startFetching,
    setFalse: stopFetching,
  } = useBoolean(true);
  const [feedMessage, setFeedMessage] = useState<{
    createdAt?: UnixTime;
    subject?: string;
    content?: string;
    emailMessages?: { recipient: string }[];
  }>({});
  const { createdAt, subject, content, emailMessages } = feedMessage;

  useEffect(() => {
    startFetching();
    http
      .get(`/email_campaign/feed_messages/${feedMessageId}`)
      .then((res) => res.json())
      .then((json) => setFeedMessage(json))
      .finally(stopFetching);
  }, []);

  const collectRecipients = (messages: { recipient: string }[]) =>
    uniqBy(
      messages.map((em) => em.recipient),
      'id',
    );

  const campaignEmailHasInfo = createdAt && subject && content && emailMessages;

  return (
    <Modal
      size="lg"
      toggle={onClose}
      header={
        <div className="flex flex-col">
          <span className="light-60 label-regular">{headerSubtitle}</span>
          <h6 className="dark-60 header6-bold">Campaign Details</h6>
        </div>
      }
    >
      {isFetching && <AnimationLoader className="static -m-4 h-[240px]" />}
      {campaignEmailHasInfo && !isFetching && (
        <CampaignEmailCard
          size="md"
          key={feedMessageId}
          title="Feed Notification"
          date={formatUnixDate(createdAt, 'LL')}
          time={formatUnixDate(createdAt, 'hh:mm A')}
          subject={subject}
          emailText={content}
          recipients={collectRecipients(emailMessages)}
          totalRecipientsCount={emailMessages.length}
          emailMessages={emailMessages}
          iconClass="category"
          color="violet"
          type="grouped"
        />
      )}
    </Modal>
  );
};

export default EmailCampaignModal;

EmailCampaignModal.propTypes = {
  feedMessageId: PropTypes.number.isRequired,
  headerSubtitle: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};
