import { isEmpty } from 'lodash-es';
import * as Type from '@/bundles/Settings/components/EmailSettings/editor/types';

interface Params {
  content: RawHTML | undefined;
  vars: Type.Variable[] | undefined;
  withDestroy?: boolean;
}

interface TextAttributes {
  content: RawHTML | undefined;
  text_variables_attributes: Type.Variable[] | undefined;
}

interface TemplateAttributes {
  text_attributes: TextAttributes;
  _destroy?: boolean;
}

export function buildTemplateAttributes({
  content,
  vars,
  withDestroy = false,
}: Params): TemplateAttributes {
  const textAttributes: TextAttributes = {
    content,
    text_variables_attributes: vars,
  };

  return {
    text_attributes: textAttributes,
    _destroy: withDestroy && isEmpty(content),
  };
}
