import React, { useState } from 'react';
import { Button } from 'stories/Button/Button';
import { Modal } from 'stories/Modals/Modal/Modal';
import { ModalActions } from 'stories/Modals/Modal/ModalActions';
import { useAppSelector } from '@/shared/lib/hooks/redux';
import { IUserRole } from 'types/User';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import { PeriodLimitationEntry } from '@/bundles/Settings/components/REport/TableBuilder/PeriodLimitation/PeriodLimitationEntry';
import styles from '@/bundles/Settings/components/REport/TableBuilder/Rules/sharedStyles.module.scss';
import { IPermissionRuleState } from '@/bundles/Settings/components/REport/TableBuilder/types';
import {
  createId,
  createRulesStateFromLimitation,
  updatedRules,
} from '@/bundles/Settings/components/REport/TableBuilder/helpers/periodLimitationUtils';
import { useRuleOptions } from '@/bundles/Settings/components/REport/TableBuilder/helpers/hooks/useRuleOptions';
import RulesHeader from '@/bundles/Settings/components/REport/TableBuilder/Rules/RulesHeader';
import { DEFAULT_PERIOD_LIMITATION_ROLE } from '@/bundles/Settings/components/REport/TableBuilder/helpers/constants';

export type PeriodsLimitationsValue = {
  default?: number | null;
} & Record<string, null | number>;

interface ModalProps extends DialogProps<IPermissionRuleState[]> {
  periodLimitations: PeriodsLimitationsValue;
}

export const PeriodLimitationModal = ({
  periodLimitations = {},
  onClose,
  onSubmit,
}: ModalProps) => {
  const userRoles: IUserRole[] = useAppSelector(
    (state) => state.users.userRoles,
  );
  const [rules, setRules] = useState<IPermissionRuleState[]>(
    createRulesStateFromLimitation(periodLimitations),
  );

  const submitDisabled = rules.reduce(
    (prev, curr) =>
      prev || curr.userRoleId === '' || curr.limit === '0' || curr.limit === 0,
    false,
  );

  const [allRoleOptions, notSelectedRoleOptions] = useRuleOptions(
    [...userRoles, DEFAULT_PERIOD_LIMITATION_ROLE],
    'name',
    rules,
    'userRoleId',
  );

  const onSave = () => {
    onSubmit?.(rules);
  };

  const actions = (
    <ModalActions>
      <Button size="s" variant="secondary" onClick={onClose}>
        Cancel
      </Button>
      <Button
        size="s"
        disabled={!rules.length || submitDisabled}
        variant="success"
        onClick={onSave}
      >
        Set Rules
      </Button>
    </ModalActions>
  );

  const handleAddRule = () => {
    setRules((prev) => [
      ...prev,
      { limit: null, userRoleId: '', id: createId() },
    ]);
  };

  const handleRemoveRule = (id: IPermissionRuleState['id']) => {
    setRules((prev) =>
      prev.length === 1 ? [] : prev.filter((r) => r.id !== id),
    );
  };

  const handleChangeUserRole = (
    id: IPermissionRuleState['id'],
    userRoleId: string,
  ) => {
    setRules((prevRules) =>
      updatedRules(prevRules, id, 'userRoleId', userRoleId),
    );
  };

  const handlePeriodChange = (id: IPermissionRuleState['id'], val: string) => {
    setRules((prevRules) => updatedRules(prevRules, id, 'limit', Number(val)));
  };

  const handleToggle = (id: IPermissionRuleState['id'], checked: boolean) => {
    setRules((prevRules) => {
      const limit = checked ? null : '0';
      return updatedRules(prevRules, id, 'limit', limit);
    });
  };

  return (
    <Modal
      header="Period Limitation Rules"
      toggle={onClose}
      actions={actions}
      classes={{
        body: 'bg-neutral-100 px-l py-m',
      }}
    >
      <div className="flex flex-col">
        <RulesHeader
          onAddRule={handleAddRule}
          ruleName="Rule"
          rulesLen={rules.length}
        />
        {rules.length > 0 && (
          <div className={styles.group}>
            {rules.map((rule) => (
              <PeriodLimitationEntry
                key={rule.id}
                rule={rule}
                allRoleOptions={allRoleOptions}
                notSelectedRoleOptions={notSelectedRoleOptions}
                onUserRoleChange={handleChangeUserRole}
                onPeriodChange={handlePeriodChange}
                onToggle={handleToggle}
                onRemove={handleRemoveRule}
              />
            ))}
          </div>
        )}
      </div>
    </Modal>
  );
};
