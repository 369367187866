import React from 'react';
import NumberFormat from 'react-number-format';

// TODO: use Maskito
export const PhoneNumberInput = ({
  value,
  onChange,
  format = '(###) ###-####',
  mask = '_',
  placeholder = 'Enter Phone Number',
  size = 'm',
}: {
  value: string;
  onChange: (value: string) => void;
  format?: string;
  mask?: string;
  placeholder?: string;
  size?: 'm' | 'l';
}) => {
  return (
    <div className={`sre-input sre-input_size-${size}`}>
      <NumberFormat
        mask={mask}
        format={format}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className="sre-input__field"
        placeholder={placeholder}
      />
      <div className="sre-input__back-layer" />
    </div>
  );
};
