import React, { useState, useRef, useEffect } from 'react';
import AnimateHeight from 'react-animate-height';
import sanitizeHtml from 'sanitize-html';
import { cn } from '@/shared/lib/css/cn';
import useElementSize from '@/shared/lib/hooks/useElementSize';
import Button from '@/stories/Button/Button';
import { DEFAULT_SANITIZE_HTML_OPTIONS } from '@/shared/lib/browser/config';

const MAX_CONTENT_HEIGHT = 200;

function PostContent({ text, expanded }: { text: string; expanded?: boolean }) {
  const ckPreviewRef = useRef(null);
  const [isContentExpanded, setIsContentExpanded] = useState(expanded);
  const { height: contentHeight } = useElementSize(ckPreviewRef);

  useEffect(() => {
    setIsContentExpanded(expanded);
  }, [text]);

  return (
    <div
      style={{
        maxHeight: isContentExpanded ? 9999 : MAX_CONTENT_HEIGHT,
        overflow: 'hidden',
        paddingBottom: isContentExpanded ? 30 : 0,
      }}
      className={cn('ck-preview', { expanded: isContentExpanded })}
    >
      <AnimateHeight
        id="example-panel"
        duration={300}
        delay={100}
        height={
          isContentExpanded || contentHeight < MAX_CONTENT_HEIGHT
            ? 'auto'
            : MAX_CONTENT_HEIGHT
        }
      >
        <div
          ref={ckPreviewRef}
          className="img-max-100 grey-primary overflow-x-auto tiptap-content"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: sanitizeHtml(text, DEFAULT_SANITIZE_HTML_OPTIONS),
          }}
        />
        {contentHeight > MAX_CONTENT_HEIGHT ? (
          <div className="read-more-overlay">
            <Button
              className="read-more"
              size="xs"
              variant={isContentExpanded ? 'secondary' : 'primary'}
              iconPosition="right"
              iconName={isContentExpanded ? 'top' : 'bottom'}
              onClick={() => setIsContentExpanded(!isContentExpanded)}
            >
              {isContentExpanded ? 'Collapse' : 'Read more'}
            </Button>
          </div>
        ) : null}
      </AnimateHeight>
    </div>
  );
}

export default PostContent;
