import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'stories/Button/Button';
import { Modal } from 'stories/Modals/Modal/Modal';
import { ModalActions } from 'stories/Modals/Modal/ModalActions';
import { useForm } from 'react-hook-form';
import { isEmpty } from 'lodash-es';
import WizardForm, { WizardPage } from '@/bundles/Shared/components/WizardForm';
import BasicInfo from '@/bundles/UserManagement/components/UserForm/BasicInfo';
import SelectUserTag from '@/bundles/UserManagement/components/UserForm/SelectUserTag';
import SelectUserRole from '@/bundles/UserManagement/components/UserForm/SelectUserRole';
import PermissionsFor from '@/bundles/UserManagement/components/UserForm/PermissionsFor';
import ReviewStep from '@/bundles/UserManagement/components/UserForm/ReviewStep';
import userFormRequest from '@/bundles/UserManagement/components/helpers/userFormRequest';
import userFormTabs from '@/bundles/UserManagement/components/helpers/userFormTabs';
import userFormDefaultValues from '@/bundles/UserManagement/components/helpers/userFormDefaultValues';
import FundAccess from '@/bundles/UserManagement/components/UserForm/FundAccess';
import AssetAccess from '@/bundles/UserManagement/components/UserForm/AssetAccess';
import SetInvestmentEntities from '@/bundles/UserManagement/components/UserForm/SetInvestmentEntities';
import fetchViewableFunds from '@/bundles/UserManagement/actions/ViewableFund';
import { plainFetchAssets } from '@/bundles/Assets/actions';
import SetAdvisors from '@/bundles/UserManagement/components/UserForm/SetAdvisors';

export const UserForm = ({
  onSubmit,
  activeUserRole,
  skipConfirm,
  customSteps,
}) => {
  const {
    watch,
    setValue,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: userFormDefaultValues({
      user: { role: activeUserRole, investmentEntities: [] },
    }),
    mode: 'onChange',
  });
  const fields = watch();
  const [funds, setFunds] = useState();
  const [assets, setAssets] = useState();

  useEffect(() => {
    fetchViewableFunds().then((data) => setFunds(data));
    plainFetchAssets().then((data) => setAssets(data.assets));
  }, []);

  const submit = () => onSubmit(userFormRequest(fields));

  const tabs = userFormTabs(fields.userRole);
  const isBasicInfoValid =
    fields.firstName &&
    fields.lastName &&
    fields.email &&
    Object.keys(errors).length === 0;

  return (
    <WizardForm onSubmit={handleSubmit(submit)} customSteps={customSteps}>
      {tabs.includes('Info') && (
        <WizardPage title="Info" isValid={isBasicInfoValid} withoutActions>
          <BasicInfo
            fields={fields}
            setValue={setValue}
            register={register}
            errors={errors}
          />
        </WizardPage>
      )}
      {tabs.includes('Role') && (
        <WizardPage title="Role" isValid={fields.userRole} withoutActions>
          <SelectUserRole fields={fields} setValue={setValue} />

          {fields.userRole && (
            <div className="flex flex-col gap-1">
              <span className="label-regular text-dark-60">Permissions</span>
              <PermissionsFor selectedRole={fields.userRole} />
            </div>
          )}
        </WizardPage>
      )}
      {tabs.includes('Entities') && (
        <WizardPage
          title="Entities"
          isValid={!isEmpty(fields.investmentEntities)}
          withoutActions
        >
          <SetInvestmentEntities setValue={setValue} fields={fields} />
        </WizardPage>
      )}
      {tabs.includes('Advisees') && (
        <WizardPage title="Advisees" isValid withoutActions>
          <SetAdvisors
            setValue={(data) => setValue('adviseeIds', data)}
            defaultValues={[]}
            placeholder="Choose one or more advisee"
            url="/users"
            params={{ not_advisors: true }}
          />
        </WizardPage>
      )}
      {tabs.includes('Tags') && (
        <WizardPage title="Tags" isValid={fields.userTags} withoutActions>
          <SelectUserTag fields={fields} setValue={setValue} />
        </WizardPage>
      )}
      {tabs.includes('Funds') && (
        <WizardPage
          title="Funds"
          withoutActions
          isValid={
            (fields.canViewFundIds || fields.canViewAllFunds) &&
            (fields.userRole?.category !== 'external'
              ? fields.canManageFundIds || fields.canManageAllFunds
              : true)
          }
        >
          <FundAccess
            fields={fields}
            setValue={setValue}
            funds={funds}
            selectedFundIds={fields.canViewFundIds}
          />
        </WizardPage>
      )}
      {tabs.includes('Assets') && (
        <WizardPage
          title="Assets"
          withoutActions
          isValid={
            (fields.canViewAssetIds || fields.canViewAll) &&
            (fields.userRole?.category !== 'external'
              ? fields.canManageAssetIds || fields.canManageAll
              : true)
          }
        >
          <AssetAccess
            fields={fields}
            setValue={setValue}
            assets={assets}
            funds={funds}
          />
        </WizardPage>
      )}
      {!skipConfirm && (
        <WizardPage title="Confirm" isValid withoutActions>
          <ReviewStep fields={fields} funds={funds} assets={assets} />
        </WizardPage>
      )}
    </WizardForm>
  );
};

const CreateUserModal = ({
  onSubmit,
  activeUserRole,
  toggleCreateUserModal,
}) => {
  const [customSteps, setCustomSteps] = useState();
  return (
    <Modal
      toggle={toggleCreateUserModal}
      header="Create User"
      size="900"
      actions={
        <ModalActions>
          <Button
            id="wizard-step-back"
            variant="secondary"
            className="d-none"
            onClick={() => setCustomSteps(`prev${Math.random()}`)}
          >
            Back
          </Button>
          <Button
            id="wizard-step-cancel"
            variant="secondary"
            onClick={toggleCreateUserModal}
          >
            Cancel
          </Button>
          <Button
            id="wizard-step-continue"
            variant="success"
            onClick={() => setCustomSteps(`next${Math.random()}`)}
            className="flex items-center"
          >
            Continue
            <span className="sre-icon-arrowRightAlt ml-[0.5rem]" />
          </Button>
        </ModalActions>
      }
    >
      <UserForm
        onSubmit={onSubmit}
        activeUserRole={activeUserRole}
        customSteps={customSteps}
      />
    </Modal>
  );
};

CreateUserModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  toggleCreateUserModal: PropTypes.func.isRequired,
  activeUserRole: PropTypes.shape({}),
};

CreateUserModal.defaultProps = {
  activeUserRole: undefined,
};

export default CreateUserModal;
