import React, { FC, useEffect, useState } from 'react';
import { DocumentCard } from 'stories/DocumentCard/DocumentCard';
import { SmallAddingButton } from 'stories/SmallAddingButton/SmallAddingButton';
import { UploadContainer } from 'stories/UploadContainer/UploadContainer';
import { cn } from '@/shared/lib/css/cn';
import { IFormItem } from '@/bundles/Shared/components/GroupForm/types/types';
import { FormFilesActions } from '@/bundles/Shared/components/GroupForm/FormItems/files/components/FormFilesActions';
import { getFileExtensionFromName } from 'lib/uploadFiles';
import useFileDialog from '@/shared/lib/hooks/useFileDialog';

interface IFormFilesProps extends IFormItem {
  config: {
    label: string;
    buttonText: string;
  };
  multifile?: boolean;
  multiple?: boolean;
}

const FormFiles: FC<IFormFilesProps> = ({
  formData,
  setFormData,
  fieldName,
  loading,
  styles,
  enabled = true,
  multifile = false,
  multiple = true,
  actions = false,
  actionsView = () => false,
  config = {
    label: 'Drag your documents here to start uploading',
    buttonText: 'Browse Documents',
  },
}) => {
  const [dropMode, setDropMode] = useState(true);
  const { openFileDialog } = useFileDialog();

  useEffect(() => {
    if (!formData[fieldName] || formData[fieldName] === 'YES') {
      setDropMode(true);
    } else {
      setDropMode(false);
    }
  }, [formData, setFormData]);
  const handleChange = (filesList: File[]) => {
    if (formData[fieldName] && formData[fieldName] !== 'YES') {
      const newFieldValue = Array.isArray(formData[fieldName])
        ? [...formData[fieldName], ...filesList]
        : [formData[fieldName], ...filesList];
      setFormData?.({
        ...formData,
        [fieldName]: newFieldValue,
      });
    } else {
      let [files] = filesList;
      if (multiple && filesList.length > 1) files = filesList;
      setFormData?.({
        ...formData,
        [fieldName]: files,
      });
      setDropMode(false);
    }
  };
  const onRemoveItem = (idx?: number) => {
    if ((idx || idx === 0) && formData[fieldName].length > 2) {
      const newFieldValue = formData[fieldName].filter((i, id) => id !== idx);
      setFormData?.({
        ...formData,
        [fieldName]: newFieldValue,
      });
    } else if ((idx || idx === 0) && formData[fieldName].length > 1) {
      const [newFieldValue] = formData[fieldName].filter((i, id) => id !== idx);
      setFormData?.({
        ...formData,
        [fieldName]: newFieldValue,
      });
    } else {
      setFormData?.({
        ...formData,
        [fieldName]: multifile ? 'YES' : null,
      });
      setDropMode(true);
    }
  };
  const onViewItem = (idx: number) => {
    const file = formData[fieldName];
    const fileId: string = file[idx]?.id || file?.id;
    if (fileId) {
      window.open(`/shared-files/${fileId}?disposition=inline`, '_blank');
    } else {
      const url = URL.createObjectURL(file.length ? file[idx] : file);
      window.open(url, '_blank');
    }
  };

  const handleAddDocument = async () => {
    const res = await openFileDialog();
    if (res == null) {
      return;
    }
    handleChange(Array.from(res));
  };

  const DocumentCardList = ({
    files,
  }: {
    files: (File & { extension?: string })[];
  }) => (
    <div className="flex flex-col gap-2">
      {files.map((file, idx) => (
        <div key={`file${idx}`}>
          <DocumentCard
            filename={file.name}
            fileSize={file.size}
            fileExtension={
              file.extension ?? getFileExtensionFromName(file.name)
            }
            actions={
              actionsView(file) ? (
                actions
              ) : (
                <FormFilesActions
                  onRemoveItem={() => onRemoveItem(idx)}
                  onViewItem={() => onViewItem(idx)}
                  disabled={loading}
                />
              )
            }
            background="white"
          />
        </div>
      ))}
    </div>
  );

  return (
    <div className={cn(styles?.wrapper ?? 'w-full')}>
      {(formData[fieldName] === 'YES' ||
        (!formData[fieldName] && dropMode)) && (
        <div className="h-full">
          <UploadContainer
            label={config.label}
            buttonText={config.buttonText}
            onChange={handleChange}
            enabled={enabled}
            multiple={multiple}
          />
        </div>
      )}
      {!dropMode &&
        formData[fieldName] &&
        Array.isArray(formData[fieldName]) &&
        formData[fieldName].length > 0 && (
          <DocumentCardList files={formData[fieldName]} />
        )}
      {formData[fieldName] &&
        formData[fieldName] !== 'YES' &&
        !formData[fieldName].length && (
          <DocumentCardList files={[formData[fieldName]]} />
        )}
      {!dropMode && multifile && formData.sharedFilesLoad !== 'NO' && (
        <SmallAddingButton className="mt-2" onClick={handleAddDocument}>
          Add Document
        </SmallAddingButton>
      )}
    </div>
  );
};
export default FormFiles;
