import SourceTypeImage from '@/bundles/Shared/components/SourceType/SourceTypeImage';
import { LegalEntityManagementPeriod } from '@/entities/core/legalEntity/model';
import { cn } from '@/shared/lib/css/cn';
import { formatDate } from '@/shared/lib/formatting/dates';
import { useMemo } from 'react';

type ManagementPeriodItemProps = Partial<LegalEntityManagementPeriod> &
  PropsWithClassName;

export const ManagementPeriodItem = (props: ManagementPeriodItemProps) => {
  const {
    propertyManagementCompanyName,
    externalIdentifier,
    dateFrom,
    dateTo,
    erpSystem,
    className,
  } = props;

  const formattedDate = useMemo(() => {
    if (!dateFrom && !dateTo) return;

    if (dateFrom && !dateTo) {
      return `${formatDate(dateFrom, 'MMM DD, YYYY')} - Present`;
    }

    if (!dateFrom && dateTo) {
      return `Until ${formatDate(dateTo, 'MMM DD, YYYY')}`;
    }

    const formattedDateFrom = formatDate(dateFrom, 'MMM DD, YYYY');
    const formattedDateTo = formatDate(dateTo, 'MMM DD, YYYY');

    if (formattedDateFrom === formattedDateTo) return formattedDateFrom;

    return `${formattedDateFrom} - ${formattedDateTo}`;
  }, [dateFrom, dateTo]);

  return (
    <div className={cn('flex gap-2 p-2', className)}>
      <div className="flex-grow space-y-2">
        <div className="space-y-1">
          <div className="truncate text-sm leading-none text-neutral-850">
            {propertyManagementCompanyName}
          </div>

          {externalIdentifier && (
            <div className="text-xs leading-tight text-neutral-550">
              {externalIdentifier}
            </div>
          )}
        </div>
        {formattedDate && (
          <div className="text-xs leading-tight text-neutral-850">
            {formattedDate}
          </div>
        )}
      </div>
      {erpSystem && (
        <SourceTypeImage sourceType={erpSystem} className="size-4 shrink-0" />
      )}
    </div>
  );
};
