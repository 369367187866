import React, { useMemo } from 'react';
import Table from 'bundles/Shared/components/Table/Table';
import { IDevelopmentProjectBudgetCategory } from 'bundles/REconcile/types/IDevelopmentProject';
import { IColumn } from 'bundles/Shared/components/Table/types';
import { nameOf } from 'types/utils';
import { CurrencyFormatter } from 'stories/ValueFormatters/CurrencyFormatter';
import { PercentFormatter } from 'stories/ValueFormatters/PercentFormatter';
import useMediaQuery, {
  XL_1_5_WIDTH_MEDIA_QUERY,
} from '@/shared/lib/hooks/useMediaQuery';
import { cn } from '@/shared/lib/css/cn';

interface Props {
  items: IDevelopmentProjectBudgetCategory[];
}

const WRAPPER_CLASSES = {
  wrapper: 'inline-semibold justify-end',
};
const TOTAL_CLASSES =
  'bg-light-10 inline-semibold dark-60 !border-light-20 text-right';

function BudgetCategoriesTable({ items }: Props) {
  const totals = items.find((item) => item.name === 'Totals')!;
  const isXl15ScreenWidth = useMediaQuery(XL_1_5_WIDTH_MEDIA_QUERY);

  const getTotalCurrencyFormatter = (value: string) => (
    <div className="flex justify-end">
      <CurrencyFormatter
        classes={WRAPPER_CLASSES}
        hideFractions
        abbreviate={!isXl15ScreenWidth}
        value={value}
      />
    </div>
  );

  const getRowCurrencyFormatter = (value: string) => (
    <div className="flex justify-end">
      <CurrencyFormatter
        abbreviate={!isXl15ScreenWidth}
        hideFractions
        value={value}
      />
    </div>
  );

  const columns = useMemo<IColumn<IDevelopmentProjectBudgetCategory>[]>(
    () => [
      {
        text: 'Category',
        dataField: nameOf<IDevelopmentProjectBudgetCategory>('name'),
        headerAlign: 'start',
      },
      {
        text: 'Initial Budget',
        dataField: nameOf<IDevelopmentProjectBudgetCategory>('initialBudget'),
        formatter: ({ row }) => getRowCurrencyFormatter(row.initialBudget),
      },
      {
        text: 'Reallocations',
        dataField: nameOf<IDevelopmentProjectBudgetCategory>('reallocations'),
        formatter: ({ row }) => getRowCurrencyFormatter(row.reallocations),
      },
      {
        text: 'Revised Budget',
        dataField: nameOf<IDevelopmentProjectBudgetCategory>('revisedBudget'),
        formatter: ({ row }) => getRowCurrencyFormatter(row.revisedBudget),
      },
      {
        text: 'Funded to Date',
        dataField: nameOf<IDevelopmentProjectBudgetCategory>('fundedToDate'),
        formatter: ({ row }) => getRowCurrencyFormatter(row.fundedToDate),
      },
      {
        text: 'Balance',
        dataField: nameOf<IDevelopmentProjectBudgetCategory>('balance'),
        formatter: ({ row }) => getRowCurrencyFormatter(row.balance),
      },
      {
        text: '% Complete',
        dataField: nameOf<IDevelopmentProjectBudgetCategory>('percentComplete'),
        formatter: ({ row }) => (
          <div className="flex justify-end">
            <PercentFormatter value={row.percentComplete} />
          </div>
        ),
      },
    ],
    [],
  );

  return (
    <Table
      classes={{
        container: 'rounded-2xl overflow-hidden',
      }}
      defaultColumn={{
        headerClasses:
          'bg-dark-80 !label-semibold text-white !pt-2 border !border-t-0 !border-dark',
        headerAlign: 'end',
      }}
      borderLessOutside
      columns={columns}
      items={items.filter((item) => item.name !== 'Totals')}
      bottomRowColumns={[
        { id: 'name', text: 'TOTALS', className: 'text-left' },
        {
          id: 'initialBudget',
          text: getTotalCurrencyFormatter(totals.initialBudget),
        },
        {
          id: 'reallocations',
          text: <span className="inline-semibold text-dark-60">-</span>,
        },
        {
          id: 'revisedBudget',
          text: getTotalCurrencyFormatter(totals.revisedBudget),
        },
        {
          id: 'fundedToDate',
          text: getTotalCurrencyFormatter(totals.fundedToDate),
        },
        {
          id: 'balance',
          text: getTotalCurrencyFormatter(totals.balance),
        },
        {
          id: 'percentComplete',
          text: (
            <PercentFormatter
              classes={WRAPPER_CLASSES}
              value={totals.percentComplete}
            />
          ),
        },
      ].map((column) => ({
        ...column,
        className: cn(TOTAL_CLASSES, column.className),
      }))}
    />
  );
}

export default BudgetCategoriesTable;
