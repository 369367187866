import React from 'react';
import { Icon } from 'stories/Icon/Icon';
import { Tooltip } from 'stories/Tooltip/Tooltip';

const ChooseFolderTooltip = () => (
  <Tooltip
    arrowPosition="center"
    mainText="Use the radio button to select a folder from the list"
  >
    <Icon
      padding="xs"
      backgroundColor="var(--light-10)"
      className="rounded-[1rem]"
      iconName="question"
    />
  </Tooltip>
);

export default ChooseFolderTooltip;
