import { MIN_ITEMS_COUNT_FOR_SPLIT } from '@/bundles/REturn/components/workflows/createEntry/consts';

export interface IWorkflowItems {
  readonly isSelected: boolean;
  readonly isMultiple: boolean;
  readonly isSingle: boolean;
}

export class WorkflowItems implements IWorkflowItems {
  readonly isSelected: boolean;
  readonly isMultiple: boolean;
  readonly isSingle: boolean;

  constructor(items: unknown[]) {
    this.isSelected = Boolean(items.length);
    this.isMultiple = items.length >= MIN_ITEMS_COUNT_FOR_SPLIT;
    this.isSingle = items.length === 1;
  }
}
