import { ReactNode, SetStateAction } from 'react';
import {
  IFormItemCustom,
  IFormItemFiles,
  IFormItemInputDate,
  IFormItemInputNumber,
  IFormItemInputText,
  IFormItemInputTextArea,
  IFormItemListSelect,
  IFormItemOptionalSection,
  IFormItemSelect,
  IFormItemSelectedButtons,
  IFormItemTreeSelect,
} from '@/bundles/Shared/components/GroupForm/types/typesFormItem';
import { IFormItemViewText } from '@/bundles/Shared/components/GroupForm/types/typesFormViewItem';
import { IconsId } from 'types/sre-icons';

type TFormItemViewTypes = IFormItemViewText;

export interface IFormItemTypes {
  [FieldType.INPUT_TEXT]: IFormItemInputText;
  [FieldType.INPUT_TEXTAREA]: IFormItemInputTextArea;
  [FieldType.INPUT_NUMBER]: IFormItemInputNumber;
  [FieldType.INPUT_DATE]: IFormItemInputDate;
  [FieldType.LIST_SELECT]: IFormItemListSelect;
  [FieldType.TREE_SELECT]: IFormItemTreeSelect;
  [FieldType.SELECT]: IFormItemSelect;
  [FieldType.OPTIONAL_SECTION]: IFormItemOptionalSection;
  [FieldType.FILES]: IFormItemFiles;
  [FieldType.SELECTED_BUTTONS]: IFormItemSelectedButtons;
  [FieldType.CUSTOM]: IFormItemCustom;
  [FieldType.NOT_IMPLEMENT]: null;
}

export interface IFormItemTypesView {
  [FieldTypeView.VIEW_TEXT]: IFormItemViewText;
  [FieldTypeView.VIEW_FILES]: IFormItemInputText;
  [FieldTypeView.VIEW_CUSTOM]: IFormItemInputText;
  [FieldTypeView.NOT_IMPLEMENT]: null;
}

export function generateTypeFormItem<
  TypeItem extends keyof IFormItemTypes,
  TypeViewItem extends keyof IFormItemTypesView = FieldTypeView.NOT_IMPLEMENT,
>(
  dataItem: IFormItemTypesView[TypeViewItem] extends TFormItemViewTypes
    ? IFormItemTypesView[TypeViewItem] | IFormItemTypes[TypeItem]
    : IFormItemTypes[TypeItem],
) {
  return dataItem;
}

export interface ISettingsForm {
  formData: Record<string, unknown>;
  setFormData?: React.Dispatch<React.SetStateAction<Record<string, unknown>>>;
  validate?: object;
  setValidate?: SetStateAction<object>;
  validation?: {
    message: string;
    func: (val: any) => void;
  };
  loading?: boolean;
}

export enum FieldTypeView {
  VIEW_TEXT = 'VIEW_TEXT',
  VIEW_FILES = 'VIEW_FILES',
  VIEW_CUSTOM = 'VIEW_CUSTOM',
  NOT_IMPLEMENT = 'NOT_IMPLEMENT',
}

export enum FieldType {
  INPUT_TEXT = 'INPUT_TEXT',
  INPUT_TEXTAREA = 'INPUT_TEXTAREA',
  INPUT_NUMBER = 'INPUT_NUMBER',
  INPUT_DATE = 'INPUT_DATE',
  SELECT = 'SELECT',
  LIST_SELECT = 'LIST_SELECT',
  TREE_SELECT = 'TREE_SELECT',
  CREATABLE_SELECT = 'CREATABLE_SELECT',
  TUMBLER = 'TUMBLER',
  OPTIONAL_SECTION = 'OPTIONAL_SECTION',
  FILES = 'FILES',
  SELECTED_BUTTONS = 'SELECTED_BUTTONS',
  CUSTOM = 'CUSTOM',
  NOT_IMPLEMENT = 'NOT_IMPLEMENT',
}

export interface IGroupFormItem {
  title: string;
  required?: boolean;
  activated?: boolean;
  formItems: IFormItem[];
  width?: string;
  styles?: {
    wrapper?: string;
    input?: string;
    size?: string;
  };
}

export interface IFormItem {
  fieldName: string;
  type?: FieldType;
  typeView?: FieldTypeView;
  title?: string;
  note?: ReactNode;
  titleView?: string;
  emptyView?: ReactNode;
  disabledTitleView?: boolean;
  placeholder?: string;
  fieldNameSnap?: string;
  formData?: any;
  required?: boolean;
  optionalView?: boolean;
  hint?: {
    content: ReactNode | ReactNode[];
    visibleTrigger?: boolean;
  };
  setFormData?: (newData: any) => void;
  loading?: boolean;
  styles?: {
    wrapper?: string;
    input?: string;
    input__item?: string;
    size?: string;
    type?: string;
    nomb?: boolean;
  };
  transformerView?: (item: any, formData: any) => void;
  viewComponent?: any[];
  enabled?: boolean;
  thousandSeparator?: boolean;
  disabled?: boolean;
  error?: string;
  validation?: {
    message: string;
    func: (val: any) => void;
  };
  validate?: object;
  setValidate?: SetStateAction<object>;
  contentViewIndepented?: boolean;
  actions?: boolean;
  actionsView?: (val: any) => boolean;
  menuPosition?: string;
  isMulti?: boolean;
}

export interface IFormButton {
  titleBtn: string;
  titleView?: string;
  titleLoading?: string;
  iconName?: IconsId;
  withoutCancel?: boolean;
  width?: string;
  message?: ReactNode | string;
  funcSendForm?: (value?: any) => void;
  funcCancel?: () => void;
  iconPosition?: 'right' | 'left';
  stylesForMainWrapper?: string;
  withoutButtons?: boolean;
}
