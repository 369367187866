import * as React from 'react';
import { ComponentProps } from 'react';
import FilterItem from '@/bundles/Shared/components/Filters/common/filterItem/FilterItem';
import Segment from '@/bundles/Shared/components/Filters/segment/Segment';

interface Props extends Omit<ComponentProps<typeof FilterItem>, 'rightIcon'> {
  onClick: () => void;
  segmentLabel?: string;
}

function SegmentFilterItem({ segmentLabel, onClick, ...props }: Props) {
  return (
    <FilterItem
      rightIcon={
        segmentLabel && (
          <Segment
            onClick={onClick}
            label={segmentLabel}
            disabled={props.hidden}
          />
        )
      }
      {...props}
    />
  );
}

export default SegmentFilterItem;
