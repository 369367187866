import { ReportThreshold } from '@/entities/report/threshold/model';
import { useExportThresholdWithOverrides } from '@/features/report/threshold/export/api';
import { ExportButton } from '@/shared/ui/ExportButton';

export const ExportThresholdWithOverridesButton = ({
  threshold,
}: {
  threshold: ReportThreshold;
}) => {
  const [handleExport, options] = useExportThresholdWithOverrides(threshold);

  return (
    <ExportButton
      onClick={handleExport}
      loading={options.isLoading}
      disabled={options.isLoading}
    />
  );
};
