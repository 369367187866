import React, { ComponentProps } from 'react';
import { cn } from '@/shared/lib/css/cn';
import { TFireStationPriority } from 'bundles/FireStation/types';
import { FireIcon } from '@/bundles/FireStation/components/FireIcon/FireIcon';
import Tag from '@/stories/Tags/Tag/Tag';
import styles from '@/bundles/FireStation/components/FireTag/FireTag.module.scss';

interface Props
  extends Omit<
    ComponentProps<typeof Tag>,
    'startIcon' | 'endIcon' | 'variant' | 'label'
  > {
  priority: TFireStationPriority;
  selected?: boolean;
}

export const FireTag = ({ className, selected, priority, ...props }: Props) => (
  <Tag
    className={cn(
      styles['sre-fire-tag'],
      className,
      selected && styles._selected,
      priority && styles[`sre-fire-tag_${priority}`],
    )}
    selected={selected}
    priority={priority}
    capitalized
    label={priority}
    startIcon={<FireIcon priority={priority} />}
    {...props}
  />
);

export default FireTag;
