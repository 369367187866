import { Button } from '@/stories/Button/Button';
import { Dropdown } from '@/stories/Dropdown/Dropdown';
import { DropdownItem } from '@/stories/Dropdown/DropdownItem/DropdownItem';
import { XYChart } from '@amcharts/amcharts5/xy';
import type { WidgetDateGranularity } from 'bundles/Shared/entities/dashboard/model/types/types';
import { GRANULARITIES } from 'bundles/Shared/widgets/dashboard/widgets/common/config';
import { WidgetStateProps } from 'bundles/Shared/widgets/dashboard/widgets/model';
import { capitalize } from 'lodash-es';
import React from 'react';

export function WidgetStateGranularity({
  state,
  onStateChange,
  granularities = GRANULARITIES,
  chartRef,
}: WidgetStateProps<{
  granularity: WidgetDateGranularity;
}> & {
  granularities?: readonly WidgetDateGranularity[];
  chartRef?: React.MutableRefObject<XYChart | null>;
}) {
  const handleGranularityChange = (granularity: WidgetDateGranularity) => {
    chartRef?.current?.xAxes?.getIndex(0)?.set('baseInterval', {
      timeUnit: granularity,
      count: 1,
    });
    onStateChange({
      ...state,
      granularity,
    });
  };
  return (
    <Dropdown
      items={granularities.map((g) => (
        <DropdownItem
          key={g}
          active={state.granularity === g}
          onClick={() => handleGranularityChange(g)}
        >
          {capitalize(g)}
        </DropdownItem>
      ))}
    >
      <Button size="s" variant="secondary">
        <span className="text-neutral-500">Group By:</span>
        {capitalize(state.granularity)}
      </Button>
    </Dropdown>
  );
}
