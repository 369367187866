import { ValueGetterFunc, ColDef } from 'ag-grid-community';
import { AutoGroupCellRenderer } from '@/bundles/Shared/components/AgGrid/Table/cellComponents/AutoGroupCellRenderer';
import { AutoGroupHeaderComponent } from '@/bundles/Shared/components/AgGrid/Table/cellComponents/AutoGroupHeaderComponent';

export const NEXT_GEN_TABLE_CONFIG = {
  row: {
    default: {
      minHeight: 32,
    },
    divider: {
      minHeight: 16,
    },
  },
  column: {
    default: {
      width: 124,
    },
    mtdAndTotal: {
      width: 108,
    },
    totals: {
      width: 148,
    },
    defaultDef: {
      flex: 1,
      suppressMovable: true,
      suppressMenu: true,
    },
    autoGroupDef: {
      flex: 1,
      pinned: true,
      resizable: true,
      suppressMenu: true,
      headerComponent: AutoGroupHeaderComponent,
      headerName: '',
      cellRenderer: AutoGroupCellRenderer,
      valueGetter: (({ node }) =>
        node?.data?.label ?? 'Total') as ValueGetterFunc,
      tooltipValueGetter: (({ node }) =>
        node?.data?.label ?? 'Total') as ValueGetterFunc,
    } satisfies ColDef,
  },
} as const;

export const DEFAULT_TABLE_ROW_PATH_DELIMITER = '/';
