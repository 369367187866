import { Button } from '@/stories/Button/Button';

interface Props {
  description?: string;
  onClick?: () => void;
  buttonText: string;
}

export const ModalAdditionalActions = ({
  description,
  onClick,
  buttonText,
}: Props) => (
  <div>
    {description && (
      <p className="light-60 inline-regular mb-m text-center">{description}</p>
    )}
    {onClick && (
      <Button onClick={onClick} variant="secondary" fluid>
        {buttonText}
      </Button>
    )}
  </div>
);

export default ModalAdditionalActions;
