import { Icon } from 'stories/Icon/Icon';
import { Tooltip } from 'stories/Tooltip/Tooltip';
import { cn } from '@/shared/lib/css/cn';
import { TooltipProps } from 'stories/Tooltip/Tooltip';
import { IConnection } from 'bundles/Shared/entities/connection/model';
import { PARSER_STATUS_UI_CONFIG } from 'bundles/Shared/entities/connection/config';

interface IParserStatus extends Partial<React.PropsWithChildren<TooltipProps>> {
  status: IConnection['parserStatus'];
  description?: string;
}

const ParserIcon = ({ status }: { status: IParserStatus['status'] }) => (
  <Icon
    className={cn(
      'flex h-[16px] w-[16px] items-center justify-center rounded-2xl text-white',
      PARSER_STATUS_UI_CONFIG[status].bgColor,
    )}
    iconName={PARSER_STATUS_UI_CONFIG[status].iconName}
  />
);

export const ParserStatus = ({
  status,
  description,
  ...props
}: IParserStatus) =>
  description ? (
    <Tooltip titleText={description} {...props}>
      <ParserIcon status={status} />
    </Tooltip>
  ) : (
    <ParserIcon status={status} />
  );
