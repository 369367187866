import { investmentIncludesClass } from '@/bundles/REturn/components/Ownership/modals/capitalInvestmentsEditor/utils';
import { useCapitalInvestments } from '@/bundles/REturn/hooks/useCapitalInvestments';
import { CapitalInvestmentClass } from '@/entities/return';
import Table from 'bundles/Shared/components/Table/Table';
import TablePagination from 'bundles/Shared/components/Table/pagination/TablePagination';
import useDebounce from '@/shared/lib/hooks/useDebounce';
import { useMemo, useState } from 'react';
import { useComputedInvEntities } from '@/bundles/REturn/components/workflows/createEntry/hooks/useComputedInvEntities';
import { useInvestmentEntitiesColumns } from '@/bundles/REturn/components/workflows/createTransfer/hooks/columns/useInvestmentEntitiesColumns';
import { InlineAlert } from '@/stories/InlineAlert/InlineAlert';
import { ISortSettings } from '@/bundles/Shared/components/Table/types';

export interface SelectInvEntProps {}

export function SelectInvestmentEntityStep({
  selectedSourceInvestmentEntity,
  setSelectedSourceInvestmentEntity,
  selectedRows,
  setSelectedRows,
  excludedIds = [],
}: SelectInvEntProps) {
  const [query, setQuery] = useState('');
  const [sortSettings, setSortSettings] = useState<ISortSettings>({});
  const debouncedQuery = useDebounce(query, 250);
  const { data: capitalInvestmentsData } = useCapitalInvestments();
  const capitalInvestments = capitalInvestmentsData ?? [];

  const computedCapitalInvestments = useComputedInvEntities({
    capitalInvestments,
    query: debouncedQuery,
  }).filter((ci) => !excludedIds.includes(ci.id));

  const [filterModel, setFilterModel] = useState<{
    investmentEntity?: CapitalInvestmentClass | 'all';
  }>({});

  const investmentClass = filterModel.investmentEntity;

  const filteredItems = useMemo(() => {
    let result = [];
    if (investmentClass == null || investmentClass === 'all')
      result = computedCapitalInvestments;

    result = computedCapitalInvestments.filter(
      (ci) =>
        investmentClass === undefined ||
        investmentIncludesClass(ci, investmentClass),
    );

    if (filterModel['legalEntity.name']?.length) {
      result = result.filter((ci) =>
        filterModel['legalEntity.name']
          .map((le) => le.name)
          .includes(ci.legalEntity.name),
      );
    }

    if (sortSettings.sortField == 'legalEntity.name') {
      result =
        sortSettings.sortOrder === 'asc'
          ? result.sort((a, b) =>
              a.legalEntity.name.localeCompare(b.legalEntity.name),
            )
          : result.sort((a, b) =>
              b.legalEntity.name.localeCompare(a.legalEntity.name),
            );
    }

    return result;
  }, [filterModel, computedCapitalInvestments]);

  const columns = useInvestmentEntitiesColumns({
    capitalInvestments: filteredItems,
    filterModel,
    setFilterModel,
    legalEntities: computedCapitalInvestments
      .map((ci) => ci.legalEntity)
      .filter(
        (item, index, self) =>
          index === self.findIndex((obj) => obj.id === item.id),
      ),
  });

  return (
    <div className="mr-[480px] flex w-full flex-col gap-4 overflow-x-auto bg-light-10 p-6">
      <div className="flex flex-col gap-2.5">
        <h6 className="header6-regular">Investment Entities</h6>
        {excludedIds?.length > 0 && (
          <InlineAlert
            status="info"
            className="w-full"
            message="You can select multiple legal entities if the transfer needs to be split"
          />
        )}
      </div>

      <div className="flex flex-col gap-2">
        <div className="flex items-center justify-end gap-2">
          <TablePagination
            className="mr-auto"
            loading={false}
            currentPage={1}
            totalSize={capitalInvestments.length}
            sizePerPage={capitalInvestments.length}
          />
          {/* <SearchInput
            placeholder="Search"
            value={query}
            size="s"
            resetValue={() => setQuery('')}
            onChange={(e) => setQuery(e.target.value)}
          /> */}
        </div>

        <Table
          singleSelectedRow={selectedSourceInvestmentEntity}
          setSingleSelectedRow={
            setSelectedSourceInvestmentEntity
              ? (item) => {
                  setSelectedSourceInvestmentEntity(item);
                }
              : undefined
          }
          selectedRows={selectedRows}
          setSelectedRows={
            setSelectedRows
              ? (e) => {
                  setSelectedRows(e);
                }
              : undefined
          }
          items={filteredItems}
          columns={columns}
          settings={sortSettings}
          setSettings={setSortSettings}
        />
      </div>
    </div>
  );
}
