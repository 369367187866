import QueryString, { stringify } from 'qs';
import queryString from 'query-string';

export const DEFAULT_STRINGIFY_OPTIONS: QueryString.IStringifyOptions = {
  arrayFormat: 'brackets',
  addQueryPrefix: true,
} as const;

// workaround for empty array []
export const getOptionsForEmptyArray = (): QueryString.IStringifyOptions => {
  const mapEmptyArray = (a: unknown) =>
    Array.isArray(a) && a.length === 0 ? [null] : a;

  return {
    filter: (_prefix, v) => mapEmptyArray(v),
  };
};

export const DEFAULT_STRINGIFY_OPTIONS_WITH_EMPTY_ARRAY = {
  ...DEFAULT_STRINGIFY_OPTIONS,
  ...getOptionsForEmptyArray(),
};

/**
 * Similar to `import stringify from 'qs'` but works with is `URLSearchParams` instead of an object
 */
export const stringifySearchParams = (
  params: URLSearchParams,
  options?: QueryString.IStringifyOptions,
): string =>
  stringify(queryString.parse(params.toString()), {
    ...DEFAULT_STRINGIFY_OPTIONS_WITH_EMPTY_ARRAY,
    ...options,
  });

export const prettyJsonStringify = (json: unknown) => {
  return JSON.stringify(json, null, 2);
};
