import Select from 'react-select';
import { Button } from 'stories/Button/Button';
import { Modal } from 'stories/Modals/Modal/Modal';
import React, { useCallback, useEffect, useState } from 'react';
import { plainFetchAssets } from 'bundles/Assets/actions';
import http from 'lib/http';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import { IAsset } from 'types/Asset';
import { coreFundsAssetsSettingsApi } from 'bundles/Funds/api/settingsCoreFundsAssetsApi';

interface Props extends DialogProps {
  fundId: number;
  fundName: string;
  fundAssets: IAsset[];
}

const SetAssetModal = ({
  onClose,
  onSubmit,
  fundId,
  fundName,
  fundAssets,
}: Props) => {
  const [selectedAssets, setSelectedAssets] = useState<
    readonly {
      value: number;
      label: string;
    }[]
  >([]);

  const { data: assetOptions, isLoading: isAssetOptionsLoading } =
    coreFundsAssetsSettingsApi.useGetApiSettingsCoreFundsAssetsQuery();

  const handleSubmit = () => {
    http
      .post(`/funds/${fundId}/link`, {
        asset_ids: selectedAssets.map((asset) => asset.value),
      })
      .then(() => {
        onSubmit?.();
        onClose();
      });
  };

  return (
    <Modal
      toggle={onClose}
      header={`Set assets for ${fundName}`}
      size="400"
      actions={
        <Button
          variant="success"
          onClick={handleSubmit}
          fluid
          className="mt-4"
          disabled={!selectedAssets}
        >
          Set asset
        </Button>
      }
      classes={{
        header: 'bg-light',
        body: 'bg-light',
        footer: 'bg-light',
      }}
    >
      <Select
        isLoading={isAssetOptionsLoading}
        menuPosition="fixed"
        menuShouldBlockScroll
        isClearable
        isMulti
        defaultValue={fundAssets.map((asset) => ({
          value: asset.id,
          label: asset.name,
        }))}
        onChange={(option) => setSelectedAssets(option)}
        options={(assetOptions ?? []).map((asset) => ({
          value: asset.id,
          label: asset.name,
        }))}
        classNamePrefix="react-select"
        placeholder="Select asset..."
      />
    </Modal>
  );
};

export default SetAssetModal;
