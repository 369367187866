/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-unstable-nested-components */
import { RouteComponentProps } from '@reach/router';
import {
  useCreateBulkSharedFileMutation,
  useDeleteBulkFoldersMutation,
  useDeleteBulkSharedFileMutation,
  useGetDocumentTypesQuery,
  useGetObjectFoldersQuery,
  useGetSharedFilesQuery,
  useToggleLockSharedFileMutation,
  useUpdateBulkFoldersMutation,
  useUpdateBulkSharedFilesMutation,
  useUpdateSharedFileMutation,
} from 'bundles/Assets/components/Media/api/objectFoldersApi';
import DocumentsPopover from 'bundles/Shared/components/DocumentsPopover/DocumentsPopover';
import PermissionsModalDocsHeader from 'bundles/Shared/components/PermissionsModalDocsHeader';
import ShownAsButton from 'bundles/Shared/components/ShownAsButton';
import TableAppliedFilters from 'bundles/Shared/components/Table/filters/TableAppliedFilters';
import {
  prepareSelectedFilters,
  resetFilter,
} from 'bundles/Shared/components/Table/filters/helpers';
import {
  sharedFilePermissions,
  typedSharedFilePermissions,
} from 'bundles/Shared/sharedFilePermissions';
import { cn } from '@/shared/lib/css/cn';
import canManageObject, { getProductName } from 'lib/InvestmentObject';
import { useModal } from '@/shared/lib/hooks/useModal';
import { currentUserIsExternal } from 'lib/permissions';
import { bulkDownload } from 'lib/sharedFile';
import { snakeCase } from 'lodash-es';
import pluralize from 'pluralize';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
  changeNodeAtPath,
  getFlatDataFromTree,
  getTreeFromFlatData,
  getVisibleNodeCount,
  map,
} from 'react-sortable-tree';
import { AnimationLoader } from 'stories/AnimationLoader/AnimationLoader';
import { Button } from 'stories/Button/Button';
import { Icon } from 'stories/Icon/Icon';
import { IconButton } from 'stories/IconButton/IconButton';
import { LinkButton } from 'stories/LinkButton/LinkButton';
import { ModalHeaderWithIcon } from 'stories/Modals/ModalHeader/ModalHeaderWithIcon/ModalHeaderWithIcon';
import { MultipleItemsIcons } from 'stories/MultipleItemsIcons/MultipleItemsIcons';
import { TreeFolderNavigation } from 'stories/TreeFolderNavigation/TreeFolderNavigation';
import { FAKE_ROOT_NODE_ID } from 'stories/TreeFolderNavigation/TreeFolderNavigation';
import { IPermissions as IOldPermissions } from 'types/Permissions';
import { ISharedDocument } from 'types/SharedDocument';
import EntityContext from '@/bundles/Shared/EntityContext';
import BulkActionsPanel from '@/bundles/Shared/components/BulkActionsPanel/BulkActionsPanel';
import MoveItemsModal from '@/bundles/Shared/components/MoveItemsModal';
import PermissionsModal from '@/bundles/Shared/components/PermissionsModal';
import Table from '@/bundles/Shared/components/Table/Table';
import TableSearch from '@/bundles/Shared/components/Table/TableSearch';
import TablePagination from '@/bundles/Shared/components/Table/pagination/TablePagination';
import findNodeById from '@/bundles/Shared/sortableTreeHelpers';
import AssetFolderBreadcrumbs from '@/bundles/Assets/components/AssetFolderBreadcrumbs';
import NewFolderModal from '@/bundles/Assets/components/Folders/NewFolderModal';
import BulkUploaderModal from '@/bundles/Assets/components/SharedFiles/BulkUploaderModal';
import EditSharedFileModal from '@/bundles/Assets/components/SharedFiles/EditSharedFileModal';
import { assetDocumentsColumns } from '@/bundles/Assets/components/SharedFiles/Table/generateDefaultColumns';
import TypeModal from '@/bundles/Assets/components/SharedFiles/TypeModal/TypeModal';
import SubfoldersList from '@/bundles/Assets/components/SubfoldersList';
import { SELECTION_COLUMN_CLASSES } from '@/bundles/Assets/components/consts';
import { asserts } from 'lib/typeHelpers/assertsType';
import { Permitted } from 'bundles/Shared/entities/permissions';

export const generateSortableTreeButtons = (
  node,
  folders,
  currentUserCanManage,
  openNewFolderModal,
  onDelete,
  hideActions = false,
) => {
  const sortableTreeButtons = [];
  const folder = folders.find(({ id }) => node.id === id);

  if (node.id === FAKE_ROOT_NODE_ID) {
    sortableTreeButtons.push(
      <IconButton
        iconName="addSmall"
        onClick={() => openNewFolderModal(undefined, undefined)}
      />,
    );
  }
  if (folder?.internal || !currentUserCanManage || hideActions)
    return sortableTreeButtons;

  if (folder && !hideActions) {
    sortableTreeButtons.push(
      <IconButton
        iconName="addSmall"
        onClick={() => openNewFolderModal(undefined, node.id)}
      />,
      <IconButton
        iconName="edit"
        onClick={() => openNewFolderModal(folder, node.parentId)}
      />,
    );

    if (folder.sharedFilesCount === 0) {
      sortableTreeButtons.push(
        <IconButton iconName="trash" onClick={() => onDelete(folder)} />,
      );
    }
  }

  return sortableTreeButtons;
};

const nodeHasChildren = (node) => node.children && node.children.length > 0;
const getNodeKey = ({ treeIndex }) => treeIndex;

export const expandNodesWithChildren = ({ tree, expanded }) =>
  map({
    treeData: tree,
    callback: ({ node }) => ({
      ...node,
      expanded: expanded && nodeHasChildren(node),
    }),
    ignoreCollapsed: false,
    getNodeKey,
  });

// TODO
// Refactor with FSD

const AssetDocuments = ({
  selectedRows,
  setSelectedRows,
  wide = false,
  hideActions = false,
  hideTreeNavigation = false,
}: {
  selectedRows: ISharedDocument[];
  setSelectedRows: (rows: ISharedDocument[]) => void;
  wide?: boolean;
  hideActions?: boolean;
  hideTreeNavigation?: boolean;
} & RouteComponentProps) => {
  const investmentObject = useContext(EntityContext)!;
  const [moveSharedFileModalOpened, setMoveSharedFileModalOpened] =
    useState(false);
  const [currentMovingIds, setCurrentMovingIds] = useState([]);
  const { openModal, confirm } = useModal();
  const [editSharedFileModalOpened, setEditSharedFileModalOpened] =
    useState(false);
  const [documentTypesModalOpened, setDocumentTypesModalOpened] =
    useState(false);
  const [editingSharedFile, setEditingSharedFile] = useState(null);
  const [newFolderModalOpened, setNewFolderModalOpened] = useState(false);
  const [parentId, setParentId] = useState(null);
  const [folderToEdit, setFolderToEdit] = useState<File | null>(null);
  const [disabledFolderIds, setDisabledFolderIds] = useState([]);
  const defaultFolderId = parseInt(location.search.split('folderId=')[1], 10);
  const [pageParams, setPageParams] = useState({
    currentPage: 1,
    sizePerPage: 10,
    sortField: 'updated_at',
    sortOrder: 'desc',
    searchQuery: '',
    folderId: defaultFolderId || undefined,
    filters: {
      shownAsUser: undefined,
      only_public: undefined,
      documentTypes: [],
    },
  });

  const entityId = investmentObject.entity.id;
  const entityName = investmentObject.entity.name;
  const entityType = investmentObject.type;
  const selectedFilters = pageParams.filters;

  const {
    currentPage,
    sizePerPage,
    sortField,
    sortOrder,
    searchQuery,
    folderId,
  } = pageParams;
  const { shownAsUser } = selectedFilters;

  const preparedParams = {
    sort_order: snakeCase(sortOrder),
    sort_field: snakeCase(sortField),
    search_query: searchQuery,
    page: currentPage,
    size_per_page: sizePerPage,
    entity_id: entityId,
    entity_type: entityType,
    folder_id: folderId,
    filters: {
      shown_as_user_id: shownAsUser?.id,
      only_public: selectedFilters?.onlyPublic,
      document_type_ids: selectedFilters?.documentTypes?.map(({ id }) => id),
    },
  };

  const {
    data: sharedFilesData,
    isLoading: isSharedFilesDataLoading,
    isFetching: isSharedFilesDataFetching,
  } = useGetSharedFilesQuery(preparedParams);
  const sharedFiles = sharedFilesData?.sharedFiles ?? [];
  const totalSize = sharedFilesData?.meta.totalSize;

  const setCurrentPage = (page, size) =>
    setPageParams({
      ...pageParams,
      sizePerPage: size ?? sizePerPage,
      currentPage: page,
    });
  const setSizePerPage = (size, page) =>
    setPageParams({
      ...pageParams,
      currentPage: page,
      sizePerPage: size,
    });
  const setSearchQuery = (query) =>
    setPageParams({
      ...pageParams,
      searchQuery: query,
      currentPage: 1,
    });

  const setSelectedFilters = (filters) =>
    setPageParams({
      ...pageParams,
      currentPage: 1,
      filters: {
        ...selectedFilters,
        ...filters,
      },
    });

  const [deleteBulkSharedFiles] = useDeleteBulkSharedFileMutation();
  const [toggleLockSharedFile] = useToggleLockSharedFileMutation();
  const [updateBulkSharedFiles] = useUpdateBulkSharedFilesMutation();
  const [createBulkSharedFiles] = useCreateBulkSharedFileMutation();
  const [updateSharedFile] = useUpdateSharedFileMutation();

  const [upadteBulkFolders] = useUpdateBulkFoldersMutation();
  const [deleteBulkFolders] = useDeleteBulkFoldersMutation();

  const [treeData, setTreeData] = useState([]);
  const [currentFolder, setCurrentFolder] = useState();
  const [bulkUploaderModalOpened, setBulkUploaderModalOpened] = useState(false);
  const [moveFolderModalOpened, setMoveFolderModalOpened] = useState(false);

  const {
    data: foldersData,
    isLoading: isFoldersDataLoading,
    isFetching: isFoldersDataFetching,
  } = useGetObjectFoldersQuery({
    entityId,
    entityType,
    folderType: 'documents',
  });
  const { data: documentTypesData } = useGetDocumentTypesQuery({
    with_documents: true,
    shared_file_documentable_id: entityId,
    shared_file_documentable_type: entityType,
  });
  const documentTypes = documentTypesData;
  const folders = foldersData ?? [];

  const treeDataRef = useRef();
  treeDataRef.current = treeData;

  const filteredFolders = folders
    .filter((folder) => folder.folderType === 'documents')
    .sort((a, b) => a.title.localeCompare(b.title) || b.id - a.id);

  const foldersRef = useRef();
  foldersRef.current = filteredFolders;

  const recursivelyExpand = ({ tree, node, path }) => {
    const newTreeData = changeNodeAtPath({
      treeData: tree,
      path,
      newNode: { ...node, expanded: true },
      getNodeKey,
    });

    if (node.parentId) {
      const foundNode = findNodeById(newTreeData, node.parentId);
      recursivelyExpand({
        tree: newTreeData,
        node: foundNode.node,
        path: foundNode.path,
      });
    } else {
      setTreeData(newTreeData);
    }
  };

  const onFolderChange = ({ tree, newParams, withoutScroll = false }) => {
    const newTreeData = map({
      treeData: tree,
      getNodeKey,
      callback: ({ node }) => ({
        ...node,
        expanded: node.expanded && nodeHasChildren(node),
      }),
      ignoreCollapsed: false,
    });

    const folder = foldersRef.current.find(
      ({ id }) => id === newParams.folder_id,
    );
    const params = {
      ...pageParams,
      folderId: newParams.folder_id,
      currentPage: 1,
    };
    setPageParams(params);
    setCurrentFolder(folder);
    if (folder) {
      const { node, path } = findNodeById(newTreeData, folder.id);
      recursivelyExpand({ tree: newTreeData, node, path });
    } else {
      setTreeData(newTreeData);
    }
    if (!withoutScroll) window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    const defaultFolder = folders.find(({ id }) => id === defaultFolderId);
    const newTreeData = getTreeFromFlatData({
      flatData: filteredFolders.map((folder) => ({
        ...folder,
        expanded:
          folder.id === defaultFolderId ||
          treeData.find(({ id }) => id === folder.id)?.expanded,
      })),
      rootKey: null,
    });

    setTreeData(newTreeData);

    if (defaultFolder) {
      history.replaceState &&
        history.replaceState(
          null,
          '',
          location.pathname +
            location.search
              .replace(/[\?&]folderId=[^&]+/, '')
              .replace(/^&/, '?'),
        );
    }

    if (defaultFolderId && folders.length > 0) {
      onFolderChange({
        tree: newTreeData,
        newParams: { folder_id: defaultFolderId },
        withoutScroll: true,
      });
    }
    if (currentFolder)
      setCurrentFolder(folders.find(({ id }) => currentFolder.id === id));
  }, [folders]);

  const onDelete = async (folder) => {
    const confirmed = await confirm({
      title: 'Delete folder',
      subtitle: 'Are you sure you want to delete folder?',
    });

    if (confirmed) {
      await deleteBulkFolders({ ids: [folder.id] });
    }
  };

  const updateItem = async (data) => {
    await updateSharedFile(data);
  };

  const openMoveFolderModal = (folder: File & { id: number }) => {
    setFolderToEdit(folder);
    const folderNode = findNodeById(treeData, folder.id);
    asserts(folderNode);

    const folderWithChildrenNodes = getFlatDataFromTree({
      treeData: [folderNode.node],
      getNodeKey,
      ignoreCollapsed: false,
    });

    setDisabledFolderIds([
      ...folderWithChildrenNodes.map((n) => n.node.id),
      ...folders.filter((f) => f.internal).map(({ id }) => id),
    ]);
    setMoveFolderModalOpened(true);
  };

  const submitPermissions = (data: Permitted & Pick<ISharedDocument, 'id'>) => {
    const preparePermissionsData = (
      permissions: Permitted & Pick<ISharedDocument, 'id'>,
    ) => ({
      id: permissions.id,
      ...typedSharedFilePermissions(permissions),
    });
    const sharedFilePermissionsReqBody = preparePermissionsData(data);
    updateItem({ shared_file: sharedFilePermissionsReqBody });
  };

  const bulkUpdatePermissions = async (data: IOldPermissions) => {
    const params = {
      shared_file_ids: selectedRows.map((file) => file.id),
      ...sharedFilePermissions(data),
    };
    await updateBulkSharedFiles(params);

    setSelectedRows([]);
  };

  const bulkUpdateDocumentType = async (data: { id: number }) => {
    const params = {
      shared_file_ids: selectedRows.map((file) => file.id),
      document_type_id: data.id,
    };

    await updateBulkSharedFiles(params);

    setSelectedRows([]);
  };

  const handleRemove = async (ids: number[]) => {
    const result = await confirm({
      title: 'Remove file',
      subtitle: <p>Are you sure you want to remove file?</p>,
    });

    if (result) {
      await deleteBulkSharedFiles({ ids });
    }
  };

  const toggleLockPermissions = async (id: number) => {
    const result = await confirm({
      title: 'Lock file permissions',
      subtitle: <p>Are you sure you want to lock file permissions?</p>,
      actions: {
        primaryButton: {
          text: 'Yes',
          variant: 'danger',
        },
        secondaryButton: {
          text: 'Cancel',
          variant: 'secondary',
        },
      },
    });
    if (result) {
      await toggleLockSharedFile({ id });
    }
  };

  const openNewFolderModal = (folder, newParentId) => {
    setNewFolderModalOpened(false);
    setFolderToEdit(folder);
    setParentId(newParentId);
    setNewFolderModalOpened(true);
  };

  const currentUserCanManage = canManageObject({
    id: entityId,
    objectType: entityType,
  });

  const bulkActionsAvailable = selectedRows
    .map(
      ({ documentType, documentable }) =>
        !documentType.internal &&
        Boolean(documentable) &&
        canManageObject({
          id: documentable.id,
          objectType: documentable.objectType,
        }),
    )
    .every(Boolean);

  const bulkSetPermissionsAvailable =
    bulkActionsAvailable &&
    selectedRows.every(({ permissionsLocked }) => !permissionsLocked);

  const isTreeExpanded =
    getVisibleNodeCount({ treeData }) !== filteredFolders.length;

  const subfolders = filteredFolders.filter((folder) =>
    currentFolder
      ? folder.parentId === currentFolder.id
      : folder.parentId === null,
  );

  const subfoldersCount = subfolders.length;

  const onNodeClick = (node) => {
    onFolderChange({
      tree: treeData,
      newParams: {
        folder_id: node.id === FAKE_ROOT_NODE_ID ? undefined : node.id,
      },
      withoutScroll: true,
    });
  };

  const openMoveItemsModal = (items) => {
    setMoveSharedFileModalOpened(true);
    setCurrentMovingIds(items);
    setDisabledFolderIds(folders.filter((f) => f.internal).map(({ id }) => id));
  };

  const localResetFilter = (key, id) =>
    resetFilter(setSelectedFilters, selectedFilters, key, id);

  const columns = assetDocumentsColumns(
    {
      move: openMoveItemsModal,
      toggleLockPermissions,
      edit: (file) => {
        setEditingSharedFile(file);
        setEditSharedFileModalOpened(true);
      },
      submitPermissions,
      remove: handleRemove,
      handleFolderClick: (folder) =>
        onFolderChange({
          tree: treeDataRef.current,
          newParams: { folder_id: folder.id },
        }),
      setSelectedFilters,
      resetFilter: localResetFilter,
    },
    hideActions,
    { objectType: investmentObject.type, id: investmentObject.entity.id },
    { selectedFilters },
    { documentTypes },
  );

  const collapsedTreeData = expandNodesWithChildren({
    tree: treeData,
    expanded: false,
  });

  const handleCreate = async (data) => {
    await createBulkSharedFiles(data);
  };

  const currentFolderIsNotInternal = useMemo(
    () => !currentFolder || (currentFolder && !currentFolder.internal),
    [currentFolder],
  );

  const LocalTablePagination = (props) => (
    <TablePagination
      loading={isSharedFilesDataFetching}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      totalSize={totalSize}
      sizePerPage={sizePerPage}
      onSizePerPageChange={setSizePerPage}
      {...props}
    />
  );

  const someLoading = isFoldersDataLoading || isSharedFilesDataLoading;

  return (
    <div className={cn('asset-documents relative', { 'w-full': !wide })}>
      {someLoading && <AnimationLoader className="h-full" />}
      {!someLoading && (
        <div
          className={cn(
            'grid gap-6',
            !hideTreeNavigation && 'md:grid-cols-[minmax(0,3fr)_minmax(0,9fr)]',
          )}
        >
          {!hideTreeNavigation && (
            <div>
              <div className="rst__left-sidebar relative">
                {isFoldersDataFetching && (
                  <AnimationLoader className="z-10 h-full w-full bg-neutral-000/50" />
                )}
                <div className="rst__tree">
                  {treeData.length === 0 && (
                    <div
                      className="flex items-center gap-1 border-b pb-2"
                      onClick={() => {
                        onFolderChange({
                          tree: treeData,
                          newParams: { folder_id: undefined },
                          withoutScroll: true,
                        });
                      }}
                    >
                      <Icon iconName="home" />
                      <span className="secondary-semibold text-neutral-900">
                        {entityName}
                      </span>
                    </div>
                  )}
                  <TreeFolderNavigation
                    treeData={treeData}
                    current={currentFolder}
                    additionalNodeProps={(node) => ({
                      actions: generateSortableTreeButtons(
                        node,
                        filteredFolders,
                        currentUserCanManage,
                        openNewFolderModal,
                        onDelete,
                        hideActions,
                      ),
                      isClickable: true,
                    })}
                    onNodeClick={onNodeClick}
                    onChange={(tree) => setTreeData(tree)}
                    rootNode={{ title: entityName }}
                    placeholderRenderer={() => (
                      <div className="flex flex-col items-center justify-between pt-8">
                        <span className="inline-regular mb-4 block">
                          This Asset no Folders yet
                        </span>
                        {currentUserCanManage && !hideActions && (
                          <Button
                            onClick={() =>
                              openNewFolderModal(undefined, currentFolder?.id)
                            }
                            variant="primary"
                          >
                            New Folder
                          </Button>
                        )}
                      </div>
                    )}
                  />
                </div>
                {treeData.filter(({ children }) => children?.length).length >
                  0 && (
                  <LinkButton
                    variant="secondary"
                    onClick={() => {
                      setTreeData(
                        expandNodesWithChildren({
                          tree: treeData,
                          expanded: isTreeExpanded,
                        }),
                      );
                    }}
                    className="mt-m"
                  >
                    {isTreeExpanded ? 'Expand all' : 'Collapse all'}
                  </LinkButton>
                )}
              </div>
            </div>
          )}
          <div>
            {currentFolder && (
              <div className="mb-4">
                <AssetFolderBreadcrumbs
                  rootPath="./"
                  rootName={entityName}
                  folders={folders}
                  currentFolder={currentFolder}
                  onRootClick={() =>
                    onFolderChange({
                      tree: treeData,
                      newParams: { folder_id: undefined },
                      withoutScroll: true,
                    })
                  }
                  onFolderChange={(params) =>
                    onFolderChange({
                      tree: treeData,
                      newParams: params,
                      withoutScroll: true,
                    })
                  }
                />
              </div>
            )}
            <div className="relative">
              {isFoldersDataFetching && (
                <AnimationLoader className="z-10 h-full w-full bg-neutral-000/50" />
              )}
              <h5>{currentFolder ? currentFolder.title : entityName}</h5>
              <div className="flex items-center justify-between">
                <p className="inline-regular  text-neutral-450">
                  {subfoldersCount > 0 &&
                    !isFoldersDataFetching &&
                    `${subfoldersCount} ${pluralize(
                      'Subfolder',
                      subfoldersCount,
                    )}`}
                  <div className="d-mblock hidden">
                    {subfoldersCount === 0 &&
                      !isFoldersDataFetching &&
                      'No subfolders'}
                  </div>
                </p>
                <div className="flex items-center">
                  {currentUserCanManage &&
                    !hideActions &&
                    currentFolderIsNotInternal && (
                      <Button
                        variant="primary"
                        onClick={() =>
                          openNewFolderModal(undefined, currentFolder?.id)
                        }
                      >
                        New Folder
                      </Button>
                    )}
                </div>
              </div>
              <SubfoldersList
                subfolders={subfolders}
                onFolderChange={(params) =>
                  onFolderChange({
                    tree: treeData,
                    newParams: params,
                    withoutScroll: true,
                  })
                }
                hideActions={hideActions}
                openMoveFolderModal={openMoveFolderModal}
                openNewFolderModal={openNewFolderModal}
                onDelete={onDelete}
                currentUserCanManage={currentUserCanManage}
              />
            </div>
            <hr className="hr-light-20 my-4" />
            <div className="flex flex-wrap items-center justify-between gap-2">
              {!currentFolder && (
                <h6 className="flex-shrink-1 text-light-90">All Documents</h6>
              )}
              {currentFolder && sharedFiles.length > 0 && (
                <h6 className="mr-4 text-light-90">Documents</h6>
              )}
              {sharedFiles.length === 0 && (
                <p className="mt-2 w-full text-neutral-450">No documents</p>
              )}
              <div className="w-lg-100 w-auto" />
              <div className="d-sm-flex hidden">
                <LocalTablePagination />
                {(totalSize === 0 || totalSize === null) && <div>&nbsp;</div>}
              </div>
              <div className="d-sm-none flex">
                <LocalTablePagination onSizePerPageChange={undefined} />
                {(totalSize === 0 || totalSize === null) && <div>&nbsp;</div>}
              </div>
              <div className="w-lg-auto w-full md:w-[100%]" />
              <div className="w-lg-auto d-justify-content-md-space-between flex w-full items-center gap-m">
                {currentUserCanManage && !currentUserIsExternal() && (
                  <ShownAsButton
                    users={investmentObject.entity.users.filter(
                      ({ role }) => role.name !== 'advisor',
                    )}
                    shownAsUser={shownAsUser}
                    setShownAsUser={(user) => {
                      setSelectedFilters({
                        ...selectedFilters,
                        shownAsUser: user,
                      });
                    }}
                  />
                )}
                {/* DEPRECATED: FE-2167 use SearchInput */}
                <TableSearch
                  inputPlaceholder="Document name or type"
                  size="m"
                  className="!my-0 !grid min-w-[240px]"
                  setSearchQuery={setSearchQuery}
                />
                {currentUserCanManage && currentFolderIsNotInternal && (
                  <Button
                    disabled={isSharedFilesDataFetching}
                    variant="primary"
                    onClick={() => setBulkUploaderModalOpened(true)}
                  >
                    Upload
                  </Button>
                )}
              </div>
            </div>
            <TableAppliedFilters
              className="mt-4 flex"
              filters={prepareSelectedFilters(selectedFilters)}
              resetFilter={localResetFilter}
            />
            <div className="mb-8 mt-4.5">
              <Table
                selectionColumn={SELECTION_COLUMN_CLASSES}
                nothingFoundClasses="mt-[5rem]"
                emptyDocumentsLabel="No Documents"
                items={sharedFiles}
                columns={columns}
                loading={isSharedFilesDataFetching}
                settings={pageParams}
                setSettings={setPageParams}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
              />
            </div>
            {moveSharedFileModalOpened && (
              <MoveItemsModal
                close={() => setMoveSharedFileModalOpened(false)}
                items={currentMovingIds}
                itemType="documents"
                onMove={async (selectedFolder) => {
                  const data = {
                    folder_id: selectedFolder.id,
                    shared_files: currentMovingIds,
                    shared_file_ids: currentMovingIds.map(({ id }) => id),
                  };
                  await updateBulkSharedFiles(data);
                  setCurrentMovingIds([]);
                  setSelectedRows([]);
                  setMoveSharedFileModalOpened(false);
                }}
                foldersTreeData={collapsedTreeData}
                disabledFolderIds={disabledFolderIds}
              />
            )}

            {editSharedFileModalOpened && (
              <EditSharedFileModal
                opened
                setOpened={setEditSharedFileModalOpened}
                sharedFile={editingSharedFile}
              />
            )}

            {selectedRows.length > 0 && !hideActions && (
              <BulkActionsPanel
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                actions={[
                  {
                    title: 'Move To',
                    icon: 'transferFile',
                    handleClick: bulkActionsAvailable
                      ? () => openMoveItemsModal(selectedRows)
                      : undefined,
                  },
                  {
                    title: 'Set Type',
                    icon: 'chartBar',
                    handleClick: bulkActionsAvailable
                      ? () => setDocumentTypesModalOpened(true)
                      : undefined,
                  },
                  {
                    title: 'Set Permissions',
                    icon: 'access',
                    handleClick: bulkSetPermissionsAvailable
                      ? async () => {
                          const res = await openModal(PermissionsModal, {
                            customHeader: (
                              <PermissionsModalDocsHeader
                                fileItems={selectedRows}
                              />
                            ),
                            initialTab: 'restricted',
                            productName: getProductName(investmentObject.type),
                            investmentObject,
                          });
                          if (res) {
                            bulkUpdatePermissions(res);
                          }
                        }
                      : undefined,
                  },
                  {
                    title: 'Download',
                    icon: 'download',
                    hidden:
                      selectedRows.filter(({ confidential }) => confidential)
                        .length > 0,
                    handleClick: () =>
                      bulkDownload(selectedRows).then(() =>
                        setSelectedRows([]),
                      ),
                  },
                  {
                    title: 'Remove',
                    icon: 'trash',
                    handleClick: bulkActionsAvailable
                      ? async () => {
                          await handleRemove(selectedRows.map(({ id }) => id));
                          setSelectedRows([]);
                        }
                      : undefined,
                  },
                ]}
              />
            )}
          </div>
        </div>
      )}

      {bulkUploaderModalOpened && (
        <BulkUploaderModal
          setOpened={setBulkUploaderModalOpened}
          defaultFolderId={currentFolder?.id}
          investmentObject={investmentObject}
          handleCreate={handleCreate}
        />
      )}

      {newFolderModalOpened && (
        <NewFolderModal
          opened
          setOpened={setNewFolderModalOpened}
          investmentObject={investmentObject}
          folder={folderToEdit}
          parentId={parentId}
          folders={folders}
        />
      )}

      {moveFolderModalOpened && (
        <MoveItemsModal
          close={() => setMoveFolderModalOpened(false)}
          items={[folderToEdit]}
          itemType="folder"
          onMove={async (selectedFolder) => {
            const data = {
              folder: {
                ids: [folderToEdit.id],
                parent_id:
                  selectedFolder.id === FAKE_ROOT_NODE_ID
                    ? undefined
                    : selectedFolder.id,
              },
            };
            await upadteBulkFolders(data);

            setMoveFolderModalOpened(false);
            setFolderToEdit(null);
            setDisabledFolderIds([]);
          }}
          disabledFolderIds={disabledFolderIds}
          foldersTreeData={collapsedTreeData}
          entityName={entityName}
        />
      )}
      {documentTypesModalOpened && (
        <TypeModal
          customHeader={
            <div className="bg-white pt-0">
              <div className="m-6">
                <ModalHeaderWithIcon
                  icons={
                    <MultipleItemsIcons
                      itemType="document"
                      items={selectedRows}
                    />
                  }
                  title={`Set Type for ${selectedRows.length} ${pluralize(
                    'documents',
                    selectedRows.length,
                  )}`}
                  subtitle={
                    <DocumentsPopover files={selectedRows}>
                      <div className="dashed-border-b cursor-pointer">
                        {`${selectedRows.length} ${pluralize(
                          'document',
                          selectedRows.length,
                        )}`}
                      </div>
                    </DocumentsPopover>
                  }
                  close={() => setDocumentTypesModalOpened(false)}
                />
              </div>
            </div>
          }
          onSubmit={bulkUpdateDocumentType}
          onClose={() => setDocumentTypesModalOpened(false)}
        />
      )}
    </div>
  );
};

export default AssetDocuments;
