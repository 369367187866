import { ManagementPeriodItem } from '@/bundles/Settings/components/Portal/LegalEntities/components/ManagementPeriodItem';
import { ERPSystem } from '@/entities/erpsystem';
import { CoreLegalEntitiesManagementPeriodDto } from '@/entities/core/legalEntity/api/settingsCoreLegalEntitiesGeneratedApi';
import { getPaletteColorByIndex } from '@/shared/config/color-palette';
import { cn } from '@/shared/lib/css/cn';
import { GapRange, getMissingIntervals, toDate } from '@/shared/lib/date';
import { formatDate } from '@/shared/lib/formatting/dates';
import { Popover } from '@/stories/Popover/Popover';
import { orderPeriodsByDateFrom } from '@/shared/lib/period';
import { Fragment, useCallback, useMemo } from 'react';

export const useLegalEntityManagementPeriodsColorsMap = (
  periods: CoreLegalEntitiesManagementPeriodDto[] | undefined,
) => {
  return useMemo(() => {
    if (!periods) {
      return new Map();
    }

    return new Map(
      periods.map((period, index) => [
        period.id,
        getPaletteColorByIndex(index),
      ]),
    );
  }, [periods]);
};

/**
 * Combines periods and fills in missing intervals.
 *
 * @param periods - An array of periods to combine and fill.
 * @returns A new array with combined periods and filled missing intervals.
 */
export const useCombinedPeriods = (
  periods: (CoreLegalEntitiesManagementPeriodDto | GapRange)[],
) => {
  const missingIntervals = getMissingIntervals(periods);

  return orderPeriodsByDateFrom([...periods, ...missingIntervals]);
};

type PeriodDividerProps = {
  date: string | null;
  position?: 'left' | 'right' | 'center';
} & PropsWithClassName;

/**
 * Renders a divider for a period.
 *
 * @param date - The date to render the divider for.
 * @returns A React component that renders a divider for a period.
 */
const PeriodDivider = ({
  date,
  className,
  position = 'center',
}: PeriodDividerProps) => {
  if (!date) {
    return null;
  }

  return (
    <div className="relative z-10 h-4 w-1 shrink-0 cursor-default rounded-sm bg-neutral-900 ring-2 ring-white ring-offset-0">
      <span
        className={cn(
          'text-semibold absolute top-full whitespace-nowrap pt-2 text-xs leading-none text-neutral-850',
          position === 'left' && 'left-0',
          position === 'right' && 'right-0',
          position === 'center' && 'left-1/2 -translate-x-1/2',
          className,
        )}
      >
        {formatDate(date, 'MMM D, YYYY')}
      </span>
    </div>
  );
};

type PeriodLineProps = {
  color?: string;
};

/**
 * Renders a line for a period.
 *
 * @param color - The color of the line.
 * @returns A React component that renders a line for a period.
 */
const PeriodLine = ({ color }: PeriodLineProps) => {
  const style = color
    ? {
        backgroundColor: color || 'transparent',
      }
    : {};

  return (
    <span
      className={cn(
        'legal-entity-management-period-line h-2 flex-1 transition-all ease-out will-change-transform',
        !color && 'h-2 flex-1 bg-neutral-250',
        color && 'hover:scale-y-125 group-aria-expanded:scale-y-125',
      )}
      style={style}
    />
  );
};

// TODO: create a basic component for inline periods
// LegalEntityManagmentPeriodsInline should be extended for this component

type LegalEntityManagmentPeriodsInlineProps<
  T extends CoreLegalEntitiesManagementPeriodDto | GapRange,
> = {
  periods: T[];
  colorsMap: Map<string, string>;
};

export const LegalEntityManagmentPeriodsInline = <
  T extends CoreLegalEntitiesManagementPeriodDto | GapRange,
>(
  props: LegalEntityManagmentPeriodsInlineProps<T>,
) => {
  const { periods = [], colorsMap } = props;

  if (!periods.length) {
    return null;
  }

  const lastPeriod = periods[periods.length - 1];
  const isLastPeriod = (index: number) => index === periods.length - 1;
  const isFirstPeriod = (index: number) => index === 0;

  const renderPeriod = useCallback(
    (period: CoreLegalEntitiesManagementPeriodDto | GapRange) => {
      if ('isGap' in period) {
        return (
          <div className="h-2 flex-1 bg-danger-020 bg-stripes bg-stripes-danger-055" />
        );
      }

      return (
        <Popover
          template={
            <ManagementPeriodItem
              propertyManagementCompanyName={
                period.propertyManagementCompanyName
              }
              externalIdentifier={period.externalIdentifier}
              dateFrom={toDate(period.dateFrom)}
              dateTo={toDate(period.dateTo)}
              erpSystem={period.erpSystem as ERPSystem}
            />
          }
          appendTo={document.body}
          placement="bottom"
          offset={[0, 8]}
          className="min-w-[12.5rem]"
          maxWidth="20rem"
          classes={{
            spanContainer: 'block flex-1 group',
          }}
        >
          <PeriodLine color={colorsMap.get(period.id)} />
        </Popover>
      );
    },
    [colorsMap, periods],
  );

  return (
    <div className="flex w-full flex-nowrap items-center pb-8 [&>*:first-child_.legal-entity-management-period-line]:rounded-s-xl [&>*:last-child_.legal-entity-management-period-line]:rounded-e-xl">
      {periods.map((period, index) => (
        <Fragment key={'isGap' in period ? `gap-${index}` : period.id}>
          {isFirstPeriod(index) && period.dateFrom && (
            <>
              {/* TODO: this is a popover skeleton, remove it when we replace the popover component */}
              {/* <span className="block flex-1">
                  <PeriodLine />
                </span> */}
              <PeriodDivider date={period.dateFrom} position="left" />
            </>
          )}

          {!isFirstPeriod(index) && <PeriodDivider date={period.dateFrom} />}

          {renderPeriod(period)}

          {isLastPeriod(index) && lastPeriod.dateTo && (
            <>
              <PeriodDivider date={lastPeriod.dateTo} />
              {/* TODO: this is a popover skeleton, remove it when we replace the popover component */}
              <span className="block flex-1">
                <PeriodLine />
              </span>
            </>
          )}
        </Fragment>
      ))}
    </div>
  );
};
