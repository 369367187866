import { DateValueDisplayOptions } from '@/shared/lib/formatting/displayOptions';
import { DEFAULT_DATE_FALLBACK } from '@/shared/lib/formatting/fallbacks';
import { formatRelativeDate } from '@/shared/lib/relativeDate';
import dayjs, { Dayjs } from 'dayjs';

export type FormatDate =
  | 'MMM DD'
  | 'MM'
  | 'MMM'
  | 'MMMM D, YYYY'
  | 'MMM-DD'
  | 'MMM DD, YYYY'
  | 'MMM D, YYYY'
  | 'MM/DD/YYYY'
  | 'MMM DD, YYYY h:mm'
  | 'MMM DD, YYYY h:mm A'
  | 'MM-DD-YYYY'
  | 'MMM-YY'
  | 'YYYY'
  | 'L'
  | 'LL'
  | 'LLL'
  | 'MM.DD.YYYY'
  | 'YYYY-MM-DD'
  | 'MMMM'
  | 'MMM-YYYY'
  | 'MMMM DD, YYYY'
  | 'MMMM DD, YYYY h:mm A'
  | 'HH:mm:ss'
  | 'Q-YY'
  | 'hh:mm A'
  | 'MMM DD, YYYY [at] LT';

export const formatUnixDateFromNow = (date: number) =>
  date ? dayjs.unix(date).fromNow() : DEFAULT_DATE_FALLBACK;

export const dateToUnix = (date: DateParam): number => {
  return dayjs(date).unix();
};

export const dateToYear = (date: DateParam): number => {
  return dayjs(date).year();
};

export const formatUnixDate = (
  date: DateString | UnixTime | number | null | undefined,
  formatOption: FormatDate,
  // TODO: FE-3618 check types and tests for date formatters
  // @ts-expect-error dayjs.unix accepts only a number
) => (date ? dayjs.unix(date).format(formatOption) : DEFAULT_DATE_FALLBACK);

type DateParam = DateString | Dayjs | Date | number | null | undefined | string;

export const formatDate = (
  date: DateParam,
  formatOption: FormatDate,
): string => (date ? dayjs(date).format(formatOption) : DEFAULT_DATE_FALLBACK);

export const formatUTCDate = (
  date: DateParam,
  formatOption: FormatDate,
): string =>
  date ? dayjs.utc(date).format(formatOption) : DEFAULT_DATE_FALLBACK;

export const toUTCDate = (date: DateParam): Date => dayjs(date).utc().toDate();

/**
 * Formats a given date to a quarter with prefix.
 *
 * @param {DateParam} date - The date to be formatted.
 * @returns {string} - The formatted date in the format "Q<quarter>-YY".
 */
export const formatDateToQuarterWithPrefix = (date: DateParam) =>
  `Q${dayjs(date).format('Q-YY')}`;

export const toDateSeconds = (date: UnixTime) => (date ? new Date(date) : ''); // JS works in milliseconds, while we pass timestamp in seconds

export const toDate = (date: UnixTime): DateString =>
  (date ? new Date(date * 1000) : '') as DateString; // JS works in milliseconds, while we pass timestamp in seconds

export const DEFAULT_DATE_FORMAT = 'MMM D, YYYY';
export type DateFormat =
  | 'YYYY'
  | 'MM'
  | 'MMM'
  | 'MM/DD/YYYY'
  | 'MMMM YYYY'
  | 'MMMM D, YYYY'
  | 'MMM D, YYYY'
  | 'Relative';

export const formatDateByDisplayOptions = (
  date: Date,
  displayOptions: DateValueDisplayOptions,
) => {
  const { date_format = DEFAULT_DATE_FORMAT } = displayOptions;
  if (date_format === 'Relative') {
    return formatRelativeDate(date, new Date());
  }
  return formatUTCDate(date, date_format);
};
