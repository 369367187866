import type { ReportGroupTemplateDto } from '@/entities/reporting/api/reportingGeneratedApi';
import { generateUrl, ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import { Icon } from '@/stories/Icon/Icon';
import { Link, useLocation } from '@reach/router';

export const ReportBuilderTemplateIconButton = ({
  template,
}: {
  template: Pick<ReportGroupTemplateDto, 'id' | 'name'>;
}) => {
  const location = useLocation();
  return (
    <Link
      state={{ from: location.pathname }}
      to={generateUrl(
        ROUTES_ROOT.reportBuilderTemplates.eagleEye.reportBuilderTemplate
          .fullPath,
        {
          pathParams: {
            templateId: template.id ?? '',
          },
        },
      )}
    >
      <div className="flex w-max items-center gap-1 rounded-[8px] bg-neutral-050 px-2 py-1">
        <Icon iconName="docPdf" className="text-danger-055" />
        <p className="secondary-regular text-neutral-850">{template.name}</p>
      </div>
    </Link>
  );
};
