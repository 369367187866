import { ReportDashboardType } from '@/bundles/Shared/entities/dashboard/model/types/types';
import {
  PERMITTED_SCHEMA,
  PermittedFormModel,
} from 'bundles/Shared/entities/permissions';
import * as yup from 'yup';

const OBJECT_SOURCES = ['assets', 'funds', 'segments'] as const;

const DASHBOARD_TYPES = [
  ReportDashboardType.EAGLE_EYE,
  ReportDashboardType.OBJECT,
  ReportDashboardType.COMPARISON_MODE,
] as const;

export const DEFAULT_DASHBOARD_PERMITTED = {
  directUsers: [],
  public: false,
  directInvestmentEntities: [],
  directRoles: [],
  directTags: [],
  indirectUsers: [],
} satisfies PermittedFormModel;

export const DASHBOARD_CREATION_SCHEMA = yup.object().shape({
  name: yup.string().required(),
  dashboard_type: yup.string().oneOf(DASHBOARD_TYPES).required(),
  object_source: yup.string().oneOf(OBJECT_SOURCES).nullable(),
  excluded_legal_entity_ids: yup
    .array()
    .of(yup.string().required())
    .required()
    .when('dashboard_type', {
      is: ReportDashboardType.OBJECT,
      then: (schema) => schema.nullable().optional(),
    }),
  segment_ids: yup
    .array()
    .of(yup.number().required())
    .required()
    .when('dashboard_type', {
      is: ReportDashboardType.OBJECT,
      then: (schema) => schema.nullable().optional(),
    }),
  asset_ids: yup.array().of(yup.number().required()).required(),
  permitted: PERMITTED_SCHEMA.required().default(undefined),
});

export type DashboardCreationFormModel = yup.InferType<
  typeof DASHBOARD_CREATION_SCHEMA
>;
