import { accountingTransactionsApi } from '@/shared/api/accountingTransactionsGeneratedApi';

const enhancedApi = accountingTransactionsApi.enhanceEndpoints({
  addTagTypes: ['AccountingTransactions'],
  endpoints: {
    getApiAccountingTransactions: {
      providesTags: ['AccountingTransactions'],
    },
  },
});

export { enhancedApi as accountingTransactionsEnhancedApi };

export const { useGetApiAccountingTransactionsQuery } = enhancedApi;
