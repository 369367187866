import { IColumn } from 'bundles/Shared/components/Table/types';
import {
  IPeriodBatch,
  TReportDataPeriodState,
} from 'bundles/Settings/types/ReportDataPeriodBulk';
import { SmallAddingButton } from 'stories/SmallAddingButton/SmallAddingButton';
import { Tooltip } from 'stories/Tooltip/Tooltip';
import { navigate } from '@reach/router';
import { DEFAULT_TOOLTIP_OFFSET } from 'stories/Tooltip/Tooltip';
import FireIcon from 'bundles/FireStation/components/FireIcon/FireIcon';
import AssetQuickFilter from 'bundles/Settings/components/REport/DataPeriods/AssetQuickFilter';
import { cn } from '@/shared/lib/css/cn';
import React, { useCallback, useMemo } from 'react';
import {
  useGetApiSettingsReportDataPeriodBatchesLegalEntitiablesQuery,
  usePutApiSettingsReportDataPeriodBatchesBulkMutation,
} from 'bundles/Settings/actions/report/dataPeriodsApiExtended';
import { LegalEntity } from '@/entities/core/legalEntity';
import { ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import { useModal } from '@/shared/lib/hooks/useModal';

export type FilterModel = {
  legalEntitiables?: LegalEntity['id'][];
};

export const useColumns = ({
  dialogType,
  period,
}: {
  period: string;
  dialogType: TReportDataPeriodState;
}) => {
  const { resetAllModals } = useModal();
  const { data: legalEntitiesData } =
    useGetApiSettingsReportDataPeriodBatchesLegalEntitiablesQuery({
      period,
      state: dialogType,
    });
  const [updateDataPeriodBulk] =
    usePutApiSettingsReportDataPeriodBatchesBulkMutation();
  const legalEntitiables = legalEntitiesData ?? [];
  const handlePublicationStateChange = useCallback(
    async (item: IPeriodBatch) => {
      await updateDataPeriodBulk({
        body: {
          data_period_batches: [
            {
              legal_entity_id: item.legalEntityId,
              state: dialogType === 'published' ? 'unpublished' : 'published',
              period,
            },
          ],
        },
      });
    },
    [dialogType, period, updateDataPeriodBulk],
  );

  return useMemo<IColumn<IPeriodBatch>[]>(
    () => [
      {
        dataField: 'legal_entity',
        headerStyle: dialogType === 'draft' ? { width: '46%' } : undefined,
        text: 'Legal Entity',
        formatter: ({ row: item }) => item.legalEntity.name,
        sortable: true,
      },
      {
        dataField: 'legal_entitiable',
        headerStyle: dialogType === 'draft' ? { width: '46%' } : undefined,
        text: 'Asset',
        formatter: ({ row: item }) => {
          if (item.legalEntity.legalEntitiable) {
            return item.legalEntity.legalEntitiable?.name;
          }
          return (
            <div className="flex items-center gap-1">
              <span className="secondary-regular text-light-60">
                No objects
              </span>
              <div className="h-[18px]">
                <Tooltip
                  variant="explanation"
                  mainText="This legal entity has not been mapped"
                  titleText="No objects yet"
                  actionText="Set Object"
                  actionHandler={() => {
                    resetAllModals();
                    navigate(
                      ROUTES_ROOT.settings.portal.legalEntities.fullPath,
                    );
                  }}
                  arrowPosition="start"
                  placement="top-start"
                  offset={[-3, DEFAULT_TOOLTIP_OFFSET[1]]}
                >
                  <FireIcon priority="high" />
                </Tooltip>
              </div>
            </div>
          );
        },
        filterComponent: ({ filterModel, setFilterModel }) => (
          <div className="ml-s inline-flex">
            <AssetQuickFilter
              legalEntitables={legalEntitiables}
              onSelectedLegalEntitiablesChanged={(newLegalEntitiables) =>
                setFilterModel({
                  ...filterModel,
                  legalEntitiables: newLegalEntitiables,
                })
              }
              selectedLegalEntitiables={filterModel.legalEntitiables ?? []}
            />
          </div>
        ),
        sortable: true,
      },
      {
        dataField: 'actions',
        text: 'Actions',
        hidden: dialogType === 'draft',
        formatter: ({ row: item }) => (
          <SmallAddingButton
            withoutIcon
            className={cn({
              'border border-light-10 bg-transparent text-light-60 hover:border-red hover:text-red':
                dialogType === 'published',
            })}
            onClick={() => handlePublicationStateChange(item)}
          >
            {dialogType === 'published' ? 'Cancel publication' : 'Publish'}
          </SmallAddingButton>
        ),
      },
    ],
    [
      resetAllModals,
      dialogType,
      legalEntitiables,
      handlePublicationStateChange,
    ],
  );
};
