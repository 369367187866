import { cn } from '@/shared/lib/css/cn';
import { Permitted } from 'bundles/Shared/entities/permissions';
import {
  currentUserAllowedTo,
  currentUserIsSuperAdmin,
  TProductNames,
} from 'lib/permissions';
import { Icon } from 'stories/Icon/Icon';
import { IconButton } from 'stories/IconButton/IconButton';
import { PopoverProps } from 'stories/Popover/Popover';

import { ICON_MAP } from '@/bundles/Shared/components/Permissions/const';
import { useAccessList } from '@/bundles/Shared/components/Permissions/hooks/useAccessList';
import { LockedTooltip } from '@/bundles/Shared/components/Permissions/LockedTooltip';
import { PermissionListPopover } from '@/bundles/Shared/components/Permissions/PermissionListPopover';
import { DEFAULT_STRING_FALLBACK } from '@/shared/lib/formatting/fallbacks';

const EmptyAccessList = () => <>{DEFAULT_STRING_FALLBACK}</>;

export function PermissionListLine({
  onClick,
  onEdit,
  permissions,
  productName,
  popoverProps,
  showEmptyFallback = true,
  emptyFallback = <EmptyAccessList />,
  lockedToEdit = false,
  hideActions = false,
  hidePublicIcon = false,
  pseudoLinkClassName = '',
  className,
}: {
  onClick: () => Promise<void> | void;
  permissions: Permitted;
  productName?: TProductNames;
  lockedToEdit?: boolean;
  popoverProps?: Pick<PopoverProps, 'placement'>;
  onEdit?: () => Promise<void> | void;
  hideActions?: boolean;
  hidePublicIcon?: boolean;
  showEmptyFallback?: boolean;
  emptyFallback?: React.ReactNode;
  pseudoLinkClassName?: string;
} & PropsWithClassName) {
  const { isPublic } = permissions;

  const { accessList } = useAccessList({ permissions });

  const isUserAllowedToEdit =
    !hideActions &&
    !lockedToEdit &&
    (!productName || currentUserAllowedTo('manage', productName));

  if (showEmptyFallback && accessList.length === 0) {
    return (
      <div className="flex w-full items-center justify-between">
        {emptyFallback}
        {isUserAllowedToEdit && onEdit && (
          <IconButton
            variant="white"
            className="self-start"
            iconName="edit"
            onClick={onEdit}
          />
        )}
      </div>
    );
  }

  return (
    <div
      className={cn(
        'flex items-center justify-between gap-2 overflow-hidden text-dark-60',
        className,
      )}
    >
      {(accessList.length > 0 || isPublic) && (
        <div className="flex gap-2 overflow-hidden">
          {isPublic && !hidePublicIcon && (
            <span className="flex h-[26px] w-[26px] items-center justify-center rounded-full bg-primary-light-5 text-dark-60">
              <Icon iconName={ICON_MAP.public} />
            </span>
          )}
          <PermissionListPopover
            popoverProps={popoverProps}
            isPublic={isPublic}
            accessList={accessList}
            onClick={onClick}
            classes={{
              pseudoLink: `inline-regular h-max ${pseudoLinkClassName}`,
            }}
          />
        </div>
      )}

      {!hideActions && lockedToEdit && (
        <LockedTooltip canUnlock={currentUserIsSuperAdmin()} />
      )}

      {isUserAllowedToEdit && onEdit && (
        <IconButton
          variant="white"
          className="self-start"
          iconName="edit"
          onClick={onEdit}
        />
      )}
    </div>
  );
}
