import { useAssertNullableContext } from '@/shared/lib/hooks/context/useAssertNullableContext';
import { createContext } from 'react';
import { IAsset } from 'types/Asset';
import { IFund } from 'types/Fund';

export type EntityContextType =
  | {
      entity: IAsset;
      type: 'Asset';
    }
  | {
      entity: IFund;
      type: 'Fund';
    };

const EntityContext = createContext<EntityContextType | undefined>(undefined);

export const useEntityContext = useAssertNullableContext(EntityContext);

export default EntityContext;
