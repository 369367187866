import React, { FC } from 'react';
import { CurrencyFormatter } from 'stories/ValueFormatters/CurrencyFormatter';
import { Card } from 'bundles/DrawPackage/InvoicesFlow/Invoices/layouts/Card';
import { Invoice } from '@/shared/types/reconcile/Invoice';

interface IInvoiceCardProps
  extends Pick<Invoice, 'id' | 'amount' | 'number' | 'date' | 'vendor'> {
  handleRemove: (id: UUID) => void;
  vendorName?: string;
}

const InvoiceCard: FC<IInvoiceCardProps> = ({
  id,
  amount,
  number,
  date,
  vendor,
  handleRemove,
}) => (
  <Card id={id} handleRemove={handleRemove}>
    <div className="flex flex-1 flex-col gap-2">
      <div className="flex justify-between">
        <div className="inline-regular text-light-90">Invoice {number}</div>
        <CurrencyFormatter value={amount} />
      </div>
      <div className="secondary-regular flex w-fit items-center gap-1 rounded-lg bg-light px-2 py-1 text-light-90">
        <div>{date}</div>
        <div>•</div>
        <div>{vendor.name}</div>
      </div>
    </div>
  </Card>
);

export default InvoiceCard;
