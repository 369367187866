import { Document, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import React, { FC, Fragment } from 'react';
import HeaderDPS from '@/bundles/DrawPackage/DrawPage/components/DrawPageSummary/HeaderDPS';
import BodyTableDPS from '@/bundles/DrawPackage/DrawPage/components/DrawPageSummary/BodyTableDPS';
import HeaderRL from '@/bundles/DrawPackage/DrawPage/components/DrawPageSummary/pages/RequisitionList/HeaderRL';
import BodyTableRL from '@/bundles/DrawPackage/DrawPage/components/DrawPageSummary/pages/RequisitionList/BodyTableRL';
import HeaderTF from '@/bundles/DrawPackage/DrawPage/components/DrawPageSummary/pages/TransferFunds/HeaderTF';
import BodyTableTF from '@/bundles/DrawPackage/DrawPage/components/DrawPageSummary/pages/TransferFunds/BodyTableTF';
import { DrawSource } from '@/shared/types/reconcile/DrawRequest';

interface IDrawPageSummaryProps {
  data: {
    categories: IDrawPreviewItem[];
    requisitionsList: IDrawPreviewRequisitionItem[];
    transferOfFunds: IDrawPreviewTransferOfFunds;
  };
}

export interface IDrawPreviewItem {
  id: string;
  code: string;
  actualToDateAmount: string;
  balanceAmount: string;
  budgetAmount: string;
  fundedToDateAmount: string;
  percent: string;
  reallocatedAmount: string;
  revisedAmount: string;
  currentRequisitionAmount: string;
  subcategories: IDrawPreviewItem[];
}

export interface IDrawPreviewRequisitionItem {
  amount: string;
  budgetSubcategory: string;
  description: string;
  invoice: {
    date: string;
    number: string;
    vendor: string;
  };
  lineItemName: string;
  requisition: {
    date: string;
    number: number;
  };
}

export interface IDrawPreviewTransferOfFunds {
  reallocations: {
    approvedDate: string;
    description: string;
    movedFrom: string;
    movedTo: string;
    number: number;
    requisitionBeginningDate: DateString;
    requisitionEndingDate: DateString;
    requisitionNumber: number;
    status: string;
    value: string;
  }[];
  total: string;
}

export const COLUMN_PAGE_SUMMARY: (keyof IDrawPreviewItem)[] = [
  'budgetAmount',
  'reallocatedAmount',
  'revisedAmount',
  'fundedToDateAmount',
  'currentRequisitionAmount',
  'actualToDateAmount',
  'balanceAmount',
];

type KindFS = keyof DrawSource;

export type ITotalProjectCosts = Record<
  KindFS,
  {
    fundingSources: {
      actualToDate: string;
      balanceToComplete: string;
      budget: string;
      completePercent: string;
      currentRequisition: string;
      fundedToDate: string;
      name: string;
      revisedBudget: string;
    }[];
    percentOfBudget: string;
    totals: {
      actualToDate: string;
      balanceToComplete: string;
      budget: string;
      completePercent: string;
      currentRequisition: string;
      fundedToDate: string;
      revisedBudget: string;
    };
  }
>;

export const COLUMN_PAGE_SUMMARY_FS = [
  'budget',
  'empty',
  'revisedBudget',
  'fundedToDate',
  'currentRequisition',
  'actualToDate',
  'balanceToComplete',
] as const;

const DrawPageSummary: FC<IDrawPageSummaryProps> = ({ data }) => {
  const styles = StyleSheet.create({
    page: {
      fontFamily: 'Helvetica',
      fontSize: 11,
      paddingTop: 15,
      paddingLeft: 15,
      paddingRight: 15,
      lineHeight: 1.5,
      flexDirection: 'column',
    },
    tableContainer: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      marginTop: 24,
    },
    row: {
      flexDirection: 'row',
      alignItems: 'center',
      height: 24,
      fontStyle: 'bold',
    },
    titleWrapper: {
      flexDirection: 'row',
      justifyContent: 'center',
      width: '100%',
      textAlign: 'center',
    },
    title: {
      fontFamily: 'Helvetica-Bold',
      fontWeight: 'bold',
      fontSize: 8,
      marginBottom: 2,
    },
  });

  return (
    <Document>
      <Page size="A4" orientation="landscape" style={styles.page}>
        <View style={styles.tableContainer}>
          <Fragment>
            <View style={styles.titleWrapper}>
              <Text style={styles.title}>Payment Requisitions Summary</Text>
            </View>
            <HeaderDPS />
            <BodyTableDPS data={data} />
          </Fragment>
        </View>
      </Page>
      <Page size="A4" orientation="landscape" style={styles.page}>
        <View style={styles.tableContainer}>
          <Fragment>
            <View style={styles.titleWrapper}>
              <Text style={styles.title}>Requisition List</Text>
            </View>
            <HeaderRL />
            <BodyTableRL data={data} />
          </Fragment>
        </View>
      </Page>
      <Page size="A4" orientation="landscape" style={styles.page}>
        <View style={styles.tableContainer}>
          <Fragment>
            <View style={styles.titleWrapper}>
              <Text style={styles.title}>Transfer of Funds Log</Text>
            </View>
            <HeaderTF />
            <BodyTableTF data={data} />
          </Fragment>
        </View>
      </Page>
    </Document>
  );
};

export default DrawPageSummary;
