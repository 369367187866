import React from 'react';
import { Button } from 'stories/Button/Button';
import { Dropdown } from 'stories/Dropdown/Dropdown';
import { DropdownItem } from 'stories/Dropdown/DropdownItem/DropdownItem';

interface IDropdownItem
  extends Omit<
    React.ComponentProps<typeof DropdownItem>,
    'children' | 'onClick' | 'active'
  > {
  label: string;
  id: string;
}

interface Props extends Omit<React.ComponentProps<typeof Dropdown>, 'items'> {
  items: IDropdownItem[];
  onSelectedChange: (id: string) => void;
  selectedItem?: string;
  buttonProps?: React.ComponentProps<typeof Button>;
  renderButton?: (selectedItem: IDropdownItem | undefined) => React.ReactNode;
}

function SecondaryDropdown({
  items,
  selectedItem,
  onSelectedChange,
  buttonProps,
  renderButton,
  ...props
}: Props) {
  return (
    <Dropdown
      {...props}
      items={items.map(({ label, id, ...itemProps }) => (
        <DropdownItem
          key={id}
          onClick={() => onSelectedChange(id)}
          active={id === (selectedItem ?? 'all')}
          {...itemProps}
        >
          {label}
        </DropdownItem>
      ))}
    >
      <>
        {renderButton?.(items.find((item) => item.id === selectedItem))}
        {!renderButton && (
          <Button
            variant="secondary"
            iconPosition="right"
            iconName="arrowBottom"
            size="s"
            {...buttonProps}
          >
            {items.find((item) => item.id === selectedItem)?.label ??
              'No Selected'}
          </Button>
        )}
      </>
    </Dropdown>
  );
}

export default SecondaryDropdown;
