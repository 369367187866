import React, { FC } from 'react';
import { RequisitionInvoice } from '@/shared/types/reconcile/Requisition';
import { Invoice } from '@/shared/types/reconcile/Invoice';
import { formatAmount } from '@/shared/lib/formatting/number';
import { cn } from '@/shared/lib/css/cn';
import styles from '@/bundles/DrawPackage/InvoicesFlow/Invoices/invoice/Invoice.module.scss';
import { transformLineItemsByCategory } from '@/bundles/DrawPackage/InvoicesFlow/Invoices/requisition/utils';

interface ITableRequisitionsLineItemsProps {
  requisition: RequisitionInvoice;
  invoice: Invoice;
  type: 'add' | 'remove';
}

interface ITransformLineItem {
  id: string;
  amount: number;
  name: string;
}

const TableRequisitionsLineItems: FC<ITableRequisitionsLineItemsProps> = ({
  requisition,
  invoice,
  type,
}) => {
  const invoicesCategory = transformLineItemsByCategory(invoice.lineItems);
  const requisitionsCategory = transformLineItemsByCategory(
    requisition?.lineItems,
  );

  const calcOldValue = (item: ITransformLineItem) => {
    const lineItemInRequisition = requisitionsCategory.find(
      (category) => category.id === item.id,
    );
    if (lineItemInRequisition) {
      return lineItemInRequisition.amount;
    }
    return 0;
  };

  const calcNewValue = (item: ITransformLineItem) => {
    const lineItemInRequisition = requisitionsCategory.find(
      (reqLineItem) => reqLineItem.id === item.id,
    );
    if (lineItemInRequisition) {
      if (type === 'add') {
        return Number(lineItemInRequisition.amount) + Number(item.amount);
      }
      return Number(lineItemInRequisition.amount) - Number(item.amount);
    }
    return item.amount;
  };

  return (
    <div>
      <div className={cn(styles.table, 'mt-l')}>
        <div className="flex">
          <div
            className={cn(
              'col-6',
              styles.tableHeaderItem,
              styles.tableItemWithoutBorder,
            )}
          >
            JCC/SoVC Details
          </div>
          <div
            className={cn(
              'col-3',
              'text-right',
              styles.tableHeaderItem,
              styles.tableItemWithoutBorder,
            )}
          >
            present value
          </div>
          <div className={cn('col-3', 'text-right', styles.tableHeaderItem)}>
            New Value
          </div>
        </div>
        <div className={styles.tableBody}>
          {invoicesCategory.map((item) => (
            <div key={item.name} className={styles.tableRow}>
              {item.name && (
                <div
                  className={cn(
                    'col-6',
                    styles.tableColumnItem,
                    styles.tableItemWithoutBorder,
                  )}
                >
                  <div className="dark-60 inline-regular pb-xs">
                    {item.name}
                  </div>
                </div>
              )}
              {item.amount && (
                <div
                  className={cn(
                    'col-3',
                    'inline-regular text-right',
                    styles.tableColumnItem,
                    styles.tableItemWithoutBorder,
                  )}
                >
                  {formatAmount(calcOldValue(item))}
                </div>
              )}
              {item.amount && (
                <div
                  className={cn(
                    'col-3',
                    'dark-60 inline-regular text-right',
                    styles.tableColumnItem,
                    styles.tableItemWithoutBorder,
                  )}
                >
                  <div className="mb-xs">
                    {formatAmount(calcNewValue(item))}
                  </div>
                  <div
                    className={cn(
                      'secondary-regular',
                      type === 'add' ? 'green' : 'red',
                    )}
                  >
                    {type === 'add' && '+'}{' '}
                    {formatAmount(calcNewValue(item) - calcOldValue(item))}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TableRequisitionsLineItems;
