import { IHeaderParams } from 'ag-grid-community';
import SetFilter from 'bundles/REconcile/components/operational/table/SetFilter';
import { ListOption } from 'stories/Checkbox/CheckList';
import { ERPSystem } from '@/entities/erpsystem/model';
import { getNameERPSystem } from '@/entities/erpsystem/ui/ERPSystemLabel';
import { LegalEntitySourceHeaderParams } from '@/bundles/Settings/components/REport/GeneralLedgers/GeneralLedgers';

interface Props extends IHeaderParams, LegalEntitySourceHeaderParams {}

export function LegalEntitySourceHeader({
  displayName,
  allSources,
  selectedSourceOptions = [],
  setSources,
}: Props) {
  const handleChecked = (options: ListOption[]) => {
    setSources(options.map((option) => option.value));
  };

  const toOptions = (sources: ERPSystem[]) =>
    sources.map((source) => ({
      id: source,
      label: getNameERPSystem(source),
      value: source,
    }));

  return (
    <div className="flex items-center gap-2">
      {displayName}
      <SetFilter
        placement="bottom-start"
        items={toOptions(allSources)}
        label={displayName}
        onChange={handleChecked}
        value={toOptions(selectedSourceOptions)}
        appendTo={() => document.body}
      />
    </div>
  );
}
