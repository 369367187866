import React, { useLayoutEffect, useState } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themesAnimated from '@amcharts/amcharts5/themes/Animated';
import { configChartColors, createXYChart } from 'lib/amcharts/utils';
import {
  useFormattedChartData,
  useChartSeriesData,
} from '@/bundles/Scoreboard/Pipeline/components/chartUtils';

export function VelocityChart({ pipelineChartsData }) {
  const [chartItemsToUpdate, setChartItemsToUpdate] = useState([]);

  const seriesData = useChartSeriesData(pipelineChartsData, ['total']);
  const addInChartItemsToUpdate = (item) => {
    setChartItemsToUpdate((prev) => [...prev, item]);
  };

  const chartData = useFormattedChartData(pipelineChartsData);

  useLayoutEffect(() => {
    const root = am5.Root.new('pipelineVelocityChart');

    root.setThemes([am5themesAnimated.new(root)]);

    const chart = createXYChart(root, {
      focusable: true,
    });

    configChartColors(chart, [
      '#2fa07d',
      '#46af98',
      '#61bdb1',
      '#66a3b1',
      '#4c899c',
      '#346f87',
      '#1c5771',
      '#6f8292',
    ]);

    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    const xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        maxDeviation: 0.1,
        groupData: false,
        startLocation: 0.5,
        endLocation: 0.5,
        baseInterval: {
          timeUnit: 'day',
          count: 1,
        },
        renderer: am5xy.AxisRendererX.new(root, {
          minGridDistance: 50,
        }),
        tooltip: am5.Tooltip.new(root, {}),
      }),
    );
    const xRenderer = xAxis.get('renderer');
    xRenderer.grid.template.setAll({
      location: 0.5,
    });

    addInChartItemsToUpdate(xAxis);
    xAxis.data.setAll(chartData);

    const yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        min: 0,
        maxDeviation: 0.1,
        renderer: am5xy.AxisRendererY.new(root, {}),
      }),
    );
    yAxis.children.unshift(
      am5.Label.new(root, {
        rotation: -90,
        text: 'Deals Count',
        y: am5.p50,
        centerX: am5.p50,
      }),
    );

    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    seriesData.forEach(({ name, valueYField }) => {
      const series = chart.series.push(
        am5xy.LineSeries.new(root, {
          minBulletDistance: 10,
          name,
          xAxis,
          yAxis,
          valueYField,
          valueXField: 'date',
          stacked: true,
          tooltip: am5.Tooltip.new(root, {
            pointerOrientation: 'horizontal',
            labelText: `${name}: {valueY}`,
          }),
        }),
      );

      series.strokes.template.setAll({
        strokeWidth: 3,
      });

      series.data.setAll(chartData);
      series.fills.template.setAll({
        visible: true,
        fillOpacity: 0.4,
      });
      series.appear(1000, 100);

      addInChartItemsToUpdate(series);
    });
    // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
    const cursor = chart.set(
      'cursor',
      am5xy.XYCursor.new(root, {
        xAxis,
      }),
    );
    cursor.lineY.set('visible', false);

    // Scrollbar config
    const scrollbarX = am5xy.XYChartScrollbar.new(root, {
      orientation: 'horizontal',
      height: 50,
    });

    chart.set('scrollbarX', scrollbarX);

    const sbxAxis = scrollbarX.chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        groupData: true,
        groupIntervals: [{ timeUnit: 'day', count: 1 }],
        baseInterval: { timeUnit: 'day', count: 1 },
        renderer: am5xy.AxisRendererX.new(root, {
          opposite: false,
          strokeOpacity: 0,
        }),
      }),
    );

    const sbyAxis = scrollbarX.chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {}),
      }),
    );

    const sbseries = scrollbarX.chart.series.push(
      am5xy.LineSeries.new(root, {
        name: 'Total (Sum)',
        xAxis: sbxAxis,
        yAxis: sbyAxis,
        valueYField: 'total',
        valueXField: 'date',
      }),
    );
    sbseries.data.setAll(chartData);
    addInChartItemsToUpdate(sbseries);

    // Add legend
    const legend = chart.children.push(
      am5.Legend.new(root, {
        paddingLeft: 35,
        useDefaultMarker: true,
      }),
    );
    legend.data.setAll(chart.series.values);

    chart.appear(1000, 100);
    return () => {
      root.dispose();
    };
  }, []);
  useLayoutEffect(() => {
    chartItemsToUpdate.forEach((item) => {
      item.data.setAll(chartData);
    });
  }, [pipelineChartsData]);

  return (
    <div
      id="pipelineVelocityChart"
      style={{ width: '100%', height: '500px' }}
    />
  );
}
