import React from 'react';
import { useGetProjectQuery } from 'bundles/REconcile/components/development/api/projects';
import { TRouteParams, useQueryParams } from '@/shared/lib/hooks/useNavigation';
import SolidGauge from 'bundles/REconcile/components/development/home/dashboard/charts/SolidGauge';
import GaugeWithGradientFill from 'bundles/REconcile/components/development/home/dashboard/charts/GaugeWithGradientFill';
import { TitledCard } from 'stories/TitledCard/TitledCard';
import { cn } from '@/shared/lib/css/cn';
import { PercentFormatter } from 'stories/ValueFormatters/PercentFormatter';

const CardContentContainer = ({ children }: React.PropsWithChildren) => (
  <div className="flex h-full w-full flex-col items-center gap-2">
    {children}
  </div>
);

function ReconcileDevelopmentDashboardProjectCharts() {
  const { projectId } = useQueryParams<TRouteParams['RECONCILE_DEVELOPMENT']>();
  const { data } = useGetProjectQuery(projectId!);

  return (
    <div
      className={cn(
        'grid grid-cols-[repeat(3,minmax(200px,1fr))] gap-4',
        'rounded-2xl',
      )}
    >
      <TitledCard>
        <SolidGauge data={data!.percentOfCompletionByCategories} />
      </TitledCard>

      <TitledCard title="Hard Cost Contingency">
        <CardContentContainer>
          <PercentFormatter
            classes={{ wrapper: 'header5-regular', value: 'text-dark-60' }}
            value={data!.hardCostContingencyUsedPercent}
          />
          <GaugeWithGradientFill data={data!.hardCostContingencyUsedPercent} />
        </CardContentContainer>
      </TitledCard>
      <TitledCard title="Soft Cost Contingency">
        <CardContentContainer>
          <PercentFormatter
            classes={{ wrapper: 'header5-regular', value: 'text-dark-60' }}
            value={data!.softCostContingencyUsedPercent}
          />
          <GaugeWithGradientFill data={data!.softCostContingencyUsedPercent} />
        </CardContentContainer>
      </TitledCard>
    </div>
  );
}

export default ReconcileDevelopmentDashboardProjectCharts;
