import Table from 'bundles/Shared/components/Table/Table';
import { ComponentProps } from 'react';

export const SELECTION_COLUMN_CLASSES: ComponentProps<
  typeof Table
>['selectionColumn'] = {
  classes: {
    td: '!px-2 !py-4 !w-8 bg-light',
    th: '!px-2 !py-3 !w-8',
  },
};
