import {
  ReportThirdPartyExtractionConnectionDto,
  ReportThirdPartyExtractionEmailConnectionInboundEmailAttachmentDto,
} from '@/shared/api/settingsReportConnectionsGeneratedApi';
import { useDeleteExtractionAttachment } from '@/features/report/connections/deleteExtractionAttachment/lib';
import { IconButton } from '@/stories/IconButton/IconButton';
import { CamelCasedProperties } from 'type-fest';

type DeleteAttachmentBodyParams = CamelCasedProperties<
  Partial<
    Pick<
      Parameters<
        ReturnType<typeof useDeleteExtractionAttachment>[0]
      >[0]['body'],
      'manual_attachments'
    >
  >
>;

export const DeleteExtractionAttachmentIconButton = ({
  attachmentId,
  connectionId,
  manualAttachments,
}: {
  connectionId: ReportThirdPartyExtractionConnectionDto['id'];
  attachmentId: ReportThirdPartyExtractionEmailConnectionInboundEmailAttachmentDto['id'];
} & DeleteAttachmentBodyParams) => {
  const [deleteAttachment, options] = useDeleteExtractionAttachment();

  return (
    <IconButton
      disabled={options.isLoading}
      variant="secondary"
      iconName="trash"
      onClick={() =>
        deleteAttachment({
          id: attachmentId,
          connectionId,
          body: {
            manual_attachments: manualAttachments,
          },
        })
      }
    />
  );
};
