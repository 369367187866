import { emptySplitApi as api } from '@/app/stores/api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiInvestmentEntities: build.query<
      GetApiInvestmentEntitiesApiResponse,
      GetApiInvestmentEntitiesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/investment_entities`,
        params: {
          page: queryArg.page,
          size_per_page: queryArg.sizePerPage,
          search_query: queryArg.searchQuery,
          filters: queryArg.filters,
          sort_field: queryArg.sortField,
          sort_order: queryArg.sortOrder,
        },
      }),
    }),
    postApiInvestmentEntities: build.mutation<
      PostApiInvestmentEntitiesApiResponse,
      PostApiInvestmentEntitiesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/investment_entities`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    putApiInvestmentEntitiesById: build.mutation<
      PutApiInvestmentEntitiesByIdApiResponse,
      PutApiInvestmentEntitiesByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/investment_entities/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    deleteApiInvestmentEntitiesById: build.mutation<
      DeleteApiInvestmentEntitiesByIdApiResponse,
      DeleteApiInvestmentEntitiesByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/investment_entities/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    deleteApiInvestmentEntitiesBulkDestroy: build.mutation<
      DeleteApiInvestmentEntitiesBulkDestroyApiResponse,
      DeleteApiInvestmentEntitiesBulkDestroyApiArg
    >({
      query: (queryArg) => ({
        url: `/api/investment_entities/bulk`,
        method: 'DELETE',
        body: queryArg.body,
      }),
    }),
    postApiInvestmentEntitiesByIdConfirmCredentials: build.mutation<
      PostApiInvestmentEntitiesByIdConfirmCredentialsApiResponse,
      PostApiInvestmentEntitiesByIdConfirmCredentialsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/investment_entities/${queryArg.id}/confirm_credentials`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as investmentEntityGeneratedApi };
export type GetApiInvestmentEntitiesApiResponse = /** status 200 success */ {
  items: InvestmentEntityDto[];
  meta: {
    totalSize: number;
  };
};
export type GetApiInvestmentEntitiesApiArg = {
  page?: number;
  sizePerPage?: number;
  searchQuery?: string;
  filters?: {
    vendor_entity_ids?: number[];
    user_ids?: number[];
  };
  sortField?:
    | 'name'
    | 'updated_at'
    | 'total_contributions'
    | 'total_distributions';
  sortOrder?: 'asc' | 'desc';
};
export type PostApiInvestmentEntitiesApiResponse = unknown;
export type PostApiInvestmentEntitiesApiArg = {
  body: {
    investment_entity?: {
      name: string;
      user_ids?: string[];
      vendor_entity_ids?: string[];
      lead_user_ids?: string[];
      address_attributes?: {
        address_line1?: string;
        address_line2?: string;
        city?: string;
        state?: string;
        zip_code?: string;
        country?: string;
      };
      bank_details_attributes?: {
        account_name?: string;
        account_number?: string;
        bank_name?: string;
        for_credit_to?: string;
        method?: string;
        payment_reference?: string;
        routing_number?: string;
        account_type?: string;
      };
    };
  };
};
export type PutApiInvestmentEntitiesByIdApiResponse = unknown;
export type PutApiInvestmentEntitiesByIdApiArg = {
  id: string;
  body: {
    investment_entity?: {
      name?: string;
      user_ids?: number[];
      vendor_entity_ids?: number[];
      address_attributes?: {
        id?: number;
        address_line1?: string;
        address_line2?: string;
        city?: string;
        state?: string;
        zip_code?: string;
        country?: string;
        _destroy?: boolean;
      };
      bank_details_attributes?: {
        id?: number;
        account_name?: string;
        account_number?: string;
        bank_name?: string;
        for_credit_to?: string;
        method?: string;
        payment_reference?: string;
        routing_number?: string;
        account_type?: string;
        _destroy?: boolean;
      };
      tax_details_attributes?: {
        id?: number;
        ssn?: string;
        number?: string;
        _destroy?: boolean;
      };
    };
  };
};
export type DeleteApiInvestmentEntitiesByIdApiResponse = unknown;
export type DeleteApiInvestmentEntitiesByIdApiArg = {
  id: string;
};
export type DeleteApiInvestmentEntitiesBulkDestroyApiResponse = unknown;
export type DeleteApiInvestmentEntitiesBulkDestroyApiArg = {
  body: any;
};
export type PostApiInvestmentEntitiesByIdConfirmCredentialsApiResponse =
  unknown;
export type PostApiInvestmentEntitiesByIdConfirmCredentialsApiArg = {
  id: string;
  body: {
    bank_details?: boolean;
    tax_details?: boolean;
  };
};
export type InvestmentEntityDto = {
  id: number;
  name: string;
  createdAt: number;
  updatedAt: number;
  updatedBy: {
    fullName: string;
  };
  address: {
    id: string;
    addressLine1: string;
    addressLine2: string;
    city: string;
    state: string;
    zipCode: string;
    country: string;
  } | null;
  bankDetails: {
    id: string;
    accountName: string;
    accountNumber: string;
    bankName: string;
    forCreditTo: string;
    paymentMethod: string;
    paymentReference: string;
    routingNumber: string;
    accountType: string;
    confirmedAt: number | null;
    requestedAt: number | null;
  } | null;
  taxDetails: {
    id: string;
    ssn: string;
    number: string;
    confirmedAt: number | null;
    requestedAt: number | null;
  } | null;
  capitalAssets: {
    id: number;
    name: string;
  }[];
  capitalFunds: {
    id: number;
    name: string;
  }[];
  linkedMembersCount: number;
  totalContributions: number;
  totalContributionsCents: number;
  users: {
    id: string;
    fullName: string;
  }[];
  leadUsers: {
    id: string;
    fullName: string;
  }[];
  credentialsStatus: 'missing' | 'unconfirmed' | 'confirmed';
  credentialsConfirmedAt?: number | null;
  canBeDeleted: boolean;
};
export const {
  useGetApiInvestmentEntitiesQuery,
  usePostApiInvestmentEntitiesMutation,
  usePutApiInvestmentEntitiesByIdMutation,
  useDeleteApiInvestmentEntitiesByIdMutation,
  useDeleteApiInvestmentEntitiesBulkDestroyMutation,
  usePostApiInvestmentEntitiesByIdConfirmCredentialsMutation,
} = injectedRtkApi;
