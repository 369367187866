import { useForecastBudgets } from '@/entities/reconcile/forecasting/features/api/forecast/useForecastBudgets';
import { useMemo } from 'react';

export const useForecastBudgetsByLegalEntityCode = (
  forecastId: string | undefined,
  year: number | undefined,
  legalEntityCode: string | undefined,
) => {
  const { budgets, ...options } = useForecastBudgets(forecastId, year);

  const filteredData = useMemo(
    () => budgets?.filter((item) => item.legalEntity.code === legalEntityCode),
    [budgets, legalEntityCode],
  );

  return {
    data: filteredData,
    ...options,
  };
};
