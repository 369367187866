import pluralize from 'pluralize';
import { REMOVE_SHARED_FILES } from '@/bundles/Assets/actions/SharedFiles';
import { deleteTrashDocuments as remove } from '@/lib/trashDocuments';

export default function removeTrashDocuments(data) {
  return async (dispatch) => {
    const { ids } = data;
    const responseData = await remove(ids);
    const { errors } = responseData;

    if (errors) {
      toastr.error(`${errors}\n`);
    } else {
      toastr.success(
        `${pluralize('Document', ids.length)} ${pluralize('have', ids.length)} been removed`,
      );
      dispatch({
        type: REMOVE_SHARED_FILES,
        payload: data,
      });
    }
  };
}
