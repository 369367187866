import { generatePath, ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import { Link, useLocation } from '@reach/router';
import Table from 'bundles/Shared/components/Table/Table';
import {
  IColumn,
  ISortSettings,
  ITableProps,
} from 'bundles/Shared/components/Table/types';
import { currentUserIsExternal } from 'lib/permissions';
import { orderBy } from 'lodash-es';
import { useMemo, useState } from 'react';
import { IAsset } from 'types/Asset';
import { TAssetStatuses } from 'types/AssetStatus';
import { nameOf } from 'types/utils';
import AssetStageBadge from '@/bundles/Assets/components/AssetBadge/AssetStageBadge';
import AssetStatusBadge from '@/bundles/Assets/components/AssetBadge/AssetStatusBadge';
import AccessCell from '@/bundles/Assets/components/Table/cellFormatters/access/AccessCell';
import ActionsCell from '@/bundles/Assets/components/Table/cellFormatters/actions/ActionsCell';
import NameCell from '@/bundles/Assets/components/Table/cellFormatters/name/NameCell';
import UserCountCell from '@/bundles/Assets/components/Table/cellFormatters/userCount/UserCountCell';

interface IAssetWithExtraFields extends IAsset {
  externalUserCount: number;
  internalUserCount: number;
}

type TAssetsTableColumnNames =
  | keyof Pick<
      IAssetWithExtraFields,
      'name' | 'stage' | 'aasmState' | 'externalUserCount' | 'internalUserCount'
    >
  | 'actions'
  | 'access';

type AvailableColumnProps = Pick<
  IColumn<IAsset>,
  | 'hidden'
  | 'sortable'
  | 'headerStyle'
  | 'classes'
  | 'headerClasses'
  | 'text'
  | 'formatter'
  | 'formatterParams'
>;

type TAssetsTableColumns = Partial<
  Record<TAssetsTableColumnNames, AvailableColumnProps>
>;

interface Props
  extends Pick<
    ITableProps<IAssetWithExtraFields>,
    'classes' | 'tableStriped' | 'borderLessOutside'
  > {
  assets: IAsset[];
  columns?: TAssetsTableColumns;
  defaultColumnOverride?: AvailableColumnProps;
}

const EXTERNAL_USER_COLUMNS: TAssetsTableColumnNames[] = ['name', 'stage'];

function AssetsTable({
  assets,
  columns: initialColumns,
  defaultColumnOverride,
  ...props
}: Props) {
  const isUserExternal = useMemo(
    () => currentUserIsExternal(),
    [window.symmetreConfig.currentUser],
  );
  const { pathname } = useLocation();
  const [sortSettings, setSortSettings] = useState<
    ISortSettings<IAssetWithExtraFields>
  >({});

  const assetsWithExtraFields = useMemo<IAssetWithExtraFields[]>(
    () =>
      assets.map((a) => ({
        ...a,
        externalUserCount: a?.users?.filter((ax) => ax.external).length,
        internalUserCount: a?.users?.filter((ax) => !ax.external).length,
      })),
    [assets],
  );

  const sortedAssets = useMemo(
    () =>
      sortSettings.sortField != null
        ? orderBy(
            assetsWithExtraFields,
            (asset) => asset[sortSettings.sortField],
            sortSettings.sortOrder,
          )
        : assetsWithExtraFields,
    [assetsWithExtraFields, sortSettings],
  );

  const columns = useMemo<IColumn<IAssetWithExtraFields>[]>(
    () =>
      [
        {
          dataField: nameOf<TAssetsTableColumnNames>('name'),
          text: 'Asset',
          formatter: ({ row: asset }) => (
            <Link
              to={generatePath(ROUTES_ROOT.assets.asset.fullPath, {
                assetSlug: asset.slug,
              })}
              state={{ from: pathname }}
            >
              <NameCell asset={asset} />
            </Link>
          ),
          sortable: true,
          ...initialColumns?.name,
        },
        {
          dataField: nameOf<TAssetsTableColumnNames>('stage'),
          text: 'Stage',
          formatter: ({ row: asset }) => <AssetStageBadge asset={asset} />,
          headerStyle: {
            width: 140,
          },
          sort: false,
          ...initialColumns?.stage,
        },
        {
          dataField: nameOf<TAssetsTableColumnNames>('aasmState'),
          text: 'Status',
          formatter: ({ row: asset }) => (
            <AssetStatusBadge status={asset.aasmState} />
          ),
          headerStyle: {
            width: 140,
          },
          sort: false,
          ...initialColumns?.aasmState,
        },
        {
          dataField: nameOf<TAssetsTableColumnNames>('access'),
          text: 'Access',
          formatter: ({ row: asset }) => <AccessCell asset={asset} />,
          ...initialColumns?.access,
        },
        {
          dataField: nameOf<TAssetsTableColumnNames>('internalUserCount'),
          text: 'Internal',
          formatter: ({ row: asset }) => (
            <UserCountCell count={asset.internalUserCount} />
          ),
          headerStyle: {
            width: 120,
          },
          sortable: true,
          ...initialColumns?.internalUserCount,
        },
        {
          dataField: nameOf<TAssetsTableColumnNames>('externalUserCount'),
          text: 'External',
          formatter: ({ row: asset }) => (
            <UserCountCell count={asset.externalUserCount} />
          ),
          headerStyle: {
            width: 120,
          },
          sortable: true,
          ...initialColumns?.externalUserCount,
        },
        {
          dataField: nameOf<TAssetsTableColumnNames>('actions'),
          text: 'Actions',
          formatter: (params) => <ActionsCell {...params} />,
          classes: 'align-middle text-center',
          headerStyle: {
            width: 85,
          },
          ...initialColumns?.actions,
        },
      ].filter((c) =>
        !isUserExternal ? true : EXTERNAL_USER_COLUMNS.includes(c.dataField),
      ),
    [initialColumns, isUserExternal],
  );

  const isStatusColumnPresented = () => {
    const statusColumn = columns.find(
      (c) => c.dataField === nameOf<TAssetsTableColumnNames>('aasmState'),
    );
    return (
      statusColumn != null &&
      (statusColumn.hidden === undefined || statusColumn.hidden === false)
    );
  };

  return (
    <Table
      items={sortedAssets}
      settings={sortSettings}
      borderLessOutside
      setSettings={(settings) => setSortSettings(settings)}
      defaultColumn={{
        classes: 'align-middle',
        cellClassRules: {
          'bg-white': (row) => row.aasmState !== TAssetStatuses.ARCHIVED,
          'bg-light-5': (row) =>
            isStatusColumnPresented() &&
            row.aasmState === TAssetStatuses.ARCHIVED,
        },
        ...defaultColumnOverride,
      }}
      columns={columns}
      {...props}
    />
  );
}

export default AssetsTable;
