import { asserts } from '@/lib/typeHelpers/assertsType';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import { SendDataButton } from 'bundles/DrawPackage/components/SendDataButton/SendDataButton';
import { SharedInput } from 'bundles/Shared/components/GroupForm/FormItems/new/SharedInput';
import { IConnection } from 'bundles/Shared/entities/connection/model';
import { usePutApiPropertyManagementCompaniesMutation } from '@/entities/propertyManagementCompany/api/settingPropertyManagementCompanyApi';
import { FC } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { Button } from 'stories/Button/Button';
import { Modal } from 'stories/Modals/Modal/Modal';
import Field, { FieldsContainer } from 'stories/Field/Field';
import { IconButton } from '@/stories/IconButton/IconButton';
import { SharedTextArea } from 'bundles/Shared/components/GroupForm/FormItems/new/SharedTextArea';
import { useConnectionById } from '@/bundles/Shared/entities/connection/api';
interface IKeyContactsModalProps extends DialogProps {
  connectionId?: IConnection['id'];
}

const KeyContactsModal: FC<IKeyContactsModalProps> = ({
  onClose,
  connectionId,
}) => {
  const { data: connectionData, refetch: refetchConnection } =
    useConnectionById(connectionId!, {
      skip: !connectionId,
    });

  const connection = connectionData?.item as IConnection | undefined;

  const [
    updatePMC,
    { isLoading: isLoadingUpdate, isSuccess: isSuccessUpdating },
  ] = usePutApiPropertyManagementCompaniesMutation();

  if (isSuccessUpdating) {
    onClose();
    refetchConnection();
  }

  const {
    control,
    getValues,
    formState: { isValid },
  } = useForm<FormValues>({
    defaultValues: {
      contactPersons: connection?.company?.contactPersons ?? [],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'contactPersons',
    shouldUnregister: false,
  });

  const onSubmit = () => {
    const values = getValues();
    asserts(connection);

    const filteredContacts = values.contactPersons.filter((person) => {
      if (person._destroy) {
        return true;
      }

      return Boolean(
        person.name?.trim() ??
          person.role?.trim() ??
          person.email?.trim() ??
          person.phone?.trim(),
      );
    });

    updatePMC({
      id: connection.company.id,
      body: {
        contact_persons_attributes: filteredContacts.map((person) => ({
          ...person,
          _destroy: person._destroy ?? false,
        })),
      },
    });
  };

  const markAsDestroyed = (index: number) => {
    const person = getValues(`contactPersons.${index}`);
    if (person.id) {
      remove(index);
      append({ ...person, _destroy: true });
    } else {
      remove(index);
    }
  };

  return (
    <Modal
      toggle={onClose}
      header="PMC Key Contacts"
      classes={{
        body: 'bg-neutral-100',
      }}
      actions={
        <>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <SendDataButton
            variant="success"
            onClick={onSubmit}
            isLoading={isLoadingUpdate}
            disabled={!isValid || isLoadingUpdate}
          >
            {connectionId ? 'Update' : 'Add'} PMC Key Contact
          </SendDataButton>
        </>
      }
    >
      {getValues('contactPersons').filter((person) => !person._destroy)
        .length == 0 && (
        <div className="text-center text-sm text-neutral-500">
          No PMC Key Contacts
        </div>
      )}
      <FieldsContainer>
        {fields.map((field, index) => {
          if (getValues(`contactPersons.${index}._destroy`)) {
            return null;
          }

          const visibleIndex = getValues('contactPersons')
            .slice(0, index)
            .filter((person) => !person._destroy).length;

          return (
            <div key={field.id} className="flex flex-col gap-4">
              <div className="flex items-center justify-between">
                <div className="inline-semibold uppercase">
                  PMC Key Contact {visibleIndex + 1}
                </div>
                <IconButton
                  iconName="trash"
                  onClick={() => markAsDestroyed(index)}
                />
              </div>

              <Field labelText="Name" newGen>
                <Controller
                  control={control}
                  name={`contactPersons.${index}.name`}
                  render={({ field: nameField }) => (
                    <SharedInput
                      value={nameField.value}
                      onChange={nameField.onChange}
                      placeholder="Enter Name"
                      newGen
                    />
                  )}
                />
              </Field>

              <Field labelText="Role" newGen>
                <Controller
                  control={control}
                  name={`contactPersons.${index}.role`}
                  render={({ field: roleField }) => (
                    <SharedInput
                      value={roleField.value}
                      onChange={roleField.onChange}
                      placeholder="Enter Role"
                      newGen
                    />
                  )}
                />
              </Field>

              <Field labelText="Email" newGen>
                <Controller
                  control={control}
                  name={`contactPersons.${index}.email`}
                  render={({ field: emailField }) => (
                    <SharedInput
                      value={emailField.value}
                      onChange={emailField.onChange}
                      placeholder="Enter Email"
                      type="email"
                      newGen
                    />
                  )}
                />
              </Field>

              <Field labelText="Phone" newGen>
                <Controller
                  control={control}
                  name={`contactPersons.${index}.phone`}
                  render={({ field: phoneField }) => (
                    <SharedInput
                      value={phoneField.value}
                      onChange={phoneField.onChange}
                      placeholder="+1"
                      newGen
                    />
                  )}
                />
              </Field>

              <Field labelText="Note" newGen>
                <Controller
                  control={control}
                  name={`contactPersons.${index}.note`}
                  render={({ field: noteField }) => (
                    <SharedTextArea
                      value={noteField.value}
                      onChange={noteField.onChange}
                      placeholder="Enter Note"
                    />
                  )}
                />
              </Field>
            </div>
          );
        })}

        <Button variant="secondary" onClick={() => append({})} className="mt-4">
          Add PMC Key Contact
        </Button>
      </FieldsContainer>
    </Modal>
  );
};

export default KeyContactsModal;
