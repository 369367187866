import { Logo } from '@/bundles/Shared/components/LeftSidebar/Logo/Logo';
import { REPORT_BUILDER_GRID_COLS_FULL_SPAN_CLASSNAME } from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/reportBuilder';
import { cn } from '@/shared/lib/css/cn';

import { isEmpty } from 'lodash-es';

export const ReportPDFHeader = ({
  objectName,
  objectAddress,
  reportSubtitle,
  reportTitle,
  reportDate,
  className,
}: {
  reportTitle: string;
  objectName?: string;
  objectAddress?: string;
  reportSubtitle?: string | null;
  reportDate?: string;
} & PropsWithClassName) => (
  <div
    className={cn(
      'flex items-center justify-between border-b border-neutral-150 py-4',
      REPORT_BUILDER_GRID_COLS_FULL_SPAN_CLASSNAME,
      className,
    )}
  >
    <div className="flex flex-col gap-2">
      <div className="flex flex-col gap-0.5">
        {(objectName != null || objectAddress != null) && (
          <div className="5 flex flex-col gap-0">
            {objectName && (
              <p className="body-semibold text-[24px] text-neutral-800">
                {objectName}
              </p>
            )}
            {objectAddress && (
              <p className="inline-regular text-[21px] text-neutral-550">
                {objectAddress}
              </p>
            )}
          </div>
        )}
        <p className="inline-regular inline-flex gap-[1ch] text-[21px] text-neutral-800">
          {reportTitle}
        </p>
        <p className="inline-regular inline-flex gap-[1ch]  text-neutral-800">
          {reportSubtitle != null && !isEmpty(reportSubtitle) && (
            <p>{reportSubtitle}</p>
          )}
          {reportSubtitle != null &&
            !isEmpty(reportSubtitle) &&
            reportDate != null &&
            ' • '}
          {reportDate != null && <p>{reportDate}</p>}
        </p>
      </div>
    </div>
    <div className="grow" />
    <Logo
      className="mb-2 h-[3.5rem] max-w-full object-contain print:block"
      variant="large"
    />
  </div>
);
