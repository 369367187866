import * as React from 'react';
import { IconButton } from 'stories/IconButton/IconButton';
import { Popover } from 'stories/Popover/Popover';
import { VendorEntity } from 'bundles/Shared/entities/investmentEntities/model';

interface Props {
  vendor?: Pick<VendorEntity, 'code' | 'name'> | null;
}

export function VendorPopover({
  vendor,
  children,
}: React.PropsWithChildren<Props>) {
  const handleCopy = async () => {
    try {
      await window.navigator.clipboard.writeText(vendor!.code);
      toastr.info('Copied to clipboard');
    } catch (_) {
      toastr.error('Unable to write to clipboard');
    }
  };

  return (
    <Popover
      trigger="mouseenter"
      classes={{
        spanContainer: 'max-w-full',
      }}
      className="p-m"
      template={
        <div
          style={{
            width: '11.5rem',
          }}
          className="flex flex-col gap-m"
        >
          <div className="flex flex-col gap-xs">
            <span className="light-60">VENDOR NAME</span>
            <span className="dark-60 inline-semibold">{vendor?.name}</span>
          </div>

          <div className="flex justify-between items-center">
            <div className="flex flex-col gap-xs">
              <span className="light-60">VENDOR CODE</span>
              <span className="dark-60 inline-semibold">{vendor?.code}</span>
            </div>
            <IconButton
              iconName="copy"
              className="bg-white"
              onClick={handleCopy}
            />
          </div>
        </div>
      }
      disabled={vendor == null}
      maxWidth="none"
    >
      {children}
    </Popover>
  );
}

export default VendorPopover;
