import React from 'react';
import { Icon } from 'stories/Icon/Icon';
import { cn } from '@/shared/lib/css/cn';
import styles from '@/bundles/Shared/components/ActivityLog/styles.module.scss';

interface Props {
  label: string;
  newValue: React.ReactNode;
  prevValue?: React.ReactNode;
}

export function LogItemKeyInfo({ newValue, prevValue, label }: Props) {
  const hasBeenChanged = Boolean(newValue != null && prevValue != null);
  const hasBeenRemoved = Boolean(prevValue && newValue === null);

  const getValue = (value: unknown) => {
    if (typeof value === 'boolean') {
      return value ? 'Yes' : 'No';
    }

    return value;
  };

  return (
    <div className={styles.infoRow}>
      <p className="secondary-regular">{label}</p>
      <p className="flex items-center gap-2">
        {prevValue != null && (
          <span
            className={cn({
              'line-through': hasBeenRemoved || hasBeenChanged,
              'dark-60': !hasBeenChanged && !hasBeenRemoved,
            })}
          >
            {getValue(prevValue)}
          </span>
        )}
        {hasBeenChanged && <Icon iconName="arrowRightAlt" />}
        {newValue != null && (
          <span className="dark-60">{getValue(newValue)}</span>
        )}
      </p>
    </div>
  );
}
