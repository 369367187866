import { DateRangeWidgetState } from '@/bundles/Shared/widgets/dashboard/widgets/common';
import { useContextChangeHandler } from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/reportBuilder';
import { ReportingWidgetIdContext } from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/reportBuilderTemplateContext';
import { ReportBuilderTemplateWidgetContext } from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/reportBuilderTemplateWidgetContext';
import { WidgetStateCalendarRangeSelector } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/state/WidgetStateCalendarRangeSelector';
import { widgetHasData } from '@/bundles/Shared/widgets/dashboard/widgets/financialTableSingeDate/lib';
import { WidgetConfigCardProps } from '@/bundles/Shared/widgets/dashboard/widgets/model';
import { selectWidgetById } from '@/entities/report/reportBuilder';
import { ExpandableWidgetConfigCard } from '@/entities/report/reportBuilder/ui/BaseWidgetConfigCard';
import { useAppSelector } from '@/shared/lib/hooks/redux';
import React from 'react';

export const GlobalLeaseWidgetCardConfigCard = (
  props: WidgetConfigCardProps &
    React.ComponentProps<typeof ExpandableWidgetConfigCard>,
) => {
  const widget = useAppSelector(selectWidgetById(props.widgetSection.id));
  const handleContextChange = useContextChangeHandler<DateRangeWidgetState>({
    widgetId: widget?.id ?? '',
    widgetGroupId: widget?.group.id ?? '',
  });
  return (
    <ExpandableWidgetConfigCard {...props} hasData={widgetHasData(props.data)}>
      {widget && (
        <WidgetStateCalendarRangeSelector
          state={widget.context}
          onStateChange={handleContextChange}
        />
      )}
      <ReportBuilderTemplateWidgetContext.Provider value={widget}>
        <ReportingWidgetIdContext.Provider value={widget.id}>
          {/* TODO resolve where to put widgetObjectsConfig when Design is ready */}
          {props.widgetObjectsConfig}
        </ReportingWidgetIdContext.Provider>
      </ReportBuilderTemplateWidgetContext.Provider>
    </ExpandableWidgetConfigCard>
  );
};
