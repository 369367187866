import React, { FC, useState, useMemo } from 'react';
import { Modal } from 'stories/Modals/Modal/Modal';
import { ConfirmationModal } from 'stories/Modals/ConfirmationModal/ConfirmationModal';
import FormComponent from 'bundles/Shared/components/GroupForm/FormComponent/FormComponent';
import FormButtons from 'bundles/Shared/components/GroupForm/FormButtons/FormButtons';
import { useAppSelector } from '@/shared/lib/hooks/redux';
import { selectReallocation } from 'bundles/Construction/reducers/ReconcileReallocationSlice';
import {
  getItemsReallocationModalEdit,
  getItemsReallocationModalEditManual,
} from '@/bundles/Construction/components/Reallocation/ReallocationDetails/initData';

interface IEditREModalProps {
  setShowModal: (val: boolean) => void;
  handleEdit: () => void;
}

const EditREModal: FC<IEditREModalProps> = ({ setShowModal, handleEdit }) => {
  const reallocation = useAppSelector(selectReallocation);
  const [formData, setFormData] = useState({
    ...reallocation,
    moved_from_id: reallocation?.movedFrom.id,
    moved_to_id: reallocation?.movedTo.id,
    subReallocations: reallocation?.children.map((subReallocation) => ({
      ...subReallocation,
      moved_from_id: subReallocation?.movedFrom.id,
      moved_to_id: subReallocation?.movedTo.id,
    })),
  });
  const [validate, setValidate] = useState({
    number: true,
  });
  const [loading, setLoading] = useState(false);
  const onLoading = () => {
    setLoading(true);
  };
  const [showConfirmModal, setConfirmModal] = useState(false);

  const initGroupFormItems = useMemo(
    () =>
      reallocation?.changeOrder
        ? getItemsReallocationModalEdit(formData, setFormData)
        : getItemsReallocationModalEditManual(formData, setFormData),
    [formData, setFormData],
  );

  const closeCOR = () => {
    setConfirmModal(true);
  };

  const confirmModalHandle = (status: boolean) => {
    if (status) {
      setShowModal(false);
    }
    setConfirmModal(false);
  };

  return (
    <>
      <Modal
        header={<div className="dark header6-bold">REallocation Details</div>}
        additionalActions={
          <FormButtons
            formData={formData}
            groupFormItems={initGroupFormItems}
            onSubmit={() => handleEdit(formData)}
            buttonConfig={{
              titleBtn: 'Save Updates',
              funcCancel: closeCOR,
            }}
            loading={loading}
            onLoading={onLoading}
          />
        }
        size="lg"
        classes={{
          body: 'reconcile-common-modal-create',
        }}
        maxHeight
        toggle={closeCOR}
        validate={validate}
      >
        {initGroupFormItems.map((formItem, idx) => (
          <div key={`reItem${idx}`}>
            <div className="dark-60 body-semibold mb-s">
              {formItem.customTitle}
            </div>
            <FormComponent
              {...formItem}
              formData={formData}
              setFormData={setFormData}
              styles={{
                wrapper: 'group-form__step-item',
              }}
              validate={validate}
              setValidate={setValidate}
            />
          </div>
        ))}
      </Modal>
      {showConfirmModal && (
        <ConfirmationModal
          title="Are you sure that you want to quit?"
          subtitle="The changed data will be lost."
          actions={{
            primaryButton: {
              text: 'Yes',
              variant: 'danger',
            },
            secondaryButton: {
              text: 'No',
              variant: 'secondary',
            },
          }}
          handleResolve={confirmModalHandle}
        />
      )}
    </>
  );
};

export default EditREModal;
