import { OBJECT_DASHBOARD_SECTION_TYPE } from 'bundles/Shared/entities/dashboard/model/types/types';
import type { ObjectDashboardSectionTypes } from 'bundles/Shared/entities/dashboard/model/types/types';
import { useGetApiRecapPagesQuery } from '@/shared/api/documentationEnhancedApi';
import {
  useUpdateWidgetConfig,
  useWidgetConfig,
} from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/config';
import { Field } from '@/stories/Field/Field';
import { Select } from '@/stories/FormControls/Select/Select';

export const RecapPageField = () => {
  const { data } = useGetApiRecapPagesQuery({});

  const { widget } =
    useWidgetConfig<typeof OBJECT_DASHBOARD_SECTION_TYPE.KPI_CARD>();

  const [updateConfig] = useUpdateWidgetConfig(
    widget.widgetType as ObjectDashboardSectionTypes,
  );

  const options =
    data?.items
      .filter((item) => !item.isDraft)
      .map((item) => ({
        id: item.slug,
        label: item.name,
        value: item.slug,
      })) ?? [];

  return (
    <Field labelText="FAQ link">
      <Select
        placeholder="Select a link"
        options={options}
        selected={options.find((o) => o.value === widget.recapPage?.slug)}
        onSelectedChange={(selected) => {
          updateConfig({
            config: widget.widgetConfig,
            recapPageSlug: selected?.value ?? null,
          });
        }}
      />
    </Field>
  );
};
