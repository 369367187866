import React, { useState, useEffect } from 'react';
import { Router, useParams, navigate } from '@reach/router';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import { AnimationLoader } from 'stories/AnimationLoader/AnimationLoader';
import { Button } from 'stories/Button/Button';
import KanbanColumn from 'stories/Kanban/KanbanColumn/KanbanColumn';
import { useGetInvoicesKanbanQuery } from '@/bundles/Construction/api/invoices';
import { fetchCompanies } from '@/bundles/Construction/reducers/ReconcileSlice';
import Invoice from '@/bundles/DrawPackage/InvoicesFlow/Invoices/Invoice';
import CreatedInvoiceCard from '@/bundles/DrawPackage/InvoicesFlow/Invoices/invoice/kanban/cards/CreatedInvoiceCard';
import VerifyInvoiceCard from '@/bundles/DrawPackage/InvoicesFlow/Invoices/invoice/kanban/cards/VerifyInvoiceCard';
import ApproveInvoiceCard from '@/bundles/DrawPackage/InvoicesFlow/Invoices/invoice/kanban/cards/ApproveInvoiceCard';
import { transformerDataInvoices } from '@/bundles/DrawPackage/InvoicesFlow/Invoices/invoice/kanban/utils';
import KanbanColumnBadge from '@/bundles/DrawPackage/InvoicesFlow/Invoices/invoice/kanban/badges/KanbanColumnBadge';
import InvoiceUploadModal from '@/bundles/DrawPackage/InvoicesFlow/Invoices/invoice/kanban/upload/ListUploader';
import { NO_INVOICES_NOTIFICATION } from '@/bundles/DrawPackage/InvoicesFlow/Invoices/dumbJSX';
import {
  generatePath,
  ROUTES_ROOT,
  TRouteParams,
} from '@/shared/lib/hooks/useNavigation';
import { clearInvoiceLineItems } from 'bundles/Construction/reducers/reconcileInvoiceStateSlice';
import FinalColumn from 'bundles/DrawPackage/InvoicesFlow/Invoices/invoice/kanban/final/FinalColumn';
import { IQueryParams } from 'types/Pagination';
import { useModal } from '@/shared/lib/hooks/useModal';

const initialPageParams: IQueryParams = {
  query: '',
};

const Invoices = () => {
  // Delete for next steps
  // const companies = useAppSelector(selectCompanies);
  const legalEntity = useAppSelector(
    (store) => store.developmentBudget.legalEntity,
  );
  const dispatch = useAppDispatch();

  const { openModal } = useModal();

  const params =
    useParams<TRouteParams['RECONCILE_DEVELOPMENT_LEGAL_ENTITY_INVOICES']>();
  // Delete for next steps
  const [pageParams, setPageParams] = useState(initialPageParams);
  // const { getUrl } = useNavigation();

  // const { settings, setSettings } = usePageParamsTableSorting(
  //   pageParams,
  //   setPageParams,
  // );

  // Delete for next steps
  // const handleFilterModelChange = useCallback(
  //   (filterModel: TFilterModel) =>
  //     setPageParams((prevParams) => ({
  //       ...prevParams,
  //       status: filterModel.status,
  //       page: 1,
  //       vendor_ids: filterModel.vendorName?.map((item) => item.id),
  //       amount: filterModel.amount,
  //     })),
  //   [],
  // );

  const { data, isLoading } = useGetInvoicesKanbanQuery({
    legalEntityCode: params?.legalEntityCode || legalEntity?.code,
    pageParams,
  });
  useEffect(() => {
    dispatch(fetchCompanies());
  }, []);

  // Delete for next steps
  // const showEmptyPage = useMemo(() => {
  //   const { amount, status, vendor_ids, query } = pageParams;
  //   const filterSelected
  //     = vendor_ids?.length > 0
  //     || Number(amount?.from) > 0
  //     || Number(amount?.to) > 0
  //     || query
  //     || status !== 'all';
  //   return !filterSelected && data?.items?.length === 0;
  // }, [pageParams, data]);

  const haveInvoices =
    data != null &&
    [...data.created.items, ...data.verified.items, ...data.approved.items]
      .length > 0;

  if (isLoading) return <AnimationLoader className="static min-h-[360px]" />;

  const showInvoiceModal = () => {
    openModal(InvoiceUploadModal, {
      legalEntityCode: params.legalEntityCode,
    });
  };

  const openVerifyFlow = (id: string) => {
    dispatch(clearInvoiceLineItems());
    navigate(
      generatePath(
        ROUTES_ROOT.reconcile.development.legalEntity.invoices.verify.fullPath,
        {
          legalEntityCode: params.legalEntityCode,
          invoiceId: id,
        },
      ),
    );
  };

  const openApproveFlow = (id: string) => {
    navigate(
      generatePath(
        ROUTES_ROOT.reconcile.development.legalEntity.invoices.approve.fullPath,
        {
          legalEntityCode: params.legalEntityCode,
          invoiceId: id,
        },
      ),
    );
  };

  const openPayFlow = (id: string) => {
    navigate(
      generatePath(
        ROUTES_ROOT.reconcile.development.legalEntity.invoices.pay.fullPath,
        {
          legalEntityCode: params.legalEntityCode,
          invoiceId: id,
        },
      ),
    );
  };

  return (
    <div className="custom-container">
      <div className="flex justify-between">
        <div>
          <h2 className="font-weight-700 mb-l mt-xl">Invoices</h2>
        </div>
        {haveInvoices && (
          <div className="flex items-center justify-between">
            <Button
              className="ml-m"
              variant="primary"
              onClick={showInvoiceModal}
            >
              Upload Invoice
            </Button>
          </div>
        )}
      </div>
      <div className="flex gap-3">
        {!haveInvoices && (
          <div className="flex w-[100%] flex-col items-center gap-2 pt-16">
            {NO_INVOICES_NOTIFICATION}
            <div className="flex items-center gap-2">
              <Button variant="secondary" disabled>
                Auto-Uploading
              </Button>
              <Button variant="primary" onClick={showInvoiceModal}>
                Upload Invoice
              </Button>
            </div>
          </div>
        )}
        {haveInvoices &&
          transformerDataInvoices(data, isLoading).map((column) => (
            <KanbanColumn
              key={column.status}
              headerColumn={
                <div className="px-4 pb-2 pt-1">
                  <div className="flex gap-1.5">
                    <div className="body-semibold text-dark-60">
                      {column.title}
                    </div>
                    <div>{column.invoices?.meta.count}</div>
                  </div>
                </div>
              }
              badge={<KanbanColumnBadge {...column} />}
            >
              <div className="form-item__list-select_scrollbar flex max-h-[70vh] flex-col gap-1.5 overflow-auto">
                {column.invoices?.items.map((invoice) => (
                  <>
                    {column.status === 'created' && (
                      <div
                        className="cursor-pointer"
                        onClick={() => openVerifyFlow(invoice.id)}
                      >
                        <CreatedInvoiceCard {...invoice} />
                      </div>
                    )}
                    {column.status === 'verified' && (
                      <div
                        className="cursor-pointer"
                        onClick={() => openApproveFlow(invoice.id)}
                      >
                        <VerifyInvoiceCard {...invoice} />
                      </div>
                    )}
                    {column.status === 'approved' && (
                      <div
                        className="cursor-pointer"
                        onClick={() => openPayFlow(invoice.id)}
                      >
                        <ApproveInvoiceCard {...invoice} />
                      </div>
                    )}
                  </>
                ))}
              </div>
            </KanbanColumn>
          ))}
        <KanbanColumn>
          <div className="flex flex-col gap-1">
            <div className="inline-semibold px-4 text-light-80">Invoices</div>
            <FinalColumn legalEntityCode={params?.legalEntityCode} />
          </div>
        </KanbanColumn>
      </div>

      <Router primary={false}>
        <Invoice
          backRoute="RECONCILE_DEVELOPMENT_LEGAL_ENTITY_INVOICES"
          path=":id/*"
          invoicesFlow
        />
      </Router>
    </div>
  );
};

export default Invoices;
