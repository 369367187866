import { reportFinancialCategoriesApi } from '@/shared/api/reportFinancialCategoriesApiGeneratedApi';

const REPORT_FINANCIAL_CATEGORIES_TAG = 'ReportFinancialCategories';

export const reportFinancialCategoriesApiEnhanced =
  reportFinancialCategoriesApi.enhanceEndpoints({
    addTagTypes: [REPORT_FINANCIAL_CATEGORIES_TAG],
    endpoints: {
      getApiReportFinancialCategoriesTree: {
        providesTags: [REPORT_FINANCIAL_CATEGORIES_TAG],
      },

      putApiReportFinancialCategoriesTree: {
        async onQueryStarted(_, { dispatch, queryFulfilled }) {
          try {
            const { data } = await queryFulfilled;
            dispatch(
              reportFinancialCategoriesApi.util.updateQueryData(
                'getApiReportFinancialCategoriesTree',
                undefined,
                (draft) => {
                  Object.assign(draft, data);
                },
              ),
            );
          } catch {
            console.error('Failed to update financial categories tree');
          }
        },
        transformResponse: (response) => {
          toastr.success('Financial Categories successfully updated');
          return response;
        },
      },
    },
  });

export const {
  useGetApiReportFinancialCategoriesTreeQuery,
  usePutApiReportFinancialCategoriesTreeMutation,
  usePostApiReportFinancialCategoriesSyncWithSourceMutation,
  usePostApiReportFinancialCategoriesSyncMutation,
} = reportFinancialCategoriesApiEnhanced;
