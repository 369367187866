import {
  GENERAL_LEDGERS_COLUMN_IDS,
  GroupedByTableActions,
} from '@/bundles/Settings/components/REport/GeneralLedgers/Table/useColumnDefs';
import UserFireStationFilterButton from '@/bundles/Shared/components/Filters/buttons/userFireStationFilterButton/UserFireStationFilterButton';
import { IColumn } from '@/bundles/Shared/components/Table/types';
import {
  LeClassification,
  LeClassificationFormatter,
  LeClassificationModal,
} from 'bundles/Shared/entities/leClasssification';
import { useModal } from '@/shared/lib/hooks/useModal';
import { ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import { IconButton } from '@/stories/IconButton/IconButton';
import { Tooltip } from '@/stories/Tooltip/Tooltip';
import { IGeneralLedgerOnIndex } from '@/types/GeneralLedger';
import { Link } from '@reach/router';
import { IHeaderParams } from 'ag-grid-community';

// TODO: review legal entity table actions

export function ClassificationHeaderColumnComponent({
  misclassified,
  misclassifiedSize,
  actions,
}: {
  misclassified: boolean;
  misclassifiedSize: number;
  actions: Pick<
    GroupedByTableActions<IGeneralLedgerOnIndex>,
    | 'onMisclassifiedFilterChange'
    | 'onDisableMisclassified'
    | 'onSetClassification'
  >;
}) {
  return (
    <div className="flex w-full items-center justify-between gap-2">
      Class
      <Link to={ROUTES_ROOT.settings.portal.legalEntities.fullPath}>
        <IconButton iconName="entities" />
      </Link>
      {misclassifiedSize > 0 && (
        <Tooltip mainText="The category's asset class doesn't match this legal entity's class.">
          <UserFireStationFilterButton
            filtered={misclassified}
            onClick={actions.onMisclassifiedFilterChange}
            onClose={actions.onDisableMisclassified}
          >
            {misclassifiedSize}
          </UserFireStationFilterButton>
        </Tooltip>
      )}
    </div>
  );
}

export function legalEntityClassificationColumn({
  actions,
  misclassified,
  misclassifiedSize,
}: {
  misclassified: boolean;
  misclassifiedSize: number;
  actions: Pick<
    GroupedByTableActions<IGeneralLedgerOnIndex>,
    | 'onMisclassifiedFilterChange'
    | 'onDisableMisclassified'
    | 'onSetClassification'
  >;
}): IColumn<IGeneralLedgerOnIndex> {
  return {
    colId: GENERAL_LEDGERS_COLUMN_IDS.legelEntityClassification,
    headerName: 'Class',
    field: 'legalEntity.classification',
    headerComponent: ({ displayName }: IHeaderParams) => (
      <div className="flex w-full items-center justify-between">
        {displayName}
        <Link to={ROUTES_ROOT.settings.portal.legalEntities.fullPath}>
          <IconButton iconName="entities" />
        </Link>

        {misclassifiedSize > 0 && (
          <Tooltip mainText="The category's asset class doesn't match this legal entity's class.">
            <UserFireStationFilterButton
              filtered={misclassified}
              onClick={actions.onMisclassifiedFilterChange}
              onClose={actions.onDisableMisclassified}
            >
              {misclassifiedSize}
            </UserFireStationFilterButton>
          </Tooltip>
        )}
      </div>
    ),
    cellRenderer: ({
      value,
      data,
    }: {
      value: LeClassification;
      data: IGeneralLedgerOnIndex;
    }) => {
      const row = data;
      const { openModal } = useModal();
      return (
        <LeClassificationFormatter
          showFireIcon={row.misclassified}
          classification={value}
          onClick={async () => {
            const res = await openModal(LeClassificationModal, {
              header: undefined,
            });

            if (!res) {
              return;
            }

            actions.onSetClassification?.(res as LeClassification, row);
          }}
        />
      );
    },
  };
}
