import React, { useState } from 'react';
import pluralize from 'pluralize';
import { Icon } from 'stories/Icon/Icon';
import { SreAvatar } from 'stories/SreAvatar/SreAvatar';
import http from 'lib/http';
import EmailMessageSnapshotModal from '@/bundles/UserManagement/components/EmailMessageSnapshotModal';

const EmailListItem = ({
  emailMessageId,
  snapshotId,
  subject,
  fullName,
  roleName,
  attachmentsCount,
}) => {
  const [isSnapshotOpened, setIsSnapshotOpened] = useState(false);

  const closeSnapshot = () => setIsSnapshotOpened(false);
  const fetchEmailMessageSnapshot = () =>
    http.get(`/user_email_messages/${emailMessageId}/snapshots/${snapshotId}`);

  const WrappedIcon = ({ iconClass }) => (
    <div className="flex bg-light-10 border-radius-[0.5rem] w-[1.5rem] h-[1.5rem] justify-center items-center">
      <Icon iconName={iconClass} className="light-60" />
    </div>
  );

  return (
    <div className="p-[1rem] flex justify-between items-center">
      <div className="flex">
        <SreAvatar
          text={fullName}
          height="3rem"
          width="3rem"
          className="mr-m"
        />
        <div className="flex flex-col">
          <span className="inline-semibold inline-regular dark-60">
            {fullName}
          </span>
          <span className="inline-regular inline-regular light-60">
            {roleName}
          </span>
        </div>
      </div>
      <div className="flex">
        {attachmentsCount > 0 && (
          <div className="flex mr-[1.5rem] items-center">
            <WrappedIcon iconClass="attach" />
            <span className="inline-regular dark-60 ml-[0.5rem]">
              {attachmentsCount} {pluralize('attachment', attachmentsCount)}
            </span>
          </div>
        )}
        <div className="flex">
          <WrappedIcon iconClass="email" />
          <span
            onClick={() => setIsSnapshotOpened(emailMessageId)}
            className="cursor-pointer sre-text-blue inline-regular inline-regular ml-[0.5rem]"
          >
            View Email
          </span>
        </div>
      </div>
      {isSnapshotOpened && (
        <EmailMessageSnapshotModal
          emailMessageId={emailMessageId}
          snapshotId={snapshotId}
          fetchSnapshot={fetchEmailMessageSnapshot}
          subject={subject}
          onClose={closeSnapshot}
        />
      )}
    </div>
  );
};

const CampaignCardEmailList = ({
  title,
  emailMessages,
  totalRecipientsCount,
}) => (
  <div className="flex flex-col">
    {emailMessages.length > 0 && (
      <div className="flex items-center mb-4 mt-[1.5rem]">
        <h6 className="header6-bold text-[1rem]">{title}</h6>
        &nbsp;
        <span className="header6-regular light-60">
          {emailMessages.length} of {totalRecipientsCount}
        </span>
      </div>
    )}
    <div className="flex flex-col bg-white border-radius-[1rem]">
      {emailMessages.map((em) => (
        <EmailListItem
          key={em.id}
          snapshotId={em.snapshotId}
          emailMessageId={em.id}
          subject={em.subject}
          fullName={em.recipient.fullName}
          roleName={em.recipient.role.name}
          attachmentsCount={em.attachmentsCount}
        />
      ))}
    </div>
  </div>
);

export default CampaignCardEmailList;
