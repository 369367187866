import React, { useCallback, useMemo, useState } from 'react';
import { AnimationLoader } from 'stories/AnimationLoader/AnimationLoader';
import { Modal } from 'stories/Modals/Modal/Modal';
import { ModalHeaderWithSubtitle } from 'stories/Modals/ModalHeader/ModalHeaderWithSubtitle/ModalHeaderWithSubtitle';
import { TRouteParams, useQueryParams } from '@/shared/lib/hooks/useNavigation';
import {
  IGetTransactionsRequest,
  useCreateTransactionSplitMutation,
  useGetTransactionsQuery,
  useLinkTransactionToCategoriesMutation,
  useLinkTransactionToLineItemMutation,
} from 'bundles/REconcile/components/development/api/transactions';
import GeneralLedgersFilterBar from 'bundles/Shared/components/Filters/GeneralLedgersFilterBar';
import {
  PageParamsPagination,
  PageParamsProvider,
  PageParamsSearch,
} from 'bundles/Shared/components/pageParams';
import { usePageParamsTableSorting } from '@/shared/lib/hooks/usePageParamsTableSorting';
import Table from 'bundles/Shared/components/Table/Table';
import {
  TFilterModel,
  useColumns,
} from 'bundles/REconcile/components/development/requisitions/modals/createTransactionsFromSource/useColumns';
import { DialogProps, useModal } from '@/shared/lib/hooks/useModal';
import MonthsRangePicker from 'bundles/REport/components/financials/filter/monthRangePicker/MonthsRangePicker';
import VerticalSeparator from 'bundles/Shared/components/VerticalSeparator/VerticalSeparator';
import {
  mapDateRangeToPageParams,
  mapFilterModelToPageParams,
  mapPageParamsToDateRange,
} from 'bundles/REconcile/components/development/requisitions/modals/createTransactionsFromSource/utils';
import { mapListToIds } from '@/shared/lib/listHelpers';
import {
  IDevelopmentLegalEntityRequisitionTransaction,
  ILineItem,
} from 'bundles/REconcile/types/ILineItem';
import LineItemsLinkTxModal from 'bundles/REconcile/components/development/requisitions/modals/LineItemsLinkTxModal';
import LineItemsSplitModal from 'bundles/REconcile/components/development/requisitions/modals/LineItemsSplitModal';
import {
  ILineItemResponseMeta,
  reconcileLineItemApi,
} from 'bundles/REconcile/components/development/api/lineItems';
import { useAppDispatch } from '@/shared/lib/hooks/redux';
import TransactionLinkToLineItemModal from 'bundles/REconcile/components/development/requisitions/modals/TransactionLinkToLineItemModal';
import { fetchDevelopmentBudgetVariance } from 'bundles/REconcile/reducers/developmentBudgetVarianceSlice';
import SelectedRowsPanel from 'bundles/REconcile/components/development/requisitions/modals/createTransactionsFromSource/SelectedRowsPanel';
import { getTypeForTransactionByLineItem } from 'bundles/REconcile/components/development/requisitions/modals/createTransactionsFromSource/bisUtils';
import { generateRequisitionDates } from 'bundles/DrawPackage/utils';
import { IRouteParamsLineItems } from 'bundles/REconcile/components/development/lineItems/LineItemsTable';

import { formatToDateStringForRequest } from '@/shared/lib/converters';

type Props = DialogProps & {
  bulkAvailable: boolean;
  headerTitle: string;
  legalEntityCode: string;
  meta: ILineItemResponseMeta;
  requisitionId: string;
  lineItem?: ILineItem;
  createFromSource?: boolean;
  params: IRouteParamsLineItems;
};
type TPageParams = Omit<IGetTransactionsRequest, 'category'>;

const TYPES = {
  sovc: 'ScheduleOfValueCode',
  jcc: 'JobCostCode',
};

function TransactionLinkTableModal({
  legalEntityCode,
  requisitionId,
  onClose,
  lineItem,
  headerTitle,
  bulkAvailable,
  meta,
  createFromSource,
  params,
}: Props) {
  const { categoryId, categoryType } =
    useQueryParams<
      TRouteParams['RECONCILE_DEVELOPMENT_LEGAL_ENTITY_DEVELOPMENT_LINE_ITEMS']
    >();
  const [selectedRows, setSelectedRows] = useState<
    IDevelopmentLegalEntityRequisitionTransaction[]
  >([]);
  const [pageParams, setPageParams] = useState<TPageParams>({
    per_page: 10,
    page: 1,
    date_range: {
      from: meta.startDate,
      to: meta.endDate,
    },
    ...(!createFromSource
      ? {
          transaction_type: getTypeForTransactionByLineItem(
            lineItem?.amount ?? 0,
            createFromSource,
          ),
        }
      : {}),
    ...(lineItem
      ? {
          amount: {
            from: lineItem.amount,
            to: Number.MAX_VALUE,
          },
          sort: {
            field: 'transaction_value',
            order: 'asc',
          },
        }
      : {}),
  });
  const { settings, setSettings } = usePageParamsTableSorting(
    pageParams,
    setPageParams,
  );
  const { data, isLoading } = useGetTransactionsQuery({
    legalEntityCode,
    requisitionId,
    category: {
      id: Number(categoryId),
      type: categoryType,
    },
    ...pageParams,
  });

  const dispatch = useAppDispatch();

  const [createTransactionSplit] = useCreateTransactionSplitMutation();
  const [linkToLineItem] = useLinkTransactionToLineItemMutation();
  const [linkToCategories] = useLinkTransactionToCategoriesMutation();
  const { openModal, resetAllModals } = useModal();

  const refresh = async () => {
    await dispatch(reconcileLineItemApi.util.invalidateTags(['LineItem']));
    await dispatch(fetchDevelopmentBudgetVariance(legalEntityCode));
  };

  const handleTransactionSplit = async (
    row: IDevelopmentLegalEntityRequisitionTransaction,
  ) => {
    const res = await openModal(LineItemsSplitModal, {
      ...row,
      requisitionNumber: data!.meta.requisitionNumber,
      header: 'Split Transaction & Create Development Line Items',
      submitText: 'Create Development Line Items',
      params,
    });

    if (!res) return;

    const resCreate = await createTransactionSplit({
      legalEntityCode,
      requisitionId,
      id: row.id,
      body: {
        existing_line_item_ids: [],
        new_line_items: res.lineItems.map((li) => ({
          amount: li.amount,
          description: li.description || li.remark,
          vendor_name: li.vendor,
          transaction_date: formatToDateStringForRequest(li.date),
          category_id: li.category_id,
          category_type: categoryType,
        })),
      },
    });
    await refresh();
    if (resCreate?.error?.status !== 422) {
      onClose();
    }
    return false;
  };
  const bulkLinkToCategory = async () => {
    // TODO: fetch data for confirmation modal endpoint to be implemented once BE is done

    await linkToCategories({
      legalEntityCode,
      requisitionId,
      body: {
        transactions_ids: selectedRows.map((t) => t.id),
        category: { id: categoryId, type: categoryType },
      },
    });
    setSelectedRows([]);
    await refresh();
  };
  const handleTransactionLinkToCategory = async (
    row: IDevelopmentLegalEntityRequisitionTransaction,
  ) => {
    // TODO: fetch data for confirmation modal endpoint to be implemented once BE is done

    await linkToCategories({
      legalEntityCode,
      requisitionId,
      body: {
        transactions_ids: [row.id],
        category: { id: categoryId, type: categoryType },
      },
    });
  };

  const linkLineItemWithSplit = async (
    row: IDevelopmentLegalEntityRequisitionTransaction,
  ) => {
    const res = await openModal(LineItemsLinkTxModal, {
      ...row,
      requisitionNumber: data!.meta.requisitionNumber,
      existingLineItems: [lineItem!],
      header: 'Split & Link Transaction',
      submitText: 'Split & Link Transaction',
      params,
      invoice: lineItem?.invoice,
    });

    if (!res) return;

    const resCreate = await createTransactionSplit({
      legalEntityCode,
      requisitionId,
      id: row.id,
      body: {
        existing_line_item_ids: res.allExistingLineItems.map(
          (item: ILineItem) => item.id,
        ),
        new_line_items: res.lineItems.map((li) => ({
          amount: li.amount,
          description: li.remark,
          vendor_name: li.vendor,
          transaction_date: formatToDateStringForRequest(li.date),
          category_id: Number(categoryId),
          category_type: categoryType,
        })),
      },
    });
    await refresh();
    if (resCreate.error?.status !== 422) {
      resetAllModals();
    }
    return false;
  };

  const linkLineItem = async (
    row: IDevelopmentLegalEntityRequisitionTransaction,
  ) => {
    const res = await openModal(TransactionLinkToLineItemModal, {
      ...row,
      linkedLineItems: [
        { ...lineItem, category: { name: data.meta.categoryName } },
      ],
    });

    if (!res) return;

    await linkToLineItem({
      legalEntityCode,
      requisitionId,
      id: row.id,
      body: {
        development_line_item_id: lineItem!.id,
      },
    });

    onClose();
  };

  const handleTransactionLinkToLineItem = async (
    row: IDevelopmentLegalEntityRequisitionTransaction,
  ) => {
    if (Number(row.value) > Number(lineItem!.amount)) {
      await linkLineItemWithSplit(row);
    }
    if (Number(row.value) === Number(lineItem!.amount)) {
      await linkLineItem(row);
    }
  };

  const handleLinkTransactionClick = async (
    row: IDevelopmentLegalEntityRequisitionTransaction,
    type: 'link' | 'split',
  ) => {
    setSelectedRows([]);
    if (!lineItem) {
      if (type === 'split') {
        await handleTransactionSplit(row);
      } else {
        await handleTransactionLinkToCategory(row);
      }
    } else {
      await handleTransactionLinkToLineItem(row);
    }
    await refresh();
  };
  const categoryText = `${
    categoryType === 'JobCostCode' ? 'JCC' : 'SOVC'
  }: ${data?.meta.categoryName}`;
  const getModalSubTitle = () =>
    `Requisition (${generateRequisitionDates(
      data?.meta.requisition.startDate,
      data?.meta.requisition.endDate,
    )}) • ${categoryText}`;

  const columns = useColumns({
    vendors: data?.meta.vendors ?? [],
    lineItem,
    handleLinkTransactionClick,
    categoryText,
    createFromSource,
  });
  const selectedGeneralLedgers = useMemo(() => {
    if (!data) {
      return [];
    }
    if (!pageParams.general_ledger_ids) {
      return data.meta.generalLedgers;
    }
    return pageParams.general_ledger_ids.map(
      (id) => data.meta.generalLedgers.find((gl) => gl.id === id)!,
    );
  }, [data?.meta.generalLedgers, pageParams.general_ledger_ids]);

  const handleFilterModelChange = useCallback((filterModel: TFilterModel) => {
    setPageParams((prevState) => ({
      ...prevState,
      page: 1,
      ...mapFilterModelToPageParams(filterModel),
    }));
  }, []);

  return (
    <Modal
      toggle={onClose}
      size="huge"
      header={
        data && (
          <ModalHeaderWithSubtitle
            title={headerTitle}
            subtitle={getModalSubTitle()}
            order="subtitle-title"
          />
        )
      }
      classes={{
        body: 'bg-light-10',
      }}
    >
      <PageParamsProvider pageParams={pageParams} setPageParams={setPageParams}>
        <GeneralLedgersFilterBar
          generalLedgers={data?.meta.generalLedgers ?? []}
          selectedGeneralLedgers={selectedGeneralLedgers}
          selectedChange={(gls) =>
            setPageParams((prevState) => ({
              ...prevState,
              general_ledger_ids: mapListToIds(gls),
            }))
          }
          getLabel={(gl) => `${gl.number} - ${gl.title}`}
          selectionMode="exclude"
        />

        <h6 className="header6-regular mt-m">Transactions</h6>
        <div className="flex justify-between mb-l mt-m">
          <div>
            <PageParamsPagination
              loading={isLoading}
              totalSize={data?.meta.totalSize ?? 0}
            />
          </div>

          <div className="flex gap-2">
            <MonthsRangePicker
              dateRange={mapPageParamsToDateRange(pageParams)}
              onChange={(dateRange) =>
                setPageParams((prevState) => ({
                  ...prevState,
                  ...mapDateRangeToPageParams(dateRange),
                }))
              }
            />
            <VerticalSeparator />
            <PageParamsSearch />
          </div>
        </div>
        {isLoading && <AnimationLoader />}
        {!isLoading && (
          <Table
            setSettings={setSettings}
            settings={settings}
            onFilterModelChange={handleFilterModelChange}
            columns={columns}
            items={data!.items}
            {...(bulkAvailable ? { selectedRows, setSelectedRows } : {})}
          />
        )}
      </PageParamsProvider>
      {selectedRows.length > 0 && (
        <SelectedRowsPanel
          bulkLinkToCategory={bulkLinkToCategory}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
        />
      )}
    </Modal>
  );
}

export default TransactionLinkTableModal;
