import React, { useRef } from 'react';
import MainMenuItem from 'bundles/Shared/components/LeftSidebar/items/MainMenuItem';
import MirrorModeCard from 'bundles/Shared/components/LeftSidebar/cards/MirrorModeCard';
import MirrorModeModal from 'bundles/Shared/components/Nav/Modals/MirrorModeModal';
import { useGenericData } from 'bundles/Shared/GenericContext';
import { useModal } from '@/shared/lib/hooks/useModal';
import { useHoverSaveArea } from 'bundles/Shared/components/LeftSidebar/hooks/useHoverSaveArea';
import { usePopoverTriggered } from 'bundles/Shared/components/LeftSidebar/hooks/usePopoverTriggered';
import MenuItemPopover from 'bundles/Shared/components/LeftSidebar/items/MenuItemPopover';
import { Icon } from 'stories/Icon/Icon';
import { cn } from '@/shared/lib/css/cn';
import { PopoverRef } from 'stories/Popover/Popover';
import { useAppDispatch } from '@/shared/lib/hooks/redux';
import { collapseSidebar } from 'bundles/Shared/components/LeftSidebar/reducers/leftSidebarSlice';

function MirrorModeMenuItem() {
  const dispatch = useAppDispatch();
  const { genericData } = useGenericData();
  const { openModal } = useModal();
  const { impersonatableUsers } = genericData.mainNavigation;
  const menuItemRef = useRef<HTMLAnchorElement>(null);
  const popoverRef = useRef<PopoverRef>();
  const { classes: hoverClasses, onMouseMove } = useHoverSaveArea();
  const { triggered, ...handlers } = usePopoverTriggered();

  return (
    <>
      <MainMenuItem
        ref={menuItemRef}
        onMouseMove={onMouseMove}
        label="Mirror Mode"
        iconName="eye"
        rightIcon={<Icon iconName="arrowRight" />}
        className={cn(
          hoverClasses,
          triggered && 'left-sidebar__main-menu-item_hovered',
        )}
        onClick={() =>
          openModal(MirrorModeModal, {
            users: impersonatableUsers,
          })
        }
      />
      <MenuItemPopover
        {...handlers}
        ref={popoverRef}
        reference={menuItemRef}
        template={
          <MirrorModeCard
            onAction={() => {
              popoverRef.current?.hide();
              dispatch(collapseSidebar());
            }}
            users={impersonatableUsers}
          />
        }
        placement="right"
        maxWidth="none"
        style={{
          width: '21.25rem',
        }}
      />
    </>
  );
}

export default MirrorModeMenuItem;
