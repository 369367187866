import React from 'react';
import { useModal } from '@/shared/lib/hooks/useModal';
import { IconButton } from 'stories/IconButton/IconButton';
import { ConfirmationModal } from 'stories/Modals/ConfirmationModal/ConfirmationModal';
import { IColumn } from 'bundles/Shared/components/Table/types';

export interface CrudActionsColumnProps<T = any> {
  onEdit: (row: T) => void;
  onRemove: (row: T) => void;
  editDisabled?: (row: T) => boolean;
  removeDisabled?: (row: T) => boolean;
  row: T;
  confirmProps?: (
    row: T,
  ) => Pick<
    React.ComponentProps<typeof ConfirmationModal>,
    'title' | 'subtitle'
  >;
}

export const CrudActionsColumn = <T,>({
  onEdit,
  onRemove,
  removeDisabled,
  editDisabled,
  row,
  confirmProps,
}: CrudActionsColumnProps<T>) => {
  const { confirm } = useModal();

  const handleRemove = async (row: T) => {
    const res = await confirm({
      title: confirmProps?.(row).title ?? 'Warning',
      subtitle: confirmProps?.(row).subtitle ?? 'Are you sure?',
    });

    if (res) {
      onRemove(row);
    }
  };
  return (
    <div className="flex gap-s">
      <IconButton
        iconName="edit"
        data-testid="edit"
        onClick={() => onEdit(row)}
        disabled={editDisabled?.(row)}
      />
      <IconButton
        iconName="trash"
        data-testid="remove"
        onClick={() => handleRemove(row)}
        disabled={removeDisabled?.(row)}
      />
    </div>
  );
};

export const getCrudActionsColumn = <T,>({
  ...props
}: Omit<CrudActionsColumnProps<T>, 'row'>): IColumn<T> => ({
  text: 'Actions',
  dataField: 'actions',
  headerStyle: {
    width: 80,
  },
  formatter: ({ row: item }) => <CrudActionsColumn row={item} {...props} />,
});
