import { EditorProps, Monaco } from '@monaco-editor/react';
import { safelyParseJSON } from 'lib/typeHelpers/safelyParseJSON';
import { useEffect, useMemo, useRef, useState } from 'react';
import { UnknownRecord } from 'type-fest';

export const useCodeEditorRef = () => {
  return useRef<Monaco | null>(null);
};

export const useJsonEditorJsonValue = <T extends UnknownRecord>(json: T) => {
  const stringifiedJson = useMemo(
    () => (json ? JSON.stringify(json, null, 2) : null),
    [json],
  );
  const [jsonValue, setJsonValue] = useState<string | null | undefined>(
    stringifiedJson,
  );
  const parsedValue = useMemo<T | null>(() => {
    return safelyParseJSON(jsonValue);
  }, [jsonValue]);
  const isDirty = useMemo(
    () => jsonValue !== stringifiedJson,
    [jsonValue, stringifiedJson],
  );

  useEffect(() => {
    setJsonValue(stringifiedJson);
  }, [stringifiedJson]);

  const editorProps = useMemo<Pick<EditorProps, 'value' | 'onChange'>>(
    () => ({
      value: jsonValue,
      onChange: setJsonValue,
    }),
    [jsonValue, setJsonValue],
  );

  return {
    editorProps,
    isDirty,
    jsonValue,
    setJsonValue,
    parsedValue,
  };
};
