import {
  transformPeriodShiftDtoToForm,
  transformPeriodShiftToDefaultDateRange,
} from 'bundles/Shared/widgets/dashboard/widgets/common/lib/config';
import * as yup from 'yup';
import {
  DEFAULT_DATE_RANGES,
  GRANULARITIES,
  PERIOD_SHIFT_SCHEMA,
} from 'bundles/Shared/widgets/dashboard/widgets/common/config';
import { transformEmptyObjectToUndefined } from '@/shared/lib/yup';
import { XYChartWidgetConfig } from 'bundles/Shared/widgets/dashboard/widgets/xyChartSingleKpi/model';

export const XY_CHART_CONFIG_SCHEMA = yup.object().shape({
  granularity: yup.string().oneOf(GRANULARITIES).required(),
  periodShiftTo: PERIOD_SHIFT_SCHEMA.nullable()
    .optional()
    .transform(transformEmptyObjectToUndefined)
    .default(undefined),
  periodShiftFrom: PERIOD_SHIFT_SCHEMA.nullable()
    .optional()
    .transform(transformEmptyObjectToUndefined)
    .default(undefined),
  defaultDateRange: yup
    .string()
    .oneOf(Object.values(DEFAULT_DATE_RANGES))
    .required(),
});
export type XYChartWidgetConfigForm = yup.InferType<
  typeof XY_CHART_CONFIG_SCHEMA
>;
export const transformXYChartWidgetConfigToForm = (
  config: XYChartWidgetConfig,
): XYChartWidgetConfigForm => {
  const periodShiftFrom = transformPeriodShiftDtoToForm(
    config.default_options.date_from?.period_shift,
  );
  const periodShiftTo = transformPeriodShiftDtoToForm(
    config.default_options.date_to?.period_shift,
  );

  return {
    periodShiftFrom,
    periodShiftTo,
    defaultDateRange: transformPeriodShiftToDefaultDateRange(
      config.default_options.date_from?.period_shift,
      config.default_options.date_to?.period_shift,
    ),
    granularity: config.default_options.granularity,
  };
};
