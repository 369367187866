import { formatToDateStringForRequest } from '@/shared/lib/converters';
import { DateRangeBase } from '@/shared/lib/date';
import dayjs from 'dayjs';
import { produce } from 'immer';
import { orderBy } from 'lodash-es';

export const orderPeriodsByDateFrom = <T extends DateRangeBase>(
  periods: T[],
  order: 'asc' | 'desc' = 'asc',
) => {
  return orderBy(periods, [(item) => item.dateFrom ?? ''], [order]);
};

export const fillPeriodsDateTo = <T extends DateRangeBase>(periods: T[]) => {
  return produce(periods, (draft) => {
    for (let i = draft.length - 1; i >= 1; i--) {
      const period = draft[i];
      const nextPeriod = draft[i - 1];

      if (!nextPeriod?.dateFrom) {
        continue;
      }

      if (
        period.dateFrom &&
        dayjs(nextPeriod.dateFrom).isSame(period.dateFrom)
      ) {
        period.dateTo = null;
        continue;
      }

      period.dateTo = formatToDateStringForRequest(
        dayjs(nextPeriod.dateFrom).subtract(1, 'day'),
      );
    }
  });
};

export const insertPeriodByDateFrom = <T extends DateRangeBase, U = string>(
  periods: (T & { id: U })[],
  insertedPeriod: T,
) => {
  const updatedPeriods = [...periods, { ...insertedPeriod, dateTo: null }];
  const sortedPeriods = orderPeriodsByDateFrom(updatedPeriods, 'desc');

  return fillPeriodsDateTo(sortedPeriods);
};

export const updatePeriodByDateFrom = <T extends DateRangeBase, U = string>(
  periods: (T & { id: U })[],
  updatedPeriod: Pick<T, 'dateFrom'> & { id: U },
) => {
  const orderedPeriods = orderPeriodsByDateFrom(periods, 'desc');

  const replacedPeriods = produce(orderedPeriods, (draft) => {
    const periodIndex = draft.findIndex((p) => p.id === updatedPeriod.id);

    if (periodIndex === -1) {
      return;
    }

    draft[periodIndex].dateFrom = updatedPeriod.dateFrom;

    if (
      (draft[periodIndex].dateTo &&
        updatedPeriod.dateFrom &&
        dayjs(draft[periodIndex].dateTo).isBefore(updatedPeriod.dateFrom)) ??
      periodIndex === 0
    ) {
      draft[periodIndex].dateTo = null;
    }
  });

  const replacedOrderedPeriods = orderPeriodsByDateFrom(
    replacedPeriods,
    'desc',
  );
  const filledPeriods = fillPeriodsDateTo(replacedOrderedPeriods);

  return filledPeriods;
};
