import React from 'react';
import Select, {
  ClearIndicatorProps,
  components,
  DropdownIndicatorProps,
  GroupBase,
  MultiValueRemoveProps,
  Props as SelectProps,
} from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { FieldPath } from 'react-hook-form/dist/types/path/eager';
import { Control, FieldValues, useController } from 'react-hook-form';
import { ListOption } from 'stories/Checkbox/CheckList';
import { cn } from '@/shared/lib/css/cn';

export interface ISharedSelectProps<
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>,
> extends SelectProps<Option, IsMulti, Group> {
  value: IsMulti extends true ? Option[] : Option;
  newGen?: boolean;
  creatable?: boolean;
  disabled?: boolean;
}

const ChevronDownIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  >
    <path d="m6 9 6 6 6-6" />
  </svg>
);

const CloseIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  >
    <path d="M18 6 6 18" />
    <path d="m6 6 12 12" />
  </svg>
);

const DropdownIndicator = (props: DropdownIndicatorProps) => {
  return (
    <components.DropdownIndicator {...props}>
      <ChevronDownIcon />
    </components.DropdownIndicator>
  );
};

const ClearIndicator = (props: ClearIndicatorProps) => {
  return (
    <components.ClearIndicator {...props}>
      <CloseIcon />
    </components.ClearIndicator>
  );
};

const MultiValueRemove = (props: MultiValueRemoveProps) => {
  return (
    <components.MultiValueRemove {...props}>
      <CloseIcon />
    </components.MultiValueRemove>
  );
};

const controlStyles = {
  base: 'border-2 border-solid border-neutral-200 radius008 bg-white hover:cursor-pointer text-sm',
  focus: 'border-new-primary-060',
  nonFocus: 'border-gray-300 hover:border-gray-400',
};
const placeholderStyles = 'text-neutral-500 pl-0.5 py-0.5';
const selectInputStyles = 'pl-0.5 py-0.5';
const valueContainerStyles = 'px-2 gap-1 text-neutral-900';
const singleValueStyles = 'ml-0.5';
const multiValueStyles = 'bg-neutral-150 rounded overflow-hidden items-center';
const multiValueLabelStyles = 'pl-1.5 pr-1 py-0.5 text-xs text-neutral-900';
const multiValueRemoveStyles =
  'p-1 hover:bg-danger-030 hover:text-danger-080 text-neutral-600 [&>svg]:w-3 [&>svg]:h-3 [&>svg]:stroke-[3px]';
const indicatorsContainerStyles = 'py-2 pr-2 gap-1';
const clearIndicatorStyles = 'text-neutral-600 p-0.5';
const indicatorSeparatorStyles = 'bg-neutral-300';
const dropdownIndicatorStyles = 'p-0.5 text-neutral-600';
const menuStyles = 'mt-0.5 border bg-white radius008 shadow-z-040 text-xs';
const groupHeadingStyles =
  'ml-3 mt-2 mb-1 text-neutral-600 font-semibold text-xs';
const optionStyles = {
  base: 'hover:cursor-pointer hover:bg-[#DEEBFF] px-3 py-2',
  focus: 'bg-neutral-100',
  selected: '!bg-info-060 text-white',
};
const noOptionsMessageStyles =
  'text-neutral-600 p-2 bg-gray-50 border-gray-200 rounded-sm';

// TODO: FE-3620 refactor
export const SharedSelect = <
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>,
>({
  disabled,
  newGen,
  creatable,
  ...props
}: ISharedSelectProps<Option, IsMulti, Group>) => {
  const SelectComponent = creatable ? CreatableSelect : Select;

  return (
    // TODO delete form-item classes
    <div className="form-item form-item__select w-full">
      <SelectComponent
        classNamePrefix={newGen ? 'new-gen-react-select' : 'react-select'}
        isDisabled={disabled}
        hideSelectedOptions={false}
        menuPlacement="bottom"
        menuPosition="fixed"
        unstyled
        styles={{
          input: (base) => ({
            ...base,
            'input:focus': {
              boxShadow: 'none',
            },
          }),
          // On mobile, the label will truncate automatically, so we want to
          // override that behaviour.
          multiValueLabel: (base) => ({
            ...base,
            whiteSpace: 'normal',
            overflow: 'visible',
          }),
          control: (base) => ({
            ...base,
            transition: 'none',
          }),
        }}
        components={{ DropdownIndicator, ClearIndicator, MultiValueRemove }}
        classNames={{
          control: ({ isFocused }) =>
            cn(
              isFocused ? controlStyles.focus : controlStyles.nonFocus,
              controlStyles.base,
            ),
          placeholder: () => placeholderStyles,
          input: () => selectInputStyles,
          valueContainer: () => valueContainerStyles,
          singleValue: () => singleValueStyles,
          multiValue: () => multiValueStyles,
          multiValueLabel: () => multiValueLabelStyles,
          multiValueRemove: () => multiValueRemoveStyles,
          indicatorsContainer: () => indicatorsContainerStyles,
          clearIndicator: () => clearIndicatorStyles,
          indicatorSeparator: () => indicatorSeparatorStyles,
          dropdownIndicator: () => dropdownIndicatorStyles,
          menu: () => menuStyles,
          groupHeading: () => groupHeadingStyles,
          option: ({ isFocused, isSelected }) =>
            cn(
              isFocused && optionStyles.focus,
              isSelected && optionStyles.selected,
              optionStyles.base,
            ),
          noOptionsMessage: () => noOptionsMessageStyles,
        }}
        {...props}
      />
    </div>
  );
};

export const SharedSelectController = <
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>,
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  name,
  onChange,
  ...props
}: {
  control: Control<TFieldValues>;
  name: TName;
} & Omit<ISharedSelectProps<Option, IsMulti, Group>, 'value'>) => {
  const { field } = useController({ name, control });
  return (
    <SharedSelect
      value={
        props.options?.find((o) => (o as ListOption).value === field.value) ??
        null
      }
      onChange={(o, actionMeta) => {
        if (onChange) {
          onChange(o, actionMeta);
          return;
        }
        field.onChange(
          Array.isArray(o) ? Array.from(o.values()) : (o as ListOption).value,
        );
      }}
      {...props}
    />
  );
};
