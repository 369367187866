import React, { useCallback } from 'react';
import {
  FORMULA_LANGUAGE_ID,
  FORMULA_LANGUAGE_THEME,
  setupMonaco,
} from '@/bundles/Shared/entities/formula/config';
import {
  FormulaInvalidReference,
  useFormulaLinkHoverProviderEffect,
} from 'bundles/Shared/entities/formula';
import styles from 'bundles/Shared/entities/formula/ui/ExpressionEditor.module.scss';
import { cn } from '@/shared/lib/css/cn';
import { CodeEditor } from '@/shared/ui/CodeEditor/component';
import { useCodeEditorRef } from '@/shared/ui/CodeEditor/lib';
import { useFormulaSuggestionProviderEffect } from 'bundles/Shared/entities/formula/lib/suggestionProvider';

interface Props
  extends Omit<React.ComponentProps<typeof CodeEditor>, 'language' | 'theme'> {
  errors?: FormulaInvalidReference[];
}

const DEFAULT_OPTIONS = {
  wordBasedSuggestions: false,
  wordWrap: true,
  fontSize: 14,
  fontWeight: 500,
  fontFamily: 'var(--font-sans)',
  lineNumbersMinChars: 2,
  minimap: {
    enabled: false,
  },
};

export function ExpressionEditor({
  errors,
  onChange,
  options,
  ...props
}: Props) {
  const codeEditorRef = useCodeEditorRef();

  useFormulaLinkHoverProviderEffect(codeEditorRef, FORMULA_LANGUAGE_ID);
  useFormulaSuggestionProviderEffect(codeEditorRef);

  const validateValue = useCallback(
    (editor, monaco) => {
      const model = editor.getModel();

      const textToValidate: string = model.getValue();

      const markers = errors
        ?.map((error) => {
          return [...textToValidate.matchAll(RegExp(error.reference, 'g'))].map(
            (match) => {
              return {
                startLineNumber: 1,
                severity: codeEditorRef.current.MarkerSeverity.Error,
                startColumn: match.index + 1,
                endColumn: match.index + match[0].length + 1,
                message: error,
                endLineNumber: 1,
              };
            },
          );
        })
        .flat();

      monaco.editor.setModelMarkers(model, FORMULA_LANGUAGE_ID, markers);
    },
    [errors],
  );

  return (
    <CodeEditor
      className={cn('rounded-lg', styles.formulaEditor)}
      theme={FORMULA_LANGUAGE_THEME}
      language={FORMULA_LANGUAGE_ID}
      beforeMount={(monaco) => {
        codeEditorRef.current = monaco;
        setupMonaco(monaco);
      }}
      onChange={(value, e) => {
        onChange?.(value, e);
      }}
      onMount={(editor, monaco) => {
        validateValue(editor, monaco);
        editor.onDidChangeModelContent(() => {
          validateValue(editor, monaco);
        });
      }}
      options={{
        ...DEFAULT_OPTIONS,
        ...options,
      }}
      {...props}
    />
  );
}
