import { KpiTableWidgetConfigDto } from '@/shared/api/dashboardsSettingsGeneratedApi';
import { DEFAULT_TABLE_VIZ_CONFIG } from 'bundles/Shared/widgets/dashboard/widgets/common/config';
import { ReadonlyDeep } from 'type-fest';

export const DEFAULT_KPI_TABLE_CONFIG = {
  ...DEFAULT_TABLE_VIZ_CONFIG,
  columns: [],
  default_options: {
    period_type: 'month',
    date: {
      period_shift: {
        months: 0,
      },
    },
    grouping_type: 'assets',
  },
  period_types: ['month'],
} satisfies ReadonlyDeep<KpiTableWidgetConfigDto>;
