import { useReportBuilderTemplateContext } from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/reportBuilderTemplateContext';
import { useGetSelectedObjectsSearchQuery } from '@/entities/report/reportBuilder/lib/useGetApiReportBuilderTemplatePreviewPdfQuery';
import {
  useReportingEntityKindContext,
  type ReportBuilderTemplateKindContextType,
} from '@/entities/reporting/context/entityKind';
import { DEFAULT_STRINGIFY_OPTIONS } from '@/lib/stringify';
import { generateUrl, ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import { useNavigate } from '@reach/router';
import { useCallback } from 'react';

const getNavigateReportBuilderTemplateWidgetPreviewPage = (
  templateKind: NonNullable<ReportBuilderTemplateKindContextType>,
) => {
  switch (templateKind) {
    case 'eagle_eye': {
      return ROUTES_ROOT.reportBuilderTemplates.eagleEye.reportBuilderTemplate
        .widgets.widget.fullPath;
    }
    case 'object_level':
    default: {
      return ROUTES_ROOT.reportBuilderTemplates.reportBuilderTemplate.widgets
        .widget.fullPath;
    }
  }
};

export const useNavigateReportBuilderTemplateWidgetPreviewPage = () => {
  const templateCtx = useReportBuilderTemplateContext()!;
  const templateKind = useReportingEntityKindContext();
  const navigate = useNavigate();

  return useCallback(
    (widgetId: string) => {
      navigate(
        generateUrl(
          getNavigateReportBuilderTemplateWidgetPreviewPage(templateKind),
          {
            pathParams: {
              templateId: templateCtx.templateId,
              widgetId,
            },
          },
        ),
      );
    },
    [templateCtx, templateKind],
  );
};

const getNavigateReportBuilderTemplateViewPagePath = (
  templateKind: NonNullable<ReportBuilderTemplateKindContextType>,
) => {
  switch (templateKind) {
    case 'eagle_eye': {
      return ROUTES_ROOT.reportBuilderTemplates.eagleEye.reportBuilderTemplate
        .view.fullPath;
    }
    case 'object_level':
    default: {
      return ROUTES_ROOT.reportBuilderTemplates.reportBuilderTemplate.view
        .fullPath;
    }
  }
};

export const useNavigateReportBuilderTemplateViewPage = () => {
  const templateCtx = useReportBuilderTemplateContext()!;
  const templateKind = useReportingEntityKindContext();
  const navigate = useNavigate();
  const payloadParams = useGetSelectedObjectsSearchQuery();

  return useCallback(() => {
    navigate(
      generateUrl(
        getNavigateReportBuilderTemplateViewPagePath(templateKind),
        {
          pathParams: {
            templateId: templateCtx.templateId,
          },
          queryParams: {
            assetIds: payloadParams.assetIds,
            segmentIds: payloadParams.segmentIds,
          },
        },
        DEFAULT_STRINGIFY_OPTIONS,
      ),
    );
  }, [templateCtx, payloadParams, templateKind]);
};
