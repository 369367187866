import * as React from 'react';
import { useRef } from 'react';
import { IconButton } from 'stories/IconButton/IconButton';
import { Popover } from 'stories/Popover/Popover';
import { cn } from '@/shared/lib/css/cn';
import { PopoverRef } from 'stories/Popover/Popover';

interface Props {
  actions: {
    label: string;
    handler: () => void;
  }[];
}

function ActionsDropdown({ actions }: Props) {
  const popoverRef = useRef<PopoverRef>(null);

  const onItemClick = (handler: () => void) => {
    popoverRef.current?.hide();
    handler();
  };

  return (
    <Popover
      trigger="click"
      className="border bg-light p-0"
      hiddenArrow
      placement="bottom-end"
      offset={[0, 1]}
      template={actions.map((a, i) => (
        <div
          key={a.label}
          className={cn('inline-regular cursor-pointer px-4 py-2', {
            'border-b border-light-10': i !== actions.length - 1,
          })}
          onClick={() => onItemClick(a.handler)}
        >
          {a.label}
        </div>
      ))}
    >
      {({ triggered }) => (
        <IconButton
          className={triggered ? 'bg-light-40' : 'bg-white'}
          iconName="more"
        />
      )}
    </Popover>
  );
}

export default ActionsDropdown;
