import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  IChangeEvent,
  IESignCoordinate,
  TChangeOrderStatus,
} from 'bundles/Construction/types';
import { TRootState } from '@/app/stores';
import {
  approveReconcileChangeEvent,
  confirmValueReconcileChangeEvent,
  createReconcileChangeEvent,
  deletedDocumentsReconcileChangeEvent,
  fetchHistoryItemReconcileChangeEvent,
  fetchReconcileChangeEvent,
  promoteReconcileChangeEvent,
  rejectReconcileChangeEvent,
  reviseReconcileChangeEvent,
  selectFinalDocumentsReconcileChangeEvent,
  setSubsReconcileChangeEvent,
  startApprovalReconcileChangeEvent,
  defineEsignPlacementChangeEvent,
  updateCostBreakDownReconcileChangeEvent,
  updateReconcileChangeEvent,
  updateStatusReconcileChangeEvent,
  uploadDocumentsReconcileChangeEvent,
  verifyReconcileChangeEvent,
  fetchChangeOrderEventMobile,
  revertReconcileChangeOrderEvent,
} from '@/bundles/Construction/actions/changeEvent';

export interface IParamsCE {
  basicParams: {
    legalEntityCode: string;
    id: string;
  };
  create: {
    legalEntityCode: string;
    data: IChangeEvent & {
      reallocation_jcc_id: number | null;
    };
  };
  update: {
    legalEntityCode: string;
    id: string;
    data: IChangeEvent & {
      reallocation_jcc_id: number | null;
    };
  };
  fetchHistoryItem: {
    legalEntityCode: string;
    id: string;
    historyEventId: number;
  };
  confirmValue: {
    legalEntityCode: string;
    id: string;
  };
  viewDocument: {
    legalEntityCode: string;
    id: string;
    documentId: string;
  };
  uploadDocuments: {
    legalEntityCode: string;
    id: string;
    data: File[];
  };
  selectedDocuments: {
    legalEntityCode: string;
    id: string;
    data: {
      document_id: string;
    };
  };
  deletedDocuments: {
    legalEntityCode: string;
    id: string;
    documentId: string;
  };
  verify: {
    legalEntityCode: string;
    id: string;
  };
  reject: {
    legalEntityCode: string;
    id: string;
    data: {
      event_comment: string;
    };
  };
  esignPlacement: {
    legalEntityCode: string;
    id: string;
    data: IESignCoordinate;
  };
  subsDate: {
    legalEntityCode: string;
    id: string;
    data: {
      substantial_completion_date: string | null;
    };
  };
}

export interface IChangeEventState {
  item: IChangeEvent | null;
  state: 'idle' | 'loading' | 'success';
  historyItem: any;
  timingImpact: 'empty' | boolean;
}

const initialState: IChangeEventState = {
  item: null,
  state: 'idle',
  historyItem: null,
  timingImpact: 'empty',
};

const fetchChangeEvent = createAsyncThunk<
  IChangeEvent,
  IParamsCE['basicParams']
>('developmentChangeEvent/fetch', async (params) => {
  const changeEvent = await fetchReconcileChangeEvent(params);

  return changeEvent;
});

const createChangeEvent = createAsyncThunk<IChangeEvent, IParamsCE['create']>(
  'developmentChangeEvent/create',
  async (params) => {
    const changeEvent = await createReconcileChangeEvent(params);

    return changeEvent;
  },
);

const fetchChangeEventMobile = createAsyncThunk<
  IChangeEvent,
  IParamsCE['basicParams']
>('developmentChangeEvent/fetch', async (params) => {
  const changeEvent = await fetchChangeOrderEventMobile(params);

  return changeEvent;
});

const updateChangeEvent = createAsyncThunk<IChangeEvent, IParamsCE['update']>(
  'developmentChangeEvent/update',
  async (params) => {
    const changeEvent = await updateReconcileChangeEvent(params);

    return changeEvent;
  },
);

const reviseChangeEvent = createAsyncThunk<IChangeEvent, IParamsCE['update']>(
  'developmentChangeEvent/revise',
  async (params) => {
    const changeEvent = await reviseReconcileChangeEvent(params);

    return changeEvent;
  },
);

const promoteChangeEvent = createAsyncThunk<IChangeEvent, IParamsCE['update']>(
  'developmentChangeEvent/promote',
  async (params) => {
    const changeEvent = await promoteReconcileChangeEvent(params);

    return changeEvent;
  },
);

const revertChangeEvent = createAsyncThunk<IChangeEvent, IParamsCE['update']>(
  'developmentChangeEvent/revert',
  async (params) => {
    const changeEvent = await revertReconcileChangeOrderEvent(params);

    return changeEvent;
  },
);

const updateStatusChangeEvent = createAsyncThunk<
  IChangeEvent,
  {
    legalEntityCode: string;
    id: string;
    data: {
      status: TChangeOrderStatus;
      event_comment: string | null;
    };
  }
>('developmentChangeEvent/updateStatus', async (params) => {
  const changeEvent = await updateStatusReconcileChangeEvent(
    params.legalEntityCode,
    params.id,
    params.data,
  );

  return changeEvent;
});

const fetchHistoryItemChangeEvent = createAsyncThunk<
  IChangeEvent,
  IParamsCE['fetchHistoryItem']
>('developmentChangeEvent/fetchHistoryItem', async (params) => {
  const historyItem = await fetchHistoryItemReconcileChangeEvent(params);
  return historyItem;
});

const confirmValueChangeEvent = createAsyncThunk<
  IChangeEvent,
  IParamsCE['confirmValue']
>('developmentChangeEvent/confirmValue', async (params) => {
  const changeEvent = await confirmValueReconcileChangeEvent(params);
  return changeEvent;
});

const uploadDocumentsChangeEvent = createAsyncThunk<
  IChangeEvent,
  IParamsCE['uploadDocuments']
>('developmentChangeEvent/uploadDocuments', async (params) => {
  const changeEvent = await uploadDocumentsReconcileChangeEvent(params);
  return changeEvent;
});

const selectedDocumentsChangeEvent = createAsyncThunk<
  IChangeEvent,
  IParamsCE['selectedDocuments']
>('developmentChangeEvent/selectedDocuments', async (params) => {
  const changeEvent = await selectFinalDocumentsReconcileChangeEvent(params);
  return changeEvent;
});

const deleteDocumentsChangeEvent = createAsyncThunk<
  IChangeEvent,
  IParamsCE['selectedDocuments']
>('developmentChangeEvent/deletedDocuments', async (params) => {
  const changeEvent = await deletedDocumentsReconcileChangeEvent(params);
  return changeEvent;
});

const verifyChangeEvent = createAsyncThunk<IChangeEvent, IParamsCE['verify']>(
  'developmentChangeEvent/verify',
  async (params) => {
    const changeEvent = await verifyReconcileChangeEvent(params);
    return changeEvent;
  },
);

const approveChangeEvent = createAsyncThunk<
  IChangeEvent,
  IParamsCE['basicParams']
>('developmentChangeEvent/approve', async (params) => {
  const changeEvent = await approveReconcileChangeEvent(params);
  return changeEvent;
});

const rejectChangeEvent = createAsyncThunk<IChangeEvent, IParamsCE['reject']>(
  'developmentChangeEvent/approve',
  async (params) => {
    const changeEvent = await rejectReconcileChangeEvent(params);
    return changeEvent;
  },
);

const setSubsDateChangeEvent = createAsyncThunk<
  IChangeEvent,
  IParamsCE['subsDate']
>('developmentChangeEvent/setSubsDate', async (params) => {
  const changeEvent = await setSubsReconcileChangeEvent(params);
  return changeEvent;
});

const updateCostBreakDownChangeEvent = createAsyncThunk<
  IChangeEvent,
  IParamsCE['subsDate']
>('developmentChangeEvent/setCostBreakDown', async (params) => {
  const changeEvent = await updateCostBreakDownReconcileChangeEvent(params);
  return changeEvent;
});

const startApprovalChangeEvent = createAsyncThunk<
  IChangeEvent,
  IParamsCE['basicParams']
>('developmentChangeEvent/startApproval', async (params) => {
  const changeEvent = await startApprovalReconcileChangeEvent(params);
  return changeEvent;
});

const defineEsignChangeEvent = createAsyncThunk<
  IChangeEvent,
  IParamsCE['esignPlacement']
>('developmentChangeEvent/esignPlacement', async (params) => {
  const changeEvent = await defineEsignPlacementChangeEvent(params);
  return changeEvent;
});

const reconcileChangeEventSlice = createSlice({
  name: 'changeEvent',
  initialState,
  reducers: {
    setCETimingImpact: (state, action) => {
      state.timingImpact = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchChangeEvent.pending, (state) => {
        state.state = 'loading';
      })
      .addCase(fetchChangeEvent.fulfilled, (state, action) => {
        const changeEvent = action.payload;
        state.item = changeEvent;
        state.state = 'success';
      });

    builder.addCase(fetchHistoryItemChangeEvent.fulfilled, (state, action) => {
      const historyItem = action.payload;
      state.historyItem = historyItem;
    });

    builder.addCase(createChangeEvent.fulfilled, (state, action) => {
      const changeEvent = action.payload;
      state.item = changeEvent;
    });

    builder.addCase(updateChangeEvent.fulfilled, (state, action) => {
      const changeEvent = action.payload;
      state.item = changeEvent;
    });

    builder.addCase(updateStatusChangeEvent.fulfilled, (state, action) => {
      const changeEvent = action.payload;
      state.item = changeEvent;
    });

    builder.addCase(confirmValueChangeEvent.fulfilled, (state, action) => {
      const changeEvent = action.payload;
      state.item = changeEvent;
    });

    builder.addCase(selectedDocumentsChangeEvent.fulfilled, (state, action) => {
      const changeEvent = action.payload;
      state.item = changeEvent;
    });

    builder.addCase(verifyChangeEvent.fulfilled, (state, action) => {
      const changeEvent = action.payload;
      state.item = changeEvent;
    });

    builder.addCase(approveChangeEvent.fulfilled, (state, action) => {
      const changeEvent = action.payload;
      state.item = changeEvent;
    });

    builder.addCase(setSubsDateChangeEvent.fulfilled, (state, action) => {
      const changeEvent = action.payload;
      state.item = changeEvent;
    });

    builder.addCase(revertChangeEvent.fulfilled, (state, action) => {
      if ('errors' in action.payload) {
        toastr.error(action.payload.errors.join(', '));
        return;
      }
      const changeEvent = action.payload;
      state.item = changeEvent;
    });

    builder.addCase(
      updateCostBreakDownChangeEvent.fulfilled,
      (state, action) => {
        const changeEvent = action.payload;
        state.item = changeEvent;
      },
    );

    builder.addCase(reviseChangeEvent.fulfilled, (state, action) => {
      const changeEvent = action.payload;
      state.item = changeEvent;
    });

    builder.addCase(promoteChangeEvent.fulfilled, (state, action) => {
      const changeEvent = action.payload;
      state.item = changeEvent;
    });

    builder.addCase(startApprovalChangeEvent.fulfilled, (state, action) => {
      const changeEvent = action.payload;
      state.item = changeEvent;
    });

    builder.addCase(defineEsignChangeEvent.fulfilled, (state, action) => {
      const changeEvent = action.payload;
      state.item = changeEvent;
    });
  },
});

export {
  fetchChangeEvent,
  createChangeEvent,
  fetchHistoryItemChangeEvent,
  updateChangeEvent,
  updateStatusChangeEvent,
  confirmValueChangeEvent,
  uploadDocumentsChangeEvent,
  selectedDocumentsChangeEvent,
  deleteDocumentsChangeEvent,
  verifyChangeEvent,
  approveChangeEvent,
  rejectChangeEvent,
  setSubsDateChangeEvent,
  updateCostBreakDownChangeEvent,
  reviseChangeEvent,
  promoteChangeEvent,
  startApprovalChangeEvent,
  defineEsignChangeEvent,
  fetchChangeEventMobile,
  revertChangeEvent,
};

export const { setCETimingImpact } = reconcileChangeEventSlice.actions;

export const selectCETimingImpact = (state: TRootState) =>
  state.changeEvent.timingImpact;
export const selectChangeEvent = (state: TRootState) => state.changeEvent.item;
export const loadingChangeEvent = (state: TRootState) =>
  state.changeEvent.state;
export const selectHistoryItemChangeEvent = (state: TRootState) =>
  state.changeEvent.historyItem;

export default reconcileChangeEventSlice.reducer;
