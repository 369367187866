import {
  PERMISSIONS_TABLE,
  PermissionTableHeaderJSX,
} from '@/bundles/UserManagement/components/roles/constants';
import styles from '@/bundles/UserManagement/components/roles/RightBar/ActivityLog/styles.module.scss';
import { IUserRoleActivityLogProduct } from '@/bundles/UserManagement/components/roles/types';
import {
  prepareProductName,
  sortByName,
} from '@/bundles/UserManagement/components/roles/utils';
import { cn } from '@/shared/lib/css/cn';
import { IProductPermissionKeys } from 'types/User';

interface Props {
  products: IUserRoleActivityLogProduct[];
}

function PermissionTableItem({
  product,
  permissionKey,
}: {
  product: IUserRoleActivityLogProduct;
  permissionKey: IProductPermissionKeys;
}) {
  const wasPermissionActivated = product.permissions[permissionKey];

  const isPermissionRemoved =
    wasPermissionActivated &&
    product.newPermissions &&
    product.newPermissions[permissionKey] === false;

  const isPermissionAdded =
    !wasPermissionActivated &&
    product.newPermissions &&
    product.newPermissions[permissionKey];

  return (
    <div className="dark-60 inline-regular flex items-center justify-center">
      <div
        className={cn(styles.permission, {
          [styles.permission_deleted]: isPermissionRemoved,
          [styles.permission_updated]: isPermissionAdded,
        })}
      >
        <span
          className={cn(styles.permission__value, {
            [styles.permission__value_updatedToPositive]: isPermissionAdded,
            [styles.permission__value_positive]: wasPermissionActivated,
          })}
        />
      </div>
    </div>
  );
}

export function ActivityLogModalPermission({ products }: Props) {
  return (
    <div className="overflow-hidden rounded-[8px]">
      <div className={cn('h-[3rem]', styles.permissionRow)}>
        {PermissionTableHeaderJSX}
      </div>

      <div className="grid gap">
        {sortByName<IUserRoleActivityLogProduct>(products).map((product) => (
          <div key={product.id} className={styles.permissionRow}>
            <div className="dark-60 inline-regular flex items-center px-4 py-3">
              {prepareProductName(product.name)}
            </div>
            {PERMISSIONS_TABLE.map(({ key }) => (
              <PermissionTableItem
                key={key}
                permissionKey={key}
                product={product}
              />
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}
