import React, { memo } from 'react';
import pluralize from 'pluralize';
import Table from 'bundles/Shared/components/Table/Table';
import { IUser } from 'types/User';
import { IColumn } from 'bundles/Shared/components/Table/types';
import { SharedWithTableSettings } from '@/bundles/Shared/components/SharedWithModal';

interface Props {
  allUsers: IUser[];
  settings: SharedWithTableSettings;
  setSettings: (settings: SharedWithTableSettings) => void;
  columns: IColumn<IUser>[];
  allUsersAmount: number;
}

const SharedWithTable = memo(
  ({ columns, allUsers, allUsersAmount, settings, setSettings }: Props) => (
    <Table
      columns={columns}
      items={allUsers}
      settings={settings}
      setSettings={setSettings}
      borderLessOutside
      classes={{ container: 'mt-m' }}
      emptyDocumentsLabel="No such users"
      emptyDocumentsSubLabel={`Out of ${allUsersAmount} ${pluralize(
        'user',
        allUsersAmount,
      )}, no users were found with the current filter parameters`}
    />
  ),
);

export default SharedWithTable;
