/**
 * TODO move to .env
 * leads to `public/logos/` folder with logos for clients
 */
const LOGO_PATH = '/logos';
export const SYMMETRE_LOGO_NAME = 'symmetre';

type Variant = 'large' | 'square' | 'horizontal';

type Params = {
  client?: string;
  variant?: Variant;
  extension?: 'svg' | 'png';
};

const DEFAULT_PARAMS = {
  client: SYMMETRE_LOGO_NAME,
  extension: 'svg',
  variant: 'large',
} as const satisfies Params;

/**
 * Generates a path to logo source
 */
export const getLogoPath = ({
  client = DEFAULT_PARAMS.client,
  extension = DEFAULT_PARAMS.extension,
  variant = DEFAULT_PARAMS.variant,
}: Params = DEFAULT_PARAMS) => {
  return [LOGO_PATH, `${client}-${variant}.${extension}`].join('/');
};
export const getAbsoluteLogoPath = (params: Params = DEFAULT_PARAMS) => {
  return window.origin.concat(getLogoPath(params));
};
