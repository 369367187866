import { usePostApiSettingsReportThirdPartyExtractionsConnectionsByConnectionIdAttachmentsParseMutation } from '@/shared/api/settingsReportConnectionsEnhancedApi';
import { SendDataButton } from 'bundles/DrawPackage/components/SendDataButton/SendDataButton';
import { IConnection } from 'bundles/Shared/entities/connection/model';

interface Props extends PropsWithClassName {
  connectionId: IConnection['id'];
  manual?: boolean;
}

export function ReparseFailedExtractionsButton({
  connectionId,
  manual,
  className,
}: Props) {
  // eslint-disable-next-line max-len
  const [reparse, { isLoading }] =
    usePostApiSettingsReportThirdPartyExtractionsConnectionsByConnectionIdAttachmentsParseMutation();

  return (
    <SendDataButton
      className={className}
      onClick={() =>
        reparse({
          connectionId,
          body: {
            manual_attachments: manual,
          },
        })
      }
      isLoading={isLoading}
      variant="danger"
      size="s"
      iconLoaderPosition="right"
      disappearIconName="sync"
      disabled={isLoading}
    >
      {isLoading && 'Reparsing'}
      {!isLoading && 'Reparse Failed'}
    </SendDataButton>
  );
}
