import CategoriesList from 'bundles/Settings/components/REport/CategoriesList/CategoriesList';
import UpsertModal from 'bundles/Settings/components/REport/JobCostCodes/UpsertModal';
import {
  fetchJobCostCodes,
  updateJobCostCodeCategories,
} from 'bundles/Shared/actions/job_cost_codes';
import {
  fetchReconcileDevelopmentCategoriesTree,
  updateReconcileDevelopmentCategoryTree,
} from 'bundles/Shared/actions/reconcile_development_categories';
import BulkActionsPanel from 'bundles/Shared/components/BulkActionsPanel/BulkActionsPanel';
import Table from 'bundles/Shared/components/Table/Table';
import { IColumn } from 'bundles/Shared/components/Table/types';
import {
  PageParamsPagination,
  PageParamsProvider,
  PageParamsSearch,
} from 'bundles/Shared/components/pageParams';
import { FinancialCategory } from '@/entities/finanicalCategory/model';
import ManageFinancialCategoriesModal from '@/widgets/financialCategory/manage/ui/ManageFinancialCategoriesModal';
import SetFinancialCategoryModal from '@/widgets/financialCategory/set/ui/SetFinancialCategoryModal';
import { useEffect, useMemo, useState } from 'react';
import { Button } from 'stories/Button/Button';
import { IconButton } from 'stories/IconButton/IconButton';
import {
  IJobCostCodeMeta,
  IJobCostCodeOnIndex,
  IJobCostCodesParams,
} from 'types/JobCostCode';
import { IReconcileDevelopmentCategoryTreeNode } from 'types/ReconcileDevelopmentCategory';

export default function JCCCrud() {
  const [isLoading, setIsLoading] = useState(true);
  const [categoriesTree, setCategoriesTree] = useState<
    IReconcileDevelopmentCategoryTreeNode[]
  >([]);
  const [selectedJobCostCodes, setSelectedJobCostCodes] = useState([]);
  const [items, setItems] = useState<IJobCostCodeOnIndex[]>([]);
  const [meta, setMeta] = useState<IJobCostCodeMeta>({
    totalSize: 0,
    perPage: 0,
  });
  const [params, setParams] = useState<IJobCostCodesParams>({ page: 1 });
  const [editedItem, setEditedItem] = useState<IJobCostCodeOnIndex | null>(
    null,
  );
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [manageCategoriesModalOpen, setManageCategoriesModalOpen] =
    useState(false);
  const [isSetCategoryModalOpen, setIsSetCategoryModalOpen] = useState(false);

  const reloadCategoriesTree = () => {
    fetchReconcileDevelopmentCategoriesTree().then((data) => {
      setCategoriesTree(data.tree);
    }, null);
  };

  const reloadItems = () => {
    setIsLoading(true);
    const payload = {
      page: params.page,
      query: params.query,
      sort: {
        field: params.sortField,
        order: params.sortOrder,
      },
    };

    fetchJobCostCodes(payload).then((data) => {
      setItems(data.items);
      setMeta(data.meta);

      if (
        data.meta.totalSize &&
        params.page > Math.ceil(data.meta.totalSize / data.meta.perPage)
      ) {
        setParams((oldParams) => ({ ...oldParams, page: 1 }));
      } else {
        setIsLoading(false);
      }
    }, null);
  };

  useEffect(() => {
    reloadCategoriesTree();
  }, []);

  useEffect(() => {
    reloadItems();
  }, [params]);

  const handleSetCategory = async (
    jccIds: number[],
    categoryId: number | null,
  ) => {
    const res = await updateJobCostCodeCategories({
      ids: jccIds,
      reconcile_development_category_id: categoryId,
    });
    if (!res) {
      return;
    }
    reloadCategoriesTree();
    reloadItems();
  };

  const handleSetCategoryModalSubmit = (category: FinancialCategory | null) => {
    setIsSetCategoryModalOpen(false);
    setSelectedJobCostCodes([]);
    handleSetCategory(
      selectedJobCostCodes.map(({ id }) => id),
      category?.id,
    );
  };

  const columns = useMemo<IColumn<IJobCostCodeOnIndex>[]>(
    () => [
      {
        sortable: true,
        text: '#',
        dataField: 'id',
      },
      {
        sortable: true,
        text: 'Name',
        dataField: 'name',
      },
      {
        sortable: true,
        text: 'Code',
        dataField: 'code',
      },
      {
        text: 'Development Category',
        dataField: 'reconcile_development_category_id',
        quickFilter: (
          <IconButton
            iconName="settings"
            className="ml-s"
            onClick={() => setManageCategoriesModalOpen(true)}
          />
        ),
        formatter: ({ row: item }) => (
          <CategoriesList
            categoryId={item.reconcileDevelopmentCategoryId}
            categoriesTree={categoriesTree}
            onChange={(id) => handleSetCategory([item.id], id)}
          />
        ),
      },
      {
        text: 'Actions',
        dataField: 'actions',
        formatter: ({ row: item }) => (
          <IconButton
            className="self-start bg-white"
            onClick={() => setEditedItem(item)}
            data-tip="Edit"
            iconName="edit"
          />
        ),
      },
    ],
    [categoriesTree],
  );

  const handleManageCategoriesModalSubmit = async (
    newTree: FinancialCategory[],
  ) => {
    await updateReconcileDevelopmentCategoryTree(newTree);
    setManageCategoriesModalOpen(false);

    reloadCategoriesTree();
    reloadItems();
  };

  return (
    <div className="mt-20">
      <PageParamsProvider pageParams={params} setPageParams={setParams}>
        <div className="mb-m flex items-center justify-between">
          <div>
            <PageParamsPagination
              loading={isLoading}
              totalSize={meta.totalSize}
              sizePerPage={meta.perPage}
            />
          </div>
          <div className="flex items-center">
            <PageParamsSearch />
            <Button
              data-cy="invoices"
              className="ml-m"
              variant="primary"
              onClick={() => setCreateModalOpen(true)}
            >
              New JCC
            </Button>
          </div>
        </div>
      </PageParamsProvider>
      <Table
        setSelectedRows={setSelectedJobCostCodes}
        selectedRows={selectedJobCostCodes}
        loading={isLoading}
        columns={columns}
        items={items}
        settings={params}
        setSettings={setParams}
        defaultColumn={{
          classes: 'bg-white',
        }}
      />
      {selectedJobCostCodes.length > 0 && (
        <BulkActionsPanel
          selectedRows={selectedJobCostCodes}
          setSelectedRows={setSelectedJobCostCodes}
          actions={[
            {
              title: 'Set Category',
              icon: 'edit',
              handleClick: () => setIsSetCategoryModalOpen(true),
            },
          ]}
        />
      )}
      {isSetCategoryModalOpen && (
        <SetFinancialCategoryModal
          canResetCategory
          categories={categoriesTree}
          onClose={() => setIsSetCategoryModalOpen(false)}
          onSubmit={handleSetCategoryModalSubmit}
        />
      )}

      {createModalOpen && (
        <UpsertModal
          item={null}
          handleClose={() => setCreateModalOpen(false)}
          onSuccess={() => {
            setCreateModalOpen(false);
            reloadItems();
          }}
        />
      )}
      {editedItem && (
        <UpsertModal
          item={editedItem}
          handleClose={() => setEditedItem(null)}
          onSuccess={() => {
            setEditedItem(null);
            reloadItems();
          }}
        />
      )}
      {manageCategoriesModalOpen && (
        <ManageFinancialCategoriesModal
          categories={categoriesTree}
          onClose={() => setManageCategoriesModalOpen(false)}
          onSubmit={handleManageCategoriesModalSubmit}
        />
      )}
    </div>
  );
}
