import React, { FC } from 'react';
import { Button } from 'stories/Button/Button';

interface Props {
  exportContinuationSheet: (assetId: number, id: number) => void;
  payApp: { assetId: number; id: number };
}

const ActionsContinuationTable: FC<Props> = ({
  exportContinuationSheet,
  payApp,
}) => (
  <Button
    iconName="download"
    variant="primary"
    children="Export"
    onClick={() => exportContinuationSheet(payApp.assetId, payApp.id)}
    iconPosition="left"
  />
);
export default ActionsContinuationTable;
