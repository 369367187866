import { cn } from '@/shared/lib/css/cn';
import { CardWrapper } from 'bundles/Shared/components/Wrappers/CardWrapper';
import {
  ASSET_PORTAL_PRODUCT_NAME,
  currentUserAllowedTo,
  FUND_PORTAL_PRODUCT_NAME,
} from 'lib/permissions';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Button } from '@/stories/Button/Button';
import { Icon } from '@/stories/Icon/Icon';
import BgCheckbox from '@/bundles/Shared/components/BgCheckbox';

const MediaItem = ({
  media,
  openLightbox,
  remove,
  openEditModal,
  openMoveModal,
  selectedItems,
  toggleSelected,
  hideActions,
  onRemove,
  actionsAlwaysVisible,
  size = 'default',
  actions,
}: {
  media: {
    id: number;
    videoUrl: string;
    smallImageUrl: string;
    smallPlaceholderUrl: string;
    largeImageUrl: string;
    title: string;
    description: string;
    assetId: number;
    documentableId: number;
    documentableType: string;
  };
  openLightbox: (id: number) => void;
  remove: () => void;
  selectedItems: unknown[];
  toggleSelected: (id: number) => void;
  openEditModal?: (media: unknown, isMedia: boolean) => void;
  openMoveModal?: (media: unknown, isMedia: boolean) => void;
  hideActions?: boolean;
  onRemove?: (id: number) => void;
  actionsAlwaysVisible?: boolean;
  size?: string;
  actions?: React.ReactNode;
}) => {
  const {
    videoUrl,
    smallPlaceholderUrl,
    smallImageUrl,
    largeImageUrl,
    id,
    title,
    description,
    assetId,
    documentableId,
    documentableType,
  } = media;
  const productName = assetId
    ? ASSET_PORTAL_PRODUCT_NAME
    : FUND_PORTAL_PRODUCT_NAME;

  const canManage = currentUserAllowedTo(
    'manage',
    productName,
    documentableType,
    assetId || documentableId,
  );

  const isSelected = selectedItems.some(
    ({ id: selectedId }) => selectedId === media.id,
  );

  return (
    <CardWrapper
      className={cn('mb-0 border-0', {
        'blue-outline p-[0.25rem]': isSelected,
        'p-[0.5rem]': !isSelected && size === 'default',
        'mx-[0.25rem] p-0': size !== 'default',
      })}
    >
      <>
        <div
          className={cn('media-item relative', {
            'h-200p': size === 'default',
            'h-80p w-120p': size === 's',
            'h-160p w-240p': size === 'm',
          })}
        >
          <div
            className={cn('h-100', { 'overlay-wrapper': size !== 'default' })}
          >
            {onRemove && (
              <Icon
                className="media-item__close-button"
                onClick={() => onRemove(id)}
                iconName="close"
              />
            )}
            <div
              className={cn('border-radius-[1rem] overflow-hidden', {
                'overlay overlay_dark': onRemove,
              })}
            />
            {videoUrl ? (
              // eslint-disable-next-line jsx-a11y/media-has-caption
              <video
                src={videoUrl}
                height={200}
                className="w-100 border-radius-[1rem]"
                controls
              />
            ) : (
              <LazyLoadImage
                wrapperClassName="h-100 w-100"
                src={smallImageUrl}
                placeholderSrc={smallPlaceholderUrl}
                effect="blur"
                large_src={largeImageUrl}
                onClick={openLightbox ? () => openLightbox(id) : null}
                className={cn(
                  'w-100 h-100 card-img-top card-image border-radius-[1rem]',
                  { 'open-lightbox': openLightbox },
                )}
              />
            )}
            {canManage && !hideActions && (
              <div
                className={cn('folder-actions', {
                  flex: actionsAlwaysVisible,
                })}
              >
                <BgCheckbox
                  id={`media-${media.id}-checkbox`}
                  isSelected={isSelected}
                  toggleSelected={toggleSelected}
                  tooltip="Select media"
                />
                <div className="ml-auto">
                  {openMoveModal && (
                    <Button
                      tooltipProps={{
                        mainText: 'Move to...',
                      }}
                      color="dark"
                      size="s"
                      className="mr-[0.5rem]"
                      onClick={() => openMoveModal(media, true)}
                    >
                      <div className="sre-icon-transferFile" />
                    </Button>
                  )}
                  {openEditModal && (
                    <Button
                      tooltipProps={{
                        mainText: 'Edit media',
                      }}
                      color="dark"
                      size="s"
                      className="mr-[0.5rem]"
                      onClick={() => openEditModal(media, true)}
                    >
                      <div className="sre-icon-edit" />
                    </Button>
                  )}
                  {remove && (
                    <Button
                      tooltipProps={{
                        mainText: 'Remove media',
                      }}
                      color="dark"
                      size="s"
                      onClick={remove}
                    >
                      <div className="sre-icon-trash" />
                    </Button>
                  )}
                </div>
              </div>
            )}
            {actions}
          </div>
        </div>
        {title && size === 'default' && (
          <div className="card-body p-2">
            <p className="album-description inline-regular">{title}</p>
          </div>
        )}
      </>
      {description && size === 'default' && (
        <div className="card-body p-2">
          <p className="album-description inline-regular">{description}</p>
        </div>
      )}
    </CardWrapper>
  );
};

export default MediaItem;
