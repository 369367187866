import React, { useCallback, useRef } from 'react';
import { Popover } from 'stories/Popover/Popover';
import {
  IContextMenuProps,
  IRowBase,
} from 'bundles/Shared/components/Table/types';
import { PopoverRef } from 'stories/Popover/Popover';

interface ContextMenuCellProps<T extends IRowBase>
  extends IContextMenuProps<T> {
  children: React.ReactElement;
}

export const ContextMenuCell = <T extends IRowBase>({
  children,
  ...columnProps
}: ContextMenuCellProps<T>) => {
  const ContextMenu = columnProps.column?.contextMenu;
  const ref = useRef<PopoverRef>(null);

  const hidePopover = useCallback(() => {
    ref.current?.hide();
  }, []);

  return (
    <Popover
      ref={ref}
      trigger="click"
      classes={{
        spanContainer: 'flex w-full h-full justify-between cursor-pointer',
      }}
      className="p-0"
      maxWidth={300}
      appendToBody
      hiddenArrow
      template={
        ContextMenu && (
          <ContextMenu hidePopover={hidePopover} {...columnProps} />
        )
      }
      placement="bottom-end"
      {...columnProps?.column.contextMenuParams?.popoverProps}
    >
      {children}
    </Popover>
  );
};
