import React from 'react';
import { ModalHeaderWithIcon } from 'stories/Modals/ModalHeader/ModalHeaderWithIcon/ModalHeaderWithIcon';
import { MultipleItemsIcons } from 'stories/MultipleItemsIcons/MultipleItemsIcons';
import { PseudoLink } from 'stories/PseudoLink/PseudoLink';
import { FileIcon } from 'stories/FileIcon/FileIcon';
import pluralize from 'pluralize';
import DocumentsPopover from 'bundles/Shared/components/DocumentsPopover/DocumentsPopover';

const NormalizedHeaderTitle = ({ fileItems }) =>
  fileItems.length > 1 ? (
    <DocumentsPopover files={fileItems}>
      <PseudoLink>
        {fileItems.length} {pluralize('documents', fileItems.length)}
      </PseudoLink>
    </DocumentsPopover>
  ) : (
    <span>
      {fileItems.length} {pluralize('documents', fileItems.length)}
    </span>
  );

export const PermissionsModalDocsHeader = ({ fileItems }) => (
  <div className="bg-white pt-0">
    <ModalHeaderWithIcon
      icons={<MultipleItemsIcons itemType="documents" items={fileItems} />}
      title={
        <>
          <span>Set permissions for </span>
          <NormalizedHeaderTitle fileItems={fileItems} />
        </>
      }
      subtitle={
        fileItems.length === 1 && (
          <div className="flex items-center inline-regular gap-xs">
            <FileIcon extension={fileItems[0]?.extension} />
            <span>{fileItems[0]?.name}</span>
          </div>
        )
      }
    />
  </div>
);

export default PermissionsModalDocsHeader;
