import React, { useMemo, useRef, useState } from 'react';
import Slider from 'react-slick';
import OpenDealsSlide from '@/pages/portfolio/widgets/OpenDeals/OpenDealsSlide';
import { useGetApiPortfolioDetailsQuery } from '@/shared/api/portfolioEnhancedApi';
import styles from '@/pages/portfolio/widgets/OpenDeals/OpenDealsCarousel.module.scss';
import { LinkButton } from '@/stories/LinkButton/LinkButton';

const OpenDealsCarousel = () => {
  const { data } = useGetApiPortfolioDetailsQuery();
  const offerings = useMemo(
    () => [...(data?.funds ?? []), ...(data?.assets ?? [])],
    [data],
  );

  const [activeIndex, setActiveIndex] = useState(0);

  if (!data) return null;

  const settings = {
    infinite: true,
    dots: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    afterChange: (current) => setActiveIndex(current),
  };

  const slider = useRef(null);
  const totalSize = offerings.length;

  return (
    <div className="rounded-2xl border-4 border-neutral-200 bg-neutral-100">
      <Slider className={styles.slider} {...settings} ref={slider}>
        {offerings.map((offering) => (
          <OpenDealsSlide
            key={`${offering.id}-${offering.name}`}
            totalSize={offerings.length}
            offering={offering}
            activeIndex={activeIndex}
            slider={slider}
          />
        ))}
      </Slider>
      {totalSize > 1 && (
        <div className={styles.footer}>
          <div className="secondary-regular text-light-60">
            {activeIndex + 1} of {totalSize}
          </div>
          <div className="secondary-regular flex">
            <LinkButton
              className="mr-4 "
              onClick={() => slider?.current?.slickPrev() as void}
            >
              Prev
            </LinkButton>
            <LinkButton onClick={() => slider?.current?.slickNext() as void}>
              Next
            </LinkButton>
          </div>
        </div>
      )}
    </div>
  );
};

export default OpenDealsCarousel;
