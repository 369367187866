import { settingsReportOperationalUnitTypesEnhancedApi } from '@/entities/report/unitTypes';
import { useAppDispatch } from '@/shared/lib/hooks/redux';
import useBoolean from '@/shared/lib/hooks/useBoolean';
import useFileDialog from '@/shared/lib/hooks/useFileDialog';
import {
  ACCEPT_XLSX_FILE_EXTENSION,
  ACCEPT_CSV_FILE_EXTENSION,
} from '@/shared/lib/browser/config';
import { importFile } from '@/shared/lib/importFile';
import { GetApiSettingsReportRentRollSourceUnitTypesExportCsvApiArg } from '@/entities/report/unitTypes/api/settingsReportOperationalUnitTypesGeneratedApi';
import { exportFile } from '@/shared/lib/exportFile';
import { snakeCaseKeys } from 'bundles/Construction/components/helpers';
import { SettingsReportRentrollSourceUnitTypeImportExportType } from '@/features/report/unitTypes/settingsImport/model';

export const useSettingsReportUnitTypesImport = (
  type: SettingsReportRentrollSourceUnitTypeImportExportType,
) => {
  const dispatch = useAppDispatch();
  const { openFileDialog } = useFileDialog({
    multiple: false,
    accept:
      type === 'csv' ? ACCEPT_CSV_FILE_EXTENSION : ACCEPT_XLSX_FILE_EXTENSION,
  });
  const {
    value: isLoading,
    setTrue: enableLoading,
    setFalse: disableLoading,
  } = useBoolean();

  const handleImport = async () => {
    const fileList = await openFileDialog();

    if (fileList === null || fileList.length < 1) return;

    const [file] = fileList;

    const res = await importFile({
      url: '/api/settings/report/rent_roll/source_unit_types/import',
      file,
      disableLoading,
      enableLoading,
    });

    if (res) {
      dispatch(
        settingsReportOperationalUnitTypesEnhancedApi.util.invalidateTags([
          'SourceUnitTypes',
        ]),
      );
    }
  };

  return [handleImport, { isLoading }] as const;
};

export const useSettingsReportUnitTypesExport = (
  type: SettingsReportRentrollSourceUnitTypeImportExportType,
) => {
  const {
    value: isLoading,
    setTrue: enableLoading,
    setFalse: disableLoading,
  } = useBoolean();

  const handleExport = (
    params: GetApiSettingsReportRentRollSourceUnitTypesExportCsvApiArg,
  ) => {
    exportFile({
      url: `/settings/report/rent_roll/source_unit_types/export.${type}`,
      params: snakeCaseKeys(params),
      filePostfix: 'unit-types',
      enableLoading,
      disableLoading,
    });
  };

  return [handleExport, { isLoading }] as const;
};
