import { apiClient, requireAuthorizationHeaders } from '@/lib/http';
import { getFileExtensionFromName } from '@/lib/uploadFiles';
import { AxiosError } from 'axios';

const XSLX_FILES_CONTENT_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

const DEFAULT_CONTENT_TYPE = 'multipart/form-data';

const CONTENT_TYPE_EXTENSION_MAP = {
  csv: 'text/csv',
  xlsx: XSLX_FILES_CONTENT_TYPE,
  xlsm: XSLX_FILES_CONTENT_TYPE,
  xls: XSLX_FILES_CONTENT_TYPE,
};

export async function importFile({
  file,
  url,
  enableLoading,
  disableLoading,
  args,
}: {
  url: string;
  file: File;
  enableLoading?: VoidFunction;
  disableLoading?: VoidFunction;
  args?: Record<string, string | Blob>;
}) {
  const formData = new FormData();
  formData.set('file', file);

  for (const key in args ?? {}) {
    formData.set(key, args![key]);
  }

  try {
    enableLoading?.();
    const resolveContentType = () => {
      const extension = getFileExtensionFromName(file.name);
      const contentType = CONTENT_TYPE_EXTENSION_MAP[extension];
      if (contentType) {
        return contentType;
      }
      return DEFAULT_CONTENT_TYPE;
    };

    const res = await apiClient.post(url, formData, {
      headers: requireAuthorizationHeaders({
        'Content-Type': resolveContentType(),
      }),
    });

    if (res.status !== 200) {
      throw res;
    }
    toastr.success('Import is complete');
    return res;
  } catch (e: unknown) {
    if (e instanceof AxiosError) {
      const syErrors = e as AxiosError<
        { errors: string[] },
        { errors: string[] }
      >;

      const msg =
        syErrors.response?.data.errors.join(', \n') ?? 'Something went wrong';

      toastr.error(msg, 'Errors', {
        timeOut: 0,
        extendedTimeOut: 0,
      });
    }
  } finally {
    disableLoading?.();
  }
}
