import { Button } from '@/stories/Button/Button';
import { useUpdateThreshold } from '@/features/report/threshold/crud/lib';
import { ReportThreshold } from '@/entities/report/threshold/model';

export const CreateReportThresholdPlaceholder = ({
  threshold,
}: {
  threshold: ReportThreshold;
}) => {
  const [updateThreshold] = useUpdateThreshold(threshold);

  return (
    <div className="flex w-full  grow flex-col items-center justify-center gap-6">
      <div className="flex max-w-[400px] flex-col items-center justify-center gap-2 text-center">
        <p className="header4-regular text-neutral-500">
          To get started, add a Threshold
        </p>
        <span className="inline-regular text-neutral-500">
          {threshold.description}
        </span>
      </div>

      <Button size="m" variant="primary" onClick={() => updateThreshold()}>
        Add Threshold
      </Button>
    </div>
  );
};
