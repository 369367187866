import { NEXT_GEN_TABLE_CLASS_NAME } from '@/bundles/Shared/components/AgGrid/Table/SymmetreAgGridTable';
import { cn } from '@/shared/lib/css/cn';
import { DateSSN } from '@/shared/lib/dateSSN/ssn';
import { DEFAULT_DATE_FORMAT, formatDate } from '@/shared/lib/formatting/dates';
import Calendar from 'stories/FlexibleFilterByPeriods/calendar/Calendar';
import type { PopoverRef } from 'stories/Popover/Popover';
import { ICellEditorParams } from 'ag-grid-community';
import dayjs from 'dayjs';
import {
  forwardRef,
  memo,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import NumberFormat from 'react-number-format';
import styles from '@/entities/valueType/ui/CellEditor/styles.module.scss';

interface Props extends ICellEditorParams {
  height: number;
  value: string | number | null | undefined;
}

const MIN_DOLLAR_DECIMALS = 2;
const MAX_POSSIBLE_VALUE = 999_999_999_999;
const KEY_BACKSPACE = 'Backspace';
const KEY_DELETE = 'Delete';
const KEY_ENTER = 'Enter';
const KEY_TAB = 'Tab';
export const DEFAULT_CELL_EDITOR_FALLBACK = '–';

type BaseCellEditorProps<T> = {
  value: T | null;
  onChange: (value: T | null) => void;
  onCancel: () => void;
};

const NumberCellEditor = (props: BaseCellEditorProps<string>) => {
  const { value, onChange } = props;

  const refInput = useRef<HTMLInputElement>(null);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  useEffect(() => {
    refInput?.current?.focus();
    refInput?.current?.select();
  }, []);

  // TODO: FE-3691 change to simple input with maskito
  return (
    <NumberFormat
      getInputRef={refInput}
      className={cn(
        styles.input,
        'inline-regular h-full w-full px-2 text-right',
      )}
      decimalSeparator="."
      maxLength={18}
      max={MAX_POSSIBLE_VALUE}
      min={MAX_POSSIBLE_VALUE}
      thousandSeparator=","
      decimalScale={MIN_DOLLAR_DECIMALS}
      value={value}
      onChange={handleChange}
    />
  );
};

const DateCellEditor = (props: BaseCellEditorProps<string>) => {
  const popoverRef = useRef<PopoverRef>(null);
  const { value, onChange, onCancel } = props;

  const calendarValue: dayjs.Dayjs[] | null = useMemo(() => {
    if (value == null) return null;

    if (typeof value === 'number') {
      return [dayjs(new DateSSN(value).toDate())] as const;
    }

    if (typeof value === 'string') {
      return [dayjs(new DateSSN(Number(value)).toDate())] as const;
    }
    return null;
  }, [value]);

  // manual handler for backspace or delete
  const handleKeyboard = (e: KeyboardEvent) => {
    if (e.key === KEY_BACKSPACE || e.key === KEY_DELETE) {
      onChange(null);
    } else if (e.key === KEY_TAB || e.key === KEY_ENTER) {
      onCancel();
    }
  };

  useEffect(() => {
    popoverRef.current?.show();

    window.addEventListener('keydown', handleKeyboard);

    return () => {
      window.removeEventListener('keydown', handleKeyboard);
    };
  }, []);

  const elem = document.querySelector(`.${NEXT_GEN_TABLE_CLASS_NAME}`);

  if (!elem) return null;

  return (
    <Calendar
      ref={popoverRef}
      value={calendarValue}
      popoverProps={{
        appendTo: elem,
        placement: 'top-start',
        classes: { spanContainer: 'cell-wrapper cell-wrapper_basic' },
        children: (
          <div className="cell-inner cell-inner_basic min-w-0">
            {calendarValue
              ? formatDate(calendarValue[0], DEFAULT_DATE_FORMAT)
              : DEFAULT_CELL_EDITOR_FALLBACK}
          </div>
        ),
      }}
      onChange={([item]) => {
        const newValue = new DateSSN(item.toDate()).toNumber();
        onChange(String(newValue));
      }}
      closeOnDateUpdate
      selectionMode="daily"
    />
  );
};

// Docs link https://www.ag-grid.com/archive/28.0.0/react-data-grid/component-cell-editor/#accessing-cell-editor-instances
export const ValueTypeCellEditor = memo(
  forwardRef<unknown, Props>((props, ref) => {
    const [value, setValue] = useState<string | null>(() => {
      const notUndefined = props.value ?? null;
      if (typeof notUndefined === 'number') {
        return String(notUndefined);
      }
      return notUndefined;
    });
    const isDate = props.data.valueType === 'date';

    useImperativeHandle(ref, () => ({
      getValue() {
        return value;
      },
    }));

    useEffect(() => {
      // quick fix: call update request after date-picker editing
      if (
        isDate &&
        ((value && value !== String(props.value)) ??
          // part of manual handler for backspace or delete
          (value === null && props.value !== value))
      ) {
        props.stopEditing();
      }
    }, [isDate, value, props.value]);

    if (isDate) {
      return (
        <DateCellEditor
          value={value}
          onChange={setValue}
          onCancel={props.stopEditing}
        />
      );
    }

    return (
      <NumberCellEditor
        value={value}
        onChange={setValue}
        onCancel={props.stopEditing}
      />
    );
  }),
);

ValueTypeCellEditor.displayName = 'ValueTypeCellEditor';
