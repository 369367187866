import TablePagination from 'bundles/Shared/components/Table/pagination/TablePagination';
import { usePageParamsContext } from 'bundles/Shared/components/pageParams/PageParamsContext';
import React from 'react';
import { IPaginationParams } from 'types/Pagination';

type Props = Omit<
  React.ComponentProps<typeof TablePagination>,
  'currentPage' | 'sizePerPage'
> & {
  sizePerPage?: number;
};

export function PageParamsPagination(props: Props) {
  const { pageParams, setPageParams } =
    usePageParamsContext<IPaginationParams>();
  const currentPage = pageParams.page ?? 1;
  const sizePerPage =
    pageParams.per_page ?? props?.sizePerPage ?? pageParams?.perPage ?? 0;

  const handleSizePerPageChange = (perPage: number) => {
    const firstItemIndex = (currentPage - 1) * sizePerPage;

    const newPage = Math.floor(firstItemIndex / perPage) + 1;
    setPageParams({ ...pageParams, perPage, page: newPage });
  };

  return (
    <TablePagination
      {...props}
      setCurrentPage={(page) => setPageParams({ ...pageParams, page })}
      currentPage={currentPage}
      sizePerPage={sizePerPage} // Standardize on "perPage"!
      onSizePerPageChange={handleSizePerPageChange}
    />
  );
}

export default PageParamsPagination;
