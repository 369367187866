import axios from 'axios';
import { stringify } from 'qs';
import http from '@/lib/http';

interface ISignedData {
  fields: {
    key: string;
  };
  url: string;
}

export interface IPresignedFile {
  file: File;
  signedData: ISignedData;
}

export const presignFile = async (file: File, params = {}) => {
  const query = stringify(
    { ...params, name: file.name, type: file.type },
    { encode: true, addQueryPrefix: true },
  );

  const res = await http.get(`/shared_files/presign${query}`);
  const signedData = (await res.json()) as ISignedData;
  return { file, signedData };
};

export const presignFiles = async (files: File[]) => {
  const presignedFiles: IPresignedFile[] = [];
  await Promise.all(
    files.map(async (fileData) => {
      presignedFiles.push(await presignFile(fileData));
    }),
  );
  return presignedFiles;
};

// options - is an object with axios callbacks
// callbacks:
//   - onUploadProgress: (progressEvent) => handleProgress
export const directUpload = (
  { file, signedData }: IPresignedFile,
  options = {},
) => {
  const form = new FormData();
  Object.keys(signedData.fields).forEach((key) =>
    form.append(key, signedData.fields[key]),
  );
  form.append('file', file);
  return axios.post(signedData.url, form, options);
};

export const compareDroppedFiles = (file1, file2) =>
  file1.name === file2.name && file1.droppedAt === file2.droppedAt;

export const getFileExtensionFromName = (fileName: string) => {
  const lastDotIndex = fileName.lastIndexOf('.');
  return fileName.slice(lastDotIndex + 1);
};

export const sanitizeFileName = (fileName: string) =>
  fileName.replace(/[^a-zA-Z0-9]/g, '_');
