import React, { useEffect, useState } from 'react';
import {
  changePagePosition,
  fetchUserRoleActivityLog,
} from 'bundles/UserManagement/reducers/userRolesSlice';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import { AnimationLoader } from 'stories/AnimationLoader/AnimationLoader';
import { HistoryItem } from 'stories/HistoryItem/HistoryItem';
import { IconButton } from 'stories/IconButton/IconButton';
import {
  ActivityLogTabType,
  IUserRoleActivityLogItem,
} from '@/bundles/UserManagement/components/roles/types';
import { LogItemShowDetailsModal } from '@/bundles/UserManagement/components/roles/RightBar/ActivityLog/LogItemShowDetailsModal';
import {
  genHistoryItemProps,
  NoActivityLogJSX,
} from '@/bundles/UserManagement/components/roles/constants';

export function AllActivityLog() {
  const dispatch = useAppDispatch();
  const [activityLogTabState, setActivityLogTabState] =
    useState<ActivityLogTabType>('loading');
  const [showLogDetails, setShowLogDetails] =
    useState<IUserRoleActivityLogItem | null>(null);
  const userRoles = useAppSelector((state) => state.userRoles);

  useEffect(() => {
    const fetch = async () => {
      setActivityLogTabState('loading');
      await dispatch(fetchUserRoleActivityLog());
      setActivityLogTabState(
        userRoles.activityLog.length ? 'loaded' : 'no-activity-log',
      );
    };
    fetch();
  }, [userRoles.roles]);

  const handleToggleShowDetailsModal = (opened: boolean) => {
    setShowLogDetails(null);
    setActivityLogTabState(opened ? 'idle' : 'show-details');
  };

  const handleShowDetailsModal = (logItem: IUserRoleActivityLogItem) => {
    setShowLogDetails(logItem);
    setActivityLogTabState('show-details');
  };

  return (
    <>
      <div className="mb-s flex items-start justify-between pb-3">
        <h4 className="header4-bold">Activity Log</h4>
        <IconButton
          className="bg-white"
          onClick={() => dispatch(changePagePosition('idle'))}
          size="l"
          iconName="close"
        />
      </div>
      <div className="flex flex-col">
        {activityLogTabState === 'loading' && <AnimationLoader />}
        {activityLogTabState === 'no-activity-log' &&
          userRoles.activityLog.length === 0 &&
          NoActivityLogJSX}

        {activityLogTabState !== 'loading' &&
          [...userRoles.activityLog]
            .reverse()
            .map((logItem, idx, arr) => (
              <HistoryItem
                key={`${logItem.name}-${logItem.createdAt}`}
                {...genHistoryItemProps(
                  logItem,
                  handleShowDetailsModal,
                  idx === arr.length - 1,
                )}
              />
            ))}

        {activityLogTabState === 'show-details' && showLogDetails && (
          <LogItemShowDetailsModal
            logItem={showLogDetails}
            toggle={handleToggleShowDetailsModal}
          />
        )}
      </div>
    </>
  );
}
