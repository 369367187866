import { ReportDashboardType } from '@/bundles/Shared/entities/dashboard/model/types/types';
import { DashboardSelectionItem } from '@/bundles/Shared/features/dashboard/create/ui/DashboardSelectionItem';
import { reportingEnhancedApi } from '@/entities/reporting/api/reportingEnhancedApi';
import { useReportBuilderTemplatesQuery } from '@/entities/reporting/api/reportingTemplates';
import { useNavigateBack } from '@/shared/lib/hooks/navigation';
import { useModal } from '@/shared/lib/hooks/useModal';
import { generateUrl, ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import { CreateNewUI } from '@/shared/ui/CreateNewUI';
import { GrowDiv } from '@/shared/ui/GrowDiv';
import { Button } from '@/stories/Button/Button';
import { Field } from '@/stories/Field/Field';
import { Icon } from '@/stories/Icon/Icon';
import { IconButton } from '@/stories/IconButton/IconButton';
import { Input } from '@/stories/FormControls/Inputs/Input/Input';
import { Select } from '@/stories/FormControls/Select/Select';
import { Textarea } from '@/stories/Textarea/Textarea';
import {
  SectionField,
  SectionFieldsContainer,
} from '@/stories/Field/FieldsWrappers';
import type { ISelectOption } from '@/stories/FormControls/Select/Select';
import { ReportBuilderGroupTemplatesModal } from '@/widgets/reporting/reportBuilderGroup/ReportBuilderGroupTemplatesModal';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate, type RouteComponentProps } from '@reach/router';
import type { FC } from 'react';
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
} from 'react-hook-form';
import * as yup from 'yup';

// entities/reporting/config
const REPORT_GROUP_TYPES = [
  ReportDashboardType.EAGLE_EYE,
  ReportDashboardType.OBJECT,
] as const;

enum ReportGroupFormat {
  PDF = 'pdf',
  EXCEL = 'excel',
}
// entities/reporting/config
const REPORT_GROUP_FORMATS = [
  ReportGroupFormat.PDF,
  ReportGroupFormat.EXCEL,
] as const;

// entities/reporting/config
const REPORT_GROUP_TEMPLATE_TYPE_SELECT_OPTIONS = [
  {
    id: ReportGroupFormat.PDF,
    label: 'PDF',
    value: ReportGroupFormat.PDF,
  },
  {
    id: ReportGroupFormat.EXCEL,
    disabled: true,
    label: 'Excel',
    value: ReportGroupFormat.EXCEL,
  },
] as const satisfies ISelectOption<ReportGroupFormat>[];

// entities/reporting/lib
const REPORT_GROUP_CREATION_SCHEMA = yup.object().shape({
  name: yup.string().required(),
  description: yup.string(),
  format_type: yup
    .string()
    .oneOf(REPORT_GROUP_FORMATS)
    .required()
    .default(ReportGroupFormat.PDF),
  report_type: yup.string().oneOf(REPORT_GROUP_TYPES).required(),
  report_builder_template_id: yup.string().required(),
});

type CreationFormModel = yup.InferType<typeof REPORT_GROUP_CREATION_SCHEMA>;

// widgets/reportings/createReportGroup/ui
const Form = () => {
  const methods = useFormContext<CreationFormModel>();
  const { register, control, watch } = methods;

  const { templateOptionsMap } = useReportBuilderTemplatesQuery();

  const { openModal } = useModal();

  const templateName = templateOptionsMap.get(
    watch('report_builder_template_id'),
  )?.name;

  return (
    <CreateNewUI.FieldsContainer>
      <Field labelText="Name" required>
        <Input {...register('name')} />
      </Field>
      <Field labelText="Description">
        <Textarea
          placeholder="Enter Description"
          {...register('description')}
        />
      </Field>
      <Field labelText="Report Format" required>
        <Controller
          control={control}
          name="format_type"
          render={({ field }) => (
            <Select
              inputProps={{
                resetButton: false,
              }}
              selected={REPORT_GROUP_TEMPLATE_TYPE_SELECT_OPTIONS.find(
                (o) => o.id === field.value,
              )}
              onSelectedChange={(o) => {
                if (Array.isArray(o) || o == null) return;

                field.onChange(o.id);
              }}
              options={REPORT_GROUP_TEMPLATE_TYPE_SELECT_OPTIONS}
            />
          )}
        />
      </Field>
      <SectionFieldsContainer>
        <Controller
          control={control}
          name="report_type"
          render={({ field }) => (
            <SectionField
              className="gap-4"
              labelText="Choose your Report type"
              required
            >
              <div className="grid grid-cols-2 gap-4">
                <DashboardSelectionItem
                  selected={field.value === ReportDashboardType.EAGLE_EYE}
                  onClick={() => field.onChange(ReportDashboardType.EAGLE_EYE)}
                  label="Eagle Eye"
                  iconName="eagleEyeDashboardGreyscale"
                />
                <DashboardSelectionItem
                  disabled
                  selected={field.value === ReportDashboardType.OBJECT}
                  onClick={() => field.onChange(ReportDashboardType.OBJECT)}
                  label="Object Level"
                  iconName="objectLevelDashboardGreyscale"
                />
              </div>
            </SectionField>
          )}
        />
      </SectionFieldsContainer>
      <SectionFieldsContainer>
        <Controller
          control={control}
          name="report_builder_template_id"
          render={({ field }) => (
            <SectionField
              className="gap-4"
              labelText="Choose your Report Template"
              required
            >
              <div className="flex items-center gap-2 rounded-[8px] border-2 border-neutral-100 p-2">
                <Icon
                  className="p-1.5 rounded-[8px] bg-neutral-100"
                  iconName="docPdf"
                />
                <p className="inline-semibold text-neutral-800">
                  {templateName ?? 'Select Template'}
                </p>
                <GrowDiv />
                <IconButton
                  onClick={async () => {
                    const res = await openModal(
                      ReportBuilderGroupTemplatesModal,
                      {},
                    );

                    if (res == null) return;

                    field.onChange(res.id);
                  }}
                  iconName="edit"
                />
              </div>
            </SectionField>
          )}
        />
      </SectionFieldsContainer>
    </CreateNewUI.FieldsContainer>
  );
};

export const CreateNewReportGroupPage: FC<RouteComponentProps> = () => {
  const { navigateBack } = useNavigateBack({
    fallbackUrl: ROUTES_ROOT.reporting.fullPath,
  });
  const navigate = useNavigate();

  // features/reporting/createReportGroup/lib
  const [createReportGroup, createOptions] =
    reportingEnhancedApi.usePostApiReportBuilderReportGroupsMutation();

  // entities/reporting/lib
  const methods = useForm<CreationFormModel>({
    defaultValues: {
      name: '',
      description: '',
      format_type: ReportGroupFormat.PDF,
    },
    resolver: yupResolver(REPORT_GROUP_CREATION_SCHEMA),
    mode: 'all',
  });
  const {
    handleSubmit,
    formState: { isValid, isDirty, isSubmitting },
  } = methods;

  // features/reporting/createReportGroup/lib
  const onSubmit = handleSubmit(async (data) => {
    const res = await createReportGroup({
      body: {
        name: data.name,
        template_id: data.report_builder_template_id,
        description: data.description,
        template_kind: data.report_type,
      },
    });
    if ('error' in res) {
      return;
    }
    navigate(
      generateUrl(ROUTES_ROOT.reporting.eagleEye.reportGroup.fullPath, {
        pathParams: {
          reportGroupId: res.data.id,
        },
      }),
    );
  });

  const submitSlot = (
    <>
      {!isDirty && (
        <span className="inline-regular text-center text-neutral-550">
          You need to fill all fields to continue
        </span>
      )}
      <Button
        disabled={!isValid || isSubmitting}
        variant="success"
        size="l"
        loading={createOptions.isLoading}
        onClick={onSubmit}
      >
        Create Group
      </Button>
    </>
  );

  return (
    <CreateNewUI>
      <CreateNewUI.BackButton onClick={navigateBack} />
      <CreateNewUI.Container>
        <CreateNewUI.Form title="Report Group" submitSlot={submitSlot}>
          <FormProvider {...methods}>
            <Form />
          </FormProvider>
        </CreateNewUI.Form>
      </CreateNewUI.Container>
    </CreateNewUI>
  );
};
