import { DialogProps, useModal } from '@/shared/lib/hooks/useModal';
import { cn } from '@/shared/lib/css/cn';
import { Modal } from '@/stories/Modals/Modal/Modal';
import { ModalHeaderWithSubtitle } from '@/stories/Modals/ModalHeader/ModalHeaderWithSubtitle/ModalHeaderWithSubtitle';
import { uniqBy } from 'lodash-es';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import pluralize from 'pluralize';
import { PropsWithChildren } from 'react';
import { SectionField } from 'stories/Field/FieldsWrappers';

const EntityTag = ({
  children,
  ...props
}: PropsWithChildren & React.HTMLAttributes<HTMLDivElement>) => (
  <div
    {...props}
    className={cn(
      'secondary-regular flex rounded-[8px] bg-neutral-050 p-1 text-neutral-850',
      props.onClick &&
        'bgt-info-000 secondary-semibold cursor-pointer text-info-050 transition-colors hover:bg-info-020',
    )}
  >
    {children}
  </div>
);

const MoreEntitiesModal = <T extends { id: string; name: string }>({
  onClose,
  itemName,
  itemsCount,
  uniqItems,
}: DialogProps & {
  uniqItems: T[];
  itemsCount: number;
  itemName: string;
}) => (
  <Modal
    header={
      <ModalHeaderWithSubtitle
        title={`Selected ${pluralize(itemName)}`}
        subtitle={pluralize(itemName)}
      />
    }
    classes={{
      body: '!py-4',
    }}
    toggle={onClose}
  >
    <p className="secondary-semibold mb-2 text-neutral-550">
      {itemsCount} {pluralize(itemName, itemsCount)} selected, including:
    </p>
    <OverlayScrollbarsComponent className="flex max-h-[400px] flex-col rounded-[8px]">
      <div className="flex flex-col gap overflow-hidden rounded-[8px] bg-neutral-100">
        {uniqItems.map((i) => (
          <div
            className="secondary-regular flex w-full bg-neutral-000 px-4 py-4 text-neutral-850"
            key={i.id}
          >
            {i.name}
          </div>
        ))}
      </div>
    </OverlayScrollbarsComponent>
  </Modal>
);

export const SelectedEntityTags = <T extends { id: string; name: string }>({
  items,
  itemName,
}: {
  items: T[];
  itemName: string;
}) => {
  const MAX_COUNT = 8;
  const uniqItems = uniqBy(items, 'name');

  const { openModal } = useModal();

  const handleMore = () => {
    openModal(MoreEntitiesModal, {
      itemName,
      uniqItems,
      itemsCount: items.length,
    });
  };

  return (
    <SectionField
      labelText={`${items.length} ${pluralize(
        itemName,
        items.length,
      )} selected, including:`}
    >
      <div className="flex flex-wrap gap-1">
        {uniqItems.slice(0, MAX_COUNT).map((u) => (
          <EntityTag key={u.id}>{u.name}</EntityTag>
        ))}
        {uniqItems.length > MAX_COUNT && (
          <EntityTag onClick={handleMore}>
            + {uniqItems.slice(MAX_COUNT).length} more
          </EntityTag>
        )}
      </div>
    </SectionField>
  );
};
