import React, { FC, useState } from 'react';
import { useGetInvoicesKanbanQuery } from 'bundles/Construction/api/invoices';
import ApproveInvoiceCard from 'bundles/DrawPackage/InvoicesFlow/Invoices/invoice/kanban/cards/ApproveInvoiceCard';
import styles from 'bundles/DrawPackage/InvoicesFlow/Invoices/invoice/Invoice.module.scss';
import { cn } from '@/shared/lib/css/cn';
import { IconButton } from 'stories/IconButton/IconButton';
import { ILegalEntity } from 'types/ReportTableSegment';
import { IQueryParams } from 'types/Pagination';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import {
  PageParamsProvider,
  PageParamsSearch,
} from 'bundles/Shared/components/pageParams';
import { SidePanel } from 'stories/Modals/Modal/Modal';

interface ICompletedLog extends DialogProps {
  legalEntityCode: ILegalEntity['code'];
}

const CompletedLog: FC<ICompletedLog> = ({ onClose, legalEntityCode }) => {
  const [pageParams, setPageParams] = useState<IQueryParams>();

  const { data: invoice } = useGetInvoicesKanbanQuery({
    legalEntityCode,
    pageParams,
  });

  const completedInvoiceLength = invoice?.completed.items.length;

  return (
    <SidePanel toggle={onClose} size="md" bodyPadding="0rem">
      <PageParamsProvider pageParams={pageParams} setPageParams={setPageParams}>
        <div className="h-full bg-light-5">
          <div className="flex justify-between border-b bg-white px-6 py-5 text-light-5">
            <div className="flex items-center gap-2 text-dark-60">
              <div className="header6-bold">Completed Invoices</div>
              <div>{completedInvoiceLength}</div>
            </div>
            <IconButton
              iconName="close"
              size="l"
              onClick={onClose}
              variant="white"
            />
          </div>
          <div className="bg-white p-6 py-4">
            <PageParamsSearch style={{ width: '100%' }} />
          </div>
          <div
            className={cn(styles.tableBody, 'gap-1.5 flex flex-col px-4 py-2')}
          >
            {invoice?.completed.items.map((invoiceItem) => (
              <ApproveInvoiceCard
                key={invoiceItem.id}
                className="w-[356px]"
                {...invoiceItem}
              />
            ))}
          </div>
        </div>
      </PageParamsProvider>
    </SidePanel>
  );
};

export default CompletedLog;
