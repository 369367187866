import React from 'react';
import { Button } from 'stories/Button/Button';
import { Dropdown } from 'stories/Dropdown/Dropdown';
import { DropdownItem } from 'stories/Dropdown/DropdownItem/DropdownItem';

interface Props {
  actions: {
    label: string;
    handler: () => void;
    hidden?: boolean;
  }[];
}

function ColumnActionsDropdown({
  actions,
  children,
}: React.PropsWithChildren<Props>) {
  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Dropdown
        maxWidth="auto"
        items={actions
          .filter((a) => !a.hidden)
          .map((action) => (
            <DropdownItem key={action.label} onClick={action.handler}>
              {action.label}
            </DropdownItem>
          ))}
      >
        <Button
          variant="secondary"
          iconName="bottom"
          iconPosition="right"
          size="xs"
        >
          {children}
        </Button>
      </Dropdown>
    </div>
  );
}

export default ColumnActionsDropdown;
