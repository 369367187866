import type { IThinTabItem } from '@/stories/Tabs/ThinTabGroup/ThinTabGroup';

export const TEMPLATE_WIDGETS_TAB = {
  label: 'Templates',
  value: 'templates',
  id: 'templates',
  tooltipProps: {
    mainText: 'Widgets from existing Report Builder Templates',
  },
} as const satisfies IThinTabItem;

export const DASHBOARD_WIDGETS_TAB_ID = 'dashboards';

export const OBJECT_LVL_WIDGETS_TAB = {
  label: 'Object Level',
  value: DASHBOARD_WIDGETS_TAB_ID,
  id: DASHBOARD_WIDGETS_TAB_ID,
  tooltipProps: {
    mainText: 'Widgets from existing Object Level dashboards',
  },
} as const satisfies IThinTabItem;

export const EXISTING_WIDGETS_TAB = {
  label: 'Existing',
  value: DASHBOARD_WIDGETS_TAB_ID,
  id: DASHBOARD_WIDGETS_TAB_ID,
} as const satisfies IThinTabItem;

export const NEW_WIDGETS_TAB = {
  label: 'New',
  value: 'new',
  id: 'new',
} as const satisfies IThinTabItem;

export const BASIC_AVAILABLE_WIDGETS_TABS = [
  NEW_WIDGETS_TAB,
  EXISTING_WIDGETS_TAB,
] as const satisfies readonly IThinTabItem[];

export const REPORT_BUILDER_TEMPLATE_AVAILABLE_WIDGETS_TABS = [
  NEW_WIDGETS_TAB,
  OBJECT_LVL_WIDGETS_TAB,
  TEMPLATE_WIDGETS_TAB,
] as const satisfies readonly IThinTabItem[];

export const REPORT_BUILDER_TEMPLATE_EAGLE_EYE_AVAILABLE_WIDGETS_TABS = [
  NEW_WIDGETS_TAB,
  TEMPLATE_WIDGETS_TAB,
] as const satisfies readonly IThinTabItem[];
