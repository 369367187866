import React, { FC } from 'react';
import { Icon } from 'stories/Icon/Icon';
import { Tooltip } from 'stories/Tooltip/Tooltip';
import { cn } from '@/shared/lib/css/cn';
import styles from '@/bundles/Construction/components/Shared/DocumentBadge/DocumentBadge.module.scss';

interface IDocumentBadgeProps {
  titleText: string;
  title: string;
  url: string;
  classNameWrapper?: string;
}

const DocumentBadge: FC<IDocumentBadgeProps> = ({
  titleText,
  title,
  url,
  classNameWrapper,
}) => (
  <Tooltip titleText={titleText}>
    <a
      target="blank"
      href={url}
      className={cn(styles.wrapper, classNameWrapper)}
    >
      <Icon
        className={cn('red', title && styles.icon, !title && 'font-16')}
        iconName="docPdf"
      />
      {title && (
        <span className="secondary-regular text-ellipsis">{title}</span>
      )}
    </a>
  </Tooltip>
);

export default DocumentBadge;
