import CustomTemplateBaseForm, {
  EmailCustomTemplateItems,
} from '@/bundles/Settings/components/EmailSettings/forms/CustomTemplateForm';
import TemplatePreview from '@/bundles/Settings/components/EmailSettings/TemplatePreview';
import { extractVariables } from '@/bundles/Settings/components/EmailSettings/utils/utils';
import {
  settingsEmailsCustomTemplateEnhancedApi as api,
  settingsEmailsCustomTemplateEnhancedApi,
} from '@/entities/emails/customTemplates/api/settingsEmailsCustomTemplateEnhancedApi';
import type {
  EmailCustomTemplate,
  EmailCustomTemplateMutationArgs,
} from '@/entities/emails/customTemplates/model';
import { useDeleteEmailCustomTemplateMutation } from '@/features/emails/custom/deleteTemplate/lib';
import { useNavigateBack } from '@/shared/lib/hooks/navigation';
import useDebounce from '@/shared/lib/hooks/useDebounce';
import { generateUrl, ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import { BuilderUI } from '@/shared/ui/BuilderUI';
import { GrowDiv } from '@/shared/ui/GrowDiv';
import { TabsContextProvider, useTabs } from '@/stories/Tabs/useTabs';
import { EMAIL_BUILDER_ITEMS_TABS as tabs } from '@/widgets/email/custom/template/config';
import {
  getEmailCustomTemplateFormDefaultValues,
  useEmailCustomTemplateFormSchemaForm,
} from '@/widgets/email/custom/template/lib';
import { useNavigate } from '@reach/router';
import { isEmpty } from 'lodash-es';
import { useMemo } from 'react';
import { FormProvider } from 'react-hook-form';
import { AnimationLoader } from 'stories/AnimationLoader/AnimationLoader';
import { Button } from 'stories/Button/Button';

export const EmailCustomTemplateBuilder = ({
  template,
}: {
  template?: EmailCustomTemplate;
}) => {
  // to refactor in FE-3437
  const methods = useEmailCustomTemplateFormSchemaForm(template);
  const formWatch = methods.watch();
  const tabsContext = useTabs(tabs, tabs[0]);
  const { data: variablesData } =
    settingsEmailsCustomTemplateEnhancedApi.useGetEmailCustomTemplateVariablesQuery();
  const variables = variablesData ?? [];

  const sections = formWatch.sections ?? [];
  const fetchUsedVariables = () => {
    return extractVariables(
      sections.map((s) => s?.headerSection?.text).join(''),
      variables.header,
    );
  };

  const usedVariables = useMemo(() => {
    return {
      header: fetchUsedVariables(),
    };
  }, [sections, variables]);

  const templateState = useMemo(
    () =>
      ({
        id: template?.id,
        name: formWatch.name,
        subject: formWatch.subject,
        schedule: formWatch.schedule,
        sections: formWatch.sections ?? [],
        recipients: {
          roles: formWatch.permitted.directRoles ?? [],
          users: formWatch.permitted.directUsers ?? [],
          investmentEntities:
            formWatch.permitted.directInvestmentEntities ?? [],
          tags: formWatch.permitted.directTags ?? [],
        },
        reports: formWatch.reports ?? [],
        usedVariables,
      }) satisfies EmailCustomTemplate & {
        usedVariables: EmailCustomTemplateMutationArgs['usedVariables'];
      },
    [
      methods.watch('name'),
      methods.watch('subject'),
      methods.watch('sections'),
      methods.watch('schedule'),
      methods.watch('reports'),
      methods.watch('permitted'),
      usedVariables,
    ],
  );
  const debouncedTemplate = useDebounce(templateState, 500);

  const { navigateBack } = useNavigateBack({
    fallbackUrl: ROUTES_ROOT.settings.emails.templates.custom.fullPath,
  });
  const navigate = useNavigate();

  const [updateCustomTemplate, { isLoading: isUpdateLoading }] =
    api.useUpdateEmailCustomTemplateMutation();

  const [createCustomTemplate, { isLoading: isCreateLoading }] =
    api.useCreateEmailCustomTemplateMutation();

  const [sendMeCustomTemplate, { isLoading: isSendMeLoading }] =
    api.useSendMeEmailCustomTemplateMutation();

  const { data: templatePreviewData, isLoading: isPreviewLoading } =
    api.usePostEmailCustomTemplatePreviewQuery(
      {
        template: debouncedTemplate,
        usedVariables,
      },
      {
        refetchOnMountOrArgChange: true,
      },
    );
  const templatePreview = templatePreviewData?.body;

  const [handleDeleteTemplate, { isLoading: isDeleteLoading }] =
    useDeleteEmailCustomTemplateMutation({
      shouldNavigateBack: true,
    });

  const isLoading = isUpdateLoading || isDeleteLoading || isCreateLoading;

  const isPreviewReady = () => {
    if (isLoading || isPreviewLoading) return false;

    if (template != null)
      return debouncedTemplate != null && templatePreview != null;

    return true;
  };

  const isSubmitLoading = isUpdateLoading || isCreateLoading;

  const isSendMeDisabled =
    isLoading || templateState == null || isSendMeLoading;

  const isSubmitDisabled =
    isLoading || !methods.formState.isValid || !methods.formState.isDirty;

  const handleSubmit = async () => {
    const params = {
      id: template?.id,
      template: templateState,
      usedVariables: templateState.usedVariables,
    } satisfies EmailCustomTemplateMutationArgs;
    template != null;
    if (template != null) {
      const res = await updateCustomTemplate(params);
      if (
        res != null &&
        !('errors' in res) &&
        'data' in res &&
        'id' in res.data
      ) {
        methods.reset(getEmailCustomTemplateFormDefaultValues(res.data));
      }
    } else {
      const res = await createCustomTemplate(params);
      if (
        res != null &&
        !('errors' in res) &&
        'data' in res &&
        'id' in res.data
      ) {
        navigate(
          generateUrl(
            ROUTES_ROOT.settings.emails.templates.custom.customTemplate.edit
              .fullPath,
            {
              pathParams: { id: res.data.id },
            },
          ),
        );
      }
    }
  };

  const handleSendEmail = () => {
    sendMeCustomTemplate({
      template: templateState,
      usedVariables: templateState.usedHeaderVariables,
    });
  };

  const handleNavigateBack = () => {
    navigateBack();
  };

  return (
    <TabsContextProvider value={tabsContext}>
      <FormProvider {...methods}>
        <BuilderUI>
          <BuilderUI.Settings>
            <BuilderUI.Settings.Header>
              <BuilderUI.Settings.Header.BackIconButton
                disabled={isLoading}
                onClick={handleNavigateBack}
              />
              <BuilderUI.Settings.Header.TitleInfo
                subtitle="Custom Templates"
                title={
                  isEmpty(templateState.name)
                    ? 'Template Name'
                    : templateState.name
                }
              />
              {template != null && (
                <BuilderUI.Settings.Header.Actions>
                  <BuilderUI.Settings.Header.DeleteIconButton
                    disabled={isLoading}
                    onClick={() => handleDeleteTemplate(template.id)}
                    isLoading={isDeleteLoading}
                  />
                </BuilderUI.Settings.Header.Actions>
              )}
            </BuilderUI.Settings.Header>
            <BuilderUI.Settings.Main>
              <BuilderUI.Settings.Main.Basic>
                <CustomTemplateBaseForm />
              </BuilderUI.Settings.Main.Basic>

              <BuilderUI.Settings.WidgetLayout>
                <EmailCustomTemplateItems isLoading={isLoading} />
              </BuilderUI.Settings.WidgetLayout>
            </BuilderUI.Settings.Main>

            <BuilderUI.Settings.Footer>
              <Button onClick={() => handleNavigateBack()}>Cancel</Button>
              <GrowDiv />
              <Button
                disabled={isSendMeDisabled}
                variant="secondary"
                size="m"
                loading={isSendMeLoading}
                onClick={() => handleSendEmail()}
                className="inline-semibold"
              >
                Send me an email
              </Button>
              <Button
                disabled={isSubmitDisabled}
                variant="success"
                loading={isSubmitLoading}
                size="m"
                onClick={() => handleSubmit()}
                className="inline-semibold"
              >
                Save
              </Button>
            </BuilderUI.Settings.Footer>
          </BuilderUI.Settings>
          <BuilderUI.PdfPreview>
            {isPreviewReady() ? (
              <TemplatePreview
                subject={templateState?.subject ?? 'Custom Template'}
                templatePreview={templatePreview}
              />
            ) : (
              <AnimationLoader />
            )}
          </BuilderUI.PdfPreview>
        </BuilderUI>
      </FormProvider>
    </TabsContextProvider>
  );
};
