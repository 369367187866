import { ObjectLegalEntityCheckboxList } from '@/bundles/REport/components/financials/filter/LegalEntitiesFilter';
import { LegalEntity } from '@/entities/core/legalEntity';
import { Button } from '@/stories/Button/Button';
import { Popover } from '@/stories/Popover/Popover';
import { IAsset } from '@/types/Asset';

export const WidgetStateLegalEntities = ({
  context,
  onStateChange,
  state,
  title,
}: {
  state: {
    excludedLegalEntityIds?: LegalEntity['id'][];
  };
  context: {
    assets?: IAsset[];
  };
  title?: string;
  onStateChange: (leId: LegalEntity['id']) => void;
}) => {
  const { assets = [] } = context;
  const { excludedLegalEntityIds = [] } = state;
  return (
    <Popover
      maxWidth={300}
      trigger="click"
      placement="bottom-end"
      hiddenArrow
      appendToBody
      template={
        <div className="flex max-h-[400px] flex-col overflow-y-auto">
          {assets.map((a) => (
            <ObjectLegalEntityCheckboxList
              key={a.id}
              title={title}
              legalEntities={a.legalEntities!}
              objectName={a.name}
              selectedLegalEntities={a.legalEntities!.filter(
                (l) => !excludedLegalEntityIds.includes(l.id),
              )}
              onChange={(_, item) => onStateChange(item?.value as string)}
            />
          ))}
        </div>
      }
    >
      <Button
        variant="secondary"
        size="s"
        iconName="bottom"
        iconPosition="right"
      >
        Legal Entities
      </Button>
    </Popover>
  );
};
