import React, { useEffect, useState } from 'react';
import Button from 'stories/Button/Button';
import Checkbox from 'stories/Checkbox/Checkbox';
import { fetchUsersForReconcileSettings } from 'bundles/Construction/actions';
import { allUserFullNames } from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/workflow/thresholdHelpers';
import { MultilineAlert } from 'stories/MultilineAlert/MultilineAlert';
import { AnimationLoader } from 'stories/AnimationLoader/AnimationLoader';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import {
  selectChangeEvent,
  startApprovalChangeEvent,
} from 'bundles/Construction/reducers/ReconcileChangeEventSlice';
import { useParams } from '@reach/router';

function RequestApprovalForm() {
  const changeEvent = useAppSelector(selectChangeEvent);
  const allUsers = useAppSelector((state) => state.usersForReconcileSettings);
  const dispatch = useAppDispatch();
  const params = useParams();

  const threshold = changeEvent?.threshold;
  const [userAcknowledged, setUserAcknowledged] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(fetchUsersForReconcileSettings());
  }, []);

  const users = allUserFullNames(
    allUsers ?? [],
    threshold.externalApproverIds,
    [threshold.internalApproverId],
  );

  const handleStartApproval = async () => {
    setLoading(true);
    await dispatch(
      startApprovalChangeEvent({
        legalEntityCode: params.legalEntityCode,
        id: params.id,
      }),
    );
    setLoading(false);
  };

  return (
    <div className="">
      <div className="ce-bb-line px-4 py-4">
        <MultilineAlert
          message="Before sending an Approval Request, we recommend discussing this Change Event with your Capital Partner(s)"
          status="attention"
          flexibleWidth
          className="mb-4"
        />
        <div className="request-approval-card">
          <div className="request-approval-card__item request-approval-card__item_line secondary-regular">
            <div>Threshold type:</div>
            <div className="dark-60">{threshold.categoryMessage}</div>
          </div>
          <div className="request-approval-card__item secondary-regular">
            <div>Approvers:</div>
            {!allUsers ? (
              <AnimationLoader />
            ) : (
              <div className="text-dark-60">
                {users.internalUsers && (
                  <div className="mb-s">
                    <div className="secondary-regular mb-2 text-light-60">
                      Internal Approver
                    </div>
                    <div>{users.internalUsers}</div>
                  </div>
                )}
                {users.externalUsers && (
                  <div>
                    <div className="light-60 secondary-regular mb-2">
                      External Approver
                    </div>
                    <div>{users.externalUsers}</div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="ce-bb-line flex px-4 py-4">
        <Checkbox
          checked={userAcknowledged}
          onChange={(e) => setUserAcknowledged(e.target.checked)}
        >
          <div className="label-semibold ml-1 text-light-90">
            I confirm, that Change Event details have been Reviewed
          </div>
        </Checkbox>
      </div>
      <div className="px-2 py-2">
        <div className="mt-2.5 flex justify-center">
          <Button
            variant="success"
            onClick={handleStartApproval}
            iconName={loading ? 'sync' : 'bell'}
            disabled={!userAcknowledged || loading}
            className="w-full"
            iconClasses={loading ? 'form-button-loading' : ''}
          >
            Request Approval
          </Button>
        </div>
      </div>
    </div>
  );
}

export default RequestApprovalForm;
