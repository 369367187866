import React from 'react';
import { getFormattedValueParts } from 'stories/ValueFormatters/utils';

interface Props extends PropsWithClassName {
  formattedValue: string;
  style?: React.CSSProperties;
}

function Fractions({ className, formattedValue, style }: Props) {
  const { fractionsValue } = getFormattedValueParts(formattedValue);
  return (
    <span className={className} style={style}>
      {fractionsValue?.length ? `.${fractionsValue}` : ''}
    </span>
  );
}

export default Fractions;
