import * as d3 from 'd3';
import { getComponentSelector } from '@/stories/TreeView/TreeView';
import type { DatumNode, Selection } from '@/stories/TreeView/types';
import styles from '@/stories/TreeView/TreeView.module.scss';
import {
  ASSET_CLASS_HAS_SET_TEXT,
  getClassificationsTooltipText,
} from 'bundles/Shared/entities/leClasssification';

export const INFO_TOOLTIP_SELECTOR = 'linksPopover';

const MAX_WIDTH = 280;
const MAX_HEIGHT = 300;

interface IInfoTooltipConfig {
  d: d3.HierarchyNode<DatumNode>;
  position: {
    x: number;
    y: number;
  };
  handleManageAssetClasses?: (d: d3.HierarchyNode<DatumNode>) => void;
}

export function InfoTooltip(
  selection: Selection<SVGSVGElement>,
  { handleManageAssetClasses, d, position }: IInfoTooltipConfig,
) {
  d3.select(getComponentSelector(INFO_TOOLTIP_SELECTOR)).remove();

  const linksPopoverGroup = selection
    .append('foreignObject')
    .attr('data-component', INFO_TOOLTIP_SELECTOR)
    .attr('class', styles.linksPopover)
    .attr('width', MAX_WIDTH)
    .attr('height', MAX_HEIGHT)
    .attr('transform', () => `translate(${position.x}, ${position.y})`);

  const linksPopoverChildrenWrapper = linksPopoverGroup
    .append('xhtml:div')
    .attr('class', styles.infoTooltip__wrapper)
    .on('mouseleave', () => {
      // TODO improve this event to fire properly
      d3.select(getComponentSelector(INFO_TOOLTIP_SELECTOR)).remove();
    });

  linksPopoverChildrenWrapper
    .append('xhtml:p')
    .text(ASSET_CLASS_HAS_SET_TEXT)
    .attr('class', 'inline-semibold w-full');

  linksPopoverChildrenWrapper
    .append('xhtml:p')
    .text(getClassificationsTooltipText(d.data.classifications))
    .attr('class', 'secondary-regular w-full');

  handleManageAssetClasses &&
    linksPopoverChildrenWrapper
      .append('xhtml:p')
      .text('Manage')
      .attr('class', 'text-info-055 inline-semibold cursor-pointer')
      .on('click', () => {
        handleManageAssetClasses?.(d.data);
      });

  d3.select('body').on('click', (e) => {
    const outside = d3
      .select(getComponentSelector(INFO_TOOLTIP_SELECTOR))
      .filter(function (_e, _a) {
        return this == e.targets;
      })
      .empty();

    if (outside) {
      const linksPopover = d3.select(
        getComponentSelector(INFO_TOOLTIP_SELECTOR),
      );
      linksPopover.remove();
    }
  });
}

export default InfoTooltip;
