import React from 'react';
import { formatUnixDateFromNow } from '@/shared/lib/formatting/dates';
import { Icon } from 'stories/Icon/Icon';
import { Link } from '@reach/router';
import { INotification } from 'types/Notification';

interface Props {
  viewAll?: boolean;
  notifications: INotification[];
}

const NotificationsList = ({ notifications, viewAll }: Props) => {
  if (!notifications) return null;

  return (
    <>
      {notifications.map((notification) => (
        <Link
          key={notification.id}
          to="/notifications"
          className="list-card__list-item inline-regular"
        >
          <div className="flex justify-between w-full">
            <span className="font-weight-600 dark-60">
              {notification.actor.fullName}
            </span>
            <div className="light-60">
              {formatUnixDateFromNow(notification.createdAt)}
            </div>
          </div>
          <div className="light-90">{notification.text}</div>
        </Link>
      ))}
      {!notifications.length && (
        <div
          style={{
            height: 260,
          }}
          className="flex justify-center flex-col items-center gap-s"
        >
          <Icon iconName="bell" className="notifications-icon" />
          <p>Nothing here yet</p>
        </div>
      )}
      {viewAll && (
        <Link
          to="/notifications"
          className="sre-link list-card__list-item left-sidebar__card-bottom pb-[0.5rem] text-center"
        >
          View all
        </Link>
      )}
    </>
  );
};

export default NotificationsList;
