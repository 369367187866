import React, { useEffect } from 'react';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import impersonationConfirm from '@/entities/impersonation/ui/impersonationConfirm';
import { useModal } from '@/shared/lib/hooks/useModal';
import AdviseeCardList from '@/bundles/AdvisorDashboard/components/AdviseeCardList';
import { navigate } from '@reach/router';

interface Props {
  advisorId: string | number;
}

const AdvisorDashboardContainer = ({ advisorId }: Props) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const redirectUrl = urlParams.get('redirect_url');
  const user = {
    id: urlParams.get('user[id]'),
    fullName: urlParams.get('user[fullName]'),
  };
  const { confirm } = useModal();

  useEffect(() => {
    if (redirectUrl && user.id) {
      impersonationConfirm({
        user,
        redirectUrl,
        onClose: () => navigate(window.location.pathname),
        confirm,
      });
    }
  }, []);

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="custom-container advisor-dashboard__container">
        <h1 className="dark-60 font-weight-600">Advisees</h1>
        <p className="light-60 mt-4">
          Select your advisee from the list below to continue
        </p>
        <AdviseeCardList advisorId={advisorId} />
      </div>
    </DndProvider>
  );
};

export default AdvisorDashboardContainer;
