import { cn } from '@/shared/lib/css/cn';
import { IHeaderParams } from 'ag-grid-community';
import { HeaderSortButton } from 'bundles/Shared/components/AgGrid/Table/cellComponents/HeaderComponent';
import React, { useState } from 'react';
import { CELL_CLASS_NAMES } from '@/bundles/Shared/components/AgGrid/Table/classNames';
import { IconButton } from '@/stories/IconButton/IconButton';

export function AutoGroupHeaderComponent({
  subHeaderName,
  displayName,
  classes,
  style,
  ...params
}: IHeaderParams & {
  style?: React.CSSProperties;
} & {
  subHeaderName: string;
  enableExpand?: boolean;
  classes?: {
    wrapper?: string;
    inner?: string;
    subHeader?: string;
    header?: string;
  };
}) {
  const [isExpanded, setIsExpanded] = useState(false);
  const handleExpand = () => {
    if (isExpanded) {
      params.api.collapseAll();
    } else {
      params.api.expandAll();
    }
    setIsExpanded(!isExpanded);
  };
  return (
    <div
      className={cn(
        CELL_CLASS_NAMES.AutoGroupHeader.wrapper.default,
        classes?.wrapper,
      )}
      style={style}
    >
      <div
        className={cn(
          CELL_CLASS_NAMES.AutoGroupHeader.inner.default,
          classes?.inner,
          params.enableSorting && '!flex-row items-center !justify-between',
        )}
      >
        <div className="flex flex-col">
          <p className={cn('inline-regular', classes?.header)}>{displayName}</p>
          <p
            className={cn(
              'inline-semibold text-neutral-000',
              classes?.subHeader,
            )}
          >
            {subHeaderName}
          </p>
        </div>
        {params.context?.mode !== 'pdf' && (
          <div className="flex items-center gap-2">
            {params.enableExpand && (
              <IconButton
                onClick={handleExpand}
                variant="ghost"
                iconName={isExpanded ? 'doubleArrowBottom' : 'doubleArrowTop'}
              />
            )}
            {params.enableSorting && (
              <HeaderSortButton displayName={displayName} {...params} />
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export function AutoGroupCustomHeaderComponent({
  customComponent,
}: IHeaderParams & {
  customComponent: string;
}) {
  return (
    <div className={cn(CELL_CLASS_NAMES.AutoGroupHeader.wrapper.default)}>
      <div className={cn(CELL_CLASS_NAMES.AutoGroupHeader.inner.default)}>
        {customComponent}
      </div>
    </div>
  );
}
