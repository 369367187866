import { ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import { useAppNavigate } from '@/shared/lib/navigation/useAppNavigate';
import http from 'lib/http';
import { useEffect, useState } from 'react';
import EmailTemplateCard from '@/bundles/Settings/components/EmailSettings/EmailTemplateCard';

const ForgotPasswordTemplatesTab = () => {
  const [templates, setTemplates] = useState([]);

  const fetchForgotPasswordTemplates = () =>
    http.get('/forgot_password_templates').then((res) => res.json());

  useEffect(() => {
    fetchForgotPasswordTemplates().then((data) => setTemplates(data));
  }, []);

  const navigate = useAppNavigate();

  const handleNavigate = (id: number) => {
    navigate(
      ROUTES_ROOT.settings.emails.templates.forgotPassword.forgotPassword.edit
        .fullPath,
      {
        pathParams: {
          forgotPasswordTemplateId: String(id),
        },
      },
    );
  };

  const defaultTemplate = templates.find((it) => it.default);

  // TODO: create shared component for default template
  return (
    <div>
      <h5 className="light-60 font-weight-500 header5-regular mb-4 mt-[1.5rem]">
        Default Template
      </h5>
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3 mb-30">
          {defaultTemplate && (
            <EmailTemplateCard
              isDefault
              id={defaultTemplate.id}
              name={defaultTemplate.name}
              onNavigate={() => handleNavigate(defaultTemplate.id)}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordTemplatesTab;
