import { useAmchart } from 'lib/amcharts/useAmchart';
import React, { useRef, useState } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themesAnimated from '@amcharts/amcharts5/themes/Animated';
import {
  GetApiSettingsUserManagementUserActivitiesSummaryApiArg,
  useGetApiSettingsUserManagementUserActivitiesSummaryQuery,
} from 'bundles/Shared/entities/user/api/userActivityApi';
import { useAppSelector } from '@/shared/lib/hooks/redux';
import { selectUserActivitySummary } from 'bundles/Shared/entities/user/slices/userActivitySlice';
import {
  addDateXAxis,
  chartDateMapper,
  conditionallyShowRange,
  getReturnDashboardTheme,
} from 'lib/amcharts/utils';
import { StreamlinedContainer } from '@/stories/StreamlinedContainer/StreamlinedContainer';
import { Tumbler } from '@/stories/Tumbler/Tumbler';
import { formatToDateStringForRequest } from '@/shared/lib/converters';

export const UserActivitySessionChart = () => {
  const { fromDate, toDate, roleIds } = useAppSelector(
    selectUserActivitySummary,
  );
  const [periodRangeType, setPeriodRangeType] = useState<'day' | 'week'>('day');

  const { data: summary } =
    useGetApiSettingsUserManagementUserActivitiesSummaryQuery({
      fromDate: formatToDateStringForRequest(fromDate),
      toDate: formatToDateStringForRequest(toDate),
      userRoleIds:
        roleIds as GetApiSettingsUserManagementUserActivitiesSummaryApiArg['userRoleIds'],
    });

  const sessions = summary?.sessions ?? [];

  const ref = useRef(null);

  useAmchart(
    ref,
    (root) => {
      if (!sessions) {
        return;
      }
      const mappedSessions = sessions.map(chartDateMapper('period'));

      const hasInternalPeriods = sessions.find((session) => session.internal);
      const hasExternalPeriods = sessions.find((session) => session.external);

      const COLOR_SET = [am5.color('#0092EE'), am5.color('#9EA4B1')];

      const myTheme = getReturnDashboardTheme(root);
      myTheme.rule('ColorSet').set('colors', COLOR_SET);
      root.setThemes([am5themesAnimated.new(root), myTheme]);

      const chart = root.container.children.push(
        am5xy.XYChart.new(root, {
          panX: false,
          panY: false,
          wheelX: 'panX',
          wheelY: 'zoomX',
          layout: root.verticalLayout,
        }),
      );

      const legend = chart.children.push(
        am5.Legend.new(root, {
          centerX: am5.p50,
          x: am5.p50,
        }),
      );

      const { xAxis } = addDateXAxis(root, chart, {
        baseInterval: { timeUnit: periodRangeType, count: 1 },
      });
      xAxis.data.setAll(mappedSessions);

      const yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          renderer: am5xy.AxisRendererY.new(root, {
            strokeOpacity: 0.1,
          }),
        }),
      );

      function makeSeries(name, fieldName) {
        const series = chart.series.push(
          am5xy.ColumnSeries.new(root, {
            name,
            xAxis,
            yAxis,
            valueYField: fieldName,
            valueXField: 'period',
          }),
        );

        series.columns.template.setAll({
          tooltipText: conditionallyShowRange(periodRangeType == 'week'),
          width: am5.percent(90),
          tooltipY: 0,
          strokeOpacity: 0,
          cornerRadiusTL: 4,
          cornerRadiusTR: 4,
        });

        series.data.setAll(mappedSessions);

        series.appear();

        series.bullets.push(function () {
          return am5.Bullet.new(root, {
            locationY: 0,
            sprite: am5.Label.new(root, {
              text: '{valueY}',
              fill: root.interfaceColors.get('alternativeText'),
              centerY: 0,
              centerX: am5.p50,
              populateText: true,
            }),
          });
        });

        legend.data.push(series);
      }

      hasInternalPeriods && makeSeries('Internal', 'internal');
      hasExternalPeriods && makeSeries('External', 'external');

      chart.appear(1000, 100);
    },
    [sessions, periodRangeType],
  );

  return (
    <StreamlinedContainer
      classes={{
        title: '!header5-regular',
      }}
      title="Sessions"
      actions={
        <Tumbler
          checked={periodRangeType == 'week'}
          onChange={() =>
            setPeriodRangeType(periodRangeType == 'day' ? 'week' : 'day')
          }
        >
          Group by week
        </Tumbler>
      }
    >
      <div className="h-[350px]" ref={ref} />
    </StreamlinedContainer>
  );
};

export default UserActivitySessionChart;
