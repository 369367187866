import { plainFetchCapitalInvestmentAccountingTransactions } from 'bundles/REturn/actions/capitalInvestments';
import { ReportLineItem } from 'bundles/REturn/actions/types';
import { useEffect, useState } from 'react';
import { IPeriodItem } from 'stories/FlexibleFilterByPeriods/types';
import { IGeneralLedger } from 'types/GeneralLedger';
import { LegalEntity } from '@/entities/core/legalEntity';
import { IPaginationMeta } from 'types/Pagination';
import { PageParams } from '@/bundles/REturn/components/Ownership/modals/linkTxModals/linkTxToManuallyAdded/types';

import { convertDollarsToCents } from '@/shared/lib/converters';

interface Params {
  pageParams: PageParams;
  periods: IPeriodItem['period'][];
  query: string;
  selectedGLs: Pick<
    IGeneralLedger,
    | 'id'
    | 'excluded'
    | 'accNumber'
    | 'accTitle'
    | 'lineItemsCount'
    | 'reportFinancialCategoryId'
  >[];
  selectedLegalEntities: Pick<LegalEntity, 'code' | 'source' | 'name' | 'id'>[];
}
export function useTransactions({
  query,
  periods: periods,
  pageParams,
  selectedGLs,
  selectedLegalEntities,
}: Params) {
  const [loading, setLoading] = useState(false);
  const [transactions, setTransactions] = useState<ReportLineItem[]>([]);
  const [paginationMeta, setPaginationMeta] = useState<IPaginationMeta>({
    perPage: 0,
    totalSize: 0,
  });

  const loadTransactions = async () => {
    setLoading(true);
    const data = await plainFetchCapitalInvestmentAccountingTransactions({
      query,
      legalEntityIds: selectedLegalEntities.map(({ id }) => id),
      generalLedgerIds: selectedGLs.map(({ id }) => id),
      fromDate: periods[0],
      toDate: periods.at(-1)!,
      ...pageParams,
      amount: {
        from: pageParams.amount?.to
          ? convertDollarsToCents(pageParams.amount?.from)
          : undefined,
        to: pageParams.amount?.to
          ? convertDollarsToCents(pageParams.amount?.to)
          : undefined,
      },
    });
    setTransactions(data.items);
    setPaginationMeta(data.meta);
    setLoading(false);
  };

  useEffect(() => {
    loadTransactions();
  }, [query, pageParams, selectedGLs, periods]);

  return {
    loading,
    loadTransactions,
    transactions,
    paginationMeta,
  };
}
