import React, { FC, SetStateAction } from 'react';
import {
  IChangeOrder,
  IHardCostCode,
  IReallocation,
} from 'bundles/Construction/types';
import SelectCOItem from '@/bundles/Construction/components/Reallocation/Modals/components/SelectCOItem';

interface ISelectCOProps {
  data: IChangeOrder[];
  formData: IReallocation[];
  setFormData: SetStateAction<IChangeOrder>;
  selectable: boolean;
  hardConstructionCost: IHardCostCode;
  hardCostContingency: IHardCostCode;
}

const SelectCO: FC<ISelectCOProps> = ({
  data,
  formData,
  setFormData,
  selectable,
  hardConstructionCost,
  hardCostContingency,
}) => (
  <div className="select-change-orders">
    {data.map((item, idx) => (
      <SelectCOItem
        key={`reallocation${idx}`}
        data={data}
        formData={formData}
        setFormData={setFormData}
        idx={idx}
        selectable={selectable}
        hardConstructionCost={hardConstructionCost}
        hardCostContingency={hardCostContingency}
        {...item}
      />
    ))}
  </div>
);

export default SelectCO;
