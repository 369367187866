import { useCallback, useMemo, useState } from 'react';
import { SearchInput } from 'stories/FormControls/Inputs/SearchInput/SearchInput';
import { debounce, isEmpty } from 'lodash-es';
import { includesInLowerCase } from '@/shared/lib/listHelpers';

export const useItemsFilterByText = <
  T extends Record<string, unknown> | string,
>(
  items: T[],
  filterBy: ((item: T, searchText: string, items: T[]) => boolean) | keyof T,
  options: {
    debounce?: number;
    skipWhenTextIsEmpty?: boolean;
  } = {
    debounce: 500,
    skipWhenTextIsEmpty: true,
  },
) => {
  const [searchText, setSearchText] = useState('');

  const onChange = useCallback(
    debounce((e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchText(e.target.value);
    }, options.debounce),
    [],
  );

  const inputProps: Pick<
    React.ComponentProps<typeof SearchInput>,
    'defaultValue' | 'onChange'
  > = {
    defaultValue: searchText,
    onChange,
  };

  const filteredItems = useMemo(() => {
    if (isEmpty(searchText) && options.skipWhenTextIsEmpty) {
      return items;
    }
    const filterPredicate =
      typeof filterBy === 'function'
        ? filterBy
        : (item: T) =>
            includesInLowerCase(
              typeof item === 'string' ? item : (item[filterBy] as string),
              searchText,
            );
    return items.filter((item) => filterPredicate(item, searchText, items));
  }, [searchText, items, filterBy]);

  return {
    searchText,
    setSearchText,
    inputProps,
    filteredItems,
  };
};
