import { emptySplitApi as api } from '@/app/stores/api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    postApiSettingsReportThirdPartyExtractionsConnectionsByConnectionIdAttachmentsParse:
      build.mutation<
        PostApiSettingsReportThirdPartyExtractionsConnectionsByConnectionIdAttachmentsParseApiResponse,
        PostApiSettingsReportThirdPartyExtractionsConnectionsByConnectionIdAttachmentsParseApiArg
      >({
        query: (queryArg) => ({
          url: `/api/settings/report/third_party_extractions/connections/${queryArg.connectionId}/attachments/parse`,
          method: 'POST',
          body: queryArg.body,
        }),
      }),
    deleteApiSettingsReportThirdPartyExtractionsConnectionsByConnectionIdAttachmentsAndId:
      build.mutation<
        DeleteApiSettingsReportThirdPartyExtractionsConnectionsByConnectionIdAttachmentsAndIdApiResponse,
        DeleteApiSettingsReportThirdPartyExtractionsConnectionsByConnectionIdAttachmentsAndIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/settings/report/third_party_extractions/connections/${queryArg.connectionId}/attachments/${queryArg.id}`,
          method: 'DELETE',
          body: queryArg.body,
        }),
      }),
    getApiSettingsReportThirdPartyExtractionsConnections: build.query<
      GetApiSettingsReportThirdPartyExtractionsConnectionsApiResponse,
      GetApiSettingsReportThirdPartyExtractionsConnectionsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/report/third_party_extractions/connections`,
        params: { active: queryArg.active },
      }),
    }),
    postApiSettingsReportThirdPartyExtractionsConnections: build.mutation<
      PostApiSettingsReportThirdPartyExtractionsConnectionsApiResponse,
      PostApiSettingsReportThirdPartyExtractionsConnectionsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/report/third_party_extractions/connections`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    getApiSettingsReportThirdPartyExtractionsConnectionsById: build.query<
      GetApiSettingsReportThirdPartyExtractionsConnectionsByIdApiResponse,
      GetApiSettingsReportThirdPartyExtractionsConnectionsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/report/third_party_extractions/connections/${queryArg.id}`,
      }),
    }),
    putApiSettingsReportThirdPartyExtractionsConnectionsById: build.mutation<
      PutApiSettingsReportThirdPartyExtractionsConnectionsByIdApiResponse,
      PutApiSettingsReportThirdPartyExtractionsConnectionsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/report/third_party_extractions/connections/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    deleteApiSettingsReportThirdPartyExtractionsConnectionsById: build.mutation<
      DeleteApiSettingsReportThirdPartyExtractionsConnectionsByIdApiResponse,
      DeleteApiSettingsReportThirdPartyExtractionsConnectionsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/report/third_party_extractions/connections/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    getApiSettingsReportThirdPartyExtractionsConnectionsByConnectionIdEmailExtractions:
      build.query<
        GetApiSettingsReportThirdPartyExtractionsConnectionsByConnectionIdEmailExtractionsApiResponse,
        GetApiSettingsReportThirdPartyExtractionsConnectionsByConnectionIdEmailExtractionsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/settings/report/third_party_extractions/connections/${queryArg.connectionId}/email_extractions`,
          params: {
            created_at_range: queryArg.createdAtRange,
            page: queryArg.page,
            per_page: queryArg.perPage,
            email_sender: queryArg.emailSender,
            query: queryArg.query,
          },
        }),
      }),
    deleteApiSettingsReportThirdPartyExtractionsConnectionsByConnectionIdEmailExtractionsAndId:
      build.mutation<
        DeleteApiSettingsReportThirdPartyExtractionsConnectionsByConnectionIdEmailExtractionsAndIdApiResponse,
        DeleteApiSettingsReportThirdPartyExtractionsConnectionsByConnectionIdEmailExtractionsAndIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/settings/report/third_party_extractions/connections/${queryArg.connectionId}/email_extractions/${queryArg.id}`,
          method: 'DELETE',
        }),
      }),
    getApiSettingsReportThirdPartyExtractionsConnectionsByConnectionIdManualUploads:
      build.query<
        GetApiSettingsReportThirdPartyExtractionsConnectionsByConnectionIdManualUploadsApiResponse,
        GetApiSettingsReportThirdPartyExtractionsConnectionsByConnectionIdManualUploadsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/settings/report/third_party_extractions/connections/${queryArg.connectionId}/manual_uploads`,
          params: {
            uploader: queryArg.uploader,
            query: queryArg.query,
            created_at_range: queryArg.createdAtRange,
            page: queryArg.page,
            per_page: queryArg.perPage,
          },
        }),
      }),
    postApiSettingsReportThirdPartyExtractionsConnectionsByConnectionIdManualUploads:
      build.mutation<
        PostApiSettingsReportThirdPartyExtractionsConnectionsByConnectionIdManualUploadsApiResponse,
        PostApiSettingsReportThirdPartyExtractionsConnectionsByConnectionIdManualUploadsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/settings/report/third_party_extractions/connections/${queryArg.connectionId}/manual_uploads`,
          method: 'POST',
          body: queryArg.body,
        }),
      }),
    deleteApiSettingsReportThirdPartyExtractionsConnectionsByConnectionIdManualUploadsAndId:
      build.mutation<
        DeleteApiSettingsReportThirdPartyExtractionsConnectionsByConnectionIdManualUploadsAndIdApiResponse,
        DeleteApiSettingsReportThirdPartyExtractionsConnectionsByConnectionIdManualUploadsAndIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/settings/report/third_party_extractions/connections/${queryArg.connectionId}/manual_uploads/${queryArg.id}`,
          method: 'DELETE',
        }),
      }),
    getApiSettingsReportThirdPartyExtractionsConnectionsByConnectionIdSftpExtractions:
      build.query<
        GetApiSettingsReportThirdPartyExtractionsConnectionsByConnectionIdSftpExtractionsApiResponse,
        GetApiSettingsReportThirdPartyExtractionsConnectionsByConnectionIdSftpExtractionsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/settings/report/third_party_extractions/connections/${queryArg.connectionId}/sftp_extractions`,
          params: {
            created_at_range: queryArg.createdAtRange,
            page: queryArg.page,
            per_page: queryArg.perPage,
            query: queryArg.query,
          },
        }),
      }),
    deleteApiSettingsReportThirdPartyExtractionsConnectionsByConnectionIdSftpExtractionsAndId:
      build.mutation<
        DeleteApiSettingsReportThirdPartyExtractionsConnectionsByConnectionIdSftpExtractionsAndIdApiResponse,
        DeleteApiSettingsReportThirdPartyExtractionsConnectionsByConnectionIdSftpExtractionsAndIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/settings/report/third_party_extractions/connections/${queryArg.connectionId}/sftp_extractions/${queryArg.id}`,
          method: 'DELETE',
        }),
      }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as settingsReportConnectionsGeneratedApi };
export type PostApiSettingsReportThirdPartyExtractionsConnectionsByConnectionIdAttachmentsParseApiResponse =
  unknown;
export type PostApiSettingsReportThirdPartyExtractionsConnectionsByConnectionIdAttachmentsParseApiArg =
  {
    connectionId: number;
    body: {
      attachment_ids?: number[];
      attachments_scope?: 'all' | 'not_parsed';
      manual_attachments?: boolean;
    };
  };
export type DeleteApiSettingsReportThirdPartyExtractionsConnectionsByConnectionIdAttachmentsAndIdApiResponse =
  unknown;
export type DeleteApiSettingsReportThirdPartyExtractionsConnectionsByConnectionIdAttachmentsAndIdApiArg =
  {
    connectionId: number;
    id: string;
    body: {
      manual_attachments?: boolean;
    };
  };
export type GetApiSettingsReportThirdPartyExtractionsConnectionsApiResponse =
  /** status 200 success */ {
    companies: {
      companyName: string;
      connections: ReportThirdPartyExtractionConnectionDto[];
    }[];
    meta: {
      connectorTypes: string[];
      reportTypes: string[];
      totalSize: number;
    };
  };
export type GetApiSettingsReportThirdPartyExtractionsConnectionsApiArg = {
  active?: 'true' | 'false';
};
export type PostApiSettingsReportThirdPartyExtractionsConnectionsApiResponse =
  /** status 200 success */ ReportThirdPartyExtractionConnectionDetailsDto;
export type PostApiSettingsReportThirdPartyExtractionsConnectionsApiArg = {
  body: {
    name: string;
    company_name: string;
    report_type: 'financial' | 'operational' | 'combined' | 'market_data';
    erp_system:
      | 'yardi'
      | 'entrata'
      | 'quickbooks_desktop'
      | 'quickbooks_online'
      | 'resman'
      | 'm3'
      | 'mri'
      | 'cyma'
      | 'fortress'
      | 'app_folio'
      | 'realpage'
      | 'sage'
      | 'rentegi'
      | 'net_suite'
      | 'rasi'
      | 'xero'
      | 'rent_manager'
      | 'buildium'
      | 'propertyware'
      | 'rentvine'
      | 'zoho'
      | 'microsoft_dynamics_365'
      | 'acumatica'
      | 'infor'
      | 'radix';
    active: boolean;
    email_connection_attributes?: {
      email_postfix: string;
    };
    sftp_connection_attributes?: {
      username: string;
      password: string;
      host: string;
      folder_path: string;
    };
  };
};
export type GetApiSettingsReportThirdPartyExtractionsConnectionsByIdApiResponse =
  /** status 200 success */ {
    item: ReportThirdPartyExtractionConnectionDetailsDto;
    meta: {
      connectorTypes: string[];
      reportTypes: string[];
    };
  };
export type GetApiSettingsReportThirdPartyExtractionsConnectionsByIdApiArg = {
  id: number;
};
export type PutApiSettingsReportThirdPartyExtractionsConnectionsByIdApiResponse =
  /** status 200 success */ ReportThirdPartyExtractionConnectionDetailsDto;
export type PutApiSettingsReportThirdPartyExtractionsConnectionsByIdApiArg = {
  id: number;
  body: {
    name?: string;
    report_type?: 'financial' | 'operational' | 'combined' | 'market_data';
    active?: boolean;
    access_note?: string;
    connection_updated_at?: DateString;
    memo_attributes?: {
      message?: string;
      _destroy?: boolean;
    };
  };
};
export type DeleteApiSettingsReportThirdPartyExtractionsConnectionsByIdApiResponse =
  unknown;
export type DeleteApiSettingsReportThirdPartyExtractionsConnectionsByIdApiArg =
  {
    id: number;
  };
export type GetApiSettingsReportThirdPartyExtractionsConnectionsByConnectionIdEmailExtractionsApiResponse =
  /** status 200 success */ {
    items: ReportThirdPartyExtractionEmailConnectionInboundEmailDto[];
    meta: PaginationDto & {
      emailSenders: string[];
    };
  };
export type GetApiSettingsReportThirdPartyExtractionsConnectionsByConnectionIdEmailExtractionsApiArg =
  {
    connectionId: number;
    createdAtRange?: {
      from?: string;
      to?: string;
    };
    page?: number;
    perPage?: number;
    emailSender?: string;
    query?: string;
  };
export type DeleteApiSettingsReportThirdPartyExtractionsConnectionsByConnectionIdEmailExtractionsAndIdApiResponse =
  unknown;
export type DeleteApiSettingsReportThirdPartyExtractionsConnectionsByConnectionIdEmailExtractionsAndIdApiArg =
  {
    connectionId: number;
    id: string;
  };
export type GetApiSettingsReportThirdPartyExtractionsConnectionsByConnectionIdManualUploadsApiResponse =
  /** status 200 success */ {
    items: ReportThirdPartyExtractionConnectionManualUploadDto[];
    meta: PaginationDto & {
      uploaders: {
        id: string;
        name: string;
      }[];
    };
  };
export type GetApiSettingsReportThirdPartyExtractionsConnectionsByConnectionIdManualUploadsApiArg =
  {
    connectionId: number;
    uploader?: number;
    query?: string;
    createdAtRange?: {
      from?: string;
      to?: string;
    };
    page?: number;
    perPage?: number;
  };
export type PostApiSettingsReportThirdPartyExtractionsConnectionsByConnectionIdManualUploadsApiResponse =
  unknown;
export type PostApiSettingsReportThirdPartyExtractionsConnectionsByConnectionIdManualUploadsApiArg =
  {
    connectionId: number;
    body: {
      files: {
        key: string;
        filename: string;
        content_type: string;
        size: number;
      }[];
    };
  };
export type DeleteApiSettingsReportThirdPartyExtractionsConnectionsByConnectionIdManualUploadsAndIdApiResponse =
  unknown;
export type DeleteApiSettingsReportThirdPartyExtractionsConnectionsByConnectionIdManualUploadsAndIdApiArg =
  {
    connectionId: number;
    id: string;
  };
export type GetApiSettingsReportThirdPartyExtractionsConnectionsByConnectionIdSftpExtractionsApiResponse =
  /** status 200 success */ {
    items: ReportThirdPartyExtractionSftpConnectionSyncDto[];
    meta: PaginationDto;
  };
export type GetApiSettingsReportThirdPartyExtractionsConnectionsByConnectionIdSftpExtractionsApiArg =
  {
    connectionId: number;
    createdAtRange?: {
      from?: string;
      to?: string;
    };
    page?: number;
    perPage?: number;
    query?: string;
  };
export type DeleteApiSettingsReportThirdPartyExtractionsConnectionsByConnectionIdSftpExtractionsAndIdApiResponse =
  unknown;
export type DeleteApiSettingsReportThirdPartyExtractionsConnectionsByConnectionIdSftpExtractionsAndIdApiArg =
  {
    connectionId: number;
    id: string;
  };
export type ReportThirdPartyExtractionConnectionDto = {
  id: number;
  name: string;
  reportType: 'financial' | 'operational' | 'combined' | 'market_data';
  erpSystem:
    | 'yardi'
    | 'entrata'
    | 'quickbooks_desktop'
    | 'quickbooks_online'
    | 'resman'
    | 'm3'
    | 'mri'
    | 'cyma'
    | 'fortress'
    | 'app_folio'
    | 'realpage'
    | 'sage'
    | 'net_suite'
    | 'rent_manager'
    | 'buildium'
    | 'propertyware'
    | 'rentvine'
    | 'zoho'
    | 'microsoft_dynamics_365'
    | 'acumatica'
    | 'infor'
    | 'radix';
  connectionStatus: 'active' | 'inactive' | 'deactivated' | 'corrupted';
  parserStatus: 'active' | 'error' | 'empty';
  lastParserError: string | null;
  extractionCount: number;
  type: 'email' | 'sftp';
  emailAddress: string | null;
};
export type PropertyManagementCompanyContactPersonDto = {
  name: string | null;
  role: string | null;
  email: string | null;
  phone: string | null;
};
export type PropertyManagementCompanyKeyInfoDto = {
  address: string | null;
  site: string | null;
  email: string | null;
  phone: string | null;
};
export type PropertyManagementCompanyDto = {
  name: string;
  contactPerson: PropertyManagementCompanyContactPersonDto | null;
  keyInfo: PropertyManagementCompanyKeyInfoDto | null;
};
export type ReportThirdPartyExtractionConnectionMemoDto = {
  id: string;
  message: string;
  authorName: string;
  writtenAt: number;
};
export type ReportThirdPartyExtractionConnectionDetailsDto = {
  id: number;
  name: string;
  companyName: string;
  company?: PropertyManagementCompanyDto;
  reportType: 'financial' | 'operational' | 'combined' | 'market_data';
  erpSystem:
    | 'yardi'
    | 'entrata'
    | 'quickbooks_desktop'
    | 'quickbooks_online'
    | 'resman'
    | 'm3'
    | 'mri'
    | 'cyma'
    | 'fortress'
    | 'app_folio'
    | 'realpage'
    | 'sage'
    | 'net_suite'
    | 'rent_manager'
    | 'buildium'
    | 'propertyware'
    | 'rentvine'
    | 'zoho'
    | 'microsoft_dynamics_365'
    | 'acumatica'
    | 'infor'
    | 'radix';
  connectionStatus: 'active' | 'inactive' | 'deactivated' | 'corrupted';
  parserStatus: 'active' | 'empty';
  type: 'email';
  emailAddress: string | null;
  createdAt: number;
  lastReceivedAt: number | null;
  lastParserLaunchAt: number | null;
  memo: ReportThirdPartyExtractionConnectionMemoDto | null;
};
export type ReportThirdPartyExtractionEmailConnectionInboundEmailAttachmentDto =
  {
    id: string;
    title: string;
    name: string;
    size: number;
    createdAt: number;
    fileUrl: string;
    extension: string;
    status: ('processing' | 'succeeded' | 'failed' | 'skipped') | null;
    error: string | null;
    canBeDeleted: boolean;
  };
export type ReportThirdPartyExtractionEmailConnectionInboundEmailDto = {
  id: string;
  receivedAt: number;
  parsedAt: number | null;
  status: ('error' | 'processed' | 'processing') | null;
  emailSender: string;
  subject: string;
  canBeDeleted: boolean;
  attachments: ReportThirdPartyExtractionEmailConnectionInboundEmailAttachmentDto[];
};
export type PaginationDto = {
  perPage: number;
  totalSize: number;
};
export type ReportThirdPartyExtractionConnectionManualUploadAttachmentDto = {
  id: string;
  name: string;
  size: number;
  createdAt: number;
  fileUrl: string;
  extension: string;
  status: ('processing' | 'succeeded' | 'failed' | 'skipped') | null;
  error: string | null;
  canBeDeleted: boolean;
};
export type ReportThirdPartyExtractionConnectionManualUploadDto = {
  id: string;
  createdAt: number;
  parsedAt: number | null;
  uploaderName: string;
  status: ('error' | 'processed' | 'processing') | null;
  canBeDeleted: boolean;
  attachments: ReportThirdPartyExtractionConnectionManualUploadAttachmentDto[];
};
export type ReportThirdPartyExtractionSftpConnectionSyncAttachmentDto = {
  id: string;
  name: string;
  size: number;
  createdAt: number;
  fileUrl: string;
  extension: string;
  status: ('processing' | 'succeeded' | 'failed' | 'skipped') | null;
  error: string | null;
  canBeDeleted: boolean;
};
export type ReportThirdPartyExtractionSftpConnectionSyncDto = {
  id: string;
  syncedAt: number;
  parsedAt: number | null;
  status: ('error' | 'processed' | 'processing') | null;
  attachments: ReportThirdPartyExtractionSftpConnectionSyncAttachmentDto[];
  canBeDeleted: boolean;
};
