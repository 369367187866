import { getRadioGroupItemPropsHandler } from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/getRadioGroupItemPropsHandler';
import {
  DATE_FORMAT_VALUE_OPTIONS,
  NUMERIC_VALUE_TYPES,
  PRECISION_OPTIONS,
  VALUE_TYPE_OPTIONS,
  ValueDisplayOptionsForm,
  ValueDisplayType,
} from '@/shared/lib/formatting/displayOptions';
import { ResetButton } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/fields/StylingFields';
import { Control, useFormContext } from 'react-hook-form';
import { FieldValues } from 'react-hook-form/dist/types';
import { FieldPath } from 'react-hook-form/dist/types/path/eager';
import {
  SectionField,
  SectionFieldsContainer,
} from 'stories/Field/FieldsWrappers';
import { RadioGroupController } from 'stories/RadioButton/RadioGroup';
import { TumblerController } from 'stories/Tumbler/Tumbler';

const VALUE_TYPE_WITHOUT_PRECISION = ['text', 'date', 'bps'];
const VALUE_TYPE_WITHOUT_HIDE_COMMA_SEPARATOR = ['text', 'date'];

export function ValueDisplayOptionsFields<
  FV extends FieldValues = FieldValues,
  N extends FieldPath<FV> = FieldPath<FV>,
>(props: {
  control: Control<FV>;
  name?: N;
  supportedTypes?: ValueDisplayType[] | readonly ValueDisplayType[];
  sectionFieldProps?: Pick<
    React.ComponentProps<typeof SectionField>,
    'labelText' | 'note' | 'button'
  >;
}) {
  const {
    name: parentFieldName = 'value_display_options',
    control,
    sectionFieldProps,
    supportedTypes,
  } = props;
  const { resetField, watch, setValue } = useFormContext();

  const getFieldName = (name: keyof ValueDisplayOptionsForm) =>
    `${parentFieldName}.${name}` as FieldPath<FV>;

  const type = watch(getFieldName('type'));
  const showPrecision = !VALUE_TYPE_WITHOUT_PRECISION.includes(type);
  const showHideCommaSeparator =
    !VALUE_TYPE_WITHOUT_HIDE_COMMA_SEPARATOR.includes(type);

  const handleChangeValueType = (value: ValueDisplayType) => {
    if (value === 'bps') {
      setValue(getFieldName('precision'), 0);
    }
    setValue(getFieldName('type'), value, {
      shouldDirty: true,
    });
  };

  return (
    <SectionFieldsContainer>
      <SectionField
        labelText="Output Format"
        note="Select the format for the column"
        button={
          <ResetButton
            onClick={() => {
              resetField(parentFieldName, {
                defaultValue: {
                  type: null,
                  precision: null,
                  kilo_formatting: false,
                },
              });
            }}
            name={parentFieldName}
          >
            Reset All
          </ResetButton>
        }
        {...sectionFieldProps}
      />
      <SectionField
        labelText="Value Type"
        button={<ResetButton name={getFieldName('type')} />}
      >
        <RadioGroupController
          control={control}
          name={getFieldName('type')}
          options={VALUE_TYPE_OPTIONS.filter((o) =>
            supportedTypes ? supportedTypes.includes(o.value) : true,
          )}
          onChange={(o) => handleChangeValueType(o.value as ValueDisplayType)}
        />
      </SectionField>
      {showPrecision && (
        <SectionField
          labelText="Precision"
          button={<ResetButton name={getFieldName('precision')} />}
        >
          <RadioGroupController
            control={control}
            name={getFieldName('precision')}
            options={PRECISION_OPTIONS.map((o) => ({
              label: o.toString(),
              value: o,
            }))}
          />
        </SectionField>
      )}
      {type === 'currency' && (
        <SectionField
          labelText="Kilo Formatting"
          button={
            <TumblerController
              control={control}
              name={getFieldName('kilo_formatting')}
              classes={{
                input: 'self-center',
              }}
            />
          }
        />
      )}
      {type === 'date' && (
        <SectionField
          labelText="Date Format"
          button={<ResetButton name={getFieldName('date_format')} />}
        >
          <RadioGroupController
            control={control}
            name={getFieldName('date_format')}
            options={DATE_FORMAT_VALUE_OPTIONS}
            getItemProps={getRadioGroupItemPropsHandler}
          />
        </SectionField>
      )}
      {showHideCommaSeparator && (
        <SectionField
          labelText="Hide Comma Separator"
          button={
            <TumblerController
              control={control}
              name={getFieldName('hide_comma_separator')}
              classes={{
                input: 'self-center',
              }}
            />
          }
        />
      )}
    </SectionFieldsContainer>
  );
}

export function NumericValueDisplayOptionsFields<
  FV extends FieldValues = FieldValues,
  N extends FieldPath<FV> = FieldPath<FV>,
>(
  props: { name: N; control: Control<FV> } & React.ComponentProps<
    typeof ValueDisplayOptionsFields
  >,
) {
  return (
    <ValueDisplayOptionsFields
      supportedTypes={NUMERIC_VALUE_TYPES}
      {...props}
    />
  );
}

export const SparklineTooltipValueDisplayOptionsFields = <
  FV extends FieldValues = FieldValues,
  N extends FieldPath<FV> = FieldPath<FV>,
>(
  props: { name: N; control: Control<FV> } & React.ComponentProps<
    typeof ValueDisplayOptionsFields
  >,
) => {
  return (
    <ValueDisplayOptionsFields
      supportedTypes={NUMERIC_VALUE_TYPES}
      sectionFieldProps={{
        labelText: 'Tooltip Value Display Options',
        note: 'Select the format for the sparkline tooltip value',
      }}
      {...props}
    />
  );
};
