import { InvestmentIndex } from '@/entities/return/api/capitalInvestmentObjectsGeneratedApi';
import { CAPITAL_INVESTMENT_CLASS_COLOR_MAP } from 'bundles/REturn/components/Ownership/modals/consts';
import { ICapitalInvestment } from 'bundles/REturn/types';
import Table from 'bundles/Shared/components/Table/Table';
import QuickFilterCheckList, {
  getDefaultMappingToOption,
} from 'bundles/Shared/components/Table/filters/QuickFilterCheckList';
import QuickFilterDropdown from 'bundles/Shared/components/Table/filters/QuickFilterDropdown';
import { IColumn, ISortSettings } from 'bundles/Shared/components/Table/types';
import { useLocalTableSorting } from '@/shared/lib/hooks/useLocalTableSorting';
import { includesInLowerCase } from '@/shared/lib/listHelpers';
import { capitalize, sortBy, uniqBy } from 'lodash-es';
import pluralize from 'pluralize';
import { useEffect, useMemo, useState } from 'react';
import { Label } from 'stories/Label/Label';
import { SearchInput } from 'stories/FormControls/Inputs/SearchInput/SearchInput';
import styles from '@/bundles/REturn/components/Ownership/selectInvestmentEntity/SelectInvestmentEntity.module.scss';

interface Props {
  capitalInvestments: InvestmentIndex[];
  selectedItems: InvestmentIndex[];
  setSelectedItems: (selectedItems: InvestmentIndex[]) => void;
}

export const CAPITAL_INVESTMENT_CLASS_OPTIONS = [
  { key: 'all', label: 'All' },
  { key: 'a', label: 'Class A' },
  { key: 'b', label: 'Class B' },
];

const SelectInvestmentEntity = ({
  capitalInvestments,
  selectedItems,
  setSelectedItems,
}: Props) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [filterModel, setFilterModel] = useState<{
    legalEntity?: InvestmentIndex['legalEntity'][];
    investmentEntity?: InvestmentIndex['investmentClass'] | 'all';
  }>({});
  const selectedLegalEntities = filterModel.legalEntity ?? [];
  const selectedInvestmentClass = filterModel.investmentEntity;
  const [sortSettings, setSortSettings] = useState<
    ISortSettings<'investmentEntity'>
  >({});

  const sortedItems = useLocalTableSorting(capitalInvestments, sortSettings, {
    investmentEntity: (ci) => ci.investmentEntity.name,
  });

  const filteredItems = useMemo(
    () =>
      sortedItems.filter(
        (ci) =>
          (selectedLegalEntities.length === 0 ||
            selectedLegalEntities
              .map((le) => le.id)
              .includes(ci.legalEntity.id)) &&
          (includesInLowerCase(ci.investmentEntity.name, searchQuery) ||
            includesInLowerCase(ci.legalEntity.name, searchQuery)) &&
          (selectedInvestmentClass === undefined ||
            ci.investmentClass == selectedInvestmentClass),
      ),
    [sortedItems, selectedLegalEntities, searchQuery, selectedInvestmentClass],
  );

  useEffect(() => {
    // mainly for dev time
    if (capitalInvestments.length === 1) {
      setSelectedItems([capitalInvestments[0]]);
    }
  }, []);

  const columns = useMemo<IColumn<InvestmentIndex>[]>(() => {
    const legalEntities = uniqBy(
      capitalInvestments.map((ci) => ci.legalEntity),
      (le) => le.id,
    );

    return [
      {
        text: 'Investment Entity',
        dataField: 'investmentEntity',
        headerStyle: {
          width: '50%',
        },
        formatter: ({ row: item }) => (
          <span className="dark-60 inline-regular flex items-center justify-between">
            {item.investmentEntity.name}
            <div className="flex items-center gap-2">
              {sortBy(item.investmentClasses).map((investmentClass) => (
                <Label
                  outline
                  size="s"
                  className="secondary-regular"
                  color={CAPITAL_INVESTMENT_CLASS_COLOR_MAP[investmentClass]}
                  key={investmentClass}
                >
                  {capitalize(investmentClass)}
                </Label>
              ))}
            </div>
          </span>
        ),
        sortable: true,
        filterComponent: QuickFilterDropdown,
        filterComponentParams: {
          options: CAPITAL_INVESTMENT_CLASS_OPTIONS,
        },
      },
      {
        text: 'Legal Entity',
        dataField: 'legalEntity',
        headerStyle: {
          width: '50%',
        },
        formatter: ({ row: item }) => (
          <span className="dark-60 inline-regular">
            {item.legalEntity.name}
          </span>
        ),
        filterComponent: QuickFilterCheckList,
        filterComponentParams: {
          ...getDefaultMappingToOption<ICapitalInvestment['legalEntity']>(
            (le) => le.name,
          ),
          items: legalEntities,
        },
      },
    ];
  }, [capitalInvestments]);

  return (
    <div className="bg-neutral-100 p-6">
      <div className="light-90 inline-regular">
        Select which Investment Entity you want to edit
      </div>
      <div className="light-60 secondary-semibold mt-4 font-semibold">
        {capitalInvestments.length}{' '}
        {pluralize('Investment Entity', capitalInvestments.length)}
      </div>
      <SearchInput
        onChange={(e) => setSearchQuery(e.target.value)}
        placeholder="Search"
        value={searchQuery}
        resetValue={() => setSearchQuery('')}
        size="s"
        className="mb-m mt-s"
      />
      <Table
        classes={{
          table: styles.table,
          th: '!p-2',
          td: '!p-2',
        }}
        items={filteredItems}
        settings={sortSettings}
        setSettings={setSortSettings}
        selectedRows={selectedItems}
        setSelectedRows={setSelectedItems}
        filterModel={filterModel}
        onFilterModelChange={setFilterModel}
        columns={columns}
      />
    </div>
  );
};

export default SelectInvestmentEntity;
