import SetFilter from '@/bundles/REconcile/components/operational/table/SetFilter';
import GroupByPopover from '@/bundles/Settings/components/REport/GeneralLedgers/GroupByPopover';
import GeneralLedgersTable from '@/bundles/Settings/components/REport/GeneralLedgers/Table/GeneralLedgersTable';
import GroupRowInnerRenderer from '@/bundles/Settings/components/REport/GeneralLedgers/Table/GroupRowInnerRenderer';
import { useTableRefCallback } from '@/bundles/Settings/components/REport/GeneralLedgers/hooks/useTableRefCallback';
import { SETTINGS_REPORT_OPERATIONAL_DICTIONARY } from '@/pages/settings/report/operational/config';
import { useLEClassUpdateAndInvalidateEntity } from '@/pages/settings/report/operational/lib';
import { getLegalEntityColumn } from '@/pages/settings/report/operational/lib/getLegalEntityColumn';
import { useSettingReportOperationTabs } from '@/pages/settings/report/operational/lib/useSettingReportOperationTabs';
import { useSettingReportOperationalPageParams } from '@/pages/settings/report/operational/lib/useSettingReportOperationalPageParams';
import { useSettingsReportOperationalLEOptions } from '@/pages/settings/report/operational/lib/useSettingsReportOperationalLEOptions';
import { BulkSetUnitTypeModal } from '@/pages/settings/report/operational/ui/BulkSetUnitTypeModal';
import { getCheckboxAggridTableColumn } from '@/bundles/Settings/shared/lib/getCheckboxAggridTableColumn';
import { getLEClassAggridTableColumn } from '@/bundles/Settings/shared/lib/getLEClassAggridTableColumn';
import BulkActionsPanel from '@/bundles/Shared/components/BulkActionsPanel/BulkActionsPanel';
import {
  PageParamsPagination,
  PageParamsProvider,
  PageParamsSearch,
} from '@/bundles/Shared/components/pageParams';
import {
  generateUrlSettingsUnitTypes,
  OPERATIONAL_MAPPING_GROUP_BY_LIST,
  SourceUnitType,
  SreUnitType,
} from '@/bundles/Shared/entities/sreUnitType';
import {
  useGetApiSettingsReportRentRollSourceUnitTypesQuery,
  useGetApiSettingsReportRentRollUnitTypesQuery,
  usePutApiSettingsReportRentRollSourceUnitTypesSetUnitTypeMutation,
} from '@/entities/report/unitTypes';
import useGridSelectedRows from '@/lib/ag-grid/useGridSelectedRows';
import { ERP_SYSTEM_SETTINGS } from '@/entities/erpsystem/config';
import { useModal } from '@/shared/lib/hooks/useModal';
import { getOptionsValues, mapListToIds } from '@/shared/lib/listHelpers';
import { Button } from '@/stories/Button/Button';
import { Dropdown } from '@/stories/Dropdown/Dropdown';
import { GeneralSettingsNavigationSection } from '@/stories/GeneralSettingsNavigationSection/GeneralSettingsNavigationSection';
import { IconButton } from '@/stories/IconButton/IconButton';
import { RouteComponentProps, useNavigate } from '@reach/router';
import { ColDef } from 'ag-grid-community';
import { omit } from 'lodash-es';
import pluralize from 'pluralize';
import { FC, useMemo, useState } from 'react';
import { SreUnitTypePopover } from '@/widgets/report/unitTypeManage/ui/UnitTypeManagePopover';
import { getUnitTypeEditButtonDomId } from '@/pages/settings/report/operational/unitTypes/lib';
import { UnitTypesManageTreeButton } from '@/features/report/unitTypes/manageTree/ui/UnitTypesManageTreeButton';
import { getErpColumn } from '@/entities/erpsystem/lib';
import { getPmcColumn } from '@/entities/propertyManagementCompany/lib';
import { filterByLEClass } from '@/entities/core/legalEntity';
import ReportSettingsScreenLayout from '@/bundles/Settings/shared/ReportSettingsScreenLayout';
import {
  SettingsReportUnitTypesExportDropdownItem,
  SettingsReportUnitTypesImportDropdownItem,
} from '@/features/report/unitTypes/settingsImport/ui';
import { useAgGridRef } from '@/lib/ag-grid/utils';

export const SettingsReportOperationalUnitTypesPage: FC<
  RouteComponentProps
> = () => {
  const navigate = useNavigate();
  const isMappedUnitTypesTab = useSettingReportOperationTabs();
  const [editedUnitType, setEditedUnitType] = useState<{
    unitType: SreUnitType;
    id: string;
  } | null>(null);
  const editedUnitTypeReference = useMemo(() => {
    if (editedUnitType == null) return null;
    return document.getElementById(
      getUnitTypeEditButtonDomId(editedUnitType.id),
    );
  }, [editedUnitType]);
  const tabParams = {
    withUnitType: isMappedUnitTypesTab,
    withoutUnitType: !isMappedUnitTypesTab,
  };

  const {
    pageParams,
    setPageParams,
    setSortParams,
    setGroupByParam,
    setLegalEntityIds,
    setSreUnitTypeIds,
    setPmcIds,
    setErps,
  } = useSettingReportOperationalPageParams();

  const {
    data: sourceUnitTypesData,
    isFetching: sourceUnitTypesFetching,
    isLoading: sourceUnitTypesLoading,
  } = useGetApiSettingsReportRentRollSourceUnitTypesQuery({
    ...tabParams,
    ...pageParams,
  });

  const exportParams = {
    ...tabParams,
    ...omit(pageParams, ['page', 'perPage']),
  };

  const [updateSourceUnitType] =
    usePutApiSettingsReportRentRollSourceUnitTypesSetUnitTypeMutation();

  const isFetching = sourceUnitTypesFetching;
  const isLoading = sourceUnitTypesLoading;
  const { openModal, confirm } = useModal();
  const { data: unitTypesData } =
    useGetApiSettingsReportRentRollUnitTypesQuery();

  const totalSize = sourceUnitTypesData?.meta.totalSize ?? 0;
  const mappedSourceUnitTypesCount =
    sourceUnitTypesData?.meta.withUnitTypeSize ?? 0;

  const unmappedSourceUnitTypesCount =
    sourceUnitTypesData?.meta.withoutUnitTypeSize ?? 0;

  const usedUnitTypesSize = sourceUnitTypesData?.meta.usedSreUnitTypesSize ?? 0;

  const { allLEOptions, selectedLEOptions } =
    useSettingsReportOperationalLEOptions({
      legalEntities: sourceUnitTypesData?.meta.legalEntities ?? [],
      selectedLEIds: pageParams.legalEntityIds,
      deps: [pageParams, sourceUnitTypesData],
    });

  const allSreUnitTypeOptions = useMemo(() => {
    return (
      sourceUnitTypesData?.meta?.sreUnitTypes?.map((ut) => ({
        ...ut,
        value: ut.id,
      })) ?? []
    );
  }, [sourceUnitTypesData]);

  const allErpsOptions = useMemo(() => {
    return (
      sourceUnitTypesData?.meta?.erps?.map((ut) => ({
        id: ut,
        value: ut,
        label: ERP_SYSTEM_SETTINGS[ut].title,
      })) ?? []
    );
  }, [sourceUnitTypesData]);

  const allPmcOptions = useMemo(() => {
    return (
      sourceUnitTypesData?.meta?.pmcs?.map((ut) => ({
        ...ut,
        value: ut.id,
        label: ut.name,
      })) ?? []
    );
  }, [sourceUnitTypesData]);

  const unitTypes = unitTypesData?.items ?? [];
  const items = (sourceUnitTypesData?.items ?? []) as SourceUnitType[];
  const tableRef = useAgGridRef();

  const sourceUnitTypesMap = useMemo(() => {
    return new Map(items.map((i) => [i.id, i] as const));
  }, [items]);

  const onSetClassification = useLEClassUpdateAndInvalidateEntity('unit-types');
  const {
    selectedRows: selectedUTs,
    deselectAll: deselectAllUTs,
    handleRowCheck,
    allShownRowsChecked,
    handleAllShownRowsCheck,
    handleGroupCheck,
    resolveGroupCheck,
  } = useGridSelectedRows<SourceUnitType>(tableRef, items);

  const refCallBack = useTableRefCallback({
    setSortParams,
    tableRef,
  });

  const selectedUTsWithLEClass = useMemo(() => {
    return filterByLEClass(selectedUTs);
  }, [selectedUTs]);

  const handleSetBulkUnitType = async () => {
    const res = await openModal(BulkSetUnitTypeModal, {
      sourceUnitTypes: selectedUTsWithLEClass,
      unitTypes: unitTypesData?.items ?? [],
    });

    if (res == null) return;

    updateSourceUnitType({
      body: {
        unit_type_id: res.id,
        source_unit_type_ids: mapListToIds(selectedUTsWithLEClass),
      },
    });
    deselectAllUTs();
  };

  const handleResetBulk = async () => {
    const res = await confirm();

    if (!res) return;

    updateSourceUnitType({
      body: {
        unit_type_id: null,
        source_unit_type_ids: mapListToIds(selectedUTs),
      },
    });
    deselectAllUTs();
  };

  const columnDefsAggrid: ColDef[] = useMemo(() => {
    const columns = [
      getCheckboxAggridTableColumn({
        allChecked: allShownRowsChecked,
        checked: selectedUTs,
        onClickAll: handleAllShownRowsCheck,
        onClick: (item, options) => handleRowCheck(item, options?.withShift),
      }),
      {
        cellClass: 'first',
        colId: 'parseUnitType',
        headerName: `Parsed ${SETTINGS_REPORT_OPERATIONAL_DICTIONARY.UnitTypes.singularForm}`,
        field: 'name',
        flex: 1,
        rowGroup: false,
      },
      {
        colId: 'sreUnitType',
        field: 'unitType.label',
        flex: 1,
        rowGroup: false,
        cellRenderer: ({ value }) => {
          return value;
        },
        headerComponent:
          allSreUnitTypeOptions.length && isMappedUnitTypesTab
            ? SetFilter
            : null,
        headerComponentParams: {
          filterName: `sRE ${SETTINGS_REPORT_OPERATIONAL_DICTIONARY.UnitTypes.singularForm}`,
          items: allSreUnitTypeOptions,
          value: pageParams.sreUnitTypeIds,
          onChange: (options) => {
            setSreUnitTypeIds(getOptionsValues(options));
          },
          actions: <UnitTypesManageTreeButton />,
          label: `sRE ${SETTINGS_REPORT_OPERATIONAL_DICTIONARY.UnitTypes.singularForm}`,
        },
      },
      getErpColumn({
        group: pageParams.group,
        options: allErpsOptions,
        onChange: setErps,
        value: pageParams.erps,
      }),
      getPmcColumn({
        group: pageParams.group,
        options: allPmcOptions,
        onChange: setPmcIds,
        value: pageParams.pmcIds,
      }),
      getLegalEntityColumn({
        allLEOptions,
        selectedLEOptions,
        setLegalEntityIds,
      }),
      getLEClassAggridTableColumn({
        isLastColumn: !isMappedUnitTypesTab,
        actions: {
          onSetClassification,
        },
      }),
    ];

    if (isMappedUnitTypesTab) {
      columns.push({
        cellClass: 'last',
        colId: 'actions',
        headerName: '',
        field: 'unitType.name',
        resizable: false,
        flex: 0,
        cellRenderer: ({ data }) => {
          const sourceUnitType = sourceUnitTypesMap.get(data.id)!;
          const disabled = sourceUnitType?.legalEntity?.classification == null;
          return (
            <IconButton
              id={getUnitTypeEditButtonDomId(data.id)}
              disabled={disabled}
              onClick={() => setEditedUnitType(data)}
              iconName="edit"
            />
          );
        },
        width: 45,
      });
    }

    return columns;
  }, [
    items,
    allLEOptions,
    selectedLEOptions,
    unitTypes,
    isMappedUnitTypesTab,
    selectedUTs,
    allShownRowsChecked,
  ]);

  const groupRendererParams = useMemo(() => {
    return {
      suppressCount: true,
      innerRenderer: GroupRowInnerRenderer,
      handleGroupCheck,
      resolveGroupCheck,
    };
  }, [handleGroupCheck, resolveGroupCheck]);

  return (
    <PageParamsProvider pageParams={pageParams} setPageParams={setPageParams}>
      <ReportSettingsScreenLayout.Content className="flex flex-col flex-grow h-screen gap-4">
        <div className="flex gap-4">
          <GeneralSettingsNavigationSection
            isLoading={isLoading}
            disabled={isFetching}
            title={`${mappedSourceUnitTypesCount} ${pluralize(
              SETTINGS_REPORT_OPERATIONAL_DICTIONARY.UnitTypes.singularForm,
              mappedSourceUnitTypesCount,
            )}`}
            subtitle={`Mapped with ${usedUnitTypesSize} sRE ${pluralize(
              SETTINGS_REPORT_OPERATIONAL_DICTIONARY.UnitTypes.singularForm,
              usedUnitTypesSize,
            )}`}
            active={isMappedUnitTypesTab}
            onClick={() => {
              navigate(generateUrlSettingsUnitTypes());
            }}
          />
          <GeneralSettingsNavigationSection
            isLoading={isLoading}
            disabled={isFetching}
            title={`${unmappedSourceUnitTypesCount} Active ${pluralize(
              SETTINGS_REPORT_OPERATIONAL_DICTIONARY.UnitTypes.singularForm,
              unmappedSourceUnitTypesCount,
            )}`}
            subtitle="Not Mapped"
            active={!isMappedUnitTypesTab}
            onClick={() => {
              navigate(generateUrlSettingsUnitTypes(false));
              setSreUnitTypeIds([]);
            }}
          />
        </div>
        <div className="flex flex-col flex-grow gap-4">
          <div className="flex flex-col gap-4">
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <PageParamsPagination
                  loading={isFetching}
                  totalSize={totalSize}
                />
              </div>
              <div className="flex items-center gap-4">
                <GroupByPopover
                  items={OPERATIONAL_MAPPING_GROUP_BY_LIST}
                  value={OPERATIONAL_MAPPING_GROUP_BY_LIST.filter((c) => {
                    return pageParams.group.includes(c.value);
                  })}
                  onChange={setGroupByParam}
                />
                <PageParamsSearch
                  suggestions={[
                    `Parsed ${SETTINGS_REPORT_OPERATIONAL_DICTIONARY.UnitTypes.singularForm} name`,
                  ]}
                  size="s"
                  placeholder={
                    SETTINGS_REPORT_OPERATIONAL_DICTIONARY.UnitTypes
                      .singularForm
                  }
                />
                <Dropdown
                  hideOnExpandedAreaClick={false}
                  className="min-w-[180px]"
                  items={
                    <>
                      <SettingsReportUnitTypesImportDropdownItem type="csv" />
                      <SettingsReportUnitTypesExportDropdownItem
                        type="csv"
                        params={exportParams}
                      />
                    </>
                  }
                >
                  <Button
                    iconName="bottom"
                    iconPosition="right"
                    variant="secondary"
                    size="s"
                  >
                    Current Floorplans
                  </Button>
                </Dropdown>
                <Dropdown
                  hideOnExpandedAreaClick={false}
                  className="min-w-[180px]"
                  items={
                    <>
                      <SettingsReportUnitTypesImportDropdownItem type="xlsx" />
                      <SettingsReportUnitTypesExportDropdownItem
                        type="xlsx"
                        params={exportParams}
                      />
                    </>
                  }
                >
                  <Button
                    iconName="bottom"
                    iconPosition="right"
                    variant="secondary"
                    size="s"
                  >
                    Floorplan History
                  </Button>
                </Dropdown>
              </div>
            </div>
          </div>

          <GeneralLedgersTable // The table renders not general ledgers but unit types, should be renamed
            className="table-theme-light_small-padding"
            ref={refCallBack}
            loading={isLoading}
            fetching={isFetching}
            columnDefs={columnDefsAggrid}
            rowData={items}
            onRowClicked={(e) => {
              const event = e.event as MouseEvent;
              if (!event.shiftKey) return;
              handleRowCheck(e.data, true);
            }}
            groupRowRendererParams={groupRendererParams}
          />

          {selectedUTs.length > 0 && (
            <BulkActionsPanel
              selectedRows={selectedUTs}
              setSelectedRows={() => deselectAllUTs()}
              actions={[
                {
                  title: `Set ${SETTINGS_REPORT_OPERATIONAL_DICTIONARY.UnitTypes.singularForm}`,
                  icon: 'edit',
                  handleClick: handleSetBulkUnitType,
                  hidden: selectedUTsWithLEClass.length === 0,
                },
                {
                  title: `Reset ${SETTINGS_REPORT_OPERATIONAL_DICTIONARY.UnitTypes.singularForm}`,
                  icon: 'reset',
                  handleClick: handleResetBulk,
                  hidden: selectedUTs.length === 0 || !isMappedUnitTypesTab,
                },
              ]}
            />
          )}
        </div>
      </ReportSettingsScreenLayout.Content>
      <SreUnitTypePopover
        visible={editedUnitType != null}
        onClose={() => setEditedUnitType(null)}
        initialValue={editedUnitType?.unitType}
        sourceUnitType={editedUnitType}
        reference={editedUnitTypeReference}
        onClickOutside={() => setEditedUnitType(null)}
        classes={{ spanContainer: 'absolute' }}
      />
    </PageParamsProvider>
  );
};
