import React from 'react';
import { IDevelopmentLegalEntityRequisitionTransaction } from 'bundles/REconcile/types/ILineItem';
import FlyBottomPanelContainer from 'bundles/Settings/components/REport/CategoriesList/PathBar/FlyBottomPanelContainer';
import FlyBottomPanelBar from 'bundles/Settings/components/REport/CategoriesList/PathBar/FlyBottomPanelBar';
import { Checkbox } from 'stories/Checkbox/Checkbox';
import { CurrencyFormatter } from 'stories/ValueFormatters/CurrencyFormatter';
import VerticalSeparator from 'bundles/Shared/components/VerticalSeparator/VerticalSeparator';
import { sumBy } from 'lodash-es';
import FlyBottomPanelBarButton from 'bundles/Settings/components/REport/CategoriesList/PathBar/FlyBottomPanelBarButton';

interface Props {
  selectedRows: IDevelopmentLegalEntityRequisitionTransaction[];
  setSelectedRows: React.Dispatch<
    React.SetStateAction<IDevelopmentLegalEntityRequisitionTransaction[]>
  >;
  bulkLinkToCategory: VoidFunction;
}

function SelectedRowsPanel({
  selectedRows,
  setSelectedRows,
  bulkLinkToCategory,
}: Props) {
  return (
    <FlyBottomPanelContainer className="max-w-max">
      <FlyBottomPanelBar className="gap-4 rounded-2xl bg-dark-100 px-4 py-2">
        <span className="inline-semibold flex items-center gap-2 !text-light">
          <Checkbox
            onChange={() => setSelectedRows([])}
            checked
            labelClassName="!self-center"
          />
          {selectedRows.length} selected
        </span>
        <VerticalSeparator className="bg-dark-60" />
        <span className="flex gap-1 font-normal !text-light">
          <span className="text-light-40">Total:</span>
          <CurrencyFormatter
            classes={{
              wrapper: 'inline-semibold',
              value: '!text-light',
              startSymbol: '!text-light',
            }}
            value={sumBy(selectedRows, (item) => Number(item.value))}
          />
        </span>
        <VerticalSeparator className="bg-dark-60" />
        <FlyBottomPanelBarButton
          className="inline-semibold bg-dark-60"
          onClick={bulkLinkToCategory}
        >
          Link To JCC
        </FlyBottomPanelBarButton>
      </FlyBottomPanelBar>
    </FlyBottomPanelContainer>
  );
}

export default SelectedRowsPanel;
