import { UnitTypeTableWidgetConfigDto } from '@/shared/api/dashboardsSettingsGeneratedApi';
import { DEFAULT_TABLE_VIZ_CONFIG } from '@/bundles/Shared/widgets/dashboard/widgets/common/config';
import { WidgetContextDateConfigCard } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/state/WidgetContextDateConfigCard';
import { WidgetConfiguration } from '@/bundles/Shared/widgets/dashboard/widgets/model';
import { UnitTypeWidgetConfigComponent } from '@/bundles/Shared/widgets/dashboard/widgets/unitTypeTable/config/component';
import { UnitTypeWidget } from '@/bundles/Shared/widgets/dashboard/widgets/unitTypeTable/widget';
import { ReadonlyDeep } from 'type-fest';

export const DEFAULT_UNIT_TYPE_TABLE_CONFIG = {
  ...DEFAULT_TABLE_VIZ_CONFIG,
  columns: [],
  default_options: {
    date: {
      period_shift: {
        months: 0,
      },
    },
  },
} satisfies ReadonlyDeep<UnitTypeTableWidgetConfigDto>;

export const UNIT_TYPE_TABLE_WIDGET_CONFIG = {
  layout: {
    w: 2,
    h: 13,
    maxH: 12,
    minH: 6,
    minW: 1,
    maxW: 2,
  },
  title: 'Unit Type Table',
  icon: 'table',
  Component: UnitTypeWidget,
  ConfigComponent: UnitTypeWidgetConfigComponent,
  CardConfigComponent: WidgetContextDateConfigCard,
} as const satisfies WidgetConfiguration;
