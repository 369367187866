import { cn } from '@/shared/lib/css/cn';
import React from 'react';
import styles from '@/stories/AnimationLoader/AnimationLoader.module.scss';
import { AnimationLoaderSVG } from '@/stories/AnimationLoader/AnimationLoaderSVG';

import { ReactComponent as LeftSvg } from 'fonts/sre-icons/Icons/loader.svg';

interface Props {
  className?: string;
  classNameWrapper?: string;
  withBg?: boolean;
}

export const AnimationLoader = ({
  className,
  classNameWrapper,
  withBg,
}: Props) => (
  <div
    className={cn(
      styles['animation-loader'],
      {
        'bg-neutral-000/50': withBg,
      },
      className,
    )}
  >
    <div
      className={cn(
        styles['animation-loader__white-wrapper'],
        classNameWrapper,
      )}
    >
      <AnimationLoaderSVG />
    </div>
  </div>
);

AnimationLoader.Widget = () => (
  <div className={cn(styles['animation-loader'], 'bg-neutral-999/20')}>
    <div className="flex items-center justify-center gap-1 rounded-[8px] bg-neutral-100 px-2 py-1">
      <LeftSvg className="animate-spin fill-black" />
      <p className="inline-regular text-neutral-850">Loading</p>
    </div>
  </div>
);

export default AnimationLoader;
