import { useDeleteApiSettingsReportThirdPartyExtractionsConnectionsByConnectionIdAttachmentsAndIdMutation } from '@/shared/api/settingsReportConnectionsEnhancedApi';
import { DELETE_EXTRACTION_CONFIRM_MODAL_SUBTITLE } from '@/features/report/connections/config';
import { useModal } from '@/shared/lib/hooks/useModal';

export const useDeleteExtractionAttachment = () => {
  const [deleteAttachment, options] =
    useDeleteApiSettingsReportThirdPartyExtractionsConnectionsByConnectionIdAttachmentsAndIdMutation();

  const { confirm } = useModal();
  const handleDelete = async (
    params: Parameters<typeof deleteAttachment>[0],
  ) => {
    const res = await confirm({
      subtitle: DELETE_EXTRACTION_CONFIRM_MODAL_SUBTITLE,
    });

    if (!res) return;

    deleteAttachment(params);
  };

  return [handleDelete, options] as const;
};
