import {
  InvestmentObjectUnitPrice,
  TransactionSource,
} from '@/entities/return/api/capitalInvestmentObjectsGeneratedApi';
import type * as Type from 'bundles/REturn/actions/types';
import { ICapitalInvestment } from 'bundles/REturn/types';
import dayjs from 'dayjs';
import { capitalize } from 'lodash-es';
import { CAPITAL_INVESTMENT_ENTRY_AMOUNT_KEY_MAP } from '@/bundles/REturn/components/Ownership/modals/manageCapitalInvestmentModal/consts';

export const getTotalInvestmentEntityAmountByEntryType = ({
  type,
  capitalInvestment,
}: {
  type: TransactionSource['kind'];
  capitalInvestment: ICapitalInvestment;
}) => {
  if (capitalInvestment)
    return capitalInvestment[CAPITAL_INVESTMENT_ENTRY_AMOUNT_KEY_MAP[type]];

  return null;
};

export function resolveIsDistributionEntry(
  entry: Type.CapitalEntry,
): entry is Type.CapitalDistribution {
  return 'kind' in entry;
}

export function formatDateReactForDatePicker(date: string): Date {
  const seconds = dayjs(date).unix();

  const milliSeconds = seconds * 1000;

  return new Date(milliSeconds);
}

export function entryHasReportLineItem(
  entry: Type.CapitalInvestmentEntryBase,
): entry is NonNullishFields<Type.CapitalInvestmentEntryBase> {
  return Boolean(entry.accountingTransaction);
}

export const resolveUnitsInputTooltipText = ({
  useSignedDateForUnitPriceDate,
  noAvailableUnitPricesByInvestmentClass,
  investmentClass,
}: {
  useSignedDateForUnitPriceDate: boolean;
  noAvailableUnitPricesByInvestmentClass: boolean;
  investmentClass: InvestmentObjectUnitPrice['investmentClass'];
}) => {
  if (noAvailableUnitPricesByInvestmentClass) {
    return `There are no available unit prices for this investment class "${capitalize(
      investmentClass ?? '',
    )}"`;
  }

  return `To enter unit amount, please set a ${
    useSignedDateForUnitPriceDate ? 'Signed Date' : 'Date'
  }`;
};
