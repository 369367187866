import { NotFound } from '@/bundles/Errors';
import { DashboardContext } from '@/bundles/Shared/entities/dashboard/lib';
import { ReportDashboardType } from '@/bundles/Shared/entities/dashboard/model/types/types';
import {
  ReportBuilderTemplateContext,
  ReportingWidgetGroupIdContext,
} from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/reportBuilderTemplateContext';
import { ReportBuilderTemplateMetaContext } from '@/entities/report/reportBuilder/context/reportBuilderTemplateMetaContext';
import {
  useGetReportBuilderTemplate,
  useGetReportBuilderTemplateGotenbergMetaWithSearchParamAssetId,
} from '@/entities/report/reportBuilder/lib';
import { resolveReportBuilderGroupHeader } from '@/entities/report/reportBuilder/lib/resolveReportBuilderGroupHeader';
import { ReportPDFHeader } from '@/entities/report/reportBuilder/ui/ReportPDFHeader';
import { useReportingEntityKindContext } from '@/entities/reporting/context/entityKind';
import { isReportBuilderViewWidgetTheLast } from '@/pages/reports/report/view/page';
import { useSearchParams } from '@/shared/lib/hooks/navigation/useSearchParams';
import { useAppSelector } from '@/shared/lib/hooks/redux';
import { PdfUI } from '@/shared/ui/PdfUI';
import { AnimationLoader } from '@/stories/AnimationLoader/AnimationLoader';
import { ReportBuilderTemplateWidget } from 'bundles/Shared/widgets/dashboard/widgetsHelpers/ui/ReportBuilderWidget';
import { useMemo } from 'react';

export const ReportBuilderTemplateViewPdf = () => {
  const templateKind = useReportingEntityKindContext();
  const [searchParams] = useSearchParams();
  const templateObjectLevelAssetId = useAppSelector(
    (state) => state.reportBuilderTemplate.objectLevelAssetId,
  );
  const hidePlaceholders = Boolean(searchParams.get('hidePlaceholders'));

  const { data, isLoading } = useGetReportBuilderTemplate();

  const { data: metaData, isLoading: isMetaDataLoading } =
    useGetReportBuilderTemplateGotenbergMetaWithSearchParamAssetId();

  const asset = useMemo(() => {
    return metaData?.assets.find(
      (a) => a.id === Number(templateObjectLevelAssetId),
    );
  }, [metaData, templateObjectLevelAssetId]);

  if (isLoading || isMetaDataLoading) return <AnimationLoader />;

  if (data == null || metaData == null) {
    return <NotFound />;
  }
  return (
    <DashboardContext.Provider
      value={{
        boardId: '',
        dashboardId: '',
        boardSlug: '',
        dashboardSlug: '',
        dashboardType:
          templateKind === 'eagle_eye'
            ? ReportDashboardType.REPORT_BUILDER_TEMPLATE_EAGLE_EYE
            : ReportDashboardType.REPORT_BUILDER_TEMPLATE,
      }}
    >
      <ReportBuilderTemplateMetaContext.Provider value={metaData}>
        <PdfUI>
          {data.groups.map((g, groupIndex, groups) => (
            <PdfUI.WidgetGroup
              hidePlaceholders={hidePlaceholders}
              key={g.id}
              {...g}
            >
              {resolveReportBuilderGroupHeader({
                displayHeaderGlobal: data.displayHeader,
                displayHeaderGroup: g.displayHeader,
              }) && (
                <ReportPDFHeader
                  objectName={
                    templateKind === 'object_level'
                      ? (asset?.name ?? undefined)
                      : undefined
                  }
                  reportTitle={data.name}
                />
              )}
              {g.widgetSections.map((w, widgetIndex, widgets) => (
                <ReportBuilderTemplateContext.Provider
                  key={w.id}
                  value={{
                    templateId: data.id,
                    widgetId: w.id,
                  }}
                >
                  <ReportingWidgetGroupIdContext.Provider value={g.id}>
                    <ReportBuilderTemplateWidget
                      widgetSection={{ ...w, groupId: g.id }}
                      pageOrientation={g.pageOrientation}
                      isLastInGroup={isReportBuilderViewWidgetTheLast({
                        groupIndex,
                        groupsLen: groups.length,
                        widgetsLen: widgets.length,
                        widgetIndex,
                      })}
                    />
                  </ReportingWidgetGroupIdContext.Provider>
                </ReportBuilderTemplateContext.Provider>
              ))}
            </PdfUI.WidgetGroup>
          ))}
        </PdfUI>
      </ReportBuilderTemplateMetaContext.Provider>
    </DashboardContext.Provider>
  );
};
