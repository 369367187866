import React, { useEffect, useState } from 'react';
import { pureFetchPipelineCards } from 'bundles/Scoreboard/Pipeline/actions';
import GanttChart from 'bundles/Scoreboard/Pipeline/components/Pipeline/PipelineCharts/GanttChart';
import { AnimationLoader } from 'stories/AnimationLoader/AnimationLoader';

export const PipelineCards = () => {
  const [cards, setCard] = useState();

  useEffect(() => {
    pureFetchPipelineCards(['gantt-chart'])
      .then((res) => res.json())
      .then((json) => setCard(json));
  }, []);

  if (!cards) return <AnimationLoader />;

  return (
    <>
      {cards.map((card) => (
        <div key={card.id} className="card">
          <div className="card-header card-header-action">
            <h5>{card.title}</h5>
          </div>
          <GanttChart deals={card.data} />
        </div>
      ))}
    </>
  );
};
