import { BaseCard } from '@/shared/ui/BaseCard';
import { RRule, type Frequency } from 'rrule';

import { PermissionListLine } from '@/bundles/Shared/components/Permissions/PermissionListLine';
import { DashboardCardLabel } from '@/bundles/Shared/entities/dashboard/ui/card/DashboardCardLabel';
import type { Permitted } from '@/bundles/Shared/entities/permissions';
import { useViewTemplatePermittedUsers } from '@/features/emails/shared/viewTemplatePermittedUsers/lib';
import { cn } from '@/shared/lib/css/cn';
import { Icon } from '@/stories/Icon/Icon';
import { IconButton } from '@/stories/IconButton/IconButton';
import { Tooltip } from '@/stories/Tooltip/Tooltip';
import { ReactComponent as FileIcon } from 'fonts/sre-icons/Icons/docPdf.svg';

const MAP_FREQ_TO_LABEL = {
  3: {
    label: 'Daily',
    labelClassName: 'bg-green',
  },
  2: {
    label: 'Weekly',
    labelClassName: 'bg-violet',
  },
  1: {
    label: 'Monthly',
    labelClassName: 'bg-yellow',
  },
  0: {
    label: 'Yearly',
    labelClassName: 'bg-pumpkin',
  },
  4: {
    label: 'Hourly',
    labelClassName: 'bg-neutral-100',
  },
  5: {
    label: 'Minutely',
    labelClassName: 'bg-neutral-100',
  },
  6: {
    label: 'Secondly',
    labelClassName: 'bg-neutral-100',
  },
} as const satisfies Record<
  Frequency,
  { label: string; labelClassName: string }
>;
const EmailTemplateCard = ({
  name,
  onNavigate,
  onDelete,
  schedule,
  subtitle,
  isDefault,
  permitted,
}: {
  name: string;
  onNavigate: () => void;
  onDelete: () => void;
  schedule?: string;
  subtitle?: string;
  isDefault?: boolean;
  permitted?: Pick<
    Permitted,
    'directUsers' | 'directRoles' | 'directTags' | 'directInvestmentEntities'
  >;
}) => {
  const rrule = schedule ? RRule.fromString(schedule) : null;

  const freq = rrule?.options.freq;
  const freqCard = freq != null ? MAP_FREQ_TO_LABEL[freq] : null;

  const [handleViewPermissions] = useViewTemplatePermittedUsers({
    permitted,
  });
  return (
    <BaseCard
      iconSlot={<FileIcon className="h-[48px] w-[48px]" />}
      imgWrapperProps={{
        onClick: onNavigate,
      }}
    >
      <div className="flex flex-col justify-between gap-1 px-2">
        {freqCard != null && (
          <div className="mb-1 flex">
            <DashboardCardLabel
              className={cn('text-neutral-000', freqCard.labelClassName)}
            >
              <Icon iconName="clock" />
              <span className="secondary-semibold text-neutral-000">
                {freqCard.label}
              </span>
            </DashboardCardLabel>
          </div>
        )}
        {isDefault && (
          <div className="mb-1 flex">
            <Tooltip mainText="Default template can't be deleted">
              <DashboardCardLabel>
                <Icon iconName="lock" />
                <span className="secondary-semibold text-neutral-900">
                  Default
                </span>
              </DashboardCardLabel>
            </Tooltip>
          </div>
        )}

        <BaseCard.Title className="inline-flex items-start justify-between gap-2">
          <p>{name}</p>
        </BaseCard.Title>
        {subtitle != null && (
          <p className="inline-regular text-neutral-550">{subtitle}</p>
        )}
        {onDelete != null && (
          <BaseCard.OverlayActions>
            <IconButton iconName="trash" onClick={onDelete} />
          </BaseCard.OverlayActions>
        )}
        {permitted != null && (
          <PermissionListLine
            permissions={{
              ...permitted,
              isPublic: false,
            }}
            onClick={() => handleViewPermissions()}
          />
        )}
      </div>
    </BaseCard>
  );
};

export default EmailTemplateCard;
