import React, { FC } from 'react';
import { useParams } from '@reach/router';
import { useGetLinkableRequisitionsQuery } from 'bundles/Construction/api/invoices';
import FormInput from 'bundles/Shared/components/GroupForm/FormItems/FormInput';
import { Dropdown } from 'stories/Dropdown/Dropdown';
import { DropdownItem } from 'stories/Dropdown/DropdownItem/DropdownItem';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import {
  changeLineItemSelectText,
  selectInvoiceDetails,
  selectLineItemSelectText,
} from 'bundles/DrawPackage/slices/ReconcileInvoicesSlice';
import { selectCompanies } from 'bundles/Construction/reducers/ReconcileSlice';
import { formatDate } from '@/shared/lib/formatting/dates';
import { cn } from '@/shared/lib/css/cn';
import {
  addListInvoiceLineItem,
  clearInvoiceLineItems,
} from 'bundles/Construction/reducers/reconcileInvoiceStateSlice';
import { IRequisition } from 'bundles/REconcile/types/IRequisition';
import { NO_REQUITSION_IN_VERIFY } from 'bundles/DrawPackage/InvoicesFlow/Invoices/dumbJSX';
import { TLineItemFlowType } from 'bundles/DrawPackage/InvoicesFlow/Invoices/edit/EditInvoice';

interface IFlowLineItemsProps {
  changeFlow: (flowType: TLineItemFlowType) => void;
}

const FlowLineItems: FC<IFlowLineItemsProps> = ({ changeFlow }) => {
  const params = useParams();
  const invoiceDetails = useAppSelector(selectInvoiceDetails);
  const companies = useAppSelector(selectCompanies);
  const selectText = useAppSelector(selectLineItemSelectText);

  const dispatch = useAppDispatch();

  const { data: requisitions } = useGetLinkableRequisitionsQuery(
    {
      legalEntityCode: params.legalEntityCode,
      vendorId: companies.find(
        (company) => company.name === invoiceDetails.vendor_name.value,
      )?.id,
    },
    {
      skip: !invoiceDetails.vendor_name.value,
    },
  );

  const haveRequisition = Boolean(requisitions?.items.length);

  const selectManual = () => {
    changeFlow('manually');
    dispatch(changeLineItemSelectText('Create manually'));
    dispatch(clearInvoiceLineItems());
  };

  const selectRequistion = (period: string, requisition: IRequisition) => {
    changeFlow('select');
    dispatch(changeLineItemSelectText(`Requisition ${period}`));
    dispatch(
      addListInvoiceLineItem(
        requisition.lineItems.map((lineItem) => ({
          ...lineItem,
          inRequisition: true,
        })),
      ),
    );
  };

  return (
    <Dropdown
      maxWidth="30rem"
      classNameContainer="w-full"
      items={
        <div>
          <DropdownItem
            key="manual"
            onClick={selectManual}
            className="w-[320px] bg-white"
          >
            Create Manually
          </DropdownItem>
          <DropdownItem
            key="select"
            classes={{
              text: 'w-full',
            }}
            className={cn('w-[320px] p-0', {
              'bg-white': haveRequisition,
              'bg-light': !haveRequisition,
            })}
          >
            <div>
              {haveRequisition && (
                <div>
                  <div className="label-regular w-full bg-light-5 px-4 py-1 text-light-60">
                    Select from Requisition
                  </div>
                  {requisitions?.items.map((requistion) => {
                    const period = `(${formatDate(
                      requistion.beginningDate,
                      'MMM DD, YYYY',
                    )} - ${formatDate(requistion.endingDate, 'MMM DD, YYYY')})`;
                    return (
                      <div
                        key={requistion.id}
                        className="inline-regular border-b border-light-10 px-4 py-2 text-light-60 last:border-b-0"
                        onClick={() => selectRequistion(period, requistion)}
                      >
                        {period}
                      </div>
                    );
                  })}
                </div>
              )}
              {!haveRequisition && NO_REQUITSION_IN_VERIFY}
            </div>
          </DropdownItem>
        </div>
      }
    >
      <div className="flex-1">
        <FormInput
          fieldName="flow"
          styles={{
            size: 'm',
          }}
          value={selectText}
        />
      </div>
    </Dropdown>
  );
};

export default FlowLineItems;
