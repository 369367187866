import React, { useMemo } from 'react';
import Table from 'bundles/Shared/components/Table/Table';
import { IPeriodBatchGroup } from 'bundles/Settings/types/ReportDataPeriodBulk';
import dayjs from 'dayjs';
import { nameOf } from 'types/utils';
import LegalEntitiesCell from 'bundles/Settings/components/REport/DataPeriods/Table/LegalEntitiesCell';
import { IColumn } from 'bundles/Shared/components/Table/types';
import styles from '@/bundles/Settings/components/REport/DataPeriods/Table/ReportDataPeriodsTable.module.scss';
import Icon from 'stories/Icon/Icon';
import { usePutApiSettingsReportDataPeriodYearsByYearPublishMutation } from 'bundles/Settings/actions/report/dataPeriodsApiExtended';

interface Props {
  period: string;
  items: IPeriodBatchGroup[];
}

function ReportDataPeriodsTable({ period, items }: Props) {
  const [publishYear] =
    usePutApiSettingsReportDataPeriodYearsByYearPublishMutation();
  const columns = useMemo<IColumn<IPeriodBatchGroup>[]>(
    () => [
      {
        text: period,
        dataField: nameOf<IPeriodBatchGroup>('period'),
        headerClasses: 'bg-dark-60 rounded-t-lg text-white !pt-2',
        headerAlign: 'between',
        classes: 'align-bottom',
        formatter: ({ row: item }) => (
          <h4 className="header4-regular text-light-90">
            {dayjs(item.period).format('MMMM')}
          </h4>
        ),
        filterComponent: () => (
          <>
            <button
              onClick={() => {
                publishYear({
                  year: Number(period),
                });
              }}
              type="button"
              className="inline-semibold flex h-[21px] items-center gap-1 rounded-lg border-0 bg-info-055 pl-1 pr-2 text-neutral-000"
            >
              <Icon iconName="actionsAlt" />
              Publish Year
            </button>
          </>
        ),
      },
      {
        text: 'READY TO PUBLISH',
        dataField: nameOf<IPeriodBatchGroup>('draftBatchesCount'),
        formatter: ({ row: item }) => (
          <LegalEntitiesCell
            period={item.period}
            dialogType="draft"
            count={item.draftBatchesCount}
          />
        ),
      },
      {
        text: 'PUBLISHED',
        dataField: nameOf<IPeriodBatchGroup>('publishedBatchesCount'),
        formatter: ({ row: item }) => (
          <LegalEntitiesCell
            period={item.period}
            dialogType="published"
            count={item.publishedBatchesCount}
          />
        ),
      },
      {
        text: 'UNPUBLISHED',
        dataField: nameOf<IPeriodBatchGroup>('unpublishedBatchesCount'),
        formatter: ({ row: item }) => (
          <LegalEntitiesCell
            period={item.period}
            dialogType="unpublished"
            count={item.unpublishedBatchesCount}
          />
        ),
      },
    ],
    [period, publishYear],
  );

  return (
    <Table
      classes={{
        container: styles.container,
        th: 'vertical-align-bottom',
      }}
      defaultColumn={{
        headerStyle: {
          width: '25%',
        },
      }}
      columns={columns}
      items={items}
    />
  );
}

export default ReportDataPeriodsTable;
