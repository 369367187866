import { formatUnixDate } from '@/shared/lib/formatting/dates';
import React, { FC } from 'react';
import { CssVar } from '@/shared/config/cssVar';
import { cn } from '@/shared/lib/css/cn';
import { Icon } from '@/stories/Icon/Icon';
import { SmallDocumentCard } from '@/stories/SmallDocumentCard/SmallDocumentCard';
import { IconsId } from 'types/sre-icons';
import styles from '@/stories/HistoryItem/HistoryItem.module.scss';

export interface IHistoryItemProps {
  icon: {
    iconName: IconsId;
    iconColor?: string;
    backgroundColor?: string;
  };
  label: React.ReactNode;
  createdBy?: string;
  createdAt?: number;
  documents?: File[];
  emailDetails?: string;
  notificationAction?: {
    label: string;
    action: (sendData?: object) => void;
    sendData?: object;
  };
  linkInTitle?: {
    label: string;
    action: () => void;
  };
  onClickDoc?: (doc: File) => void;
  lastItem?: boolean;
  disabledDocument?: boolean;
  insertAdjasentJSX?: {
    afterBegin?: React.ReactNode;
    afterLabel?: React.ReactNode;
    afterCreatorInfo?: React.ReactNode;
    beforeEnd?: React.ReactNode;
  };
}

export const HistoryItem: FC<IHistoryItemProps> = ({
  icon,
  label,
  createdBy,
  createdAt,
  documents,
  emailDetails,
  notificationAction,
  linkInTitle,
  onClickDoc,
  lastItem,
  disabledDocument,
  insertAdjasentJSX,
}) => (
  <div className={styles['sre-history-item']}>
    <div className={styles['sre-history-item__leftbar']}>
      <div
        className={cn(styles['sre-history-item__icon'], icon?.iconColor)}
        style={{ backgroundColor: icon.backgroundColor || CssVar.white }}
      >
        <Icon iconName={icon.iconName} />
      </div>
      {!lastItem && <div className={styles['sre-history-item__line']} />}
    </div>
    <div className={styles['sre-history-item__body']}>
      {insertAdjasentJSX?.afterBegin}
      <div className={styles['sre-history-item__label']}>
        {label}
        {linkInTitle && (
          <span
            onClick={linkInTitle.action}
            className={styles['sre-history-item__link']}
          >
            {` ${linkInTitle.label}`}
          </span>
        )}
      </div>
      {insertAdjasentJSX?.afterLabel}
      {createdBy && createdAt && (
        <div
          className={cn('secondary-regular', styles['sre-history-item__date'])}
        >
          {createdBy} • {formatUnixDate(createdAt, 'LL [at] LT')}
        </div>
      )}
      {insertAdjasentJSX?.afterCreatorInfo}
      {emailDetails && (
        <div className={styles['sre-history-item__emailDetails']}>
          {emailDetails}
        </div>
      )}
      {notificationAction && (
        <div
          onClick={() =>
            notificationAction.action(notificationAction?.sendData)
          }
          className={cn(
            'label-regular',
            styles['sre-history-item__notificationAction'],
          )}
        >
          {notificationAction.label}
        </div>
      )}
      {documents &&
        documents.map((doc, idx) => (
          <div
            key={`doc${idx}`}
            className={styles['sre-history-item__document']}
          >
            <SmallDocumentCard
              iconName="docPdf"
              iconColorClassName="red"
              label={doc.name}
              onClick={() => onClickDoc?.(doc)}
              disabled={disabledDocument}
            />
          </div>
        ))}
      {insertAdjasentJSX?.beforeEnd}
    </div>
  </div>
);
