import {
  allSubPathMatches,
  generateUrl,
} from '@/shared/lib/hooks/useNavigation';
import { useMatch, useNavigate } from '@reach/router';
import { useEffect } from 'react';
import { UnknownRecord } from 'type-fest';

export function useNavigateToFirst<T extends UnknownRecord>({
  items,
  matchParamKey,
  path,
  idKey = 'id',
  pathParams: additionalParams = {},
}: {
  items: T[] | undefined;
  matchParamKey: string;
  path: string;
  idKey?: keyof T;
  pathParams?: Partial<Record<string, string | number>>;
}) {
  const navigate = useNavigate();
  const match = useMatch(allSubPathMatches(path));

  const idFromURL = match?.[matchParamKey];

  useEffect(() => {
    if (items == null || items.length <= 0) return;

    const itemExistsInItems = Boolean(
      items.find((item) => item[idKey] === idFromURL),
    );

    if (itemExistsInItems) return;

    const [firstItem] = items;

    navigate(
      generateUrl(path, {
        pathParams: {
          ...additionalParams,
          [matchParamKey]: firstItem[idKey],
        },
      }),
      {
        replace: true,
      },
    );
  }, [items]);

  return {
    idFromURL,
  };
}
