import { Contribution } from '@/entities/return';
import { useGetApiCapitalInvestmentCommitmentsQuery } from '@/entities/return/api/capitalInvestmentObjectsEnhancedApi';
import { InvestmentObjectUnitPrice } from '@/entities/return/api/capitalInvestmentObjectsGeneratedApi';
import { mapListToIds } from '@/shared/lib/listHelpers';
import { cn } from '@/shared/lib/css/cn';
import { AnimationLoader } from '@/stories/AnimationLoader/AnimationLoader';
import { Tumbler } from '@/stories/Tumbler/Tumbler';
import {
  AllocationProvider,
  useAllocationContext,
} from 'bundles/REconcile/components/AllocationProvider';
import styles from 'bundles/REturn/components/Ownership/modals/bulkAddModal.module.scss';
import CapitalInvestmentsEditor, {
  TEditableCapitalInvestments,
} from 'bundles/REturn/components/Ownership/modals/capitalInvestmentsEditor/CapitalInvestmentsEditor';
import {
  capitalInvestmentSumMapper,
  resolveValidContribution,
} from 'bundles/REturn/components/Ownership/modals/capitalInvestmentsEditor/utils';
import EnterEntriesStep from 'bundles/REturn/components/Ownership/modals/disAndContriubutionsModals/EnterEntriesStep';
import EnterTotalFormStep from 'bundles/REturn/components/Ownership/modals/disAndContriubutionsModals/EnterTotalFormStep';
import ProgressDivider from 'bundles/REturn/components/Ownership/modals/disAndContriubutionsModals/ProgressDivider';
import { groupBy, partition } from 'lodash-es';
import React, { useCallback, useEffect, useMemo } from 'react';

interface Props {
  capitalInvestments: TEditableCapitalInvestments[];
  onCapitalInvestmentsChange: React.Dispatch<
    React.SetStateAction<TEditableCapitalInvestments[]>
  >;
  onValidChange: (valid: boolean) => void;
  unitPrices: InvestmentObjectUnitPrice[];
  investmentClass: Contribution['investmentClass'];
  onToggleInvestmentClass: () => void;
}

function EnterContibutionsStep({
  capitalInvestments,
  onCapitalInvestmentsChange,
  onValidChange,
  unitPrices,
  investmentClass,
  onToggleInvestmentClass,
}: Props) {
  const {
    data: capitalInvestmentCommitments,
    isFetching: isFetchingCapitalInvestmentCommitments,
  } = useGetApiCapitalInvestmentCommitmentsQuery({
    investmentIds: mapListToIds(capitalInvestments),
  });

  const groupedCommitments = groupBy(
    capitalInvestmentCommitments?.filter((commit) => commit.signedDate != null),
    'investmentId',
  );

  const { someZeroBasedUnitPrices } = useMemo(() => {
    const [classAUnitPrices, classBUnitPrices] = partition(
      unitPrices,
      (unitPrice) => unitPrice.investmentClass === 'a',
    );

    const someZeroBasedClassAUnitPrices = classAUnitPrices.some(
      (unitPrice) => unitPrice.priceCents === 0,
    );
    const someZeroBasedClassBUnitPrices = classBUnitPrices.some(
      (unitPrice) => unitPrice.priceCents === 0,
    );

    return {
      someZeroBasedUnitPrices:
        someZeroBasedClassAUnitPrices || someZeroBasedClassBUnitPrices,
    };
  }, [unitPrices]);

  const {
    total,
    setTotal,
    allocatedAmount,
    allItemsAreAllocated,
    totalValid,
    allocationFlags,
  } = useAllocationContext()!;

  const resolveValid = () => {
    if (someZeroBasedUnitPrices) {
      const { invalid, someZeroBasedCapitalInvestments } =
        resolveValidContribution({
          capitalInvestments,
        });

      if (invalid) return false;

      if (someZeroBasedCapitalInvestments) {
        return (
          allocationFlags.isFullyAllocated &&
          !capitalInvestments.some((ci) => ci.date == null)
        );
      }
    }

    return (
      totalValid &&
      allocationFlags.isFullyAllocated &&
      !capitalInvestments.some((ci) => ci.date == null) &&
      allItemsAreAllocated
    );
  };

  const valid = resolveValid();

  const resetCapitalInvestments = useCallback(() => {
    onCapitalInvestmentsChange((prev) =>
      prev.map((item) => ({ ...item, unitsQuantity: undefined, amount: 0 })),
    );
  }, [onCapitalInvestmentsChange]);

  useEffect(() => {
    resetCapitalInvestments();
  }, [investmentClass]);

  useEffect(() => {
    onValidChange(valid);
  }, [capitalInvestments, total, valid]);

  return (
    <>
      <div className="bg-neutral-000 px-6 pt-4">
        <EnterTotalFormStep
          total={total}
          title="Enter Total Contribution"
          stepNumber={1}
          activated={totalValid}
          setTotal={setTotal}
          slot={
            <Tumbler
              value={investmentClass === 'a'}
              onChange={onToggleInvestmentClass}
            >
              Enable the toggle to switch to Class A contributions
            </Tumbler>
          }
        />
        <EnterEntriesStep
          title="Enter your Contributions below"
          stepNumber={2}
          activated={allItemsAreAllocated && allocationFlags.isFullyAllocated}
          currentTotal={allocatedAmount}
          total={total ?? 0}
        />
      </div>
      <ProgressDivider total={total ?? 0} currentTotal={allocatedAmount} />
      <div className={cn('gap-2 p-6', styles.bulkAddModalFields)}>
        {isFetchingCapitalInvestmentCommitments ? (
          <AnimationLoader />
        ) : (
          <CapitalInvestmentsEditor
            investmentClass={investmentClass}
            capitalInvestments={capitalInvestments}
            withDateColumn
            onCapitalInvestmentsChange={onCapitalInvestmentsChange}
            unitPrices={unitPrices}
            groupedCommitments={groupedCommitments}
          />
        )}
      </div>
    </>
  );
}

export default (props: Props) => {
  const updateItem = useCallback(
    (index: number, value: number) => {
      props.onCapitalInvestmentsChange((prevState) =>
        prevState.map((ci, i) => {
          if (index === i) {
            return {
              ...ci,
              amount: value,
            };
          }
          return ci;
        }),
      );
    },
    [props.onCapitalInvestmentsChange],
  );

  return (
    <AllocationProvider
      items={props.capitalInvestments}
      total={undefined}
      updateItem={updateItem}
      sumMapper={capitalInvestmentSumMapper}
    >
      <EnterContibutionsStep {...props} />
    </AllocationProvider>
  );
};
