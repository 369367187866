import React from 'react';
import { cn } from '@/shared/lib/css/cn';
import Tag from '@/stories/Tags/Tag/Tag';

type Props = Omit<React.ComponentProps<typeof Tag>, 'endIcon'>;

export const TagWithIcon = ({ className, ...props }: Props) => (
  <Tag className={cn(className)} {...props} />
);

export default TagWithIcon;
