import { IColumn } from 'bundles/Shared/components/Table/types';
import { useMemo } from 'react';
import NameCell from 'bundles/Assets/components/Table/cellFormatters/name/NameCell';
import {
  BudgetLegalEntity,
  BudgetYear,
  OperationalBudgetsYearsResponse,
} from '@/bundles/REconcile/components/operational/types';
import { Link, NavigateOptions, useLocation, useNavigate } from '@reach/router';
import { replacePathParams } from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/crud/crudApi';
import { generateUrl, ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import { Badge } from 'stories/Badges/Badge/Badge';
import { IconButton } from 'stories/IconButton/IconButton';
import { STATUS_BADGE_COLORS } from '@/bundles/REconcile/components/operational/consts';
import { startCase } from 'lodash-es';
import { formatDate, toDate } from '@/shared/lib/formatting/dates';
import {
  ImportBudgetReq,
  ImportBudgetResponse,
  useExportBudgetMutation,
} from '@/bundles/REconcile/components/operational/api/legalEntities';
import { apiClient, requireAuthorizationHeaders } from 'lib/http';
import {
  buildIconButtonProps,
  createImportResultsNotification,
} from '@/bundles/REconcile/components/operational/utils';
import { useModal } from '@/shared/lib/hooks/useModal';
import { ImportBudgetResultModal } from '@/bundles/REconcile/components/operational/ReconcileOperational/ImportBudgetResultModal';
import useFileDialog from '@/shared/lib/hooks/useFileDialog';
import { ACCEPT_BUDGET_FILE_EXTENSION } from '@/shared/lib/browser/config';
import QuickFilterCheckList from 'bundles/Shared/components/Table/filters/QuickFilterCheckList';
import { usePostApiReconcileOperationalBudgetsMutation } from '@/bundles/REconcile/components/operational/api/reconcileOperationalEnhancedApi';
import { importFile } from '@/shared/lib/importFile';
import { useDeleteApiReconcileOperationalBudgetsById } from '@/features/reconcile/operational/deleteBudget';
import { ExportIconButton } from '@/shared/ui/ExportButton';
import { ImportIconButton } from '@/shared/ui/ImportButton';

const generateBudgetUrl = (budgetId: BudgetLegalEntity['budgetId']) => {
  const url = generateUrl(
    ROUTES_ROOT.reconcile.operational.budgetTable.fullPath,
    { pathParams: { budgetId: String(budgetId) } },
  );
  return url;
};

export const importBudget = async (params: ImportBudgetReq) => {
  const formData = new FormData();
  formData.set('import[file]', params.file);
  formData.set('import[type]', params.type);

  const res = await apiClient.post(
    `api/reconcile/operational/budgets/${params.budgetId}/import`,
    formData,
    {
      headers: requireAuthorizationHeaders({
        'Content-Type': 'multipart/form-data',
      }),
    },
  );
  const data = res.data as ImportBudgetResponse;
  return data;
};

export function useColumnDefs(
  params: {
    currentBudget: BudgetYear;
  } & OperationalBudgetsYearsResponse['meta'],
) {
  const { currentBudget, assets, legalEntities } = params;
  const navigate = useNavigate();
  const { openFileDialog } = useFileDialog({
    multiple: false,
    accept: ACCEPT_BUDGET_FILE_EXTENSION,
  });
  const [exportBudget] = useExportBudgetMutation();
  const [handleCreateBudget, { isLoading: isBudgetCreating }] =
    usePostApiReconcileOperationalBudgetsMutation();

  const [deleteBudget] = useDeleteApiReconcileOperationalBudgetsById();

  const { pathname, search } = useLocation();
  const from = `${pathname}${search}`;
  const navigateOptions = {
    state: {
      from,
    },
  } as const satisfies NavigateOptions<unknown>;
  const { openModal } = useModal();

  const handleImportBudget = async (args: Omit<ImportBudgetReq, 'file'>) => {
    const fileList = await openFileDialog();
    if (fileList === null || fileList.length < 1) return;

    const axiosResponse = await importFile({
      file: fileList[0],
      url: `api/reconcile/operational/budgets/${args.budgetId}/import`,
      args: {
        type: args.type,
      },
    });
    const importResponse = axiosResponse?.data;

    createImportResultsNotification(importResponse);

    const res = await openModal(ImportBudgetResultModal, {
      importResponse,
    });

    if (!res) return;

    const url = generateUrl(
      ROUTES_ROOT.reconcile.operational.budgetTable.fullPath,
      {
        pathParams: { budgetId: String(args.budgetId) },
      },
    );

    return navigate(url);
  };

  const columnDefs = useMemo<IColumn<BudgetLegalEntity>[]>(
    () => [
      {
        sortable: true,
        dataField: 'asset',
        text: 'Asset',
        formatter: ({ row }) => {
          const nameCell = <NameCell asset={row.asset} />;
          if (!row.budgetId) return nameCell;

          return (
            <Link
              to={replacePathParams(
                ROUTES_ROOT.reconcile.operational.budgetTable.fullPath,
                {
                  budgetId: String(row.budgetId),
                },
              )}
              state={navigateOptions.state}
            >
              {nameCell}
            </Link>
          );
        },
        filterComponent: ({ filterModel, setFilterModel, column }) => (
          <QuickFilterCheckList
            column={column}
            items={assets}
            getId={(item) => item.id}
            getLabel={(item) => item.name}
            popoverProps={{
              placement: 'bottom-start',
            }}
            filterModel={{
              ...filterModel,
              asset: assets.filter(
                (a) => filterModel.assetIds?.includes(a.id) ?? false,
              ),
            }}
            setFilterModel={(newFilterModel) => {
              setFilterModel({
                ...filterModel,
                assetIds: newFilterModel.asset?.map((a) => a.id),
              });
            }}
            search
          />
        ),
      },
      {
        sortable: true,
        dataField: 'legal_entity',
        text: 'Legal Entity',
        formatter: ({ row }) => row.name,
        filterComponent: ({ filterModel, setFilterModel, column }) => (
          <QuickFilterCheckList
            column={column}
            items={legalEntities}
            getId={(item) => item.id}
            getLabel={(item) => item.name}
            popoverProps={{
              placement: 'bottom-start',
            }}
            filterModel={{
              ...filterModel,
              legal_entity: legalEntities.filter(
                (le) => filterModel.legalEntityIds?.includes(le.id) ?? false,
              ),
            }}
            setFilterModel={(newFilterModel) => {
              setFilterModel({
                ...filterModel,
                legalEntityIds: newFilterModel.legal_entity?.map((le) => le.id),
              });
            }}
            search
          />
        ),
      },
      {
        sortable: true,
        dataField: 'state',
        text: 'Status',
        formatter: ({ row }) => (
          <Badge
            classes={{
              value: STATUS_BADGE_COLORS[row.state],
            }}
          >
            {startCase(row.state)}
          </Badge>
        ),
      },
      {
        sortable: true,
        dataField: 'updated_at',
        text: 'Last Update',
        formatter: ({ row }) => (
          <span className="inline-regular text-neutral-500">
            {formatDate(toDate(row.updatedAt), 'MMM DD, YYYY h:mm')}
          </span>
        ),
      },
      {
        dataField: 'actions',
        text: 'Actions',
        headerClasses: 'w-[120px]',
        formatter: ({ row }) => (
          <div className="flex items-center gap-2">
            {row.budgetId ? (
              <>
                <ImportIconButton
                  {...buildIconButtonProps('Import Budget', 'importUpload')}
                  onClick={() => {
                    handleImportBudget({
                      budgetId: row.budgetId,
                      type: 'symmetre',
                    });
                  }}
                />
                <ImportIconButton
                  {...buildIconButtonProps('Import Raw Budget', 'uploadCloud')}
                  onClick={() => {
                    handleImportBudget({
                      budgetId: row.budgetId,
                      type: 'symmetre_raw',
                    });
                  }}
                />
                <ExportIconButton
                  {...buildIconButtonProps('Export Budget', 'exportDownload')}
                  onClick={() => {
                    exportBudget({
                      budgetId: row.budgetId,
                      name: row.name,
                      year: currentBudget.year,
                    });
                  }}
                />
                <IconButton
                  {...buildIconButtonProps('Edit Budget', 'edit')}
                  onClick={() => {
                    const url = generateBudgetUrl(row.budgetId);
                    navigate(url, navigateOptions);
                  }}
                />
                <IconButton
                  {...buildIconButtonProps('Delete Budget', 'trash')}
                  onClick={() => {
                    deleteBudget(row.budgetId);
                  }}
                />
              </>
            ) : (
              <IconButton
                tooltipProps={{
                  mainText: 'Add Budget',
                  classes: {
                    spanContainer: 'ml-auto',
                  },
                }}
                size="m"
                variant="secondary"
                disabled={isBudgetCreating}
                onClick={async () => {
                  const res = await handleCreateBudget({
                    body: {
                      legal_entity_id: row.id,
                      year: currentBudget.year,
                    },
                  });
                  if ('data' in res) {
                    const url = generateBudgetUrl(res.data.id);
                    navigate(url, navigateOptions);
                  }
                }}
                iconName="addSmall"
              />
            )}
          </div>
        ),
      },
    ],
    [...Object.values(params), isBudgetCreating, from],
  );

  return columnDefs;
}
