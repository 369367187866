import React, { FC, SetStateAction } from 'react';
import { cn } from '@/shared/lib/css/cn';
import { IFormItem } from '@/bundles/Shared/components/GroupForm/types/types';
import FCStepCore from '@/bundles/Shared/components/GroupForm/FormComponent/FCStepCore';

interface IFCStepsNumberProps {
  formItem: IFormItem;
  formData: object;
  setFormData: SetStateAction<object>;
  loading: string;
  validate: object;
  setValidate: SetStateAction<object>;
  formItems: IFormItem[];
  styles?: {
    wrapper?: string;
    stepWrapper?: string;
    gap?: string;
    transparent?: string;
  };
  title: string;
  required: boolean;
}

const FCStepsWithoutNumber: FC<IFCStepsNumberProps> = ({
  formData,
  setFormData,
  loading,
  formItems,
  styles,
  validate,
  setValidate,
  title,
  required,
}) => {
  return (
    <div className="mb-m">
      <div
        className={cn(
          'dark-60 body-semibold mb-s',
          required && 'required-title',
        )}
      >
        {title}
      </div>
      <FCStepCore
        formData={formData}
        setFormData={setFormData}
        loading={loading}
        validate={validate}
        setValidate={setValidate}
        formItems={formItems}
        required={required}
        title={title}
        styles={styles}
      />
    </div>
  );
};

export default FCStepsWithoutNumber;
