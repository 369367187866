import React from 'react';
import { SectionField } from 'stories/Field/FieldsWrappers';
import { RadioGroup } from 'stories/RadioButton/RadioGroup';
import { FieldPath, FieldValues } from 'react-hook-form/dist/types';
import { Control, useController, useFormContext } from 'react-hook-form';
import { NumericColumnSettingsForm } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/form';
import { ListOption } from 'stories/Checkbox/CheckList';
import { Input } from '@/stories/FormControls/Inputs/Input/Input';
import { Field } from '@/stories/Field/Field';

const HEADER_OPTIONS = [
  {
    value: 'title_and_date',
    label: 'Title and Date Label',
  },
  {
    value: 'title_only',
    label: 'Title only',
  },
  {
    value: 'date_label_only',
    label: 'Date Label only',
  },
  {
    value: 'title_and_custom_sublabel',
    label: 'Title and Custom Sub Label',
  },
] as const;
type HeaderOption = (typeof HEADER_OPTIONS)[number]['value'];

const HEADER_OPTION_TO_FIELD_MAP = {
  title_and_date: {
    hide_title: false,
    hide_subtitle: false,
    subtitle: null,
  },
  title_only: {
    hide_title: false,
    hide_subtitle: true,
    subtitle: null,
  },
  date_label_only: {
    hide_title: true,
    hide_subtitle: false,
    subtitle: null,
  },
  title_and_custom_sublabel: {
    hide_title: false,
    hide_subtitle: false,
    subtitle: '',
  },
} as const satisfies Record<HeaderOption, NumericColumnSettingsForm['header']>;

const getHeaderOptionValue = (header: NumericColumnSettingsForm['header']) => {
  if (header?.subtitle != null) {
    return 'title_and_custom_sublabel';
  }
  if (header?.hide_title) {
    return 'date_label_only';
  }
  if (header?.hide_subtitle) {
    return 'title_only';
  }
  return 'title_and_date';
};

export function ColumnHeaderField<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({ name, control }: { name: TName; control: Control<TFieldValues> }) {
  const { register } = useFormContext<TFieldValues>();
  const { field } = useController({
    control,
    name,
  });

  const handleChange = (o: ListOption) => {
    field.onChange({ ...HEADER_OPTION_TO_FIELD_MAP[o.value as HeaderOption] });
  };

  return (
    <SectionField
      labelText="Column Header Settings "
      note="You can control what will be displayed in the table header"
    >
      <RadioGroup
        onChange={handleChange}
        options={HEADER_OPTIONS}
        value={getHeaderOptionValue(field.value)}
      />
      {field.value?.subtitle != null && (
        <Field labelText="Custom Sub Label">
          <Input
            placeholder="Enter Custom Sub Label"
            {...register(`${name}.subtitle`)}
          />
        </Field>
      )}
    </SectionField>
  );
}
