import {
  useGetLegalEntityCodeFromURL,
  useGetForecastNameFromURL,
  useGetYearFromURL,
} from '@/entities/reconcile/forecasting/lib';
import { ForecastionBudgetTable } from '@/entities/reconcile/forecasting/widgets/ForecastingBudgetTable';
import { useForecastByName } from '@/entities/reconcile/forecasting/features/api/forecast/useForecastByName';
import { ImportIconButton } from '@/shared/ui/ImportButton';
import { ExportIconButton } from '@/shared/ui/ExportButton';
import { useExportForecastingBudget } from '@/entities/reconcile/forecasting/features/api/forecast-budgets/useExportForecastingBudget';
import { cn } from '@/shared/lib/css/cn';
import { IconButton } from '@/stories/IconButton/IconButton';
import { Tooltip } from '@/stories/Tooltip/Tooltip';
import { useImportForecastingBudget } from '@/entities/reconcile/forecasting/features/api/forecast-budgets/useImportForecastingBudget';
import { useForecastBudgetsByLegalEntityCode } from '@/entities/reconcile/forecasting/features/api/forecast/useForecastBudgetsByLegalEntityCode';
import StatusDropdown from '@/bundles/REconcile/underwritting/ui/StatusDropdown';
import { useUpdateForecastingBudgetState } from '@/entities/reconcile/forecasting/features/api/forecast-budgets/useUpdateForecastingBudgetState';
import { LEGAL_ENTITY_STATUS_MAP } from '@/bundles/REconcile/underwritting/UnderwritingBudgetPage';
import { useForecastBudget } from '@/entities/reconcile/forecasting/features/api/forecast/useForecastBudget';
import { useCleanForecastingBudget } from '@/entities/reconcile/forecasting/features/api/forecast-budgets/useCleanForecastingBudget';
import { CleanForecastingBudgetDropdown } from '@/entities/reconcile/forecasting/features/ui/CleanForecastingBudgetDropdown';
import { useCreateForecastBudget } from '@/entities/reconcile/forecasting/features/api/forecast/useCreateForecastBudget';

const ForecastingBudgetPage = () => {
  const forecastName = useGetForecastNameFromURL();
  const { forecast, isLoading: isForecastLoading } =
    useForecastByName(forecastName);
  const { createFromSource, isLoading: isLoadingCreateFromSource } =
    useCreateForecastBudget();
  const legalEntityCode = useGetLegalEntityCodeFromURL();
  const year = useGetYearFromURL();

  const { data: budgets, isLoading: isLoadingBudgets } =
    useForecastBudgetsByLegalEntityCode(
      forecast?.id ?? '',
      Number(year),
      legalEntityCode,
    );
  const { budget, isLoading: isLoadingBudget } = useForecastBudget(
    forecast?.id ?? '',
    budgets[0]?.id ?? '',
  );

  const [downloadFile, { isLoading: isLoadingExport }] =
    useExportForecastingBudget();
  const [importFile] = useImportForecastingBudget();

  const handleImport = async () => {
    if (!forecast || !budget) return;

    await importFile(forecast.id, budget.id);
  };

  const handleExport = () => {
    if (!forecast || !budget) return;

    downloadFile(forecast.id, budget.id);
  };

  const [updateBudgetState, { isLoading: isLoadingUpdate }] =
    useUpdateForecastingBudgetState();

  const handleUpdateBudget = (state: 'draft' | 'published') => {
    if (!forecast || !budget) return;

    updateBudgetState({
      id: budget.id,
      forecastId: forecast.id,
      state,
    });
  };

  const {
    cleanBudget,
    deleteBudget,
    isLoading: isLoadingClean,
  } = useCleanForecastingBudget();

  const handleCleanBudget = () => {
    if (!forecast || !budget) return;

    cleanBudget({
      forecastId: forecast.id,
      budgetId: budget.id,
    });
  };

  const handleDeleteBudget = () => {
    if (!forecast || !budget) return;

    deleteBudget({
      forecastId: forecast.id,
      budgetId: budget.id,
    });
  };

  const handleCreateFromSource = async () => {
    if (!forecast || !budget) return;

    await createFromSource(forecast.id, budget.id, {
      year: budget.year.toString(),
    });
  };

  const isEmptyBudget =
    !budget ||
    (budget.budgetFinancialCategoryRows.length === 0 &&
      budget.budgetMetricRows.length === 0);
  const isLoading = isForecastLoading || isLoadingBudgets || isLoadingBudget;

  return (
    <div className="flex h-full flex-col bg-neutral-100">
      {budget && (
        <div
          className={cn(
            'flex items-start justify-between gap-4 border-b border-neutral-200 bg-neutral-000 px-6 py-3',
          )}
        >
          <div className="flex h-full min-h-[56px] items-center gap-4">
            {budget.legalEntity != null && (
              <div className="gap-0.5 flex flex-col">
                <p className="header5-bold text-neutral-900">
                  {budget.legalEntity.name}
                </p>
                <p className="label-regular text-neutral-500">
                  {budget.legalEntity.assetName}
                </p>
              </div>
            )}
          </div>

          <div className="flex items-center gap-2">
            {budget.state !== 'no_budget' && (
              <StatusDropdown
                onStatusSelect={handleUpdateBudget}
                dropdownDisabled={isLoadingUpdate}
                // @ts-expect-error TODO: BudgetStatusDropdown UI Lib component
                status={budget.state}
              >
                <IconButton
                  disabled={isLoadingUpdate}
                  classes={{
                    icon: LEGAL_ENTITY_STATUS_MAP[budget.state].className,
                  }}
                  iconName={LEGAL_ENTITY_STATUS_MAP[budget.state].icon}
                  size="l"
                  variant="secondary"
                />
              </StatusDropdown>
            )}
            <Tooltip titleText="Import the budget">
              <ImportIconButton size="l" onClick={handleImport} />
            </Tooltip>
            <Tooltip
              titleText={isLoadingExport ? 'Exporting...' : 'Export the budget'}
            >
              <ExportIconButton
                size="l"
                onClick={handleExport}
                disabled={isLoadingExport}
              />
            </Tooltip>
            {isEmptyBudget && (
              <Tooltip
                titleText="Create budget from Source"
                placement="top-end"
                arrowPosition="end"
              >
                <IconButton
                  isLoading={isLoadingCreateFromSource}
                  onClick={handleCreateFromSource}
                  iconName="add"
                  size="l"
                  variant="secondary"
                />
              </Tooltip>
            )}
            {!isEmptyBudget && (
              <Tooltip titleText="Clean/Delete the budget">
                <CleanForecastingBudgetDropdown
                  dropdownDisabled={isLoadingClean}
                  onClean={handleCleanBudget}
                  onDelete={handleDeleteBudget}
                >
                  <IconButton
                    disabled={isLoadingClean}
                    iconName="trash"
                    size="l"
                    variant="secondary"
                  />
                </CleanForecastingBudgetDropdown>
              </Tooltip>
            )}
          </div>
        </div>
      )}

      {forecast && budget && (
        <ForecastionBudgetTable
          budgetId={budget.id}
          forecastId={forecast.id}
          isLoading={isLoading}
        />
      )}
    </div>
  );
};

export default ForecastingBudgetPage;
