import { ReportDashboardType } from '@/bundles/Shared/entities/dashboard/model/types/types';
import type { IThinTabItem } from '@/stories/Tabs/ThinTabGroup/ThinTabGroup';

export const REPORT_GROUP_TYPES_TABS = [
  {
    id: 'recurring',
    label: 'Recurring',
    disabled: true,
  },
  {
    id: 'one-off',
    label: 'One Off',
  },
] as const satisfies IThinTabItem[];

export const REPORT_GROUP_TEMPLATE_TYPE_TABS = [
  {
    id: ReportDashboardType.EAGLE_EYE,
    label: 'Eagle Eye',
  },
  {
    id: ReportDashboardType.OBJECT,
    label: 'Object',
    disabled: true,
  },
] as const satisfies IThinTabItem[];

export enum ReportingEagleEyeObjectsConfig {
  Asset = 'asset',
  Segment = 'segment',
  ExcludedLegalEntity = 'excluded_legal_entity',
}

export type ReportingEagleEyeObject =
  | {
      id: number;
      type:
        | ReportingEagleEyeObjectsConfig.Asset
        | ReportingEagleEyeObjectsConfig.Segment;
    }
  | {
      id: string;
      type: ReportingEagleEyeObjectsConfig.ExcludedLegalEntity;
    };
