import { Button } from '@/stories/Button/Button';
import React from 'react';
import { AccruedValuesEditor } from '@/bundles/REturn/components/Ownership/modals/addAccruedModal/AccruedValuesEditor';
import { CapitalInvestment } from '@/entities/return';

export const EnterAccruedValuesStep = ({
  selectedCapitalInvestments,
  setSelectedCapitalInvestments,
}: {
  selectedCapitalInvestments: CapitalInvestment[];
  setSelectedCapitalInvestments: (value: CapitalInvestment[]) => void;
}) => {
  const resetValues = () => {
    setSelectedCapitalInvestments(
      selectedCapitalInvestments.map((item) => ({
        ...item,
        startDate: undefined,
        endDate: undefined,
        periodType: undefined,
      })),
    );
  };

  return (
    <>
      <div className="flex justify-between gap-l">
        <div>
          <span className="inline-semibold text-dark-80">
            Enter Accrued Details
          </span>
          <span className="text-danger-055"> *</span>
          <p className="pt-xs text-xs text-neutral-500">
            Accrued entries will be created automatically from the Start Date,
            depending on the set period (every month or quarter). If no Stop
            Date is set, new accrued entries will be created moving forward
            also.
          </p>
        </div>
        <div>
          <Button variant="secondary" size="xs" onClick={resetValues}>
            Reset
          </Button>
        </div>
      </div>
      <div className="pt-l">
        <AccruedValuesEditor
          selectedCapitalInvestments={selectedCapitalInvestments}
          setSelectedCapitalInvestments={setSelectedCapitalInvestments}
        />
      </div>
    </>
  );
};
