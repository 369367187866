import VerticalSeparator from '@/bundles/Shared/components/VerticalSeparator/VerticalSeparator';
import type { EagleEyeBoard } from 'bundles/Shared/entities/dashboard/model/types/types';
import { CreateBoardButton } from '@/bundles/Shared/features/dashboard/boardCRUD';
import { useMoveBoards } from '@/bundles/Shared/features/dashboard/moveBoards';
import { ThinTabGroup } from '@/stories/Tabs/ThinTabGroup/ThinTabGroup';

import {
  OnDragEndResponder,
  Draggable,
  DragDropContext,
  Droppable,
} from '@hello-pangea/dnd';
import { arrayMoveImmutable } from 'array-move';
import { useState, useEffect, Fragment } from 'react';
import { DashboardBoardActions } from '@/bundles/Shared/widgets/dashboard/board/ui/DashboardBoardActions';
import { cn } from '@/shared/lib/css/cn';

type Board = Pick<EagleEyeBoard, 'id' | 'slug'>;

interface Props extends PropsWithClassName {
  boards: Board[];
  selectedBoardId: EagleEyeBoard['id'] | undefined;
  onBoardChange?: (board: Board | undefined | null) => void;
  onBoardUpdate?: (board: Board | undefined | null) => void;
  classes?: {
    content?: string;
  };
}

export const DashboardBoardNavigationEditable = (props: Props) => {
  const { boards, selectedBoardId, className, onBoardChange, onBoardUpdate } =
    props;
  const [orderedBoards, setOrderedBoards] = useState(boards);
  const moveBoard = useMoveBoards();
  const [selectedBoard, setSelectedBoard] = useState<EagleEyeBoard | null>(
    null,
  );

  useEffect(() => {
    // sync local boards for optimistically updating the UI
    if (orderedBoards !== boards) {
      setOrderedBoards(boards);
    }
  }, [boards]);

  const handleDragEnd: OnDragEndResponder = (e) => {
    const { destination, source } = e;
    if (!destination) {
      return;
    }
    if (destination.index === source.index) {
      return;
    }
    const newBoards = arrayMoveImmutable(
      boards,
      source.index,
      destination.index,
    );
    setOrderedBoards(newBoards);
    moveBoard({
      boards: newBoards,
    });
  };

  const handleBoardClick = (board: Board | undefined | null) => {
    if (!onBoardChange) {
      return;
    }

    onBoardChange(board);
  };

  const handleBoardCreate = (board: Board | undefined | null) => {
    if (!onBoardChange) {
      return;
    }

    onBoardChange(board);
  };

  const handleBoardUpdate = (board: EagleEyeBoard) => {
    if (!onBoardUpdate) {
      return;
    }

    onBoardUpdate(board);
  };

  const handleBoardDelete = () => {
    if (!onBoardChange) {
      return;
    }

    onBoardChange(boards[0]);
  };

  const renderBoardTab = (board: EagleEyeBoard, index: number) => {
    return (
      <Fragment key={board.id}>
        <Draggable draggableId={board.id} index={index}>
          {(provided) => (
            <ThinTabGroup.Item
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              ref={provided.innerRef}
              id={board.id}
              key={board.id}
              selected={board.id === selectedBoardId}
              onContextMenu={(e) => {
                e.preventDefault();
                setSelectedBoard(board);
              }}
              onClick={() => handleBoardClick(board)}
            >
              {board.name}
            </ThinTabGroup.Item>
          )}
        </Draggable>
        {index !== boards.length - 1 && <VerticalSeparator />}
      </Fragment>
    );
  };

  return (
    <div
      className={cn('flex items-center rounded-xl bg-neutral-200', className)}
    >
      <DashboardBoardActions
        visible={Boolean(selectedBoard)}
        onClickOutside={() => setSelectedBoard(null)}
        onHidden={() => setSelectedBoard(null)}
        board={selectedBoard}
        reference={document.getElementById(selectedBoard?.id ?? '')}
        onBoardDelete={handleBoardDelete}
        onBoardUpdate={handleBoardUpdate}
      />
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="boards" direction="horizontal">
          {({ innerRef, droppableProps, placeholder }) => (
            <ThinTabGroup.Container ref={innerRef} {...droppableProps}>
              {orderedBoards.map(renderBoardTab)}
              {placeholder}
            </ThinTabGroup.Container>
          )}
        </Droppable>
      </DragDropContext>
      <CreateBoardButton onBoardCreate={handleBoardCreate} />
    </div>
  );
};
