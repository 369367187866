import {
  ReportThirdPartyExtractionConnectionDto,
  ReportThirdPartyExtractionConnectionManualUploadDto,
  ReportThirdPartyExtractionEmailConnectionInboundEmailDto,
  ReportThirdPartyExtractionSftpConnectionSyncDto,
} from '@/shared/api/settingsReportConnectionsGeneratedApi';
import { useDeleteExtraction } from '@/features/report/connections/deleteExtraction/lib';
import { IconButton } from '@/stories/IconButton/IconButton';

export const DeleteExtractionIconButton = ({
  extractionId,
  connectionId,
  extractionType,
}: Parameters<typeof useDeleteExtraction>[0] & {
  extractionId:
    | ReportThirdPartyExtractionEmailConnectionInboundEmailDto['id']
    | ReportThirdPartyExtractionConnectionManualUploadDto['id']
    | ReportThirdPartyExtractionSftpConnectionSyncDto['id'];
  connectionId: ReportThirdPartyExtractionConnectionDto['id'];
}) => {
  const [deleteExtracion, options] = useDeleteExtraction({
    extractionType,
  });

  return (
    <IconButton
      disabled={options.isLoading}
      variant="secondary"
      iconName="trash"
      onClick={() =>
        deleteExtracion({
          id: extractionId,
          connectionId,
        })
      }
    />
  );
};
