import React, { useState } from 'react';
import { cn } from '@/shared/lib/css/cn';
import { connect } from 'react-redux';
import { formatDate } from '@/shared/lib/formatting/dates';
import { Button } from 'stories/Button/Button';
import { IconButton } from 'stories/IconButton/IconButton';
import { LinkButton } from 'stories/LinkButton/LinkButton';
import { Modal } from 'stories/Modals/Modal/Modal';
import { Tumbler } from 'stories/Tumbler/Tumbler';
import { updateDealPathGoal } from '@/bundles/Settings/actions/dealPathSettings';
import { CardWrapper } from 'bundles/Shared/components/Wrappers/CardWrapper';
import Table from 'bundles/Shared/components/Table/Table';
import { IColumn } from 'bundles/Shared/components/Table/types';

interface DealPathGoal {
  active: boolean;
  id: number;
  name: string;
  goals: {
    [key: string]: number;
  };
  updated_at: string;
}

interface PropertyType {
  name: string;
  active: boolean;
  id: number;
}

const tableColumns = ({
  propertyTypes,
  toggleActive,
  onClick,
}: {
  propertyTypes: string[];
  toggleActive: (row: DealPathGoal) => void;
  onClick: (row: DealPathGoal) => void;
}): IColumn<DealPathGoal>[] => [
  {
    dataField: 'id',
    text: 'ID',
    hidden: true,
  },
  {
    dataField: 'active',
    text: 'Years',
    formatter: ({ row }) => (
      <Tumbler
        className={cn(
          'font-weight-500 font-16',
          'mr-10',
          row.active ? 'dark-60' : 'light-60',
        )}
        defaultChecked={row.active}
        onChange={() => toggleActive(row)}
      >
        {row.name}
      </Tumbler>
    ),
  },
  ...propertyTypes.map((propertyType) => ({
    dataField: propertyType,
    text: propertyType,
    formatter: ({ row }) => (
      <div>
        {row.goals[propertyType] && (
          <div className="flex justify-between">
            <span className={cn({ 'light-60 font-weight-600': !row.active })}>
              {row.goals[propertyType]} Deals
            </span>
            <IconButton iconName="edit" onClick={() => onClick(row)} />
          </div>
        )}

        {!row.goals[propertyType] && (
          <LinkButton onClick={() => onClick(row)}>Set Goal</LinkButton>
        )}
      </div>
    ),
  })),
  {
    dataField: 'total',
    text: 'Total',
    formatter: ({ row }) => (
      <span className="light-60">
        {Object.values(row.goals).reduce((acc, i) => acc + Number(i), 0)}
      </span>
    ),
  },
  {
    dataField: 'updated_at',
    text: 'Updated At',
    formatter: ({ row }) => (
      <span className="light-60">{formatDate(row.updated_at, 'MMMM, DD')}</span>
    ),
  },
];

const DealTargets = (props: {
  goals: DealPathGoal[];
  propertyTypes: string[];
  update: (id: number, data: any) => void;
}) => {
  const [selectedGoal, setSelectedGoal] = useState<DealPathGoal | null>(null);
  const { goals, propertyTypes } = props;

  const submit = async (e) => {
    e.preventDefault();
    const data = propertyTypes.reduce((acc, type) => {
      acc[type] = Number(e.target[type].value);
      return acc;
    }, {});
    await props.update(selectedGoal?.id, { goals: data });
    setSelectedGoal(null);
  };

  return (
    <div>
      <CardWrapper className="bg-light">
        <div className="px-5 py-3">
          <h3>Target # of Deals</h3>
        </div>
        <div className="px-5 py-5">
          <div className="table-wrap">
            <div className="table-container table-responsive">
              <Table
                items={goals}
                columns={tableColumns({
                  toggleActive: (row) =>
                    props.update(row.id, { active: !row.active }),
                  onClick: setSelectedGoal,
                  propertyTypes,
                })}
              />
            </div>
          </div>
        </div>
      </CardWrapper>
      {selectedGoal && (
        <Modal
          header="Set a goal"
          toggle={() => setSelectedGoal(null)}
          actions={
            <Button
              form="dealTargetsForm"
              variant="success"
              fluid
              type="submit"
            >
              Submit
            </Button>
          }
        >
          <form id="dealTargetsForm" onSubmit={submit}>
            {propertyTypes.map((propertyType) => (
              <div
                className="from-group mb-2"
                key={`${selectedGoal.id}-${propertyType}`}
              >
                <label htmlFor={propertyType}>{propertyType}</label>
                <input
                  className="form-control"
                  defaultValue={selectedGoal?.goals[propertyType]}
                  id={propertyType}
                  name={propertyType}
                  type="number"
                />
              </div>
            ))}
          </form>
        </Modal>
      )}
    </div>
  );
};

const mapStateToProps = ({ dealPathSettings }) => ({
  goals: dealPathSettings.goals,
  propertyTypes: dealPathSettings.propertyTypes
    .filter((pt: PropertyType) => pt.active)
    .map((pt: PropertyType) => pt.name),
});

const mapActionCreators = {
  update: updateDealPathGoal,
};
export default connect(mapStateToProps, mapActionCreators)(DealTargets);
