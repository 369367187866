import { useGetApiReconcileForecastingForecastsByForecastIdBudgetsQuery } from '@/entities/reconcile/forecasting/api/reconcileForecastingEntitiesEnchancedApi';
import { generateUrl, ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';

export const useForecastBudgets = (
  forecastId: string | undefined,
  year: number | undefined,
) => {
  return useGetApiReconcileForecastingForecastsByForecastIdBudgetsQuery(
    {
      forecastId: forecastId ?? '',
      year: year ?? 0,
    },
    {
      skip: !forecastId || !year,
      selectFromResult: ({ data, isLoading, isFetching, ...options }) => ({
        ...options,
        budgets:
          data?.items?.map((budget) => ({
            ...budget,
            legalEntityCode: budget.legalEntity.code,
            pageViewUrl: generateUrl(
              ROUTES_ROOT.reconcile.forecasting.budgets.budget.fullPath,
              {
                pathParams: {
                  forecastName: budget.forecast.name,
                  year: budget.year.toString(),
                  legalEntityCode: budget.legalEntity.code,
                },
              },
            ),
          })) ?? [],
        isLoading,
        isFetching,
        budgetsCount: data?.items?.length ?? 0,
      }),
    },
  );
};
