import { SettingsFormModal } from '@/bundles/Shared/components/SettingsModal/SettingsModal';
import { reportingEnhancedApi } from '@/entities/reporting/api/reportingEnhancedApi';
import type { ReportGroupDto } from '@/entities/reporting/api/reportingGeneratedApi';
import { useModal } from '@/shared/lib/hooks/useModal';
import { Field } from '@/stories/Field/Field';
import { Input } from '@/stories/FormControls/Inputs/Input/Input';
import { Textarea } from '@/stories/Textarea/Textarea';
import { FieldsContainer } from '@/stories/Field/Field';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFormContext } from 'react-hook-form';
import * as yup from 'yup';

const SCHEMA = yup.object().shape({
  name: yup.string().required(),
  description: yup.string(),
});

type Form = yup.InferType<typeof SCHEMA>;

const Fields = () => {
  const { register } = useFormContext<Form>();
  return (
    <FieldsContainer>
      <Field labelText="Name" required>
        <Input {...register('name')} />
      </Field>
      <Field labelText="Description">
        <Textarea
          placeholder="Enter Description"
          {...register('description')}
        />
      </Field>
    </FieldsContainer>
  );
};

const Modal = (
  props: Omit<React.ComponentProps<typeof SettingsFormModal<Form>>, 'fields'> &
    Pick<ReportGroupDto, 'name' | 'description'>,
) => {
  return <SettingsFormModal<Form> fields={<Fields />} {...props} />;
};

export const useUpdateReportGroup = () => {
  const [updateGroup, options] =
    reportingEnhancedApi.usePutApiReportBuilderReportGroupsByIdMutation();

  const { openModal } = useModal();

  const handleUpdate = async (
    group: Pick<ReportGroupDto, 'id' | 'name' | 'description'>,
  ) => {
    const res = await openModal(Modal, {
      description: group.description,
      name: group.name,
      formProps: {
        defaultValues: {
          name: group.name ?? '',
          description: group.description ?? '',
        },
        resolver: yupResolver(SCHEMA),
        mode: 'all',
      },
    });

    if (res == null) return;

    updateGroup({
      id: group.id,
      body: {
        description: res.description,
        name: res.name,
      },
    });
  };

  return [handleUpdate, options] as const;
};
