import { useAssertNullableContext } from '@/shared/lib/hooks/context/useAssertNullableContext';
import { createContext } from 'react';

export type ReportingEntityStageType = 'report' | 'template';

export const ReportingEntityStageContext =
  createContext<ReportingEntityStageType | null>(null);

export const useReportingEntityStageContext = useAssertNullableContext(
  ReportingEntityStageContext,
);
