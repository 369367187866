import {
  settingsReportBuilderTemplatesGeneratedApi,
  type DeleteApiSettingsReportBuilderTemplatesByIdApiResponse,
  type PostApiSettingsReportBuilderEagleEyeTemplatesApiResponse,
  type PostApiSettingsReportBuilderEagleEyeTemplatesByIdDuplicateApiResponse,
  type PostApiSettingsReportBuilderTemplatesApiResponse,
  type PostApiSettingsReportBuilderTemplatesByIdDuplicateApiResponse,
  type PutApiSettingsReportBuilderEagleEyeTemplatesByEagleEyeTemplateIdGroupsAndGroupIdWidgetSectionsIdApiResponse,
  type PutApiSettingsReportBuilderEagleEyeTemplatesByIdApiResponse,
  type PutApiSettingsReportBuilderTemplatesByIdApiResponse,
  type PutApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndGroupIdWidgetSectionsIdApiResponse,
  type ReportBuilderTemplateGroupDto,
} from '@/entities/report/reportBuilder/api/settingsReportBuilderTemplatesGeneratedApi';
import { createApiTagType } from '@/shared/lib/api/lib';
import { sortByPosition } from '@/shared/lib/listHelpers/toSorted';
import { EntityToastrCreator } from '@/shared/lib/toastr/entityToastrCreator';
import { arrayMoveImmutable } from 'array-move';

const templateToastr = new EntityToastrCreator('Report Builder Template');
const widgetToastr = new EntityToastrCreator('Widget');
const tagToastr = new EntityToastrCreator('Tag');

export const settingsReportBuilderTemplatesEnhancedApi =
  settingsReportBuilderTemplatesGeneratedApi.enhanceEndpoints({
    addTagTypes: [
      'allTemplates',
      'template',
      'template:eagleEye',

      'meta:assets',
      'meta:assets:eagleEye',
      'meta:tags',
      'widget:evaluate',
      'widget:preview',
      'widget:copyable_list_from_template',
      'widget:copyable_list_from_object_level_dashboard',
    ],
    endpoints: {
      /**
       * @deprecated use `getApiSettingsReportBuilderGotenbergTemplatesById` instead
       */
      getApiSettingsReportBuilderTemplatesById: {
        providesTags: (_, __, args) => [createApiTagType('template', args.id)],
      },
      getApiSettingsReportBuilderGotenbergTemplatesById: {
        providesTags: (_, __, args) => [createApiTagType('template', args.id)],
      },
      getApiSettingsReportBuilderTemplatesByTemplateIdCopyableWidgetSections: {
        providesTags: ['widget:copyable_list_from_template'],
      },
      postApiSettingsReportBuilderTemplatesByTemplateIdCopyableWidgetSectionsCopyToGroup:
        {
          invalidatesTags: (_, __, args) => [
            'widget:copyable_list_from_template',
            createApiTagType('template', args.templateId),
          ],
        },

      getApiSettingsReportBuilderTemplatesByTemplateIdObjectDashboardsCopyableWidgetSections:
        {
          providesTags: ['widget:copyable_list_from_object_level_dashboard'],
        },
      postApiSettingsReportBuilderTemplatesByTemplateIdObjectDashboardsCopyableWidgetSectionsCopyToGroup:
        {
          invalidatesTags: (_, __, args) => [
            'widget:copyable_list_from_object_level_dashboard',
            createApiTagType('template', args.templateId),
          ],
        },

      deleteApiSettingsReportBuilderTemplateTagsById: {
        invalidatesTags: ['meta:tags', 'allTemplates'],
        transformResponse: (
          response: DeleteApiSettingsReportBuilderTemplatesByIdApiResponse,
        ) => {
          toastr.success(tagToastr.delete().toString());
          return response;
        },
      },

      deleteApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndGroupIdWidgetSectionsId:
        {
          invalidatesTags: (_, __, args) => [
            createApiTagType('template', args.templateId),
            'widget:preview',
            'widget:evaluate',
          ],
        },
      deleteApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndId: {
        invalidatesTags: (_, __, args) => [
          createApiTagType('template', args.templateId),
          'widget:preview',
          'widget:evaluate',
        ],
        onQueryStarted(patch, { dispatch, queryFulfilled }) {
          const patchResult = dispatch(
            settingsReportBuilderTemplatesEnhancedApi.util.updateQueryData(
              'getApiSettingsReportBuilderGotenbergTemplatesById',
              {
                id: patch.templateId,
              },
              (draft) => {
                draft.groups = draft.groups.filter((g) => g.id !== patch.id);
              },
            ),
          );
          queryFulfilled.catch(patchResult.undo);
        },
      },
      postApiSettingsReportBuilderTemplatesByTemplateIdGroups: {
        invalidatesTags: (_, __, args) => [
          createApiTagType('template', args.templateId),
          'widget:preview',
          'widget:evaluate',
        ],
      },
      postApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndGroupIdWidgetSections:
        {
          invalidatesTags: (_, __, args) => [
            createApiTagType('template', args.templateId),
            'widget:preview',
            'widget:evaluate',
          ],
        },
      putApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndGroupIdWidgetSectionsId:
        {
          invalidatesTags: (_, __, args) => [
            createApiTagType('template', args.templateId),
            'widget:preview',
            'widget:evaluate',
          ],
          onQueryStarted(patch, { dispatch, queryFulfilled }) {
            const widgetId = patch.id;
            const { groupId } = patch;
            const newPosition = patch.body.position;
            const newWidth = patch.body.width_percent;
            const newGroupId = patch.body.destination_group_id;

            const patchResult = dispatch(
              settingsReportBuilderTemplatesEnhancedApi.util.updateQueryData(
                'getApiSettingsReportBuilderTemplatesById',
                {
                  id: patch.templateId,
                },
                (draft) => {
                  const currentGroup = draft.groups.find(
                    (g) => g.id === groupId,
                  );
                  if (currentGroup == null) return;

                  const currentWidget = currentGroup.widgetSections.find(
                    (w) => w.id === widgetId,
                  );

                  if (currentWidget == null) return;

                  let resultGroups: ReportBuilderTemplateGroupDto[] = [];

                  if (newGroupId == null) {
                    let newGroupWidgets = [...currentGroup.widgetSections];

                    if (newPosition != null) {
                      newGroupWidgets = arrayMoveImmutable(
                        currentGroup.widgetSections,
                        currentWidget.position.y,
                        newPosition,
                      );
                    }

                    newGroupWidgets = newGroupWidgets.map((w, wIdx) => {
                      return {
                        ...w,
                        position: {
                          ...w.position,
                          w:
                            newWidth != null && w.id === widgetId
                              ? newWidth
                              : w.position.w,
                          y: wIdx,
                        },
                      };
                    });

                    const newGroup = {
                      ...currentGroup,
                      widgetSections: newGroupWidgets,
                    };

                    resultGroups = draft.groups.map((g) => {
                      if (g.id !== groupId) return g;

                      return newGroup;
                    });
                  } else {
                    resultGroups = draft.groups.map((g) => {
                      if (g.id === currentGroup.id) {
                        return {
                          ...g,
                          widgetSections: g.widgetSections.filter(
                            (w) => w.id !== widgetId,
                          ),
                        };
                      }

                      if (g.id === newGroupId) {
                        return {
                          ...g,
                          widgetSections: g.widgetSections.toSpliced(
                            newPosition ?? currentWidget.position.y,
                            0,
                            currentWidget,
                          ),
                        };
                      }
                      return g;
                    });
                  }

                  Object.assign(draft, {
                    groups: resultGroups,
                  });
                },
              ),
            );
            queryFulfilled.catch(patchResult.undo);
          },
          transformResponse: (
            response: PutApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndGroupIdWidgetSectionsIdApiResponse,
          ) => {
            toastr.success(widgetToastr.update().toString());
            return response;
          },
        },
      putApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndId: {
        invalidatesTags: (_, __, args) => [
          createApiTagType('template', args.templateId),
          'widget:preview',
          'widget:evaluate',
        ],
        onQueryStarted(patch, { dispatch, queryFulfilled }) {
          const groupId = patch.id;
          const newPosition = patch.body.position;
          const newPageOrientation = patch.body.page_orientation;

          if (newPosition == null || newPageOrientation == null) return;

          const patchResult = dispatch(
            settingsReportBuilderTemplatesEnhancedApi.util.updateQueryData(
              'getApiSettingsReportBuilderTemplatesById',
              {
                id: patch.templateId,
              },
              (draft) => {
                const currentGroup = draft.groups.find((g) => g.id === groupId);
                if (currentGroup == null) return;

                const newGroups = arrayMoveImmutable(
                  draft.groups.toSorted(sortByPosition),
                  currentGroup.position,
                  newPosition,
                ).map((g, gIdx) => {
                  if (g.id === groupId) {
                    return {
                      ...g,
                      position: gIdx,
                      pageOrientation: newPageOrientation,
                    };
                  }
                  return { ...g, position: gIdx };
                });

                Object.assign(draft, {
                  groups: newGroups,
                });
              },
            ),
          );
          queryFulfilled.catch(patchResult.undo);
        },
      },

      postApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndGroupIdWidgetSectionsPreview:
        {
          providesTags: ['widget:preview', 'widget:evaluate'],
        },

      getApiSettingsReportBuilderTemplates: {
        providesTags: ['allTemplates'],
      },

      // #region Eagle Eye templates
      getApiSettingsReportBuilderEagleEyeTemplatesById: {
        providesTags: (_, __, args) => [
          createApiTagType('template:eagleEye', args.id),
        ],
      },
      getApiSettingsReportBuilderEagleEyeTemplatesByEagleEyeTemplateIdCopyableWidgetSections:
        {
          providesTags: ['widget:copyable_list_from_template'],
        },
      postApiSettingsReportBuilderEagleEyeTemplatesByEagleEyeTemplateIdCopyableWidgetSectionsCopyToGroup:
        {
          invalidatesTags: (_, __, args) => [
            'widget:copyable_list_from_template',
            createApiTagType('template:eagleEye', args.eagleEyeTemplateId),
          ],
        },
      getApiSettingsReportBuilderGotenbergEagleEyeTemplatesById: {
        providesTags: (_, __, args) => [
          createApiTagType('template:eagleEye', args.id),
        ],
      },
      deleteApiSettingsReportBuilderEagleEyeTemplatesById: {
        invalidatesTags: ['allTemplates'],
        onQueryStarted(patch, { dispatch, queryFulfilled }) {
          const patchResult = dispatch(
            settingsReportBuilderTemplatesEnhancedApi.util.updateQueryData(
              'getApiSettingsReportBuilderTemplates',
              {} as unknown as void,
              (draft) => {
                draft.items = draft.items.filter((t) => t.id !== patch.id);
              },
            ),
          );
          queryFulfilled.catch(patchResult.undo);
        },
        transformResponse: (
          response: DeleteApiSettingsReportBuilderTemplatesByIdApiResponse,
        ) => {
          toastr.success(templateToastr.delete().toString());
          return response;
        },
      },

      postApiSettingsReportBuilderEagleEyeTemplatesByIdDuplicate: {
        invalidatesTags: ['allTemplates'],
        transformResponse: (
          response: PostApiSettingsReportBuilderEagleEyeTemplatesByIdDuplicateApiResponse,
        ) => {
          toastr.success(templateToastr.duplicate().toString());
          return response;
        },
      },
      putApiSettingsReportBuilderEagleEyeTemplatesById: {
        invalidatesTags: (_, __, args) => [
          'allTemplates',
          createApiTagType('template:eagleEye', args.id),
        ],
        transformResponse: (
          response: PutApiSettingsReportBuilderEagleEyeTemplatesByIdApiResponse,
        ) => {
          toastr.success(templateToastr.update().toString());
          return response;
        },
      },
      postApiSettingsReportBuilderEagleEyeTemplates: {
        invalidatesTags: ['allTemplates'],
        transformResponse: (
          response: PostApiSettingsReportBuilderEagleEyeTemplatesApiResponse,
        ) => {
          toastr.success(templateToastr.create().toString());
          return response;
        },
      },
      postApiSettingsReportBuilderEagleEyeTemplatesByEagleEyeTemplateIdGroups: {
        invalidatesTags: (_, __, args) => {
          return [
            createApiTagType('template:eagleEye', args.eagleEyeTemplateId),
            'widget:preview',
            'widget:evaluate',
          ];
        },
      },
      postApiSettingsReportBuilderEagleEyeTemplatesByEagleEyeTemplateIdGroupsAndGroupIdWidgetSections:
        {
          invalidatesTags: (_, __, args) => {
            return [
              createApiTagType('template:eagleEye', args.eagleEyeTemplateId),
              'widget:preview',
              'widget:evaluate',
            ];
          },
        },
      putApiSettingsReportBuilderEagleEyeTemplatesByEagleEyeTemplateIdGroupsAndGroupIdWidgetSectionsId:
        {
          invalidatesTags: (_, __, args) => [
            createApiTagType('template:eagleEye', args.eagleEyeTemplateId),
            'widget:preview',
            'widget:evaluate',
          ],
          onQueryStarted(patch, { dispatch, queryFulfilled }) {
            const widgetId = patch.id;
            const { groupId } = patch;
            const newPosition = patch.body.position;
            const newWidth = patch.body.width_percent;
            const newGroupId = patch.body.destination_group_id;

            const patchResult = dispatch(
              settingsReportBuilderTemplatesEnhancedApi.util.updateQueryData(
                'getApiSettingsReportBuilderGotenbergEagleEyeTemplatesById',
                {
                  id: patch.eagleEyeTemplateId,
                },
                (draft) => {
                  const currentGroup = draft.groups.find(
                    (g) => g.id === groupId,
                  );
                  if (currentGroup == null) return;

                  const currentWidget = currentGroup.widgetSections.find(
                    (w) => w.id === widgetId,
                  );

                  if (currentWidget == null) return;

                  let resultGroups: ReportBuilderTemplateGroupDto[] = [];

                  if (newGroupId == null) {
                    let newGroupWidgets = [...currentGroup.widgetSections];

                    if (newPosition != null) {
                      newGroupWidgets = arrayMoveImmutable(
                        currentGroup.widgetSections,
                        currentWidget.position.y,
                        newPosition,
                      );
                    }

                    newGroupWidgets = newGroupWidgets.map((w, wIdx) => {
                      return {
                        ...w,
                        position: {
                          ...w.position,
                          w:
                            newWidth != null && w.id === widgetId
                              ? newWidth
                              : w.position.w,
                          y: wIdx,
                        },
                      };
                    });

                    const newGroup = {
                      ...currentGroup,
                      widgetSections: newGroupWidgets,
                    };

                    resultGroups = draft.groups.map((g) => {
                      if (g.id !== groupId) return g;

                      return newGroup;
                    });
                  } else {
                    resultGroups = draft.groups.map((g) => {
                      if (g.id === currentGroup.id) {
                        return {
                          ...g,
                          widgetSections: g.widgetSections.filter(
                            (w) => w.id !== widgetId,
                          ),
                        };
                      }

                      if (g.id === newGroupId) {
                        return {
                          ...g,
                          widgetSections: g.widgetSections.toSpliced(
                            newPosition ?? currentWidget.position.y,
                            0,
                            currentWidget,
                          ),
                        };
                      }
                      return g;
                    });
                  }

                  Object.assign(draft, {
                    groups: resultGroups,
                  });
                },
              ),
            );
            queryFulfilled.catch(patchResult.undo);
          },
          transformResponse: (
            response: PutApiSettingsReportBuilderEagleEyeTemplatesByEagleEyeTemplateIdGroupsAndGroupIdWidgetSectionsIdApiResponse,
          ) => {
            toastr.success(widgetToastr.update().toString());
            return response;
          },
        },
      putApiSettingsReportBuilderEagleEyeTemplatesByEagleEyeTemplateIdGroupsAndId:
        {
          invalidatesTags: (_, __, args) => [
            createApiTagType('template:eagleEye', args.eagleEyeTemplateId),
            'widget:preview',
            'widget:evaluate',
          ],
          onQueryStarted(patch, { dispatch, queryFulfilled }) {
            const groupId = patch.id;
            const newPosition = patch.body.position;
            const newPageOrientation = patch.body.page_orientation;

            if (newPosition == null && newPageOrientation == null) return;

            const patchResult = dispatch(
              settingsReportBuilderTemplatesEnhancedApi.util.updateQueryData(
                'getApiSettingsReportBuilderGotenbergEagleEyeTemplatesById',
                {
                  id: patch.eagleEyeTemplateId,
                },
                (draft) => {
                  const currentGroup = draft.groups.find(
                    (g) => g.id === groupId,
                  );
                  if (currentGroup == null) return;

                  const newGroups = arrayMoveImmutable(
                    draft.groups.toSorted(sortByPosition),
                    currentGroup.position,
                    newPosition ?? currentGroup.position,
                  ).map((g, gIdx) => {
                    if (g.id === groupId) {
                      return {
                        ...g,
                        position: gIdx,
                        pageOrientation:
                          newPageOrientation ?? currentGroup.position,
                      };
                    }
                    return { ...g, position: gIdx };
                  });

                  Object.assign(draft, {
                    groups: newGroups,
                  });
                },
              ),
            );
            queryFulfilled.catch(patchResult.undo);
          },
        },
      deleteApiSettingsReportBuilderEagleEyeTemplatesByEagleEyeTemplateIdGroupsAndId:
        {
          invalidatesTags: (_, __, args) => {
            return [
              createApiTagType('template:eagleEye', args.eagleEyeTemplateId),
              'widget:preview',
              'widget:evaluate',
            ];
          },
          onQueryStarted(patch, { dispatch, queryFulfilled }) {
            const patchResult = dispatch(
              settingsReportBuilderTemplatesEnhancedApi.util.updateQueryData(
                'getApiSettingsReportBuilderGotenbergEagleEyeTemplatesById',
                {
                  id: patch.eagleEyeTemplateId,
                },
                (draft) => {
                  draft.groups = draft.groups.filter((g) => g.id !== patch.id);
                },
              ),
            );
            queryFulfilled.catch(patchResult.undo);
          },
        },
      deleteApiSettingsReportBuilderEagleEyeTemplatesByEagleEyeTemplateIdGroupsAndGroupIdWidgetSectionsId:
        {
          invalidatesTags: (_, __, args) => [
            createApiTagType('template:eagleEye', args.eagleEyeTemplateId),
            'widget:preview',
            'widget:evaluate',
          ],
          onQueryStarted(patch, { dispatch, queryFulfilled }) {
            const patchResult = dispatch(
              settingsReportBuilderTemplatesEnhancedApi.util.updateQueryData(
                'getApiSettingsReportBuilderGotenbergEagleEyeTemplatesById',
                {
                  id: patch.eagleEyeTemplateId,
                },
                (draft) => {
                  draft.groups = draft.groups.map((g) => {
                    if (g.id !== patch.groupId) {
                      return g;
                    }
                    return {
                      ...g,
                      widgetSections: g.widgetSections.filter(
                        (w) => w.id !== patch.id,
                      ),
                    };
                  });
                },
              ),
            );
            queryFulfilled.catch(patchResult.undo);
          },
        },

      postApiSettingsReportBuilderEagleEyeTemplatesByEagleEyeTemplateIdGroupsAndGroupIdWidgetSectionsPreview:
        {
          providesTags: ['widget:preview', 'widget:evaluate'],
        },
      // #endregion

      deleteApiSettingsReportBuilderTemplatesById: {
        invalidatesTags: ['allTemplates'],
        onQueryStarted(patch, { dispatch, queryFulfilled }) {
          const patchResult = dispatch(
            settingsReportBuilderTemplatesEnhancedApi.util.updateQueryData(
              'getApiSettingsReportBuilderTemplates',
              {} as unknown as void,
              (draft) => {
                draft.items = draft.items.filter((t) => t.id !== patch.id);
              },
            ),
          );
          queryFulfilled.catch(patchResult.undo);
        },
        transformResponse: (
          response: DeleteApiSettingsReportBuilderTemplatesByIdApiResponse,
        ) => {
          toastr.success(templateToastr.delete().toString());
          return response;
        },
      },

      postApiSettingsReportBuilderTemplates: {
        invalidatesTags: ['allTemplates'],
        transformResponse: (
          response: PostApiSettingsReportBuilderTemplatesApiResponse,
        ) => {
          toastr.success(templateToastr.create().toString());
          return response;
        },
      },

      postApiSettingsReportBuilderTemplatesByIdDuplicate: {
        invalidatesTags: ['allTemplates'],
        transformResponse: (
          response: PostApiSettingsReportBuilderTemplatesByIdDuplicateApiResponse,
        ) => {
          toastr.success(templateToastr.duplicate().toString());
          return response;
        },
      },

      putApiSettingsReportBuilderTemplatesById: {
        invalidatesTags: ['template', 'allTemplates'],
        transformResponse: (
          response: PutApiSettingsReportBuilderTemplatesByIdApiResponse,
        ) => {
          toastr.success(templateToastr.update().toString());
          return response;
        },
      },

      getApiSettingsReportBuilderTemplatesMeta: {
        providesTags: ['meta:assets', 'meta:tags'],
      },
      getApiSettingsReportBuilderEagleEyeTemplatesMeta: {
        providesTags: ['meta:assets:eagleEye', 'meta:tags'],
      },
      getApiSettingsReportBuilderGotenbergTemplatesMeta: {
        providesTags: ['meta:assets', 'meta:tags'],
      },
      getApiSettingsReportBuilderGotenbergEagleEyeTemplatesMeta: {
        providesTags: ['meta:assets:eagleEye', 'meta:tags'],
      },

      postApiSettingsReportBuilderGotenbergWidgetSectionsByIdEvaluate: {
        providesTags: ['widget:evaluate'],
      },
    },
  });

export const {
  // Template editor
  // The first hook will be replaced with Object Level API; The second one is for Eagle Eye
  useGetApiSettingsReportBuilderGotenbergTemplatesByIdQuery,
  useGetApiSettingsReportBuilderGotenbergEagleEyeTemplatesByIdQuery,

  // The first hook will be replaced with Object Level API; The second one is for Eagle Eye
  usePutApiSettingsReportBuilderTemplatesByIdMutation,
  usePutApiSettingsReportBuilderEagleEyeTemplatesByIdMutation,

  // #region Template editor (Group actions)
  // The first hook will be replaced with Object Level API; The second one is for Eagle Eye
  useDeleteApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndIdMutation,
  useDeleteApiSettingsReportBuilderEagleEyeTemplatesByEagleEyeTemplateIdGroupsAndIdMutation,

  // The first hook will be replaced with Object Level API; The second one is for Eagle Eye
  usePutApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndIdMutation,
  usePutApiSettingsReportBuilderEagleEyeTemplatesByEagleEyeTemplateIdGroupsAndIdMutation,

  // The first hook will be replaced with Object Level API; The second one is for Eagle Eye
  usePostApiSettingsReportBuilderTemplatesByTemplateIdGroupsMutation,
  usePostApiSettingsReportBuilderEagleEyeTemplatesByEagleEyeTemplateIdGroupsMutation,
  // #endregion

  // #region Template editor (Widget Section actions)
  // The first hook will be replaced with Object Level API; The second one is for Eagle Eye
  useGetApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndGroupIdWidgetSectionsIdQuery,
  useGetApiSettingsReportBuilderEagleEyeTemplatesByEagleEyeTemplateIdGroupsAndGroupIdWidgetSectionsIdQuery,

  // The first hook will be replaced with Object Level API; The second one is for Eagle Eye
  useDeleteApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndGroupIdWidgetSectionsIdMutation,
  useDeleteApiSettingsReportBuilderEagleEyeTemplatesByEagleEyeTemplateIdGroupsAndGroupIdWidgetSectionsIdMutation,

  // The first hook will be replaced with Object Level API; The second one is for Eagle Eye
  usePutApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndGroupIdWidgetSectionsIdMutation,
  usePutApiSettingsReportBuilderEagleEyeTemplatesByEagleEyeTemplateIdGroupsAndGroupIdWidgetSectionsIdMutation,

  // The first hook will be replaced with Object Level API; The second one is for Eagle Eye
  usePostApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndGroupIdWidgetSectionsMutation,
  usePostApiSettingsReportBuilderEagleEyeTemplatesByEagleEyeTemplateIdGroupsAndGroupIdWidgetSectionsMutation,

  // The first hook will be replaced with Object Level API; The second one is for Eagle Eye
  usePostApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndGroupIdWidgetSectionsPreviewQuery,
  usePostApiSettingsReportBuilderEagleEyeTemplatesByEagleEyeTemplateIdGroupsAndGroupIdWidgetSectionsPreviewQuery,
  // #endregion

  // Template view page
  usePostApiSettingsReportBuilderGotenbergWidgetSectionsByIdEvaluateQuery,

  // Template list
  useGetApiSettingsReportBuilderTemplatesQuery,

  // The first hook will be replaced with Object Level API; The second one is for Eagle Eye
  useDeleteApiSettingsReportBuilderTemplatesByIdMutation,
  useDeleteApiSettingsReportBuilderEagleEyeTemplatesByIdMutation,

  // The first hook will be replaced with Object Level API; The second one is for Eagle Eye
  usePostApiSettingsReportBuilderTemplatesByIdDuplicateMutation,
  usePostApiSettingsReportBuilderEagleEyeTemplatesByIdDuplicateMutation,

  // The first hook will be replaced with Object Level API; The second one is for Eagle Eye
  usePostApiSettingsReportBuilderTemplatesMutation,
  usePostApiSettingsReportBuilderEagleEyeTemplatesMutation,

  // Template Meta

  // The first hook will be replaced with Object Level API; The second one is for Eagle Eye
  useGetApiSettingsReportBuilderGotenbergTemplatesMetaQuery,
  useGetApiSettingsReportBuilderGotenbergEagleEyeTemplatesMetaQuery,

  // The first hook will be replaced with Object Level API; The second one is for Eagle Eye
  useGetApiSettingsReportBuilderTemplatesMetaQuery,
  useGetApiSettingsReportBuilderEagleEyeTemplatesMetaQuery,

  // Template Tags
  useDeleteApiSettingsReportBuilderTemplateTagsByIdMutation,

  // Copyable widgets

  // The first hook will be replaced with Object Level API; The second one is for Eagle Eye
  useGetApiSettingsReportBuilderTemplatesByTemplateIdCopyableWidgetSectionsQuery,
  useGetApiSettingsReportBuilderEagleEyeTemplatesByEagleEyeTemplateIdCopyableWidgetSectionsQuery,

  // The first hook will be replaced with Object Level API; The second one is for Eagle Eye
  usePostApiSettingsReportBuilderTemplatesByTemplateIdCopyableWidgetSectionsCopyToGroupMutation,
  usePostApiSettingsReportBuilderEagleEyeTemplatesByEagleEyeTemplateIdCopyableWidgetSectionsCopyToGroupMutation,

  useGetApiSettingsReportBuilderTemplatesByTemplateIdObjectDashboardsCopyableWidgetSectionsQuery,
  usePostApiSettingsReportBuilderTemplatesByTemplateIdObjectDashboardsCopyableWidgetSectionsCopyToGroupMutation,
} = settingsReportBuilderTemplatesEnhancedApi;
