import {
  useDeleteApiSettingsReportBuilderEagleEyeTemplatesByIdMutation,
  useDeleteApiSettingsReportBuilderTemplatesByIdMutation,
} from '@/entities/report/reportBuilder/api/settingsReportBuilderTemplatesEnhancedApi';
import type { ReportBuilderTemplateDto } from '@/entities/report/reportBuilder/api/settingsReportBuilderTemplatesGeneratedApi';
import { TemplateAssetsInUse } from '@/entities/report/reportBuilder/ui/ReportBuilderTemplateAssetsInUse';
import { useReportingEntityKindContext } from '@/entities/reporting/context/entityKind';
import {
  useModal,
  useResetAllModalsOnUnmount,
} from '@/shared/lib/hooks/useModal';
import { useCallback } from 'react';

export const useReportBuilderTemplateDelete = (onSuccess?: () => void) => {
  const templateKind = useReportingEntityKindContext();
  const [deleteTemplate, options] =
    useDeleteApiSettingsReportBuilderTemplatesByIdMutation();
  const [deleteEagleEyeTemplate, eagleEyeOptions] =
    useDeleteApiSettingsReportBuilderEagleEyeTemplatesByIdMutation();
  const { confirm } = useModal();

  useResetAllModalsOnUnmount();

  const isLoading = options.isLoading || eagleEyeOptions.isLoading;

  return [
    useCallback(
      async (template: Pick<ReportBuilderTemplateDto, 'id' | 'assets'>) => {
        const res = await confirm({
          title: (
            <>
              <p>Delete template</p>
            </>
          ),
          subtitle: (
            <div className="flex flex-col gap-4">
              {template.assets != null && template.assets.length > 0 && (
                <p className="inline-regular">
                  This Template is used in{' '}
                  <TemplateAssetsInUse assets={template.assets} />
                </p>
              )}
              <p>Are you sure you want to delete this template?</p>
            </div>
          ),
        });
        if (!res) return;

        if (templateKind === 'eagle_eye') {
          const response = await deleteEagleEyeTemplate({
            id: template.id,
          });

          return response;
        }

        const response = await deleteTemplate({ id: template.id });

        if ((response != null && 'error' in response) || response == null) {
          console.error(response.error);
          return;
        }

        return onSuccess?.();
      },
      [templateKind],
    ),
    { isLoading },
  ] as const;
};
