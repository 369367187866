import { GrowDiv } from '@/shared/ui/GrowDiv';
import { Field } from '@/stories/Field/Field';
import { ColorPickerWidget } from '@/widgets/core/sreColors/settings/ui/ColorPickerWidget';
import { ResetButton } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/fields/StylingFields';
import React from 'react';
import { Control, useController } from 'react-hook-form';
import { FieldPath, FieldValues } from 'react-hook-form/dist/types';
import { SectionField } from 'stories/Field/FieldsWrappers';

export function ColorField<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  name,
  ...props
}: React.ComponentProps<typeof SectionField> & {
  name: TName;
  control: Control<TFieldValues>;
  component?: 'SectionField' | 'Field';
}) {
  const { field } = useController({ name, control });
  const Component = props.component === 'Field' ? Field : SectionField;
  return (
    <Component
      labelText={'Color'}
      button={
        <>
          <GrowDiv />
          <ResetButton
            onClick={() =>
              field.onChange(null, {
                shouldDirty: true,
              })
            }
            name={name}
          />
        </>
      }
      {...props}
    >
      <ColorPickerWidget value={field.value} onChange={field.onChange} />
    </Component>
  );
}
