import { IGlRow, TRowData } from 'bundles/Shared/components/ReportTable/types';
import { omit } from 'lodash-es';
import { calcTotalGlColValue } from '@/bundles/REconcile/components/operational/editor/helpers/calcTotalGlColValue';

interface IParams {
  row: IGlRow;
  columnKey: keyof TRowData;
  newValue: string | number;
}

/**
 * @param {columnKey} should be one of the `row.data` keys except the last one (total column key)
 */

export function getUpdatedRowData({ row, newValue, columnKey }: IParams) {
  const rowDataWithUpdatedColumn: TRowData = {
    ...row.data,
    [columnKey]: {
      ...row.data[columnKey],
      value: String(newValue),
    },
  };

  const totalColumnKey = String(
    Math.max(...Object.keys(rowDataWithUpdatedColumn).map(Number)),
  );

  const updatedRowDataWithoutTotalColumn: TRowData = omit(
    rowDataWithUpdatedColumn,
    totalColumnKey,
  );

  const rowDataValueObjList = Object.values(updatedRowDataWithoutTotalColumn);

  const updatedTotalValue = calcTotalGlColValue(rowDataValueObjList);

  const updatedRowData: TRowData = {
    ...rowDataWithUpdatedColumn,
    [totalColumnKey]: {
      ...row.data[totalColumnKey],
      value: updatedTotalValue,
    },
  };

  return updatedRowData;
}
