import { usePutApiReconcileForecastingForecastsByForecastIdBudgetsAndBudgetIdCleanMutation } from '@/entities/reconcile/forecasting/api/reconcileForecastingEntitiesEnchancedApi';
import { PutApiReconcileForecastingForecastsByForecastIdBudgetsAndBudgetIdCleanApiArg } from '@/entities/reconcile/forecasting/api/reconcileForecastingEntitiesGeneratedApi';
import { useModal } from '@/shared/lib/hooks/useModal';
import { EntityToastrCreator } from '@/shared/lib/toastr/entityToastrCreator';

const t = new EntityToastrCreator('Budget');

export const useCleanForecastingBudget = () => {
  const [cleanBudgetApi, options] =
    usePutApiReconcileForecastingForecastsByForecastIdBudgetsAndBudgetIdCleanMutation();

  const { confirm } = useModal();

  const deleteBudget = async ({
    forecastId,
    budgetId,
  }: PutApiReconcileForecastingForecastsByForecastIdBudgetsAndBudgetIdCleanApiArg) => {
    const res = await confirm({
      title: 'Delete forecasting budget',
      subtitle: 'Are you sure you want to delete this budget?',
    });

    if (!res) return false;

    await cleanBudgetApi({
      forecastId,
      budgetId,
    });

    toastr.success(t.delete().toString());
  };

  const cleanBudget = async ({
    forecastId,
    budgetId,
  }: PutApiReconcileForecastingForecastsByForecastIdBudgetsAndBudgetIdCleanApiArg) => {
    const res = await confirm({
      title: 'Clean forecasting budget',
      subtitle: 'Are you sure you want to clean this budget?',
    });

    if (!res) return false;

    await cleanBudgetApi({
      forecastId,
      budgetId,
      lineItemsOnly: true,
    });

    toastr.success(t.clean().toString());
  };

  return {
    deleteBudget,
    cleanBudget,
    ...options,
  };
};
