import { typedLocalStorage } from '@/shared/lib/localStorage';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import http, { API_URL } from 'lib/http';
import { DEFAULT_STRINGIFY_OPTIONS } from 'lib/stringify';
import { omit } from 'lodash-es';
import { stringify } from 'qs';
import { IUser, IUserRole } from 'types/User';

export type UserAccessLevels = Record<
  string,
  {
    canManageAssetsCount: number;
    canManageFundsCount: number;
    canManageAllAssets: boolean;
    canManageAllFunds: boolean;
  }
>;

export interface FetchUsersResponse {
  items: IUser[];
  meta: {
    currentPage: number;
    totalFireStationFailedInvites: number;
    totalFireStationInviteStatuses: number;
    totalPages: number;
    totalSize: number;
    userAccessLevels: UserAccessLevels;
  };
}
export interface FetchUserRoleResponse {
  items: IUserRole[];
  meta: {
    currentPage: number;
    totalPages: number;
    totalSize: number;
  };
}

export async function fetchUsers(data = {}) {
  const res = await http.get(
    `/users${stringify(data, {
      addQueryPrefix: true,
      arrayFormat: 'brackets',
    })}`,
  );
  const responseData = await res.json();
  return responseData as FetchUsersResponse;
}

type FetchImpersonationUsersResponse = {
  items: IUser[];
  meta: {
    totalSize: number;
    perPage: number;
    page: number;
  };
};

export const impersonationApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_URL}/api/`,
    paramsSerializer: (params) =>
      stringify(params, {
        arrayFormat: 'brackets',
      }),
  }),
  reducerPath: 'mirrorModeApi',
  tagTypes: ['users', 'roles'],
  endpoints: (build) => ({
    getUsers: build.query<
      FetchImpersonationUsersResponse,
      {
        user_role_id?: string;
        page?: number;
        per_page?: number;
        search_query?: string;
      }
    >({
      query: (params) => ({
        url: '/user_impersonations/users',
        params,
      }),
      providesTags: ['users'],
      serializeQueryArgs: ({ queryArgs }) => {
        return omit(queryArgs, 'page');
      },
      merge: (currentCache, newData) => {
        currentCache.items.push(...newData.items);
        // eslint-disable-next-line no-param-reassign
        currentCache.meta = newData.meta;
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.search_query !== previousArg?.search_query;
      },
    }),
    getRoles: build.query<FetchUserRoleResponse, never>({
      query: (params) => ({
        url: '/user_impersonations/user_roles',
        params,
      }),
      providesTags: ['roles'],
    }),
  }),
});

export const { useGetUsersQuery, useGetRolesQuery } = impersonationApi;

const initialState: {
  page: number;
  query: string;
  totalSize: number;
  selectedRole: IUserRole | null;
} = {
  page: 1,
  query: '',
  totalSize: 0,
  selectedRole: typedLocalStorage.getItem('currentMirrorModeRole'),
};

export const impersonationSlice = createSlice({
  name: 'impersonation',
  initialState,
  reducers: {
    incrementPage(state) {
      state.page = state.page + 1;
    },
    roleChange(state, action: PayloadAction<IUserRole>) {
      state.page = 1;
      state.query = '';
      state.selectedRole = action.payload;
    },
    queryChange(state, action: PayloadAction<string>) {
      state.page = 1;
      state.query = action.payload;
    },
  },
});

export const { incrementPage, queryChange, roleChange } =
  impersonationSlice.actions;

/**
 * @deprecated
 */
export async function fetchImpersonationUsers(params: {
  user_role_id?: string;
  page?: number;
  per_page?: number;
  search_query?: string;
}) {
  const res = await http.get(
    `/user_impersonations/users${stringify(params, DEFAULT_STRINGIFY_OPTIONS)}`,
  );
  const responseData = await res.json();
  return responseData as {
    items: FetchUsersResponse['items'];
    meta: {
      totalSize: number;
      perPage: number;
      page: number;
    };
  };
}

/**
 * @deprecated
 */
export async function fetchImpersonationUserRoles() {
  const res = await http.get('/user_impersonations/user_roles');
  const responseData = await res.json();
  return responseData as FetchUserRoleResponse;
}
