import { LegalEntityUnderwritingBudget } from 'bundles/REconcile/underwritting/api/reconcileUnderwritingBudgetGeneratedApi';
import { uwbSliceActions } from 'bundles/REconcile/underwritting/ctx/underwritingBudgetSlice';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import { cn } from '@/shared/lib/css/cn';

const FILTER_ITEMS = [
  {
    id: 'published',
    label: 'Published',
  },
  {
    id: 'draft',
    label: 'Draft',
  },
  {
    id: 'no_budget',
    label: 'No Budget',
  },
] as const satisfies readonly {
  id: LegalEntityUnderwritingBudget['state'];
  label: string;
}[];

export function FilterLegalEntityState() {
  const uwbSlice = useAppSelector((state) => state.underwritingBudgetSlice);
  const dispatch = useAppDispatch();
  const { leFilter } = uwbSlice;

  return (
    <div className="flex items-center gap-2">
      {FILTER_ITEMS.map((item) => (
        <button
          onClick={() => {
            dispatch(
              uwbSliceActions.changeLEFilter(
                item.id === leFilter ? undefined : item.id,
              ),
            );
          }}
          className={cn(
            'label-regular cursor-pointer rounded-2xl border border-neutral-100 bg-white px-2 py-1 text-neutral-900',
            leFilter === item.id &&
              'border-info-055 bg-info-055 text-neutral-000',
            leFilter !== item.id && 'shadow-z-020',
          )}
          key={item.id}
        >
          {item.label}
        </button>
      ))}
    </div>
  );
}
