import React, { useState } from 'react';
import { Icon } from '@/stories/Icon/Icon';
import { Tooltip } from '@/stories/Tooltip/Tooltip';
import { cn } from '@/shared/lib/css/cn';

interface ICopyTextItem
  extends Partial<React.AllHTMLAttributes<HTMLDivElement>> {
  text: string;
}

export const handleCopyText = async (
  e: React.MouseEvent<HTMLElement>,
  text: string,
) => {
  e.stopPropagation();
  try {
    // Attention - doesn't work locally
    await window.navigator.clipboard.writeText(text);
  } catch (err) {
    console.error(err);
  }
};

export const CopyTextItemIcon = ({ text }: { text: string }) => {
  const [copy, setCopy] = useState(false);

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    setCopy(true);
    handleCopyText(e, text);
  };

  return copy ? (
    <Tooltip mainText="Copied">
      <Icon iconName="copyAlt" onClick={(e) => handleClick(e)} />
    </Tooltip>
  ) : (
    <Icon iconName="copyAlt" onClick={(e) => handleClick(e)} />
  );
};

export const CopyTextItem = ({
  text,
  children,
  className,
  ...props
}: ICopyTextItem) => {
  const [showBtn, setShowBtn] = useState<boolean>(false);

  return (
    <div
      className={cn('flex items-center gap-1', className)}
      onMouseEnter={() => setShowBtn(true)}
      onMouseLeave={() => setShowBtn(false)}
      {...props}
    >
      {children}
      {showBtn && <CopyTextItemIcon text={text} />}
    </div>
  );
};
