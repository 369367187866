import { WidgetStateDate } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/state/WidgetStateDate';
import { useContextChangeHandler } from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/reportBuilder';
import { widgetHasData } from '@/bundles/Shared/widgets/dashboard/widgets/financialTableSingeDate/lib';
import { WidgetConfigCardProps } from '@/bundles/Shared/widgets/dashboard/widgets/model';
import { selectWidgetById } from '@/entities/report/reportBuilder';
import type { RecentRentrollWidgetConfigDto } from '@/entities/report/reportBuilder/api/settingsReportBuilderTemplatesGeneratedApi';
import { BaseWidgetConfigCard } from '@/entities/report/reportBuilder/ui/BaseWidgetConfigCard';
import { ExpandCollapseRowsIcon } from '@/entities/report/reportBuilder/ui/ExpandCollapseRowsIcon';
import { useAppSelector } from '@/shared/lib/hooks/redux';

export const RecentRentrollWidgetConfigCard = (
  props: WidgetConfigCardProps,
) => {
  const widget = useAppSelector(selectWidgetById(props.widgetSection.id));
  const handleContextChange = useContextChangeHandler({
    widgetId: widget?.id ?? '',
    widgetGroupId: widget?.group.id ?? '',
  });

  return (
    <BaseWidgetConfigCard
      labelSlot={
        widget && (
          <div className="flex items-center justify-center gap-2">
            <ExpandCollapseRowsIcon
              includeSourceUnitTypes={
                (widget?.widgetConfig as RecentRentrollWidgetConfigDto)
                  ?.include_source_unit_types
              }
            />
            <WidgetStateDate
              closeOnDateUpdate
              state={widget.context}
              onStateChange={handleContextChange}
            />
          </div>
        )
      }
      hasData={widgetHasData(props.data)}
      {...props}
    />
  );
};
