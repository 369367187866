import React from 'react';
import { FieldValues } from 'react-hook-form/dist/types';
import { Control } from 'react-hook-form';
import { SectionField } from '@/stories/Field/FieldsWrappers';
import * as yup from 'yup';
import { InputNumberController } from '@/bundles/Shared/components/GroupForm/FormItems/InputNumber';

export const DEFAULT_WIDGET_HISTORY_SIZE = 0;

export const HISTORY_SIZE_SCHEMA = yup.number().required();

export const DEFAULT_HISTORY_SIZE_FIELD_SCHEMA = yup.object().shape({
  history_size: HISTORY_SIZE_SCHEMA,
});

export function HistorySizeField<
  TFieldValues extends FieldValues = FieldValues,
>({
  control,
  name = 'history_size',
}: {
  control: Control<TFieldValues>;
  name?: string;
}) {
  return (
    <SectionField
      note="The number of historical values to display"
      labelText="History Size"
    >
      <InputNumberController control={control} name={name} />
    </SectionField>
  );
}
