import React from 'react';
import { cn } from '@/shared/lib/css/cn';
import Icon from '@/stories/Icon/Icon';

interface Props
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  text?: string;
  backgroundColor?: string;
  color?: string;
  borderRadius?: string;
  initialsCount?: number;
  height?: string;
  width?: string;
  fontSize?: string;
  fontWeight?: string;
  iconName?: string;
  roundClasses?: string;
}

export const SreAvatar = ({
  backgroundColor = '#92D6A5',
  color = '#1C8336',
  borderRadius = '50%',
  text,
  initialsCount = 2,
  height = '1.5rem',
  width = '1.5rem',
  fontSize = '0.875rem',
  fontWeight = 'normal',
  iconName,
  roundClasses,
  className,
}: Props) => (
  <div className={className}>
    <div
      className={cn('flex items-center justify-center', roundClasses)}
      style={{
        backgroundColor,
        color,
        borderRadius,
        height,
        width,
        fontSize,
        fontWeight,
        lineHeight: fontSize,
      }}
    >
      {text &&
        text
          .split(' ')
          .splice(0, initialsCount)
          .map((n) => n && n[0].toUpperCase())
          .join('')}
      {iconName && <Icon iconName={iconName} />}
    </div>
  </div>
);

export default SreAvatar;
