import { DEFAULT_NUMBER_VALUE_DISPLAY_OPTIONS } from '@/shared/lib/formatting/displayOptions';
import { transformPeriodShiftDtoToForm } from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/config';
import { DEFAULT_WIDGET_HISTORY_SIZE } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/fields/HistorySizeField';
import { SPARKLINE_TYPE } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/fields/SparklineTypeField';
import { transformEmptyObjectToUndefined } from '@/shared/lib/yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  PERIOD_SHIFT_SCHEMA,
  TOTAL_CALCULATION_STRATEGIES,
} from 'bundles/Shared/widgets/dashboard/widgets/common/config';
import {
  NUMERIC_COLUMN_SETTINGS_FORM_SCHEMA,
  SPARKLINE_COLUMN_SETTINGS_FORM_SCHEMA,
  TEXT_COLUMN_SETTINGS_FORM_SCHEMA,
  transformColumnSettingsToForm,
  WidgetTableColumnSettingsColumnType,
} from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/form';
import { TableVizConfigColumn } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/model';
import { KpiTableWidgetConfigColumn } from 'bundles/Shared/widgets/dashboard/widgets/kpiTable/model';
import { ListOption } from 'stories/Checkbox/CheckList';
import * as yup from 'yup';

import { ADJUSTMENT_EXPRESSION_SCHEMA } from 'bundles/Shared/widgets/dashboard/widgets/common/features/adjustments/config/base';

const KPI_TABLE_WIDGET_CONFIG_BASE_COLUMN_SCHEMA = yup.object().shape({
  key: yup.number(),
  label: yup.string().required(),
  expression: yup.string().required(),
  period_shift: PERIOD_SHIFT_SCHEMA.nullable()
    .optional()
    .transform(transformEmptyObjectToUndefined)
    .default(undefined),
});

export const KPI_TABLE_WIDGET_CONFIG_NUMERIC_COLUMN_SCHEMA =
  KPI_TABLE_WIDGET_CONFIG_BASE_COLUMN_SCHEMA.concat(
    NUMERIC_COLUMN_SETTINGS_FORM_SCHEMA,
  ).shape({
    adjustment: ADJUSTMENT_EXPRESSION_SCHEMA.nullable().optional(),
    total_calculation_strategy: yup
      .string()
      .oneOf(Object.values(TOTAL_CALCULATION_STRATEGIES))
      .nullable()
      .optional()
      .default(undefined),
  });

export const KPI_TABLE_WIDGET_CONFIG_TEXT_COLUMN_SCHEMA =
  KPI_TABLE_WIDGET_CONFIG_BASE_COLUMN_SCHEMA.concat(
    TEXT_COLUMN_SETTINGS_FORM_SCHEMA,
  );

export const KPI_TABLE_WIDGET_CONFIG_SPARKLINE_COLUMN_SCHEMA =
  KPI_TABLE_WIDGET_CONFIG_BASE_COLUMN_SCHEMA.shape({
    adjustment: ADJUSTMENT_EXPRESSION_SCHEMA.nullable().optional(),
  }).concat(SPARKLINE_COLUMN_SETTINGS_FORM_SCHEMA);

export type KpiTableWidgetConfigNumericColumnForm = yup.InferType<
  typeof KPI_TABLE_WIDGET_CONFIG_NUMERIC_COLUMN_SCHEMA
>;

export type KpiTableWidgetConfigTextColumnForm = yup.InferType<
  typeof KPI_TABLE_WIDGET_CONFIG_TEXT_COLUMN_SCHEMA
>;

export type KpiTableWidgetConfigSparklineColumnForm = yup.InferType<
  typeof KPI_TABLE_WIDGET_CONFIG_SPARKLINE_COLUMN_SCHEMA
>;

export type KpiTableWidgetConfigColumnForm =
  | KpiTableWidgetConfigNumericColumnForm
  | KpiTableWidgetConfigTextColumnForm
  | KpiTableWidgetConfigSparklineColumnForm;

export const DEFAULT_KPI_TABLE_WIDGET_CONFIG_NUMERIC_COLUMN: Partial<KpiTableWidgetConfigNumericColumnForm> =
  {
    type: 'numeric',
    value_display_options: DEFAULT_NUMBER_VALUE_DISPLAY_OPTIONS,
    adjustment: null,
    period_shift: {
      key: 'weeks',
      value: 0,
    },
    total_calculation_strategy: 'evaluate',
  };

const DEFAULT_KPI_TABLE_WIDGET_CONFIG_SPARKLINE_COLUMN: Partial<KpiTableWidgetConfigSparklineColumnForm> =
  {
    type: 'sparkline',
    value_display_options: DEFAULT_NUMBER_VALUE_DISPLAY_OPTIONS,
    history_size: DEFAULT_WIDGET_HISTORY_SIZE,
    adjustment: null,
    sparkline_type: SPARKLINE_TYPE.LINE,
    header: {
      hide_subtitle: true,
    },
  };

export const DEFAULT_KPI_TABLE_WIDGET_CONFIG_TEXT_COLUMN: Partial<KpiTableWidgetConfigTextColumnForm> =
  {
    type: 'text',
    value_display_options: {
      kilo_formatting: false,
      type: 'text',
      precision: 0,
    },
    period_shift: {
      key: 'weeks',
      value: 0,
    },
    total_calculation_strategy: 'none',
    text_display_strategy: 'text_default',
  };

// month and custom
export const DATE_PICKER_SETTINGS_OPTIONS = [
  {
    value: 'month',
    label: 'Month',
  },
  {
    value: 'week',
    label: 'Week',
  },
] as const satisfies readonly ListOption[];

export const getKpiTableResolver = (
  columnType: WidgetTableColumnSettingsColumnType,
) => {
  if (columnType === 'sparkline') {
    return yupResolver(KPI_TABLE_WIDGET_CONFIG_SPARKLINE_COLUMN_SCHEMA);
  }
  if (columnType === 'text') {
    return yupResolver(KPI_TABLE_WIDGET_CONFIG_TEXT_COLUMN_SCHEMA);
  }
  return yupResolver(KPI_TABLE_WIDGET_CONFIG_NUMERIC_COLUMN_SCHEMA);
};

export const getKpiTableDefaultColumnForm = (
  columnType: WidgetTableColumnSettingsColumnType,
) => {
  if (columnType === 'sparkline') {
    return DEFAULT_KPI_TABLE_WIDGET_CONFIG_SPARKLINE_COLUMN;
  }
  if (columnType === 'text') {
    return DEFAULT_KPI_TABLE_WIDGET_CONFIG_TEXT_COLUMN;
  }
  return DEFAULT_KPI_TABLE_WIDGET_CONFIG_NUMERIC_COLUMN;
};

export const transformColumnConfigToForm = (
  columnConfig: KpiTableWidgetConfigColumn,
  columnSettings: TableVizConfigColumn,
): KpiTableWidgetConfigColumnForm => {
  return {
    key: columnConfig.key,
    label: columnConfig.label,
    expression: columnConfig.expression,
    adjustment: columnConfig.adjustment_expression,
    period_shift: transformPeriodShiftDtoToForm(columnConfig.period_shift),
    total_calculation_strategy: columnConfig.total_calculation_strategy,
    history_size: columnConfig.history_size,
    ...transformColumnSettingsToForm(columnSettings),
  };
};
