import React, { SetStateAction } from 'react';
import { IChangeOrder, IChangeOrderEvent } from 'bundles/Construction/types';
import { formatAmount } from '@/shared/lib/formatting/number';
import {
  FieldType,
  FieldTypeView,
  IGroupFormItem,
} from '@/bundles/Shared/components/GroupForm/types/types';
import SelectCO from '@/bundles/Construction/components/ChangeOrder/FormComponents/SelectCO';
import { dateTransformer } from '@/bundles/Shared/components/GroupForm/FormViewItem/transformerFormViewItem';

const getItemsCOModal = (
  formData: IChangeOrder,
  setFormData: SetStateAction<IChangeOrder>,
  changeOrderEvents: IChangeOrderEvent,
): IGroupFormItem[] => {
  const fileChangeStatusYes = () => {
    if (!formData.sharedFiles) {
      setFormData({
        ...formData,
        sharedFiles: 'YES',
      });
    }
  };

  const fileChangeStatusNo = () => {
    setFormData({
      ...formData,
      sharedFiles: '',
    });
  };

  const qaItems = {
    yes: {
      active: formData?.sharedFiles === 'YES' || formData?.sharedFiles,
      handle: fileChangeStatusYes,
      disabled: false,
    },
    no: {
      active: formData.sharedFiles === '',
      handle: fileChangeStatusNo,
      disabled: false,
    },
  };

  const groupFormItems = [
    {
      title: 'Select Change Order Requests you want to include',
      titleView: 'Selected Change Order Requests',
      required: true,
      activated:
        formData.value ||
        formData.value === 0 ||
        formData?.changeOrderEventIds?.length,
      open: true,
      formItems: [
        {
          fieldName: 'changeOrderEvents',
          fieldNameSnap: 'change_order_event_ids',
          type: FieldType.CUSTOM,
          typeView: FieldTypeView.VIEW_CUSTOM,
          content: (
            <SelectCO
              COE={changeOrderEvents}
              formData={formData}
              setFormData={setFormData}
              selectable
            />
          ),
          contentView: (
            <SelectCO
              COE={formData?.changeOrderEvents}
              formData={formData}
              setFormData={setFormData}
              selectable={false}
            />
          ),
          required: true,
          optionalView: true,
          styles: {
            wrapper: 'w-full form-item_nomb',
            size: 'm',
          },
        },
      ],
      styles: {
        containerClassName: 'group-form__step-item_nopadding',
      },
    },
    {
      title: 'Change Order Details',
      required: true,
      activated: true,
      open: false,
      formItems: [
        {
          fieldName: 'number',
          fieldNameSnap: 'number',
          type: FieldType.INPUT_NUMBER,
          typeView: FieldTypeView.VIEW_TEXT,
          title: 'Number',
          titleView: 'Number',
          placeholder: '0000',
          maxLength: 4,
          required: true,
          optionalView: true,
          styles: {
            wrapper: 'w-25 form-item_white',
            size: 'm',
          },
        },
        {
          fieldName: 'value',
          fieldNameSnap: 'value',
          type: FieldType.INPUT_NUMBER,
          typeView: FieldTypeView.VIEW_TEXT,
          title: 'Amount',
          titleView: 'Amount',
          disabled: true,
          transformerView: formatAmount,
          leftIcon: 'paid',
          placeholder: '0',
          required: true,
          optionalView: true,
          styles: {
            wrapper: 'w-25 form-item_white',
            size: 'm',
          },
          thousandSeparator: true,
        },
        {
          fieldName: 'receivedDate',
          fieldNameSnap: 'received_date',
          type: FieldType.INPUT_DATE,
          typeView: FieldTypeView.VIEW_TEXT,
          title: 'Received date',
          titleView: 'Received date',
          transformerView: dateTransformer,
          placeholder: 'Date',
          required: true,
          optionalView: true,
          format: 'MM-DD-YYYY',
          formatView: 'MM-dd-yy',
          leftIcon: 'calendar',
          styles: {
            wrapper: 'w-25 form-item_white',
            size: 'm',
          },
          maxDate: new Date(),
        },
        {
          fieldName: 'description',
          fieldNameSnap: 'description',
          type: FieldType.INPUT_TEXTAREA,
          typeView: FieldTypeView.VIEW_TEXT,
          title: 'Detailed Description',
          titleView: 'Detailed Description',
          placeholder: 'Enter detailed description or other relevant data',
          required: true,
          optionalView: true,
          styles: {
            wrapper: 'w-full form-item_white',
          },
        },
      ],
    },
    {
      title: 'Would you like to add Supporting Documents?',
      titleView: formData.sharedFiles
        ? 'Supporting Documents'
        : 'No Supporting Documents',
      disabledTitleView: !formData.sharedFiles,
      required: false,
      activated:
        (formData?.sharedFiles === '' || formData?.sharedFiles) &&
        formData?.sharedFiles !== 'YES',
      open: false,
      hasQA: true,
      formItems: [
        {
          fieldName: 'sharedFiles',
          type: FieldType.FILES,
          typeView: FieldTypeView.VIEW_FILES,
          optional: formData.sharedFiles === 'YES' || formData.sharedFiles,
          optionalView: true,
          enabled: formData.sharedFiles === 'YES',
          multifile: true,
        },
      ],
      qaItems,
      styles: {
        transparent: true,
      },
    },
  ];

  return groupFormItems;
};
export default getItemsCOModal;
