import { usePutApiCoreAssetsByAssetIdReportBuilderReportsAndIdMutation } from '@/entities/report/reportBuilder';
import { reportingEnhancedApi } from '@/entities/reporting/api/reportingEnhancedApi';
import { useReportingEntityKindContext } from '@/entities/reporting/context/entityKind';
import { useMemo } from 'react';

export const useUpdateReport = () => {
  const reportKind = useReportingEntityKindContext();

  const updateObjectLevelMutation =
    usePutApiCoreAssetsByAssetIdReportBuilderReportsAndIdMutation();

  const eagleEyeMutation =
    reportingEnhancedApi.usePutApiReportBuilderEagleEyeReportsByIdMutation();

  const mutation = useMemo(() => {
    switch (reportKind) {
      case 'eagle_eye': {
        return eagleEyeMutation;
      }
      case 'object_level': {
        return updateObjectLevelMutation;
      }
    }
  }, [reportKind]);

  return mutation;
};
