import { resolveFund, TFundView } from 'bundles/Construction/actions/report';
import { emptySplitApi } from '@/app/stores/api';

interface IGetConstructionSnapshotRequest {
  legalEntityCode: string;
  view: TFundView;
}

export const constructionSnapshotApi = emptySplitApi.injectEndpoints({
  endpoints: (build) => ({
    getConstructionSnapshot: build.query<any, IGetConstructionSnapshotRequest>({
      query: ({ legalEntityCode, view }) => ({
        url: `/api/reconcile/legal_entities/${legalEntityCode}/snapshots`,
        params: {
          allocation_fund: resolveFund(view),
        },
      }),
    }),
  }),
});

export const { useGetConstructionSnapshotQuery } = constructionSnapshotApi;
