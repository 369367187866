import React from 'react';
import {
  FieldType,
  FieldTypeView,
  IGroupFormItem,
} from 'bundles/Shared/components/GroupForm/types/types';
import { corNumberRegEx } from 'lib/dictionaries';
import { IJobCostCode } from 'types/JobCostCode';
import {
  formatAmount,
  percentNumberFormat,
} from '@/shared/lib/formatting/number';
import {
  IChangeOrderEvent,
  ICompany,
  IContact,
} from 'bundles/Construction/types';
import { dateTransformer } from 'bundles/Shared/components/GroupForm/FormViewItem/transformerFormViewItem';
import {
  amountTransformer,
  categoryTransformer,
  reallocationTransformer,
  reallocationTransformerName,
  reasonsTransformer,
} from '@/bundles/Construction/components/ChangeOrderEvent/FormComponents/transformer';
import COReallocation from '@/bundles/Construction/components/ChangeOrderEvent/FormComponents/COReallocation';
import CODORDate from '@/bundles/Construction/components/ChangeOrderEvent/FormComponents/CODORDate';
import COProbability from '@/bundles/Construction/components/ChangeOrderEvent/FormComponents/COProbability';
import HINTS from 'bundles/Construction/components/ChangeOrderEvent/hints';

import { TTypeFieldHD } from 'bundles/Shared/components/HistoryDetails/HistoryDetailsSwitcher';
import { formatUnixDate } from '@/shared/lib/formatting/dates';
import {
  useReconcileSubmittedByCompanyField,
  useReconcileSubmittedByContactField,
} from 'bundles/Construction/components/ChangeOrderEvent/fields/fields';

export const getDataHDArrowsCE = (
  kind: 'status_changed' | 'revise' | 'update',
) => {
  if (kind === 'status_changed') {
    return [
      {
        title: 'Key Info',
        data: [
          {
            titleView: 'Status',
            fieldName: 'status',
            typeField: TTypeFieldHD.ARROWS,
          },
        ],
      },
    ];
  }
  return [
    {
      title: 'Key Info',
      data: [
        {
          titleView: 'Name or Title',
          fieldName: 'title',
          typeField: TTypeFieldHD.ARROWS,
        },
        {
          titleView: 'Detailed Description',
          fieldName: 'description',
          typeField: TTypeFieldHD.ARROWS,
          styles: {
            wrapper: 'bottom',
          },
        },
        {
          titleView: 'Reasons',
          fieldName: 'changeOrderReasons',
          typeField: TTypeFieldHD.ARROWS,
          isArray: true,
          objectName: 'categoryWithName',
          styles: {
            wrapper: 'bottom',
          },
        },
        {
          titleView: 'Event category',
          fieldName: 'category',
          typeField: TTypeFieldHD.ARROWS,
        },
      ],
    },
    {
      title: 'Additional Details',
      data: [
        {
          titleView: 'Submitted by Company',
          fieldName: 'submittedByCompany',
          typeField: TTypeFieldHD.ARROWS,
        },
        {
          titleView: 'Submitted by Contact',
          fieldName: 'submittedByContact',
          typeField: TTypeFieldHD.ARROWS,
        },
        {
          titleView: 'Number',
          fieldName: 'number',
          typeField: TTypeFieldHD.ARROWS,
        },
        {
          titleView: 'Contractor date',
          fieldName: 'contractorDate',
          typeField: TTypeFieldHD.ARROWS,
          transformerView: (date: Date) => formatUnixDate(date, 'MMM DD, YYYY'),
        },
        {
          titleView: 'Received date',
          fieldName: 'receivedDate',
          typeField: TTypeFieldHD.ARROWS,
          transformerView: (date: Date) => formatUnixDate(date, 'MMM DD, YYYY'),
        },
      ],
    },
    {
      title: 'Financial Impact',
      data: [
        {
          titleView: 'Amount',
          fieldName: 'value',
          typeField: TTypeFieldHD.ARROWS,
          transformerView: formatAmount,
        },
        {
          titleView: 'Probability',
          fieldName: 'probability',
          typeField: TTypeFieldHD.ARROWS,
          transformerView: (val: number) =>
            `${val === null ? '0%' : val ? `${val}%` : 'n/a'}`,
        },
        {
          titleView: 'Weighted Amount',
          fieldName: 'weightedValue',
          typeField: TTypeFieldHD.ARROWS,
          transformerView: formatAmount,
        },
        {
          titleView: 'REallocations',
          fieldName: 'temporaryReallocations',
          typeField: TTypeFieldHD.ARROWS,
          isArray: true,
          objectName: 'from',
          styles: {
            wrapper: 'bottom',
          },
        },
      ],
    },
  ];
};

export const getHistoryDataCE = (type: 'update') => {
  const types = [
    {
      type: 'update',
      title: 'Details of modifying Change Event Details',
    },
    {
      type: 'revise',
      title: 'Revision Details',
    },
    {
      type: 'status_changed',
      title: 'Changing status Details',
    },
  ];

  return types.find((elem) => elem.type === type);
};

const getItemsCORModal = (
  formData: IChangeOrderEvent,
  setFormData: any,
  companies: ICompany[],
  contacts: IContact[],
  filteredReasons: any,
  JCCForReallocations?: IJobCostCode[],
): IGroupFormItem[] => {
  const AcceptableStatuses = {
    COR: ['VOID', 'PENDING-PROCEEDING', 'PENDING-NOT PROCEEDING'],
    PCO: ['VOID', 'DRAFT', 'PENDING-PROCEEDING', 'PENDING-NOT PROCEEDING'],
    IPC: ['VOID', 'DRAFT'],
  };
  const { category, value } = formData;
  const companyField = useReconcileSubmittedByCompanyField(companies, {
    fieldNameSnap: 'reconcile_company',
    styles: {
      wrapper: 'w-50',
    },
  });
  const contactField = useReconcileSubmittedByContactField(contacts, {
    fieldNameSnap: 'reconcile_contact',
    styles: {
      wrapper: 'w-50',
    },
  });
  const COR = {
    details: [
      companyField,
      contactField,
      {
        fieldName: 'number',
        fieldNameSnap: 'number',
        type: FieldType.INPUT_TEXT,
        typeView: FieldTypeView.VIEW_TEXT,
        title: 'Number',
        titleView: 'Number',
        placeholder: '0000-A',
        hint: HINTS.number,
        required: true,
        optionalView: true,
        validation: {
          message:
            'Please enter 1 to 4 digits and an optional letter (Examples: 123, 4325-A, 332G)',
          func: (e) => corNumberRegEx.test(e),
        },
        styles: {
          wrapper: 'w-25 form-item_white',
          size: 'm',
        },
      },
      {
        fieldName: 'contractorDate',
        fieldNameSnap: 'contractor_date',
        type: FieldType.INPUT_DATE,
        typeView: FieldTypeView.VIEW_TEXT,
        title: 'Contractor date',
        titleView: 'Contractor date',
        transformerView: dateTransformer,
        placeholder: 'Date',
        hint: HINTS.contractorDate,
        required: true,
        optionalView: true,
        format: 'MM-DD-YYYY',
        formatView: 'MM-dd-yy',
        leftIcon: 'calendar',
        styles: {
          wrapper: 'w-25 form-item_white',
          size: 'm',
        },
        maxDate: new Date(),
      },
      {
        fieldName: 'receivedDate',
        fieldNameSnap: 'received_date',
        type: FieldType.INPUT_DATE,
        typeView: FieldTypeView.VIEW_TEXT,
        title: 'Received date',
        titleView: 'Received date',
        transformerView: dateTransformer,
        placeholder: 'Date',
        hint: HINTS.receivedDate,
        required: true,
        optionalView: true,
        format: 'MM-DD-YYYY',
        formatView: 'MM-dd-yy',
        leftIcon: 'calendar',
        styles: {
          wrapper: 'w-25 form-item_white',
          size: 'm',
        },
        maxDate: new Date(),
      },
      {
        fieldName: 'status',
        fieldNameSnap: 'status',
        type: FieldType.SELECT,
        typeView: FieldTypeView.VIEW_TEXT,
        title: 'Status',
        titleView: 'Status',
        placeholder: 'Select status',
        required: true,
        optionalView: true,
        options: AcceptableStatuses.COR.map((el) => ({ value: el, label: el })),
        styles: {
          wrapper: 'w-50',
        },
      },
      {
        fieldName: 'sentToDesignerOfRecordDate',
        fieldNameSnap: 'sent_to_designer_of_record_date',
        type: FieldType.OPTIONAL_SECTION,
        typeView: FieldTypeView.VIEW_TEXT,
        title: 'DOR date',
        titleView: 'DOR date',
        hint: HINTS.sentToDesignerOfRecordDate,
        transformerView: dateTransformer,
        optional: false,
        optionalView: formData?.sentToDesignerOfRecordDate,
        content: <CODORDate formData={formData} setFormData={setFormData} />,
        styles: {
          nomb: true,
        },
      },
    ],
  };
  const PCO = {
    details: [
      companyField,
      contactField,
      {
        fieldName: 'number',
        fieldNameSnap: 'number',
        type: FieldType.INPUT_TEXT,
        typeView: FieldTypeView.VIEW_TEXT,
        title: 'Number',
        titleView: 'Number',
        placeholder: '0000-A',
        hint: HINTS.number,
        required: true,
        optionalView: true,
        validation: {
          message:
            'Please enter 1 to 4 digits and an optional letter (Examples: 123, 4325-A, 332G)',
          func: (e) => corNumberRegEx.test(e),
        },
        styles: {
          wrapper: 'w-25 form-item_white',
          size: 'm',
        },
      },
      {
        fieldName: 'contractorDate',
        fieldNameSnap: 'contractor_date',
        type: FieldType.INPUT_DATE,
        typeView: FieldTypeView.VIEW_TEXT,
        title: 'Contractor date',
        titleView: 'Contractor date',
        transformerView: dateTransformer,
        placeholder: 'Date',
        hint: HINTS.contractorDate,
        required: true,
        optionalView: true,
        format: 'MM-DD-YYYY',
        formatView: 'MM-dd-yy',
        leftIcon: 'calendar',
        styles: {
          wrapper: 'w-25 form-item_white',
          size: 'm',
        },
        maxDate: new Date(),
      },
      {
        fieldName: 'receivedDate',
        fieldNameSnap: 'received_date',
        type: FieldType.INPUT_DATE,
        typeView: FieldTypeView.VIEW_TEXT,
        title: 'Received date',
        titleView: 'Received date',
        transformerView: dateTransformer,
        placeholder: 'Date',
        hint: HINTS.receivedDate,
        required: true,
        optionalView: true,
        format: 'MM-DD-YYYY',
        formatView: 'MM-dd-yy',
        leftIcon: 'calendar',
        styles: {
          wrapper: 'w-25 form-item_white',
          size: 'm',
        },
        maxDate: new Date(),
      },
      {
        fieldName: 'status',
        fieldNameSnap: 'status',
        type: FieldType.SELECT,
        typeView: FieldTypeView.VIEW_TEXT,
        title: 'Status',
        titleView: 'Status',
        placeholder: 'Select status',
        required: true,
        optionalView: true,
        options: AcceptableStatuses.PCO.map((el) => ({ value: el, label: el })),
        styles: {
          wrapper: 'w-50',
        },
      },
    ],
  };

  const IPC = {
    details: [
      contactField,
      {
        fieldName: 'status',
        fieldNameSnap: 'status',
        type: FieldType.SELECT,
        typeView: FieldTypeView.VIEW_TEXT,
        title: 'Status',
        titleView: 'Status',
        placeholder: 'Select status',
        required: true,
        optionalView: true,
        options: AcceptableStatuses.IPC.map((el) => ({ value: el, label: el })),
        styles: {
          wrapper: 'w-50',
        },
      },
    ],
  };

  const changeData = { COR, PCO, IPC };

  const fileChangeStatusYes = () => {
    if (!formData.sharedFiles) {
      setFormData({
        ...formData,
        sharedFiles: 'YES',
      });
    }
  };

  const fileChangeStatusNo = () => {
    setFormData({
      ...formData,
      sharedFiles: '',
    });
  };

  const qaItems = {
    yes: {
      active: formData?.sharedFiles === 'YES' || formData?.sharedFiles,
      handle: fileChangeStatusYes,
      disabled: !formData?.category,
    },
    no: {
      active: formData.sharedFiles === '',
      handle: fileChangeStatusNo,
      disabled: !formData?.category,
    },
  };

  const groupFormItems = [
    {
      title: 'Key Info',
      required: true,
      activated: true,
      open: true,
      formItems: [
        {
          fieldName: 'title',
          fieldNameSnap: 'title',
          type: FieldType.INPUT_TEXT,
          typeView: FieldTypeView.VIEW_TEXT,
          title: 'Name or Title',
          titleView: 'Name or Title',
          placeholder: 'Enter summary description of change event',
          required: true,
          optionalView: true,
          styles: {
            wrapper: 'w-full form-item_white',
            size: 'm',
            back_layer: 'bg-white',
          },
        },
        {
          fieldName: 'description',
          fieldNameSnap: 'description',
          type: FieldType.INPUT_TEXTAREA,
          typeView: FieldTypeView.VIEW_TEXT,
          title: 'Detailed Description',
          titleView: 'Detailed Description',
          placeholder: 'Enter detailed description or other relevant data',
          required: true,
          optionalView: true,
          styles: {
            wrapper: 'w-full form-item_white',
          },
        },
        {
          fieldName: 'changeOrderReasons',
          fieldNameSnap: 'changeOrderReasons',
          type: FieldType.LIST_SELECT,
          typeView: FieldTypeView.VIEW_TEXT,
          transformerView: reasonsTransformer,
          title: 'Reasons',
          titleView: 'Reasons',
          placeholder: 'Select Reasons',
          required: true,
          optionalView: true,
          options: filteredReasons,
          isMulti: true,
          styles: {
            wrapper: 'w-75 form-item_white',
            input: 'w-60',
            size: 'm',
          },
        },
        {
          fieldName: 'category',
          fieldNameSnap: 'category',
          type: FieldType.SELECTED_BUTTONS,
          typeView: FieldTypeView.VIEW_TEXT,
          title: 'Select Event category',
          titleView: 'Event category',
          hint: HINTS.category,
          transformerView: categoryTransformer,
          placeholder: 'Enter detailed description or other relevant data',
          buttons: [
            {
              title: 'COR',
              text: 'Change Order Request',
              active: formData.category === 'COR',
              value: 'COR',
            },
            {
              title: 'PCO',
              text: 'Potential Change Order',
              active: formData.category === 'PCO',
              value: 'PCO',
            },
            {
              title: 'IPC',
              text: 'Internal Risk of Change',
              active: formData.category === 'IPC',
              value: 'IPC',
            },
          ],
          required: true,
          optionalView: true,
          styles: {
            nomb: true,
            wrapper: 'w-full',
          },
        },
      ],
    },
    {
      title: 'Additional Details',
      required: true,
      activated: true,
      open: false,
      formItems: category ? changeData[category].details : [],
    },
    {
      title: 'Financial Impact',
      required: true,
      activated: true,
      open: false,
      formItems: [],
    },
    {
      title: 'Would you like to add Supporting Documents?',
      titleView: formData.sharedFiles
        ? 'Supporting Documents'
        : 'No Supporting Documents',
      disabledTitleView: !formData.sharedFiles,
      required: false,
      activated:
        formData?.category &&
        (formData?.sharedFiles === '' || formData?.sharedFiles) &&
        formData?.sharedFiles !== 'YES',
      open: false,
      hasQA: true,
      formItems: [],
      qaItems,
      styles: {
        transparent: true,
      },
    },
  ];
  if (category) {
    groupFormItems[2].formItems.push(
      {
        fieldName: 'value',
        fieldNameSnap: 'value',
        type: FieldType.INPUT_NUMBER,
        typeView: FieldTypeView.VIEW_TEXT,
        title: 'Amount',
        titleView: 'Amount',
        transformerView: amountTransformer,
        leftIcon: 'paid',
        placeholder: '0',
        hint: HINTS.amount,
        required: true,
        optionalView: true,
        styles: {
          wrapper: 'w-25 form-item_white',
          size: 'm',
        },
        thousandSeparator: true,
      },
      {
        fieldName: 'probability',
        fieldNameSnap: 'probability',
        type: FieldType.OPTIONAL_SECTION,
        typeView: FieldTypeView.VIEW_TEXT,
        title: 'Probability',
        titleView: 'Probability',
        transformerView: percentNumberFormat,
        hint: HINTS.probability,
        optional: false,
        optionalView: true,
        defaultValue: '100',
        expanded: formData.probability,
        content: (
          <COProbability formData={formData} setFormData={setFormData} />
        ),
        viewComponent: [
          {
            fieldName: 'probability_comment',
            typeView: FieldTypeView.VIEW_TEXT,
            titleView: 'Probability Comment',
          },
        ],
      },
    );
    groupFormItems[3].formItems.push({
      fieldName: 'sharedFiles',
      type: FieldType.FILES,
      typeView: FieldTypeView.VIEW_FILES,
      optional: formData.sharedFiles === 'YES' || formData.sharedFiles,
      optionalView: true,
      enabled: formData.sharedFiles === 'YES',
      multifile: true,
    });
  }
  if (category && category !== 'IPC') {
    groupFormItems[2].formItems.push({
      fieldName: 'reallocationJccId',
      fieldNameSnap: 'reallocation_jcc_id',
      type: FieldType.OPTIONAL_SECTION,
      typeView: FieldTypeView.VIEW_TEXT,
      title: 'REallocation',
      titleView: 'REallocation Budget Category',
      hint: HINTS.reallocationJccId,
      transformerView: (value: any) =>
        reallocationTransformerName(value, JCCForReallocations),
      optional: false,
      optionalView: formData?.reallocationJccId,
      content: <COReallocation formData={formData} setFormData={setFormData} />,
      viewComponent: [
        {
          fieldName: 'weightedAmount',
          typeView: FieldTypeView.VIEW_TEXT,
          titleView: 'REallocation Amount',
          transformerView: reallocationTransformer,
        },
      ],
      styles: {
        nomb: true,
      },
    });
  }
  return groupFormItems;
};
export default getItemsCORModal;
