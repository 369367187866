import { portfolioApi } from '@/shared/api/portfolioGeneratedApi';

const portfolioEnhancedApi = portfolioApi.enhanceEndpoints({});

export const {
  useGetApiPortfolioActivityLogItemsQuery,
  useGetApiPortfolioDocumentsRecentQuery,
  useGetApiPortfolioContributionsByIdQuery,
  useGetApiPortfolioDistributionsByIdQuery,
  useGetApiPortfolioQuery,
  useGetApiPortfolioDetailsQuery,
  useGetApiPortfolioInvestmentEntitiesQuery,
} = portfolioEnhancedApi;
