import { Button } from '@/stories/Button/Button';
import { IconButton } from '@/stories/IconButton/IconButton';
import { DEFAULT_DROPDOWN_OFFSET } from '@/stories/Popover/Popover';

interface Props {
  onNext: () => void;
  onBack: () => void;
  onCurrent: () => void;
  disableOnNext?: boolean;
  disableOnBack?: boolean;
}

function CalendarPagination({
  onNext,
  onBack,
  onCurrent,
  disableOnNext,
  disableOnBack,
}: Props) {
  return (
    <div className="flex h-max gap-1">
      <IconButton
        variant="secondary"
        style={{
          transform: 'scale(-1,1)',
        }}
        iconName="right"
        className="text-neutral-500"
        onClick={onBack}
        disabled={disableOnBack}
      />
      <Button
        size="xs"
        tooltipProps={{
          mainText: 'Current Week',
          placement: 'top',
          offset: DEFAULT_DROPDOWN_OFFSET,
        }}
        variant="secondary"
        onClick={onCurrent}
      >
        <div className="h-[8px] w-[8px] rounded-full bg-neutral-500" />
      </Button>
      <IconButton
        variant="secondary"
        iconName="right"
        className="text-neutral-500"
        onClick={onNext}
        disabled={disableOnNext}
      />
    </div>
  );
}

export default CalendarPagination;
