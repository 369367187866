import { fetchCustomerConfig } from 'bundles/Assets/actions/CustomerConfig/fetchCustomerConfig';
import {
  changePagePosition,
  createUserRole,
} from 'bundles/UserManagement/reducers/userRolesSlice';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import { capitalize } from 'lodash-es';
import React, { useEffect, useMemo, useState } from 'react';
import { AnimationLoader } from 'stories/AnimationLoader/AnimationLoader';
import { Button } from 'stories/Button/Button';
import { Field } from 'stories/Field/Field';
import { IconButton } from 'stories/IconButton/IconButton';
import { Input } from 'stories/FormControls/Inputs/Input/Input';
import { Select } from 'stories/FormControls/Select/Select';
import { SelectableMultilineButton } from 'stories/SelectableMultilineButton/SelectableMultilineButton';
import type { ISelectOption } from 'stories/FormControls/Select/Select';
import type { IProduct, IProductPermissionKeys, IUserRole } from 'types/User';
import { CATEGORIES } from '@/bundles/UserManagement/components/roles/constants';
import { genUpdatedRole } from '@/bundles/UserManagement/components/roles/helpers/genUpdatedRole';
import type * as Types from '@/bundles/UserManagement/components/roles/types';
import { prepareProducts } from '@/bundles/UserManagement/components/roles/utils';
import { RolePermissions } from '@/bundles/UserManagement/components/roles/RightBar/RolePermissions';

interface Props {
  activeRoleCategory: IUserRole['category'];
  setRightBarState: React.Dispatch<
    React.SetStateAction<Types.RightBarStateType>
  >;
  groupOptions: Types.IGroupOptions;
}

export function CreateRole({
  activeRoleCategory,
  setRightBarState,
  groupOptions,
}: Props) {
  const [createRoleState, setCreateRoleState] =
    useState<Types.CreateRoleStateType>('idle');
  const dispatch = useAppDispatch();
  const restrictProducts = useAppSelector(
    (state) => state.customerConfig.restrictProducts,
  );
  const [formData, setFormData] = useState<Types.ICreateRoleFormData>({
    category: activeRoleCategory,
    name: '',
    group: null,
    products: [],
  });

  useEffect(() => {
    if (!restrictProducts.length) {
      setCreateRoleState('loading');
      dispatch(fetchCustomerConfig());
      setCreateRoleState('loaded');
    }
  }, []);

  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      products: prepareProducts(restrictProducts),
    }));
  }, [restrictProducts]);

  const isFormDataValid = useMemo(
    () =>
      formData.category !== null &&
      formData.name !== '' &&
      formData.products.some((product) =>
        Object.values(product).includes(true),
      ),
    [formData],
  );

  if (createRoleState === 'loading') {
    return <AnimationLoader />;
  }

  const handleSelectCategory = (category: IUserRole['category']) => {
    setFormData((prev) => ({ ...prev, group: null, category }));
  };

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = ({
    target,
  }) => {
    setFormData((prev) => ({
      ...prev,
      [target.name]: target.value,
    }));
  };

  const handleSelect = (sel: ISelectOption | ISelectOption[]) => {
    if (Array.isArray(sel)) return;
    setFormData((prev) => ({
      ...prev,
      group: sel?.label ?? '',
    }));
  };

  const handleChangedState = () => {
    setRightBarState(
      isFormDataValid ? 'create:validData' : 'create:invalidData',
    );
  };

  const resetState = () => {
    setRightBarState('idle');
  };

  const toggleDataPermission = (
    key: IProductPermissionKeys,
    productId: IProduct['id'],
  ) => {
    setFormData((prevRole) => genUpdatedRole(prevRole, key, productId));
  };

  return (
    <>
      <div className="mb-s flex items-start justify-between pb-[0.75rem]">
        <h4 className="header4-bold">Create Role</h4>
        <IconButton
          className="bg-white"
          onClick={() => dispatch(changePagePosition('idle'))}
          size="l"
          iconName="close"
        />
      </div>

      <div className="mb-l grid gap-s">
        <span className="light-90 inline-semibold">
          Select a Role Type <span className="red">*</span>
        </span>
        <div className="flex items-center gap-s">
          {CATEGORIES.map(({ text, value }) => (
            <SelectableMultilineButton
              selected={formData.category === value}
              onClick={() => handleSelectCategory(value)}
              key={value}
              title={capitalize(value)}
              text={text}
            />
          ))}
        </div>
      </div>

      <div className="mb-l grid gap-s">
        <span className="light-90 inline-semibold">Key Info</span>
        <div className="grid gap-m rounded-[8px] bg-white p-m">
          <Field labelText="Group">
            <Select
              disabled={formData.category === null}
              placeholder="Select Role Group"
              spanContainer="m-0"
              inputProps={{ className: 'w-full' }}
              selected={{
                id: formData?.group ?? '',
                label: formData?.group ?? '',
              }}
              options={groupOptions[formData.category]}
              onSelectedChange={handleSelect}
            />
          </Field>
          <Field labelText="Role Name" required>
            <Input
              placeholder="Enter role Name"
              value={formData.name}
              name="name"
              onChange={handleChange}
            />
          </Field>
        </div>
      </div>

      <RolePermissions
        requiredMark
        roleDataState={formData}
        actions={{ handleChangedState, resetState, toggleDataPermission }}
      />

      <span className="light-60 label-regular mt-l text-center">
        Complete all required steps{' '}
      </span>
      <Button
        className="mt-s"
        size="l"
        onClick={() => {
          dispatch(createUserRole(formData));
        }}
        disabled={!isFormDataValid}
        variant="success"
      >
        Create Role
      </Button>
    </>
  );
}
