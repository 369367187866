import { CELL_CLASS_NAMES } from '@/bundles/Shared/components/AgGrid/Table/classNames';
import { buildTableCellStyles } from '@/bundles/Shared/components/AgGrid/Table/utils/useTableCellStyleApplier';
import type { AgGridTableContext } from '@/bundles/Shared/widgets/dashboard/widgets/common/model';
import { WidgetViewMode } from '@/bundles/Shared/widgets/dashboard/widgets/model';
import { AUTO_GROUP_COLUMN_KEY } from '@/lib/ag-grid/constants';
import { AgGridReactRef } from '@/lib/ag-grid/types';
import { cn } from '@/shared/lib/css/cn';
import {
  ColDef,
  ColGroupDef,
  Column,
  ExcelExportParams,
  GridOptions,
  HeaderValueGetterParams,
  ICellRendererParams,
  IHeaderParams,
} from 'ag-grid-community';
import { AgGridReactProps } from 'ag-grid-react';
import { HeaderGroupComponent } from 'bundles/Shared/components/AgGrid/Table/cellComponents/HeaderGroupComponent';
import {
  AUTO_COLUM_DEF_MAX_WIDTH,
  AUTO_COLUM_DEF_MIN_WIDTH,
  DEFAULT_GROUP_BG_CLASS,
  getFakeColumnDefs,
  WIDGET_TABLE_AUTO_GROUP_COL_DEF,
} from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/lib';
import {
  findColumnByColDef,
  getUngroupedColumns,
  isKpiTableRowAsset,
  isKpiTableRowSegment,
  isKpiTableRowTotal,
  KpiTableGroupingType,
  KpiTableWidgetDataRow,
} from 'bundles/Shared/widgets/dashboard/widgets/kpiTable';
import { isGroupColDef } from 'lib/ag-grid/utils';
import { cloneDeep, merge } from 'lodash-es';
import { useEffect, useMemo } from 'react';
import { UnknownRecord } from 'type-fest/source/internal';

export const PATH_DELIMITER = '/';

export const ASSET_PATH_PREFIX = 'asset-';
export const SEGMENT_PATH_PREFIX = 'segment-';
export const LEGAL_ENTITY_PATH_PREFIX = 'legal_entity-';

export const ROW_HEIGHT = 34;
const EXTENDED_ROW_HEIGHT = 52;
const PINNED_ROW_HEIGHT = 42;

export const isDividerColumn = (col: Column) =>
  col.getColId().startsWith('divider-column-');

export const useAutoGroupColDef = ({
  groupingType,
  mode,
  ref,
  colDef,
  headerBackground,
}: {
  groupingType: KpiTableGroupingType;
  mode: WidgetViewMode;
  ref?: React.RefObject<AgGridReactRef>;
  colDef?: ColDef;
  headerBackground?: React.CSSProperties['color'] | undefined;
}) => {
  useEffect(() => {
    ref?.current?.api?.refreshHeader();
  }, [groupingType]);

  return useMemo<ColDef>(() => {
    return merge(cloneDeep(WIDGET_TABLE_AUTO_GROUP_COL_DEF), {
      sortable: mode !== 'pdf',
      minWidth: AUTO_COLUM_DEF_MIN_WIDTH,
      maxWidth: AUTO_COLUM_DEF_MAX_WIDTH,
      headerValueGetter: (params: HeaderValueGetterParams) => {
        return params.context.groupingType === 'assets' ? 'Asset' : 'Segment';
      },
      headerComponentParams: (
        params: IHeaderParams<unknown, AgGridTableContext>,
      ) => ({
        headerName:
          params.context.groupingType === 'assets' ? 'Asset' : 'Segment',
        subHeaderName:
          params.context.groupingType === 'assets' ? 'Entity' : 'Asset',
        enableExpand: params.context.groupingType === 'segments',
        // TODO replace with AutoColStyleBuilder in FE-3921
        style: buildTableCellStyles({
          background: headerBackground,
        }),
      }),
      cellRendererParams: (params: ICellRendererParams) => {
        const { node } = params;
        const isTotalRow = node.data && isKpiTableRowTotal(node.data);
        const isSegmentRow = node.data && isKpiTableRowSegment(node.data);
        const isAssetRow = node.data && isKpiTableRowAsset(node.data);
        const hasChildren =
          node.childrenAfterGroup && node.childrenAfterGroup.length > 0;
        const resolveIcon = () => {
          if (isAssetRow && hasChildren) {
            return undefined;
          }
          if (isAssetRow) {
            return 'asset';
          }
          return 'segments';
        };
        return {
          ...(isSegmentRow || isAssetRow
            ? {
                subLabel: params.node.data.subLabel,
                icon: resolveIcon(),
              }
            : {}),
          classes: {
            wrapper: cn(
              CELL_CLASS_NAMES.AutoGroupCell.wrapper.basic,
              isTotalRow &&
                '!bg-neutral-850 !border-neutral-700 !text-neutral-000',
            ),
            inner: cn(
              CELL_CLASS_NAMES.AutoGroupCell.inner.basic,
              colDef?.colId === AUTO_GROUP_COLUMN_KEY && '!justify-start',
            ),
          },
        };
      },
      ...colDef,
    });
  }, [colDef?.cellClass, headerBackground]);
};

export const getDataPath: GridOptions['getDataPath'] = (row) =>
  row.path?.split(PATH_DELIMITER) as string[];

export const getRowHeight: AgGridReactProps['getRowHeight'] = (params) => {
  const row = params.node.data as KpiTableWidgetDataRow;
  if (row?.subLabel) {
    return EXTENDED_ROW_HEIGHT;
  }
  if (params.node.isRowPinned()) {
    return PINNED_ROW_HEIGHT;
  }
  return ROW_HEIGHT;
};

export const getRowId: GridOptions['getRowId'] = ({ data: row }) => {
  return row.path;
};

export const addDividersToColumnGroups = (
  columnGroups: ColGroupDef[],
): ColGroupDef[] => {
  if (columnGroups.length === 1) {
    return columnGroups;
  }

  const dividersCount = columnGroups.length - 1;
  return Array.from({
    length: columnGroups.length + dividersCount,
  }).map((_, index) => {
    if (index % 2 === 1) {
      return {
        headerName: '',
        groupId: `divider-group-${index}`,
        headerGroupComponent: HeaderGroupComponent,
        headerGroupComponentParams: {
          classes: {
            wrapper: '!bg-neutral-100 !border-neutral-100',
          },
        },
        children: [
          {
            width: 8,
            maxWidth: 8,
            colId: `divider-column-${index}`,
            headerComponentParams: {
              classes: {
                wrapper: '!bg-neutral-100 !border-neutral-100',
              },
            },
            cellClass: (params) => {
              if (params.node.data && isKpiTableRowTotal(params.node.data)) {
                return '!bg-neutral-700';
              }
              return '!bg-neutral-100';
            },
            cellRenderer: () => <></>,
          },
        ],
      };
    }
    return columnGroups[Math.floor(index / 2)];
  });
};

export const buildWidgetTableColumnDefs = <
  T extends {
    label: string;
    key: number;
  },
>({
  columns,
  columnToColDefMapper,
  rows,
  agGridConfig,
}: {
  columns: T[];
  columnToColDefMapper: (c: T, cg?: ColGroupDef) => ColDef;
  rows: UnknownRecord[];
  agGridConfig: Partial<GridOptions>;
}): (ColDef | ColGroupDef)[] => {
  if (columns.length === 0 || rows.length === 0) {
    return getFakeColumnDefs(
      columns.map((c) => ({
        headerName: c.label,
        cellRendererParams: {
          classes: {
            wrapper: DEFAULT_GROUP_BG_CLASS,
          },
        },
      })),
      rows.length,
    );
  }
  const { columnDefs } = agGridConfig;
  if (columnDefs?.some(isGroupColDef)) {
    const colGroupDefs = columnDefs?.filter(isGroupColDef);
    const mappedColGroupDefs = colGroupDefs.map((cg: ColGroupDef) => {
      const children = cg.children.map((c: ColDef) => {
        const column = findColumnByColDef(columns, c);

        return {
          ...(column && columnToColDefMapper(column, cg)),
          ...c,
        };
      });
      return {
        ...cg,
        groupId: cg.groupId && `group-${cg.groupId?.toString()}`,
        children,
      };
    });

    return [
      ...getUngroupedColumns(colGroupDefs, columns).map((c) => ({
        ...columnToColDefMapper(c),
        ...columnDefs.find((cd: ColDef) => cd.colId === c.key.toString()),
      })),
      ...addDividersToColumnGroups(mappedColGroupDefs),
    ];
  }
  return [...columns.map(columnToColDefMapper)];
};

export const KPI_TABLE_WIDGET_EXPORT_PARAMS = {
  shouldRowBeSkipped: (rowParams) => {
    if (!rowParams.node.parent || rowParams.node.parent.level === -1) {
      return false;
    }
    return !rowParams.node.parent.expanded;
  },
} as const satisfies ExcelExportParams;
