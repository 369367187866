import {
  reportingGeneratedApi,
  type DeleteApiReportBuilderReportGroupsByIdApiResponse,
  type PostApiReportBuilderEagleEyeReportsByEagleEyeReportIdPdfApiResponse,
  type PostApiReportBuilderReportGroupsApiResponse,
  type PostApiReportBuilderReportGroupsByReportGroupIdEagleEyeReportsApiResponse,
  type PutApiReportBuilderEagleEyeReportsByIdApiResponse,
} from '@/entities/reporting/api/reportingGeneratedApi';
import { createApiTagType } from '@/shared/lib/api/lib';
import { EntityToastrCreator } from '@/shared/lib/toastr/entityToastrCreator';

const reportGroupToast = new EntityToastrCreator('Report Group');
const reportToast = new EntityToastrCreator('Report');
const widgetToast = new EntityToastrCreator('Widget');

export const reportingEnhancedApi = reportingGeneratedApi.enhanceEndpoints({
  addTagTypes: [
    'eagleEye:reportGroups',
    'eagleEye:reportGroup:reports',
    'eagleEye:report',
    'eagleEye:report:post:snapshot',
    'eagleEye:report:get:snapshot',
    'reportTemplates',
  ],
  endpoints: {
    getApiReportBuilderReportGroups: {
      providesTags: ['eagleEye:reportGroups'],
    },
    postApiReportBuilderReportGroups: {
      invalidatesTags: ['eagleEye:reportGroups'],
      transformResponse: (
        response: PostApiReportBuilderReportGroupsApiResponse,
      ) => {
        toastr.success(reportGroupToast.create().toString());
        return response;
      },
    },
    putApiReportBuilderReportGroupsById: {
      invalidatesTags: ['eagleEye:reportGroups'],
      transformResponse: (
        response: PostApiReportBuilderReportGroupsApiResponse,
      ) => {
        toastr.success(reportGroupToast.update().toString());
        return response;
      },
    },
    getApiReportBuilderReportGroupsByReportGroupIdEagleEyeReports: {
      providesTags: (_, __, args) => [
        createApiTagType('eagleEye:reportGroup:reports', args.reportGroupId),
      ],
    },
    getApiReportBuilderEagleEyeReportsById: {
      providesTags: (_, __, args) => [
        createApiTagType('eagleEye:report', args.id),
      ],
    },
    deleteApiReportBuilderReportGroupsById: {
      invalidatesTags: () => ['eagleEye:reportGroups'],
      transformResponse: (
        res: DeleteApiReportBuilderReportGroupsByIdApiResponse,
      ) => {
        toastr.success(reportGroupToast.delete().toString());
        return res;
      },
      // TODO Optimistic update
    },
    deleteApiReportBuilderEagleEyeReportsById: {
      invalidatesTags: (_, __, _args) => [
        'eagleEye:reportGroups',
        'eagleEye:reportGroup:reports',
      ],
      transformResponse: (
        res: PostApiReportBuilderReportGroupsByReportGroupIdEagleEyeReportsApiResponse,
      ) => {
        toastr.success(reportToast.delete().toString());
        return res;
      },
      // TODO Optimistic update
    },
    postApiReportBuilderReportGroupsByReportGroupIdEagleEyeReports: {
      invalidatesTags: (_, __, args) => [
        'eagleEye:reportGroups',
        createApiTagType('eagleEye:reportGroup:reports', args.reportGroupId),
      ],
      transformResponse: (
        res: PostApiReportBuilderReportGroupsByReportGroupIdEagleEyeReportsApiResponse,
      ) => {
        toastr.success(reportToast.create().toString());
        return res;
      },
    },
    putApiReportBuilderEagleEyeReportsById: {
      invalidatesTags: (_, __, args) => [
        'eagleEye:reportGroups',
        createApiTagType('eagleEye:report', args.id),
      ],
      transformResponse: (
        res: PutApiReportBuilderEagleEyeReportsByIdApiResponse,
      ) => {
        toastr.success(reportToast.update().toString());
        return res;
      },
      // TODO Optimistic update
    },
    putApiReportBuilderEagleEyeReportsByEagleEyeReportIdWidgetSectionsAndWidgetSectionIdSnapshot:
      {
        invalidatesTags: (_, __, args) => [
          createApiTagType('eagleEye:report', args.eagleEyeReportId),
          'eagleEye:report:post:snapshot',
        ],
        transformResponse: (
          res: PutApiReportBuilderEagleEyeReportsByIdApiResponse,
        ) => {
          toastr.success(widgetToast.update().toString());
          return res;
        },
      },

    postApiReportBuilderEagleEyeReportsByEagleEyeReportIdPdf: {
      invalidatesTags: (_, __, args) => [
        'eagleEye:reportGroups',
        'eagleEye:reportGroup:reports',
        createApiTagType('eagleEye:report', args.eagleEyeReportId),
      ],
      transformResponse: (
        res: PostApiReportBuilderEagleEyeReportsByEagleEyeReportIdPdfApiResponse,
      ) => {
        toastr.success(reportToast.generate().toString());
        return res;
      },
    },

    postApiReportBuilderEagleEyeReportsByEagleEyeReportIdWidgetSectionsAndWidgetSectionIdSnapshot:
      {
        providesTags: ['eagleEye:report:post:snapshot'],
      },
    getApiReportBuilderEagleEyeReportsByEagleEyeReportIdWidgetSectionsAndWidgetSectionIdSnapshot:
      {
        providesTags: ['eagleEye:report:get:snapshot'],
      },
  },
});
