import React from 'react';
import { cn } from '@/shared/lib/css/cn';
import styles from '@/pages/portfolio/widgets/Dashboard/Dashboard.module.scss';

export function SettingsHugeTab({
  inactive,
  handleClick,
  children,
  hasShadow = false,
}: {
  inactive: boolean;
  handleClick: () => void;
  children: React.ReactNode;
  hasShadow?: boolean;
}) {
  return (
    <button
      onClick={handleClick}
      type="button"
      className={cn('flex flex-col p-m light-90 w-full', styles.tab, {
        [styles.inactive]: inactive,
        'shadow-m-light': hasShadow,
      })}
    >
      {children}
    </button>
  );
}
