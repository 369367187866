import {
  convertDecimalToPercentage,
  convertToBps,
} from '@/shared/lib/converters';
import { DateSSN } from '@/shared/lib/dateSSN/ssn';
import {
  isDateValueDisplayOptions,
  isTextValueDisplayOptions,
  ValueDisplayOptions,
  ValueDisplayType,
} from '@/shared/lib/formatting/displayOptions';
import { DEFAULT_NUMBER_FALLBACK } from '@/shared/lib/formatting/fallbacks';
import { CurrencyFormatter } from '@/stories/ValueFormatters/CurrencyFormatter';
import { MetricNumberFormatter } from '@/stories/ValueFormatters/MetricNumberFormatter';
import { PercentFormatter } from '@/stories/ValueFormatters/PercentFormatter';
import { DateFormatter } from '@/stories/ValueFormatters/DateFormatter';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import {
  BasicCellRenderer,
  BasicCellRendererProps,
} from 'bundles/Shared/components/AgGrid/Table/cellComponents/BasicCellRenderer';
import { merge } from 'lodash-es';
import React, { CSSProperties } from 'react';
import BpsNumberFormatter from 'stories/ValueFormatters/BpsNumberFormatter';
import { NumberFormatter } from 'stories/ValueFormatters/NumberFormatter';
import SqftNumberFormatter from 'stories/ValueFormatters/SqftNumberFormatter';
import { UnknownRecord } from 'type-fest/source/internal';

export type FormattedColDef = Pick<ColDef, 'cellRenderer' | 'type'> & {
  displayOptions?: ValueDisplayOptions;
};
export type CellRenderers =
  | 'number'
  | 'percentage'
  | 'currency'
  | 'sqft'
  | 'metric'
  | 'date'
  | 'bps'
  | 'text';

type CellRenderersWithoutNumber = Exclude<CellRenderers, 'number' | 'text'>;

export const DISPLAY_TYPE_NUMBER_FORMATTERS = {
  currency: CurrencyFormatter,
  percentage: PercentFormatter,
  metric: MetricNumberFormatter,
  sqft: SqftNumberFormatter,
  number: NumberFormatter,
  bps: BpsNumberFormatter,
  date: DateFormatter,
} as const satisfies Record<Exclude<CellRenderers, 'text'>, React.FC>;

const TextValueFormatter = ({
  value,
  fallbackValue = DEFAULT_NUMBER_FALLBACK,
}: {
  value: string | null;
  fallbackValue?: string;
}) => (value === '' || value == null ? fallbackValue : value);

export const convertValueByDisplayType = (
  value: number | null | string | Date,
  displayType: ValueDisplayType,
) => {
  if (displayType === 'date') {
    return value ? new DateSSN(value).toDate() : null;
  }
  if (displayType === 'percentage') {
    return value ? convertDecimalToPercentage(value) : value;
  }
  if (displayType === 'bps') {
    return value ? convertToBps(value) : value;
  }
  return value;
};

export const getFormatterByDisplayOptions = (
  displayOptions: ValueDisplayOptions,
) => {
  if (isTextValueDisplayOptions(displayOptions)) {
    return TextValueFormatter;
  }

  if (isDateValueDisplayOptions(displayOptions)) {
    return ({ value, ...props }: { value: Date | null }) => (
      <DateFormatter
        value={convertValueByDisplayType(value, displayOptions.type)}
        dateFormat={displayOptions.date_format}
        {...props}
      />
    );
  }
  const { type, precision, hide_comma_separator, kilo_formatting } =
    displayOptions;

  const Component = DISPLAY_TYPE_NUMBER_FORMATTERS[type];

  return ({
    value,
    ...props
  }: { value: number | null } & React.ComponentProps<
    (typeof DISPLAY_TYPE_NUMBER_FORMATTERS)[CellRenderersWithoutNumber]
  >) => {
    return (
      <Component
        value={convertValueByDisplayType(value, type)}
        toLocalStringOptions={{
          minimumFractionDigits: precision,
          maximumFractionDigits: precision,
          useGrouping: !hide_comma_separator,
        }}
        abbreviate={kilo_formatting}
        {...props}
      />
    );
  };
};

export type StyledBasicCellRendererProps = Omit<
  BasicCellRendererProps,
  'styles'
> & {
  styles?: CSSProperties;
  labelColor?: string;
};

const CellValueLabelWrapper = ({
  children,
  color,
}: React.PropsWithChildren<{ color?: string }>) => {
  return (
    <div
      style={{
        background: color,
      }}
      className="!rounded-lg px-2 pt-0.5"
    >
      {children}
    </div>
  );
};

export const StyledBasicCellRenderer = ({
  styles,
  labelColor,
  children,
  ...params
}: React.PropsWithChildren<StyledBasicCellRendererProps> &
  ICellRendererParams) => {
  const Wrapper = labelColor ? CellValueLabelWrapper : React.Fragment;
  const wrapperProps = labelColor ? { color: labelColor } : {};
  return (
    <BasicCellRenderer
      styles={{
        wrapper: styles,
      }}
      {...params}
    >
      <Wrapper {...wrapperProps}>
        {children ?? params.valueFormatted ?? params.value}
      </Wrapper>
    </BasicCellRenderer>
  );
};

export const shouldShowFallback = (value: number | string | null) =>
  value == null || (typeof value === 'number' ? value === 0 : value === '');

export const createAgGridTableFormattedColDef = (
  displayOptions: ValueDisplayOptions,
): FormattedColDef => {
  const { type } = displayOptions;

  const Component = getFormatterByDisplayOptions(displayOptions);

  return {
    type,
    cellRenderer: ({
      styles,
      ...cellRendererParams
    }: ICellRendererParams &
      StyledBasicCellRendererProps & {
        formatterParams: UnknownRecord;
      }) => {
      const inheritedFormatterParams = {
        fallbackValue: DEFAULT_NUMBER_FALLBACK,
        styles: {
          allParts: {
            color: 'inherit',
          },
        },
      };

      const finalFormatterParams = merge(
        inheritedFormatterParams,
        cellRendererParams.formatterParams,
      );

      return (
        <StyledBasicCellRenderer styles={styles} {...cellRendererParams}>
          {shouldShowFallback(cellRendererParams.value) ? (
            inheritedFormatterParams.fallbackValue
          ) : (
            <Component
              value={cellRendererParams.value}
              {...finalFormatterParams}
            />
          )}
        </StyledBasicCellRenderer>
      );
    },
    displayOptions,
  };
};
