import {
  AssetsReportBuilderReportDto,
  AssetsReportBuilderReportWidgetDto,
  PutApiCoreAssetsByAssetIdReportBuilderReportsAndIdApiArg,
} from '@/entities/report/reportBuilder/api/coreAssetsReportBuilderReportsGeneratedApi';
import {
  EAGLE_EYE_REPORT_BUILDER_WIDGET_TYPES_LIST,
  REPORT_BUILDER_WIDGET_TYPES_LIST,
} from '@/entities/report/reportBuilder/model';
import { mapListToIds } from '@/shared/lib/listHelpers';
import { isEqual, pick, sortBy } from 'lodash-es';

export const filterKnownWidgetTypes = (
  widget: AssetsReportBuilderReportWidgetDto,
): boolean =>
  REPORT_BUILDER_WIDGET_TYPES_LIST.concat(
    EAGLE_EYE_REPORT_BUILDER_WIDGET_TYPES_LIST,
  ).includes(widget.widgetType);

type PayloadBody =
  PutApiCoreAssetsByAssetIdReportBuilderReportsAndIdApiArg['body'];

export const isReportPayloadBodyAndReportDataEqual = (
  report: AssetsReportBuilderReportDto,
  payloadBody: PayloadBody,
) => {
  const isFormDateAndDataDateEqual = isEqual(report.date, payloadBody.date);
  const isFormDateFormatEqual = isEqual(
    report.dateFormat,
    payloadBody.date_format,
  );
  const isFormLEsAndDataLEsEqual = isEqual(
    sortBy(mapListToIds(report.legalEntities ?? [])),
    sortBy(payloadBody.legal_entity_ids ?? []),
  );
  const isFormAndDataEqual =
    isFormDateAndDataDateEqual &&
    isEqual(pick(report, ['name', 'subtitle']), {
      name: payloadBody.name,
      subtitle: payloadBody.subtitle,
    }) &&
    isFormDateFormatEqual &&
    isFormLEsAndDataLEsEqual;
  return {
    isFormLEsAndDataLEsEqual,
    isFormDateAndDataDateEqual,
    isFormAndDataEqual,
    isFormDateFormatEqual,
  };
};

export const filterRedundantFieldsFromPayloadBody = ({
  isFormDateAndDataDateEqual,
  payloadBody,
}: {
  payloadBody: PayloadBody;
  isFormDateAndDataDateEqual: boolean;
}): PayloadBody => ({
  date: !isFormDateAndDataDateEqual ? payloadBody.date : undefined,
  legal_entity_ids: payloadBody.legal_entity_ids,
  name: payloadBody.name,
  subtitle: payloadBody.subtitle,
  date_format: payloadBody.date_format,
});
