import { ComponentProps, ReactNode } from 'react';
import { cn } from '@/shared/lib/css/cn';
import { IconButton } from '@/stories/IconButton/IconButton';
import { Icon } from '@/stories/Icon/Icon';
import { FormItemHintTooltip } from '@/stories/FormItemHintTooltip/FormItemHintTooltip';
import { useHovered } from '@/shared/lib/hooks/useHovered';
import styles from '@/stories/OptionalFormSection/optionalFormSection.module.scss';

interface Props
  extends Pick<ComponentProps<typeof FormItemHintTooltip>, 'hint'> {
  content?: ReactNode | ReactNode[];
  expanded?: boolean;
  expandedHandle?: (status: boolean) => void;
  title?: string;
  className?: string;
}

export const OptionalFormSection = ({
  className,
  expanded,
  expandedHandle,
  title,
  hint,
  content,
  ...props
}: Props) => {
  const { hovered, onMouseLeave, onMouseOver } = useHovered(false);
  const hintTooltipTemplate = hint && (
    <FormItemHintTooltip
      hint={hint}
      hovered={hovered}
      classes={{ spanContainer: 'ml-xs' }}
    />
  );
  return (
    <div
      className={`${className || ''}`}
      onMouseLeave={onMouseLeave}
      onMouseOver={onMouseOver}
      {...props}
    >
      {!expanded && (
        <button
          type="button"
          className={cn(styles.expandBtn)}
          onClick={() => expandedHandle(true)}
        >
          <div className="flex items-center">
            {title}
            {hint && (
              <FormItemHintTooltip
                hint={hint}
                hovered={hovered}
                classes={{ spanContainer: 'ml-xs' }}
              />
            )}
          </div>
          <Icon className="ml-auto" iconName="addSmall" />
        </button>
      )}
      {expanded && (
        <div className={cn('rounded-[1rem] bg-light', styles.expandedArea)}>
          <div
            className={cn(
              'flex items-center justify-between gap-s',
              styles.expandedAreaHeader,
            )}
          >
            <div className="flex items-center">
              <h5 className="title dark-60 header6-regular">{title}</h5>
              {hintTooltipTemplate}
            </div>
            <IconButton
              className="flex-[0_0_24px] bg-white"
              iconName="closeSmall"
              onClick={() => expandedHandle(false)}
            />
          </div>
          {content && <div>{content}</div>}
        </div>
      )}
    </div>
  );
};

export default OptionalFormSection;
