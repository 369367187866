import React, { ComponentProps } from 'react';
import ReactDatePicker from 'react-datepicker';
import { CssVar } from '@/shared/config/cssVar';
import { INVESTMENT_CLASSES } from 'bundles/REturn/components/Ownership/modals/createOwnerModal/CreateOwnerModal';
import { Input } from 'stories/FormControls/Inputs/Input/Input';

export const STATIC_DATE_PICKER_PROPS = {
  portalId: 'datepicker-portal',
  showYearDropdown: true,
  placeholderText: 'Select Date',
  customInput: <Input leftIcon="calendar" size="m" />,
} as const satisfies Partial<ComponentProps<typeof ReactDatePicker>>;

export const STATIC_QUERATER_DATE_PICKER_PROPS = {
  portalId: 'quarterpicker-portal',
  showYearDropdown: true,
  placeholderText: 'Select period',
  showQuarterYearPicker: true,
  dateFormat: 'yyyy, QQQ',
  customInput: <Input leftIcon="calendar" rightIcon="arrowBottom" size="m" />,
} as const satisfies Partial<ComponentProps<typeof ReactDatePicker>>;

export const CAPITAL_INVESTMENT_CLASS_COLOR_MAP = {
  a: CssVar.yellow,
  b: CssVar.neutral500,
} as const satisfies Record<(typeof INVESTMENT_CLASSES)[number], CssVar>;
