import React, { FC } from 'react';
import { IconButton } from 'stories/IconButton/IconButton';

interface IPayAppDocumentActions {
  onDownloadItem: () => void;
  onViewItem: () => void;
}

export const PayAppDocumentActions: FC<IPayAppDocumentActions> = ({
  onDownloadItem,
  onViewItem,
}) => {
  const buttons = [
    {
      icon: 'eye',
      handleFunc: onViewItem,
    },
    {
      icon: 'download',
      handleFunc: onDownloadItem,
    },
  ];
  return (
    <div className="flex items-center">
      {buttons.map((btn, idx) => (
        <IconButton
          iconName={btn.icon}
          className="ml-s"
          key={`btn${idx}`}
          onClick={btn.handleFunc}
        />
      ))}
    </div>
  );
};
