import useElementSize, {
  ElementSizeContext,
} from '@/shared/lib/hooks/useElementSize';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import pluralize from 'pluralize';
import { PropsWithChildren, useRef } from 'react';

interface Props extends PropsWithChildren {
  totalCountLabel: string;
  itemsLen: number;
  hideTotalInfo?: boolean;
}

export function SelectedItemsWrapper({
  hideTotalInfo,
  itemsLen,
  totalCountLabel,
  children,
}: Props) {
  const listRef = useRef<HTMLDivElement>(null);
  const size = useElementSize(listRef);

  return (
    <div className="flex max-h-[368px] flex-col gap-2">
      {!hideTotalInfo && (
        <p className="label-regular to-light-60">
          {itemsLen} {pluralize(totalCountLabel, itemsLen)}
        </p>
      )}
      <OverlayScrollbarsComponent className="shadow-z-000">
        <div ref={listRef} className="flex flex-col gap border bg-light-10">
          <ElementSizeContext.Provider value={size}>
            {children}
          </ElementSizeContext.Provider>
        </div>
      </OverlayScrollbarsComponent>
    </div>
  );
}
