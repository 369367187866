import * as React from 'react';
import { IFolder, ISharedDocument } from 'types/SharedDocument';
import FileExtensionIcon from '@/bundles/Shared/components/fileExtensionIcon/FileExtensionIcon';
import FileInfo from '@/bundles/Assets/components/SharedFiles/FileInfo';
import DocumentLink from '@/bundles/Assets/components/SharedFiles/Table/fieldRenderers/DocumentLink';
import DocumentType from '@/bundles/Assets/components/SharedFiles/Table/fieldRenderers/DocumentType';
import UploadDate from '@/bundles/Assets/components/SharedFiles/Table/fieldRenderers/UploadDate';
import DownloadButton from '@/bundles/Assets/components/SharedFiles/Table/buttons/DownloadButton';
import ExtendedDetailsPopover from '@/bundles/Assets/components/SharedFiles/Table/formatters/extendedDetails/ExtendedDetailsPopover';
import DocumentableAndFolder from '@/bundles/Assets/components/SharedFiles/Table/fieldRenderers/DocumentableAndFolder';

export interface Actions {
  handleFolderClick: (folder: IFolder) => void;
}

interface Props {
  row: ISharedDocument;
  actions: Actions;
}

export const FileLink = ({ row }: { row: ISharedDocument }) => (
  <div>
    <DocumentLink row={row}>{row.title}</DocumentLink>
  </div>
);

function ExtendedDetails({ row, actions }: Props) {
  return (
    <div className="flex items-center justify-between">
      <div className="flex flex-col items-start gap-2 lg:flex-row">
        <FileExtensionIcon className="bg-light-10" extension={row.extension} />
        <div>
          <FileLink row={row} />
          <span className="hidden lg:block">
            <FileInfo
              size={row.size}
              extension={row.extension.toLocaleUpperCase()}
            />
          </span>
        </div>
        <div className="flex flex-col gap-4 lg:hidden">
          <div className="flex flex-col gap-2">
            <DocumentType row={row} mobile />
            <UploadDate row={row} mobile />
          </div>
          <DocumentableAndFolder row={row} />
          <DownloadButton row={row} />
        </div>
      </div>
      <ExtendedDetailsPopover row={row} actions={actions} />
    </div>
  );
}

export default ExtendedDetails;
