import React, { IntrinsicElements } from 'react';
import { cn } from '@/shared/lib/css/cn';
import '@/stories/Textarea/textarea.css';

interface Props
  extends React.DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > {
  status?: 'success' | 'error';
}

export const Textarea = React.forwardRef<IntrinsicElements.textarea, Props>(
  ({ status, className, ...props }: Props, ref) => (
    <textarea
      ref={ref}
      className={cn(
        'sre-textarea',
        status && `sre-textarea_${status}`,
        className,
      )}
      {...props}
    />
  ),
);

export default Textarea;
