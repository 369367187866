import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { IFilterParams, ISetFilterParams } from 'ag-grid-community';
import { ListOption } from 'stories/Checkbox/CheckList';
import { IFilterReactComp } from 'ag-grid-react';
import SplittedCheckList from 'stories/Checkbox/SplittedCheckList';

export const SetFilterComponent = forwardRef<
  unknown,
  IFilterParams & ISetFilterParams
>((props, ref) => {
  const [selectedOptions, setSelectedOptions] = useState<ListOption[]>([]);

  // expose AG Grid Filter Lifecycle callbacks
  useImperativeHandle<unknown, IFilterReactComp>(ref, () => {
    return {
      doesFilterPass() {
        return true;
      },

      isFilterActive() {
        return !selectedOptions.length;
      },

      getModel() {
        if (selectedOptions.length === 0) {
          return null;
        }
        return selectedOptions.map((option) => option.value);
      },

      setModel(model) {
        setSelectedOptions(model ?? []);
      },
    };
  });

  if (props.values?.length === 0) {
    return (
      <div className="h-4 w-full">
        <p className="light-60 label-regular">No options</p>
      </div>
    );
  }

  return (
    <SplittedCheckList
      items={(props.values as string[]).map((value) => ({
        value,
        label: props.valueFormatter ? props.valueFormatter({ value }) : value,
      }))}
      value={selectedOptions}
      onChange={(newItems) => {
        setSelectedOptions(newItems);
        props.filterChangedCallback();
      }}
    />
  );
});
