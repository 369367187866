import NoDataOverlay from '@/bundles/Shared/components/NoDataOverlay';
import {
  PageParamsProvider,
  PageParamsSearch,
} from '@/bundles/Shared/components/pageParams';
import TablePagination from '@/bundles/Shared/components/Table/pagination/TablePagination';
import Table from '@/bundles/Shared/components/Table/Table';
import { IColumn } from '@/bundles/Shared/components/Table/types';
import {
  ReportThreshold,
  ReportThresholdLegalEntityOverride,
} from '@/entities/report/threshold/model';
import {
  useDeleteLegalEntityOverrideForThreshold,
  useLegalEntityOverridesForThreshold,
  useLegalEntitiesForThreshold,
} from '@/features/report/threshold/legalEntityOverride/api';
import { AddOrUpdateLegalEntityOverrideForThresholdModal } from '@/features/report/threshold/legalEntityOverride/ui/AddLegalEntityOverrideForThresholdModal';
import { getFormatterByDisplayOptions } from '@/shared/lib/formatting/table';
import { useModal } from '@/shared/lib/hooks/useModal';
import { GrowDiv } from '@/shared/ui/GrowDiv';
import { Button } from '@/stories/Button/Button';
import { IconButton } from '@/stories/IconButton/IconButton';
import { nameOf } from '@/types/utils';
import { useMemo, useState } from 'react';

const AddThresholdButton = ({
  threshold,
  className,
}: {
  threshold: ReportThreshold;
} & PropsWithClassName) => {
  const { openModal } = useModal();
  return (
    <Button
      className={className}
      variant="primary"
      size="s"
      onClick={() => {
        openModal(AddOrUpdateLegalEntityOverrideForThresholdModal, {
          threshold,
        });
      }}
    >
      Add Threshold
    </Button>
  );
};
export const ReportThresholdLegalEntityOverridesTable = ({
  threshold,
}: {
  threshold: ReportThreshold;
}) => {
  const { openModal } = useModal();
  const [pageParams, setPageParams] = useState<{
    query: string;
  }>({
    query: '',
  });
  const { data: legalEntities, isLoading: isLoadingLegalEntities } =
    useLegalEntitiesForThreshold();
  const { legalEntityOverrides, isLoading: isLoadingLegalEntityOverrides } =
    useLegalEntityOverridesForThreshold(threshold);
  const { legalEntityOverrides: filteredLegalEntityOverrides } =
    useLegalEntityOverridesForThreshold(threshold, {
      search: pageParams.query,
    });
  const [deleteLegalEntityOverride] =
    useDeleteLegalEntityOverrideForThreshold(threshold);

  const columns = useMemo<IColumn<ReportThresholdLegalEntityOverride>[]>(
    () => [
      {
        dataField: nameOf<ReportThresholdLegalEntityOverride>('legalEntityId'),
        text: 'Legal Entity',
        formatter: ({ row }) => {
          const legalEntity = legalEntities?.items.find(
            (le) => le.id === row.legalEntityId,
          );
          return legalEntity?.name;
        },
      },
      {
        dataField: nameOf<ReportThresholdLegalEntityOverride>('value'),
        text: threshold.title,
        formatter: ({ row }) => {
          const Formatter = getFormatterByDisplayOptions({
            type: threshold.valueType,
            precision: 0,
          });
          return <Formatter value={row.value} />;
        },
      },
      {
        dataField: 'actions',
        classes: 'w-[80px]',
        formatter: ({ row }) => {
          return (
            <div className="flex gap-2">
              <IconButton
                iconName="edit"
                onClick={() => {
                  openModal(AddOrUpdateLegalEntityOverrideForThresholdModal, {
                    threshold,
                    legalEntityOverride: row,
                  });
                }}
                variant="secondary"
                size="s"
              />
              <IconButton
                iconName="trash"
                onClick={() => {
                  deleteLegalEntityOverride(row.id);
                }}
                variant="secondary"
                size="s"
              />
            </div>
          );
        },
      },
    ],
    [threshold.valueType, threshold.title, legalEntities],
  );

  if (legalEntityOverrides.length === 0 && !isLoadingLegalEntityOverrides) {
    return (
      <NoDataOverlay
        title="To get started, add a Threshold"
        subTitle="This threshold will be used by default for all Legal Entities. You can override it for specific Legal Entities later"
        customDocumentsComponent={
          <AddThresholdButton className="mt-2" threshold={threshold} />
        }
      />
    );
  }

  return (
    <PageParamsProvider pageParams={pageParams} setPageParams={setPageParams}>
      <div className="flex justify-between gap-2">
        <TablePagination
          currentPage={1}
          totalSize={legalEntityOverrides.length}
          sizePerPage={10}
        />

        <GrowDiv />
        <PageParamsSearch placeholder="Search by Legal Entity" />
        <AddThresholdButton threshold={threshold} />
      </div>
      <Table
        loading={isLoadingLegalEntities || isLoadingLegalEntityOverrides}
        columns={columns}
        items={filteredLegalEntityOverrides}
      />
    </PageParamsProvider>
  );
};
