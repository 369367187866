import { useLocalStorageValue } from '@/shared/lib/hooks/useLocalStorageValue';
import { Link } from '@reach/router';
import { login } from 'lib/currentUser';
import { apiClient } from 'lib/http';
import { useState } from 'react';
import { Button } from 'stories/Button/Button';
import { Checkbox } from 'stories/Checkbox/Checkbox';

interface Props {
  user?: {
    email: string;
  };
}

const Login = ({ user }: Props) => {
  const [submitting, setSubmitting] = useState(false);
  const [otpRequired, setOtpRequired] = useState(false);
  const [rememberMeDefaultChecked, setRememberMeDefaultChecked] =
    useLocalStorageValue('authLoginFormRememberMeDefaultChecked', false);

  const handleSubmit = (e) => {
    setSubmitting(true);
    e.preventDefault();
    const form = e.target;

    const userData = {
      email: form.email.value,
      password: form.password.value,
      remember_me: form.remember_me.checked,
    };

    if (otpRequired) userData.otp_attempt = form.otp_attempt.value;

    const redirectTo =
      window.history.state?.from &&
      window.history.state.from !== '/users/sign_in'
        ? window.history.state.from
        : '/';

    apiClient
      .post('/users/sign_in', { user: userData })
      .then(({ data }) => {
        if (data.otp_required) {
          setOtpRequired(true);
          window.toastr.success('One Time Password sent to your email');
        } else {
          login(data.token);
          window.location.href = redirectTo;
        }
      })
      .catch((error) => {
        window.toastr.error(`${error.response.data.errors} \n`);
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <div>
      <h1 className="auth__header mb-4">Account Login</h1>
      <p className="light-60 text-[1.5rem]-125rem text-center">
        Sign in to your account
      </p>
      <form onSubmit={handleSubmit} className="flex flex-col gap-6">
        <label className="auth__label">
          Email
          <input
            name="email"
            className="form-control auth__input"
            type="text"
            placeholder="Enter email"
            defaultValue={user?.email}
          />
        </label>

        <label className="auth__label">
          Password
          <input
            name="password"
            className="form-control auth__input"
            type="password"
            placeholder="Enter password"
          />
        </label>

        {otpRequired && (
          <label className="auth__label mb-1">
            One Time Password
            <input
              name="otp_attempt"
              className="form-control auth__input"
              type="otp_attempt"
              placeholder="Enter OTP"
            />
          </label>
        )}

        <Checkbox
          name="remember_me"
          defaultChecked={rememberMeDefaultChecked}
          onChange={(e) => {
            setRememberMeDefaultChecked(e.target.checked);
          }}
        >
          Remember me
        </Checkbox>

        <Button
          variant="success"
          type="submit"
          size="l"
          className="mt-4 w-full"
          disabled={submitting}
        >
          {submitting ? 'Logging in...' : 'Log In'}
        </Button>

        <Link to="/users/password/new" className="sre-link block text-center">
          Forgot your password?
        </Link>
      </form>
    </div>
  );
};

export default Login;
