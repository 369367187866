import { DashboardSelectionItem } from '@/bundles/Shared/features/dashboard/create/ui/DashboardSelectionItem';
import type { ReportBuilderTemplatePreviewDto } from '@/entities/report/reportBuilder/api/settingsReportBuilderTemplatesGeneratedApi';
import type { DialogProps } from '@/shared/lib/hooks/useModal';
import { Button } from '@/stories/Button/Button';
import { Input } from '@/stories/FormControls/Inputs/Input/Input';
import { Modal } from '@/stories/Modals/Modal/Modal';
import Field, { FieldsContainer } from '@/stories/Field/Field';
import { useForm } from 'react-hook-form';

type Form = Pick<ReportBuilderTemplatePreviewDto, 'name' | 'kind'>;

export function CreateReportBuilderTemplateModal({
  onClose,
  onSubmit,
  template,
  isDuplicating,
}: DialogProps<Form> & {
  template?: Partial<Form>;
  isDuplicating?: boolean;
}) {
  const {
    register,
    formState: { isValid },
    handleSubmit,
    setValue,
    watch,
  } = useForm<Form>({
    mode: 'all',
    defaultValues: {
      name: template?.name ?? '',
      kind: template?.kind ?? 'object_level',
    },
  });

  const reportTemplateKind = watch('kind');

  const onSubmitHandler = handleSubmit((data) => {
    onSubmit?.(data);
  });

  return (
    <Modal
      toggle={onClose}
      header={isDuplicating ? 'Duplicate template' : 'Create template'}
      classes={{
        body: 'bg-white',
      }}
      actions={
        <>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="success"
            disabled={!isValid}
            onClick={onSubmitHandler}
          >
            Create
          </Button>
        </>
      }
    >
      <FieldsContainer>
        <Field labelText="Name" required>
          <Input
            {...register('name', {
              required: true,
            })}
          />
        </Field>
        <Field labelText="Report Template Type" required>
          <div className="grid grid-cols-[1fr,1fr] items-center gap-4">
            <DashboardSelectionItem
              disabled={isDuplicating}
              label="Object Level"
              selected={reportTemplateKind === 'object_level'}
              iconName="objectLevelDashboardGreyscale"
              onClick={() => {
                setValue('kind', 'object_level', {
                  shouldDirty: true,
                  shouldTouch: true,
                });
              }}
            />
            <DashboardSelectionItem
              disabled={isDuplicating}
              label="Eagle Eye"
              selected={reportTemplateKind === 'eagle_eye'}
              iconName="eagleEyeDashboardGreyscale"
              onClick={() => {
                setValue('kind', 'eagle_eye', {
                  shouldDirty: true,
                  shouldTouch: true,
                });
              }}
            />
          </div>
        </Field>
      </FieldsContainer>
    </Modal>
  );
}
