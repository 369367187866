import ReportSettingsScreenLayout from '@/bundles/Settings/shared/ReportSettingsScreenLayout';
import Table from '@/bundles/Shared/components/Table/Table';
import {
  PageParamsPagination,
  PageParamsProvider,
  PageParamsSearch,
} from '@/bundles/Shared/components/pageParams';
import {
  ReportManualVariable,
  ReportManualVariableLegalEntity,
  useGetApiReportManualVariablesByManualVariableIdLegalEntitiesQuery,
} from '@/entities/report/manualVariable';
import {
  DeleteManualVariable,
  EditManualVariable,
  ExportMVLegalEntitiesButton,
  ImportMVLegalEntitiesButton,
} from '@/features/report/manualVariable';
import { currentUserIsSreAdmin } from '@/lib/permissions';
import { GeneralSettingsNavigationSection } from '@/stories/GeneralSettingsNavigationSection/GeneralSettingsNavigationSection';
import { IPaginationParams, IQueryParams } from '@/types/Pagination';
import {
  ManageManualVariableLegalEntityValues,
  useManualVariableColumnDefs,
} from '@/widgets/report/manualVariable/settings';
import { RouteComponentProps, useLocation } from '@reach/router';
import { startCase } from 'lodash-es';
import pluralize from 'pluralize';
import { useState } from 'react';

type LegalEntitKind = 'configured' | 'not_configured';

type LegalEntityValuesModalState = {
  modalOpened: boolean;
  legalEntity: ReportManualVariableLegalEntity | null;
};

export const SettingsReportManualVariables = ({
  manualVariable,
}: {
  manualVariable: ReportManualVariable;
} & RouteComponentProps) => {
  const [
    { legalEntity: currentLegalEntity, modalOpened },
    setLegalEntityValuesModal,
  ] = useState<LegalEntityValuesModalState>({
    legalEntity: null,
    modalOpened: false,
  });

  const openModal = ({
    legalEntity,
  }: Pick<LegalEntityValuesModalState, 'legalEntity'>) => {
    setLegalEntityValuesModal({
      modalOpened: true,
      legalEntity,
    });
  };

  const closeModal = () => {
    setLegalEntityValuesModal({
      modalOpened: false,
      legalEntity: null,
    });
  };

  const { pathname } = useLocation();
  const manualVariableId = pathname.slice(
    pathname.lastIndexOf('/') + 1,
    pathname.length,
  );

  const [pageParams, setPageParams] = useState<
    IQueryParams &
      Required<IPaginationParams> & {
        legalEntitiesKind: LegalEntitKind;
      }
  >({
    query: '',
    legalEntitiesKind: 'configured',
    page: 1,
    perPage: 50,
  });
  const { perPage, ...sameParams } = pageParams;
  const { data, isFetching } =
    useGetApiReportManualVariablesByManualVariableIdLegalEntitiesQuery({
      manualVariableId,
      sizePerPage: perPage,
      ...sameParams,
    });

  const handleLEKind = (legalEntitiesKind: LegalEntitKind) => {
    setPageParams((prev) => ({
      ...prev,
      page: 1,
      legalEntitiesKind,
    }));
  };

  const LEGAL_ENTITY_KIND: { kind: LegalEntitKind; count: number }[] = [
    {
      kind: 'configured',
      count: data?.meta.configuredLegalEntitiesCount ?? 0,
    },
    {
      kind: 'not_configured',
      count: data?.meta.notConfiguredLegalEntitiesCount ?? 0,
    },
  ];

  const columns = useManualVariableColumnDefs({
    onAddClick: openModal,
  });

  const items = data?.items ?? [];

  return (
    <PageParamsProvider pageParams={pageParams} setPageParams={setPageParams}>
      <ReportSettingsScreenLayout
        title={manualVariable.label}
        subTitle="Manual Variable"
        className="grow"
        actions={
          currentUserIsSreAdmin() && (
            <div className="flex items-center gap-2">
              <EditManualVariable manualVariable={manualVariable} />
              {manualVariable.canDestroy && (
                <DeleteManualVariable manualVariableId={manualVariable.id} />
              )}
            </div>
          )
        }
      >
        <div className="flex flex-col gap-4 p-6">
          <div className="flex items-center gap-4 pb-2">
            {LEGAL_ENTITY_KIND.map(({ kind, count }) => (
              <GeneralSettingsNavigationSection
                key={kind}
                active={pageParams.legalEntitiesKind === kind}
                title={`${count} ${startCase(kind)} ${pluralize(
                  'Legal Entity',
                  count,
                )}`}
                onClick={() => {
                  handleLEKind(kind);
                }}
              />
            ))}
          </div>
          <div className="flex items-center justify-between gap-4">
            <PageParamsPagination
              loading={isFetching}
              totalSize={data?.meta.totalSize ?? 0}
              sizePerPage={perPage}
            />
            <PageParamsSearch className="ml-auto" />
            <ImportMVLegalEntitiesButton manualVariableId={manualVariableId} />
            <ExportMVLegalEntitiesButton manualVariableId={manualVariableId} />
          </div>
          <Table loading={isFetching} columns={columns} items={items} />
          {modalOpened && (
            <ManageManualVariableLegalEntityValues
              onClose={closeModal}
              legalEntity={currentLegalEntity!}
              manualVariable={manualVariable}
            />
          )}
        </div>
      </ReportSettingsScreenLayout>
    </PageParamsProvider>
  );
};
