import {
  CREATE_CHANGE_ORDER_CONFIG,
  FETCH_CHANGE_ORDER_CONFIGS,
  REMOVE_CHANGE_ORDER_CONFIG,
  UPDATE_CHANGE_ORDER_CONFIG,
} from '@/bundles/Assets/actions';

const initialState = null;

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case FETCH_CHANGE_ORDER_CONFIGS:
      return payload;
    case CREATE_CHANGE_ORDER_CONFIG:
      return [...state, payload];
    case UPDATE_CHANGE_ORDER_CONFIG:
      return state.map((c) => (c.id === payload.id ? payload : c));
    case REMOVE_CHANGE_ORDER_CONFIG:
      return state.filter((c) => c.id !== payload);
    default:
      return state;
  }
}
