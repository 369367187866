import React, { useEffect, useState } from 'react';
import { AnimationLoader } from 'stories/AnimationLoader/AnimationLoader';
import { Modal } from 'stories/Modals/Modal/Modal';
import http from 'lib/http';
import { formatUnixDate } from '@/shared/lib/formatting/dates';
import EmailMessageTimelineActivity from '@/bundles/UserManagement/components/EmailMessageTimelineActivity';
import UserInviteStatusDot from '@/bundles/UserManagement/components/inviteStatusDot/UserInviteStatusDot';

const UserActivityLogModal = ({
  userId,
  userName,
  inviteStatus,
  lastSeenAt,
  lastSignInAt,
  onClose,
}) => {
  const [emailMessages, setEmailMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchEmailMessages = () =>
    http
      .get(`/user_email_messages?user_id=${userId}`)
      .then((res) => res.json());

  useEffect(() => {
    setIsLoading(true);
    fetchEmailMessages()
      .then((emailMessagesJson) => {
        setEmailMessages(emailMessagesJson);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const Header = ({ name, inviteStatus, lastSeenAt, lastSignInAt }) => (
    <div className="flex items-center">
      <UserInviteStatusDot inviteStatus={inviteStatus} />
      <div className="flex flex-col ml-4">
        <h6 className="header6-bold">{name}</h6>
        <div className="light-60 label-regular">
          {formatUnixDate(lastSeenAt || lastSignInAt, 'LL')}
        </div>
      </div>
    </div>
  );

  return (
    <Modal
      toggle={onClose}
      size="md"
      header={
        <Header
          name={userName}
          lastSignInAt={lastSignInAt}
          lastSeenAt={lastSeenAt}
          inviteStatus={inviteStatus}
        />
      }
      classes={{
        header: 'bg-light',
        body: 'bg-light min-h-[500px]',
        footer: 'bg-light',
      }}
    >
      {isLoading && <AnimationLoader />}
      {!isLoading && (
        <div className="activity-log-timeline__container h-full px-s position-relative">
          {emailMessages.map((emailMessage, i) => (
            <EmailMessageTimelineActivity
              key={emailMessage.id}
              isFirst={i === 0}
              isLast={i === emailMessages.length - 1}
              senderName={emailMessage.initiatedBy?.fullName}
              sentAt={emailMessage.sentAt}
              subject={emailMessage.subject}
              status={emailMessage.status}
              snapshotId={emailMessage.snapshotId}
              emailMessageId={emailMessage.id}
              recipientName={userName}
            />
          ))}
        </div>
      )}
    </Modal>
  );
};

export default UserActivityLogModal;
