import React, { useCallback } from 'react';
import { Button } from 'stories/Button/Button';
import { CurrencyFormatter } from 'stories/ValueFormatters/CurrencyFormatter';
import { Field } from 'stories/Field/Field';
import { Icon } from 'stories/Icon/Icon';
import { Modal } from 'stories/Modals/Modal/Modal';
import { Popover } from 'stories/Popover/Popover';
import { Textarea } from 'stories/Textarea/Textarea';
import { DialogProps, useModal } from '@/shared/lib/hooks/useModal';
import { FieldsContainer } from 'stories/Field/Field';
import CurrencyInputNumber from 'bundles/Shared/components/GroupForm/FormItems/CurrencyInputNumber';
import { Controller, useForm } from 'react-hook-form';
import { ILineItem } from 'bundles/REconcile/types/ILineItem';
import JccTreeSelect from 'bundles/Construction/components/Reallocation/Modals/createManualReallocationModal/JccTreeSelect';
import { LegalEntity } from '@/entities/core/legalEntity';
import DateSelect from 'bundles/Shared/components/GroupForm/FormItems/DateSelect';
import VendorSelect from 'bundles/REconcile/components/VendorSelect';
import { TDevelopmentCategorizableType } from 'bundles/REconcile/actions/developmentBudgetVariance';
import {
  getJccTypeFromKey,
  transformCategorizedIdToKey,
  transformJCCKeyToId,
} from 'bundles/Construction/components/Reallocation/Modals/components/utils';

type EditableLineItem = {
  amount: number;
  date: Date;
  category_id: number;
  vendor: string;
  // todo move to separate type
  category_type?: 'jcc' | 'sovc';
  remark?: string;
};

interface Props
  extends Omit<
      React.ComponentProps<typeof Modal>,
      'toggle' | 'actions' | 'children'
    >,
    DialogProps<EditableLineItem> {
  lineItem: Partial<EditableLineItem>;
  legalEntityCode: LegalEntity['code'];
  categoryType?: TDevelopmentCategorizableType;
  fieldSettings?: Partial<{
    vendor: {
      disabled?: boolean;
    };
    amount: {
      max?: number;
      suggestedValue?: number;
      allowNegative?: boolean;
      disallowPositive?: boolean;
      disabled?: boolean;
    };
    date: {
      disabled?: boolean;
    };
    category_id: {
      disabled?: boolean;
    };
    remark: {
      disabled?: boolean;
    };
  }>;
  submitText?: string;
}

function LineItemModal({
  lineItem,
  legalEntityCode,
  categoryType,
  fieldSettings = {},
  onClose,
  onSubmit,
  submitText,
  ...props
}: Props) {
  const { confirm } = useModal();
  const { amount, date, remark, category_id, vendor } = fieldSettings;
  const resolver = useCallback((values: EditableLineItem) => {
    const errors: Partial<ILineItem> = {};
    // todo move messages to default utils
    if (!values.amount) {
      errors.amount = 'Amount is required';
    }

    if (amount) {
      const { max, disallowPositive } = amount;
      if (disallowPositive && values.amount > 0) {
        errors.amount = 'Amount must be negative';
      }

      if (max && values.amount > max) {
        errors.amount = `Amount must be less than ${max}`;
      }
    }

    if (!values.date) {
      errors.date = 'Date is required';
    }

    if (!values.category_id) {
      errors.category_id = 'Category is required';
    }

    return {
      values,
      errors,
    };
  }, []);

  const {
    control,
    register,
    getValues,
    setValue,
    formState: { isValid },
    handleSubmit,
  } = useForm<Partial<EditableLineItem>>({
    mode: 'onChange',
    values: {
      ...lineItem,
      amount: lineItem.amount ?? 0,
      category_type:
        categoryType && categoryType === 'JobCostCode' ? 'jcc' : 'sovc',
    },
    defaultValues: {
      amount: 0,
    },
    resolver,
  });

  const handleClose = async () => {
    const confirmed = await confirm({
      title: 'Are you sure you want to quit?',
      subtitle: 'This will lead to the loss of all progress',
      actions: {
        primaryButton: {
          text: 'Confirm',
          variant: 'danger',
        },
        secondaryButton: {
          text: 'Cancel',
          variant: 'secondary',
        },
      },
    });
    if (confirmed) onClose();
  };

  return (
    <Modal
      actions={
        <>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            data-testid="crud-submit"
            variant="success"
            onClick={handleSubmit((values) =>
              onSubmit?.(values as EditableLineItem),
            )}
            disabled={!isValid}
          >
            {submitText ?? 'Save'}
          </Button>
        </>
      }
      toggle={() => onClose()}
      header={
        <span className="header6-bold text-dark-60">Development Line Item</span>
      }
      {...props}
    >
      <FieldsContainer className="grid grid-cols-[auto,140px]">
        <Field labelText="Vendor">
          <Controller
            control={control}
            name="vendor"
            render={({ field }) => (
              <VendorSelect
                value={field.value}
                onChange={(o) => field.onChange(o?.label)}
                isDisabled={vendor?.disabled}
              />
            )}
          />
        </Field>
        <Field required labelText="Transaction Date">
          <Controller
            control={control}
            name="date"
            render={({ field }) => (
              <DateSelect
                leftIcon
                dateFormat="MM-dd-yy"
                selected={field.value}
                onChange={field.onChange}
                {...date}
              />
            )}
          />
        </Field>

        <Field required labelText="Development Category (JCC/SoVC)">
          <Controller
            control={control}
            name="category_id"
            render={({ field }) => (
              <JccTreeSelect
                queryParams={{
                  legalEntityCode,
                  without_sovcs: 'false',
                }}
                value={
                  field.value &&
                  transformCategorizedIdToKey(
                    field.value,
                    getValues('category_type')!,
                  )
                }
                onChange={(key) => {
                  field.onChange(transformJCCKeyToId(key));
                  setValue('category_type', getJccTypeFromKey(key));
                }}
                inputProps={{
                  disabled: category_id?.disabled,
                }}
              />
            )}
          />
        </Field>
        <Controller
          control={control}
          name="amount"
          render={({ field, fieldState }) => (
            <Field error={fieldState.error} required labelText="Amount">
              <Popover
                placement="bottom-start"
                disabled={
                  !amount?.suggestedValue ||
                  !(field.value == null || field.value === 0)
                }
                className="cursor-pointer px-4 py-2 hover:bg-light-20"
                trigger="click"
                hideOnExpandedAreaClick
                template={
                  <div
                    onClick={() => field.onChange(amount?.suggestedValue)}
                    className="flex flex-col"
                  >
                    <div className="flex gap-2">
                      <Icon iconName="magic" />
                      <CurrencyFormatter
                        classes={{
                          value: 'inline-semibold text-dark-60',
                        }}
                        value={amount?.suggestedValue}
                      />
                    </div>
                    <span className="secondary-regular text-light-60">
                      Autofill amount
                    </span>
                  </div>
                }
              >
                <CurrencyInputNumber
                  // todo move to currency input
                  onValueChange={(value) => field.onChange(value ?? null)}
                  value={field.value}
                  error={fieldState.isDirty && fieldState.invalid}
                  {...amount}
                />
              </Popover>
            </Field>
          )}
        />
        <Field className="col-span-2" labelText="Remark">
          <Textarea
            placeholder={'Enter remark'}
            {...register('remark')}
            {...remark}
          />
        </Field>
      </FieldsContainer>
    </Modal>
  );
}

export default LineItemModal;
