import {
  ACTIVITY_LOG_ICONS,
  ACTIVITY_LOG_KIND_TITLES,
  MAX_CHARS_IN_ROW,
} from '@/bundles/UserManagement/components/roles/constants';
import {
  ActivityLogKindTitleType,
  ActivityLogKindType,
  ICreateRoleFormData,
} from '@/bundles/UserManagement/components/roles/types';
import { MODULE_LABELS } from '@/lib/dictionaries';
import { TProductNames } from '@/lib/permissions';
import {
  camelCase,
  mapKeys,
  omit,
  sortBy,
  startCase,
  truncate,
  uniq,
} from 'lodash-es';
import { ISelectOption } from 'stories/FormControls/Select/Select';
import type { IRestrictProduct } from 'types/CustomerConfig';
import { IconsId } from 'types/sre-icons';
import {
  PropertiesCamelToSnakeCase,
  PropertiesSankeToCamelCase,
} from 'types/StringUtilityTypes';
import type { IUser, IUserRole } from 'types/User';

export const optinsFor = (vals: string[]) =>
  vals.map((val) => ({ id: val, label: val }));

export function sortByName<T extends { name: string }>(arr: T[]) {
  return sortBy(arr, ['name']);
}

// TODO remove when BE-8655
const renameReportingModule = (name: string): string => {
  if (name === TProductNames.REPORT_BUILDER) {
    return startCase(MODULE_LABELS.Reporting.toLowerCase());
  }
  return name;
};

export const prepareProductName = (name: TProductNames) => {
  return startCase(renameReportingModule(name));
};

export const camelToSnake = (camel: string) =>
  camel.replace(/[A-Z]/g, (c) => `_${c.toLowerCase()}`);
export const camelKeysToSnake = <T extends Record<string, unknown>>(obj: T) =>
  Object.fromEntries(
    Object.entries(obj).map(([k, v]) => [camelToSnake(k), v]),
  ) as PropertiesCamelToSnakeCase<T>;

export const snakeKeysToCamel = <T extends Record<string, unknown>>(obj: T) =>
  mapKeys(obj, (_, k) => camelCase(k)) as PropertiesSankeToCamelCase<T>;

export const prepareProducts = (restrictProducts: IRestrictProduct[]) => {
  const res = restrictProducts.map((product) => ({
    ...product,
    canConfigure: false,
    canManage: false,
    canView: false,
  }));
  return sortBy(res, ['name']);
};

export const removeNameField = (formData: ICreateRoleFormData) => ({
  ...formData,
  products: formData.products.map((product) => omit(product, ['name'])),
});

export function userNamesResolver(users: Pick<IUser, 'id' | 'fullName'>[]) {
  let hiddenNamesLen = 0;

  if (!users || !users.length) {
    return { userNames: '', hiddenNamesLen };
  }
  let userNamesStr = users.map((r) => r.fullName).join(', ');

  if (userNamesStr.length > MAX_CHARS_IN_ROW) {
    userNamesStr = truncate(userNamesStr, { length: MAX_CHARS_IN_ROW });

    hiddenNamesLen = users.length - (userNamesStr.split(',').length - 1);
  }
  return { userNames: userNamesStr, hiddenNamesLen };
}

export const getGroupOptions = (
  roles: IUserRole[],
  c: IUserRole['category'],
): ISelectOption[] =>
  optinsFor(
    uniq(
      roles
        .filter((r) => r.category === c && Boolean(r.group))
        .map((r) => r.group),
    ),
  );

export const resolveActivityLogIcon = (kind: ActivityLogKindType): IconsId =>
  ACTIVITY_LOG_ICONS[kind];

export const resolveActivityLogKindTitle = (
  kind: ActivityLogKindType,
): ActivityLogKindTitleType => ACTIVITY_LOG_KIND_TITLES[kind];
