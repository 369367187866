import { useGetApiSettingsReportThirdPartyExtractionsConnectionsQuery } from '@/shared/api/settingsReportConnectionsEnhancedApi';
import { GetApiSettingsReportThirdPartyExtractionsConnectionsApiArg } from '@/shared/api/settingsReportConnectionsGeneratedApi';
import { IConnection } from '@/bundles/Shared/entities/connection/model';
import { cn } from '@/shared/lib/css/cn';
import SkeletonBlock from '@/stories/ProjectCard/SkeletonBlock';

import { useConnectionsColumns } from 'bundles/Settings/components/REport/Connections/ui/useConnectionsColumns';
import Table from 'bundles/Shared/components/Table/Table';
import { usePageParamsContext } from 'bundles/Shared/components/pageParams/PageParamsContext';
import pluralize from 'pluralize';
import { Icon } from 'stories/Icon/Icon';
import { Tooltip } from 'stories/Tooltip/Tooltip';

const ConnectionsTable = () => {
  const { pageParams } =
    usePageParamsContext<GetApiSettingsReportThirdPartyExtractionsConnectionsApiArg>();
  const { data: connections, isLoading } =
    useGetApiSettingsReportThirdPartyExtractionsConnectionsQuery({
      ...pageParams,
    });

  const { columns } = useConnectionsColumns();

  return (
    <ConnectionsTable.Wrapper>
      {connections?.companies.map(
        (item) =>
          Boolean(item.connections.length) && (
            <div
              key={item.companyName}
              className="flex flex-col gap-2 rounded-2xl bg-neutral-250 px-1 pb-1 pt-2"
            >
              <div className="flex items-center gap-2 px-3">
                <div className="body-semibold text-neutral-850">
                  {item.companyName}
                </div>
                <Tooltip
                  mainText={`${item.connections.length} ${pluralize(
                    'connector',
                    item.connections.length,
                  )}`}
                >
                  <div className="flex items-center gap-1">
                    <Icon iconName="link" />
                    <div className="inline-semibold text-neutral-500">
                      {item.connections.length}
                    </div>
                  </div>
                </Tooltip>
              </div>
              <Table
                classes={{
                  container: 'table-gen-2',
                  table: 'bg-white rounded-lg connections-table',
                }}
                columns={columns}
                items={(item.connections ?? []) as unknown as IConnection[]}
                loading={isLoading}
              />
            </div>
          ),
      )}
    </ConnectionsTable.Wrapper>
  );
};

ConnectionsTable.Wrapper = (props: LayoutProps) => (
  <div className={cn('flex flex-col gap-2', props.className)}>
    {props.children}
  </div>
);

ConnectionsTable.Fetching = () => (
  <ConnectionsTable.Wrapper>
    {Array.from({ length: 4 }, (_, idx) => (
      <SkeletonBlock key={idx} className="h-[170px] w-full rounded-2xl" />
    ))}
  </ConnectionsTable.Wrapper>
);

ConnectionsTable.Loading = ConnectionsTable.Fetching;

export default ConnectionsTable;
