import { formatUnixDate } from '@/shared/lib/formatting/dates';
import pluralize from 'pluralize';
import { Icon } from 'stories/Icon/Icon';

interface Props {
  viewsCount: number;
  lastViewedAt: UnixTime | null;
}

export function ViewsCountFormatter({ lastViewedAt, viewsCount }: Props) {
  if (viewsCount === 0) {
    return (
      <div className="flex min-h-[24px] items-center gap-2">
        <span className="flex h-2 w-2 rounded-full bg-light-10" />
        <span className="label-regular text-light-60">Not yet viewed</span>
      </div>
    );
  }

  return (
    <div className="flex min-h-[24px] flex-col gap-2">
      <div className="flex items-center gap-1">
        <Icon iconName="check" className="text-green-dark-2" />
        <p className="secondary-regular text-dark-60">
          {viewsCount} {pluralize('view', viewsCount)}
        </p>
      </div>
      {lastViewedAt && (
        <p className="secondary-regular text-light-60">
          Last viewed {formatUnixDate(lastViewedAt, 'MMMM DD, YYYY h:mm A')}
        </p>
      )}
    </div>
  );
}
