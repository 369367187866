import React from 'react';
import PropTypes from 'prop-types';
import { SreAvatar } from 'stories/SreAvatar/SreAvatar';

const FeedMessageAvatar = ({ text }) => (
  <SreAvatar
    text={text}
    backgroundColor="#92D6A5"
    color="#1C8336"
    borderRadius="100%"
    width="2.5rem"
    height="2.5rem"
  />
);

FeedMessageAvatar.propTypes = {
  text: PropTypes.string.isRequired,
};

export default FeedMessageAvatar;
