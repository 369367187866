import React, { ReactElement } from 'react';
import { join } from 'lodash-es';
import pluralize from 'pluralize';

import { IFireAlertCard } from 'types/FireAlertCard';
import PermissionList from 'bundles/Shared/components/PermissionList';
import { generatePopoverContent } from '@/bundles/FireStation/components/utils/utils';

interface IAlertCardData {
  title: string;
  body: ReactElement;
  actionText: string;
}

export const alertDataFormatter = (
  fireAlertCard: IFireAlertCard,
): IAlertCardData | null => {
  if (fireAlertCard.kind === 'expired_invitation') {
    const alerts = fireAlertCard.expiredInvitationAlerts;
    return {
      title: `${pluralize('User', alerts.length)} ${pluralize(
        'have',
        alerts.length,
      )} expired access to registration.`,
      body: (
        <>
          <PermissionList
            hideAvatar
            hideIcon
            permissions={generatePopoverContent(
              alerts.map((obj) => obj.userToInvite),
            )}
            className="d-inline-flex"
            mainListClasses="light-90"
          />
          {pluralize('have', alerts.length)} expired registration access, need
          to send it again.
        </>
      ),
      actionText: 'Invite Again',
    };
  }

  if (fireAlertCard.kind === 'yardi_legal_entities_mismatch') {
    const alerts = fireAlertCard.yardiLegalEntitiesMismatchAlerts;
    const lastAlert = alerts[alerts.length - 1];
    return {
      title: `Legal Entities list mismatch occurred during ${
        alerts.length
      } latest ${pluralize('parsing', alerts.length)}.`,
      body: (
        <>
          Legal Entities list mismatch occurred during {alerts.length} latest{' '}
          {pluralize('parsing', alerts.length)}
          <br />
          {lastAlert && (
            <>
              Latest parsing
              {lastAlert.missingCodes.length
                ? ` was missing LEs ${join(lastAlert.missingCodes, ', ')}`
                : ''}
              {lastAlert.missingCodes.length && lastAlert.unexpectedCodes.length
                ? ' and'
                : ''}
              {lastAlert.unexpectedCodes.length
                ? ` contained new LEs ${join(lastAlert.unexpectedCodes, ', ')}`
                : ''}
            </>
          )}
        </>
      ),
      actionText: 'Hide',
    };
  }

  if (fireAlertCard.kind === 'published_data_period_batch_update') {
    const alerts = fireAlertCard.publishedDataPeriodBatchUpdateAlerts;
    return {
      title: `Published data was updated for ${alerts.length} data ${pluralize(
        'period',
        alerts.length,
      )} during recent parsing.`,
      body: (
        <>
          Published data was updated for {alerts.length} data{' '}
          {pluralize('period', alerts.length)} during recent parsing
          <br />
          {alerts.map((alert) => (
            <>
              - {alert.generalLedgerName} of {alert.legalEntityName} for period{' '}
              {alert.period}
              <br />
            </>
          ))}
        </>
      ),
      actionText: 'Hide',
    };
  }

  if (fireAlertCard.kind === 'pending_change_order_approval') {
    const CO =
      fireAlertCard.reconcilePendingChangeOrderApprovalAlert
        .changeOrderToApprove;
    return {
      title: 'Pending Change Order Approval',
      body: (
        <>
          <div>
            Change order <b>#{CO.number}</b> for <b>{CO.legalEntity.name}</b>{' '}
            required your approval.
          </div>
        </>
      ),
      actionText: 'Review',
    };
  }

  if (fireAlertCard.kind === 'pending_change_order_event_approval') {
    const COR =
      fireAlertCard.reconcilePendingChangeOrderEventApprovalAlert
        .changeOrderEventToApprove;
    return {
      title: 'Pending Change Event Approval',
      body: (
        <>
          <div>
            Change event <b>#{COR.number}</b> - <b>{COR.title}</b> for{' '}
            <b>{COR.legalEntity.name}</b> required your approval.
          </div>
        </>
      ),
      actionText: 'Review',
    };
  }

  return null;
};
