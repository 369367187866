import { useSearchParams } from '@/shared/lib/hooks/navigation/useSearchParams';

export const useAssetIdSearchParam = () => {
  const [searchParams] = useSearchParams();
  return searchParams.get('assetId');
};

export const useAllAssetIdsSearchParams = () => {
  const [searchParams] = useSearchParams();
  return searchParams.getAll('assetIds[]').map(Number);
};

export const useAllSegmentIdsSearchParams = () => {
  const [searchParams] = useSearchParams();
  return searchParams.getAll('segmentIds[]').map(Number);
};
