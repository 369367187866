import { exportFile } from '@/shared/lib/exportFile';
import useBoolean from '@/shared/lib/hooks/useBoolean';

export const useExportForecastingBudget = () => {
  const {
    value: isLoading,
    setTrue: enableLoading,
    setFalse: disableLoading,
  } = useBoolean();

  const handleExport = (forecastId: string, budgetId: string) => {
    exportFile({
      url: `/reconcile/forecasting/forecasts/${forecastId}/budgets/${budgetId}/export`,
      filePostfix: 'forecasting-budget',
      enableLoading,
      disableLoading,
    });
  };

  return [handleExport, { isLoading }] as const;
};
