import UniversalFilterButton from '@/bundles/Shared/components/Filters/buttons/universalFilterButton/UniversalFilterButton';
import CheckList from 'stories/Checkbox/CheckList';
import { GetApiCapitalInvestmentObjectSummariesApiArg } from '@/entities/return/api/capitalInvestmentObjectsGeneratedApi';
import { Popover } from '@/stories/Popover/Popover';

const OPTIONS = [
  {
    value: 'Asset',
    label: 'Asset',
  },
  {
    value: 'Fund',
    label: 'Fund',
  },
] as const satisfies readonly {
  value: GetApiCapitalInvestmentObjectSummariesApiArg['objectType'];
  label: GetApiCapitalInvestmentObjectSummariesApiArg['objectType'];
}[];

export function QuickFilterByObjectType({
  checkedItem,
  onCheckItemClick,
}: {
  checkedItem: GetApiCapitalInvestmentObjectSummariesApiArg['objectType'];
  onCheckItemClick: (
    item: GetApiCapitalInvestmentObjectSummariesApiArg['objectType'],
  ) => VoidFunction;
}) {
  return (
    <Popover
      template={
        <CheckList
          items={OPTIONS}
          value={
            checkedItem
              ? [
                  {
                    value: checkedItem,
                    label: checkedItem,
                  },
                ]
              : OPTIONS
          }
          onChange={(newItems) => {
            const value = newItems.length === 1 ? newItems[0].value : undefined;
            onCheckItemClick(value);
          }}
        />
      }
      maxWidth="37.5rem"
      placement="bottom-start"
      hiddenArrow
      trigger="click"
      offset={[0, 2]}
    >
      {({ triggered }) => (
        <UniversalFilterButton
          clicked={triggered}
          filtered={checkedItem !== undefined}
          onClose={() => onCheckItemClick(undefined)}
        />
      )}
    </Popover>
  );
}
