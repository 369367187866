import React, { useState, useMemo } from 'react';
import { useAppSelector } from '@/shared/lib/hooks/redux';
import { Modal } from 'stories/Modals/Modal/Modal';
import { PayApp, PayAppSummary } from '@/shared/types/reconcile/PayApp';
import {
  getGroupFormItems,
  payData,
} from '@/bundles/Construction/components/PayApp/PayAppSummary/initData';
import SingleForm from 'bundles/Shared/components/GroupForm/SingleForm';

interface ISubmitData {
  pay_app_summary: object;
  sync_asset_config_budgets: boolean;
}

interface Props {
  payApp: PayApp;
  payAppSummary: PayAppSummary;
  onSubmit: (
    legalEntityCode: string,
    payAppId: number,
    payAppSummaryId: number,
    data: ISubmitData,
  ) => void;
  onClose: () => void;
}

const PayAppSummaryForm = ({
  onSubmit,
  payApp,
  onClose,
  payAppSummary,
}: Props) => {
  const legalEntity = useAppSelector(
    (state) => state.developmentBudget.legalEntity,
  );

  const [formData, setFormData] = useState(payAppSummary);
  const syncAssetConfigBudgets = false;

  const initGroupFormItems = useMemo(() => getGroupFormItems(), []);

  const handleSubmit = async () => {
    const formDataTransform = {};
    Object.keys(formData).forEach((key) => {
      const payItem = payData.find((p) => p.fieldName === key && formData[key]);
      if (payItem) {
        formDataTransform[payItem.fieldNameSnap] = formData[payItem.fieldName];
      }
    });

    await onSubmit(
      legalEntity.code,
      payAppSummary.payAppId ?? payApp.id,
      payAppSummary?.id,
      {
        pay_app_summary: formDataTransform,
        sync_asset_config_budgets: syncAssetConfigBudgets,
      },
    );
  };
  return (
    <Modal header="Pay App Summary" toggle={onClose} size="600">
      <SingleForm
        singleFormItems={initGroupFormItems}
        onSubmit={handleSubmit}
        formData={formData}
        setFormData={setFormData}
        buttonConfig={{
          titleBtn: 'Save Updates',
          stylesForMainWrapper: 'mt-6',
          funcCancel: onClose,
        }}
      />
    </Modal>
  );
};

export default PayAppSummaryForm;
