import { mapItemsToListOption } from '@/shared/lib/listHelpers';
import { LegalEntity } from '@/entities/core/legalEntity/model';
import { DependencyList, useMemo } from 'react';

function xorByIds<T extends { id: number | string }>(
  ids: T['id'][],
  items: T[],
): T[] {
  let currentIds = ids;
  const res: T[] = [];

  for (const item of items) {
    if (currentIds.length === 0) break;
    if (currentIds.includes(item.id)) {
      res.push(item);
      currentIds = currentIds.filter((id) => id !== item.id);
    }
  }
  return res;
}

export const useSettingsReportOperationalLEOptions = ({
  legalEntities,
  selectedLEIds,
  deps,
}: {
  legalEntities: Pick<LegalEntity, 'id' | 'code' | 'name'>[];
  selectedLEIds: LegalEntity['id'][];
  deps: DependencyList;
}) => {
  return useMemo(() => {
    const allLEOptions = mapItemsToListOption(legalEntities ?? [], 'name');

    const selectedLEOptions = mapItemsToListOption(
      xorByIds(selectedLEIds, legalEntities),
      'name',
    );

    return {
      allLEOptions,
      selectedLEOptions,
    };
  }, deps);
};
