import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_URL } from 'lib/http';
import {
  Invoice,
  InvoiceBadge,
  PayableTeamContact,
  RequestInvoiceApprovalStamp,
  SoftwareProvider,
} from '@/shared/types/reconcile/Invoice';
import { stringify, IStringifyOptions } from 'qs';
import { RequisitionInvoice } from '@/shared/types/reconcile/Requisition';
import { DEFAULT_STRINGIFY_OPTIONS } from 'lib/stringify';
import { DrawRequest } from '@/shared/types/reconcile/DrawRequest';
import { ISharedDocument } from 'types/SharedDocument';
import { IPaginationMeta, IQueryParams } from 'types/Pagination';
import { IPageParamsDrawRequest } from '@/bundles/DrawPackage/DrawPage/DrawRequest';
import { IRequisition } from 'bundles/REconcile/types/IRequisition';
import { ILineItemWithCategory } from 'bundles/DrawPackage/InvoicesFlow/Invoices/verify/utils';
import { IPageParamsNoPaidInvoices } from 'bundles/DrawPackage/InvoicesFlow/Invoices/pay/components/markAsPaid/AddInvoicesModal';
import { TPageParamsTransactions } from 'bundles/DrawPackage/InvoicesFlow/Invoices/pay/components/markAsPaid/SelectTransactionTable';
import { FundingSource } from '@/shared/types/reconcile/FundingSource';

export type TInvoicesWithMeta = {
  items: Invoice[];
  meta: IPaginationMeta & {
    totalInvoices: string;
    totalBudget: string;
    totalVariance: string;
    requisition: IRequisition;
  };
};

export type IErrorInvoice = string[];

export type StatusInvoices =
  | 'created'
  | 'verified'
  | 'approved'
  | 'completed'
  | 'archived';

type CanbanInvoices = Record<
  StatusInvoices,
  {
    items: Invoice[];
    meta: { count: number; totalAmount: RawFloat };
  }
>;

export const reconcileInvoiceApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_URL}/api/reconcile/development/legal_entities/`,
  }),
  reducerPath: 'reconcileInvoice',
  tagTypes: ['Invoice', 'Requisition', 'Draw', 'PayableTeam', 'Transaction'],
  endpoints: (build) => ({
    getInvoices: build.query<
      {
        items: Invoice[];
        meta: {
          totalAmount: string;
        };
      },
      {
        legalEntityCode: string;
        pageParams: IQueryParams;
      }
    >({
      query: (params) => {
        const options: IStringifyOptions = {
          addQueryPrefix: true,
          arrayFormat: 'brackets',
        };
        return `/${params.legalEntityCode}/invoices${stringify(
          {
            ...params.pageParams,
            'sort[order]': params.pageParams.sortOrder,
            'sort[field]': params.pageParams.sortField,
          },
          options,
        )}`;
      },
      providesTags: ['Invoice'],
    }),
    getInvoicesKanban: build.query<
      CanbanInvoices,
      {
        legalEntityCode: string;
        pageParams?: IQueryParams;
      }
    >({
      query: (params) =>
        `/${params.legalEntityCode}/invoices/kanban${stringify(
          {
            ...params.pageParams,
          },
          DEFAULT_STRINGIFY_OPTIONS,
        )}`,
      providesTags: ['Invoice'],
    }),
    getPayableInvoices: build.query<
      Invoice,
      {
        legalEntityCode: string;
        pageParams: IQueryParams;
      }
    >({
      query: (params) => {
        const options: IStringifyOptions = {
          addQueryPrefix: true,
          arrayFormat: 'brackets',
        };
        let withSortParams = params.pageParams?.sort;
        if (withSortParams) {
          withSortParams = {
            ...params.pageParams,
            'sort[order]': params.pageParams?.sort.order,
            'sort[field]':
              params.pageParams?.sort?.field === 'vendor_ids'
                ? 'vendor'
                : params.pageParams?.sort.field,
          };
        }

        return `/${params.legalEntityCode}/payable_invoices${stringify(
          {
            ...(withSortParams || params.pageParams),
          },
          options,
        )}`;
      },
      providesTags: ['Invoice', 'Requisition'],
    }),

    getInvoicesWithRequisition: build.query<
      TInvoicesWithMeta,
      {
        legalEntityCode: string;
        requisitionId: string;
        pageParams: IQueryParams;
      }
    >({
      query: (params) => {
        const transformSortParams = {
          ...params.pageParams,
          'sort[order]': params.pageParams?.sort?.order,
          'sort[field]':
            params.pageParams?.sort?.field === 'vendor_ids'
              ? 'vendor'
              : params.pageParams?.sort?.field,
        };

        return `/${params.legalEntityCode}/requisitions/${
          params.requisitionId
        }/invoices${stringify(
          {
            ...transformSortParams,
          },
          DEFAULT_STRINGIFY_OPTIONS,
        )}`;
      },
      providesTags: ['Invoice', 'Requisition'],
    }),
    getInvoice: build.query<
      Invoice,
      {
        legalEntityCode: string;
        invoiceId: string;
      }
    >({
      query: (params) =>
        `/${params.legalEntityCode}/invoices/${params.invoiceId}`,
      providesTags: ['Invoice'],
    }),
    createInvoice: build.mutation<
      Invoice,
      {
        legalEntityCode: string;
        body: Invoice & { vendor_name: string };
      }
    >({
      query(params) {
        return {
          url: `${params.legalEntityCode}/invoices`,
          method: 'POST',
          body: JSON.stringify(params.body),
        };
      },
      transformResponse: (response) => {
        toastr.success('Invoice Created');
        return response.data;
      },
      invalidatesTags: ['Invoice'],
    }),
    uploadInvoices: build.mutation<
      Invoice,
      {
        legalEntityCode: string;
        data: Invoice & { vendor_name: string };
      }
    >({
      query(params) {
        return {
          url: `${params.legalEntityCode}/invoices/batch_upload`,
          method: 'POST',
          body: JSON.stringify(params.data),
        };
      },
      transformResponse: (response) => {
        toastr.success('Invoices Uploaded');
        return response.data;
      },
      invalidatesTags: ['Invoice'],
    }),
    updateInvoice: build.mutation<
      Invoice,
      {
        legalEntityCode: string;
        body: Invoice;
        invoiceId: number;
      }
    >({
      query(params) {
        return {
          url: `${params.legalEntityCode}/invoices/${params.invoiceId}`,
          method: 'PUT',
          body: JSON.stringify(params.body),
        };
      },
      transformResponse: (response) => {
        toastr.success('Invoice Updated');
        return response.data;
      },
      invalidatesTags: ['Invoice', 'Requisition'],
    }),
    deleteInvoice: build.mutation<
      Invoice,
      {
        legalEntityCode: string;
        invoiceId: number;
      }
    >({
      query(params) {
        return {
          url: `${params.legalEntityCode}/invoices/${params.invoiceId}`,
          method: 'DELETE',
        };
      },
      transformResponse: (response) => {
        toastr.success('Invoice Deleted');
        return response.data;
      },
      invalidatesTags: ['Invoice'],
    }),
    verifyInvoice: build.mutation<
      Invoice,
      {
        legalEntityCode: string;
        body: {
          date: DateString;
          vendor_name: string;
          line_items: ILineItemWithCategory[];
          selected_line_items: string[];
          approval_stamp: RequestInvoiceApprovalStamp;
        };
        invoiceId: number;
        customNotify?: string;
      }
    >({
      query(params) {
        return {
          url: `${params.legalEntityCode}/invoices/${params.invoiceId}/verify`,
          method: 'PUT',
          body: JSON.stringify(params.body),
        };
      },
      transformResponse: (response, _, arg) => {
        toastr.success(arg.customNotify ?? 'Invoice verified');
        return response.data;
      },
      invalidatesTags: ['Invoice', 'Requisition'],
    }),
    approveInvoice: build.mutation<
      Invoice,
      {
        legalEntityCode: string;
        invoiceId: number;
      }
    >({
      query(params) {
        return {
          url: `${params.legalEntityCode}/invoices/${params.invoiceId}/approve`,
          method: 'PUT',
          body: JSON.stringify({
            forced: true, // temporary decision
          }),
        };
      },
      transformResponse: (response) => {
        toastr.success('Invoice approved');
        return response.data;
      },
      invalidatesTags: ['Invoice'],
    }),
    getRequisitions: build.query<
      {
        items: RequisitionInvoice[];
      },
      {
        legalEntityCode: string;
        not_funded?: boolean;
        fully_mapped_to_invoices?: boolean;
      }
    >({
      query: (params) =>
        `${API_URL}/api/development/legal_entities/${params.legalEntityCode}/requisitions?not_funded=${params?.not_funded}&fully_mapped_to_invoices=${params?.fully_mapped_to_invoices}`,
      providesTags: ['Requisition'],
    }),
    addPayableInvoice: build.mutation<
      Invoice,
      {
        legalEntityCode: string;
        invoiceId: number;
        body: {
          requisition_id: string;
        };
      }
    >({
      query(params) {
        return {
          url: `${params.legalEntityCode}/payable_invoices/${params.invoiceId}/add_to_requisition`,
          method: 'PUT',
          body: JSON.stringify(params.body),
        };
      },
      transformResponse: (response) => {
        toastr.success('Invoice added to requisition');
        return response.data;
      },
      invalidatesTags: ['Invoice', 'Requisition'],
    }),
    removePayableInvoice: build.mutation<
      Invoice,
      {
        legalEntityCode: string;
        invoiceId: number;
        body: {
          requisition_id: string;
        };
      }
    >({
      query(params) {
        return {
          url: `${params.legalEntityCode}/invoices/${params.invoiceId}/remove_from_requisition`,
          method: 'PUT',
          body: JSON.stringify(params.body),
        };
      },
      transformResponse: (response) => {
        toastr.success('Invoice removed from requisition');
        return response.data;
      },
      invalidatesTags: ['Invoice', 'Requisition'],
    }),
    getLinkableRequisitions: build.query<
      {
        items: RequisitionInvoice[];
      },
      {
        legalEntityCode: string;
        vendorId: string;
      }
    >({
      query: (params) =>
        `${params.legalEntityCode}/requisitions/linkable${stringify(
          {
            vendor_id: params.vendorId,
          },
          DEFAULT_STRINGIFY_OPTIONS,
        )}`,
      providesTags: ['Requisition'],
    }),
    getPayableInvoicesBadges: build.query<
      {
        items: {
          topVendors: InvoiceBadge[];
          otherVendors: InvoiceBadge;
        };
      },
      {
        legalEntityCode: string;
      }
    >({
      query: (params) => `${params.legalEntityCode}/payable_invoices/badges`,
      providesTags: ['Invoice'],
    }),
    getDraws: build.query<
      {
        items: DrawRequest[];
      },
      {
        legalEntityCode: string;
        pageParams: IPageParamsDrawRequest;
      }
    >({
      query: (params) => {
        const options: IStringifyOptions = {
          addQueryPrefix: true,
          arrayFormat: 'brackets',
        };
        return `/${params.legalEntityCode}/draws${stringify(
          {
            ...params.pageParams,
            'sort[order]': params.pageParams.sortOrder,
            'sort[field]': params.pageParams.sortField,
          },
          options,
        )}`;
      },
      providesTags: ['Draw'],
    }),
    getDrawInvoices: build.query<
      {
        items: Invoice[];
      },
      {
        drawId: string;
      }
    >({
      query: (params) =>
        `${API_URL}/api/reconcile/development/draws/${params.drawId}/invoices`,
      providesTags: ['Draw'],
    }),
    generateDrawPreview: build.mutation<
      {
        items: Invoice[];
      },
      {
        legalEntityCode: string;
        requisitionId: string;
        fundingSources?: Pick<FundingSource, 'id' | 'amount'>;
      }
    >({
      query: (params) => ({
        url: `${params.legalEntityCode}/draws/preview_categories${stringify(
          {
            requisition_id: params.requisitionId,
          },
          DEFAULT_STRINGIFY_OPTIONS,
        )}`,
        method: 'POST',
        body: JSON.stringify({
          funding_sources: params?.fundingSources ?? {},
        }),
      }),
      providesTags: ['Draw'],
    }),
    createDraw: build.mutation<
      Invoice,
      {
        legalEntityCode: string;
        body: {
          requisition_id: string;
          document: {
            file: ISharedDocument;
          };
        };
      }
    >({
      query(params) {
        return {
          url: `${params.legalEntityCode}/draws`,
          method: 'POST',
          body: JSON.stringify(params.body),
        };
      },
      transformResponse: (response) => {
        toastr.success('Draw Request Created');
        return response.data;
      },
      invalidatesTags: ['Draw', 'Requisition'],
    }),
    deleteDraw: build.mutation<
      Invoice,
      {
        drawId: string;
      }
    >({
      query(params) {
        return {
          url: `${API_URL}/api/reconcile/development/draws/${params.drawId}`,
          method: 'DELETE',
        };
      },
      transformResponse: (response) => {
        toastr.success('Draw Deleted');
        return response.data;
      },
      invalidatesTags: ['Draw', 'Requisition'],
    }),
    getPayableTeamContacts: build.query<
      {
        items: PayableTeamContact[];
        meta: {
          softwareProviders: SoftwareProvider[];
        };
      },
      never
    >({
      query: () =>
        `${API_URL}/api/settings/reconcile/accounts_payable_team_contacts`,
      providesTags: ['PayableTeam'],
    }),
    createPaybleTeamContact: build.mutation<
      PayableTeamContact,
      {
        body: Omit<PayableTeamContact, 'id'>;
      }
    >({
      query(params) {
        return {
          url: `${API_URL}/api/settings/reconcile/accounts_payable_team_contacts`,
          method: 'POST',
          body: JSON.stringify({
            ...params.body,
            software_provider: params.body.softwareProvider,
          }),
        };
      },
      transformResponse: (response: PayableTeamContact) => {
        toastr.success(
          'Accounts Payable Team Contact was successfully created',
        );
        return response;
      },
      invalidatesTags: ['PayableTeam'],
    }),
    markForPaymentInvoice: build.mutation<
      Invoice,
      {
        legalEntityCode: string;
        invoiceId: number;
        body: {
          subject: string;
          body: string;
          save_as_template: boolean;
          contact_id?: string;
        };
      }
    >({
      query(params) {
        return {
          url: `${params.legalEntityCode}/invoices/${params.invoiceId}/mark_for_payment`,
          method: 'POST',
          body: JSON.stringify(params.body),
        };
      },
      transformResponse: (response: { data: Invoice }) => {
        toastr.success('Your email was successfully sent');
        return response.data;
      },
      invalidatesTags: ['Invoice'],
    }),
    getPaymentEmailTemplate: build.query<
      {
        name: string;
        subject: string;
        body: string;
      },
      {
        contactId: string;
      }
    >({
      query: (params) =>
        `${API_URL}/api/reconcile/accounts_payable_team_contacts/${params.contactId}/payment_request_email_templates`,
    }),
    getLinkableTransactions: build.query<
      {
        items: RequisitionInvoice[];
      },
      {
        legalEntityCode: string;
        periods: string[];
        pageParams: TPageParamsTransactions;
      }
    >({
      query: (params) =>
        `${params.legalEntityCode}/invoices/transactions/linkable${stringify(
          {
            ...params.pageParams,
            transaction_type: params.pageParams.valueType,
            date_range: {
              from: params.periods[0],
              to: params.periods.at(-1),
            },
          },
          DEFAULT_STRINGIFY_OPTIONS,
        )}`,
      providesTags: ['Transaction'],
    }),
    getNoPaidInvoices: build.query<
      {
        items: RequisitionInvoice[];
      },
      {
        legalEntityCode: string;
        pageParams: IPageParamsNoPaidInvoices;
      }
    >({
      query: (params) =>
        `${params.legalEntityCode}/not_paid_invoices${stringify(
          {
            ...params.pageParams,
          },
          DEFAULT_STRINGIFY_OPTIONS,
        )}`,
    }),
    markAsPaidInvoice: build.mutation<
      Invoice,
      {
        legalEntityCode: string;
        invoiceId: string;
        body: {
          invoice_ids: UUID[];
          transaction_id: number;
        };
      }
    >({
      query(params) {
        return {
          url: `${params.legalEntityCode}/invoices/mark_as_paid`,
          method: 'POST',
          body: JSON.stringify(params.body),
        };
      },
      transformResponse: (response: { data: Invoice }) => {
        toastr.success('Invoice Marked as Paid');
        return response.data;
      },
      invalidatesTags: ['Transaction', 'Invoice'],
    }),
    markAsFundedRequisitions: build.mutation<
      Invoice,
      {
        legalEntityCode: string;
        requisitionId: string;
        body: {
          funding_sources: Pick<FundingSource, 'id' | 'amount'>[];
        };
      }
    >({
      query(params) {
        return {
          url: `${API_URL}/api/development/legal_entities/${params.legalEntityCode}/requisitions/${params.requisitionId}/mark_as_funded`,
          method: 'POST',
          body: JSON.stringify(params.body),
        };
      },
      transformResponse: (response: { data: Invoice }) => {
        toastr.success('Requisition Marked as Funded');
        return response.data;
      },
    }),
  }),
});

export const {
  useGetRequisitionsQuery,
  useGetLinkableRequisitionsQuery,
  useGetInvoicesQuery,
  useGetInvoicesKanbanQuery,
  useGetPayableInvoicesQuery,
  useGetPayableInvoicesBadgesQuery,
  useGetInvoicesWithRequisitionQuery,
  useGetInvoiceQuery,
  useGetDrawsQuery,
  useGetDrawInvoicesQuery,
  useGenerateDrawPreviewMutation,
  useCreateDrawMutation,
  useCreateInvoiceMutation,
  useUploadInvoicesMutation,
  useUpdateInvoiceMutation,
  useDeleteInvoiceMutation,
  useDeleteDrawMutation,
  useVerifyInvoiceMutation,
  useApproveInvoiceMutation,
  useAddPayableInvoiceMutation,
  useRemovePayableInvoiceMutation,
  useGetPayableTeamContactsQuery,
  useMarkForPaymentInvoiceMutation,
  useCreatePaybleTeamContactMutation,
  useGetPaymentEmailTemplateQuery,
  useGetLinkableTransactionsQuery,
  useGetNoPaidInvoicesQuery,
  useMarkAsPaidInvoiceMutation,
  useMarkAsFundedRequisitionsMutation,
} = reconcileInvoiceApi;
