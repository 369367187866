import type { ReportBuilderAssetDto } from '@/entities/report/reportBuilder/api/settingsReportBuilderTemplatesGeneratedApi';
import { generateUrl, ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import { GrowDiv } from '@/shared/ui/GrowDiv';
import { Icon } from '@/stories/Icon/Icon';
import { Popover } from '@/stories/Popover/Popover';
import { PseudoLink } from '@/stories/PseudoLink/PseudoLink';
import { Link } from '@reach/router';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import pluralize from 'pluralize';

export const TemplateAssetsInUse = ({
  assets,
}: {
  assets: Pick<ReportBuilderAssetDto, 'id' | 'name' | 'slug'>[];
}) => (
  <Popover
    template={
      <OverlayScrollbarsComponent className="p-1">
        <div className="flex max-h-[200px] flex-col gap-4">
          {assets.map((a) => (
            <Link
              key={a.id}
              to={generateUrl(ROUTES_ROOT.assets.asset.reports.fullPath, {
                pathParams: {
                  assetSlug: a.slug,
                },
              })}
              className="flex items-center gap-2 text-neutral-850 hover:text-info-055"
            >
              <Icon className="text-neutral-500" iconName="asset" />
              <p className="secondary-regular ">{a.name}</p>
              <GrowDiv />
              <Icon iconName="externalLink" />
            </Link>
          ))}
        </div>
      </OverlayScrollbarsComponent>
    }
    maxWidth="400px"
    appendToBody
    placement="bottom-start"
    hiddenArrow
    classes={{
      spanContainer: 'd-inline-flex mnw-0',
    }}
  >
    <PseudoLink className={'text-ellipsis text-neutral-850'}>
      {assets.length} {pluralize('asset', assets.length)}
    </PseudoLink>
  </Popover>
);
