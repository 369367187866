import React, { useMemo } from 'react';
import { FilterCard } from 'stories/filterCard/FilterCard';
import { StatisticLine } from 'stories/filterCard/statistic/StatisticLine';
import { useHovered } from '@/shared/lib/hooks/useHovered';
import { round, sum } from 'lodash-es';
import { accountingNumberFormat } from '@/shared/lib/formatting/number';
import { IChangeOrderEventBages } from 'types/reconcile';
import { cn } from '@/shared/lib/css/cn';
import {
  CHANGE_ORDER_STATUS_TO_CONTINGENCY_CATEGORY_MAPPING,
  CONTINGENCY_CATEGORY_SETTINGS,
} from 'bundles/Construction/constants/contingencyCategory';
import { TFilterCardType } from 'bundles/Construction/types';
import { getContingencyCategoriesPercent } from 'bundles/Construction/utils';
import ConstructionReportCardStatistics from 'bundles/Construction/components/cards/ConstructionReportCardStatistics';
import styles from '@/bundles/Construction/components/cards/ChangeManagementFilterCard/ChangeManagementFilterCard.module.scss';

interface Props {
  filtered: boolean;
  onFilter: () => void;
  report: IChangeOrderEventBages;
  initialContingency: number;
  cardType: TFilterCardType;
  className?: string;
}

const ACTIVE_COLOR = 'var(--dark)';

const CARD_TYPE_LABELS: Record<TFilterCardType, string> = {
  approved: 'Excluded CORs',
  completed: 'Excluded COs',
  ipc: 'Internal Projected Change (IPC)',
  pending: 'Pending CORs & PCOs',
};

function ChangeManagementCEFilterCard({
  filtered,
  onFilter,
  report,
  initialContingency,
  cardType,
  className,
}: Props) {
  const { onMouseLeave, onMouseOver, hovered } = useHovered(false);
  const statusMetrics = round((report.amount * 100) / initialContingency, 1);

  const categoriesPercent = getContingencyCategoriesPercent(report);
  const currentCategory =
    CHANGE_ORDER_STATUS_TO_CONTINGENCY_CATEGORY_MAPPING[cardType];

  const remaining = useMemo(
    () => 100 - sum(Object.values(categoriesPercent)),
    [categoriesPercent],
  );

  const statistics = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(CONTINGENCY_CATEGORY_SETTINGS).map(([key, settings]) => [
          key,
          {
            color: key === currentCategory ? ACTIVE_COLOR : undefined,
            hoverColor: settings.color,
            value: categoriesPercent[key as TContingencyCategory] ?? remaining,
          },
        ]),
      ),
    [currentCategory],
  );

  const cardTypeDisplay = CARD_TYPE_LABELS[cardType];

  return (
    <FilterCard
      className={cn(styles.card, className)}
      classes={{
        hoverPart: styles.hoverPart,
        filter: styles.filter,
      }}
      onMouseLeave={onMouseLeave}
      onMouseOver={onMouseOver}
      header={<span className="inline-semibold pt-xxs">{cardTypeDisplay}</span>}
      filtered={filtered}
      onFilter={onFilter}
      filterText={`Filtered by ${cardTypeDisplay}`}
      hoverContent={
        <ConstructionReportCardStatistics report={report} cardType={cardType} />
      }
      content={
        <>
          <div className="flex flex-col">
            <span className="dark-60 header6-regular">
              {report.amount
                ? accountingNumberFormat(round(report.amount, 2))
                : '–'}
            </span>
            <span className="light-90 label-regular">
              {statusMetrics}% of Initial Contingency
            </span>
          </div>
          <StatisticLine
            className="mt-2"
            hovered={hovered}
            statistics={statistics}
          />
        </>
      }
    />
  );
}

export default ChangeManagementCEFilterCard;
