import React, { FC, ReactNode } from 'react';
import { cn } from '@/shared/lib/css/cn';
import NumberBoardCard from 'bundles/DrawPackage/InvoicesFlow/Invoices/invoice/kanban/cards/NumberBoardCard';

interface IBoardCardProps {
  children: ReactNode;
  number?: string;
  className?: string;
}

const BoardCard: FC<IBoardCardProps> = ({ children, number, className }) => (
  <div className={cn('relative rounded-2xl bg-white p-4', className)}>
    {children}
    {number && <NumberBoardCard number={number} />}
  </div>
);

export default BoardCard;
