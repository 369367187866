import { UNGROUPED_GROUP_KEY } from '@/bundles/Shared/entities/dashboard/model/slices/shared';
import { DEFAULT_WIDGET_HISTORY_SIZE } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/fields/HistorySizeField';
import { commonTableWidgetUpdaters } from '@/bundles/Shared/widgets/dashboard/widgets/common/updaters';
import { HistoricalReviewTableWidgetConfigDto } from '@/shared/api/dashboardsSettingsGeneratedApi';

import {
  maxIdGenerator,
  transformPeriodShiftFormToDto,
  transformPeriodTypeFormToDto,
} from 'bundles/Shared/widgets/dashboard/widgets/common/lib/config';

import { upsertColumnSettings } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/updaters';
import { upsertRowSettings } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/updaters';
import { HistoricalReviewTableWidgetConfigModel } from 'bundles/Shared/widgets/dashboard/widgets/historicalTable';
import { HistoricalTableWidgetColumnForm } from 'bundles/Shared/widgets/dashboard/widgets/historicalTable/config/column.form';
import { HistoricalTableWidgetConfigForm } from 'bundles/Shared/widgets/dashboard/widgets/historicalTable/config/component.form';
import { HistoricalTableWidgetRowForm } from 'bundles/Shared/widgets/dashboard/widgets/historicalTable/config/row.form';
import { produce } from 'immer';
import { isEmpty } from 'lodash-es';

export const moveColumn = (
  ...args: Parameters<typeof commonTableWidgetUpdaters.moveColumn>
) => {
  const [{ fromIndex, toIndex, sourceGroupId, targetGroupId }, widgetConfig] =
    args;
  const shiftedIndexesWithoutLabel = {
    fromIndex:
      sourceGroupId === UNGROUPED_GROUP_KEY ? fromIndex - 1 : fromIndex,
    toIndex: targetGroupId === UNGROUPED_GROUP_KEY ? toIndex - 1 : toIndex,
  };
  const labelColumnIndex = widgetConfig.viz_config.columns.findIndex(
    (col) => col.key === 'label',
  );

  const labelColumn = widgetConfig.viz_config.columns[labelColumnIndex];
  const updatedWidgetConfig = commonTableWidgetUpdaters.moveColumn(
    {
      ...shiftedIndexesWithoutLabel,
      sourceGroupId,
      targetGroupId,
    },
    produce(widgetConfig, (draft) => {
      draft.viz_config.columns.splice(labelColumnIndex, 1);
    }),
  );

  return produce(updatedWidgetConfig, (draft) => {
    draft.viz_config.columns.unshift({
      ...labelColumn,
      order: -1,
    });
  });
};

export const upsertColumn = ({
  column,
  widgetConfig,
  groupId,
}: {
  column: HistoricalTableWidgetColumnForm;
  widgetConfig: HistoricalReviewTableWidgetConfigModel;
  groupId?: string;
}) => {
  return produce(widgetConfig, (draft) => {
    const { columns = [] } = draft;
    let columnIndex = columns.findIndex((c) => c.key === column.key);
    if (columnIndex === -1) {
      columnIndex = columns.length;
    }
    const key = column.key ?? maxIdGenerator(draft.columns, 'key');
    columns[columnIndex] = {
      key,
      label: column.label,
      period_shift: transformPeriodShiftFormToDto(
        column.period_shift ?? undefined,
      ),
      period_type: transformPeriodTypeFormToDto(column.period_type),
    };

    upsertColumnSettings(column, draft, {
      key: key.toString(),
      groupId,
    });
  });
};

export const upsertRow = (
  form: HistoricalTableWidgetRowForm,
  widgetConfig: HistoricalReviewTableWidgetConfigModel,
) => {
  return produce(widgetConfig, (draft) => {
    const { rows = [] } = draft;
    let rowIndex = rows.findIndex((r) => r.key === form.key);
    if (rowIndex === -1) {
      rowIndex = rows.length;
    }

    const key = form.key ?? maxIdGenerator(draft.rows, 'key');
    rows[rowIndex] = {
      key,
      label: form.label,
      expression: form.expression,
      type: form.type === 'sparkline' ? 'numeric' : form.type,
      history_size: form.history_size ?? DEFAULT_WIDGET_HISTORY_SIZE,
    };
    if (!isEmpty(form.adjustment)) {
      rows[rowIndex].adjustment_expression = form.adjustment;
    }

    upsertRowSettings(form, draft, {
      key: key.toString(),
    });
  });
};

export const updateDefaultOptions = (
  values: HistoricalTableWidgetConfigForm,
  widgetConfig: HistoricalReviewTableWidgetConfigDto,
): HistoricalReviewTableWidgetConfigDto => {
  return produce(widgetConfig, (draft) => {
    draft.viz_config.header_background = values.header_background ?? undefined;
    draft.default_options = {
      date: {
        period_shift: transformPeriodShiftFormToDto(values.period_shift),
      },
    };
  });
};
