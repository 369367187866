import ErrorBoundaryPage from '@/app/ui/ErrorBoundaryPage';
import { NotFound } from '@/bundles/Errors';
import { reportingEnhancedApi } from '@/entities/reporting/api/reportingEnhancedApi';
import { useEagleEyeReportIdParam } from '@/entities/reporting/lib/eagleEye';
import { PdfPreview } from '@/shared/ui/PdfPreview';
import { useHostedUrl } from '@/shared/lib/hooks/useHostedUrl';
import { AnimationLoader } from '@/stories/AnimationLoader/AnimationLoader';
import type { RouteComponentProps } from '@reach/router';
import { type FC } from 'react';

export const EagleEyeGeneratedReportPage: FC<RouteComponentProps> = () => {
  const id = useEagleEyeReportIdParam();
  const { data, isLoading, isError } =
    reportingEnhancedApi.useGetApiReportBuilderEagleEyeReportsByIdQuery(
      {
        id: id!,
      },
      {
        skip: id == null,
      },
    );

  if (isLoading) return <AnimationLoader />;
  if (isError) return <NotFound />;

  if (data == null || data.fileUrl == null || id == null) {
    return <ErrorBoundaryPage />;
  }

  return <PdfPreview fileUrl={data.fileUrl} />;
};
