import { fetchNewNotifications } from 'bundles/Notifications/actions';
import { useAppDispatch } from '@/shared/lib/hooks/redux';
import { useEffect } from 'react';
import LeftSidebar from '@/bundles/Shared/components/LeftSidebar/LeftSidebar';
import MobileNav from '@/bundles/Shared/components/Nav/MobileNav';

const MainNav = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchNewNotifications());
  }, []);

  return (
    <div className="print:hidden">
      <MobileNav />
      <LeftSidebar />
    </div>
  );
};

export default MainNav;
