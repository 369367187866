import pluralize from 'pluralize';
import { useCallback, type ComponentProps } from 'react';
import AlbumCard from 'stories/AlbumCard/AlbumCard';

export interface IFolder {
  id: number;
  sharedFilesCount: number;
  childrenCount: number;
  description: string;
  title: string;
  coverImageUrl: string;
  parentId?: string | number;
}

interface Props {
  folders: IFolder[];
  onClick: (id: IFolder['id']) => void;
  selectedFolders?: IFolder[];
  canManage?: boolean;
  toggleSelected?: (folder: IFolder) => void;
  onEdit?: (folder: IFolder) => void;
  onDelete?: (folder: IFolder) => void;
  checkboxDisabled?: boolean;
  lgSize?: number;
}

const AlbumsList = ({
  folders,
  selectedFolders,
  toggleSelected,
  canManage,
  onClick,
  onEdit,
  onDelete,
  checkboxDisabled = false,
}: Props) => {
  const getActions = useCallback(
    (folder: IFolder) => {
      const res: ComponentProps<typeof AlbumCard>['actions'] = [];

      if (!canManage) return res;

      if (onDelete) {
        res.push({
          icon: 'trash',
          onClick: () => onDelete(folder),
        });
      }

      if (onEdit) {
        res.push({
          icon: 'edit',
          onClick: () => onEdit(folder),
        });
      }

      return res;
    },
    [canManage],
  );

  return (
    <div className="grid gap-l md:grid-cols-4 3xl:grid-cols-5 4xl:grid-cols-6">
      {folders.map((folder) => (
        <div key={folder.id}>
          <AlbumCard
            key={folder.id}
            imageCover={folder.coverImageUrl}
            selected={
              selectedFolders?.find(({ id }) => id === folder.id) !== undefined
            }
            onSelectToggle={
              canManage && toggleSelected
                ? () => toggleSelected?.(folder)
                : undefined
            }
            title={folder.title}
            description={folder.description}
            onClick={() => onClick(folder.id)}
            checkboxDisabled={checkboxDisabled}
            actions={getActions(folder)}
            imagePlaceholder="media"
            items={
              <>
                {folder.sharedFilesCount + folder.childrenCount}&nbsp;
                {pluralize(
                  'item',
                  Number(folder.sharedFilesCount) +
                    Number(folder.childrenCount),
                )}
              </>
            }
          />
        </div>
      ))}
    </div>
  );
};

export default AlbumsList;
