import { capitalize } from 'lodash-es';
import React from 'react';
import { IconButton } from 'stories/IconButton/IconButton';
import { LinkButton } from 'stories/LinkButton/LinkButton';
import { Tumbler } from 'stories/Tumbler/Tumbler';

interface Props {
  onToggle: (value: boolean) => void;
  onSort: () => void;
  sort: 'asc' | 'desc' | null;
  assignLabel: string;
  fieldLabel: string;
  label: string;
  customCurrentValueFormatter?: React.ReactNode;
  openEditModal?: () => void;
  currentValue?: any;
  sameValue: boolean;
  disabled?: boolean;
}

const BulkUploaderToggler = ({
  sameValue,
  currentValue,
  openEditModal,
  onToggle,
  sort,
  onSort,
  assignLabel,
  fieldLabel,
  customCurrentValueFormatter,
  label,
  disabled,
}: Props) => (
  <td className="!w-[24%] pb-s">
    <div className="flex flex-col justify-between">
      <Tumbler
        onChange={(e) => onToggle(e.target.checked)}
        disabled={disabled}
        checked={sameValue}
        className="label-regular self-start"
      >
        {label}
      </Tumbler>
      {sameValue && currentValue && (
        <div className="inline-regular mt-s flex items-center justify-between">
          {customCurrentValueFormatter}
          {!customCurrentValueFormatter && currentValue}
          {openEditModal && (
            <IconButton size="m" iconName="edit" onClick={openEditModal} />
          )}
        </div>
      )}
      {sameValue && !currentValue && (
        <LinkButton className="d-block mt-s text-left" onClick={openEditModal}>
          {assignLabel}
        </LinkButton>
      )}
      {!sameValue && (
        <div className="mt-s flex items-center">
          <p className="light-60 secondary-regular mr-s">{fieldLabel}</p>
          {onSort && (
            // TODO: replace with IconButton when "S" size will be available
            <IconButton
              className={sort && 'blue'}
              onClick={onSort}
              iconName={`sort${capitalize(sort ?? 'off')}`}
            />
          )}
        </div>
      )}
    </div>
  </td>
);

export default BulkUploaderToggler;
