import React, { FC } from 'react';
import { formatAmount } from '@/shared/lib/formatting/number';
import styles from '@/bundles/DrawPackage/components/TotalInfoBlock.tsx/TotalInfoBlock.module.scss';

interface ITotalInfoBlockProps {
  metaData: {
    title: string;
    value: string;
  }[];
}

const TotalInfoBlock: FC<ITotalInfoBlockProps> = ({ metaData }) => (
  <div className={styles.wrapper}>
    {metaData.map((item, idx) => (
      <div key={`total${idx}`} className="label-regular flex">
        <div className="light-60 mr-[6px]">{item.title}</div>
        <div className="dark-60">{formatAmount(item.value)}</div>
      </div>
    ))}
  </div>
);

export default TotalInfoBlock;
