import React, { FC } from 'react';
import { cn } from '@/shared/lib/css/cn';
import type { MenuItem } from '@/bundles/InvestmentObjects/components/menu/InvestmentObjectMenu';
import ReconcileMenuItemLink from '@/bundles/InvestmentObjects/components/menu/ReconcileMenu/ReconcileMenuItemLink';
import styles from 'bundles/InvestmentObjects/components/menu/ReconcileMenu/ReconcileMenu.module.scss';

const ReconcileMenuItemGroup: FC<{
  items: MenuItem[];
  title: string;
}> = ({ items, title }) => (
  <div>
    {Boolean(items.length) && (
      <div>
        <div className={cn(styles.reconcileMenuTitle, 'label-regular')}>
          {title}
        </div>
        <div className={styles.reconcileMenuItems}>
          {items.map((item) => (
            <ReconcileMenuItemLink key={item.to} {...item}>
              {item.label}
            </ReconcileMenuItemLink>
          ))}
        </div>
      </div>
    )}
  </div>
);

export default ReconcileMenuItemGroup;
