import React, { ComponentProps, useMemo, useState } from 'react';
import { Badge } from 'stories/Badges/Badge/Badge';
import { Checkbox } from 'stories/Checkbox/Checkbox';
import { SearchInput } from 'stories/FormControls/Inputs/SearchInput/SearchInput';
import useAlphabetGroups from '@/shared/lib/hooks/useAlphabetGroups';
import pluralize from 'pluralize';

interface Props<T extends { id: number | string }> {
  items: T[];
  getLabelFromItem: (item: T) => string;
  selectedItems: T[];
  setSelectedItems: (selectedItems: T[]) => void;
  disabledItemIds?: T['id'][];
  selectAll?: boolean;
  withSearch?: boolean;
  searchEntityLabel?: string;
  searchProps?: Omit<
    ComponentProps<typeof SearchInput>,
    'value' | 'onChange' | 'resetValue'
  >;
}

const GroupedCheckboxes = <T extends { id: number | string }>({
  items,
  getLabelFromItem,
  selectedItems,
  setSelectedItems,
  disabledItemIds = [],
  selectAll = true,
  withSearch,
  searchEntityLabel,
  searchProps,
}: Props<T>) => {
  const [searchQuery, setSearchQuery] = useState('');

  const filtered = useMemo(
    () =>
      items.filter(
        (item) =>
          !searchQuery ||
          getLabelFromItem(item)
            .toLowerCase()
            .includes(searchQuery.toLowerCase()),
      ),
    [items, searchQuery],
  );

  const sortedGroups = useAlphabetGroups(filtered, getLabelFromItem, (a, b) =>
    getLabelFromItem(a).localeCompare(getLabelFromItem(b)),
  );

  const onSelectAll = (selected: boolean) => {
    setSelectedItems(
      selected
        ? items.filter(
            (item) => !disabledItemIds || !disabledItemIds.includes(item.id),
          )
        : [],
    );
  };

  const onSelectItem = (item: T, checked: boolean) => {
    setSelectedItems(
      checked
        ? [...selectedItems, item]
        : selectedItems.filter(({ id }) => id !== item.id),
    );
  };

  const getItemChecked = (item: T) =>
    selectedItems.map(({ id }) => id).includes(item.id) ||
    (disabledItemIds && disabledItemIds.includes(item.id));

  const groupsCount = Object.keys(sortedGroups).length;
  const displaySelectAll = withSearch
    ? !searchQuery && selectAll && groupsCount > 0
    : selectAll;
  const allSelected =
    selectedItems.length ===
    items.filter(
      (item) => !disabledItemIds || !disabledItemIds.includes(item.id),
    ).length;

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-2">
        <div className="secondary-semibold text-neutral-500">
          {items.length}{' '}
          {pluralize(searchEntityLabel ?? 'Entity', items.length)}
        </div>
        <SearchInput
          onChange={(e) => setSearchQuery(e.target.value)}
          placeholder="Search"
          value={searchQuery}
          resetValue={() => setSearchQuery('')}
          size="s"
          {...searchProps}
        />
      </div>
      {groupsCount === 0 && (
        <div className="mb-m mt-l text-center">
          <p>No results found</p>
        </div>
      )}
      <div className="flex flex-col gap-4">
        {displaySelectAll && (
          <div className="ml-1 flex">
            <Checkbox
              checked={allSelected}
              onChange={(e) => onSelectAll(e.target.checked)}
            >
              Select All
            </Checkbox>
          </div>
        )}
        {Object.entries(sortedGroups).map(([group, groupedItems]) => (
          <div key={group} className="flex flex-col items-start gap-4">
            <Badge
              classes={{ value: 'header6-bold sre-badge_dark' }}
              className="header6-bold flex min-h-[24px] min-w-[24px] items-center justify-center rounded-[8px] bg-neutral-700 pt-0.5 text-white"
            >
              {group.toUpperCase()}
            </Badge>
            <div className="ml-1 flex flex-col gap-2">
              {groupedItems.map((item) => (
                <Checkbox
                  key={item.id}
                  labelClassName="ml-xs"
                  checked={getItemChecked(item)}
                  disabled={
                    disabledItemIds && disabledItemIds.includes(item.id)
                  }
                  onChange={(e) => onSelectItem(item, e.target.checked)}
                >
                  {getLabelFromItem(item)}
                </Checkbox>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default GroupedCheckboxes;
