import React, { FC } from 'react';
import { cn } from '@/shared/lib/css/cn';

interface IStatusItemProps {
  type: 'empty' | 'initial' | 'final';
  title: string;
  subTitle: string;
  classes?: {
    title?: string;
    subtitle?: string;
  };
}

const DEFAULT_CLASSES = {
  title: 'secondary-regular text-light-60',
  subtitle: 'label-regular text-light-90',
};

export const StatusItem: FC<IStatusItemProps> = ({
  type,
  title,
  subTitle,
  classes,
}) => (
  <div className="flex-1">
    <div className="mb-0.5 flex items-center gap-1.5">
      <div
        className={cn(classes?.title, {
          [DEFAULT_CLASSES.title]: !classes?.title,
        })}
      >
        {title}
      </div>
      <div className="flex gap-1">
        <div
          className={cn('h-2 w-2 rounded bg-light-10', {
            'bg-blue': type === 'initial',
            'bg-green': type === 'final',
          })}
        />
        <div
          className={cn('h-2 w-2 rounded bg-light-10', {
            'bg-green': type === 'final',
          })}
        />
      </div>
    </div>
    <div
      className={cn(classes?.subtitle, {
        [DEFAULT_CLASSES.subtitle]: !classes?.subtitle,
      })}
    >
      {subTitle}
    </div>
  </div>
);
