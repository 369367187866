import type { ReportSegment } from '@/bundles/Shared/entities/segment/model';
import type { ReportBuilderTemplateDto } from '@/entities/report/reportBuilder/api/settingsReportBuilderTemplatesGeneratedApi';
import { apiClient, requireAuthorizationHeaders } from '@/lib/http';
import { DEFAULT_STRINGIFY_OPTIONS } from '@/lib/stringify';
import { formatToDateStringForRequest } from '@/shared/lib/converters';
import type { IAsset } from '@/types/Asset';
import { stringify } from 'qs';
export * from '../lib/helpers';

const getTemplatesApiPath = (templateId: string) =>
  `/api/settings/report_builder/templates/${templateId}/preview_pdf`;

const getEagleEyeTemplatesApiPath = (templateId: string) =>
  `/api/settings/report_builder/eagle_eye_templates/${templateId}/preview_pdf`;

export const plainPreviewReportTemplatePdf = ({
  templateId,
  assetId,
  date,
  renderPdfHost: render_pdf_host,
  assetIds: asset_ids,
  segmentIds: segment_ids,
  templateKind = 'object_level',
}: {
  date: DateString;
  templateId: string;
  renderPdfHost: string;
  assetIds: IAsset['id'][];
  templateKind?: ReportBuilderTemplateDto['kind'];
  segmentIds?: ReportSegment['id'][];
  assetId?: string;
}) => {
  const path =
    templateKind === 'object_level'
      ? getTemplatesApiPath(templateId)
      : getEagleEyeTemplatesApiPath(templateId);

  const queryParams = stringify(
    {
      date: formatToDateStringForRequest(date),
      asset_id: assetId,
      render_pdf_host,
      asset_ids: templateKind === 'eagle_eye' ? undefined : asset_ids,
      segment_ids: templateKind === 'eagle_eye' ? undefined : segment_ids,
    },
    DEFAULT_STRINGIFY_OPTIONS,
  );

  return apiClient.get<Blob>(`${path}${queryParams}`, {
    responseType: 'blob',
    headers: requireAuthorizationHeaders({}),
  });
};
