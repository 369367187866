import { IDevelopmentBudgetOnIndex } from 'types/DevelopmentBudget';
import { AnyAction } from 'redux';
import {
  IReportBudgetOnIndex,
  TBudgetState,
  TNoBudgetState,
} from 'types/ReportBudget';
import { LegalEntity } from '@/entities/core/legalEntity';
import { ILegalEntitiable } from 'types/LegalEntitiable';
import {
  matchOperationalBudgetsLoaded,
  matchOperationalBudgetsLoading,
  matchOperationalBudgetYearsFilter,
  matchOperationalBudgetYearsLoaded,
} from '@/bundles/REconcile/actions/operationalBudgets';
import {
  BUDGETS_LOADED,
  BUDGETS_LOADING,
} from '@/bundles/REconcile/actions/developmentBudgets';

export type TOperationalBudgetYears = Record<
  string,
  Record<TBudgetState | TNoBudgetState, number[]>
>;

export interface IBudgetsState {
  development: {
    status: 'idle' | 'loading';
    meta?: {
      totalSize: number;
      perPage: number;
    };
    items: IDevelopmentBudgetOnIndex[];
  };
  operational: {
    status: 'idle' | 'loading';
    items: IReportBudgetOnIndex[];
    legalEntities: LegalEntity[];
    legalEntitiables: ILegalEntitiable[];
    years?: {
      items: TOperationalBudgetYears;
      filter: number[];
    };
    meta?: {
      totalSize: number;
      perPage: number;
    };
  };
}

const initialState: IBudgetsState = {
  development: {
    status: 'idle',
    items: [],
  },
  operational: {
    status: 'idle',
    items: [],
    legalEntities: [],
    legalEntitiables: [],
    years: undefined,
  },
};

export default function budgetsReducer(
  state = initialState,
  action: AnyAction,
): IBudgetsState {
  if (matchOperationalBudgetsLoaded(action)) {
    return {
      ...state,
      operational: {
        ...state.operational,
        status: 'idle',
        items: action.payload.budgets,
        meta: action.payload.meta,
      },
    };
  }
  if (matchOperationalBudgetsLoading(action)) {
    return {
      ...state,
      operational: {
        ...state.operational,
        status: 'loading',
      },
    };
  }
  if (matchOperationalBudgetYearsLoaded(action)) {
    return {
      ...state,
      operational: {
        ...state.operational,
        years: {
          items: action.payload.years,
          filter: Object.keys(action.payload.years).map((k) => Number(k)),
        },
        legalEntities: action.payload.legalEntities,
        legalEntitiables: action.payload.legalEntitiables,
      },
    };
  }
  if (matchOperationalBudgetYearsFilter(action)) {
    return {
      ...state,
      operational: {
        ...state.operational,
        years: {
          ...state.operational.years,
          filter: action.payload,
        },
      },
    };
  }
  switch (action.type) {
    case BUDGETS_LOADING: {
      return {
        ...state,
        development: {
          ...state.development,
          status: 'loading',
        },
      };
    }
    case BUDGETS_LOADED: {
      return {
        ...state,
        development: {
          ...state.development,
          status: 'idle',
          items: action.payload.budgets,
          meta: action.payload.meta,
        },
      };
    }
    default:
      return state;
  }
}
