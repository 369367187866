import { emptySplitApi as api } from '@/app/stores/api';

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiPropertyManagementCompanies: build.query<
      GetApiPropertyManagementCompaniesApiResponse,
      GetApiPropertyManagementCompaniesApiArg
    >({
      query: () => ({ url: '/api/property_management_companies' }),
    }),
    putApiPropertyManagementCompanies: build.mutation<
      PutApiPropertyManagementCompaniesApiResponse,
      PutApiPropertyManagementCompaniesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/property_management_companies/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as settingPropertyManagementCompanyApi };
export type GetApiPropertyManagementCompaniesApiResponse =
  /** status 200 List of property management companies */ {
    items?: {
      id: number;
      name: string;
    }[];
  };
export type GetApiPropertyManagementCompaniesApiArg = void;

export type PutApiPropertyManagementCompaniesApiResponse = {
  id: number;
  name: string;
  contactPerson: {
    id: number;
    name: string;
    role: string;
    email: string;
    phone: string;
  };
  keyInfo: {
    id: number;
    address: string;
    site: string;
    email: string;
    phone: string;
  };
};

export type PutApiPropertyManagementCompaniesApiArg = {
  id: number;
  body: {
    name: string;
    contact_persons_attributes: {
      name: string;
      role: string;
      email: string;
      phone: string;
      id?: number;
      _destroy?: boolean | undefined;
    }[];
    key_info_attributes: {
      address: string;
      site: string;
      email: string;
      phone: string;
    };
  };
};
export const {
  useGetApiPropertyManagementCompaniesQuery,
  usePutApiPropertyManagementCompaniesMutation,
} = injectedRtkApi;
