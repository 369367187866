import React, { useRef } from 'react';
import MainMenuItem from 'bundles/Shared/components/LeftSidebar/items/MainMenuItem';
import NotificationsModal from 'bundles/Shared/components/Nav/Modals/NotificationsModal';
import NotificationsCard from 'bundles/Shared/components/LeftSidebar/cards/NotificationsCard';
import { useModal } from '@/shared/lib/hooks/useModal';
import MenuItemPopover from 'bundles/Shared/components/LeftSidebar/items/MenuItemPopover';
import { usePopoverTriggered } from 'bundles/Shared/components/LeftSidebar/hooks/usePopoverTriggered';
import { Badge } from 'stories/Badges/Badge/Badge';
import { Icon } from 'stories/Icon/Icon';
import { cn } from '@/shared/lib/css/cn';
import { useHoverSaveArea } from 'bundles/Shared/components/LeftSidebar/hooks/useHoverSaveArea';
import { PopoverRef } from 'stories/Popover/Popover';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import useMediaQuery, {
  MEDIUM_WIDTH_MEDIA_QUERY,
} from '@/shared/lib/hooks/useMediaQuery';
import { collapseSidebar } from 'bundles/Shared/components/LeftSidebar/reducers/leftSidebarSlice';

function NotificationsMenuItem() {
  const dispatch = useAppDispatch();
  const isMediumWidth = useMediaQuery(MEDIUM_WIDTH_MEDIA_QUERY);
  const notifications = useAppSelector((state) => state.notifications.unread);
  const { openModal } = useModal();
  const menuItemRef = useRef<HTMLAnchorElement>(null);
  const popoverRef = useRef<PopoverRef>();
  const { classes: hoverClasses, onMouseMove } = useHoverSaveArea();
  const { triggered, ...handlers } = usePopoverTriggered();

  const label = (
    <div className="flex justify-between items-center">
      Notifications
      {notifications && notifications.length > 0 && (
        <Badge backgroundColor="var(--ye)" className="mr-s">
          +{notifications.length}
        </Badge>
      )}
    </div>
  );

  return (
    <>
      <MainMenuItem
        onMouseMove={onMouseMove}
        classes={{
          label: 'flex-grow',
        }}
        className={cn(
          triggered && 'left-sidebar__main-menu-item_hovered',
          hoverClasses,
        )}
        ref={menuItemRef}
        label={label}
        iconName="bell"
        path="/notifications"
        rightIcon={notifications && <Icon iconName="arrowRight" />}
        onClick={() => openModal(NotificationsModal, {})}
      />
      {isMediumWidth && notifications && (
        <MenuItemPopover
          {...handlers}
          ref={popoverRef}
          reference={menuItemRef}
          template={
            <NotificationsCard
              onAction={() => {
                popoverRef.current?.hide();
                dispatch(collapseSidebar());
              }}
            />
          }
          placement="right"
          maxWidth="none"
          style={{
            width: '21.25rem',
          }}
        />
      )}
    </>
  );
}

export default NotificationsMenuItem;
