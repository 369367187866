import React from 'react';
import { MultilineAlert } from 'stories/MultilineAlert/MultilineAlert';

const ReallocationWarning = () => (
  <MultilineAlert
    title="Be Aware"
    message="Hard Cost Contingency reallocations should likely be addressed via the Change Order workflow"
  />
);

export default ReallocationWarning;
