import { HUGE_MODAL_Z_INDEX } from '@/bundles/Shared/constants';
import { CapitalInvestment } from '@/entities/return';
import {
  InvestmentIndex,
  InvestmentObjectPreferredReturn,
  PostApiCapitalInvestmentDistributionsBulkApiArg,
  SplitItem,
} from '@/entities/return/api/capitalInvestmentObjectsGeneratedApi';
import {
  DISTRIBUTION_KINDS,
  DistributionKind,
} from 'bundles/REturn/actions/types';
import EnterDistributionsStep, {
  calculatePreferred,
  StepData,
} from 'bundles/REturn/components/Ownership/modals/addDistributionsModal/EnterDistributionsStep';
import { Step3Distributions } from 'bundles/REturn/components/Ownership/modals/addDistributionsModal/Step3Distributions';
import {
  BulkDistributionContext,
  useBulkDistribution,
} from 'bundles/REturn/components/Ownership/modals/addDistributionsModal/hooks/useBulkDistribution';
import { ReturnRawTableEditor } from 'bundles/REturn/components/Ownership/modals/capitalInvestmentsEditor/ReturnRawTableEditor';
import SelectInvestmentEntity from 'bundles/REturn/components/Ownership/selectInvestmentEntity/SelectInvestmentEntity';
import { IColumn } from 'bundles/Shared/components/Table/types';
import {
  formatDate,
  formatDateToQuarterWithPrefix,
} from '@/shared/lib/formatting/dates';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import { numDiff, parseIntOrFloat } from '@/shared/lib/formatting/number';
import { asserts, assertsType } from 'lib/typeHelpers/assertsType';
import { capitalize, omit, range, startCase, sum, uniqueId } from 'lodash-es';
import pluralize from 'pluralize';
import React, { useMemo, useState } from 'react';
import { Button } from 'stories/Button/Button';
import { CurrencyFormatter } from 'stories/ValueFormatters/CurrencyFormatter';
import { Modal } from 'stories/Modals/Modal/Modal';
import { ModalActions } from 'stories/Modals/Modal/ModalActions';
import { ModalHeaderWithSubtitle } from 'stories/Modals/ModalHeader/ModalHeaderWithSubtitle/ModalHeaderWithSubtitle';
import { PseudoLink } from 'stories/PseudoLink/PseudoLink';
import { convertDollarsToCents } from '@/shared/lib/converters';
import { DEFAULT_STRING_FALLBACK } from '@/shared/lib/formatting/fallbacks';

interface Props
  extends DialogProps<PostApiCapitalInvestmentDistributionsBulkApiArg['body']> {
  capitalInvestments: InvestmentIndex[];
  preferredReturn?: InvestmentObjectPreferredReturn;
}

export const DISTRIBUTION_LABEL_MAPPINGS = {
  preferred: 'Pref. Payment',
  excess_cash: 'Excess Cash',
  refinance: 'Refinance',
  sale: 'Sale',
  liquidation: 'Liquidation',
  return_of_capital: 'Return of Capital',
} as const satisfies Record<DistributionKind, string>;

export const DISTRIBUTION_CATEGORIES_OPTIONS = DISTRIBUTION_KINDS.map((c) => {
  return {
    id: c,
    label: DISTRIBUTION_LABEL_MAPPINGS[c] || startCase(capitalize(c)),
  };
});

export type RemainingDistribution = {
  id: string;
  kind: DistributionKind | null;
  amount: number;
};

export type DistributionRecordItem = {
  amount: number;
  valid: boolean;
  diff?: number;
  currentTotalAmount?: number;
  expectedTotalAmount?: number;
};

export type SelectedInvestmentEntity = CapitalInvestment & {
  _remainingDistributionRecord: Partial<
    Record<DistributionKind, DistributionRecordItem>
  >;
  note?: string;
};

const AddDistributionsModal = ({
  onClose,
  onSubmit,
  capitalInvestments,
  preferredReturn,
}: Props) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedInvestmentEntities, setSelectedInvestmentEntities] = useState<
    InvestmentIndex[]
  >([]);
  const [step2Valid, setStep2Valid] = useState(false);
  const [step2Data, setStep2Data] = useState<StepData>({
    date: null,
    period: null,
    periodType: 'quarterly',
    preferred: 0,
    total: 0,
    remaining: 0,
    remainingDistribution: [],
  });
  const bulkDistribution = useBulkDistribution();

  const step1Content = useMemo(
    () => (
      <SelectInvestmentEntity
        capitalInvestments={capitalInvestments}
        selectedItems={selectedInvestmentEntities}
        setSelectedItems={(selectedItems) => {
          setSelectedInvestmentEntities(selectedItems);
        }}
      />
    ),
    [selectedInvestmentEntities],
  );

  const step2Content = useMemo(
    () => (
      <>
        <div className="flex flex-col items-start gap-1 px-6 py-4 bg-white border-b border-neutral-150">
          <p className="uppercase secondary-regular text-neutral-500">
            Investment Entity
          </p>
          <PseudoLink className="inline-regular text-neutral-850">
            {selectedInvestmentEntities
              .slice(0, 5)
              .map((inv) => inv.investmentEntity.name)
              .join(', ')}
          </PseudoLink>
        </div>
        <EnterDistributionsStep
          closeModal={onClose}
          data={step2Data}
          setData={setStep2Data}
          onValidChange={setStep2Valid}
          selectedCapitalInvestments={selectedInvestmentEntities}
        />
      </>
    ),
    [step2Data, selectedInvestmentEntities],
  );

  const [updatedCapitalInvestmentEntities, setUpdateInvestmentEntities] =
    useState<SelectedInvestmentEntity[]>([]);

  const investmentEntitiesWithDistributions = useMemo<
    SelectedInvestmentEntity[]
  >(() => {
    const { length } = selectedInvestmentEntities;
    const { remainingDistribution } = step2Data;

    type DistributionKindEntry = [DistributionKind, DistributionRecordItem[]];

    const totalRemainingEntries = Object.fromEntries(
      remainingDistribution.map(
        (item) =>
          [item.kind, item] as [
            DistributionKind,
            NonNullish<RemainingDistribution>,
          ],
      ),
    );

    const getRemainingEntries = (inv: InvestmentIndex) =>
      remainingDistribution.map(
        (item) =>
          [
            item.kind,
            range(length).map(() => ({
              amount: parseIntOrFloat(
                (item.amount / 100) * parseIntOrFloat(Number(inv.interest)),
              ),
              valid: false,
            })),
          ] satisfies DistributionKindEntry,
      );

    const { preferred: totalPreferred, periodType } = step2Data;

    const getPreferredEntry = (inv: InvestmentIndex): DistributionKindEntry => {
      return [
        'preferred',
        Array.from({ length }, () => {
          const classAPercent = preferredReturn?.classAPercent ?? 0;
          const classBPercent = preferredReturn?.classBPercent ?? 0;

          const amount = calculatePreferred(
            periodType,
            classAPercent,
            classBPercent,
            [inv],
          );

          return {
            amount,
            valid: false,
            expectedTotalAmount: totalPreferred,
          };
        }),
      ];
    };

    const getAllEntries = (inv: InvestmentIndex) => {
      const remainingEntries = getRemainingEntries(inv);

      return totalPreferred > 0
        ? [getPreferredEntry(inv), ...remainingEntries]
        : remainingEntries;
    };

    const resWithAmounts = selectedInvestmentEntities.map((item, idx) => ({
      ...item,
      _remainingDistributionRecord: Object.fromEntries(
        getAllEntries(item).map(([k, amounts]) => [k, amounts[idx]]),
      ),
    })) satisfies SelectedInvestmentEntity[];

    const currentTotalPreferred = parseIntOrFloat(
      resWithAmounts.reduce(
        (acc, curr) =>
          acc + curr._remainingDistributionRecord?.preferred?.amount || 0,
        0,
      ),
    );

    const preferredMatches = currentTotalPreferred === totalPreferred;

    const currentTotalRemaining = Object.fromEntries(
      remainingDistribution.map((item) => [
        item.kind,
        parseIntOrFloat(
          sum(
            resWithAmounts.map(
              (inv) =>
                inv._remainingDistributionRecord?.[item.kind]?.amount ?? 0,
            ),
          ),
        ),
      ]),
    );

    const resWithValidation = resWithAmounts.map((item) => ({
      ...item,
      _remainingDistributionRecord: Object.fromEntries(
        Object.entries(item._remainingDistributionRecord ?? {}).map(
          ([kind, distroRecord]) => {
            if (kind === 'preferred') {
              return [
                kind,
                {
                  ...distroRecord,
                  diff: parseIntOrFloat(
                    numDiff(totalPreferred, currentTotalPreferred),
                  ),
                  currentTotalAmount: currentTotalPreferred,
                  expectedTotalAmount: totalPreferred,
                  valid: preferredMatches,
                },
              ];
            }
            const valid =
              currentTotalRemaining[kind] ===
              totalRemainingEntries[kind].amount;

            const expectedTotalAmount = totalRemainingEntries[kind].amount;
            const currentTotalAmount = currentTotalRemaining[kind];

            return [
              kind,
              {
                ...distroRecord,
                diff: parseIntOrFloat(
                  numDiff(expectedTotalAmount, currentTotalAmount),
                ),
                currentTotalAmount,
                expectedTotalAmount,
                valid,
              },
            ];
          },
        ),
      ),
    }));

    const updatedCapitalInvestmentEntitiesMap = new Map(
      updatedCapitalInvestmentEntities.map((ci) => [ci.id, ci]),
    );

    return resWithValidation.map((item) => ({
      ...item,
      note: updatedCapitalInvestmentEntitiesMap.get(item.id)?.note,
      _remainingDistributionRecord:
        updatedCapitalInvestmentEntitiesMap.get(item.id)
          ?._remainingDistributionRecord ?? item._remainingDistributionRecord,
    })) as SelectedInvestmentEntity[];
  }, [selectedInvestmentEntities, step2Data, updatedCapitalInvestmentEntities]);

  const isStep3Invalid = useMemo(() => {
    const res = updatedCapitalInvestmentEntities.some((inv) => {
      const entries = Object.entries(inv._remainingDistributionRecord ?? {});
      const hasInvalid = entries.some(([, { valid }]) => valid === false);
      return hasInvalid;
    });

    return res;
  }, [updatedCapitalInvestmentEntities]);

  const step3Content = (
    <Step3Distributions
      step2Data={step2Data}
      investmentEntitiesWithDistributions={investmentEntitiesWithDistributions}
      setUpdateInvestmentEntities={setUpdateInvestmentEntities}
    />
  );

  const hasUpdatedPreferred = step2Data.preferred !== 0;

  const fixedTotalPreferred = useMemo(() => {
    if (!hasUpdatedPreferred) return [];

    return [
      {
        id: 'preferred',
        text: <CurrencyFormatter value={step2Data.preferred} />,
      },
    ];
  }, [step2Data.preferred]);

  const step4Content = (
    <div className="flex flex-col gap-6 px-6 pt-4 pb-6">
      <div className="flex flex-col gap-2">
        <p className="body-semibold text-neutral-900">
          Review Action
          <span className="text-danger-055"> *</span>
        </p>
        <p className="inline-regular text-neutral-650">
          Please review the distribution amounts below before creating the
          distribution
        </p>
      </div>
      <div className="flex gap-10 px-6 py-4 bg-white rounded-lg">
        <div className="flex w-[120px] flex-col gap-1">
          <p className="uppercase secondary-regular text-neutral-500">Date</p>
          <p className="inline-regular text-neutral-850">
            {formatDate(step2Data.date as DateString, 'MMM DD, YYYY')}
          </p>
        </div>
        <div className="flex w-[120px] flex-col gap-1">
          <p className="uppercase secondary-regular text-neutral-500">Period</p>
          <p className="inline-regular text-neutral-850">
            {step2Data.period &&
              step2Data.periodType === 'quarterly' &&
              formatDateToQuarterWithPrefix(step2Data.period)}
            {step2Data.periodType === 'monthly' && (
              <>{formatDate(step2Data.period as DateString, 'MMM-YYYY')}</>
            )}
          </p>
        </div>
        <div className="flex w-[120px] flex-col gap-1">
          <p className="uppercase secondary-regular text-neutral-500">
            Total Distribution
          </p>
          <p className="inline-regular text-neutral-850">
            <CurrencyFormatter value={step2Data.total} />
          </p>
        </div>
      </div>
      <ReturnRawTableEditor
        columns={[
          {
            text: 'Investment Entity',
            dataField: 'investmentEntity.name',
            formatter: ({ row: item }) => (
              <span className="dark-60 inline-regular">
                {item.investmentEntity.name}
              </span>
            ),
          },
          {
            text: 'Legal Entity',
            dataField: 'legalEntity.name',
            formatter: ({ row: item }) => (
              <span className="dark-60 inline-regular">
                {item.legalEntity.name}
              </span>
            ),
          },
          {
            text: 'Contribution',
            dataField: 'totalContributions',
            formatter: ({ row: item }) => (
              <CurrencyFormatter value={item.totalContributionsDollars} />
            ),
          },
          {
            text: 'Note',
            dataField: 'note',
            formatter: ({ row }) => (
              <span className="dark-60 inline-regular">
                {row.note ?? DEFAULT_STRING_FALLBACK}
              </span>
            ),
          },
          {
            text: DISTRIBUTION_LABEL_MAPPINGS.preferred,
            dataField: 'preferred',
            hidden: !hasUpdatedPreferred,
            formatter: ({ row }) => (
              <CurrencyFormatter
                value={row._remainingDistributionRecord?.preferred?.amount}
              />
            ),
          },
          ...step2Data.remainingDistribution.map(
            (item) =>
              ({
                text: startCase(capitalize(item.kind)),
                dataField: item.id,
                formatter: ({ row }) => (
                  <CurrencyFormatter
                    value={
                      row._remainingDistributionRecord?.[item.kind]?.amount
                    }
                  />
                ),
              }) satisfies IColumn<SelectedInvestmentEntity>,
          ),
        ]}
        items={updatedCapitalInvestmentEntities}
        bottomRowColumns={[
          {
            id: 'total',
            text: 'Total',
            className: 'inline-semibold',
          },
          {
            id: 'empty-0',
          },
          {
            id: 'empty-1',
          },
          ...fixedTotalPreferred,
          ...step2Data.remainingDistribution.map((item) => ({
            id: uniqueId('total_'),
            text: <CurrencyFormatter value={item.amount} />,
          })),
        ]}
      />
    </div>
  );

  const steps: Record<string, React.JSX.Element> = {
    1: step1Content,
    2: step2Content,
    3: step3Content,
    4: step4Content,
  };

  const stepsLength = Object.keys(steps).length;

  const stepsValid: Record<string, boolean> = {
    1: selectedInvestmentEntities.length !== 0,
    2: step2Valid,
    3: !isStep3Invalid,
    4: true,
  };

  const pluralizedSelectedInvestmentEntities = `${
    selectedInvestmentEntities.length
  } ${pluralize('Investment Entity', selectedInvestmentEntities.length)}`;
  const stepsSubmitText: Record<string, string> = {
    1: `Continue ${
      selectedInvestmentEntities.length > 0
        ? `with ${pluralizedSelectedInvestmentEntities}`
        : ''
    }`,
    2: 'Next',
    3: 'Next',
    4: 'Create Distribution',
  };

  const groupDistributionWithSplitItems = (
    data: PostApiCapitalInvestmentDistributionsBulkApiArg['body'],
  ): PostApiCapitalInvestmentDistributionsBulkApiArg['body'] => {
    const groupedDataMap = new Map<
      number,
      PostApiCapitalInvestmentDistributionsBulkApiArg['body'][number]
    >();

    data.forEach((record) => {
      const investmentId = record.investment_id;
      if (!groupedDataMap.has(investmentId)) {
        groupedDataMap.set(investmentId, {
          investment_id: investmentId,
          amount: 0,
          kind: record.kind,
          period: record.period,
          period_type: record.period_type,
          date: record.date,
          note: record.note,
          split_items: [],
        });
      }

      const group = groupedDataMap.get(investmentId)!;

      group.amount += record.amount;

      assertsType<SplitItem[number]['kind']>(
        record.kind,
        (i) => i !== 'split_items',
      );

      group.split_items?.push({
        amount_cents: convertDollarsToCents(record.amount),
        kind: record.kind,
      });
    });

    groupedDataMap.forEach((group, key) => {
      if (group.split_items?.length === 1) {
        const omitSplitItems = omit(group, 'split_items');
        groupedDataMap.set(key, omitSplitItems);
      } else {
        const newGroup = {
          ...group,
          kind: 'split_items',
        } satisfies typeof group;
        groupedDataMap.set(key, newGroup);
      }
    });

    return Array.from(groupedDataMap.values());
  };

  const handleSubmit = () => {
    if (currentStep !== stepsLength) {
      setCurrentStep(currentStep + 1);
      return;
    }
    const { date, period, periodType } = step2Data;

    asserts(period);
    asserts(date);

    const res = updatedCapitalInvestmentEntities.flatMap((item) =>
      Object.entries(item._remainingDistributionRecord ?? [])
        .filter(([, { amount }]) => amount > 0)
        .map(
          ([kind, { amount }]: [
            DistributionKind,
            { amount: number; valid: boolean },
          ]) =>
            ({
              investment_id: item.id,
              amount,
              kind,
              period,
              period_type: periodType,
              date,
              note: item.note,
            }) satisfies PostApiCapitalInvestmentDistributionsBulkApiArg['body'][number],
        ),
    ) satisfies PostApiCapitalInvestmentDistributionsBulkApiArg['body'];

    onSubmit?.(groupDistributionWithSplitItems(res));
  };

  return (
    <Modal
      toggle={() => onClose()}
      size="1000"
      classes={{
        body: '!p-0',
      }}
      bodyHeight={600}
      zIndex={HUGE_MODAL_Z_INDEX + 2}
      header={
        <ModalHeaderWithSubtitle
          title="Bulk Adding of Distributions Values"
          subtitle={`Step ${currentStep} of ${stepsLength}`}
          order="subtitle-title"
        />
      }
      actions={
        <ModalActions alignItems="space-between">
          <Button
            variant="secondary"
            onClick={
              currentStep === 1
                ? onClose
                : () => setCurrentStep(currentStep - 1)
            }
          >
            {currentStep === 1 ? 'Cancel' : 'Back to Selection'}
          </Button>
          <Button
            variant="success"
            onClick={handleSubmit}
            disabled={!stepsValid[currentStep]}
          >
            {stepsSubmitText[currentStep]}
          </Button>
        </ModalActions>
      }
    >
      <BulkDistributionContext.Provider value={bulkDistribution}>
        {steps[currentStep]}
      </BulkDistributionContext.Provider>
    </Modal>
  );
};

export default AddDistributionsModal;
