import React, { PropsWithChildren } from 'react';
import { cn } from '@/shared/lib/css/cn';

type Props = PropsWithChildren &
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export function CardWrapper({ children, className, ...divProps }: Props) {
  return (
    <div
      {...divProps}
      className={cn(
        'flex flex-col rounded-[16px] border border-black/[0.125] bg-white',
        className,
      )}
    >
      {children}
    </div>
  );
}
