import { cn } from '@/shared/lib/css/cn';
import { yupResolver } from '@hookform/resolvers/yup';
import { navigate, useLocation } from '@reach/router';
import LoadingPanel from 'bundles/Shared/components/LoadingPanel';
import { login } from 'lib/currentUser';
import { apiClient } from 'lib/http';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import yupPassword from 'yup-password';
import PasswordFields from '@/bundles/Registration/PasswordFields';
import { Button } from '@/stories/Button/Button';
import { PASSWORD_VALIDATION_SCHEMA } from '@/bundles/Registration/ResetPassword';

yupPassword(yup);

const REGISTRATION_SCHEMA = PASSWORD_VALIDATION_SCHEMA.shape({
  firstName: yup.string().required('Complete field to proceed!'),
  lastName: yup.string().required('Complete field to proceed!'),
  agreed: yup.boolean().oneOf([true], 'Review and accept to proceed!'),
});

const Registration = () => {
  const params = new URLSearchParams(useLocation().search);
  const [loading, setLoading] = useState(true);

  function onSubmit(data) {
    const body = {
      user: {
        password: data.password,
        password_confirmation: data.password_confirmation,
        first_name: data.firstName,
        last_name: data.lastName,
        invitation_token: params.get('invitation_token'),
      },
    };
    apiClient.put('/users/invitation', body).then(({ data: response }) => {
      if (response.errors) {
        window.toastr.error(response.errors);
      } else {
        login(response.token);
        window.location.href = '/';
        window.toastr.success(response.message);
      }
    });
  }

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(REGISTRATION_SCHEMA),
    criteriaMode: 'all',
    mode: 'onChange',
  });

  useEffect(() => {
    const queryObj = { invitation_token: params.get('invitation_token') };
    apiClient('/users/invitation', { params: queryObj })
      .then(({ data }) => {
        setLoading(false);
        setValue('firstName', data.firstName);
        setValue('lastName', data.lastName);
      })
      .catch(() => {
        toastr.info(
          'Your account was already activated, please try signing in',
        );
        navigate('/');
      });
  }, []);

  if (loading) return <LoadingPanel />;

  return (
    <div>
      <h1 className="auth__header"> Set up your password </h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group auth__form-group">
          <label className="auth__label">
            First name
            <span className="red"> *</span>
            <input
              type="text"
              {...register('firstName')}
              className={cn('form-control auth__input', {
                'is-invalid': errors.firstName,
              })}
              placeholder="Enter first name"
            />
            <div className="invalid-feedback">{errors.firstName?.message}</div>
          </label>
        </div>
        <div className="form-group auth__form-group">
          <label className="auth__label">
            Last name
            <span className="red"> *</span>
            <input
              className={cn('form-control auth__input', {
                'is-invalid': errors.lastName,
              })}
              {...register('lastName')}
              type="text"
              placeholder="Enter last name"
            />
            <div className="invalid-feedback">{errors.lastName?.message}</div>
          </label>
        </div>
        {/* by default chrome adding email/password autocomplete for the first password field */}
        {/* and for the previous field (which is last name) */}
        {/* so this hidden field will prevent suggesting autocompletion for the last name */}
        <input type="text" style={{ position: 'absolute', top: -1000 }} />
        <PasswordFields
          errors={errors}
          watch={watch}
          register={register}
          getValues={getValues}
        />
        <div className="custom-control checkbox mb-[2rem] pl-0">
          <input
            className={cn('custom-checkbox', { 'is-invalid': errors.agreed })}
            id="disclaimer"
            type="checkbox"
            {...register('agreed')}
          />
          <label htmlFor="disclaimer" className="flex items-center gap-2">
            <div className="auth__disclaimer">
              I agree to the{' '}
              <a
                href="https://symmetre.com/terms-of-service"
                target="_blank"
                rel="noreferrer"
                className="sre-link"
              >
                Terms of Service
              </a>
              {' and '}
              <a
                href="https://symmetre.com/privacy-policy"
                target="_blank"
                rel="noreferrer"
                className="sre-link"
              >
                Privacy Policy
              </a>
            </div>
          </label>
          <div className="invalid-feedback">{errors.agreed?.message}</div>
        </div>
        <Button
          variant="success"
          type="submit"
          size="l"
          className="mb-4 w-full"
        >
          Let's Get Started!
        </Button>
      </form>
    </div>
  );
};

export default Registration;
