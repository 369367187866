import React from 'react';
import { updateWorkflowReconcileSettings } from 'bundles/REconcile/reducers/developmentLegalEntitySlice';
import { IUpdateWorkflowReconcileSettings } from 'bundles/REconcile/actions/workflowConfig';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import { cn } from '@/shared/lib/css/cn';
import { Tooltip } from 'stories/Tooltip/Tooltip';
import { Tumbler } from 'stories/Tumbler/Tumbler';
import ThresholdTypeCard from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/workflow/checkboxes/cards/ThresholdTypeCard';
import {
  THRESHOLD_TYPE_CARDS_PROPS,
  TOOLTIP_TEXT,
  TUMBLER_PROPS,
} from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/workflow/checkboxes/constants';

const Column = ({
  header,
  children,
  className,
}: React.PropsWithChildren<{
  header: string;
  className?: string;
}>) => (
  <div className={cn('flex flex-col gap-m bg-white px-l pb-l pt-m', className)}>
    <h6 className="light-90 header6-regular">{header}</h6>
    {children}
  </div>
);

interface Props {
  legalEntityId: string;
}

function ChangeEventWorkflowSettings({ legalEntityId }: Props) {
  const dispatch = useAppDispatch();
  const workflowSettings = useAppSelector(
    (state) => state.developmentLegalEntity.workflow,
  );

  const getChangeHandler =
    (key: keyof IUpdateWorkflowReconcileSettings) => (e) => {
      dispatch(
        updateWorkflowReconcileSettings({
          legalEntityId,
          [key]: e.target.checked,
        }),
      );
    };

  const getCorApprovalRequiredDisabled = () =>
    workflowSettings?.timingImpactThresholdEnabled ||
    workflowSettings?.contingencyThresholdEnabled ||
    workflowSettings?.individualCorAmountThresholdEnabled;

  return (
    <div
      style={{
        gridTemplateColumns: '1fr 1fr 2fr',
        gap: '1px',
      }}
      className="rounded-[1rem] mb-l grid bg-light-10"
    >
      <Column header="Auto e-Signature placement">
        <div className="flex flex-col gap-s">
          <Tumbler
            {...TUMBLER_PROPS}
            onChange={getChangeHandler('enable_cor_esignature_placement')}
            checked={workflowSettings?.eSignatureCorDocsEnabled}
          >
            Change Order Event Docs
          </Tumbler>
        </div>
      </Column>
      <Column header="Require Approval Workflows">
        <div className="flex flex-col gap-s">
          <Tooltip
            disabled={!getCorApprovalRequiredDisabled()}
            mainText={TOOLTIP_TEXT}
            arrowPosition="start"
            placement="top-start"
          >
            <Tumbler
              {...TUMBLER_PROPS}
              disabled={getCorApprovalRequiredDisabled()}
              checked={workflowSettings?.corApprovalRequired}
              onChange={() =>
                dispatch(
                  updateWorkflowReconcileSettings({
                    enable_cor_approval_requirement:
                      !workflowSettings?.corApprovalRequired,
                    enable_estimated_timing_impact_threshold: false,
                    enable_aggregate_contingency_threshold: false,
                    enable_individual_cor_amount_threshold: false,
                    legalEntityId,
                  }),
                )
              }
            >
              for Change Order Events
            </Tumbler>
          </Tooltip>
        </div>
      </Column>
      <Column className="flex-grow" header="Threshold Types">
        <div
          style={{
            gridTemplateColumns: '1fr 1fr 1fr',
          }}
          className="items-start grid gap-s"
        >
          {THRESHOLD_TYPE_CARDS_PROPS.map((cardProps) => (
            <ThresholdTypeCard
              {...cardProps}
              key={cardProps.valueKey}
              onChange={getChangeHandler(cardProps.updateKey)}
            />
          ))}
        </div>
      </Column>
    </div>
  );
}

export default ChangeEventWorkflowSettings;
