import { usePostApiSettingsReportThirdPartyExtractionsConnectionsByConnectionIdManualUploadsMutation } from '@/shared/api/settingsReportConnectionsEnhancedApi';
import { useLazyGetApiCacheFileStoragePresignQuery } from '@/entities/sharedFile/api/rootCacheFileStorageGeneratedApi';
import { useModal } from '@/shared/lib/hooks/useModal';
import { ExtractParams, ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import { useParams } from '@reach/router';
import UploadFilesModal, {
  RequestFile,
  ResponsePresignFile,
  SubmitFile,
} from 'bundles/Shared/shared/UploadFilesModal';
import { ComponentProps } from 'react';
import { Button } from 'stories/Button/Button';

const { fullPath: ROUTE } = ROUTES_ROOT.settings.report.connections.connection;

export const UploadManualExtractions = () => {
  const params = useParams<ExtractParams<typeof ROUTE>>();
  const { openModal } = useModal();

  const [uploadManualExtractions] =
    usePostApiSettingsReportThirdPartyExtractionsConnectionsByConnectionIdManualUploadsMutation();
  const [uploadManualExtractionsPresign] =
    useLazyGetApiCacheFileStoragePresignQuery();

  const onUpload = (files: { documents: RequestFile[] }) => {
    uploadManualExtractions({
      connectionId: Number(params.connectionId),
      body: {
        files: files.documents,
      },
    });
  };

  const customPresignFile: ComponentProps<
    typeof UploadFilesModal
  >['customPresignFile'] = async (file: SubmitFile) => {
    try {
      const res = await uploadManualExtractionsPresign({
        filename: file.name,
        type: file.type,
      });
      return {
        file,
        signedData: res.data as ResponsePresignFile['signedData'],
      };
    } catch (e) {
      throw new Error(e);
    }
  };

  const showUploadFileModal = () => {
    openModal(UploadFilesModal, {
      onUpload,
      customPresignFile,
    });
  };

  return (
    <div>
      <Button
        variant="primary"
        iconName="upload"
        size="s"
        onClick={showUploadFileModal}
      >
        Upload File
      </Button>
    </div>
  );
};
