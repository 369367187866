import { snakeCase } from 'lodash-es';
import React, { useMemo } from 'react';
import { accountingNumberFormat } from '@/shared/lib/formatting/number';
import { Icon } from 'stories/Icon/Icon';
import { Tooltip } from 'stories/Tooltip/Tooltip';

export const reallocationNumberFormatter = ({ number, status }) => {
  if (!number) return '';
  return ['PENDING', 'DRAFT'].includes(status) ? `P-${number}` : number;
};

export const requiredBudgetTypesSpecified = (assetConfig) =>
  assetConfig.hardConstructionCostCode &&
  assetConfig.hardCostContingencyCode &&
  assetConfig.softCostContingencyCode;

export const coeAmountFormatter = ({
  id,
  verified,
  status,
  probability,
  value,
  weightedAmount,
}: {
  id: string;
  verified: boolean;
  status: string;
  probability: number;
  value: number;
  weightedAmount: number;
}) => {
  const valueBlock = () => {
    if (['VOID'].includes(status)) {
      return <p>{accountingNumberFormat('0')}</p>;
    }
    if (
      ['COMPLETED', 'APPROVED'].includes(status) ||
      probability === null ||
      probability === 100
    ) {
      return accountingNumberFormat(value);
    }
    return (
      <>
        <p>{accountingNumberFormat(weightedAmount)}</p>
        <small className="italic">
          {accountingNumberFormat(value)} ({probability}% weight)
        </small>
      </>
    );
  };

  if (verified) {
    return (
      <span className="flex items-center justify-end">
        <Tooltip mainText="Verified">
          <Icon
            iconName="info"
            className="mr-1.5"
            data-for={`verify-amount-${id}`}
          />
        </Tooltip>
        {accountingNumberFormat(['VOID'].includes(status) ? '0' : value)}
      </span>
    );
  }

  return <p>{valueBlock()}</p>;
};

export const coeWeightedAmountFormatter = ({
  probability,
  amount,
  weightedAmount,
  verified,
}: {
  probability: number;
  amount: number;
  weightedAmount: number;
  verified: boolean;
}) =>
  accountingNumberFormat(
    (!probability || verified ? amount : weightedAmount) || '0',
  );

export const snakeCaseKeys = (record) =>
  Object.fromEntries(Object.entries(record).map(([k, v]) => [snakeCase(k), v]));

export function useGroupedFilteredItems(enterArr: any[] = []) {
  return useMemo(() => {
    const separator = new Set(enterArr.map((elem) => elem.category));
    const result = [];
    separator.forEach((separateItem) => {
      const separateItems = enterArr.filter(
        (elem) => elem.category === separateItem,
      );
      result.push({
        category: separateItem,
        values: separateItems.map((item) => ({
          label: item.name,
          id: item.id,
        })),
      });
    });
    return result;
  }, [enterArr]);
}
