import { AccountingTransactionType } from '@/entities/accounting/transaction/model';

export const TRANSACTION_TYPE_LABELS: Record<
  AccountingTransactionType | 'all',
  string
> = {
  all: 'All',
  [AccountingTransactionType.DEBIT]: 'Debit',
  [AccountingTransactionType.CREDIT]: 'Credit',
} as const;
export const TRANSACTION_TYPE_COLORS: Record<
  AccountingTransactionType,
  string
> = {
  [AccountingTransactionType.CREDIT]: 'var(--red)',
  [AccountingTransactionType.DEBIT]: 'var(--green)',
};
export const CLASS_NAMES_BY_TRANSACTION_TYPE = {
  credit: 'bg-red-light-5 text-red',
  debit: 'bg-green-light-5 text-green',
} as const;
