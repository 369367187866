import type { RRule } from 'rrule';
import type { Variable } from '@/bundles/Settings/components/EmailSettings/editor/types';

export const getFailedEmailMsgs = (emailMessages) => {
  const filteredMsgs = emailMessages.filter(
    ({ events }) => events.length > 0 && events.at(-1).kind === 'failed',
  );
  return filteredMsgs;
};

export const filterUnsubscribedMessages = (deliveredEmails) =>
  deliveredEmails.filter((em) =>
    em.events.some((e) => e.kind === 'unsubscribed'),
  );

export const filterComplainedMessages = (deliveredEmails) =>
  deliveredEmails.filter((em) =>
    em.events.some((e) => e.kind === 'complained'),
  );

export const getRRuleOptionsFormErrorState = (
  rruleOptions: RRule['options'],
) => {
  return {
    monthDay:
      rruleOptions.bymonthday?.[0] > 31 ||
      (rruleOptions.bymonthday?.[0] ?? 0) === 0,
    repeatEvery: rruleOptions.interval === 0,
  };
};

export const extractVariables = (
  content: string | null | undefined,
  vars: Variable[],
) => {
  if (!content) return [];

  const regex = /<span class="variable"[^>]*>([^<]+)<\/span>/g;
  const matches: string[] = [];
  let match: RegExpExecArray | null;

  while ((match = regex.exec(content)) !== null) {
    matches.push(match[1]);
  }

  return vars
    .map((variable) => {
      if (matches.includes(variable.label)) {
        return {
          name_in_text: variable.label,
          variable_id: variable.id,
        };
      }

      return null;
    })
    .filter(Boolean);
};
