import { currentUserAllowedTo, currentUserIsSreAdmin } from '@/lib/permissions';

export const currentUserAllowedToSeeReporingTemplatePage = () =>
  currentUserIsSreAdmin() ||
  currentUserAllowedTo('configure', 'report_builder');

export const currentUserAllowedToSeeNavigateToReportBuilderTemplateViewPage =
  () => {
    return currentUserIsSreAdmin();
  };
