import UniversalFilterButton from 'bundles/Shared/components/Filters/buttons/universalFilterButton/UniversalFilterButton';
import LoadingPanel from 'bundles/Shared/components/LoadingPanel';
import { Popover } from '@/stories/Popover/Popover';
import {
  PmcErpSelect,
  PropertyManagementCompany,
} from '@/bundles/Assets/components/SharedFiles/Table/Filters/PmcErpSelect';
import { ERPSystem } from '@/entities/erpsystem';

interface Props {
  pmcOptions: PropertyManagementCompany[];
  erpOptions: ERPSystem[];
  selectedPmcValues: PropertyManagementCompany[] | undefined;
  selectedErpValues: ERPSystem[] | undefined;
  onPmcValuesChange: (pmcValues: PropertyManagementCompany[]) => void;
  onErpValuesChange: (erpValues: ERPSystem[]) => void;
  resetFilter: () => void;
}

export const QuickFilterByPmcOrErp = ({
  pmcOptions,
  erpOptions,
  selectedPmcValues,
  selectedErpValues,
  onPmcValuesChange,
  onErpValuesChange,
  resetFilter,
}: Props) => {
  return (
    <Popover
      appendToBody
      template={
        pmcOptions && erpOptions ? (
          <PmcErpSelect
            pmcOptions={pmcOptions}
            erpOptions={erpOptions}
            selectedPmcValues={selectedPmcValues}
            selectedErpValues={selectedErpValues}
            onPmcValuesChange={onPmcValuesChange}
            onErpValuesChange={onErpValuesChange}
          />
        ) : (
          <LoadingPanel styles={{ minHeight: '15rem', minWidth: '15rem' }} />
        )
      }
      maxWidth="37.5rem"
      placement="bottom-end"
      hiddenArrow
      trigger="click"
      offset={[0, 2]}
    >
      {({ triggered }) => (
        <UniversalFilterButton
          clicked={triggered}
          filtered={Boolean(
            selectedPmcValues?.length || selectedErpValues?.length,
          )}
          onClose={resetFilter}
        />
      )}
    </Popover>
  );
};
