import { usePutApiReconcileForecastingForecastsByForecastIdBudgetsAndBudgetIdCopyFromSourceMutation } from '@/entities/reconcile/forecasting/api/reconcileForecastingEntitiesEnchancedApi';
import { ForecastingBudget } from '@/entities/reconcile/forecasting/api/reconcileForecastingEntitiesGeneratedApi';
import {
  CreateForecastingBudgetFromSourceModal,
  ForecastBudgetFromSourceForm,
} from '@/entities/reconcile/forecasting/features/ui/CreateForecastingBudgetFromSourceModal';
import { ForecastingForecast } from '@/entities/reconcile/forecasting/model';
import { useModal } from '@/shared/lib/hooks/useModal';
import { EntityToastrCreator } from '@/shared/lib/toastr/entityToastrCreator';

const t = new EntityToastrCreator('Budget');

export const useCreateForecastBudget = () => {
  const [createFromSource, options] =
    usePutApiReconcileForecastingForecastsByForecastIdBudgetsAndBudgetIdCopyFromSourceMutation();

  const { openModal } = useModal();
  const handleCreateForecastFromSource = async (
    forecastId: ForecastingForecast['id'],
    budgetId: ForecastingBudget['id'],
    defaultValues?: Partial<ForecastBudgetFromSourceForm>,
  ) => {
    const values = await openModal(CreateForecastingBudgetFromSourceModal, {
      forecastId,
      defaultValues,
    });

    if (!values) return;

    const res = await createFromSource({
      forecastId,
      budgetId,
      body: {
        source_budget_id: values.sourceBudgetId,
      },
    });

    if ('error' in res) return;

    toastr.success(t.create().toString());
  };

  return {
    createFromSource: handleCreateForecastFromSource,
    ...options,
  };
};
