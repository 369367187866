import React, { ChangeEvent, FC } from 'react';
import NumberFormat from 'react-number-format';
import { Field } from 'stories/Field/Field';
import { Icon } from 'stories/Icon/Icon';
import { cn } from '@/shared/lib/css/cn';
import { IFormItem } from 'bundles/Shared/components/GroupForm/types/types';
import { trimSeparator } from '@/shared/lib/formatting/number';

interface Props extends IFormItem {
  value: number | string;
  min: number;
  max: number;
  format?: string;
  mask?: string;
  prefix?: string;
  maxLength?: number;
  transformedValue?: (value: string) => string;
}

const DOLLAR_DECIMAL_LENGTH = 2;

const FormInputNumber: FC<Props> = ({
  title,
  placeholder,
  value,
  formData,
  setFormData,
  fieldName,
  required,
  format,
  hint,
  mask = '',
  maxLength,
  prefix,
  styles,
  leftIcon,
  thousandSeparator,
  disabled,
  error,
  max,
  transformedValue,
}) => {
  const handleChange = (data: {
    formattedValue: string;
    value: string;
    floatValue: number;
  }): void => {
    const valueCalculating = (changingValue: number) => {
      if (max) {
        const isNegative = max < 0;
        if (isNegative) {
          return changingValue;
        }
        if (max < changingValue) {
          return max;
        }
        return changingValue;
      }
      return changingValue;
    };

    setFormData({
      ...formData,
      [fieldName]: valueCalculating(data.floatValue),
    });
  };

  const handleBlur = (data: ChangeEvent<HTMLInputElement>) => {
    if (!max) {
      return;
    }
    const isNegative = max < 0;
    if (!(isNegative && trimSeparator(data.target.value) < max)) {
      return;
    }
    setFormData({
      ...formData,
      [fieldName]: max,
    });
  };

  return (
    <div
      className={cn(
        'form-item',
        styles?.wrapper ?? 'w-full',
        styles?.nomb && 'form-item_nomb',
      )}
    >
      <Field labelText={title} required={required} hint={hint}>
        <div
          className={cn(
            'sre-input',
            styles?.size ? `sre-input_size-${styles?.size}` : '',
          )}
        >
          <NumberFormat
            format={format}
            className={cn(
              'sre-input__field',
              'form-item__number',
              leftIcon ? 'form-item__date_left' : '',
              error && 'sre-input__field_error',
              styles?.input,
            )}
            mask={mask}
            placeholder={placeholder}
            value={transformedValue ? transformedValue(value) : value}
            onValueChange={handleChange}
            onBlur={handleBlur}
            maxLength={maxLength}
            disabled={disabled}
            prefix={prefix}
            thousandSeparator={thousandSeparator}
            decimalScale={DOLLAR_DECIMAL_LENGTH}
          />
          <div className="sre-input__back-layer" />
          {leftIcon && (
            <div className="sre-input__side-icon sre-input__side-icon_left">
              <Icon iconName={leftIcon} />
            </div>
          )}
        </div>
        {error && <div className="red">{error}</div>}
      </Field>
    </div>
  );
};
export default FormInputNumber;
