import { cn } from '@/shared/lib/css/cn';
import { StepLine } from 'bundles/Construction/components/ChangeOrder/AvailableActions/Wrappers';
import { TApprover } from 'bundles/Construction/components/eSignature/types';
import { TChangeOrderStatus } from 'bundles/Construction/types';
import { formatDate } from '@/shared/lib/formatting/dates';
import { capitalize, sortBy } from 'lodash-es';
import { Icon } from 'stories/Icon/Icon';
import { MultilineAlertContainer } from 'stories/MultilineAlert/MultilineAlert';
import { CssVar } from '@/shared/config/cssVar';

type ApproverRequestStatus = 'REQUESTED' | 'NOT-REQUESTED' | 'APPROVED';
type TApproverForRequestStatus = Pick<TApprover, 'requestedAt' | 'approvedAt'>;

const getApproverRequestStatus = (
  approver: TApproverForRequestStatus,
): ApproverRequestStatus => {
  if (approver.approvedAt != null) {
    return 'APPROVED';
  }
  if (approver.requestedAt != null) {
    return 'REQUESTED';
  }
  return 'NOT-REQUESTED';
};

const ApproverStepLine = ({
  approver,
  allApproved,
}: {
  approver: TApproverForRequestStatus;
  allApproved?: boolean;
}) => {
  const status = getApproverRequestStatus(approver);
  return (
    <StepLine
      className={cn(
        allApproved
          ? 'border-green'
          : {
              'border-blue': status === 'APPROVED',
              'border-dashed border-light-30': status === 'NOT-REQUESTED',
              'border-dashed border-blue': status === 'REQUESTED',
            },
      )}
    />
  );
};

const StatusTrackerItem = ({
  approver,
  notLast,
  allApproved,
}: {
  approver: TApprover;
  allApproved?: boolean;
  notLast?: boolean;
}) => {
  const approverRequestStatus = getApproverRequestStatus(approver);
  const notGrey = ['APPROVED', 'REQUESTED'].includes(approverRequestStatus);

  return (
    <div className={cn('flex gap-2', notLast && 'h-[64px]')}>
      <div className="gap-w flex flex-col">
        <span
          className={cn(
            'circle h-17 w-17 pl-[1px]',
            allApproved
              ? 'bg-green'
              : {
                  'bg-blue': notGrey,
                  'bg-light-10': approverRequestStatus === 'NOT-REQUESTED',
                },
          )}
        >
          <Icon
            color={notGrey ? CssVar.white : CssVar.light60}
            iconName={
              approverRequestStatus === 'APPROVED' ? 'checkSmall' : 'clock'
            }
          />
        </span>
        {notLast && (
          <ApproverStepLine allApproved={allApproved} approver={approver} />
        )}
      </div>
      <div className="flex flex-col gap-0.5">
        <div className="label-semibold text-dark-60">{approver.userName}</div>
        <div className="label-regular text-light-90">
          {capitalize(approver.userRoleCategory)} • {approver.userRoleName}
        </div>
        <div className="secondary-regular text-light-60">
          {approver.approvedAt
            ? formatDate(approver.approvedAt, 'MMM DD, YYYY')
            : 'Pending Approval'}
        </div>
      </div>
    </div>
  );
};

interface Props {
  approvers: TApprover[];
  status: TChangeOrderStatus;
}
const MessageWithApprovers = ({ approvers, status }: Props) => {
  const approved = status === 'APPROVED';

  return (
    <MultilineAlertContainer
      status={approved ? 'success' : 'info'}
      flexibleWidth
      classes={{
        body: '!p-4',
      }}
    >
      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-2">
          <div className="secondary-semibold text-dark-60">
            {!approved && 'This'} Change Order{' '}
            {approved ? 'has been Approved' : 'is awaiting approval'}
          </div>
          {!approved && (
            <p className="label-regular text-light-90">
              You will receive an email notification once it's been approved.
            </p>
          )}
        </div>
        <div className="flex flex-col">
          {sortBy(approvers, 'priority').map((approver, i) => (
            <StatusTrackerItem
              key={approver.id}
              allApproved={approved}
              approver={approver}
              notLast={i < approvers.length - 1}
            />
          ))}
        </div>
      </div>
    </MultilineAlertContainer>
  );
};

export default MessageWithApprovers;
