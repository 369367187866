import { ForecastingForecast } from '@/entities/reconcile/forecasting/api/reconcileForecastingEntitiesGeneratedApi';
import { LinkWrapper } from '@/shared/ui/LinkWrapper';
import { ProjectCard } from '@/stories/ProjectCard/ProjectCard';
import { memo } from 'react';

type ForecastListItemProps = ForecastingForecast & {
  pageViewUrl?: string;
  selected?: boolean;
};

const ForecastListItem = ({
  id,
  label,
  description,
  years,
  pageViewUrl: url,
  selected,
}: ForecastListItemProps) => (
  <LinkWrapper to={url} key={id}>
    <ProjectCard
      selected={selected}
      header={
        <div className="flex w-full items-start justify-between gap-2">
          <div className="flex grow flex-col gap-3">
            <div className="flex flex-col gap-1">
              <p className="inline-semibold text-neutral-800">{label}</p>
            </div>
          </div>
        </div>
      }
    >
      <div className="flex flex-col gap-2 text-xs text-neutral-550">
        {description}
      </div>
      <div className="flex flex-nowrap items-end justify-between gap-2">
        <div className="flex flex-wrap gap-1 text-xs text-neutral-550">
          {years && years?.length > 0 ? (
            years.map((year) => (
              <div
                key={id + year}
                className="rounded-[0.5rem] bg-neutral-050 px-2 py-1 text-xs text-neutral-850"
              >
                {year}
              </div>
            ))
          ) : (
            <div className="rounded-[0.5rem] bg-neutral-050 px-2 py-1 text-xs text-neutral-850">
              No budgets
            </div>
          )}
        </div>
      </div>
    </ProjectCard>
  </LinkWrapper>
);

export default memo(ForecastListItem);
