import React from 'react';
import ESignActionTooltip from 'bundles/Construction/components/eSignature/ESignActionTooltip';
import { Button } from 'stories/Button/Button';
import { LinkAsButton } from 'stories/LinkAsButton/LinkAsButton';
import { useNavigation } from '@/shared/lib/hooks/useNavigation';
import { useParams } from '@reach/router';

interface Props {
  skipped: boolean;
  onSkip: () => void;
  placed: boolean;
  canSkip: boolean;
  showPlacementsModal: (readonly: boolean) => void;
}

const DocumentESignatureActions = ({
  skipped,
  onSkip,
  placed,
  showPlacementsModal,
  canSkip,
}: Props) => {
  const navigation = useNavigation();
  const params = useParams();
  const settingsLink = navigation.getUrl(
    'RECONCILE_DEVELOPMENT_LEGAL_ENTITY_SETTINGS',
    {
      legalEntityCode: params.legalEntityCode,
      tab: 'workflow',
    },
  );

  return (
    <div className="border-t border-light-10 bg-light-5 px-6 py-4">
      <div className="flex items-center justify-between gap-4">
        <div>
          <div className="body-semibold text-dark-60">
            Configure who needs to sign<span className="text-red"> *</span>
          </div>
          {placed && (
            <p className="secondary-regular text-dark-60">
              eSignature settings are already in place. Click edit if you would
              like to change them.
            </p>
          )}
          {skipped && (
            <p className="secondary-regular text-dark-60">
              You chose to skip eSignatures for this document. If it does need
              to be signed, please update your project settings.
            </p>
          )}
        </div>
        <div className="flex gap-2">
          {!skipped && (
            <>
              {!placed && (
                <ESignActionTooltip
                  disabled={canSkip}
                  title="Skip is unavailable"
                  content="You cannot skip this action, given you have approvers in your project settings"
                  settingsLink={settingsLink}
                >
                  <Button
                    variant="warning"
                    onClick={onSkip}
                    size="s"
                    iconPosition="right"
                    disabled={!canSkip}
                    iconName={!canSkip ? 'lock' : undefined}
                  >
                    Skip
                  </Button>
                </ESignActionTooltip>
              )}
              <ESignActionTooltip
                disabled={!canSkip}
                title="Configure is unavailable"
                content="If eSignatures are mandatory, please make sure you have approvers in your project settings"
                settingsLink={settingsLink}
              >
                <Button
                  size="s"
                  variant={placed ? 'success' : 'warning'}
                  onClick={() => showPlacementsModal(false)}
                  disabled={canSkip}
                  iconName={canSkip ? 'lock' : undefined}
                  iconPosition="right"
                >
                  {placed ? 'Edit' : 'Configure'}
                </Button>
              </ESignActionTooltip>
            </>
          )}
          {skipped && (
            <LinkAsButton
              size="s"
              variant="secondary"
              link={settingsLink}
              className="white-space-nowrap"
            >
              Project Settings
            </LinkAsButton>
          )}
        </div>
      </div>
    </div>
  );
};

export default DocumentESignatureActions;
