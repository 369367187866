import React from 'react';
import {
  LightUserRole,
  RolesFilterList,
} from '@/bundles/Shared/features/user/filter/byRole/ui/RolesFilterList';
import { Button } from 'stories/Button/Button';
import { Icon } from 'stories/Icon/Icon';
import { Popover } from 'stories/Popover/Popover';

interface Props {
  roles: LightUserRole[];
  roleIds: string[];
  onChangeRoles: (roles: LightUserRole[]) => void;
}

export function UserRoleFilter({ roles, roleIds, onChangeRoles }: Props) {
  return (
    <Popover
      appendToBody
      trigger="click"
      placement="bottom-start"
      maxWidth="fit-content"
      className="p-m"
      hiddenArrow
      offset={[0, 2]}
      template={
        <RolesFilterList
          columns
          records={roles}
          selectedIds={roleIds}
          onChange={(newRoles) => onChangeRoles(newRoles)}
        />
      }
    >
      <Button
        iconName="userAlt"
        variant="secondary"
        className="text-neutral-500"
        size="s"
      >
        <div className="flex items-center gap-1">
          <div className="inline-regular">Role</div>
          <div className="leading-[0.8] text-info-055">
            {roleIds.length > 1 && roleIds.length}
            {roleIds.length === 1 &&
              roles.find((role) => role.id === roleIds[0])?.name}
            {roleIds.length === 0 && 'All'}
          </div>
          <Icon iconName="arrowBottom" />
        </div>
      </Button>
    </Popover>
  );
}
