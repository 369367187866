import { cn } from '@/shared/lib/css/cn';

import React from 'react';
import Tag from 'stories/Tags/Tag/Tag';

function FormulaTagList({
  children,
  className,
}: React.PropsWithChildren & PropsWithClassName) {
  return (
    <div className={cn('flex flex-wrap items-center gap-2', className)}>
      {children}
    </div>
  );
}

export function EntityTagLabel({ ...props }: React.ComponentProps<typeof Tag>) {
  return <Tag {...props} />;
}

EntityTagLabel.List = FormulaTagList;
