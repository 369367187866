import { TConfirmFuncProps } from '@/shared/lib/hooks/useModal';
import React, { ComponentProps } from 'react';
import TemplateEditor from '@/bundles/Settings/components/EmailSettings/editor/TemplateEditor';
import * as Type from '@/bundles/Settings/components/EmailSettings/editor/types';

export const initInvitationTemplateVars: Type.InvitationTemplateVars = {
  body: [],
  greeting: [],
  acceptInvitation: [],
  additionalInfo: [],
};

export const initFeedMessageTemplateVars: Type.FeedMessageTemplateVars = {
  greeting: [],
  additionalInfo: [],
  callToAction: [],
};

export const initForgotPasswordTemplateVars: Type.ForgotPasswordTemplateVars = {
  greeting: [],
  body: [],
  callToAction: [],
  additionalInfo: [],
};

export const TOOLBAR_ITEMS_VARIABLE_AND_ALIGNMENTS: ComponentProps<
  typeof TemplateEditor
>['toolbarItems'] = [
  'alignment:left',
  'alignment:center',
  'alignment:right',
  'variable',
];

export const confirmResetingReplyRecipients: TConfirmFuncProps = {
  title: 'Reset Access for replies',
  subtitle: (
    <p className="-mb-4">
      Are you sure you want to reset access
      <br />
      for all members?
    </p>
  ),
  actions: {
    primaryButton: {
      text: 'Reset',
      variant: 'danger',
    },
    secondaryButton: {
      text: 'Cancel',
      variant: 'secondary',
    },
  },
};

export const TOOLBAR_GROUPS_VARIABLES: ComponentProps<
  typeof TemplateEditor
>['toolbarGroups'] = [
  {
    name: 'Variables',
    items: ['variable' as const],
  },
];

export const TOOLBAR_GROUPS_ALIGNMENTS: ComponentProps<
  typeof TemplateEditor
>['toolbarGroups'] = [
  {
    name: 'Alignments',
    items: ['align' as const],
  },
];

export const TOOLBAR_GROUPS_VARIABLES_AND_ALIGNMENTS: ComponentProps<
  typeof TemplateEditor
>['toolbarGroups'] = [
  ...TOOLBAR_GROUPS_VARIABLES,
  ...TOOLBAR_GROUPS_ALIGNMENTS,
];

export const TEMPLATE_DESCRIPTION =
  'In this section you can use special variables to automate filling templates, these variables are under fields';
