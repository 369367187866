import { formatAmount } from '@/shared/lib/formatting/number';
import React, { FC } from 'react';
import { SmallAddingButton } from 'stories/SmallAddingButton/SmallAddingButton';
import { Tooltip } from 'stories/Tooltip/Tooltip';
import { IFee } from 'bundles/Construction/components/ChangeOrderEvent/CostBreakdownDetails/types';
import { checkAmount } from 'bundles/Construction/components/ChangeOrderEvent/CostBreakdownDetails/utils';

export interface Props {
  fees: IFee[];
  editOnly: boolean;
  allowedFee: (fee: IFee) => void;
  invalidAmount: number;
  feesSubtotal: () => void;
  setShowModal: (st: string) => void;
  editCost: boolean;
}

const ContractorFee: FC<Props> = ({
  fees,
  editOnly,
  feesSubtotal,
  setShowModal,
  editCost,
  invalidAmount,
}) => (
  <div className="card-cost-breakdown card-cost-breakdown_line">
    {fees.length ? (
      <div>
        <div className="dark-60 inline-semibold mb-s">Contractor Fees</div>
        <table className="table-reasons table">
          <thead>
            <tr>
              <th className="w-35">Cost Code</th>
              <th className="w-35">Comment</th>
              <th className="w-10">Amount</th>
            </tr>
          </thead>
          <tbody>
            {fees.map((fee, i) => (
              <tr key={i}>
                <td className="dark-60 secondary-regular">
                  {fee.costCode?.code} - {fee.costCode?.name}
                </td>
                <td className="dark-60 secondary-regular">{fee.comment}</td>
                <td className="dark-60 secondary-regular text-right">
                  {checkAmount(fee, invalidAmount) ? (
                    <Tooltip
                      mainText={`Allowed fee: ${formatAmount(
                        checkAmount(fee ?? '0', invalidAmount),
                      )}`}
                      arrowPosition="center"
                    >
                      <span className="red">
                        {formatAmount(fee.amount ?? 0)}
                      </span>
                    </Tooltip>
                  ) : (
                    <span>{formatAmount(fee.amount ?? 0)}</span>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="flex justify-between items-center">
          {!editOnly && !editCost && (
            <SmallAddingButton onClick={() => setShowModal('fees')}>
              Add Fee
            </SmallAddingButton>
          )}
          {fees.length > 0 && (
            <div className="flex secondary-regular">
              <div className="mr-xs">Total Fees:</div>
              <div className="dark-60">{formatAmount(feesSubtotal())}</div>
            </div>
          )}
        </div>
      </div>
    ) : (
      !editOnly && (
        <div
          className="blue inline-semibold cursor-pointer"
          onClick={() => setShowModal('fees')}
        >
          Add Contractor Fee
        </div>
      )
    )}
  </div>
);

export default ContractorFee;
