import { cn } from '@/shared/lib/css/cn';
import { PropsWithChildren } from 'react';

export const StatsCard = ({
  value,
  label,
  total,
  children,
}: PropsWithChildren<{
  value: number;
  label: string;
  total?: number;
}>) => {
  return (
    <div className="group relative h-full w-full">
      <div className="left-0 top-0 flex w-full flex-col gap-2 rounded-xl p-4 group-hover:absolute group-hover:bg-white group-hover:shadow-[0px_8px_24px_8px_rgba(62,74,99,0.1)]">
        <div
          className={cn(
            'text-nowrap text-xl font-medium',
            value > 0 && 'text-neutral-850',
          )}
        >
          {value}{' '}
          {total !== undefined && (
            <span className="text-neutral-550">/ {total}</span>
          )}
        </div>
        <div className="text-nowrap text-xs font-semibold text-neutral-550">
          {label}
        </div>
        <div
          className={cn(
            'w-full transition-all duration-200',
            'hidden opacity-0 group-hover:block group-hover:opacity-100',
          )}
        >
          {children}
        </div>
      </div>
    </div>
  );
};
