import { useConnectionById } from '@/bundles/Shared/entities/connection/api';
import { formatToDateStringForRequest } from '@/shared/lib/converters';
import { shiftTodayBackward } from '@/shared/lib/date';
import { ExtractParams, ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import { IThinTabItem } from '@/stories/Tabs/ThinTabGroup/ThinTabGroup';
import { RouteComponentProps, useParams } from '@reach/router';
import ConnectionInfo from 'bundles/Settings/components/REport/Connections/widgets/connectionPage/ConnectionInfo';
import EmailExtractionsTable from 'bundles/Settings/components/REport/Connections/widgets/connectionPage/EmailExtractionsTable';
import SftpExtractionsTable from 'bundles/Settings/components/REport/Connections/widgets/connectionPage/SftpExtractionsTable';
import CustomPreview from 'bundles/Shared/components/FilePreviews/CustomPreview';
import { PageParamsProvider } from 'bundles/Shared/components/pageParams';
import {
  isEmailExtractions,
  isSftpExtractions,
} from 'bundles/Shared/entities/connection/lib';
import { TParserStatus } from 'bundles/Shared/entities/connection/model';
import {
  DEFAULT_DATE_RANGE_FOR_ERP_EXTRACTIONS,
  DEFAULT_DATE_RANGE_FOR_MANUAL_EXTRACTIONS,
  DEFAULT_EXTRACTIONS_PER_PAGE,
} from 'bundles/Shared/entities/extraction/lib';
import {
  ParseStoredExtractionsButton,
  ReparseFailedExtractionsButton,
} from 'bundles/Shared/features/connection/reparse';
import ManualExtractionsTable from 'bundles/Shared/widgets/extraction/ManualExtractionsTable';
import { UploadManualExtractions } from 'bundles/Shared/widgets/extraction/UploadManualExtractions';
import { FC, useEffect, useState } from 'react';
import { ThinTabGroup } from 'stories/Tabs/ThinTabGroup/ThinTabGroup';

export enum MethodExtractions {
  ERP = 'erp',
  MANUAL = 'manual',
}

const TABS = [
  {
    label: 'ERP Extractions',
    id: MethodExtractions.ERP,
  },
  {
    label: 'Manual Extractions',
    id: MethodExtractions.MANUAL,
  },
] as const satisfies readonly IThinTabItem<FlattenEnum<MethodExtractions>>[];

export const SettingsReportConnectionPage: FC<RouteComponentProps> = () => {
  const { fullPath: ROUTE } =
    ROUTES_ROOT.settings.report.connections.connection;
  const params = useParams<ExtractParams<typeof ROUTE>>();
  const [pageParams, setPageParams] = useState({
    perPage: DEFAULT_EXTRACTIONS_PER_PAGE,
    page: 1,
    createdAtRange: {
      from: formatToDateStringForRequest(shiftTodayBackward(1, 'month')),
      to: formatToDateStringForRequest(new Date()),
    },
  });

  const [methodExtractions, setMethodExtractions] = useState<
    FlattenEnum<MethodExtractions>
  >(MethodExtractions.ERP);

  useEffect(() => {
    if (methodExtractions === MethodExtractions.ERP) {
      setPageParams({
        ...pageParams,
        createdAtRange: DEFAULT_DATE_RANGE_FOR_ERP_EXTRACTIONS,
      });
    }
    if (methodExtractions === MethodExtractions.MANUAL) {
      setPageParams({
        ...pageParams,
        createdAtRange: DEFAULT_DATE_RANGE_FOR_MANUAL_EXTRACTIONS,
      });
    }
  }, [methodExtractions]);

  const { data: connection } = useConnectionById(Number(params.connectionId));

  return (
    <CustomPreview
      leftBlock={
        <div className="flex flex-col gap-4 p-2">
          <div className="font-bold text-neutral-800">Extractions</div>
          <div className="flex justify-between">
            <ThinTabGroup
              items={TABS}
              selectedItem={methodExtractions}
              onSelectedItemChange={(newItem) =>
                setMethodExtractions(newItem.id)
              }
              className="w-fit"
            />
            <div className="flex gap-2">
              <ParseStoredExtractionsButton
                manualAttachments={methodExtractions === 'manual'}
                connectionId={Number(params.connectionId)}
              />
              {connection?.item.parserStatus === TParserStatus.ERROR && (
                <ReparseFailedExtractionsButton
                  connectionId={Number(params.connectionId)}
                  manual={methodExtractions === 'manual'}
                />
              )}

              {methodExtractions === 'manual' && <UploadManualExtractions />}
            </div>
          </div>
          <PageParamsProvider
            pageParams={pageParams}
            setPageParams={setPageParams}
          >
            {methodExtractions === 'erp' && (
              <>
                {isEmailExtractions(connection?.item) && (
                  <EmailExtractionsTable />
                )}
                {isSftpExtractions(connection?.item) && (
                  <SftpExtractionsTable />
                )}
              </>
            )}
            {methodExtractions === 'manual' && <ManualExtractionsTable />}
          </PageParamsProvider>
        </div>
      }
      withoutHeader
    >
      <ConnectionInfo />
    </CustomPreview>
  );
};
