import React from 'react';
import { IconButton } from 'stories/IconButton/IconButton';

interface Props {
  current: number;
  total: number;
  onNext: () => void;
  onPrev: () => void;
}

function VerticalPagination({ current, total, onNext, onPrev }: Props) {
  return (
    <div className="flex items-center">
      <div className="light-60 label-regular mr-m">
        {current} of {total}
      </div>
      <IconButton
        disabled={current - 1 === 0}
        iconName="arrowTop"
        className="mr-s"
        onClick={onPrev}
      />
      <IconButton
        iconName="arrowBottom"
        className="mr-m"
        disabled={current + 1 > total}
        onClick={onNext}
      />
    </div>
  );
}

export default VerticalPagination;
