import React, { useCallback } from 'react';
import { Layout, ReactGridLayoutProps } from 'react-grid-layout';
import { ResponsiveGridLayout } from '@/shared/lib/react-grid-layout/ResponsiveGridLayout';
import styles from '@/bundles/Shared/widgets/dashboard/layout/ui/ScoreboardGridLayout.module.scss';
import { cn } from '@/shared/lib/css/cn';
import { DEFAULT_LAYOUT_PROPS } from 'bundles/Shared/widgets/dashboard/layout';
import DashboardSectionDragButton from 'bundles/Shared/features/dashboard/sectionActions/ui/DashboardSectionDragButton';
import { dashboardLayoutChanged } from 'bundles/Shared/features/dashboard/sectionActions';
import { useDispatch } from 'react-redux';

import type {
  ReportDashboardSectionPositionLayout,
  ReportDashboardSectionPositionLayouts,
  ReportDashboardSectionPositionWithId,
} from 'bundles/Shared/entities/dashboard/model/types/types';

import { useDashboardContext } from 'bundles/Shared/entities/dashboard/lib';
import { find, map, merge, pick } from 'lodash-es';

export function DashboardGridLayout({
  backdrop,
  editable = false,
  onDrop,
  originalLayout,
  ...props
}: Omit<ReactGridLayoutProps, 'onDrop'> & {
  originalLayout?: ReportDashboardSectionPositionLayout;
  backdrop?: boolean;
  editable?: boolean;
  onDrop?: (
    layout: ReportDashboardSectionPositionLayout,
    item: ReportDashboardSectionPositionWithId,
    e: Event,
  ) => void;
}) {
  const { dashboardId, boardId } = useDashboardContext();
  const dispatch = useDispatch();
  const handleLayoutChange = useCallback(
    (layout: Layout[], allLayouts: ReportDashboardSectionPositionLayouts) => {
      dispatch(
        dashboardLayoutChanged({
          layout: layout as ReportDashboardSectionPositionLayouts['lg'],
          dashboardId,
          boardId,
          allLayouts,
        }),
      );
    },
    [boardId, dashboardId],
  );

  const handleDrop = useCallback(
    (
      layout: Layout[],
      item: ReportDashboardSectionPositionWithId,
      e: Event,
    ) => {
      if (onDrop) {
        // merge the layout with the original layout to keep the type
        const mergedLayout = map(layout, function (layoutItem) {
          const foundItem = find(originalLayout, { i: layoutItem.i });
          return merge({}, layoutItem, pick(foundItem, ['type']));
        }) as ReportDashboardSectionPositionWithId[];
        onDrop(mergedLayout, item, e);
      }
    },
    [onDrop],
  );
  return (
    <ResponsiveGridLayout
      {...DEFAULT_LAYOUT_PROPS}
      draggableHandle={DashboardSectionDragButton.HANDLE}
      compactType="vertical"
      isDraggable={editable}
      isResizable={editable}
      onLayoutChange={handleLayoutChange}
      onDrop={handleDrop}
      className={cn(
        'layout min-h-full w-full',
        styles.scoreboardGridLayout,
        backdrop && styles.scoreboardGridLayout_backdropped,
      )}
      isDroppable
      {...props}
    />
  );
}
