import * as React from 'react';
import { HTMLAttributes } from 'react';
import styles from '@/stories/Calendar/Header/CalendarHeader.module.css';
import dayjs from 'dayjs';
import { formatDate } from '@/shared/lib/formatting/dates';
import { cn } from '@/shared/lib/css/cn';
import { IconButton } from '@/stories/IconButton/IconButton';
import {
  MonthPickerDropdown,
  YearPickerDropdown,
} from 'stories/Calendar/Pickers/CalendarPickerDropdown';
import { CalendarRangeProps } from 'stories/Calendar/Range/CalendarRange';

type ArrowMonthProps = Omit<
  React.AllHTMLAttributes<HTMLElement>,
  'onClick' | 'aria-label'
>;

export interface CalendarHeaderProps
  extends Omit<HTMLAttributes<HTMLDivElement>, 'onChange'>,
    Pick<CalendarRangeProps, 'maxDate' | 'minDate'> {
  viewDate: Date;
  prevMonthHidden?: boolean;
  nextMonthHidden?: boolean;
  disablePickers?: boolean;
  prevMonthProps?: ArrowMonthProps;
  nextMonthProps?: ArrowMonthProps;
  disableFuture?: boolean;
  onChange(viewDate: Date): void;
  onNextMonth?(): void;
  onPrevMonth?(): void;
}

export const CalendarHeader = ({
  viewDate,
  onChange,
  prevMonthHidden = false,
  nextMonthHidden = false,
  disablePickers = false,
  onNextMonth,
  onPrevMonth,
  prevMonthProps = {},
  nextMonthProps = {},
  maxDate,
  minDate,
  disableFuture,
  className,
  ...restProps
}: CalendarHeaderProps) => {
  const onMonthsChange = React.useCallback(
    (month: number) => onChange(dayjs(viewDate).set('month', month).toDate()),
    [onChange, viewDate],
  );
  const onYearChange = React.useCallback(
    (year: number) => onChange(dayjs(viewDate).set('year', year).toDate()),
    [onChange, viewDate],
  );

  const currentYear = viewDate.getFullYear();

  const { className: prevMonthClassName, ...restPrevMonthProps } =
    prevMonthProps;
  const { className: nextMonthClassName, ...restNextMonthProps } =
    nextMonthProps;

  return (
    <div className={cn(styles.CalendarHeader, className)} {...restProps}>
      {disablePickers ? (
        <span className={cn(styles.CalendarHeader__pickers)}>
          <span className={styles.CalendarHeader__month}>
            {formatDate(dayjs(viewDate), 'MMMM')}
          </span>
          &nbsp;
          {formatDate(dayjs(viewDate), 'YYYY')}
        </span>
      ) : (
        <div className={cn(styles.CalendarHeader__pickers)}>
          <MonthPickerDropdown
            value={dayjs(viewDate).month()}
            onChange={onMonthsChange}
          />
          <YearPickerDropdown
            viewDate={viewDate}
            value={currentYear}
            onChange={onYearChange}
            maxDate={maxDate}
            minDate={minDate}
          />
        </div>
      )}
      <div className="absolute right-0 flex gap-2">
        {!prevMonthHidden && (
          <IconButton
            data-testid="prev-month"
            className="text-info-055"
            size="s"
            onClick={onPrevMonth}
            iconName="arrowTop"
          />
        )}
        {!nextMonthHidden && (
          <IconButton
            data-testid="next-month"
            className="text-info-055"
            size="s"
            iconName="arrowBottom"
            onClick={onNextMonth}
          />
        )}
      </div>
    </div>
  );
};
