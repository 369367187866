import sanitizeHtml from 'sanitize-html';

export const DEFAULT_SANITIZE_HTML_OPTIONS = {
  allowedClasses: {
    '*': ['table', 'text-node'],
  },
  allowedAttributes: {
    '*': [
      'href',
      'align',
      'alt',
      'center',
      'bgcolor',
      'style',
      'target',
      'rel',
    ],
  },
} satisfies sanitizeHtml.IOptions;

export const ACCEPT_CSV_FILE_EXTENSION = '.csv';
export const ACCEPT_XLSX_FILE_EXTENSION = '.xlsx,.xlsm,.xls';

export const ACCEPT_BUDGET_FILE_EXTENSION = '.xlsx,.xlsm,.xls,.csv,.txt';
