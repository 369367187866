import { SettingsFormModal } from '@/bundles/Shared/components/SettingsModal/SettingsModal';
import {
  CAPTION_SCHEMA,
  CaptionFormFields,
  type CaptionSchemaForm,
} from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/fields/CaptionFields';
import { yupResolver } from '@hookform/resolvers/yup';

export const CaptionSettingsModal = ({
  initialForm,
  ...props
}: Omit<
  React.ComponentProps<typeof SettingsFormModal<CaptionSchemaForm>>,
  'fields' | 'formProps'
> & {
  initialForm?: CaptionSchemaForm;
}) => {
  return (
    <SettingsFormModal<CaptionSchemaForm>
      fields={<CaptionFormFields />}
      formProps={{
        resolver: yupResolver(CAPTION_SCHEMA),
        defaultValues: initialForm,
      }}
      {...props}
    />
  );
};
