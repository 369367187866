import { useModal } from '@/shared/lib/hooks/useModal';
import EmailTemplateFormInputGroup from 'bundles/EmailBuilder/components/EmailTemplateFormInputGroup';
import RecipientsModal from 'bundles/Shared/components/RecipientsModal/RecipientsModal';
import Select from 'react-select';
import { Input } from 'stories/FormControls/Inputs/Input/Input';
import { IUserRole } from 'types/User';
import InvitationTemplateEditor from '@/bundles/Settings/components/EmailSettings/editor/TemplateEditor';
import type * as Type from '@/bundles/Settings/components/EmailSettings/editor/types';
import ReplyRecipientsSection from '@/bundles/Settings/components/EmailSettings/ReplyRecipientsSection';
import {
  confirmResetingReplyRecipients,
  TEMPLATE_DESCRIPTION,
  TOOLBAR_GROUPS_VARIABLES,
} from '@/bundles/Settings/components/EmailSettings/utils/consts';

interface Props {
  template: Type.InvitationTemplateState;
  isDefault: boolean;
  variables: Type.InvitationTemplateVars;
  replyRecipients: Type.ReplyRecipient[];
  roles: IUserRole[];
  selectedRoles: IUserRole[];
  onSelectedRolesChange: (roles: IUserRole[]) => void;
  onTemplateChange: (template: Type.InvitationTemplateState) => void;
  invitationTemplateId?: Type.InvitationTemplate['id'];
  isLoading?: boolean;
}

const InvitationTemplateForm = ({
  invitationTemplateId,
  variables,
  selectedRoles,
  roles,
  template,
  replyRecipients,
  isDefault,
  onTemplateChange,
  onSelectedRolesChange,
  isLoading,
}: Props) => {
  const { openModal, confirm } = useModal();

  const {
    greeting: greetingVariables,
    body: bodyVariables,
    acceptInvitation: acceptInvitationVariables,
  } = variables;
  const { subject, body, greeting, acceptInvitation } = template;

  const setTemplateField = <K extends keyof Type.InvitationTemplateState>(
    field: K,
    value: Type.InvitationTemplateState[K],
  ) => {
    onTemplateChange({
      ...template,
      [field]: value,
    });
  };

  const updateSelectedRoles = (options) => {
    const values = options.map((o) => o.value);
    const allRoles = new Set(
      [...selectedRoles, ...roles].filter((r) => values.includes(r.id)),
    );
    onSelectedRolesChange(Array.from(allRoles));
  };

  const handleSetReplyRecipients = async () => {
    const res = await openModal(RecipientsModal, {
      replyRecipientOptions: replyRecipients,
      initSelectedRecipients: template.replyRecipients ?? [],
    });
    if (!res) return;

    setTemplateField('replyRecipients', res);
  };

  const handleReset = async () => {
    const result = await confirm(confirmResetingReplyRecipients);

    if (!result) return;

    setTemplateField('replyRecipients', []);
  };

  const displayRolesSelect = () => {
    if (roles.length < 1) return false;

    if (invitationTemplateId && isDefault) {
      return false;
    }

    return true;
  };

  return (
    <form>
      <div className="flex flex-col gap-4">
        <div>
          <EmailTemplateFormInputGroup isLoading={isLoading}>
            <>
              <label
                className="label-regular mb-2 text-dark-60"
                htmlFor="subject"
              >
                Email Subject
              </label>
              <Input
                id="subject"
                onChange={(e) => setTemplateField('subject', e.target.value)}
                value={subject}
                placeholder="Enter Subject"
              />
            </>
            {displayRolesSelect() && (
              <>
                <div className="label dark-60 label-regular secondary-regular mb-2 mt-4 text-dark-60">
                  Roles
                  <span className="red">*</span>
                </div>
                <Select
                  menuPosition="fixed"
                  menuShouldBlockScroll
                  isClearable
                  isMulti
                  value={selectedRoles.map((role) => ({
                    value: role.id,
                    label: role.name,
                  }))}
                  onChange={updateSelectedRoles}
                  options={roles.map((role) => ({
                    value: role.id,
                    label: role.name,
                  }))}
                  classNamePrefix="react-select"
                  placeholder="Select roles"
                />
              </>
            )}
            <ReplyRecipientsSection
              replyRecipients={template.replyRecipients ?? []}
              onReset={handleReset}
              onSet={handleSetReplyRecipients}
            />
          </EmailTemplateFormInputGroup>
        </div>
        <div>
          <EmailTemplateFormInputGroup
            title="Template"
            isLoading={isLoading}
            description={TEMPLATE_DESCRIPTION}
          >
            <div className="label-regular mb-2 text-dark-60">
              Greeting Line<span className="red">*</span>
            </div>
            <InvitationTemplateEditor
              placeholder="Enter Greeting Line"
              text={greeting}
              setText={(value: RawHTML) => setTemplateField('greeting', value)}
              variables={greetingVariables}
            />

            <div className="label-regular mb-2 mt-4 text-dark-60">
              Main content
              <span className="red"> *</span>
            </div>
            <InvitationTemplateEditor
              placeholder="Enter Main Content"
              text={body}
              setText={(value: RawHTML) => setTemplateField('body', value)}
              variables={bodyVariables}
            />

            <div className="label-regular mb-2 mt-4 text-dark-60">
              Accept Invitation Button
              <span className="red"> *</span>
            </div>
            <InvitationTemplateEditor
              placeholder="Enter Value"
              text={acceptInvitation}
              setText={(value: RawHTML) =>
                setTemplateField('acceptInvitation', value)
              }
              variables={acceptInvitationVariables}
              toolbarGroups={TOOLBAR_GROUPS_VARIABLES}
            />
            <div className="label-regular mb-2 mt-4 text-dark-60">
              Additional Info
            </div>
            <InvitationTemplateEditor
              placeholder="Enter Additional Info"
              text={template.additionalInfo}
              setText={(text: RawHTML) =>
                setTemplateField('additionalInfo', text)
              }
              variables={variables.additionalInfo}
              toolbarGroups={TOOLBAR_GROUPS_VARIABLES}
            />
          </EmailTemplateFormInputGroup>
        </div>
      </div>
    </form>
  );
};

export default InvitationTemplateForm;
