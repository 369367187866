import * as d3 from 'd3';
import styles from '@/stories/TreeView/TreeView.module.scss';

export const CONTEXT_MENU_ID = 'contextmenu';
export const CONTEXT_MENU_SELECTOR = `#${CONTEXT_MENU_ID}`;

export function ContextMenu(
  selection: d3.Selection<any, unknown, null, undefined>,
  {
    items,
    data,
    position,
    onClickOutside,
  }: {
    items: {
      text: string;
      handler: (data: any) => void;
      disabled?: (data: any) => boolean;
    }[];
    data: any;
    position: {
      x: number;
      y: number;
    };
    onClickOutside?: () => void;
  },
) {
  const ITEM_HEIGHT = 32;
  const ITEM_WIDTH = 183;

  d3.select(CONTEXT_MENU_SELECTOR).remove();

  const contextMenuGroup = selection
    .append('g')
    .attr('id', CONTEXT_MENU_ID)
    .attr('transform', `translate(${position.x}, ${position.y})`);

  contextMenuGroup
    .append('rect')
    .attr('class', styles.contextMenuRect)
    .attr('width', ITEM_WIDTH)
    .attr('height', ITEM_HEIGHT * items.length)
    .attr('y', -ITEM_HEIGHT / 2)
    .attr('ry', 8);

  const textGroup = contextMenuGroup
    .selectAll('g')
    .data(items, (d) => d.text)
    .enter()
    .append('g')
    .attr('cursor', (d, i) => (d.disabled?.(data) ? 'default' : 'pointer'))
    .on('click', (e, d) => !d.disabled?.(data) && d.handler(data));

  textGroup
    .append('rect')
    .attr('width', ITEM_WIDTH)
    .attr('height', 1)
    .attr('fill', (d, i) => (i === 0 ? 'transparent' : 'var(--light-15)'))
    .attr('x', 0)
    .attr('y', (d, i) => i * ITEM_HEIGHT - ITEM_HEIGHT / 2);

  textGroup
    .append('rect')
    .attr('width', ITEM_WIDTH)
    .attr('height', ITEM_HEIGHT)
    .attr('fill', (d) =>
      d.disabled?.(data) ? 'var(--light-10)' : 'transparent',
    )
    .attr('x', 0)
    .attr('y', (d, i) => i * ITEM_HEIGHT - ITEM_HEIGHT / 2);

  textGroup
    .append('text')
    .text((d) => d.text)
    .attr('x', 12)
    .attr('y', (d, i) => i * ITEM_HEIGHT)
    .attr('dominant-baseline', 'middle')
    .attr('class', styles.contextMenuItem);

  d3.select('body').on('click', (e) => {
    const outside = d3
      .select(CONTEXT_MENU_SELECTOR)
      .filter(function (_e, _a, b) {
        return this == e.target;
      })
      .empty();

    if (outside) {
      if (onClickOutside !== undefined) {
        onClickOutside();
      } else {
        contextMenuGroup.remove();
      }
    }
  });
}
