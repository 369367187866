import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchUserStatistics } from '@/bundles/UserManagement/actions/UserStatistics';
import { StatisticsVerticalLine } from '@/bundles/UserManagement/components/StatisticsVerticalLine';
import getBadgeColor from '@/bundles/UserManagement/components/helpers/getBadgeColor';
import USER_STATUS_LABELS from '@/bundles/UserManagement/components/helpers/userStatusLabels';

const UserStatistics = ({ fetchStatistics, statistics }) => {
  useEffect(() => {
    fetchStatistics();
  }, []);

  if (!statistics) return null;

  const total = Object.values(statistics).reduce((res, el) => res + el, 0);

  const availableStatuses = Object.keys(USER_STATUS_LABELS).filter(
    (key) => key !== 'all',
  );

  return (
    <div className="um-statistics-container flex bg-light-20 p-[1rem] rounded-[8px] gap-s">
      <div className="flex px-[1rem] flex-col justify-center">
        <div className="dark-60 header5-bold">{total}</div>
        <div className="light-60 secondary-regular uppercase">Total USERs</div>
      </div>
      <div className="flex">
        {availableStatuses.map((status) => (
          <StatisticsVerticalLine
            key={status}
            status={status}
            statistics={statistics}
            total={total}
          />
        ))}
      </div>
      <div className="um-stats-legend">
        {availableStatuses.map((status) => (
          <div className="secondary-regular light-60" key={status}>
            <div
              className="sre-icon-dot mr-s"
              style={{ backgroundColor: getBadgeColor(status) }}
            />
            {USER_STATUS_LABELS[status].toUpperCase()}
          </div>
        ))}
      </div>
    </div>
  );
};

UserStatistics.propTypes = {
  fetchStatistics: PropTypes.func.isRequired,
  statistics: PropTypes.shape({}),
};

UserStatistics.defaultProps = {
  statistics: null,
};

const mapStateToProps = ({ userManagement }) => ({
  statistics: userManagement.statistics,
});

const mapActionCreators = {
  fetchStatistics: fetchUserStatistics,
};

export default connect(mapStateToProps, mapActionCreators)(UserStatistics);
