import { cn } from '@/shared/lib/css/cn';
import dayjs, { Dayjs } from 'dayjs';
import { toOrdinal } from 'number-to-words';
import React, { forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import {
  getCellKey,
  getCurrentStartOfMonthByValue,
  getDatesArrayForCurrentMonth,
  getRowIndexFromCellKey,
  getRowKey,
} from 'stories/FlexibleFilterByPeriods/calendar/utils';
import {
  DAYS_IN_WEEK,
  EMPTY_WEEK_LENGTH_ARRAY,
  generatePrevPeriods,
  INFINITE_PERIODS,
} from 'stories/FlexibleFilterByPeriods/consts';

import { DATE_STRING_FORMAT } from '@/shared/lib/converters';
import { formatDate } from '@/shared/lib/formatting/dates';
import { isEmpty } from 'lodash-es';
import { Button } from '@/stories/Button/Button';
import { FlexibleFilterByPeriods } from '@/stories/FlexibleFilterByPeriods/FlexibleFilterByPeriods';
import { Icon } from '@/stories/Icon/Icon';
import {
  DEFAULT_DROPDOWN_OFFSET,
  Popover,
  PopoverProps,
  PopoverRef,
} from '@/stories/Popover/Popover';
import { CalendarCell } from '@/stories/FlexibleFilterByPeriods/calendar/CalendarCell';

import styles from '@/stories/FlexibleFilterByPeriods/calendar/Calendar.module.scss';
import CalendarPagination from '@/stories/FlexibleFilterByPeriods/calendar/CalendarPagination';

const Th = ({
  children,
  className,
}: React.PropsWithChildren<PropsWithClassName>) => (
  <th
    className={cn(
      'secondary-regular bg-white px-2 py-1 text-center text-light-90',
      className,
    )}
  >
    {children}
  </th>
);

interface Props {
  selectionMode?: 'weekly' | 'daily';
  value?: Dayjs | Dayjs[] | null;
  defaultValue?: Dayjs | Dayjs[] | null;
  onChange?: (value: Dayjs[]) => void;
  isDayDisabled?: (day: Dayjs) => boolean;
  allowFutureDates?: boolean;
  popoverProps?: Omit<PopoverProps, 'template'>;
  buttonProps?: React.ComponentProps<typeof Button>;
  closeOnDateUpdate?: boolean;
}

type Indexes = { rowIndex: number; cellIndex: number };

function checkIfDateIsAfterToday(date: Dayjs) {
  return date.isAfter(dayjs());
}

const Calendar = forwardRef<PopoverRef, Props>((props, forwardedRef) => {
  const internalRef = useRef<PopoverRef>(null);
  const popoverRef = (forwardedRef ??
    internalRef) as React.RefObject<PopoverRef>;
  const {
    defaultValue,
    selectionMode,
    allowFutureDates,
    popoverProps,
    buttonProps,
  } = props;
  const [internalValue, setInternalValue] = useState(defaultValue);
  const value = props.value === undefined ? internalValue : props.value;
  const [currentStartOfMonth, setCurrentStartOfMonth] = useState<Dayjs>(
    getCurrentStartOfMonthByValue(value ?? dayjs()),
  );
  const [hoveredCell, setHoveredCell] = useState<string | null>();

  const dates = useMemo(
    () => getDatesArrayForCurrentMonth(currentStartOfMonth),
    [currentStartOfMonth],
  );

  useEffect(() => {
    setCurrentStartOfMonth(getCurrentStartOfMonthByValue(value ?? dayjs()));
  }, [props.value]);

  const rowsCount = Math.floor(dates.length / DAYS_IN_WEEK);

  const onChange: Props['onChange'] = (newValue) => {
    if (props.value === undefined) {
      setInternalValue(newValue);
    }

    props.onChange?.(newValue);
  };

  const getDateByIndexes = ({ rowIndex, cellIndex }: Indexes): Dayjs =>
    dates[rowIndex * DAYS_IN_WEEK + cellIndex];

  const handleCellClick = ({ rowIndex, cellIndex }: Indexes) => {
    const date = getDateByIndexes({ rowIndex, cellIndex });
    onChange(
      selectionMode === 'weekly'
        ? [date.startOf('week'), date.endOf('week')]
        : [date],
    );

    if (props.closeOnDateUpdate) {
      popoverRef.current?.hide();
    }
  };

  const getCellSelected = ({ rowIndex, cellIndex }: Indexes) => {
    if (!value) {
      return false;
    }
    const date = getDateByIndexes({ rowIndex, cellIndex });
    if (selectionMode === 'weekly') {
      const [firstDayOfWeek] = value as unknown as Dayjs[];
      return firstDayOfWeek.isSame(date, 'week');
    }
    return date.isSame(value as Dayjs, 'day');
  };

  const getCellProps = ({
    rowIndex,
    cellIndex,
  }: Indexes): React.ComponentProps<typeof CalendarCell> => {
    const cellDate = getDateByIndexes({
      rowIndex,
      cellIndex,
    });
    const hovered =
      !isEmpty(hoveredCell) &&
      (selectionMode === 'weekly'
        ? getRowIndexFromCellKey(hoveredCell!) === rowIndex
        : hoveredCell === getCellKey(rowIndex, cellIndex));
    const disabled =
      (!allowFutureDates && checkIfDateIsAfterToday(cellDate)) ||
      props.isDayDisabled?.(cellDate);

    return {
      disabled,
      hovered,
      fromCurrentMonth: cellDate.isSame(currentStartOfMonth, 'month'),
      selected: getCellSelected({ rowIndex, cellIndex }),
      onClick: () =>
        handleCellClick({
          rowIndex,
          cellIndex,
        }),
    };
  };

  const monthSelector = useMemo(
    () => (
      <FlexibleFilterByPeriods
        popoverProps={{
          placement: 'bottom-start',
          appendTo: 'parent',
        }}
        filterByPeriodsType="mtd"
        closeOnMonthUpdate
        isSingleSelection
        periodItems={[
          {
            period: currentStartOfMonth.format(DATE_STRING_FORMAT),
            type: 'mtd',
          },
        ]}
        possiblePeriods={
          allowFutureDates ? INFINITE_PERIODS : generatePrevPeriods()
        }
        onUpdatePeriodItems={(items) =>
          setCurrentStartOfMonth(dayjs(items[0].period))
        }
      />
    ),
    [currentStartOfMonth],
  );

  const template = (
    <div className="flex w-max flex-col gap-4 bg-white p-2 pt-4">
      <div className="flex justify-between px-2">
        {monthSelector}
        <CalendarPagination
          disableOnNext={
            !allowFutureDates && currentStartOfMonth.isSame(dayjs(), 'month')
          }
          onCurrent={() => setCurrentStartOfMonth(dayjs().startOf('month'))}
          onBack={() =>
            setCurrentStartOfMonth((prev) => prev.subtract(1, 'month'))
          }
          onNext={() => setCurrentStartOfMonth((prev) => prev.add(1, 'month'))}
        />
      </div>
      <table
        className={cn(
          'w-[370px]',
          styles.roundedCorners,
          selectionMode === 'weekly' && styles.roundedCorners_weekly,
        )}
      >
        <thead>
          <tr>
            {selectionMode === 'weekly' && <Th>Week</Th>}
            {dayjs.weekdaysShort(true).map((weekday) => (
              <Th className="w-[calc(100%/7)]" key={`th-${weekday}`}>
                {weekday}
              </Th>
            ))}
          </tr>
        </thead>
        <tbody>
          {Array.from({ length: rowsCount }).map((_, rowIndex) => (
            <tr key={getRowKey(rowIndex)}>
              {selectionMode === 'weekly' && (
                <CalendarCell
                  {...getCellProps({ rowIndex, cellIndex: 0 })}
                  active
                  isRangeEdge
                  fromCurrentMonth
                >
                  {currentStartOfMonth.add(rowIndex, 'week').week()}
                </CalendarCell>
              )}
              {EMPTY_WEEK_LENGTH_ARRAY.map((__, cellIndex) => {
                const cellDate = getDateByIndexes({
                  rowIndex,
                  cellIndex,
                });
                const cellKey = getCellKey(rowIndex, cellIndex);
                return (
                  <CalendarCell
                    {...getCellProps({ rowIndex, cellIndex })}
                    key={cellKey}
                    onMouseEnter={() => setHoveredCell(cellKey)}
                    onMouseLeave={() => setHoveredCell(null)}
                  >
                    {cellDate.date()}
                  </CalendarCell>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  const getLabel = () => {
    if (!value) {
      return selectionMode === 'weekly' ? 'Select Week' : 'Select Day';
    }
    if (selectionMode === 'daily') {
      const [day] = value as Dayjs[];
      return formatDate(day, 'MMM-DD');
    }

    const [startOfWeek, endOfWeek] = value as Dayjs[];
    return `${toOrdinal(startOfWeek.date())}-${toOrdinal(
      endOfWeek.date(),
    )} ${startOfWeek.format('MMMM')}`;
  };

  return (
    <Popover
      ref={popoverRef}
      placement="bottom-start"
      hiddenArrow
      className="overflow-hidden !rounded-b-2xl !rounded-t-lg p-0"
      trigger="click"
      offset={DEFAULT_DROPDOWN_OFFSET}
      template={template}
      maxWidth="max-content"
      {...popoverProps}
    >
      {popoverProps?.children ?? (
        <Button
          variant="secondary"
          size="s"
          iconName="bottom"
          iconPosition="right"
          className="font-medium leading-none text-info-055"
          {...buttonProps}
        >
          <Icon iconName="calendar" className="text-neutral-450" />
          {getLabel()}
        </Button>
      )}
    </Popover>
  );
});

export default Calendar;
