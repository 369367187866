import useBoolean from '@/shared/lib/hooks/useBoolean';
import { useEffect, useMemo, useState } from 'react';

export const usePdfBlobCreateObjectURL = (pdfBlob: Blob | null) => {
  return useMemo(() => pdfBlob && URL.createObjectURL(pdfBlob), [pdfBlob]);
};

export const useFetchPreviewPdf = ({
  fetchCallback,
  effectDeps,
  skip,
}: {
  fetchCallback: () => Promise<{ data: Blob } | object>;
  skip: () => boolean;
  effectDeps: React.DependencyList;
}) => {
  const [pdfBlob, setPdfBlob] = useState<Blob | null>(null);
  const [error, setError] = useState();
  const {
    value: isLoading,
    setTrue: enableLoading,
    setFalse: disableLoading,
  } = useBoolean();

  useEffect(() => {
    if (skip() || isLoading) return;

    const fetchData = async () => {
      enableLoading();

      try {
        const res = await fetchCallback();

        if (!('data' in res)) throw new Error('No data in response');

        setPdfBlob(res.data);
      } catch (e) {
        setError(e);
        setPdfBlob(null);
      } finally {
        disableLoading();
      }
    };
    fetchData();
  }, effectDeps);

  return {
    isLoading,
    error,
    pdfBlob,
  };
};
