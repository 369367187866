import {
  PostApiSettingsReportFormulasApiResponse,
  PutApiSettingsReportFormulasByIdApiResponse,
  settingsReportFormulasApiGenerated,
} from '@/shared/api/settingsReportFormulasGeneratedApi';

const SETTINGS_REPORT_FORMULAS_TAG = 'SettingsReportFormulas';
const SETTINGS_REPORT_VARIABLES_TAG = 'SettingsReportVariables';
const SETTINGS_REPORT_FORMULA_TAGS_TAG = 'SettingsReportFormulaTags';

export const settingsReportFormulasApiEnhanced =
  settingsReportFormulasApiGenerated.enhanceEndpoints({
    addTagTypes: [
      SETTINGS_REPORT_FORMULAS_TAG,
      SETTINGS_REPORT_VARIABLES_TAG,
      SETTINGS_REPORT_FORMULA_TAGS_TAG,
    ],
    endpoints: {
      getApiSettingsReportFormulas: {
        providesTags: [SETTINGS_REPORT_FORMULAS_TAG],
      },
      deleteApiSettingsReportFormulaTagsById: {
        invalidatesTags: [
          SETTINGS_REPORT_FORMULAS_TAG,
          SETTINGS_REPORT_FORMULA_TAGS_TAG,
        ],
      },
      putApiSettingsReportFormulaTagsById: {
        invalidatesTags: [
          SETTINGS_REPORT_FORMULAS_TAG,
          SETTINGS_REPORT_FORMULA_TAGS_TAG,
        ],
      },
      postApiSettingsReportFormulaTags: {
        invalidatesTags: [SETTINGS_REPORT_FORMULA_TAGS_TAG],
      },
      getApiSettingsReportFormulaTags: {
        providesTags: [SETTINGS_REPORT_FORMULA_TAGS_TAG],
      },
      putApiSettingsReportFormulasById: {
        invalidatesTags: (res, meta) => {
          if (res == null) {
            return [];
          }
          return [
            SETTINGS_REPORT_FORMULAS_TAG,
            SETTINGS_REPORT_FORMULA_TAGS_TAG,
          ];
        },
        transformResponse: (
          response: PutApiSettingsReportFormulasByIdApiResponse,
        ) => {
          toastr.success('Formula Updated');
          return response;
        },
      },
      postApiSettingsReportFormulas: {
        invalidatesTags: (res, meta) => {
          if (res == null) {
            return [];
          }
          return [SETTINGS_REPORT_FORMULAS_TAG];
        },
        transformResponse: (
          response: PostApiSettingsReportFormulasApiResponse,
        ) => {
          toastr.success('Formula Created');
          return response;
        },
      },
      postApiSettingsReportFormulaTagsSync: {
        invalidatesTags: [SETTINGS_REPORT_FORMULA_TAGS_TAG],
      },
      getApiSettingsReportVariables: {
        providesTags: [SETTINGS_REPORT_VARIABLES_TAG],
      },
      deleteApiSettingsReportFormulasBulkDestroy: {
        invalidatesTags: [SETTINGS_REPORT_FORMULAS_TAG],
        transformResponse: (response) => {
          toastr.success('Formulas Deleted');
          return response;
        },
      },
      deleteApiSettingsReportFormulasById: {
        invalidatesTags: [SETTINGS_REPORT_FORMULAS_TAG],
        transformResponse: (response) => {
          toastr.success('Formula Deleted');
          return response;
        },
      },
      putApiSettingsReportFormulasReplaceAllReferences: {
        invalidatesTags: [SETTINGS_REPORT_FORMULAS_TAG],
        transformResponse: (response) => {
          toastr.success('References Updated');
          return response;
        },
      },
    },
  });

export const {
  useGetApiSettingsReportFormulasQuery,
  usePostApiSettingsReportFormulaTagsMutation,
  usePutApiSettingsReportFormulaTagsByIdMutation,
  useDeleteApiSettingsReportFormulaTagsByIdMutation,
  usePutApiSettingsReportFormulasByIdMutation,
  usePostApiSettingsReportFormulasMutation,
  useGetApiSettingsReportVariablesQuery,
  useGetApiSettingsReportFormulaTagsQuery,
  usePutApiSettingsReportFormulasReplaceAllReferencesMutation,
  useDeleteApiSettingsReportFormulasBulkDestroyMutation,
  usePostApiSettingsReportFormulaTagsSyncMutation,
  useDeleteApiSettingsReportFormulasByIdMutation,
} = settingsReportFormulasApiEnhanced;
