import { TitledCard } from 'stories/TitledCard/TitledCard';
import VerticalSeparator from 'bundles/Shared/components/VerticalSeparator/VerticalSeparator';
import React from 'react';
import {
  GetApiSettingsUserManagementUserActivitiesSummaryApiArg,
  useGetApiSettingsUserManagementUserActivitiesSummaryQuery,
} from 'bundles/Shared/entities/user/api/userActivityApi';
import { useAppSelector } from '@/shared/lib/hooks/redux';
import { selectUserActivitySummary } from 'bundles/Shared/entities/user/slices/userActivitySlice';
import { formatDate } from '@/shared/lib/formatting/dates';
import { TrendLabel } from 'bundles/Shared/shared/TrendLabel';
import { cn } from '@/shared/lib/css/cn';
import { AnimationLoader } from 'stories/AnimationLoader/AnimationLoader';
import { formatToDateStringForRequest } from '@/shared/lib/converters';

type ValueItem = {
  trendLabel: string;
  trend?: number;
  value?: number | string;
  oneItem?: boolean;
};

const ValueContainer = ({ value, trend, trendLabel, oneItem }: ValueItem) => (
  <div className="flex flex-1 flex-col items-center gap-2">
    <div className="text-[20px] font-medium text-neutral-850">{value ?? 0}</div>
    <div
      className={cn(
        'secondary-regular flex w-full items-center justify-between text-neutral-500',
        (!trend || oneItem) && 'justify-center gap-2',
      )}
    >
      <div className="font-medium">{trendLabel}</div>
      {Boolean(trend) && (
        <TrendLabel value={trend ?? 0}>
          <div className="whitespace-nowrap">vs last period</div>
        </TrendLabel>
      )}
    </div>
  </div>
);

const UserActivityCardsPanel = () => {
  const { fromDate, toDate, roleIds } = useAppSelector(
    selectUserActivitySummary,
  );

  const { data: summary, isLoading } =
    useGetApiSettingsUserManagementUserActivitiesSummaryQuery({
      fromDate: formatToDateStringForRequest(fromDate),
      toDate: formatToDateStringForRequest(toDate),
      userRoleIds:
        roleIds as GetApiSettingsUserManagementUserActivitiesSummaryApiArg['userRoleIds'],
    });

  const hasInternalAndExternalUsersSummary = Boolean(
    summary?.totalInternalUsers && summary?.totalExternalUsers,
  );

  return (
    <div className="flex gap-4">
      {isLoading && <AnimationLoader />}
      <TitledCard title="Sessions" className="flex-1">
        <div className="flex flex-grow items-center justify-center gap-6">
          <ValueContainer
            trendLabel="Visits"
            value={summary?.totalSessions}
            trend={summary?.sessionsTrend}
          />
          <VerticalSeparator size="1.25rem" />
          <ValueContainer
            trendLabel="Duration"
            value={formatDate(
              {
                second: summary?.totalDuration ?? 0,
              },
              'HH:mm:ss',
            )}
            trend={summary?.durationTrend}
          />
        </div>
      </TitledCard>
      <TitledCard title="Users" className="flex-1">
        <div className="flex flex-grow items-center justify-center gap-6">
          {Boolean(summary?.totalInternalUsers) && (
            <ValueContainer
              trendLabel="Internal"
              value={summary?.totalInternalUsers}
              trend={summary?.internalUsersTrend}
              oneItem={!hasInternalAndExternalUsersSummary}
            />
          )}
          {hasInternalAndExternalUsersSummary && (
            <VerticalSeparator size="1.25rem" />
          )}
          {Boolean(summary?.totalExternalUsers) && (
            <ValueContainer
              trendLabel="External"
              value={summary?.totalExternalUsers}
              trend={summary?.externalUsersTrend}
              oneItem={!hasInternalAndExternalUsersSummary}
            />
          )}
        </div>
      </TitledCard>
      <TitledCard title="Device" className="flex-1">
        <div className="flex flex-grow items-center justify-center gap-6">
          <ValueContainer
            trendLabel="Desktop"
            value={summary?.totalDesktopUsers}
            trend={summary?.desktopUsersTrend}
          />
          <VerticalSeparator size="1.25rem" />
          <ValueContainer
            trendLabel="Mobile"
            value={summary?.totalMobileUsers}
            trend={summary?.mobileUsersTrend}
          />
        </div>
      </TitledCard>
    </div>
  );
};

export default UserActivityCardsPanel;
