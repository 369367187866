import {
  TrailingPeriodType,
  TimePeriodType,
} from '@/bundles/Shared/widgets/dashboard/widgets/common/config';
import { SparklineCellRendererConfigParams } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/renderers/SparklineCellRenderer';
import { WidgetTableTextCellRendererConfigParams } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/renderers/TextCellRenderer';
import {
  DateValueDisplayOptions,
  NumericValueDisplayOptions,
  TextValueDisplayOptions,
  ValueDisplayOptions,
} from '@/shared/lib/formatting/displayOptions';

type FontStyles = 'bold' | 'normal';

export type TableVizConfigColumnGradientThreshold = {
  type: 'min' | 'max' | 'percentile' | 'percentage' | 'value';
  color: string;
  value?: number | null;
};

export type TableVizConfigGradientBackground = {
  type: 'gradient';
  threshold: TableVizConfigColumnGradientThreshold[];
  ignore_zeros?: boolean;
};

export type TableVizConfigColorBackground = {
  type: 'color';
  color: string;
};

export type TableVizConfigBackground =
  | TableVizConfigGradientBackground
  | TableVizConfigColorBackground;

export const isTableVizConfigGradientBackground = (
  background: TableVizConfigBackground,
): background is TableVizConfigGradientBackground =>
  background.type === 'gradient';

export const isTableVizConfigColorBackground = (
  background: TableVizConfigBackground,
): background is TableVizConfigColorBackground => background.type === 'color';

export type TableVizConfigComparisonRule =
  | 'negative_red_positive_green'
  | 'negative_green_positive_red'
  | 'negative_red';

export type TableVizConfigTextDisplayStrategy =
  | 'text_default'
  | 'text_full_history'
  | 'text_current_value';

export type TableVizConfigComparison =
  | TableVizConfigColumnComparison
  | TableVizConfigZeroComparison;

type TableVizConfigColumnComparison = {
  rule: TableVizConfigComparisonRule;
  to_col_id: string;
  hide_negative_values?: boolean;
};

export type TableVizConfigZeroComparison = {
  rule: TableVizConfigComparisonRule;
  to_col_id: undefined | null;
  hide_negative_values?: boolean;
};

export const isTableVizConfigZeroComparison = (
  comparison: TableVizConfigComparison | TableVizConfigZeroComparison,
): comparison is TableVizConfigZeroComparison => {
  return comparison.to_col_id == null;
};

export type BaseTableVizConfigColumn = {
  key: string;
  col_id: string;
  order: number;
  group_id?: string | undefined | null;
  column_group_show?: 'open' | null;
  initial_sort?: 'asc' | 'desc' | null;
  value_display_options?: ValueDisplayOptions;
  header?: {
    hide_title?: boolean;
    hide_subtitle?: boolean;
    subtitle?: string;
  };
  font_weight?: FontStyles | undefined;
  border_color?: string | undefined;
  background?: TableVizConfigColorBackground | undefined;
  align?: 'left' | 'center' | 'right' | undefined;
  hidden?: boolean;
  max_width?: number;
  min_width?: number;
};

export type NumericTableVizConfigColumn = BaseTableVizConfigColumn & {
  value_display_options: NumericValueDisplayOptions;
  background?: TableVizConfigBackground | undefined;
  comparison?: TableVizConfigComparison;
};

export type SparklineTableVizConfigColumn = BaseTableVizConfigColumn & {
  value_display_options: NumericValueDisplayOptions;
  cell_renderer: SparklineCellRendererConfigParams;
};

export type DateTableVizConfigColumn = BaseTableVizConfigColumn & {
  value_display_options: DateValueDisplayOptions;
};

export type TextTableVizConfigColumn = BaseTableVizConfigColumn & {
  value_display_options: TextValueDisplayOptions;
  cell_renderer?: WidgetTableTextCellRendererConfigParams;
};

export type TableVizConfigColumn =
  | NumericTableVizConfigColumn
  | DateTableVizConfigColumn
  | TextTableVizConfigColumn
  | SparklineTableVizConfigColumn;

export const isTextTableVizConfigColumn = (
  column: TableVizConfigColumn,
): column is TextTableVizConfigColumn =>
  column.value_display_options?.type === 'text';

export const isNumericTableVizConfigColumn = (
  column: TableVizConfigColumn,
): column is NumericTableVizConfigColumn =>
  column.value_display_options?.type !== 'date' &&
  column.value_display_options?.type !== 'text';

export const isDateTableVizConfigColumn = (
  column: TableVizConfigColumn,
): column is DateTableVizConfigColumn =>
  column.value_display_options?.type === 'date';

export const isSparklineTableVizConfigColumn = (
  column: TableVizConfigColumn,
): column is SparklineTableVizConfigColumn =>
  'cell_renderer' in column && column.cell_renderer?.type === 'sparkline';

export type TableVizConfigColumnGroup = {
  group_id: string;
  header_name: string;
  order: number;
  background?: string | undefined;
  border_color?: string | undefined;
  child_can_override_period?: boolean;
  icon?: string | undefined | null;
  hidden?: boolean;
};

export type BaseTableVizConfigRow = {
  order: number;
  key: string;
  value_display_options?: ValueDisplayOptions;
  font_weight?: FontStyles | undefined;
  background?: TableVizConfigColorBackground | undefined;
  comparison?: TableVizConfigComparison;
};

export type NumericTableVizConfigRow = BaseTableVizConfigRow & {
  value_display_options: NumericValueDisplayOptions;
  background?:
    | TableVizConfigColorBackground
    | TableVizConfigGradientBackground
    | undefined;
};

export type TextTableVizConfigRow = BaseTableVizConfigRow & {
  value_display_options: TextValueDisplayOptions;
  cell_renderer?: WidgetTableTextCellRendererConfigParams;
};

export type SparklineTableVizConfigRow = BaseTableVizConfigRow & {
  cell_renderer: SparklineCellRendererConfigParams;
};

export type DateTableVizConfigRow = BaseTableVizConfigRow & {
  value_display_options: DateValueDisplayOptions;
};

export type TableVizConfigRow =
  | BaseTableVizConfigRow
  | NumericTableVizConfigRow
  | DateTableVizConfigRow
  | TextTableVizConfigRow
  | SparklineTableVizConfigRow;

export const isSparklineTableVizConfigRow = (
  row: TableVizConfigRow,
): row is SparklineTableVizConfigRow =>
  'cell_renderer' in row && row.cell_renderer?.type === 'sparkline';

export const isTextTableVizConfigRow = (
  row: TableVizConfigRow,
): row is TextTableVizConfigRow => row.value_display_options?.type === 'text';

export const isNumericTableVizConfigRow = (
  row: TableVizConfigRow,
): row is NumericTableVizConfigRow =>
  row.value_display_options?.type !== 'date' &&
  row.value_display_options?.type !== 'text';

export type TableVizConfig = {
  column_groups: TableVizConfigColumnGroup[];
  columns: TableVizConfigColumn[];
  rows: TableVizConfigRow[];
  grid_options?: {
    group_default_expanded?: number;
  };
  header_background?: string | undefined;
};

export type TableBaseConfigColumn = {
  key: string;
  label: string;
};

export type TablePeriodConfigColumn = TableBaseConfigColumn & {
  period_type: TimePeriodType | TrailingPeriodType;
};
