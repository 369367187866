import { DEFAULT_NUMBER_FALLBACK } from '@/shared/lib/formatting/fallbacks';

const getFieldHead = (
  columnValidations: boolean[] = [],
  configGridProps: {
    deleteRow: boolean;
    editCell: boolean;
  },
) => {
  const headData = [
    {
      field: 'a',
      title: 'a',
      description: 'Item',
      width: 80,
      align: 'center',
      currency: false,
    },
    {
      field: 'b',
      title: 'b',
      description: 'Description of Work',
      width: 250,
      align: 'left',
      currency: false,
    },
    {
      field: 'c',
      title: 'c',
      description: 'Scheduled Value',
      width: 150,
      align: 'right',
      currency: true,
    },
    {
      field: 'd',
      title: 'd',
      description: 'From Previous Applicaton',
      formula: '(D + E)',
      width: 150,
      align: 'right',
      currency: true,
    },
    {
      field: 'e',
      title: 'e',
      description: 'Work completed + This Period In Place',
      width: 150,
      align: 'right',
      currency: true,
    },
    {
      field: 'f',
      title: 'f',
      description: 'Materials Presently Stored',
      formula: '(Not in D or E)',
      width: 150,
      align: 'right',
      currency: true,
    },
    {
      field: 'g',
      title: 'g',
      description: 'Total Completed and Stored',
      formula: '(D + E + F)',
      width: 150,
      align: 'right',
      currency: true,
    },
    {
      field: 'gDividedByC',
      title: 'g',
      description: '%',
      formula: 'G / C',
      width: 80,
      align: 'center',
      currency: false,
      editable: false,
    },
    {
      field: 'h',
      title: 'h',
      description: 'Balance to Finish',
      formula: '(C - G)',
      width: 150,
      align: 'right',
      currency: true,
    },
    {
      field: 'i',
      title: 'i',
      description: 'Retainage',
      width: 150,
      align: 'right',
      currency: true,
    },
  ];

  configGridProps.deleteRow &&
    headData.push({
      field: '_settings',
      title: DEFAULT_NUMBER_FALLBACK,
      description: 'Action',
      width: 50,
      align: 'center',
      currency: false,
      editable: false,
      deleted: true,
    });
  return headData.map((item, idx) =>
    columnValidations[idx] || item.field === '_settings'
      ? item
      : { ...item, error: true },
  );
};
export default getFieldHead;
