import http from '@/lib/http';

export const FETCH_CHANGE_ORDER_CONFIGS =
  'symmetre-client-api/FETCH_CHANGE_ORDER_CONFIGS';
export const CREATE_CHANGE_ORDER_CONFIG =
  'symmetre-client-api/CREATE_CHANGE_ORDER_CONFIG';
export const UPDATE_CHANGE_ORDER_CONFIG =
  'symmetre-client-api/UPDATE_CHANGE_ORDER_CONFIG';
export const REMOVE_CHANGE_ORDER_CONFIG =
  'symmetre-client-api/REMOVE_CHANGE_ORDER_CONFIG';

export function fetchChangeOrderConfigs() {
  return async (dispatch) => {
    const res = await http.get('/change_order_configs');

    const assets = await res.json();

    dispatch({ type: FETCH_CHANGE_ORDER_CONFIGS, payload: assets });
  };
}

export function createChangeOrderConfig(data) {
  return async (dispatch) => {
    const res = await http.post('/change_order_configs', data);

    const payload = await res.json();
    toastr.success('Config has been successfully created');

    dispatch({ type: CREATE_CHANGE_ORDER_CONFIG, payload });
  };
}

export function updateChangeOrderConfig(id, data) {
  return async (dispatch) => {
    const res = await http.put(`/change_order_configs/${id}`, data);

    const payload = await res.json();
    toastr.success('Config has been successfully updated');

    dispatch({ type: UPDATE_CHANGE_ORDER_CONFIG, payload });
  };
}

export function removeChangeOrderConfig(id) {
  return async (dispatch) => {
    await http.del(`/change_order_configs/${id}`);

    toastr.success('Config has been successfully removed');

    dispatch({ type: REMOVE_CHANGE_ORDER_CONFIG, payload: id });
  };
}
