import { Link, useParams } from '@reach/router';
import { FieldTypeView } from 'bundles/Shared/components/GroupForm/types/types';
import { FormItemHintTooltip } from 'stories/FormItemHintTooltip/FormItemHintTooltip';
import { formatAmount } from '@/shared/lib/formatting/number';
import { formatUnixDate } from '@/shared/lib/formatting/dates';
import { useNavigation } from '@/shared/lib/hooks/useNavigation';
import {
  categoryTransformer,
  reasonsTransformer,
} from '@/bundles/Construction/components/ChangeOrderEvent/FormComponents/transformer';
import { IChangeEvent } from 'bundles/Construction/types';
import { coeWeightedAmountFormatter } from '@/bundles/Construction/components/helpers';

export const getDataCEKeyinfo = (changeEvent: IChangeEvent) => [
  {
    titleView: 'Name or Title',
    fieldName: 'title',
    typeView: FieldTypeView.VIEW_TEXT,
    value: changeEvent?.title,
    styles: {
      wrapper: 'w-50 pr-s',
    },
  },
  {
    titleView: 'Detailed Description',
    fieldName: 'description',
    typeView: FieldTypeView.VIEW_TEXT,
    value: changeEvent?.description,
    styles: {
      wrapper: 'w-50 pr-s',
    },
  },
  {
    fieldName: 'changeOrderReasons',
    typeView: FieldTypeView.VIEW_TEXT,
    transformerView: reasonsTransformer,
    titleView: 'Reasons',
  },
  {
    fieldName: 'category',
    typeView: FieldTypeView.VIEW_TEXT,
    titleView: 'Event category',
    transformerView: categoryTransformer,
  },
];

export const getDataCEAdditionalDetails = (changeEvent: IChangeEvent) => {
  const {
    category,
    contractorDate,
    receivedDate,
    reconcileCompany,
    reconcileContact,
    sentToDorDate,
  } = changeEvent;
  return [
    ...(category !== 'IPC'
      ? [
          {
            fieldName: 'reconcileCompany',
            typeView: FieldTypeView.VIEW_TEXT,
            titleView: 'Submitted by Company',
            transformerView: () => reconcileCompany?.name,
          },
        ]
      : []),
    {
      fieldName: 'reconcileContact',
      typeView: FieldTypeView.VIEW_TEXT,
      titleView: 'Submitted by Contact',
      transformerView: () => reconcileContact?.name,
    },
    {
      fieldName: 'displayNumber',
      typeView: FieldTypeView.VIEW_TEXT,
      titleView: 'Number',
    },
    ...(category !== 'IPC'
      ? [
          {
            fieldName: 'contractorDate',
            typeView: FieldTypeView.VIEW_TEXT,
            titleView: 'Contractor date',
            transformerView: () =>
              formatUnixDate(contractorDate, 'MMM DD, YYYY'),
          },
          {
            fieldName: 'receivedDate',
            typeView: FieldTypeView.VIEW_TEXT,
            titleView: 'Received date',
            transformerView: () => formatUnixDate(receivedDate, 'MMM DD, YYYY'),
          },
        ]
      : []),
    ...(category === 'COR'
      ? [
          {
            fieldName: 'sentToDorDate',
            typeView: FieldTypeView.VIEW_TEXT,
            titleView: 'DOR date',
            emptyView: 'n/a',
            transformerView: () =>
              formatUnixDate(sentToDorDate, 'MMM DD, YYYY'),
          },
        ]
      : []),
  ];
};

export const getDataCEFinancialImpact = (changeEvent: IChangeEvent) => {
  const navigation = useNavigation();
  const params = useParams();
  const {
    probability,
    status,
    probabilityComment,
    amount,
    weightedAmount,
    verified,
    changeOrder,
    reallocation,
    legalEntityCode,
  } = changeEvent;
  const data = [
    {
      fieldName: 'amount',
      typeView: FieldTypeView.VIEW_TEXT,
      titleView: 'Amount',
      transformerView: formatAmount,
    },
    ...(reallocation
      ? [
          {
            fieldName: 'reallocation',
            typeView: FieldTypeView.VIEW_TEXT,
            titleView: 'Funded From',
            transformerView: () => (
              <Link
                to={navigation.getUrl(
                  'RECONCILE_DEVELOPMENT_LEGAL_ENTITY_REALLOCATION',
                  {
                    reallocationId: reallocation?.id,
                    legalEntityCode,
                  },
                )}
                className="second-link-color"
              >
                {reallocation?.name}
              </Link>
            ),
          },
          {
            fieldName: 'reallocation',
            typeView: FieldTypeView.VIEW_TEXT,
            titleView: 'REallocation Amount',
            transformerView: () =>
              coeWeightedAmountFormatter({
                probability,
                amount,
                weightedAmount,
                verified,
              }),
          },
        ]
      : []),
    ...(changeOrder?.id
      ? [
          {
            fieldName: 'changeOrder',
            typeView: FieldTypeView.VIEW_TEXT,
            titleView: 'Included in',
            transformerView: () => (
              <Link
                to={navigation.getUrl(
                  'RECONCILE_DEVELOPMENT_LEGAL_ENTITY_CHANGE_ORDER',
                  {
                    changeOrderId: changeOrder?.id,
                    legalEntityCode,
                  },
                )}
                className="second-link-color"
              >
                CO #{changeOrder?.number}
              </Link>
            ),
          },
        ]
      : []),
    ...(!changeEvent?.verified
      ? [
          {
            fieldName: 'probability',
            typeView: FieldTypeView.VIEW_TEXT,
            titleView: 'Probability',
            emptyView: 'n/a',
            transformerView: (v) =>
              status === 'VOID' ? (
                'n/a'
              ) : (
                <div className="flex">
                  <div className="mr-xs">{v}%</div>
                  {probabilityComment && (
                    <FormItemHintTooltip
                      hint={{
                        content: probabilityComment,
                      }}
                      hovered
                    />
                  )}
                </div>
              ),
          },
          {
            fieldName: 'weightedAmount',
            typeView: FieldTypeView.VIEW_TEXT,
            titleView: 'Weighted Amount',
            emptyView: formatAmount(0),
            transformerView: () =>
              coeWeightedAmountFormatter({
                probability,
                amount,
                weightedAmount,
                verified,
              }),
          },
        ]
      : []),
  ];
  return data;
};
