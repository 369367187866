import { reportingEnhancedApi } from '@/entities/reporting/api/reportingEnhancedApi';
import type { ReportGroupDto } from '@/entities/reporting/api/reportingGeneratedApi';
import { useReportingEntityKindContext } from '@/entities/reporting/context/entityKind';
import { useModal } from '@/shared/lib/hooks/useModal';
import { useMemo } from 'react';

export const useDeleteReportGroup = (onSuccess?: () => void) => {
  const reportKind = useReportingEntityKindContext();

  const deleteEagleEyeMutation =
    reportingEnhancedApi.useDeleteApiReportBuilderReportGroupsByIdMutation();

  const [deleteReportGroup, options] = useMemo(() => {
    switch (reportKind) {
      case 'eagle_eye': {
        return deleteEagleEyeMutation;
      }
      case 'object_level': {
        return [
          () => {
            console.error('Not supported yet');
          },
          { isLoading: false },
        ];
      }
    }
  }, [reportKind]);

  const { confirm } = useModal();

  const handleDelete = async (id: ReportGroupDto['id']) => {
    const bool = await confirm({
      title: 'Do you want to delete this Report Group',
    });

    if (!bool) return;

    const response = await deleteReportGroup({
      id,
    });

    if ((response != null && 'error' in response) || response == null) {
      console.error(response.error);
      return;
    }

    return onSuccess?.();
  };

  return [handleDelete, options] as const;
};
