import { ComponentProps, useEffect, useState } from 'react';
import { cn } from '@/shared/lib/css/cn';
import { Icon } from '@/stories/Icon/Icon';
import Tag from '@/stories/Tags/Tag/Tag';

interface Props
  extends Omit<
    ComponentProps<typeof Tag>,
    'startIcon' | 'endIcon' | 'variant'
  > {
  defaultExcluded?: boolean;
  onExclude?: () => void;
  onInclude?: () => void;
}

export const ReturnableTag = ({
  className,
  defaultExcluded,
  onInclude,
  onExclude,
  ...props
}: Props) => {
  const [excluded, setExcluded] = useState(defaultExcluded);
  const onClickInclude = () => {
    onInclude();
    setExcluded(false);
  };
  const onClickExclude = () => {
    onExclude();
    setExcluded(true);
  };

  useEffect(() => {
    setExcluded(defaultExcluded);
  }, [defaultExcluded]);

  return (
    <Tag
      className={cn(excluded && 'sre-tag_excluded', className)}
      excluded={excluded}
      endIcon={
        excluded ? (
          <Icon className="blue" iconName="unarchive" />
        ) : (
          <Icon className="light-60" iconName="closeSmall" />
        )
      }
      onClick={excluded ? onClickInclude : onClickExclude}
      {...props}
    />
  );
};

export default ReturnableTag;
