import { NotFound } from '@/bundles/Errors';
import {
  REPORT_BUILDER_DATE_FORMAT_MAP,
  useGetApiCoreAssetsByAssetIdReportBuilderReportsAndIdQuery,
} from '@/entities/report/reportBuilder';
import { useGetApiSettingsReportBuilderEagleEyeTemplatesMetaQuery } from '@/entities/report/reportBuilder/api/settingsReportBuilderTemplatesEnhancedApi';
import { resolveReportBuilderGroupHeader } from '@/entities/report/reportBuilder/lib/resolveReportBuilderGroupHeader';
import { ReportPDFHeader } from '@/entities/report/reportBuilder/ui/ReportPDFHeader';
import { reportingEnhancedApi } from '@/entities/reporting/api/reportingEnhancedApi';
import type { EagleEyeReportDto } from '@/entities/reporting/api/reportingGeneratedApi';
import { useEagleEyeReportIdParam } from '@/entities/reporting/lib/eagleEye';
import { useAssetIdSearchParam } from '@/shared/lib/hooks/navigation/useAssetIdSearchParam';
import { useSearchParams } from '@/shared/lib/hooks/navigation/useSearchParams';
import { mapListToIds } from '@/shared/lib/listHelpers';
import { PdfUI } from '@/shared/ui/PdfUI';
import { AnimationLoader } from '@/stories/AnimationLoader/AnimationLoader';
import { RouteComponentProps, useParams } from '@reach/router';
import {
  EagleEyeReportBuilderWidget,
  ObjectLevelReportBuilderWidget,
} from 'bundles/Shared/widgets/dashboard/widgetsHelpers/ui/ReportBuilderWidget';
import { FC } from 'react';

export const isReportBuilderViewWidgetTheLast = ({
  groupIndex,
  groupsLen,
  widgetIndex,
  widgetsLen,
}: {
  groupIndex: number;
  groupsLen: number;
  widgetIndex: number;
  widgetsLen: number;
}) => {
  return groupIndex === groupsLen - 1 && widgetIndex === widgetsLen - 1;
};

const getReportViewWidgetDateFormat = (
  params: Pick<EagleEyeReportDto, 'date' | 'dateFormat'>,
) =>
  params.dateFormat != null
    ? REPORT_BUILDER_DATE_FORMAT_MAP[params.dateFormat]?.dateFormatter(
        params.date as DateString,
      )
    : undefined;

export const ObjectLevelReportViewPage: FC<RouteComponentProps> = () => {
  const params = useParams();

  const [searchParams] = useSearchParams();
  const hidePlaceholders = Boolean(searchParams.get('hidePlaceholders'));

  const assetId = useAssetIdSearchParam();
  const { data, isLoading } =
    useGetApiCoreAssetsByAssetIdReportBuilderReportsAndIdQuery(
      {
        id: params.id,
        assetId: assetId!,
      },
      {
        skip: assetId == null,
      },
    );
  if (isLoading) return <AnimationLoader />;

  if (data?.report == null) {
    return <NotFound />;
  }

  return (
    <PdfUI>
      {data.report.groups.map((group, groupIndex, groups) => (
        <PdfUI.WidgetGroup
          hidePlaceholders={hidePlaceholders}
          {...group}
          key={group.id}
        >
          {resolveReportBuilderGroupHeader({
            displayHeaderGlobal: data.report.displayHeader,
            displayHeaderGroup: group.displayHeader,
          }) && (
            <ReportPDFHeader
              objectAddress={data.meta.asset?.address ?? ''}
              objectName={data.meta.asset?.name ?? ''}
              reportTitle={data.report.name}
              reportSubtitle={data.report.subtitle}
              reportDate={
                data.report.dateFormat != null
                  ? REPORT_BUILDER_DATE_FORMAT_MAP[
                      data.report.dateFormat
                    ]?.dateFormatter(data.report.date)
                  : undefined
              }
            />
          )}
          {group.widgets.map((w, widgetIndex, widgets) => (
            <ObjectLevelReportBuilderWidget
              reportData={data.report}
              key={w.id}
              widgetSection={w}
              pageOrientation={group.pageOrientation}
              isLastInGroup={isReportBuilderViewWidgetTheLast({
                groupIndex,
                groupsLen: groups.length,
                widgetsLen: widgets.length,
                widgetIndex,
              })}
            />
          ))}
        </PdfUI.WidgetGroup>
      ))}
    </PdfUI>
  );
};

// TODO dry with ObjectLevelReportViewPage
export const EagleEyeReportViewPage: FC<RouteComponentProps> = () => {
  const reportId = useEagleEyeReportIdParam();
  const { data: metaData, isLoading: isMetaDataLoading } =
    useGetApiSettingsReportBuilderEagleEyeTemplatesMetaQuery();

  const [searchParams] = useSearchParams();
  const hidePlaceholders = Boolean(searchParams.get('hidePlaceholders'));

  const { data, isLoading } =
    reportingEnhancedApi.useGetApiReportBuilderEagleEyeReportsByIdQuery(
      {
        id: reportId!,
      },
      {
        skip: reportId == null,
      },
    );
  if (isLoading || isMetaDataLoading) return <AnimationLoader />;

  if (data == null || metaData == null) {
    return <NotFound />;
  }

  return (
    <PdfUI>
      {data.groups.map((group, groupIndex, groups) => {
        // TODO remove `widgets` when BE & FE migrates to new endpoints
        const groupWidgets = group.widgets ?? group.widgetSections;

        return (
          <PdfUI.WidgetGroup
            hidePlaceholders={hidePlaceholders}
            {...group}
            key={group.id}
          >
            {resolveReportBuilderGroupHeader({
              displayHeaderGlobal: data.displayHeader,
              displayHeaderGroup: group.displayHeader,
            }) && (
              <ReportPDFHeader
                reportTitle={data.name}
                reportSubtitle={data.subtitle}
                reportDate={getReportViewWidgetDateFormat(data)}
              />
            )}
            {groupWidgets.map((w, widgetIndex) => (
              <EagleEyeReportBuilderWidget
                reportData={data}
                key={w.id}
                widgetSection={w}
                pageOrientation={group.pageOrientation}
                isLastInGroup={isReportBuilderViewWidgetTheLast({
                  groupIndex,
                  groupsLen: groups.length,
                  widgetsLen: groupWidgets.length,
                  widgetIndex,
                })}
                state={{
                  assets: mapListToIds(metaData.assets),
                  segments: mapListToIds(metaData.segments),
                }}
                context={{
                  assets: metaData.assets,
                  segments: metaData.segments,
                }}
              />
            ))}
          </PdfUI.WidgetGroup>
        );
      })}
    </PdfUI>
  );
};
