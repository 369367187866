/* eslint-disable react/jsx-wrap-multilines */
import { CapitalInvestment } from '@/entities/return';
import {
  AllocationContext,
  useAllocation,
} from 'bundles/REconcile/components/AllocationProvider';
import AllocationStatistics from 'bundles/REconcile/components/AllocationStatistics';
import { ReportLineItem } from 'bundles/REturn/actions/types';
import CapitalInvestmentsEditor, {
  TEditableCapitalInvestments,
} from 'bundles/REturn/components/Ownership/modals/capitalInvestmentsEditor/CapitalInvestmentsEditor';
import dayjs from 'dayjs';
import { formatDate } from '@/shared/lib/formatting/dates';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import { useCallback, useState } from 'react';
import { Button } from 'stories/Button/Button';
import { CurrencyFormatter } from 'stories/ValueFormatters/CurrencyFormatter';
import { Modal } from 'stories/Modals/Modal/Modal';
import { ModalHeaderWithIcon } from 'stories/Modals/ModalHeader/ModalHeaderWithIcon/ModalHeaderWithIcon';
import { EntyTxWithReviewInfo } from '@/bundles/REturn/components/workflows/createEntry/types';

import {
  convertCentsToDollars,
  DATE_STRING_FORMAT,
} from '@/shared/lib/converters';

interface Props extends DialogProps<EntyTxWithReviewInfo[]> {
  transaction: ReportLineItem;
  capitalInvestments: CapitalInvestment[];
}

export function SplitContribution({
  transaction,
  capitalInvestments,
  onClose,
  onSubmit,
}: Props) {
  const [editableContributions, setCapitalInvestments] = useState<
    TEditableCapitalInvestments[]
  >(
    capitalInvestments.map(
      ({ id, investmentEntity, legalEntity, ...capitalInvestment }) => ({
        id,
        investmentEntity,
        amount: 0,
        legalEntity,
        date: dayjs((investmentEntity.createdAt ?? 0) * 1000).format(
          DATE_STRING_FORMAT,
        ),
        totalAmount: capitalInvestment.totalContributionsDollars,
      }),
    ),
  );
  const updateItem = useCallback((index, amount) => {
    setCapitalInvestments((prev) =>
      prev.map((prevItem, i) => {
        if (index === i) {
          return {
            ...prevItem,
            amount: amount ?? 0,
          };
        }
        return prevItem;
      }),
    );
  }, []);

  const sumMapper = useCallback(
    (item: TEditableCapitalInvestments) => item.amount ?? 0,
    [],
  );

  const allocation = useAllocation({
    total: convertCentsToDollars(transaction.amountCents),
    items: editableContributions,
    sumMapper,
    updateItem,
  });
  const { total, allItemsAreAllocated, allocationFlags, allocatedAmount } =
    allocation;

  const resolveIsSubmitDisabled = () =>
    !allItemsAreAllocated || !allocationFlags.isFullyAllocated;

  const handleSubmit = () => {
    onSubmit?.(
      editableContributions.map((contrib) => ({
        amount: contrib.amount!,
        investmentId: contrib.id,
        accountingTransactionId: transaction.id,
        investmentName: contrib.investmentEntity.name,
        totalAmount: contrib.totalAmount,
      })),
    );
  };

  return (
    <Modal
      size="900"
      toggle={onClose}
      header={<ModalHeaderWithIcon title="Split Transaction" />}
      actions={
        <>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            disabled={resolveIsSubmitDisabled()}
            type="submit"
            variant="success"
          >
            Split Transaction and Continue
          </Button>
        </>
      }
    >
      <div className="-m-6 flex flex-col">
        <div className="gap-y-1.5 grid grid-cols-[repeat(2,94px)] items-center gap-x-8 border-b border-light-10 bg-white px-6 py-4">
          <p className="secondary-regular uppercase text-light-60">Amount</p>
          <p className="secondary-regular uppercase text-light-60">Date</p>
          <CurrencyFormatter
            value={convertCentsToDollars(transaction.amountCents)}
          />
          <p className="inline-regular text-dark-60">
            {formatDate(transaction.date, 'MMM DD, YYYY')}
          </p>
        </div>
        <div className="flex flex-col gap-4 bg-light px-6 py-4">
          <AllocationStatistics
            allocatedAmount={allocatedAmount}
            totalAmount={total ?? 0}
          />
          <AllocationContext.Provider value={allocation}>
            <CapitalInvestmentsEditor
              onCapitalInvestmentsChange={setCapitalInvestments}
              capitalInvestments={editableContributions}
            />
          </AllocationContext.Provider>
        </div>
      </div>
    </Modal>
  );
}
