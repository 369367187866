import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import ObjectAccessForm from '@/bundles/UserManagement/components/UserForm/ObjectAccessForm';
import LoadingPanel from '@/bundles/Shared/components/LoadingPanel';
import { permissionsFor, FUND_PORTAL_PRODUCT_NAME } from 'lib/permissions';

const FundAccess = ({ fields, setValue, funds }) => {
  const [filteredFunds, setFilteredFunds] = useState(funds);

  useEffect(() => {
    setFilteredFunds(funds);
  }, [funds]);

  if (!filteredFunds) return <LoadingPanel />;

  const onSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setFilteredFunds(
      funds.filter((fund) => fund.name.toLowerCase().includes(query)),
    );
  };

  let inputState;

  if (fields.userRole.name === 'Super Admin') {
    // NOTE: Force checked for Super Admins. Should we hide those tabs completely?
    inputState = () => ({
      disabled: true,
      disableLabel: null,
      checked: true,
      changeHandler: undefined,
    });
  } else {
    inputState = (rule, key) => {
      const result = {
        disabled: false,
        disableLabel: null,
        checked: undefined,
        changeHandler: undefined,
      };

      if (key === 'all') {
        if (rule === 'View') {
          result.disabled = fields.canManageAllFunds;
          result.checked = fields.canManageAllFunds || fields.canViewAllFunds;
          result.changeHandler = (e) =>
            setValue('canViewAllFunds', e.target.checked);
        } else if (rule === 'Manage') {
          result.checked = fields.canManageAllFunds;
          result.changeHandler = (e) =>
            setValue('canManageAllFunds', e.target.checked);
        }

        return result;
      }

      if (rule === 'View') {
        const forced =
          fields.canManageAllFunds ||
          fields.canViewAllFunds ||
          fields.canManageFundIds.includes(key);

        result.disabled = forced;
        result.checked = forced || fields.canViewFundIds.includes(key);
        result.changeHandler = (e) =>
          setValue(
            'canViewFundIds',
            e.target.checked
              ? [...fields.canViewFundIds, key]
              : fields.canViewFundIds.filter((f) => f !== key),
          );
      } else if (rule === 'Manage') {
        result.disabled = fields.canManageAllFunds;
        result.checked =
          fields.canManageAllFunds || fields.canManageFundIds.includes(key);
        result.changeHandler = (e) =>
          setValue(
            'canManageFundIds',
            e.target.checked
              ? [...fields.canManageFundIds, key]
              : fields.canManageFundIds.filter((f) => f !== key),
          );
      }
      return result;
    };
  }

  return (
    <ObjectAccessForm
      title="All Funds"
      rules={
        permissionsFor(fields.userRole, FUND_PORTAL_PRODUCT_NAME, 'can_manage')
          ? ['View', 'Manage']
          : ['View']
      }
      objects={filteredFunds}
      objectName="Fund"
      searchHandler={onSearch}
      inputState={inputState}
    />
  );
};

FundAccess.propTypes = {
  fields: PropTypes.shape().isRequired,
  setValue: PropTypes.func.isRequired,
  funds: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default FundAccess;
