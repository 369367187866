import { usePutApiReconcileForecastingForecastsByIdMutation } from '@/entities/reconcile/forecasting/api/reconcileForecastingEntitiesEnchancedApi';
import { ForecastingFormModal } from '@/entities/reconcile/forecasting/features/ui/ForecastingFormModal';
import { useModal } from '@/shared/lib/hooks/useModal';
import { generateUrl, ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import { useNavigate } from '@reach/router';
import { EntityToastrCreator } from '@/shared/lib/toastr/entityToastrCreator';
import { ForecastingForecast } from '@/entities/reconcile/forecasting/model';

const t = new EntityToastrCreator('Forecast');

export const useEditForecast = () => {
  const [editForecast, editOptions] =
    usePutApiReconcileForecastingForecastsByIdMutation();
  const { openModal } = useModal();
  const navigate = useNavigate();

  const handleEditForecast = async (forecast: ForecastingForecast) => {
    if (!forecast) return;

    const values = await openModal(ForecastingFormModal, {
      mode: 'edit',
      defaultValues: {
        name: forecast.name,
        label: forecast.label,
        description: forecast.description ?? '',
      },
    });

    if (!values) return;

    const res = await editForecast({
      id: forecast.id,
      body: values,
    });

    if ('error' in res) return;

    if (values.name !== forecast.name) {
      const path = generateUrl(
        ROUTES_ROOT.reconcile.forecasting.forecast.fullPath,
        {
          pathParams: {
            forecastName: values.name,
          },
        },
      );

      navigate(path, {
        replace: true,
      });
    }

    toastr.success(t.update().toString());
  };

  const options = {
    ...editOptions,
    isLoading: editOptions.isLoading,
  };

  return { editForecast: handleEditForecast, options };
};
