import * as React from 'react';
import { cn } from '@/shared/lib/css/cn';
import assetImagePlaceholder from 'images/building.svg';
import { IAsset } from 'types/Asset';
import styles from '@/bundles/Assets/components/Table/cellFormatters/name/NameCell.module.scss';
import useBoolean from '@/shared/lib/hooks/useBoolean';

function NameCell<
  A extends Pick<IAsset, 'smallPictureUrl' | 'name' | 'pictureUrl'>,
>({ asset }: { asset: A }) {
  const { smallPictureUrl, name, pictureUrl } = asset;
  const { value: error, setTrue } = useBoolean();

  return (
    <div className="flex items-center">
      <div
        className={cn(styles.imageContainer, {
          [styles.placeHolder]:
            (smallPictureUrl == null && pictureUrl == null) || error,
        })}
      >
        <img
          onError={setTrue}
          alt={name}
          src={
            error
              ? assetImagePlaceholder
              : (smallPictureUrl ?? pictureUrl ?? assetImagePlaceholder)
          }
          className={cn(styles.image)}
        />
      </div>
      <span className="header-6">{name}</span>
    </div>
  );
}

export default NameCell;
