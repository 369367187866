import { useAssertNullableContext } from '@/shared/lib/hooks/context/useAssertNullableContext';
import { createContext, useContext } from 'react';

type ReportBuilderTemplateContextType = {
  widgetId: string;
  templateId: string;
};
export const ReportBuilderTemplateContext =
  createContext<ReportBuilderTemplateContextType | null>(null);

export const useReportBuilderTemplateContext = () => {
  return useContext(ReportBuilderTemplateContext);
};
export const useAssertedReportBuilderTemplateContext = useAssertNullableContext(
  ReportBuilderTemplateContext,
);

type ReportBuilderTemplateGroupIdType = string;

export const ReportingWidgetGroupIdContext =
  createContext<ReportBuilderTemplateGroupIdType | null>(null);

export const useNullableReportingWidgetGroupIdContext = () => {
  return useContext(ReportingWidgetGroupIdContext);
};

export const useReportingWidgetGroupIdContext = useAssertNullableContext(
  ReportingWidgetGroupIdContext,
);

type ReportingWidgetIdType = string;

export const ReportingWidgetIdContext =
  createContext<ReportingWidgetIdType | null>(null);

export const useReportingWidgetIdContext = useAssertNullableContext(
  ReportingWidgetIdContext,
);
