import { CapitalInvestment } from '@/entities/return';
import { useGetApiCapitalInvestmentsQuery } from '@/entities/return/api/capitalInvestmentObjectsEnhancedApi';
import {
  GetApiCapitalInvestmentsApiArg,
  GetApiCapitalInvestmentsApiResponse,
} from '@/entities/return/api/capitalInvestmentObjectsGeneratedApi';
import { TRouteParams } from '@/shared/lib/hooks/useNavigation';
import { useParams } from '@reach/router';
import { createSelector } from '@reduxjs/toolkit';
import { useMemo } from 'react';
import { convertCentsToDollars } from '@/shared/lib/converters';

export function useCapitalInvestments(params?: GetApiCapitalInvestmentsApiArg) {
  const { objectId } = useParams<TRouteParams['RETURN_OWNERSHIPS_SCREEN']>();

  if (!objectId) throw new Error('"objectId" must exist in props');

  const prepareData = useMemo(() => {
    const emptyArray: GetApiCapitalInvestmentsApiResponse = [];
    return createSelector(
      (data: GetApiCapitalInvestmentsApiResponse | undefined) => {
        return data ?? emptyArray;
      },
      (investments) => {
        return investments.map(
          (item) =>
            ({
              ...item,

              totalContributionsDollars: convertCentsToDollars(
                item.totalContributionsCents,
              ),
            }) as CapitalInvestment,
        );
      },
    );
  }, []);

  return useGetApiCapitalInvestmentsQuery(
    {
      investmentObjectId: objectId,
      ...params,
    },
    {
      selectFromResult: ({ data, ...res }) => {
        return {
          ...res,
          data: prepareData(data),
        };
      },
    },
  );
}
