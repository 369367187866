import React, { useMemo } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { IDevelopmentBudgetDataRow } from 'bundles/REconcile/types/BudgetRows';
import useGroupWithConnectorColDef from 'bundles/Shared/components/AgGrid/GroupWithConnector/useGroupWithConnectorColDef';
import { ColDef } from 'ag-grid-community';
import { isNodeTotalFooter } from 'lib/ag-grid/utils';
import { cn } from '@/shared/lib/css/cn';
import {
  autoGroupColumnFormatterSimplified,
  PLACEHOLDER_COLUMN,
} from 'bundles/REconcile/components/development/editor/utils';
import { useGridDefaultParams } from 'lib/ag-grid/useGridDefaultParams';
import { AUTO_GROUP_COLUMN_KEY } from 'lib/ag-grid/constants';
import {
  DEFAULT_CELL_CLASS_RULES,
  getRowHeight,
} from 'bundles/REconcile/components/development/requisitions/shared/requisitionsTable/utils';
import { CurrencyFormatter } from 'stories/ValueFormatters/CurrencyFormatter';
import LoadingTable from 'bundles/Shared/components/LoadingTable';
import { useGetProjectSummaryQuery } from 'bundles/Construction/api/projectSummary';
import { useParams } from '@reach/router';
import { ExtractParams, ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';

interface IDevelopmentBudgetDataRowExtended extends IDevelopmentBudgetDataRow {
  path: string[];
}

function ProjectSummary() {
  const { legalEntityCode } =
    useParams<
      ExtractParams<
        typeof ROUTES_ROOT.reconcile.development.legalEntity.summary.fullPath
      >
    >();

  const { data, isLoading } = useGetProjectSummaryQuery({
    legalEntityCode,
  });

  const { onGridReady } = useGridDefaultParams({
    enableExpandOnClick: true,
    autosizeGroupColumnOnExpand: true,
    redrawNodeOnExpand: true,
  });

  const defaultColDef = useMemo<ColDef>(
    () => ({
      cellClassRules: DEFAULT_CELL_CLASS_RULES,
      suppressMenu: true,
      resizable: true,
    }),
    [],
  );

  const autoGroupColumnDef = useGroupWithConnectorColDef(
    {
      colId: AUTO_GROUP_COLUMN_KEY,
      headerClass:
        'bg-dark-60 ag-header-left secondary-semibold font-weight-600',
      headerName: '',
      pinned: true,
      valueFormatter: autoGroupColumnFormatterSimplified,
    },
    [],
  );

  const colDefs = useMemo<ColDef[]>(
    () => [
      ...(
        [
          {
            headerName: 'Initial Budget',
            field: 'budget_line_items',
          },
          {
            headerName: 'Reallocations',
            field: 'reallocations',
          },
          {
            headerName: 'Adjusted Budget',
            field: 'adjusted_budget',
          },
          {
            headerName: 'Requisitions',
            field: 'requisitions',
          },
          {
            headerName: 'Forecasted Spend',
            field: 'forecasted_spend',
          },
          {
            headerName: 'Total Spend',
            field: 'total_spend',
            valueGetter: ({ data }) =>
              data && Number(data.requisitions) + Number(data.forecasted_spend),
          },
          {
            headerName: 'Variance',
            valueGetter: ({ data }) =>
              data &&
              Number(data.adjusted_budget) -
                Number(data.requisitions) -
                Number(data.forecasted_spend),
            pinned: 'right',
          },
        ] satisfies ColDef[]
      ).map((col) => ({
        cellRenderer: ({ node, value }) => (
          <div className="flex justify-end w-full h-full">
            <CurrencyFormatter
              hideFractions
              classes={{
                allParts: isNodeTotalFooter(node) ? 'text-white' : undefined,
                wrapper: cn('leading-[inherit] secondary-regular'),
              }}
              value={value}
            />
          </div>
        ),
        aggFunc: 'sum',
        valueGetter: ({ data }) => data?.[col.field] && Number(data[col.field]),
        ...col,
      })),
      {
        colId: PLACEHOLDER_COLUMN,
        flex: 1,
      },
    ],
    [],
  );

  if (isLoading) return <LoadingTable rows={10} cols={5} />;

  return (
    <div className="h-full ag-grid-30">
      <AgGridReact
        className="ag-theme-alpine dark"
        suppressCellFocus
        groupDefaultExpanded={1}
        suppressContextMenu
        headerHeight={24}
        groupHeaderHeight={20}
        groupIncludeTotalFooter
        groupIncludeFooter
        defaultColDef={defaultColDef}
        onGridReady={onGridReady}
        autoGroupColumnDef={autoGroupColumnDef}
        getDataPath={(data: IDevelopmentBudgetDataRowExtended) => data.path}
        treeData
        rowData={data ?? []}
        columnDefs={colDefs}
        getRowHeight={getRowHeight}
      />
    </div>
  );
}

export default ProjectSummary;
