import { cn } from '@/shared/lib/css/cn';
import React from 'react';
import { Icon } from 'stories/Icon/Icon';
import FilterButton from '@/bundles/Shared/components/Filters/buttons/filterButton/FilterButton';
import styles from '@/bundles/Shared/components/Filters/buttons/universalFilterButton/UniversalFilterButton.module.scss';

type Props = React.ComponentProps<typeof FilterButton>;

function UniversalFilterButton({ className, children, ...props }: Props) {
  return (
    <FilterButton
      className={cn('secondary-semibold', styles.button, className)}
      classes={{
        container: styles.container,
        filtered: styles.button_filtered,
        clicked: styles.button_clicked,
      }}
      iconName="filtersFilledAlt"
      {...props}
    >
      {children}
      {props.filtered && <Icon iconName="bottom" />}
    </FilterButton>
  );
}

export default UniversalFilterButton;
