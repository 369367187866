import { cn } from '@/shared/lib/css/cn';
import { RowNode } from 'ag-grid-community';
import { ReactComponent as LongLine } from 'images/treeConnectors/from-sketch/connect-down-direction.svg';
import { ReactComponent as InnerLine } from 'images/treeConnectors/from-sketch/connect-inner-placement.svg';
import { ReactComponent as PointerDown } from 'images/treeConnectors/from-sketch/connect-pointer-down.svg';
import { ReactComponent as PointerEnd } from 'images/treeConnectors/from-sketch/connect-pointer-end.svg';
import { ReactComponent as LastLine } from 'images/treeConnectors/from-sketch/connect-right-direction.svg';

const rowMinHeight = 32;

const Empty = () => <div className="w-[16px]" />;

const PointerDownWithLongLine = () => (
  <div className="mt-1 flex flex-col">
    <PointerDown />
    <div className="flex h-[4px] w-[12px] justify-center">
      <LongLine className="!scale-y-0" />
    </div>
  </div>
);

const checkDeepForLastLine = (n: RowNode): boolean => {
  if (n.level !== 1 && !n.parent?.lastChild) return false;
  if (n.lastChild) return true;

  if (n.parent) {
    return checkDeepForLastLine(n.parent);
  }
  return false;
};

const checkDeepLastChild = (n: RowNode | null): boolean => {
  if (!n?.lastChild) return false;

  if (n.level === 1) return true;

  return checkDeepLastChild(n.parent);
};

const checkDeepForEmpty = (n: RowNode): boolean => {
  if (checkDeepLastChild(n)) return true;

  if (n.parent) {
    return checkDeepForEmpty(n.parent);
  }
  return false;
};

export type ConnectorProps = {
  node: RowNode;
  expanded: boolean;
  connectorsCount: number;
  index: number;
  withoutFooter?: boolean;
};

export default function Connector({
  node,
  expanded,
  connectorsCount,
  index,
  withoutFooter,
}: ConnectorProps) {
  const isLastConnector = index === connectorsCount - 1;
  const isExpandable = node.isExpandable();
  const hasChildren = node.hasChildren();
  const isFooter = node.footer;
  const isFirstBeforePointer = isExpandable && index === connectorsCount - 2;

  const showPointer = isExpandable && isLastConnector;
  const showInner = (!isExpandable && isLastConnector) || isFirstBeforePointer;
  const showLastLine = isFooter && isLastConnector;
  const rowHeight = node.rowHeight ?? rowMinHeight;

  const getSvgConnectorWithoutFooter = () => {
    if (isLastConnector) {
      if (!node.hasChildren()) {
        if (node.lastChild) return LastLine;

        return InnerLine;
      }

      if (isExpandable) {
        return expanded ? PointerDownWithLongLine : PointerEnd;
      }
    }

    if (node.level === index + 1) {
      if (checkDeepForLastLine(node)) return LastLine;

      return InnerLine;
    }

    if (checkDeepForEmpty(node)) return Empty;

    return LongLine;
  };

  const getSvgConnector = () => {
    if (showLastLine) return LastLine;

    if (showInner) return InnerLine;

    if (showPointer) return expanded ? PointerDown : PointerEnd;

    return LongLine;
  };

  const SvgConnector = withoutFooter
    ? getSvgConnectorWithoutFooter()
    : getSvgConnector();

  return (
    <>
      <div
        className={cn(
          'flex w-[16px] items-center justify-center',
          rowHeight > rowMinHeight && 'h-full self-start',
        )}
      >
        <SvgConnector />
      </div>
      {isLastConnector && !hasChildren && (
        <div
          className="relative h-[1px] w-4 before:absolute before:-left-[10px] before:h-[1px] before:w-5 before:bg-[#ACB3B6] before:content-['']
        "
        />
      )}
    </>
  );
}

export function ConnectorList(
  props: Pick<ConnectorProps, 'withoutFooter' | 'node' | 'expanded'>,
) {
  const nodeHasChildren = props.node.hasChildren();
  const connectorsCount = props.node.level + (nodeHasChildren ? 1 : 0);

  return (
    <>
      {Array.from({ length: connectorsCount }, (_, index) => (
        <Connector
          key={index}
          connectorsCount={connectorsCount}
          index={index}
          {...props}
        />
      ))}
    </>
  );
}
