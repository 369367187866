import React from 'react';
import { Badge } from 'stories/Badges/Badge/Badge';

import { ReportSegment } from 'bundles/Shared/entities/segment/model';

interface Props {
  segment: ReportSegment;
}

const StatusCell = ({ segment }: Props) => {
  const { state } = segment;

  const isPublished = state === 'published';

  const statusColor = isPublished ? 'var(--gr)' : 'var(--dark-60)';
  const statusLabel = isPublished ? 'Published' : 'Draft';

  return (
    <div>
      <Badge backgroundColor={statusColor}>{statusLabel}</Badge>
    </div>
  );
};

export default StatusCell;
