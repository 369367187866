import { ReportLineItem } from 'bundles/REturn/actions/types';
import pluralize from 'pluralize';
import { createReviewInfoMap } from '@/bundles/REturn/components/workflows/createEntry/utils';

interface Props {
  transactions: ReportLineItem[];
}

export function ReviewInfoText({ transactions }: Props) {
  const allGLNames: string[] = [];

  const generalLedgerInfoJSX = (
    <span className="inline-semibold">
      <span className="inline-regular"> from </span>
      General {pluralize('Ledger', allGLNames.length)}: {allGLNames.join(', ')}
    </span>
  );

  const reviewInfoMap = createReviewInfoMap({
    transactionsLen: transactions.length,
  });

  const pluralizeByTx = (word: string) => pluralize(word, transactions.length);
  return (
    <div className="flex flex-col gap-2">
      <h6 className="header6-bold text-dark-60">Review action</h6>
      <p className="inline-regular text-light-90">
        By {reviewInfoMap.workflowActionType} {pluralizeByTx('this')}{' '}
        {pluralizeByTx('transaction')}
        {allGLNames.length > 0 && generalLedgerInfoJSX}, you will update the{' '}
        {reviewInfoMap.reviewEndingText}:
      </p>
    </div>
  );
}
