import { CurrencyFormatterClasses } from 'stories/ValueFormatters/CurrencyFormatter';
import { CellTypeFlags } from '@/bundles/Shared/components/AgGrid/Table/classNames';

const isColoredFormula = (flags: CellTypeFlags): boolean =>
  flags.isBlueFormula ||
  flags.isGreyFormula ||
  flags.isDarkGreyFormula ||
  flags.isDarkFormula ||
  flags.isGreenFormula;

const ALL_PARTS_NEUTRAL_900 = {
  allParts: 'text-neutral-900',
} as const satisfies CurrencyFormatterClasses;

export function getCurrencyCellClasses(
  flags: CellTypeFlags,
): CurrencyFormatterClasses {
  if (flags.isFormula) {
    if (flags.isGreyFormula) {
      return ALL_PARTS_NEUTRAL_900;
    }

    if (isColoredFormula(flags)) {
      return {
        allParts: 'text-white',
      };
    }
  }

  if (flags.isFooter) {
    return {
      allParts: 'text-neutral-900 font-semibold',
    };
  }
  return ALL_PARTS_NEUTRAL_900;
}
