import React, { useEffect } from 'react';
import { navigate, Router, useParams } from '@reach/router';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import {
  createReallocation,
  fetchChangeOrders,
  fetchJCCForReallocations,
  fetchReallocations,
} from 'bundles/Construction/actions';
import ReallocationLogs from '@/bundles/Construction/components/Reallocation/ReallocationLogs';
import { AnimationLoader } from 'stories/AnimationLoader/AnimationLoader';
import CreateManualReallocationModal from 'bundles/Construction/components/Reallocation/Modals/createManualReallocationModal/CreateManualReallocationModal';
import CreateAutoReallocationModal from 'bundles/Construction/components/Reallocation/Modals/CreateAutoReallocationModal';
import Reallocation from 'bundles/Construction/components/Reallocation/Reallocation';

const ReallocationsContainer = () => {
  const legalEntity = useAppSelector(
    (state) => state.developmentBudget.legalEntity,
  );
  const asset = useAppSelector((state) => state.currentAsset);
  const changeOrders = useAppSelector(
    (state) => state.changeOrderManagement.changeOrders,
  );
  const reallocations = useAppSelector((state) => state.reallocations);
  const JCCForReallocations = useAppSelector(
    (state) => state.JCCForReallocations,
  );
  const dispatch = useAppDispatch();
  const params = useParams();

  useEffect(() => {
    dispatch(
      fetchReallocations(
        params?.legalEntityCode ||
          asset?.legalEntities[0].code ||
          legalEntity.code,
      ),
    );
    dispatch(
      fetchChangeOrders(
        params?.legalEntityCode ||
          asset?.legalEntities[0].code ||
          legalEntity.code,
      ),
    );
    dispatch(fetchJCCForReallocations());
  }, [legalEntity]);

  const handleSubmit = async (data) => {
    try {
      await dispatch(
        createReallocation(
          params?.legalEntityCode ||
            asset?.legalEntities[0].code ||
            legalEntity.code,
          data,
        ),
      );
    } catch (_e) {
      return false;
    }

    await dispatch(
      fetchReallocations(
        params?.legalEntityCode ||
          asset?.legalEntities[0].code ||
          legalEntity.code,
      ),
    );
    await dispatch(
      fetchChangeOrders(
        params?.legalEntityCode ||
          asset?.legalEntities[0].code ||
          legalEntity.code,
      ),
    );
    await navigate('.');
  };

  if (!reallocations || !changeOrders || !JCCForReallocations) {
    return <AnimationLoader />;
  }

  return (
    <Router primary={false}>
      <ReallocationLogs reallocations={reallocations} path="/">
        <CreateManualReallocationModal
          path="new-manual"
          JCCForReallocations={JCCForReallocations}
          onSubmit={handleSubmit}
        />
        <CreateAutoReallocationModal
          path="new-co"
          JCCForReallocations={JCCForReallocations}
          changeOrders={changeOrders}
          onSubmit={handleSubmit}
        />
        <Reallocation
          containerClassName="reconcile-entity-modal"
          path=":reallocationId"
        />
      </ReallocationLogs>
    </Router>
  );
};

export default ReallocationsContainer;
