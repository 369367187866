/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
/* eslint-disable import/prefer-default-export */
import * as d3 from 'd3';
import {
  INFO_TOOLTIP_SELECTOR,
  InfoTooltip,
} from 'stories/TreeView/InfoTooltip';
import { NODE_INFO_COMPONENT, NodeInfo } from 'stories/TreeView/NodeInfo';
import { Badge } from '@/stories/TreeView/Badge';
import {
  CHECKBOX_GROUP_COMPONENT,
  Checkbox,
} from '@/stories/TreeView/Checkbox';
import {
  CONTEXT_MENU_SELECTOR,
  ContextMenu,
} from '@/stories/TreeView/ContextMenu';
import { Link } from '@/stories/TreeView/Link';
import {
  LINKS_POPOVER_SELECTOR,
  LinksPopover,
} from '@/stories/TreeView/LinksPopover';
import {
  NODE_BACKGROUND_COMPONENT,
  NodeBackground,
} from '@/stories/TreeView/NodeBackground';
import {
  NODE_EXPANDER_GROUP_COMPONENT,
  NodeExpander,
} from '@/stories/TreeView/NodeExpander';
import { NODE_LINKS_COMPONENT, NodeLinks } from '@/stories/TreeView/NodeLinks';
import { NODE_TEXT_COMPONENT, NodeText } from '@/stories/TreeView/Text';
import {
  CONTAINER_GROUP_COMPONENT,
  LINKS_GROUP_COMPONENT,
  MULTIPLE_ROOTS_PARENT_ID,
  NODES_GROUP_COMPONENT,
  getComponentSelector,
} from '@/stories/TreeView/TreeView';
import styles from '@/stories/TreeView/TreeView.module.scss';
import type { IUpdateTreeParams } from '@/stories/TreeView/types';

const NODE_GAP = 6;
const TRANSITION_DURATION = 1;
const CIRCLE_RADIUS = 8;
const CIRCLE_LEFT_MARGIN = 6;
const TOP_PADDING_LINKS_POPOVER = 15;

export function updateTree(params: IUpdateTreeParams) {
  const {
    source,
    root,
    tree,
    svg: initialSvg,
    actions,
    handleOpenMoreLinkedItems,
    handleManageAssetClasses,
    icons,
    withCheckbox,
    onSelectedChange,
    everyNodeWithCheckbox,
    showMultipleRootsParent,
  } = params;
  const svg = d3.select(initialSvg);
  const containerGroup = svg.select(
    getComponentSelector(CONTAINER_GROUP_COMPONENT),
  );
  const linksGroup = svg.select(getComponentSelector(LINKS_GROUP_COMPONENT));
  const nodesGroup = svg.select(getComponentSelector(NODES_GROUP_COMPONENT));

  const duration = () => {
    return TRANSITION_DURATION;
  };

  // Compute the new tree layout.
  tree(root);

  const nodes = root
    .descendants()
    .reverse()
    .filter(
      (n) => showMultipleRootsParent || n.id !== MULTIPLE_ROOTS_PARENT_ID,
    );
  const links = root
    .links()
    .filter(
      (l) =>
        showMultipleRootsParent || l.source.id !== MULTIPLE_ROOTS_PARENT_ID,
    );

  const transition = svg
    .transition()
    .duration(duration)
    .tween(
      'resize',
      window.ResizeObserver ? null : () => () => svg.dispatch('toggle'),
    );

  // Update the nodes…
  const node = nodesGroup.selectAll('[id*=node-]').data(nodes, (_e, d) => d.id);

  // Enter any new nodes at the parent's previous position
  const nodeEnter = node
    .enter()
    .append('g')
    .attr('id', (_e, d) => `node-${d.id}`)
    .attr('transform', () => {
      return `translate(${source.y0},${source.x0})`;
    })
    .attr('class', styles.node);

  const nodeBgGroup = nodeEnter
    .call(NodeBackground, 'create', {
      cursorPointer: !everyNodeWithCheckbox,
    })
    .select(getComponentSelector(NODE_BACKGROUND_COMPONENT));

  if (withCheckbox) {
    nodeBgGroup
      .filter((d) => {
        if (d.id === MULTIPLE_ROOTS_PARENT_ID) return false;

        if (everyNodeWithCheckbox) {
          return !d.data.disableSelection;
        }

        return !d.data.disableSelection && d._children === undefined;
      })
      .call(Checkbox, 'create', {
        icon: icons.checkIcon,
        lockIcon: icons.lockIcon,
      });
  }
  nodeBgGroup
    .call(NodeLinks, 'create', {
      leftMargin: NODE_GAP,
      leftIcon: icons.linkIcon,
    })
    .select(getComponentSelector(NODE_LINKS_COMPONENT));

  nodeBgGroup
    .call(NodeInfo, 'create', {
      leftMargin: NODE_GAP,
      leftIcon: icons.infoIcon,
    })
    .select(getComponentSelector(NODE_INFO_COMPONENT));

  nodeBgGroup
    .call(NodeText, 'create')
    .select(getComponentSelector(NODE_TEXT_COMPONENT));

  nodeBgGroup
    .filter((g) => g._children != null && g._children.length > 0)
    .call(NodeExpander, 'create', {
      radius: CIRCLE_RADIUS,
      leftMargin: CIRCLE_LEFT_MARGIN,
      leftIcon: icons.leftIcon,
    });

  nodeEnter
    .filter((d) => d._children != null && d._children.length > 0)
    .call(Badge, 'create', {
      leftMargin: CIRCLE_LEFT_MARGIN,
    });

  // Transition nodes to their new position.
  const nodeUpdate = node.merge(nodeEnter);

  nodeUpdate
    .transition(transition)
    .attr('transform', (d) => `translate(${d.y},${d.x})`)
    .attr('fill-opacity', 1)
    .attr('stroke-opacity', 1);

  if (withCheckbox) {
    nodeUpdate
      .select(getComponentSelector(CHECKBOX_GROUP_COMPONENT))
      .on('click', (e, d) => {
        onSelectedChange?.(d.data);
      });
    nodeUpdate.call(Checkbox, 'update');
  }

  nodeUpdate // TODO enable once this task is done - https://linear.app/symmetre/issue/SRE-2686/extend-reconciledevelopmentcategory-with-linked-items-field
    .select(getComponentSelector(NODE_LINKS_COMPONENT))
    .on('mouseenter', (event, d) => {
      event.preventDefault();
      event.stopPropagation();

      const linksPopover = svg.select(
        getComponentSelector(LINKS_POPOVER_SELECTOR),
      );

      linksPopover.remove();
      LinksPopover(containerGroup, {
        items: d.data?.linkedItems,
        position: {
          x: d.y,
          y: d.x + TOP_PADDING_LINKS_POPOVER,
        },
        d,
        data: d.data,
        handleOpenMoreLinkedItems,
      });
    });

  nodeUpdate
    .select(getComponentSelector(NODE_TEXT_COMPONENT))
    .on('contextmenu', function (event, d) {
      if (actions !== undefined && d.id !== MULTIPLE_ROOTS_PARENT_ID) {
        event.preventDefault();
        event.stopPropagation();
        const contextMenu = d3.select(CONTEXT_MENU_SELECTOR);
        const bg = d3.select(this.parentNode).select('rect');
        const close = (outside?: boolean) => {
          d.clicked = false;
          svg.select(CONTEXT_MENU_SELECTOR).remove();
          // todo code smell
          if (outside) {
            bg.attr('fill', 'transparent');
          }
        };
        if (contextMenu.empty()) {
          d.clicked = true;
          bg.attr('fill', 'var(--light-30)');
          ContextMenu(containerGroup, {
            items: actions,
            position: {
              x: d.y,
              y: d.x + 30,
            },
            data: d.data,
            onClickOutside: () => close(true),
          });
        } else {
          close();
        }
      }
    });

  nodeUpdate.call(NodeText, 'update');

  nodeUpdate.call(NodeLinks, 'update', {
    leftMargin: NODE_GAP,
    leftIcon: icons.linkIcon,
  });

  nodeUpdate.call(NodeInfo, 'update', {
    leftMargin: NODE_GAP,
    leftIcon: icons.infoIcon,
  });

  nodeUpdate
    .select(getComponentSelector(NODE_INFO_COMPONENT))
    .on('mouseenter', (event, d) => {
      event.preventDefault();
      event.stopPropagation();

      const infoTooltip = svg.select(
        getComponentSelector(INFO_TOOLTIP_SELECTOR),
      );

      infoTooltip.remove();

      InfoTooltip(containerGroup, {
        position: {
          x: d.y,
          y: d.x + TOP_PADDING_LINKS_POPOVER,
        },
        d,
        data: d.data,
        handleManageAssetClasses,
      });
    });

  nodeUpdate
    .call(NodeExpander, 'update', {
      radius: CIRCLE_RADIUS,
      leftMargin: CIRCLE_LEFT_MARGIN,
      leftIcon: icons.leftIcon,
    })
    .select(getComponentSelector(NODE_EXPANDER_GROUP_COMPONENT));

  nodeUpdate
    .call(NodeBackground, 'update')
    .select(
      getComponentSelector(
        everyNodeWithCheckbox
          ? NODE_EXPANDER_GROUP_COMPONENT
          : NODE_BACKGROUND_COMPONENT,
      ),
    )
    .on('click', (e, d) => {
      d.children = d.children ? null : d._children;
      updateTree({
        ...params,
        source: d,
      });
    });

  nodeUpdate.call(Badge, 'update', {
    leftMargin: CIRCLE_LEFT_MARGIN,
  });

  nodeUpdate.each(function (d) {
    d.nodeBox = this.getBBox();
  });

  // Transition exiting nodes to the parent's new position.
  node
    .exit()
    .transition(transition)
    .remove()
    .attr('transform', () => `translate(${source.y},${source.x})`)
    .attr('fill-opacity', 0)
    .attr('stroke-opacity', 0);

  // Update the links…
  const link = linksGroup
    .selectAll('g[id*=path]')
    .data(links, (d) => d.target.data.id);

  link.call(Link, {
    source,
  });

  // Stash the old positions for transition.
  root.eachBefore((d) => {
    d.y0 = d.x;
    d.x0 = d.y;
  });
}
