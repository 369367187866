import React from 'react';
import { useParams } from '@reach/router';
import { approveChangeEvent } from 'bundles/Construction/reducers/ReconcileChangeEventSlice';
import { useAppDispatch } from '@/shared/lib/hooks/redux';
import { Button } from 'stories/Button/Button';

const ApprovalForm = () => {
  const params = useParams();
  const dispatch = useAppDispatch();
  const approveHandle = async () => {
    await dispatch(
      approveChangeEvent({
        legalEntityCode: params.legalEntityCode,
        id: params.id,
      }),
    );
  };

  return (
    <div>
      <div className="px-m py-m">
        <Button
          variant="success"
          iconName="acceptAlt"
          onClick={approveHandle}
          className="w-full"
        >
          Approve
        </Button>
      </div>
    </div>
  );
};

export default ApprovalForm;
