import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Range, useThumbOverlap, getTrackBackground } from 'react-range';
import { cn } from '@/shared/lib/css/cn';
import '@/stories/RangeSlider/rangeSlider.css';

function ThumbLabel({ rangeRef, values, index }) {
  const [labelValue, style] = useThumbOverlap(
    rangeRef,
    values,
    index,
    1,
    ' - ',
    (value) => `${value}`,
  );
  return (
    <div
      data-label={index}
      className="sre-range-slider-thumb__label"
      style={{ ...style }}
    >
      {labelValue}
    </div>
  );
}

export const RangeSlider = ({
  min,
  max,
  defaultValue,
  isDisabled,
  className,
  step,
  ...props
}) => {
  const [value, setValue] = useState(defaultValue);

  const rangeRef = useRef();
  const Thumb = ({ props, index }) => {
    if (index !== 1) {
      props.style = {
        ...props.style,
        backgroundColor: 'transparent',
      };
    }
    return (
      <div
        {...props}
        className="sre-range-slider-thumb"
        style={{
          ...props.style,
          backgroundColor: index !== 1 ? 'transparent' : '#fff',
          visibility: index !== 1 ? 'hidden' : 'visible',
        }}
      >
        <ThumbLabel
          rangeRef={rangeRef.current}
          values={[min, value, max]}
          index={index}
        />
        <div
          style={{
            backgroundColor: index !== 1 ? 'transparent' : 'var(--bl)',
          }}
          className="sre-range-slider-thumb__circle"
        />
      </div>
    );
  };
  const Track = ({ props, children, disabled }) => (
    <div
      onMouseDown={props.onMouseDown}
      onTouchStart={props.onTouchStart}
      style={{
        ...props.style,
      }}
      className={cn('sre-range-slider', className, {
        'sre-range-slider_disabled': disabled,
      })}
    >
      <div
        ref={props.ref}
        className="sre-range-slider__track"
        style={{
          background: disabled
            ? getTrackBackground({
                values: [min, value, max],
                colors: [
                  'var(--light-20)',
                  'var(--light-60)',
                  'var(--light-20)',
                  'var(--light-20)',
                ],
                min,
                max,
              })
            : getTrackBackground({
                values: [min, value, max],
                colors: [
                  'var(--light-20)',
                  'var(--bl)',
                  'var(--light-20)',
                  'var(--light-20)',
                ],
                min,
                max,
              }),
        }}
      >
        {children}
      </div>
    </div>
  );
  return (
    <Range
      ref={rangeRef}
      values={[min, value, max]}
      onChange={(result) => setValue(result[1])}
      renderThumb={Thumb}
      renderTrack={Track}
      min={min}
      max={max}
      disabled={isDisabled}
      step={step}
      onFinalChange={() => props.onFinish(value)}
      {...props}
    />
  );
};

function defaultValueValidation(props, propName, componentName) {
  const currentProp = props[propName];
  if (!currentProp) {
    return new Error(
      `Prop \`${propName}\` is required in ${componentName} component. Validation failed.`,
    );
  }
  if (currentProp < props.min || currentProp > props.max) {
    return new Error(
      `Invalid prop \`${propName}\` in ${componentName} component. It should get values between min and max props. Validation failed.`,
    );
  }
}

RangeSlider.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
  defaultValue: defaultValueValidation,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
  onFinish: PropTypes.func,
};
RangeSlider.defaultProps = {
  isDisabled: false,
  className: '',
  onFinish() {},
};

export default RangeSlider;
