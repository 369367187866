import React from 'react';
import FundCardHorizontal from 'bundles/Funds/components/FundCardHorizontal';
import { useGetApiPortfolioDetailsQuery } from '@/shared/api/portfolioEnhancedApi';

const Funds = () => {
  const { data } = useGetApiPortfolioDetailsQuery();
  const funds = data?.funds ?? [];
  return (
    <div>
      {funds.length !== 0 && (
        <>
          <h5 className="light-60 header5-regular mb-m">Funds</h5>
          {funds.map((fund) => (
            <FundCardHorizontal key={fund.id} editable={false} fund={fund} />
          ))}
        </>
      )}
    </div>
  );
};

export default Funds;
