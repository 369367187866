import { ListOption } from 'stories/Checkbox/CheckList';
import { OperationalMappingGroupByValue } from 'bundles/Shared/entities/sreUnitType';
import { ILegalEntity } from '@/types/LegalEntity';
import { IPaginationParams, IQueryParams } from 'types/Pagination';
import { ISortParams } from 'types/Sort';
import { useCallback, useState } from 'react';

export function useSettingReportOperationalPageParams() {
  const [pageParams, setPageParams] = useState<
    IQueryParams &
      IPaginationParams & {
        group: OperationalMappingGroupByValue[];
        sort: ISortParams<'legal_entity' | undefined>;
      } & {
        legalEntityIds: ILegalEntity['id'][];
      }
  >({
    page: 1,
    perPage: 50,
    group: [],
    query: '',
    legalEntityIds: [],
    sort: {
      field: undefined,
      order: 'asc',
    },
    sreUnitTypeIds: [],
    sreUnitStatusIds: [],
    sreChargeCodeIds: [],
    erps: [],
    pmcIds: [],
  });

  const setSortParams = (sort: (typeof pageParams)['sort']) => {
    setPageParams((prev) => ({
      ...prev,
      sort,
    }));
  };

  const setGroupByParam = useCallback((options: ListOption<string>[]) => {
    setPageParams((prev) => ({
      ...prev,
      page: 1,
      group: options.map(({ value }) => value) as (typeof pageParams)['group'],
    }));
  }, []);

  const setLegalEntityIds = (legalEntityIds: ILegalEntity['id'][]) => {
    setPageParams((prevParams) => ({
      ...prevParams,
      page: 1,
      legalEntityIds,
    }));
  };

  const setSreUnitTypeIds = (sreUnitTypeIds: string[]) => {
    setPageParams((prevParams) => ({
      ...prevParams,
      page: 1,
      sreUnitTypeIds,
    }));
  };

  const setSreUnitStatusIds = (sreUnitStatusIds: string[]) => {
    setPageParams((prevParams) => ({
      ...prevParams,
      page: 1,
      sreUnitStatusIds,
    }));
  };

  const setSreChargeCodeIds = (sreChargeCodeIds: string[]) => {
    setPageParams((prevParams) => ({
      ...prevParams,
      page: 1,
      sreChargeCodeIds,
    }));
  };

  const setErps = (erps: string[]) => {
    setPageParams((prevParams) => ({
      ...prevParams,
      page: 1,
      erps,
    }));
  };

  const setPmcIds = (pmcIds: string[]) => {
    setPageParams((prevParams) => ({
      ...prevParams,
      page: 1,
      pmcIds,
    }));
  };

  return {
    pageParams,
    setPageParams,
    setSortParams,
    setGroupByParam,
    setLegalEntityIds,
    setSreUnitTypeIds,
    setSreUnitStatusIds,
    setSreChargeCodeIds,
    setErps,
    setPmcIds,
  };
}
