import { Icon } from '@/stories/Icon/Icon';

export const createTransferFlowMap = ({ stepIdx }: { stepIdx: number }) => {
  const sharedProperties = {
    entryCreationText: 'Confirm Transfer',
  } as const;

  switch (stepIdx) {
    case 0: {
      return {
        ...sharedProperties,
        goBackwardButtonTextJSX: null,
        goForwardButtonText: 'Accept & Go Forward',
        goForwardHintTextJSX: null,
        entryCreationText: 'Transfer of ownership',
      } as const;
    }

    case 1: {
      return {
        ...sharedProperties,
        goBackwardButtonTextJSX: 'Back',
        goForwardButtonText: 'Accept & Go Forward',
        goForwardHintTextJSX: null,
        entryCreationText: 'Transfer of ownership',
      } as const;
    }

    case 2: {
      return {
        ...sharedProperties,
        goBackwardButtonTextJSX: (
          <div className="flex items-center gap-s">
            <Icon iconName="arrowLeft" className="text-neutral-500" />
            Back
          </div>
        ),
        goForwardButtonText: sharedProperties.entryCreationText,
        goForwardHintTextJSX: () => null,
      } as const;
    }

    default: {
      return {
        ...sharedProperties,
        goBackwardButtonTextJSX: 'Back',
        goForwardButtonText: sharedProperties.entryCreationText,
        goForwardHintTextJSX: () => null,
      } as const;
    }
  }
};
