import { Icon } from '@/stories/Icon/Icon';
import { Tooltip } from '@/stories/Tooltip/Tooltip';

export const DistributionNoteTooltip = ({
  note,
  classes,
}: {
  note: string;
  classes?: string;
}) =>
  note && note !== '' ? (
    <Tooltip mainText={note}>
      <Icon iconName="info" className={classes} />
    </Tooltip>
  ) : null;
