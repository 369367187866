import React, { useRef } from 'react';
import styles from 'bundles/Construction/components/eSignature/ESignPlacementModal.module.scss';
import { cn } from '@/shared/lib/css/cn';
import { Icon } from 'stories/Icon/Icon';
import { TPlacementKinds } from 'bundles/Construction/components/eSignature/types';

interface Props {
  value: string;
  kind: TPlacementKinds;
  color: {
    light: string;
    main: string;
  };
  x: number | string;
  y: number | string;
  onRemove?: () => void;
}

const SignaturePlacement = ({ x, y, onRemove, color, kind, value }: Props) => {
  const dropdownRef = useRef(null);

  if (!value) return null;

  return (
    <div
      className="position-absolute flex items-start group z-10"
      style={{ top: Number(y), left: Number(x) }}
      ref={dropdownRef}
    >
      <div
        className={cn(
          'flex items-center justify-between',
          styles.placedItem,
          styles[kind],
        )}
        style={{ backgroundColor: color.light, color: color.main }}
      >
        <span>{value}</span>
        {onRemove && (
          <Icon
            iconName="closeSmall"
            onClick={onRemove}
            className="ml-2 hidden group-hover:block"
          />
        )}
      </div>
    </div>
  );
};

export default SignaturePlacement;
