import { prepareItemNameForDuplicate } from '@/bundles/Shared/widgets/dashboard/card/lib';
import {
  usePostApiSettingsReportBuilderEagleEyeTemplatesByIdDuplicateMutation,
  usePostApiSettingsReportBuilderTemplatesByIdDuplicateMutation,
} from '@/entities/report/reportBuilder/api/settingsReportBuilderTemplatesEnhancedApi';
import type { ReportBuilderTemplateDto } from '@/entities/report/reportBuilder/api/settingsReportBuilderTemplatesGeneratedApi';
import { CreateReportBuilderTemplateModal } from '@/entities/report/reportBuilder/ui/CreateReportBuilderTemplateModal';
import { useModal } from '@/shared/lib/hooks/useModal';

export const useReportBuilderTemplateDuplicate = () => {
  const [duplicateTemplate, options] =
    usePostApiSettingsReportBuilderTemplatesByIdDuplicateMutation();
  const [duplicateEagleEyeTemplate, eagleEyeoptions] =
    usePostApiSettingsReportBuilderEagleEyeTemplatesByIdDuplicateMutation();
  const { openModal } = useModal();

  const isLoading = options.isLoading || eagleEyeoptions.isLoading;

  return [
    async (
      template: Pick<ReportBuilderTemplateDto, 'id' | 'name' | 'kind'>,
    ) => {
      const res = await openModal(CreateReportBuilderTemplateModal, {
        isDuplicating: true,
        template: {
          name: prepareItemNameForDuplicate(template.name),
          kind: template.kind,
        },
      });
      if (!res) {
        return;
      }
      if (res.kind === 'eagle_eye') {
        return duplicateEagleEyeTemplate({
          id: template.id,
          body: { name: res.name },
        });
      }
      return duplicateTemplate({ id: template.id, body: { name: res.name } });
    },
    { isLoading },
  ] as const;
};
