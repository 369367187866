import React, { FC, SetStateAction, useMemo } from 'react';
import { IconButton } from 'stories/IconButton/IconButton';
import { MultilineAlert } from 'stories/MultilineAlert/MultilineAlert';
import { SmallAddingButton } from 'stories/SmallAddingButton/SmallAddingButton';
import { getMarkupModal } from '@/bundles/Construction/components/ChangeOrderEvent/CostBreakdownDetails/initData';
import FormComponent from 'bundles/Shared/components/GroupForm/FormComponent/FormComponent';
import {
  calcAllowedMarkupValue,
  withinCushionedAmount,
} from '@/bundles/Construction/components/ChangeOrderEvent/CostBreakdownDetails/utils';
import {
  IChangeOrderMarkup,
  IFee,
  IMarkup,
} from 'bundles/Construction/components/ChangeOrderEvent/CostBreakdownDetails/types';

interface Props {
  changeOrderMarkups: IChangeOrderMarkup[];
  markupData: IMarkup[];
  setMarkupData: SetStateAction<IMarkup>;
  feeData: IFee[];
  setFeeData: SetStateAction<IFee>;
  invalidAmount?: number;
}

const MarkupModal: FC<Props> = ({
  changeOrderMarkups,
  invalidAmount,
  markupData,
  setMarkupData,
  feeData,
  setFeeData,
}) => {
  const transformChangeOrderMarkups = changeOrderMarkups.map((v) => ({
    value: v.id,
    label: `${v.code} - ${v.name}`,
    code: v.code,
    name: v.name,
    percent: v.percent,
    includedInFee: v.includedInFee,
  }));

  const formItems = useMemo(
    () => getMarkupModal(transformChangeOrderMarkups),
    [],
  );

  const setFormDataFunc = (newData, idx) => {
    const newGroupFormData = markupData.map((i, idGroup) => {
      if (idx === idGroup) {
        const includedInFee = changeOrderMarkups.find(
          (markup) => markup.id === i?.changeOrderMarkup,
        )?.includedInFee;
        if (includedInFee) {
          const newFeeData = feeData.map((fee: IFee) => ({
            ...fee,
          }));
          setFeeData(newFeeData);
        }
        return newData;
      }
      return i;
    });
    if (markupData) {
      setMarkupData(newGroupFormData);
    }
  };

  const removeItem = (idx) => {
    const newGroupFormData = markupData.filter(
      (item, idItem) => idItem !== idx,
    );
    setMarkupData(newGroupFormData);
  };

  return (
    <div>
      {markupData.map((formItem, idx) => {
        const [value, textMessage] = calcAllowedMarkupValue(
          changeOrderMarkups,
          formItem,
          invalidAmount,
        );

        const markupExceeded = !withinCushionedAmount(formItem.amount, value);
        return (
          <div key={`markup${idx}`}>
            {markupExceeded && formItem.changeOrderMarkup && (
              <MultilineAlert
                status="attention"
                icon="paid"
                message={
                  <div className="dark-60 inline-semibold">
                    Allowed Markups: {textMessage}
                  </div>
                }
                className="mb-m"
              />
            )}
            <FormComponent
              formData={formItem}
              setFormData={(newData) => setFormDataFunc(newData, idx)}
              formItems={formItems.map((item) => {
                if (item.fieldName === 'amount') {
                  return {
                    ...item,
                    error:
                      !formItem.comment && formItem.amount && markupExceeded
                        ? 'Allowable markup exceeded. You need to leave a comment'
                        : '',
                  };
                }
                return item;
              })}
              styles={{
                wrapper: 'group-form__step-item',
              }}
              unitNum={idx + 1}
              actions={
                <IconButton
                  iconName="closeSmall"
                  onClick={() => removeItem(idx)}
                />
              }
            />
          </div>
        );
      })}
      <SmallAddingButton
        onClick={() => {
          setMarkupData([
            ...markupData,
            {
              changeOrderMarkup: '',
              amount: '',
              comment: '',
            },
          ]);
        }}
      >
        Add Contractor Markups
      </SmallAddingButton>
    </div>
  );
};

export default MarkupModal;
