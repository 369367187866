import * as React from 'react';
import { capitalize } from 'lodash-es';
import { cn } from '@/shared/lib/css/cn';
import {
  IUser,
  TUserFireStatuses,
  TUserInviteStatus,
  USER_FIRE_STATUSES,
} from 'types/User';
import { formatUnixDate } from '@/shared/lib/formatting/dates';
import getBadgeColor from '@/bundles/UserManagement/components/helpers/getBadgeColor';
import UserInviteStatusDot from '@/bundles/UserManagement/components/inviteStatusDot/UserInviteStatusDot';
import ErrorMessage from '@/bundles/UserManagement/components/errors/errorMessage/ErrorMessage';
import styles from '@/bundles/UserManagement/components/table/cellFormatters/CellFormatters.module.scss';
import { IUserTableColumnsActions } from '@/bundles/UserManagement/components/table/userTableColumns';

interface Props {
  user: IUser;
  action: IUserTableColumnsActions['invite'];
}

const FIRE_STATUS_LABELS: Record<
  TUserFireStatuses,
  {
    text: string;
    action: string;
  }
> = {
  [TUserInviteStatus.CREATED]: {
    text: 'To send campaigns to user you need to invite this user',
    action: 'Send an Invite',
  },
  [TUserInviteStatus.INVITED_LONG_TIME_AGO]: {
    text: 'It looks like this user did not accept the last invitation',
    action: 'Invite again',
  },
};

function UserStatusCell({ user, action }: Props) {
  const {
    inviteStatus,
    invitationSentAt,
    deactivatedAt,
    lastSeenAt,
    createdAt,
  } = user;

  const getStatusLabel = () =>
    inviteStatus === TUserInviteStatus.INVITED_LONG_TIME_AGO
      ? 'EXPIRED'
      : capitalize(inviteStatus) || 'Active';

  const getStatusDate = () => {
    switch (inviteStatus) {
      case TUserInviteStatus.CREATED:
        return formatUnixDate(createdAt, 'LL');
      case TUserInviteStatus.INVITED:
        return formatUnixDate(invitationSentAt, 'LL');
      case TUserInviteStatus.DEACTIVATED:
        return formatUnixDate(deactivatedAt, 'LL');
      case TUserInviteStatus.INVITED_LONG_TIME_AGO:
        return `Invited ${formatUnixDate(invitationSentAt, 'LL')}`;
      default:
        return lastSeenAt && `Last seen ${formatUnixDate(lastSeenAt, 'LL')}`;
    }
  };

  return (
    <div
      className={cn('p-s', {
        [styles.fired]: (USER_FIRE_STATUSES as ReadonlyArray<string>).includes(
          inviteStatus,
        ),
      })}
    >
      <div className="flex  items-center m-0 pl-s">
        <UserInviteStatusDot inviteStatus={inviteStatus} />
        <div className="flex flex-col ml-s">
          <span
            className="inline-semibold"
            style={{ color: getBadgeColor(inviteStatus) }}
          >
            {getStatusLabel()}
          </span>
          <span className="light-60 label-regular">{getStatusDate()}</span>
        </div>
      </div>
      {(USER_FIRE_STATUSES as ReadonlyArray<string>).includes(inviteStatus) && (
        <ErrorMessage
          className="mt-s"
          actionLabel={FIRE_STATUS_LABELS[inviteStatus].action}
          onActionClick={() => action(user)}
          text={FIRE_STATUS_LABELS[inviteStatus].text}
        />
      )}
    </div>
  );
}

export default UserStatusCell;
