import { ReportingEntityKindContext } from '@/entities/reporting/context/entityKind';
import { ReportingEntityStageContext } from '@/entities/reporting/context/entityStage';
import { ReportBuilderTemplateBuilder } from '@/widgets/reportBuilder/reportBuillderTemplate/builder';
import { type RouteComponentProps } from '@reach/router';
import type { FC } from 'react';

export const ReportBuilderTemplatePage: FC<RouteComponentProps> = () => {
  return (
    <ReportingEntityKindContext.Provider value="object_level">
      <ReportingEntityStageContext.Provider value="template">
        <ReportBuilderTemplateBuilder />
      </ReportingEntityStageContext.Provider>
    </ReportingEntityKindContext.Provider>
  );
};
