import React, { useState } from 'react';
import { Modal } from '@/stories/Modals/Modal/Modal';
import {
  TabHeader,
  TabNavigation,
} from '@/widgets/userProfile/ui/UpdateUserModal';
import { BasicTab } from '@/widgets/investmentEntity/tabs/BasicTab';
import { AddressTab } from '@/widgets/investmentEntity/tabs/AddressTab';
import { BankDetailsTab } from '@/widgets/investmentEntity/tabs/BankDetailsTab';
import { TaxDetailsTab } from '@/widgets/investmentEntity/tabs/TaxDetailsTab';
import { InvestmentEntityDto } from '@/shared/api/investmentEntityGeneratedApi';
import { useBoolean } from 'usehooks-ts';

const TABS = [
  {
    key: 'basic',
    label: 'Basic',
  },
  {
    key: 'address',
    label: 'Address',
  },
  {
    key: 'bankDetails',
    label: 'Bank Details',
  },
  {
    key: 'taxDetails',
    label: 'Tax Details',
  },
] as const;

type TabKey = (typeof TABS)[number]['key'];

export const ManageInvestmentEntityModal = ({
  currentItem,
  onClose,
}: {
  onClose: () => void;
  currentItem?: InvestmentEntityDto;
}) => {
  const [activeTab, setActiveTab] = useState<TabKey>('basic');
  const { value: newRecord, setFalse: setNewRecordFalse } = useBoolean(
    !currentItem?.id,
  );

  return (
    <Modal
      toggle={onClose}
      size="1000"
      classes={{
        body: 'flex min-h-[600px]',
      }}
    >
      <div className="border-right w-[250px] border-r bg-light-5 pr-12">
        <TabNavigation
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          tabs={TABS.map((tab) => ({
            ...tab,
            disabled: newRecord && tab.key !== 'basic',
          }))}
        />
      </div>

      <div className="relative flex-1 pl-l">
        <TabHeader
          activeTab={activeTab}
          onClose={onClose}
          label="Investment Entity"
          tabs={TABS}
        />

        <div className="my-4">
          {activeTab === 'basic' && (
            <BasicTab
              item={currentItem}
              isNewRecord={newRecord}
              handleCreateRecord={setNewRecordFalse}
            />
          )}
          {activeTab === 'address' && <AddressTab item={currentItem} />}
          {activeTab === 'bankDetails' && <BankDetailsTab item={currentItem} />}
          {activeTab === 'taxDetails' && <TaxDetailsTab item={currentItem} />}
        </div>
      </div>
    </Modal>
  );
};

export default ManageInvestmentEntityModal;
