import { ManagementPeriodItem } from '@/bundles/Settings/components/Portal/LegalEntities/components/ManagementPeriodItem';
import { DATA_SOURCE_TYPE_NAMES } from '@/bundles/Shared/entities/connection/config';
import { TConnectionReportType } from '@/bundles/Shared/entities/connection/model';
import { LegalEntity } from '@/entities/core/legalEntity';
import { cn } from '@/shared/lib/css/cn';
import { diffDates } from '@/shared/lib/date';
import { Popover } from '@/stories/Popover/Popover';
import React, {
  cloneElement,
  isValidElement,
  useCallback,
  useMemo,
} from 'react';

type LegalEntityManagmentPeriodsItemProps = {
  type: TConnectionReportType;
  dataSources?: LegalEntity['managementPeriods'];
  disabled?: boolean;
} & PropsWithClassName;

export const LegalEntityManagmentPeriodsItem = (
  props: React.PropsWithChildren<LegalEntityManagmentPeriodsItemProps>,
) => {
  const { dataSources, type, children, disabled, className } = props;

  const sortedByDateFrom = useMemo(() => {
    if (!dataSources?.length) return [];

    return dataSources.toSorted((a, b) => {
      if (!a.dateFrom && !b.dateFrom) return 0;
      if (!a.dateFrom) return 1;
      if (!b.dateFrom) return -1;
      return diffDates(a.dateFrom, b.dateFrom);
    });
  }, [dataSources]);

  const renderContent = useCallback(() => {
    if (disabled) return null;
    if (!sortedByDateFrom.length) return null;

    return (
      <div
        className={cn('min-w-[12.5rem]', className)}
        aria-disabled={disabled}
      >
        <div className="p-2 text-xs font-semibold leading-none text-neutral-550">
          {DATA_SOURCE_TYPE_NAMES[type] || 'Data Sources'}
        </div>
        {sortedByDateFrom.map((source) => (
          <ManagementPeriodItem
            className={'border-b border-neutral-150 last:border-0'}
            key={source.id}
            {...source}
          />
        ))}
      </div>
    );
  }, [sortedByDateFrom, disabled, type]);

  if (disabled) {
    if (isValidElement<React.HTMLAttributes<HTMLElement>>(children)) {
      return cloneElement<React.HTMLAttributes<HTMLElement>>(children, {
        'aria-disabled': disabled,
      });
    }

    return null;
  }

  return (
    <Popover
      template={renderContent()}
      maxWidth="20rem"
      appendTo={document.body}
      placement="top-start"
      arrowPosition="start"
      trigger="mouseenter"
      offset={[0, 8]}
      className="p-2 text-sm"
    >
      {children}
    </Popover>
  );
};
