import React, { FC, useState } from 'react';
import { cn } from '@/shared/lib/css/cn';
import styles from '@/bundles/DrawPackage/InvoicesFlow/Invoices/verify/eSign/stamp/ColorPicker.module.scss';
import { getCssVariableByAccessor } from 'lib/helpers';
import { CssVar } from '@/shared/config/cssVar';

interface IColorPickerProps {
  color: string;
  handleChange: (color: string) => void;
}

const DEFAULT_COLOR = [
  {
    title: 'Black',
    value: getCssVariableByAccessor(CssVar.black),
  },
  {
    title: 'Grey',
    value: getCssVariableByAccessor(CssVar.light60),
  },
  {
    title: 'Green',
    value: getCssVariableByAccessor(CssVar.green),
  },
  {
    title: 'Blue',
    value: getCssVariableByAccessor(CssVar.blue),
  },
  {
    title: 'Purple',
    value: getCssVariableByAccessor(CssVar.violet),
  },
  {
    title: 'Yellow',
    value: getCssVariableByAccessor(CssVar.yellow),
  },
  {
    title: 'Red',
    value: getCssVariableByAccessor(CssVar.red),
  },
  {
    title: 'Pumpkin',
    value: getCssVariableByAccessor(CssVar.pumpkin),
  },
  {
    title: 'Brown',
    value: getCssVariableByAccessor(CssVar.yellowDark5),
  },
];

const ColorPicker: FC<IColorPickerProps> = ({ color, handleChange }) => {
  const [open, setOpen] = useState(false);

  return (
    <div>
      {open && (
        <div className={styles.overLayer} onClick={() => setOpen(false)} />
      )}
      <div className={styles.wrapper}>
        <div
          style={{
            backgroundColor: color,
          }}
          className={cn(
            'border-3 h-[20px] w-[20px] cursor-pointer rounded-md border-light-20',
          )}
          onClick={() => setOpen(!open)}
        />
        {open && (
          <div className="absolute bottom-0 left-[100%] flex flex-col gap-1 rounded-lg bg-white p-2">
            <div className="dark-60 inline-semibold">Color</div>
            <div className="flex flex-col gap-2">
              {DEFAULT_COLOR.map((colorItem, idx) => (
                <div
                  key={`color${idx}`}
                  className="flex cursor-pointer items-center gap-2"
                  onClick={() => {
                    handleChange(colorItem.value);
                    setOpen(false);
                  }}
                >
                  <div
                    style={{
                      backgroundColor: colorItem.value,
                    }}
                    className={cn(
                      'h-[20px] w-[20px] rounded-md border-2 border-light-5',
                    )}
                  />
                  <div className="dark-60 inline-regular">
                    {colorItem.title}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ColorPicker;
