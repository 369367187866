import * as yup from 'yup';
import { VALUE_DISPLAY_OPTIONS_SCHEMA } from '@/shared/lib/formatting/displayOptions';
import {
  PERIOD_SHIFT_SCHEMA,
  PERIOD_TYPE_SCHEMA,
} from 'bundles/Shared/widgets/dashboard/widgets/common/config';
import { transformEmptyObjectToUndefined } from '@/shared/lib/yup';
import {
  KpiCardWidgetConfig,
  KpiCardWidgetVizConfigKpi,
} from 'bundles/Shared/widgets/dashboard/widgets/kpiCard';
import {
  transformPeriodShiftDtoToForm,
  transformPeriodTypeDtoToForm,
} from 'bundles/Shared/widgets/dashboard/widgets/common/lib/config';
import { TEXT_DISPLAY_STRATEGY_DEFAULT_FIELD_SCHEMA } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/fields/TextDisplayStrategyField';

export const KPI_CARD_WIDGET_CONFIG_ROW_SCHEMA = yup
  .object()
  .shape({
    key: yup.number(),
    label: yup.string().required(),
    expression: yup.string().required(),
    value_display_options: VALUE_DISPLAY_OPTIONS_SCHEMA.nullable().required(),
    period_type: PERIOD_TYPE_SCHEMA.required(),
    period_shift: PERIOD_SHIFT_SCHEMA.nullable()
      .optional()
      .transform(transformEmptyObjectToUndefined)
      .default(undefined),
    type: yup.string().oneOf(['text', 'numeric']).required(),
  })
  .concat(TEXT_DISPLAY_STRATEGY_DEFAULT_FIELD_SCHEMA);

export type KpiCardWidgetRowForm = yup.InferType<
  typeof KPI_CARD_WIDGET_CONFIG_ROW_SCHEMA
>;
export const transformKpiCardRowConfigToForm = (
  rowConfig: KpiCardWidgetConfig['kpis'][number],
  rowSettings: KpiCardWidgetVizConfigKpi,
): KpiCardWidgetRowForm => {
  return {
    key: rowConfig.key,
    label: rowConfig.label,
    type: rowConfig.type,
    period_shift: transformPeriodShiftDtoToForm(rowConfig.period_shift),
    period_type: transformPeriodTypeDtoToForm(rowConfig.period_type),
    value_display_options: rowSettings.value_display_options,
    expression: rowConfig.expression,
    text_display_strategy: rowSettings.cell_renderer?.params?.variant,
  };
};
