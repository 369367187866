import React, { FC } from 'react';
import { CheckpointsSectionItem } from '@/stories/CheckpointsSectionItem/CheckpointsSectionItem';
import { cn } from '@/shared/lib/css/cn';
import styles from '@/stories/CheckpointsSection/CheckpointsSection.module.scss';

interface ICheckpointsSectionProps {
  title: string;
  data: {
    label: string;
    checkpoint: {
      title: string;
      check: boolean;
      onClick: (title: string, check: boolean) => void;
    }[];
  }[];
}

export const CheckpointsSection: FC<ICheckpointsSectionProps> = ({
  title,
  data,
}) => (
  <div>
    <div
      className={cn('inline-semibold', styles['sre-checkpoint-section__title'])}
    >
      {title}
    </div>
    {data.map((item, idx) => (
      <div
        key={`checkpoints${idx}`}
        className={styles['sre-checkpoint-section__block']}
      >
        <div className={styles['sre-checkpoint-section__subtitle']}>
          {item.label}
        </div>
        {item.checkpoint.map((item, idx) => (
          <CheckpointsSectionItem key={`checkpoint${idx}`} {...item} />
        ))}
      </div>
    ))}
  </div>
);
