import React from 'react';
import { Field } from 'stories/Field/Field';
import { Input } from 'stories/FormControls/Inputs/Input/Input';

import { DialogProps } from '@/shared/lib/hooks/useModal';
import { FormProvider, useForm } from 'react-hook-form';
import { FieldsContainer } from 'stories/Field/Field';
import { yupResolver } from '@hookform/resolvers/yup';
import { HistoricalReviewTableWidgetConfigDto } from '@/shared/api/dashboardsSettingsGeneratedApi';
import { EditPanelActions } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/EditPanelActions';
import type { CreateNewForm } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/EditPanelActions';
import { PeriodShiftField } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/fields/PeriodShiftField';
import { PeriodTypeField } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/fields/PeriodTypeField';
import { ResetButton } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/fields/StylingFields';
import {
  useUpdateListPropertyWidgetConfig,
  useUpdateWidgetConfig,
  useWidgetConfig,
} from 'bundles/Shared/widgets/dashboard/widgets/common/lib/config';
import { SidePanel } from 'stories/Modals/Modal/Modal';
import { OBJECT_DASHBOARD_SECTION_TYPE } from 'bundles/Shared/entities/dashboard/model/types/types';
import {
  HISTORICAL_TABLE_WIDGET_CONFIG_COLUMN_SCHEMA,
  HISTORICAL_TABLE_WIDGET_CONFIG_DEFAULT,
  HistoricalTableWidgetColumnForm,
  transformColumnConfigToForm,
} from 'bundles/Shared/widgets/dashboard/widgets/historicalTable/config/column.form';
import { upsertColumn } from 'bundles/Shared/widgets/dashboard/widgets/historicalTable/config/updaters';
import { TableVizConfigColumn } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/model';
import {
  SectionField,
  SectionFieldsContainer,
} from 'stories/Field/FieldsWrappers';
import { ColumnHeaderField } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/fields/ColumnHeaderField';
import { COLUMN_CONFIGURATION_LABEL_INFO_TEXT } from '@/bundles/Shared/widgets/dashboard/widgets/common/config';

interface Props extends DialogProps<CreateNewForm> {
  type: 'create' | 'edit';
  columnConfig?: HistoricalReviewTableWidgetConfigDto['columns'][number];
  columnSettings?: TableVizConfigColumn;
  groupId?: string;
}

export function HistoricalTableWidgetConfigColumn({
  type,
  columnConfig,
  columnSettings,
  onClose,
  onSubmit,
  groupId,
}: Props) {
  const { widget } =
    useWidgetConfig<
      typeof OBJECT_DASHBOARD_SECTION_TYPE.HISTORICAL_REVIEW_TABLE
    >();
  const [updateWidgetConfig] = useUpdateWidgetConfig(
    OBJECT_DASHBOARD_SECTION_TYPE.HISTORICAL_REVIEW_TABLE,
  );
  const resolver = yupResolver(HISTORICAL_TABLE_WIDGET_CONFIG_COLUMN_SCHEMA);
  const methods = useForm<HistoricalTableWidgetColumnForm & CreateNewForm>({
    resolver,
    mode: 'all',
    defaultValues: {
      ...HISTORICAL_TABLE_WIDGET_CONFIG_DEFAULT,
      ...(columnConfig &&
        columnSettings &&
        transformColumnConfigToForm(columnConfig, columnSettings)),
    },
  });
  const { control, register, handleSubmit } = methods;

  const { removeItem: removeColumn } = useUpdateListPropertyWidgetConfig(
    OBJECT_DASHBOARD_SECTION_TYPE.HISTORICAL_REVIEW_TABLE,
    'columns',
  );

  const handleSubmitClick = handleSubmit(async (values) => {
    await updateWidgetConfig({
      config: upsertColumn({
        column: values,
        widgetConfig: widget.widgetConfig,
        groupId,
      }),
    });
    onSubmit?.({
      createNew: values.createNew,
    });
  });

  const handleRemove = async () => {
    await removeColumn(columnConfig!.key);
    onClose();
  };

  return (
    <FormProvider {...methods}>
      <SidePanel
        header={type === 'create' ? 'Create New Column' : 'Edit Column'}
        toggle={onClose}
        actions={
          <EditPanelActions
            type={type}
            onClose={onClose}
            onSubmit={handleSubmitClick}
            onRemove={handleRemove}
          />
        }
      >
        <FieldsContainer>
          <Field note={COLUMN_CONFIGURATION_LABEL_INFO_TEXT} labelText="Title">
            <Input
              placeholder="Enter Primary Title"
              className="w-full"
              {...register('label')}
            />
          </Field>
          <SectionFieldsContainer>
            <SectionField
              required
              labelText="Time Period"
              note="Select the format for the column"
              button={<ResetButton name="period_type" />}
            >
              <PeriodTypeField control={control} name="period_type" />
            </SectionField>

            <SectionField
              labelText="Period Shift"
              note="Select the format for the column"
              button={<ResetButton name="period_shift" />}
            >
              <PeriodShiftField control={control} name="period_shift" />
            </SectionField>
            <ColumnHeaderField name="header" control={control} />
          </SectionFieldsContainer>
        </FieldsContainer>
      </SidePanel>
    </FormProvider>
  );
}
