import React, { FC } from 'react';
import { DocumentCard } from 'stories/DocumentCard/DocumentCard';
import { StreamlinedContainer } from 'stories/StreamlinedContainer/StreamlinedContainer';
import { PayAppDocumentActions } from '@/bundles/Construction/components/PayApp/PayAppDetail/components/PayAppDocumentActions';
import { downloadSharedFile } from 'lib/sharedFile';
import { formatDate, formatUnixDate } from '@/shared/lib/formatting/dates';

interface IPayAppDetailProps {
  payApp: any;
}

const PayAppDetail: FC<IPayAppDetailProps> = ({ payApp }) => {
  const { sharedFile } = payApp;

  const onViewItem = () => {
    window.open(`/shared-files/${sharedFile.id}?disposition=inline`, '_blank');
  };

  const onDownloadItem = () => downloadSharedFile(sharedFile);
  return (
    <StreamlinedContainer
      title={`Pay App ${payApp?.number}`}
      subtitle="Initial Details"
    >
      <div>
        <div className="flex items-center">
          <div className="pay-detail-item">
            <div className="pay-detail-item__title">Application Date</div>
            <div className="pay-detail-item__value">
              {formatDate(payApp.applicationDate, 'MMM DD, YYYY')}
            </div>
          </div>
          <div className="pay-detail-item">
            <div className="pay-detail-item__title">Uploaded At</div>
            <div className="pay-detail-item__value">
              {formatUnixDate(payApp?.uploadedAt, 'MMM DD, YYYY')}
            </div>
          </div>
        </div>
        <div className="flex items-center">
          <div className="pay-detail-item">
            <div className="pay-detail-item__title">Period To</div>
            <div className="pay-detail-item__value">
              {formatDate(payApp.periodToDate, 'MMM DD, YYYY')}
            </div>
          </div>
          <div className="pay-detail-item">
            <div className="pay-detail-item__title">Uploaded By</div>
            <div className="pay-detail-item__value">{payApp?.uploadedBy}</div>
          </div>
        </div>
        <div>
          <DocumentCard
            filename={sharedFile.title}
            fileExtension={sharedFile.extension}
            fileSize={sharedFile.size}
            actions={
              <PayAppDocumentActions
                onViewItem={onViewItem}
                onDownloadItem={onDownloadItem}
              />
            }
          />
        </div>
      </div>
    </StreamlinedContainer>
  );
};

export default PayAppDetail;
