import { LEFieldsToUpdate } from 'bundles/Settings/components/Portal/LegalEntities/LegalEntityModal';
import { camelKeysToSnake } from 'bundles/UserManagement/components/roles/utils';
import http from 'lib/http';
import { LegalEntity } from '@/entities/core/legalEntity';
import { LeClassification } from 'bundles/Shared/entities/leClasssification';

export const updateLegalEntity = async ({
  id,
  ...payload
}: {
  id: string;
} & (
  | {
      classification: LeClassification;
    }
  | LEFieldsToUpdate
)) => {
  const res = await http.put(`/legal_entities/${id}`, payload);

  if (res.status === 200) {
    toastr.success('Legal entity updated successfully');
    return true;
  }
  const data = await res.json();
  toastr.error(data.errors);
  return false;
};

export const includeOrExcludeLE = async ({
  ids,
  excluded,
}: {
  ids: string[];
  excluded: boolean;
}) => {
  const response = await http.put('/legal_entities/bulk/exclude', {
    legal_entity_ids: ids,
    excluded,
  });
  if (response.status !== 200) {
    const responseJSON = await response.json();
    toastr.error(`${responseJSON.errors}\n`);
    return false;
  }
  toastr.success('Legal entities successfully updated');
  return true;
};

export const updateBulkLEClass = async (payload: {
  legalEntityIds: LegalEntity['id'][];
  classification: LeClassification;
}) => {
  const response = await http.put(
    '/legal_entities/bulk/set_classification',
    camelKeysToSnake(payload),
  );
  if (response.status !== 200) {
    const responseJSON = await response.json();
    toastr.error(`${responseJSON.errors}\n`);
    return false;
  }
  toastr.success('Legal Entity has been successfully mapped');
  return true;
};

export const setObjectForSingleLE = async (id: string, payload) => {
  const response = await http.put(`/legal_entities/${id}`, payload);
  if (response.status !== 200) {
    const responseJSON = await response.json();
    toastr.error(`${responseJSON.errors}\n`);
    return false;
  }
  toastr.success('Legal Entity has been successfully mapped');
  return true;
};

export const bulkSetObject = async (payload) => {
  const response = await http.put('/legal_entities/bulk/set_object', payload);
  if (response.status !== 200) {
    const responseJSON = await response.json();
    toastr.error(`${responseJSON.errors}\n`);
    return false;
  }
  toastr.success('Legal Entity has been successfully mapped');
  return true;
};
