import { useAddDashboardWidgetSection } from '@/bundles/Shared/features/dashboard/addWidget/lib';
import { useCopyWidgetSectionToBoard } from '@/bundles/Shared/features/dashboard/copyWidget';
import { cn } from '@/shared/lib/css/cn';
import { useModal } from '@/shared/lib/hooks/useModal';
import { ReportDashboardType } from 'bundles/Shared/entities/dashboard/model/types/types';
import type {
  AllWidgetTypes,
  ReportDashboardSectionPositionLayouts,
} from 'bundles/Shared/entities/dashboard/model/types/types';
import { useDashboardContext } from 'bundles/Shared/entities/dashboard/lib';
import {
  WidgetPreview,
  WidgetSearchPreview,
} from 'bundles/Shared/widgets/dashboard/widgets/common/ui/WidgetPreview';
import React, { useMemo } from 'react';
import { useDrag } from 'react-dnd';
import { Tooltip } from 'stories/Tooltip/Tooltip';
import {
  COMPARISON_DASHBOARD_WIDGETS_CONFIG_MAP,
  EAGLE_EYE_DASHBOARD_WIDGETS_CONFIG_MAP,
  OBJECT_DASHBOARD_WIDGETS_CONFIG_MAP,
  WIDGETS_CONFIG_MAP,
} from '@/bundles/Shared/widgets/dashboard/widgets/config';
import { AvailableWidgetsModal } from '@/bundles/Shared/widgets/dashboard/widgetsBar/ui/AvailableWidgetsModal';

const DraggableWidgetContainer = ({
  children,
  className,
  id,
  ...props
}: React.PropsWithChildren<
  React.AllHTMLAttributes<HTMLDivElement> & {
    id: string;
  }
>) => {
  const [_, drag] = useDrag({
    item: {
      type: 'dashboard-widget',
      id,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  return (
    <div
      ref={drag}
      onDragStart={(e) => e.dataTransfer.setData('text/plain', id)}
      draggable
      className={cn('inline-flex cursor-pointer', className)}
      {...props}
    >
      {children}
    </div>
  );
};

const MAX_DISPLAYED_WIDGETS = 3;

export function DashboardWidgetsBar({
  ...props
}: Pick<React.AllHTMLAttributes<HTMLDivElement>, 'onDragStart'> & {
  layout: ReportDashboardSectionPositionLayouts;
}) {
  const { dashboardType: type, boardId, dashboardId } = useDashboardContext();
  const { openModal } = useModal();
  const [copyDashboardWidget] = useCopyWidgetSectionToBoard({
    dashboardId,
    dashboardType: type,
    boardId,
  });

  const [addDashboardWidget] = useAddDashboardWidgetSection();

  const widgetsMap = useMemo(() => {
    switch (type) {
      case ReportDashboardType.OBJECT: {
        return OBJECT_DASHBOARD_WIDGETS_CONFIG_MAP;
      }
      case ReportDashboardType.EAGLE_EYE: {
        return EAGLE_EYE_DASHBOARD_WIDGETS_CONFIG_MAP;
      }
      case ReportDashboardType.COMPARISON_MODE: {
        return COMPARISON_DASHBOARD_WIDGETS_CONFIG_MAP;
      }
    }
  }, [type]);

  const handleAddWidget = async () => {
    const res = await openModal(
      AvailableWidgetsModal,
      {},
      {
        topLevelRender: false,
      },
    );

    if (res == null) return;

    if (res.type === 'add_new_widget') {
      return addDashboardWidget({
        type: res.widgetOptions.map((option) => option.widgetType),
        boardId,
        layout: props.layout.lg,
      });
    }

    return copyDashboardWidget({
      sections: res.widgetOptions,
    });
  };

  return (
    <div className="fixed bottom-6 left-1/2 flex justify-center" {...props}>
      <div
        style={{
          backdropFilter: 'blur(8px)',
        }}
        className="flex gap-4 rounded-[32px] bg-[rgba(226,226,226,0.29)] p-4  shadow-[0px_4px_16px_0px_rgba(144,144,144,0.25)]"
      >
        {Object.keys(widgetsMap)
          .slice(0, MAX_DISPLAYED_WIDGETS)
          .map((widgetKey: AllWidgetTypes) => (
            <DraggableWidgetContainer key={widgetKey} id={widgetKey}>
              <Tooltip mainText={WIDGETS_CONFIG_MAP[widgetKey]?.title}>
                <WidgetPreview type={widgetKey} />
              </Tooltip>
            </DraggableWidgetContainer>
          ))}

        <WidgetSearchPreview onClick={() => handleAddWidget()} />
      </div>
    </div>
  );
}
