/* eslint-disable no-restricted-imports */
/* eslint-disable check-file/no-index */
import { KpiCardWidgetConfigCard } from '@/bundles/Shared/widgets/dashboard/widgets/kpiCard/config/card';
import { KpiCardWidgetConfig } from 'bundles/Shared/widgets/dashboard/widgets/kpiCard/config/component';
import { KpiCardWidget } from 'bundles/Shared/widgets/dashboard/widgets/kpiCard/widget';
import { WidgetConfiguration } from 'bundles/Shared/widgets/dashboard/widgets/model';

export const KPI_CARD_WIDGET_CONFIG = {
  layout: {
    w: 1,
    h: 4,
    maxH: 4,
    minH: 4,
    minW: 1,
    maxW: 2,
  },
  title: 'Kpi Card',
  icon: 'table',
  Component: KpiCardWidget,
  ConfigComponent: KpiCardWidgetConfig,
  CardConfigComponent: KpiCardWidgetConfigCard,
} as const satisfies WidgetConfiguration;

export * from './model';
