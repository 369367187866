import { useParams } from '@reach/router';
import CategoriesList from 'bundles/Settings/components/REport/CategoriesList/CategoriesList';
import UpsertModal from 'bundles/Settings/components/REport/JobCostCodes/UpsertModal';
import { fetchJobCostCodesLE } from 'bundles/Shared/actions/job_cost_codes';
import { fetchReconcileDevelopmentCategoriesTree } from 'bundles/Shared/actions/reconcile_development_categories';
import Table from 'bundles/Shared/components/Table/Table';
import { IColumn } from 'bundles/Shared/components/Table/types';
import {
  PageParamsPagination,
  PageParamsProvider,
  PageParamsSearch,
} from 'bundles/Shared/components/pageParams';
import { TRouteParams } from '@/shared/lib/hooks/useNavigation';
import { useEffect, useMemo, useState } from 'react';
import { IconButton } from 'stories/IconButton/IconButton';
import {
  IJobCostCodeMeta,
  IJobCostCodeOnIndex,
  IJobCostCodesParams,
} from 'types/JobCostCode';
import { IReconcileDevelopmentCategoryTreeNode } from 'types/ReconcileDevelopmentCategory';
import { DEFAULT_NUMBER_FALLBACK } from '@/shared/lib/formatting/fallbacks';

export default function JCCinLE() {
  const [isLoading, setIsLoading] = useState(true);
  const [categoriesTree, setCategoriesTree] = useState<
    IReconcileDevelopmentCategoryTreeNode[]
  >([]);

  const [items, setItems] = useState<IJobCostCodeOnIndex[]>([]);
  const [meta, setMeta] = useState<IJobCostCodeMeta>({
    totalSize: 0,
    perPage: 0,
  });
  const paramsRoute =
    useParams<TRouteParams['RECONCILE_DEVELOPMENT_LEGAL_ENTITY_SETTINGS']>();
  const [params, setParams] = useState<IJobCostCodesParams>({ page: 1 });
  const [editedItem, setEditedItem] = useState<IJobCostCodeOnIndex | null>(
    null,
  );

  const reloadCategoriesTree = () => {
    fetchReconcileDevelopmentCategoriesTree().then((data) => {
      setCategoriesTree(data.tree);
    }, null);
  };

  const reloadItems = () => {
    setIsLoading(true);
    const payload = {
      page: params.page,
      query: params.query,
      sort: {
        field: params.sortField,
        order: params.sortOrder,
      },
    };

    fetchJobCostCodesLE(paramsRoute.legalEntityCode, payload).then((data) => {
      setItems(data.items);
      setMeta(data.meta);

      if (
        data.meta.totalSize &&
        params.page > Math.ceil(data.meta.totalSize / data.meta.perPage)
      ) {
        setParams((oldParams) => ({ ...oldParams, page: 1 }));
      } else {
        setIsLoading(false);
      }
    }, null);
  };

  useEffect(() => {
    reloadCategoriesTree();
  }, []);

  useEffect(() => {
    reloadItems();
  }, [params]);

  const columns = useMemo<IColumn<IJobCostCodeOnIndex>[]>(
    () => [
      {
        sortable: true,
        text: '#',
        dataField: 'id',
      },
      {
        sortable: true,
        text: 'Name',
        dataField: 'name',
      },
      {
        sortable: true,
        text: 'Code',
        dataField: 'code',
      },
      {
        text: 'General Ledgers',
        dataField: 'general_ledgers',
        formatter: ({ row: item }) => {
          if (item.generalLedgers === undefined) return DEFAULT_NUMBER_FALLBACK;

          return `${item.generalLedgers.length} GLs`;
        },
      },
      {
        text: 'Development Category',
        dataField: 'reconcile_development_category_id',
        formatter: ({ row: item }) => (
          <CategoriesList
            categoryId={item.reconcileDevelopmentCategoryId}
            categoriesTree={categoriesTree}
          />
        ),
      },
      {
        text: 'Actions',
        dataField: 'actions',
        formatter: ({ row: item }) => (
          <IconButton
            className="self-start bg-white"
            onClick={() => setEditedItem(item)}
            data-tip="Edit"
            iconName="edit"
          />
        ),
      },
    ],
    [categoriesTree],
  );
  return (
    <>
      <PageParamsProvider pageParams={params} setPageParams={setParams}>
        <div className="mb-m flex items-center justify-between">
          <div>
            <PageParamsPagination
              loading={isLoading}
              totalSize={meta.totalSize}
              sizePerPage={meta.perPage}
            />
          </div>
          <div className="flex items-center">
            <PageParamsSearch />
          </div>
        </div>
      </PageParamsProvider>
      <Table
        loading={isLoading}
        columns={columns}
        items={items}
        settings={params}
        setSettings={setParams}
        defaultColumn={{
          classes: 'bg-white',
        }}
      />
      {editedItem && (
        <UpsertModal
          item={editedItem}
          handleClose={() => setEditedItem(null)}
          onSuccess={() => {
            setEditedItem(null);
            reloadItems();
          }}
          indiviualSettings
          generalLedgers={meta.generalLedgers}
        />
      )}
    </>
  );
}
