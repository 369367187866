/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-wrap-multilines */
import { TransactionSource } from '@/entities/return/api/capitalInvestmentObjectsGeneratedApi';
import {
  CapitalInvestmentEntryBase,
  TransactionReportLineItem,
} from 'bundles/REturn/actions/types';
import FullWhiteTable from 'bundles/Shared/components/Table/variants/FullWhiteTable';
import { HUGE_MODAL_Z_INDEX } from 'bundles/Shared/constants';
import { formatUnixDate } from '@/shared/lib/formatting/dates';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import React from 'react';
import { SidePanel } from 'stories/Modals/Modal/Modal';
import { ModalHeaderWithSubtitle } from 'stories/Modals/ModalHeader/ModalHeaderWithSubtitle/ModalHeaderWithSubtitle';
import {
  getCapitalEntryAmountColumn,
  getCapitalEntryKindColumn,
} from '@/bundles/REturn/components/Ownership/modals/linkTxModals/linkTxToManuallyAdded/hooks/tableColumns/useEntryColumns';

interface Props extends DialogProps<boolean> {
  entry: NonNullishFields<CapitalInvestmentEntryBase>;
  type: TransactionSource['kind'];
}

const ModalHeaderInfo = ({
  infoName,
  value,
}: {
  infoName: React.ReactNode;
  value: React.ReactNode;
}) => (
  <div className="gap-1.5 flex flex-col">
    <p className="label-regular text-light-60">{infoName}</p>
    <p className="inline-regular text-light-90">{value}</p>
  </div>
);

export default function SplittedEntryModal({ onClose, entry, type }: Props) {
  const { accountingTransaction } = entry;
  const { mappedTransactions, generalLedger } = accountingTransaction;

  return (
    <SidePanel
      toggle={onClose}
      maxHeight
      zIndex={HUGE_MODAL_Z_INDEX - 1}
      header={
        <ModalHeaderWithSubtitle
          title={entry.investmentEntity.name}
          subtitle="Investment Entity"
          order="subtitle-title"
        />
      }
      classes={{
        header: 'bg-white',
        body: 'bg-light flex flex-col',
      }}
    >
      <div className="-m-6 mb-0 flex flex-col gap-4 border-b border-light-10 bg-white px-6 py-4">
        <ModalHeaderInfo
          infoName="General Ledger"
          value={`${generalLedger.accNumber} - ${generalLedger.accTitle}`}
        />
        <div className="flex items-center gap-6">
          <ModalHeaderInfo
            infoName="Added by"
            value={entry.createdBy.fullName}
          />
          <ModalHeaderInfo
            infoName="Added Date"
            value={formatUnixDate(entry.createdAt, 'MMMM DD, YYYY h:mm A')}
          />
        </div>
      </div>
      <div className="mt-5 flex flex-col gap-3">
        <p className="inline-semibold text-dark-60">Transactions</p>
        <FullWhiteTable<TransactionReportLineItem['mappedTransactions'][number]>
          columns={[
            {
              dataField: 'name',
              text: 'Investment Entity',
              formatter: ({ row }) => (
                <span className="inline-regular text-light-90">
                  {row.investmentEntity.name}
                </span>
              ),
            },
            {
              dataField: 'date',
              text: 'ADDED BY',
              formatter: ({ row }) => (
                <div className="gap-0.5 flex flex-col">
                  <span className="secondary-semibold">
                    {row.createdBy.fullName}
                  </span>
                  <span>
                    <span className="text-light-60">on </span>
                    <span className="text-dark-60">
                      {formatUnixDate(row.createdAt, 'MM-DD-YYYY')}
                    </span>
                  </span>
                </div>
              ),
            },
            getCapitalEntryAmountColumn({
              hasUnits: type === 'contribution',
              headerAlign: type === 'contribution' ? 'end' : 'start',
            }),
            getCapitalEntryKindColumn(type),
          ]}
          items={mappedTransactions ?? []}
        />
      </div>
    </SidePanel>
  );
}
