import React, { FC, useState } from 'react';
import { IChangeEvent } from 'bundles/Construction/types';
import { Button } from 'stories/Button/Button';
import { SelectionSection } from 'stories/SelectionSection/SelectionSection';
import { SelectionSectionItem } from 'stories/SelectionSection/SelectionSectionItem/SelectionSectionItem';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import { changeReconcileActiveAction } from 'bundles/Construction/reducers/ReconcileSlice';
import { selectChangeEvent } from 'bundles/Construction/reducers/ReconcileChangeEventSlice';

interface IPromoteModalProps {
  setShowCECreateModal: (show: IChangeEvent['category'] | false) => void;
}

const PromoteModal: FC<IPromoteModalProps> = ({ setShowCECreateModal }) => {
  const changeEvent = useAppSelector(selectChangeEvent);
  const [active, setActive] = useState<
    IChangeEvent['category'] | 'revise' | false
  >(false);

  const dispatch = useAppDispatch();

  const openModal = () => {
    setShowCECreateModal(active);
    dispatch(changeReconcileActiveAction(null));
  };
  return (
    <div>
      <div className="ce-bb-line px-m py-m">
        <div className="light-90 inline-semibold mb-s">
          Select category to promote to:
        </div>
        <SelectionSection>
          {changeEvent?.category === 'IPC' && (
            <SelectionSectionItem
              title="PCO"
              subtitle="Potential Change Order"
              onChange={() => setActive('PCO')}
              checked={active === 'PCO'}
            />
          )}
          <SelectionSectionItem
            title="COR"
            subtitle="Change Order Request"
            onChange={() => setActive('COR')}
            checked={active === 'COR'}
          />
        </SelectionSection>
      </div>
      <div className="px-m py-m">
        <Button
          variant="success"
          onClick={openModal}
          disabled={!active}
          className="w-full"
        >
          Promote
        </Button>
      </div>
    </div>
  );
};

export default PromoteModal;
