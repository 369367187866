import React from 'react';
import { useAppSelector } from '@/shared/lib/hooks/redux';
import { buildLayoutsId } from 'bundles/Shared/entities/dashboard/model/slices/shared';
import { DashboardHasChangesButton } from 'bundles/Shared/entities/dashboard/ui/DashboardHasChangesButton';
import { DashboardIcon } from 'bundles/Shared/entities/dashboard/ui/DashboardIcon';
import { DashboardSettingsToggle } from 'bundles/Shared/entities/dashboard/ui/DashboardSettingsToggle';
import { DashboardStatusDropdown } from 'bundles/Shared/entities/dashboard/ui/DashboardStatusDropdown';
import { ReportDashboardType } from 'bundles/Shared/entities/dashboard/model/types/types';
import {
  selectReportDashboardSettingsDirty,
  selectReportEagleEyeDashboardSettingsById,
} from 'bundles/Shared/entities/dashboard/model/slices/eagleEyeSlice';
import { useDashboardContext } from 'bundles/Shared/entities/dashboard/lib';
import { useReportEagleEyeDashboardSettingsById } from 'bundles/Shared/entities/dashboard/api';
import IconButton from 'stories/IconButton/IconButton';
import { Link, useNavigate } from '@reach/router';
import { generateUrl, ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import { useEditEagleEyeDashboard } from 'bundles/Shared/features/dashboard/update/eagleEye/lib';
import { DashboardLayout } from 'bundles/Shared/components/layouts/dashboard/DashboardLayout';
import { useUpdateDashboardStatus } from 'bundles/Shared/features/dashboard/updateStatus';
import { DashboardPermissionListLine } from 'bundles/Shared/features/dashboard/updatePermissions';
import { useDeleteDashboard } from 'bundles/Shared/features/dashboard/delete';
import { useDashboardUpdateLayout } from 'bundles/Shared/features/dashboard/sectionActions';
import { GrowDiv } from '@/shared/ui/GrowDiv';
import { useResetAllModalsOnUnmount } from '@/shared/lib/hooks/useModal';

function SettingsReportDashboardHeader() {
  const { dashboardId, boardId, dashboardSlug, boardSlug } =
    useDashboardContext();

  const { dashboard } = useReportEagleEyeDashboardSettingsById({
    dashboardId,
  });
  const dashboardSettings = useAppSelector((state) =>
    selectReportEagleEyeDashboardSettingsById(
      state,
      buildLayoutsId({
        dashboardId,
        boardId,
      }),
    ),
  );
  const navigate = useNavigate();
  const isDirty = useAppSelector(
    selectReportDashboardSettingsDirty({
      dashboardId,
      boardId,
    }),
  );
  const editDashboard = useEditEagleEyeDashboard();
  const moveLayout = useDashboardUpdateLayout();
  const [removeDashboard] = useDeleteDashboard(ReportDashboardType.EAGLE_EYE);
  const updateDashboardStatus = useUpdateDashboardStatus();
  const handleScoreboardUpdate = async () => {
    await moveLayout({
      boardId,
      layout: dashboardSettings!.layouts!.lg,
    });
  };

  const handleEditDashboard = async () => {
    const newDashboard = await editDashboard(dashboard);
    if (newDashboard == null) {
      return;
    }
    navigate(
      generateUrl(ROUTES_ROOT.settings.report.dashboards.dashboard.fullPath, {
        pathParams: {
          dashboardId: newDashboard.slug,
        },
        queryParams: {
          boardId: boardSlug ?? '',
        },
      }),
    );
  };

  const handleRemoveScoreboard = async () => {
    const res = await removeDashboard(dashboard.id);
    if (!res) return;
    navigate(ROUTES_ROOT.settings.report.dashboards.fullPath);
  };

  useResetAllModalsOnUnmount();

  return (
    <DashboardLayout.Header className="h-auto">
      <DashboardLayout.Header.Title
        title={dashboard.name}
        subtitle={
          <>
            <DashboardIcon iconName="eagleEyeDashboard" />
            Eagle Eye
          </>
        }
      />

      <IconButton onClick={handleEditDashboard} iconName="edit" />
      <DashboardPermissionListLine
        dashboardType={ReportDashboardType.EAGLE_EYE}
        permissions={dashboard.permitted}
        dashboardId={dashboardId}
      />
      <IconButton onClick={handleRemoveScoreboard} iconName="trash" />
      <DashboardStatusDropdown
        onChange={(status) => {
          updateDashboardStatus({
            dashboardId: dashboard.id,
            status,
          });
        }}
        value={dashboard.status}
      />
      <GrowDiv />
      <DashboardHasChangesButton
        isDirty={isDirty}
        onClick={handleScoreboardUpdate}
      />
      <Link
        to={generateUrl(ROUTES_ROOT.scoreboards.scoreboard.fullPath, {
          pathParams: {
            slug: dashboardSlug ?? '',
          },
          queryParams: {
            boardId: boardSlug ?? '',
          },
        })}
      >
        <DashboardSettingsToggle isActive />
      </Link>
    </DashboardLayout.Header>
  );
}

export default SettingsReportDashboardHeader;
