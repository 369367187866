import {
  isSparklineTableVizConfigRow,
  isTextTableVizConfigRow,
} from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/table/model';
import {
  CellClassParams,
  ColDef,
  ICellRendererParams,
} from 'ag-grid-community';
import { DashboardHistoricalReviewTableWidgetDto } from '@/shared/api/dashboardsGeneratedApi';
import {
  ColDefBuilder,
  resolveBackgroundAndTextColor,
} from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/ColumnDefsBuilder';
import {
  TableVizConfigColumn,
  TableVizConfigRow,
} from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/model';

import {
  createAgGridTableFormattedColDef,
  StyledBasicCellRendererProps,
} from '@/shared/lib/formatting/table';
import { WidgetViewMode } from 'bundles/Shared/widgets/dashboard/widgets/model';
import { buildExcelStyleId } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/table/useTableWidgetExportFeature';
import { WidgetTableTextCellRenderer } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/renderers/TextCellRenderer';
import {
  NumericValueDisplayOptions,
  ValueDisplayOptions,
} from '@/shared/lib/formatting/displayOptions';
import { formatDateRangeForPeriodType } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/table/lib';
import { HistoricalReviewTableWidgetConfigModel } from '@/bundles/Shared/widgets/dashboard/widgets/historicalTable/model';
import { getSparklineCellRendererComponentObject } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/renderers/SparklineCellRenderer';

type HistoryRow = {
  value: string;
  dateFrom: DateString;
  dateTo: DateString;
};

type HistoricalTableWidgetDtoRow =
  DashboardHistoricalReviewTableWidgetDto['data'][0] & {
    key?: number;
    history?: {
      [key: number]: HistoryRow[];
    };
  };

const isLabelColumn = (columnSettings: TableVizConfigColumn) => {
  return columnSettings.key == 'label';
};

export class RowColDefBuilder<
  Column extends {
    label: string;
    key: number;
  },
> extends ColDefBuilder<Column> {
  rows: TableVizConfigRow[];
  columnsConfig: HistoricalReviewTableWidgetConfigModel['columns'];
  constructor({
    mode,
    onPinColumn,
    rows,
    columnsConfig,
  }: {
    mode: WidgetViewMode;
    rows: TableVizConfigRow[];
    columnsConfig: HistoricalReviewTableWidgetConfigModel['columns'];
    onPinColumn?: (colId: string) => unknown;
  }) {
    super({
      mode,
      onPinColumn,
    });
    this.withSubHeaderName((params) => {
      const colConfig = columnsConfig?.find(
        (c) => c.key.toString() === params.columnSettings.key,
      );
      return formatDateRangeForPeriodType(
        params.column,
        colConfig,
        params.columnSettings,
      );
    });
    this.columnsConfig = columnsConfig;
    this.rows = rows ?? [];
  }

  findRowSettings(rowKey: string | undefined) {
    return this.rows.find((r) => r.key === rowKey);
  }

  buildLabelCellRenderer({ params }: { params: ICellRendererParams }) {
    const { cellRenderer } = createAgGridTableFormattedColDef({
      type: 'text',
    });
    return {
      component: cellRenderer,
      params,
    };
  }

  getRowValueDisplayOptionsOrDefault(
    rowSettings?: TableVizConfigRow,
  ): ValueDisplayOptions {
    if (rowSettings?.value_display_options) {
      return rowSettings.value_display_options;
    }
    return {
      type: 'number',
    };
  }

  isSparklineColumn({
    params,
  }: {
    columnSettings?: TableVizConfigColumn;
    params?: Pick<ICellRendererParams, 'data'>;
  }) {
    const data = params?.data as HistoricalTableWidgetDtoRow;
    const rowSettings = this.findRowSettings(data?.key?.toString());
    return Boolean(rowSettings && isSparklineTableVizConfigRow(rowSettings));
  }

  buildCustomCellRenderer({
    params,
    column,
  }: {
    params: ICellRendererParams;
    column?: Column;
  }) {
    const rowSettings = this.findRowSettings(params.data?.key?.toString());
    if (this.isSparklineColumn({ params })) {
      return getSparklineCellRendererComponentObject({
        params,
        displayOptions: this.getRowValueDisplayOptionsOrDefault(
          rowSettings,
        ) as NumericValueDisplayOptions,
        column,
        columnConfig: this.columnsConfig?.find(
          (c) => c.key.toString() === column?.key.toString(),
        ),
        config: rowSettings!.cell_renderer,
      });
    }

    return {
      component: WidgetTableTextCellRenderer,
      params: {
        ...params,
        config: rowSettings?.cell_renderer,
      },
    };
  }

  build({
    column,
    columnSettings,
  }: {
    columnSettings: TableVizConfigColumn;
    column?: Column;
  }): ColDef {
    const { ...inheritedColDef } = super.build({
      column,
      columnSettings,
    });
    return {
      ...inheritedColDef,
      sortable: false,
      comparator: undefined,
      headerName: isLabelColumn(columnSettings)
        ? ''
        : inheritedColDef.headerName,
      // row excel styles works only with cellClassRules
      cellClassRules: Object.fromEntries(
        this.rows.map((row) => [
          buildExcelStyleId({
            id: row.key,
            type: 'row',
          }),
          (params: CellClassParams) => {
            const currentRow = params.data as HistoricalTableWidgetDtoRow;
            return (
              currentRow.key?.toString() === row.key &&
              !isLabelColumn(columnSettings)
            );
          },
        ]),
      ),
      cellRendererParams: (
        params: ICellRendererParams<HistoricalTableWidgetDtoRow>,
      ) => {
        const row = params.data;
        const rowSettings = this.findRowSettings(row?.key?.toString());

        const inheritedParams = super
          .build({ column, columnSettings })
          .cellRendererParams(params);

        const resolveAlignment = () => {
          if (isLabelColumn(columnSettings)) {
            return 'left';
          }
          return columnSettings.align ?? 'right';
        };

        return {
          ...inheritedParams,
          ...this.buildAlignmentCellParams(resolveAlignment(), params),
          styles: {
            ...inheritedParams.styles,
            ...(isLabelColumn(columnSettings)
              ? {}
              : resolveBackgroundAndTextColor({
                  params,
                  direction: 'row',
                  shouldApplyGradient: () => !isLabelColumn(columnSettings),
                  background: rowSettings?.background,
                  comparison: rowSettings?.comparison,
                })),
            fontWeight: rowSettings?.font_weight ?? 'normal',
          },
        } satisfies StyledBasicCellRendererProps;
      },
      cellRendererSelector: (params: ICellRendererParams) => {
        if (isLabelColumn(columnSettings)) {
          return this.buildLabelCellRenderer({
            params,
          });
        }

        const row = params.data as HistoricalTableWidgetDtoRow;
        const rowSettings = this.findRowSettings(row.key?.toString());

        if (
          rowSettings &&
          (isTextTableVizConfigRow(rowSettings) ||
            this.isSparklineColumn({ params }))
        ) {
          return this.buildCustomCellRenderer({
            params,
            column,
          });
        }

        const { cellRenderer } = createAgGridTableFormattedColDef(
          this.getRowValueDisplayOptionsOrDefault(rowSettings),
        );
        return {
          component: cellRenderer,
          params,
        };
      },
    };
  }
}
