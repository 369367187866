import React, { useCallback } from 'react';
import { Button } from 'stories/Button/Button';
import { Field } from 'stories/Field/Field';
import { Modal } from 'stories/Modals/Modal/Modal';
import { Input } from 'stories/FormControls/Inputs/Input/Input';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import { FieldsContainer } from 'stories/Field/Field';
import CurrencyInputNumber from 'bundles/Shared/components/GroupForm/FormItems/CurrencyInputNumber';
import { Controller, useForm } from 'react-hook-form';
import { LegalEntity } from '@/entities/core/legalEntity';
import { FundingSourceTypeSelect } from '@/bundles/REconcile/components/development/home/legalEntitySettingsModal/fundingSources/features/infoSection/FundingSourceTypeSelect';
import {
  useCreateFundingSourceMutation,
  useUpdateFundingSourceMutation,
} from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/fundingSources/api/reconcileFundingSourcesApi';
import { FundingSource } from '@/shared/types/reconcile/FundingSource';

export type EditableFundingSource = {
  amount: RawFloat;
  name: string;
  kind: FundingSource['kind'];
};

type ErrorMessage = {
  [K in keyof EditableFundingSource]: string;
};

interface Props
  extends Omit<
      React.ComponentProps<typeof Modal>,
      'toggle' | 'actions' | 'children'
    >,
    DialogProps<EditableFundingSource> {
  legalEntityCode: LegalEntity['code'];
  type: 'create' | 'edit';
  submitText?: string;
  fundingSource?: FundingSource;
}

function FundingSourceModal({
  type,
  legalEntityCode,
  onClose,
  submitText,
  fundingSource,
  ...props
}: Props) {
  const [createFundingSource, { isSuccess: isSuccessCreating }] =
    useCreateFundingSourceMutation();
  const [updateFundingSource, { isSuccess: isSuccessUpdating }] =
    useUpdateFundingSourceMutation();
  if (isSuccessCreating || isSuccessUpdating) {
    onClose();
  }
  const resolver = useCallback((values: EditableFundingSource) => {
    const errors: Partial<ErrorMessage> = {};
    if (!values.amount) {
      errors.amount = 'Amount is required';
    }

    if (!values.name) {
      errors.name = 'Name is required';
    }

    if (!values.kind) {
      errors.kind = 'Type is required';
    }

    return {
      values,
      errors,
    };
  }, []);

  const {
    control,
    formState: { isValid },
    handleSubmit,
  } = useForm<Partial<EditableFundingSource>>({
    mode: 'onChange',
    values:
      type === 'edit'
        ? {
            amount: fundingSource?.amount,
            kind: fundingSource?.kind,
            name: fundingSource?.name,
          }
        : {},
    resolver,
  });

  const handleSend = (values: EditableFundingSource) => {
    if (type === 'create') {
      return createFundingSource({
        legalEntityCode,
        body: values,
      });
    }
    if (type === 'edit' && fundingSource) {
      return updateFundingSource({
        legalEntityCode,
        body: values,
        fundingSourceId: fundingSource.id,
      });
    }
  };

  return (
    <Modal
      actions={
        <>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            data-testid="crud-submit"
            variant="success"
            onClick={handleSubmit((values) => handleSend(values))}
            disabled={!isValid}
          >
            {submitText ?? 'Save'}
          </Button>
        </>
      }
      toggle={onClose}
      {...props}
    >
      <FieldsContainer>
        <Field labelText="Name" required>
          <Controller
            control={control}
            name="name"
            render={({ field }) => (
              <Input
                placeholder="Enter Name"
                onChange={(value) => field.onChange(value)}
                value={field.value}
              />
            )}
          />
        </Field>
        <Field labelText="Amount" required>
          <Controller
            control={control}
            name="amount"
            render={({ field }) => (
              <CurrencyInputNumber
                onValueChange={(value) => field.onChange(value ?? null)}
                value={field.value}
              />
            )}
          />
        </Field>
        <Field labelText="Type" required>
          <Controller
            control={control}
            name="kind"
            render={({ field }) => (
              <FundingSourceTypeSelect
                onChange={field.onChange}
                value={field.value}
              />
            )}
          />
        </Field>
      </FieldsContainer>
    </Modal>
  );
}

export default FundingSourceModal;
