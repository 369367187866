import { selectCustomer } from '@/app/reducers/genericSlice';
import { cn } from '@/shared/lib/css/cn';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import { getLogoPath, SYMMETRE_LOGO_NAME } from '@/shared/lib/ui/logo';
import { Link } from '@reach/router';
import { useGenericData } from 'bundles/Shared/GenericContext';
import { IS_STAGING } from 'lib/http';
import React, { useState } from 'react';
import Icon from 'stories/Icon/Icon';
import LinkButton from 'stories/LinkButton/LinkButton';
import { NavigationPin } from '@/bundles/Shared/components/LeftSidebar/NavigationPin/NavigationPin';
import {
  collapseSidebar,
  isLeftSidebarExpanded,
  isLeftSidebarPinned,
  togglePinStateOfLeftSidebar,
} from '@/bundles/Shared/components/LeftSidebar/reducers/leftSidebarSlice';
import styles from '@/bundles/Shared/components/LeftSidebar/Logo/Logo.module.scss';
import { ReactComponent as SymmetreLogoSvg } from '/images/symmetre-bw.svg';
import { ReactComponent as SymmetreLogoColoredSvg } from '/images/symmetre-colored.svg';

export const Logo = ({
  variant,
  symmetre,
  ...props
}: {
  variant: 'large' | 'square' | 'horizontal';
  symmetre?: boolean;
} & Omit<
  React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  >,
  'src'
>) => {
  const [fallbackUrl, setFallbackUrl] = useState<string | null>(null);
  const customer = useAppSelector(selectCustomer);
  const client =
    IS_STAGING || customer?.subdomain == null || symmetre
      ? SYMMETRE_LOGO_NAME
      : customer.subdomain;

  return (
    <img
      alt={client}
      src={fallbackUrl ?? getLogoPath({ client, variant, extension: 'png' })}
      onError={
        fallbackUrl == null
          ? () => {
              setFallbackUrl(
                getLogoPath({
                  client: SYMMETRE_LOGO_NAME,
                  variant,
                  extension: 'png',
                }),
              );
            }
          : undefined
      }
      {...props}
    />
  );
};

export const SymmetreLogo = (props: React.ComponentProps<typeof Logo>) => {
  return <Logo {...props} symmetre />;
};

export const SymmetreLogoIcon = ({
  theme,
  ...props
}: React.SVGProps<SVGSVGElement> & {
  theme: 'colored' | 'grayscale';
}) => {
  if (theme === 'colored') {
    return <SymmetreLogoColoredSvg {...props} />;
  }
  return <SymmetreLogoSvg {...props} />;
};

export const LeftSidebarLogo = () => {
  const { genericData } = useGenericData();
  const { customer } = genericData!;
  const [logoNotFound, setLogoNotFound] = useState(false);
  const expanded = useAppSelector(isLeftSidebarExpanded);
  const isPinnedSidebarModeAvailable = matchMedia(
    '(min-width: 1500px) and (pointer:fine)',
  ).matches;
  const dispatch = useAppDispatch();
  const leftSidebarPinned = useAppSelector(isLeftSidebarPinned);
  const togglePinButtonState = () => {
    dispatch(togglePinStateOfLeftSidebar());
  };

  return (
    <div
      className={cn(
        'vertical-align-center mb-s flex w-full items-center p-s md:mb-m md:p-0 md:pt-xs',
        {
          'pr-s': isPinnedSidebarModeAvailable,
          'justify-center': !expanded,
          'md:px-xs': expanded,
        },
      )}
    >
      <div className="flex h-l w-l items-center justify-center text-light md:h-xl md:w-xl">
        <div
          className={cn(
            styles.logo,
            'light hidden h-xl w-xl items-center justify-center text-center md:flex',
          )}
          style={{ backgroundColor: customer.initials.color }}
        >
          <Link to="/" className="light">
            {!logoNotFound ? (
              <Logo
                onError={() => setLogoNotFound(true)}
                className={cn(styles.logoImg)}
                variant="square"
              />
            ) : (
              customer.initials.label
            )}
          </Link>
        </div>
      </div>
      <Link to="/">
        <Logo variant="horizontal" className={cn(styles.secondLogo, 'ml-4')} />
      </Link>
      <div className={styles.close}>
        <LinkButton onClick={() => dispatch(collapseSidebar())}>
          <Icon iconName="close" />
        </LinkButton>
      </div>
      {isPinnedSidebarModeAvailable && (
        <NavigationPin
          onClick={togglePinButtonState}
          pinned={leftSidebarPinned}
          className="ml-auto"
        />
      )}
    </div>
  );
};
