import { ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import { includesInLowerCase } from '@/shared/lib/listHelpers';
import { GrowDiv } from '@/shared/ui/GrowDiv';
import { TileList } from '@/shared/ui/TileList';
import { Link, RouteComponentProps } from '@reach/router';
import {
  PageParamsProvider,
  PageParamsSearch,
} from 'bundles/Shared/components/pageParams';
import { DASHBOARD_ENTITY_NAME } from 'bundles/Shared/entities/dashboard/config';
import { ReportDashboardStatus } from 'bundles/Shared/entities/dashboard/model/types/types';
import { useGetApiSettingsReportDashboardsQuery } from '@/shared/api/dashboardSettingsEnhancedApi';
import { DashboardFilterByStatusTabs } from 'bundles/Shared/features/dashboard/filter/byStatus';
import {
  DashboardFilterByTypeTabs,
  type PageParamsDashboardFilterByType,
} from 'bundles/Shared/features/dashboard/filter/byType';
import { DashboardSettingsCard } from 'bundles/Shared/widgets/dashboard/card';
import { isEmpty } from 'lodash-es';
import React, { useMemo, useState } from 'react';
import { IQueryParams } from 'types/Pagination';

type Props = RouteComponentProps;

type PageParams = IQueryParams & {
  status: FlattenEnum<ReportDashboardStatus> | null;
};

const SettingsReportDashboards: React.FC<Props> = () => {
  const [pageParams, setPageParams] = useState<
    PageParams & PageParamsDashboardFilterByType
  >({
    query: '',
    status: null,
    type: null,
  });
  const { data, isLoading } = useGetApiSettingsReportDashboardsQuery();

  const filteredData = useMemo(
    () =>
      (data ?? []).filter((o) => {
        const matchesQuery = includesInLowerCase(
          o.name,
          pageParams.query ?? '',
        );
        const matchesStatus =
          pageParams.status == null || o.status === pageParams.status;
        const matchesType =
          pageParams.type == null || o.type === pageParams.type;

        return matchesQuery && matchesStatus && matchesType;
      }),
    [data, pageParams],
  );

  return (
    <PageParamsProvider pageParams={pageParams} setPageParams={setPageParams}>
      <TileList>
        <TileList.Header>
          <DashboardFilterByStatusTabs
            status={pageParams.status}
            onChange={(status) =>
              setPageParams((prev) => ({ ...prev, status }))
            }
          />
          <DashboardFilterByTypeTabs
            type={pageParams.type}
            onChange={(type) => {
              setPageParams((prev) => ({ ...prev, type }));
            }}
          />
          <GrowDiv />
          <Link to={ROUTES_ROOT.settings.report.dashboards.new.fullPath}>
            <TileList.CreateButton entityName={DASHBOARD_ENTITY_NAME} />
          </Link>
          <PageParamsSearch placeholder="Dashboard Name" />
        </TileList.Header>

        <TileList.ItemCounter count={filteredData?.length ?? 0} />
        <TileList.Items
          isLoading={isLoading}
          isQueryEmpty={isEmpty(pageParams.query)}
          isListEmpty={filteredData?.length === 0}
          noDataOverlayTitle="No Dashboards found"
        >
          {filteredData.map(({ id }) => (
            <DashboardSettingsCard dashboardId={id} key={id} />
          ))}
        </TileList.Items>
      </TileList>
    </PageParamsProvider>
  );
};

export default SettingsReportDashboards;
