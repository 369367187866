import React from 'react';
import { Icon } from 'stories/Icon/Icon';
import StatisticsRow, {
  StatisticRowList,
} from 'bundles/Construction/components/cards/StatisticsRow/StatisticsRow';
import { IChangeOrderEventStatisticsByTiming } from 'bundles/Construction/types';
import { accountingNumberFormat } from '@/shared/lib/formatting/number';
import styles from '@/bundles/Construction/components/cards/COLogsInfoCard/COLogsInfoCard.module.scss';

interface Props {
  impact: IChangeOrderEventStatisticsByTiming;
  onImpactClick: (id: number) => void;
  currentContractSum: number;
}

const Field = ({ label, value }: { label: string; value: React.ReactNode }) => (
  <div className="flex flex-col">
    <span className="light-60 inline-semibold">{label}</span>
    <span className="dark-60 flex items-center lead-semibold">{value}</span>
  </div>
);

function COLogsInfoCard({ currentContractSum, impact, onImpactClick }: Props) {
  return (
    <div className={styles.card}>
      <div className="flex flex-col gap-m">
        <Field
          label="Current Contract Sum"
          value={accountingNumberFormat(currentContractSum)}
        />
        <Field
          label="Timing Impact"
          value={
            <>
              {impact.days} days{' '}
              <Icon className="light-60 ml-s" iconName="info" />
            </>
          }
        />
      </div>
      <div className={styles.hoverPart}>
        <span className="d-block label-regular mb-s">Timing Impact</span>
        <StatisticRowList>
          {impact.changeOrders.map((item) => (
            <StatisticsRow
              key={item.id}
              label={
                <span
                  onClick={() => onImpactClick(item.id)}
                  className="blue cursor-pointer"
                >
                  CO {item.number}
                </span>
              }
              value={`${item.timingImpactDays}d`}
            />
          ))}
        </StatisticRowList>
      </div>
    </div>
  );
}

export default COLogsInfoCard;
