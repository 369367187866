import { Icon } from '@/stories/Icon/Icon';
import { Tooltip } from '@/stories/Tooltip/Tooltip';

export const NO_SELECTED_SOURCES = (
  <div className="light-60 pt-s text-center">
    <div className="inline-semibold mb-s font-semibold">
      No Selected Sources
    </div>
    <div className="secondary-regular px-6 font-normal">
      Just start to select General Ledgers to complete this form
    </div>
  </div>
);

export const PUBLICATION_DATASETS = (
  <div className="flex flex-col gap-s">
    <span className="dark-60 body-semibold">
      What do my Equity Partners see?
    </span>
    <span className="light-60 secondary-regular">
      In Draft status, the data below will remain hidden. Once Published, any
      manual entries will immediately be visible to your equity partners.
    </span>
    <span className="light-60 secondary-regular">
      Any linked transactions via your REport module will only be visible if
      you've published the period. You can revert to Draft status at any time.
    </span>
  </div>
);
export const CAPITAL_SETTINGS_DESCRIPTION_JSX = (
  <div className="flex flex-col gap-s">
    <span className="body-semibold text-neutral-900">Data synchronization</span>
    <span className="light-60 secondary-regular">
      The <b className="font-semibold">From source</b> setting enables linking
      and creating contribution or distribution entries using your financial
      transactions.
    </span>
  </div>
);

export const CAPITAL_ACCRUED_SETTINGS_DESCRIPTION_JSX = (
  <div className="flex flex-col gap-2">
    <span className="body-semibold text-neutral-900">
      Accrued Pref. Settings
    </span>
  </div>
);

export const CAPITAL_ACCRUED_SETTINGS_OPTIONS = [
  {
    label: (
      <div className="flex items-center gap-2">
        <div>Not Applicable</div>
        <Tooltip mainText="Use Not Applicable if this asset will never have Accrued Pref. entries">
          <Icon iconName="info" />
        </Tooltip>
      </div>
    ),
    value: 'not_applicable',
  },
  {
    label: (
      <div className="flex items-center gap-2">
        In Process
        <Tooltip mainText="Use In Process if the Accrued Pref. entries have not been created yet but will be in the future">
          <Icon iconName="info" />
        </Tooltip>
      </div>
    ),
    value: 'in_process',
  },
  {
    label: (
      <div className="flex items-center gap-2">
        Live
        <Tooltip mainText="Use Live if the Accrued Pref. entries are ready to be displayed to Equity Partners">
          <Icon iconName="info" />
        </Tooltip>
      </div>
    ),
    value: 'live',
  },
];
