import React from 'react';
import {
  generatePath,
  ROUTES_ROOT,
  TRouteParams,
  useQueryParams,
} from '@/shared/lib/hooks/useNavigation';
import { useGetProjectQuery } from 'bundles/REconcile/components/development/api/projects';
import {
  NavLink,
  NavLinkList,
  NavSection,
  NavSectionHeader,
  TitleCardWithDetails,
} from 'bundles/REconcile/components/development/home/dashboard/Wrappers';
import { CurrencyFormatter } from 'stories/ValueFormatters/CurrencyFormatter';
import StatisticLine from 'stories/filterCard/statistic/StatisticLine';
import { CssVar } from '@/shared/config/cssVar';
import Label from 'stories/Label/Label';
import StatisticsRow, {
  StatisticRowList,
} from 'bundles/Construction/components/cards/StatisticsRow/StatisticsRow';
import { startCase, sumBy } from 'lodash-es';
import { TitledCard } from 'stories/TitledCard/TitledCard';
import { PercentFormatter } from 'stories/ValueFormatters/PercentFormatter';

const LE_ROUTES = ROUTES_ROOT.reconcile.development.legalEntity;

const LABEL_SETTINGS = {
  generalPartner: {
    title: 'General Partners',
    color: CssVar.primaryLight5,
  },
  limitedPartner: {
    title: 'Limited Partners',
    color: CssVar.teal,
  },
  lender: {
    title: 'Lenders',
    color: CssVar.violet,
  },
} as const;

const CardWithDetails = ({ label, value, sources }) => {
  const statisticLabels = [
    ...Object.keys(LABEL_SETTINGS).map((key) => ({
      color: LABEL_SETTINGS[key].color,
      hoverColor: LABEL_SETTINGS[key].color,
      value: Number(sources[key]),
    })),
    {
      color: CssVar.light10,
      hoverColor: CssVar.light10,
      value: 100 - sumBy(Object.values(sources), (amount) => Number(amount)),
    },
  ];

  return (
    <TitleCardWithDetails
      hoverContent={
        <StatisticRowList className="w-full">
          {Object.keys(LABEL_SETTINGS).map((key) => (
            <StatisticsRow
              key={LABEL_SETTINGS[key].title}
              label={
                <span className="flex items-center gap-1 text-light-90">
                  <div
                    style={{ backgroundColor: LABEL_SETTINGS[key].color }}
                    className="h-[8px] w-[8px] rounded-[100%]"
                  />
                  {LABEL_SETTINGS[key].title}
                </span>
              }
              value={<PercentFormatter value={sources[key]} />}
            />
          ))}
        </StatisticRowList>
      }
      title={startCase(label)}
    >
      <div className="flex flex-col items-center gap-4">
        <CurrencyFormatter
          abbreviate
          classes={{ wrapper: 'header3-bold', value: 'text-dark-60' }}
          value={value}
        />
        <StatisticLine className="w-[153px]" statistics={statisticLabels} />
        <div className="flex flex-wrap items-center justify-center gap-x-4 gap-y-2">
          {Object.values(LABEL_SETTINGS).map((settings) => (
            <Label key={settings.title} color={settings.color}>
              {settings.title}
            </Label>
          ))}
        </div>
      </div>
    </TitleCardWithDetails>
  );
};

function ReconcileDevelopmentDashboardDrawPackageNav() {
  const { projectId } = useQueryParams<TRouteParams['RECONCILE_DEVELOPMENT']>();
  const { data, isSuccess } = useGetProjectQuery(projectId!);

  const legalEntityCode = data!.legalEntity.code;

  const links = [
    {
      to: generatePath(LE_ROUTES.invoices.fullPath, { legalEntityCode }),
      title: 'Invoices',
    },
    {
      to: generatePath(LE_ROUTES.requisitions.fullPath, {
        legalEntityCode,
      }),
      title: 'Requisitions',
    },
    {
      to: generatePath(LE_ROUTES.payApps.fullPath, { legalEntityCode }),
      title: 'Pay Apps',
    },
    {
      to: generatePath(LE_ROUTES.draws.fullPath, { legalEntityCode }),
      title: 'Draws',
    },
  ];

  return (
    <NavSection>
      <NavSectionHeader title="Draw Package">
        <NavLinkList>
          {links.map((link) => (
            <NavLink key={link.to} to={link.to}>
              {link.title}
            </NavLink>
          ))}
        </NavLinkList>
      </NavSectionHeader>
      {isSuccess && (
        <div className="grid grid-cols-3 gap-4">
          <CardWithDetails
            label="Funded To Date"
            value={data?.drawPackage.fundedToDate.totalAmount}
            sources={data?.drawPackage.fundedToDate.sources}
          />
          <TitledCard title="Current Requisitions">
            <CurrencyFormatter
              classes={{ wrapper: 'header3-bold', value: 'text-dark-60' }}
              abbreviate
              value={data?.drawPackage.currentRequisition.totalAmount}
            />
          </TitledCard>
          <CardWithDetails
            label="Balance"
            value={data?.drawPackage.balance.totalAmount}
            sources={data?.drawPackage.balance.sources}
          />
        </div>
      )}
    </NavSection>
  );
}

export default ReconcileDevelopmentDashboardDrawPackageNav;
