import { PAGES_WITHOUT_FOOTER } from '@/app/config';
import { useLocation } from '@reach/router';
import { SymmetreLogo } from 'bundles/Shared/components/LeftSidebar/Logo/Logo';
import { useMemo } from 'react';
import '@/app/ui/footer/footer.css';

export const Footer = () => {
  const { pathname: locationPathname } = useLocation();
  const footerIsVisible = useMemo(
    () =>
      !PAGES_WITHOUT_FOOTER.some((pageRoute) =>
        locationPathname.includes(pageRoute),
      ),
    [locationPathname],
  );

  if (!footerIsVisible) return;

  return (
    <footer className="footer flex flex-col items-center justify-center gap-6 md:flex-row md:justify-between">
      <div className="flex items-center">
        <span className="mr-s">Powered by</span>
        <a href="https://symmetre.com" target="blank" className="footer_logo">
          <SymmetreLogo variant="large" />
        </a>
      </div>
      <div>
        <a href="https://symmetre.com/" target="blank" className="blue mr-m">
          About us
        </a>
        <span>© symmetRE, Inc., {new Date().getFullYear()}</span>
      </div>
    </footer>
  );
};

export default Footer;
