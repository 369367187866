import * as React from 'react';
import { DocumentCard } from 'stories/DocumentCard/DocumentCard';
import { IconButton } from 'stories/IconButton/IconButton';
import { ComponentProps } from 'react';
import { cn } from '@/shared/lib/css/cn';

interface Props
  extends Omit<
    ComponentProps<typeof DocumentCard>,
    'actions' | 'filenameClassName' | 'background'
  > {
  variant: 'light' | 'dark';
  onRemove: () => void;
}

export function CommentsDocumentCard({ variant, onRemove, ...props }: Props) {
  return (
    <DocumentCard
      className={cn('border-0', variant === 'dark' ? 'bg-dark-80' : 'bg-white')}
      classes={{
        fileName: cn(variant === 'dark' && 'white'),
        icon: cn(variant === 'dark' && 'bg-white'),
      }}
      actions={
        onRemove && (
          <IconButton
            onClick={onRemove}
            iconName="closeSmall"
            className={cn(variant === 'dark' && 'bg-dark-40')}
            variant={variant === 'light' ? 'secondary' : 'primary'}
          />
        )
      }
      {...props}
    />
  );
}

export default CommentsDocumentCard;
