import { usePutApiReconcileForecastingForecastsByForecastIdBudgetsAndIdMutation } from '@/entities/reconcile/forecasting/api/reconcileForecastingEntitiesEnchancedApi';
import { ForecastingBudget } from '@/entities/reconcile/forecasting/api/reconcileForecastingEntitiesGeneratedApi';
import { EntityToastrCreator } from '@/shared/lib/toastr/entityToastrCreator';
import { useCallback } from 'react';

const t = new EntityToastrCreator('Budget');

export function useUpdateForecastingBudgetState() {
  const [updateBudget, options] =
    usePutApiReconcileForecastingForecastsByForecastIdBudgetsAndIdMutation();

  const handleUpdate = useCallback(
    async ({
      id,
      forecastId,
      state,
    }: {
      id: ForecastingBudget['id'];
      forecastId: ForecastingBudget['forecast']['id'];
      state: Exclude<ForecastingBudget['state'], 'no_budget'>;
    }) => {
      await updateBudget({
        id,
        forecastId,
        body: {
          budget: {
            state,
          },
        },
      });

      toastr.success(t.update().toString());
    },
    [],
  );

  return [handleUpdate, options] as const;
}
