import { EntityToastrCreator } from '@/shared/lib/toastr/entityToastrCreator';
import {
  PutApiSettingsReportRentRollNonRevenueConfigApiResponse,
  settingsReportNonRevenueConfigApi,
} from '@/shared/api/settingsReportNonRevenueConfigGeneratedApi';

const SETTINGS_REPORT_NON_REVENUE_CONFIG_TAG = 'SettingsReportNonRevenueConfig';
const t = new EntityToastrCreator('Non Revenue Config');
export const settingsReportNonRevenueConfigApiEnhanced =
  settingsReportNonRevenueConfigApi.enhanceEndpoints({
    addTagTypes: [SETTINGS_REPORT_NON_REVENUE_CONFIG_TAG],
    endpoints: {
      getApiSettingsReportRentRollNonRevenueConfig: {
        providesTags: [SETTINGS_REPORT_NON_REVENUE_CONFIG_TAG],
      },
      putApiSettingsReportRentRollNonRevenueConfig: {
        invalidatesTags: [SETTINGS_REPORT_NON_REVENUE_CONFIG_TAG],
        transformResponse: (
          response: PutApiSettingsReportRentRollNonRevenueConfigApiResponse,
        ) => {
          toastr.success(t.update().toString());
          return response;
        },
      },
    },
  });

export const {
  useGetApiSettingsReportRentRollNonRevenueConfigQuery,
  usePutApiSettingsReportRentRollNonRevenueConfigMutation,
} = settingsReportNonRevenueConfigApiEnhanced;
