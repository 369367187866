import React from 'react';
import { IconButton } from '@/stories/IconButton/IconButton';
import { Icon } from '@/stories/Icon/Icon';
import { AssetInfoCard } from '@/stories/AssetInfoCard/AssetInfoCard';

interface Props {
  onEdit: () => void;
  onDelete: () => void;
  website: string;
  websiteTitle: string;
  canManage: boolean;
}

export const AssetWebsiteCard = ({
  website,
  websiteTitle,
  onEdit,
  onDelete,
  canManage,
}: Props) => {
  const removeProtocol = (link: string) =>
    link.replace(/^https?:\/\//, '').replace(/^www\./, '');

  return (
    <AssetInfoCard
      content={
        <div className="flex  items-center">
          <div
            className={
              'flex h-[40px] w-[40px] items-center justify-center rounded-full bg-neutral-000 p-4'
            }
          >
            <Icon
              className="text-[1.5rem] bg-neutral-000"
              size="l"
              iconName="globe"
            />
          </div>
          <a className="ml-m" href={website} target="_blank" rel="noreferrer">
            <div className="blue body-semibold mb-xxs">{websiteTitle}</div>
            <div className={'light-60 inline-regular break-all'}>
              {removeProtocol(website)}
            </div>
          </a>
        </div>
      }
      actions={
        canManage && (
          <>
            <IconButton onClick={onEdit} variant="secondary" iconName="edit" />
            <IconButton
              onClick={onDelete}
              variant="secondary"
              iconName="trash"
            />
          </>
        )
      }
    />
  );
};

export default AssetWebsiteCard;
