import { ShownInfoLine } from '@/bundles/Shared/components/Table/pagination/TablePagination';
import { useCreateForecast } from '@/entities/reconcile/forecasting/features/api/forecast/useCreateForecast';
import { useForecasts } from '@/entities/reconcile/forecasting/features/api/forecast/useForecasts';
import { useGetForecastNameFromURL } from '@/entities/reconcile/forecasting/lib';
import { cn } from '@/shared/lib/css/cn';
import { useAppSelector } from '@/shared/lib/hooks/redux';
import { useNavigateToFirst } from '@/shared/lib/hooks/useNavigateToFirst';
import { ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import { RouteComponentProps } from '@reach/router';
import NoDataOverlay from 'bundles/Shared/components/NoDataOverlay';
import ListNavLayout from 'bundles/Shared/components/layouts/screenWithListNavigationLayout/ScreenWithListNavigationLayout';
import { range } from 'lodash-es';
import { FC, PropsWithChildren } from 'react';
import SkeletonBlock from 'stories/ProjectCard/SkeletonBlock';
import { Button } from 'stories/Button/Button';
import ForecastListItem from '@/entities/reconcile/forecasting/features/ui/ForecastListItem';

const ForecastingPage: FC<RouteComponentProps & PropsWithChildren> = ({
  children,
}) => {
  const uwbSlice = useAppSelector((state) => state.underwritingBudgetSlice);
  const { isTableExpanded } = uwbSlice;

  const { forecasts, forecastsCount, isFetching, isLoading } = useForecasts();
  const { createForecast: handleCreateForecast } = useCreateForecast();

  const forecastNameFromURL = useGetForecastNameFromURL();

  useNavigateToFirst({
    items: forecasts,
    matchParamKey: 'forecastName',
    path: ROUTES_ROOT.reconcile.forecasting.forecast.fullPath,
    idKey: 'name',
  });

  return (
    <ListNavLayout
      className={cn(
        isTableExpanded
          ? 'flex h-[initial]'
          : 'grid-cols-[25.5rem_minmax(auto,1fr)] 2xl:grid-cols-[25.5rem_minmax(auto,1fr)]',
      )}
    >
      <ListNavLayout.Navigation
        className={cn('next-div-w-full relative', isTableExpanded && 'hidden')}
      >
        <ListNavLayout.NavigationHeaderGroup className="flex-row items-end justify-between">
          <ListNavLayout.NavigationHeader
            title="Forecasting"
            subtitle="REconcile"
          />
          <Button
            disabled={isLoading}
            onClick={handleCreateForecast}
            variant="primary"
            size="s"
          >
            New Forecast
          </Button>
        </ListNavLayout.NavigationHeaderGroup>

        {Boolean(forecastsCount) && (
          <ShownInfoLine totalSize={forecastsCount ?? 0} />
        )}
        {isLoading && (
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap overflow-hidden rounded-2xl">
              {range(0, 5).map((i) => (
                <div key={i} className="flex flex-col gap-4 bg-light-5">
                  <SkeletonBlock className="h-[112px]" />
                </div>
              ))}
            </div>
          </div>
        )}
        <ListNavLayout.NavigationCardList className="rounded-2xl">
          {!isLoading &&
            forecasts?.map((item) => (
              <ForecastListItem
                {...item}
                key={item.id}
                selected={item.name === forecastNameFromURL}
              />
            ))}

          {!isFetching && forecastsCount === 0 && (
            <NoDataOverlay title="Nothing found" />
          )}
        </ListNavLayout.NavigationCardList>
      </ListNavLayout.Navigation>

      {children}
    </ListNavLayout>
  );
};

export default ForecastingPage;
