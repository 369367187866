import React, { FC } from 'react';
import { CurrencyFormatter } from 'stories/ValueFormatters/CurrencyFormatter';
import { getVendorFormatter } from 'bundles/Shared/components/Table/formatters/drawPackage';
import { IDevelopmentLegalEntityInvoiceTransaction } from 'bundles/DrawPackage/InvoicesFlow/Invoices/pay/MarkAsPaidInvoice';
import { Card } from 'bundles/DrawPackage/InvoicesFlow/Invoices/layouts/Card';

interface ITransactionCardProps
  extends IDevelopmentLegalEntityInvoiceTransaction {
  handleRemove: (id: IDevelopmentLegalEntityInvoiceTransaction['id']) => void;
  vendorName?: string;
}

const TransactionCard: FC<ITransactionCardProps> = ({
  id,
  value,
  date,
  vendorName,
  handleRemove,
}) => (
  <Card id={id} handleRemove={handleRemove}>
    <div className="flex flex-1 flex-col gap-2">
      <div className="flex justify-between">
        <div className="inline-regular text-light-90">{date}</div>
        <CurrencyFormatter value={value} />
      </div>
      {getVendorFormatter(
        vendorName
          ? {
              name: vendorName,
            }
          : undefined,
      )}
    </div>
  </Card>
);

export default TransactionCard;
