import * as React from 'react';
import { ISharedDocument } from 'types/SharedDocument';
import { Link, useLocation } from '@reach/router';
import { Icon } from 'stories/Icon/Icon';
import { PseudoLink } from 'stories/PseudoLink/PseudoLink';
import { ReactComponent as LastLine } from 'images/treeConnectors/lastLine.svg';
import { generatePath, ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import { stringify } from 'qs';

interface Props {
  row: ISharedDocument;
}

function DocumentableAndFolder({ row }: Props) {
  const { pathname } = useLocation();
  const { slug, objectType } = row.documentable;
  const isAsset = objectType == 'Asset';

  const documentableScreenPath = generatePath(
    isAsset
      ? ROUTES_ROOT.assets.asset.fullPath
      : ROUTES_ROOT.funds.fund.fullPath,
    { assetSlug: slug, fundSlug: slug },
  );
  const documentableDocumentsScreenPath = generatePath(
    isAsset
      ? ROUTES_ROOT.assets.asset.documents.fullPath
      : ROUTES_ROOT.funds.fund.documents.fullPath,
    { assetSlug: slug, fundSlug: slug },
  );
  const queryParams = stringify(
    { folderId: String(row.folder.id) },
    { addQueryPrefix: true },
  );
  const documentableDocumentsScreenPathWithQueryParams = `${documentableDocumentsScreenPath}${queryParams}`;

  return (
    <div className="flex flex-col">
      <div className="flex min-h-[24px] items-center gap-1">
        <Icon iconName="asset" className="text-light-60" />
        <Link state={{ from: pathname }} to={documentableScreenPath}>
          <PseudoLink className="secondary-semibold text-dark-60">
            {row.documentable.name}
          </PseudoLink>
        </Link>
      </div>

      <div className="flex items-center gap-1">
        <div className="relative h-6 w-4">
          <LastLine className="absolute -top-1" />
        </div>
        <Icon iconName="folder" className="text-light-60" />
        <Link
          className="secondary-regular leading-none text-blue"
          state={{ from: pathname }}
          to={documentableDocumentsScreenPathWithQueryParams}
        >
          {row.folder.title}
        </Link>
      </div>
    </div>
  );
}

export default DocumentableAndFolder;
