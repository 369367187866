import React, { FC } from 'react';
import ArrowsTypeField from '@/bundles/Shared/components/HistoryDetails/HistoryTypeFields/ArrowsTypeField';
import '@/bundles/Shared/components/HistoryDetails/history-details.scss';

export enum TTypeFieldHD {
  ARROWS = 'ARROWS',
}

interface IHistoryDetailsConfig {
  changes: {
    current: any;
    old: any;
  };
  title: string;
  transformerView?: (value: any) => any;
  objectName?: string;
  styles?: {
    wrapper: string;
  };
  isArray?: boolean;
}

interface IHistoryDetailsSwitcherProps extends IHistoryDetailsConfig {
  typeField: TTypeFieldHD;
}

const HistoryDetailsSwitcher: FC<IHistoryDetailsSwitcherProps> = ({
  typeField,
  title,
  changes,
  transformerView,
  objectName,
  styles,
  isArray,
}) => {
  const config: IHistoryDetailsConfig = {
    title,
    changes,
    transformerView,
    objectName,
    styles,
    isArray,
  };

  switch (typeField) {
    case TTypeFieldHD.ARROWS:
      return <ArrowsTypeField {...config} />;
  }
};

export default HistoryDetailsSwitcher;
