import {
  PERMISSIONS_TABLE,
  PermissionTableHeaderJSX,
} from '@/bundles/UserManagement/components/roles/constants';
import styles from '@/bundles/UserManagement/components/roles/RightBar/styles.module.scss';
import { prepareProductName } from '@/bundles/UserManagement/components/roles/utils';
import { cn } from '@/shared/lib/css/cn';
import { sortBy } from 'lodash-es';
import { Tumbler } from 'stories/Tumbler/Tumbler';
import {
  IProduct,
  IProductPermissionKeys,
  IUserRole,
  TUserRoleCategory,
} from 'types/User';

interface Props {
  roleDataState: IUserRole | Omit<IUserRole, 'id' | 'users'>;
  actions: {
    handleChangedState: () => void;
    resetState: () => void;
    toggleDataPermission: (
      key: IProductPermissionKeys,
      id: IProduct['id'],
    ) => void;
  };
  requiredMark?: boolean;
  readonly?: boolean;
  isSaving?: boolean;
}
export function RolePermissions({
  actions,
  roleDataState,
  requiredMark,
  readonly,
  isSaving,
}: Props) {
  const isTumberDisabled = (
    product: IProduct,
    permissionTableKey: (typeof PERMISSIONS_TABLE)[number]['key'],
  ) => {
    if (isSaving || readonly) return true;

    // External Users cannot `manage` or `configure`
    if (
      roleDataState.category === TUserRoleCategory.EXTERNAL &&
      permissionTableKey !== 'canView'
    ) {
      return true;
    }

    // TODO: FE-3890 add this condition to the backend
    if (
      roleDataState.category === TUserRoleCategory.EXTERNAL &&
      product.name == 'return'
    ) {
      return true;
    }

    return false;
  };

  return (
    <div data-name="permissions" className="mb-auto">
      <div className="mb-2 flex items-center justify-between">
        <span className="inline-semibold text-light-90">
          Permissions{requiredMark && <span className="red"> *</span>}
        </span>
      </div>

      <div className="overflow-hidden rounded-[8px]">
        <div className={cn('h-[3rem]', styles.row)}>
          {PermissionTableHeaderJSX}
        </div>

        <div className="grid gap">
          {sortBy(roleDataState.products, ['name']).map((product) => (
            <div key={product.id} className={styles.row}>
              <div className="inline-regular flex h-[3.5rem] items-center pl-4 text-dark-60">
                {prepareProductName(product.name)}
              </div>
              {PERMISSIONS_TABLE.map(({ key }) => (
                <div
                  key={key}
                  className="inline-regular flex h-[3.5rem] items-center justify-center text-dark-60"
                >
                  <div>
                    <Tumbler
                      disabled={isTumberDisabled(product, key)}
                      classes={{ input: 'sre-tumbler__input_green' }}
                      onChange={() =>
                        actions.toggleDataPermission(key, product.id)
                      }
                      checked={product[key]}
                    />
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
