import React from 'react';
import { FieldsManageable } from 'stories/FieldsManageable/FieldsManageable';
import { updatePipelineCard } from 'bundles/Settings/components/DealPath/actions';
import { IPipelineCard } from 'bundles/Settings/components/DealPath/PipelineCards';
import { useAppSelector } from '@/shared/lib/hooks/redux';
import { optionFor, optionsFor } from 'lib/helpers';
import Select from 'react-select';
import Field from 'stories/Field/Field';
import Label from 'stories/Label/Label';

interface Props {
  card: IPipelineCard;
}

const reservedFields = [optionFor('count')];

const SnapshotCard = ({ card }: Props) => {
  const dealPathFields = useAppSelector(
    (state) => state.dealPathSettings.dealPathFields,
  );
  const categories = useAppSelector(
    (state) => state.dealPathSettings.categories,
  );
  const activeFieldsOptions = dealPathFields
    .filter((f) => f.active)
    .map((f) => optionFor(f.key))
    .concat(reservedFields);

  return (
    <div>
      <FieldsManageable
        initFields={card.schema ?? []}
        selectOptions={activeFieldsOptions}
        onChange={(fields) => updatePipelineCard(card, { schema: fields })}
      />
      <Field labelText="Categories" className="mt-m">
        <Select
          isMulti
          options={optionsFor(categories.map((c) => c.name))}
          onChange={(values) =>
            updatePipelineCard(card, {
              meta: { categories: values.map((v) => v.value) },
            })
          }
          defaultValue={optionsFor(card.meta?.categories || [])}
          classNamePrefix="react-select"
          placeholder="Select specific categories"
        />
      </Field>
    </div>
  );
};

export default SnapshotCard;
