import React, { useEffect, useRef } from 'react';
import { navigate } from '@reach/router';
import { Breadcrumbs } from 'stories/Breadcrumbs/Breadcrumbs';
import { useNavigation } from '@/shared/lib/hooks/useNavigation';

interface Props {
  assetName: string;
}

function DevelopmentLegalEntityBreadcrumbs({ assetName }: Props) {
  const { getUrl } = useNavigation();
  // todo new routing
  const fromUrl = useRef<string>();
  useEffect(() => {
    fromUrl.current = window.history.state?.from;
  }, []);

  return (
    <Breadcrumbs
      onBackClick={() => {
        const reconcileUrl = getUrl('RECONCILE_DEVELOPMENT', {});
        if (fromUrl.current && fromUrl.current.includes(reconcileUrl)) {
          navigate(-1);
          return;
        }
        navigate(reconcileUrl);
      }}
      backgroundColor="white"
      onClick={(path) => navigate(path)}
      breadcrumbs={[
        { label: 'REconcile', to: '../', isCurrent: false },
        { label: 'Development', to: '../', isCurrent: false },
        { label: assetName, to: '', isCurrent: true },
      ]}
    />
  );
}

export default DevelopmentLegalEntityBreadcrumbs;
