import { cn } from '@/shared/lib/css/cn';
import { Icon } from 'stories/Icon/Icon';
import { IconButton } from 'stories/IconButton/IconButton';
import { SeparatorLine } from '@/bundles/REturn/components/Ownership/modals/HeaderForStepperModal/SeparatorLine';

export interface Step {
  name: string;
}

interface Props extends PropsWithClassName {
  heading: string;
  currentIdx: number;
  steps: Step[];
  onClose: VoidFunction;
  disableCloseBtn?: boolean;
}

export default function HeaderForStepperModal({
  heading,
  currentIdx,
  steps,
  onClose,
  className,
  disableCloseBtn = false,
}: Props) {
  return (
    <div className={cn('border-b border-light-10 bg-white', className)}>
      <div className="relative grid grid-cols-[1fr_5fr_1fr] items-center justify-center px-5 py-6">
        <div />
        <h5 className="header5-bold text-center text-dark-60">{heading}</h5>
        <IconButton
          disabled={disableCloseBtn}
          onClick={onClose}
          size="l"
          variant="white"
          iconName="close"
          className="justify-self-end"
        />
      </div>
      <div className="mx-auto flex max-w-screen-md items-center justify-center py-4">
        {steps.map((step, idx, arr) => (
          <div
            key={step.name}
            className="gap-0.5 grid min-w-[166px] flex-1 place-items-center"
          >
            <div
              className={cn(
                'relative grid w-full grid-cols-[1fr_max-content_1fr] items-center gap-2',
              )}
            >
              <SeparatorLine hidden={idx === 0} position="left" />
              <Icon
                iconName="checkSmall"
                className={cn('h-4 w-4 text-center transition', {
                  'bg-green-light-3 text-green-dark-1': idx < currentIdx,
                  'bg-light-90 text-white': idx === currentIdx,
                  'bg-light-20 text-white': idx > currentIdx,
                })}
              />
              <SeparatorLine hidden={idx === arr.length - 1} position="right" />
            </div>
            <span className="inline-semibold text-dark-60">{step.name}</span>
          </div>
        ))}
      </div>
    </div>
  );
}
