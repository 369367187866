import { cn } from '@/shared/lib/css/cn';

import { REPORT_BUILDER_GRID_COLS_FULL_SPAN_CLASSNAME } from 'bundles/Shared/widgets/dashboard/widgets/common/lib/reportBuilder';
import { PropsWithChildren } from 'react';

export const EmptyWidget = ({
  children,
  className,
}: PropsWithChildren & PropsWithClassName) => (
  <div
    className={cn(
      'inline-regular flex h-[50px] w-full break-inside-avoid-page items-center justify-center rounded-[16px] bg-neutral-100 text-neutral-500',
      REPORT_BUILDER_GRID_COLS_FULL_SPAN_CLASSNAME,
      className,
    )}
  >
    {children}
  </div>
);
