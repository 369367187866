import React, { FC } from 'react';
import { Button } from 'stories/Button/Button';
import { useModal } from '@/shared/lib/hooks/useModal';
import { useParams } from '@reach/router';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import {
  addInvoiceLineItem,
  selectInvoiceLineItems,
} from 'bundles/Construction/reducers/reconcileInvoiceStateSlice';
import ManualLineItems from '@/bundles/DrawPackage/InvoicesFlow/Invoices/verify/lineItems/ManualLineItems';
import { useGetDevelopmentCategoryFlatQuery } from 'bundles/Construction/api/core';
import {
  selectInvoiceDetails,
  setEditInvoiceChanging,
} from 'bundles/DrawPackage/slices/ReconcileInvoicesSlice';
import FlowLineItems from '@/bundles/DrawPackage/InvoicesFlow/Invoices/verify/lineItems/FlowLineItems';
import SelectLineItems from '@/bundles/DrawPackage/InvoicesFlow/Invoices/verify/lineItems/SelectLineItems';
import { TLineItemFlowType } from '@/bundles/DrawPackage/InvoicesFlow/Invoices/verify/VerifyInvoice';
import AllocationStatistics from 'bundles/REconcile/components/AllocationStatistics';
import LineItemModal from 'bundles/REconcile/components/LineItemModal';
import { transformCategorizedIdToKey } from 'bundles/Construction/components/Reallocation/Modals/components/utils';
import { useAllocation } from 'bundles/REconcile/components/AllocationProvider';

import { formatToDateStringForRequest } from '@/shared/lib/converters';

interface ILineItemInvoicesProps {
  changeFlow: (flow: TLineItemFlowType) => void;
  flow: TLineItemFlowType;
  editFlow?: boolean;
}

const LineItemsInvoices: FC<ILineItemInvoicesProps> = ({
  flow,
  changeFlow,
  editFlow,
}) => {
  const dispatch = useAppDispatch();
  const { openModal } = useModal();
  const params = useParams();
  const lineItems = useAppSelector(selectInvoiceLineItems);
  const invoiceDetails = useAppSelector(selectInvoiceDetails);

  const { data: categoriesFlat } = useGetDevelopmentCategoryFlatQuery({
    legalEntityCode: params?.legalEntityCode,
    without_sovcs: 'false',
  });
  const selectedLineItems = lineItems.filter(
    (lineItem) => flow !== 'select' || lineItem.selected,
  );
  const { total, allocatedAmount, notAllocatedAmount } = useAllocation({
    items: selectedLineItems,
    sumMapper: (item) => Number(item.amount),
    total: Number(invoiceDetails.amount),
  });

  const openLineItemsModal = async () => {
    const res = await openModal(LineItemModal, {
      header: (
        <span className="header6-bold text-dark-60">
          Create Development Line Item
        </span>
      ),
      classes: {
        wrapper: '!my-0 !ml-auto !mr-4',
      },
      fieldSettings: {
        date: {
          disabled: true,
        },
        vendor: {
          disabled: true,
        },
        amount: {
          max: notAllocatedAmount,
          suggestedValue: notAllocatedAmount,
        },
      },
      legalEntityCode: params.legalEntityCode,
      lineItem: {
        vendor: invoiceDetails.vendor_name.value,
        date: new Date(invoiceDetails.date),
      },
      submitText: 'Create Item',
    });
    dispatch(setEditInvoiceChanging(false));
    dispatch(
      addInvoiceLineItem({
        ...res,
        date: formatToDateStringForRequest(res.date),
      }),
    );
  };

  const transformLineItems = lineItems.map((item) => {
    if (item.developmentCategory) {
      return item;
    }
    // todo standardize category_key
    const categoryItem = categoriesFlat?.find(
      (category) =>
        category.key ===
          transformCategorizedIdToKey(item.category_id, item.category_type) ||
        category.key === item.category_id,
    );

    return {
      amount: item.amount,
      developmentCategory: {
        name: categoryItem?.name ?? '',
        path: categoryItem?.categoryPath ?? '',
      },
      selected: item?.selected,
      description: item.description ?? item?.remark,
    };
  });

  return (
    <div className="flex flex-1 flex-col">
      <div className="border-b border-light-10 bg-white px-6 py-4">
        <div className="body-semibold mb-2 text-dark-60">
          Development Line Items
        </div>
        <div className="flex justify-between gap-4">
          <FlowLineItems changeFlow={changeFlow} />
          <div>
            <Button
              variant="primary"
              size="m"
              onClick={openLineItemsModal}
              className="whitespace-nowrap"
            >
              Create Line Item
            </Button>
          </div>
        </div>
        <AllocationStatistics
          className="px-4 pt-4"
          totalAmount={total ?? 0}
          allocatedAmount={allocatedAmount}
        />
      </div>
      <div className="flex-1 border-b border-light-10 bg-light px-6 py-4">
        {flow === 'manually' && (
          <ManualLineItems items={transformLineItems} editFlow={editFlow} />
        )}
        {flow === 'select' && (
          <SelectLineItems
            items={transformLineItems}
            maxAmount={notAllocatedAmount}
            editFlow={editFlow}
          />
        )}
      </div>
    </div>
  );
};

export default LineItemsInvoices;
