import { listenerMiddleware } from '@/app/stores/listener';
import { isAnyOf, ThunkDispatch } from '@reduxjs/toolkit';
import {
  PostApiSettingsReportComparisonDashboardsApiResponse,
  PostApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsApiResponse,
  PostApiSettingsReportEagleEyeDashboardsApiResponse,
  PostApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdWidgetSectionsApiResponse,
  PostApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsApiResponse,
  PostApiSettingsReportObjectDashboardsApiResponse,
  PostApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdWidgetSectionsApiResponse,
  PostApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsApiResponse,
  PutApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdApiResponse,
  PutApiSettingsReportComparisonDashboardsByIdApiResponse,
  PutApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdApiResponse,
  PutApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdWidgetSectionsIdApiResponse,
  PutApiSettingsReportObjectDashboardsByIdApiResponse,
  PutApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdApiResponse,
  PutApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdWidgetSectionsIdApiResponse,
} from '@/shared/api/dashboardsSettingsGeneratedApi';
import { settingsReportFormulasApiEnhanced } from '@/shared/api/settingsReportFormulasEnhanced';
import { mustFind } from 'lib/typeHelpers';

import { fetchGenericData } from '@/app/reducers/actions';
import { createApiTagType } from '@/shared/lib/api/lib';
import { produce } from 'immer';
import { Action } from 'redux';
import {
  GetApiReportScoreboardsByIdApiResponse,
  reportDashboardsApi,
} from '@/shared/api/dashboardsGeneratedApi';

const DASHBOARD_SETTINGS_TAG = 'DashboardSettings';
const EAGLE_EYE_DASHBOARD_SETTING_TAG = 'EagleEyeDashboardSetting';
const OBJECT_DASHBOARD_SETTING_TAG = 'ObjectDashboardSetting';
const WIDGET_TAG = 'ObjectDashboardWidget';
const DASHBOARD_TAG = 'EagleEyeDashboard';
const DASHBOARD_WIDGET_TAG = 'ObjectDashboardWidget';
const DASHBOARD_WIDGET_DATA_TAG = 'ObjectDashboardWidgetData';

const getDeleteDashboardQueryStartedHandler =
  () =>
  async (
    { ...patch },
    {
      dispatch,
      queryFulfilled,
    }: {
      dispatch: ThunkDispatch<unknown, unknown, Action>;
      queryFulfilled: Promise<unknown>;
    },
  ) => {
    const patchResult = dispatch(
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      enhancedApi.util.updateQueryData(
        'getApiSettingsReportDashboards',
        undefined,
        (draft) => {
          const index = draft.findIndex((item) => item.id === patch.id);
          draft.splice(index, 1);
        },
      ),
    );
    try {
      await queryFulfilled;
      dispatch(fetchGenericData());
    } catch {
      patchResult.undo();
    }
  };

const getDashboardTransformResponseHandler =
  () => (response: GetApiReportScoreboardsByIdApiResponse) => {
    return produce(response, (draft) => {
      const setFields = (
        section: NonNullable<
          GetApiReportScoreboardsByIdApiResponse['item']['boards']
        >[number]['sections'][number],
      ) => {
        // eslint-disable-next-line no-param-reassign
        section.id = section.id.toString();
        // eslint-disable-next-line no-param-reassign
        section.widgetType =
          'widgetType' in section ? section.widgetType : section.sectionType;
      };
      (draft.item?.boards ?? draft.boards)?.forEach((board) => {
        board.sections?.forEach(setFields);
      });
    });
  };

const enhancedApi = reportDashboardsApi.enhanceEndpoints({
  addTagTypes: [
    DASHBOARD_SETTINGS_TAG,

    EAGLE_EYE_DASHBOARD_SETTING_TAG,
    DASHBOARD_TAG,

    OBJECT_DASHBOARD_SETTING_TAG,
    WIDGET_TAG,
    DASHBOARD_WIDGET_TAG,
    DASHBOARD_WIDGET_DATA_TAG,

    'ComparisonDashboardTag', // View pages
    'ComparisonDashboardSettingsTag', // Edit pages
    'ComparisonDashboardWidgetTag',
    'ComparisonDashboardWidgetDataTag',
  ],
  endpoints: {
    // GET View Dashboard
    getApiReportScoreboardsById: {
      providesTags: (result, _, arg) =>
        result
          ? [createApiTagType(DASHBOARD_TAG, arg.id), DASHBOARD_TAG]
          : [DASHBOARD_TAG],
      transformResponse: getDashboardTransformResponseHandler(),
    },
    getApiReportObjectDashboardsById: {
      providesTags: (result, _, arg) =>
        result
          ? [createApiTagType(DASHBOARD_TAG, arg.id), DASHBOARD_TAG]
          : [DASHBOARD_TAG],
    },
    getApiReportComparisonDashboardsById: {
      providesTags: [DASHBOARD_TAG],
    },

    getApiSettingsReportDashboards: {
      providesTags: [DASHBOARD_SETTINGS_TAG],
    },

    // GET Settings Dashboard
    getApiSettingsReportObjectDashboardsById: {
      providesTags: (result, _, arg) =>
        result
          ? [
              createApiTagType(OBJECT_DASHBOARD_SETTING_TAG, arg.id),
              OBJECT_DASHBOARD_SETTING_TAG,
            ]
          : [OBJECT_DASHBOARD_SETTING_TAG],
    },

    getApiSettingsReportEagleEyeDashboardsById: {
      providesTags: [EAGLE_EYE_DASHBOARD_SETTING_TAG],
      transformResponse: getDashboardTransformResponseHandler(),
    },
    getApiSettingsReportComparisonDashboardsById: {
      providesTags: (result, _, arg) =>
        result
          ? [
              createApiTagType('ComparisonDashboardSettingsTag', arg.id),
              'ComparisonDashboardSettingsTag',
            ]
          : ['ComparisonDashboardSettingsTag'],
    },

    // Update Settings Dashboard
    putApiSettingsReportEagleEyeDashboardsById: {
      transformResponse: getDashboardTransformResponseHandler(),
      transformErrorResponse: () => {
        toastr.error('Something went wrong');
      },
      invalidatesTags: [DASHBOARD_WIDGET_DATA_TAG, DASHBOARD_TAG],
      onQueryStarted: async ({ id }, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;

          dispatch(
            enhancedApi.util.updateQueryData(
              'getApiSettingsReportEagleEyeDashboardsById',
              { id },
              (draft) => {
                Object.assign(draft, data);
              },
            ),
          );
          dispatch(fetchGenericData());
          dispatch(
            enhancedApi.util.updateQueryData(
              'getApiSettingsReportDashboards',
              undefined,
              (draft) => {
                const updatedScoreboard = mustFind(
                  draft,
                  (item) => item.id === id,
                );
                updatedScoreboard.name = data.name;
                updatedScoreboard.status = data.status;
                updatedScoreboard.permitted = data.permitted;
                updatedScoreboard.slug = data.slug;
              },
            ),
          );
        } catch {
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          console.error('Error updating dashboard');
        }
      },
    },

    putApiSettingsReportComparisonDashboardsById: {
      invalidatesTags: (_, __, arg) => [
        {
          type: 'ComparisonDashboardSettingsTag',
          id: arg.id,
        },
        DASHBOARD_SETTINGS_TAG,
      ],
      transformResponse: (
        response: PutApiSettingsReportComparisonDashboardsByIdApiResponse,
      ) => {
        toastr.success('Dashboard updated successfully');
        return response;
      },
      onCacheEntryAdded: (_, { dispatch }) => {
        dispatch(fetchGenericData());
      },
    },

    putApiSettingsReportObjectDashboardsById: {
      invalidatesTags: (_, __, arg) => [
        { type: OBJECT_DASHBOARD_SETTING_TAG, id: arg.id },
        DASHBOARD_SETTINGS_TAG,
      ],
      transformResponse: (
        response: PutApiSettingsReportObjectDashboardsByIdApiResponse,
      ) => {
        toastr.success('Dashboard updated successfully');
        return response;
      },
      onCacheEntryAdded: (_, { dispatch }) => {
        dispatch(fetchGenericData());
      },
    },

    // Update Layout

    putApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdWidgetSectionsMove:
      {
        transformResponse: (response) => {
          toastr.success('Layout updated successfully');
          return response;
        },
        invalidatesTags: [OBJECT_DASHBOARD_SETTING_TAG, DASHBOARD_TAG],
      },
    putApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdWidgetSectionsMove:
      {
        transformResponse: (response) => {
          toastr.success('Layout updated successfully');
          return response;
        },
        invalidatesTags: [EAGLE_EYE_DASHBOARD_SETTING_TAG, DASHBOARD_TAG],
      },
    putApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdWidgetSectionsMove:
      {
        transformResponse: (response) => {
          toastr.success('Layout updated successfully');
          return response;
        },
        invalidatesTags: ['ComparisonDashboardSettingsTag'],
      },

    // Post Api Settings Dashboards

    postApiSettingsReportObjectDashboards: {
      invalidatesTags: [DASHBOARD_SETTINGS_TAG],
      transformResponse: (
        response: PostApiSettingsReportObjectDashboardsApiResponse,
      ) => {
        toastr.success('Dashboard created successfully');
        return response;
      },
      onCacheEntryAdded: (_, { dispatch }) => {
        dispatch(fetchGenericData());
      },
    },

    postApiSettingsReportEagleEyeDashboards: {
      invalidatesTags: [DASHBOARD_SETTINGS_TAG],
      transformResponse: (
        response: PostApiSettingsReportEagleEyeDashboardsApiResponse,
      ) => {
        toastr.success('Dashboard created successfully');
        return response;
      },
    },

    postApiSettingsReportComparisonDashboards: {
      invalidatesTags: [DASHBOARD_SETTINGS_TAG],
      transformResponse: (
        response: PostApiSettingsReportComparisonDashboardsApiResponse,
      ) => {
        toastr.success('Dashboard created successfully');
        return response;
      },
      onCacheEntryAdded: (_, { dispatch }) => {
        dispatch(fetchGenericData());
      },
    },

    // Delete Api Settings Dashboard

    deleteApiSettingsReportEagleEyeDashboardsById: {
      transformResponse: (response) => {
        toastr.success('Dashboard deleted successfully');
        return response;
      },
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onQueryStarted: getDeleteDashboardQueryStartedHandler(),
    },

    deleteApiSettingsReportObjectDashboardsById: {
      transformResponse: (response) => {
        toastr.success('Dashboard deleted successfully');
        return response;
      },
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onQueryStarted: getDeleteDashboardQueryStartedHandler(),
    },

    deleteApiSettingsReportComparisonDashboardsById: {
      transformResponse: (response) => {
        toastr.success('Dashboard deleted successfully');
        return response;
      },
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onQueryStarted: getDeleteDashboardQueryStartedHandler(),
    },

    // Duplicate Dashboard

    postApiSettingsReportObjectDashboardsDuplicate: {
      invalidatesTags: [DASHBOARD_SETTINGS_TAG, DASHBOARD_TAG],
      onCacheEntryAdded: (_, { dispatch }) => {
        dispatch(fetchGenericData());
      },
      transformResponse: (response) => {
        toastr.success('Dashboard duplicated successfully');
        return response;
      },
    },

    postApiSettingsReportEagleEyeDashboardsDuplicate: {
      invalidatesTags: [DASHBOARD_SETTINGS_TAG],
      onCacheEntryAdded: (_, { dispatch }) => {
        dispatch(fetchGenericData());
      },
      transformResponse: (response) => {
        toastr.success('Dashboard duplicated successfully');
        return response;
      },
    },

    postApiSettingsReportComparisonDashboardsDuplicate: {
      invalidatesTags: [DASHBOARD_SETTINGS_TAG],
      onCacheEntryAdded: (_, { dispatch }) => {
        dispatch(fetchGenericData());
      },
      transformResponse: (response) => {
        toastr.success('Dashboard duplicated successfully');
        return response;
      },
    },

    // Add Widget

    postApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdWidgetSections:
      {
        invalidatesTags: [OBJECT_DASHBOARD_SETTING_TAG, DASHBOARD_TAG],
        transformResponse: (
          response: PostApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdWidgetSectionsApiResponse,
        ) => {
          toastr.success('Widget added successfully');
          return response;
        },
      },

    postApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdWidgetSections:
      {
        invalidatesTags: [EAGLE_EYE_DASHBOARD_SETTING_TAG, DASHBOARD_TAG],
        transformResponse: (
          response: PostApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdWidgetSectionsApiResponse,
        ) => {
          toastr.success('Widget added successfully');
          return response;
        },
      },

    postApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdWidgetSections:
      {
        invalidatesTags: ['ComparisonDashboardSettingsTag', DASHBOARD_TAG],
        transformResponse: (
          response: PostApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdWidgetSectionsApiResponse,
        ) => {
          toastr.success('Widget added successfully');
          return response;
        },
      },

    // Delete Widget

    deleteApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdWidgetSectionsId:
      {
        invalidatesTags: [OBJECT_DASHBOARD_SETTING_TAG, DASHBOARD_TAG],
        transformResponse: (response) => {
          toastr.success('Widget deleted successfully');
          return response;
        },
      },

    deleteApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdWidgetSectionsId:
      {
        invalidatesTags: [EAGLE_EYE_DASHBOARD_SETTING_TAG, DASHBOARD_TAG],
        transformResponse: (response) => {
          toastr.success('Widget deleted successfully');
          return response;
        },
      },

    deleteApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdWidgetSectionsId:
      {
        invalidatesTags: ['ComparisonDashboardSettingsTag', DASHBOARD_TAG],
        transformResponse: (response) => {
          toastr.success('Widget deleted successfully');
          return response;
        },
      },

    // Update Widget

    putApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdWidgetSectionsId:
      {
        transformResponse: (
          response: PutApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdWidgetSectionsIdApiResponse,
        ) => {
          toastr.success('Widget updated successfully');
          return response;
        },
        invalidatesTags: [
          OBJECT_DASHBOARD_SETTING_TAG,
          WIDGET_TAG,
          DASHBOARD_WIDGET_TAG,
        ],
      },

    putApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdWidgetSectionsId:
      {
        transformResponse: (
          response: PutApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdWidgetSectionsIdApiResponse,
        ) => {
          toastr.success('Widget updated successfully');
          return response;
        },
        invalidatesTags: [
          'ComparisonDashboardSettingsTag',
          WIDGET_TAG,
          DASHBOARD_WIDGET_TAG,
          DASHBOARD_TAG,
        ],
        onQueryStarted(patch, { dispatch, queryFulfilled }) {
          const patchResult = dispatch(
            enhancedApi.util.updateQueryData(
              'getApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdWidgetSectionsId',
              {
                id: patch.id,
                boardId: patch.boardId,
                comparisonDashboardId: patch.comparisonDashboardId,
              },
              (draft) => {
                Object.assign(draft, {
                  title: patch.body.title,
                  widgetConfig: patch.body.config,
                });
              },
            ),
          );
          queryFulfilled.catch(patchResult.undo);
        },
      },

    putApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdWidgetSectionsId:
      {
        invalidatesTags: [
          EAGLE_EYE_DASHBOARD_SETTING_TAG,
          WIDGET_TAG,
          DASHBOARD_TAG,
        ],
        transformResponse: (
          response: PutApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdWidgetSectionsIdApiResponse,
        ) => {
          toastr.success('Widget updated successfully');
          return response;
        },
        onQueryStarted(patch, { dispatch, queryFulfilled }) {
          const patchResult = dispatch(
            enhancedApi.util.updateQueryData(
              'getApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdWidgetSectionsId',
              {
                id: patch.id,
                boardId: patch.boardId,
                eagleEyeDashboardId: patch.eagleEyeDashboardId,
              },
              (draft) => {
                Object.assign(draft, {
                  widgetConfig: patch.body.config,
                });
              },
            ),
          );
          queryFulfilled.catch(patchResult.undo);
        },
      },

    // Move Widget
    putApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdWidgetSectionsIdMoveToBoard:
      {
        invalidatesTags: ['ComparisonDashboardSettingsTag', DASHBOARD_TAG],
        transformResponse: (response) => {
          toastr.success('Widget moved successfully');
          return response;
        },
      },

    putApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdWidgetSectionsIdMoveToBoard:
      {
        invalidatesTags: [EAGLE_EYE_DASHBOARD_SETTING_TAG, DASHBOARD_TAG],
        transformResponse: (response) => {
          toastr.success('Widget moved successfully');
          return response;
        },
      },

    putApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdWidgetSectionsIdMoveToBoard:
      {
        invalidatesTags: [OBJECT_DASHBOARD_SETTING_TAG, DASHBOARD_TAG],
        transformResponse: (response) => {
          toastr.success('Widget moved successfully');
          return response;
        },
      },

    // Delete board
    deleteApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardId:
      {
        invalidatesTags: [OBJECT_DASHBOARD_SETTING_TAG, DASHBOARD_TAG],
        transformResponse: (response) => {
          toastr.success('Board deleted successfully');
          return response;
        },
      },

    deleteApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardId:
      {
        invalidatesTags: [EAGLE_EYE_DASHBOARD_SETTING_TAG, DASHBOARD_TAG],
        transformResponse: (response) => {
          toastr.success('Board deleted successfully');
          return response;
        },
      },

    deleteApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardId:
      {
        invalidatesTags: ['ComparisonDashboardSettingsTag', DASHBOARD_TAG],
        transformResponse: (response) => {
          toastr.success('Board deleted successfully');
          return response;
        },
      },

    // Add Board

    postApiSettingsReportObjectDashboardsByObjectDashboardIdBoards: {
      invalidatesTags: [OBJECT_DASHBOARD_SETTING_TAG, DASHBOARD_TAG],
      transformResponse: (
        response: PostApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsApiResponse,
      ) => {
        toastr.success('Board added successfully');
        return response;
      },
    },

    postApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoards: {
      invalidatesTags: ['ComparisonDashboardSettingsTag', DASHBOARD_TAG],
      transformResponse: (
        response: PostApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsApiResponse,
      ) => {
        toastr.success('Board added successfully');
        return response;
      },
    },

    postApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoards: {
      invalidatesTags: [EAGLE_EYE_DASHBOARD_SETTING_TAG, DASHBOARD_TAG],
      transformResponse: (
        response: PostApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsApiResponse,
      ) => {
        toastr.success('Board added successfully');
        return response;
      },
    },
    // Update Board
    putApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardId: {
      invalidatesTags: [OBJECT_DASHBOARD_SETTING_TAG, DASHBOARD_TAG],
      transformResponse: (
        response: PutApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdApiResponse,
      ) => {
        toastr.success('Board updated successfully');
        return response;
      },
    },
    putApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardId:
      {
        invalidatesTags: [EAGLE_EYE_DASHBOARD_SETTING_TAG, DASHBOARD_TAG],
        transformResponse: (
          response: PutApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdApiResponse,
        ) => {
          toastr.success('Board updated successfully');
          return response;
        },
      },
    putApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardId:
      {
        invalidatesTags: ['ComparisonDashboardSettingsTag', DASHBOARD_TAG],
        transformResponse: (
          response: PutApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdApiResponse,
        ) => {
          toastr.success('Board updated successfully');
          return response;
        },
      },

    // Widget Copy

    postApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdCopyableWidgetSectionsCopyToBoard:
      {
        invalidatesTags: [OBJECT_DASHBOARD_SETTING_TAG, DASHBOARD_TAG],
        transformResponse: (response) => {
          toastr.success('Widget copied successfully');
          return response;
        },
      },

    postApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdCopyableWidgetSectionsCopyToBoard:
      {
        invalidatesTags: [EAGLE_EYE_DASHBOARD_SETTING_TAG, DASHBOARD_TAG],
        transformResponse: (response) => {
          toastr.success('Widget copied successfully');
          return response;
        },
      },

    postApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdCopyableWidgetSectionsCopyToBoard:
      {
        invalidatesTags: ['ComparisonDashboardSettingsTag'],
        transformResponse: (response) => {
          toastr.success('Widget copied successfully');
          return response;
        },
      },

    // GET report widget section by id

    getApiReportObjectDashboardsByObjectDashboardIdWidgetSectionsAndId: {
      providesTags: (result, _, arg) =>
        result
          ? [
              {
                type: DASHBOARD_WIDGET_TAG,
                id: arg.id,
              },
              DASHBOARD_WIDGET_TAG,
            ]
          : [DASHBOARD_WIDGET_TAG],
    },
    getApiReportEagleEyeDashboardsByEagleEyeDashboardIdWidgetSectionsAndId: {
      providesTags: (result, _, arg) =>
        result
          ? [
              {
                type: DASHBOARD_WIDGET_TAG,
                id: arg.id,
              },
              DASHBOARD_WIDGET_TAG,
            ]
          : [DASHBOARD_WIDGET_TAG],
    },

    getApiReportComparisonDashboardsByComparisonDashboardIdWidgetSectionsAndId:
      {
        providesTags: (result, _, arg) =>
          result
            ? [
                {
                  type: DASHBOARD_WIDGET_TAG,
                  id: arg.id,
                },
                DASHBOARD_WIDGET_TAG,
              ]
            : [DASHBOARD_WIDGET_TAG],
      },

    // Get Settings Widget Section by id

    getApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdWidgetSectionsId:
      {
        providesTags: (result, _, arg) =>
          result
            ? [
                {
                  type: DASHBOARD_WIDGET_TAG,
                  id: arg.id,
                },
                DASHBOARD_WIDGET_TAG,
              ]
            : [DASHBOARD_WIDGET_TAG],
      },

    getApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdWidgetSectionsId:
      {
        providesTags: [WIDGET_TAG],
      },

    getApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdWidgetSectionsId:
      {
        providesTags: (result, _, arg) =>
          result
            ? [
                {
                  type: DASHBOARD_WIDGET_TAG,
                  id: arg.id,
                },
                DASHBOARD_WIDGET_TAG,
              ]
            : [DASHBOARD_WIDGET_TAG],
      },
  },
});

listenerMiddleware.startListening({
  // eslint-disable-next-line max-len
  matcher: isAnyOf(
    settingsReportFormulasApiEnhanced.endpoints.putApiSettingsReportFormulasById
      .matchFulfilled,
  ),
  effect: (_, listener) => {
    listener.dispatch(
      enhancedApi.util.invalidateTags([
        WIDGET_TAG,
        OBJECT_DASHBOARD_SETTING_TAG,
      ]),
    );
  },
});

export { enhancedApi as dashboardSettingsApiEnhanced };

export const {
  useGetApiReportScoreboardsByIdQuery,

  useGetApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdCopyableWidgetSectionsQuery,
  usePostApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdCopyableWidgetSectionsCopyToBoardMutation,
  usePostApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdCopyableWidgetSectionsCopyToBoardMutation,
  useGetApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdCopyableWidgetSectionsQuery,

  useGetApiReportObjectDashboardsByIdQuery,
  useGetApiReportObjectDashboardsByObjectDashboardIdWidgetSectionsAndIdQuery,
  useGetApiReportEagleEyeDashboardsByEagleEyeDashboardIdWidgetSectionsAndIdQuery,
  useGetApiSettingsReportDashboardsQuery,
  useGetApiSettingsReportObjectDashboardsByIdQuery,
  usePutApiSettingsReportObjectDashboardsByIdMutation,

  useGetApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdWidgetSectionsIdQuery,
  useGetApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdWidgetSectionsIdQuery,
  usePostApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdWidgetSectionsPreviewQuery,
  usePostApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdWidgetSectionsMutation,
  usePostApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdWidgetSectionsMutation,
  usePostApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdWidgetSectionsPreviewQuery,
  usePutApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdWidgetSectionsIdMoveToBoardMutation,
  usePutApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdWidgetSectionsIdMoveToBoardMutation,
  usePutApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdWidgetSectionsMoveMutation,
  usePutApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdWidgetSectionsMoveMutation,
  usePutApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdWidgetSectionsIdMutation,
  usePutApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdWidgetSectionsIdMutation,
  useDeleteApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdWidgetSectionsIdMutation,
  useDeleteApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdWidgetSectionsIdMutation,
  usePostApiSettingsReportEagleEyeDashboardsMutation,
  useGetApiSettingsReportEagleEyeDashboardsByIdQuery,
  usePutApiSettingsReportEagleEyeDashboardsByIdMutation,
  useDeleteApiSettingsReportEagleEyeDashboardsByIdMutation,
  useDeleteApiSettingsReportObjectDashboardsByIdMutation,
  usePostApiSettingsReportEagleEyeDashboardsDuplicateMutation,
  usePostApiSettingsReportObjectDashboardsDuplicateMutation,
  useLazyGetApiSettingsReportEagleEyeDashboardsByIdSubjectablesQuery,
  useLazyGetApiSettingsReportObjectDashboardsByIdSubjectablesQuery,
  useGetApiSettingsReportObjectDashboardsMetaQuery,
  useGetApiSettingsReportEagleEyeDashboardsMetaQuery,
  usePostApiSettingsReportObjectDashboardsMutation,
  usePostApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsMutation,
  usePostApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsMutation,
  usePutApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdMutation,
  usePutApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdMutation,
  useDeleteApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdMutation,
  useDeleteApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdMutation,

  // Comparison Dashboards

  // Get

  useGetApiReportComparisonDashboardsByComparisonDashboardIdWidgetSectionsAndIdQuery,
  useGetApiReportComparisonDashboardsByIdQuery,

  // Settings
  useGetApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdCopyableWidgetSectionsQuery,
  useGetApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdWidgetSectionsIdQuery,

  useGetApiSettingsReportComparisonDashboardsByIdQuery,
  useGetApiSettingsReportComparisonDashboardsByIdSubjectablesQuery,
  useGetApiSettingsReportComparisonDashboardsMetaQuery,

  // Lazy Get
  useLazyGetApiSettingsReportComparisonDashboardsByIdSubjectablesQuery,

  // Delete
  useDeleteApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdMutation,
  useDeleteApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdWidgetSectionsIdMutation,
  useDeleteApiSettingsReportComparisonDashboardsByIdMutation,

  // Put
  usePutApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdMutation,
  usePutApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdWidgetSectionsIdMoveToBoardMutation,
  usePutApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdWidgetSectionsIdMutation,
  usePutApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdWidgetSectionsMoveMutation,
  usePutApiSettingsReportComparisonDashboardsByIdMutation,

  // Post
  usePostApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdCopyableWidgetSectionsCopyToBoardMutation,
  usePostApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdWidgetSectionsMutation,
  usePostApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdWidgetSectionsPreviewQuery,
  usePostApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsMutation,
  usePostApiSettingsReportComparisonDashboardsDuplicateMutation,
  usePostApiSettingsReportComparisonDashboardsMutation,
} = enhancedApi;
