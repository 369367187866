import React from 'react';
import { Button } from 'stories/Button/Button';
import { Popover } from 'stories/Popover/Popover';

interface Props {
  label: string;
  buttonProps?: Record<string, unknown>;
}

function MonthRangePickerDropdown({
  children,
  label,
  buttonProps,
}: React.PropsWithChildren<Props>) {
  return (
    <Popover
      template={children}
      maxWidth="auto"
      placement="bottom-end"
      offset={[0, 2]}
      hiddenArrow
      trigger="click"
      style={{ padding: '0px' }}
    >
      <Button
        variant="secondary"
        iconPosition="right"
        iconName="arrowBottom"
        {...buttonProps}
      >
        {label}
      </Button>
    </Popover>
  );
}

export default MonthRangePickerDropdown;
