import { IColumn, IRowBase } from 'bundles/Shared/components/Table/types';
import QuickFilterColorDropdown, {
  TQuickFilterColorDropdownProps,
} from 'bundles/Shared/components/Table/filters/QuickFilterColorDropdown';
import {
  TRANSACTION_TYPE_COLORS,
  TRANSACTION_TYPE_LABELS,
} from '@/entities/accounting/transaction/config';
import { AccountingTransactionType } from '@/entities/accounting/transaction/model';
import { CssVar } from '@/shared/config/cssVar';
import { CapitalEntry } from 'bundles/REturn/actions/types';

export const TRANSACTION_TYPE_FILTER: Pick<
  IColumn<IRowBase>,
  'filterComponent' | 'filterComponentParams'
> = {
  filterComponent: QuickFilterColorDropdown,
  filterComponentParams: {
    options: Object.keys(TRANSACTION_TYPE_LABELS).map(
      (k: AccountingTransactionType | 'all') => ({
        key: k,
        color: k === 'all' ? '' : TRANSACTION_TYPE_COLORS[k],
        label: TRANSACTION_TYPE_LABELS[k],
      }),
    ),
  } as TQuickFilterColorDropdownProps,
};

export const createExactMatchFilter = (
  entryAmount: CapitalEntry['amount'],
): Pick<IColumn<IRowBase>, 'filterComponent' | 'filterComponentParams'> => ({
  filterComponent: QuickFilterColorDropdown,
  filterComponentParams: {
    options: [
      {
        key: entryAmount,
        color: CssVar.greenDark2,
        label: 'Exact match',
      },
    ],
  } as TQuickFilterColorDropdownProps,
});
