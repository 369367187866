import React from 'react';
import { useNavigation } from '@/shared/lib/hooks/useNavigation';
import { Popover } from 'stories/Popover/Popover';
import { PseudoLink } from 'stories/PseudoLink/PseudoLink';
import { Link } from '@reach/router';

interface Props {
  items: { id: number; displayNumber: string }[];
  legalEntityCode: string;
}

function AwaitingApprovalPopover({
  items,
  children,
  legalEntityCode,
}: React.PropsWithChildren<Props>) {
  const navigation = useNavigation();
  return (
    <Popover
      appendTo={document.body}
      className="overflow-auto p-2"
      template={
        <div className="flex max-h-[15rem] flex-col">
          {items.map((item, i, arr) => (
            <>
              <div className="secondary-regular flex flex-col items-start gap-2 p-2">
                <Link
                  to={navigation.getUrl(
                    'RECONCILE_DEVELOPMENT_LEGAL_ENTITY_CHANGE_EVENT',
                    {
                      id: item.id.toString(),
                      legalEntityCode,
                    },
                  )}
                >
                  <PseudoLink className="text-dark-60">
                    COR {item.displayNumber}
                  </PseudoLink>
                </Link>
              </div>
              {i + 1 < arr.length && (
                <div className="pr-2">
                  <div className="h-px mr-2 w-full bg-light-10" />
                </div>
              )}
            </>
          ))}
        </div>
      }
    >
      {children}
    </Popover>
  );
}

export default AwaitingApprovalPopover;
