import React from 'react';
import { Input } from 'stories/FormControls/Inputs/Input/Input';
import { cn } from '@/shared/lib/css/cn';
import { IconsId } from 'types/sre-icons';

export interface ISharedInput<TValue>
  extends React.InputHTMLAttributes<HTMLInputElement> {
  value: TValue;
  error?: string;
  placeholder?: string;
  disabled?: boolean;
  leftIcon?: IconsId;
  className?: string;
  classNameWrapper?: string;
  classNameInput?: string;
  classNameError?: string;
  size?: 's' | 'm' | 'l';
  newGen?: boolean;
}

const NEW_STYLE =
  'bg-white border-2 border-neutral-200 shadow-z-020 disabled:bg-neutral-100';

export const SharedInput = <TValue,>({
  onChange,
  value,
  error,
  disabled,
  placeholder,
  leftIcon,
  className,
  classNameWrapper,
  classNameInput,
  classNameError,
  size = 's',
  newGen,
  ...props
}: ISharedInput<TValue>) => (
  <div className={cn('flex w-full flex-col gap-2', classNameWrapper)}>
    <Input
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      disabled={disabled}
      leftIcon={leftIcon}
      className={className}
      size={size}
      classes={{
        input: newGen ? [NEW_STYLE, classNameInput].join(' ') : classNameInput,
      }}
      {...props}
    />
    {error && (
      <div className={cn('text-danger-055', classNameError)}>{error}</div>
    )}
  </div>
);
