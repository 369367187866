import { useContext, type Context } from 'react';

export const useAssertNullableContext =
  <T>(context: Context<T>, errorMessage?: string) =>
  (): NonNullable<T> => {
    const res = useContext(context);

    if (res == null) {
      throw new Error(
        errorMessage ??
          `${
            context.displayName ?? 'useContext'
          } was used outside of its Provider`,
      );
    }

    return res;
  };
