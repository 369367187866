import { View, StyleSheet, Text } from '@react-pdf/renderer';
import React, { FC, ReactNode } from 'react';
import { SmallCell } from 'bundles/Shared/components/PDFLibrary/ui/table/SmallCell';
import {
  DEFAULT_VAR_PDF,
  uiPDFStyles,
} from 'bundles/Shared/components/PDFLibrary/ui/uiPDFStyles';
import { formatAmount } from '@/shared/lib/formatting/number';
import { IDrawPreviewTransferOfFunds } from '@/bundles/DrawPackage/DrawPage/components/DrawPageSummary';
import { formatDate } from '@/shared/lib/formatting/dates';
import { generateRequisitionDates } from 'bundles/DrawPackage/utils';

interface IBodyTableTFProps {
  data: {
    transferOfFunds: IDrawPreviewTransferOfFunds;
  };
}

const BodyTableTF: FC<IBodyTableTFProps> = ({ data }) => {
  const styles = StyleSheet.create({
    body: {
      flexDirection: 'row',
      width: '100%',
      flexWrap: 'wrap',
    },
    valueCol: {
      flex: 1,
      textAlign: 'right',
      alignItems: 'flex-end',
      borderLeftWidth: 0.5,
      borderBottomWidth: 0.5,
      borderColor: DEFAULT_VAR_PDF.borderDarkColor,
      borderStyle: 'solid',
      paddingHorizontal: 2,
      paddingTop: 2,
    },
    valueColCenter: {
      flex: 1,
      alignItems: 'center',
      borderLeftWidth: 0.5,
      borderBottomWidth: 0.5,
      borderColor: DEFAULT_VAR_PDF.borderDarkColor,
      borderStyle: 'solid',
      paddingHorizontal: 2,
      paddingTop: 2,
    },
    mediumCenterCol: {
      width: '12%',
      alignItems: 'center',
      borderLeftWidth: 0.5,
      borderBottomWidth: 0.5,
      borderColor: DEFAULT_VAR_PDF.borderDarkColor,
      borderStyle: 'solid',
      paddingHorizontal: 2,
      paddingTop: 2,
    },
    bigValueCol: {
      width: '20%',
      borderLeftWidth: 0.5,
      borderBottomWidth: 0.5,
      borderColor: DEFAULT_VAR_PDF.borderDarkColor,
      borderStyle: 'solid',
      padding: 2,
    },
    totalTitle: {
      fontSize: 9,
      textTransform: 'uppercase',
      fontFamily: 'Helvetica-Bold',
      padding: 2,
      color: '#fff',
    },
    totalText: {
      color: '#fff',
      fontSize: 8,
      fontFamily: 'Helvetica-Bold',
      padding: 2,
    },
  });

  if (!data) return <View />;

  return (
    <View style={styles.body}>
      <View
        style={{
          width: '100%',
        }}
      >
        {data.transferOfFunds?.reallocations?.map((transferItem, idx) => (
          <View
            key={`req${idx}`}
            style={{
              width: '100%',
              flexDirection: 'row',
              borderRightWidth: 0.5,
              borderColor: DEFAULT_VAR_PDF.borderDarkColor,
              borderStyle: 'solid',
            }}
          >
            <SmallCell
              styles={{
                wrapper: styles.valueColCenter,
              }}
            >
              {transferItem.number}
            </SmallCell>
            <SmallCell
              styles={{
                wrapper: styles.valueColCenter,
              }}
            >
              {transferItem.status}
            </SmallCell>
            <SmallCell
              styles={{
                wrapper: styles.mediumCenterCol,
              }}
            >
              {generateRequisitionDates(
                transferItem.requisitionBeginningDate,
                transferItem.requisitionEndingDate,
              )}
            </SmallCell>
            <SmallCell
              styles={{
                wrapper: styles.bigValueCol,
              }}
            >
              {transferItem.description}
            </SmallCell>
            <SmallCell
              styles={{
                wrapper: styles.valueColCenter,
              }}
            >
              {formatDate(transferItem.approvedDate, 'MMM DD, YYYY')}
            </SmallCell>
            <SmallCell
              styles={{
                wrapper: styles.valueCol,
              }}
            >
              {formatAmount(transferItem.value)}
            </SmallCell>
            <SmallCell
              styles={{
                wrapper: styles.bigValueCol,
              }}
            >
              {transferItem.movedFrom}
            </SmallCell>
            <SmallCell
              styles={{
                wrapper: styles.bigValueCol,
              }}
            >
              {transferItem.movedTo}
            </SmallCell>
          </View>
        ))}
      </View>
      <View
        style={{
          width: '100%',
          flexDirection: 'row',
          backgroundColor: DEFAULT_VAR_PDF.backgroundTotal,
          borderLeftWidth: 1,
          borderBottomWidth: 1,
          borderRightWidth: 1,
          borderColor: DEFAULT_VAR_PDF.borderDarkColor,
          borderStyle: 'solid',
        }}
      >
        <View
          style={{
            width: '53%',
            paddingHorizontal: 2,
            paddingTop: 2,
          }}
        >
          <Text style={styles.totalTitle}>Total</Text>
        </View>
        <View
          style={{
            paddingHorizontal: 2,
            paddingTop: 2,
            borderLeftWidth: 0.5,
            borderRightWidth: 0.5,
            borderColor: DEFAULT_VAR_PDF.borderColor,
            alignItems: 'flex-end',
            width: '7%',
          }}
        >
          <Text style={styles.totalText}>
            {formatAmount(data?.transferOfFunds?.total)}
          </Text>
        </View>
      </View>
    </View>
  );
};

export default BodyTableTF;
