import {
  AllCellClassNames,
  CellClassNames,
  CellTypeFlags,
} from '@/bundles/Shared/components/AgGrid/Table/classNames';

export function getColorClassName<CN extends AllCellClassNames>(
  classNames: CellClassNames<CN>,
  nodeFlags: CellTypeFlags,
): string {
  const { isFooter, isFormula, isEditable } = nodeFlags;

  switch (true) {
    case isFormula: {
      switch (true) {
        case nodeFlags.isBlueFormula:
          return classNames.formula.blue;
        case nodeFlags.isGreyFormula:
          return classNames.formula.grey;
        case nodeFlags.isDarkGreyFormula:
          return classNames.formula['dark-grey'];
        case nodeFlags.isDarkFormula:
          return classNames.formula.dark;
        case nodeFlags.isGreenFormula:
          return classNames.formula.green;
        default:
          return classNames.basic;
      }
    }

    case isFooter:
      return classNames.footer;

    case isEditable:
      return classNames.editable;

    default:
      return classNames.basic;
  }
}
