import {
  transformPeriodShiftDtoToForm,
  transformPeriodTypeDtoToForm,
} from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/config';
import { transformEmptyObjectToUndefined } from '@/shared/lib/yup';
import {
  PERIOD_SHIFT_SCHEMA,
  PERIOD_TYPE_SCHEMA,
} from 'bundles/Shared/widgets/dashboard/widgets/common/config';
import { NUMERIC_COLUMN_SETTINGS_FORM_SCHEMA } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/form';
import { TableVizConfigColumnGroup } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/model';
import { KpiTableSingleDateWidgetConfig } from 'bundles/Shared/widgets/dashboard/widgets/kpiTableSingleDate';
import { findFirstColumnSettingsInGroup } from 'bundles/Shared/widgets/dashboard/widgets/kpiTableSingleDate/config/updaters';
import * as yup from 'yup';

export const KPI_TABLE_BULK_GROUP_SCHEMA =
  NUMERIC_COLUMN_SETTINGS_FORM_SCHEMA.shape({
    can_override: yup.boolean().optional(),
    period_type: PERIOD_TYPE_SCHEMA.required(),
    period_shift: PERIOD_SHIFT_SCHEMA.nullable()
      .optional()
      .transform(transformEmptyObjectToUndefined)
      .default(undefined),
  });

export type KpiTableBulkGroupForm = yup.InferType<
  typeof KPI_TABLE_BULK_GROUP_SCHEMA
>;

export const transformConfigToGroupForm = ({
  group,
  widgetConfig,
}: {
  group?: TableVizConfigColumnGroup;
  widgetConfig: KpiTableSingleDateWidgetConfig;
}): KpiTableBulkGroupForm => {
  const { firstColumn, firstColumnSettingsInGroup } =
    findFirstColumnSettingsInGroup({
      columnsConfig: widgetConfig.columns,
      groupId: group?.group_id ?? '',
      vizConfig: widgetConfig.viz_config,
    });
  const cantOverridePeriod = group?.child_can_override_period === false;

  return {
    can_override: cantOverridePeriod,
    period_shift: cantOverridePeriod
      ? transformPeriodShiftDtoToForm(firstColumn?.period_shift)
      : undefined,
    period_type: cantOverridePeriod
      ? transformPeriodTypeDtoToForm(firstColumn?.period_type)
      : undefined,
    header: firstColumnSettingsInGroup?.header,
  };
};
