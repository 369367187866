import React, { FC, useState } from 'react';
import { getSliceNumber } from 'bundles/Shared/components/Table/formatters/drawPackage';
import { cn } from '@/shared/lib/css/cn';

interface INumberBoardCardProps {
  number: string;
}

const Corner = ({
  placement,
  bgColor,
}: {
  placement: 'left-top' | 'right-bottom';
  bgColor: string;
}) => (
  <div
    style={{
      // todo replace with tailwind (tailwind scale flip doesn't work in Firefox)
      transform: placement === 'left-top' ? 'scale(-1,1)' : 'scale(-1,1)',
    }}
    className={cn(`${bgColor} absolute h-[10px] w-[10px]`, {
      '-right-0 top-[100%]': placement === 'right-bottom',
      '-left-[10px] top-0': placement === 'left-top',
    })}
  >
    <div className="w-full h-full rounded-tl-2xl bg-white" />
  </div>
);

const NumberBoardCard: FC<INumberBoardCardProps> = ({ number }) => {
  const MAX_LENGTH_NUMBER = 6;
  const bigLength = number.length > MAX_LENGTH_NUMBER;

  const [showBigNumber, setShowBigNumber] = useState(false);
  return (
    <div
      className={cn(
        'light-90 inline-semibold absolute right-0 top-0 rounded-bl-2xl rounded-tr-2xl bg-light-20 px-3 py-0.5',
        {
          'bg-dark-60 text-white': showBigNumber,
        },
      )}
      onMouseEnter={() => bigLength && setShowBigNumber(true)}
      onMouseLeave={() => bigLength && setShowBigNumber(false)}
    >
      {showBigNumber && number}
      {!showBigNumber && getSliceNumber(number, MAX_LENGTH_NUMBER / 2)}
      <Corner
        placement="left-top"
        bgColor={showBigNumber ? 'bg-dark-60' : 'bg-light-20'}
      />
      <Corner
        placement="right-bottom"
        bgColor={showBigNumber ? 'bg-dark-60' : 'bg-light-20'}
      />
    </div>
  );
};

export default NumberBoardCard;
