import {
  currentUserAllowedTo,
  currentUserIsSreAdmin,
  currentUserIsSuperAdmin,
} from '@/lib/permissions';

export const currentUserAllowedToSeeReportingPage = () => {
  return (
    currentUserIsSreAdmin() || currentUserAllowedTo('view', 'report_builder')
  );
};
export const currentUserAllowedToManageReportingPage = () => {
  return (
    currentUserIsSreAdmin() ||
    currentUserIsSuperAdmin() ||
    currentUserAllowedTo('manage', 'report_builder')
  );
};

export const currentUserAllowedToConfigureReportingPage = () => {
  return (
    currentUserIsSreAdmin() ||
    currentUserIsSuperAdmin() ||
    currentUserAllowedTo('configure', 'report_builder')
  );
};
