import { useAssertNullableContext } from '@/shared/lib/hooks/context/useAssertNullableContext';
import { createContext } from 'react';

export type ReportingEntityIdType = string;

export const ReportingEntityIdContext =
  createContext<ReportingEntityIdType | null>(null);

export const useReportingEntityIdContext = useAssertNullableContext(
  ReportingEntityIdContext,
);
