import { ValueTypeInputController } from '@/entities/valueType/ui/ValueTypeInput';
import {
  useAddLegalEntityOverrideForThreshold,
  useLegalEntitiesForThreshold,
  useUpdateLegalEntityOverrideForThreshold,
} from '@/features/report/threshold/legalEntityOverride/api';
import {
  ReportThreshold,
  ReportThresholdLegalEntityOverride,
} from '@/entities/report/threshold/model';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import { Field } from '@/stories/Field/Field';
import { Modal } from '@/stories/Modals/Modal/Modal';
import { ModalActions } from '@/stories/Modals/Modal/ModalActions';
import { Button } from '@/stories/Button/Button';
import { FieldsContainer } from '@/stories/Field/Field';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { SharedSelectController } from '@/bundles/Shared/components/GroupForm/FormItems/new/SharedSelect';
import * as yup from 'yup';
import { VariableValueTypePossibleValues } from '@/entities/valueType/model';
import { getDefaultValueForValueType } from '@/entities/valueType/lib';

const ADD_LEGAL_ENTITY_OVERRIDE_FOR_THRESHOLD_SCHEMA = yup.object().shape({
  legalEntityId: yup.string().required(),
  value: yup.mixed().required(),
});
type AddLegalEntityOverrideForThresholdForm = yup.InferType<
  typeof ADD_LEGAL_ENTITY_OVERRIDE_FOR_THRESHOLD_SCHEMA
>;

export const AddOrUpdateLegalEntityOverrideForThresholdModal = (
  props: DialogProps & {
    threshold: ReportThreshold;
    legalEntityOverride?: ReportThresholdLegalEntityOverride;
  },
) => {
  const { data: legalEntities } = useLegalEntitiesForThreshold();
  const { threshold, onClose } = props;
  const methods = useForm<AddLegalEntityOverrideForThresholdForm>({
    resolver: yupResolver(ADD_LEGAL_ENTITY_OVERRIDE_FOR_THRESHOLD_SCHEMA),
    defaultValues: {
      legalEntityId: props.legalEntityOverride?.legalEntityId,
      value:
        props.legalEntityOverride?.value ??
        getDefaultValueForValueType(threshold.valueType),
    },
    mode: 'all',
  });
  const {
    control,
    formState: { isValid, isSubmitting, isDirty },
  } = methods;

  const [addLegalEntityOverride] =
    useAddLegalEntityOverrideForThreshold(threshold);
  const [updateLegalEntityOverride] =
    useUpdateLegalEntityOverrideForThreshold(threshold);

  const handleSubmit = methods.handleSubmit((data) => {
    if (props.legalEntityOverride) {
      updateLegalEntityOverride(
        props.legalEntityOverride.id,
        data.value as VariableValueTypePossibleValues,
      );
    } else {
      addLegalEntityOverride({
        legalEntityId: data.legalEntityId,
        value: data.value as VariableValueTypePossibleValues,
      });
    }
    onClose();
  });

  return (
    <Modal
      toggle={onClose}
      header="Add Threshold Override"
      actions={
        <ModalActions alignItems="space-between">
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="success"
            onClick={handleSubmit}
            disabled={
              !isValid ||
              isSubmitting ||
              (!isDirty && threshold?.globalValue != null)
            }
          >
            Submit
          </Button>
        </ModalActions>
      }
    >
      <FormProvider {...methods}>
        <FieldsContainer>
          <Field labelText="Legal Entity" required>
            <SharedSelectController
              control={control}
              name="legalEntityId"
              options={legalEntities?.items.map((le) => ({
                label: le.name,
                value: le.id,
              }))}
            />
          </Field>
          <Field labelText="Value" required>
            <ValueTypeInputController
              control={control}
              name="value"
              valueType={threshold.valueType}
            />
          </Field>
        </FieldsContainer>
      </FormProvider>
    </Modal>
  );
};
