import { LegalEntity } from '@/entities/core/legalEntity';
import { cn } from '@/shared/lib/css/cn';
import { mapItemsToListOption } from '@/shared/lib/listHelpers';
import CheckList from '@/stories/Checkbox/CheckList';
import FilterBar from 'bundles/Shared/components/Filters/common/filterBar/FilterBar';
import FilterItem from 'bundles/Shared/components/Filters/common/filterItem/FilterItem';
import { capitalize } from 'lodash-es';
import pluralize from 'pluralize';
import React, { ComponentProps } from 'react';

interface Props<T extends Pick<LegalEntity, 'id' | 'name'>>
  extends PropsWithClassName {
  selectedLegalEntities: T[];
  legalEntities: T[];
  onSelectedChange?: (newLegalEntities: T[]) => void;
  selectionMode?: 'exclude' | 'include';
}

// merge with src/bundles/Shared/components/Filters/GeneralLedgersFilterBar.tsx
function LegalEntitiesFilter<T extends Pick<LegalEntity, 'id' | 'name'>>({
  legalEntities,
  selectedLegalEntities,
  onSelectedChange,
  selectionMode = 'exclude',
  className,
}: Props<T>) {
  const getFilterItemProps = (
    le: T,
  ): Pick<React.ComponentProps<typeof FilterItem>, 'hidden' | 'onHide'> => {
    const isSelected =
      selectedLegalEntities.find((sle) => le.id === sle.id) != null;
    const onHide =
      onSelectedChange &&
      (() =>
        onSelectedChange(
          isSelected
            ? selectedLegalEntities.filter((sge) => sge.id !== le.id)
            : [...selectedLegalEntities, le],
        ));

    return {
      onHide,
      hidden: selectionMode === 'include' ? isSelected : !isSelected,
    };
  };

  return (
    <FilterBar
      className={cn('d-inline-flex', className)}
      title="Legal Entities"
    >
      {legalEntities.map((le) => (
        <FilterItem key={le.id} label={le.name} {...getFilterItemProps(le)} />
      ))}
    </FilterBar>
  );
}

export const ObjectLegalEntityCheckboxList = <
  L extends Pick<LegalEntity, 'id' | 'name'>,
>({
  onChange,
  legalEntities,
  selectedLegalEntities,
  objectName,
  title = undefined,
}: {
  legalEntities: L[];
  selectedLegalEntities: L[];
  objectName: string;
  title?: string;
} & Pick<ComponentProps<typeof CheckList>, 'onChange'>) => {
  return (
    <div className="p-2">
      <span className="label-regular mb-xs block text-light-60">
        {title ?? `${capitalize(pluralize(objectName))}'s Entities`}
      </span>
      <CheckList<L['id']>
        atLeastOneChecked
        value={mapItemsToListOption(selectedLegalEntities, 'name')}
        onChange={onChange}
        items={mapItemsToListOption(legalEntities, 'name')}
      />
    </div>
  );
};

export default LegalEntitiesFilter;
