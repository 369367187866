import React from 'react';
import { IInvestmentEntity } from 'types/IInvestmentEntity';
import GroupedCheckboxes from 'bundles/Shared/components/groupedSelection/GroupedCheckboxes';

interface Props {
  investmentEntities: IInvestmentEntity[];
  selectedInvestmentEntities: IInvestmentEntity[];
  onSelectedChanged: (newInvestmentEntities: IInvestmentEntity[]) => void;
  disabledItemIds: number[];
}

function SelectInvestmentEntityStep({
  investmentEntities,
  selectedInvestmentEntities,
  onSelectedChanged,
  disabledItemIds,
}: Props) {
  return (
    <>
      {investmentEntities?.length === disabledItemIds.length && (
        <p className="text-center">No investment entities found</p>
      )}
      {investmentEntities &&
        investmentEntities.length > disabledItemIds.length && (
          <div className="flex flex-col gap-4">
            <div className="inline-semibold text-neutral-850">
              Select the Investment Entity
              <span className="text-danger-055"> *</span>
            </div>
            <GroupedCheckboxes
              items={investmentEntities}
              getLabelFromItem={(item) => item.name}
              selectedItems={selectedInvestmentEntities}
              setSelectedItems={onSelectedChanged}
              selectAll
              withSearch
              searchEntityLabel="Investment Entity"
              disabledItemIds={disabledItemIds}
            />
          </div>
        )}
    </>
  );
}

export default SelectInvestmentEntityStep;
