import React, { useRef } from 'react';
import SettingsCard from 'bundles/Shared/components/LeftSidebar/cards/SettingsCard';
import SettingsModal from 'bundles/Shared/components/Nav/Modals/SettingsModal';
import MainMenuItem from 'bundles/Shared/components/LeftSidebar/items/MainMenuItem';
import { useModal } from '@/shared/lib/hooks/useModal';
import { usePopoverTriggered } from 'bundles/Shared/components/LeftSidebar/hooks/usePopoverTriggered';
import { useHoverSaveArea } from 'bundles/Shared/components/LeftSidebar/hooks/useHoverSaveArea';
import MenuItemPopover from 'bundles/Shared/components/LeftSidebar/items/MenuItemPopover';
import { cn } from '@/shared/lib/css/cn';
import { Icon } from 'stories/Icon/Icon';
import { DEFAULT_DROPDOWN_OFFSET, PopoverRef } from 'stories/Popover/Popover';
import useMediaQuery, {
  MEDIUM_WIDTH_MEDIA_QUERY,
} from '@/shared/lib/hooks/useMediaQuery';
import { useAppDispatch } from '@/shared/lib/hooks/redux';
import { collapseSidebar } from 'bundles/Shared/components/LeftSidebar/reducers/leftSidebarSlice';

function SettingsMenuItem() {
  const dispatch = useAppDispatch();
  const isMediumWidth = useMediaQuery(MEDIUM_WIDTH_MEDIA_QUERY);
  const { openModal } = useModal();
  const menuItemRef = useRef<HTMLAnchorElement>(null);
  const popoverRef = useRef<PopoverRef>();
  const { triggered, ...handlers } = usePopoverTriggered();
  const { classes: hoverClasses, onMouseMove } = useHoverSaveArea();
  return (
    <>
      <MainMenuItem
        ref={menuItemRef}
        label="Settings"
        iconName="configurations"
        path="/settings"
        rightIcon={<Icon iconName="arrowRight" />}
        onClick={() => openModal(SettingsModal, {})}
        className={cn(
          hoverClasses,
          triggered && 'left-sidebar__main-menu-item_hovered',
        )}
        onMouseMove={onMouseMove}
      />
      {isMediumWidth && (
        <MenuItemPopover
          {...handlers}
          className="shadow-m-light w-[21.25rem] overflow-hidden !rounded-[1rem] border border-light-30 bg-light p-0"
          ref={popoverRef}
          reference={menuItemRef}
          placement="right-end"
          offset={DEFAULT_DROPDOWN_OFFSET}
          template={
            <SettingsCard
              onAction={() => {
                popoverRef.current?.hide();
                dispatch(collapseSidebar());
              }}
              withHeader
            />
          }
          maxWidth="none"
        />
      )}
    </>
  );
}

export default SettingsMenuItem;
