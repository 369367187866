import React, { useState, useEffect } from 'react';
import { ConfirmationModal } from 'stories/Modals/ConfirmationModal/ConfirmationModal';
import { Modal } from 'stories/Modals/Modal/Modal';
import { directUpload, presignFiles } from 'lib/uploadFiles';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import GroupForm from 'bundles/Shared/components/GroupForm/GroupForm';
import { navigate, useParams } from '@reach/router';
import {
  fetchCompanies,
  fetchContacts,
  selectCompanies,
  selectContacts,
  selectReasons,
} from 'bundles/Construction/reducers/ReconcileSlice';
import getItemsCORModal from '@/bundles/Construction/components/ChangeOrderEvent/initData';
import { IChangeOrderEvent } from 'bundles/Construction/types';
import { useGroupedFilteredItems } from '@/bundles/Construction/components/helpers';
import { createChangeEvent } from '@/bundles/Construction/reducers/ReconcileChangeEventSlice';
import { updateChangeEventsAfterCreating } from '@/bundles/Construction/actions/changeOrderEvents';

export const ChangeOrderEventWorkflow = () => {
  const developmentLegalEntity = useAppSelector(
    (state) => state.developmentBudget.legalEntity,
  );
  const companies = useAppSelector(selectCompanies);
  const contacts = useAppSelector(selectContacts);
  const reasons = useAppSelector(selectReasons);
  const JCCForReallocations = useAppSelector(
    (state) => state.JCCForReallocations,
  );

  const params = useParams();
  const dispatch = useAppDispatch();

  const [formData, setFormData] = useState<IChangeOrderEvent>({});
  const [showConfirmModal, setConfirmModal] = useState(false);
  const [disabledCloseIcon, setDisabledCloseIcon] = useState(false);

  const filteredReasons = useGroupedFilteredItems(reasons);

  useEffect(() => {
    dispatch(fetchCompanies());
    dispatch(fetchContacts());
  }, [params.legalEntityCode]);

  const initGroupFormItems = getItemsCORModal(
    formData,
    setFormData,
    companies,
    contacts,
    filteredReasons,
    JCCForReallocations,
  );

  const submit = async () => {
    let presignedFiles = [];
    if (formData.sharedFiles) {
      presignedFiles = await presignFiles(
        formData.sharedFiles.length
          ? formData.sharedFiles
          : [formData.sharedFiles],
      );
    }
    await Promise.all(presignedFiles.map((file) => directUpload(file)));
    const formDataTransform = {};
    const CORItems = [];
    Object.keys(formData).forEach((key) => {
      initGroupFormItems.forEach((item) => {
        const findItem = item.formItems.find(
          (p) => p.fieldName === key && formData[key],
        );
        if (findItem) CORItems.push(findItem);
      });
    });
    CORItems.forEach((formItem) => {
      if (formItem.fieldName !== 'changeOrderReasons') {
        formDataTransform[formItem.fieldNameSnap] =
          formData[formItem.fieldName];
      }
    });
    const changeEvent = await dispatch(
      createChangeEvent({
        legalEntityCode: params.legalEntityCode,
        data: {
          contact_name: formDataTransform.reconcile_contact?.label,
          probability_comment: formData?.probability_comment,
          ...formDataTransform,
          ...(formData?.category !== 'IPC'
            ? {
                company_name: formDataTransform.reconcile_company?.label,
              }
            : {}),
          value: formData.value,
          change_order_reason_ids: Array.isArray(formData.changeOrderReasons)
            ? formData.changeOrderReasons.map((r) => r.id)
            : [formData.changeOrderReasons],
          reallocation_jcc_id: formDataTransform.reallocation_jcc_id,
          files: presignedFiles.map((f) => ({
            key: f.signedData.fields.key,
            filename: f.file.name,
            content_type: f.file.type,
            size: f.file.size,
          })),
        },
      }),
    );
    await dispatch(updateChangeEventsAfterCreating(changeEvent));
    navigate('./');
  };

  const closeCOR = () => {
    setConfirmModal(true);
  };

  const confirmModalHandle = (status: boolean) => {
    if (status) {
      navigate('./');
    }
    setConfirmModal(false);
  };

  const disabledCloseIconFunc = () => {
    setDisabledCloseIcon(true);
  };
  return (
    <>
      <Modal
        header={
          <div>
            <div className="label-regular">
              {developmentLegalEntity?.assetName}
            </div>
            <div className="dark header6-bold">Create Change Event</div>
          </div>
        }
        size="lg"
        classes={{
          body: 'reconcile-common-modal-create',
        }}
        maxHeight
        toggle={closeCOR}
        disabledClose={disabledCloseIcon}
      >
        <GroupForm
          viewModeSwitchable={false}
          groupFormItems={initGroupFormItems}
          onSubmit={submit}
          formData={formData}
          setFormData={setFormData}
          buttonConfig={{
            titleBtn: 'Review & Confirm',
            titleView: 'Create Change Event',
            titleLoading: 'Creating Change Event',
            width: '100%',
            withoutCancel: true,
            message: 'Complete all required steps to Review & Confirm',
            funcSendForm: disabledCloseIconFunc,
          }}
          groupFormConfig={{
            reviewFlow: true,
            steps: true,
          }}
        />
      </Modal>
      {showConfirmModal && (
        <ConfirmationModal
          title="Are you sure that you want to quit?"
          subtitle="The provided data will be lost."
          actions={{
            primaryButton: {
              text: 'Yes',
              variant: 'danger',
            },
            secondaryButton: {
              text: 'No',
              variant: 'secondary',
            },
          }}
          handleResolve={confirmModalHandle}
        />
      )}
    </>
  );
};

export default ChangeOrderEventWorkflow;
