import { Distribution, DistributionKindBadge } from '@/entities/return';
import {
  IFormatterProps,
  IRowBase,
} from 'bundles/Shared/components/Table/types';

export const distributionKindFormatter = ({
  row,
}: IFormatterProps<IRowBase & Pick<Distribution, 'kind' | 'splitItems'>>) =>
  row.kind === 'split_items' ? (
    <div className="flex flex-wrap gap-0.5">
      {row.splitItems.map((item) => (
        <DistributionKindBadge key={item.kind} kind={item.kind} />
      ))}
    </div>
  ) : (
    <DistributionKindBadge kind={row.kind} />
  );
