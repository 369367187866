import React, { FC } from 'react';
import { Field } from 'stories/Field/Field';
import { SelectableButtonGroup } from 'stories/SelectableButtonGroup/SelectableButtonGroup';
import { SelectButton } from 'stories/SelectableButtonGroup/SelectableButtonGroup';
import { cn } from '@/shared/lib/css/cn';
import { IFormItem } from '@/bundles/Shared/components/GroupForm/types/types';

interface Props extends IFormItem {
  value: string;
  buttons: SelectButton[];
  handleFunc?: () => void;
}

const FormSelectedButtons: FC<Props> = ({
  title,
  formData,
  setFormData,
  fieldName,
  required,
  buttons,
  hint,
  styles,
  disabled,
  handleFunc,
}) => {
  const handleChange = (value: any): void => {
    if (handleFunc) {
      handleFunc(value);
    } else {
      setFormData({
        ...formData,
        [fieldName]: value,
      });
    }
  };
  return (
    <div
      className={cn(
        'form-item',
        styles?.nomb && 'form-item_nomb',
        styles?.wrapper,
      )}
    >
      <Field labelText={title} required={required} hint={hint}>
        <SelectableButtonGroup
          buttons={buttons}
          onSelect={handleChange}
          disabled={disabled}
          styles={styles}
        />
      </Field>
    </div>
  );
};
export default FormSelectedButtons;
