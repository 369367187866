import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import { fetchDevelopmentLegalEntity } from 'bundles/REconcile/reducers/developmentBudgetSlice';
import DevelopmentLegalEntityBreadcrumbs from 'bundles/REconcile/components/development/screen/DevelopmentLegalEntityBreadcrumbs';
import DevelopmentLegalEntityHeader from 'bundles/REconcile/components/development/screen/DevelopmentLegalEntityHeader';
import DevelopmentLegalEntityMenu from 'bundles/REconcile/components/development/screen/DevelopmentLegalEntityMenu';
import { Router } from '@reach/router';
import Reallocations from 'bundles/Construction/components/Reallocation/Reallocations';
import PayApps from 'bundles/Construction/PayApps';
import ChangeManagement from 'bundles/REconcile/components/development/screen/ChangeManagement';
import ChangeOrderLogs from 'bundles/Construction/ChangeOrderLogs';
import ChangeEventLogs from 'bundles/Construction/ChangeEventLogs';
import {
  allSubPathMatches,
  ROUTES_ROOT,
  TRouteParams,
} from '@/shared/lib/hooks/useNavigation';
import { RESET_CHANGE_ORDER_MANAGEMENT_STATE } from 'bundles/Construction/reducers/ChangeOrderManagementReducer';
import Snapshot from 'bundles/Construction/Snapshot';
import Invoices from 'bundles/DrawPackage/InvoicesFlow/Invoices';
import Requisitions from 'bundles/REconcile/components/development/screen/Requisitions';
import { currentUserAllowedTo, TProductNames } from 'lib/permissions';
import DrawRequest from 'bundles/DrawPackage/DrawPage/DrawRequest';
import Summary from 'bundles/Construction/Summary';
import { AnimationLoader } from 'stories/AnimationLoader/AnimationLoader';

type Props = TRouteParams['RECONCILE_DEVELOPMENT_LEGAL_ENTITY'];

const ChangeOrderLogsWrapper = ({ navigate }) => (
  <ChangeManagement title="Change Order Logs">
    <ChangeOrderLogs navigate={navigate} />
  </ChangeManagement>
);

const ChangeEventLogsWrapper = ({ navigate }) => (
  <ChangeManagement title="Change Event Logs">
    <ChangeEventLogs navigate={navigate} />
  </ChangeManagement>
);

const LEGAL_ENTITY_ROUTES = ROUTES_ROOT.reconcile.development.legalEntity;

function DevelopmentLegalEntity({ legalEntityCode }: Props) {
  const dispatch = useAppDispatch();
  const developmentLegalEntity = useAppSelector(
    (state) => state.developmentBudget.legalEntity,
  );
  const loadingState = useAppSelector((state) => state.developmentBudget.state);

  useEffect(() => {
    dispatch({ type: RESET_CHANGE_ORDER_MANAGEMENT_STATE });
    dispatch(fetchDevelopmentLegalEntity(legalEntityCode));
  }, [legalEntityCode]);

  if (loadingState !== 'success' || developmentLegalEntity == null) {
    return <AnimationLoader />;
  }

  return (
    <div>
      <div className="bg-white px-l pb-m pt-m">
        <DevelopmentLegalEntityBreadcrumbs
          assetName={developmentLegalEntity.assetName}
        />
        <div className="mt-s" />
        <DevelopmentLegalEntityHeader
          legalEntityName={developmentLegalEntity.name}
          assetName={developmentLegalEntity.assetName}
          smallPictureUrl={developmentLegalEntity.pictureUrl}
        />
      </div>
      <DevelopmentLegalEntityMenu legalEntityCode={legalEntityCode} />
      <Router basepath="/" primary={false}>
        {currentUserAllowedTo('view', TProductNames.CHANGE_MANAGEMENT) && (
          <Snapshot
            path={LEGAL_ENTITY_ROUTES.snapshot.fullPath}
            initialLegalEntityCode={legalEntityCode}
          />
        )}
        {currentUserAllowedTo('manage', TProductNames.CHANGE_MANAGEMENT) && (
          <Summary path={LEGAL_ENTITY_ROUTES.summary.fullPath} />
        )}
        {currentUserAllowedTo('manage', TProductNames.CHANGE_MANAGEMENT) && (
          <>
            <Reallocations
              path={allSubPathMatches(
                LEGAL_ENTITY_ROUTES.reallocations.fullPath,
              )}
            />
            <PayApps
              path={allSubPathMatches(LEGAL_ENTITY_ROUTES.payApps.fullPath)}
            />
            <ChangeOrderLogsWrapper
              path={allSubPathMatches(
                LEGAL_ENTITY_ROUTES.changeOrderLogs.fullPath,
              )}
            />
            <ChangeEventLogsWrapper
              path={allSubPathMatches(
                LEGAL_ENTITY_ROUTES.changeEventLogs.fullPath,
              )}
            />
            <Invoices
              path={allSubPathMatches(LEGAL_ENTITY_ROUTES.invoices.fullPath)}
            />
            <Requisitions
              path={allSubPathMatches(
                LEGAL_ENTITY_ROUTES.requisitions.fullPath,
              )}
            />
            <DrawRequest
              primary={false}
              path={allSubPathMatches(LEGAL_ENTITY_ROUTES.draws.fullPath)}
            />
          </>
        )}
      </Router>
    </div>
  );
}

export default DevelopmentLegalEntity;
