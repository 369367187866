import React, { useMemo, useState } from 'react';
import { TChangeOrderStatus } from 'bundles/Construction/types';
import { ChangeEventInfoSection } from 'stories/ChangeEventInfoSection/ChangeEventInfoSection';
import { ConfirmationModal } from 'stories/Modals/ConfirmationModal/ConfirmationModal';
import { formatAmount } from '@/shared/lib/formatting/number';
import FormInput from 'bundles/Shared/components/GroupForm/FormItems/FormInput';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import {
  selectChangeEvent,
  updateStatusChangeEvent,
} from 'bundles/Construction/reducers/ReconcileChangeEventSlice';
import { useParams } from '@reach/router';

interface Props {
  setShowSnapshotModal: (b: boolean) => void;
}

const STATUSES = [
  {
    key: 'PENDING-NOT PROCEEDING',
    label: 'Pending & Not Proceeding',
    color: 'yellow-dark-5',
  },
  {
    key: 'PENDING-PROCEEDING',
    label: 'Pending & Proceeding',
    color: 'yellow-dark-3',
  },
  {
    key: 'VOID',
    label: 'Void',
    color: 'light-30',
  },
  {
    key: 'DRAFT',
    label: 'Draft',
    color: 'light-60',
  },
  {
    key: 'REJECTED',
    label: 'Rejected',
    color: 'pumpkin-dark-5',
  },
  {
    key: 'DISPUTED',
    label: 'Disputed',
    color: 'pumpkin',
  },
  {
    key: 'COMPLETED',
    label: 'Completed',
    color: 'green-dark-4',
  },
  {
    key: 'APPROVED',
    label: 'Approved',
    color: 'green',
  },
];

function InfoBadge({ setShowSnapshotModal }: Props) {
  const changeEvent = useAppSelector(selectChangeEvent);

  const dispatch = useAppDispatch();

  const params = useParams();
  const [statusKey, setStatusKey] = useState<TChangeOrderStatus | null>(null);
  const [comment, setComment] = useState({
    value: '',
  });

  const infoData = useMemo(() => {
    let selected = null;
    let options = null;
    if (changeEvent) {
      selected = STATUSES.find(
        (statusItem) => statusItem.key === changeEvent?.status,
      );
      options = STATUSES.filter((statusItem) =>
        changeEvent?.availableStatuses?.find(
          (availableStatus) => statusItem.key === availableStatus,
        ),
      );
    }
    return {
      selected,
      options,
    };
  }, [changeEvent]);

  const handleItemClick = async (confirm: boolean) => {
    if (confirm) {
      await dispatch(
        updateStatusChangeEvent({
          legalEntityCode: params.legalEntityCode,
          id: changeEvent.id,
          data: {
            status: statusKey,
            event_comment: comment.value,
          },
        }),
      );
    }
    setStatusKey(null);
    setComment({
      value: '',
    });
  };

  const handleShowModal = (item: string) => {
    setStatusKey(item.key);
  };
  return (
    <div className="mb-m">
      <ChangeEventInfoSection
        title="CHANGE EVENT INFO"
        amount={formatAmount(changeEvent?.weightedAmount)}
        selected={infoData.selected ?? []}
        options={infoData.options ?? []}
        onIconClick={() => setShowSnapshotModal(true)}
        handleItemClick={handleShowModal}
      />
      {statusKey && (
        <ConfirmationModal
          title="Change Status"
          subtitle={
            <div>
              <div className="mb-s">
                Are you sure you want to change the status of this Change Event?
              </div>
              <div className="mb-xs">
                If so, please leave a comment here for future reference
              </div>
              <FormInput
                fieldName="value"
                setFormData={setComment}
                value={comment.value}
                styles={{
                  nomb: true,
                  input__item: 'comment-in-modal',
                }}
              />
            </div>
          }
          actions={{
            primaryButton: {
              text: 'Yes',
              variant: 'danger',
            },
            secondaryButton: {
              text: 'No',
              variant: 'secondary',
            },
          }}
          handleResolve={handleItemClick}
        />
      )}
    </div>
  );
}

export default InfoBadge;
