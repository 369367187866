import React, { useState } from 'react';
import { CurrencyFormatter } from 'stories/ValueFormatters/CurrencyFormatter';
import { IconButton } from 'stories/IconButton/IconButton';
import { InlineObject } from 'stories/Objects/InlineObject/InlineObject';
import { animated } from 'react-spring';
import { useFade } from '@/shared/lib/hooks/useFade';
import pluralize from 'pluralize';
import dayjs from 'dayjs';
import { ReportLineItem } from 'bundles/REturn/actions/types';

import { convertCentsToDollars } from '@/shared/lib/converters';

interface Props {
  items: ReportLineItem[];
}
// update stories too
function TransactionCollapsedList({ items }: Props) {
  const [visible, setVisible] = useState(false);
  const style = useFade(visible);

  const allTransactionValueCents = items.reduce(
    (prev, curr) => prev + Number(curr.amountCents),
    0,
  );

  return (
    <span className="relative grid grid-cols-[1fr_max-content_max-content] items-center justify-between gap-4 rounded-lg bg-light p-4">
      <span className="inline-regular text-light-90">
        {items.length} {pluralize('Transaction', items.length)}
      </span>
      <CurrencyFormatter
        classes={{
          value: 'font-semibold text-dark-60',
          startSymbol: 'text-light-60',
        }}
        value={convertCentsToDollars(allTransactionValueCents)}
      />
      <IconButton
        variant="white"
        offHoverStyles
        onClick={() => setVisible((prev) => !prev)}
        iconName={!visible ? 'arrowBottom' : 'arrowTop'}
      />
      <animated.div
        className="absolute left-0 right-0 top-[calc(100%_+_4px)] w-full"
        style={style}
      >
        <ul
          className="grid gap overflow-hidden rounded-lg border border-light bg-light"
          tabIndex={-1}
        >
          {items.map((item) => (
            <div
              key={item.vendorEntity?.name}
              className="grid grid-cols-[1fr_max-content_max-content] gap-4 bg-white p-4"
            >
              <div className="grid justify-start justify-items-start gap-1">
                <span className="inline-regular text-light-60">
                  {dayjs(item.date).format('MMM DD, YYYY')}
                </span>
                <InlineObject
                  fallbackNode=" "
                  hideTooltipWhenOverflow
                  iconName="bag"
                  object={item.vendorEntity?.name}
                />
              </div>
              <CurrencyFormatter
                value={convertCentsToDollars(item.amountCents)}
              />
              <div className="w-6" />
            </div>
          ))}
        </ul>
      </animated.div>
    </span>
  );
}

export default TransactionCollapsedList;
