import React from 'react';
import {
  IColumn,
  IContextMenuProps,
  IFormatterProps,
} from 'bundles/Shared/components/Table/types';
import { GeneralLedger } from 'bundles/Settings/components/REport/GeneralLedgers/quickMapping/QuickMappingModal';
import { CategoryObject } from 'stories/Objects/CategoryObject/CategoryObject';
import { GroupContainerItem } from 'stories/Table/Actions/ActionWrapper/GroupContainerItem';
import { Icon } from 'stories/Icon/Icon';
import { SelectableItem } from 'stories/Table/Actions/SelectableItem';
import { cn } from '@/shared/lib/css/cn';
import { ActionsContainer } from 'stories/Table/Actions/ActionsContainer';

import {
  getCategoryPathFromTreeById,
  mapCategoryPathToCodes,
  useCategoryPathFromFinancialCategoriesById,
} from '@/entities/finanicalCategory/lib';
import { useGetApiReportFinancialCategoriesTreeQuery } from '@/shared/api/reportFinancialCategoriesApiEnhanced';

type Props = IFormatterProps<GeneralLedger>;
export type SuggestedCategoryContextMenuParams =
  IColumn<GeneralLedger>['contextMenuParams'] & {
    onSelectedChange?: (
      row: GeneralLedger,
      id: GeneralLedger['selectedCategory'],
    ) => void;
  };

export const SuggestedCategoryContextMenu = ({
  row,
  column,
  hidePopover,
}: IContextMenuProps<GeneralLedger>) => {
  const { onSelectedChange } =
    column.contextMenuParams as SuggestedCategoryContextMenuParams;
  const { data } = useGetApiReportFinancialCategoriesTreeQuery();
  const tree = data?.tree ?? [];

  const handleSelectedChange = (id: GeneralLedger['selectedCategory']) => {
    onSelectedChange?.(row, id);
    hidePopover?.();
  };

  return (
    <ActionsContainer>
      <GroupContainerItem title="Quick map With">
        {row.suggestedReportFinancialCategories.map((category) => (
          <SelectableItem
            selected={row.selectedCategory === category.id}
            key={category.id}
            id={category.id}
            text={
              <CategoryObject
                className="bg-transparent hover:bg-transparent"
                path={mapCategoryPathToCodes(
                  getCategoryPathFromTreeById(category.id, tree),
                )}
              />
            }
            onClick={() => handleSelectedChange(category.id)}
          />
        ))}
        <SelectableItem
          selected={row.selectedCategory === 'ignored'}
          id="ignored"
          text="Ignore it"
          onClick={() => handleSelectedChange('ignored')}
        />
      </GroupContainerItem>
    </ActionsContainer>
  );
};

function SuggestedCategoryCell({ row }: Props) {
  const path = useCategoryPathFromFinancialCategoriesById(
    row.selectedCategory === 'ignored' ? null : row.selectedCategory,
  );

  return (
    <div className="flex w-full items-center justify-between gap-1">
      <div className="flex min-w-0 items-center gap-1">
        {row.selectedCategory != null && row.selectedCategory !== 'ignored' ? (
          <CategoryObject path={path.map(({ code }) => code)} />
        ) : (
          `${row.suggestedReportFinancialCategories.length} suggestions`
        )}
        <Icon className="text-neutral-450" iconName="edit" />
      </div>
      <div
        className={cn('white-space-nowrap flex items-center gap-1', {
          'text-danger-080': row.selectedCategory === 'ignored',
          'text-success-080':
            row.selectedCategory != null && row.selectedCategory !== 'ignored',
          'text-info-055': row.selectedCategory == null,
        })}
      >
        {row.selectedCategory === 'ignored' && 'IGNORED'}
        {row.selectedCategory !== 'ignored' && row.selectedCategory != null && (
          <>
            <Icon className="text-success-080" iconName="checkSmall" />
            MAPPED
          </>
        )}
        {row.selectedCategory == null && (
          <>
            <Icon iconName="magic" />
            MAP
          </>
        )}
      </div>
    </div>
  );
}

export default SuggestedCategoryCell;
