import { SymmetreAgGridTable } from '@/bundles/Shared/components/AgGrid/Table/SymmetreAgGridTable';
import { WidgetProps } from 'bundles/Shared/widgets/dashboard/widgets/model';
import { useGridDefaultParams } from 'lib/ag-grid/useGridDefaultParams';
import React, { useMemo } from 'react';
import {
  DEFAULT_WIDGET_TABLE_PROPS,
  useWidgetTableDefaultGroupDef,
  WIDGET_TABLE_AUTO_GROUP_COL_DEF,
} from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/table/lib';
import { AgGridReactRef } from '@/lib/ag-grid/types';

export const WidgetTable = React.forwardRef<
  AgGridReactRef,
  Pick<WidgetProps, 'mode' | 'isLastInGroup'> &
    React.ComponentProps<typeof SymmetreAgGridTable> & {
      defaultParams?: Parameters<typeof useGridDefaultParams>[0];
      /**
       * May lead to performance issues since all columns are rendered in the DOM
       */
      expensivePropSuppressColumnVirtualisation?: boolean;
    }
>(
  (
    {
      mode,
      defaultParams,
      expensivePropSuppressColumnVirtualisation,
      isLastInGroup,
      ...props
    },
    externalRef,
  ) => {
    const { onGridReady } = useGridDefaultParams({
      enableExpandOnClick: true,
      sizeColumnsToFit: {
        afterColumnsChanged: true,
      },
      ...defaultParams,
    });
    const defaultGroupDef = useWidgetTableDefaultGroupDef(mode);
    return (
      <SymmetreAgGridTable
        {...DEFAULT_WIDGET_TABLE_PROPS}
        popupParent={document.body}
        ref={externalRef}
        domLayout={mode === 'pdf' ? 'autoHeight' : 'normal'}
        // workaround for ag-grid truncate last row in pdf
        getRowHeight={(params) =>
          mode === 'pdf' && isLastInGroup && params.node.lastChild ? 50 : 32
        }
        enableRoundedCorners={mode === 'pdf'}
        hideShadowsPinnedColumn={mode === 'pdf'}
        onGridReady={onGridReady}
        treeData
        groupDefaultExpanded={0}
        groupIncludeFooter={false}
        groupIncludeTotalFooter={false}
        autoGroupColumnDef={WIDGET_TABLE_AUTO_GROUP_COL_DEF}
        defaultColGroupDef={defaultGroupDef}
        rowClass={mode === 'pdf' ? 'break-inside-avoid' : undefined}
        suppressColumnVirtualisation={expensivePropSuppressColumnVirtualisation}
        {...props}
      />
    );
  },
);

export const WidgetTablePlaceholder = () => {
  const columnDefs = useMemo(
    () => [
      {
        headerName: 'Example Column 1',
      },
      {
        headerName: 'Example Column 2',
      },
    ],
    [],
  );
  return (
    <WidgetTable
      mode="view"
      autoGroupColumnDef={undefined}
      columnDefs={columnDefs}
      rowData={[]}
    />
  );
};
