import React from 'react';
import { cn } from '@/shared/lib/css/cn';
import { Button } from '@/stories/Button/Button';
import {
  openFormulasSettings,
  selectFormulasSettingsIsOpened,
} from 'bundles/Shared/entities/formula';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';

export function DynamicValuesButton({
  ...props
}: Omit<React.ComponentProps<typeof Button>, 'onClick'>) {
  const dispatch = useAppDispatch();
  const isDynamicValuesOpen = useAppSelector(selectFormulasSettingsIsOpened);

  return (
    <>
      <Button
        iconClasses={cn(isDynamicValuesOpen && 'text-info-055')}
        iconName={isDynamicValuesOpen ? 'panelLeft' : 'panelRight'}
        onClick={() => {
          dispatch(openFormulasSettings());
        }}
        variant="secondary"
        {...props}
      >
        Dynamic Values
      </Button>
    </>
  );
}
