import React, { useEffect } from 'react';
import { LinkButton } from '@/stories/LinkButton/LinkButton';
import { apiClient } from '@/lib/http';
import { useLoadedUserProfile } from '@/widgets/userProfile/hooks/useLoadedUserProfile';
import { useCountdown, useBoolean } from 'usehooks-ts'; // TODO: FE-3718
import { cn } from '@/shared/lib/css/cn';

export const ResetPasswordLink = ({ className }: { className?: string }) => {
  const {
    value: isDisabled,
    setTrue: disable,
    setFalse: enable,
  } = useBoolean(false);
  const user = useLoadedUserProfile();
  const userData = { email: user?.data?.email };

  const [count, { startCountdown, resetCountdown }] = useCountdown({
    countStart: 30,
    intervalMs: 1000,
  });

  const resetPassword = () => {
    disable();

    apiClient
      .post('/users/password', { user: userData })
      .then((response) => response.data)
      .then(() => {
        window.toastr.info(
          'You should receive an email with the instructions soon',
        );
        startCountdown();
      })
      .catch((error) => {
        toastr.error(`${error.response.data.errors}\n`);
        enable();
        resetCountdown();
      });
  };

  useEffect(() => {
    if (count === 0 && isDisabled) {
      enable();
    }
  }, [count, isDisabled]);

  return (
    <div
      className={cn('label-regular mt-2 text-xs text-neutral-550', className)}
    >
      Can't remember your current password?
      <LinkButton
        variant="secondary"
        className="label-regular ml-1"
        onClick={resetPassword}
        disabled={isDisabled}
      >
        Reset your password via email
        {isDisabled && ` (${count}s)`}
      </LinkButton>
    </div>
  );
};
