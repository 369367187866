import React, { useEffect } from 'react';
import { updateOperationalBudgetsLineItemBulk } from 'bundles/REconcile/actions/operationalBudgets';
import { IOperationalBudgetsLineItemRequest } from 'bundles/REconcile/actions/operationalBudgetsTypes';
import { IReportBudgetOnShow } from 'types/ReportBudget';
import { IAutoSavingStatus } from '@/bundles/REconcile/components/operational/OperationalBudget';
import useDebounce from '@/shared/lib/hooks/useDebounce';

function replaceTextAndNaN(
  lineItems: IOperationalBudgetsLineItemRequest[],
): IOperationalBudgetsLineItemRequest[] {
  return lineItems.map((li) => ({
    ...li,
    value: Number.isNaN(parseFloat(li.value ?? '')) ? null : li.value,
  }));
}

export function useUpdateLineItemsEffect({
  budget,
  updatedLineItems,
  setAutoSavingStatus,
}: {
  budget: IReportBudgetOnShow;
  updatedLineItems: IOperationalBudgetsLineItemRequest[];
  setAutoSavingStatus: React.Dispatch<React.SetStateAction<IAutoSavingStatus>>;
}): void {
  const debouncedUpdatedLineItems = useDebounce(updatedLineItems, 2500);

  useEffect(() => {
    const fetchAsync = async () => {
      if (!debouncedUpdatedLineItems.length) return;
      setAutoSavingStatus({
        value: 'saving',
      });

      const res = await updateOperationalBudgetsLineItemBulk(budget.id, {
        line_items: replaceTextAndNaN(debouncedUpdatedLineItems),
      });
      if (!res) {
        setAutoSavingStatus({
          value: 'error',
        });
        return;
      }
      setAutoSavingStatus({
        value: 'saved',
      });
    };
    fetchAsync();
  }, [debouncedUpdatedLineItems]);
}
