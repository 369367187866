import { ERPSystemIcon } from '@/entities/erpsystem/ui/ERPSystemIcon';
import { ERPSystem } from '@/entities/erpsystem';

interface Props {
  sourceType: ERPSystem;
  className?: string;
}

/**
 * @deprecated use ERPSystemIcon instead
 */
function SourceTypeImage({ sourceType, className }: Props) {
  return <ERPSystemIcon system={sourceType} className={className} />;
}

export default SourceTypeImage;
