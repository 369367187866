import React, { FC, useState, useMemo } from 'react';
import { Modal } from 'stories/Modals/Modal/Modal';
import { ConfirmationModal } from 'stories/Modals/ConfirmationModal/ConfirmationModal';
import FormComponent from 'bundles/Shared/components/GroupForm/FormComponent/FormComponent';
import FormButtons from 'bundles/Shared/components/GroupForm/FormButtons/FormButtons';
import {
  selectCompanies,
  selectContacts,
  selectReasons,
} from 'bundles/Construction/reducers/ReconcileSlice';
import { useAppSelector } from '@/shared/lib/hooks/redux';
import { selectChangeEvent } from 'bundles/Construction/reducers/ReconcileChangeEventSlice';
import { TTypeCostBreakdown } from '@/bundles/Construction/components/ChangeOrderEvent/CostBreakdownDetails';
import { useGroupedFilteredItems } from '@/bundles/Construction/components/helpers';
import getItemsCORModalEdit from '@/bundles/Construction/components/ChangeOrderEvent/EditChangeEventModal/initData';
import { convertToMilliseconds } from '@/shared/lib/converters';

interface IEditChangeEventModalProps {
  setShowModal: (typeCost: TTypeCostBreakdown) => void;
  handleEdit: () => void;
  isVerified: boolean;
}

const EditChangeEventModal: FC<IEditChangeEventModalProps> = ({
  setShowModal,
  handleEdit,
  isVerified,
}) => {
  const changeEvent = useAppSelector(selectChangeEvent);
  const reasons = useAppSelector(selectReasons);
  const companies = useAppSelector(selectCompanies);
  const contacts = useAppSelector(selectContacts);
  const canEditAllAttr = !changeEvent?.availableActions.includes(
    'update_all_attributes',
  );

  const [formData, setFormData] = useState({
    ...changeEvent,
    reconcileCompany: changeEvent?.reconcileCompany?.id,
    reconcileContact: changeEvent?.reconcileContact?.id,
    number: changeEvent?.code
      ? `${changeEvent.number}-${changeEvent.code}`
      : changeEvent?.number,
    receivedDate: convertToMilliseconds(changeEvent?.receivedDate),
    contractorDate: convertToMilliseconds(changeEvent?.contractorDate),
    sentToDesignerOfRecordDate: changeEvent?.sentToDorDate
      ? convertToMilliseconds(changeEvent?.sentToDorDate)
      : null,
    changeOrderReasons: changeEvent?.changeOrderReasons.map((reason) => ({
      label: reason.name,
      id: reason.id,
    })),
    reallocationJccId: changeEvent?.reallocation
      ? changeEvent?.reallocation.jccId
      : null,
    probability_comment: changeEvent?.probabilityComment
      ? changeEvent?.probabilityComment
      : null,
  });
  const [validate, setValidate] = useState({
    number: true,
  });
  const [loading, setLoading] = useState(false);
  const onLoading = () => {
    setLoading(true);
  };
  const [showConfirmModal, setConfirmModal] = useState(false);
  const [disabledCloseIcon, setDisabledCloseIcon] = useState(false);

  const closeCOR = () => {
    setConfirmModal(true);
  };

  const confirmModalHandle = (status: boolean) => {
    if (status) {
      setShowModal(false);
    }
    setConfirmModal(false);
  };

  const filteredReasons = useGroupedFilteredItems(reasons);

  const initGroupFormItems = useMemo(
    () =>
      getItemsCORModalEdit(
        formData,
        setFormData,
        companies,
        contacts,
        filteredReasons,
        true,
        isVerified,
        canEditAllAttr,
      ),
    [formData, setFormData],
  );
  return (
    <>
      <Modal
        header={
          <div>
            <div className="dark header6-bold">Change Event Details</div>
          </div>
        }
        additionalActions={
          <FormButtons
            formData={formData}
            groupFormItems={initGroupFormItems}
            onSubmit={() => handleEdit(formData)}
            buttonConfig={{
              titleBtn: 'Save Updates',
              funcCancel: closeCOR,
            }}
            loading={loading}
            onLoading={onLoading}
          />
        }
        size="lg"
        classes={{
          body: 'reconcile-common-modal-create',
        }}
        maxHeight
        toggle={closeCOR}
        disabledClose={disabledCloseIcon}
        validate={validate}
      >
        {initGroupFormItems.map((formItem) => (
          <div key={formItem.title}>
            <div className="dark-60 body-semibold mb-s">
              {formItem.customTitle}
            </div>
            <FormComponent
              {...formItem}
              formData={formData}
              setFormData={setFormData}
              styles={{
                wrapper: 'group-form__step-item',
              }}
              validate={validate}
              setValidate={setValidate}
            />
          </div>
        ))}
      </Modal>
      {showConfirmModal && (
        <ConfirmationModal
          title="Are you sure that you want to quit?"
          subtitle="The changed data will be lost."
          actions={{
            primaryButton: {
              text: 'Yes',
              variant: 'danger',
            },
            secondaryButton: {
              text: 'No',
              variant: 'secondary',
            },
          }}
          handleResolve={confirmModalHandle}
        />
      )}
    </>
  );
};

export default EditChangeEventModal;
