import * as React from 'react';
import { formatUnixDate } from '@/shared/lib/formatting/dates';
import { ISharedDocument } from '@/types/SharedDocument';

interface Props {
  row: ISharedDocument;
  mobile?: boolean;
}

function UploadDate({ row, mobile }: Props) {
  return !mobile ? (
    <div className="font-weight-400 d-block text-light-60">
      {formatUnixDate(row.createdAt, 'MM-DD-YYYY')}
    </div>
  ) : (
    <p>
      <span className="light-60">Upload Date: </span>
      <span className="dark-60 font-weight-500">
        {formatUnixDate(row.createdAt, 'MM-DD-YYYY')}
      </span>
    </p>
  );
}

export default UploadDate;
