import { LegalEntityUnderwritingBudget } from 'bundles/REconcile/underwritting/api/reconcileUnderwritingBudgetGeneratedApi';
import { MetricsSettingsPanel } from 'bundles/REconcile/underwritting/features/MetricsSettingsModal';
import useBoolean from '@/shared/lib/hooks/useBoolean';
import { IconButton } from 'stories/IconButton/IconButton';

export function UpdateMetrics({
  budgetId,
}: {
  budgetId: NonNullish<LegalEntityUnderwritingBudget['budget']>['id'];
}) {
  const {
    value: metricsPanel,
    setTrue: openMetricsPanel,
    setFalse: closeMetricsPanel,
  } = useBoolean();

  return (
    <>
      <div className="flex w-full items-center justify-between gap-4">
        KPIs and Metrics
        <IconButton onClick={openMetricsPanel} iconName="settings" />
      </div>
      {metricsPanel && (
        <MetricsSettingsPanel budgetId={budgetId} onClose={closeMetricsPanel} />
      )}
    </>
  );
}
