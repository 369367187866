import { useNavigate } from '@reach/router';
import ContainerWithAppBar from 'bundles/Shared/components/Containers/ContainerWithAppBar';
import AppBar from 'bundles/Shared/components/Containers/appBar/AppBar';
import { IconButton } from '@/stories/IconButton/IconButton';
import FileTracker from 'bundles/Assets/components/FileTracker';
import React from 'react';

const DocumentsPage = () => {
  const navigate = useNavigate();
  return (
    <ContainerWithAppBar
      appBar={
        <AppBar
          classes={{
            title: 'flex items-center gap-4',
          }}
          title={
            <>
              <IconButton
                onClick={() => navigate(-1)}
                className="h-[40px]"
                iconName="arrowLeft"
              />
              Documents
            </>
          }
        />
      }
    >
      <FileTracker />
    </ContainerWithAppBar>
  );
};
export default DocumentsPage;
