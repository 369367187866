import { Contribution, Distribution } from '@/entities/return';
import {
  InvestmentIndex,
  TransactionSource,
} from '@/entities/return/api/capitalInvestmentObjectsGeneratedApi';
import { ICapitalInvestment } from 'bundles/REturn/types';
import { useModal } from '@/shared/lib/hooks/useModal';
import { capitalize, sumBy, uniqBy } from 'lodash-es';
import { useCallback } from 'react';
import ReviewAction from '@/bundles/REturn/components/Ownership/modals/manageCapitalInvestmentModal/ReviewAction';
import { getTotalInvestmentEntityAmountByEntryType as getInvestmentEntityTotalAmountByEntryType } from '@/bundles/REturn/components/Ownership/modals/manageCapitalInvestmentModal/utils';
import { convertCentsToDollars } from '@/shared/lib/converters';

interface Params {
  items: (Contribution | Distribution)[];
  type: TransactionSource['kind'];
  removeAction: (id: Contribution['id'] | Distribution['id']) => Promise<void>;
  capitalInvestments: InvestmentIndex[];
}

const TOTAL_ENTRY_AMOUNT_MAP = {
  contribution: 'totalContributionsCents',
  distribution: 'totalDistributions',
} as const;

export function useRemoveEntry({
  items,
  removeAction,
  type,
  capitalInvestments,
}: Params) {
  const { openModal } = useModal();

  const onRemoveEntry = useCallback(
    async (entry: Contribution | Distribution) => {
      const { accountingTransaction } = entry;

      const gl = `${accountingTransaction?.generalLedger.accNumber ?? ''} ${
        accountingTransaction?.generalLedger.accTitle ?? ''
      }`;
      const generalLedgerLine = (
        <span className="inline-semibold"> General Ledger: {gl}</span>
      );

      const mappedTransactions = entry.accountingTransaction
        ? entry.accountingTransaction.mappedTransactions
        : ([entry] as NonNullish<
            Contribution['reportLineItem']
          >['mappedTransactions']);

      const usedCapitalInvestments = capitalInvestments.filter((ci) =>
        mappedTransactions
          ?.map(({ capitalInvestmentId }) => capitalInvestmentId)
          .includes(ci.id),
      );

      const totalEntryAmount = sumBy(usedCapitalInvestments, (item) => {
        const amount = item[TOTAL_ENTRY_AMOUNT_MAP[type]];
        return type === 'contribution' ? convertCentsToDollars(amount) : amount; // Remove the ternary when distrs will be migrated to cents
      });

      const presentTotalEntryAmount =
        totalEntryAmount -
        mappedTransactions.reduce(
          (acc: number, item: Contribution | Distribution) => {
            return convertCentsToDollars(item.amountCents) + acc;
          },
          0,
        );

      const entryReviewActionItem = (
        <ReviewAction.Item title={capitalize(type)}>
          <ReviewAction.SubItem name="Total">
            <ReviewAction.AmountComparison
              past={totalEntryAmount}
              present={presentTotalEntryAmount}
            />
          </ReviewAction.SubItem>
        </ReviewAction.Item>
      );

      const getPastTotalAmount = (capitalInvestment: ICapitalInvestment) =>
        getInvestmentEntityTotalAmountByEntryType({
          capitalInvestment,
          type,
        });

      const uniqMappedTransactions = uniqBy(
        mappedTransactions,
        'capitalInvestmentId',
      );

      const children = accountingTransaction ? (
        <div className="flex flex-col gap-4">
          <p className="inline-regular text-light-90">
            By removing this transaction from
            {generalLedgerLine}, you will update the amount as following:
          </p>

          {Boolean(mappedTransactions?.length) && (
            <ReviewAction.Item title="Investment Entity">
              {uniqMappedTransactions?.map((transaction) => {
                const capitalInvestment = capitalInvestments.find(
                  ({ id }) => id === transaction.capitalInvestmentId,
                );

                if (!capitalInvestment) return null;

                const pastRawFloat = getPastTotalAmount(capitalInvestment);

                if (pastRawFloat === null) return null;

                const past = Number(pastRawFloat);

                const allTransactions = mappedTransactions.filter(
                  (t) =>
                    t.capitalInvestmentId === transaction.capitalInvestmentId,
                );
                const presentAmount = sumBy(allTransactions, (t) =>
                  convertCentsToDollars(t.amountCents),
                );

                return (
                  <ReviewAction.SubItem
                    key={transaction.id}
                    name={transaction.investmentEntity.name}
                  >
                    <ReviewAction.AmountComparison
                      past={past}
                      present={past - presentAmount}
                    />
                  </ReviewAction.SubItem>
                );
              })}
            </ReviewAction.Item>
          )}

          {entryReviewActionItem}
        </div>
      ) : (
        <div className="flex flex-col gap-4">
          <p className="inline-regular text-light-90">
            By removing this manual entry, you will update the amount as
            following:
          </p>
          {entryReviewActionItem}
        </div>
      );

      const res = await openModal(ReviewAction.Modal, {
        children,
      });

      if (!res) return;

      removeAction(entry.id);
    },
    [items, type, removeAction],
  );

  return onRemoveEntry;
}
