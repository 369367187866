/* eslint-disable max-len */
import Table from 'bundles/Shared/components/Table/Table';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { Breadcrumbs } from 'stories/Breadcrumbs/Breadcrumbs';
import { Button } from 'stories/Button/Button';
import { IconButton } from 'stories/IconButton/IconButton';
import { IUser, IUserRole } from 'types/User';
import { IChangeOrderApprover } from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/crud';
import { ICompany } from 'bundles/Construction/types';
import { navigate } from '@reach/router';
import {
  useDeleteApiSettingsReconcileLegalEntitiesByLegalEntityIdChangeOrderApproversAndIdMutation,
  useGetApiSettingsReconcileLegalEntitiesByLegalEntityIdChangeOrderApproversQuery,
  usePostApiSettingsReconcileLegalEntitiesByLegalEntityIdChangeOrderApproversMutation,
  usePutApiSettingsReconcileLegalEntitiesByLegalEntityIdChangeOrderApproversAndIdMutation,
  usePutApiSettingsReconcileLegalEntitiesByLegalEntityIdChangeOrderApproversAndIdSwapMutation,
} from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/workflow/approvers/api/reconcileWorkflowApproversApiExtended';
import { IColumn } from 'bundles/Shared/components/Table/types';
import { LegalEntity } from '@/entities/core/legalEntity';
import { UserRoleSelect } from 'bundles/Shared/entities/user/ui/UserRoleSelect';
import { UserSelect } from 'bundles/Shared/entities/user/ui/UserSelect';
import { CompanySelect } from 'bundles/Shared/entities/company/ui/CompanySelect';
import { SharedInput } from 'bundles/Shared/components/GroupForm/FormItems/new/SharedInput';

interface IApproversTableProps {
  legalEntityId: LegalEntity['id'];
}

export type TApproverRow = {
  priority: number;
  jobTitle?: string;
  userId?: IUser['id'];
  userRoleId?: IUserRole['id'];
  companyId?: ICompany['id'];
  id?: IChangeOrderApprover['id'];
  _destroy?: boolean;
};

const ApproversTable: FC<IApproversTableProps> = ({ legalEntityId }) => {
  const [data, setData] = useState<TApproverRow[]>([]);

  const { data: approvers, isLoading } =
    useGetApiSettingsReconcileLegalEntitiesByLegalEntityIdChangeOrderApproversQuery(
      {
        legalEntityId,
      },
      { skip: !legalEntityId },
    );

  const [addApprover] =
    usePostApiSettingsReconcileLegalEntitiesByLegalEntityIdChangeOrderApproversMutation();
  const [updateApprover] =
    usePutApiSettingsReconcileLegalEntitiesByLegalEntityIdChangeOrderApproversAndIdMutation();
  const [deleteApprover] =
    useDeleteApiSettingsReconcileLegalEntitiesByLegalEntityIdChangeOrderApproversAndIdMutation();
  const [swapPriorityApprover] =
    usePutApiSettingsReconcileLegalEntitiesByLegalEntityIdChangeOrderApproversAndIdSwapMutation();

  const users =
    approvers?.meta.users.map((u) => ({
      value: u.id,
      label: u.fullName,
      roleId: u.userRoleId,
    })) ?? [];
  const userRoles =
    approvers?.meta.userRoles.map((r) => ({ value: r.id, label: r.name })) ??
    [];
  const companies =
    approvers?.meta.companies.map((r) => ({ value: r.id, label: r.name })) ??
    [];

  useEffect(() => {
    setData(
      !isLoading
        ? [
            ...(approvers?.items.map((a) => ({
              userId: a.user.id,
              userRoleId: a.userRole.id,
              companyId: a.company.id,
              priority: a.priority,
              jobTitle: a.jobTitle,
              id: a.id,
            })) ?? []),
          ]
        : [],
    );
  }, [approvers]);

  const handleCreatingApprover = (
    field: { value: string },
    row: TApproverRow,
    fieldName: keyof TApproverRow,
  ) => {
    let newData = {
      ...row,
      [fieldName]: field.value,
    };

    if (fieldName === 'userRoleId') {
      newData = {
        ...row,
        userId: undefined,
        userRoleId: field.value,
      };
    }

    setData((prevData) => [
      ...prevData.slice(0, prevData?.length - 1),
      newData,
    ]);

    if (fieldName === 'companyId') {
      const bodyRequest = {
        company_id: field.value,
        user_id: row.userId,
        user_role_id: row.userRoleId,
        job_title: row.jobTitle,
      };
      addApprover({
        legalEntityId,
        body: bodyRequest,
      });
    }
  };

  const handleUpdateRoleApprover = (
    field: { value: string },
    row: TApproverRow,
  ) => {
    setData((prevData) =>
      prevData?.map((item) => {
        if (row.id === item.id) {
          return {
            ...item,
            userRoleId: field.value,
          };
        }
        return item;
      }),
    );
  };

  const handleChange = (
    field: { value: string | number },
    row: TApproverRow,
    fieldName: 'userId' | 'companyId' | 'jobTitle',
  ) => {
    const bodyRequest = {
      company_id: fieldName === 'companyId' ? field.value : row.companyId,
      user_id: fieldName === 'userId' ? field.value : row.userId,
      priority: row.priority,
      user_role_id: row.userRoleId,
      job_title: fieldName === 'jobTitle' ? field.value : row.jobTitle,
    };
    if (row.id) {
      updateApprover({
        id: row.id,
        legalEntityId,
        body: bodyRequest,
      });
    }
  };

  const swapPriority = (row: TApproverRow, direction: 'up' | 'down') => {
    const swapPriorityItem =
      direction === 'up' ? row.priority - 1 : row.priority + 1;

    swapPriorityApprover({
      id: row.id,
      legalEntityId,
      body: {
        swap_with_id: approvers?.items.find(
          (approver) => approver.priority === swapPriorityItem,
        ).id,
      },
    });
  };
  const addEmptyTemplateApprover = () =>
    setData((prevData) => [
      ...prevData,
      { priority: (approvers?.items.length ?? 0) + 1 },
    ]);

  const columns = useMemo(
    (): IColumn<TApproverRow>[] => [
      {
        dataField: 'priority',
        text: 'ID',
        classes: 'vertical-align-middle',
        headerStyle: {
          width: 20,
        },
      },
      {
        dataField: 'userRoleId',
        text: 'Role',
        classes: 'w-[25%]',
        formatter: ({ row }) => (
          <UserRoleSelect
            options={userRoles}
            value={row.userRoleId}
            onChange={
              row.id
                ? (e: { value: string }) => handleUpdateRoleApprover(e, row)
                : (e: { value: string }) =>
                    handleCreatingApprover(e, row, 'userRoleId')
            }
          />
        ),
      },
      {
        dataField: 'userId',
        text: 'User',
        classes: 'w-[25%]',

        formatter: ({ row }) => (
          <UserSelect
            options={users?.filter((u) => u.roleId === row.userRoleId) ?? []}
            disabled={!row.userRoleId}
            value={row.userId}
            onChange={
              row.id
                ? (e: { value: string }) => handleChange(e, row, 'userId')
                : (e: { value: string }) =>
                    handleCreatingApprover(e, row, 'userId')
            }
          />
        ),
      },
      {
        dataField: 'jobTitle',
        text: 'Job Title',
        classes: 'w-[25%]',
        formatter: ({ row }) => {
          const [jobTitle, setJobTitle] = useState(row.jobTitle ?? '');

          useEffect(() => {
            setJobTitle(row.jobTitle);
          }, [row]);

          return (
            <SharedInput
              placeholder="Enter Job Title"
              size="m"
              classNameInput="bg-white border-neutral-200"
              onChange={(e) => setJobTitle(e.target.value)}
              onBlur={(e) => {
                if (row.id && e.target.value !== row.jobTitle) {
                  handleChange(e.target, row, 'jobTitle');
                }
                handleCreatingApprover(e.target, row, 'jobTitle');
              }}
              disabled={!row.userId}
              value={jobTitle}
            />
          );
        },
      },
      {
        dataField: 'companyId',
        text: 'Company',
        classes: 'w-[25%]',
        formatter: ({ row }) => (
          <CompanySelect
            options={companies}
            disabled={!row.jobTitle}
            value={row.companyId}
            onChange={
              row.id
                ? (e: { value: string }) => handleChange(e, row, 'companyId')
                : (e: { value: string }) =>
                    handleCreatingApprover(e, row, 'companyId')
            }
          />
        ),
      },
      {
        dataField: 'actions',
        text: 'Actions',
        classes: 'vertical-align-middle whitespace-nowrap',
        headerStyle: {
          width: 120,
        },
        formatter: ({ row }) => (
          <>
            <IconButton
              iconName="bottom"
              className="mr-1"
              onClick={() => swapPriority(row, 'down')}
              disabled={
                !row.id ||
                row.id ===
                  approvers?.items[approvers?.items?.length - 1 || 0]?.id
              }
            />
            <IconButton
              iconName="top"
              className="mr-10"
              onClick={() => swapPriority(row, 'up')}
              disabled={!row.id || row.id === approvers?.items[0]?.id}
            />
            <IconButton
              iconName="closeSmall"
              onClick={() =>
                row.id
                  ? deleteApprover({
                      id: row.id,
                      legalEntityId,
                    })
                  : setData((prevData) =>
                      prevData?.slice(0, prevData.length - 1),
                    )
              }
            />
          </>
        ),
      },
    ],
    [approvers],
  );

  return (
    <div className="flex flex-col gap-6 p-6">
      <Breadcrumbs
        onBackClick={() => navigate(-1)}
        className="w-fit"
        breadcrumbs={[
          { label: 'Settings', to: '../', isCurrent: false },
          { label: 'Approvers', to: '', isCurrent: true },
        ]}
      />
      <div className="header5-bold text-neutral-850">Approvers</div>
      <Table
        borderLessOutside
        items={data ?? []}
        columns={columns}
        emptyDocumentsLabel="No Approvers Yet"
        loading={isLoading || !legalEntityId}
      />
      <Button
        variant="secondary"
        size="xs"
        onClick={addEmptyTemplateApprover}
        className="mt-2 w-fit"
        disabled={approvers?.items?.length !== data?.length}
      >
        Add Approver
      </Button>
    </div>
  );
};

export default ApproversTable;
