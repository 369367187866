import { Icon } from '@/stories/Icon/Icon';
import { Tooltip } from '@/stories/Tooltip/Tooltip';
import { cn } from '@/shared/lib/css/cn';
import styles from '@/stories/Objects/CategoryObject/CategoryObject.module.scss';
import objectStyles from '@/stories/Objects/Objects.module.scss';
import { useMemo } from 'react';

interface Props extends PropsWithClassName {
  path?: string[];
  onSetCategory?: () => void;
  longText?: boolean;
}

export function CategoryObject({
  path,
  onSetCategory,
  longText,
  className,
}: Props) {
  const pathNotEmpty = path !== undefined && path.length > 0;
  const pathIsOverflow = path !== undefined && path.length > 2;

  const [firstItem, lastItem] = useMemo(
    () =>
      pathNotEmpty
        ? [path[0], path.length > 1 ? path[path.length - 1] : undefined]
        : [],
    [path],
  );

  if (!pathNotEmpty) {
    return (
      <div
        className={cn(
          objectStyles.container,
          objectStyles.container_empty,
          styles.container,
        )}
      >
        <span className="secondary-semibold" onClick={onSetCategory}>
          Set Category
        </span>
      </div>
    );
  }

  return (
    <Tooltip
      className="d-inline-flex"
      mainText={path?.join(' / ')}
      maxWidth="none"
      disabled={longText ? !longText : !pathIsOverflow}
      arrowPosition="center"
      classes={{
        spanContainer: 'min-w-0',
      }}
    >
      <div className={cn(objectStyles.container, styles.container, className)}>
        <Icon className="light-60 mr-xs" iconName="category" />
        <div className="secondary-regular overflow-hidden text-ellipsis whitespace-nowrap	">
          <span>{!longText ? firstItem : '...'}</span>
          {pathIsOverflow && (
            <>
              <span className={styles.separator} />
              <span className="light-40">...</span>
            </>
          )}
          {lastItem && (
            <>
              <span className={styles.separator} />
              <span>{lastItem}</span>
            </>
          )}
        </div>
      </div>
    </Tooltip>
  );
}

export default CategoryObject;
