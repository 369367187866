import { formatAmount } from '@/shared/lib/formatting/number';
import React, { FC } from 'react';
import { SmallAddingButton } from 'stories/SmallAddingButton/SmallAddingButton';
import { Tooltip } from 'stories/Tooltip/Tooltip';
import { TTypeCostBreakdown } from '@/bundles/Construction/components/ChangeOrderEvent/CostBreakdownDetails';
import { IMarkup } from 'bundles/Construction/components/ChangeOrderEvent/CostBreakdownDetails/types';
import { checkAmount } from 'bundles/Construction/components/ChangeOrderEvent/CostBreakdownDetails/utils';

interface Props {
  markups: IMarkup[];
  editOnly: boolean;
  markupsSubtotal: () => void;
  invalidAmount: number;
  allowedMarkup: (markup: IMarkup) => void;
  setShowModal: (typeCost: TTypeCostBreakdown) => void;
  editCost: boolean;
}

const Markups: FC<Props> = ({
  markups,
  editOnly,
  markupsSubtotal,
  setShowModal,
  editCost,
  invalidAmount,
}) => (
  <div className="card-cost-breakdown card-cost-breakdown_line">
    {markups.length ? (
      <div>
        <div className="dark-60 inline-semibold mb-s">Contractor Markups</div>
        <table className="table-reasons table">
          <thead>
            <tr>
              <th className="w-35">Cost Code</th>
              <th className="w-35">Comment</th>
              <th className="w-10">Amount</th>
            </tr>
          </thead>
          <tbody>
            {markups.map((markup, i) => (
              <tr key={i}>
                <td className="dark-60 secondary-regular">
                  {`${markup.costCode?.code} - ${markup.costCode?.name}`}
                </td>
                <td className="dark-60 secondary-regular">{markup.comment}</td>
                <td className="dark-60 secondary-regular text-right">
                  {checkAmount(markup, invalidAmount) ? (
                    <Tooltip
                      mainText={`Allowed markup: ${formatAmount(
                        checkAmount(markup ?? '0', invalidAmount),
                      )}`}
                      arrowPosition="center"
                    >
                      <span className="red">
                        {formatAmount(markup.amount ?? 0)}
                      </span>
                    </Tooltip>
                  ) : (
                    <span>{formatAmount(markup.amount ?? 0)}</span>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="flex justify-between items-center">
          {!editOnly && !editCost && (
            <SmallAddingButton onClick={() => setShowModal('markups')}>
              Add Markup
            </SmallAddingButton>
          )}
          {markups.length > 0 && (
            <div className="flex secondary-regular">
              <div className="mr-xs">Total Markups</div>
              <div className="dark-60">{formatAmount(markupsSubtotal())}</div>
            </div>
          )}
        </div>
      </div>
    ) : (
      !editOnly && (
        <div
          className="blue inline-semibold cursor-pointer"
          onClick={() => {
            setShowModal('markups');
          }}
        >
          Add Contractor Markups
        </div>
      )
    )}
  </div>
);

export default Markups;
