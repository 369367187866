import React, { useMemo, useState } from 'react';
import { Button } from 'stories/Button/Button';
import { Icon } from 'stories/Icon/Icon';
import { InlineObject } from 'stories/Objects/InlineObject/InlineObject';
import { Label } from 'stories/Label/Label';
import { formatDate } from '@/shared/lib/formatting/dates';
import { formatAmount } from '@/shared/lib/formatting/number';
import { DialogProps, useModal } from '@/shared/lib/hooks/useModal';
import {
  IDevelopmentLegalEntityRequisitionTransaction,
  ILineItem,
} from 'bundles/REconcile/types/ILineItem';
import { VendorPopover } from '@/entities/accounting/transaction/ui/VendorPopover';
import Table from 'bundles/Shared/components/Table/Table';
import { IColumn } from 'bundles/Shared/components/Table/types';
import ActionsCell from 'bundles/REconcile/components/development/lineItems/ActionsCell';
import { useAppSelector } from '@/shared/lib/hooks/redux';
import {
  useGetDevelopmentCategoryFlatQuery,
  useGetDevelopmentCategoryQuery,
} from 'bundles/Construction/api/core';
import pluralize from 'pluralize';
import { getRemarkFormatter } from '@/entities/accounting/transaction/ui/Formatters';
import { TRouteParams, useQueryParams } from '@/shared/lib/hooks/useNavigation';
import styles from 'bundles/REturn/components/Ownership/modals/capitalInvestmentsEditor/CapitaInvestmentEditor.module.scss';
import { getTransactionAmountByType } from '@/bundles/REconcile/components/development/requisitions/modals/createTransactionsFromSource/bisUtils';
import AllocationStatistics from 'bundles/REconcile/components/AllocationStatistics';
import { useAllocation } from 'bundles/REconcile/components/AllocationProvider';
import LineItemModal from 'bundles/REconcile/components/LineItemModal';
import { transformCategorizedIdToKey } from 'bundles/Construction/components/Reallocation/Modals/components/utils';
import { sumBy, uniqueId } from 'lodash-es';
import { CssVar } from '@/shared/config/cssVar';
import { SidePanel } from 'stories/Modals/Modal/Modal';

const colors = [CssVar.green, CssVar.yellow, CssVar.blue, CssVar.pumpkin];

type Props = DialogProps &
  IDevelopmentLegalEntityRequisitionTransaction & {
    requisitionNumber: number;
    existingLineItems?: ILineItem[];
    header: string;
    submitText: string;
  };

type TItemWithExtraData = ILineItem & {
  color: string;
  category_id: string;
  new?: boolean;
};

const LineItemsSplitModal = ({
  onClose,
  onSubmit,
  header,
  submitText,
  vendorEntity,
  value,
  date,
  period,
  note,
  requisitionNumber,
  existingLineItems = [],
  valueType,
}: Props) => {
  const transactionAmount = getTransactionAmountByType(valueType, value);
  const [lineItems, setLineItems] = useState<TItemWithExtraData[]>([]);
  const [loading, setLoading] = useState(false);
  const { openModal } = useModal();
  const legalEntityCode = useAppSelector(
    (state) => state.developmentBudget.legalEntity.code,
  );
  const { data: categories } = useGetDevelopmentCategoryQuery({
    legalEntityCode,
  });
  const { data: categoriesFlat, isLoading: isLoadingCategories } =
    useGetDevelopmentCategoryFlatQuery({ legalEntityCode });
  const { categoryId, categoryType } =
    useQueryParams<
      TRouteParams['RECONCILE_DEVELOPMENT_LEGAL_ENTITY_DEVELOPMENT_LINE_ITEMS']
    >();

  // including some extra parameters for existing line items as its have different shape
  const allLineItems = useMemo<TItemWithExtraData[]>(
    () => [
      ...existingLineItems.map((li, i) => ({
        ...li,
        color: colors[i % colors.length],
        category_id: categoryId,
      })),
      ...lineItems,
    ],
    [lineItems],
  );

  const renderHeader = (type: 'new' | 'edit') => (
    <div>
      <div className="header6-bold text-dark-60">
        {type === 'edit' ? 'Edit' : 'Add'} Line Item
      </div>
      <div className="label-regular text-light-60 ">
        Requisition #{requisitionNumber}
      </div>
    </div>
  );

  const {
    total,
    allocatedAmount: residualAmount,
    notAllocatedAmount,
    getAllocatedAmountWithoutItem,
    allocationFlags,
  } = useAllocation({
    total: Math.abs(transactionAmount),
    items: allLineItems,
    sumMapper: (item) => Math.abs(Number(item.amount)),
  });

  const addLineItem = async () => {
    const res = await openModal(LineItemModal, {
      lineItem: {
        vendor: vendorEntity?.name,
        date: new Date(date),
        category_id: Number(categoryId),
      },
      fieldSettings: {
        amount: {
          allowNegative: valueType === 'credit',
          disallowPositive: valueType === 'credit',
          suggestedValue:
            (valueType === 'credit' ? -1 : 1) * notAllocatedAmount,
        },
        category_id: {
          disabled: true,
        },
      },
      legalEntityCode,
      categoryType,
      header: renderHeader('new'),
      submitText: 'Add item',
    });

    if (!res) return;

    const color = colors[allLineItems.length % colors.length];
    setLineItems([
      ...lineItems,
      {
        ...res,
        color,
        id: uniqueId(),
        new: true,
      },
    ]);
  };

  const handleRowEdit = async (row: ILineItem) => {
    const res = await openModal(LineItemModal, {
      lineItem: row,
      fieldSettings: {
        amount: {
          allowNegative: valueType === 'credit',
          disallowPositive: valueType === 'credit',
          suggestedValue: getAllocatedAmountWithoutItem(
            lineItems.findIndex((li) => li.id === row.id),
          ),
        },
        category_id: {
          disabled: true,
        },
      },
      legalEntityCode,
      categoryType,
      header: renderHeader('new'),
      submitText: 'Add item',
    });

    if (!res) return;

    setLineItems(
      lineItems.map((li) =>
        li.id === row.id
          ? {
              ...li,
              ...res,
            }
          : li,
      ),
    );
  };

  const handleRowRemove = (row) =>
    setLineItems(lineItems.filter((li) => li.id !== row.id));

  const handleSubmit = () => {
    setLoading(true);
    onSubmit({ lineItems });
    setLoading(false);
  };

  const columns: IColumn<TItemWithExtraData>[] = useMemo(
    () => [
      {
        text: 'JCC/SOVC',
        dataField: 'category_id',
        formatter: ({ row }) => (
          <div>
            <Label className="whitespace-normal text-left" color={row.color}>
              {
                categoriesFlat?.find(
                  (c) =>
                    transformCategorizedIdToKey(
                      row.category_id,
                      categoryType === 'JobCostCode' ? 'jcc' : 'sovc',
                    ) === c.key,
                ).name
              }
            </Label>
          </div>
        ),
      },
      {
        text: 'Remark',
        dataField: 'remark',
        formatter: getRemarkFormatter((item) => (
          <div className="text-dark-60">{item.description ?? item.remark}</div>
        )),
      },
      {
        text: 'Amount',
        dataField: 'amount',
        formatter: ({ row }) => (
          <div className="text-dark-60">{formatAmount(row.amount)}</div>
        ),
        classes: 'text-right whitespace-nowrap',
        headerAlign: 'end',
      },
      {
        text: '',
        dataField: 'actions',
        headerStyle: {
          width: 60,
        },
        formatter: ({ row }) => {
          if (!row.new) return '';

          return (
            <ActionsCell
              actions={[
                {
                  label: 'Edit',
                  handler: () => handleRowEdit(row),
                  icon: 'edit',
                },
                {
                  label: 'Delete',
                  handler: () => handleRowRemove(row),
                  icon: 'trash',
                },
              ]}
            />
          );
        },
      },
    ],
    [lineItems, categories, isLoadingCategories],
  );
  return (
    <div>
      <SidePanel toggle={onClose} header={header} bodyPadding="0">
        <div className="flex h-full flex-col">
          <div className="px-6 py-4">
            <div className="flex">
              <div className="mb-4 w-[100px]">
                <small className="mb-1.5 text-light-60">TX DATE</small>
                <div className="inline-regular text-light-90">
                  {formatDate(date, 'MMM DD, YYYY')}
                </div>
              </div>
              <div>
                <small className="mb-1 text-light-60">VENDOR</small>
                <div>
                  {vendorEntity && (
                    <VendorPopover vendor={vendorEntity}>
                      <InlineObject
                        iconName="bag"
                        object={vendorEntity?.name}
                        hideTooltipWhenOverflow
                      />
                    </VendorPopover>
                  )}
                  {!vendorEntity && <div>-</div>}
                </div>
              </div>
            </div>
            <div className="flex">
              <div className="w-[100px]">
                <small className="mb-1.5 text-light-60">PERIOD</small>
                <div className="light-90 inline-regular">
                  {formatDate(period, 'MMM-YYYY')}
                </div>
              </div>
              <div>
                <small className="mb-1.5 text-light-60">DESCRIPTION</small>
                <div className="inline-regular text-light-90">{note}</div>
              </div>
            </div>
          </div>
          <div className="flex flex-1 flex-col bg-light px-6 py-4">
            <div className="mb-4">
              <AllocationStatistics
                totalAmount={total ?? 0}
                allocatedAmount={residualAmount}
              />
            </div>

            <div className="flex-1">
              <div className="mb-1.5 flex justify-between">
                <h6 className="dark-60">
                  {lineItems.length > 0 && lineItems.length}{' '}
                  {pluralize('Line Item', lineItems.length)}
                </h6>
                <Button
                  disabled={allocationFlags.isFullyAllocated}
                  variant="secondary"
                  size="s"
                  onClick={addLineItem}
                >
                  Add Line Item
                </Button>
              </div>
              <Table
                borderLessOutside
                classes={{
                  table: styles.table,
                  container: styles.container,
                  body: 'transparent',
                }}
                defaultColumn={{
                  headerClasses: 'bg-white pt-s text-light-60',
                }}
                columns={columns}
                items={allLineItems}
              />
              {allLineItems.length > 0 && (
                <div className="mr-[60px] pr-4 pt-2 text-right">
                  <span className="font-14 mr-1 text-light-60">Total:</span>
                  <span className="font-14 text-dark-60">
                    {sumBy(allLineItems, (item) => Number(item.amount))}
                  </span>
                </div>
              )}
            </div>
            <Button
              variant="success"
              className="w-full mt-4"
              onClick={handleSubmit}
              disabled={!allocationFlags.isFullyAllocated || loading}
            >
              <div className="flex items-center">
                {loading && (
                  <Icon className="form-button-loading mr-2" iconName="sync" />
                )}
                {submitText}
              </div>
            </Button>
          </div>
        </div>
      </SidePanel>
    </div>
  );
};

export default LineItemsSplitModal;
