import FullWhiteTable from '@/bundles/Shared/components/Table/variants/FullWhiteTable';
import { CapitalInvestment, Distribution } from '@/entities/return';
import { AccruedPreferredReturn } from '@/entities/return/model/types/accruedPreferredReturn';
import {
  formatDate,
  formatDateToQuarterWithPrefix,
  formatUnixDate,
} from '@/shared/lib/formatting/dates';
import { CurrencyFormatter } from '@/stories/ValueFormatters/CurrencyFormatter';
import { Icon } from '@/stories/Icon/Icon';
import { IconButton } from '@/stories/IconButton/IconButton';
import { InlineObject } from '@/stories/Objects/InlineObject/InlineObject';
import React from 'react';
import MainColumnFormatter from '@/bundles/REturn/components/Ownership/modals/manageCapitalInvestmentModal/Columns/MainColumnFormatter';
import {
  AccruedAdjustment,
  AccruedEntryDto,
} from '@/entities/return/api/capitalInvestmentObjectsGeneratedApi';
import { useRemoveEntry } from '@/bundles/REturn/components/Ownership/modals/manageCapitalInvestmentModal/hooks/useRemoveEntry';
import { useEditManualEntry } from '@/bundles/REturn/components/Ownership/modals/manageCapitalInvestmentModal/hooks/useEditManualEntry';
import {
  useDeleteApiCapitalInvestmentAccruedPreferredReturnsByAccruedPreferredReturnIdAdjustmentsAndIdMutation,
  useDeleteApiCapitalInvestmentDistributionsByIdMutation,
} from '@/entities/return/api/capitalInvestmentObjectsEnhancedApi';
import { capitalize } from 'lodash-es';
import { useModal } from '@/shared/lib/hooks/useModal';
import { EditAccruedEntryModal } from '@/bundles/REturn/components/Ownership/modals/manageCapitalInvestmentModal/modals/EditAccruedEntryModal';
import { cn } from '@/shared/lib/css/cn';
import { DISTRIBUTION_LABEL_MAPPINGS } from '@/bundles/REturn/components/Ownership/modals/addDistributionsModal/AddDistributionsModal';

export const AccruedPreferredReturnModalBody = ({
  preferredDistributions,
  items,
  capitalInvestments,
  capitalInvestment,
}: {
  preferredDistributions: Distribution[];
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  items: (AccruedPreferredReturn | Distribution | AccruedAdjustment)[];
  capitalInvestments: CapitalInvestment[];
  capitalInvestment: CapitalInvestment;
}) => {
  const [deleteDistribution] =
    useDeleteApiCapitalInvestmentDistributionsByIdMutation();
  const removeDistribution = async (id: Distribution['id']) => {
    await deleteDistribution({ id });
  };

  const onRemoveDistribution = useRemoveEntry({
    items: preferredDistributions,
    type: 'distribution',
    removeAction: removeDistribution,
    capitalInvestments,
  });

  const onEditManualDistribution = useEditManualEntry({
    capitalInvestment,
    type: 'distribution',
  });

  const createdBy = (row: AccruedEntryDto) =>
    row.createdBy ? row.createdBy.fullName : 'system';

  const { openModal, confirm } = useModal();
  const onEditManualAccruedEntry = async (entry: AccruedEntryDto) => {
    await openModal(EditAccruedEntryModal, {
      row: entry,
      investmentEntityName: capitalInvestment.name,
    });
  };

  const [removeAdjustment] =
    useDeleteApiCapitalInvestmentAccruedPreferredReturnsByAccruedPreferredReturnIdAdjustmentsAndIdMutation();
  const onRemoveAdjustment = async (row) => {
    const result = await confirm({
      title: 'Do you want to remove the adjustment?',
      subtitle: '',
      actions: {
        primaryButton: {
          text: 'Remove',
          variant: 'danger',
        },
        secondaryButton: {
          text: 'Cancel',
          variant: 'secondary',
        },
      },
    });
    if (!result) return;

    removeAdjustment({
      id: row.id,
      accruedPreferredReturnId: row.accruedPreferredReturnId,
    });
  };

  const ADJUSTMENT = 'adjustment';
  const DISTRIBUTION = 'distribution';
  const ACCRUED = 'accrued';

  const getEntryType = (
    // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
    row: AccruedEntryDto | Distribution | AccruedAdjustment,
  ) => {
    if (row._type === 'adjustment') {
      return ADJUSTMENT;
    }
    if (row._type === 'distribution') {
      return DISTRIBUTION;
    }
    return ACCRUED;
  };

  const accruedEntryColumn = (row: AccruedEntryDto) => (
    <div>
      <div className="flex items-center justify-between">
        <div className="text-xs font-semibold text-neutral-850">
          {capitalize(row.periodType)}
        </div>
        <IconButton
          variant="secondary"
          iconName="editAlt"
          onClick={() => onEditManualAccruedEntry(row)}
        />
      </div>
      <div className="pt-2 text-xs text-neutral-500">
        Entry created by {createdBy(row)} on&nbsp;
        <span className="text-neutral-850">
          {formatUnixDate(row.createdAt as DateString, 'MMM DD, YYYY hh:mm A')}
        </span>
      </div>
    </div>
  );

  const adjustmentColumn = (row: AccruedAdjustment) => (
    <div>
      <div className="flex items-center justify-between">
        <div className="flex gap-xs text-xs font-semibold text-neutral-850">
          Adjustment
          {row.note && (
            <Icon
              iconName="info"
              tooltipProps={{
                mainText: row.note,
                className: 'text-xs text-neutral-500',
              }}
            />
          )}
        </div>
        <IconButton
          variant="secondary"
          iconName="trash"
          onClick={() => onRemoveAdjustment(row)}
        />
      </div>
      <div className="pt-2 text-xs text-neutral-500">
        Entry created by {createdBy(row)} on&nbsp;
        <span className="text-neutral-850">
          {formatUnixDate(row.createdAt as DateString, 'MMM DD, YYYY hh:mm A')}
        </span>
      </div>
    </div>
  );

  const columns = [
    {
      dataField: 'entries',
      headerClasses: 'w-[200px]',
      text: 'Entries',
      formatter: ({ row }) => {
        const entryType = getEntryType(row);

        if (entryType == ACCRUED) {
          return accruedEntryColumn(row);
        }

        if (entryType == ADJUSTMENT) {
          return adjustmentColumn(row);
        }

        if (entryType == DISTRIBUTION) {
          return (
            <MainColumnFormatter
              type={row._type}
              entry={row}
              actions={{
                onRemoveEntry: onRemoveDistribution,
                onEditManualEntry: onEditManualDistribution,
              }}
            />
          );
        }
      },
    },
    {
      dataField: 'date',
      headerClasses: 'w-[100px]',
      text: 'Date',
      formatter: ({
        row,
      }: {
        // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
        row: AccruedPreferredReturn | Distribution | AccruedAdjustment;
      }) => (
        <div className="flex flex-col gap-0.5">
          <span className="secondary-regular text-dark-60">
            {formatDate(row.date as DateString, 'MMM DD, YYYY')}
          </span>
          {row.periodType === 'quarterly' && (
            <span className="secondary-regular text-neutral-500">
              {formatDateToQuarterWithPrefix(
                (row.period ?? row.date) as DateString,
              )}
            </span>
          )}
          {row.periodType === 'monthly' && (
            <span className="secondary-regular text-neutral-500">
              {formatDate(row.period ?? (row.date as DateString), 'MMM-YYYY')}
            </span>
          )}
        </div>
      ),
    },
    {
      dataField: 'amount',
      headerClasses: 'w-[110px]',
      text: 'Amount',
      formatter: ({ row }) => {
        if (row.overriddenAmount) {
          return (
            <div>
              <CurrencyFormatter
                value={row.calculatedAmount}
                classes={{ value: 'text-xs', allParts: 'line-through' }}
              />
              <div className="flex items-center gap-1">
                <CurrencyFormatter
                  value={row.overriddenAmount}
                  classes={{ value: 'text-xs' }}
                />
                <Icon
                  iconName="info"
                  tooltipProps={{
                    mainText: `Amount was overridden by ${
                      row.amountUpdatedBy.fullName
                    } at ${formatUnixDate(
                      row.amountUpdatedBy.updatedAt,
                      'MMM DD, YYYY hh:mm A',
                    )}`,
                  }}
                />
              </div>
            </div>
          );
        }

        return (
          <CurrencyFormatter
            value={row.amount}
            classes={{ value: 'text-xs' }}
          />
        );
      },
    },
    {
      dataField: 'type',
      headerClasses: 'w-[120px]',
      text: 'Type',
      formatter: ({ row }) => {
        const entryType = getEntryType(row);
        let label;
        switch (entryType) {
          case ADJUSTMENT:
            label = 'Adjustment';
            break;
          case DISTRIBUTION:
            label = DISTRIBUTION_LABEL_MAPPINGS.preferred;
            break;
          case ACCRUED:
            label = 'Accrued Pref.';
            break;
        }

        return (
          <InlineObject
            object={label}
            className={cn({
              'bg-pumpkin-000 text-pumpkin-050 hover:bg-pumpkin-light-4':
                entryType === ADJUSTMENT,
              'bg-violet-000 text-violet-055 hover:bg-violet-light-4':
                entryType === ACCRUED,
            })}
          />
        );
      },
    },
  ];

  const totalEndingBalance = items.reduce((acc, item) => acc + item.amount, 0);

  return (
    <div>
      <div className="flex justify-between text-xs font-semibold text-neutral-550">
        <div className="text-xs font-semibold text-neutral-550">TOTAL:</div>
        <div>
          <CurrencyFormatter value={totalEndingBalance} />
        </div>
      </div>
      <div className="flex w-full flex-col gap-2 pt-2">
        <FullWhiteTable items={items} columns={columns} />
      </div>
    </div>
  );
};
