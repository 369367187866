import React from 'react';
import { CapitalEntry, ReportLineItem } from 'bundles/REturn/actions/types';
import ReviewAction from '@/bundles/REturn/components/Ownership/modals/manageCapitalInvestmentModal/ReviewAction';

import { convertCentsToDollars } from '@/shared/lib/converters';

interface Props {
  entries: CapitalEntry[];
  transaction: ReportLineItem;
}

export function ConfirmTransactionLink({ entries, transaction }: Props) {
  return (
    <div className="flex flex-col gap-4">
      <p className="inline-regular text-light-90">
        Please confirm you would like to link the following transactions:
      </p>

      <ReviewAction.Item title="Investment Entity">
        {entries.map((entry) => (
          <ReviewAction.SubItem
            key={entry.id}
            name={entry.investmentEntity.name}
            className="items-start"
          >
            <ReviewAction.LinkTransaction
              userName={entry.createdBy.fullName}
              amountOnLeft={
                entry.amountCents
                  ? convertCentsToDollars(entry.amountCents)
                  : entry.amount
              }
              dateOnLeft={entry.date}
              generalLedgerTitle={transaction.generalLedgerTitle}
              amountOnRight={convertCentsToDollars(transaction.amountCents)}
              dateOnRight={transaction.date}
            />
          </ReviewAction.SubItem>
        ))}
        <ReviewAction.SubItem name="Total" className="items-start">
          <ReviewAction.Total
            amount={convertCentsToDollars(transaction.amountCents)}
          />
        </ReviewAction.SubItem>
      </ReviewAction.Item>
    </div>
  );
}
