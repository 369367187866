import { OperationalMappingGroupByValue } from 'bundles/Shared/entities/sreUnitType';
import { ListOption } from 'stories/Checkbox/CheckList';
import { ColDef } from 'ag-grid-community';
import SetFilter from 'bundles/REconcile/components/operational/table/SetFilter';
import { getOptionsValues } from '@/shared/lib/listHelpers';

export function getPmcColumn({
  group,
  onChange,
  value,
  options = [],
}: {
  group: OperationalMappingGroupByValue[];
  options: ListOption<string>[];
  onChange: (items: string[]) => void;
  value: string[];
}): ColDef {
  return {
    colId: 'pmc',
    headerName: 'PMC',
    enableRowGroup: true,
    field: 'propertyManagementCompany.name',
    flex: 1,
    rowGroup: group.includes('pmc'),
    hide: group.includes('pmc'),
    headerComponent: options.length ? SetFilter : null,
    headerComponentParams: {
      filterName: 'PMC',
      items: options,
      value: options.filter((option) => value.includes(option.value)),
      onChange: (value) => onChange(getOptionsValues(value)),
      label: 'PMC',
    },
  };
}
