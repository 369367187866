import {
  OBJECTABLE_TYPE_CLASS_NAME_MAP,
  OBJECTABLE_TYPE_ICON_NAME_MAP,
} from '@/bundles/REport/components/financials/consts';
import { REPORT_FINANCIALS_DASHBOARD_HEADER_LOADING_JSX } from '@/bundles/REport/components/financials/dumbJSX';
import type { ObjAndStateProps } from '@/bundles/REport/components/financials/types';
import ScreenWithListNavigationLayout from '@/bundles/Shared/components/layouts/screenWithListNavigationLayout/ScreenWithListNavigationLayout';
import { cn } from '@/shared/lib/css/cn';

export function FinancialsDashboardHeaderObject({
  object,
  loading,
}: {
  object: ObjAndStateProps;
  loading: boolean;
}) {
  if (loading) return REPORT_FINANCIALS_DASHBOARD_HEADER_LOADING_JSX;

  return (
    <div className="flex items-center gap-4">
      <ScreenWithListNavigationLayout.HeaderImg
        imgSrc={'smallPictureUrl' in object ? object.smallPictureUrl : null}
        placeholderIcon={OBJECTABLE_TYPE_ICON_NAME_MAP[object._type]}
      />
      <div className="flex flex-col gap-1">
        <p
          className={cn(
            OBJECTABLE_TYPE_CLASS_NAME_MAP[object._type],
            'secondary-semibold uppercase',
          )}
        >
          {object._type}
        </p>
        <p className="header5-bold text-neutral-800">
          {'title' in object ? object.title : object.name}
        </p>
      </div>
    </div>
  );
}
