import React from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { CategoryRules } from 'bundles/Settings/components/REport/TableBuilder/CategoryRules/CategoryRules';
import {
  IAllRules,
  IUserFinancialCategoryRule,
  IUserRoleFinancialCategoryRule,
} from 'types/ReportTableConfig';
import { isRowCategory } from '@/bundles/Shared/components/ReportTable/types';

interface Props extends ICellRendererParams {
  allRules: IAllRules;
  handleRoleRulesChange: (
    newRules: IUserRoleFinancialCategoryRule[],
    categoryPath: string,
  ) => void;
  handleUserRulesChange: (
    newRules: IUserFinancialCategoryRule[],
    categoryPath: string,
  ) => void;
  handleHasChanges: (changing: boolean) => void;
}

const filteredRules = (
  allRules: IAllRules,
  categoryPath: string,
): IAllRules => {
  const roleRules = allRules.roleRules.filter(
    (rule) => rule.categoryPath === categoryPath,
  );
  const userRules = allRules.userRules.filter(
    (rule) => rule.categoryPath === categoryPath,
  );

  return {
    roleRules,
    userRules,
  };
};

export const CellRenderer = ({
  data,
  allRules,
  handleRoleRulesChange,
  handleUserRulesChange,
  handleHasChanges,
}: Props) => {
  if (!isRowCategory(data)) return null;

  const { categoryPath, label } = data;

  return (
    <CategoryRules
      categoryPath={categoryPath}
      handleRoleRulesChange={handleRoleRulesChange}
      handleUserRulesChange={handleUserRulesChange}
      handleHasChanges={handleHasChanges}
      allRules={filteredRules(allRules, categoryPath)}
      label={label}
    />
  );
};
