import { cn } from '@/shared/lib/css/cn';
import { RadioButton } from '@/stories/RadioButton/RadioButton';
import React from 'react';
import { Control, FieldValues, useController } from 'react-hook-form';
import { FieldPath } from 'react-hook-form/dist/types/path/eager';
import { BaseId, ListOption } from 'stories/Checkbox/CheckList';

export type RadioGroupItemProps = Omit<
  React.ComponentProps<typeof RadioButton>,
  'checked' | 'onChange' | 'children'
>;

interface Props<ID extends BaseId = BaseId> extends PropsWithClassName {
  value: ID | null | undefined;
  onChange: (items: ListOption<ID>) => void;
  options: ListOption<ID>[] | readonly ListOption<ID>[];
  getItemProps?: (item: ListOption<ID>) => RadioGroupItemProps;
}

export function RadioGroup({
  value,
  onChange,
  options,
  className,
  getItemProps,
}: Props) {
  return (
    <div className={cn('flex flex-col gap-2', className)}>
      {options.map((option) => (
        <RadioButton
          key={option.value}
          checked={value === option.value}
          onChange={() => onChange(option)}
          {...getItemProps?.(option)}
        >
          {option.label}
        </RadioButton>
      ))}
    </div>
  );
}

export const RadioGroupController = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  name,
  onChange,
  ...props
}: {
  control: Control<TFieldValues>;
  name: TName;
} & Omit<Props, 'value' | 'onChange'> &
  Partial<Pick<Props, 'onChange'>>) => {
  const { field } = useController({ name, control });
  return (
    <RadioGroup
      value={field.value}
      onChange={(o) => {
        if (onChange) {
          onChange(o);
          return;
        }
        field.onChange(o.value);
      }}
      {...props}
    />
  );
};
