import { reportManualVariablesApi as api } from '@/entities/report/manualVariable/api/settingsReportManualVariablesGeneratedApi';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiSettingsReportManualVariableTags: build.query<
      GetApiSettingsReportManualVariableTagsApiResponse,
      GetApiSettingsReportManualVariableTagsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/report/manual_variable_tags`,
        params: { query: queryArg.query },
      }),
    }),
    postApiSettingsReportManualVariableTags: build.mutation<
      PostApiSettingsReportManualVariableTagsApiResponse,
      PostApiSettingsReportManualVariableTagsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/report/manual_variable_tags`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    postApiSettingsReportManualVariableTagsSync: build.mutation<
      PostApiSettingsReportManualVariableTagsSyncApiResponse,
      PostApiSettingsReportManualVariableTagsSyncApiArg
    >({
      query: () => ({
        url: `/api/settings/report/manual_variable_tags/sync`,
        method: 'POST',
      }),
    }),
    putApiSettingsReportManualVariableTagsById: build.mutation<
      PutApiSettingsReportManualVariableTagsByIdApiResponse,
      PutApiSettingsReportManualVariableTagsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/report/manual_variable_tags/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    deleteApiSettingsReportManualVariableTagsById: build.mutation<
      DeleteApiSettingsReportManualVariableTagsByIdApiResponse,
      DeleteApiSettingsReportManualVariableTagsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/report/manual_variable_tags/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as reportManualVariableTagsApi };
export type GetApiSettingsReportManualVariableTagsApiResponse =
  /** status 200 List of Manual Variable Tags */ {
    items?: ReportManualVariableTagDto[];
    meta?: {
      systemSync?: ReportCustomerBridgeSyncDto | null;
    };
  };
export type GetApiSettingsReportManualVariableTagsApiArg = {
  /** Search by name */
  query?: string;
};
export type PostApiSettingsReportManualVariableTagsApiResponse = unknown;
export type PostApiSettingsReportManualVariableTagsApiArg = {
  body: {
    name: string;
    kind: string;
  };
};
export type PostApiSettingsReportManualVariableTagsSyncApiResponse =
  /** status 200 success */ undefined;
export type PostApiSettingsReportManualVariableTagsSyncApiArg = void;
export type PutApiSettingsReportManualVariableTagsByIdApiResponse = unknown;
export type PutApiSettingsReportManualVariableTagsByIdApiArg = {
  id: string;
  body: {
    name: string;
    kind: string;
  };
};
export type DeleteApiSettingsReportManualVariableTagsByIdApiResponse = unknown;
export type DeleteApiSettingsReportManualVariableTagsByIdApiArg = {
  id: string;
};
export type ReportManualVariableTagDto = {
  id: string;
  name: string;
  kind: 'system' | 'other';
};
export type ReportCustomerBridgeSyncDto = {
  id: string;
  status: 'in_progress' | 'completed' | 'failed';
  kind:
    | 'report_financial_category'
    | 'report_system_manual_variables'
    | 'reckoner_system_formulas';
  memos: string[];
  syncedAt: number | null;
  createdAt: number;
  initiator: {
    id: string;
    name: string;
  };
};
export type ForbiddenDto = {
  errors?: string[];
};
