import { cn } from '@/shared/lib/css/cn';
import EmailMessageSnapshotModal from 'bundles/UserManagement/components/EmailMessageSnapshotModal';
import http from 'lib/http';
import { useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { Icon } from 'stories/Icon/Icon';
import { IconButton } from 'stories/IconButton/IconButton';
import { LinkButton } from 'stories/LinkButton/LinkButton';
import {
  filterComplainedMessages,
  filterUnsubscribedMessages,
  getFailedEmailMsgs,
} from '@/bundles/Settings/components/EmailSettings/utils/utils';
import CampaignCardEmailList from '@/bundles/Settings/components/EmailSettings/EmailCampaign/CampaignEmailCard/CampaignCardEmailList';
import CampaignCardEmailListModal from '@/bundles/Settings/components/EmailSettings/EmailCampaign/CampaignEmailCard/CampaignCardEmailListModal';
import DeliveredEmailsList from '@/bundles/Settings/components/EmailSettings/EmailCampaign/CampaignEmailCard/DeliveredEmailList';
import OpenedEmailsList from '@/bundles/Settings/components/EmailSettings/EmailCampaign/CampaignEmailCard/OpenedEmailList';
import {
  MESSAGE_NAME,
  MESSAGE_STATUS,
} from '@/bundles/Settings/components/EmailSettings/EmailCampaign/CampaignEmailCard/dictionary';

const EmailCounter = ({ count, totalRecipientsCount, onClick }) => {
  const text = `${count} of ${totalRecipientsCount}`;

  if (count > 0) {
    return (
      <span role="link" className="sre-link cursor-pointer" onClick={onClick}>
        {text}
      </span>
    );
  }

  return <span>{text}</span>;
};

const AdditionalEmailActions = ({
  unsubscribedMessages,
  complainedMessages,
}) => {
  if (unsubscribedMessages + complainedMessages === 0) return null;

  return (
    <>
      <Icon className="w-4 text-light-60" iconName="arrowRightAlt" />
      <span className="inline-regular text-light-60">
        {unsubscribedMessages > 0 && MESSAGE_NAME.unsubscribed}
        {complainedMessages > 0 && MESSAGE_NAME.complained}
      </span>
    </>
  );
};

const StatsRow = ({
  title,
  titleColor,
  completedTitleColor,
  campaignTypeTitle,
  campaignTypeColor,
  campaignTypeIcon,
  createdAtDate,
  createdAtTime,
  iconComponent,
  completedCount,
  deliveredEmails,
  openedEmails,
  totalRecipientsCount,
  modalContent,
  type,
  hasAccordion,
}) => {
  const [listOpened, setListOpened] = useState(false);
  const [
    IsUnsubscribedComplainedAccordOpened,
    setIsUnsubscribedComplainedAccordOpened,
  ] = useState(false);
  const deliveredUnsubscribedMessages = deliveredEmails
    ? filterUnsubscribedMessages(deliveredEmails).length
    : 0;
  const deliveredComplainedMessages = deliveredEmails
    ? filterComplainedMessages(deliveredEmails).length
    : 0;
  const openedUnsubscribedMessages = openedEmails
    ? filterUnsubscribedMessages(openedEmails).length
    : 0;
  const openedComplainedMessages = openedEmails
    ? filterComplainedMessages(openedEmails).length
    : 0;

  return (
    <div
      className={cn(
        'transition-03 rounded-[8px] border border-transparent data-[opened=true]:border-neutral-200',
        hasAccordion ? 'mb-xs' : 'mb-s',
      )}
      data-opened={hasAccordion && IsUnsubscribedComplainedAccordOpened}
    >
      <div
        className={cn('flex items-center gap-s pl-s', {
          'py-xs pr-m': hasAccordion,
          'pr-m': !hasAccordion && completedCount === 0 && type === 'grouped',
        })}
      >
        <div className="flex">{iconComponent}</div>
        <div className="flex flex-grow items-center gap-s">
          <span
            className={cn(
              'inline-semibold',
              completedCount === totalRecipientsCount
                ? completedTitleColor
                : titleColor,
            )}
          >
            {title}
          </span>
          {hasAccordion &&
            (title === MESSAGE_STATUS.delivered ||
              title === MESSAGE_STATUS.opened) && (
              <IconButton
                iconName={
                  IsUnsubscribedComplainedAccordOpened ? 'top' : 'bottom'
                }
                onClick={() =>
                  setIsUnsubscribedComplainedAccordOpened(
                    !IsUnsubscribedComplainedAccordOpened,
                  )
                }
              />
            )}
          {type === 'personal' && title === MESSAGE_STATUS.delivered && (
            <AdditionalEmailActions
              unsubscribedMessages={deliveredUnsubscribedMessages}
              complainedMessages={deliveredComplainedMessages}
            />
          )}
          {type === 'personal' && title === MESSAGE_STATUS.opened && (
            <AdditionalEmailActions
              unsubscribedMessages={openedUnsubscribedMessages}
              complainedMessages={openedComplainedMessages}
            />
          )}
        </div>
        {type === 'grouped' && (
          <div className="flex justify-end">
            <EmailCounter
              count={completedCount}
              totalRecipientsCount={totalRecipientsCount}
              onClick={() => setListOpened(true)}
            />
          </div>
        )}
      </div>
      {hasAccordion && (
        <AnimateHeight
          duration={300}
          height={IsUnsubscribedComplainedAccordOpened ? 'auto' : 0}
        >
          <div className="rounded-bottom-s bg-light py-s pl-xl pr-m">
            <div className="w-100 light-60 flex flex-col gap-xs">
              <div className="flex justify-between gap-m">
                <span>{MESSAGE_NAME.unsubscribed}</span>
                <span>
                  {deliveredUnsubscribedMessages || openedUnsubscribedMessages}
                </span>
              </div>
              <div className="flex justify-between gap-m">
                <span>{MESSAGE_NAME.complained}</span>
                <span>
                  {deliveredComplainedMessages || openedComplainedMessages}
                </span>
              </div>
            </div>
          </div>
        </AnimateHeight>
      )}
      {listOpened && (
        <CampaignCardEmailListModal
          title={campaignTypeTitle}
          color={campaignTypeColor}
          iconClass={campaignTypeIcon}
          date={createdAtDate}
          time={createdAtTime}
          onClose={() => setListOpened(false)}
        >
          {modalContent}
        </CampaignCardEmailListModal>
      )}
    </div>
  );
};

const CampaignEmailCardStats = ({
  createdAtDate,
  createdAtTime,
  campaignTypeTitle,
  campaignTypeColor,
  campaignTypeIcon,
  totalRecipientsCount,
  emailMessages,
  type,
}) => {
  const sentEmails = emailMessages.filter((em) =>
    em.events.some((e) => e.kind === 'sent'),
  );
  const deliveredEmails = emailMessages.filter((em) =>
    em.events.some((e) => e.kind === 'delivered'),
  );
  const openedEmails = emailMessages.filter((em) =>
    em.events.some((e) => e.kind === 'opened'),
  );
  const failedEmails = getFailedEmailMsgs(emailMessages);

  const allSent = sentEmails.length === totalRecipientsCount;
  const allDelivered = deliveredEmails.length === totalRecipientsCount;
  const allOpened = openedEmails.length === totalRecipientsCount;

  const Check = () => <Icon iconName="check green" />;
  const Bullet = () => <span className="blue ml-xs mr-xs text-[1rem]">•</span>;
  const [isSnapshotOpened, setIsSnapshotOpened] = useState(false);

  const closeSnapshot = () => setIsSnapshotOpened(false);
  const fetchEmailMessageSnapshot = () =>
    http.get(
      `/user_email_messages/${emailMessages[0].id}/snapshots/${emailMessages[0].snapshotId}`,
    );
  const groupedType = type === 'grouped';
  return (
    <div
      className={cn('inline-regular inline-regular flex flex-col', {
        'pr-m': !groupedType,
      })}
    >
      {type === 'personal' && (
        <div className="mb-m flex pl-s">
          <Icon
            iconName="email"
            className="light-60 mr-s"
            backgroundColor="var(--light-10)"
            padding="xs"
          />
          <LinkButton onClick={() => setIsSnapshotOpened(true)}>
            View email
          </LinkButton>
        </div>
      )}
      <StatsRow
        hasAccordion={groupedType}
        title="Sent"
        titleColor="light-60"
        completedTitleColor="green"
        campaignTypeTitle={campaignTypeTitle}
        campaignTypeColor={campaignTypeColor}
        campaignTypeIcon={campaignTypeIcon}
        createdAtDate={createdAtDate}
        createdAtTime={createdAtTime}
        completedCount={sentEmails.length}
        totalRecipientsCount={totalRecipientsCount}
        completedColor="green"
        iconComponent={allSent ? <Check /> : <Bullet />}
        modalContent={
          <CampaignCardEmailList
            emailMessages={sentEmails}
            title="Sent"
            totalRecipientsCount={totalRecipientsCount}
          />
        }
        type={type}
      />
      <StatsRow
        hasAccordion={groupedType && deliveredEmails.length > 0}
        title="Delivered"
        titleColor="light-60"
        completedTitleColor="green"
        campaignTypeTitle={campaignTypeTitle}
        campaignTypeColor={campaignTypeColor}
        campaignTypeIcon={campaignTypeIcon}
        createdAtDate={createdAtDate}
        createdAtTime={createdAtTime}
        completedCount={deliveredEmails.length}
        deliveredEmails={deliveredEmails}
        totalRecipientsCount={totalRecipientsCount}
        iconComponent={allDelivered ? <Check /> : <Bullet />}
        modalContent={
          <DeliveredEmailsList
            deliveredEmails={deliveredEmails}
            totalRecipientsCount={totalRecipientsCount}
          />
        }
        type={type}
      />
      <StatsRow
        hasAccordion={groupedType && openedEmails.length > 0}
        title="Opened"
        openedEmails={openedEmails}
        titleColor="light-60"
        completedTitleColor="green"
        campaignTypeTitle={campaignTypeTitle}
        campaignTypeColor={campaignTypeColor}
        campaignTypeIcon={campaignTypeIcon}
        createdAtDate={createdAtDate}
        createdAtTime={createdAtTime}
        completedCount={openedEmails.length}
        totalRecipientsCount={totalRecipientsCount}
        iconComponent={allOpened ? <Check /> : <Bullet />}
        modalContent={
          <OpenedEmailsList
            openedEmails={openedEmails}
            totalRecipientsCount={totalRecipientsCount}
          />
        }
        type={type}
      />
      {failedEmails.length > 0 && (
        <StatsRow
          hasAccordion={groupedType}
          title={MESSAGE_STATUS.failed}
          titleColor="red"
          completedTitleColor="red"
          campaignTypeTitle={campaignTypeTitle}
          campaignTypeColor={campaignTypeColor}
          campaignTypeIcon={campaignTypeIcon}
          createdAtDate={createdAtDate}
          createdAtTime={createdAtTime}
          completedCount={failedEmails.length}
          totalRecipientsCount={totalRecipientsCount}
          iconComponent={<Icon iconName="closeSmall red mr-s" />}
          modalContent={
            <CampaignCardEmailList
              emailMessages={failedEmails}
              title={MESSAGE_STATUS.failed}
              totalRecipientsCount={totalRecipientsCount}
            />
          }
          type={type}
        />
      )}

      {isSnapshotOpened && (
        <EmailMessageSnapshotModal
          emailMessageId={emailMessages[0].id}
          snapshotId={emailMessages[0].snapshotId}
          fetchSnapshot={fetchEmailMessageSnapshot}
          subject={emailMessages[0].subject}
          onClose={closeSnapshot}
        />
      )}
    </div>
  );
};

export default CampaignEmailCardStats;
