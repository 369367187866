import { selectInvoiceLineItem } from 'bundles/Construction/reducers/reconcileInvoiceStateSlice';
import { useAppDispatch } from '@/shared/lib/hooks/redux';
import React, { FC } from 'react';
import { CurrencyFormatter } from 'stories/ValueFormatters/CurrencyFormatter';
import { IconButton } from 'stories/IconButton/IconButton';
import { Checkbox } from 'stories/Checkbox/Checkbox';
import { Icon } from 'stories/Icon/Icon';
import { InvoiceLineItem } from '@/shared/types/reconcile/Invoice';
import CategoryLineItem from '@/bundles/DrawPackage/InvoicesFlow/Invoices/invoice/CategoryLineItem';
import { Card } from 'bundles/DrawPackage/InvoicesFlow/Invoices/layouts/Card';

interface ILineItemCardProps extends InvoiceLineItem {
  idx?: number;
  maxAmount?: number;
  onRemove?: (idx: number) => void;
  selectFlow?: boolean;
  selected?: boolean;
  inRequisition?: boolean;
}

const LineItemCard: FC<ILineItemCardProps> = ({
  amount,
  description,
  developmentCategory,
  onRemove,
  idx,
  selectFlow,
  selected,
  maxAmount,
  inRequisition,
}) => {
  const dispatch = useAppDispatch();

  const selectLineItem = (idxSelect: number) => {
    dispatch(selectInvoiceLineItem(idxSelect));
  };

  return (
    <Card>
      {selectFlow && (
        <div>
          <Checkbox
            checked={Boolean(selected)}
            disabled={!selected && amount > maxAmount}
            onChange={() => selectLineItem(idx)}
          />
        </div>
      )}
      <div className="flex flex-1 flex-col gap-2">
        <div className="flex justify-between">
          <div className="inline-semibold text-dark-60">
            {developmentCategory.name}
          </div>
          <CurrencyFormatter
            value={amount}
            classes={{
              value: 'inline-semibold text-dark-60',
            }}
          />
        </div>
        <div>
          <CategoryLineItem item={{ developmentCategory }} />
        </div>
        <div className="inline-regular text-light-90">{description}</div>
      </div>
      {inRequisition && selectFlow && (
        <div className="px-1">
          <Icon className="text-light-30" iconName="link" />
        </div>
      )}
      {onRemove && !inRequisition && (
        <div>
          <IconButton iconName="closeSmall" onClick={() => onRemove(idx)} />
        </div>
      )}
    </Card>
  );
};

export default LineItemCard;
