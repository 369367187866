import { WidgetSettingsListLayout } from '@/bundles/Shared/components/layouts/dashboard/WidgetLayout';
import type { ComparisonDashboardSectionTypes } from 'bundles/Shared/entities/dashboard/model/types/types';
import { UNGROUPED_GROUP_KEY } from 'bundles/Shared/entities/dashboard/model/slices/shared';
import {
  getFinancialTableWidgetDefaultUngroupedGroup,
  useDragEndResponder,
  useFinancialTableVizConfig,
  useRenderGroupCallback,
} from '@/bundles/Shared/widgets/dashboard/widgets/common/financialTable/ui/financialTableWidgetColumnGroups/lib';
import { FinancialTableWidgetActions } from '@/bundles/Shared/widgets/dashboard/widgets/common/financialTable/ui/financialTableWidgetColumnGroups/model';
import { useWidgetConfig } from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/config';
import { GROUPS_DROPPABLE_ID } from '@/bundles/Shared/widgets/dashboard/widgets/kpiTable';
import { Button } from '@/stories/Button/Button';
import { Dropdown } from '@/stories/Dropdown/Dropdown';
import { DropdownItem } from '@/stories/Dropdown/DropdownItem/DropdownItem';
import { DragDropContext, Droppable } from '@hello-pangea/dnd';
import pluralize from 'pluralize';

export const FinancialTableWidgetColumnGroups = ({
  actions,
}: {
  actions: FinancialTableWidgetActions;
}) => {
  const { widget } = useWidgetConfig<ComparisonDashboardSectionTypes>();

  const { columns, viz_config } = widget.widgetConfig;

  const vizConfigGroups = useFinancialTableVizConfig({
    columns,
    vizConfig: viz_config,
  });

  const handleSortEnd = useDragEndResponder();
  const renderGroup = useRenderGroupCallback(actions);

  const allColumnsChecked = !viz_config?.columns?.some((c) => c.hidden);
  const allUngroupedChecked = !vizConfigGroups.groupedByGroupId[
    UNGROUPED_GROUP_KEY
  ]?.some((c) => c.hidden);
  const allShownColumnsLen =
    viz_config?.columns?.filter((c) => !c.hidden).length ?? 0;
  const vizConfigColumnLen = viz_config?.columns?.length ?? 0;

  return (
    <WidgetSettingsListLayout>
      <WidgetSettingsListLayout.Header
        checked={allColumnsChecked}
        onChange={(e) => actions.column.onHideAll(e.target.checked)}
        label={
          vizConfigColumnLen === 0
            ? 'No Columns Yet'
            : `${allShownColumnsLen} of ${vizConfigColumnLen} ${pluralize(
                'COLUMN',
                vizConfigColumnLen,
              )}`
        }
      >
        <Dropdown
          className="sre-dropdown-v2"
          items={
            <>
              {
                <DropdownItem
                  onClick={() => {
                    actions.group.onAdd();
                  }}
                >
                  Group
                </DropdownItem>
              }
              <DropdownItem
                onClick={() => {
                  actions.column.onAdd({
                    groupId: null,
                  });
                }}
              >
                Column
              </DropdownItem>
            </>
          }
        >
          <Button
            size="xs"
            variant="secondary"
            iconPosition="right"
            iconName="bottom"
          >
            Add
          </Button>
        </Dropdown>
      </WidgetSettingsListLayout.Header>

      <DragDropContext onDragEnd={handleSortEnd}>
        {renderGroup(
          getFinancialTableWidgetDefaultUngroupedGroup({
            hidden: !allUngroupedChecked,
            children:
              vizConfigGroups.groupedByGroupId[UNGROUPED_GROUP_KEY] ?? [],
          }),
          0,
        )}
        <Droppable type="groups" droppableId={GROUPS_DROPPABLE_ID}>
          {(provided) => (
            <div
              className="flex flex-col gap-2"
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {vizConfigGroups.columnGroups.map((g, idx) =>
                renderGroup(g, idx),
              )}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </WidgetSettingsListLayout>
  );
};
