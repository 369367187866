import { useGetApiSettingsReportDashboardsQuery } from '@/shared/api/dashboardSettingsEnhancedApi';
import { DashboardWidgetSectionDto } from '@/shared/api/dashboardsSettingsGeneratedApi';
import { orderBy } from 'lodash-es';

export const NO_BOARD_ID = 'board-none';

export const useReportSettingsDashboardByIdOrSlug = (
  dashboardIdOrSlug: string,
) => {
  const { data, isLoading, isFetching } =
    useGetApiSettingsReportDashboardsQuery();
  return {
    data: data?.find(
      (d) => d.id === dashboardIdOrSlug || d.slug === dashboardIdOrSlug,
    ),
    isLoading,
    isFetching,
  };
};

export const orderDashboardSections = <
  S extends Pick<DashboardWidgetSectionDto, 'position'>,
>(
  sections: S[],
) => {
  return orderBy(
    sections,
    [(section) => section.position.y, (section) => section.position.x],
    ['asc', 'asc'],
  );
};
