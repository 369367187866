import React from 'react';
import { InvestmentEntityDto } from '@/shared/api/investmentEntityGeneratedApi';
import { usePutApiInvestmentEntitiesByIdMutation } from '@/shared/api/investmentEntityEnhancedApi';
import { SectionField } from '@/stories/Field/FieldsWrappers';
import { Button } from '@/stories/Button/Button';
import { Input } from '@/stories/FormControls/Inputs/Input/Input';
import { Select } from '@/stories/FormControls/Select/Select';
import { HorizontalEditableField } from '@/stories/Field/HorizontalEditableField';
import { useBoolean } from 'usehooks-ts';
import { useForm } from 'react-hook-form';
import { cn } from '@/shared/lib/css/cn';
import { DEFAULT_STRING_FALLBACK } from '@/shared/lib/formatting/fallbacks';

const TAX_TYPES = [
  { id: 'ssn', label: 'SSN' },
  { id: 'ein', label: 'EIN' },
];

export const TaxDetailsTab = ({ item }: { item: InvestmentEntityDto }) => {
  const {
    value: isEditing,
    setTrue: startEditing,
    setFalse: stopEditing,
  } = useBoolean(false);

  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { isValid },
  } = useForm({
    defaultValues: {
      type: item?.taxDetails?.type ?? '',
      number: item?.taxDetails?.number ?? '',
    },
    mode: 'onChange',
  });

  const formValues = watch();

  const [updateInvestmentEntity, { isLoading: isUpdating }] =
    usePutApiInvestmentEntitiesByIdMutation();

  const handleCancel = () => {
    reset();
    stopEditing();
  };

  const handleSave = handleSubmit(async (data) => {
    const result = await updateInvestmentEntity({
      id: String(item.id),
      body: {
        investment_entity: {
          tax_details_attributes: {
            type: data.type,
            number: data.number,
          },
        },
      },
    });

    if ('data' in result) {
      stopEditing();
    }
  });

  return (
    <SectionField
      labelText="Tax Details"
      button={
        isEditing ? (
          <div className="flex gap-2">
            <Button size="xs" variant="secondary" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              size="xs"
              variant="success"
              onClick={handleSave}
              loading={isUpdating}
              disabled={!isValid}
            >
              Save Changes
            </Button>
          </div>
        ) : (
          <Button size="xs" variant="secondary" onClick={startEditing}>
            Change
          </Button>
        )
      }
    >
      <div className="-mx-4 divide-y divide-solid divide-neutral-200">
        <HorizontalEditableField
          label="Type"
          value={formValues.type || DEFAULT_STRING_FALLBACK}
          editable={isEditing}
          className={cn(isEditing ? 'py-2' : 'py-4')}
          required={isEditing}
        >
          <Select
            spanContainer="w-full"
            onSelectedChange={(value) => {
              if (value && 'label' in value) {
                setValue('type', value.id);
              }
            }}
            selected={TAX_TYPES.find((option) => option.id === formValues.type)}
            options={TAX_TYPES}
            placeholder="Select Tax Type"
          />
        </HorizontalEditableField>

        <HorizontalEditableField
          label="Tax Number"
          value={formValues.number || DEFAULT_STRING_FALLBACK}
          editable={isEditing}
          className={cn(isEditing ? 'py-2' : 'py-4')}
          required={isEditing}
        >
          <Input
            {...register('number', { required: true })}
            placeholder="Enter Tax Number"
          />
        </HorizontalEditableField>
      </div>
    </SectionField>
  );
};
