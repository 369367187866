import { ReturnInvestmentObjectTab } from '@/bundles/REturn/components/Home/investmentObjectDetailsModal/utils';
import { generateUrl, ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import { useNavigate } from '@reach/router';
import VerticalPagination from 'bundles/REturn/components/Ownership/modals/manageCapitalInvestmentModal/VerticalPagination';
import { IconButton } from 'stories/IconButton/IconButton';

interface Props {
  investmentObjectIds: string[];
  id: string;
  onClose: VoidFunction;
  currentTab?: ReturnInvestmentObjectTab;
}

function InvestmentObjectDetailsHeader({
  investmentObjectIds,
  id,
  onClose,
  currentTab,
}: Props) {
  const navigate = useNavigate();

  const currentIndex = investmentObjectIds.findIndex((idx) => idx === id);
  const paginationHandler = (detailsId: string) => () =>
    navigate(
      generateUrl(ROUTES_ROOT.return.fullPath, {
        queryParams: { detailsId, tab: currentTab },
        pathParams: {},
      }),
    );
  const handleNext = paginationHandler(investmentObjectIds[currentIndex + 1]);
  const handlePrev = paginationHandler(investmentObjectIds[currentIndex - 1]);

  return (
    <div className="flex items-center">
      <VerticalPagination
        current={currentIndex + 1}
        total={investmentObjectIds.length}
        onNext={handleNext}
        onPrev={handlePrev}
      />
      <IconButton
        iconName="close"
        size="l"
        onClick={onClose}
        variant="secondary"
      />
    </div>
  );
}

export default InvestmentObjectDetailsHeader;
