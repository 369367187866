import React, { useState } from 'react';
import dayjs from 'dayjs';
import { LinkButton } from 'stories/LinkButton/LinkButton';
import { cn } from '@/shared/lib/css/cn';
import http from 'lib/http';
import EmailMessageSnapshotModal from '@/bundles/UserManagement/components/EmailMessageSnapshotModal';

const TripleMarksStatus = ({ status }) => {
  const isOpened = status === 'opened';
  const isDelivered = isOpened || status === 'delivered';
  const isSent = isDelivered || isOpened || status === 'sent';

  return (
    <div className="flex flex-col position-relative text-[1rem]">
      <i
        className={cn('sre-icon-check position-absolute light-20', {
          green: isOpened,
        })}
        style={{ top: '-0.9rem' }}
      />
      <i
        className={cn('sre-icon-check position-absolute light-20', {
          green: isDelivered,
        })}
        style={{ top: '-0.45rem' }}
      />
      <i
        className={cn('sre-icon-check position-absolute light-20', {
          green: isSent,
        })}
      />
    </div>
  );
};

const EmailMessageTimelineActivity = ({
  emailMessageId,
  snapshotId,
  isFirst,
  isLast,
  status,
  subject,
  senderName,
  sentAt,
  recipientName,
}) => {
  const [displayEmailId, setDisplayEmailId] = useState();

  const sentDaysAgo = dayjs.unix(sentAt).fromNow();
  const from = senderName || 'Someone';
  const isSnapshotOpen = displayEmailId != null;
  const closeSnapshot = () => setDisplayEmailId(null);
  const statusLabel = (s) => (s === 'opened' ? 'Seen' : s);
  const fetchEmailMessageSnapshot = () =>
    http.get(`/user_email_messages/${emailMessageId}/snapshots/${snapshotId}`);

  return (
    <div>
      {isSnapshotOpen && (
        <EmailMessageSnapshotModal
          emailMessageId={emailMessageId}
          snapshotId={snapshotId}
          fetchSnapshot={fetchEmailMessageSnapshot}
          subject={subject}
          onClose={closeSnapshot}
        />
      )}

      <div className="flex">
        <div
          className={cn('activity-log-timeline__item-container', {
            'activity-log-timeline__last-item': isLast,
          })}
        >
          {!isFirst && <div className="activity-log-timeline__line_top" />}
          <div
            className={cn('activity-log-timeline__dot', {
              'mt-[0.25rem]': isFirst,
            })}
          />
        </div>
        <div
          className={cn('flex flex-grow flex-col ml-4', { 'mt-4': !isFirst })}
        >
          <span className="lh-[1rem] secondary-regular">{sentDaysAgo}</span>
          <p className="secondary-regular dark-60 mt-[0.25rem]">
            <b className="secondary-semibold">{from}</b> sent an email to &nbsp;
            <b className="secondary-semibold">{recipientName}:</b>
          </p>
          <p className="inline-regular dark-60 mt-[0.25rem]">{subject}</p>
          <div className="flex items-center mt-[0.5rem]">
            {snapshotId && (
              <LinkButton
                onClick={() => setDisplayEmailId(emailMessageId)}
                fontWeight="bold"
                className="secondary-regular bg-light-5 border-radius-[0.5rem] activity-log-timeline__view-email-button"
              >
                View Email
              </LinkButton>
            )}
            <div className={cn({ 'ml-4': snapshotId })}>
              <TripleMarksStatus status={status} />
            </div>
            <span className="ml-[1.5rem] secondary-regular secondary-regular light-60 capitalize-first-letter">
              {statusLabel(status)}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailMessageTimelineActivity;
