import selectIEimage from 'images/return/createTransferFlow/select-investment-entity-row.png';
export const HINT_IMG_ALT_TEXT = 'hint for selecting a row from table';

export const SELECT_INVESTMENT_TEXT_JSX = (
  <p className="body-semibold text-dark-60">
    Select Investment Entity <span className="inline-semibold text-red">*</span>
  </p>
);

export const SELECT_CLASS_TO_TRANSFER_JSX = (
  <p className="body-semibold text-dark-60">
    Select Class to transfer <span className="inline-semibold text-red">*</span>
  </p>
);

export const AMOUNT_TO_TRANSFER_JSX = (
  <p className="body-semibold text-dark-60">
    Amount to transfer <span className="inline-semibold text-red">*</span>
  </p>
);

export const TRANSFER_DATE_JSX = (
  <p className="body-semibold text-dark-60">
    Transfer date <span className="inline-semibold text-red">*</span>
  </p>
);

export const CONTRIBUTION_DATE_JSX = (
  <p className="body-semibold text-dark-60">
    Unit Price Date <span className="inline-semibold text-red">*</span>
  </p>
);

export const FIRST_STEP_HINT_INFO_JSX = (
  <>
    <div className="flex flex-col gap-1.5">
      {SELECT_INVESTMENT_TEXT_JSX}

      <p className="inline-regular text-light-70">
        To transfer ownership, first select the investment entity that is
        selling ownership
      </p>
    </div>
    <div className="flex justify-center p-4">
      <img
        src={selectIEimage}
        alt={HINT_IMG_ALT_TEXT}
        width={188}
        height={188}
      />
    </div>
  </>
);
