import {
  FETCH_CONSTRUCTION_SNAPSHOT,
  FETCH_CONSTRUCTION_REPORT,
  FETCH_CHANGE_ORDER_EVENT_BADGES,
} from '@/bundles/Construction/actions';

const initialState = null;

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_CONSTRUCTION_SNAPSHOT:
      return action.payload;
    case FETCH_CONSTRUCTION_REPORT:
      return { ...state, report: action.payload };
    case FETCH_CHANGE_ORDER_EVENT_BADGES:
      return { ...state, badgesinfo: action.payload };
    default:
      return state;
  }
}
