import {
  AgGridEvent,
  CellClickedEvent,
  CellRendererSelectorFunc,
  ColDef,
  ColGroupDef,
  GridApi,
  RowGroupOpenedEvent,
  RowNode,
} from 'ag-grid-community';
import { AUTO_GROUP_COLUMN_KEY } from 'lib/ag-grid/constants';
import { sum, tail } from 'lodash-es';
import { useEffect, useRef } from 'react';
import { AgGridReactRef } from '@/lib/ag-grid/types';
export const expandAllChildren = (n: RowNode) => {
  n.allLeafChildren
    .filter((nx) => nx.hasChildren())
    .forEach((nx) => {
      nx.setExpanded(!n.expanded);
    });
};

export const toggleAllRowChildren = (n: RowNode, expand: boolean) => {
  n.allLeafChildren
    .filter((nx) => nx.hasChildren())
    .forEach((nx) => {
      nx.setExpanded(expand);
    });
};

export const expandAllGroupChildren = (n: RowNode, expand: boolean) => {
  tail(n.allLeafChildren)
    .filter((nx) => nx.hasChildren())
    .forEach((nx) => {
      nx.setExpanded(expand);
    });
};

export const expandOnClickHandler = (e: CellClickedEvent) => {
  if (e.node.hasChildren() && !e.column.isCellEditable(e.node)) {
    e.node.setExpanded(!e.node.expanded);
  }
};

export const autoSizeColumnsHandler = (e: AgGridEvent, colIds: string[]) => {
  e.api.autoSizeColumns(colIds);
};

export const autoSizeGroupColumnHandler = (e: RowGroupOpenedEvent) => {
  e.api.autoSizeColumn(AUTO_GROUP_COLUMN_KEY);
};

export const handleSizeColumnsToFit = (e: AgGridEvent, width: number) => {
  const allColumnsWidthSum = sum(
    e.api.getColumns()?.map((c) => c.getActualWidth()),
  );

  if (allColumnsWidthSum < width) {
    e.api.sizeColumnsToFit(width - 2);
  }
};

export const redrawNodeOnExpandHandler = (e: RowGroupOpenedEvent) => {
  e.api.redrawRows({
    rowNodes: [e.node],
  });
};

export const clearFocusedCellAndRange = (api: GridApi) => {
  api.clearFocusedCell();
  api.clearRangeSelection();
};

export const isNodeTotalFooter = (node: RowNode) =>
  node.footer && node.level === -1;

export const isGroupColDef = (
  colDef: ColDef | ColGroupDef,
): colDef is ColGroupDef => 'children' in colDef;

const EmptyCellRenderer = () => null;
export const getCellRendererSelectorWithHideTotals =
  (cellRendererSelector: CellRendererSelectorFunc): CellRendererSelectorFunc =>
  (params) => {
    // dont render anything when group expanded
    if (!params.node.footer && params.node.aggData && params.node.expanded) {
      return {
        component: EmptyCellRenderer,
      };
    }
    return cellRendererSelector(params);
  };

export const useLoadingOverlayEffect = ({
  grid,
  isLoading,
}: {
  grid: AgGridReactRef | null;
  isLoading?: boolean;
}) =>
  useEffect(() => {
    if (isLoading) {
      setTimeout(() => {
        grid?.api?.showLoadingOverlay();
      });
    } else {
      grid?.api?.hideOverlay();
    }
  }, [isLoading]);

export const useAgGridRef = () => {
  const gridRef = useRef<AgGridReactRef>(null);

  return gridRef;
};

export const isRootNodeFooter = (node: RowNode) =>
  node.level === -1 && node.footer;

export const getNodeFooterParent = (node: RowNode) => {
  const [firstChild] = node.childrenAfterGroup ?? [];
  return firstChild?.parent;
};
