import { cn } from '@/shared/lib/css/cn';
import PropTypes from 'prop-types';
import { Tooltip } from 'stories/Tooltip/Tooltip';

const RenderObjectAccessInput = ({
  rule,
  objectName,
  objectKey,
  disabled,
  disableLabel,
  checked,
  changeHandler,
}) => {
  const htmlId = `can-${rule}-${objectName}-${objectKey}`;

  return (
    <Tooltip hiddenArrow disabled={!disableLabel} mainText={disableLabel}>
      <input
        id={htmlId}
        type="checkbox"
        className="custom-checkbox"
        checked={checked}
        onChange={disabled ? undefined : changeHandler}
        readOnly={disabled || !changeHandler}
      />
      <label
        htmlFor={htmlId}
        className={cn('pt-[0.25rem]', { 'custom-disabled-checkbox': disabled })}
      />
    </Tooltip>
  );
};
RenderObjectAccessInput.propTypes = {
  rule: PropTypes.string.isRequired,
  objectName: PropTypes.string.isRequired,
  objectKey: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  disableLabel: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  changeHandler: PropTypes.func,
};
RenderObjectAccessInput.defaultProps = {
  disabled: false,
  disableLabel: null,
  changeHandler: undefined,
};

const ObjectAccessForm = ({
  title,
  rules,
  objects,
  objectName,
  searchHandler,
  inputState,
}) => (
  <>
    {searchHandler && (
      <div className="position-relative mb-4">
        <input
          type="text"
          className="form-control bg-light"
          placeholder="Search"
          onChange={searchHandler}
        />
        <i className="sre-icon-search search-icon" />
      </div>
    )}
    <table className="curved-border no-margin-bottom no-border-spacing striped-table mb-4 table">
      <thead>
        <tr className="bg-light">
          <th className="light-90 font-weight-500 secondary-regular">
            Access to:
          </th>
          {rules.map((rule) => (
            <th
              key={rule}
              className="light-90 font-weight-500 secondary-regular w-[120px] text-center"
            >
              {rule}
            </th>
          ))}
        </tr>
      </thead>

      <tr>
        <td className="font-14 light-90">{title}</td>
        {rules.map((rule) => (
          <td key={rule} className="w-[120px] text-center">
            <div className="checkbox">
              <RenderObjectAccessInput
                rule={rule}
                objectName={objectName}
                objectKey="all"
                {...inputState(rule, 'all')}
              />
            </div>
          </td>
        ))}
      </tr>
    </table>
    <table className="curved-border no-margin-bottom no-border-spacing striped-table mb-4 table">
      {objects.map((obj) => (
        <tr key={obj.id}>
          <td className="font-14 light-90">{obj.name}</td>
          {rules.map((rule) => (
            <td key={`${rule}-${obj.id}`} className="w-[120px] text-center">
              <div className="checkbox">
                <RenderObjectAccessInput
                  rule={rule}
                  objectName={objectName}
                  objectKey={`${obj.id}`}
                  {...inputState(rule, obj.id)}
                />
              </div>
            </td>
          ))}
        </tr>
      ))}
      {objects.length === 0 && (
        <tr>
          <td className="text-center">Nothing found...</td>
        </tr>
      )}
    </table>
  </>
);
ObjectAccessForm.propTypes = {
  title: PropTypes.string.isRequired,
  rules: PropTypes.arrayOf(PropTypes.string).isRequired,
  objects: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  objectName: PropTypes.string.isRequired,
  searchHandler: PropTypes.func,
  inputState: PropTypes.func.isRequired,
};
ObjectAccessForm.defaultProps = {
  searchHandler: null,
};

export default ObjectAccessForm;
