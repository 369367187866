import store from '@/app/stores';
import { selectReportComparisonDashboardMetadataById } from '@/bundles/Shared/entities/dashboard/model/slices/comparisonSlice';
import { WIDGETS_CONFIG_MAP } from '@/bundles/Shared/widgets/dashboard/widgets/config';
import { mapListToIds } from '@/shared/lib/listHelpers';
import { addSectionsToLayout } from 'bundles/Shared/entities/dashboard/lib';
import { ReportDashboardType } from 'bundles/Shared/entities/dashboard/model/types/types';
import type { AllWidgetTypes } from 'bundles/Shared/entities/dashboard/model/types/types';
import { buildLayoutsId } from 'bundles/Shared/entities/dashboard/model/slices/shared';
import { mapLayoutToLayoutDto } from 'bundles/Shared/entities/dashboard/api';

import {
  usePostApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdCopyableWidgetSectionsCopyToBoardMutation,
  usePostApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdCopyableWidgetSectionsCopyToBoardMutation,
  usePostApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdCopyableWidgetSectionsCopyToBoardMutation,
} from '@/shared/api/dashboardSettingsEnhancedApi';

import { useCallback, useMemo } from 'react';
import { selectReportObjectDashboardMetadataById } from '@/bundles/Shared/entities/dashboard/model/slices/objectSlice';
import { selectReportEagleEyeDashboardSettingsById } from '@/bundles/Shared/entities/dashboard/model/slices/eagleEyeSlice';

export const useCopyWidgetSectionToBoard = ({
  dashboardType,
  boardId,
  dashboardId,
}: {
  dashboardType: ReportDashboardType;
  dashboardId: string;
  boardId: string;
}) => {
  const eagleEyeMutation =
    usePostApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdCopyableWidgetSectionsCopyToBoardMutation();
  const objectMutation =
    usePostApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdCopyableWidgetSectionsCopyToBoardMutation();
  const comparisonMutation =
    usePostApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdCopyableWidgetSectionsCopyToBoardMutation();

  const [copyToBoard, options] = useMemo(() => {
    switch (dashboardType) {
      case ReportDashboardType.OBJECT: {
        return objectMutation;
      }
      case ReportDashboardType.EAGLE_EYE: {
        return eagleEyeMutation;
      }
      case ReportDashboardType.COMPARISON_MODE: {
        return comparisonMutation;
      }
      default: {
        return [
          () => {
            throw new Error('Unsupported dashboard type');
          },
          {},
        ];
      }
    }
  }, [objectMutation, eagleEyeMutation, comparisonMutation]);

  const stateSelector = useMemo(() => {
    switch (dashboardType) {
      case ReportDashboardType.OBJECT: {
        return selectReportObjectDashboardMetadataById;
      }
      case ReportDashboardType.EAGLE_EYE: {
        return selectReportEagleEyeDashboardSettingsById;
      }
      case ReportDashboardType.COMPARISON_MODE: {
        return selectReportComparisonDashboardMetadataById;
      }
    }
  }, [
    selectReportEagleEyeDashboardSettingsById,
    selectReportEagleEyeDashboardSettingsById,
    selectReportComparisonDashboardMetadataById,
  ]);

  const handleCopyToBoard = useCallback(
    async ({
      sections,
    }: {
      sections: {
        id: string;
        widgetType: AllWidgetTypes;
      }[];
    }) => {
      const { layouts: targetBoardLayout } = stateSelector(
        store.getState(),
        buildLayoutsId({
          boardId,
          dashboardId,
        }),
      )!;
      const newLayout = addSectionsToLayout(
        targetBoardLayout!.lg,
        sections.map((s) => ({
          i: s.id,
          type: s.widgetType,
          ...WIDGETS_CONFIG_MAP[s.widgetType].layout,
        })),
      );
      return await copyToBoard({
        objectDashboardId: dashboardId,
        eagleEyeDashboardId: dashboardId,
        comparisonDashboardId: dashboardId,
        boardId,
        body: {
          board_layout: mapLayoutToLayoutDto(newLayout),
          sections_to_copy_ids: mapListToIds(sections),
        },
      });
    },
    [copyToBoard, dashboardId, boardId, stateSelector],
  );

  return [handleCopyToBoard, options] as const;
};
