import {
  selectUserActivitySummary,
  setUserActivityChangingDate,
  setUserActivityFilterRoles,
} from 'bundles/Shared/entities/user/slices/userActivitySlice';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import React, { useEffect } from 'react';
import { Button } from 'stories/Button/Button';
import { fetchUserRoles } from 'bundles/UserManagement/reducers/userRolesSlice';
import { UserRoleFilter } from 'bundles/Shared/features/user/filter/byRole';
import { IUserRole, TUserRoleCategory } from 'types/User';
import dayjs from 'dayjs';
import { UserActivityReportModal } from 'bundles/Shared/widgets/user/activity/ui/UserActivityReportModal';
import { useModal } from '@/shared/lib/hooks/useModal';
import { formatToDateStringForRequest } from '@/shared/lib/converters';
import { CalendarRangeSelector } from '@/stories/Calendar/RangeSelector/CalendarRangeSelector';

const DEFAULT_PERIOD_FILTER = [
  dayjs()
    .subtract(7 - 1, 'day')
    .startOf('day')
    .toDate(),
  dayjs().startOf('day').toDate(),
];

const UserActivityFiltersBar = () => {
  const { openModal } = useModal();
  const { fromDate, toDate, roleIds } = useAppSelector(
    selectUserActivitySummary,
  );

  const periods: Date[] = [dayjs(fromDate).toDate(), dayjs(toDate).toDate()];

  const userRolesState = useAppSelector((state) => state.userRoles);
  const { roles } = userRolesState;

  const dispatch = useAppDispatch();

  const onChangeRoles = (newRoles: IUserRole[]) => {
    dispatch(setUserActivityFilterRoles(newRoles.map((role) => role.id)));
  };

  useEffect(() => {
    if (!roles.length) {
      dispatch(fetchUserRoles());
    }
  }, []);

  return (
    <div className="flex justify-between">
      <div className="flex items-center gap-2" />
      <div className="flex items-center gap-4">
        <div>
          <CalendarRangeSelector
            defaultValue={DEFAULT_PERIOD_FILTER}
            value={periods}
            onChange={(p) => {
              dispatch(
                setUserActivityChangingDate({
                  fromDate: formatToDateStringForRequest(p[0]),
                  toDate: formatToDateStringForRequest(p[1]),
                }),
              );
            }}
          />
        </div>
        <UserRoleFilter
          roles={roles.filter(
            (role) => role.category !== TUserRoleCategory.SYMMETRE,
          )}
          roleIds={roleIds ?? []}
          onChangeRoles={onChangeRoles}
        />
        <Button
          variant="primary"
          onClick={() => {
            openModal(UserActivityReportModal);
          }}
        >
          Report
        </Button>
      </div>
    </div>
  );
};

export default UserActivityFiltersBar;
