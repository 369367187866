import React, { useMemo, useState } from 'react';
import { Button } from 'stories/Button/Button';
import { DialogProps, useModal } from '@/shared/lib/hooks/useModal';
import { IESignCoordinate } from 'bundles/Construction/types';
import { RenderPage, RenderPageProps } from '@react-pdf-viewer/core';
import FilePreview from 'bundles/Shared/components/FilePreviews/FilePreview';
import pluralize from 'pluralize';
import SignaturePlacement from 'bundles/Construction/components/eSignature/SignaturePlacement';
import ApproverCard from 'bundles/Construction/components/eSignature/ApproverCard';
import { CURSOR_DISCREPANCY } from 'bundles/Construction/components/eSignature/consts';
import { SIDEBAR_SUBHEADER_NOTE } from 'bundles/Construction/components/eSignature/dumbJSX';
import { ICOContractFile } from 'bundles/Construction/components/eSignature/types';
import customCursor from '@/bundles/Construction/components/eSignature/custom-cursor.svg';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { camelCase, uniqueId } from 'lodash-es';
import {
  getIndicatorColor,
  preparedFields,
} from 'bundles/Construction/components/eSignature/utils';
import dayjs from 'dayjs';

interface Props extends DialogProps {
  contractFile: ICOContractFile;
  onSubmit: (data: IESignCoordinate[]) => void;
  readonly: boolean;
}

const ESignPlacementModalV2 = ({
  contractFile,
  onClose,
  onSubmit,
  readonly,
}: Props) => {
  const fields = contractFile.defaultSignatureFields;

  const approvers =
    contractFile.approvers.length > 0
      ? contractFile.approvers
      : contractFile.defaultApprovers;
  const initialState =
    contractFile.approverMarkups?.map((markup) => ({
      ...markup,
      color: getIndicatorColor(markup.approverId, approvers),
    })) || [];

  const [selectedField, setSelectedField] = useState<IESignCoordinate>();
  const [coordinates, setCoordinates] =
    useState<IESignCoordinate[]>(initialState);
  const { confirm } = useModal();

  const sortedFields = useMemo(() => preparedFields(fields), []);

  const handleClose = async () => {
    if (readonly) {
      onClose();
      return;
    }

    const res = await confirm({
      title: 'Are you sure you want to quit?',
      subtitle: <p>Closing will lead to the loss of all progress</p>,
    });
    if (res) onClose();
  };

  const handlePageClick = (e, pageIndex) => {
    if (readonly || !selectedField) return;

    const rect = e.target.getBoundingClientRect();
    const x = e.clientX - rect.left - CURSOR_DISCREPANCY;
    const y = e.clientY - rect.top - CURSOR_DISCREPANCY;

    const coordinate = coordinates.find(
      (c) =>
        c.kind === selectedField.kind &&
        c.approverId === selectedField.approverId,
    );
    const coordData = {
      ...selectedField,
      x,
      y,
      pageIndex,
      pageHeight: rect.height,
      pageWidth: rect.width,
      id: uniqueId(),
    };

    if (coordinate) {
      setCoordinates(
        coordinates.map((c) =>
          c.kind === selectedField.kind &&
          c.approverId === selectedField.approverId
            ? coordData
            : c,
        ),
      );
    } else {
      setCoordinates([...coordinates, coordData]);
    }

    const fieldIndex = sortedFields.findIndex(
      (field) => field.kind === selectedField.kind,
    );
    const nextField = sortedFields[fieldIndex + 1];
    setSelectedField(
      nextField ? { ...selectedField, kind: nextField.kind } : undefined,
    );
  };

  const getValue = (coord: IESignCoordinate) => {
    const approver = approvers.find((a) => a.id === coord.approverId)!;

    if (['initials', 'signature'].includes(coord.kind))
      return approver.userName;
    if (coord.kind === 'company') return approver.companyName;
    if (coord.kind === 'date') return dayjs().format('MM-DD-YYYY');

    return approver[camelCase(coord.kind)] as string;
  };

  const fulfilled = () =>
    coordinates.filter((c) => fields.includes(c.kind)).length ===
    approvers.length * fields.length;
  const renderPage: RenderPage = (props: RenderPageProps) => (
    <div className="mb-2">
      {props.canvasLayer.children}
      {Object.values(coordinates)
        .filter((c) => c.pageIndex === props.pageIndex)
        .map((coord: IESignCoordinate) => (
          <SignaturePlacement
            key={coord.id}
            value={getValue(coord)}
            kind={coord.kind}
            color={coord.color}
            x={
              Number(coord.x) * (Number(props.width) / Number(coord.pageWidth))
            }
            y={
              Number(coord.y) *
              (Number(props.height) / Number(coord.pageHeight))
            }
            onRemove={
              readonly
                ? undefined
                : () => {
                    setCoordinates(
                      coordinates.filter((c) => c.id !== coord.id),
                    );
                  }
            }
          />
        ))}
      <div
        onClick={(e) => handlePageClick(e, props.pageIndex)}
        className="position-absolute inset-0 z-[9]"
        style={{
          cursor:
            !selectedField || fulfilled()
              ? 'auto'
              : `url(${customCursor}), auto`,
        }}
      />
      {props.annotationLayer.children}
      {props.textLayer.children}
    </div>
  );

  const requiredJobTitle = !sortedFields.find(
    (field) => field.kind === 'job_title',
  )?.optional;
  const hasJobTitles = approvers.every((approver) => approver.jobTitle);

  return (
    <FilePreview
      file={contractFile}
      handleClose={handleClose}
      customRenderPage={renderPage}
      classes={{
        fileContainer: 'w-2/3 xl:w-[calc(100%-542px)] h-screen overflow-auto',
        contentContainer: 'w-1/3 xl:w-[542px] h-screen bg-light flex flex-col',
      }}
      header={<h6 className="dark-60 header6-bold">Markup Window</h6>}
    >
      <div className="bg-white px-6 py-4">
        <div className="flex gap-4">{SIDEBAR_SUBHEADER_NOTE}</div>
      </div>
      <div className="flex-1 overflow-auto p-6">
        <div className="label-regular mb-4">
          {approvers.length} {pluralize('user', approvers.length)}
        </div>
        {approvers.map((approver, i) => (
          <ApproverCard
            key={approver.id}
            approver={approver}
            fields={sortedFields}
            selectedField={selectedField}
            coordinates={coordinates}
            color={getIndicatorColor(approver.id, approvers)}
            onFieldClick={(field) => {
              if (readonly) return;
              setSelectedField({
                ...field,
                approverId: approver.id,
              });
            }}
            requiredJobTitle={requiredJobTitle}
            onClose={onClose}
          />
        ))}
      </div>
      {!readonly && (
        <div className="bg-white px-6 pb-6 pt-4">
          <div className="light-60 label-regular mb-3 text-center">
            Allocate all e-signature items on the page to Confirm
          </div>
          <Button
            variant="success"
            onClick={() => onSubmit(coordinates)}
            disabled={!fulfilled() || (!hasJobTitles && requiredJobTitle)}
            fluid
          >
            Confirm
          </Button>
        </div>
      )}
    </FilePreview>
  );
};

export default ESignPlacementModalV2;
