import { cn } from '@/shared/lib/css/cn';

export function DashboardCardLabel({ children, className }: LayoutProps) {
  return (
    <div
      className={cn(
        'flex gap-1 !rounded-lg bg-neutral-100 px-1.5 py-1',
        className,
      )}
    >
      {children}
    </div>
  );
}
