import { startCase } from 'lodash-es';
import { CurrencyFormatter } from 'stories/ValueFormatters/CurrencyFormatter';
import { Popover } from 'stories/Popover/Popover';
import { PseudoLink } from 'stories/PseudoLink/PseudoLink';
import { EntryAmountAndKind } from '@/bundles/REturn/components/workflows/createEntry/splitModal/SplitTxBetweenKinds';

export function ReviewDistributionKinds({
  kindReviewInfo,
}: {
  kindReviewInfo: EntryAmountAndKind[];
}) {
  if (kindReviewInfo.length === 1) {
    return (
      <p className="secondary-regular text-light-60">
        {startCase(kindReviewInfo[0].kind)}
      </p>
    );
  }

  return (
    <Popover
      template={
        <div className="flex flex-col gap-2 p-2">
          <p className="secondary-regular text-light-60">
            {kindReviewInfo.length} Categories
          </p>
          {kindReviewInfo.map(({ amount, kind }) => (
            <div
              className="flex items-center justify-between gap-4 border-b border-light-10 pb-2 last:border-none last:pb-0"
              key={kind}
            >
              <p className="inline-regular text-dark-60">{startCase(kind)}</p>
              <CurrencyFormatter value={amount} />
            </div>
          ))}
        </div>
      }
    >
      <PseudoLink className="secondary-regular text-light-60">
        {kindReviewInfo.length} Categories
      </PseudoLink>
    </Popover>
  );
}
