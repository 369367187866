import { IconButton } from 'stories/IconButton/IconButton';
import { SelectedItemsWrapper } from '@/bundles/REturn/components/workflows/createEntry/selectedItems/SelectedItems';
import { ICapitalInvestment } from 'bundles/REturn/types';

// TODO rename to SelectedCapitalInvestments
export function SelectedInvEntities({
  invEntities,
  onRemoveItem,
  ...props
}: {
  totalCountLabel: string;
  invEntities: ICapitalInvestment[];
  onRemoveItem: (id: ICapitalInvestment['id']) => void;
  hideTotalInfo?: boolean;
}) {
  return (
    <SelectedItemsWrapper {...props} itemsLen={invEntities.length}>
      {invEntities.map((item) => (
        <div
          key={item.id}
          className="grid grid-cols-[1fr_max-content] bg-white"
        >
          <div className="inline-regular flex flex-col gap-2 p-4 text-dark-60">
            {item.investmentEntity.name}
          </div>
          <IconButton
            onClick={() => onRemoveItem(item.id)}
            iconName="closeSmall"
            variant="white"
            className="m-4"
          />
        </div>
      ))}
    </SelectedItemsWrapper>
  );
}
