import { formatDate, formatUnixDate } from '@/shared/lib/formatting/dates';
import { accountingNumberFormat } from '@/shared/lib/formatting/number';
import { ReturnInvestmentObjectTab } from 'bundles/REturn/components/Home/investmentObjectDetailsModal/utils';
import styles from 'bundles/REturn/components/Ownership/modals/capitalInvestmentsEditor/CapitaInvestmentEditor.module.scss';
import { IReturnInvestmentEntity } from 'bundles/REturn/types';
import Table from 'bundles/Shared/components/Table/Table';
import { IColumn } from 'bundles/Shared/components/Table/types';
import { sumBy } from 'lodash-es';
import { useMemo } from 'react';
import InlineObject from 'stories/Objects/InlineObject/InlineObject';

export interface IDetailsItem {
  investmentEntity: Pick<
    IReturnInvestmentEntity,
    'name' | 'createdBy' | 'createdAt'
  >;
  amount: number;
  date?: number;
}

interface Props {
  items: IDetailsItem[];
  tab: ReturnInvestmentObjectTab;
}

function DetailsTable({ items, tab }: Props) {
  const columns = useMemo<IColumn<IDetailsItem>[]>(
    () => [
      {
        text: 'Investment Entity',
        dataField: 'investmentEntity.name',
        headerStyle: {
          maxWidth: '60%',
        },
        formatter: ({ row }) => (
          <div className="flex flex-col items-start gap-2">
            <InlineObject iconName="bag" object={row.investmentEntity.name} />
            {row.investmentEntity.createdBy && (
              <span className="secondary-regular flex gap-s">
                <span className="light-60">Added by</span>
                <span className="dark-60">
                  {row.investmentEntity.createdBy.fullName}
                </span>
                <span className="light-60">at</span>
                <span className="dark-60">
                  {formatUnixDate(row.investmentEntity.createdAt, 'LLL')}
                </span>
              </span>
            )}
          </div>
        ),
      },
      {
        text: 'As Of',
        dataField: 'date',
        hidden: tab === 'commitments',
        formatter: ({ row }) => formatDate(row.date, 'LL'),
      },
      {
        text: 'Amount',
        dataField: 'amount',
        formatter: ({ row: item }) =>
          accountingNumberFormat(item.amount ?? item.amountCents),
        headerClasses: 'justify-end',
        classes: 'text-right',
        headerAlign: 'end',
        headerStyle: {
          width: 150,
        },
      },
    ],
    [tab],
  );

  return (
    <div className="flex flex-col gap-s">
      <Table
        classes={{
          table: styles.table,
          container: styles.container,
        }}
        borderLessOutside
        columns={columns}
        items={items}
      />
      <span className="align-self-end secondary-regular flex gap-xs">
        <span className="light-60 ">Total</span>
        <span className="dark-60">
          {accountingNumberFormat(sumBy(items, (item) => item.amount))}
        </span>
      </span>
    </div>
  );
}

export default DetailsTable;
