import ErrorBoundary from '@/app/ui/ErrorBoundary';
import { ReportDashboardType } from '@/bundles/Shared/entities/dashboard/model/types/types';
import { DEFAULT_WIDGET_GROUPING_TYPE } from '@/bundles/Shared/widgets/dashboard/widgets/common/config';
import {
  WidgetErrorBoundary,
  WidgetErrorBoundaryFallback,
} from '@/bundles/Shared/widgets/dashboard/widgetsHelpers/ui/WidgetErrorBoundary';
import { useGetApiCoreAssetsByAssetIdReportBuilderReportsAndReportIdWidgetSectionsWidgetSectionIdSnapshotQuery } from '@/entities/report/reportBuilder/api';
import type {
  AssetsReportBuilderReportGroupDto,
  GetApiCoreAssetsByAssetIdReportBuilderReportsAndIdApiResponse,
} from '@/entities/report/reportBuilder/api/coreAssetsReportBuilderReportsGeneratedApi';
import { useReportBuilderTemplateMetaContext } from '@/entities/report/reportBuilder/context/reportBuilderTemplateMetaContext';
import { useGetReportBuilderTemplateWidgetSectionEvaluate } from '@/entities/report/reportBuilder/lib';
import { reportingEnhancedApi } from '@/entities/reporting/api/reportingEnhancedApi';
import { formatToDateStringForRequest } from '@/shared/lib/converters';
import { useAssetIdSearchParam } from '@/shared/lib/hooks/navigation/useAssetIdSearchParam';
import { useSearchParams } from '@/shared/lib/hooks/navigation/useSearchParams';
import { mapListToIds } from '@/shared/lib/listHelpers';
import { useParams } from '@reach/router';
import { getInitialReportBuilderTemplateWidgetState } from 'bundles/Shared/entities/dashboard/model/slices/reportBuilderTemplatesSlice';
import { resolveWidgetSectionPositionClassName } from 'bundles/Shared/widgets/dashboard/widgets/common/lib/reportBuilder';
import {
  ALL_REPORT_BUILDER_WIDGETS_CONFIG_MAP,
  REPORT_BUILDER_WIDGETS_CONFIG_MAP,
} from 'bundles/Shared/widgets/dashboard/widgets/config';
import {
  isWidgetHasNoDataAndHiddenWidgetPlaceholders,
  widgetHasData,
} from 'bundles/Shared/widgets/dashboard/widgets/financialTableSingeDate/lib';
import type { WidgetProps } from 'bundles/Shared/widgets/dashboard/widgets/model';
import { safelyParseJSON } from 'lib/typeHelpers/safelyParseJSON';
import { type FC, useMemo } from 'react';
import type { UnknownRecord } from 'type-fest';

export const useIsReportBuilderWidgetHidden = ({
  data,
  Component,
  hidePlaceholders,
  isError,
  deps = [],
}: {
  data: UnknownRecord | null;
  Component: FC;
  isError: boolean;
  hidePlaceholders: boolean;
  deps?: React.DependencyList;
}) => {
  return useMemo(() => {
    if (isError || !Component) return true;

    if (
      data !== null &&
      isWidgetHasNoDataAndHiddenWidgetPlaceholders(hidePlaceholders, data)
    ) {
      return true;
    }

    if (data !== null && !widgetHasData(data)) return true;

    if (data == null) return true;
    return false;
  }, [isError, data, Component, ...deps]);
};

// TODO merge with `EagleEyeReportBuilderWidget` and create `ReportBuilderWidget`
export const ObjectLevelReportBuilderWidget = ({
  reportData,
  pageOrientation,
  widgetSection,
  ...props
}: {
  reportData: GetApiCoreAssetsByAssetIdReportBuilderReportsAndIdApiResponse['report'];
  pageOrientation: AssetsReportBuilderReportGroupDto['pageOrientation'];
} & Omit<
  WidgetProps,
  | 'data'
  | 'isLoading'
  | 'isFetching'
  | 'isError'
  | 'mode'
  | 'state'
  | 'dashboardId'
  | 'widgetId'
  | 'context'
  | 'dashboardType'
>) => {
  const params = useParams();
  const assetId = useAssetIdSearchParam();
  const [searchParams] = useSearchParams();
  const hidePlaceholders = searchParams.get('hidePlaceholders') ?? 'false';

  const { data, isLoading, isFetching, isError } =
    useGetApiCoreAssetsByAssetIdReportBuilderReportsAndReportIdWidgetSectionsWidgetSectionIdSnapshotQuery(
      {
        assetId,
        widgetSectionId: widgetSection.id,
        reportId: params.id,
        body: {
          context: {},
        },
      },
    );

  const { Component } =
    REPORT_BUILDER_WIDGETS_CONFIG_MAP[widgetSection.widgetType] ?? {};

  const isWidgetHidden = useIsReportBuilderWidgetHidden({
    Component,
    data,
    hidePlaceholders: Boolean(safelyParseJSON(hidePlaceholders)),
    isError,
    deps: [reportData],
  });

  if (isWidgetHidden) return null;

  return (
    <ErrorBoundary
      fallback={<WidgetErrorBoundaryFallback title={widgetSection.title} />}
    >
      <Component
        mode="pdf"
        pageOrientation={pageOrientation}
        state={(widgetSection.snapshotContext ?? {}) as UnknownRecord}
        onStateChange={() => {}}
        data={data?.widget}
        isLoading={isLoading}
        isError={isError}
        isFetching={isFetching}
        widgetSection={widgetSection}
        dashboardId={''}
        widgetId={''}
        context={{}}
        className={resolveWidgetSectionPositionClassName(
          widgetSection.position,
        )}
        dashboardType={ReportDashboardType.REPORT_BUILDER_TEMPLATE}
        {...props}
      />
    </ErrorBoundary>
  );
};

// TODO merge with `ObjectLevelReportBuilderWidget` and create `ReportBuilderWidget`

export const EagleEyeReportBuilderWidget = ({
  reportData,
  pageOrientation,
  widgetSection,
  context,
  state,
  ...props
}: {
  reportData: GetApiCoreAssetsByAssetIdReportBuilderReportsAndIdApiResponse['report'];
  pageOrientation: AssetsReportBuilderReportGroupDto['pageOrientation'];
} & Omit<
  WidgetProps,
  | 'data'
  | 'isLoading'
  | 'isFetching'
  | 'isError'
  | 'mode'
  | 'dashboardId'
  | 'widgetId'
  | 'dashboardType'
>) => {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const hidePlaceholders = searchParams.get('hidePlaceholders') ?? 'false';

  const { data, isLoading, isFetching, isError } =
    reportingEnhancedApi.useGetApiReportBuilderEagleEyeReportsByEagleEyeReportIdWidgetSectionsAndWidgetSectionIdSnapshotQuery(
      {
        eagleEyeReportId: params.reportId,
        widgetSectionId: widgetSection.id,
      },
    );
  const { Component } =
    ALL_REPORT_BUILDER_WIDGETS_CONFIG_MAP[widgetSection.widgetType] ?? {};

  const isWidgetHidden = useIsReportBuilderWidgetHidden({
    Component,
    data,
    hidePlaceholders: Boolean(safelyParseJSON(hidePlaceholders)),
    isError,
    deps: [reportData],
  });

  if (isWidgetHidden) return null;

  return (
    <ErrorBoundary
      fallback={<WidgetErrorBoundaryFallback title={widgetSection.title} />}
    >
      <Component
        mode="pdf"
        pageOrientation={pageOrientation}
        state={widgetSection.snapshotContext ?? ({} as UnknownRecord)}
        onStateChange={() => {}}
        data={data?.widget}
        isLoading={isLoading}
        isError={isError}
        isFetching={isFetching}
        widgetSection={widgetSection}
        dashboardId={''}
        widgetId={''}
        context={context ?? {}}
        className={resolveWidgetSectionPositionClassName(
          widgetSection.position,
        )}
        dashboardType={ReportDashboardType.REPORT_BUILDER_TEMPLATE_EAGLE_EYE}
        {...props}
      />
    </ErrorBoundary>
  );
};

export const ReportBuilderTemplateWidget = ({
  pageOrientation,
  widgetSection,
  ...props
}: {
  pageOrientation: AssetsReportBuilderReportGroupDto['pageOrientation'];
} & Omit<
  WidgetProps,
  | 'data'
  | 'isLoading'
  | 'isFetching'
  | 'isError'
  | 'mode'
  | 'state'
  | 'dashboardId'
  | 'widgetId'
  | 'context'
>) => {
  const meta = useReportBuilderTemplateMetaContext();
  const [searchParams] = useSearchParams();
  const hidePlaceholders = searchParams.get('hidePlaceholders') ?? 'false';
  const selectedDate =
    formatToDateStringForRequest(searchParams.get('date') ?? new Date()) ??
    formatToDateStringForRequest(new Date());

  const context = {
    // XYChartWidget expects data to be in data prop
    assets: meta.assets,
    segments: meta.segments,
  };

  const state = getInitialReportBuilderTemplateWidgetState(
    widgetSection,
    selectedDate,
  );

  const { data, isLoading, isFetching, isError } =
    useGetReportBuilderTemplateWidgetSectionEvaluate(widgetSection);

  const { Component } =
    ALL_REPORT_BUILDER_WIDGETS_CONFIG_MAP[widgetSection.widgetType] ?? {};

  const isWidgetHidden = useIsReportBuilderWidgetHidden({
    Component,
    data,
    hidePlaceholders: Boolean(safelyParseJSON(hidePlaceholders)),
    isError,
  });

  if (isWidgetHidden) return null;

  return (
    <WidgetErrorBoundary
      widgetSection={widgetSection}
      dashboardType={ReportDashboardType.REPORT_BUILDER_TEMPLATE}
      mode="pdf"
    >
      <Component
        mode="pdf"
        pageOrientation={pageOrientation}
        state={
          {
            date: selectedDate,
            groupingType: state.groupingType,
            period: state.period,
            kpi: state.kpi,
            dateFrom: state.dateFrom,
            dateTo: state.dateTo,
            granularity: state.granularity,

            // XYChartWidget expects data to be in data prop
            assets: mapListToIds(meta.assets),
            segments: mapListToIds(meta.segments),
          } as UnknownRecord
        }
        onStateChange={() => {}}
        data={data?.widget}
        isLoading={isLoading}
        isError={isError}
        isFetching={isFetching}
        widgetSection={widgetSection}
        dashboardId={''}
        widgetId={''}
        context={context}
        className={resolveWidgetSectionPositionClassName(
          widgetSection.position,
        )}
        {...props}
      />
    </WidgetErrorBoundary>
  );
};
