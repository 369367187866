import { HUGE_MODAL_Z_INDEX } from '@/bundles/Shared/constants';
import { PostApiCapitalInvestmentDistributionsBulkApiArg } from '@/entities/return/api/capitalInvestmentObjectsGeneratedApi';
import SelectInvestmentEntity from 'bundles/REturn/components/Ownership/selectInvestmentEntity/SelectInvestmentEntity';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import pluralize from 'pluralize';
import React, { useMemo, useState } from 'react';
import { Button } from 'stories/Button/Button';
import { Modal } from 'stories/Modals/Modal/Modal';
import { ModalActions } from 'stories/Modals/Modal/ModalActions';
import { ModalHeaderWithSubtitle } from 'stories/Modals/ModalHeader/ModalHeaderWithSubtitle/ModalHeaderWithSubtitle';
import { EnterAccruedValuesStep } from '@/bundles/REturn/components/Ownership/modals/addAccruedModal/EnterAccruedValuesStep';
import { EnterAccruedAmountValuesStep } from '@/bundles/REturn/components/Ownership/modals/addAccruedModal/EnterAccruedAmountValuesStep';
import {
  convertCentsToDollars,
  convertDollarsToCents,
  formatToDateStringForRequest,
} from '@/shared/lib/converters';
import { ReviewStep } from '@/bundles/REturn/components/Ownership/modals/addAccruedModal/ReviewStep';
import { isSameMonth } from '@/shared/lib/date';
import { usePostApiCapitalInvestmentAccruedPreferredReturnsPrecalculateValuesMutation } from '@/entities/return/api/capitalInvestmentObjectsEnhancedApi';
import LoadingPanel from '@/bundles/Shared/components/LoadingPanel';
import { CapitalInvestment } from '@/entities/return';

interface Props
  extends DialogProps<PostApiCapitalInvestmentDistributionsBulkApiArg['body']> {
  capitalInvestments: CapitalInvestment[];
}

const AddAccruedModal = ({ onClose, capitalInvestments, onSubmit }: Props) => {
  const [getPrecalculatedValues, { isLoading }] =
    usePostApiCapitalInvestmentAccruedPreferredReturnsPrecalculateValuesMutation();

  const [currentStep, setCurrentStep] = useState(1);
  const [selectedCapitalInvestments, setSelectedCapitalInvestments] = useState<
    CapitalInvestment[]
  >([]);

  const step1Content = useMemo(
    () => (
      <SelectInvestmentEntity
        capitalInvestments={capitalInvestments}
        selectedItems={selectedCapitalInvestments}
        setSelectedItems={(selectedItems) => {
          setSelectedCapitalInvestments(selectedItems);
        }}
      />
    ),
    [selectedCapitalInvestments],
  );

  const step2Content = (
    <div className="p-6">
      <EnterAccruedValuesStep
        selectedCapitalInvestments={selectedCapitalInvestments}
        setSelectedCapitalInvestments={setSelectedCapitalInvestments}
      />
    </div>
  );

  const step3Content = (
    <div className="p-6">
      <EnterAccruedAmountValuesStep
        selectedCapitalInvestments={selectedCapitalInvestments}
        setSelectedCapitalInvestments={setSelectedCapitalInvestments}
      />
    </div>
  );

  const step4Content = (
    <div className="p-6">
      <ReviewStep selectedCapitalInvestments={selectedCapitalInvestments} />
    </div>
  );

  const steps: Record<string, React.JSX.Element> = {
    1: step1Content,
    2: step2Content,
    3: step3Content,
    4: step4Content,
  };

  const stepsLength = Object.keys(steps).length;

  const step2Valid = () => {
    return selectedCapitalInvestments.every(
      (entry) => entry.startDate && entry.periodType,
    );
  };

  const stepsValid: Record<string, boolean> = {
    1: selectedCapitalInvestments.length !== 0,
    2: step2Valid(),
    3: selectedCapitalInvestments
      .flatMap((entry) => entry.entries)
      .every((entry) => entry?.amount),
    4: true,
  };

  const pluralizedSelectedCapitalInvestments = `${
    selectedCapitalInvestments.length
  } ${pluralize('Investment Entity', selectedCapitalInvestments.length)}`;
  const stepsSubmitText: Record<string, string> = {
    1: `Continue ${
      selectedCapitalInvestments.length > 0
        ? `with ${pluralizedSelectedCapitalInvestments}`
        : ''
    }`,
    2: 'Next',
    3: 'Next',
    4: 'Create Accrued Preferred',
  };

  const handleSubmit = () => {
    if (currentStep !== stepsLength) {
      if (currentStep == 2) {
        const data = selectedCapitalInvestments.map((item) => ({
          start_date: formatToDateStringForRequest(item.startDate),
          end_date: item.endDate
            ? formatToDateStringForRequest(item.endDate)
            : undefined,
          period_type: item.periodType,
          investment_id: item.id,
        }));

        getPrecalculatedValues({
          body: { data },
        }).then((response) => {
          setSelectedCapitalInvestments(
            selectedCapitalInvestments.map((item) => ({
              ...item,
              entries: response.data
                .filter((entry) => entry.investmentId === item.id)
                .map((entry) => ({
                  ...entry,
                  amount: convertCentsToDollars(entry.amountCents),
                  initialAmount: convertCentsToDollars(entry.amountCents),
                  capitalBalance: convertCentsToDollars(
                    entry.capitalBalanceCents,
                  ),
                })),
            })),
          );

          setCurrentStep(currentStep + 1);
        });
      } else {
        setCurrentStep(currentStep + 1);
      }

      return;
    }

    const payload = {
      accrued_perferred_return: selectedCapitalInvestments.map((entry) => {
        const entries = entry.entries.map((accruedEntry) => ({
          date: accruedEntry.endDate
            ? formatToDateStringForRequest(accruedEntry.endDate)
            : undefined,
          amount_cents: convertDollarsToCents(accruedEntry.amount),
        }));

        // Remove the last entry if it's not the last day of the month
        if (entries.length > 0) {
          const lastEntry = entries[entries.length - 1];

          if (!isSameMonth(lastEntry, lastEntry)) {
            entries.pop();
          }
        }

        const currentDate = new Date(new Date().setHours(0, 0, 0, 0));
        const filteredEntries = entries.filter(
          (e) => new Date(e.date) <= currentDate,
        );

        return {
          capital_investment_id: entry.id,
          start_date: formatToDateStringForRequest(entry.startDate),
          end_date: entry.endDate
            ? formatToDateStringForRequest(entry.endDate)
            : undefined,
          period_type: entry.periodType,
          amount_cents: convertDollarsToCents(
            entry.entries.at(-1)?.amount ?? 0,
          ),
          accrued_entries_attributes: filteredEntries,
        };
      }),
    };

    onSubmit(payload);
  };

  return (
    <Modal
      toggle={() => onClose()}
      size="xl"
      classes={{
        body: '!p-0',
      }}
      bodyHeight={600}
      zIndex={HUGE_MODAL_Z_INDEX + 2}
      header={
        <ModalHeaderWithSubtitle
          title="Bulk Adding of Accrued Preferred Values"
          subtitle={`Step ${currentStep} of ${stepsLength}`}
          order="subtitle-title"
        />
      }
      actions={
        <ModalActions alignItems="space-between">
          <Button
            variant="secondary"
            onClick={
              currentStep === 1
                ? onClose
                : () => setCurrentStep(currentStep - 1)
            }
          >
            {currentStep === 1 ? 'Cancel' : 'Back to Selection'}
          </Button>
          <Button
            variant="success"
            onClick={handleSubmit}
            disabled={!stepsValid[currentStep]}
          >
            {stepsSubmitText[currentStep]}
          </Button>
        </ModalActions>
      }
    >
      {isLoading ? <LoadingPanel /> : steps[currentStep]}
    </Modal>
  );
};

export default AddAccruedModal;
