import React, { useEffect, useState } from 'react';
import { navigate } from '@reach/router';

import { AnimationLoader } from 'stories/AnimationLoader/AnimationLoader';
import { Button } from 'stories/Button/Button';
import { IconButton } from 'stories/IconButton/IconButton';
import { IDevelopmentBudgetOnShow } from 'types/DevelopmentBudget';
import { IReconcileDevelopmentCategoryTreeNode } from 'types/ReconcileDevelopmentCategory';

import {
  fetchDevelopmentBudget,
  publishDevelopmentBudget,
} from 'bundles/REconcile/actions/developmentBudgets';
import { IDevelopmentBudgetData } from 'bundles/REconcile/types/BudgetRows';
import DevelopmentBudgetEditorTable from 'bundles/REconcile/components/development/editor/DevelopmentBudgetEditorTable';
import { TRouteParams } from '@/shared/lib/hooks/useNavigation';

type Props = TRouteParams['RECONCILE_DEVELOPMENT_LEGAL_ENTITY_BUDGET_EDITOR'];

export default function DevelopmentBudgetEditor({ budgetId }: Props) {
  const [loading, setLoading] = useState(true);
  const [item, setItem] = useState<IDevelopmentBudgetOnShow | null>(null);
  const [data, setData] = useState<IDevelopmentBudgetData>(null);
  const [categoriesTree, setCategoriesTree] = useState<
    IReconcileDevelopmentCategoryTreeNode[] | null
  >(null);

  const reload = async () => {
    setLoading(true);
    const result = await fetchDevelopmentBudget(budgetId);
    setItem(result?.item);
    setCategoriesTree(result?.meta?.categoriesTree);
    setData(result?.meta?.data);
    setLoading(false);
  };

  const goBack = () => navigate(-1);

  const handlePublish = async () => {
    const res = await publishDevelopmentBudget(budgetId);
    if (res) {
      reload();
    }
  };

  useEffect(() => {
    reload();
  }, [budgetId]);

  if (loading || item === null || categoriesTree === null)
    return <AnimationLoader />;

  return (
    <div className="flex flex-col">
      <div className="w-full flex items-center bg-light p-2 pl-4">
        <IconButton
          iconName="arrowLeft"
          onClick={() => {
            goBack();
          }}
          className="mr-4"
        />
        <h6 className="mr-[0.5rem]">{item.legalEntity.name}</h6>
        <div className="flex-grow" />
        {item.state !== 'published' && (
          <Button onClick={handlePublish} variant="primary">
            Publish
          </Button>
        )}
      </div>
      <div
        className="w-full overflow-auto"
        style={{ height: 'calc(-150px + 100vh)' }}
      >
        <DevelopmentBudgetEditorTable
          budgetId={item.id}
          rows={data.rows}
          columns={data.columns}
        />
      </div>
    </div>
  );
}
