import { emptySplitApi as api } from '@/app/stores/api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiSettingsCoreLegalEntitiesLegalEntitiables: build.query<
      GetApiSettingsCoreLegalEntitiesLegalEntitiablesApiResponse,
      GetApiSettingsCoreLegalEntitiesLegalEntitiablesApiArg
    >({
      query: () => ({
        url: `/api/settings/core/legal_entities/legal_entitiables`,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as legalEntitiesSettingsApi };
export type GetApiSettingsCoreLegalEntitiesLegalEntitiablesApiResponse =
  /** status 200 success */ CoreLegalEntitiesLegalEntitiables[];
export type GetApiSettingsCoreLegalEntitiesLegalEntitiablesApiArg = void;
export type CoreLegalEntitiesLegalEntitiables = {
  id: number;
  slug: string;
  name: string;
  aasmState: 'published' | 'draft' | 'archived';
  objectType: 'Asset' | 'Fund';
  pictureUrl: string | null;
};
