import {
  useCreateFolderMutation,
  useUpdateFolderMutation,
} from 'bundles/Assets/components/Media/api/objectFoldersApi';
import { useState } from 'react';
import { Button } from 'stories/Button/Button';
import { Input } from 'stories/FormControls/Inputs/Input/Input';
import { Modal } from 'stories/Modals/Modal/Modal';
import { ModalHeaderWithIcon } from 'stories/Modals/ModalHeader/ModalHeaderWithIcon/ModalHeaderWithIcon';
import { MultipleItemsIcons } from 'stories/MultipleItemsIcons/MultipleItemsIcons';

const NewFolderModal = ({
  setOpened,
  folder,
  parentId,
  investmentObject,
  folders,
  setFolders,
}) => {
  const [title, setTitle] = useState(folder?.title || '');
  const [createFolder, { isLoading: isCreating }] = useCreateFolderMutation();
  const [updateFolder, { isLoading: isUpdating }] = useUpdateFolderMutation();
  const isLoading = isUpdating || isCreating;

  const onSubmit = async (e) => {
    e.preventDefault();
    const data = {
      folder: {
        id: folder?.id,
        title,
        folderable_id: investmentObject.entity.id,
        folderable_type: investmentObject.type,
        folder_type: 'documents',
      },
    };
    if (parentId) data.folder.parent_id = parentId;

    if (folder) {
      const res = await updateFolder(data);
      if (res) {
        const newFolders = folders.map((f) => (f.id === res.id ? res : f));
        setFolders?.(newFolders);
      }
    } else {
      const res = await createFolder(data);
      if (res) {
        setFolders?.([...folders, res]);
      }
    }
    setOpened(false);
  };

  const editFolderHeader = (
    <ModalHeaderWithIcon
      icons={<MultipleItemsIcons itemType="folder" items={[folder]} />}
      title={folder?.title}
    />
  );

  return (
    <Modal
      toggle={() => setOpened(false)}
      header={folder ? editFolderHeader : 'Create New Folder'}
      size="400"
      classes={{
        header: 'bg-light gap-4',
        body: 'bg-light',
        footer: 'bg-light',
      }}
      actions={
        <>
          <Button
            variant="secondary"
            onClick={() => setOpened(false)}
            disabled={isLoading}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="success"
            onClick={onSubmit}
            loading={isLoading}
            disabled={isLoading}
          >
            {folder ? 'Update' : 'Create'} Folder
          </Button>
        </>
      }
    >
      <form className="flex h-full flex-col" onSubmit={onSubmit}>
        <label htmlFor="create-title-input">Folder Name</label>
        <Input
          size="l"
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Enter Folder Name"
          id="create-title-input"
          defaultValue={title}
        />
      </form>
    </Modal>
  );
};

export default NewFolderModal;
