import {
  AVAILABLE_PERIOD_TYPES,
  AVAILABLE_PERIOD_TYPES_OPTIONS,
  AvailablePeriodType,
  HEADER_BACKGROUND_SCHEMA,
  PERIOD_SHIFT_SCHEMA,
} from '@/bundles/Shared/widgets/dashboard/widgets/common/config';
import { GROUPING_TYPE_OPTIONS } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/fields/GroupingTypeFIeld';
import { KpiTableWidgetConfigDto } from '@/shared/api/dashboardsSettingsGeneratedApi';
import { getOptionsValues } from '@/shared/lib/listHelpers';
import { transformEmptyObjectToUndefined } from '@/shared/lib/yup';
import { transformPeriodShiftDtoToForm } from 'bundles/Shared/widgets/dashboard/widgets/common/lib/config';
import { DATE_PICKER_SETTINGS_OPTIONS } from 'bundles/Shared/widgets/dashboard/widgets/kpiTable/config/column.form';
import * as yup from 'yup';

export const SUPPORTED_KPI_TABLE_PERIOD_TYPES = [
  AVAILABLE_PERIOD_TYPES.T3,
  AVAILABLE_PERIOD_TYPES.T6,
  AVAILABLE_PERIOD_TYPES.T12,
  AVAILABLE_PERIOD_TYPES.MTD,
  AVAILABLE_PERIOD_TYPES.YTD,
  AVAILABLE_PERIOD_TYPES.MTM,
  AVAILABLE_PERIOD_TYPES.MONTH,
] as const satisfies AvailablePeriodType[];

export const SUPPORTED_KPI_TABLE_PERIOD_TYPES_OPTIONS =
  AVAILABLE_PERIOD_TYPES_OPTIONS.filter((type) =>
    SUPPORTED_KPI_TABLE_PERIOD_TYPES.includes(type.value),
  );

export const KPI_TABLE_WIDGET_CONFIG_SCHEMA = yup
  .object()
  .concat(HEADER_BACKGROUND_SCHEMA)
  .shape({
    periodTypes: yup
      .array()
      .of(yup.string().oneOf(SUPPORTED_KPI_TABLE_PERIOD_TYPES).required())
      .required(),
    datePickerSetting: yup
      .string()
      .oneOf(getOptionsValues(DATE_PICKER_SETTINGS_OPTIONS))
      .required(),
    availablePeriodTypes: yup
      .array()
      .of(yup.string().oneOf(SUPPORTED_KPI_TABLE_PERIOD_TYPES).required())
      .required(),
    defaultPeriodType: yup
      .string()
      .oneOf(SUPPORTED_KPI_TABLE_PERIOD_TYPES)
      .required(),
    period_shift: PERIOD_SHIFT_SCHEMA.nullable()
      .optional()
      .transform(transformEmptyObjectToUndefined)
      .default(undefined),
    groupingType: yup
      .string()
      .oneOf(getOptionsValues(GROUPING_TYPE_OPTIONS))
      .optional()
      .nullable()
      .default(undefined),
    hideTotalRow: yup.boolean().optional().nullable().default(undefined),
  });

export const DEFAULT_KPI_TABLE_WIDGET_CONFIG_WEEK: Partial<KpiTableWidgetConfigForm> =
  {
    datePickerSetting: 'week',
    periodTypes: ['week'],
    defaultPeriodType: 'week',
  };

export const DEFAULT_KPI_TABLE_WIDGET_CONFIG_MONTH: Partial<KpiTableWidgetConfigForm> =
  {
    datePickerSetting: 'month',
    periodTypes: ['mtd'],
    availablePeriodTypes: ['mtd', 'ytd'],
    defaultPeriodType: 'mtd',
  };

export type KpiTableWidgetConfigForm = yup.InferType<
  typeof KPI_TABLE_WIDGET_CONFIG_SCHEMA
>;

export const transformKpiTableWidgetConfigToForm = (
  config: KpiTableWidgetConfigDto,
): KpiTableWidgetConfigForm => {
  return {
    period_shift: transformPeriodShiftDtoToForm(
      config.default_options.date?.period_shift,
    ),
    availablePeriodTypes: config.period_types,
    defaultPeriodType: config.default_options.period_type,
    periodTypes: config.period_types,
    datePickerSetting: config.default_options.period_type.includes('week')
      ? 'week'
      : 'month',
    hideTotalRow: config.hide_total_row,
    groupingType: config.default_options.grouping_type,
    header_background: config.viz_config?.header_background,
  };
};
