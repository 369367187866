import { ViewsCountFormatter } from '@/bundles/Shared/components/ColumnFormatters/ViewsCountFormatter';
import {
  fullNameColumnContent,
  roleColumnContent,
} from '@/bundles/Shared/components/SharedWithTableColumns';
import { IColumn } from '@/bundles/Shared/components/Table/types';
import { tableHeaderStyle } from '@/bundles/Shared/components/Permissions/ViewPermissionedUsersModal/consts';
import { LightUser } from '@/bundles/Shared/components/Permissions/ViewPermissionedUsersModal/types';

export const fullNameColumn: IColumn<LightUser> = {
  dataField: 'fullName',
  headerStyle: tableHeaderStyle,
  text: 'Name',
  formatter: ({ row }) => fullNameColumnContent(row),
  sortable: true,
};

export const roleNameColumn: IColumn<LightUser> = {
  dataField: 'role.name',
  headerStyle: tableHeaderStyle,
  text: 'Role',
  formatter: ({ row }) => roleColumnContent(row),
  sortable: true,
};

export const viewsColumn: IColumn<
  LightUser & {
    lastViewedAt: UnixTime | null;
    viewsCount: number;
  }
> = {
  sortable: true,
  dataField: 'viewsCount',
  headerStyle: {
    minWidth: 260,
  },
  text: 'Viewed',
  formatter: ({ row }) => (
    <ViewsCountFormatter
      viewsCount={row.viewsCount}
      lastViewedAt={row.lastViewedAt}
    />
  ),
};
