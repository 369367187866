import React from 'react';
import { Button } from '@/stories/Button/Button';
import { LinkButton } from '@/stories/LinkButton/LinkButton';

import { IUser } from '@/types/User';
import { useModal } from '@/shared/lib/hooks/useModal';
import { UserInfoSection } from '@/bundles/Shared/entities/user/ui/UserInfoSection';
import { UpdateUserModal } from '@/widgets/userProfile/ui/UpdateUserModal';
import { logout } from '@/lib/currentUser';

interface Props {
  currentUser: IUser;
}

const UserCard = ({ currentUser }: Props) => {
  const { fullName, role, email } = currentUser;
  const handleClick = () =>
    logout().then(() => {
      window.location.href = '/users/sign_in';
    });

  const { openModal } = useModal();

  const openUpdateUserModal = () => {
    openModal(UpdateUserModal, {});
  };

  return (
    <div className="logout-card px-5 py-4">
      <div className="light-60 inline-regular flex flex-col items-center text-center">
        <UserInfoSection
          fullName={fullName}
          email={email}
          roleName={role?.name ?? ''}
        />
        <LinkButton
          type="submit"
          onClick={openUpdateUserModal}
          className="font-weight-600 inline-regular mt-3"
        >
          Profile
        </LinkButton>
        <hr className="my-4 w-full" />
        <Button
          variant="secondary"
          type="submit"
          onClick={handleClick}
          className="font-weight-400 inline-regular"
        >
          Logout
          <span className="sre-icon-logout light-60" />
        </Button>
      </div>
    </div>
  );
};

export default UserCard;
