import { formatDate } from '@/shared/lib/formatting/dates';
import { DEFAULT_DATE_FALLBACK } from '@/shared/lib/formatting/fallbacks';
export const generateRequisitionDates = (
  beginDate: DateString,
  endDate: DateString,
) => {
  if (beginDate && endDate) {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    return `${formatDate(beginDate, 'MMM DD')} - ${formatDate(
      endDate,
      'MMM DD, YYYY',
    )}`;
  }
  return DEFAULT_DATE_FALLBACK as string;
};
