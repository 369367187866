import { LegalEntity } from '@/entities/core/legalEntity';
import { CssVar } from '@/shared/config/cssVar';
import { useModal } from '@/shared/lib/hooks/useModal';
import { Link } from '@reach/router';
import { useGetLEActivityLogsQuery } from 'bundles/Settings/components/Portal/LegalEntities/api/settingsCoreLegalEntityActivityLogsApi';
import { useEffect, useState } from 'react';
import { IntersectionOptions, useInView } from 'react-intersection-observer';
import { AnimationLoader } from 'stories/AnimationLoader/AnimationLoader';
import { HistoryItem } from 'stories/HistoryItem/HistoryItem';
import { ModalHeaderWithSubtitle } from 'stories/Modals/ModalHeader/ModalHeaderWithSubtitle/ModalHeaderWithSubtitle';
import { SidePanel } from 'stories/Modals/Modal/Modal';
import ActivityLogDetailsModal from '@/bundles/Settings/components/Portal/LegalEntities/ActivityLogDetailsModal';
import {
  MAP_KINDS_TO_ICON_NAME,
  MAP_KINDS_TO_TEXT,
} from '@/bundles/Settings/components/Portal/LegalEntities/LEActionsDictionary';

interface Props {
  onClose: () => void;
  legalEntity?: LegalEntity;
}

const ACTIVITY_LOGS_LIMIT_PER_SLICE = 20;
const INTERSECTION_OPTIONS = {
  delay: 400,
  root: null,
  rootMargin: '0px',
  threshold: 0.4,
} as const satisfies IntersectionOptions;

const ActivityLogListModal = ({ legalEntity, onClose }: Props) => {
  const { openModal } = useModal();
  const [currentIdx, setCurrentIdx] = useState<number>(1);
  const { ref, inView } = useInView(INTERSECTION_OPTIONS);

  const modalHeader = (
    <ModalHeaderWithSubtitle
      title={legalEntity?.name ?? 'Activity Log'}
      subtitle={legalEntity?.name ? 'Activity Log' : undefined}
      order="subtitle-title"
    />
  );

  useEffect(() => {
    if (inView) {
      setCurrentIdx((prev) => prev + 1);
    }
  }, [inView]);

  const { data: logs, isLoading } = useGetLEActivityLogsQuery(undefined);

  const currentSliceOfLogs =
    logs?.slice(0, currentIdx * ACTIVITY_LOGS_LIMIT_PER_SLICE) ?? [];

  const hasMoreLogs = (logs ?? []).length > currentSliceOfLogs.length;

  return (
    <SidePanel toggle={onClose} header={modalHeader}>
      {isLoading && <AnimationLoader />}
      {!isLoading && logs?.length === 0 && (
        <p className="text-center">Nothing Found</p>
      )}
      {!isLoading && logs != null && logs?.length > 0 && (
        <>
          {currentSliceOfLogs.map((log, idx) => (
            <HistoryItem
              key={log.id}
              icon={{
                iconName: MAP_KINDS_TO_ICON_NAME[log.kind],
                iconColor: CssVar.light60,
                backgroundColor: CssVar.light,
              }}
              label={
                <div>
                  {!legalEntity && (
                    <p className="secondary-semibold">{log.legalEntity.name}</p>
                  )}
                  {MAP_KINDS_TO_TEXT[log.kind] || 'unknown action'}{' '}
                  {'object_name' in log.brief && (
                    <Link
                      to={`${
                        log.kind === 'assigned_to_asset'
                          ? '/assets/'
                          : '/funds/'
                      }${log.brief.object_slug}`}
                      onClick={onClose}
                      className="text-info-055"
                    >
                      {log.brief.object_name}
                    </Link>
                  )}
                </div>
              }
              createdBy={log.whoDoneIt.fullName}
              createdAt={log.updatedAt}
              notificationAction={
                log.details
                  ? {
                      label: 'show details',
                      action: () =>
                        openModal(ActivityLogDetailsModal, {
                          activityLog: log,
                        }),
                    }
                  : undefined
              }
              lastItem={idx === logs.length - 1}
            />
          ))}
          {hasMoreLogs && (
            <div ref={ref} className="h-[64px]">
              <AnimationLoader className="static" />
            </div>
          )}
        </>
      )}
    </SidePanel>
  );
};

export default ActivityLogListModal;
