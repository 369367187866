import { formatAmount } from '@/shared/lib/formatting/number';
import React, { useMemo } from 'react';
import getPayAppSummary from '@/bundles/Construction/components/PayApp/PayAppSummary/initData';
import { PayAppSummary } from '@/shared/types/reconcile/PayApp';

interface Props {
  payAppSummary: PayAppSummary;
}

const PayAppSummaryTable = ({ payAppSummary }: Props) => {
  const payAppSummaryData = useMemo(
    () => getPayAppSummary(payAppSummary),
    [payAppSummary],
  );

  return (
    <div className="pay-app-summary">
      <div className="pay-app-summary__item pay-app-summary__item_right">
        {payAppSummaryData.slice(0, 5).map((item, idx) => (
          <div
            key={item.title}
            className={`flex justify-between pay-app-summary__row ${
              idx % 2 && 'pay-app-summary__row_dark'
            }`}
          >
            <div>{item.title}</div>
            <div className="pay-app-summary__money">
              {formatAmount(item.value)}
            </div>
          </div>
        ))}
      </div>
      <div className="pay-app-summary__item pay-app-summary__item_left">
        {payAppSummaryData.slice(5).map((item, idx) => (
          <div
            key={item.title}
            className={`flex justify-between pay-app-summary__row ${
              idx % 2 && 'pay-app-summary__row_dark'
            }`}
          >
            <div>{item.title}</div>
            <div className="pay-app-summary__money">
              {formatAmount(item.value)}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PayAppSummaryTable;
