import { mapListToIds } from '@/shared/lib/listHelpers';
import { PermittedFormModel } from '@/bundles/Shared/entities/permissions/model';

export const transformPermissionsToRequest = (
  permissions: PermittedFormModel,
): {
  directly_permitted_user_ids: string[];
  directly_permitted_user_role_ids: string[];
  directly_permitted_user_tag_ids: number[];
  directly_permitted_investment_entity_ids: number[];
  public_permission_attributes: {
    _destroy?: boolean;
  };
} =>
  permissions.public
    ? {
        directly_permitted_user_ids: [],
        directly_permitted_user_role_ids: [],
        directly_permitted_user_tag_ids: [],
        directly_permitted_investment_entity_ids: [],
        public_permission_attributes: {},
      }
    : {
        directly_permitted_user_ids: mapListToIds(
          permissions.directUsers ?? [],
        ),
        directly_permitted_user_role_ids: mapListToIds(
          permissions.directRoles ?? [],
        ),
        directly_permitted_user_tag_ids: mapListToIds(
          permissions.directTags ?? [],
        ),
        directly_permitted_investment_entity_ids: mapListToIds(
          permissions.directInvestmentEntities ?? [],
        ),
        public_permission_attributes: { _destroy: true },
      };
