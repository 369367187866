import { cn } from '@/shared/lib/css/cn';
import { useAppDispatch } from '@/shared/lib/hooks/redux';
import { Link } from '@reach/router';
import { collapseSidebar } from 'bundles/Shared/components/LeftSidebar/reducers/leftSidebarSlice';
import React from 'react';

interface Props {
  title: string;
  panel?: React.ReactNode;
}

export function LeftSidebarMenu({
  title,
  panel,
  children,
}: React.PropsWithChildren<Props>) {
  return (
    <div className="flex flex-col gap-2 bg-neutral-000 pb-4">
      <div className="flex justify-between gap-2 p-4 pb-0">
        <span className="header6-bold text-neutral-800">{title}</span>
        {panel}
      </div>
      <div className="flex flex-col px-3">{children}</div>
    </div>
  );
}

LeftSidebarMenu.Item = ({
  children,
  getProps,
  ...props
}: React.ComponentProps<typeof Link>) => {
  const dispatch = useAppDispatch();
  return (
    <Link
      {...props}
      onClick={(e) => {
        dispatch(collapseSidebar());
      }}
      getProps={(params) => {
        const linkProps = getProps?.(params) as
          | { className: string }
          | undefined;
        return {
          ...linkProps,
          className: cn(
            'p-1 flex gap-2 inline-regular text-neutral-800 hover:bg-neutral-100 rounded-[8px] items-center',
            params.isCurrent && 'inline-semibold',
            linkProps?.className,
          ),
        };
      }}
    >
      {children}
    </Link>
  );
};
