/* eslint-disable block-spacing */
import { LogItemKeyInfo } from 'bundles/Shared/components/ActivityLog/LogItemKeyInfo';
import { Icon } from 'stories/Icon/Icon';
import { Modal } from 'stories/Modals/Modal/Modal';
import { KEY_INFO_ITEMS } from '@/bundles/UserManagement/components/roles/constants';
import { IUserRoleActivityLogItem } from '@/bundles/UserManagement/components/roles/types';
import { ActivityLogModalPermission } from '@/bundles/UserManagement/components/roles/RightBar/ActivityLog/ActivityLogModalPermission';
import { LogItemAuthorAndDate } from '@/bundles/UserManagement/components/roles/RightBar/ActivityLog/LogItemAuthorAndDate';

interface Props {
  logItem: IUserRoleActivityLogItem;
  toggle: (opened: boolean) => void;
}

export function LogItemShowDetailsModal({ logItem, toggle }: Props) {
  return (
    <Modal
      size="600"
      classes={{ body: 'bg-light' }}
      toggle={toggle}
      header={
        <div className="flex gap-4">
          <div className="w-40p h-40p rounded-[8px] flex items-center justify-center bg-light">
            <Icon className="header4-regular" iconName="edit" />
          </div>
          <div>
            <h6 className="header6-bold">Details of Modifying Role</h6>
            <LogItemAuthorAndDate
              className="inline-regular"
              authorName={logItem.whoDoneIt.fullName}
              date={logItem.createdAt}
            />
          </div>
        </div>
      }
    >
      <div className="mb-m">
        <h6 className="header6-regular mb-s">Key Info</h6>
        <div className="rounded-[8px] secondary-regular flex flex-col gap overflow-hidden">
          {Object.values(KEY_INFO_ITEMS).map(
            ({ label, newFieldName, prevFieldName }) => (
              <LogItemKeyInfo
                key={label}
                newValue={logItem[newFieldName]}
                prevValue={logItem[prevFieldName]}
                label={label}
              />
            ),
          )}
        </div>
      </div>

      <div>
        <h6 className="header6-regular mb-s">Permissions</h6>
        <div className="rounded-[8px] secondary-regular flex flex-col gap overflow-hidden">
          <ActivityLogModalPermission products={logItem.products} />
        </div>
      </div>
    </Modal>
  );
}
