import {
  FETCH_DEAL_PATH_GOALS,
  FETCH_DEAL_PATH_SETTINGS,
  UPDATE_DEAL_PATH_CATEGORY,
  UPDATE_DEAL_PATH_EMPLOYEE,
  UPDATE_DEAL_PATH_FIELD,
  SYNC_DEAL_PATH_FIELD,
  UPDATE_DEAL_PATH_GOAL,
  UPDATE_DEAL_PATH_PROPERTY_TYPE,
  UPDATE_DEAL_PATH_TEAM,
  UPDATE_DEAL_PATH_USER_ROLE,
  REMOVE_DEAL_PATH_EMPLOYEE,
  CREATE_DEAL_PATH_EMPLOYEE,
} from '@/bundles/Settings/actions/dealPathSettings';

const initialState = {
  goals: null,
  categories: null,
  teams: null,
  propertyTypes: null,
  dealPathFields: null,
  userRoles: null,
  employees: null,
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case FETCH_DEAL_PATH_SETTINGS:
      return {
        ...payload,
        activePropertyTypes: payload.propertyTypes
          .filter((item) => item.active)
          .map((item) => item.name),
      };
    case FETCH_DEAL_PATH_GOALS:
      return { ...state, goals: payload };
    case UPDATE_DEAL_PATH_CATEGORY:
      return {
        ...state,
        categories: state.categories.map((category) =>
          category.id === payload.id ? payload : category,
        ),
      };
    case UPDATE_DEAL_PATH_GOAL:
      return {
        ...state,
        goals: state.goals.map((goal) =>
          goal.id === payload.id ? payload : goal,
        ),
      };
    case UPDATE_DEAL_PATH_TEAM:
      return {
        ...state,
        teams: state.teams.map((team) =>
          team.id === payload.id ? payload : team,
        ),
      };
    case UPDATE_DEAL_PATH_PROPERTY_TYPE:
      return {
        ...state,
        propertyTypes: state.propertyTypes.map((pt) =>
          pt.id === payload.id ? payload : pt,
        ),
      };
    case UPDATE_DEAL_PATH_FIELD:
      return {
        ...state,
        dealPathFields: state.dealPathFields.map((f) =>
          f.id === payload.id ? payload : f,
        ),
      };
    case SYNC_DEAL_PATH_FIELD:
      return {
        ...state,
        usageCount: state.usageCount + 1,
        isDisplayWarningCount:
          state.usageCount + 1 >=
          state.limitDailyUsage - state.lastCountDailyUsage,
      };

    case CREATE_DEAL_PATH_EMPLOYEE:
      return {
        ...state,
        employees: [payload, ...state.employees],
      };
    case UPDATE_DEAL_PATH_EMPLOYEE:
      return {
        ...state,
        employees: state.employees.map((f) =>
          f.id === payload.id ? payload : f,
        ),
      };
    case REMOVE_DEAL_PATH_EMPLOYEE:
      return {
        ...state,
        employees: state.employees.filter((f) => f.id !== payload.id),
      };
    case UPDATE_DEAL_PATH_USER_ROLE:
      return {
        ...state,
        userRoles: state.userRoles.map((role) =>
          role.id === payload.id ? payload : role,
        ),
      };
    default:
      return state;
  }
}
