import React, { useState } from 'react';
import { Modal } from 'stories/Modals/Modal/Modal';
import { IJobCostCodeOnIndex } from 'types/JobCostCode';
import SingleForm from 'bundles/Shared/components/GroupForm/SingleForm';
import { IGeneralLedger } from 'types/GeneralLedger';
import {
  createJobCostCode,
  updateJobCostCode,
} from 'bundles/Shared/actions/job_cost_codes';
import { getJCCItems } from '@/bundles/Settings/components/REport/JobCostCodes/initData';
import { useParams } from '@reach/router';

export default function UpsertModal(props: {
  handleClose: () => void;
  item: IJobCostCodeOnIndex | null;
  onSuccess: () => void;
  indiviualSettings?: boolean;
  generalLedgers?: IGeneralLedger[];
}) {
  const params = useParams();
  const [formData, setFormData] = useState({
    name: props.item?.name ?? '',
    code: props.item?.code ?? '',
    general_ledgers:
      props.item?.generalLedgers?.map((gl) => ({
        value: gl.id,
        label: `${gl.accNumber} - ${gl.accTitle}`,
      })) ?? [],
  });

  const onSubmit = async () => {
    const payload = {
      ...formData,
      general_ledger_ids: formData.general_ledgers.map((gl) => gl.value),
    };

    const promise = props.item
      ? updateJobCostCode(props.item.id, payload, params.legalEntityCode)
      : createJobCostCode(payload);

    promise.then((responseData) => {
      if (!responseData.errors) {
        props.onSuccess();
        props.handleClose();
        reset();
      } else {
        return false;
      }
    }, null);
  };

  return (
    <Modal
      header={props.item ? 'JCC update' : 'JCC create'}
      toggle={props.handleClose}
      classes={{
        header: 'bg-light',
        body: 'bg-light overflow-y-visible',
        footer: 'bg-light',
      }}
      contentClassName="overflow-visible"
    >
      <SingleForm
        formData={formData}
        setFormData={setFormData}
        singleFormItems={getJCCItems(
          props.generalLedgers,
          props.indiviualSettings,
        )}
        buttonConfig={{
          titleBtn: 'Submit',
          funcCancel: props.handleClose,
        }}
        onSubmit={onSubmit}
      />
    </Modal>
  );
}
