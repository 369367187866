import { View, StyleSheet, Text } from '@react-pdf/renderer';
import React, { FC } from 'react';
import { sumBy, startCase } from 'lodash-es';
import { SmallCell } from 'bundles/Shared/components/PDFLibrary/ui/table/SmallCell';
import {
  DEFAULT_VAR_PDF,
  uiPDFStyles,
} from 'bundles/Shared/components/PDFLibrary/ui/uiPDFStyles';
import {
  IDrawPreviewItem,
  COLUMN_PAGE_SUMMARY,
  ITotalProjectCosts,
  COLUMN_PAGE_SUMMARY_FS,
} from '@/bundles/DrawPackage/DrawPage/components/DrawPageSummary';
import { formatAmount } from '@/shared/lib/formatting/number';

interface IBodyTableDPSProps {
  data: {
    categories: IDrawPreviewItem[];
    totalProjectCosts: ITotalProjectCosts;
  };
}

const BodyTableDPS: FC<IBodyTableDPSProps> = ({ data }) => {
  const styles = StyleSheet.create({
    body: {
      flexDirection: 'row',
      width: '100%',
    },
    categoryCol: {
      width: '14%',
      borderBottomWidth: 0.5,
      borderLeftWidth: 0.5,
      borderColor: DEFAULT_VAR_PDF.borderDarkColor,
      borderStyle: 'solid',
      justifyContent: 'center',
      paddingHorizontal: 2,
    },
    subCategoryCol: {
      width: '86%',
      borderBottomWidth: 0.5,
      borderLeftWidth: 0.5,
      borderColor: DEFAULT_VAR_PDF.borderDarkColor,
      borderStyle: 'solid',
      justifyContent: 'center',
      alignItems: 'center',
    },
    valueCol: {
      flex: 1,
      textAlign: 'right',
      alignItems: 'flex-end',
      borderLeftWidth: 0.5,
      borderColor: DEFAULT_VAR_PDF.borderDarkColor,
      borderStyle: 'solid',
      paddingHorizontal: 2,
      paddingTop: 2,
    },
    valueColTotal: {
      flex: 1,
      textAlign: 'right',
      alignItems: 'flex-end',
      borderLeftWidth: 0.5,
      borderColor: DEFAULT_VAR_PDF.borderDarkColor,
      borderStyle: 'solid',
      padding: 2,
      backgroundColor: DEFAULT_VAR_PDF.backgroundSubTotal,
    },
    totalTitle: {
      fontSize: 9,
      textTransform: 'uppercase',
      fontFamily: 'Helvetica-Bold',
      padding: 2,
      color: '#fff',
    },
    totalText: {
      color: '#fff',
      fontSize: 8,
      fontFamily: 'Helvetica-Bold',
      padding: 2,
    },
  });

  const calcTotal = (fieldName: keyof IDrawPreviewItem) =>
    sumBy(data?.categories, (item) => Number(item[fieldName])) || 0;

  const calcTotalFS = (fieldName: string) =>
    sumBy(Object.values(data?.totalProjectCosts), (item) =>
      Number(item.totals[fieldName]),
    );

  if (!data) return <View />;

  return (
    <View style={styles.body}>
      <View
        style={{
          width: '100%',
        }}
      >
        {data.categories.map((category, idCategory) => (
          <View
            key={`category${idCategory}`}
            style={{
              width: '100%',
              flexDirection: 'row',
              borderRightWidth: 1,
              borderColor: DEFAULT_VAR_PDF.borderDarkColor,
              borderStyle: 'solid',
            }}
          >
            <View style={styles.categoryCol}>
              <Text style={uiPDFStyles.smallTitle}>{category.code}</Text>
            </View>
            <View style={styles.subCategoryCol}>
              {category.subcategories.map((subcategory, idSubCategory) => (
                <View
                  key={`subCategory${idSubCategory}`}
                  style={{
                    width: '100%',
                    flexDirection: 'row',
                  }}
                >
                  <View
                    style={{
                      width: '16.2%',
                      paddingHorizontal: 2,
                      paddingTop: 2,
                      paddingBottom:
                        category.subcategories.length === idSubCategory + 1
                          ? 6
                          : 0,
                    }}
                  >
                    <Text style={uiPDFStyles.smallText}>
                      {subcategory.code}
                    </Text>
                  </View>
                  {COLUMN_PAGE_SUMMARY.map((columnName) => (
                    <SmallCell
                      key={columnName}
                      styles={{
                        wrapper: styles.valueCol,
                      }}
                    >
                      {formatAmount(subcategory[columnName])}
                    </SmallCell>
                  ))}
                  <View style={styles.valueCol}>
                    <Text style={uiPDFStyles.smallText}>
                      {subcategory.percent}%
                    </Text>
                  </View>
                </View>
              ))}
              <View
                style={{
                  width: '100%',
                  flexDirection: 'row',
                }}
              >
                <View
                  style={{
                    width: '16.2%',
                    paddingHorizontal: 2,
                    paddingTop: 2,
                    backgroundColor: DEFAULT_VAR_PDF.backgroundSubTotal,
                  }}
                >
                  <Text style={uiPDFStyles.lightSmallTitle}>
                    Total {category.code.substring(0, 17)}
                  </Text>
                </View>
                {COLUMN_PAGE_SUMMARY.map((columnName) => (
                  <SmallCell
                    key={`${columnName}`}
                    styles={{
                      wrapper: styles.valueColTotal,
                      text: uiPDFStyles.lightSmallTitle,
                    }}
                  >
                    {formatAmount(category[columnName])}
                  </SmallCell>
                ))}
                <View style={styles.valueColTotal}>
                  <Text style={uiPDFStyles.lightSmallTitle}>
                    {category.percent}%
                  </Text>
                </View>
              </View>
            </View>
          </View>
        ))}
        <View
          style={{
            width: '100%',
            flexDirection: 'row',
            backgroundColor: DEFAULT_VAR_PDF.backgroundTotal,
            borderLeftWidth: 1,
            borderBottomWidth: 1,
            borderRightWidth: 1,
            borderColor: DEFAULT_VAR_PDF.borderDarkColor,
            borderStyle: 'solid',
          }}
        >
          <View
            style={{
              width: '28%',
              paddingHorizontal: 2,
              paddingTop: 2,
            }}
          >
            <Text style={styles.totalTitle}>Total</Text>
          </View>
          {COLUMN_PAGE_SUMMARY.map((columnName) => (
            <SmallCell
              key={columnName}
              styles={{
                wrapper: styles.valueCol,
                text: styles.totalText,
              }}
            >
              {columnName === 'reallocatedAmount'
                ? ''
                : formatAmount(calcTotal(columnName))}
            </SmallCell>
          ))}
          <View style={styles.valueCol}>
            <Text style={styles.totalText}>
              {(
                calcTotal('fundedToDateAmount') / calcTotal('budgetAmount')
              ).toFixed(2)}
              %
            </Text>
          </View>
        </View>
        {Object.keys(data.totalProjectCosts).map((kind) => (
          <View
            key={kind}
            style={{
              width: '100%',
              flexDirection: 'row',
              borderLeftWidth: 1,
              borderBottomWidth: 1,
              borderRightWidth: 1,
              borderColor: DEFAULT_VAR_PDF.borderDarkColor,
              borderStyle: 'solid',
            }}
          >
            <View
              style={{
                width: '9%',
                paddingHorizontal: 2,
                paddingTop: 2,
              }}
            >
              <Text style={uiPDFStyles.smallTitle}>{startCase(kind)}</Text>
            </View>
            <View
              style={{
                width: '5%',
                paddingHorizontal: 2,
                paddingTop: 2,
                borderLeftWidth: 1,
                borderColor: DEFAULT_VAR_PDF.borderColor,
                borderStyle: 'solid',
              }}
            >
              <Text style={uiPDFStyles.smallTitle}>
                {data.totalProjectCosts[kind].percentOfBudget}%
              </Text>
            </View>
            <View
              style={{
                width: '14%',
                paddingHorizontal: 2,
              }}
            >
              {data.totalProjectCosts[kind].fundingSources.map(
                (fundingSource, idx: number) => (
                  <SmallCell
                    key={fundingSource.name}
                    styles={{
                      wrapper: {
                        ...styles.valueCol,
                        textAlign: 'left',
                        alignItems: 'flex-start',
                        // eslint-disable-next-line max-len
                        paddingBottom:
                          idx + 1 ===
                          data.totalProjectCosts[kind].fundingSources.length
                            ? 6
                            : 0,
                      },
                      text: uiPDFStyles.smallTitle,
                    }}
                  >
                    {fundingSource.name}
                  </SmallCell>
                ),
              )}
              <View
                style={{
                  width: '100%',
                  padding: 2,
                  backgroundColor: DEFAULT_VAR_PDF.backgroundSubTotal,
                }}
              >
                <Text style={uiPDFStyles.lightSmallTitle}>
                  Net Funded by {startCase(kind)}
                </Text>
              </View>
            </View>
            <View
              style={{
                flex: 1,
              }}
            >
              {data.totalProjectCosts[kind].fundingSources.map(
                (fundingSource, idx: number) => (
                  <View
                    key={fundingSource.name}
                    style={{
                      flexDirection: 'row',
                      width: '100%',
                    }}
                  >
                    {COLUMN_PAGE_SUMMARY_FS.map((columnName) => (
                      <SmallCell
                        key={`${columnName}`}
                        styles={{
                          wrapper: {
                            ...styles.valueCol,
                            // eslint-disable-next-line max-len
                            paddingBottom:
                              idx + 1 ===
                              data.totalProjectCosts[kind].fundingSources.length
                                ? 6
                                : 0,
                          },
                          text: uiPDFStyles.smallText,
                        }}
                      >
                        {columnName === 'empty'
                          ? ''
                          : formatAmount(fundingSource[columnName])}
                      </SmallCell>
                    ))}
                    <SmallCell
                      styles={{
                        wrapper: {
                          ...styles.valueCol,
                          // eslint-disable-next-line max-len
                          paddingBottom:
                            idx + 1 ===
                            data.totalProjectCosts[kind].fundingSources.length
                              ? 6
                              : 0,
                        },
                        text: uiPDFStyles.smallText,
                      }}
                    >
                      {fundingSource.completePercent}%
                    </SmallCell>
                  </View>
                ),
              )}
              <View
                style={{
                  flexDirection: 'row',
                  width: '100%',
                }}
              >
                {COLUMN_PAGE_SUMMARY_FS.map((columnName) => (
                  <SmallCell
                    key={`${columnName}`}
                    styles={{
                      wrapper: styles.valueColTotal,
                      text: uiPDFStyles.lightSmallTitle,
                    }}
                  >
                    {columnName === 'empty'
                      ? ''
                      : formatAmount(
                          data.totalProjectCosts[kind].totals[columnName],
                        )}
                  </SmallCell>
                ))}
                <SmallCell
                  styles={{
                    wrapper: styles.valueColTotal,
                    text: uiPDFStyles.lightSmallTitle,
                  }}
                >
                  {data.totalProjectCosts[kind].totals.completePercent}%
                </SmallCell>
              </View>
            </View>
          </View>
        ))}
        <View
          style={{
            width: '100%',
            flexDirection: 'row',
            backgroundColor: DEFAULT_VAR_PDF.backgroundTotal,
            borderLeftWidth: 1,
            borderBottomWidth: 1,
            borderRightWidth: 1,
            borderColor: DEFAULT_VAR_PDF.borderDarkColor,
            borderStyle: 'solid',
          }}
        >
          <View
            style={{
              width: '28%',
              paddingHorizontal: 2,
              paddingTop: 2,
            }}
          >
            <Text style={styles.totalTitle}>Total</Text>
          </View>
          {COLUMN_PAGE_SUMMARY_FS.map((columnName) => (
            <SmallCell
              key={columnName}
              styles={{
                wrapper: styles.valueCol,
                text: styles.totalText,
              }}
            >
              {columnName === 'empty'
                ? ''
                : formatAmount(calcTotalFS(columnName))}
            </SmallCell>
          ))}
          <View style={styles.valueCol}>
            <Text style={styles.totalText} />
          </View>
        </View>
      </View>
    </View>
  );
};

export default BodyTableDPS;
