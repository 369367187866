import React from 'react';
import { cn } from '@/shared/lib/css/cn';

export function SeparatorLine({
  hidden,
  position,
}: {
  position: 'left' | 'right';
  hidden: boolean;
}) {
  return (
    <span
      className={cn('h-0.5 w-full', {
        'bg-transparent': hidden,
        'bg-light-10': !hidden,
        'rounded-r': position === 'left',
        'rounded-l': position === 'right',
      })}
    />
  );
}
