import { EntityToastrCreator } from '@/shared/lib/toastr/entityToastrCreator';
import { userProfileGeneratedApi } from '@/widgets/userProfile/api/userProfileGeneratedApi';
const USER_PROFILE_TAG = 'UserProfile';

const etc = new EntityToastrCreator('User');

export const userProfileEnhancedApi = userProfileGeneratedApi.enhanceEndpoints({
  addTagTypes: [USER_PROFILE_TAG],
  endpoints: {
    getApiUserProfileUser: {
      providesTags: [USER_PROFILE_TAG],
    },
    putApiUserProfileUser: {
      invalidatesTags: [USER_PROFILE_TAG],
      transformResponse: (response) => {
        if (!response.errors) {
          toastr.success(etc.update().toString());
        }
        return response;
      },
    },
  },
});

export const {
  useGetApiUserProfileUserQuery,
  usePutApiUserProfileUserMutation,
} = userProfileEnhancedApi;
