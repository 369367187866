import React from 'react';
import { Button } from '@/stories/Button/Button';
import { Modal } from '@/stories/Modals/Modal/Modal';
import { ThinTabGroup } from '@/stories/Tabs/ThinTabGroup/ThinTabGroup';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import { useTabs } from 'stories/Tabs/useTabs';
import { FORMULA_AND_VARIABLES_TABS } from 'bundles/Shared/entities/formula/config';
import { FormulaList, VariableList } from 'bundles/Shared/widgets/formula/list';
import { Formula, FormulaInUse } from 'bundles/Shared/entities/formula';

type Props = DialogProps<Formula['reference']>;

export function ReplaceWithFormulaModal({ onClose, onSubmit }: Props) {
  const { tab, thinTabGroupProps } = useTabs(
    FORMULA_AND_VARIABLES_TABS,
    FORMULA_AND_VARIABLES_TABS[0],
  );
  return (
    <Modal
      toggle={onClose}
      header="Replace with"
      classes={{
        body: 'flex flex-col gap-4',
      }}
      bodyHeight={900}
    >
      <ThinTabGroup fullWidth {...thinTabGroupProps} />
      {tab?.id === 'formulas' && (
        <FormulaList
          filterInvalidFormulas
          formulaCardProps={(formula) => ({
            className: 'group-item',
            labelPanel: (
              <>
                <FormulaInUse.Popover
                  referencedInEntities={formula.referencedInEntities}
                >
                  <FormulaInUse.Label
                    count={formula.referencedInEntities.length}
                  />
                </FormulaInUse.Popover>
                <div className="grow" />
                <Button
                  className="invisible group-hover:!visible"
                  variant="secondary"
                  size="xs"
                  onClick={() => onSubmit?.(formula.reference)}
                >
                  Replace With
                </Button>
              </>
            ),
          })}
        />
      )}
      {tab?.id === 'variables' && <VariableList onSelect={onSubmit} />}
    </Modal>
  );
}
