import { CssVar } from '@/shared/config/cssVar';
import { TableVizConfigColumnGroup } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/model';
import { getCssVariableByAccessor } from 'lib/helpers';
import { IconsId } from 'types/sre-icons';
import * as yup from 'yup';

export const KPI_TABLE_WIDGET_CONFIG_COLUMN_GROUP_SCHEMA = yup.object().shape({
  key: yup.string(),
  label: yup.string().required(),
  color: yup.string().nullable(),
  icon: yup.string().nullable(),
});

export type KpiTableWidgetColumnGroupForm = yup.InferType<
  typeof KPI_TABLE_WIDGET_CONFIG_COLUMN_GROUP_SCHEMA
>;

export const KPI_COLUMN_GROUP_ICONS = [
  'apartments',
  'asset',
  'funds',
  'unit',
  'averageRent',
  'distribution',
  'userAlt',
  'businessman',
  'builder',
  'bag',
  'archive',
  'folder',
  'feedMessageAlt',
  'documentAlt',
  'budgetCover',
  'calculationsAlt',
  'calendar',
  'clock',
  'logout',
  'credit',
  'debit',
  'economicAlt',
  'fin',
  'analys',
  'inProgress',
  'layerFilled',
  'segments',
  'groupByFilled',
  'draft',
  'archived',
  'link',
  'check',
  'location',
  'lock',
  'nodeOpen',
  'reload',
  'sync',
  'swap',
  'share',
  'tag',
  'access',
  'actionsAlt',
] as const satisfies readonly IconsId[];

export const getColumnGroupColors = () => [
  {
    value: getCssVariableByAccessor(CssVar.neutral900),
  },
  {
    value: getCssVariableByAccessor(CssVar.violet055),
  },
  {
    value: getCssVariableByAccessor(CssVar.indigo055),
  },
  {
    value: getCssVariableByAccessor(CssVar.danger055),
  },
  {
    value: getCssVariableByAccessor(CssVar.pink055),
  },
  {
    value: getCssVariableByAccessor(CssVar.pumpkin055),
  },
  {
    value: getCssVariableByAccessor(CssVar.attention055),
  },
  {
    value: getCssVariableByAccessor(CssVar.teal055),
  },
  {
    value: getCssVariableByAccessor(CssVar.success055),
  },
];

export const transformColGroupToGroupForm = (
  colGroup: TableVizConfigColumnGroup,
): KpiTableWidgetColumnGroupForm => {
  return {
    key: colGroup.group_id,
    label: colGroup.header_name,
    color: colGroup.background,
    icon: colGroup.icon,
  };
};
