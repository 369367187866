import {
  usePutApiSettingsReportBuilderEagleEyeTemplatesByIdMutation,
  usePutApiSettingsReportBuilderTemplatesByIdMutation,
} from '@/entities/report/reportBuilder/api/settingsReportBuilderTemplatesEnhancedApi';
import type {
  PutApiSettingsReportBuilderEagleEyeTemplatesByIdApiArg,
  PutApiSettingsReportBuilderTemplatesByIdApiArg,
  ReportBuilderTemplateDto,
} from '@/entities/report/reportBuilder/api/settingsReportBuilderTemplatesGeneratedApi';
import { useReportingEntityKindContext } from '@/entities/reporting/context/entityKind';

export const useReportBuilderTemplateUpdate = () => {
  const templateKind = useReportingEntityKindContext();
  const [updateTemplate, updateOptions] =
    usePutApiSettingsReportBuilderTemplatesByIdMutation();

  const [updateEagleEyeTemplate, updateEagleEyeOptions] =
    usePutApiSettingsReportBuilderEagleEyeTemplatesByIdMutation();
  const isLoading = updateOptions.isLoading || updateEagleEyeOptions.isLoading;

  return [
    (
      templateForm: (
        | PutApiSettingsReportBuilderTemplatesByIdApiArg
        | PutApiSettingsReportBuilderEagleEyeTemplatesByIdApiArg
      ) &
        Pick<ReportBuilderTemplateDto, 'id'>,
    ) => {
      if (templateKind === 'eagle_eye') {
        return updateEagleEyeTemplate({
          id: templateForm.id,
          body: templateForm.body,
        });
      }

      return updateTemplate({
        id: templateForm.id,
        body: templateForm.body,
      });
    },
    { isLoading },
  ] as const;
};
