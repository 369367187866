import {
  useCreateEntityTag,
  useDeleteEntityTag,
  useEditEntityTag,
} from '@/features/report/tag/crud';
import { Button } from '@/stories/Button/Button';
import { IconButton } from '@/stories/IconButton/IconButton';
import {
  EntityTag,
  EntityTagKind,
  isTagSystem,
  TagEntity,
} from 'bundles/Shared/entities/tag';
import { IS_UNIFORM } from 'lib/http';

type EntityProps = {
  entity: TagEntity;
};

type TagProps = {
  tag: EntityTag;
};

export const EditTagButton = ({ tag, entity }: TagProps & EntityProps) => {
  const editTag = useEditEntityTag(entity);
  if (isTagSystem(tag) && !IS_UNIFORM) return null;
  return <IconButton onClick={() => editTag(tag)} iconName="edit" size="s" />;
};

export const DeleteTagButton = ({ tag, entity }: TagProps & EntityProps) => {
  const deleteTag = useDeleteEntityTag(entity);
  if (isTagSystem(tag) && !IS_UNIFORM) return null;
  return (
    <IconButton onClick={() => deleteTag(tag)} iconName="trash" size="s" />
  );
};

export const CreateTagButton = (
  props: EntityProps & {
    system?: boolean;
  },
) => {
  const createTag = useCreateEntityTag(props.entity);
  const handleClick = () =>
    createTag({
      tag: {
        kind: props.system ? EntityTagKind.System : EntityTagKind.Other,
      },
    });
  if (props.system && !IS_UNIFORM) return null;
  return (
    <Button onClick={handleClick} size="xs" variant="secondary">
      Add
    </Button>
  );
};
