import { useCallback } from 'react';
import type { FieldValues, UseFormReturn } from 'react-hook-form';

export const useFormOnSetValue = <
  TFieldValues extends FieldValues,
  TransformedValues extends FieldValues | undefined = undefined,
>(
  methods: UseFormReturn<TFieldValues, TransformedValues>,
) => {
  return useCallback(
    (
      ...args: [
        Parameters<typeof methods.setValue>[0],
        Parameters<typeof methods.setValue>[1],
      ]
    ) =>
      methods.setValue(args[0], args[1], {
        shouldDirty: true,
        shouldTouch: true,
        shouldValidate: true,
      }),
    [],
  );
};
