import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import { fetchUserRoles } from 'bundles/Assets/actions';
import React, { useEffect, useState } from 'react';
import { Button } from 'stories/Button/Button';
import { Modal } from 'stories/Modals/Modal/Modal';
import { ModalActions } from 'stories/Modals/Modal/ModalActions';
import { IAllRules } from 'types/ReportTableConfig';

import { useGetApiSettingsCoreFiltersUsersQuery } from 'bundles/UserManagement/api/settingsCoreLegalEntitiesApi';
import {
  createRoleRuleItemState,
  createRoleRulesState,
  createUserRuleItemState,
  createUserRulesState,
  prepareRoleRulesState,
  prepareUserRulesState,
} from '@/bundles/Settings/components/REport/TableBuilder/helpers/categoryRulesUtils';
import { useRuleOptions } from '@/bundles/Settings/components/REport/TableBuilder/helpers/hooks/useRuleOptions';
import { updatedRules } from '@/bundles/Settings/components/REport/TableBuilder/helpers/periodLimitationUtils';
import RulesHeader from '@/bundles/Settings/components/REport/TableBuilder/Rules/RulesHeader';
import styles from '@/bundles/Settings/components/REport/TableBuilder/Rules/sharedStyles.module.scss';
import { AccessType } from '@/bundles/Settings/components/REport/TableBuilder/types';
import { CategoryRulesEntry } from '@/bundles/Settings/components/REport/TableBuilder/CategoryRules/CategoryRulesEntry';
import {
  RoleRule,
  UserRule,
} from '@/bundles/Settings/components/REport/TableBuilder/CategoryRules/types';

interface Props extends DialogProps<IAllRules> {
  allRules: IAllRules;
  categoryPath: string;
  label: string;
}

export function CategoryRulesModal({
  onClose,
  onSubmit,
  allRules,
  categoryPath,
  label,
}: Props) {
  const dispatch = useAppDispatch();
  const usersStore = useAppSelector((state) => state.users);
  const { userRoles: roles, userRolesConnected } = usersStore;

  const { data: users } = useGetApiSettingsCoreFiltersUsersQuery();

  const [roleRules, setRoleRules] = useState<RoleRule[]>(
    createRoleRulesState(allRules.roleRules),
  );
  const [userRules, setUserRules] = useState<UserRule[]>(
    createUserRulesState(allRules.userRules),
  );

  const onSave = () => {
    onSubmit?.({
      roleRules: prepareRoleRulesState(roleRules, roles, categoryPath),
      userRules: prepareUserRulesState(userRules, users ?? [], categoryPath),
    });
  };

  const resolveDisabled = () => {
    const emptyRules = !userRules.length && !roleRules.length;

    const userRulesInvalid = userRules.reduce(
      (prev, curr) => prev || curr.accessType === null || curr.userId === null,
      false,
    );
    const roleRulesInvalid = roleRules.reduce(
      (prev, curr) => prev || curr.accessType === null || curr.roleId === null,
      false,
    );

    if (emptyRules) return true;

    return userRulesInvalid || roleRulesInvalid;
  };

  const actions = (
    <ModalActions>
      <Button size="s" variant="secondary" onClick={onClose}>
        Cancel
      </Button>
      <Button
        size="s"
        disabled={resolveDisabled()}
        variant="success"
        onClick={onSave}
      >
        Set Rules
      </Button>
    </ModalActions>
  );

  const handleAddRoleRule = () => {
    setRoleRules((prevRules) => [...prevRules, createRoleRuleItemState()]);
  };
  const handleAddUserRule = () => {
    setUserRules((prevRules) => [...prevRules, createUserRuleItemState()]);
  };

  const handleRemoveRule = (
    id: Rule['id'],
    setState: React.Dispatch<React.SetStateAction<Rule[]>>,
  ) => {
    setState((prevRules) => prevRules.filter((r) => r.id !== id));
  };

  const handleChangeUserId = (
    id: UserRule['id'],
    userId: UserRule['userId'],
  ) => {
    setUserRules((prevRules) => updatedRules(prevRules, id, 'userId', userId));
  };

  const handleChangeRoleId = (
    id: RoleRule['id'],
    userId: RoleRule['roleId'],
  ) => {
    setRoleRules((prevRules) => updatedRules(prevRules, id, 'roleId', userId));
  };

  const handleUserRuleAccessTypeChange = (
    id: UserRule['id'],
    accessType: AccessType,
  ) => {
    setUserRules((prevRules) =>
      updatedRules(prevRules, id, 'accessType', accessType),
    );
  };
  const handleRoleRuleAccessTypeChange = (
    id: RoleRule['id'],
    accessType: AccessType,
  ) => {
    setRoleRules((prevRules) =>
      updatedRules(prevRules, id, 'accessType', accessType),
    );
  };

  const [allUserOptions, notSelectedUserOptions] = useRuleOptions(
    users ?? [],
    'fullName',
    userRules,
    'userId',
  );
  const [allRoleOptions, notSelectedRoleOptions] = useRuleOptions(
    roles,
    'name',
    roleRules,
    'roleId',
  );

  useEffect(() => {
    if (!userRolesConnected) {
      dispatch(fetchUserRoles());
    }
  }, []);

  return (
    <Modal
      header={
        <div>
          <p className="header6-bold text-neutral-900">Category Rules</p>
          <p className="label-regular text-neutral-500">{label}</p>
        </div>
      }
      toggle={onClose}
      actions={actions}
      classes={{
        body: 'bg-neutral-100 !px-0 !py-4',
      }}
      size="600"
    >
      <div className="flex flex-col px-l">
        <RulesHeader
          rulesLen={roleRules.length}
          onAddRule={handleAddRoleRule}
          ruleName="User Role Rule"
        />
        {roleRules.length > 0 && (
          <div className={styles.group}>
            {roleRules.map((rule) => (
              <CategoryRulesEntry
                type="role"
                rule={rule}
                key={rule.id}
                onRemove={() => handleRemoveRule(rule.id, setRoleRules)}
                onItemIdChange={handleChangeRoleId}
                allUserOptions={allRoleOptions}
                notSelectedUserOptions={notSelectedRoleOptions}
                onAccessTypeChange={handleRoleRuleAccessTypeChange}
              />
            ))}
          </div>
        )}
      </div>
      <span className={styles.divider} />
      <div className="flex flex-col px-l">
        <RulesHeader
          rulesLen={userRules.length}
          onAddRule={handleAddUserRule}
          ruleName="User Rule"
        />
        {userRules.length > 0 && (
          <div className={styles.group}>
            {userRules.map((rule) => (
              <CategoryRulesEntry
                type="user"
                key={rule.id}
                rule={rule}
                onRemove={() => handleRemoveRule(rule.id, setUserRules)}
                allUserOptions={allUserOptions}
                notSelectedUserOptions={notSelectedUserOptions}
                onItemIdChange={handleChangeUserId}
                onAccessTypeChange={handleUserRuleAccessTypeChange}
              />
            ))}
          </div>
        )}
      </div>
    </Modal>
  );
}
