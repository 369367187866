import React from 'react';
import prettyBytes from 'pretty-bytes';
import { cn } from '@/shared/lib/css/cn';
import { dateTransformer } from 'bundles/Shared/components/GroupForm/FormViewItem/transformerFormViewItem';
import styles from '@/stories/DocumentCard/DocumentCard.module.scss';
import { convertToMilliseconds } from '@/shared/lib/converters';

interface Props {
  size?: number;
  extension?: string;
  permissions?: string;
  date?: string;
}

const FileInfo = ({ size, extension, permissions, date }: Props) => (
  <p className="light-60 secondary-regular flex items-center">
    {size && prettyBytes(size)}
    {size && extension && ' · '}
    {extension && (
      <span className="text-uppercase mx-xs">{` ${extension}`}</span>
    )}
    {extension && date && ' · '}
    {date && (
      <span className="mx-xs">{` ${dateTransformer(
        convertToMilliseconds(date),
        'MM-dd-yy',
      )}`}</span>
    )}
    {extension && permissions && ' · '}
    {permissions && (
      <div className={cn(styles.permissions, 'ml-xs')}>{permissions}</div>
    )}
  </p>
);

export default FileInfo;
