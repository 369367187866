import {
  bulkSetObject,
  includeOrExcludeLE,
  setObjectForSingleLE,
  updateBulkLEClass,
  updateLegalEntity,
} from 'bundles/Settings/actions/legalEntity';
import LegalEntityModal from 'bundles/Settings/components/Portal/LegalEntities/LegalEntityModal';
import { ModalHeaderForUpdatingLE } from 'bundles/Settings/components/Portal/LegalEntities/ModalHeaderForSetObjectLE';
import {
  LegalEntityFieldOption,
  SetObjectForLEModal,
} from 'bundles/Settings/components/Portal/LegalEntities/UpdateLegalEntityModals';
import { settingsCoreLegalEntityActivityLogsApi } from 'bundles/Settings/components/Portal/LegalEntities/api/settingsCoreLegalEntityActivityLogsApi';
import {
  LegalEntitiesFilters,
  TFilterModel,
  useLegalEntityColumns,
} from 'bundles/Settings/components/Portal/LegalEntities/legalEntityColumns';
import BulkActionsPanel from 'bundles/Shared/components/BulkActionsPanel/BulkActionsPanel';
import { ViewPermissionedUsersModal } from 'bundles/Shared/components/Permissions/ViewPermissionedUsersModal';
import {
  FetchPermissionedUsersWithMetaResponse,
  LightUser,
} from 'bundles/Shared/components/Permissions/ViewPermissionedUsersModal/types';
import PermissionsModal from 'bundles/Shared/components/PermissionsModal';
import Table from 'bundles/Shared/components/Table/Table';
// import { resetFilter } from 'bundles/Shared/components/Table/filters/helpers';
import TablePagination from 'bundles/Shared/components/Table/pagination/TablePagination';
import {
  LeClassificationModal,
  LeClassification,
} from 'bundles/Shared/entities/leClasssification';
import { useGetApiSettingsCoreLegalEntitiesLegalEntitiablesQuery } from 'bundles/Shared/entities/legalEntity/api/settingsCoreLegalEntitiesApiEnhanced';
import { legalEntityPermissions } from 'bundles/Shared/legalEntityPermissions';
import { useAppDispatch } from '@/shared/lib/hooks/redux';
import { useModal } from '@/shared/lib/hooks/useModal';
import http from 'lib/http';
import { ASSET_PORTAL_PRODUCT_NAME } from 'lib/permissions';
import { omit, partition } from 'lodash-es';
import { useMemo, useState } from 'react';
import { AnimationLoader } from '@/stories/AnimationLoader/AnimationLoader';
import { Button } from '@/stories/Button/Button';
import { SearchInput } from '@/stories/FormControls/Inputs/SearchInput/SearchInput';
import { ThinTabGroupWithAmount } from '@/stories/ThinTabGroupWithAmount/ThinTabGroupWithAmount';
import { LegalEntity } from '@/entities/core/legalEntity';
import {
  settingsCoreLegalEntitiesEnhancedApi,
  useGetApiSettingsCoreLegalEntitiesMetaQuery,
  useGetApiSettingsCoreLegalEntitiesQuery,
} from '@/entities/core/legalEntity/api/settingsCoreLegalEntitiesEnhancedApi';
import { GetApiSettingsCoreLegalEntitiesApiArg } from '@/entities/core/legalEntity/api/settingsCoreLegalEntitiesGeneratedApi';
import { LegalEntityMappingButton } from '@/bundles/Settings/components/Portal/LegalEntities/components/LegalEntityMappingButton';
import { Router } from '@reach/router';
import { LegalEntitiyManagementPeriods } from '@/widgets/core/legalEntity/settings/ui/periods';

interface IScopeItem {
  id: string;
  scopeName: string;
  label: string;
  varName: string;
  amount?: number;
}

const SIZE_PER_PAGE = 50;
const DEFAULT_SCOPE = 'active-and-not-mapped';

export const updateLegalEntityObject = async (
  selectedObjectOption: LegalEntityFieldOption,
  row: LegalEntity,
) => {
  const [type, id] = selectedObjectOption.value.split('_');

  // TODO: legacy api call
  return setObjectForSingleLE(row.id, {
    legal_entitiable_type: type,
    legal_entitiable_id: id,
  });
};

const defaultFilters: LegalEntitiesFilters = {
  legalEntitiables: {
    asset_ids: [],
    fund_ids: [],
  },
  erpSystems: [],
  pmcIds: [],
};

export const LegalEntities = () => {
  const dispatch = useAppDispatch();

  const [currentLegalEntity, setCurrentLegalEntity] = useState<LegalEntity>();

  const { openModal, confirm } = useModal();

  const [pageParams, setPageParams] =
    useState<GetApiSettingsCoreLegalEntitiesApiArg>({
      page: 1,
      perPage: SIZE_PER_PAGE,
      sort: {
        field: 'name',
        order: 'asc',
      },
      scope: DEFAULT_SCOPE,
      firePermissions: false,
      query: undefined,
      ...defaultFilters,
    });

  const { page: currentPage, query: searchQuery } = pageParams;

  const setCurrentPage = (page: number) =>
    setPageParams({ ...pageParams, page });

  const setSearchQuery = (query: string) =>
    setPageParams((params) => ({
      ...params,
      page: 1,
      query,
    }));

  const { data: legalEntitiables, isLoading: isLegalEntitiablesLoading } =
    useGetApiSettingsCoreLegalEntitiesLegalEntitiablesQuery();
  const [allAssets, allFunds] = useMemo(
    () => partition(legalEntitiables ?? [], (l) => l.objectType === 'Asset'),
    [legalEntitiables],
  );

  const [selectedRows, setSelectedRows] = useState<LegalEntity[]>([]);

  const [permissionsModalOpened, setPermissionsModalOpened] = useState(false);
  const [bulkPermissionsModalOpened, setBulkPermissionsModalOpened] =
    useState(false);

  const legalEntitiesScope = pageParams.scope;
  const setLegalEntitiesScope = (
    scope: GetApiSettingsCoreLegalEntitiesApiArg['scope'],
  ) => {
    setPageParams((params) => ({
      ...params,
      scope,
      page: 1,
      ...defaultFilters,
    }));
  };

  const selectedFilters = useMemo(() => {
    return {
      legalEntitiables: {
        asset_ids: pageParams.legalEntitiables?.asset_ids ?? [],
        fund_ids: pageParams.legalEntitiables?.fund_ids ?? [],
      },
      erpSystems: pageParams.erpSystems ?? [],
      pmcIds: pageParams.pmcIds ?? [],
    };
  }, [pageParams.legalEntitiables, pageParams.erpSystems, pageParams.pmcIds]);

  const {
    data,
    isLoading: isLegalEntitiesLoading,
    isFetching: isLegalEntitiesFetching,
  } = useGetApiSettingsCoreLegalEntitiesQuery(pageParams);

  const { items: legalEntities, meta: legalEntitiesMeta } = data ?? {};

  const { data: legalEntitiesMetaData } =
    useGetApiSettingsCoreLegalEntitiesMetaQuery();

  const AVAILABLE_SCOPES = useMemo<IScopeItem[]>(
    () => [
      {
        id: 'active-and-mapped',
        scopeName: 'active-and-mapped',
        label: 'Active & Mapped',
        varName: 'mappedSize',
        amount: legalEntitiesMetaData?.mappedSize,
      },
      {
        id: 'active-and-not-mapped',
        scopeName: 'active-and-not-mapped',
        label: 'Active & Not Mapped',
        varName: 'notMappedSize',
        amount: legalEntitiesMetaData?.notMappedSize,
      },
      {
        id: 'excluded',
        scopeName: 'excluded',
        label: 'Excluded',
        varName: 'excludedSize',
        amount: legalEntitiesMetaData?.excludedSize,
      },
    ],
    [legalEntitiesMetaData],
  );

  const refreshData = () => {
    // TODO: should be refreshed by redux query
    // FIX: https://linear.app/symmetre/issue/FE-3446/[epic]-lemp-ui-updates-fe-v0#comment-fb07d15e
    dispatch(
      settingsCoreLegalEntitiesEnhancedApi.util.invalidateTags([
        'SettingsCoreLegalEntities',
      ]),
    );

    dispatch(
      settingsCoreLegalEntityActivityLogsApi.util.invalidateTags([
        'LegalEntityActivityLogsTag',
      ]),
    );
  };

  const setSelectedFilters = (filters: LegalEntitiesFilters) => {
    return setPageParams((params) => ({
      ...params,
      page: 1,
      ...filters,
    }));
  };

  const onSetObjectForSingleLE = async (
    selectedObjectOption: LegalEntityFieldOption,
    row: LegalEntity,
  ) => {
    const res = await updateLegalEntityObject(selectedObjectOption, row);

    if (!res) {
      return;
    }

    refreshData();
  };

  const onBulkSetClass = async (classification: LeClassification) => {
    const res = await updateBulkLEClass({
      classification,
      legalEntityIds: selectedRows.map(({ id }) => id),
    });
    if (!res) {
      return;
    }

    refreshData();
    setSelectedRows([]);
  };

  const onBulkSetObject = async (
    selectedObjectOption: LegalEntityFieldOption,
  ) => {
    const [type, objectId] = selectedObjectOption.value.split('_');
    const res = await bulkSetObject({
      legal_entitiable_type: type,
      legal_entitiable_id: Number(objectId),
      legal_entity_ids: selectedRows.map(({ id }) => id),
    });
    if (!res) {
      return;
    }
    refreshData();
    setSelectedRows([]);
  };

  const onClearCurrentLegalEntity = () => {
    setCurrentLegalEntity(undefined);
  };

  const openSetObjModalForSingleLE = async (row: LegalEntity) => {
    const res = await openModal(SetObjectForLEModal, {
      currentLegalEntities: [row],
    });

    if (res) {
      onSetObjectForSingleLE(res, row);
      refreshData();
    } else {
      onClearCurrentLegalEntity();
    }
  };

  const openSetLEClassifaction = async (row: LegalEntity) => {
    const res = await openModal(LeClassificationModal<false>, {
      header: (
        <ModalHeaderForUpdatingLE
          title="Set class for"
          currentLegalEntities={[row]}
        />
      ),
    });

    if (!res) return;

    await updateLegalEntity({
      id: row.id,
      classification: res,
    });

    refreshData();
  };

  const onViewPermissions = async (le: LegalEntity) => {
    await openModal(ViewPermissionedUsersModal, {
      fetchPermissionedUsersWithMeta: async () => {
        const res = await http.get(`/legal_entities/${le.id}/permissions`);
        const permissions = await res.json();

        return permissions as FetchPermissionedUsersWithMetaResponse<LightUser>;
      },
    });
  };

  const onOpenLegalEntityModal = async (
    _event?: React.MouseEvent<HTMLButtonElement>,
    legalEntity?: LegalEntity,
  ) => {
    const res = await openModal(LegalEntityModal, {
      currentLegalEntity: legalEntity,
    });

    if (!res) {
      return;
    }

    refreshData();
  };

  const tableColumns = useLegalEntityColumns({
    actions: {
      onViewPermissions,
      openSetObjModalForSingleLE,
      openSetLEClassifaction,
      setPermissionsModalOpened,
      setCurrentLegalEntity,
      setLegalEntitiesScope,
      refreshMeta: refreshData,
      setLegalEntityModalOpened: (legalEntity) => {
        onOpenLegalEntityModal(undefined, legalEntity);
      },
      setSelectedFilters,
      setSelectedRows,
      legalEntitiesScope,
      localResetFilter: () => {},
    },
    allAssets,
    allFunds,
    selectedFilters,
    confirm,
    fireStationOptions: {
      totals: omit(legalEntitiesMeta, 'perPage'),
    },
    pmcOptions: legalEntitiesMetaData?.propertyManagementCompanies ?? [],
    erpOptions: legalEntitiesMetaData?.erpSystems ?? [],
  });

  const handleFilterModelChange = (filterModel: TFilterModel) =>
    setPageParams((prevParams) => ({
      ...prevParams,
      currentPage: 1,
      firePermissions: filterModel.permissions_fire,
    }));

  const handleIncludeAndExclude = async () => {
    const [excludedIds, includedIds] = [
      selectedRows
        .filter((selectedRow) => selectedRow.excluded)
        .map(({ id }) => id),
      selectedRows
        .filter((selectedRow) => !selectedRow.excluded)
        .map(({ id }) => id),
    ];
    await Promise.all([
      excludedIds.length > 0
        ? includeOrExcludeLE({ ids: excludedIds, excluded: false })
        : Promise.resolve(true),
      includedIds.length > 0
        ? includeOrExcludeLE({ ids: includedIds, excluded: true })
        : Promise.resolve(true),
    ]);
    setSelectedRows([]);
    refreshData();
  };

  if (isLegalEntitiablesLoading)
    return <AnimationLoader className="static min-h-[360px]" />;

  const selectedLETabChanged = (newTab: IScopeItem) => {
    setLegalEntitiesScope(
      newTab.scopeName as GetApiSettingsCoreLegalEntitiesApiArg['scope'],
    );
    setSelectedRows([]);
  };

  if (isLegalEntitiesLoading) return <AnimationLoader />;

  return (
    <>
      {(bulkPermissionsModalOpened || permissionsModalOpened) && (
        <PermissionsModal
          title="Configure permissions"
          onClose={() => {
            setBulkPermissionsModalOpened(false);
            setPermissionsModalOpened(false);
          }}
          onSubmit={async (newPermissions) => {
            if (bulkPermissionsModalOpened) {
              await http
                .put('/legal_entities/bulk/update_permissions', {
                  ...legalEntityPermissions(newPermissions),
                  legal_entity_ids: selectedRows.map(({ id }) => id),
                })
                .then(() => setSelectedRows([]));
            }

            if (permissionsModalOpened && currentLegalEntity) {
              await http.put(`/legal_entities/${currentLegalEntity.id}`, {
                ...legalEntityPermissions(newPermissions),
              });
            }

            setLegalEntitiesScope(legalEntitiesScope);
            setPermissionsModalOpened(false);
            toastr.success('Permissions have been successfully updated');
          }}
          initialState={
            permissionsModalOpened ? currentLegalEntity?.permitted : null
          }
          initialTab={
            permissionsModalOpened && currentLegalEntity?.permitted.public
              ? 'public'
              : 'restricted'
          }
          productName={ASSET_PORTAL_PRODUCT_NAME}
          itemType="Legal Entity"
        />
      )}
      <div className="mt-4 flex gap-4" role="tablist">
        <ThinTabGroupWithAmount
          selectedItem={AVAILABLE_SCOPES.find(
            (scope) => legalEntitiesScope === scope.scopeName,
          )}
          onSelectedItemChange={selectedLETabChanged}
          items={AVAILABLE_SCOPES}
        />
      </div>
      <div className="mt-4 flex items-center justify-between">
        <div>
          <div className="flex items-center">
            <TablePagination
              loading={isLegalEntitiesLoading}
              currentPage={currentPage ?? 1}
              setCurrentPage={setCurrentPage}
              totalSize={legalEntitiesMeta?.totalSize ?? 0}
              sizePerPage={pageParams.perPage ?? 0}
            />
          </div>
        </div>
        <div className="flex items-center gap-2">
          <SearchInput
            size="s"
            placeholder="Search"
            delay={500}
            suggestions={[
              'Asset, Fund',
              'Legal Entity',
              'External Identifier (Code)',
            ]}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            resetValue={() => setSearchQuery('')}
          />
          <LegalEntityMappingButton />
          <Button variant="primary" size="s" onClick={onOpenLegalEntityModal}>
            Add Legal Entity
          </Button>
        </div>
      </div>
      <div className="mt-5">
        <Table
          nothingFoundClasses="mt-[8px]"
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          items={legalEntities ?? []}
          columns={tableColumns}
          loading={isLegalEntitiesFetching}
          settings={pageParams}
          setSettings={(settings) =>
            setPageParams({
              ...settings,
              sort: {
                field: settings.sortField,
                order: settings.sortOrder,
              },
            })
          }
          classes={{ container: 'table-container_legal-entities' }}
          onFilterModelChange={handleFilterModelChange}
        />
      </div>
      {/* TODO: review bulk actions */}
      {selectedRows.length > 0 && (
        <BulkActionsPanel
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          actions={[
            {
              title: 'Set Object',
              icon: 'edit',
              handleClick: async () => {
                const res = await openModal(SetObjectForLEModal, {
                  currentLegalEntities: selectedRows,
                });

                if (res) {
                  onBulkSetObject(res);
                  refreshData();
                  return;
                }

                onClearCurrentLegalEntity();
              },
            },
            {
              title: 'Set Class',
              icon: 'edit',
              handleClick: async () => {
                const res = await openModal(LeClassificationModal, {
                  header: (
                    <ModalHeaderForUpdatingLE
                      title="Set class for"
                      currentLegalEntities={selectedRows}
                    />
                  ),
                });
                if (!res) return;

                onBulkSetClass(res);
              },
            },
            {
              title: legalEntitiesScope === 'excluded' ? 'Include' : 'Exclude',
              handleClick: handleIncludeAndExclude,
              icon: legalEntitiesScope === 'excluded' ? 'eye' : 'eyeSlash',
            },
            {
              title: 'Set Permissions',
              icon: 'edit',
              handleClick: () => {
                setBulkPermissionsModalOpened(true);
              },
            },
          ]}
        />
      )}
      <Router primary={false}>
        <LegalEntitiyManagementPeriods path=":legalEntityId/periods" />
      </Router>
    </>
  );
};
