import React, { PropsWithChildren } from 'react';
import StepsFormStepv2 from 'stories/StepsFormStep/StepsFormStepv2';
import { CurrencyFormatter } from 'stories/ValueFormatters/CurrencyFormatter';

interface Props
  extends Pick<
    React.ComponentProps<typeof StepsFormStepv2>,
    'stepNumber' | 'activated' | 'title' | 'classes'
  > {
  total: number;
  currentTotal: number;
}

function EnterEntriesStep({
  total,
  currentTotal,
  children,
  ...props
}: Props & PropsWithChildren) {
  return (
    <StepsFormStepv2 required {...props}>
      <div className="flex gap-0.5 text-[0.75rem] text-neutral-500">
        {total > 0 || currentTotal > 0 ? (
          <>
            <CurrencyFormatter
              classes={{
                wrapper: 'secondary-semibold inline-flex',
                value: 'text-neutral-950',
              }}
              value={currentTotal}
            />
            of
            <CurrencyFormatter
              classes={{
                wrapper: 'secondary-semibold inline-flex',
                value: 'text-neutral-950',
              }}
              value={total}
            />
            Total
          </>
        ) : (
          <div className="h-[18px]" />
        )}
      </div>
      {children}
    </StepsFormStepv2>
  );
}

export default EnterEntriesStep;
