import { CompareToField } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/fields/CompareToField';

import { HighlightingRulesField } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/fields/HighlightingRulesField';
import { useFormContext, Control } from 'react-hook-form';

import { NumericColumnSettingsForm } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/table/form';
import { TableVizConfig } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/table/model';

export function HighlithingWithComparisonField<
  TFieldValues extends NumericColumnSettingsForm = NumericColumnSettingsForm,
>(
  props: {
    control: Control<TFieldValues>;
    columnsConfig: {
      key: string;
      label: string;
    }[];
    vizConfig: TableVizConfig;
  } & Pick<
    React.ComponentProps<typeof HighlightingRulesField>,
    'allowedHighlightingTypes'
  >,
) {
  const control = props.control as Control<NumericColumnSettingsForm>;
  const { columnsConfig, vizConfig, allowedHighlightingTypes } = props;
  const { watch } = useFormContext<NumericColumnSettingsForm>();
  const comparison = watch('comparison');
  const highlightingRules = watch('highlighting_rules');
  const hideNegativeValues = watch('hide_negative_values');
  return (
    <>
      <CompareToField
        disabled={highlightingRules != null || hideNegativeValues === true}
        columnsConfig={columnsConfig}
        control={control}
        name="comparison"
        vizConfig={vizConfig}
        disabledNote={
          hideNegativeValues === true
            ? undefined
            : 'Settings are not available because Highlighting Rules'
        }
      />

      <HighlightingRulesField
        disabled={comparison != null || hideNegativeValues === true}
        disabledNote={
          hideNegativeValues === true
            ? undefined
            : 'Settings are not available because Comparison is active'
        }
        control={control}
        name="highlighting_rules"
        allowedHighlightingTypes={allowedHighlightingTypes}
      />
    </>
  );
}
