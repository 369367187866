import React, { useState } from 'react';
import { Button } from 'stories/Button/Button';
import { Modal } from 'stories/Modals/Modal/Modal';
import { MultilineAlert } from 'stories/MultilineAlert/MultilineAlert';
import { RangeSlider } from 'stories/RangeSlider/RangeSlider';
import { Tab } from 'stories/Tab/Tab';
import { TabItem } from 'stories/TabItem/TabItem';
import { Badge } from 'stories/Badges/Badge/Badge';
import { CardWrapper } from 'bundles/Shared/components/Wrappers/CardWrapper';
import { connect } from 'react-redux';
import { cn } from '@/shared/lib/css/cn';
import { updateDealPathUserRole } from '@/bundles/Settings/actions/dealPathSettings';
import PercentInput from '@/bundles/Shared/components/PercentInput';
import RadarChartDealPathSettings from '@/bundles/Shared/components/Charts/RadarChartDealPathSettings';
import { CssVar } from '@/shared/config/cssVar';

const UtilisationRow = ({ name, position, onChange, target, weight }) => (
  <tr>
    <td style={{ width: '40%' }}>
      <div className="flex justify-between items-center">
        <div>
          <Badge backgroundColor={CssVar.light10} textColor={CssVar.light60}>
            {position}
          </Badge>
          <span className="font-16 ml-2 dark-60">{name}</span>
        </div>
        <PercentInput
          onChange={(e) => onChange({ target, weight: +e.target.value })}
          defaultValue={weight}
          min={0}
          max={100}
        />
      </div>
    </td>
    <td style={{ width: '60%' }}>
      <RangeSlider
        min={0}
        max={10}
        defaultValue={+target || 0}
        onFinish={(value) => onChange({ target: value, weight })}
      />
    </td>
  </tr>
);

const EmployeeUtilizationForm = ({ role, categories, onClose, onSubmit }) => {
  const [metrics, setMetrics] = useState(
    role.metrics.length > 0
      ? role.metrics
      : categories.map((c) => ({ deal_path_category_id: c.id })),
  );
  const totalWeight = metrics.reduce((acc, m) => acc + (m.weight || 0), 0);
  const tipMessage = () => {
    if (totalWeight > 100) {
      return <span className="text-red">The sum of points must equal 100</span>;
    }
    if (totalWeight === 100) {
      return (
        <span className="text-green">
          Great, You have distributed all the points!
        </span>
      );
    }

    return (
      <span>
        You have <strong>{100 - totalWeight} points</strong> to distribute by
        stages{' '}
      </span>
    );
  };

  const handleChange = (data, category) =>
    setMetrics(
      metrics.map((m) =>
        category.id !== m.deal_path_category_id
          ? m
          : { ...data, deal_path_category_id: category.id },
      ),
    );

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({ metrics });
  };

  return (
    <form onSubmit={handleSubmit}>
      <h2 className="mb-30">Metrics for {role.name}</h2>
      <div className="flex light-80 font-14 p-2">
        <div style={{ width: 180 }}>Stages</div>
        <div className="mr-4">Weight Points</div>
        <div className="mr-4">Weight Points</div>
        <div>Target</div>
      </div>
      <table className="table table-bordered curved-border">
        <tbody>
          {categories.map((category, i) => (
            <UtilisationRow
              key={category.id}
              name={category.name}
              position={i + 1}
              onChange={(data) => handleChange(data, category)}
              {...metrics.find((m) => m.deal_path_category_id === category.id)}
            />
          ))}
        </tbody>
      </table>
      <div className="flex light-80 font-14 px-2 text-right mb-4">
        <div style={{ width: 275 }} className="mr-4">
          Total
          <strong
            className={cn('ml-2 font-weight-600 d-inline-block', {
              'text-red': totalWeight > 100,
            })}
            style={{ width: 50 }}
          >
            {totalWeight} WP
          </strong>
        </div>
        <div>{tipMessage()}</div>
      </div>
      <div className="flex justify-between">
        <Button onClick={onClose} variant="secondary">
          Cancel
        </Button>
        <Button disabled={totalWeight !== 100} variant="success" type="submit">
          Save Metrics
        </Button>
      </div>
    </form>
  );
};

const EmployeeUtilization = ({ teams, categories, update }) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState(teams[0]);
  const [selectedRoleId, setSelectedRoleId] = useState(
    selectedTeam?.userRoles[0]?.id,
  );

  const selectedRole = () =>
    selectedTeam.userRoles.find((r) => r.id === selectedRoleId);

  const handleSubmit = async (data) => {
    await update(selectedRoleId, data);

    // TODO: didn't find a way to reload charts without hard refresh
    window.location.reload();
  };

  const utilisationData = () => {
    const total = selectedRole().metrics.reduce(
      (acc, m) => acc + m.target * (m.weight / 100),
      0,
    );

    return selectedRole().metrics.map((m) => ({
      utilization: (m.target * m.weight) / total,
      stage: categories.find((c) => c.id === m.deal_path_category_id)?.name,
    }));
  };

  if (!selectedRoleId) {
    return (
      <MultilineAlert
        status="info"
        message="Please adjust core settings in order to make any changes here"
      />
    );
  }

  return (
    <div>
      <CardWrapper className="bg-light">
        <div className="py-3 px-5">
          <h3>Employee Utilization</h3>
        </div>
        <div className="p-3">
          <Tab className="mb-50">
            {teams.map((team) => (
              <TabItem
                key={team.id}
                active={team.id === selectedTeam.id}
                onClick={() => {
                  setSelectedTeam(team);
                  setSelectedRoleId(team.userRoles[0].id);
                }}
              >
                {team.name}
              </TabItem>
            ))}
          </Tab>
          <Tab className="justify-center">
            {selectedTeam.userRoles.map((role) => (
              <TabItem
                variant="circle"
                key={role.id}
                active={role.id === selectedRoleId}
                onClick={() => setSelectedRoleId(role.id)}
              >
                {role.name}
              </TabItem>
            ))}
          </Tab>
        </div>
        <div className="px-5 py-5">
          <div>
            <RadarChartDealPathSettings
              name="employeeUtilization"
              data={utilisationData()}
            />
            <div className="text-center mt-30">
              <Button variant="primary" onClick={() => setShowModal(true)}>
                Change Metrics
              </Button>
            </div>
          </div>
        </div>
      </CardWrapper>
      {showModal && (
        <Modal
          toggle={() => setShowModal(false)}
          header={
            <span className="font-weight-400 font-16">{selectedTeam.name}</span>
          }
          classes={{
            header: 'bg-light',
            body: 'bg-light',
            footer: 'bg-light',
          }}
          size="lg"
        >
          <EmployeeUtilizationForm
            onSubmit={handleSubmit}
            onClose={() => setShowModal(false)}
            role={selectedRole()}
            categories={categories}
          />
        </Modal>
      )}
    </div>
  );
};

const mapStateToProps = ({ dealPathSettings }) => ({
  categories: dealPathSettings.categories.filter((c) => c.active),
  teams: dealPathSettings.teams.filter(
    (t) => t.active && t.userRoles.length > 0,
  ),
});

const mapActionCreators = {
  update: updateDealPathUserRole,
};

export default connect(mapStateToProps, mapActionCreators)(EmployeeUtilization);
