import {
  useNullableReportingWidgetGroupIdContext,
  useReportBuilderTemplateContext,
} from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/reportBuilderTemplateContext';
import { WidgetSectionMap } from '@/bundles/Shared/widgets/dashboard/widgets/config';
import {
  useGetApiSettingsReportBuilderGotenbergEagleEyeTemplatesByIdQuery,
  useGetApiSettingsReportBuilderGotenbergTemplatesByIdQuery,
  usePutApiSettingsReportBuilderEagleEyeTemplatesByEagleEyeTemplateIdGroupsAndGroupIdWidgetSectionsIdMutation,
  usePutApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndGroupIdWidgetSectionsIdMutation,
} from '@/entities/report/reportBuilder/api/settingsReportBuilderTemplatesEnhancedApi';
import { useNullableReportingEntityKindContext } from '@/entities/reporting/context/entityKind';
import { arrayMoveImmutable } from 'array-move';
import { useReportSettingsDashboardByIdOrSlug } from 'bundles/Settings/components/REport/Dashboards/Dashboard/lib';
import { ReportDashboardType } from 'bundles/Shared/entities/dashboard/model/types/types';
import type {
  AllWidgetTypes,
  PeriodShiftConfig,
  TrailingPeriodType,
} from 'bundles/Shared/entities/dashboard/model/types/types';
import { useDashboardContext } from 'bundles/Shared/entities/dashboard/lib';

import {
  useGetApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdWidgetSectionsIdQuery,
  useGetApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdWidgetSectionsIdQuery,
  useGetApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdWidgetSectionsIdQuery,
  usePutApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdWidgetSectionsIdMutation,
  usePutApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdWidgetSectionsIdMutation,
  usePutApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdWidgetSectionsIdMutation,
} from '@/shared/api/dashboardSettingsEnhancedApi';

import { useWidgetScreenParams } from 'bundles/Shared/widgets/dashboard/widgets/common/lib/data';
import {
  DEFAULT_DATE_RANGES,
  PERIOD_SHIFT_OPTIONS,
  PeriodShiftForm,
  PeriodTypeForm,
  RANGE_TO_PERIOD_SHIFT_MAP,
  TimePeriodType,
} from 'bundles/Shared/widgets/dashboard/widgets/common/config';
import { debounce } from 'lodash-es';
import { useCallback, useEffect, useMemo } from 'react';
import { DeepPartial, UseFormReturn } from 'react-hook-form';
import { UnknownRecord } from 'type-fest/source/internal';

export const useWidgetConfig = <WidgetType extends AllWidgetTypes>() => {
  const { widgetId, dashboardId, boardId } = useWidgetScreenParams();
  const { data: dashboard } = useReportSettingsDashboardByIdOrSlug(dashboardId);
  const templateCtx = useReportBuilderTemplateContext();
  const reportTemplateWidgetGroupId =
    useNullableReportingWidgetGroupIdContext();
  const templateKind = useNullableReportingEntityKindContext();

  const query = useMemo(() => {
    switch (dashboard?.type) {
      case ReportDashboardType.OBJECT: {
        return useGetApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdWidgetSectionsIdQuery;
      }
      case ReportDashboardType.EAGLE_EYE: {
        return useGetApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdWidgetSectionsIdQuery;
      }
      case ReportDashboardType.COMPARISON_MODE: {
        return useGetApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdWidgetSectionsIdQuery;
      }
      default: {
        return useGetApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdWidgetSectionsIdQuery;
      }
    }
  }, [
    dashboard,
    useGetApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdWidgetSectionsIdQuery,
    useGetApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdWidgetSectionsIdQuery,
    useGetApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdWidgetSectionsIdQuery,
  ]);

  if (templateCtx != null && reportTemplateWidgetGroupId != null) {
    if (templateKind === 'eagle_eye') {
      return useGetApiSettingsReportBuilderGotenbergEagleEyeTemplatesByIdQuery(
        {
          id: templateCtx.templateId,
        },
        {
          selectFromResult: (result) => {
            const group = result.data?.groups.find(
              (g) => g.id === reportTemplateWidgetGroupId,
            );
            const widget = group?.widgetSections.find(
              (w) => w.id === templateCtx.widgetId,
            );
            return {
              ...result,
              widget: widget as unknown as WidgetSectionMap[WidgetType],
              dashboardId,
            };
          },
        },
      );
    }
    return useGetApiSettingsReportBuilderGotenbergTemplatesByIdQuery(
      {
        id: templateCtx.templateId,
      },
      {
        selectFromResult: (result) => {
          const group = result.data?.groups.find(
            (g) => g.id === reportTemplateWidgetGroupId,
          );
          const widget = group?.widgetSections.find(
            (w) => w.id === templateCtx.widgetId,
          );
          return {
            ...result,
            widget: widget as unknown as WidgetSectionMap[WidgetType],
            dashboardId,
          };
        },
      },
    );
  }

  return query(
    {
      id: widgetId,
      boardId,
      objectDashboardId: (dashboard?.type === ReportDashboardType.OBJECT
        ? dashboardId
        : undefined) as string,
      comparisonDashboardId: (dashboard?.type ===
      ReportDashboardType.COMPARISON_MODE
        ? dashboardId
        : undefined) as string,
      eagleEyeDashboardId: (dashboard?.type === ReportDashboardType.EAGLE_EYE
        ? dashboardId
        : undefined) as string,
    },
    {
      selectFromResult: (result) => {
        return {
          ...result,
          widget: result.data! as unknown as WidgetSectionMap[WidgetType],
          dashboardId,
        };
      },
      skip: dashboard == null,
    },
  );
};

export const useUpdateWidgetConfig = <T extends AllWidgetTypes>(
  widgetType: T,
) => {
  const { widgetId, dashboardId, boardId } = useWidgetScreenParams();
  const { dashboardType } = useDashboardContext();
  const objectMutation =
    usePutApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdWidgetSectionsIdMutation();
  const eagleEyeMutation =
    usePutApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdWidgetSectionsIdMutation();
  const comparisonMutation =
    usePutApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdWidgetSectionsIdMutation();

  const templateCtx = useReportBuilderTemplateContext();
  const reportTemplateWidgetGroupId =
    useNullableReportingWidgetGroupIdContext();
  const templateKind = useNullableReportingEntityKindContext();

  const templateMutation =
    usePutApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndGroupIdWidgetSectionsIdMutation();
  const [reportBuilderTemplateMutation] = templateMutation;

  const eagleEyeTemplateMutation =
    usePutApiSettingsReportBuilderEagleEyeTemplatesByEagleEyeTemplateIdGroupsAndGroupIdWidgetSectionsIdMutation();
  const [reportBuilderTemplateEagleEyeMutation] = eagleEyeTemplateMutation;

  const [mutate, options] = useMemo(() => {
    if (templateCtx != null) {
      if (templateKind === 'eagle_eye') {
        return eagleEyeTemplateMutation;
      }
      return templateMutation;
    }
    switch (dashboardType) {
      case ReportDashboardType.OBJECT: {
        return objectMutation;
      }
      case ReportDashboardType.EAGLE_EYE: {
        return eagleEyeMutation;
      }
      case ReportDashboardType.COMPARISON_MODE: {
        return comparisonMutation;
      }
      case ReportDashboardType.REPORT_BUILDER_TEMPLATE_EAGLE_EYE: {
        return eagleEyeTemplateMutation;
      }
      case ReportDashboardType.REPORT_BUILDER_TEMPLATE: {
        return templateMutation;
      }
      default: {
        return [
          () => {
            console.error(`"${dashboardType}" this type not support`);
          },
          {},
        ];
      }
    }
  }, [dashboardType, templateCtx]);

  const handler = useCallback(
    async ({
      config,
      title,
      recapPageSlug,
    }: {
      config: WidgetSectionMap[T]['widgetConfig'];
      title?: string;
      recapPageSlug?: string;
    }) => {
      if (templateCtx != null && reportTemplateWidgetGroupId != null) {
        if (templateKind === 'eagle_eye') {
          return reportBuilderTemplateEagleEyeMutation({
            eagleEyeTemplateId: templateCtx.templateId,
            groupId: reportTemplateWidgetGroupId,
            id: templateCtx.widgetId,
            body: {
              title,
              config,
            },
          });
        }
        return reportBuilderTemplateMutation({
          templateId: templateCtx.templateId,
          groupId: reportTemplateWidgetGroupId,
          id: templateCtx.widgetId,
          body: {
            title,
            config,
          },
        });
      }
      return await mutate({
        id: widgetId,
        boardId,
        objectDashboardId: dashboardId,
        comparisonDashboardId: dashboardId,
        eagleEyeDashboardId: dashboardId,
        body: {
          widget_type: widgetType,
          title,
          config,
          recap_page_slug: recapPageSlug,
        },
      });
    },
    [widgetId, dashboardId, mutate, widgetType, templateCtx, templateKind],
  );
  const debouncedUpdateConfig = useCallback(debounce(handler, 500), [handler]);

  return [handler, options, debouncedUpdateConfig] as const;
};

export const useUpdateWidgetConfigEffect = <
  T extends UnknownRecord,
  WidgetType extends AllWidgetTypes,
>(
  methods: UseFormReturn<T>,
  updater: (
    values: DeepPartial<T>,
    widgetConfig: WidgetSectionMap[WidgetType]['widgetConfig'],
  ) => WidgetSectionMap[WidgetType]['widgetConfig'],
) => {
  const { widget } = useWidgetConfig();
  const [_, __, debouncedUpdate] = useUpdateWidgetConfig(widget.widgetType);
  const { watch } = methods;
  useEffect(() => {
    const sub = watch((values) => {
      debouncedUpdate({
        config: updater(values, widget.widgetConfig),
      });
    });

    return () => {
      sub.unsubscribe();
    };
  }, []);
};

export const maxIdGenerator = <T extends UnknownRecord>(
  list: T[],
  key: keyof T,
) => {
  return list.length === 0
    ? 0
    : Math.max(
        ...list.map((c) => {
          const valueNumber = Number(c[key]);

          if (Number.isNaN(valueNumber)) return 0;

          return valueNumber;
        }),
      ) + 1;
};

export const listUpdater = <T extends UnknownRecord, K extends keyof T>(
  list: T[],
  {
    key,
    idGenerator,
  }: {
    key: K;
    idGenerator?: (item: T, list: T[]) => T[K];
  },
) => {
  const moveItem = (oldIndex: number, newIndex: number) => {
    if (oldIndex === newIndex) {
      return;
    }
    return arrayMoveImmutable(list, oldIndex, newIndex);
  };

  const removeItem = (itemKey: T[K]) => {
    return list.filter((row) => row[key] !== itemKey);
  };

  const upsertItem = (item: T) => {
    const isItemExists = list.some((row) => row[key] === item[key]);
    let newItemId: number;
    if (idGenerator) {
      newItemId = idGenerator(item, list);
    } else {
      newItemId =
        list.length === 0
          ? 0
          : Math.max(...list.map((c) => Number(c[key]))) + 1;
    }
    if (isItemExists) {
      return list.map((row) => {
        if (row[key] === item[key]) {
          return item;
        }
        return row;
      });
    }
    return [
      ...list,
      {
        ...item,
        [key]: newItemId,
      },
    ];
  };

  return {
    removeItem,
    upsertItem,
    moveItem,
  } as const;
};

export const useUpdateListPropertyWidgetConfig = <
  T extends AllWidgetTypes,
  K extends keyof WidgetSectionMap[T]['widgetConfig'],
  WidgetConfig extends WidgetSectionMap[T]['widgetConfig'] & {
    [Key in K]: Array<UnknownRecord>;
  } = WidgetSectionMap[T]['widgetConfig'] & {
    [Key in K]: Array<UnknownRecord>;
  },
>(
  widgetType: T,
  listKey: K,
) => {
  const [updateColumnConfig] = useUpdateWidgetConfig(widgetType);
  const { widget } = useWidgetConfig<T>();
  const widgetConfig = widget.widgetConfig as WidgetConfig;

  const updateList = async (list: UnknownRecord[]) => {
    return await updateColumnConfig({
      config: {
        ...widgetConfig,
        [listKey]: list,
      },
    });
  };

  const moveItem = (oldIndex: number, newIndex: number) => {
    if (oldIndex === newIndex) {
      return;
    }
    return updateList(
      arrayMoveImmutable(widgetConfig[listKey], oldIndex, newIndex),
    );
  };

  const removeItem = (itemKey: number) => {
    return updateList(
      widgetConfig[listKey].filter((row) => row.key !== itemKey),
    );
  };

  const cloneItem = async (itemKey: number) => {
    const item = widgetConfig[listKey].find((row) => row.key === itemKey);
    if (!item) {
      return;
    }
    const newItemId = Math.max(
      ...widgetConfig[listKey].map((c) => Number(c.key)),
    );
    const newItem = {
      ...item,
      key: newItemId + 1,
    };

    const newWidgetConfig = {
      ...widgetConfig,
      [listKey]: [...widgetConfig[listKey], newItem],
    };

    if (widgetConfig.viz_config != null) {
      const newVizConfigItem = {
        ...widgetConfig.viz_config[listKey].find(
          (row) => row.key === itemKey.toString(),
        ),
        key: (newItemId + 1).toString(),
      };

      newWidgetConfig.viz_config = {
        ...widgetConfig.viz_config,
        [listKey]: widgetConfig.viz_config[listKey]
          .filter((listItem) => listItem.key !== newVizConfigItem.key)
          .concat(newVizConfigItem),
      };
    }

    return await updateColumnConfig({
      config: newWidgetConfig,
    });
  };

  const upsertItem = (item: WidgetConfig[K][number]) => {
    const isItemExists = widgetConfig[listKey].some(
      (row) => row.key === item.key,
    );
    const newRowId =
      widgetConfig[listKey].length === 0
        ? 0
        : Math.max(...widgetConfig[listKey].map((c) => Number(c.key))) + 1;
    const rows = !isItemExists
      ? [
          ...widgetConfig[listKey],
          {
            ...item,
            key: newRowId,
          },
        ]
      : widgetConfig[listKey].map((row) => {
          if (row.key === item.key) {
            return item;
          }
          return row;
        });

    return updateList(rows);
  };

  return {
    removeItem,
    upsertItem,
    moveItem,
    cloneItem,
  } as const;
};

export const transformPeriodShiftFormToDto = (
  periodShift: PeriodShiftForm | undefined | null,
): PeriodShiftConfig | undefined => {
  const { key, value } = periodShift ?? { key: undefined, value: undefined };
  const defaultPeriodShift = Object.fromEntries(
    PERIOD_SHIFT_OPTIONS.map((o) => [o, 0]),
  );

  if (periodShift == null) {
    return undefined;
  }

  return (
    key && value !== 0 && value != null
      ? {
          ...defaultPeriodShift,
          [key]: value ?? 0,
        }
      : defaultPeriodShift
  ) as PeriodShiftConfig;
};
export const transformPeriodShiftDtoToForm = (
  periodShift: Partial<PeriodShiftConfig> | undefined,
): PeriodShiftForm | undefined => {
  const nonZeroPeriodShift = Object.entries(periodShift ?? {}).filter(
    ([, value]) => value !== 0,
  );
  const [firstNonZeroPeriodShift] = nonZeroPeriodShift;
  const [period_shift_key, period_shift_value] = firstNonZeroPeriodShift ?? [];

  return period_shift_key == null
    ? undefined
    : ({
        key: period_shift_key,
        value: period_shift_value,
      } as PeriodShiftForm);
};
export const transformPeriodShiftToDefaultDateRange = (
  periodShiftFrom: Partial<PeriodShiftConfig> | undefined,
  periodShiftTo: Partial<PeriodShiftConfig> | undefined,
) => {
  const periodShiftToForm = transformPeriodShiftDtoToForm(periodShiftTo);
  const periodShiftForm = transformPeriodShiftDtoToForm(periodShiftFrom);
  // shiftDiff and respect +1 the boundaries
  const shiftDiff =
    periodShiftForm?.value - (periodShiftToForm?.value ?? 0) + 1;

  const defaultDateRange = Object.entries(RANGE_TO_PERIOD_SHIFT_MAP).find(
    ([, periodShift]) =>
      periodShift.key === periodShiftForm?.key &&
      periodShift.value === shiftDiff,
  )?.[0] as (typeof DEFAULT_DATE_RANGES)[keyof typeof DEFAULT_DATE_RANGES];

  return defaultDateRange ?? 'none';
};

export const transformPeriodTypeDtoToForm = (
  periodType: TrailingPeriodType | TimePeriodType,
): PeriodTypeForm => {
  return {
    periodType: typeof periodType === 'string' ? 'time' : 'trailing',
    value: periodType,
  };
};

export const transformPeriodTypeFormToDto = (
  periodType: PeriodTypeForm | undefined,
): TrailingPeriodType | TimePeriodType | undefined => {
  return periodType?.value;
};
