import React, { FC, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { formatAmount } from '@/shared/lib/formatting/number';
import { Icon } from 'stories/Icon/Icon';
import { InvoiceLineItem } from '@/shared/types/reconcile/Invoice';
import styles from '@/bundles/DrawPackage/InvoicesFlow/Invoices/invoice/Invoice.module.scss';
import CategoryLineItem from '@/bundles/DrawPackage/InvoicesFlow/Invoices/invoice/CategoryLineItem';
import { cn } from '@/shared/lib/css/cn';

interface ITableInvoicesProps {
  items: InvoiceLineItem[];
  onRemove: () => void;
  classNameTableBody?: string;
}

const TableLineItems: FC<ITableInvoicesProps> = ({
  items,
  onRemove,
  classNameTableBody,
}) => {
  const isRemovable = onRemove !== undefined;
  const [showScroll, setShowScroll] = useState(true);
  const [showShadow, setShowShadow] = useState(true);
  const refTable = useRef(null);

  useEffect(() => {
    setShowScroll(
      refTable.current.scrollHeight > refTable.current.clientHeight,
    );
  }, [items]);

  useLayoutEffect(() => {
    const scrollingHandle = () => {
      if (refTable.current) {
        const positionScrollTable = Math.floor(
          refTable.current.scrollTop + refTable.current.clientHeight,
        );
        const heightTable = refTable.current.scrollHeight;
        const COEF_ENDING_TABLE = 0.95;

        setShowShadow(positionScrollTable < COEF_ENDING_TABLE * heightTable);
      }
    };

    if (showScroll) {
      addEventListener('wheel', scrollingHandle);
    }
    return () => removeEventListener('wheel', scrollingHandle);
  }, [items, refTable]);
  return (
    <div className={styles.table}>
      <div className={cn('flex', showScroll && 'pr-1')}>
        <div
          className={cn(
            isRemovable ? 'col-8' : 'col-9',
            styles.tableHeaderItem,
          )}
        >
          Line Items
        </div>
        <div className={cn('col-3', 'text-right', styles.tableHeaderItem)}>
          Amount
        </div>
        {isRemovable && (
          <div className={cn('col-1', 'text-right', styles.tableHeaderItem)} />
        )}
      </div>
      <div
        className={cn(
          styles.tableBody,
          styles.tableBodyLineItems,
          classNameTableBody,
        )}
        ref={refTable}
      >
        {items.map((item, idx) => (
          <div key={item.developmentCategory.name} className={styles.tableRow}>
            {item.developmentCategory && (
              <div
                className={cn(
                  onRemove ? 'col-8' : 'col-9',
                  styles.tableColumnItem,
                )}
              >
                <div className="dark-60 inline-regular pb-xs">
                  {item.developmentCategory.name}
                </div>
                <CategoryLineItem item={item} />
              </div>
            )}
            {item.amount && (
              <div
                className={cn(
                  'col-3',
                  'dark-60 inline-regular text-right',
                  styles.tableColumnItem,
                )}
              >
                {formatAmount(item.amount)}
              </div>
            )}
            {isRemovable && (
              <div className={cn('col-1', styles.tableColumnItem)}>
                <Icon iconName="trash" onClick={() => onRemove(idx)} />
              </div>
            )}
          </div>
        ))}
      </div>
      {showScroll && showShadow && <div className={styles.tableScrollBlock} />}
    </div>
  );
};

export default TableLineItems;
