/* eslint-disable max-params */
import { ComponentProps, useCallback, useMemo, useState } from 'react';
import http from 'lib/http';
import { Button } from '@/stories/Button/Button';
import { IconButton } from '@/stories/IconButton/IconButton';
import { InvestmentObjectHoverable } from 'bundles/Shared/components/InvestmentObjectHoverable';
import FireIcon from 'bundles/FireStation/components/FireIcon/FireIcon';
import { TConfirmFunc } from '@/shared/lib/hooks/useModal';
import QuickFilterFireButton, {
  TQuickFilterFireButtonProps,
} from 'bundles/Shared/components/Table/filters/QuickFilterFireButton';
import { IColumn } from 'bundles/Shared/components/Table/types';
import { LEConfigsMeta, LegalEntity } from '@/entities/core/legalEntity';
import LastActivityOfLEPopover from '@/bundles/Settings/components/Portal/LegalEntities/LastActivityOfLEPopover';
import { PermissionListLine } from 'bundles/Shared/components/Permissions/PermissionListLine';
import { CoreLegalEntitiesLegalEntitiables } from 'bundles/Shared/entities/legalEntity/api/settingsCoreLegalEntitiesApi';
import { LeClassificationFormatter } from 'bundles/Shared/entities/leClasssification/ui/LeClassificationFormatters';
import { LegalEntityManagmentPeriods } from '@/bundles/Settings/components/Portal/LegalEntities/components/LegalEntityManagmentPeriods';
import {
  ErpsystemDto,
  GetApiSettingsCoreLegalEntitiesApiArg,
} from '@/entities/core/legalEntity/api/settingsCoreLegalEntitiesGeneratedApi';
import { navigate } from '@reach/router';
import { generateUrl, ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import QuickFilterByObject from '@/bundles/Assets/components/SharedFiles/Table/Filters/QuickFilterByObject';
import { IInvestmentObject } from '@/types/IInvestmentObject';
import { DEFAULT_DATE_FALLBACK } from '@/shared/lib/formatting/fallbacks';
import { QuickFilterByPmcOrErp } from '@/bundles/Assets/components/SharedFiles/Table/Filters/QuickFilterByPmcOrErp';
import { PropertyManagementCompany } from '@/bundles/Assets/components/SharedFiles/Table/Filters/PmcErpSelect';
import { ERPSystem } from '@/entities/erpsystem';

export type LegalEntitiesFilters =
  | Partial<
      Pick<
        GetApiSettingsCoreLegalEntitiesApiArg,
        'legalEntitiables' | 'erpSystems' | 'pmcIds'
      >
    >
  | undefined;

export type TFilterModel = {
  permissions_fire?: boolean;
};

const sortOptions: Pick<IColumn<LegalEntity>, 'sortable'> = {
  sortable: true,
};

const getSortOptions = (hasSort: boolean) => (hasSort ? sortOptions : {});

const name = (sort: typeof sortOptions): IColumn<LegalEntity> => ({
  dataField: 'name',
  text: 'Legal Entity',
  ...sort,
});

const useDataSourcesColumn = (
  actions: Actions,
  {
    pmcOptions,
    erpOptions,
  }: { pmcOptions: PropertyManagementCompany[]; erpOptions: ERPSystem[] },
): IColumn<LegalEntity> => {
  const [selectedPmcValues, setSelectedPmcValues] = useState<
    PropertyManagementCompany[] | undefined
  >();
  const [selectedErpValues, setSelectedErpValues] = useState<
    ERPSystem[] | undefined
  >();

  const handlePmcValuesChange = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-shadow
    (pmcValues: PropertyManagementCompany[] | undefined) => {
      actions.setSelectedFilters({
        pmcIds: pmcValues?.map((pmc) => pmc.id),
      });
      setSelectedPmcValues(pmcValues);
    },
    [actions],
  );

  const handleErpValuesChange = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-shadow
    (erpValues: ERPSystem[] | undefined) => {
      actions.setSelectedFilters({
        erpSystems: erpValues as ErpsystemDto[],
      });
      setSelectedErpValues(erpValues);
    },
    [actions],
  );

  const handleReset = useCallback(() => {
    setSelectedPmcValues(undefined);
    setSelectedErpValues(undefined);
    actions.setSelectedFilters({
      pmcIds: undefined,
      erpSystems: undefined,
    });
  }, [actions]);

  return {
    dataField: 'managementPeriods',
    text: 'Data Sources',
    headerStyle: { width: 200 },
    classes: '!p-3',
    formatter: ({ row }) => {
      const url = generateUrl(
        ROUTES_ROOT.settings.portal.legalEntities.periods.fullPath,
        {
          pathParams: {
            id: row.id,
          },
        },
      );

      return (
        <div className="flex justify-between gap-4">
          <LegalEntityManagmentPeriods row={row} />

          {row.managementPeriods.length > 0 && (
            <IconButton
              tooltipProps={{
                mainText: 'Edit',
              }}
              variant="secondary"
              onClick={() => navigate(url)}
              iconName="edit"
              className="invisible group-hover:visible"
            />
          )}
        </div>
      );
    },
    // @ts-expect-error
    filterComponent:
      actions.legalEntitiesScope === 'active-and-mapped'
        ? QuickFilterByPmcOrErp
        : undefined,
    filterComponentParams: {
      pmcOptions,
      erpOptions,
      selectedPmcValues,
      selectedErpValues,
      onPmcValuesChange: handlePmcValuesChange,
      onErpValuesChange: handleErpValuesChange,
      resetFilter: handleReset,
    } satisfies ComponentProps<typeof QuickFilterByPmcOrErp>,
  };
};

const leClassification = (actions: Actions): IColumn<LegalEntity> => ({
  dataField: 'classification',
  text: 'Class',
  headerStyle: { width: 200 },
  formatter: ({ row }) => (
    <LeClassificationFormatter
      classification={row.classification}
      onClick={() => {
        actions.openSetLEClassifaction(row);
      }}
      showFireIcon={row.classification == null && row.legalEntitiableId != null}
    />
  ),
});

const lastUpdated = (): IColumn<LegalEntity> => ({
  dataField: 'lastUpdated',
  text: 'Last updated',
  headerStyle: { width: 185 },
  formatter: ({ row }) =>
    row.lastActivity ? (
      <LastActivityOfLEPopover legalEntity={row} />
    ) : (
      DEFAULT_DATE_FALLBACK
    ),
});

export const useLegalEntitiableColumn = (
  actions: Actions,
  filters: {
    allAssets: CoreLegalEntitiesLegalEntitiables[];
    allFunds: CoreLegalEntitiesLegalEntitiables[];
    selectedFilters: LegalEntitiesFilters;
  } = {
    allAssets: [],
    allFunds: [],
    selectedFilters: undefined,
  },
): IColumn<LegalEntity> => {
  const { allAssets, allFunds, selectedFilters } = filters;

  const investmentObjects: IInvestmentObject[] = useMemo(() => {
    const objects = [...allAssets, ...allFunds];

    if (!objects.length) return [];

    return objects.map((obj) => ({
      id: obj.id,
      name: obj.name,
      klass: obj.objectType,
      uniqId: `${obj.id}-${obj.objectType}`,
      legalEntities: [],
    }));
  }, [allAssets, allFunds]);

  const selectedObjects = useMemo(() => {
    if (!selectedFilters) return [];

    const objects = {
      assets: investmentObjects.filter(
        (o) =>
          (selectedFilters.legalEntitiables?.asset_ids ?? []).includes(o.id) &&
          o.klass === 'Asset',
      ),
      funds: investmentObjects.filter(
        (o) =>
          (selectedFilters.legalEntitiables?.fund_ids ?? []).includes(o.id) &&
          o.klass === 'Fund',
      ),
    };

    return [...objects.assets, ...objects.funds];
  }, [selectedFilters]);

  const handleSelectObjects = (items: IInvestmentObject[]) => {
    actions.setSelectedFilters({
      legalEntitiables: {
        asset_ids: items.filter((i) => i.klass === 'Asset').map((i) => i.id),
        fund_ids: items.filter((i) => i.klass === 'Fund').map((i) => i.id),
      },
    });
  };

  // const handleReset = () => actions.localResetFilter('investmentObjects');
  const handleReset = () => {
    actions.setSelectedFilters({
      legalEntitiables: {
        asset_ids: [],
        fund_ids: [],
      },
    });
  };

  return {
    dataField: 'asset',
    text: 'Object',
    classes: 'min-w-[160px] !py-3',
    // @ts-expect-error
    filterComponent:
      actions.legalEntitiesScope === 'active-and-mapped'
        ? QuickFilterByObject
        : undefined,
    filterComponentParams: {
      investmentObjects,
      selectedObjects,
      setSelectedObjects: handleSelectObjects,
      resetFilter: handleReset,
    } satisfies ComponentProps<typeof QuickFilterByObject>,
    formatter: ({ row }) => {
      if (!row.legalEntitiable) {
        return (
          <div className="flex items-center gap-2">
            <Button
              size="xs"
              variant="secondary"
              onClick={() => {
                actions.setCurrentLegalEntity(row);
                actions.openSetObjModalForSingleLE(row);
              }}
            >
              Set Object
            </Button>
            <FireIcon priority="medium" />
          </div>
        );
      }

      return (
        <InvestmentObjectHoverable
          object={row.legalEntitiable}
          forcedKey={`le-${row.id}`}
        />
      );
    },
    ...getSortOptions(actions.legalEntitiesScope === 'active-and-mapped'),
  };
};

const permissions = (
  actions: Actions,
  fireStationOptions: LEFireStationOptions,
): IColumn<LegalEntity> => ({
  dataField: 'permissions',
  text: 'Permissions',
  filterComponent: QuickFilterFireButton,
  filterComponentParams: {
    count: fireStationOptions.totals.totalFireStationPermissions,
  } satisfies TQuickFilterFireButtonProps<LegalEntity>,
  formatter: ({ row }) => (
    <PermissionListLine
      onClick={() => actions.onViewPermissions(row)}
      permissions={{
        directInvestmentEntities: row.permitted.investmentEntities,
        directRoles: row.permitted.roles,
        directTags: row.permitted.tags,
        directUsers: row.permitted.users,
        indirectUsers: row.permitted.autoSelectedUsers,
        isPublic: row.permitted.public,
      }}
    />
  ),
});

const leActions = (
  actions: Actions,
  confirm: TConfirmFunc,
): IColumn<LegalEntity> => ({
  dataField: 'actions',
  headerStyle: { width: 82 },
  classes: '!py-3',
  formatter: ({ row }) => (
    <div className="flex w-max items-center gap-2">
      <IconButton
        tooltipProps={{
          mainText: row.excluded ? 'Set Active' : 'Exclude',
        }}
        variant="secondary"
        onClick={async () => {
          const result = await confirm({
            title: 'Warning',
            subtitle: 'Are you sure?',
          });
          if (result) {
            http
              .post(`/legal_entities/${row.id}/exclude`, {})
              .then((res) => {
                // TODO: error handling
                if (res.ok)
                  toastr.success('Legal Entity has been successfully updated');
              })
              .then(() =>
                actions.setLegalEntitiesScope(actions.legalEntitiesScope),
              )
              .then(() => {
                actions.refreshMeta();
                actions.setSelectedRows([]);
              });
          }
        }}
        iconName={row.excluded ? 'eye' : 'eyeSlash'}
      />
      <IconButton
        tooltipProps={{
          mainText: 'Edit',
        }}
        variant="secondary"
        onClick={() => actions.setLegalEntityModalOpened(row)}
        iconName="edit"
      />
    </div>
  ),
});

interface Actions {
  onViewPermissions: (le: LegalEntity) => Promise<void>;
  setLegalEntityModalOpened: (v: LegalEntity) => void;
  setPermissionsModalOpened: (v: boolean) => void;
  setCurrentLegalEntity: (le: LegalEntity) => void;
  openSetObjModalForSingleLE: (le: LegalEntity) => void;
  openSetLEClassifaction: (le: LegalEntity) => void;
  legalEntitiesScope: GetApiSettingsCoreLegalEntitiesApiArg['scope'];
  setLegalEntitiesScope: (
    scope: GetApiSettingsCoreLegalEntitiesApiArg['scope'],
  ) => void;
  refreshMeta: () => void;
  setSelectedRows: (rows: LegalEntity[]) => void;
  setSelectedFilters: (filters: LegalEntitiesFilters) => void;
  localResetFilter: (filterName: string) => void;
}

export interface LEFireStationOptions {
  totals: Omit<LEConfigsMeta, 'perPage'>;
}

interface Params {
  actions: Actions;
  allAssets: CoreLegalEntitiesLegalEntitiables[] | undefined;
  allFunds: CoreLegalEntitiesLegalEntitiables[] | undefined;
  selectedFilters: LegalEntitiesFilters;
  fireStationOptions: LEFireStationOptions;
  pmcOptions: PropertyManagementCompany[];
  erpOptions: ERPSystem[];
  confirm: TConfirmFunc;
}

export const useLegalEntityColumns = ({
  actions,
  allAssets = [],
  allFunds = [],
  pmcOptions = [],
  erpOptions = [],
  selectedFilters,
  fireStationOptions,
  confirm,
}: Params): IColumn<LegalEntity>[] => {
  const legalEntitiable = useLegalEntitiableColumn(actions, {
    allAssets,
    allFunds,
    selectedFilters,
  });
  const dataSources = useDataSourcesColumn(actions, {
    pmcOptions,
    erpOptions,
  });

  const tableColumns = useMemo(
    () => [
      name(sortOptions),
      legalEntitiable,
      leClassification(actions),
      permissions(actions, fireStationOptions),
      dataSources,
      lastUpdated(),
      leActions(actions, confirm),
    ],
    [
      actions,
      legalEntitiable,
      dataSources,
      allAssets,
      allFunds,
      pmcOptions,
      erpOptions,
      selectedFilters,
      fireStationOptions,
      confirm,
    ],
  );

  return tableColumns;
};
