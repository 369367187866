import type { ReportBuilderTemplateEagleEyeWidgetTypes } from '@/bundles/Shared/entities/dashboard/model/types/types';
import {
  DEFAULT_OBJECT_LEVEL_ASSET_ID_PARAM_KEY,
  getInitialReportBuilderTemplateWidgetState,
  getSelectorReportBuilderTemplateWidgetState,
} from '@/bundles/Shared/entities/dashboard/model/slices/reportBuilderTemplatesSlice';
import {
  useReportBuilderTemplateContext,
  useReportingWidgetGroupIdContext,
} from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/reportBuilderTemplateContext';
import { useReportBuilderTemplateWidgetContext } from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/reportBuilderTemplateWidgetContext';
import type { WidgetSection } from '@/bundles/Shared/widgets/dashboard/widgets/model';
import { useGetApiCoreAssetsByAssetIdReportBuilderReportsQuery } from '@/entities/report/reportBuilder/api';
import {
  useGetApiSettingsReportBuilderEagleEyeTemplatesByEagleEyeTemplateIdGroupsAndGroupIdWidgetSectionsIdQuery,
  useGetApiSettingsReportBuilderEagleEyeTemplatesMetaQuery,
  useGetApiSettingsReportBuilderGotenbergEagleEyeTemplatesByIdQuery,
  useGetApiSettingsReportBuilderGotenbergEagleEyeTemplatesMetaQuery,
  useGetApiSettingsReportBuilderGotenbergTemplatesByIdQuery,
  useGetApiSettingsReportBuilderGotenbergTemplatesMetaQuery,
  useGetApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndGroupIdWidgetSectionsIdQuery,
  useGetApiSettingsReportBuilderTemplatesMetaQuery,
  usePostApiSettingsReportBuilderEagleEyeTemplatesByEagleEyeTemplateIdGroupsAndGroupIdWidgetSectionsPreviewQuery,
  usePostApiSettingsReportBuilderGotenbergWidgetSectionsByIdEvaluateQuery,
  usePostApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndGroupIdWidgetSectionsPreviewQuery,
} from '@/entities/report/reportBuilder/api/settingsReportBuilderTemplatesEnhancedApi';
import type {
  GetApiSettingsReportBuilderGotenbergTemplatesMetaApiArg,
  ReportBuilderEagleEyeTemplateDto,
  ReportBuilderTemplateDto,
  ReportBuilderTemplateGroupWidgetSectionDto,
} from '@/entities/report/reportBuilder/api/settingsReportBuilderTemplatesGeneratedApi';
import { REPORT_BUILDER_FORM_TABS } from '@/entities/report/reportBuilder/config';
import { useGetSelectedObjectsSearchQuery } from '@/entities/report/reportBuilder/lib/useGetApiReportBuilderTemplatePreviewPdfQuery';
import { reportingEnhancedApi } from '@/entities/reporting/api/reportingEnhancedApi';
import { useReportingEntityKindContext } from '@/entities/reporting/context/entityKind';
import { useReportingEntityStageContext } from '@/entities/reporting/context/entityStage';
import { useEagleEyeReportIdParam } from '@/entities/reporting/lib/eagleEye';
import { formatToDateStringForRequest } from '@/shared/lib/converters';
import { useReportBuilderTemplateScreenParams } from '@/shared/lib/hooks/navigation/dashboardsNavitation';
import {
  useAllAssetIdsSearchParams,
  useAssetIdSearchParam,
} from '@/shared/lib/hooks/navigation/useAssetIdSearchParam';
import { useSearchParams } from '@/shared/lib/hooks/navigation/useSearchParams';
import { useAppSelector } from '@/shared/lib/hooks/redux';
import { useSearchParamsTabs } from '@/stories/Tabs/useTabs';
import { useParams } from '@reach/router';
import { useMemo } from 'react';

export const isReportBuilderTempalteWidgetEditable = (
  widgetType:
    | ReportBuilderTemplateEagleEyeWidgetTypes
    | ReportBuilderTemplateGroupWidgetSectionDto['widgetType'],
) => {
  switch (widgetType) {
    case 'kpi_card':
    case 'xy_chart':
    case 'historical_review_table':
    case 'delinquency_tracker_table':
    case 'financial_table_single_period':
    case 'financial_table_single_date':
    case 'unit_type_table':
    case 'kpi_table':
    case 'kpi_table_single_date':
    case 'xy_chart_single_kpi':
    case 'global_lease_table': {
      return true;
    }
    default: {
      return false;
    }
  }
};

export const useFindReportBuilderTemplateWidget = (
  template:
    | ReportBuilderEagleEyeTemplateDto
    | ReportBuilderTemplateDto
    | undefined,
) => {
  const params = useReportBuilderTemplateScreenParams();

  return useMemo(() => {
    if (template == null) return null;

    const allWidgets = template.groups.flatMap((g) =>
      g.widgetSections.map((ws) => ({ ...ws, groupId: g.id })),
    );

    return allWidgets.find((w) => w.id === params.widgetId);
  }, [template]);
};

export const isReportBuilderTempalteWidgetIncludeSourceUnitTypesEditable = (
  widgetType: ReportBuilderTemplateGroupWidgetSectionDto['widgetType'],
) => {
  switch (widgetType) {
    case 'recent_rent_roll_table': {
      return true;
    }
    default: {
      return false;
    }
  }
};

export const useGetReportBuilderTemplateGotenbergMetaWithSearchParamAssetId =
  () => {
    const templateKind = useReportingEntityKindContext();
    const assetId = useAssetIdSearchParam();

    const query = useMemo(() => {
      switch (templateKind) {
        case 'eagle_eye': {
          return useGetApiSettingsReportBuilderGotenbergEagleEyeTemplatesMetaQuery;
        }
        case 'object_level':
        default: {
          return useGetApiSettingsReportBuilderGotenbergTemplatesMetaQuery;
        }
      }
    }, [templateKind]);

    return query({
      [DEFAULT_OBJECT_LEVEL_ASSET_ID_PARAM_KEY]: assetId
        ? Number(assetId)
        : null,
    } as unknown as GetApiSettingsReportBuilderGotenbergTemplatesMetaApiArg);
  };
export const useGetReportBuilderTemplateMetaWithSearchParamAssetId = () => {
  const templateKind = useReportingEntityKindContext();
  const assetId = useAssetIdSearchParam();

  const query = useMemo(() => {
    switch (templateKind) {
      case 'eagle_eye': {
        return useGetApiSettingsReportBuilderEagleEyeTemplatesMetaQuery;
      }
      case 'object_level':
      default: {
        return useGetApiSettingsReportBuilderTemplatesMetaQuery;
      }
    }
  }, [templateKind]);

  return query({
    [DEFAULT_OBJECT_LEVEL_ASSET_ID_PARAM_KEY]: assetId ? Number(assetId) : null,
  } as unknown as GetApiSettingsReportBuilderGotenbergTemplatesMetaApiArg);
};

export const useGetReportBuilderTemplate = () => {
  const params = useReportBuilderTemplateScreenParams();
  const templateKind = useReportingEntityKindContext();

  const query = useMemo(() => {
    switch (templateKind) {
      case 'eagle_eye': {
        return useGetApiSettingsReportBuilderGotenbergEagleEyeTemplatesByIdQuery;
      }
      case 'object_level':
      default: {
        return useGetApiSettingsReportBuilderGotenbergTemplatesByIdQuery;
      }
    }
  }, [templateKind]);

  return query({
    id: params.templateId,
  });
};
export const useGetReportingReportEntity = () => {
  const entityKind = useReportingEntityKindContext();

  // TODO rename `useEagleEyeReportIdParam` in `useReportIdParam` when Object Level is migrated
  const reportId = useEagleEyeReportIdParam();
  const { id } = useParams();

  // TODO assetId should be removed from payload when Object Level Reporting is migrated
  const assetId = useAssetIdSearchParam();

  const query = useMemo(() => {
    switch (entityKind) {
      case 'eagle_eye': {
        return reportingEnhancedApi.useGetApiReportBuilderEagleEyeReportsByIdQuery;
      }
      case 'object_level':
      default: {
        return useGetApiCoreAssetsByAssetIdReportBuilderReportsQuery;
      }
    }
  }, [entityKind]);

  // TODO assetId should be removed from payload when Object Level Reporting is migrated
  const params =
    entityKind === 'eagle_eye'
      ? {
          id: reportId,
        }
      : {
          id,
          assetId,
        };

  return query(params);
};

export const useGetReportingEntity = () => {
  const entityStage = useReportingEntityStageContext();

  const query = useMemo(() => {
    switch (entityStage) {
      case 'report': {
        return useGetReportingReportEntity;
      }
      case 'template':
      default: {
        return useGetReportBuilderTemplate;
      }
    }
  }, [entityStage]);

  return query();
};

export const useGetReportBuilderTemplateWidgetSectionEvaluate = (
  widgetSection: WidgetSection,
) => {
  const templateKind = useReportingEntityKindContext();
  const params = useReportBuilderTemplateScreenParams();
  const { templateId } = params;
  const [searchParams] = useSearchParams();
  const selectedDate =
    formatToDateStringForRequest(searchParams.get('date') ?? new Date()) ??
    formatToDateStringForRequest(new Date());

  const context = getInitialReportBuilderTemplateWidgetState(
    widgetSection,
    selectedDate,
  );

  const assetIds = useAllAssetIdsSearchParams();

  const widgetState = useAppSelector(
    getSelectorReportBuilderTemplateWidgetState(
      templateId,
      widgetSection.groupId,
      widgetSection.id,
    ),
  );

  const query = useMemo(() => {
    switch (templateKind) {
      case 'eagle_eye':
      case 'object_level':
      default: {
        return usePostApiSettingsReportBuilderGotenbergWidgetSectionsByIdEvaluateQuery;
      }
    }
  }, [templateKind]);

  return query({
    id: widgetSection.id,
    groupId: widgetSection.groupId,
    templateId,
    body: {
      context: {
        // use date from search params for object level widgets
        // use state date for eagle eye widgets
        date: templateKind === 'object_level' ? selectedDate : context.date,
        date_from: context.dateFrom,
        date_to: context.dateTo,
        granularity: context.granularity,
        grouping_type: context.groupingType,
        period: context.period,
        asset_ids:
          templateKind === 'eagle_eye' ? widgetState.assetIds : assetIds,
        segment_ids: templateKind === 'eagle_eye' ? widgetState.segmentIds : [],
        excluded_legal_entity_ids:
          templateKind === 'eagle_eye' ? widgetState.excludedLEIds : [],
      },
    },
  });
};

export const useGetReportBuilderTemplateWidgetSectionPreview = () => {
  const templateCtx = useReportBuilderTemplateContext()!;
  const groupId = useReportingWidgetGroupIdContext();
  const templateKind = useReportingEntityKindContext();
  const widget = useReportBuilderTemplateWidgetContext();

  const query = useMemo(() => {
    switch (templateKind) {
      case 'eagle_eye': {
        return usePostApiSettingsReportBuilderEagleEyeTemplatesByEagleEyeTemplateIdGroupsAndGroupIdWidgetSectionsPreviewQuery;
      }
      case 'object_level':
      default: {
        return usePostApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndGroupIdWidgetSectionsPreviewQuery;
      }
    }
  }, [templateKind]);

  const payloadParams = useGetSelectedObjectsSearchQuery();

  const widgetSectionState = useAppSelector(
    getSelectorReportBuilderTemplateWidgetState(
      templateCtx.templateId,
      groupId,
      templateCtx.widgetId,
    ),
  );

  return query({
    templateId: templateCtx.templateId,
    eagleEyeTemplateId: templateCtx.templateId,
    groupId,
    body: {
      config: widget.widgetConfig,
      context: {
        date: formatToDateStringForRequest(
          widgetSectionState.date ?? new Date(),
        ),
        date_from: widgetSectionState.dateFrom,
        date_to: widgetSectionState.dateTo,
        granularity: widgetSectionState.granularity,
        grouping_type: widgetSectionState.groupingType,
        period: widgetSectionState.period,
        asset_ids: payloadParams.assetIds,
        segment_ids: payloadParams.segmentIds,
      },
      widget_type: widget.widgetType,
    },
  });
};

export const useGetReportBuilderTemplateWidgetSection = () => {
  const templateCtx = useReportBuilderTemplateContext()!;
  const groupId = useReportingWidgetGroupIdContext();
  const templateKind = useReportingEntityKindContext();

  const query = useMemo(() => {
    switch (templateKind) {
      case 'eagle_eye': {
        return useGetApiSettingsReportBuilderEagleEyeTemplatesByEagleEyeTemplateIdGroupsAndGroupIdWidgetSectionsIdQuery;
      }
      case 'object_level':
      default: {
        return useGetApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndGroupIdWidgetSectionsIdQuery;
      }
    }
  }, [templateKind]);

  return query({
    groupId,
    eagleEyeTemplateId: templateCtx.templateId,
    id: templateCtx.widgetId,
    templateId: templateCtx.templateId,
  });
};

export const useReportingFormTabs = () => {
  return useSearchParamsTabs(REPORT_BUILDER_FORM_TABS);
};
