import React from 'react';
import { EmailSection } from '@/widgets/userProfile/ui/tabs/AccountSecurityTab/EmailSection';
import { PasswordSection } from '@/widgets/userProfile/ui/tabs/AccountSecurityTab/PasswordSection';

export const AccountSecurityTab = () => {
  return (
    <div className="mt-4 flex flex-col gap-4 transition-all">
      <EmailSection />
      <PasswordSection />
    </div>
  );
};
