import type { LegalEntity } from '@/entities/core/legalEntity';
import type { IAsset } from '@/types/Asset';
import { uniq, xor, xorBy } from 'lodash-es';

export const xorLegalEntityIds = (
  legalEntityAIds: LegalEntity['id'][],
  legalEntityBIds: LegalEntity['id'][],
) => xor(legalEntityAIds, legalEntityBIds);

export const xorLegalEntitiesByIds = (
  legalEntityAIds: Pick<LegalEntity, 'id'>[],
  legalEntityBIds: Pick<LegalEntity, 'id'>[],
) => xorBy(legalEntityAIds, legalEntityBIds, 'id');

export const excludeLegalEntityHandlers = ({
  allLegalEntityIds,
  excludedLegalEntityIds,
  selectedAssetIds,
}: {
  allLegalEntityIds: LegalEntity['id'][];
  excludedLegalEntityIds: LegalEntity['id'][];
  selectedAssetIds: IAsset['id'][];
}) => {
  return {
    changeAll: (type: 'excluded' | 'included') => {
      return type === 'excluded' ? [] : allLegalEntityIds;
    },

    includeAsset: (
      assetId: IAsset['id'],
      assetExcludedLegalEntityIds: LegalEntity['id'][],
    ):
      | {
          type: 'excludedLegalEntityChange';
          result: LegalEntity['id'][];
        }
      | {
          type: 'assetsChange';
          result: IAsset['id'][];
        } => {
      if (selectedAssetIds.includes(assetId)) {
        return {
          type: 'excludedLegalEntityChange',
          result: xorLegalEntityIds(
            assetExcludedLegalEntityIds,
            excludedLegalEntityIds,
          ),
        };
      }
      return {
        type: 'assetsChange',
        result: [...(selectedAssetIds ?? []), assetId],
      };
    },

    includeLegalEntity: (legalEntityId: LegalEntity['id']) => {
      return excludedLegalEntityIds?.filter(
        (id: LegalEntity['id']) => id !== legalEntityId,
      );
    },

    excludeAsset: (assetId: IAsset['id']) => {
      return selectedAssetIds?.filter((id: IAsset['id']) => id !== assetId);
    },

    excludeLegalEntity: (id: LegalEntity['id']) => {
      return uniq([...(excludedLegalEntityIds ?? []), id]);
    },

    excludeAssetAndFilterOutExcludedLegalEntities: (
      assetId: IAsset['id'],
      assetLegalEntityIds: LegalEntity['id'][],
    ) => ({
      assetIds: selectedAssetIds.filter((id) => id !== assetId),
      excludedLegalEntityIds: excludedLegalEntityIds.filter(
        (leId) => !assetLegalEntityIds.includes(leId),
      ),
    }),
  };
};
