import { cn } from '@/shared/lib/css/cn';
import { GroupContainerItem } from '@/stories/Table/Actions/ActionWrapper/GroupContainerItem';
import { Icon } from '@/stories/Icon/Icon';
import { IconButton } from '@/stories/IconButton/IconButton';
import { SelectableItem } from '@/stories/Table/Actions/SelectableItem';
import { Tooltip } from '@/stories/Tooltip/Tooltip';
import Popover, { DEFAULT_DROPDOWN_OFFSET } from '@/stories/Popover/Popover';
import { ActionsContainer } from '@/stories/Table/Actions/ActionsContainer';
import { IconsId } from '@/types/sre-icons';
import { lowerCase } from 'lodash-es';
import React, { ComponentProps, PropsWithChildren } from 'react';

export const BaseCard = ({
  tooltipProps = {
    disabled: true,
  },
  ...props
}: PropsWithChildren &
  PropsWithClassName & {
    iconSlot: React.ReactNode;
    imgWrapperProps?: React.DOMAttributes<HTMLDivElement>;
    tooltipProps?: ComponentProps<typeof Tooltip>;
  }) => (
  <div
    className={cn(
      'group radius024 relative flex flex-col gap-4 bg-neutral-000 p-2 pb-4 shadow-z-000',
      props.className,
    )}
  >
    <Tooltip className="flex grow" {...tooltipProps}>
      <div
        {...props.imgWrapperProps}
        className={cn(
          'radius016 relative flex h-[120px] grow items-center justify-center bg-neutral-100',
          props.imgWrapperProps?.onClick && 'cursor-pointer',
        )}
      >
        {props.iconSlot}
      </div>
    </Tooltip>
    {props.children}
  </div>
);

const Wrapper = (props: PropsWithChildren) => (
  <div className="grid grid-cols-[repeat(auto-fill,minmax(250px,1fr))] gap-4">
    {props.children}
  </div>
);

BaseCard.Status = ({
  statusLabel,
  iconName,
  iconClassName,
}: {
  statusLabel: string;
  iconName: IconsId;
  iconClassName: string;
}) => (
  <div className="flex w-min items-center gap-1 rounded-[8px] bg-neutral-100 px-1.5 py-1">
    <Icon iconName={iconName} className={iconClassName} />
    <p className="secondary-semibold text-neutral-800">{statusLabel}</p>
  </div>
);
BaseCard.Title = ({ children, className }: LayoutProps) => (
  <div className={cn('header6-bold text-neutral-900', className)}>
    {children}
  </div>
);
BaseCard.Subtitle = ({ children }: LayoutProps) => (
  <div className="inline-semibold text-neutral-550">{children}</div>
);

BaseCard.Wrapper = Wrapper;

BaseCard.OverlayActions = ({
  children,
  isLoading,
  className,
}: LayoutProps & {
  isLoading?: boolean;
}) => (
  <div
    className={cn(
      'invisible absolute right-4 top-4 flex items-start gap-1 !rounded-lg bg-neutral-000 p-1 group-hover:!visible',
      isLoading && 'visible',
      className,
    )}
  >
    {children}
  </div>
);

BaseCard.DropdownActions = ({
  items,
  isLoading,
  className,
}: {
  items: {
    text: string;
    onAction: VoidFunction;
  }[];
  isLoading?: boolean;
} & PropsWithClassName) => {
  return (
    <Popover
      offset={DEFAULT_DROPDOWN_OFFSET}
      className="p-0"
      trigger="click"
      classes={{
        spanContainer: className,
      }}
      hiddenArrow
      placement="bottom-start"
      hideOnExpandedAreaClick
      template={
        <ActionsContainer>
          <GroupContainerItem title="Actions">
            {items.map((item) => (
              <SelectableItem
                key={item.text}
                id={lowerCase(item.text)}
                text={item.text}
                onClick={item.onAction}
              />
            ))}
          </GroupContainerItem>
        </ActionsContainer>
      }
    >
      <IconButton isLoading={isLoading} disabled={isLoading} iconName="more" />
    </Popover>
  );
};
