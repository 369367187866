import { useGetApiUserProfileUserQuery } from '@/widgets/userProfile/api/userProfileEnhancedApi';

export function useLoadedUserProfile() {
  const res = useGetApiUserProfileUserQuery();

  return {
    ...res,
    data: res.data,
  };
}
