import React, { FC, useState } from 'react';
import { navigate, useParams } from '@reach/router';
import GroupForm from 'bundles/Shared/components/GroupForm/GroupForm';
import { ConfirmationModal } from 'stories/Modals/ConfirmationModal/ConfirmationModal';
import { Modal } from 'stories/Modals/Modal/Modal';
import { connect } from 'react-redux';
import { findJCC } from 'bundles/Shared/helpers/job_cost_code';
import { IJobCostCode } from 'types/JobCostCode';
import { IChangeOrder, IHardCostCode } from 'bundles/Construction/types';
import { directUpload, presignFiles } from 'lib/uploadFiles';
import {
  useGetDevelopmentCategoryQuery,
  useGetDevelopmentCategoryFlatQuery,
} from 'bundles/Construction/api/core';
import { useAppSelector } from '@/shared/lib/hooks/redux';
import getItemsReallocationModal from '@/bundles/Construction/components/Reallocation/Modals/initData';
import { transformJCCKeyToId } from '@/bundles/Construction/components/Reallocation/Modals/components/utils';

interface ICreateAutoReallocationModalProps {
  JCCForReallocations: IJobCostCode[];
  changeOrders: IChangeOrder[];
  onSubmit: (obj: object) => Promise<void>;
}

const CreateAutoReallocationModal: FC<ICreateAutoReallocationModalProps> = ({
  changeOrders,
  onSubmit,
}) => {
  const params = useParams();
  const [showConfirmModal, setConfirmModal] = useState(false);
  const [disabledCloseIcon, setDisabledCloseIcon] = useState(false);
  const developmentLegalEntity = useAppSelector(
    (state) => state.developmentBudget.legalEntity,
  );
  const legalEntityConfig = developmentLegalEntity.config;

  const { data: JCCTree } = useGetDevelopmentCategoryQuery({
    legalEntityCode: params.legalEntityCode,
    without_sovcs: 'true',
  });

  const { data: JCCForReallocations } = useGetDevelopmentCategoryFlatQuery({
    legalEntityCode: params.legalEntityCode,
    without_sovcs: 'true',
  });

  const hardConstructionCost: IHardCostCode = findJCC(
    legalEntityConfig.hardConstructionCostCode,
    JCCForReallocations || [],
  );
  const hardCostContingency: IHardCostCode = findJCC(
    legalEntityConfig.hardCostContingencyCode,
    JCCForReallocations || [],
  );
  const [formData, setFormData] = useState({
    subReallocations: [],
  });
  const approvedChangeOrders = changeOrders.filter(
    (co) => !co.inReallocation && co.status === 'APPROVED',
  );

  const initGroupFormItems = getItemsReallocationModal(
    formData,
    setFormData,
    approvedChangeOrders,
    hardConstructionCost,
    hardCostContingency,
    JCCForReallocations,
    JCCTree,
  );

  const submit = async () => {
    let presignedFiles = formData.sharedFiles.filter((file) => !file.id);
    if (presignedFiles.length) {
      presignedFiles = await presignFiles(presignedFiles);
      await Promise.all(presignedFiles.map((file) => directUpload(file)));
    }
    return onSubmit({
      change_order_id: formData.change_order_id,
      value: Math.abs(Number(formData.value)),
      description: formData.description,
      moved_from_id: transformJCCKeyToId(formData.moved_from_id),
      moved_to_id: transformJCCKeyToId(formData.moved_to_id),
      input_type: 'auto',
      sub_reallocations: formData?.subReallocations.map((subr) => ({
        moved_from_id: transformJCCKeyToId(subr.moved_from_id),
        moved_to_id: transformJCCKeyToId(subr.moved_to_id),
        value: subr.value,
        status: 'PENDING',
      })),
      files: presignedFiles.map((f) => ({
        key: f.signedData.fields.key,
        filename: f.file.name,
        content_type: f.file.type,
        size: f.file.size,
      })),
    });
  };

  const closeModal = () => {
    setConfirmModal(true);
  };

  const confirmModalHandle = (status: boolean) => {
    if (status) {
      navigate('./');
    }
    setConfirmModal(false);
  };

  const disabledCloseIconFunc = () => {
    setDisabledCloseIcon(true);
  };
  return (
    <>
      <Modal
        header={
          <div>
            <div className="label-regular">
              {developmentLegalEntity.assetName}
            </div>
            <div className="dark header6-bold">
              Create CO-Driven REallocation
            </div>
          </div>
        }
        size="lg"
        classes={{
          body: 'reconcile-common-modal-create',
        }}
        maxHeight
        toggle={closeModal}
        disabledClose={disabledCloseIcon}
      >
        <GroupForm
          groupFormItems={initGroupFormItems}
          onSubmit={submit}
          formData={formData}
          setFormData={setFormData}
          buttonConfig={{
            titleBtn: 'Review & Confirm',
            titleView: 'Create CO-Driven REallocation',
            titleLoading: 'Creating CO-Driven REallocation',
            width: '100%',
            withoutCancel: true,
            funcSendForm: disabledCloseIconFunc,
          }}
          groupFormConfig={{
            reviewFlow: true,
            steps: true,
          }}
        />
      </Modal>
      {showConfirmModal && (
        <ConfirmationModal
          title="Are you sure that you want to quit?"
          subtitle="The provided data will be lost."
          actions={{
            primaryButton: {
              text: 'Yes',
              variant: 'danger',
            },
            secondaryButton: {
              text: 'No',
              variant: 'secondary',
            },
          }}
          handleResolve={confirmModalHandle}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ changeOrderManagement }) => ({
  changeOrders: changeOrderManagement.changeOrders,
});

export default connect(mapStateToProps)(CreateAutoReallocationModal);
