import { MediaWidget } from '@/bundles/Shared/widgets/dashboard/widgets/media/ui/MediaWidget';
import { MediaWidgetConfigCard } from '@/bundles/Shared/widgets/dashboard/widgets/media/ui/MediaWidgetConfigCard';
import { WidgetConfiguration } from '@/bundles/Shared/widgets/dashboard/widgets/model';
import type { CSSProperties } from 'react';

export const MEDIA_WIDGET_CONFIG = {
  icon: 'newIconText',
  title: 'Media',
  Component: MediaWidget,
  CardConfigComponent: MediaWidgetConfigCard,
  layout: {
    minW: 1,
    maxW: 2,
    minH: 12,
    maxH: 12,
    w: 1,
    h: 12,
  },
} as const satisfies WidgetConfiguration;

export const MEDIA_WIDGET_OBJECT_POSITION_OPTIONS = [
  {
    label: 'Center',
    value: 'center',
  },
  {
    label: 'Top',
    value: 'top',
  },
  {
    label: 'Right',
    value: 'right',
  },
  {
    label: 'Bottom',
    value: 'bottom',
  },
  {
    label: 'Left',
    value: 'left',
  },
] as const satisfies {
  value: React.CSSProperties['objectPosition'];
  label: string;
}[];

export const MEDIA_WIDGET_OBJECT_FIT_OPTIONS = [
  {
    label: 'Original size',
    value: 'none',
  },
  {
    label: 'Fill frame (crops if needed)',
    value: 'cover',
  },
  {
    label: 'Show entire image',
    value: 'contain',
  },
  {
    label: 'Stretch to fill',
    value: 'fill',
  },
  {
    label: 'Shrink only if needed',
    value: 'scale-down',
  },
] as const satisfies {
  value: React.CSSProperties['objectFit'];
  label: string;
}[];

export type MediaWidgetDisplayOptions = {
  objectFit: React.CSSProperties['objectFit'];
  objectPosition: React.CSSProperties['objectPosition'];
  height: number;
  isFullHeight: boolean;
  widthPercentage: number;
};

export const DEFAULT_MEDIA_WIDGET_DISPLAY_OPTIONS = {
  objectFit: 'cover',
  objectPosition: 'center',
  height: 260,
  isFullHeight: true,
  widthPercentage: 100,
} as const satisfies MediaWidgetDisplayOptions;

export const DEFAULT_MEDIA_WIDGET_FIGURE_STYLES = {
  minWidth: '20%',
  maxWidth: '100%',
  height: '100%',
  minHeight: 200,
  maxHeight: 1000,
} as const satisfies CSSProperties;
