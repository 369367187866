import React, { FC } from 'react';
import { cn } from '@/shared/lib/css/cn';
import styles from '@/stories/ChangeEventInfoSection/ChangeEventInfoSection.module.scss';
import { ChangeableStatusBadge } from '@/stories/ChangeableStatusBadge/ChangeableStatusBadge';
import { IconButton } from '@/stories/IconButton/IconButton';
import { formatAmount } from '@/shared/lib/formatting/number';
import { TStatusOption } from '@/stories/ChangeableStatusBadge/components/StatusOptionsDropdown';
import { useAppSelector } from '@/shared/lib/hooks/redux';
import { selectChangeEvent } from 'bundles/Construction/reducers/ReconcileChangeEventSlice';

interface IChangeEventInfoSectionProps {
  amount: string;
  options: TStatusOption[];
  selected: TStatusOption;
  title: string;
  handleItemClick?: (st: TStatusOption) => void;
  onIconClick?: () => void;
}

export const ChangeEventInfoSection: FC<IChangeEventInfoSectionProps> = ({
  title,
  onIconClick,
  amount,
  handleItemClick,
  selected,
  options,
}) => {
  const changeEvent = useAppSelector(selectChangeEvent);
  return (
    <div className={styles['sre-ce-info-section']}>
      <div className={styles['sre-ce-info-section__header']}>
        <div
          className={cn(
            'header6-regular',
            styles['sre-ce-info-section__title'],
          )}
        >
          {title}
        </div>
        {onIconClick && (
          <IconButton
            iconName="barsAlt"
            onClick={onIconClick}
            variant="white"
          />
        )}
      </div>
      <div className={styles['sre-ce-info-section__body']}>
        <div>Amount</div>
        <div
          className={cn(
            'header4-regular',
            styles['sre-ce-info-section__amount'],
          )}
        >
          {changeEvent?.status === 'VOID' ? formatAmount('0') : amount}
        </div>
      </div>
      <ChangeableStatusBadge
        selected={selected}
        options={options}
        handleItemClick={handleItemClick}
      />
    </div>
  );
};
