import {
  FieldType,
  IGroupFormItem,
} from 'bundles/Shared/components/GroupForm/types/types';

const getItemsAppModal = (): IGroupFormItem[] => {
  const groupFormItems = [
    {
      title: 'Basic',
      activated: true,
      styles: {
        wrapper: 'flex-wrap flex gap-4',
      },
      formItems: [
        {
          fieldName: 'number',
          type: FieldType.INPUT_NUMBER,
          format: '####-##',
          mask: '*',
          title: 'PayApp Number',
          placeholder: 'Enter PayApp Number',
          required: true,
        },
        {
          fieldName: 'application_date',
          type: FieldType.INPUT_DATE,
          title: 'Application Date',
          placeholder: 'Select Date',
          required: true,
          format: 'MM-DD-YYYY',
        },
        {
          fieldName: 'period_to_date',
          type: FieldType.INPUT_DATE,
          title: 'Period To',
          placeholder: 'Select Date',
          required: true,
          format: 'MM-DD-YYYY',
        },
      ],
    },
    {
      title: 'Pay App Document',
      activated: true,
      formItems: [
        {
          fieldName: 'files',
          type: FieldType.FILES,
          required: true,
          multiple: false,
        },
      ],
    },
  ];
  return groupFormItems;
};
export default getItemsAppModal;
