import React from 'react';
import { InvestmentEntityDto } from '@/shared/api/investmentEntityGeneratedApi';
import { usePutApiInvestmentEntitiesByIdMutation } from '@/shared/api/investmentEntityEnhancedApi';
import { SectionField } from '@/stories/Field/FieldsWrappers';
import { Button } from '@/stories/Button/Button';
import { Input } from '@/stories/FormControls/Inputs/Input/Input';
import { Select } from '@/stories/FormControls/Select/Select';
import { HorizontalEditableField } from '@/stories/Field/HorizontalEditableField';
import { useBoolean } from 'usehooks-ts';
import { useForm } from 'react-hook-form';
import { cn } from '@/shared/lib/css/cn';
import { STATES } from '@/bundles/Shared/lib/states';
import { COUNTRIES } from '@/bundles/Shared/lib/countries';
import { DEFAULT_STRING_FALLBACK } from '@/shared/lib/formatting/fallbacks';
const STATE_OPTIONS = STATES.map((state) => ({
  id: state.name,
  label: state.name,
}));

export const AddressTab = ({ item }: { item: InvestmentEntityDto }) => {
  const {
    value: isEditing,
    setTrue: startEditing,
    setFalse: stopEditing,
  } = useBoolean(false);

  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { isValid },
  } = useForm({
    defaultValues: {
      addressLine1: item?.address?.addressLine1 ?? '',
      addressLine2: item?.address?.addressLine2 ?? '',
      city: item?.address?.city ?? '',
      state: item?.address?.state ?? '',
      country: item?.address?.country ?? '',
      zipCode: item?.address?.zipCode ?? '',
    },
    mode: 'onChange',
  });

  const formValues = watch();

  const [updateInvestmentEntity, { isLoading: isUpdating }] =
    usePutApiInvestmentEntitiesByIdMutation();

  const handleCancel = () => {
    reset();
    stopEditing();
  };

  const handleSave = handleSubmit(async (data) => {
    const result = await updateInvestmentEntity({
      id: item.id,
      body: {
        investment_entity: {
          address_attributes: {
            address_line1: data.addressLine1,
            address_line2: data.addressLine2,
            city: data.city,
            state: data.country === 'United States' ? data.state : null,
            country: data.country,
            zip_code: data.zipCode,
          },
        },
      },
    });

    if (!result.data?.errors) {
      stopEditing();
    }
  });

  const withState = formValues.country === 'United States';

  return (
    <SectionField
      labelText="Address Information"
      button={
        isEditing ? (
          <div className="flex gap-2">
            <Button size="xs" variant="secondary" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              size="xs"
              variant="success"
              onClick={handleSave}
              loading={isUpdating}
              disabled={!isValid}
            >
              Save Changes
            </Button>
          </div>
        ) : (
          <Button size="xs" variant="secondary" onClick={startEditing}>
            Change
          </Button>
        )
      }
    >
      <div className="-mx-4 divide-y divide-solid divide-neutral-200">
        <HorizontalEditableField
          label="Address Line 1"
          value={formValues.addressLine1 || DEFAULT_STRING_FALLBACK}
          editable={isEditing}
          className={cn(isEditing ? 'py-2' : 'py-4')}
          required={isEditing}
        >
          <Input {...register('addressLine1')} />
        </HorizontalEditableField>

        <HorizontalEditableField
          label="Address Line 2"
          value={formValues.addressLine2 || DEFAULT_STRING_FALLBACK}
          editable={isEditing}
          className={cn(isEditing ? 'py-2' : 'py-4')}
        >
          <Input {...register('addressLine2')} />
        </HorizontalEditableField>

        <HorizontalEditableField
          label="City"
          value={formValues.city || DEFAULT_STRING_FALLBACK}
          editable={isEditing}
          className={cn(isEditing ? 'py-2' : 'py-4')}
          required={isEditing}
        >
          <Input {...register('city')} />
        </HorizontalEditableField>

        <HorizontalEditableField
          label={withState ? 'State' : 'Country'}
          value={
            [formValues.country, formValues.state]
              .filter((v) => v)
              .join(' / ') || DEFAULT_STRING_FALLBACK
          }
          editable={isEditing}
          className={cn(isEditing ? 'py-2' : 'py-4')}
          required={isEditing}
        >
          <div className="flex w-full gap-2">
            <Select
              spanContainer="w-full"
              onSelectedChange={(value) => {
                setValue('country', value?.label);
                if (!withState) {
                  setValue('state', undefined);
                }
              }}
              selected={COUNTRIES.find(
                (option) => option.label === formValues.country,
              )}
              options={COUNTRIES}
            />
            {formValues.country === 'United States' && (
              <Select
                spanContainer="w-100"
                onSelectedChange={(value) => {
                  setValue('state', value?.label);
                }}
                selected={STATE_OPTIONS.find(
                  (option) => option.id === formValues.state,
                )}
                options={STATE_OPTIONS}
              />
            )}
          </div>
        </HorizontalEditableField>

        <HorizontalEditableField
          label="ZIP/Postal Code"
          value={formValues.zipCode || DEFAULT_STRING_FALLBACK}
          editable={isEditing}
          className={cn(isEditing ? 'py-2' : 'py-4')}
          required={isEditing}
        >
          <Input {...register('zipCode')} />
        </HorizontalEditableField>
      </div>
    </SectionField>
  );
};
