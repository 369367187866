import React, { useRef, useState } from 'react';
import { cn } from '@/shared/lib/css/cn';
import { Icon } from 'stories/Icon/Icon';
import BadgeWithStatus from '@/bundles/Shared/components/BadgeWithStatus';
import { Input } from '@/stories/FormControls/Inputs/Input/Input';
import { PasswordInput } from '@/stories/FormControls/Inputs/PasswordInput/PasswordInput';
import { PasswordValidationBadges } from '@/bundles/Registration/PasswordValidationBadges';

export const getAllRequirements = (passedRequirements) => {
  const passedRequirementsKeys =
    (passedRequirements && Object.keys(passedRequirements)) || [];
  return {
    minUppercase: {
      text: '1 uppercase letter',
      valid:
        passedRequirements && !passedRequirementsKeys.includes('minUppercase'),
    },
    minLowercase: {
      text: '1 lowercase letter',
      valid:
        passedRequirements && !passedRequirementsKeys.includes('minLowercase'),
    },
    minNumber: {
      text: '1 number',
      valid:
        passedRequirements && !passedRequirementsKeys.includes('minNumber'),
    },
    minSymbol: {
      text: '1 symbol',
      valid:
        passedRequirements && !passedRequirementsKeys.includes('minSymbol'),
    },
    min: {
      text: 'at least 8 characters',
      valid: passedRequirements && !passedRequirementsKeys.includes('min'),
    },
  };
};

export const PASSWORD_VALIDATION_MESSAGE =
  'Ensure all requirements are met to proceed!';

const PasswordFields = ({ errors, watch, register, getValues }) => {
  const passedRequirements = errors?.password?.types;
  const allRequirements = getAllRequirements(passedRequirements);

  const passwordInput = useRef(null);
  const executeScroll = () => {
    if (window.innerWidth < 600)
      passwordInput.current.scrollIntoView({ behavior: 'smooth' });
  };

  const { password, passwordConfirmation } = watch();
  const passwordsMatched = password === passwordConfirmation;
  return (
    <div>
      <div className="form-group auth__form-group">
        <label
          className="auth__label"
          onClick={executeScroll}
          ref={passwordInput}
        >
          Password
          <span className="red"> *</span>
          <p
            className="font-weight-400 my-[0.5rem] text-[0.75rem]"
            style={{ color: '#848d91' }}
          >
            Please create a secure password <br />
            including the following criteria below
          </p>
          <div className="position-relative">
            <PasswordInput
              onClick={executeScroll}
              {...register('password')}
              error={Boolean(errors.password)}
              placeholder="Enter password"
              size="l"
            />
          </div>
          <div
            className={cn('invalid-feedback', { 'd-block': errors.password })}
          >
            {PASSWORD_VALIDATION_MESSAGE}
          </div>
        </label>
        <PasswordValidationBadges
          allRequirements={allRequirements}
          errors={errors}
          value={password}
        />
      </div>
      <div className="form-group auth__form-group">
        <label className="auth__label">
          Password confirmation
          <span className="red"> *</span>
          <div className="position-relative mt-2">
            <PasswordInput
              {...register('passwordConfirmation')}
              error={Boolean(errors.passwordConfirmation)}
              placeholder="Repeat password"
              size="l"
            />
          </div>
        </label>
        <BadgeWithStatus
          className="mr-[0.5rem] mt-[0.5rem]"
          isSuccessful={passwordsMatched && passwordConfirmation !== undefined}
        >
          Passwords must match
        </BadgeWithStatus>
      </div>
    </div>
  );
};

export default PasswordFields;
