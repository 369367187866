import { referenceRegExpTester } from '@/bundles/Shared/entities/formula';
import { REFERENCE_FIELD_NOTE } from '@/bundles/Shared/entities/formula/config';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import { LabelFieldController } from '@/shared/ui/LabelFieldController';
import Button from '@/stories/Button/Button';
import Field, { FieldsContainer } from '@/stories/Field/Field';
import Input from '@/stories/FormControls/Inputs/Input/Input';
import { Modal } from '@/stories/Modals/Modal/Modal';
import { ModalHeaderWithSubtitle } from '@/stories/Modals/ModalHeader/ModalHeaderWithSubtitle/ModalHeaderWithSubtitle';
import Textarea from '@/stories/Textarea/Textarea';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';

const FORECASTING_SCHEMA = yup.object().shape({
  label: yup.string().required(),
  name: yup.string().required().test(referenceRegExpTester),
  description: yup.string().optional(),
});

export type ForecastingForm = yup.InferType<typeof FORECASTING_SCHEMA>;

export const ForecastingFormModal = ({
  onClose,
  onSubmit,
  defaultValues,
  mode = 'create',
}: DialogProps<ForecastingForm> & {
  mode?: 'create' | 'edit';
  defaultValues?: ForecastingForm;
}) => {
  const methods = useForm<ForecastingForm>({
    resolver: yupResolver(FORECASTING_SCHEMA),
    defaultValues,
  });

  const {
    control,
    formState: { isDirty, isValid, isSubmitting },
  } = methods;

  const handleSubmit = methods.handleSubmit((values) => onSubmit?.(values));

  return (
    <Modal
      toggle={onClose}
      header={
        <ModalHeaderWithSubtitle
          title={mode === 'create' ? 'Create Forecast' : 'Edit Forecast'}
        />
      }
      actions={
        <>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            disabled={!isDirty || !isValid || isSubmitting}
            variant="success"
            onClick={handleSubmit}
          >
            {mode === 'create' ? 'Create' : 'Save'}
          </Button>
        </>
      }
    >
      <FormProvider {...methods}>
        <FieldsContainer>
          <Field labelText="Forecast Name" required>
            <LabelFieldController
              control={control}
              name="label"
              referenceFieldName="name"
              placeholder="Enter Forecast Name"
              syncReference={mode !== 'edit'}
            />
          </Field>
          <Field labelText="Reference" required note={REFERENCE_FIELD_NOTE}>
            <Input
              {...methods.register('name')}
              disabled={mode === 'edit'}
              placeholder="Forecast Reference"
            />
          </Field>
          <Field labelText="Description">
            <Textarea
              {...methods.register('description')}
              placeholder="Enter Description"
            />
          </Field>
        </FieldsContainer>
      </FormProvider>
    </Modal>
  );
};
