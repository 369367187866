import { IThinTabItem } from '@/stories/Tabs/ThinTabGroup/ThinTabGroup';

export const FINANCIAL_TABLE_CONFIGURATION_TABS = [
  {
    id: 'basic',
    label: 'Basic',
  },
  {
    id: 'columns',
    label: 'Columns',
  },
  {
    id: 'rows',
    label: 'Rows',
  },
] as const satisfies readonly IThinTabItem[];
