import { ERP_SYSTEMS, ERPSystem } from '@/entities/erpsystem';

export const NON_REVENUE_UNITS_INFO_TEXT =
  'By default all of the below is considered vacant and available for occupancy calculations. To override, check the statuses you consider to be non-revenue units in order to display a “True” availability percentage';

export const ERP_NON_REVENUE_STATUSES_TEXT_MAP = {
  [ERP_SYSTEMS.REALPAGE]:
    'Realpage uses the same status for Model and Admin, as a result we cannot split them out',
  [ERP_SYSTEMS.RESMAN]:
    'Resman Rent Rolls advise us which units have been excluded, but not why. We can match this',
} as const as Partial<Record<ERPSystem, string>>;
