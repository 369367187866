import React, { useState } from 'react';
import { Button } from 'stories/Button/Button';
import { Input } from 'stories/FormControls/Inputs/Input/Input';
import { IDocumentType } from 'types/DocumentType';

interface Props {
  onSubmit: (documentType: Partial<IDocumentType>) => void;
  documentType: Partial<IDocumentType>;
}

function DocumentTypeFields({
  onSubmit,
  documentType: initialDocumentType,
}: Props) {
  const [documentType, setDocumentType] = useState(initialDocumentType);
  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onSubmit(documentType);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="from-group mb-2">
        <label htmlFor="name">Name</label>
        <Input
          value={documentType.name}
          onChange={(e) =>
            setDocumentType({
              ...documentType,
              name: e.target.value,
            })
          }
          id="name"
        />
      </div>
      <Button
        disabled={documentType.name == null || documentType.name.length === 0}
        variant="success"
        className="mt-50"
        fluid
        type="submit"
      >
        Submit
      </Button>
    </form>
  );
}

export default DocumentTypeFields;
