import { DEFAULT_WIDGET_HISTORY_SIZE } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/fields/HistorySizeField';
import { commonTableWidgetUpdaters } from '@/bundles/Shared/widgets/dashboard/widgets/common/updaters';
import { KpiTableWidgetConfigDto } from '@/shared/api/dashboardsSettingsGeneratedApi';
import {
  DEFAULT_GROUP_BG_COLOR,
  DEFAULT_GROUP_BORDER_COLOR_RGB,
} from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/lib';
import {
  maxIdGenerator,
  transformPeriodShiftFormToDto,
} from 'bundles/Shared/widgets/dashboard/widgets/common/lib/config';
import { upsertColumnSettings } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/updaters';
import {
  KpiTableWidgetColumnGroupForm,
  KpiTableWidgetConfig,
  KpiTableWidgetConfigColumnForm,
  KpiTableWidgetConfigForm,
} from 'bundles/Shared/widgets/dashboard/widgets/kpiTable';
import * as d3 from 'd3';
import { produce } from 'immer';
import { isEmpty } from 'lodash-es';

export const upsertColumnGroup = <
  T extends Pick<KpiTableWidgetConfig, 'viz_config'>,
>(
  columnGroup: KpiTableWidgetColumnGroupForm,
  config: T,
): T => {
  return produce(config, (draft) => {
    const vizConfig = draft.viz_config;
    const columnGroups = vizConfig.column_groups ?? [];
    vizConfig.column_groups = columnGroups;
    const columnGroupIndex =
      columnGroup.key != null
        ? columnGroups.findIndex((cg) => cg.group_id === columnGroup.key)
        : columnGroups.length;

    const newColumnGroup = produce(
      columnGroups[columnGroupIndex] ?? {
        group_id: maxIdGenerator(columnGroups, 'group_id').toString(),
        header_name: '',
        order: columnGroupIndex,
        icon: null,
      },
      (draftGroup) => {
        draftGroup.header_name = columnGroup.label;
        draftGroup.icon = columnGroup.icon ?? null;
        draftGroup.background =
          columnGroup.color ??
          draft.viz_config.header_background ??
          DEFAULT_GROUP_BG_COLOR; // TODO replace with `null` in FE-3921
        draftGroup.border_color =
          d3
            .color(columnGroup.color ?? draft.viz_config.header_background)
            ?.darker(0.2)
            .formatRgb() ?? DEFAULT_GROUP_BORDER_COLOR_RGB; // TODO replace with `null` in FE-3921
      },
    );
    columnGroups[columnGroupIndex] = newColumnGroup;
  });
};

export const insertColumnInGroup = (
  columnId: string,
  groupId: string,
  viz_config: KpiTableWidgetConfig['viz_config'],
) => {
  const group = viz_config.column_groups?.find((cg) => cg.group_id === groupId);
  if (group == null) {
    return;
  }
  const column = viz_config.columns?.find((c) => c.col_id === columnId);
  if (column == null) {
    return;
  }
  column.group_id = groupId;
};

export const upsertColumn = (
  column: KpiTableWidgetConfigColumnForm,
  config: KpiTableWidgetConfig,
  {
    groupId,
  }: {
    groupId?: string;
  },
): KpiTableWidgetConfig => {
  return produce(config, (draft) => {
    let columnIndex = draft.columns.findIndex((c) => c.key === column.key);
    if (columnIndex === -1) {
      columnIndex = draft.columns.length;
    }
    draft.columns[columnIndex] = {
      label: column.label,
      expression: column.expression,
      period_shift: transformPeriodShiftFormToDto(
        column.period_shift ?? undefined,
      ),
      key: column.key ?? maxIdGenerator(draft.columns, 'key'),
      type: column.type === 'sparkline' ? 'numeric' : column.type,
      total_calculation_strategy:
        column.total_calculation_strategy ?? undefined,
      history_size: column.history_size ?? DEFAULT_WIDGET_HISTORY_SIZE,
    };

    if (!isEmpty(column.adjustment)) {
      draft.columns[columnIndex].adjustment_expression = column.adjustment;
    }

    if (column.sort) {
      draft.viz_config.columns = draft.viz_config.columns.map((c) => {
        if (c.col_id === column.key?.toString()) {
          return { ...c, initial_sort: column.sort ?? null };
        }

        return { ...c, initial_sort: undefined };
      });
    }

    const colId = draft.columns[columnIndex].key.toString();
    upsertColumnSettings(column, draft, { key: colId, groupId });
  });
};

export const {
  moveGroup,
  removeGroup,
  removeColumn,
  moveColumn,
  cloneColumn,
  toggleColumnHidden,
  toggleAllColumnsHidden,
  toggleGroupHidden,
  cloneGroup,
} = commonTableWidgetUpdaters;

export const updateDefaultOptions = (
  values: KpiTableWidgetConfigForm,
  widgetConfig: KpiTableWidgetConfigDto,
): KpiTableWidgetConfigDto => {
  return produce(widgetConfig, (draft) => {
    const periodShift = transformPeriodShiftFormToDto(values.period_shift);
    draft.viz_config.header_background = values.header_background ?? undefined;
    draft.hide_total_row = values.hideTotalRow ?? undefined;
    draft.default_options.grouping_type = values.groupingType;
    draft.default_options.date = periodShift
      ? {
          period_shift: periodShift,
        }
      : undefined;
    if (values.datePickerSetting === 'month') {
      draft.period_types = values.availablePeriodTypes;
      draft.default_options.period_type = values.defaultPeriodType;
      return;
    }
    draft.period_types = ['week'];
    draft.default_options.period_type = 'week';
  });
};
