import * as yup from 'yup';
import {
  isNumericTableVizConfigColumn,
  isSparklineTableVizConfigColumn,
  isSparklineTableVizConfigRow,
  isTextTableVizConfigColumn,
  isTextTableVizConfigRow,
  NumericTableVizConfigColumn,
  SparklineTableVizConfigColumn,
  TableVizConfigColumn,
  TableVizConfigRow,
} from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/model';
import { transformEmptyObjectToUndefined } from '@/shared/lib/yup';
import {
  FONT_STYLES,
  SORTING_VALUES,
} from 'bundles/Shared/widgets/dashboard/widgets/common/config';
import { COMPARE_TO_SCHEMA } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/fields/CompareToField';
import { VALUE_DISPLAY_OPTIONS_SCHEMA } from '@/shared/lib/formatting/displayOptions';
import { HIGHLITHING_RULES_SCHEMA } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/fields/HighlightingRulesField';
import { convertDecimalToPercentage } from '@/shared/lib/converters';
import { TEXT_DISPLAY_STRATEGY_DEFAULT_FIELD_SCHEMA } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/fields/TextDisplayStrategyField';
import { DEFAULT_HISTORY_SIZE_FIELD_SCHEMA } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/fields/HistorySizeField';
import { DEFAULT_SPARKLINE_TYPE_FIELD_SCHEMA } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/fields/SparklineTypeField';
export const WIDGET_TABLE_WIDGET_ROW_TYPES = {
  NUMERIC: 'numeric',
  TEXT: 'text',
  SPARKLINE: 'sparkline',
} as const;

export const WIDGET_TABLE_WIDGET_COLUMN_TYPES = {
  NUMERIC: 'numeric',
  TEXT: 'text',
  SPARKLINE: 'sparkline',
} as const;

export type WidgetTableRowSettingsRowType =
  (typeof WIDGET_TABLE_WIDGET_ROW_TYPES)[keyof typeof WIDGET_TABLE_WIDGET_ROW_TYPES];

export type WidgetTableColumnSettingsColumnType =
  (typeof WIDGET_TABLE_WIDGET_COLUMN_TYPES)[keyof typeof WIDGET_TABLE_WIDGET_COLUMN_TYPES];

export const BASE_COLUMN_SETTINGS_FORM_SCHEMA = yup.object().shape({
  header: yup
    .object()
    .shape({
      hide_title: yup.boolean().optional().default(undefined),
      hide_subtitle: yup.boolean().optional().default(undefined),
      subtitle: yup.string().optional().nullable().default(undefined),
    })
    .optional()
    .default(undefined),
  sort: yup
    .string()
    .oneOf([...SORTING_VALUES])
    .optional()
    .default(undefined)
    .nullable(),
  value_display_options: VALUE_DISPLAY_OPTIONS_SCHEMA.nullable()
    .optional()
    .transform(transformEmptyObjectToUndefined)
    .default(undefined),
  font_weight: yup.string().oneOf(FONT_STYLES).optional().default(undefined),
});

export type BaseColumnSettingsForm = yup.InferType<
  typeof BASE_COLUMN_SETTINGS_FORM_SCHEMA
>;

export const TEXT_COLUMN_SETTINGS_FORM_SCHEMA =
  BASE_COLUMN_SETTINGS_FORM_SCHEMA.shape({
    type: yup.string().oneOf([WIDGET_TABLE_WIDGET_COLUMN_TYPES.TEXT]),
  }).concat(TEXT_DISPLAY_STRATEGY_DEFAULT_FIELD_SCHEMA);

export type TextColumnSettingsForm = yup.InferType<
  typeof TEXT_COLUMN_SETTINGS_FORM_SCHEMA
>;

export const NUMERIC_COLUMN_SETTINGS_FORM_SCHEMA =
  BASE_COLUMN_SETTINGS_FORM_SCHEMA.shape({
    type: yup.string().oneOf([WIDGET_TABLE_WIDGET_COLUMN_TYPES.NUMERIC]),
    comparison: COMPARE_TO_SCHEMA.nullable()
      .optional()
      .transform(transformEmptyObjectToUndefined)
      .default(undefined),
    border_color: yup.string().optional().nullable(),
    highlighting_rules: HIGHLITHING_RULES_SCHEMA.optional()
      .nullable()
      .default(undefined),
    hide_negative_values: yup.boolean().optional().default(undefined),
  });

export type NumericColumnSettingsForm = yup.InferType<
  typeof NUMERIC_COLUMN_SETTINGS_FORM_SCHEMA
>;

export const SPARKLINE_COLUMN_SETTINGS_FORM_SCHEMA =
  BASE_COLUMN_SETTINGS_FORM_SCHEMA.shape({
    type: yup.string().oneOf([WIDGET_TABLE_WIDGET_COLUMN_TYPES.SPARKLINE]),
    stroke_color: yup.string().optional().nullable(),
    fill_color: yup.string().optional().nullable(),
  })
    .concat(DEFAULT_HISTORY_SIZE_FIELD_SCHEMA)
    .concat(DEFAULT_SPARKLINE_TYPE_FIELD_SCHEMA);

export type SparklineColumnSettingsForm = yup.InferType<
  typeof SPARKLINE_COLUMN_SETTINGS_FORM_SCHEMA
>;

export const BASE_ROW_SETTINGS_FORM_SCHEMA = yup.object().shape({
  type: yup
    .string()
    .oneOf(Object.values(WIDGET_TABLE_WIDGET_ROW_TYPES))
    .required(),
});

export type BaseRowSettingsForm = yup.InferType<
  typeof BASE_ROW_SETTINGS_FORM_SCHEMA
>;

export type ColumnSettingsForm =
  | NumericColumnSettingsForm
  | TextColumnSettingsForm
  | SparklineColumnSettingsForm;

export const NUMERIC_ROW_SETTINGS_FORM_SCHEMA =
  BASE_ROW_SETTINGS_FORM_SCHEMA.shape({
    value_display_options: VALUE_DISPLAY_OPTIONS_SCHEMA.nullable().required(),
    font_weight: yup.string().oneOf(FONT_STYLES).optional().default(undefined),
    highlighting_rules: HIGHLITHING_RULES_SCHEMA.optional()
      .nullable()
      .default(undefined),
  });

export const TEXT_ROW_SETTINGS_FORM_SCHEMA =
  BASE_ROW_SETTINGS_FORM_SCHEMA.shape({
    type: yup.string().oneOf([WIDGET_TABLE_WIDGET_ROW_TYPES.TEXT]),
    font_weight: yup.string().oneOf(FONT_STYLES).optional().default(undefined),
    value_display_options: VALUE_DISPLAY_OPTIONS_SCHEMA.nullable().required(),
  }).concat(TEXT_DISPLAY_STRATEGY_DEFAULT_FIELD_SCHEMA);

export const SPARKLINE_ROW_SETTINGS_FORM_SCHEMA =
  BASE_ROW_SETTINGS_FORM_SCHEMA.shape({
    type: yup.string().oneOf([WIDGET_TABLE_WIDGET_ROW_TYPES.SPARKLINE]),
  })
    .concat(DEFAULT_HISTORY_SIZE_FIELD_SCHEMA)
    .concat(DEFAULT_SPARKLINE_TYPE_FIELD_SCHEMA);

export type SparklineRowSettingsForm = yup.InferType<
  typeof SPARKLINE_ROW_SETTINGS_FORM_SCHEMA
>;
export type TextRowSettingsForm = yup.InferType<
  typeof TEXT_ROW_SETTINGS_FORM_SCHEMA
>;

export type NumericRowSettingsForm = yup.InferType<
  typeof NUMERIC_ROW_SETTINGS_FORM_SCHEMA
>;

export type RowSettingsForm =
  | NumericRowSettingsForm
  | TextRowSettingsForm
  | SparklineRowSettingsForm;

export const resolveColumnSettingsType = (
  columnSettings: TableVizConfigColumn,
) => {
  if (isSparklineTableVizConfigColumn(columnSettings)) {
    return WIDGET_TABLE_WIDGET_COLUMN_TYPES.SPARKLINE;
  }
  if (isTextTableVizConfigColumn(columnSettings)) {
    return WIDGET_TABLE_WIDGET_COLUMN_TYPES.TEXT;
  }
  return WIDGET_TABLE_WIDGET_COLUMN_TYPES.NUMERIC;
};

export const resolveRowSettingsType = (rowSettings: TableVizConfigRow) => {
  if (isSparklineTableVizConfigRow(rowSettings)) {
    return WIDGET_TABLE_WIDGET_ROW_TYPES.SPARKLINE;
  }
  if (isTextTableVizConfigRow(rowSettings)) {
    return WIDGET_TABLE_WIDGET_ROW_TYPES.TEXT;
  }
  return WIDGET_TABLE_WIDGET_ROW_TYPES.NUMERIC;
};

export const transformHighlightingRulesToForm = ({
  background,
  comparison,
}: {
  comparison: TableVizConfigColumn['comparison'];
  background: TableVizConfigColumn['background'];
}): NumericColumnSettingsForm['highlighting_rules'] => {
  if (comparison?.to_col_id) {
    return null;
  }
  if (comparison?.rule) {
    return {
      type: 'font',
      color_rule: comparison.rule,
    };
  }
  if (background && background.type === 'gradient') {
    return {
      type: 'column',
      gradient_ignore_zeros: background.ignore_zeros,
      gradient_thresholds: background.threshold.map((t) =>
        t.type === 'percentage'
          ? {
              ...t,
              value: convertDecimalToPercentage(t.value),
            }
          : t,
      ),
    };
  }
  return null;
};

const transformBaseColumnSettings: (
  columnSettings: TableVizConfigColumn,
) => BaseColumnSettingsForm = (columnSettings) => {
  return {
    type: resolveColumnSettingsType(columnSettings),
    value_display_options: columnSettings.value_display_options,
    sort: columnSettings.initial_sort,
    font_weight: columnSettings.font_weight ?? 'normal',
    header: columnSettings.header,
    border_color: columnSettings.border_color,
  };
};

const transformNumericColumnSettings = (
  columnSettings: NumericTableVizConfigColumn,
): NumericColumnSettingsForm => {
  return {
    highlighting_rules: transformHighlightingRulesToForm({
      background: columnSettings.background,
      comparison: columnSettings.comparison,
    }),
    hide_negative_values: columnSettings.comparison?.hide_negative_values,
    comparison: columnSettings.comparison?.to_col_id
      ? {
          rule: columnSettings.comparison.rule,
          compareTo: columnSettings.comparison.to_col_id,
        }
      : null,
  };
};

const transformSparklineColumnSettings = (
  columnSettings: SparklineTableVizConfigColumn,
): Omit<SparklineColumnSettingsForm, 'history_size'> => {
  return {
    sparkline_type: columnSettings.cell_renderer?.params?.type,
    stroke_color: columnSettings.cell_renderer?.params?.stroke,
    fill_color: columnSettings.cell_renderer?.params?.fill,
  };
};

export const transformColumnSettingsToForm = (
  columnSettings: TableVizConfigColumn,
):
  | NumericColumnSettingsForm
  | TextColumnSettingsForm
  | SparklineColumnSettingsForm => {
  if (isSparklineTableVizConfigColumn(columnSettings)) {
    return {
      ...transformBaseColumnSettings(columnSettings),
      ...transformSparklineColumnSettings(columnSettings),
    };
  }
  if (isNumericTableVizConfigColumn(columnSettings)) {
    return {
      ...transformBaseColumnSettings(columnSettings),
      ...transformNumericColumnSettings(columnSettings),
    };
  }
  if (isTextTableVizConfigColumn(columnSettings)) {
    return {
      ...transformBaseColumnSettings(columnSettings),
      text_display_strategy: columnSettings.cell_renderer?.params?.variant,
    };
  }
  return transformBaseColumnSettings(columnSettings);
};

export const transformRowSettingsToForm = (
  rowSettings: TableVizConfigRow,
): RowSettingsForm => {
  if (isSparklineTableVizConfigRow(rowSettings)) {
    return {
      type: 'sparkline',
      value_display_options: rowSettings.value_display_options,
    };
  }
  if (isTextTableVizConfigRow(rowSettings)) {
    return {
      type: 'text',
      value_display_options: rowSettings.value_display_options,
      font_weight: rowSettings.font_weight ?? 'normal',
      text_display_strategy: rowSettings.cell_renderer?.params?.variant,
    };
  }
  return {
    type: 'numeric',
    value_display_options: rowSettings.value_display_options,
    font_weight: rowSettings.font_weight ?? 'normal',
    highlighting_rules: transformHighlightingRulesToForm({
      background: rowSettings.background,
      comparison: rowSettings.comparison,
    }),
  };
};
