import { usePostApiReportFinancialCategoriesSyncMutation } from '@/shared/api/reportFinancialCategoriesApiEnhanced';
import { cn } from '@/shared/lib/css/cn';
import { formatUnixDate } from '@/shared/lib/formatting/dates';
import React from 'react';
import { Button } from 'stories/Button/Button';
import { TagEntity } from 'bundles/Shared/entities/tag';
import { usePostApiSettingsReportFormulaTagsSyncMutation } from '@/shared/api/settingsReportFormulasEnhanced';
import { ReportCustomerBridgeSyncDto } from '@/shared/api/settingsReportFormulasGeneratedApi';
import { IS_UNIFORM } from 'lib/http';
import { SYNC_IN_PROGRESS_NOTE } from 'bundles/Shared/features/syncWithUniform';
import { usePostApiSettingsReportManualVariableTagsSyncMutation } from '@/entities/report/manualVariable';
import { usePostApiRecapPagesSyncMutation } from '@/shared/api/documentationEnhancedApi';

const ENTITY_SYNC_MUTATIONS: Record<
  TagEntity | 'category' | 'documentationPage',
  | typeof usePostApiSettingsReportFormulaTagsSyncMutation
  | typeof usePostApiReportFinancialCategoriesSyncMutation
  | typeof usePostApiSettingsReportManualVariableTagsSyncMutation
  | typeof usePostApiRecapPagesSyncMutation
> = {
  category: usePostApiReportFinancialCategoriesSyncMutation,
  formula: usePostApiSettingsReportFormulaTagsSyncMutation,
  manualVariable: usePostApiSettingsReportManualVariableTagsSyncMutation,
  documentationPage: usePostApiRecapPagesSyncMutation,
};

export const SyncWithClientsButton = ({
  meta,
  entity,
}: {
  entity: TagEntity | 'category' | 'documentationPage' | 'manualVariable';
  meta:
    | Pick<ReportCustomerBridgeSyncDto, 'status' | 'syncedAt'>
    | null
    | undefined;
}) => {
  const useSyncMutation = ENTITY_SYNC_MUTATIONS[entity];

  const [syncFormulaTags] = useSyncMutation();

  if (!IS_UNIFORM) {
    return null;
  }

  const syncInProgressNote =
    entity !== 'category' ? 'Approximate time 5-10 min' : SYNC_IN_PROGRESS_NOTE;

  return (
    <div className="flex flex-col items-end gap-2 max-[720px]:hidden">
      {IS_UNIFORM && (
        <Button
          iconName={meta?.status === 'in_progress' ? 'sync' : 'importUpload'}
          disabled={meta?.status === 'in_progress'}
          onClick={() => syncFormulaTags()}
          variant="secondary"
          size="xs"
        >
          {meta?.status === 'in_progress'
            ? 'Syncing in Progress'
            : 'Sync with Clients'}
        </Button>
      )}

      {meta && (
        <span
          className={cn(
            'secondary-regular text-neutral-550',
            meta.status === 'failed' && 'text-danger-055',
          )}
        >
          {meta.status === 'failed' && 'Last Sync Failed'}
          {meta.status === 'in_progress' && syncInProgressNote}
          {meta.status === 'completed' &&
            meta.syncedAt &&
            `Last updated ${formatUnixDate(
              meta.syncedAt as UnixTime,
              'MMM DD, YYYY h:mm',
            )}`}
        </span>
      )}
    </div>
  );
};
