import React from 'react';
import { SreAvatar } from 'stories/SreAvatar/SreAvatar';
import { IReportTableSegment } from 'types/ReportTableSegment';

interface Props {
  segment: IReportTableSegment;
}

const CellCreatedBy = ({ segment }: Props) => (
  <div className="flex">
    <div className="mr-s">
      <SreAvatar
        text={segment.createdBy.name}
        height="3rem"
        width="3rem"
        className="mr-s"
      />
    </div>
    <div>
      <h6>{segment.createdBy.name}</h6>
      <div className="light-60">{segment.createdBy.role}</div>
    </div>
  </div>
);

export default CellCreatedBy;
