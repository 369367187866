import { DialogProps } from '@/shared/lib/hooks/useModal';
import React, { useState } from 'react';
import { Button } from 'stories/Button/Button';
import { IAsset } from 'types/Asset';
import { IFund } from 'types/Fund';
import { IInvestmentEntity } from 'types/IInvestmentEntity';
import { objectsCount } from '@/pages/portfolio/widgets/Dashboard/investmentEntitiesFilter/InvestmentEntitiesFilter';
import { SidePanel } from 'stories/Modals/Modal/Modal';

interface Props extends DialogProps {
  investmentEntities: Pick<IInvestmentEntity, 'id' | 'name'> & {
    assets: Pick<IAsset, 'id' | 'name'>[];
  } & { funds: Pick<IFund, 'id' | 'name'>[] };
  activeInvestmentEntitiesIds: string[];
}

const MobileManageModal = ({
  investmentEntities,
  activeInvestmentEntitiesIds,
  onClose,
  onSubmit,
}: Props) => {
  const [selectedInvestmentEntitiesIds, setSelectedInvestmentEntitiesIds] =
    useState(activeInvestmentEntitiesIds);

  const toggleSelectedInvestmentEntities = (id) => {
    if (selectedInvestmentEntitiesIds.includes(id)) {
      setSelectedInvestmentEntitiesIds(
        selectedInvestmentEntitiesIds.filter((item) => item !== id),
      );
    } else {
      setSelectedInvestmentEntitiesIds(
        selectedInvestmentEntitiesIds.concat(id),
      );
    }
  };

  return (
    <SidePanel
      header="Investment Entities"
      size="md"
      toggle={onClose}
      bodyPadding="0.5rem 1rem"
      actions={
        <>
          <Button
            variant="success"
            onClick={() => onSubmit(selectedInvestmentEntitiesIds)}
          >
            Apply
          </Button>
        </>
      }
    >
      <div className="flex flex-col">
        {investmentEntities.map((item) => (
          <div className="flex items-center justify-between pb-4" key={item.id}>
            <div className="flex flex-col gap-1">
              <div className="inline-semibold text-dark-60">{item.name}</div>
              <div className="inline-regular text-light-90">
                {[
                  objectsCount(item.assets.length, 'Asset'),
                  objectsCount(item.funds.length, 'Fund'),
                ]
                  .filter(Boolean)
                  .join(', ')}
              </div>
            </div>
            <div>
              <Button
                size="s"
                variant="secondary"
                onClick={() => toggleSelectedInvestmentEntities(item.id)}
              >
                {selectedInvestmentEntitiesIds?.includes(item.id)
                  ? 'Hide'
                  : 'Show'}
              </Button>
            </div>
          </div>
        ))}
      </div>
    </SidePanel>
  );
};

export default MobileManageModal;
