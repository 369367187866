import React, { FC } from 'react';
import { Icon } from 'stories/Icon/Icon';
import { cn } from '@/shared/lib/css/cn';
import styles from 'bundles/DrawPackage/InvoicesFlow/Invoices/invoice/Invoice.module.scss';

interface IPreSelectDrawProps {}

const PreSelectDraw: FC<IPreSelectDrawProps> = ({}) => {
  return (
    <div className={cn('light-50 body-regular', styles.preSelectDraw)}>
      <Icon iconName="file" className="font-24" />
      <div>
        <div>Select Requisition</div>
        <div>to preview the Draw Request</div>
      </div>
    </div>
  );
};

export default PreSelectDraw;
