import React from 'react';
import { Badge } from 'stories/Badges/Badge/Badge';
import { Label } from 'stories/Label/Label';
import { CssVar } from '@/shared/config/cssVar';

export const SET_SOURCE_REMINDER_JSX = (
  <span className="secondary-regular text-red-dark-2">
    First you need to add sources
  </span>
);

export const MANUAL_ENTRY_BADGE_JSX = (
  <Badge
    backgroundColor={CssVar.light}
    textColor={CssVar.dark60}
    className="text-nowrap"
  >
    <span className="secondary-regular">Manual Entry</span>
  </Badge>
);

export const INITIAL_LABEL_JSX = (
  <Label color={CssVar.light60} outline>
    INITIAL
  </Label>
);
export const EXACT_MATCH_LABEL_JSX = (
  <Label color={CssVar.green} outline>
    EXACT MATCH
  </Label>
);
