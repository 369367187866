import React, { useEffect, useState } from 'react';
import { SharedInput } from 'bundles/Shared/components/GroupForm/FormItems/new/SharedInput';
import { Field } from 'stories/Field/Field';
import { IconButton } from 'stories/IconButton/IconButton';
import {
  buildConnectionAddress,
  transformPostfixUserValue,
} from 'bundles/Shared/entities/connection/lib';
import { ERPSystem } from '@/entities/erpsystem';

type ConnectionAddressSelectProps = {
  emailPostfix: string;
  erpSystem: ERPSystem;
  onChange: (postfix: string) => void;
  hideControls?: boolean;
};

const MAX_CONNECTION_TYPE_LENGTH = 8;

export const ConnectionAddressSelect = ({
  emailPostfix,
  erpSystem,
  onChange,
  hideControls,
}: ConnectionAddressSelectProps) => {
  const [postfix, setPostfix] = useState<string>(emailPostfix ?? '');
  const [editMode, setEditMode] = useState<boolean>(false);

  useEffect(() => {
    setPostfix(emailPostfix);
  }, [emailPostfix]);

  const handleSelect = () => {
    onChange(postfix);
    setEditMode(false);
  };

  return (
    <>
      <Field labelText="Connection Address">
        <div className="flex items-center gap-2">
          <SharedInput
            className="flex-1"
            value={buildConnectionAddress(postfix, erpSystem)}
            newGen
            disabled
          />
          {!hideControls && <IconButton size="l" iconName="copyAlt" />}
          {editMode && (
            <IconButton
              className="bg-danger-055 text-white"
              size="l"
              iconName="closeSmall"
              onClick={() => setEditMode(false)}
            />
          )}
          {!editMode && !hideControls && (
            <IconButton
              size="l"
              iconName="editAlt"
              onClick={() => setEditMode(true)}
            />
          )}
        </div>
      </Field>
      {editMode && (
        <Field labelText="Connection Address">
          <div className="flex items-center gap-2">
            <SharedInput
              className="flex-1"
              value={postfix}
              onChange={(e) =>
                setPostfix(transformPostfixUserValue(e.target.value, erpSystem))
              }
              maxLength={MAX_CONNECTION_TYPE_LENGTH}
              newGen
            />
            <IconButton size="l" iconName="check" onClick={handleSelect} />
          </div>
        </Field>
      )}
    </>
  );
};
