import { IconButton } from 'stories/IconButton/IconButton';
import { Tooltip } from 'stories/Tooltip/Tooltip';

export const LockedTooltip = ({ canUnlock }) => (
  <Tooltip
    mainText={
      canUnlock
        ? "You need to unlock this file's permissions before making any changes"
        : "This file's permissions have been locked and cannot be changed."
    }
    arrowPosition="center"
  >
    <IconButton iconName="lock" disabled />
  </Tooltip>
);
