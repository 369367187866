import React from 'react';
import { AnimationLoader } from 'stories/AnimationLoader/AnimationLoader';
import styles from '@/bundles/Shared/components/FilePreviews/FilePreview.module.scss';

const FilePreviewPreloader = () => (
  <div className={styles.preloader}>
    <AnimationLoader />
  </div>
);

export default FilePreviewPreloader;
