export const THEME_COLORS = {
  attention: {
    100: 'rgba(215, 121, 0, 1)',
    '000': 'rgba(255, 248, 235, 1)',
    '020': 'rgba(255, 235, 191, 1)',
    '030': 'rgba(255, 221, 148, 1)',
    '040': 'rgba(255, 209, 111, 1)',
    '050': 'rgba(255, 195, 64, 1)',
    '055': 'rgba(255, 187, 21, 1)',
    '060': 'rgba(255, 178, 0, 1)',
    '070': 'rgba(255, 166, 0, 1)',
    '080': 'rgba(255, 154, 0, 1)',
    '090': 'rgba(248, 138, 0, 1)',
  },
  danger: {
    100: 'rgba(151, 0, 13, 1)',
    '000': 'rgba(255, 240, 241, 1)',
    '020': 'rgba(255, 194, 195, 1)',
    '030': 'rgba(255, 155, 154, 1)',
    '040': 'rgba(255, 117, 117, 1)',
    '050': 'rgba(255, 73, 78, 1)',
    '055': 'rgba(255, 34, 49, 1)',
    '060': 'rgba(255, 0, 29, 1)',
    '070': 'rgba(240, 0, 0, 1)',
    '080': 'rgba(224, 0, 0, 1)',
    '090': 'rgba(191, 0, 8, 1)',
  },
  indigo: {
    100: 'rgba(11, 33, 65, 1)',
    '000': 'rgba(241, 243, 249, 1)',
    '020': 'rgba(197, 205, 230, 1)',
    '030': 'rgba(157, 171, 215, 1)',
    '040': 'rgba(122, 142, 200, 1)',
    '050': 'rgba(85, 109, 185, 1)',
    '055': 'rgba(60, 88, 175, 1)',
    '060': 'rgba(56, 83, 169, 1)',
    '070': 'rgba(49, 72, 147, 1)',
    '080': 'rgba(41, 62, 133, 1)',
    '090': 'rgba(3, 40, 98, 1)',
  },
  info: {
    100: 'rgba(9, 65, 102, 1)',
    '000': 'rgba(238, 247, 254, 1)',
    '020': 'rgba(180, 223, 249, 1)',
    '030': 'rgba(130, 201, 244, 1)',
    '040': 'rgba(87, 182, 241, 1)',
    '050': 'rgba(45, 162, 237, 1)',
    '055': 'rgba(0, 146, 238, 1)',
    '060': 'rgba(22, 139, 221, 1)',
    '070': 'rgba(20, 129, 205, 1)',
    '080': 'rgba(19, 105, 163, 1)',
    '090': 'rgba(8, 80, 128, 1)',
  },
  neutral: {
    100: 'rgba(239, 240, 241, 1)',
    150: 'rgba(224, 227, 228, 1)',
    200: 'rgba(217, 220, 221, 1)',
    250: 'rgba(214, 217, 218, 1)',
    300: 'rgba(193, 198, 200, 1)',
    400: 'rgba(173, 179, 182, 1)',
    450: 'rgba(152, 160, 163, 1)',
    500: 'rgba(132, 141, 145, 1)',
    550: 'rgba(112, 122, 127, 1)',
    600: 'rgba(92, 103, 109, 1)',
    650: 'rgba(71, 84, 90, 1)',
    700: 'rgba(51, 65, 72, 1)',
    750: 'rgba(44, 56, 62, 1)',
    800: 'rgba(39, 50, 56, 1)',
    850: 'rgba(33, 42, 47, 1)',
    900: 'rgba(26, 34, 37, 1)',
    950: 'rgba(13, 17, 19, 1)',
    999: 'rgba(0, 0, 0, 1)',
    '000': 'rgba(255, 255, 255, 1)',
    '050': 'rgba(245, 245, 246, 1)',
  },
  'new-primary': {
    100: 'rgba(6, 20, 44, 1)',
    '000': 'rgba(181, 187, 198, 1)',
    '020': 'rgba(157, 164, 178, 1)',
    '030': 'rgba(132, 141, 160, 1)',
    '040': 'rgba(109, 119, 140, 1)',
    '050': 'rgba(85, 96, 120, 1)',
    '055': 'rgba(59, 74, 101, 1)',
    '060': 'rgba(34, 51, 81, 1)',
    '070': 'rgba(7, 29, 62, 1)',
    '080': 'rgba(7, 25, 56, 1)',
    '090': 'rgba(7, 23, 50, 1)',
  },
  success: {
    100: 'rgba(0, 90, 40, 1)',
    '000': 'rgba(237, 249, 241, 1)',
    '020': 'rgba(178, 233, 197, 1)',
    '030': 'rgba(124, 216, 161, 1)',
    '040': 'rgba(68, 203, 126, 1)',
    '050': 'rgba(0, 187, 88, 1)',
    '055': 'rgba(0, 177, 60, 1)',
    '060': 'rgba(0, 164, 51, 1)',
    '070': 'rgba(0, 148, 46, 1)',
    '080': 'rgba(0, 133, 41, 1)',
    '090': 'rgba(0, 108, 46, 1)',
  },
  white: '#fff',
  black: '#000',
  transparent: 'transparent',

  'light-10': '#e0e3e4',
  'light-20': '#d6d9da',
  light: {
    DEFAULT: '#f8f9fa',
    5: '#eff0f1',
    10: '#e0e3e4',
    15: '#d9dcdd',
    20: '#d6d9da',
    30: '#c1c6c8',
    40: '#adb3b6',
    50: '#98a0a3',
    60: '#848d91',
    70: '#707a7f',
    80: '#5c676d',
    90: '#47545a',
  },
  'dark-60': '#212a2f', // TODO: remove when bootstrap will be removed
  dark: {
    DEFAULT: '#334148',
    20: '#2c383e',
    40: '#273238',
    60: '#212a2f',
    80: '#1a2225',
    100: '#0d1113',
  },

  // todo remove when bootstrap will be removed
  // resolves conflict with bootstrap bg-dark.button:focus
  'tw-primary': '#3e4a63',
  primary: {
    DEFAULT: '#3e4a63',
    light: {
      1: '#576076',
      2: '#6f778a',
      3: '#868d9e',
      4: '#9ea4b1',
      5: '#b6bbc5',
    },
    dark: {
      1: '#26334f',
      2: '#0d1c3c',
      3: '#0c1936',
      4: '#0b1730',
      5: '#09132a',
    },
  },

  bl: '#0092ee', // DEPRECATED use "blue" instead
  blue: {
    DEFAULT: '#0092ee',
    light: {
      5: '#eef7fe',
      4: '#b4dff9',
      3: '#82c9f4',
      2: '#57b6f1',
      1: '#2da2ed',
    },
    dark: {
      1: '#168bdd',
      2: '#1481cd',
      3: '#1369a3',
      4: '#085080',
      5: '#094166',
    },
  },

  gr: '#2cae4c', // DEPRECATED use "green" instead
  green: {
    DEFAULT: '#2cae4c',
    light: {
      5: '#eff9f2',
      4: '#bde7c8',
      3: '#92d6a5',
      2: '#6dc885',
      1: '#45b863',
    },
    dark: {
      1: '#25a143',
      2: '#21913c',
      3: '#1c8336',
      4: '#0f6a35',
      5: '#0c582d',
    },
  },

  pink: {
    '000': 'rgba(254, 239, 244, 1)',
    100: 'rgba(110, 21, 52, 1)',
    '020': 'rgba(249, 187, 207, 1)',
    '030': 'rgba(244, 143, 175, 1)',
    '040': 'rgba(241, 102, 148, 1)',
    '050': 'rgba(237, 61, 118, 1)',
    '055': 'rgba(235, 34, 98, 1)',
    '060': 'rgba(221, 32, 97, 1)',
    '070': 'rgba(188, 32, 85, 1)',
    '080': 'rgba(165, 29, 76, 1)',
  },

  teal: {
    DEFAULT: '#169B84',
    '000': 'rgba(238, 248, 246, 1)',
    '020': 'rgba(180, 225, 218, 1)',
    '030': 'rgba(130, 205, 193, 1)',
    '040': 'rgba(87, 187, 172, 1)',
    '050': 'rgba(44, 168, 148, 1)',
    '055': 'rgba(22, 155, 132, 1)',
    '060': 'rgba(19, 137, 117, 1)',
    '070': 'rgba(17, 128, 104, 1)',
    '080': 'rgba(14, 111, 89, 1)',
    '090': 'rgba(11, 98, 79, 1)',
    100: 'rgba(10, 75, 59, 1)',

    light: {
      5: '#EEF8F6',
      4: '#B4E1DA',
      3: '#82CDC1',
      2: '#57BBAC',
      1: '#2CA894',
    },
    dark: {
      1: '#138975',
      2: '#118068',
      3: '#0E6F59',
      4: '#0B624F',
      5: '#0A4B3B',
    },
  },

  re: '#f6413e', // DEPRECATED use "red" instead
  red: {
    DEFAULT: '#f6413e',
    light: {
      5: '#fff1f1',
      4: '#fcc5c4',
      3: '#f9a09d',
      2: '#f87e7a',
      1: '#f75a56',
    },
    dark: {
      1: '#fc3231',
      2: '#eb1c2c',
      3: '#cd0a1b',
      4: '#af0e1a',
      5: '#8a0f17',
    },
  },

  violet: {
    DEFAULT: '#6645b0',
    100: 'rgba(48, 22, 85, 1)',
    '000': 'rgba(244, 242, 251, 1)',
    '020': 'rgba(211, 197, 234, 1)',
    '030': 'rgba(182, 160, 219, 1)',
    '040': 'rgba(156, 126, 206, 1)',
    '050': 'rgba(128, 90, 192, 1)',
    '055': 'rgba(108, 67, 182, 1)',
    '060': 'rgba(102, 62, 175, 1)',
    '070': 'rgba(94, 56, 167, 1)',
    '080': 'rgba(76, 48, 128, 1)',
    '090': 'rgba(63, 27, 110, 1)',
    light: {
      1: '#7a5cba',
      2: '#977fc9',
      3: '#b2a1d7',
      4: '#d1c6e7',
      5: '#f4f2fa',
    },
    dark: {
      1: '#6040a9',
      2: '#593aa1',
      3: '#48317c',
      4: '#3a1d6a',
      5: '#2d1752',
    },
  },

  yellow: {
    DEFAULT: '#FEBE46',
    light: {
      1: '#FEC65B',
      2: '#FED37E',
      3: '#FEDE9E',
      4: '#FFECC4',
      5: '#FFF8EC',
    },
    dark: {
      1: '#FEB636',
      2: '#FDAB32',
      3: '#F89F27',
      4: '#E99023',
      5: '#CA7E1F',
    },
  },

  pumpkin: {
    DEFAULT: '#fd6135',
    100: 'rgba(185, 26, 0, 1)',
    '000': 'rgba(255, 243, 240, 1)',
    '020': 'rgba(255, 205, 189, 1)',
    '030': 'rgba(255, 171, 145, 1)',
    '040': 'rgba(255, 140, 105, 1)',
    '050': 'rgba(255, 107, 61, 1)',
    '055': 'rgba(255, 81, 26, 1)',
    '060': 'rgba(255, 68, 2, 1)',
    '070': 'rgba(250, 52, 0, 1)',
    '080': 'rgba(238, 43, 0, 1)',
    '090': 'rgba(213, 30, 0, 1)',
    light: {
      1: '#fd764c',
      2: '#fd9372',
      3: '#feaf96',
      4: '#fecfc0',
      5: '#fff4f1',
    },
    dark: {
      1: '#f9562b',
      2: '#e64821',
      3: '#db401c',
      4: '#c43414',
      5: '#aa2d10',
    },
  },
} as const;
