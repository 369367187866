import { useGetApiAccountingTransactionsQuery } from '@/shared/api/accountingTransactionsEnhancedApi';
import { AccountingTransaction } from '@/entities/accounting/transaction/model';

export const useAccountingTransactions = (
  args: Parameters<typeof useGetApiAccountingTransactionsQuery>[0],
) => {
  return useGetApiAccountingTransactionsQuery(
    {
      page: 1,
      perPage: 10,
      ...args,
    },
    {
      selectFromResult: ({ data, ...rest }) => ({
        transactions: data?.items as AccountingTransaction[],
        meta: data?.meta,
        ...rest,
      }),
    },
  );
};
