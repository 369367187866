import {
  DistributionKind,
  DISTRIBUTION_KINDS,
} from 'bundles/REturn/actions/types';
import { TConfirmFuncProps } from '@/shared/lib/hooks/useModal';
import { startCase } from 'lodash-es';
import { ComponentProps } from 'react';
import { ISelectOption } from 'stories/FormControls/Select/Select';
import { ConfirmationModal } from 'stories/Modals/ConfirmationModal/ConfirmationModal';
import { Step } from '@/bundles/REturn/components/Ownership/modals/HeaderForStepperModal/HeaderForStepperModal';
import { SUCCESS_CONFIRM_MODAL_TITLE_JSX } from '@/bundles/REturn/components/workflows/createEntry/dumbJSX';
import { DistributionKindOption } from '@/bundles/REturn/components/workflows/createEntry/types';

export const RIGHT_SIDE_PANEL_WIDTH = 480;

export const MIN_ITEMS_COUNT_FOR_SPLIT = 2;

export const SPLIT_TRANSACTION_BETWEEN_CATEGORIES_TOKEN =
  'split_transaction_between_categories';

export const CREATE_ENTRY_WORKFLOW_STEPS: Step[] = [
  {
    name: 'Transactions',
  },
  {
    name: 'Investment Entities',
  },
  {
    name: 'Review',
  },
];

export const SPLIT_TRANSACTION_OPTION: DistributionKindOption = {
  label: startCase(SPLIT_TRANSACTION_BETWEEN_CATEGORIES_TOKEN),
  value: SPLIT_TRANSACTION_BETWEEN_CATEGORIES_TOKEN,
};

export const ENTRY_TYPE_SEARCH_PARAM_KEY_NAME = 'entryType';

export const entryCreationConfirmModalProps = {
  title: SUCCESS_CONFIRM_MODAL_TITLE_JSX,
  actions: {
    primaryButton: {
      text: 'Yes',
      variant: 'success',
    },
    secondaryButton: {
      text: 'No',
      variant: 'secondary',
    },
  },
} as const satisfies Pick<
  ComponentProps<typeof ConfirmationModal>,
  'actions' | 'title'
>;

export const entryCreationConfirmWorkIsDoneModalProps = {
  title: SUCCESS_CONFIRM_MODAL_TITLE_JSX,
  actions: {
    primaryButton: {
      text: 'Ok',
      variant: 'success',
    },
  },
} as const satisfies Pick<
  ComponentProps<typeof ConfirmationModal>,
  'actions' | 'title'
>;

export const CONFIRM_CLOSING_WORKFLOW_PROPS = {
  title: 'Are you sure you want to quit?',
  subtitle: 'Closing will lead to the loss of all progress',
  actions: {
    primaryButton: {
      text: 'Quit',
      variant: 'danger',
    },
    secondaryButton: {
      text: 'Cancel',
      variant: 'secondary',
    },
  },
} as const satisfies TConfirmFuncProps;

export const DISTRIBUTION_KIND_SELECT_OPTIONS = DISTRIBUTION_KINDS.map<
  ISelectOption<DistributionKind>
>((kind) => ({
  id: kind,
  value: kind,
  label: startCase(kind),
}));

export const MAX_SPLITTED_ENTRY_TRANSACTIONS_COUNT =
  DISTRIBUTION_KIND_SELECT_OPTIONS.length;

export const INLINE_ALERT_TEXT_MAP_FOR_INVESTMENT_ENTITY = {
  single:
    'Selecting more than one Transaction will require you to choose just one Investment Entity',
  multiple: 'You can select one Entity to link or multiple Entities to split',
} as const;
