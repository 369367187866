import { useUnitPriceFieldFlags } from '@/bundles/REturn/components/Ownership/modals/manageCapitalInvestmentModal/hooks/useUnitPriceFieldFlags';
import { resolveUnitsInputTooltipText } from '@/bundles/REturn/components/Ownership/modals/manageCapitalInvestmentModal/utils';
import { Contribution } from '@/entities/return';
import { useGetApiCapitalInvestmentCommitmentsQuery } from '@/entities/return/api/capitalInvestmentObjectsEnhancedApi';
import {
  Commitment,
  InvestmentIndex,
  InvestmentObjectUnitPrice,
} from '@/entities/return/api/capitalInvestmentObjectsGeneratedApi';
import { formatDate } from '@/shared/lib/formatting/dates';
import { assertIsSelectOption } from '@/stories/FormControls/Select/utils';
import { STATIC_DATE_PICKER_PROPS } from 'bundles/REturn/components/Ownership/modals/consts';
import { UnitsInputNumber } from 'bundles/REturn/components/Ownership/modals/disAndContriubutionsModals/TotalWithUnits';
import CurrencyInputNumber from 'bundles/Shared/components/GroupForm/FormItems/CurrencyInputNumber';
import { SettingsModal } from 'bundles/Shared/components/SettingsModal/SettingsModal';
import dayjs from 'dayjs';
import { useModal } from '@/shared/lib/hooks/useModal';
import { parseNumberFromCurrencyInput } from '@/shared/lib/formatting/number';
import { ComponentProps } from 'react';
import ReactDatePicker from 'react-datepicker';
import { createPeriodFromDate } from 'stories/FlexibleFilterByPeriods/utils';
import { Input } from 'stories/FormControls/Inputs/Input/Input';
import { Select } from 'stories/FormControls/Select/Select';

export type ContributionForm = {
  amount: number | undefined;
  date: DateString | undefined;
  investmentClass: InvestmentObjectUnitPrice['investmentClass'] | null;
  signedDate?: DateString | undefined;
  unitsQuantity?: Contribution['unitsQuantity'];
};

type ReturnContributionForm = {
  amount: number;
  signedDate: DateString | undefined;
  date: DateString;
  investmentClass: InvestmentObjectUnitPrice['investmentClass'];
  commitmentId: Commitment['id'];
  unitsQuantity?: Contribution['unitsQuantity'];
};

const INVESTMENT_CLASSES_OPTIONS = [
  {
    id: 'a' as InvestmentObjectUnitPrice['investmentClass'],
    label: 'A',
  },
  {
    id: 'b' as InvestmentObjectUnitPrice['investmentClass'],
    label: 'B',
  },
];

type Params = {
  initialForm: ContributionForm;
} & Partial<
  Pick<
    ComponentProps<typeof SettingsModal>,
    'modalHeaderProps' | 'submitButtonText'
  >
>;

export function useAddManualContributionWithUnitPricesSettingsModal({
  capitalInvestment,
}: {
  capitalInvestment: InvestmentIndex;
}) {
  const { openModal } = useModal();

  const { data: capitalInvestmentCommitmentsData } =
    useGetApiCapitalInvestmentCommitmentsQuery({
      investmentIds: [capitalInvestment.id],
    });

  const capitalInvestmentCommitments = capitalInvestmentCommitmentsData ?? [];

  const commitmentSignedDateOptions =
    capitalInvestmentCommitments
      ?.filter((commit) => commit.signedDate != null)
      ?.map((commit) => ({
        id: commit.signedDate as DateString,
        label: formatDate(commit.signedDate as DateString, 'MMM DD, YYYY'),
        value: commit.id,
      })) ?? [];

  const hasNoOptions = commitmentSignedDateOptions.length === 0;

  const resolveUnitPriceFieldFlags = useUnitPriceFieldFlags();

  return async (
    { initialForm, modalHeaderProps, submitButtonText }: Params = {
      initialForm: {
        amount: 0,
        signedDate: undefined,
        date: undefined,
        investmentClass: null,
        unitsQuantity: undefined,
      },
    },
  ) => {
    const res = await openModal(
      SettingsModal<ContributionForm, ReturnContributionForm>,
      {
        initialForm,
        classes: {
          fieldsContainer: 'flex-wrap flex-row',
        },
        modalHeaderProps: modalHeaderProps ?? {
          title: 'Add New Contribution',
          subtitle: capitalInvestment.investmentEntity.name,
        },
        submitButtonText: submitButtonText ?? 'Add Contribution',
        isDisabled: (form) => {
          if (form.date == null) return true;

          const { isClosestUnitPriceZero } = resolveUnitPriceFieldFlags({
            form,
            hasNoCommitmentsOptions: hasNoOptions,
          });

          if (isClosestUnitPriceZero) {
            return (
              form.investmentClass == null ||
              form.unitsQuantity == null ||
              form.unitsQuantity === 0
            );
          }

          if (form.amount == null || form.amount === 0) return true;

          if (hasNoOptions) {
            return form.investmentClass == null;
          }

          if (form.investmentClass === 'b') {
            return form.signedDate == null;
          }

          return form.investmentClass == null;
        },
        fields: [
          {
            fieldProps: {
              className: 'w-full',
            },
            name: 'class',
            renderer({ form, setForm }) {
              return (
                <Select<InvestmentObjectUnitPrice['investmentClass']>
                  placeholder="Select Class"
                  size="m"
                  options={INVESTMENT_CLASSES_OPTIONS}
                  selected={INVESTMENT_CLASSES_OPTIONS.find(
                    (o) => o.id === form.investmentClass,
                  )}
                  onSelectedChange={(selected) => {
                    assertIsSelectOption(selected);
                    setForm((prev) => ({
                      ...prev,
                      investmentClass: selected.id ?? null,
                      amount: undefined,
                      date: undefined,
                      signedDate: undefined,
                      unitsQuantity: undefined,
                    }));
                  }}
                />
              );
            },
          },
          {
            isHidden: (f) => f.investmentClass !== 'b' || hasNoOptions,
            name: 'signed date',
            fieldProps: {
              className: 'w-full',
            },
            renderer({ form, setForm }) {
              return (
                <Select<DateString>
                  placeholder={
                    hasNoOptions ? 'No Options' : 'Select Signed Date'
                  }
                  size="m"
                  options={commitmentSignedDateOptions}
                  disabled={hasNoOptions || form.investmentClass == null}
                  selected={commitmentSignedDateOptions.find(
                    (o) => o.id === form.signedDate,
                  )}
                  onSelectedChange={(selected) => {
                    assertIsSelectOption(selected);
                    setForm((prev) => ({
                      ...prev,
                      amount: form.investmentClass === 'b' ? 0 : prev.amount,
                      signedDate: selected.id ?? null,
                      commitmentId: selected.value,
                    }));
                  }}
                  inputProps={{
                    leftIcon: 'calendar',
                  }}
                />
              );
            },
          },
          {
            name: 'date',
            fieldProps: {
              className: 'w-full',
            },
            renderer({ form, setForm }) {
              return (
                <ReactDatePicker
                  {...STATIC_DATE_PICKER_PROPS}
                  selected={form.date ? dayjs(form.date).toDate() : null}
                  onChange={(newDate) => {
                    setForm((prev) => ({
                      ...prev,
                      amount: form.investmentClass === 'a' ? 0 : prev.amount,
                      date: createPeriodFromDate(newDate ?? new Date()),
                      unitsQuantity: undefined,
                    }));
                  }}
                />
              );
            },
          },
          {
            name: 'value',
            fieldProps(form) {
              const { isClosestUnitPriceZero } = resolveUnitPriceFieldFlags({
                form,
                hasNoCommitmentsOptions: hasNoOptions,
              });
              if (isClosestUnitPriceZero) {
                return {};
              }
              return {
                className: 'w-[48%]',
              };
            },
            isHidden(form) {
              const { isClosestUnitPriceZero } = resolveUnitPriceFieldFlags({
                form,
                hasNoCommitmentsOptions: hasNoOptions,
              });

              return isClosestUnitPriceZero;
            },
            renderer({ form, setForm }) {
              return (
                <CurrencyInputNumber
                  selectOnFocus
                  size="m"
                  value={form.amount ?? ''}
                  allowNegative={false}
                  onValueChange={(value) => {
                    setForm((prev) => ({
                      ...prev,
                      amount: value ?? undefined,
                    }));
                  }}
                />
              );
            },
          },
          {
            name: 'units',
            fieldProps: (form) => {
              const { isClosestUnitPriceZero } = resolveUnitPriceFieldFlags({
                form,
                hasNoCommitmentsOptions: hasNoOptions,
              });

              if (isClosestUnitPriceZero) {
                return {
                  className: 'w-full',
                };
              }
              return {
                className: 'w-[48%] ml-auto',
              };
            },
            renderer({ form, setForm }) {
              const {
                useSignedDateForUnitPriceDate,
                noAvailableUnitPricesByInvestmentClass,
                unitsDisabledToChange,
                closestUnitPrice,
                value,
                isClosestUnitPriceZero,
              } = resolveUnitPriceFieldFlags({
                form,
                hasNoCommitmentsOptions: hasNoOptions,
              });

              return (
                <UnitsInputNumber
                  tooltipMainText={resolveUnitsInputTooltipText({
                    investmentClass: form.investmentClass!,
                    useSignedDateForUnitPriceDate,
                    noAvailableUnitPricesByInvestmentClass,
                  })}
                  selectOnFocus
                  classes={{
                    outer: 'w-full',
                  }}
                  placeholder="0"
                  disabled={
                    unitsDisabledToChange ||
                    noAvailableUnitPricesByInvestmentClass
                  }
                  value={value ?? ''}
                  onChange={(e) => {
                    if (isClosestUnitPriceZero) {
                      setForm((prev) => ({
                        ...prev,
                        unitsQuantity: Number(e.target.value),
                      }));
                      return;
                    }
                    const amount =
                      e.target.value && closestUnitPrice
                        ? (parseNumberFromCurrencyInput(e.target.value) ?? 0) *
                          closestUnitPrice
                        : undefined;
                    setForm((prev) => ({
                      ...prev,
                      amount,
                    }));
                  }}
                />
              );
            },
          },
        ],
      },
    );

    return res;
  };
}

export function useAddManualContributionSettingsModal({
  capitalInvestment,
}: {
  capitalInvestment: InvestmentIndex;
}) {
  const { openModal } = useModal();

  return async (
    { initialForm, modalHeaderProps, submitButtonText }: Params = {
      initialForm: {
        amount: 0,
        date: undefined,
        investmentClass: null,
        signedDate: undefined,
      },
    },
  ) => {
    const res = await openModal(
      SettingsModal<ContributionForm, ReturnContributionForm>,
      {
        initialForm,
        modalHeaderProps: modalHeaderProps ?? {
          title: 'Add New Contribution',
          subtitle: capitalInvestment.investmentEntity.name,
        },
        submitButtonText: submitButtonText ?? 'Add Contribution',
        isDisabled: (f) => f.date == null || !f.amount,
        fields: [
          {
            fieldProps: {
              className: 'w-full',
            },
            name: 'class',
            renderer({ form, setForm }) {
              return (
                <Select<InvestmentObjectUnitPrice['investmentClass']>
                  placeholder="Select Class"
                  size="m"
                  options={INVESTMENT_CLASSES_OPTIONS}
                  selected={INVESTMENT_CLASSES_OPTIONS.find(
                    (o) => o.id === form.investmentClass,
                  )}
                  onSelectedChange={(selected) => {
                    assertIsSelectOption(selected);
                    setForm((prev) => ({
                      ...prev,
                      investmentClass: selected.id ?? null,
                    }));
                  }}
                />
              );
            },
          },
          {
            name: 'date',
            renderer({ form, setForm }) {
              return (
                <ReactDatePicker
                  {...STATIC_DATE_PICKER_PROPS}
                  selected={form.date ? dayjs(form.date).toDate() : null}
                  onChange={(newDate) => {
                    setForm((prev) => ({
                      ...prev,
                      date: createPeriodFromDate(newDate ?? new Date()),
                    }));
                  }}
                  customInput={
                    <Input
                      leftIcon="calendar"
                      rightIcon="arrowBottom"
                      size="m"
                    />
                  }
                />
              );
            },
          },
          {
            name: 'value',
            renderer({ form, setForm }) {
              return (
                <CurrencyInputNumber
                  selectOnFocus
                  size="m"
                  value={form.amount}
                  allowNegative={false}
                  onValueChange={(value) => {
                    setForm((prev) => ({
                      ...prev,
                      amount: value ?? undefined,
                    }));
                  }}
                />
              );
            },
          },
        ],
      },
    );

    return res;
  };
}
