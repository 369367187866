import { useMemo } from 'react';
import * as Const from '@/stories/FlexibleFilterByPeriods/consts';
import * as Types from '@/stories/FlexibleFilterByPeriods/types';
import * as Util from '@/stories/FlexibleFilterByPeriods/utils';

interface Params {
  groupedByYearPossiblePeriods: Types.PossiblePeriodsGroupedByYear;
}

export function useAvailableYears({ groupedByYearPossiblePeriods }: Params) {
  return useMemo(() => {
    const entries = Object.entries(groupedByYearPossiblePeriods);

    const yearArrForYearly = entries
      .filter(([_, periodArr]) => periodArr.length !== 0)
      .map(([year]) => year);

    const yearEntries = entries.map<
      Entry<string, Entries<Types.QuarterMapKey, number[]>>
    >(([year, periodArr]) => {
      const monthNumberArr = periodArr.map(Util.getMonthNumberFromPeriod);
      return [year, Util.createQuarterEntries(monthNumberArr)];
    });

    const yearEntriesForQuarterly = yearEntries.filter(
      ([_, quarterEntries]) =>
        quarterEntries.length &&
        quarterEntries.some(
          ([__, monthNumbers]) =>
            monthNumbers.length >= Const.MIN_MONTHS_IN_QUARTER,
        ),
    );

    const yearEntriesForMonthly = yearEntries.filter(
      ([_, quarterEntries]) => quarterEntries.length,
    );

    return {
      yearArrForYearly,
      yearEntriesForQuarterly,
      yearEntriesForMonthly,
    };
  }, [groupedByYearPossiblePeriods]);
}
