import { Fragment, lazy, Suspense, useCallback } from 'react';
import { AnimationLoader } from '@/stories/AnimationLoader/AnimationLoader';
import { Button } from '@/stories/Button/Button';
import { Modal } from '@/stories/Modals/Modal/Modal';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import HeaderForStepperModal, {
  Step,
} from '@/bundles/REturn/components/Ownership/modals/HeaderForStepperModal/HeaderForStepperModal';
import {
  CoreLegalEntitiesManagementPeriodDto,
  CoreLegalEntitiesRawDataPeriodDto,
  CoreLegalEntityDto,
} from '@/entities/core/legalEntity/api/settingsCoreLegalEntitiesGeneratedApi';
import { FormProvider, Resolver, useForm } from 'react-hook-form';
import { ERPSystemLabel } from '@/entities/erpsystem/ui/ERPSystemLabel';
import { ConnectionReportTypeLabel } from '@/bundles/Shared/entities/connection/ui/ConnectionReportTypeLabel';
import { usePutApiSettingsCoreLegalEntitiesByIdManagementPeriodGroupsAndTypeMutation } from '@/entities/core/legalEntity/api/settingsCoreLegalEntitiesEnhancedApi';
import { ERPSystem } from '@/entities/erpsystem';
import { cn } from '@/shared/lib/css/cn';
import { TruncatedText } from '@/shared/ui/TruncatedText';

const LegalEntityManagmentPeriodsMappingSource = lazy(() =>
  import(
    '@/bundles/Settings/components/Portal/LegalEntities/components/LegalEntityManagmentPeriodsMappingSource'
  ).then((c) => ({ default: c.LegalEntityManagmentPeriodsMappingSource })),
);

const LegalEntityManagmentPeriodsMappingLegalEntity = lazy(() =>
  import(
    '@/bundles/Settings/components/Portal/LegalEntities/components/LegalEntityManagmentPeriodsMappingLegalEntity'
  ).then((c) => ({ default: c.LegalEntityManagmentPeriodsMappingLegalEntity })),
);

const LegalEntityManagmentPeriodsMappingPeriods = lazy(() =>
  import(
    '@/bundles/Settings/components/Portal/LegalEntities/components/LegalEntityManagmentPeriodsMappingPeriods'
  ).then((c) => ({ default: c.LegalEntityManagmentPeriodsMappingPeriods })),
);

const LegalEntityManagmentPeriodsMappingReview = lazy(() =>
  import(
    '@/bundles/Settings/components/Portal/LegalEntities/components/LegalEntityManagmentPeriodsMappingReview'
  ).then((c) => ({ default: c.LegalEntityManagmentPeriodsMappingReview })),
);

type Props = DialogProps & {
  initialSourceTab?: 'included' | 'excluded';
  onAfterClose?: (success?: boolean) => void;
};
type FormStep = 'source' | 'legalEntity' | 'periods' | 'review';

export type LegalEntityManagmentPeriodsMappingValues = {
  step: FormStep;
  source: CoreLegalEntitiesRawDataPeriodDto;
  legalEntity: CoreLegalEntityDto;
  periods: CoreLegalEntitiesManagementPeriodDto[];
};

const formSteps = [
  {
    name: 'Source',
    type: 'source',
  },
  {
    name: 'Legal Entity',
    type: 'legalEntity',
  },
  {
    name: 'Periods',
    type: 'periods',
  },
  {
    name: 'Review',
    type: 'review',
  },
] as const satisfies (Step & {
  type: FormStep;
})[];

const stepOrder: FormStep[] = formSteps.map((step) => step.type);

const resolver: Resolver<LegalEntityManagmentPeriodsMappingValues> = (
  values,
) => {
  switch (values.step) {
    case 'source': {
      if (!values.source) {
        return {
          values,
          errors: {
            type: 'Source is required',
          },
        };
      }

      break;
    }
    case 'legalEntity': {
      if (!values.legalEntity) {
        return {
          values,
          errors: {
            id: 'Legal Entity is required',
          },
        };
      }

      if (!values.legalEntity.legalEntitiable) {
        return {
          values,
          errors: {
            id: 'Object is required, please set object for selected legal entity',
          },
        };
      }

      break;
    }
    case 'periods': {
      if (!values.periods.length) {
        break;
      }

      const mappedPeriod = values.periods.find(
        (period) =>
          period.pmcManagedObjectId === values.source.pmcManagedObjectId,
      );

      if (mappedPeriod?.dateFrom ?? mappedPeriod?.dateTo) {
        break;
      }

      return {
        values,
        errors: {
          id: 'Periods are required',
        },
      };
    }
  }

  return {
    values,
    errors: {},
  };
};

export const LegalEntityManagmentPeriodsMappingModal = ({
  initialSourceTab,
  onClose,
  onAfterClose,
}: Props) => {
  const form = useForm({
    resolver,
    mode: 'onSubmit',
    defaultValues: {
      step: stepOrder[0],
      periods: [],
    },
  });
  const {
    watch,
    handleSubmit,
    setValue,
    resetField,
    formState: { isValid },
  } = form;
  const fields = watch();
  const currentStepIndex = stepOrder.indexOf(fields.step);
  const isLastStep = currentStepIndex === stepOrder.length - 1;

  const [updatePeriods, { isLoading: isSaveLoading }] =
    usePutApiSettingsCoreLegalEntitiesByIdManagementPeriodGroupsAndTypeMutation();

  const handleClose = (success?: boolean) => {
    onClose?.();
    onAfterClose?.(success);
  };

  const setDefaultPeriodBySource = () => {
    return setValue('periods', [
      {
        ...fields.source,
        dateFrom: null,
        dateTo: null,
        rawDataPeriod: {
          dateFrom: fields.source.dateFrom,
          dateTo: fields.source.dateTo,
        },
      },
    ]);
  };

  const next = useCallback(async () => {
    if (isLastStep) {
      const res = await updatePeriods({
        id: fields.legalEntity.id,
        type: fields.source.type,
        body: fields.periods.map((period) => ({
          pmc_managed_object_id: period.pmcManagedObjectId,
          date_from: period.dateFrom,
          date_to: period.dateTo,
        })),
      });

      if ('error' in res) {
        return;
      }

      handleClose(true);

      return;
    }

    if (fields.step === 'periods' && !fields.periods.length) {
      setDefaultPeriodBySource();
    }

    setValue('step', stepOrder[currentStepIndex + 1], {
      shouldValidate: true,
    });
  }, [
    fields.step,
    fields.periods,
    isLastStep,
    updatePeriods,
    onClose,
    stepOrder,
    setValue,
  ]);

  const handleNextStep = async () => {
    await handleSubmit(next, console.error)();
  };

  const handleBackStep = () => {
    const previousStep = Math.max(0, currentStepIndex - 1);

    // clear current step
    resetField(
      stepOrder[
        currentStepIndex
      ] as keyof LegalEntityManagmentPeriodsMappingValues,
    );

    resetField(
      stepOrder[previousStep] as keyof LegalEntityManagmentPeriodsMappingValues,
    );

    // go to previous step
    setValue('step', stepOrder[previousStep], {
      shouldValidate: true,
    });
  };

  const FooterActionsWrapper = isLastStep
    ? ({ children }: React.PropsWithChildren) => (
        <div className="flex-grow">
          <div className="mx-auto flex max-w-[33.75rem] gap-4">{children}</div>
        </div>
      )
    : Fragment;

  return (
    <Modal
      toggle={() => handleClose()}
      defaultHeaderContainer={false}
      header={
        <>
          <HeaderForStepperModal
            heading="Data Source Mapping"
            currentIdx={currentStepIndex}
            steps={formSteps}
            onClose={() => handleClose()}
          />
          {fields.source &&
            currentStepIndex > stepOrder.indexOf('source') &&
            !isLastStep && (
              <div className="flex gap-6 px-6 py-4 bg-white border-b rounded-lg text-nowrap border-neutral-150">
                <div className="flex justify-between flex-grow gap-6 max-w-max">
                  <div className="flex flex-col gap-1">
                    <div className="uppercase secondary-regular text-neutral-500">
                      PMC NAME
                    </div>
                    <TruncatedText
                      className="inline-regular max-w-[10rem] text-neutral-850"
                      text={fields.source.propertyManagementCompanyName}
                    />
                  </div>
                  <div className="flex flex-col gap-1">
                    <div className="uppercase secondary-regular text-neutral-500">
                      EXTERNAL IDENTIFIER
                    </div>
                    {/* TODO: show tooltip */}
                    <TruncatedText
                      className="inline-regular max-w-[10rem] text-neutral-850"
                      text={fields.source.externalIdentifier}
                    />
                  </div>
                  <div className="flex flex-col gap-1">
                    <div className="uppercase secondary-regular text-neutral-500">
                      REPORT TYPE
                    </div>
                    <div className="inline-regular text-neutral-850">
                      <ConnectionReportTypeLabel type={fields.source.type} />
                    </div>
                  </div>
                  <div className="flex flex-col gap-1">
                    <div className="uppercase secondary-regular text-neutral-500">
                      ERP SYSTEM
                    </div>
                    <div className="inline-regular text-neutral-850">
                      <ERPSystemLabel
                        system={fields.source.erpSystem as ERPSystem}
                        className="gap-2"
                        typeThemeColor="mono"
                      />
                    </div>
                  </div>
                </div>
                {fields.legalEntity &&
                  currentStepIndex > stepOrder.indexOf('legalEntity') && (
                    <>
                      <div className="self-center w-0 h-2/3 shrink-0 border-x border-neutral-150" />
                      <div className="flex flex-col gap-1 shrink-0">
                        <div className="uppercase secondary-regular text-neutral-500">
                          Legal Entity
                        </div>
                        <div className="inline-regular text-neutral-850">
                          {fields.legalEntity.name}
                        </div>
                      </div>
                    </>
                  )}
              </div>
            )}
        </>
      }
      size={fields.step === 'review' ? 'huge' : '900'}
      classes={{
        body: cn(
          'bg-neutral-100 relative z-50',
          fields.step === 'legalEntity' && '!p-0',
        ),
      }}
      contentClassName="min-h-[38rem]"
      zIndex={10}
      actions={
        <FooterActionsWrapper>
          {currentStepIndex === 0 && (
            <Button onClick={onClose} variant="secondary">
              Cancel
            </Button>
          )}
          {currentStepIndex > 0 && (
            <Button onClick={handleBackStep} fluid={isLastStep}>
              Back
            </Button>
          )}
          <Button
            disabled={!isValid || isSaveLoading}
            variant="success"
            onClick={handleNextStep}
            fluid={isLastStep}
            loading={isSaveLoading}
          >
            {isLastStep ? 'Confirm Mapping' : 'Accept and Go Forward'}
          </Button>
        </FooterActionsWrapper>
      }
    >
      <div className="flex flex-col gap-4">
        <FormProvider {...form}>
          <Suspense fallback={<AnimationLoader />}>
            {fields.step === 'source' && (
              <LegalEntityManagmentPeriodsMappingSource
                initialSourceTab={initialSourceTab}
              />
            )}
            {fields.step === 'legalEntity' && (
              <LegalEntityManagmentPeriodsMappingLegalEntity />
            )}
            {fields.step === 'periods' &&
              fields.legalEntity &&
              fields.source && <LegalEntityManagmentPeriodsMappingPeriods />}
            {fields.step === 'review' && (
              <LegalEntityManagmentPeriodsMappingReview />
            )}
          </Suspense>
        </FormProvider>
      </div>
    </Modal>
  );
};
