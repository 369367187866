import React, { useEffect, useState } from 'react';
import { Input } from 'stories/FormControls/Inputs/Input/Input';
import useDebounce from '@/shared/lib/hooks/useDebounce';

// DEPRECATED: FE-2167 use SearchInput
interface Props {
  setSearchQuery?: (query: string) => void;
  setCurrentPage?: (pageNumber: number) => void;
  onChange?: (value: string) => void;
  className?: string;
  debounceTimeout?: number;
  leftIconOfInput?: string;
  rightIconOfInput?: string;
  inputPlaceholder?: string;
  size?: React.ComponentProps<typeof Input>['size'];
}

const TableSearch = ({
  setSearchQuery,
  setCurrentPage,
  onChange,
  className = '',
  debounceTimeout = 300,
  leftIconOfInput = 'search',
  rightIconOfInput,
  inputPlaceholder,
  size = 's',
}: Props) => {
  const [initialized, setInitialized] = useState<boolean>(false);
  const [value, setValue] = useState<string>('');
  const debouncedValue = useDebounce(value, debounceTimeout);

  const handleSearchQuery = () => {
    if (!initialized) {
      setInitialized(true);
      return;
    }
    if (onChange) onChange(value);
    if (setSearchQuery) setSearchQuery(value);
    if (setCurrentPage) setCurrentPage(1);
  };

  useEffect(() => {
    handleSearchQuery();
  }, [debouncedValue]);

  return (
    <div
      className={`has-search table-search relative flex items-center ${className}`}
    >
      {!rightIconOfInput && (
        <span className="sre-icon-search form-control-feedback" />
      )}
      <Input
        className="w-full"
        leftIcon={leftIconOfInput}
        rightIcon={rightIconOfInput}
        value={value}
        size={size}
        onChange={(e) => setValue(e.target.value)}
        placeholder={inputPlaceholder}
      />
    </div>
  );
};

export default TableSearch;
