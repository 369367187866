import { ISelectOption } from 'stories/FormControls/Select/Select';
import { IUser, IUserRole } from 'types/User';
import { IPermissionRequestBody } from '@/bundles/Settings/components/REport/TableBuilder/types';
import { PERIODS_TYPES } from 'bundles/Shared/entities/periodsType/lib';
import { IUserTag } from 'types/IUserTag';

export function isReportTableConfigValid(configString: string) {
  try {
    const o = JSON.parse(configString);

    const isValid = Boolean(o && typeof o === 'object' && Array.isArray(o));

    return isValid;
  } catch (e) {
    return false;
  }
}

export function parseColumnConfigForPreview(config: string): string[] {
  const colsArr: string[] = JSON.parse(config);
  return colsArr.filter((col) => col !== '');
}

export function createRestrictedPermissionAttributes({
  userIds,
  roleIds,
  tagIds,
}: {
  userIds: IUser['id'][];
  roleIds: IUserRole['id'][];
  tagIds: IUserTag['id'][];
}): IPermissionRequestBody {
  return {
    public_permission_attributes: { _destroy: '1' as const },
    directly_permitted_user_ids: userIds,
    directly_permitted_user_role_ids: roleIds,
    directly_permitted_user_tag_ids: tagIds,
  };
}

export const getPeriodsTypeByValue = (value?: string): ISelectOption | null =>
  PERIODS_TYPES.find((type) => type.value === value) ?? null;
