import { toggleShowUnpublished } from '@/bundles/REport/reducers/financialsSlice';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import { IconButton } from '@/stories/IconButton/IconButton';

export const FinancialsShowPublishedDataButton = () => {
  const showUnpublished = useAppSelector(
    (state) => state.financials.showUnpublished,
  );

  const dispatch = useAppDispatch();

  const handleToggleShowUnpublished = () => {
    dispatch(toggleShowUnpublished());
  };

  return (
    <IconButton
      tooltipProps={{
        mainText: showUnpublished
          ? 'Hide unpublished data'
          : 'Show unpublished data',
        placement: 'top',
        delay: 300,
      }}
      size="l"
      isActive={showUnpublished}
      variant="secondary"
      iconName="unpublished"
      onClick={handleToggleShowUnpublished}
    />
  );
};
