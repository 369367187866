import type {
  PeriodShiftConfig,
  ReportComparisonDashboardSection,
} from '@/bundles/Shared/entities/dashboard/model/types/types';
import {
  BaseFinancialTableWidgetConfigBaseColumnDto,
  FinancialTableSinglePeriodWidgetConfigDto,
} from '@/shared/api/dashboardsSettingsGeneratedApi';
import { FINANCIAL_TABLE_SINGLE_PERIOD_PERIOD_TYPES } from '@/bundles/Shared/widgets/dashboard/widgets/financialTableSingePeriod/config';

export const isFinancialTableSinglePeriodWidgetSection = (
  section: ReportComparisonDashboardSection,
): section is FinancialTableSinglePeriodWidgetSection =>
  section.widgetType === 'financial_table_single_period';

export type FinancialTableSinglePeriodPeriodType =
  (typeof FINANCIAL_TABLE_SINGLE_PERIOD_PERIOD_TYPES)[number];

export type FinancialTableSinglePeriodWidgetSection = {
  widgetType: 'financial_table_single_period';
  widgetConfig: {
    columns: BaseFinancialTableWidgetConfigBaseColumnDto[];
    default_options: {
      date: {
        period_shift: PeriodShiftConfig;
      };
      period_type: FinancialTableSinglePeriodPeriodType;
    };
    period_types: FinancialTableSinglePeriodPeriodType[];
    viz_config: FinancialTableSinglePeriodWidgetConfigDto['viz_config'];
    viz_type?: FinancialTableSinglePeriodWidgetConfigDto['viz_type'];
  };
  defaultOptions: {
    availablePeriodTypes: FinancialTableSinglePeriodPeriodType[];
    groupingType: 'assets' | 'segments';
    date: {
      period_shift: PeriodShiftConfig;
    };
    periodType: FinancialTableSinglePeriodPeriodType;
  };
  id: string;
  title: string;
};
