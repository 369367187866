import FilterButton from '@/bundles/Shared/components/Filters/buttons/filterButton/FilterButton';
import { cn } from '@/shared/lib/css/cn';
import { Icon } from '@/stories/Icon/Icon';

export function FilterByFire({
  count,
  onClick,
  onClose,
  filtered,
}: {
  count: number;
  onClose: VoidFunction;
  onClick: VoidFunction;
  filtered: boolean;
}) {
  return (
    <FilterButton
      onClick={onClick}
      filtered={filtered}
      onClose={onClose}
      className={cn(
        'flex items-center gap-1 rounded-[8px] bg-neutral-000 pr-2 text-danger-070 shadow-sm',
        {
          'bg-danger-070  text-neutral-000': filtered,
        },
      )}
    >
      <Icon
        className={cn({
          'bg-danger-070 text-neutral-000': filtered,
        })}
        iconName="fire"
      />
      <span className="secondary-regular ">{count}</span>
    </FilterButton>
  );
}
