import { TMentionedUser } from 'bundles/REconcile/types/Comments';

export const MENTION_MARKER = '@';

// <@{userId}>
const backendFormat = (user: TMentionedUser) =>
  `<${MENTION_MARKER}U${user.id}>`;
// @{userName}
const clientFormat = (user: TMentionedUser) =>
  `${MENTION_MARKER}${user.fullName}`;

export const processMentions = ({
  users,
  text,
  direction,
  clientFormat: internalClientFormat = clientFormat,
}: {
  users: TMentionedUser[];
  text: string;
  direction: 'client' | 'backend';
  clientFormat?: (user: TMentionedUser) => string;
}) =>
  users.reduce((previousValue, user) => {
    const searchValue =
      direction === 'client' ? backendFormat(user) : internalClientFormat(user);
    const replaceValue =
      direction === 'client' ? internalClientFormat(user) : backendFormat(user);
    return previousValue.replace(searchValue, replaceValue);
  }, text);
