import React from 'react';
import { cn } from '@/shared/lib/css/cn';
import { Icon } from 'stories/Icon/Icon';
import PermissionList from '@/bundles/Shared/components/PermissionList';
import { IconsId } from '@/types/sre-icons';
import { IUser } from '@/types/User';

interface Props {
  size?: 'sm';
  title: string;
  date: string;
  iconClass?: IconsId;
  color: string;
  time: string;
  recipients: IUser[];
}

const CampaignEmailCardInfo = ({
  size,
  title,
  iconClass,
  color,
  date,
  time,
  recipients,
}: Props) => {
  const permissions = {
    users: recipients,
    tags: [],
    roles: [],
    investmentEntities: [],
    autoSelectedUsers: [],
  };

  return (
    <div className="flex">
      <div className={cn(size === 'sm' ? 'mr-4' : 'mr-[1.5rem]')}>
        <div
          className={`email-campaign-card__icon email-campaign-card__icon_${color} email-campaign-card__icon_${size}`}
        >
          <Icon iconName={iconClass} />
        </div>
      </div>
      <div className="flex flex-col mnw-0">
        <h6
          className={cn(
            'font-weight-500 text-[1rem] header6-regular',
            size === 'sm' ? 'mb-[0.25rem]' : 'mb-[0.5rem]',
          )}
        >
          {title}
        </h6>
        <div
          className={cn('flex', size === 'sm' ? 'mb-[0.25rem]' : 'mb-[0.5rem]')}
        >
          <span className="inline-regular dark-60 mr-[0.5rem] inline-regular">
            {date}
          </span>
          <span className="inline-regular light-60 inline-regular">{time}</span>
        </div>
        {recipients && (
          <div className="flex items-center">
            <span className="inline-regular light-60 mr-[0.5rem] inline-regular">
              To:
            </span>
            <PermissionList
              hideAvatar
              hideIcon
              permissions={permissions}
              popoverOptions={{ placement: 'right-end' }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default CampaignEmailCardInfo;
