import {
  ASSET_PORTAL_PRODUCT_NAME,
  currentUserAllowedTo,
  FUND_PORTAL_PRODUCT_NAME,
} from '@/lib/permissions';

export const getProductName = (type) => {
  if (!type) return false;
  switch (type.toLowerCase()) {
    case 'fund':
      return FUND_PORTAL_PRODUCT_NAME;
    case 'asset':
      return ASSET_PORTAL_PRODUCT_NAME;
    default:
      return null;
  }
};

const canManageObject = (object) =>
  currentUserAllowedTo(
    'manage',
    getProductName(object.objectType),
    object.objectType,
    object.id,
  );

export default canManageObject;
