import { EAGLE_EYE_OBJECT_SELECTION_SCHEMA } from '@/bundles/Shared/features/dashboard/update/eagleEye';
import type { LegalEntity } from '@/entities/core/legalEntity';
import type { ReportBuilderEagleEyeTemplateDto } from '@/entities/report/reportBuilder/api/settingsReportBuilderTemplatesGeneratedApi';
import { mapListToIds } from '@/shared/lib/listHelpers';
import type { IAsset } from '@/types/Asset';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, useFormContext } from 'react-hook-form';
import * as yup from 'yup';

export const REPORT_BUILDER_TEMPLATE_EAGLE_EYE_OBJECT_SETTINGS_SCHEMA =
  EAGLE_EYE_OBJECT_SELECTION_SCHEMA.concat(
    yup.object().shape({
      copied_from_dashboard_id: yup.string(),
      applied_filter_set_id: yup.string(),
    }),
  );

export type ReportBuilderTemplateEagleEyeObjectSettingsSchema = yup.InferType<
  typeof REPORT_BUILDER_TEMPLATE_EAGLE_EYE_OBJECT_SETTINGS_SCHEMA
>;

export const useReportBuilderTemplateEagleEyeObjectSettingsForm = (
  template: ReportBuilderEagleEyeTemplateDto,
) =>
  useForm<ReportBuilderTemplateEagleEyeObjectSettingsSchema>({
    resolver: yupResolver(
      REPORT_BUILDER_TEMPLATE_EAGLE_EYE_OBJECT_SETTINGS_SCHEMA,
    ),
    defaultValues: {
      asset_ids: mapListToIds(template.assets ?? []),
      excluded_legal_entity_ids: mapListToIds(
        template.excludedLegalEntities ?? [],
      ),
      segment_ids: mapListToIds(template.segments ?? []),
    },
  });

export const useReportBuilderTemplateEagleEyeObjectSettingsSchemaFormContext =
  useFormContext<ReportBuilderTemplateEagleEyeObjectSettingsSchema>;

export type ReportBuilderWidgetEagleEyeObjectSettingsSchema = yup.InferType<
  typeof EAGLE_EYE_OBJECT_SELECTION_SCHEMA
>;

export const useReportBuilderWidgetEagleEyeObjectSettingsForm = (params: {
  defaultAssetIds: IAsset['id'][];
  defaultSegmentIds: IAsset['id'][];
  defaultExcludedLegalEntityIds: LegalEntity['id'][];
}) =>
  useForm<ReportBuilderWidgetEagleEyeObjectSettingsSchema>({
    resolver: yupResolver(EAGLE_EYE_OBJECT_SELECTION_SCHEMA),
    defaultValues: {
      asset_ids: params.defaultAssetIds,
      excluded_legal_entity_ids: params.defaultExcludedLegalEntityIds,
      segment_ids: params.defaultSegmentIds,
    },
  });

export const useReportBuilderWidgetEagleEyeObjectSettingsSchemaFormContext =
  useFormContext<ReportBuilderWidgetEagleEyeObjectSettingsSchema>;
