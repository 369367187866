import { usePostApiReconcileForecastingForecastsMutation } from '@/entities/reconcile/forecasting/api/reconcileForecastingEntitiesEnchancedApi';
import { ForecastingFormModal } from '@/entities/reconcile/forecasting/features/ui/ForecastingFormModal';
import { useModal } from '@/shared/lib/hooks/useModal';
import { generateUrl, ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import { EntityToastrCreator } from '@/shared/lib/toastr/entityToastrCreator';
import { useNavigate } from '@reach/router';

const t = new EntityToastrCreator('Forecast');

export const useCreateForecast = () => {
  const [createForecast, options] =
    usePostApiReconcileForecastingForecastsMutation();
  const { openModal } = useModal();
  const navigate = useNavigate();

  const handleCreateForecast = async () => {
    const values = await openModal(ForecastingFormModal, {});

    if (!values) return;

    const res = await createForecast({
      body: values,
    });

    if ('error' in res) return;

    toastr.success(t.create().toString());

    const path = generateUrl(
      ROUTES_ROOT.reconcile.forecasting.forecast.fullPath,
      {
        pathParams: {
          forecastName: values.name,
        },
      },
    );
    navigate(path);
  };

  return {
    createForecast: handleCreateForecast,
    options,
  };
};
