import CreateBudgetsForFutureYearsModal from '@/bundles/REconcile/components/modals/CreateBudgetsForFutureYearsModal';
import { budgetYearValidator } from '@/bundles/REconcile/components/operational/utils';
import { usePostApiReconcileForecastingForecastsByForecastIdYearsMutation } from '@/entities/reconcile/forecasting/api/reconcileForecastingEntitiesEnchancedApi';
import { ForecastingForecast } from '@/entities/reconcile/forecasting/api/reconcileForecastingEntitiesGeneratedApi';
import { useModal } from '@/shared/lib/hooks/useModal';
import { EntityToastrCreator } from '@/shared/lib/toastr/entityToastrCreator';

const t = new EntityToastrCreator('Budgets');

export const useCreateForecastYear = (id?: ForecastingForecast['id']) => {
  const [createForecastYear, options] =
    usePostApiReconcileForecastingForecastsByForecastIdYearsMutation();
  const { openModal } = useModal();

  const handleCreateForecastYear = async (
    years: ForecastingForecast['years'],
  ) => {
    if (!id || !years) return;

    const values = await openModal(CreateBudgetsForFutureYearsModal, {
      years,
      selectProps: {
        notFoundFlow: {
          selectButtonLabel: 'Create a new year',
          searchValueValidator: (searchText) =>
            budgetYearValidator(searchText, years),
          textResolver: (searchText) => {
            if (years.includes(Number(searchText))) {
              return 'Year already exists';
            }
            return null;
          },
        },
      },
    });

    if (!values) return;

    const res = await createForecastYear({
      forecastId: id,
      body: {
        year: values.year,
      },
    });

    if ('error' in res) return;

    toastr.success(t.create().toString());
  };

  return { createForecastYear: handleCreateForecastYear, options };
};
