import { ColDefParams } from '@/bundles/Shared/components/ReportTable/helpers/hooks/useColGroupDefs';
import { cn } from '@/shared/lib/css/cn';
import { ICellRendererParams } from 'ag-grid-community';
import { isRowEditable } from 'bundles/REconcile/components/operational/editor/hooks/useColumnDefs';
import {
  isRowCategory,
  isRowDivider,
  isRowHeader,
} from 'bundles/Shared/components/ReportTable/types';
import { CurrencyCellRendererProps } from '@/bundles/Shared/components/AgGrid/Table/cellComponents/CurrencyCellRenderer';
import { CELL_CLASS_NAMES } from '@/bundles/Shared/components/AgGrid/Table/classNames';
import { getColorClassName } from '@/bundles/Shared/components/AgGrid/Table/utils/getColorClassName';
import { getCurrencyCellClasses } from '@/bundles/Shared/components/AgGrid/Table/utils/getCurrencyCellClasses';
import { getNodeFlags } from '@/bundles/Shared/components/AgGrid/Table/utils/getNodeFlags';
import { DEFAULT_NUMBER_FALLBACK } from '@/shared/lib/formatting/fallbacks';

const isValueCell = (params: ICellRendererParams) =>
  !(isRowHeader(params.data ?? {}) || isRowDivider(params.data ?? {}));

const isValueCellReconcileOperational = (params: ICellRendererParams) =>
  isRowEditable(params.data ?? {}) ||
  params.node.footer ||
  isRowCategory(params.data ?? {});

export function getBaseCellRendererParams(
  node: ICellRendererParams['node'],
  options?: Options,
): Pick<CurrencyCellRendererProps, 'classes' | 'formatterParams'> {
  const nodeFlags = getNodeFlags(node);
  const wrapperColorClassName = getColorClassName(
    CELL_CLASS_NAMES.CommonCell.wrapper,
    nodeFlags,
  );
  const innerColorClassName = getColorClassName(
    CELL_CLASS_NAMES.CommonCell.inner,
    nodeFlags,
  );

  const classes = {
    wrapper: cn(
      wrapperColorClassName,
      node.isExpandable() && 'cursor-pointer',
      'leading-normal relative',
    ),
    inner: cn(innerColorClassName, options?.innerClassName),
  };

  const currencyClasses = getCurrencyCellClasses(nodeFlags);

  const formatterParams: Partial<CurrencyCellRendererProps['formatterParams']> =
    {
      classes: currencyClasses,
      ...options?.formatterParams,
    };

  return {
    classes,
    formatterParams,
  };
}

export function getReconcileOperationalCellRendererParams(
  params: ICellRendererParams,
): CurrencyCellRendererProps {
  return {
    ...getBaseCellRendererParams(params.node, {
      formatterParams: {
        fallbackValue: isValueCellReconcileOperational(params)
          ? DEFAULT_NUMBER_FALLBACK
          : null,
      },
    }),
  };
}

export function getEmptyCellRendererParams(
  params: ICellRendererParams,
): CurrencyCellRendererProps {
  return {
    ...getBaseCellRendererParams(params.node),
    formatterParams: {
      fallbackValue: null,
    },
  };
}

type Options = {
  innerClassName?: string;
  formatterParams?: CurrencyCellRendererProps['formatterParams'];
  labelColor?: string;
};

export function getReportFinancialsCellRendererParams(
  params: ICellRendererParams,
  options?: Options,
  colDefParams?: ColDefParams,
): CurrencyCellRendererProps {
  const { classes, formatterParams } = getBaseCellRendererParams(
    params.node,
    options,
  );

  return {
    classes,
    labelColor: options?.labelColor,
    formatterParams: {
      hideFractions:
        formatterParams?.precision != null
          ? formatterParams.precision === 0
          : false,
      toLocalStringOptions: {
        minimumFractionDigits:
          colDefParams?.colGroupChild?.display?.precision ??
          params.data?.display?.precision ??
          null,
        maximumFractionDigits:
          colDefParams?.colGroupChild?.display?.precision ??
          params.data?.display?.precision ??
          null,
      },
      ...formatterParams,
      fallbackValue: isValueCell(params) ? DEFAULT_NUMBER_FALLBACK : null,
    },
  };
}
