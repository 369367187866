import React, { useState } from 'react';
import { navigate } from '@reach/router';
import { cn } from '@/shared/lib/css/cn';
import { GeneralSettingsNavigationSection } from 'stories/GeneralSettingsNavigationSection/GeneralSettingsNavigationSection';
import { ObjectsSettingsNavigationSection } from 'stories/ObjectsSettingsNavigationSection/ObjectsSettingsNavigationSection';
import {
  ASSET_PORTAL_PRODUCT_NAME,
  FUND_PORTAL_PRODUCT_NAME,
  USER_MANAGEMENT_PRODUCT_NAME,
  currentUserAllowedTo,
} from 'lib/permissions';
import { ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';

interface Props {
  className?: string;
}

const PORTAL_ROUTES = ROUTES_ROOT.settings.portal;

function PortalSettingsNavigation({ className }: Props) {
  const { pathname } = window.location;
  const [currentTab, setCurrentTab] = useState<string>(
    pathname || PORTAL_ROUTES.core.fullPath,
  );
  const setTabAndNavigate = (route: string) => {
    setCurrentTab(route);
    navigate(route);
  };

  const groupedTabs: {
    title: string;
    subTabs: {
      title: string;
      active: boolean;
      allowed: boolean;
      link: string;
      onClick: () => void;
    }[];
  }[] = [
    {
      title: 'Objects',
      subTabs: [
        {
          title: 'Funds',
          active: currentTab === PORTAL_ROUTES.funds.fullPath,
          allowed: currentUserAllowedTo('configure', FUND_PORTAL_PRODUCT_NAME),
          onClick: () => setTabAndNavigate(PORTAL_ROUTES.funds.fullPath),
          link: PORTAL_ROUTES.funds.fullPath,
        },
        {
          title: 'Assets',
          active: currentTab === PORTAL_ROUTES.assets.fullPath,
          allowed: currentUserAllowedTo('configure', ASSET_PORTAL_PRODUCT_NAME),
          onClick: () => setTabAndNavigate(PORTAL_ROUTES.assets.fullPath),
          link: PORTAL_ROUTES.assets.fullPath,
        },
      ],
    },
    {
      title: 'Entities',
      subTabs: [
        {
          title: 'Investment',
          active: currentTab === PORTAL_ROUTES.investmentEntities.fullPath,
          onClick: () =>
            setTabAndNavigate(PORTAL_ROUTES.investmentEntities.fullPath),
          link: PORTAL_ROUTES.investmentEntities.fullPath,
          allowed: currentUserAllowedTo(
            'configure',
            USER_MANAGEMENT_PRODUCT_NAME,
          ),
        },
        {
          title: 'Legal',
          active: currentTab === PORTAL_ROUTES.legalEntities.fullPath,
          onClick: () =>
            setTabAndNavigate(PORTAL_ROUTES.legalEntities.fullPath),
          link: PORTAL_ROUTES.legalEntities.fullPath,
          allowed: true,
        },
      ],
    },
  ];

  const renderGroupedTabs = () => {
    const allowedTabs = groupedTabs.map((tab) => ({
      ...tab,
      subTabs: tab.subTabs.filter((subTab) => subTab.allowed),
    }));

    return allowedTabs.map((tab) => {
      if (!tab.subTabs.length) return null;

      if (tab.subTabs.length > 1) {
        return (
          <ObjectsSettingsNavigationSection
            key={tab.title}
            title={tab.title}
            tabs={[...tab.subTabs]}
          />
        );
      }

      return (
        <GeneralSettingsNavigationSection
          key={tab.title}
          title={tab.title}
          subtitle={tab.subTabs[0].title}
          onClick={tab.subTabs[0].onClick}
          link={tab.subTabs[0].link}
          active={tab.subTabs[0].active}
        />
      );
    });
  };
  return (
    <div className={cn('flex gap-m', className)}>
      <GeneralSettingsNavigationSection
        title="General"
        subtitle="Asset Stages, Doc Types, Feed Types"
        link={PORTAL_ROUTES.core.fullPath}
        active={currentTab === PORTAL_ROUTES.core.fullPath}
        onClick={() => {
          setCurrentTab(PORTAL_ROUTES.core.fullPath);
        }}
      />
      {renderGroupedTabs()}
    </div>
  );
}

export default PortalSettingsNavigation;
