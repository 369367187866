import { FC, ReactNode } from 'react';
import { Icon } from '@/stories/Icon/Icon';
import { IconButton } from '@/stories/IconButton/IconButton';
import { cn } from '@/shared/lib/css/cn';
import { IconsId } from 'types/sre-icons';
import styles from '@/stories/HugeModalHeaderForCe/HugeModalHeaderForCe.module.scss';

interface IHugeModalHeaderForCeProps {
  onClose: () => void;
  content?: ReactNode;
  iconName?: IconsId;
  subtitle?: ReactNode;
}

export const HugeModalHeaderForCe: FC<IHugeModalHeaderForCeProps> = ({
  title,
  subtitle,
  iconName,
  onClose,
  content,
}) => {
  return (
    <div className={styles['sre-huge-modal-header']}>
      <div className={styles['sre-huge-modal-header__block']}>
        {iconName && (
          <div className={styles['sre-huge-modal-header__icon']}>
            <Icon
              iconName={iconName}
              className={styles['sre-huge-modal-header__i']}
            />
          </div>
        )}
        <div>
          <div
            className={cn(
              'header6-bold',
              styles['sre-huge-modal-header__title'],
            )}
          >
            {title}
          </div>
          <div
            className={cn(
              'inline-regular',
              styles['sre-huge-modal-header__subtitle'],
            )}
          >
            {subtitle}
          </div>
        </div>
      </div>
      <div className="flex items-center">
        {content}
        <IconButton
          iconName="close"
          variant="white"
          onClick={onClose}
          size="l"
        />
      </div>
    </div>
  );
};
