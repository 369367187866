import { FC } from 'react';
import {
  StepperItem,
  IStepperItemProps,
} from '@/stories/StepperItem/StepperItem';
import { cn } from '@/shared/lib/css/cn';

interface IStepperProps extends HTMLButtonElement {
  steps: IStepperItemProps[];
  className: string;
  activeStep: string;
}

export const Stepper: FC<IStepperProps> = ({
  steps,
  className,
  activeStep,
}) => (
  <div className={cn('flex justify-center', className)}>
    {steps.map((step, idx) => (
      <StepperItem
        key={step.value}
        activeStep={activeStep}
        {...step}
        firstItem={idx === 0}
        lastItem={steps.length === idx + 1}
      />
    ))}
  </div>
);
