import React, { useState } from 'react';
import GroupForm from 'bundles/Shared/components/GroupForm/GroupForm';
import { FieldType } from 'bundles/Shared/components/GroupForm/types/types';
import { Modal } from 'stories/Modals/Modal/Modal';

interface Props {
  onSubmit: () => void;
  onClose: () => void;
  row: object;
  options: {
    label: string;
    value: string;
  }[];
}

const GanttChartSettingsModal = ({
  onSubmit,
  onClose,
  row,
  options,
}: Props) => {
  const [formData, setFormData] = useState(row || {});

  const initGroupFormItems = [
    {
      title: '',
      required: true,
      activated: true,
      open: true,
      formItems: [
        {
          menuPosition: 'fixed',
          fieldName: 'startDate',
          fieldNameSnap: 'start_date',
          type: FieldType.SELECT,
          title: 'Start Date Field',
          placeholder: 'Enter or select the field name',
          required: true,
          options,
        },
        {
          menuPosition: 'fixed',
          fieldName: 'endDate',
          fieldNameSnap: 'end_date',
          type: FieldType.SELECT,
          title: 'End Date Field',
          placeholder: 'Enter or select the field name',
          required: true,
          options,
        },
      ],
    },
  ];

  return (
    <Modal
      header="Update settings"
      toggle={onClose}
      classes={{
        header: 'bg-light',
        body: 'bg-light',
        footer: 'bg-light',
      }}
    >
      <GroupForm
        groupFormItems={initGroupFormItems}
        onSubmit={() => onSubmit(formData)}
        formData={formData}
        setFormData={setFormData}
        buttonConfig={{
          titleBtn: 'Submit',
          iconName: 'null',
          withoutCancel: false,
          funcCancel: onClose,
        }}
      />
    </Modal>
  );
};

export default GanttChartSettingsModal;
