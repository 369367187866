import { cn } from '@/shared/lib/css/cn';
import { Icon } from '@/stories/Icon/Icon';
import { Tooltip } from '@/stories/Tooltip/Tooltip';
import React, { ComponentProps } from 'react';
import { IconsId } from 'types/sre-icons';
import '@/stories/IconButton/iconButton.css';

interface Props
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  iconName: IconsId;
  tooltipProps?: ComponentProps<typeof Tooltip>;
  variant?: 'default' | 'primary' | 'secondary' | 'white' | 'dark' | 'ghost';
  size?: 'xs' | 's' | 'm' | 'l';
  isDarkText?: boolean;
  disabled?: boolean;
  offHoverStyles?: boolean;
  classes?: {
    icon?: string;
  };
  isActive?: boolean;
  isLoading?: boolean;
}

export const IconButton = React.forwardRef<HTMLButtonElement, Props>(
  (
    {
      size = 'm',
      variant = 'secondary',
      className,
      iconName,
      tooltipProps = {
        disabled: true,
      },
      disabled,
      offHoverStyles,
      onClick,
      classes,
      children,
      isDarkText,
      isActive,
      isLoading = false,
      ...props
    },
    ref,
  ) => {
    const rawButton = (
      <button
        ref={ref}
        type="button"
        className={cn(
          'sre-icon-button',
          `sre-icon-button_${variant}`,
          {
            [`sre-icon-button_size-${size}`]: size !== 'm',
            'min-w-6': size === 'm',
            '_off-hover-styles': offHoverStyles,
            rounded: size === 'xs',
            'hover:border-current border border-transparent bg-transparent text-neutral-100 hover:text-neutral-200':
              variant === 'ghost',
          },
          variant === 'secondary' && 'shadow-z-020',
          variant === 'secondary' &&
            isActive &&
            'border-neutral-999 bg-neutral-999 text-neutral-000',
          {
            'text-neutral-850 hover:!text-neutral-650': isDarkText,
          },
          className,
        )}
        disabled={isLoading || disabled}
        onClick={onClick}
        {...props}
      >
        {children ?? (
          <Icon
            className={cn(classes?.icon, isLoading && 'animate-spin')}
            iconName={isLoading ? 'loader' : iconName}
          />
        )}
      </button>
    );

    if (tooltipProps.disabled) return rawButton;

    return <Tooltip {...tooltipProps}>{rawButton}</Tooltip>;
  },
);

IconButton.displayName = 'IconButton';

export default IconButton;
