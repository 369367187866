import { LeClassification } from '@/bundles/Shared/entities/leClasssification/types';

export const LE_CLASSIFICATION_LABEL_MAP = {
  hospitality: 'Hospitality',
  mixed_use: 'Mixed Use',
  multifamily: 'Multifamily',
  office: 'Office',
  op_co: 'OpCo',
  retail: 'Retail',
  senior_living: 'Senior Living',
  spv: 'SPV', // Special Purpose Vehicle
  student: 'Student',
  commercial: 'Commercial',
} as const satisfies Record<LeClassification, string>;

export const LE_CLASSIFICATION_OPTIONS = Object.entries(
  LE_CLASSIFICATION_LABEL_MAP,
).map(([leClass, label]) => ({
  value: leClass as LeClassification,
  label,
})) as {
  value: LeClassification;
  label: string;
}[];

export const LE_CLASSIFICATIONS = [
  'hospitality',
  'mixed_use',
  'multifamily',
  'office',
  'op_co',
  'retail',
  'senior_living',
  'spv',
  'student',
  'commercial',
] as const;

export const ASSET_CLASS_HAS_SET_TEXT = 'Asset Classes have been set' as const;

export const getClassificationsTooltipText = (
  classifications: LeClassification[],
) =>
  `This category branch is for ${classifications
    .map((c) => LE_CLASSIFICATION_LABEL_MAP[c])
    .join(', ')} legal entities only`;
