import React, { FC, ReactNode } from 'react';
import Icon from 'stories/Icon/Icon';
import '@/stories/InlineAlert/inline-alert.scss';
import { cn } from '@/shared/lib/css/cn';

type TStatus = 'attention' | 'danger' | 'success' | 'info' | 'secondary';

interface IInlineAlertProps extends PropsWithClassName {
  message: ReactNode;
  classes?: {
    body?: string;
  };
  status?: TStatus;
}

export const InlineAlert: FC<IInlineAlertProps> = ({
  message,
  status = 'attention',
  className,
  classes,
}) => (
  <div className={cn('sre-alert', `sre-alert_${status}`, className)}>
    <div className={cn('sre-alert__body', classes?.body)}>
      <Icon iconName="info" />
      <div>{message}</div>
    </div>
  </div>
);
