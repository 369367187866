import {
  IRowBase,
  isRowFormula,
  isRowGl,
  isRowJcc,
} from 'bundles/Shared/components/ReportTable/types';
import { RowNode } from 'ag-grid-community';
import { CellTypeFlags } from '@/bundles/Shared/components/AgGrid/Table/classNames';

export function getNodeFlags(node: RowNode): CellTypeFlags {
  const nodeData = node.data as IRowBase | undefined;

  const totalsColor = nodeData?.display?.background;

  return {
    isFooter: node.footer,
    isFormula: Boolean(nodeData && isRowFormula(nodeData)),
    isBlueFormula: totalsColor === 'blue',
    isGreenFormula: totalsColor === 'green',
    isDarkFormula: totalsColor === 'dark',
    isDarkGreyFormula: totalsColor === 'dark-grey',
    isGreyFormula: totalsColor === 'grey',
    isEditable: Boolean(nodeData && (isRowGl(nodeData) || isRowJcc(nodeData))),
    isRoot: node.level === 0,
    isGrandTotal: node.level === -1 && node.footer,
  };
}
