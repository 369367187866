import { cn } from '@/shared/lib/css/cn';
import useBoolean from '@/shared/lib/hooks/useBoolean';
import React, { useRef } from 'react';
import AnimateHeight from 'react-animate-height';
import { Button } from 'stories/Button/Button';
import { Icon } from 'stories/Icon/Icon';
import { IconButton } from 'stories/IconButton/IconButton';
import styles from '@/bundles/Shared/components/Filters/common/filterBar/FilterBar.module.scss';

export enum FILTER_BAR_TEST_IDS {
  closeBtn = 'close-filter-bar',
}

interface Props {
  title?: string;
  onClose?: () => void;
  className?: string;
  customStyles?: {
    title?: string;
  };
  isCollapsible?: boolean;
  collapsibleOptions?: {
    defaultCollapsed?: boolean;
  };
}

const FILTER_BAR_MIN_HEIGHT = 40;

function FilterBar({
  title,
  onClose,
  children,
  className,
  customStyles,
  isCollapsible,
  collapsibleOptions,
}: React.PropsWithChildren<Props>) {
  const ref = useRef<HTMLDivElement>(null);
  const { value: collapsed, toggle } = useBoolean(
    collapsibleOptions?.defaultCollapsed,
  );
  const canClose = onClose != null;

  const rawContent = (
    <div
      ref={ref}
      className={cn(
        styles.container,
        canClose && styles.container_closable,
        className,
      )}
    >
      <span
        className={cn(
          'inline-semibold min-w-max leading-none',
          styles.title,
          customStyles?.title,
        )}
      >
        <Icon iconName="filtersFilledAlt" className={styles.icon} />
        {title}
      </span>
      <div className={cn(styles.children, {})}>{children}</div>
      {isCollapsible &&
        (ref.current?.clientHeight ?? 0) > FILTER_BAR_MIN_HEIGHT && (
          <Button
            size="s"
            className="h-min min-w-max"
            iconPosition="right"
            onClick={toggle}
            iconName={collapsed ? 'arrowBottom' : 'arrowTop'}
          >
            {collapsed ? 'See All' : 'See less'}
          </Button>
        )}
      {canClose && (
        <IconButton
          data-testid={FILTER_BAR_TEST_IDS.closeBtn}
          className={styles.closeButton}
          offHoverStyles
          onClick={onClose}
          iconName="closeSmall"
        />
      )}
    </div>
  );

  if (isCollapsible) {
    return (
      <AnimateHeight
        height={collapsed ? 40 : 'auto'}
        duration={200}
        className="overflow-hidden rounded-lg"
      >
        {rawContent}
      </AnimateHeight>
    );
  }

  return rawContent;
}

export default FilterBar;
