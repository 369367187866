import {
  fetchPermissionBySharedFileId,
  fetchSubjectablesBySharedFileId,
} from 'bundles/Shared/actions/permission_modal';
import { EditPermissionsModal } from 'bundles/Shared/components/Permissions/EditPermissionsModal';
import { PermissionListLine } from 'bundles/Shared/components/Permissions/PermissionListLine';
import { ViewPermissionedUsersModal } from 'bundles/Shared/components/Permissions/ViewPermissionedUsersModal';
import { viewsColumn } from 'bundles/Shared/components/Permissions/ViewPermissionedUsersModal/columns';
import { FileInfoTitle } from 'bundles/Shared/components/Permissions/ViewPermissionedUsersModal/components/FileInfoTitle';
import { getProductName } from 'lib/InvestmentObject';
import { useModal } from '@/shared/lib/hooks/useModal';
import pluralize from 'pluralize';
import { Icon } from 'stories/Icon/Icon';
import { LinkButton } from 'stories/LinkButton/LinkButton';
import { ISharedDocument } from 'types/SharedDocument';
import { Permitted } from 'bundles/Shared/entities/permissions';

interface Props {
  row: ISharedDocument;
  hideActions: boolean;
  actions: {
    remove: (ids) => Promise<void>;
    resetFilter: unknown;
    setSelectedFilters: unknown;
    submitPermissions: (
      res: Permitted & Pick<ISharedDocument, 'id'>,
    ) => Promise<void>;
  };
  isLocked: boolean;
  showViewCounter?: boolean;
}

export function ViewSharedWithModalHeader({ row, handleEditPermissions }) {
  return (
    <>
      <FileInfoTitle {...row} />
      {!row.permissionsLocked && (
        <div className="flex items-center gap-2">
          <i className="sre-icon-edit" />
          <LinkButton fontWeight="bold" onClick={handleEditPermissions}>
            Edit permissions
          </LinkButton>
        </div>
      )}
    </>
  );
}

function PermissionedUsers({
  row,
  actions,
  hideActions,
  isLocked,
  showViewCounter = false,
}: Props) {
  const { permitted } = row;
  const { openModal } = useModal();

  if (row.documentType.internal) {
    return (
      <div className="flex items-center">
        <span className="permission-type-icon">
          <Icon iconName="role" />
        </span>
        Internal roles only
      </div>
    );
  }

  const handleEditPermissions = async () => {
    const res = await openModal(EditPermissionsModal, {
      fetchSubjectables: () => fetchSubjectablesBySharedFileId(row.id),
      objectableName: 'document',
    });

    if (res) {
      await actions.submitPermissions({
        id: row.id,
        ...res,
      });
    }
  };

  const handleViewSharedWithModal = async () => {
    await openModal(ViewPermissionedUsersModal, {
      fetchPermissionedUsersWithMeta: () =>
        fetchPermissionBySharedFileId(row.id),
      customColumns: [viewsColumn],
      headerTitle: (
        <ViewSharedWithModalHeader
          row={row}
          handleEditPermissions={handleEditPermissions}
        />
      ),
    });
  };

  return (
    <div className="flex flex-col gap-2">
      <PermissionListLine
        onClick={handleViewSharedWithModal}
        onEdit={handleEditPermissions}
        productName={getProductName(row.documentable.objectType)}
        permissions={{
          isPublic: permitted.isPublic,
          directUsers: permitted.users,
          indirectUsers: [],
          directInvestmentEntities: permitted.investmentEntities,
          directRoles: permitted.roles,
          directTags: permitted.tags,
        }}
        lockedToEdit={isLocked}
        hideActions={hideActions}
      />
      {showViewCounter && (
        <p className="secondary-regular text-light-60">
          {row.viewsCount > 0
            ? `Viewed by ${pluralize('user', row.viewsCount, true)}`
            : 'Not viewed yet'}
        </p>
      )}
    </div>
  );
}

export default PermissionedUsers;
