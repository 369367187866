import { UNSUPPORTED_DASHBOARD_INFO_TEXT } from '@/bundles/Settings/components/REport/Dashboards/Dashboard/config';
import {
  ReportDashboardStatus,
  ReportDashboardType,
} from 'bundles/Shared/entities/dashboard/model/types/types';
import type { ReportEagleEyeDashboard } from 'bundles/Shared/entities/dashboard/model/types/types';
import { useDashboardContext } from 'bundles/Shared/entities/dashboard/lib';

import {
  usePutApiSettingsReportComparisonDashboardsByIdMutation,
  usePutApiSettingsReportEagleEyeDashboardsByIdMutation,
  usePutApiSettingsReportObjectDashboardsByIdMutation,
} from '@/shared/api/dashboardSettingsEnhancedApi';

import { useMemo } from 'react';

export const useUpdateDashboardStatus = () => {
  const { dashboardType } = useDashboardContext();
  const [updateEagleEyeDashboard] =
    usePutApiSettingsReportEagleEyeDashboardsByIdMutation();
  const [updateObjectDashboard] =
    usePutApiSettingsReportObjectDashboardsByIdMutation();
  const [updateComparisonDashboard] =
    usePutApiSettingsReportComparisonDashboardsByIdMutation();

  const updateDashboard = useMemo(() => {
    switch (dashboardType) {
      case ReportDashboardType.OBJECT: {
        return updateObjectDashboard;
      }
      case ReportDashboardType.EAGLE_EYE: {
        return updateEagleEyeDashboard;
      }
      case ReportDashboardType.COMPARISON_MODE: {
        return updateComparisonDashboard;
      }
      default: {
        return () => {
          toastr.warning(UNSUPPORTED_DASHBOARD_INFO_TEXT);
        };
      }
    }
  }, [dashboardType]);

  return async ({
    dashboardId,
    status,
  }: {
    dashboardId: ReportEagleEyeDashboard['id'];
    status: ReportDashboardStatus | `${ReportDashboardStatus}`;
  }) => {
    const res = await updateDashboard({
      id: dashboardId,
      body: {
        status,
      },
    });

    return res;
  };
};
