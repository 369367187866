import React, { useMemo, useState } from 'react';
import { LegalEntity } from '@/entities/core/legalEntity';
import { ModalHeaderForUpdatingLE } from '@/bundles/Settings/components/Portal/LegalEntities/ModalHeaderForSetObjectLE';
import { DialogProps, useModal } from '@/shared/lib/hooks/useModal';
import { Button } from '@/stories/Button/Button';
import { Dropdown } from '@/stories/Dropdown/Dropdown';
import { DropdownItem } from '@/stories/Dropdown/DropdownItem/DropdownItem';
import { Modal } from '@/stories/Modals/Modal/Modal';
import { SharedSelect } from '@/bundles/Shared/components/GroupForm/FormItems/new/SharedSelect';
import FundFormModal from '@/bundles/Funds/components/FundFormModal';
import AssetFormModal from '@/bundles/Assets/components/AssetFormModal';
import { useAppDispatch } from '@/shared/lib/hooks/redux';
import { createFund } from '@/bundles/Funds/actions';
import { createAsset } from '@/bundles/Assets/actions';
import {
  legalEntitiesSettingsEnhancedApi,
  TAGS,
  useGetApiSettingsCoreLegalEntitiesLegalEntitiablesQuery,
} from '@/bundles/Shared/entities/legalEntity/api/settingsCoreLegalEntitiesApiEnhanced';
import { partition } from 'lodash-es';

interface Props extends DialogProps<LegalEntityFieldOption> {
  currentLegalEntities: LegalEntity[];
}

export type LegalEntityFieldOption = {
  value: string;
  label: string;
};

// TODO: FE-3788 react-hook-form
export function SetObjectForLEModal({
  currentLegalEntities,
  onClose,
  onSubmit,
}: Props) {
  const [selectedObject, setSelectedObject] =
    useState<LegalEntityFieldOption>();

  const { data: legalEntitiables, isLoading: isLegalEntitiablesLoading } =
    useGetApiSettingsCoreLegalEntitiesLegalEntitiablesQuery();
  const [assets, funds] = useMemo(
    () => partition(legalEntitiables ?? [], (l) => l.objectType === 'Asset'),
    [legalEntitiables],
  );

  // update data format
  const assetOptions = useMemo<LegalEntityFieldOption[]>(
    () =>
      assets.map((asset) => ({
        value: `Asset_${asset.id}`,
        label: asset.name,
      })),
    [assets],
  );
  const fundOptions = useMemo(
    () => funds.map((fund) => ({ value: `Fund_${fund.id}`, label: fund.name })),
    [funds],
  );

  const handleSubmit = () => {
    onSubmit?.(selectedObject);
  };
  const onCloseModal = () => {
    setSelectedObject(undefined);
    onClose();
  };

  const dispatch = useAppDispatch();
  const { openModal } = useModal();

  const updateObjects = () => {
    dispatch(
      legalEntitiesSettingsEnhancedApi.util.invalidateTags([
        TAGS.LEGAL_ENTITIABLES,
      ]),
    );
  };

  // TODO: FE-3783 – review createFund fn
  const handleCreateFund = async () => {
    const res = await openModal(FundFormModal, {});

    if (res != null) {
      const fund = await dispatch(createFund(res));
      updateObjects();

      setSelectedObject({
        value: `Fund_${fund.id}`,
        label: fund.name,
      });
    }
  };

  // TODO: FE-3784 – review createAsset fn
  const handleCreateAsset = async () => {
    const res = await openModal(AssetFormModal, {});

    if (res != null) {
      const asset = await dispatch(createAsset(res));
      updateObjects();

      setSelectedObject({
        value: `Asset_${asset.id}`,
        label: asset.name,
      });
    }
  };

  return (
    <Modal
      toggle={onCloseModal}
      header={
        <ModalHeaderForUpdatingLE
          title="Set object for"
          currentLegalEntities={currentLegalEntities}
        />
      }
      actions={
        <>
          <Button onClick={onCloseModal} variant="secondary">
            Cancel
          </Button>
          <Button
            variant="success"
            onClick={handleSubmit}
            disabled={!selectedObject}
          >
            Set Object
          </Button>
        </>
      }
      size="500"
    >
      <div className="mb-s flex items-center justify-between">
        <label htmlFor="object">
          Object <span className="red">*</span>
        </label>
        <Dropdown
          hideOnExpandedAreaClick={false}
          className="min-w-[180px]"
          appendTo={document.body}
          items={
            <>
              <DropdownItem iconName="asset" onClick={handleCreateAsset}>
                Asset
              </DropdownItem>
              <DropdownItem iconName="funds" onClick={handleCreateFund}>
                Fund
              </DropdownItem>
            </>
          }
        >
          <Button
            iconName="bottom"
            iconPosition="right"
            variant="secondary"
            size="xs"
          >
            Add Object
          </Button>
        </Dropdown>
      </div>
      <SharedSelect
        menuPosition="fixed"
        menuShouldBlockScroll
        onChange={(option: unknown) =>
          setSelectedObject(option as LegalEntityFieldOption)
        }
        value={selectedObject}
        placeholder="Select Asset or Fund"
        isLoading={isLegalEntitiablesLoading}
        options={[
          {
            label: 'Assets',
            options: assetOptions,
          },
          {
            label: 'Funds',
            options: fundOptions,
          },
        ]}
      />
    </Modal>
  );
}
