import FormInput from 'bundles/Shared/components/GroupForm/FormItems/FormInput';
import FormSelect from 'bundles/Shared/components/GroupForm/FormItems/FormSelect';
import LoadingPanel from 'bundles/Shared/components/LoadingPanel';
import { jCCToOption } from 'bundles/Shared/helpers/job_cost_code';
import React, { FC, SetStateAction, useEffect } from 'react';
import { connect } from 'react-redux';
import { IJobCostCode } from 'types/JobCostCode';
import { fetchJCCForReallocations } from '@/bundles/Construction/actions';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import { IChangeOrderEvent } from 'bundles/Construction/types';

interface ICODORDateProps {
  formData: Record<string, any>;
  setFormData: SetStateAction<IChangeOrderEvent>;
  onChange: (num: number | null) => void;
  weightedAmount: number;
  reallocationJccId: number;
  menuPlacement: 'top' | 'bottom' | 'auto';
  canEditAllAttr: boolean;
}

const COReallocation: FC<ICODORDateProps> = ({
  formData,
  setFormData,
  menuPlacement = 'auto',
  canEditAllAttr = false,
}) => {
  const JCCForReallocations = useAppSelector(
    (state) => state.JCCForReallocations,
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchJCCForReallocations());
  }, []);

  if (!JCCForReallocations) return <LoadingPanel />;

  return (
    <div className="flex items-center">
      <FormSelect
        fieldName="reallocationJccId"
        formData={formData}
        setFormData={setFormData}
        options={JCCForReallocations.map(jCCToOption)}
        styles={{
          wrapper: 'w-50 pr-s',
          nomb: true,
        }}
        menuPlacement={menuPlacement}
        disabled={canEditAllAttr}
      />
      <FormInput
        value={
          formData?.probability
            ? (Number(formData?.amount) / 100) * formData?.probability
            : formData?.amount
        }
        formData={formData}
        loading
        leftIcon="paid"
        styles={{
          wrapper: 'w-25 pl-s',
          size: 'm',
          nomb: true,
        }}
      />
    </div>
  );
};

export default COReallocation;
