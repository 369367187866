import { InvestmentEntityClassesBadges } from '@/bundles/REturn/components/Ownership/ownershipTable/formatters/InvestmentEntityColumn';
import { CapitalInvestment, CapitalInvestmentClass } from '@/entities/return';
import { InvestmentIndex } from '@/entities/return/api/capitalInvestmentObjectsGeneratedApi';
import { CapitalInvestmentEntryType } from 'bundles/REturn/actions/types';
import { CAPITAL_INVESTMENT_ENTRY_AMOUNT_KEY_MAP } from 'bundles/REturn/components/Ownership/modals/manageCapitalInvestmentModal/consts';
import UsersColumn from 'bundles/REturn/components/Ownership/ownershipTable/formatters/UsersColumn';
import { CAPITAL_INVESTMENT_CLASS_OPTIONS } from 'bundles/REturn/components/Ownership/selectInvestmentEntity/SelectInvestmentEntity';
import QuickFilterDropdown from 'bundles/Shared/components/Table/filters/QuickFilterDropdown';
import { IColumn } from 'bundles/Shared/components/Table/types';
import { capitalize } from 'lodash-es';
import pluralize from 'pluralize';
import { useMemo } from 'react';
import { CurrencyFormatter } from 'stories/ValueFormatters/CurrencyFormatter';

interface Params {
  capitalInvestments: InvestmentIndex[];
  entryType: CapitalInvestmentEntryType;
  filterModel: {
    investmentEntity?: CapitalInvestmentClass | 'all';
  };
  setFilterModel: (data: unknown) => void;
}

export function useInvEntitiesColumns({
  capitalInvestments,
  entryType,
  filterModel,
  setFilterModel,
}: Params) {
  const entryName = capitalize(pluralize(entryType, 1));
  const type = pluralize(entryType, 1) as 'contribution' | 'distribution';
  const totalEntryAmountKeyName = CAPITAL_INVESTMENT_ENTRY_AMOUNT_KEY_MAP[type];

  const columns = useMemo<IColumn<CapitalInvestment>[]>(
    () => [
      {
        dataField: 'investmentEntity',
        text: 'Investment Entity',
        formatter: ({ row }) => (
          <div className="flex items-center justify-between gap-2">
            <span>{row.investmentEntity.name}</span>
            <div className="flex justify-center gap-2">
              <InvestmentEntityClassesBadges
                capitalInvestmentClasses={row.investmentClasses}
              />
            </div>
          </div>
        ),
        headerClasses: 'w-[320px]',
        filterComponent: QuickFilterDropdown,
        filterComponentParams: {
          options: CAPITAL_INVESTMENT_CLASS_OPTIONS,
          filterModel,
          setFilterModel,
        },
      },
      {
        dataField: 'investmentEntity.users',
        text: 'Users',
        formatter: ({ row }) => <UsersColumn row={row} />,
        headerStyle: {
          minWidth: 220,
        },
      },
      {
        dataField: totalEntryAmountKeyName,
        text: `Current ${entryName}`,
        formatter: ({ row }) => (
          <div className="flex items-center justify-end">
            <CurrencyFormatter
              classes={{ value: 'inline-regular text-dark-60' }}
              value={row[totalEntryAmountKeyName]}
            />
          </div>
        ),
        headerClasses: 'w-[180px]',
      },
    ],
    [capitalInvestments, entryType],
  );

  return columns;
}
