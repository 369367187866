import * as React from 'react';
import { BudgetAvatar } from 'stories/BudgetAvatar/BudgetAvatar';
import { cn } from '@/shared/lib/css/cn';
import dayjs from 'dayjs';
import { ISharedDocument } from 'types/SharedDocument';
import { TMentionedUser } from 'bundles/REconcile/types/Comments';
import CommentContent from 'bundles/REconcile/components/comments/CommentContent';
import { downloadSharedFile } from 'lib/sharedFile';
import { CommentsDocumentCard } from '@/bundles/REconcile/components/comments/CommentsDocumentCard/CommentsDocumentCard';
import { convertToMilliseconds } from '@/shared/lib/converters';

interface Props {
  fullName: string;
  createdAt: number;
  message: string;
  attachments: ISharedDocument[];
  onRemove?: () => void;
  className?: string;
  mentionedUsers?: TMentionedUser[];
}

export function CommentSection({
  fullName,
  createdAt,
  message,
  attachments,
  mentionedUsers = [],
  onRemove,
  className,
}: Props) {
  return (
    <div className={cn('p-s flex flex-col dark-60 gap-s bg-white', className)}>
      <div className="flex justify-between">
        <div className="flex gap-s">
          <BudgetAvatar fullName={fullName} />
          <div className="flex flex-col">
            <span className="secondary-semibold">{fullName}</span>
            <span className="light-60 secondary-regular">
              {dayjs(createdAt).fromNow()}
            </span>
          </div>
        </div>
      </div>
      <CommentContent mentionedUsers={mentionedUsers} message={message} />
      {attachments.length > 0 && (
        <div className="flex flex-col gap-s">
          {attachments.map((a) => (
            <CommentsDocumentCard
              key={a.id}
              variant="light"
              className="cursor-pointer"
              filename={a.name}
              fileExtension={a.extension}
              fileSize={a.size}
              date={new Date(convertToMilliseconds(a.createdAt))}
              onRemove={onRemove}
              onClick={() => downloadSharedFile(a)}
            />
          ))}
        </div>
      )}
    </div>
  );
}

export default CommentSection;
