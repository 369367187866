import React from 'react';
import { formatAmount } from '@/shared/lib/formatting/number';
import { Icon } from 'stories/Icon/Icon';
import { findJCC } from 'bundles/Shared/helpers/job_cost_code';
import { useGroupedFilteredItems } from '@/bundles/Construction/components/helpers';

export const categoryTransformer = (category: string) => {
  switch (category) {
    case 'COR':
      return 'COR (Change Order Request)';

    case 'PCO':
      return 'PCO (Potential Change Order)';

    case 'IPC':
      return 'IPC (Internal Risk of Change)';

    default:
      return '';
  }
};
export const amountTransformer = (value: any, formData: any) => {
  return formData.probability === 100 || !formData.probability ? (
    <div className="mr-s">{formatAmount(value)}</div>
  ) : (
    <div className="flex items-center">
      <div className="mr-s line-through">{formatAmount(value)}</div>
      <Icon iconName="arrowRightAlt" className="light-60 mr-s" />
      <div className="dark-60 secondary-semibold">
        {formatAmount(
          (value / 100) * (formData?.probability ? formData?.probability : 100),
        )}
      </div>
    </div>
  );
};

export const reallocationTransformer = (value: any, formData: any) => {
  return formatAmount(
    (value / 100) * (formData?.probability ? formData.probability : 100),
  );
};

export const reallocationTransformerName = (value: any, JCC: any) => {
  return findJCC(value, JCC).name;
};

export const reasonsTransformer = (
  value: { value: string; label: string }[] | string[] | string,
) => {
  const reasonsWithCategories = useGroupedFilteredItems(value);
  return reasonsWithCategories[0]?.category
    ? reasonsWithCategories.map((item, idx) => (
        <div key={`category${idx}`}>
          <span>{item.category}</span>
          <span className="light-60">{' ('}</span>
          {item.values.map((reason, idx) => (
            <span key={`reason${idx}`} className="light-60">
              {reason.label + (idx === item.values.length - 1 ? '' : '; ')}
            </span>
          ))}
          <span className="light-60">{')'}</span>
          <span>{';'}</span>
        </div>
      ))
    : value
        .map((item) => {
          if (item.label) return item.label;
          return item;
        })
        .join('; ');
};
