import { HUGE_MODAL_Z_INDEX } from 'bundles/Shared/constants';
import { GroupContainerItem } from 'stories/Table/Actions/ActionWrapper/GroupContainerItem';
import { Popover } from 'stories/Popover/Popover';
import { SelectableItem } from 'stories/Table/Actions/SelectableItem';

export const CleanForecastingBudgetDropdown = ({
  children,
  dropdownDisabled,
  onClean,
  onDelete,
}: React.PropsWithChildren<{
  onClean: () => void;
  onDelete: () => void;
  dropdownDisabled?: boolean;
}>) => (
  <Popover
    appendToBody
    placement="bottom-end"
    offset={[0, 0]}
    className="w-[184px] p-0 !pt-1"
    trigger="click"
    disabled={dropdownDisabled}
    hiddenArrow
    zIndex={HUGE_MODAL_Z_INDEX - 1}
    template={
      <GroupContainerItem className="py-1" title={'Options'}>
        <SelectableItem
          id={1}
          text="Clean"
          onClick={onClean}
          className="min-h-fit"
        />
        <SelectableItem
          id={2}
          text="Delete"
          onClick={onDelete}
          className="min-h-fit"
        />
      </GroupContainerItem>
    }
  >
    {children}
  </Popover>
);
