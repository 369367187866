import React, { FC, ReactNode } from 'react';
import { cn } from '@/shared/lib/css/cn';
import StreamlinedContainerHeader from '@/stories/StreamlinedContainer/components/StreamlinedContainerHeader';
import '@/stories/StreamlinedContainer/container.scss';

interface Props
  extends React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLElement>,
      HTMLElement
    >,
    Omit<React.ComponentProps<typeof StreamlinedContainerHeader>, 'title'> {
  title?: string;
  children: ReactNode;
}

export const StreamlinedContainer: FC<Props> = ({
  children,
  actions,
  title = '',
  subtitle,
  className,
  classes,
}) => (
  <div className={cn('sre-container', className)}>
    <StreamlinedContainerHeader
      actions={actions}
      title={title}
      subtitle={subtitle}
      classes={classes}
    />
    <div className="sre-container__body">{children}</div>
  </div>
);
