import React, { ReactNode } from 'react';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import {
  deleteDocumentsChangeEvent,
  selectChangeEvent,
  selectedDocumentsChangeEvent,
  uploadDocumentsChangeEvent,
  fetchChangeEvent,
} from 'bundles/Construction/reducers/ReconcileChangeEventSlice';
import { useParams } from '@reach/router';
import DocumentsUpload from '@/bundles/Construction/components/DocumentsUpload';
import { AnimationLoader } from 'stories/AnimationLoader/AnimationLoader';

const readonlyStatuses = ['COMPLETED', 'APPROVED', 'REJECTED', 'DISPUTED'];

interface Props {
  definePlacementButton: ReactNode;
}

function Documents({ definePlacementButton }: Props) {
  const changeEvent = useAppSelector(selectChangeEvent);
  const dispatch = useAppDispatch();
  const params = useParams();

  const refreshCE = (legalEntityCode, id) => {
    // after upload on deleted file - we need waiting for implemation changing in data
    setTimeout(() => {
      dispatch(
        fetchChangeEvent({
          legalEntityCode,
          id,
        }),
      );
    }, 500);
  };

  const uploadFiles = async (legalEntityCode, id, data) => {
    const files = await dispatch(
      uploadDocumentsChangeEvent({
        legalEntityCode,
        id,
        data: {
          files: data.files,
        },
      }),
    );
    return files.payload;
  };

  const selectFiles = (legalEntityCode, id, idFile) => {
    dispatch(
      selectedDocumentsChangeEvent({
        legalEntityCode,
        id,
        data: {
          document_id: idFile,
        },
      }),
    );
  };

  const removeItems = (
    arrayId: { ids: string[] },
    legalEntityCode: string,
    id: string,
  ) => {
    dispatch(
      deleteDocumentsChangeEvent({
        legalEntityCode,
        id,
        documentId: arrayId.ids[0],
      }),
    );
  };

  const isCOR = changeEvent?.category === 'COR';
  if (!changeEvent?.documents) return <AnimationLoader />;

  return (
    <div className="change-card">
      <div className="change-card__header">
        <div className="change-card__title header6-bold">Documents</div>
      </div>
      <DocumentsUpload
        documents={changeEvent.documents}
        uploadFiles={uploadFiles}
        id={params.id}
        legalEntityCode={params.legalEntityCode}
        finalFile={changeEvent?.contractFileId}
        finalFileStatus="Verified"
        removeItems={removeItems}
        selectHandle={selectFiles}
        refresh={refreshCE}
        isReadOnly={
          readonlyStatuses.includes(changeEvent.status) || changeEvent?.verified
        }
        supportSelect={isCOR}
        definePlacementButton={definePlacementButton}
      />
    </div>
  );
}

export default Documents;
