import http from 'lib/http';
import { stringify } from 'qs';
import { ISharedDocument } from 'types/SharedDocument';
import {
  FetchPermissionBySharedFileIdResponse,
  FetchSubjectablesBySharedFileIdResponse,
} from '@/bundles/Shared/actions/types';

export async function fetchPermissionModalSubjectables(data?: unknown) {
  const res = await http.get(
    `/permission_modal/subjectables${stringify(data, {
      addQueryPrefix: true,
      arrayFormat: 'brackets',
    })}`,
  );
  const responseData: unknown = await res.json();
  return responseData;
}

export async function fetchPermissionBySharedFileId(id: ISharedDocument['id']) {
  const res = await http.get(`/shared_files/${id}/permissions`);
  const responseData = await res.json();
  return responseData as FetchPermissionBySharedFileIdResponse;
}

export async function fetchSubjectablesBySharedFileId(
  id: ISharedDocument['id'],
) {
  const res = await http.get(`/shared_files/${id}/subjectables`);
  const responseData = await res.json();
  return responseData as FetchSubjectablesBySharedFileIdResponse;
}
