import { useDeleteApiReconcileForecastingForecastsByIdMutation } from '@/entities/reconcile/forecasting/api/reconcileForecastingEntitiesEnchancedApi';
import { useModal } from '@/shared/lib/hooks/useModal';
import { EntityToastrCreator } from '@/shared/lib/toastr/entityToastrCreator';

const t = new EntityToastrCreator('Forecast');

export const useDeleteForecast = () => {
  const [deleteForecast, options] =
    useDeleteApiReconcileForecastingForecastsByIdMutation();

  const { confirm } = useModal();

  const handleDeleteForecast = async (id: string) => {
    const res = await confirm({
      title: 'Delete forecast',
      subtitle: 'Are you sure you want to delete this forecast?',
    });

    if (!res) return false;

    await deleteForecast({ id });

    toastr.success(t.delete().toString());

    return true;
  };

  return { deleteForecast: handleDeleteForecast, options };
};
