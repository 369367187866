import {
  TableVizConfigColumnGradientThreshold,
  TableVizConfigGradientBackground,
} from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/table/model';
import { GradientThresholdForm } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/fields/GradientField';
import { rangePercentile } from '@/shared/lib/statistics';
import * as d3 from 'd3';

type MinMaxObject = {
  min: number;
  max: number;
  minWithoutZero: number;
  maxWithoutZero: number;
};
export type MinMaxValues = Record<string, MinMaxObject>;

export const findMaxThreshold = (thresholds: GradientThresholdForm[]) => {
  return thresholds.find((t) => t.type === 'max');
};
export const findMinThreshold = (thresholds: GradientThresholdForm[]) => {
  return thresholds.find((t) => t.type === 'min');
};

const filterZeroValues = (values: number[]) => {
  return values.filter((v) => v !== 0);
};

const buildMinMaxObject = (values: number[]): MinMaxObject => {
  const valuesWithoutZero = filterZeroValues(values);
  return {
    min: Math.min(...values),
    max: Math.max(...values),
    minWithoutZero: Math.min(...valuesWithoutZero),
    maxWithoutZero: Math.max(...valuesWithoutZero),
  };
};
export const thresholdMinMaxValuesForColumns = (
  rows: Record<string, number>[],
  columns: { key: string }[],
) => {
  if (rows.length === 0) {
    return {};
  }
  return columns.reduce((acc, column) => {
    const values = rows.map((row) => row[column.key]);
    return {
      ...acc,
      [column.key]: buildMinMaxObject(values),
    };
  }, {} as MinMaxValues);
};

export const thresholdMinMaxValuesForRows = (
  rows: Record<string, number>[],
  columns: { key: string }[],
): MinMaxValues => {
  if (rows.length === 0) {
    return {};
  }

  return rows.reduce((acc, row) => {
    const values = columns.map((column) => row[column.key.toString()]);
    return {
      ...acc,
      [row.key?.toString()]: buildMinMaxObject(values),
    };
  }, {} as MinMaxValues);
};
export const extractGradientFromThreshold = (
  field: GradientThresholdForm[],
): string[] => {
  return field.map((f) => f.color);
};

export const getThresholdBackgroundColor = ({
  background,
  minMaxValues,
  value,
  columnKey,
}: {
  background: TableVizConfigGradientBackground;
  minMaxValues: MinMaxValues;
  value: number;
  columnKey: string;
}): string | number => {
  const dataMin = background.ignore_zeros
    ? minMaxValues[columnKey].minWithoutZero
    : minMaxValues[columnKey].min;
  const dataMax = background.ignore_zeros
    ? minMaxValues[columnKey].maxWithoutZero
    : minMaxValues[columnKey].max;

  const { threshold } = background;
  const minThreshold = findMinThreshold(threshold);
  const maxThreshold = findMaxThreshold(threshold);

  if (value === dataMax && maxThreshold) {
    return maxThreshold.color;
  }

  if (value === dataMin && minThreshold) {
    return minThreshold.color;
  }

  const getDomainByThreshold = (t: TableVizConfigColumnGradientThreshold) => {
    if (t.type === 'min') {
      return dataMin;
    }
    if (t.type === 'max') {
      return dataMax;
    }
    if (t.type === 'percentile') {
      return rangePercentile({
        value: t.value!,
        rangeMin: dataMin,
        rangeMax: dataMax,
      });
    }
    return t.value!;
  };
  const gradient = extractGradientFromThreshold(threshold);
  const domain = threshold.map(getDomainByThreshold);
  const gradientScale = d3.scaleLinear().domain(domain).range(gradient);
  return gradientScale(value);
};
