import React from 'react';
import { Badge } from 'stories/Badges/Badge/Badge';
import { CssVar } from '@/shared/config/cssVar';
import { ISharedDocument } from 'types/SharedDocument';
import MediaItem from 'bundles/Assets/components/SharedFiles/MediaItem';
import DocumentItem from 'bundles/Shared/components/DocumentItem';
import PermissionList from 'bundles/Shared/components/PermissionList';
import { Media } from '@/bundles/InvestmentObjects/components/Overview/Posts/AttachedItemsPanel';

interface Props {
  attachedDocuments: ISharedDocument[];
  attachedMedia: Media[];
  onDocumentRemove: (id: string) => void;
  onMediaRemove: (id: string) => void;
  openLightbox: (id: string) => void;
  disableScrollId?: string;
}

const AttachedItemsList = ({
  attachedMedia,
  attachedDocuments,
  onMediaRemove,
  onDocumentRemove,
  openLightbox,
  disableScrollId = '',
}: Props) => (
  <div
    id={disableScrollId}
    className="modal-sticky-bottom-menu__attached-items-list p-m pt-0"
  >
    {attachedMedia.length > 0 && (
      <div>
        <h6 className="d-inline">Media</h6>
        <Badge
          className="ml-2"
          textColor={CssVar.light60}
          backgroundColor={CssVar.light10}
        >
          {attachedMedia.length}
        </Badge>
        <div className="mt-4 flex flex-wrap gap-4">
          {attachedMedia.map((media) => (
            <div key={media.id} className="mb-[0.5rem]">
              <MediaItem
                media={media}
                hideActions
                selectedItems={[]}
                onRemove={onMediaRemove ? (id) => onMediaRemove(id) : null}
                openLightbox={() => openLightbox(media.id)}
                size="m"
              />
            </div>
          ))}
        </div>
      </div>
    )}

    {attachedDocuments.length > 0 && (
      <div className={attachedMedia.length > 0 ? 'mt-m' : ''}>
        <h6 className="d-inline">Documents</h6>
        <Badge
          className="ml-2"
          textColor={CssVar.light60}
          backgroundColor={CssVar.light10}
        >
          {attachedDocuments.length}
        </Badge>
        <div className="flex flex-col flex-wrap items-start">
          {attachedDocuments.map((doc) => (
            <DocumentItem
              key={doc.id}
              id={doc.id}
              className="mt-4"
              permissions={
                doc.permitted ? (
                  <PermissionList
                    permissions={doc.permitted}
                    hideIcon
                    mainListClasses="secondary-regular light-90"
                  />
                ) : null
              }
              onRemove={onDocumentRemove ? (id) => onDocumentRemove(id) : null}
              filename={doc.filename}
              fileSize={doc.size}
              fileExtension={doc.extension}
              withLink={!doc.confidential}
            />
          ))}
        </div>
      </div>
    )}
  </div>
);

export default AttachedItemsList;
