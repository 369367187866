import { cn } from '@/shared/lib/css/cn';
import useOutsideClick from '@/shared/lib/hooks/useOutsideClick';
import { GridOptions } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { clearFocusedCellAndRange } from 'lib/ag-grid/utils';
import { ComponentProps, forwardRef, useRef } from 'react';
import { NEXT_GEN_TABLE_CONFIG } from '@/bundles/Shared/components/AgGrid/Table/consts';
import {
  getDataPath,
  getRowHeight,
  getRowId,
} from '@/bundles/Shared/components/AgGrid/Table/utils/tablePropsUtils';
import { AgGridReactRef } from '@/lib/ag-grid/types';

type Props = Omit<ComponentProps<typeof AgGridReact>, 'animateRows'> & {
  hideAmountsOnExpandedRows?: boolean;
  hideShadowsPinnedColumn?: boolean;
  /**
   * @deprecated use `viz_config.auto_column instead */
  hideHeader?: boolean;
  headerBackground?: React.CSSProperties['background'];
  enableRoundedCorners?: boolean;
  classes?: {
    container?: string;
  };
};

export const NEXT_GEN_TABLE_CLASS_NAME = 'nextGenTable';

export const SymmetreAgGridTable = forwardRef<AgGridReactRef, Props>(
  (
    {
      className,
      hideAmountsOnExpandedRows,
      hideShadowsPinnedColumn,
      hideHeader,
      headerBackground,
      enableRoundedCorners,
      classes,
      context,
      ...props
    },
    ref,
  ) => {
    const divRef = useRef<HTMLDivElement>(null);

    useOutsideClick(divRef, () => {
      if (ref != null && 'current' in ref && ref.current?.api) {
        clearFocusedCellAndRange(ref.current?.api);
      }
    });
    const options: GridOptions = {
      groupIncludeTotalFooter: true,
      groupIncludeFooter: true,
      suppressContextMenu: true,
      enableRangeSelection: true,
      animateRows: true,
      treeData: true,
      groupDefaultExpanded: -1,
      headerHeight: NEXT_GEN_TABLE_CONFIG.row.default.minHeight,
      groupHeaderHeight: NEXT_GEN_TABLE_CONFIG.row.default.minHeight,
      getRowHeight,
      getRowId,
      getDataPath,
      ...props,
    };
    return (
      <div
        ref={divRef}
        className={cn('ag-grid-30 h-full bg-white', classes?.container)}
      >
        <AgGridReact
          containerStyle={{
            '--next-gen-table-header-background-color': headerBackground,
          }}
          className={cn(
            `${NEXT_GEN_TABLE_CLASS_NAME} text-sm`,
            {
              [`${NEXT_GEN_TABLE_CLASS_NAME}_hide-amounts-on-expanded-rows`]:
                hideAmountsOnExpandedRows,
              [`${NEXT_GEN_TABLE_CLASS_NAME}_hide-shadows_pinned_column`]:
                hideShadowsPinnedColumn,
              [`${NEXT_GEN_TABLE_CLASS_NAME}_rounded-corners`]:
                enableRoundedCorners,
              ['${NEXT_GEN_TABLE_CLASS_NAME}_hide-header']: hideHeader,
            },
            options.groupIncludeFooter && 'group-include-footer',
            className,
          )}
          ref={ref}
          context={{
            options,
            ...context,
          }}
          {...options}
        />
      </div>
    );
  },
);
