import {
  useGetCustomerConfigQuery,
  useUpdateSharedFileMutation,
} from 'bundles/Assets/components/Media/api/objectFoldersApi';
import { sharedFilePermissions } from 'bundles/Shared/sharedFilePermissions';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Select from 'react-select';
import { Button } from 'stories/Button/Button';
import { Input } from 'stories/FormControls/Inputs/Input/Input';
import { Modal } from 'stories/Modals/Modal/Modal';
import { ModalHeaderWithIcon } from 'stories/Modals/ModalHeader/ModalHeaderWithIcon/ModalHeaderWithIcon';
import { MultipleItemsIcons } from 'stories/MultipleItemsIcons/MultipleItemsIcons';
import { OverlaySpinner } from 'stories/OverlaySpinner/OverlaySpinner';
import PermissionedUsers from '@/bundles/Assets/components/SharedFiles/Table/formatters/PermissionedUsers';

const EditSharedFileModal = ({
  opened,
  setOpened,
  sharedFile,
}: {
  opened: boolean;
  setOpened: (b: boolean) => void;
  sharedFile: unknown;
}) => {
  const [updateSharedFile, { isLoading: isUpdatingSharedFile }] =
    useUpdateSharedFileMutation();
  const { data: customerConfigData, isLoading: isLoadingCustomerConfigData } =
    useGetCustomerConfigQuery({});
  const documentTypes =
    customerConfigData?.documentTypes.filter(
      (type) => !type.internal && type.active,
    ) ?? [];

  const [permissions, setPermissions] = useState(sharedFile.permitted);

  const defaultValues = {
    title: sharedFile.title || '',
    document_type_id: sharedFile.documentType.id,
  };

  const { handleSubmit, register, reset, watch, setValue } = useForm();

  const fields = watch();

  useEffect(() => {
    reset(defaultValues);
  }, [opened]);

  const onReset = () => {
    reset(defaultValues);
  };

  const onSubmit = async (formData) => {
    const data = {
      shared_file: {
        ...formData,
        id: sharedFile?.id,
      },
    };

    if (!sharedFile.permissionsLocked) {
      data.shared_file = {
        ...data.shared_file,
        ...sharedFilePermissions({
          ...permissions,
          public: permissions.isPublic,
        }),
      };
    }

    await updateSharedFile(data);
    setOpened(false);
    onReset();
  };

  if (!sharedFile) return null;

  const documentTypeOptions = documentTypes.map((item) => ({
    value: item.id,
    label: item.name,
  }));
  const customHeader = (
    <ModalHeaderWithIcon
      icons={<MultipleItemsIcons itemType="documents" items={[sharedFile]} />}
      title={sharedFile.title}
      subtitle={sharedFile.extension}
    />
  );

  return (
    <Modal
      header={customHeader}
      toggle={() => setOpened(false)}
      size="400"
      actions={
        <>
          <Button variant="secondary" onClick={() => setOpened(false)}>
            Cancel
          </Button>
          <Button type="submit" variant="success" form="editSharedFileForm">
            Update
          </Button>
        </>
      }
      classes={{
        header: 'bg-light',
        body: 'bg-light',
        footer: 'bg-light',
      }}
    >
      <form
        id="editSharedFileForm"
        onSubmit={handleSubmit(onSubmit)}
        className="flex h-full flex-col"
      >
        {isUpdatingSharedFile && (
          <OverlaySpinner overlayClasses="rounded-[0.5rem]" />
        )}
        <label className="secondary-regular text-dark-60">Document Name</label>
        <Input
          size="l"
          {...register('title')}
          value={fields.title}
          onChange={(e) => setValue('title', e.target.value)}
          placeholder="Enter Document Name"
        />

        <label className="secondary-regular mt-4 text-dark-60">
          Document Type
        </label>
        <Select
          isLoading={isLoadingCustomerConfigData}
          {...register('document_type_id')}
          options={documentTypeOptions}
          value={documentTypeOptions.find(
            (dt) => dt.value === fields.document_type_id,
          )}
          onChange={(option) => setValue('document_type_id', option?.value)}
          classNamePrefix="react-select"
          placeholder="Select document type"
          id="selectDocumentType"
          isClearable
          menuPosition="fixed"
          menuShouldBlockScroll
        />
        <div className="mt-4">
          <p className="secondary-regular mb-2 text-dark-60">
            Who can see the documents?
          </p>
          <div className="inline-block text-dark-60">
            <PermissionedUsers
              row={{
                ...sharedFile,
                permitted: permissions,
              }}
              isLocked={sharedFile.permissionsLocked}
              actions={{
                submitPermissions: (perms) => {
                  setPermissions({
                    isPublic: perms.isPublic,
                    users: perms.directUsers,
                    indirectUsers: [],
                    investmentEntities: perms.directInvestmentEntities,
                    roles: perms.directRoles,
                    tags: perms.directTags,
                  });
                },
              }}
            />
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default EditSharedFileModal;
