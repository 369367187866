import React from 'react';
import { Popover } from 'stories/Popover/Popover';
import UniversalFilterButton from 'bundles/Shared/components/Filters/buttons/universalFilterButton/UniversalFilterButton';
import InvestmentObjectSelect from 'bundles/Shared/components/Filters/assets/investmentObjectSelect/InvestmentObjectSelect';
import { IInvestmentObject } from 'types/IInvestmentObject';
import { ILegalEntitiable } from 'bundles/Settings/types/ReportDataPeriodBulk';

interface Props {
  legalEntitables: ILegalEntitiable[];
  selectedLegalEntitiables: ILegalEntitiable[];
  onSelectedLegalEntitiablesChanged: (
    selectedAssets: ILegalEntitiable[],
  ) => void;
}

const mapAssetToOption = (
  legalEntitiable: ILegalEntitiable,
): IInvestmentObject => ({
  id: legalEntitiable.id,
  name: legalEntitiable.name,
  klass: legalEntitiable.type,
  legalEntities: [],
  uniqId: `${legalEntitiable.id}-${legalEntitiable.type}`,
});

function AssetQuickFilter({
  legalEntitables,
  selectedLegalEntitiables,
  onSelectedLegalEntitiablesChanged,
}: Props) {
  return (
    <Popover
      trigger="click"
      hiddenArrow
      offset={[0, 2]}
      placement="bottom-end"
      maxWidth="fit-content"
      template={
        <InvestmentObjectSelect
          investmentObjects={legalEntitables.map(mapAssetToOption)}
          selectedInvestmentObjects={selectedLegalEntitiables.map(
            mapAssetToOption,
          )}
          onSelectedInvestmentObjectsChange={(newItems) =>
            onSelectedLegalEntitiablesChanged(
              newItems.map(
                (o) =>
                  legalEntitables.find(
                    (a) => a.id === o.id && a.type === o.klass,
                  )!,
              ),
            )
          }
          withoutPopover
        />
      }
    >
      {({ triggered }) => (
        <UniversalFilterButton
          clicked={triggered}
          filtered={selectedLegalEntitiables.length > 0}
          onClose={() => onSelectedLegalEntitiablesChanged([])}
        />
      )}
    </Popover>
  );
}

export default AssetQuickFilter;
