import React, { ReactNode } from 'react';
import { cn } from '@/shared/lib/css/cn';
import UniversalFilterButton from 'bundles/Shared/components/Filters/buttons/universalFilterButton/UniversalFilterButton';
import { Checkbox } from 'stories/Checkbox/Checkbox';
import { Popover } from 'stories/Popover/Popover';
import styles from '@/bundles/Construction/components/Filters/styles.module.scss';

export interface ISelectOption {
  label: string;
  id: number | string;
  disabled?: boolean;
}

interface OptionProps extends Pick<ISelectOption, 'label' | 'disabled'> {
  selected?: boolean;
  onClick?: () => void;
}

interface StatusItem {
  value: string;
  description: string | ReactNode;
}

interface Props {
  items: StatusItem[];
  selectedItems: string[];
  setSelectedItems: (selectedObjects: string[]) => void;
  resetFilter: () => void;
}

function Option({ selected, label, onClick }: OptionProps) {
  return (
    <li
      className={cn(styles.option, {
        [styles.option_selected]: selected,
      })}
    >
      <Checkbox
        labelClassName={styles.checkbox}
        checked={selected}
        onChange={onClick}
      >
        {label}
      </Checkbox>
    </li>
  );
}

const QuickFilterByStatus = ({
  items,
  selectedItems,
  setSelectedItems,
  resetFilter,
}: Props) => {
  const getOptionSelected = (value: string) =>
    selectedItems.find((val) => value === val) !== undefined;

  const handleClick = (value: string) => {
    if (getOptionSelected(value)) {
      setSelectedItems([...selectedItems.filter((val) => value !== val)]);
      return;
    }

    setSelectedItems([...selectedItems, value]);
  };

  return (
    <Popover
      template={
        <ul className={styles.list}>
          {items.map((o) => (
            <Option
              key={o.value}
              selected={getOptionSelected(o.value)}
              onClick={() => handleClick(o.value)}
              label={o.value}
            />
          ))}
          {items.length === 0 && <Option label="No Options" />}
        </ul>
      }
      maxWidth="37.5rem"
      placement="bottom-start"
      hiddenArrow
      trigger="click"
      offset={[0, 2]}
    >
      {({ triggered }) => (
        <UniversalFilterButton
          clicked={triggered}
          filtered={selectedItems.length > 0}
          onClose={resetFilter}
        />
      )}
    </Popover>
  );
};

export default QuickFilterByStatus;
