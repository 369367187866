import { UNGROUPED_GROUP_KEY } from '@/bundles/Shared/entities/dashboard/model/slices/shared';
import {
  ColumSizeWidgetState,
  DisplayedGroupsWidgetState,
} from '@/bundles/Shared/widgets/dashboard/widgets/common/model';
import { columnToColumnSettingsVizConfigKeyMatcher } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/table/lib';
import {
  TableVizConfig,
  TableVizConfigColumnGroup,
} from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/table/model';
import { columnToColumnSettingsByKeyMatcher } from '@/bundles/Shared/widgets/dashboard/widgets/kpiTable';
import useBoolean from '@/shared/lib/hooks/useBoolean';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import { Button } from '@/stories/Button/Button';
import { Checkbox } from '@/stories/Checkbox/Checkbox';
import { Icon } from '@/stories/Icon/Icon';
import { ModalHeaderWithSubtitle } from '@/stories/Modals/ModalHeader/ModalHeaderWithSubtitle/ModalHeaderWithSubtitle';
import { SidePanel } from '@/stories/Modals/Modal/Modal';
import { useMemo } from 'react';

type Group = {
  id: string;
  name: string;
  hide: boolean;
  allColDefIds: string[];
  children?: Omit<Group, 'children'>[];
};

type ColumSize = null | 'default' | 'compact';
export type ColumnSizeChangeHandler = (c: ColumSize) => void;

const WidgetStateColumnsSidePanel = ({
  onColumnSizeChange,
  onColumnStateChange,
  onClose,
  groups,
  dataColumns,
  vizConfig,
}: DialogProps & {
  groups: Group[];
  dataColumns: {
    key: number;
    vizConfigKey: string;
    label: string;
  }[];
  vizConfig: TableVizConfig;
  onColumnSizeChange: ColumnSizeChangeHandler;
  onColumnStateChange: (groupId: string, colId?: string) => void;
}) => {
  const { column_groups, columns } = vizConfig;
  const hasUngroupedColumns = columns.some((c) => !c.group_id);

  const renderColumnGroup = (cg: TableVizConfigColumnGroup) => {
    const group = groups.find((g) => g.id === cg.group_id);

    return (
      <div
        key={cg.group_id}
        className="flex flex-col rounded-[8px] bg-neutral-000 p-2"
      >
        <label className="flex items-center gap-1 p-1 pr-2">
          <Icon
            iconName="groupByFilled"
            className="!my-0.5 flex h-max leading-none"
          />
          <p className="inline-semibold flex-grow text-neutral-800">
            {cg.header_name}
          </p>
          <Checkbox
            labelClassName="flex h-max !my-0.5 leading-none"
            indeterminate={group?.children?.some((i) => !i.hide)}
            checked={!group?.hide && group?.children?.every((c) => !c.hide)}
            onChange={() => onColumnStateChange(cg.group_id)}
          />
        </label>
        <div className="flex flex-col gap-0.5 p-1 pr-2">
          {group?.children?.map((item, idx) => {
            const vizConfigColumn = columns.find((c) => c.col_id === item.id)!;
            const dataColumn = dataColumns.find(
              (c) =>
                columnToColumnSettingsVizConfigKeyMatcher(c, vizConfigColumn) ||
                columnToColumnSettingsByKeyMatcher(c, vizConfigColumn),
            );

            return (
              <label key={item.id} className="flex items-center gap-1">
                <p className="inline-regular flex w-[12px] items-center justify-center text-neutral-400">
                  {idx + 1}
                </p>
                <p className="inline-regular flex-grow overflow-hidden break-words text-neutral-700">
                  {dataColumn?.label}
                </p>
                <div className="grow" />
                <Checkbox
                  labelClassName="flex h-max !my-0.5 leading-none"
                  checked={!item.hide}
                  onChange={() => onColumnStateChange(cg.group_id, item.id)}
                />
              </label>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <SidePanel
      size="380"
      header={<ModalHeaderWithSubtitle title="Displayed columns" />}
      toggle={onClose}
      classes={{
        overlay: 'max-w-[380px]',
        body: 'flex flex-col gap-4 !pb-[90px]',
      }}
    >
      <div className="flex flex-col gap-2 rounded-[8px] bg-neutral-000 p-4">
        <p className="secondary-semibold text-neutral-800">Column size</p>
        <p className="secondary-regular text-neutral-550">
          You can control the size of the columns of the widget
        </p>
        <div className="flex items-center gap-2">
          <Button
            onClick={() => {
              onColumnSizeChange('compact');
            }}
            size="s"
            fluid
          >
            Compact
          </Button>
          <Button
            onClick={() => {
              onColumnSizeChange('default');
            }}
            size="s"
            fluid
          >
            Default
          </Button>
        </div>
      </div>
      <div className="flex flex-col gap-4">
        {hasUngroupedColumns &&
          renderColumnGroup({
            group_id: UNGROUPED_GROUP_KEY,
            header_name: 'Ungrouped',
            order: 0,
          })}
        {column_groups.map(renderColumnGroup)}
      </div>
    </SidePanel>
  );
};

type State = ColumSizeWidgetState & DisplayedGroupsWidgetState;

export const WidgetStateColumns = ({
  onColumnStateChange,
  isDataLoading,
  onColumnSizeChange,
  state,
  vizConfig,
  columns,
}: {
  state: State;
  onColumnSizeChange: ColumnSizeChangeHandler;
  onColumnStateChange: (groupId: string, colId?: string) => void;
  vizConfig: TableVizConfig;
  columns: {
    key: string;
    label: string;
  }[];
  isDataLoading?: boolean;
}) => {
  const { value: sidePanelOpen, toggle: toggleSidePanelOpen } = useBoolean();

  const noColumns = (state.displayedGroups?.length ?? 0) === 0;

  const columnsMeta = useMemo(() => {
    return {
      totalLength: (state.displayedGroups ?? []).flatMap(
        (g) => g.children ?? [],
      ).length,
      activeLength: (state.displayedGroups ?? []).flatMap(
        (g) => g.children?.filter((i) => !i.hide) ?? [],
      ).length,
    };
  }, [state.displayedGroups]);

  const buttonLabel = useMemo(() => {
    if (isDataLoading) {
      return 'Loading';
    }

    if (noColumns) {
      return 'No columns';
    }

    return (
      <>
        {columnsMeta.activeLength} of {columnsMeta.totalLength}
      </>
    );
  }, [isDataLoading, noColumns, columnsMeta]);

  return (
    <>
      <Button
        loading={isDataLoading}
        disabled={noColumns || isDataLoading}
        size="s"
        iconName="columnsAlt"
        onClick={toggleSidePanelOpen}
      >
        {buttonLabel}
      </Button>

      {sidePanelOpen && (
        <WidgetStateColumnsSidePanel
          onColumnSizeChange={onColumnSizeChange}
          onColumnStateChange={onColumnStateChange}
          onClose={toggleSidePanelOpen}
          groups={state.displayedGroups}
          dataColumns={columns}
          vizConfig={vizConfig}
        />
      )}
    </>
  );
};
