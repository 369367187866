import React, { FC, SetStateAction } from 'react';
import { AnimationLoader } from 'stories/AnimationLoader/AnimationLoader';
import { FilterCard } from 'stories/filterCard/FilterCard';
import { useGetPayableInvoicesBadgesQuery } from 'bundles/Construction/api/invoices';
import { useParams } from '@reach/router';
import { InvoiceBadge } from '@/shared/types/reconcile/Invoice';
import { formatAmount } from '@/shared/lib/formatting/number';
import pluralize from 'pluralize';
import { usePageParamsContext } from 'bundles/Shared/components/pageParams/PageParamsContext';
import styles from '@/bundles/DrawPackage/styles.module.scss';
import { TFilterModelPayableInvoices } from '@/bundles/DrawPackage/PayableInvoices/components/useColumns';

interface IBadgesBarProps {
  pageParams: TFilterModelPayableInvoices;
  setPageParams: SetStateAction<any>;
}

const BadgesBar: FC<IBadgesBarProps> = () => {
  const { pageParams, setPageParams } =
    usePageParamsContext<TFilterModelPayableInvoices>();
  const params = useParams();
  const { data, isLoading } = useGetPayableInvoicesBadgesQuery({
    legalEntityCode: params?.legalEntityCode,
  });

  if (isLoading) return <AnimationLoader />;

  const { topVendors, otherVendors } = data;

  const filterFunc = (id: number) => {
    const haveVendor = pageParams.vendor_ids?.find(
      (vendorId) => vendorId === id,
    );
    if (haveVendor) {
      const newVendorsIds = pageParams.vendor_ids.filter(
        (vendorId) => vendorId !== id,
      );
      setPageParams({
        ...pageParams,
        vendor_ids: newVendorsIds,
      });
      return;
    }
    if (pageParams.vendor_ids) {
      setPageParams({
        ...pageParams,
        vendor_ids: [...pageParams.vendor_ids, id],
      });
      return;
    }
    setPageParams({
      ...pageParams,
      vendor_ids: [id],
    });
  };

  return (
    <div className="mb-l flex gap-m">
      {topVendors.map((vendor: InvoiceBadge) => (
        <FilterCard
          key={vendor.id}
          header={
            <div>
              <div className="header5-bold mb-xs">
                {formatAmount(vendor.amount)}
              </div>
              <div className="label-regular">{vendor.name}</div>
            </div>
          }
          content={
            <div className="label-regular">
              {vendor.invoicesCount} {pluralize('Invoice', vendor.invoiceCount)}
            </div>
          }
          onFilter={() => filterFunc(vendor.id)}
          className={styles.filterCard}
          filtered={pageParams.vendor_ids?.find(
            (vendorId) => vendorId === vendor.id,
          )}
          filterText="Filtered"
        />
      ))}
      {otherVendors.amount && (
        <FilterCard
          header={
            <div className="light-90">
              <div className="header5-bold mb-xs">
                {formatAmount(otherVendors.amount)}
              </div>
              <div className="label-regular">
                Other {otherVendors.invoicesCount}{' '}
                {pluralize('Vendor', otherVendors.invoiceCount)}
              </div>
            </div>
          }
          content={
            <div className="label-regular">
              {otherVendors.invoicesCount}{' '}
              {pluralize('Invoice', otherVendors.invoiceCount)}
            </div>
          }
          className={styles.filterCard}
        />
      )}
    </div>
  );
};

export default BadgesBar;
