import React, { FC, useState } from 'react';
import { cn } from '@/shared/lib/css/cn';
import styles from 'bundles/Construction/styles.module.scss';
import pluralize from 'pluralize';
import { Button } from 'stories/Button/Button';
import { CategoryObject } from 'stories/Objects/CategoryObject/CategoryObject';
import { formatAmount } from '@/shared/lib/formatting/number';
import { navigate } from '@reach/router';
import { Invoice, InvoiceLineItem } from '@/shared/types/reconcile/Invoice';
import { TriangleSvg } from 'bundles/Construction/components/Shared/triangleForPopover';

interface ILineItemsInvoiceProps {
  lineItemsLength: number;
  object: {
    id: string;
    lineItems: InvoiceLineItem[];
  };
  hideBtn?: boolean;
}

export const INVOICE_STATUS_BUTTONS = {
  created: {
    text: 'Verify',
    func: (id: number) => navigate(`invoices/verify/${id}`),
  },
  verified: {
    text: 'Approve',
    func: (id: number) => navigate(`invoices/${id}`),
  },
  approved: {
    text: 'Details',
    // For next tasks
    func: null,
  },
};

const LineItemsInvoice: FC<ILineItemsInvoiceProps> = ({
  lineItemsLength,
  object,
  hideBtn,
}: {
  lineItemsLength: number;
  object: Invoice;
}) => {
  const [showList, setShowList] = useState(false);
  const isLineItems = Boolean(lineItemsLength);

  return (
    <div className="flex justify-between">
      <div
        className={cn(
          isLineItems && styles.invoiceLineItems,
          !isLineItems && 'light-60',
        )}
      >
        <div
          className={cn(Boolean(isLineItems) && styles.invoiceLineItemsCounter)}
          onClick={(e) => {
            e.stopPropagation();
            setShowList(!showList);
          }}
          onMouseEnter={() => {
            isLineItems && setShowList(true);
          }}
        >
          {lineItemsLength} {pluralize('item', lineItemsLength)}
        </div>
        {showList && (
          <div className={styles.invoiceLineItemsTriangle}>
            <TriangleSvg />
          </div>
        )}
        {showList && (
          <div
            className={styles.invoiceLineItemsModalSafe}
            onClick={(e) => {
              e.stopPropagation();
            }}
            onMouseLeave={() => {
              setShowList(false);
            }}
          >
            <div className={styles.invoiceLineItemsModal}>
              <div className={styles.invoiceLineItemsTitle}>
                {lineItemsLength} Line {pluralize('Item', lineItemsLength)}
              </div>
              {object.lineItems.map((item, idx) => (
                <div
                  key={item.developmentCategory.name}
                  className={cn(
                    styles.invoiceLineItemsElem,
                    idx !== lineItemsLength - 1 && styles.invoiceLineItemsLine,
                  )}
                >
                  <div className="pr-m">
                    <CategoryObject path={item.developmentCategory.path} />
                  </div>
                  <div className="light-60 secondary-regular pr-m">
                    {item.developmentCategory.name}
                  </div>
                  <div className="secondary-regular">
                    {formatAmount(item.amount)}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
        {showList && (
          <div
            onMouseEnter={() => setShowList(false)}
            className={styles.invoiceLineItemsOverlayer}
          />
        )}
      </div>
      {object.status !== 'approved' && !hideBtn && (
        <Button
          variant="secondary"
          size="xs"
          onClick={(e) => {
            e.stopPropagation();
            INVOICE_STATUS_BUTTONS[object.status].func(object.id);
          }}
        >
          {INVOICE_STATUS_BUTTONS[object.status].text}
        </Button>
      )}
    </div>
  );
};

export default LineItemsInvoice;
