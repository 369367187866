import { useAssertNullableContext } from '@/shared/lib/hooks/context/useAssertNullableContext';
import useSearchParamsState from '@/shared/lib/hooks/useSearchParamsState';
import { createContext, useMemo, useState, type Context } from 'react';
import ThinTabGroup, {
  IThinTabItem,
} from 'stories/Tabs/ThinTabGroup/ThinTabGroup';
import { ReadonlyDeep } from 'type-fest';

export interface TabsContextValue<T> {
  tab: T | undefined;
  setTab: React.Dispatch<React.SetStateAction<T | undefined>>;
  thinTabGroupProps: Pick<
    React.ComponentProps<typeof ThinTabGroup>,
    'items' | 'selectedItem' | 'onSelectedItemChange'
  >;
}

const TabsContext = createContext<TabsContextValue<
  IThinTabItem | ReadonlyDeep<IThinTabItem>
> | null>(null);

export const useTabs = <T extends IThinTabItem | ReadonlyDeep<IThinTabItem>>(
  tabsArgs: T[] | readonly T[] | (() => T[] | readonly T[]),
  defaultTab?: T | number,
) => {
  const tabs = typeof tabsArgs === 'function' ? tabsArgs() : tabsArgs;

  const [tab, setTab] = useState<T | undefined>(
    typeof defaultTab === 'number' ? tabs[defaultTab] : defaultTab,
  );

  const thinTabGroupProps: Pick<
    React.ComponentProps<typeof ThinTabGroup>,
    'items' | 'selectedItem' | 'onSelectedItemChange'
  > = {
    items: tabs as unknown as IThinTabItem[],
    onSelectedItemChange: (item) => setTab(item as unknown as T),
    selectedItem: tab as IThinTabItem,
  };

  return {
    thinTabGroupProps,
    tab,
    setTab,
  };
};

/**
 * Same as `useTabs` but keeps state with browswer search param in sync
 */
export const useSearchParamsTabs = <
  T extends IThinTabItem | ReadonlyDeep<IThinTabItem>,
>(
  tabsArgs: T[] | readonly T[] | (() => T[] | readonly T[]),
  searchParamKey: string = 'tab',
  defaultTab?: number,
) => {
  const tabs = typeof tabsArgs === 'function' ? tabsArgs() : tabsArgs;

  const [selectedId, setSelectedId] = useSearchParamsState<T['id']>(
    searchParamKey,
    tabs[defaultTab ?? 0].id,
  );

  const selectedItem = useMemo(() => {
    return tabs.find((t) => t.id === selectedId);
  }, [tabs, selectedId]);

  const thinTabGroupProps: Pick<
    React.ComponentProps<typeof ThinTabGroup>,
    'items' | 'selectedItem' | 'onSelectedItemChange'
  > = {
    items: tabs as unknown as IThinTabItem[],
    onSelectedItemChange: (item) => setSelectedId(item.id),
    selectedItem,
  };

  return {
    thinTabGroupProps,
    tab: selectedItem,
    setTab: setSelectedId,
  };
};

export const useTabsContext = <
  T extends IThinTabItem | ReadonlyDeep<IThinTabItem>,
>(): TabsContextValue<T> => {
  return useAssertNullableContext<TabsContextValue<T>>(
    TabsContext as Context<TabsContextValue<T>>,
  )();
};

export const TabsContextProvider = ({
  children,
  value,
}: React.PropsWithChildren<{
  value: TabsContextValue<IThinTabItem | ReadonlyDeep<IThinTabItem>>;
}>) => {
  return <TabsContext.Provider value={value}>{children}</TabsContext.Provider>;
};
