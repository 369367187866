import { IColumn } from 'bundles/Shared/components/Table/types';
import { UserActivityUsers } from 'bundles/Shared/entities/user/api/userActivityApi';
import { TrendLabel } from 'bundles/Shared/shared/TrendLabel';
import dayjs from 'dayjs';
import { formatUnixDate } from '@/shared/lib/formatting/dates';

export enum TColumnIdsUserActivity {
  NAME = 'fullName',
  USER_ROLES = 'userRole',
  SESSIONS = 'sessions',
  DURATION = 'duration',
  LAST_SEEN = 'lastSeen',
  MOBILE = 'mobile',
  DESKTOP = 'desktop',
  STATS = 'stats',
}

function formatTimeUnit(unit: number): string {
  return unit.toString().padStart(2, '0');
}

export function formatTimeFromSeconds(seconds: number) {
  const roundedSeconds = Math.round(seconds);
  const miliseconds = roundedSeconds * 1000;
  const dayjsDuration = dayjs.duration(miliseconds);

  const hours = dayjsDuration.hours();
  const minutes = dayjsDuration.minutes();
  const dateSeconds = dayjsDuration.seconds();

  const formattedHours = formatTimeUnit(hours);
  const formattedMinutes = formatTimeUnit(minutes);
  const formattedSeconds = formatTimeUnit(dateSeconds);

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
}

export const useUserActivityColumns = (): IColumn<
  UserActivityUsers['items'][0]
>[] => [
  {
    dataField: TColumnIdsUserActivity.NAME,
    text: 'Name',
    formatter: ({ row }) => (
      <div className="inline-semibold text-neutral-900">
        <div>{row.fullName}</div>
      </div>
    ),
  },
  {
    dataField: TColumnIdsUserActivity.USER_ROLES,
    text: 'Role',
    formatter: ({ row }) => <div>{row.userRole.name}</div>,
  },
  {
    dataField: TColumnIdsUserActivity.SESSIONS,
    text: 'Sessions',
    sortable: true,
  },
  {
    dataField: TColumnIdsUserActivity.DURATION,
    text: 'Time',
    sortable: true,
    formatter: ({ row }) => formatTimeFromSeconds(row.duration),
  },
  {
    dataField: TColumnIdsUserActivity.LAST_SEEN,
    text: 'Last Seen',
    sortable: true,
    formatter: ({ row }) => <div>{formatUnixDate(row.lastSeen, 'LL')}</div>,
  },
  {
    dataField: TColumnIdsUserActivity.MOBILE,
    text: 'Mobile',
    sortable: true,
  },
  {
    dataField: TColumnIdsUserActivity.DESKTOP,
    text: 'Desktop',
    sortable: true,
  },
  {
    dataField: TColumnIdsUserActivity.STATS,
    text: 'Stats',
    formatter: ({ row }) => <TrendLabel value={row.trend} />,
  },
];
