export type ReportBuilderTemplate = {
  id: string;
  name: string;
  tags: string[];
  kind: 'object_level' | 'eagle_eye';
};

export const REPORT_BUILDER_WIDGET_TYPES = {
  KPI_CARD: 'kpi_card',
  DELINQUENCY_TRACKER_TABLE: 'delinquency_tracker_table',
  RECENT_RENT_ROLL_TABLE: 'recent_rent_roll_table',
  HISTORICAL_TABLE: 'historical_review_table',
  TEXT_AREA: 'text_area',
  FINANCIAL_TABLE_SINGLE_DATE: 'financial_table_single_date',
  UNIT_MIX_SUMMARY_TABLE: 'unit_mix_summary_table',
  MEDIA: 'media',
  XY_CHART: 'xy_chart',
  UNIT_TYPE_TABLE: 'unit_type_table',
  GLOBAL_LEASE_TABLE: 'global_lease_table',
} as const;

export type ReportBuilderWidgetTypes =
  (typeof REPORT_BUILDER_WIDGET_TYPES)[keyof typeof REPORT_BUILDER_WIDGET_TYPES];

export const REPORT_BUILDER_WIDGET_TYPES_LIST = Object.values(
  REPORT_BUILDER_WIDGET_TYPES,
) as ReportBuilderWidgetTypes[];

export const EAGLE_EYE_REPORT_BUILDER_WIDGET_TYPES = {
  KPI_CARD: 'kpi_card',
  KPI_TABLE: 'kpi_table',
  KPI_TABLE_SINGLE_DATE: 'kpi_table_single_date',
  XY_CHART_SINGLE_KPI: 'xy_chart_single_kpi',
  GLOBAL_LEASE_TABLE: 'global_lease_table',
} as const;

export type EagleEyeReportBuilderWidgetTypes =
  (typeof EAGLE_EYE_REPORT_BUILDER_WIDGET_TYPES)[keyof typeof EAGLE_EYE_REPORT_BUILDER_WIDGET_TYPES];

export const EAGLE_EYE_REPORT_BUILDER_WIDGET_TYPES_LIST = Object.values(
  EAGLE_EYE_REPORT_BUILDER_WIDGET_TYPES,
) as ReportBuilderWidgetTypes[];

export type ReportBuilderWidgetContext = {
  date?: string | undefined;
  dateFrom?: string | undefined;
  dateTo?: string | undefined;
  granularity?: string | undefined;
};
