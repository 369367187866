import selectTableRowHint from 'images/return/createEntryWorkflow/select-table-row-hint.png';
import selectInvEntRowHint from 'images/return/createEntryWorkflow/select-inv-ent-row-hint.png';
import React from 'react';
import { Icon } from 'stories/Icon/Icon';

const HINT_IMG_ALT_TEXT = 'hint for selecting a row from table';

export const SELECT_TRANSACTION_TEXT_JSX = (
  <p className="body-semibold text-dark-60">
    Select Transaction <span className="inline-semibold text-red">*</span>
  </p>
);

export const FIRST_STEP_HINT_INFO_JSX = (
  <>
    <div className="flex flex-col gap-1.5">
      {SELECT_TRANSACTION_TEXT_JSX}

      <p className="inline-regular text-light-70">
        Just start to select Transaction on the Left Side to start create the
        Contribution
      </p>
    </div>
    <div className="flex justify-center p-4">
      <img src={selectTableRowHint} alt={HINT_IMG_ALT_TEXT} />
    </div>
  </>
);

export const SELECT_INV_ENTITY_TEXT_JSX = (
  <div className="flex flex-col gap-1.5 text-dark-60">
    <p className="body-semibold ">Link to</p>

    <p className="inline-regular">
      Select Investment Entity{' '}
      <span className="inline-semibold text-red">*</span>
    </p>
  </div>
);

export const SECOND_STEP_HINT_INFO_JSX = (
  <>
    {SELECT_INV_ENTITY_TEXT_JSX}
    <div className="flex justify-center p-4">
      <img src={selectInvEntRowHint} alt={HINT_IMG_ALT_TEXT} />
    </div>
  </>
);

export const THIRD_STEP_GO_BACKWARD_BUTTON_TEXT_JSX = (
  <div className="flex items-center gap-2">
    <Icon className="text-light-60" iconName="arrowLeft" />
    Back
  </div>
);

export const SUCCESS_CONFIRM_MODAL_TITLE_JSX = (
  <div className="mb-6 flex flex-col gap-6">
    <Icon className="text-[2rem] text-[#2CAE4C]" iconName="acceptAlt" />
    <p>Success!</p>
  </div>
);
