import React, { useEffect, useMemo, useState } from 'react';
import { NavigateFn, Router, useParams } from '@reach/router';
import Table from 'bundles/Shared/components/Table/Table';
import ChangeOrderWorkflow from 'bundles/Construction/components/ChangeOrderWorkflow';
import { changeOrderColumns } from 'bundles/Construction/components/tableContent';
import { AnimationLoader } from 'stories/AnimationLoader/AnimationLoader';
import { Badge } from 'stories/Badges/Badge/Badge';
import { Button } from 'stories/Button/Button';
import { Tumbler } from 'stories/Tumbler/Tumbler';
import { generateXLSX } from '@/bundles/Construction/components/Shared/constructionReport';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import ChangeOrder from 'bundles/Construction/components/ChangeOrder';
import { FETCH_CHANGE_ORDER } from 'bundles/Construction/actions';
import ExportDropdownButton from '@/bundles/Construction/components/ExportDropdownButton';
import BadgesBar from '@/bundles/Construction/components/ChangeOrder/BadgesBar/BadgesBar';
import styles from '@/bundles/Construction/styles.module.scss';
import { useItems } from '@/bundles/Construction/hooks/ChangeOrderLogs/useItems';
import type { IPageParams } from 'bundles/Construction/types';
import {
  createChangeOrder,
  fetchChangeOrderEvents,
  fetchChangeOrders,
} from '@/bundles/Construction/actions';
import { saveCOLogAsPDF } from 'bundles/Construction/utils';

const initialPageParams: IPageParams = {
  sortField: 'number',
  sortOrder: 'desc',
  statusList: [],
};

interface Props {
  navigate: NavigateFn;
}

const ChangeOrderLogs = (props: Props) => {
  const { navigate } = props;
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [pageParams, setPageParams] = useState(initialPageParams);
  const params = useParams();
  const legalEntity = useAppSelector(
    (state) => state.developmentBudget.legalEntity,
  );
  const asset = useAppSelector((state) => state.currentAsset);
  const changeOrders = useAppSelector(
    (state) => state.changeOrderManagement.changeOrders,
  );
  const legalEntityCode = legalEntity.code;

  const [idleChecked, setIdleChecked] = useState(
    new URL(document.location).searchParams.get('idle_only') === 't',
  );

  const idleEvents = useMemo(
    () => (changeOrders ? changeOrders.filter((co) => co.idle) : []),
    [changeOrders],
  );

  const items = useItems(changeOrders, setIsLoading, [pageParams, idleChecked]);

  useEffect(() => {
    dispatch(
      fetchChangeOrders(
        params?.legalEntityCode ||
          asset?.legalEntities[0].code ||
          legalEntity?.code,
      ),
    );
  }, [params?.legalEntityCode]);

  const handleClick = (e, row) => {
    const ANCHOR_TAG_NAME = 'A';
    if (
      e.target.tagName !== ANCHOR_TAG_NAME &&
      e.target.parentNode.tagName !== ANCHOR_TAG_NAME
    ) {
      dispatch({ type: FETCH_CHANGE_ORDER, payload: row });
      navigate(row.id.toString());
    }
  };

  if (isLoading) return <AnimationLoader className="static min-h-[360px]" />;

  return (
    <div>
      <BadgesBar
        legalEntityCode={legalEntityCode}
        pageParams={pageParams}
        setPageParams={setPageParams}
        navigate={navigate}
      />
      <div className="mt-15">
        <div className="flex items-center col-12 mb-m">
          {items.length > 0 && (
            <span className={styles.itemsInfo}>
              {' '}
              <strong>1 - {items.length}</strong> of {items.length}
            </span>
          )}
          <div className={styles.checkbox}>
            <Tumbler
              checked={idleChecked}
              onChange={(e) => setIdleChecked(e.target.checked)}
            >
              Show Idle
              <Badge
                backgroundColor="var(--light-20)"
                textColor="var(--dark-60)"
                className="ml-1.5"
              >
                {idleEvents.length}
              </Badge>
            </Tumbler>
          </div>
          <ExportDropdownButton
            onClickPDF={() =>
              saveCOLogAsPDF({
                assetName: legalEntity.assetName,
                changeOrders: items,
              })
            }
            onClickExcel={() =>
              generateXLSX(legalEntity.code, {
                change_order_ids: items.map((ce) => ce.id),
                visibility: 'change-orders',
              })
            }
          />
          <Button
            className="ml-4"
            variant="primary"
            onClick={() => {
              navigate('new');
            }}
          >
            Create Change Order
          </Button>
        </div>
        <div>
          <Table
            loading={isLoading}
            columns={changeOrderColumns}
            items={items || []}
            onRowClick={handleClick}
            settings={pageParams}
            setSettings={setPageParams}
          />
        </div>
        <Router primary={false}>
          <ChangeOrderWorkflow
            path="new"
            onSubmit={(data) =>
              dispatch(createChangeOrder(legalEntityCode, data))
                .then(() => dispatch(fetchChangeOrderEvents(legalEntityCode)))
                .then(() => navigate('.'))
            }
          />
          <ChangeOrder
            legalEntityCode={legalEntityCode}
            containerClassName="reconcile-entity-modal"
            path=":changeOrderId"
          />
        </Router>
      </div>
    </div>
  );
};

export default ChangeOrderLogs;
