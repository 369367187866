import { useCallback } from 'react';
import { ThresholdModal } from '@/entities/report/threshold/ui/ThresholdModal';
import {
  useDeleteApiSettingsReportThresholdsByKindMutation,
  usePutApiSettingsReportThresholdsByKindMutation,
} from '@/shared/api/settingsReportThresholdsEnhancedApi';
import { useModal } from '@/shared/lib/hooks/useModal';
import { ReportThreshold } from '@/entities/report/threshold/model';

export const useUpdateThreshold = (threshold: ReportThreshold) => {
  const { openModal } = useModal();
  const [updateThreshold, options] =
    usePutApiSettingsReportThresholdsByKindMutation();
  const handler = useCallback(
    async (
      modalProps?: Omit<
        React.ComponentProps<typeof ThresholdModal>,
        'onSubmit' | 'onClose'
      >,
    ) => {
      const res = await openModal(ThresholdModal, {
        threshold,
        ...modalProps,
      });
      if (res) {
        await updateThreshold({
          kind: threshold.kind,
          body: {
            global_value: res.globalValue,
          },
        });
      }
    },
    [threshold],
  );

  return [handler, options] as const;
};

export const useDeleteThreshold = (threshold: ReportThreshold) => {
  const { confirm } = useModal();
  const [deleteThreshold, options] =
    useDeleteApiSettingsReportThresholdsByKindMutation();
  const callback = useCallback(async () => {
    const res = await confirm({
      title: 'Delete Threshold',
      subtitle:
        'Are you sure you want to remove this threshold? Note all the specified Legal Entity thresholds will also be removed.',
    });
    if (res) {
      await deleteThreshold({ kind: threshold.kind });
    }
  }, [threshold]);

  return [callback, options] as const;
};
