import { importCapital } from '@/bundles/REturn/actions/capitalInvestments';
import saveFileOnUserDevice from '@/shared/lib/browser/saveFileOnUserDevice';
import FileExtensionIcon from 'bundles/Shared/components/fileExtensionIcon/FileExtensionIcon';
import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import { Button } from 'stories/Button/Button';
import { IconButton } from 'stories/IconButton/IconButton';
import { Modal } from 'stories/Modals/Modal/Modal';
import { ModalActions } from 'stories/Modals/Modal/ModalActions';
import { ModalHeaderWithSubtitle } from 'stories/Modals/ModalHeader/ModalHeaderWithSubtitle/ModalHeaderWithSubtitle';
import { MultilineAlert } from 'stories/MultilineAlert/MultilineAlert';
import { OverlaySpinner } from 'stories/OverlaySpinner/OverlaySpinner';
import FileInfo from 'stories/DocumentCard/components/FileInfo';

interface Props {
  onClose: () => void;
  refetch: () => void;
  importType: string;
}

export const ImportREturnModal = ({ onClose, refetch, importType }: Props) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [result, setResult] = useState<any>(false);
  const [loading, setLoading] = useState(false);

  const onDrop = (file: File[]) => {
    setSelectedFile(file[0]);
  };

  const importXlsx = async (formData: FormData) => {
    const response = await importCapital(formData, importType);

    saveFileOnUserDevice(
      response,
      `${window.symmetreConfig.customer.subdomain}-${importType}.xlsx`,
    );
  };

  const handleFileUpload = async () => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append('file', selectedFile);
      setLoading(true);
      await importXlsx(formData);
      setLoading(false);
      setResult(true);
      refetch();
    }
  };

  return (
    <Modal
      toggle={onClose}
      size="600"
      header={
        <ModalHeaderWithSubtitle
          title={`Import ${importType} from Excel`}
          order="subtitle-title"
        />
      }
      actions={
        <ModalActions alignItems="space-between">
          <Button variant="secondary" onClick={onClose}>
            Close
          </Button>
          {!result && (
            <Button
              variant="success"
              onClick={handleFileUpload}
              disabled={selectedFile === null && !loading}
            >
              Import
            </Button>
          )}
        </ModalActions>
      }
    >
      {loading && <OverlaySpinner size="small" />}
      {!selectedFile && !result && (
        <div className="align-center flex h-full w-full justify-center">
          <Dropzone
            onDrop={onDrop}
            accept={{
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                ['.xlsx'],
            }}
            multiple={false}
          >
            {({ getRootProps, getInputProps }) => (
              <div
                {...getRootProps({
                  className: 'dropzone rounded-[0.5rem] w-full',
                })}
              >
                <input {...getInputProps()} multiple={false} />
                <div className="dz-default dz-message">
                  <div className="dz-icon">
                    <i className="demo-pli-upload-to-cloud icon-5x" />
                  </div>
                  <div>
                    <span className="text-sm">
                      Drag a file to start uploading
                    </span>
                    <p className="light-60 m-2 text-sm">or</p>
                    <Button variant="primary">Browse files</Button>
                  </div>
                </div>
              </div>
            )}
          </Dropzone>
        </div>
      )}

      {selectedFile && !result && (
        <div className="flex flex-col">
          <i className="demo-pli-file-excel icon-5x" />
          <div className="text-md dark-60 mb-3">Selected file for import:</div>
          <div className="flex gap-s">
            <FileExtensionIcon className="bg-light-10" extension="xlsx" />
            <div className="text-sm">
              {selectedFile.name}
              <FileInfo size={selectedFile.size} extension={'xlsx'} />
            </div>
            <div>
              <IconButton
                iconName="closeSmall"
                onClick={() => setSelectedFile(null)}
              />
            </div>
          </div>
        </div>
      )}

      {result && (
        <div>
          Your records has been imported. Open downloaded excel file to see
          details
        </div>
      )}
    </Modal>
  );
};
