import React, { useEffect, useRef, useState } from 'react';
import { Icon } from 'stories/Icon/Icon';
import { cn } from '@/shared/lib/css/cn';
import { isEmpty } from 'lodash-es';
import useFileDialog from '@/shared/lib/hooks/useFileDialog';
import { TextEditor, TextEditorRef } from '@symmetre-web/text-editor';
import {
  MENTION_MARKER,
  processMentions,
} from 'bundles/REconcile/components/comments/CommentEditor/utils';
import CommentsDocumentCard from '@/bundles/REconcile/components/comments/CommentsDocumentCard/CommentsDocumentCard';

type Content = Record<string, unknown>;

export interface ICommentSubmitBody {
  text: string;
  files: File[];
}

interface Props {
  variant: 'dark' | 'light';
  onSubmit: (content: ICommentSubmitBody) => void;
  onTextChange?: (text: string) => void;
  text?: string;
  resetOnSubmit?: boolean;
  onCancel?: () => void;
  className?: string;
  mentionableUsers?: { id: string; fullName: string }[];
}

function CommentEditor({
  variant,
  text,
  mentionableUsers = [],
  onSubmit,
  resetOnSubmit,
  className,
}: Props) {
  const editorRef = useRef<TextEditorRef>(null);
  const [internalText, setInternalText] = useState(text ?? '');
  const { files, openFileDialog, setFiles } = useFileDialog({
    fileMode: 'replace',
  });

  const editor = editorRef?.current?.getProseMirrorEditor();

  useEffect(() => {
    setInternalText(text ?? '');
  }, [text]);

  const getIconClassName = () =>
    cn(
      'cursor-pointer',
      variant === 'dark'
        ? 'cursor-pointer bg-dark-40 white'
        : 'cursor-pointer bg-white light-60',
    );

  const handleFileRemove = (file: File) => {
    setFiles(Array.from(files).filter((f) => f !== file));
  };

  const handleMentionClick = () => {
    if (editor) {
      editor.commands.insertContent(MENTION_MARKER);
      editor.commands.focus('end');
    }
  };

  const handleSubmit = () => {
    if (!editor) return;

    const plainText = editor.getText();
    if (isEmpty(plainText.trim())) return;

    onSubmit({
      text: processMentions({
        users: mentionableUsers.map((user) => ({
          id: String(user.id),
          fullName: user.fullName,
        })),
        text: plainText,
        direction: 'backend',
      }),
      files: Array.from(files),
    });

    if (resetOnSubmit) {
      setInternalText('');
      editor.commands.clearContent();
      setFiles([]);
    }
  };

  const handleChange = (content: unknown) => {
    const htmlContent = content ? JSON.stringify(content) : '';
    setInternalText(htmlContent);
  };

  const parseContent = (jsonString: string): Content | undefined => {
    try {
      return JSON.parse(jsonString) as Content;
    } catch (e) {
      console.error('Failed to parse editor content:', e);
      return undefined;
    }
  };

  return (
    <div
      className={cn(className, 'comment-editor', `comment-editor_${variant}`)}
    >
      <div className="flex items-center gap-s">
        <div className={`flex-grow secondary-regular`}>
          <TextEditor
            value={internalText ? parseContent(internalText) : undefined}
            onChange={handleChange}
            placeholder="Write a comment"
            ref={editorRef}
            mentionableUsers={mentionableUsers}
          />
        </div>

        <div className="flex gap-xs">
          <Icon
            onClick={() => openFileDialog()}
            padding="xs"
            className={getIconClassName()}
            iconName="attach"
          />
          <Icon
            onClick={handleMentionClick}
            padding="xs"
            className={getIconClassName()}
            iconName="userAdd"
          />
          <Icon
            padding="xs"
            className={cn(
              'white cursor-pointer',
              isEmpty(internalText)
                ? 'disabled bg-dark-40 opacity-50'
                : 'bg-blue',
            )}
            iconName="longArrowUp"
            onClick={handleSubmit}
          />
        </div>
      </div>

      {files && files.length > 0 && (
        <div className="flex flex-col mt-s gap-s">
          {Array.from(files).map((f, i) => (
            <CommentsDocumentCard
              key={i}
              filename={f.name}
              fileSize={f.size}
              fileExtension={f.name.split('.').pop() || ''}
              variant={variant}
              onRemove={() => handleFileRemove(f)}
            />
          ))}
        </div>
      )}
    </div>
  );
}

export default CommentEditor;
