import React, { useEffect, useState } from 'react';
import { Button } from '@/stories/Button/Button';
import { Icon } from '@/stories/Icon/Icon';
import { Input } from '@/stories/FormControls/Inputs/Input/Input';
import { ReturnableTag } from '@/stories/Tags/ReturnableTag/ReturnableTag';
import { Tag } from '@/stories/Tags/Tag/Tag';
import { SectionField } from '@/stories/Field/FieldsWrappers';
import { useForm, UseFormSetValue } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useBoolean } from 'usehooks-ts';
import { HorizontalEditableField } from '@/stories/Field/HorizontalEditableField';
import {
  InvestmentEntityDto,
  usePostApiInvestmentEntitiesMutation,
} from '@/shared/api/investmentEntityGeneratedApi';
import { usePutApiInvestmentEntitiesByIdMutation } from '@/shared/api/investmentEntityEnhancedApi';
import { coreFiltersUsersSettingsApi } from '@/bundles/UserManagement/api/settingsCoreLegalEntitiesApi';
import { cn } from '@/shared/lib/css/cn';
import AddItemFromList from '@/bundles/Shared/components/AddItemFromList';
import { VendorEntity } from '@/bundles/Shared/entities/investmentEntities/model';
import { fetchVendorsEntities } from '@/bundles/Settings/actions/vendor_entities';
import { useCustomer } from '@/lib/customers';
import { REPORT_PRODUCT_NAME } from '@/lib/permissions';
import { IUser } from '@/types/User';
import { DEFAULT_STRING_FALLBACK } from '@/shared/lib/formatting/fallbacks';
const validationSchema = yup.object({
  name: yup.string().required('Name is required'),
  memberIds: yup.array().of(yup.string()),
  leadMemberIds: yup.array().of(yup.string()),
  vendorIds: yup.array().of(yup.string()),
});

type FormValues = yup.InferType<typeof validationSchema>;

export const SelectedUsersList = ({
  itemIds,
  setValue,
  entityKey,
  labelKey,
  allItems,
}: {
  itemIds: string[];
  setValue: UseFormSetValue<FormValues>;
  entityKey: keyof FormValues;
  labelKey: keyof FormValues;
  allItems: IUser[] | VendorEntity[];
}) => {
  return (
    <div className="flex flex-wrap gap-2">
      {itemIds.map((id) => (
        <div key={id}>
          <ReturnableTag
            onInclude={() => {
              setValue(entityKey, [...itemIds, id]);
            }}
            label={allItems?.find((item) => item.id === id)?.[labelKey] ?? ''}
            onClick={() => {
              setValue(
                entityKey,
                itemIds.filter((itemId) => itemId !== id),
              );
            }}
          />
        </div>
      ))}
    </div>
  );
};

export const BasicTab = ({
  item,
  isNewRecord,
  handleCreateRecord,
}: {
  isNewRecord: boolean;
  handleCreateRecord: () => void;
  item?: InvestmentEntityDto;
}) => {
  const {
    value: isEditing,
    setTrue: startEditing,
    setFalse: stopEditing,
  } = useBoolean(isNewRecord);

  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { isValid },
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      id: item?.id ?? '',
      name: item?.name ?? '',
      userIds: item?.users?.map((user) => user.id) ?? [],
      leadMemberIds: item?.leadUsers?.map((user) => user.id) ?? [],
      vendorIds: item?.vendorEntities?.map((vendor) => vendor.id) ?? [],
    },
    mode: 'onChange',
  });

  const formValues = watch();
  const toggle = (key: keyof FormValues, id: string) =>
    setValue(
      key,
      (((formValues[key] as string[]) || []).includes(id)
        ? ((formValues[key] as string[]) || []).filter(
            (itemId) => itemId !== id,
          )
        : [...((formValues[key] as string[]) || []), id]) as
        | IUser['id'][]
        | VendorEntity['id'][],
    );

  const handleCancel = () => {
    stopEditing();
  };

  const [createInvestmentEntity, { isLoading: isCreating }] =
    usePostApiInvestmentEntitiesMutation();

  const [updateInvestmentEntity, { isLoading: isUpdating }] =
    usePutApiInvestmentEntitiesByIdMutation();

  const handleSave = handleSubmit(async (data) => {
    let result;
    if (isNewRecord) {
      result = await createInvestmentEntity({
        body: {
          investment_entity: {
            name: data.name,
          },
        },
      });
      if (!result.data.errors) {
        handleCreateRecord();
      }
    } else {
      result = await updateInvestmentEntity({
        id: formValues.id,
        body: {
          investment_entity: {
            name: data.name,
            user_ids: data.userIds,
            lead_user_ids: data.leadMemberIds,
            vendor_entity_ids: data.vendorIds,
          },
        },
      });
    }

    if (!result.data.errors) {
      const entity = result.data as InvestmentEntityDto;

      reset({
        id: entity.id,
        name: entity.name,
        userIds: entity.userIds ?? [],
        leadMemberIds: entity.leadUsers?.map((user) => user.id) || [],
        vendorIds: entity.vendorEntities?.map((vendor) => vendor.id) || [],
      });
      stopEditing();
    }
  });

  const [allVendorEntities, setAllVendorEntities] = useState<VendorEntity[]>(
    [],
  );

  const { data: allUsers } =
    coreFiltersUsersSettingsApi.useGetApiSettingsCoreFiltersUsersQuery();

  const currentCustomerHasReportProduct =
    useCustomer().products?.includes(REPORT_PRODUCT_NAME);

  useEffect(() => {
    if (!currentCustomerHasReportProduct) return;

    fetchVendorsEntities().then((res) =>
      setAllVendorEntities(
        (
          res as {
            meta: {
              perPage: number;
              totalSize: number;
            };
            vendors: VendorEntity[];
          }
        ).vendors,
      ),
    );
  }, []);

  return (
    <SectionField
      labelText="Key Info"
      button={
        isEditing ? (
          <div className="flex gap-2">
            <Button size="xs" variant="secondary" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              size="xs"
              variant="success"
              onClick={handleSave}
              loading={isCreating || isUpdating}
              disabled={!isValid}
            >
              Save Changes
            </Button>
          </div>
        ) : (
          <Button size="xs" variant="secondary" onClick={startEditing}>
            Change
          </Button>
        )
      }
    >
      <div className="-mx-4 divide-y divide-solid divide-neutral-200">
        <HorizontalEditableField
          label="Name"
          value={formValues.name}
          editable={isEditing}
          required={isEditing}
          className={isEditing ? 'py-2' : 'py-4'}
        >
          <Input {...register('name')} />
        </HorizontalEditableField>

        <HorizontalEditableField
          label="Members"
          value={
            <div className="flex flex-wrap gap-2">
              {formValues.userIds?.map((id) => (
                <Tag
                  key={id}
                  label={
                    allUsers?.find((user) => user.id === id)?.fullName ?? ''
                  }
                />
              ))}
              {formValues.userIds?.length === 0 && DEFAULT_STRING_FALLBACK}
            </div>
          }
          editable={isEditing}
          className="py-4"
        >
          <div className="flex flex-wrap items-center gap-2">
            <AddItemFromList
              className="p-0"
              allItems={allUsers}
              selectedItems={formValues.userIds?.map((id) => ({ id })) ?? []}
              onSelect={(user) => toggle('userIds', user.id)}
              mask="users"
              getMainFieldTitle={(user) => user.fullName}
              getSecondaryFieldTitle={(user) => user.role?.name}
            >
              <Icon iconName="add" />
            </AddItemFromList>
            <SelectedUsersList
              itemIds={formValues.userIds ?? []}
              setValue={setValue}
              entityKey="userIds"
              labelKey="fullName"
              allItems={allUsers}
              onRemove={(id) => toggle('userIds', id)}
            />
          </div>
        </HorizontalEditableField>

        <HorizontalEditableField
          label="Lead Members"
          value={
            <div className="flex flex-wrap gap-2">
              {formValues.leadMemberIds?.map((id) => (
                <Tag
                  key={id}
                  label={
                    allUsers?.find((user) => user.id === id)?.fullName ?? ''
                  }
                />
              ))}
              {formValues.leadMemberIds?.length === 0 &&
                DEFAULT_STRING_FALLBACK}
            </div>
          }
          editable={isEditing}
          className={cn(isEditing ? 'py-4' : 'py-4')}
        >
          <div className="flex flex-wrap items-center gap-2">
            <AddItemFromList
              className="p-0"
              allItems={allUsers}
              selectedItems={
                formValues.leadMemberIds?.map((id) => ({ id })) ?? []
              }
              onSelect={(user) => toggle('leadMemberIds', user.id)}
              mask="users"
              getMainFieldTitle={(user) => user.fullName}
              getSecondaryFieldTitle={(user) => user.role?.name}
            >
              <Icon iconName="add" />
            </AddItemFromList>
            <SelectedUsersList
              itemIds={formValues.leadMemberIds ?? []}
              setValue={setValue}
              entityKey="leadMemberIds"
              labelKey="fullName"
              allItems={allUsers}
              onRemove={(id) => toggle('leadMemberIds', id)}
            />
          </div>
        </HorizontalEditableField>

        <HorizontalEditableField
          label="Vendor Entities"
          value={
            <div className="flex flex-wrap gap-2">
              {formValues.vendorIds?.map((id) => (
                <Tag
                  key={id}
                  label={
                    allVendorEntities?.find((vendor) => vendor.id === id)
                      ?.name ?? ''
                  }
                />
              ))}
              {formValues.vendorIds?.length === 0 && DEFAULT_STRING_FALLBACK}
            </div>
          }
          editable={isEditing}
          className="py-4"
        >
          <div className="flex flex-wrap items-center gap-2">
            <AddItemFromList
              className="p-0"
              allItems={allVendorEntities}
              selectedItems={formValues.vendorIds?.map((id) => ({ id })) ?? []}
              onSelect={(vendor) => toggle('vendorIds', vendor.id)}
              mask="vendors"
              getMainFieldTitle={(vendor) => vendor.name}
              getSecondaryFieldTitle={(vendor) => vendor.code}
            >
              <Icon iconName="add" />
            </AddItemFromList>
            <SelectedUsersList
              itemIds={formValues.vendorIds ?? []}
              setValue={setValue}
              entityKey="vendorIds"
              labelKey="name"
              allItems={allVendorEntities}
              onRemove={(id) => toggle('vendorIds', id)}
            />
          </div>
        </HorizontalEditableField>
      </div>
    </SectionField>
  );
};

export default BasicTab;
