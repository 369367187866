import React from 'react';
import { ERPSystem, ERPSystemLabel } from '@/entities/erpsystem';

interface Props {
  sourceType: ERPSystem;
}

function SourceTypeColumn({ sourceType }: Props) {
  return <ERPSystemLabel system={sourceType} />;
}

export default SourceTypeColumn;
