import {
  PERIOD_MAP,
  PERIOD_MAP_REVERSED,
} from '@/bundles/REturn/components/Ownership/modals/addDistributionsModal/EnterDistributionsStep';
import { InvestmentIndex } from '@/entities/return/api/capitalInvestmentObjectsGeneratedApi';
import { useModal } from '@/shared/lib/hooks/useModal';
import { ReactDatePickerWrapper } from '@/stories/FlexibleFilterByPeriods/reactdatepickerWrapper';
import { DistributionKind } from 'bundles/REturn/actions/types';
import { DISTRIBUTION_CATEGORIES_OPTIONS } from 'bundles/REturn/components/Ownership/modals/addDistributionsModal/AddDistributionsModal';
import { STATIC_DATE_PICKER_PROPS } from 'bundles/REturn/components/Ownership/modals/consts';
import CurrencyInputNumber from 'bundles/Shared/components/GroupForm/FormItems/CurrencyInputNumber';
import { SettingsModal } from 'bundles/Shared/components/SettingsModal/SettingsModal';
import dayjs from 'dayjs';
import { assertsType } from 'lib/typeHelpers/assertsType';
import { ComponentProps } from 'react';
import ReactDatePicker from 'react-datepicker';
import { createPeriodFromDate } from 'stories/FlexibleFilterByPeriods/utils';
import { Input } from 'stories/FormControls/Inputs/Input/Input';
import { Select } from 'stories/FormControls/Select/Select';

type DistributionForm = {
  amount: number;
  date: DateString | undefined;
  period: string | null | undefined;
  period_type: 'monthly' | 'quarterly' | null;
  kind: DistributionKind | undefined;
  quarter?: string | null;
  note?: string | undefined;
};

type ReturnDistributionForm = {
  amount: number;
  date: DateString;
  period: string;
  period_type: 'monthly' | 'quarterly';
  kind: DistributionKind;
};

export function useAddManualDistributionSettingsModal({
  capitalInvestment,
}: {
  capitalInvestment: InvestmentIndex;
}) {
  const { openModal } = useModal();

  return async (
    {
      initialForm,
      modalHeaderProps,
      submitButtonText,
    }: {
      initialForm: DistributionForm;
    } & Partial<
      Pick<
        ComponentProps<typeof SettingsModal>,
        'modalHeaderProps' | 'submitButtonText'
      >
    > = {
      initialForm: {
        amount: 0,
        date: undefined,
        period: undefined,
        period_type: 'quarterly',
        kind: undefined,
        note: undefined,
      },
    },
  ) => {
    const res = await openModal(
      SettingsModal<DistributionForm, ReturnDistributionForm>,
      {
        initialForm,
        modalHeaderProps: modalHeaderProps ?? {
          title: 'Add New Distribution',
          subtitle: capitalInvestment.investmentEntity.name,
        },
        submitButtonText: submitButtonText ?? 'Add Distribution',
        isDisabled: (f) =>
          f.date == null ||
          f.period == null ||
          f.amount === 0 ||
          f.amount === undefined ||
          f.kind == null,
        fields: [
          {
            name: 'value',
            renderer({ form, setForm }) {
              return (
                <CurrencyInputNumber
                  selectOnFocus
                  size="m"
                  value={form.amount}
                  allowNegative={false}
                  onValueChange={(value) => {
                    setForm((prev) => ({
                      ...prev,
                      amount: value,
                    }));
                  }}
                />
              );
            },
          },
          {
            name: 'date',
            renderer({ form, setForm }) {
              return (
                <ReactDatePicker
                  {...STATIC_DATE_PICKER_PROPS}
                  selected={form.date ? dayjs(form.date).toDate() : null}
                  onChange={(newDate) => {
                    setForm((prev) => ({
                      ...prev,
                      quarter:
                        prev.quarter == null && newDate
                          ? createPeriodFromDate(newDate)
                          : prev.quarter,
                      date: createPeriodFromDate(newDate ?? new Date()),
                    }));
                  }}
                />
              );
            },
          },
          {
            name: 'period',
            renderer({ form, setForm }) {
              return (
                <ReactDatePickerWrapper
                  periodType={PERIOD_MAP[form.period_type]}
                  setPeriodType={(periodTypeShorthand) => {
                    setForm((prev) => ({
                      ...prev,
                      period_type: PERIOD_MAP_REVERSED[periodTypeShorthand],
                    }));
                  }}
                  selected={form.period ? dayjs(form.period).toDate() : null}
                  onChange={(newDate) => {
                    setForm((prev) => ({
                      ...prev,
                      period: createPeriodFromDate(newDate ?? new Date()),
                    }));
                  }}
                />
              );
            },
          },
          {
            name: 'Transaction Kind',
            renderer({ form, setForm }) {
              return (
                <Select
                  size="m"
                  options={DISTRIBUTION_CATEGORIES_OPTIONS}
                  selected={DISTRIBUTION_CATEGORIES_OPTIONS.find(
                    (c) => c.id === form.kind,
                  )}
                  onSelectedChange={(option) => {
                    assertsType<
                      (typeof DISTRIBUTION_CATEGORIES_OPTIONS)[number]
                    >(option, (i) => i != null && !Array.isArray(option));

                    setForm((prev) => ({
                      ...prev,
                      kind: option.id,
                    }));
                  }}
                  placeholder="Select Category"
                  spanContainer="w-full"
                  inputProps={{
                    className: 'w-full',
                  }}
                />
              );
            },
          },
          {
            name: 'Note',
            optional: true,
            renderer({ form, setForm }) {
              return (
                <Input
                  className="w-full"
                  type="text"
                  placeholder="Note"
                  value={form.note}
                  onChange={(e) => {
                    setForm((prev) => ({
                      ...prev,
                      note: e.target.value,
                    }));
                  }}
                />
              );
            },
          },
        ],
      },
    );

    return res;
  };
}
