import {
  HEADER_BACKGROUND_SCHEMA,
  PERIOD_SHIFT_SCHEMA,
} from '@/bundles/Shared/widgets/dashboard/widgets/common/config';
import { transformPeriodShiftDtoToForm } from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/config';
import {
  DEFAULT_DRILLDOWN_FIELD_SCHEMA,
  DEFAULT_WIDGET_DRILLDOWN,
} from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/fields/DrilldownField';
import type { FinancialTableSinglePeriodWidgetConfigDto } from '@/shared/api/dashboardsGeneratedApi';
import * as yup from 'yup';

export const BASIC_TAB_FORM_SCHEMA = yup
  .object()
  .concat(HEADER_BACKGROUND_SCHEMA)
  .shape({
    availablePeriodTypes: yup.array().of(yup.string()).required(),
    defaultPeriodType: yup.string().required(),
    period_shift: PERIOD_SHIFT_SCHEMA.nullable().optional(),
  })
  .concat(DEFAULT_DRILLDOWN_FIELD_SCHEMA);

export type FinancialTableSinglePeriodBasicTabForm = yup.InferType<
  typeof BASIC_TAB_FORM_SCHEMA
>;

export const transformFinancialTableSinglePeriodConfigToForm = (
  config: FinancialTableSinglePeriodWidgetConfigDto,
): FinancialTableSinglePeriodBasicTabForm => {
  return {
    availablePeriodTypes: config.period_types,
    defaultPeriodType: config.default_options.period_type,
    period_shift: transformPeriodShiftDtoToForm(
      config.default_options.date?.period_shift,
    ),
    header_background: config.viz_config?.header_background,
    enable_drill_down: config.enable_drill_down ?? DEFAULT_WIDGET_DRILLDOWN,
  };
};
