/* eslint-disable react/no-unstable-nested-components */
import { useCommitmentSettingsModalWithUnitPrices } from '@/bundles/REturn/components/Ownership/modals/manageCapitalInvestmentModal/hooks/useCommitmentSettingsModal';
import { useTotals } from '@/bundles/REturn/components/Ownership/modals/manageCapitalInvestmentModal/hooks/useTotals';
import { ContributionsByClassTotalColumn } from '@/bundles/REturn/components/Ownership/ownershipTable/columns';
import { useLoadedCapitalTransactionSources } from '@/bundles/REturn/hooks/useLoadedCapitalTransactionSources';
import { Contribution, Distribution } from '@/entities/return';
import {
  useDeleteApiCapitalInvestmentCommitmentsByIdMutation,
  useDeleteApiCapitalInvestmentContributionsByIdMutation,
  useDeleteApiCapitalInvestmentDistributionsByIdMutation,
  usePutApiCapitalInvestmentCommitmentsByIdMutation,
} from '@/entities/return/api/capitalInvestmentObjectsEnhancedApi';
import {
  Commitment,
  InvestmentIndex,
  TransactionSource,
} from '@/entities/return/api/capitalInvestmentObjectsGeneratedApi';
import { formatDate } from '@/shared/lib/formatting/dates';
import { useModal } from '@/shared/lib/hooks/useModal';
import { Dropdown } from '@/stories/Dropdown/Dropdown';
import { DropdownItem } from '@/stories/Dropdown/DropdownItem/DropdownItem';
import { IconButton } from '@/stories/IconButton/IconButton';
import type * as Type from 'bundles/REturn/actions/types';
import { useLoadedInvestmentObject } from 'bundles/REturn/hooks/useLoadedInvestmentObject';
import { IColumn, TFilterModel } from 'bundles/Shared/components/Table/types';
import FullWhiteTable from 'bundles/Shared/components/Table/variants/FullWhiteTable';
import { useCustomer } from 'lib/customers';
import { REPORT_PRODUCT_NAME } from 'lib/permissions';
import { useCallback, useMemo, useState } from 'react';
import {
  capitalEntryDateColumn,
  getCapitalEntryAmountColumn,
  getCapitalEntryClass,
  getCapitalEntryKindColumn,
} from '@/bundles/REturn/components/Ownership/modals/linkTxModals/linkTxToManuallyAdded/hooks/tableColumns/useEntryColumns';
import FireFilters from '@/bundles/REturn/components/Ownership/modals/manageCapitalInvestmentModal/Columns/FireFilters';
import MainColumnFormatter from '@/bundles/REturn/components/Ownership/modals/manageCapitalInvestmentModal/Columns/MainColumnFormatter';
import { useComputedItems } from '@/bundles/REturn/components/Ownership/modals/manageCapitalInvestmentModal/hooks/useComputedItems';
import { useEditManualEntry } from '@/bundles/REturn/components/Ownership/modals/manageCapitalInvestmentModal/hooks/useEditManualEntry';
import { useRemoveEntry } from '@/bundles/REturn/components/Ownership/modals/manageCapitalInvestmentModal/hooks/useRemoveEntry';
import {
  convertCentsToDollars,
  convertDollarsToCents,
} from '@/shared/lib/converters';
import { DEFAULT_STRING_FALLBACK } from '@/shared/lib/formatting/fallbacks';
type Props = {
  notMappedCount: number;
  items: Contribution[] | Distribution[];
  type: TransactionSource['kind'];
  capitalInvestment: InvestmentIndex;
  capitalInvestments: InvestmentIndex[];
};

export const CommitmentsTable = ({
  items,
  capitalInvestment,
}: {
  capitalInvestment: InvestmentIndex;
  items: Commitment[];
}) => {
  const { confirm } = useModal();
  const commitmentModal = useCommitmentSettingsModalWithUnitPrices();
  const [updateCommitment] =
    usePutApiCapitalInvestmentCommitmentsByIdMutation();
  const [deleteCommitment] =
    useDeleteApiCapitalInvestmentCommitmentsByIdMutation();

  const handleEdit = useCallback(
    async (row: Commitment) => {
      const res = await commitmentModal({
        capitalInvestment,
        initialForm: {
          amount: convertCentsToDollars(row.amountCents),
          date: row.signedDate ?? undefined,
        },
        modalHeaderProps: {
          title: 'Edit Commitment',
          subtitle: capitalInvestment.investmentEntity.name,
        },
        submitButtonText: 'Edit',
      });

      if (res == null) return;

      await updateCommitment({
        id: row.id,
        body: {
          amount_cents: res.amount
            ? convertDollarsToCents(res.amount)
            : undefined,
          signed_date: res.date,
        },
      });
    },
    [capitalInvestment, commitmentModal, updateCommitment],
  );

  const handleRemove = async (row: Commitment) => {
    const res = await confirm({
      subtitle: 'Are you sure you want to remove this commitment?',
    });

    if (!res) return;

    await deleteCommitment({
      id: row.id,
    });
  };

  const columns = useMemo<IColumn<Commitment>[]>(() => {
    return [
      {
        dataField: 'creator',
        text: 'Creator',
        formatter: ({ row }) => (
          <div className="secondary-semibold flex items-center justify-between">
            <span className="text-neutral-900">
              {row?.createdBy?.fullName || DEFAULT_STRING_FALLBACK}
            </span>
            <Dropdown
              appendTo={document.body}
              items={[
                <DropdownItem
                  key="edit"
                  iconName="editAlt"
                  onClick={() => handleEdit(row)}
                >
                  Edit
                </DropdownItem>,
                <DropdownItem
                  key="remove"
                  iconName="trash"
                  onClick={() => handleRemove(row)}
                >
                  Remove
                </DropdownItem>,
              ]}
            >
              <IconButton variant="secondary" iconName="more" />
            </Dropdown>
          </div>
        ),
        headerClasses: 'w-[300px]',
      },
      {
        dataField: 'date',
        text: 'Signed Date',
        formatter: ({ row }) => (
          <div className="flex flex-col gap-0.5">
            <span className="secondary-regular text-dark-60">
              {formatDate(row.signedDate as DateString, 'MM-DD-YYYY')}
            </span>
          </div>
        ),
      },
      getCapitalEntryAmountColumn({
        hasUnits: true,
        headerAlign: 'end',
      }),
    ];
  }, [items]);

  return <FullWhiteTable items={items} columns={columns} />;
};

const CapitalInvestmentEntryTable = ({
  items,
  type,
  notMappedCount,
  capitalInvestment,
  capitalInvestments,
}: Props) => {
  const currentCustomerHasReportProduct =
    useCustomer().products?.includes(REPORT_PRODUCT_NAME);
  const [filterModel, setFilterModel] = useState({
    notMapped: false,
    withoutSources: false,
  });

  const { data: capitalTransactionSources } =
    useLoadedCapitalTransactionSources();

  const { data: capitalInvestmentObject } = useLoadedInvestmentObject();
  const { fromSourceEnabled } = capitalInvestmentObject;

  const [deleteContribution] =
    useDeleteApiCapitalInvestmentContributionsByIdMutation();
  const [deleteDistribution] =
    useDeleteApiCapitalInvestmentDistributionsByIdMutation();

  const removeAction = async (id: Contribution['id'] | Distribution['id']) => {
    const deleteEntry =
      type === 'contribution' ? deleteContribution : deleteDistribution;
    await deleteEntry({ id });
  };

  const hasSources = useMemo(() => {
    return capitalTransactionSources.some((source) => source.kind === type);
  }, [capitalTransactionSources, type]);

  const warningFiresLength = notMappedCount;
  const redFiresLength = hasSources ? 0 : notMappedCount;

  const { computedItems } = useComputedItems({
    filterModel,
    items,
  });

  const handleFilterModelChange = useCallback(
    (newFilterModel: TFilterModel) => {
      setFilterModel((prevParams) => ({
        ...prevParams,
        ...newFilterModel,
      }));
    },
    [],
  );

  const onRemoveEntry = useRemoveEntry({
    items: computedItems,
    type,
    removeAction,
    capitalInvestments,
  });

  const onEditManualEntry = useEditManualEntry({
    capitalInvestment,
    type,
  });

  const headerText = useMemo(() => {
    if (fromSourceEnabled) {
      if (currentCustomerHasReportProduct) return 'Linked with source';

      return 'Entries';
    }

    return 'Transaction';
  }, [currentCustomerHasReportProduct, fromSourceEnabled]);

  const totals = useTotals({
    computedItems,
    type,
  });
  return (
    <div className="flex flex-col gap-2">
      <FullWhiteTable<Type.CapitalEntry>
        items={computedItems}
        filterModel={filterModel}
        onFilterModelChange={handleFilterModelChange}
        columns={[
          {
            dataField: 'accountingTransaction',
            headerClasses: 'w-[300px]',
            text: headerText,
            formatter: ({ row }) => (
              <MainColumnFormatter
                type={type}
                entry={row}
                noSources={Boolean(redFiresLength)}
                actions={{
                  onRemoveEntry,
                  onEditManualEntry,
                }}
              />
            ),
            mapClasses: {
              headerDiv: 'gap-2',
              headerFilterDiv: 'gap-2',
            },
            filterComponent: fromSourceEnabled && FireFilters,
            filterComponentParams: {
              hidden: !currentCustomerHasReportProduct,
              fireFilterList: [
                {
                  count: warningFiresLength,
                  priority: 'low',
                  filterModelField: 'notMapped',
                },
                {
                  count: redFiresLength,
                  priority: 'high',
                  filterModelField: 'withoutSources',
                },
              ],
            },
          },
          capitalEntryDateColumn,
          getCapitalEntryClass(type),
          getCapitalEntryAmountColumn({
            hasUnits: type === 'contribution',
            headerAlign: type === 'contribution' ? 'end' : 'start',
          }),
          getCapitalEntryKindColumn(type),
        ]}
      >
        {type === 'contribution' ? (
          <span className="secondary-regular flex justify-end gap-1 pr-4 text-dark-60">
            <span className="text-light-60">Total</span>
            <ContributionsByClassTotalColumn
              classAContributions={totals.classAContributions}
              classBContributions={totals.classBContributions}
              classAContributionsUnits={totals.classAContributionsUnits}
              classBContributionsUnits={totals.classBContributionsUnits}
              contributions={totals.amount}
              contributionsUnits={totals.units}
            />
          </span>
        ) : (
          <FullWhiteTable.Total value={totals.amount} />
        )}
      </FullWhiteTable>
    </div>
  );
};

export default CapitalInvestmentEntryTable;
