import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import {
  fetchReasons,
  selectReasons,
} from 'bundles/Construction/reducers/ReconcileSlice';
import { AnimationLoader } from 'stories/AnimationLoader/AnimationLoader';

interface Props {
  title: string;
}

function ChangeManagement({ title, children }: React.PropsWithChildren<Props>) {
  const dispatch = useAppDispatch();
  const reasons = useAppSelector(selectReasons);

  // todo get rid of this
  useEffect(() => {
    if (reasons == null) {
      dispatch(fetchReasons());
    }
  }, []);

  if (reasons == null) {
    return <AnimationLoader />;
  }

  return (
    <div className="custom-container">
      <h2 className="font-weight-700 mb-l mt-xl">{title}</h2>
      {children}
    </div>
  );
}

export default ChangeManagement;
