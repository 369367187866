import * as React from 'react';
import { capitalize } from 'lodash-es';
import { Badge } from 'stories/Badges/Badge/Badge';
import { TAssetStatuses } from 'types/AssetStatus';
import { ComponentProps } from 'react';

interface Props extends Omit<ComponentProps<typeof Badge>, 'children'> {
  status: TAssetStatuses;
}

const STATUS_BADGE_COLORS: Record<TAssetStatuses, string> = {
  [TAssetStatuses.PUBLISHED]: 'bg-green white-text',
  [TAssetStatuses.DRAFT]: 'bg-dark-60 white-text',
  [TAssetStatuses.ARCHIVED]: 'bg-light-30 light-90',
};

function AssetStatusBadge({ status, ...props }: Props) {
  return (
    <Badge
      classes={{
        value: STATUS_BADGE_COLORS[status],
      }}
      {...props}
    >
      {capitalize(status)}
    </Badge>
  );
}

export default AssetStatusBadge;
