import React, { useRef, useState } from 'react';
import { Button } from 'stories/Button/Button';
import { DropdownItem } from 'stories/Dropdown/DropdownItem/DropdownItem';
import { InlineObject } from 'stories/Objects/InlineObject/InlineObject';
import { Modal } from 'stories/Modals/Modal/Modal';
import { DialogProps, useModal } from '@/shared/lib/hooks/useModal';
import { IContractFile, IESignCoordinate } from 'bundles/Construction/types';
import { capitalize, uniqueId } from 'lodash-es';
import { cn } from '@/shared/lib/css/cn';
import {
  RenderPage,
  RenderPageProps,
  Viewer,
  Worker,
} from '@react-pdf-viewer/core';
import useOutsideClick from '@/shared/lib/hooks/useOutsideClick';
import { camelKeysToSnake } from 'bundles/UserManagement/components/roles/utils';

import styles from '@/bundles/Construction/components/eSignature/ESignPlacementModal.module.scss';
import '@react-pdf-viewer/core/lib/styles/index.css';
import customCursor from '@/bundles/Construction/components/eSignature/custom-cursor.svg';
import {
  CURSOR_DISCREPANCY,
  EXAMPLES,
  WORKFLOW,
} from 'bundles/Construction/components/eSignature/consts';
import { PDF_WORKER_URL } from 'bundles/Shared/constants';

interface PlacementProps {
  value: string;
  kind: string;
  x: number;
  y: number;
  onKindSet: (kind: string) => void;
  onRemove?: () => void;
}

interface Props extends DialogProps {
  contractFile: IContractFile;
}

const Placement = ({
  kind,
  x,
  y,
  onKindSet,
  onRemove,
  value,
}: PlacementProps) => {
  const [active, setActive] = useState(kind === 'default');
  const dropdownRef = useRef(null);
  useOutsideClick(dropdownRef, () => setActive(false));

  return (
    <div
      className="position-absolute flex items-start z-10"
      style={{ top: Number(y), left: Number(x) }}
    >
      <span
        onClick={() => setActive(!active)}
        className={cn(
          kind === 'default' ? styles.placedItemDefault : styles.placedItem,
          styles[kind],
        )}
      >
        {value}
      </span>
      {active && (
        <ul className="sre-dropdown" ref={dropdownRef}>
          {WORKFLOW.map((t) => (
            <DropdownItem
              key={t}
              active={t === kind}
              onClick={() => {
                onKindSet(t);
                setActive(false);
              }}
            >
              {capitalize(t)}
            </DropdownItem>
          ))}
          {onRemove && (
            <DropdownItem onClick={onRemove} iconName="trash">
              Remove
            </DropdownItem>
          )}
        </ul>
      )}
    </div>
  );
};

const ESignPlacementModal = ({ contractFile, onClose, onSubmit }: Props) => {
  const initialCoordinates = contractFile.esignPlacements.reduce(
    (acc, placement) => ({ ...acc, [placement.id]: placement }),
    {},
  );

  const [coordinates, setCoordinates] = useState<{
    [key: string]: IESignCoordinate;
  }>(initialCoordinates);
  const [draftCoordinate, setDraftCoordinate] =
    useState<IESignCoordinate | null>(null);
  const { confirm } = useModal();

  const handleClose = async () => {
    const res = await confirm({
      title: 'Are you sure you want to quit?',
      subtitle: <p>Closing will lead to the loss of all progress</p>,
    });
    if (res) onClose();
  };

  const handlePageClick = (e, pageIndex) => {
    if (Object.values(coordinates).length === WORKFLOW.length) return;

    const rect = e.target.getBoundingClientRect();
    const x = e.clientX - rect.left - CURSOR_DISCREPANCY;
    const y = e.clientY - rect.top - CURSOR_DISCREPANCY;

    setDraftCoordinate({
      x,
      y,
      pageIndex,
      id: uniqueId(),
      pageWidth: rect.width,
      pageHeight: rect.height,
    });
  };

  const handleSetPlacement = (coord, kind) => {
    setCoordinates({ ...coordinates, [coord.id]: { ...coord, kind } });
    setDraftCoordinate(null);
  };

  const fulfilled = (): boolean => {
    const kinds = Object.values(coordinates).map((c) => c.kind);
    return WORKFLOW.every((kind) => kinds.includes(kind));
  };

  const confirmPlacement = () => {
    onSubmit(Object.values(coordinates).map(camelKeysToSnake));
  };

  const renderPage: RenderPage = (props: RenderPageProps) => (
    <div>
      {props.canvasLayer.children}
      {Object.values(coordinates)
        .filter((c) => c.pageIndex === props.pageIndex)
        .map((coord: IESignCoordinate) => (
          <Placement
            key={coord.id}
            value={EXAMPLES[coord.kind]}
            kind={coord.kind}
            x={coord.x * (props.width / coord.pageWidth)}
            y={coord.y * (props.height / coord.pageHeight)}
            onKindSet={(kind) => handleSetPlacement(coord, kind)}
            onRemove={() => {
              const dup = { ...coordinates };
              delete dup[coord.id];
              setCoordinates(dup);
            }}
          />
        ))}
      {draftCoordinate && (
        <Placement
          value="Select Object..."
          kind="default"
          x={draftCoordinate.x}
          y={draftCoordinate.y}
          onKindSet={(kind) => handleSetPlacement(draftCoordinate, kind)}
        />
      )}
      <div
        onClick={(e) => handlePageClick(e, props.pageIndex)}
        className="position-absolute z-[9]"
        style={{
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          cursor: fulfilled() ? 'auto' : `url(${customCursor}), auto`,
        }}
      />
      {props.annotationLayer.children}
      {props.textLayer.children}
    </div>
  );

  return (
    <Modal toggle={handleClose} size="huge" header="Document E-sign Placement">
      <Worker workerUrl={PDF_WORKER_URL}>
        <Viewer
          fileUrl={contractFile.fileUrl || contractFile.url}
          renderPage={renderPage}
        />
      </Worker>
      <div className={styles.panel}>
        <span className="white inline-semibold">
          Please define & locate the objects
        </span>
        {Object.values(coordinates).map((coord) => (
          <InlineObject
            key={coord.id}
            className="z-[3] ml-s"
            object={capitalize(coord.kind)}
            iconName="check"
          />
        ))}
        <Button
          variant="success"
          disabled={!fulfilled()}
          onClick={confirmPlacement}
          className="ml-auto"
        >
          Confirm
        </Button>
      </div>
    </Modal>
  );
};

export default ESignPlacementModal;
