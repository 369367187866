import { useLoadedCapitalPreferredReturn } from '@/bundles/REturn/hooks/useLoadedCapitalPreferredReturn';
import { useLoadedInvestmentObject } from '@/bundles/REturn/hooks/useLoadedInvestmentObject';
import { useCapitalUnitPricesByInvestmentClass } from '@/bundles/REturn/hooks/useLoadedUnitPrices';
import VerticalSeparator from '@/bundles/Shared/components/VerticalSeparator/VerticalSeparator';
import {
  useDeleteApiCapitalUnitPricesByIdMutation,
  usePostApiCapitalPreferredReturnsMutation,
  usePostApiCapitalUnitPricesMutation,
  usePutApiCapitalPreferredReturnsByIdMutation,
  usePutApiCapitalUnitPricesByIdMutation,
} from '@/entities/return/api/capitalInvestmentObjectsEnhancedApi';
import { InvestmentObjectUnitPrice } from '@/entities/return/api/capitalInvestmentObjectsGeneratedApi';
import { CapitalInvestmentCard } from '@/entities/return/ui/CapitalInvestmentCard';
import useBoolean from '@/shared/lib/hooks/useBoolean';
import { useModal } from '@/shared/lib/hooks/useModal';
import { mustFind } from '@/lib/typeHelpers';
import { CurrencyFormatter } from '@/stories/ValueFormatters/CurrencyFormatter';
import { PercentFormatter } from '@/stories/ValueFormatters/PercentFormatter';
import { EditUnitPricesModal } from '@/widgets/return/ui/EditUnitPricesModal';
import { CardTitle } from '@/widgets/return/ui/OwnershipsCards';
import { PreferredReturnSettingsModal } from '@/widgets/return/ui/PreferredReturnSettingsModal';
import { UnitPricesSettingsModal } from '@/widgets/return/ui/UnitPricesSettingsModal';
import dayjs from 'dayjs';
import { capitalize } from 'lodash-es';
import { convertCentsToDollars } from '@/shared/lib/converters';
import { DEFAULT_NUMBER_FALLBACK } from '@/shared/lib/formatting/fallbacks';
const UNIT_PRICES_LEN_BREAKPOINT = 3;

export function CapitalInvestmentClassSettingsCard({
  investmentClass,
}: {
  investmentClass: InvestmentObjectUnitPrice['investmentClass'];
}) {
  const {
    value: editInvestmentClassSettingsModalOpened,
    setTrue: openInvestmentClassSettingsModal,
    setFalse: closeInvestmentClassSettingsModal,
  } = useBoolean(false);

  const { data: capitalInvestmentObject } = useLoadedInvestmentObject();

  const { openModal } = useModal();

  const {
    data: preferredReturnData,
    isFetching: isPreferredReturnDataFetching,
  } = useLoadedCapitalPreferredReturn();

  const {
    capitalUnitPricesPartitionByInvestmentClass,
    isFetching: isUnitPricesDataFetching,
  } = useCapitalUnitPricesByInvestmentClass({
    investmentClass,
  });

  const [unitPrices] = capitalUnitPricesPartitionByInvestmentClass;

  const preferredReturnByInvestmentClass =
    investmentClass === 'a'
      ? preferredReturnData?.classAPercent
      : preferredReturnData?.classBPercent;

  const unitPricesLen = unitPrices.length;

  const [createUnitPrices, { isLoading: isCreatingUnitPrice }] =
    usePostApiCapitalUnitPricesMutation();
  const [updateUnitPrice, { isLoading: isUpdatingUnitPrice }] =
    usePutApiCapitalUnitPricesByIdMutation();
  const [deleteUnitPrice, { isLoading: isDeletingUnitPrice }] =
    useDeleteApiCapitalUnitPricesByIdMutation();
  const [createPreferredReturns, { isLoading: isCreatingPreferredReturn }] =
    usePostApiCapitalPreferredReturnsMutation();
  const [updatePreferredReturns, { isLoading: isUpdadingPreferredReturn }] =
    usePutApiCapitalPreferredReturnsByIdMutation();

  const isPreferredReturnFetching =
    isPreferredReturnDataFetching ||
    isCreatingPreferredReturn ||
    isUpdadingPreferredReturn;

  const isUnitPricesLoading =
    isUnitPricesDataFetching ||
    isCreatingUnitPrice ||
    isUpdatingUnitPrice ||
    isDeletingUnitPrice;

  const handleAddAdjustment = async () => {
    const lastUnitPrice = unitPrices.at(-1)!;
    const minDate = dayjs(lastUnitPrice.date).add(1, 'day').toDate();

    const res = await openModal(UnitPricesSettingsModal, {
      label: 'Adjustment',
      dateInputLabel: 'Date',
      priceInputLabel: 'Price',
      minDate,
      submitButtonLabel: 'Add Adjustment',
    });
    if (res === undefined) return;

    createUnitPrices({
      body: {
        investment_class: investmentClass,
        date: res?.period,
        price_cents: res?.priceCents,
        investment_object_id: capitalInvestmentObject.id,
      },
    });
  };

  const handlePreferredReturnSettings = async () => {
    const res = await openModal(PreferredReturnSettingsModal, {
      capitalInvestmentObject,
      investmentClass,
      preferredReturnPercent: preferredReturnByInvestmentClass,
    });

    if (!res) return;

    const classPercentBody =
      investmentClass === 'a'
        ? {
            class_a_percent: res.preferredReturnPercent,
            class_b_percent: preferredReturnData?.classBPercent ?? 0,
          }
        : {
            class_b_percent: res.preferredReturnPercent,
            class_a_percent: preferredReturnData?.classAPercent ?? 0,
          };

    if (preferredReturnData?.id != null) {
      await updatePreferredReturns({
        id: preferredReturnData.id,
        body: classPercentBody,
      });
    } else {
      await createPreferredReturns({
        body: {
          ...classPercentBody,
          investment_object_id: capitalInvestmentObject.id,
        },
      });
    }
  };

  const handleUnitPriceUpdate = async (
    unitPriceId: (typeof unitPrices)[number]['id'],
    prevUnitPriceId: (typeof unitPrices)[number]['id'] | null,
  ) => {
    const unitPrice = mustFind(unitPrices, (up) => up.id === unitPriceId);
    const prevUnitPrice = unitPrices.find((up) => up.id === prevUnitPriceId);

    const res = await openModal(UnitPricesSettingsModal, {
      label: 'Adjustment',
      dateInputLabel: 'Date',
      priceInputLabel: 'Price',
      submitButtonLabel: 'Update Adjustment',
      minDate: prevUnitPrice
        ? dayjs(prevUnitPrice.date).add(1, 'day').toDate()
        : null,
      initialDate: dayjs(unitPrice.date).toDate(),
      initialPrice: convertCentsToDollars(unitPrice.priceCents),
    });

    if (res === undefined) return;

    await updateUnitPrice({
      id: unitPriceId,
      body: {
        date: res.period,
        price_cents: res.priceCents,
        investment_class: investmentClass,
      },
    });
  };

  const handleAddUnitPrice = async () => {
    const res = await openModal(UnitPricesSettingsModal, {});
    if (res === undefined) return;
    createUnitPrices({
      body: {
        investment_class: investmentClass,
        date: res?.period,
        price_cents: res?.priceCents,
        investment_object_id: capitalInvestmentObject.id,
      },
    });
  };

  return (
    <>
      {editInvestmentClassSettingsModalOpened && (
        <EditUnitPricesModal
          investmentClass={investmentClass}
          preferredReturn={preferredReturnByInvestmentClass ?? 0}
          onPreferredReturnUpdate={handlePreferredReturnSettings}
          onClose={closeInvestmentClassSettingsModal}
          capitalInvestmentObject={capitalInvestmentObject}
          onDeleteUnitPrice={async (id) => {
            await deleteUnitPrice({ id });
          }}
          disabledButtons={isUnitPricesLoading || isPreferredReturnFetching}
          onAdjustments={handleAddAdjustment}
          onUpdateUnitPrice={handleUnitPriceUpdate}
          onAddUnitPrice={handleAddUnitPrice}
        />
      )}
      <CapitalInvestmentCard
        title={`Class ${capitalize(investmentClass)}`}
        onSettings={openInvestmentClassSettingsModal}
        isLoading={isUnitPricesLoading || isPreferredReturnFetching}
        fireEnabled={preferredReturnByInvestmentClass == null}
      >
        <div className="flex gap-7">
          <CardTitle
            title="Preferred Return"
            value={
              <PercentFormatter
                toLocalStringOptions={{
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 2,
                }}
                value={preferredReturnByInvestmentClass ?? 0}
                classes={{
                  allParts: 'header5-regular',
                  value:
                    preferredReturnData == null
                      ? 'text-neutral-500'
                      : 'text-neutral-900',
                }}
              />
            }
          />
          <VerticalSeparator className="-mx-1 w-6 bg-neutral-150" />
          {unitPricesLen === 0 && (
            <CardTitle
              title="Base Unit Price"
              zeroValue
              value={DEFAULT_NUMBER_FALLBACK}
            />
          )}
          {unitPrices[0] && (
            <CardTitle
              title="Base Unit Price"
              value={
                <CurrencyFormatter
                  value={convertCentsToDollars(unitPrices[0].priceCents)}
                />
              }
            />
          )}

          {unitPrices[1] && (
            <CardTitle
              title="First Adjustment"
              value={
                <CurrencyFormatter
                  value={convertCentsToDollars(unitPrices[1].priceCents)}
                />
              }
            />
          )}
          {unitPrices.length >= UNIT_PRICES_LEN_BREAKPOINT &&
            unitPrices.at(-1) != null && (
              <CardTitle
                title={dayjs(unitPrices.at(-1)!.date).format('MMM DD, YYYY')}
                value={
                  <CurrencyFormatter
                    value={convertCentsToDollars(unitPrices.at(-1)!.priceCents)}
                  />
                }
              />
            )}
        </div>
      </CapitalInvestmentCard>
    </>
  );
}
