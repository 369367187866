import {
  get1YearAgoDate,
  get90DaysAgoDate,
  todayDate,
} from '@/shared/lib/date';

import { formatToDateStringForRequest } from '@/shared/lib/converters';
import { ReportDashboardType } from '@/bundles/Shared/entities/dashboard/model/types/types';
import { GlobalLeaseTableWidgetState } from '@/bundles/Shared/widgets/dashboard/widgets/globalLeaseTable/widget';
import { GlobalLeaseTableWidgetDto } from '@/shared/api/dashboardsGeneratedApi';

export const getInitialStateForGlobalLeaseTableWidget = (
  _?: GlobalLeaseTableWidgetDto,
  context?: {
    date: string;
    dashboardType: ReportDashboardType;
  },
): GlobalLeaseTableWidgetState => {
  const isReportBuilderTemplate =
    context?.dashboardType === ReportDashboardType.REPORT_BUILDER_TEMPLATE;

  if (isReportBuilderTemplate) {
    return {
      page: 1,
      perPage: -1,
      dateFrom: formatToDateStringForRequest(get1YearAgoDate()),
      dateTo: formatToDateStringForRequest(todayDate()),
      useLtoThresholds: true,
    };
  }

  return {
    page: 1,
    perPage: 10,
    dateFrom: formatToDateStringForRequest(get90DaysAgoDate()),
    dateTo: formatToDateStringForRequest(todayDate()),
    useLtoThresholds: true,
  };
};
