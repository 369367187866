import FilterButton from '@/bundles/Shared/components/Filters/buttons/filterButton/FilterButton';
import type { FilterOption } from '@/entities/report/reportBuilder/config';
import { Popover } from '@/stories/Popover/Popover';
import { Select } from '@/stories/FormControls/Select/Select';
import { DEFAULT_DROPDOWN_OFFSET } from '@/stories/Popover/Popover';

export const ReportBuilderTemplateEagleEyeFilterDropdown = ({
  options,
  selected,
  onChange,
  onReset,
  disabled,
}: {
  options: FilterOption[];
  selected: FilterOption | null;
  onChange: (id: FilterOption['value']) => void;
  onReset: () => void;
  disabled?: boolean;
}) => (
  <Popover
    hiddenArrow
    trigger="click"
    placement="bottom-end"
    offset={DEFAULT_DROPDOWN_OFFSET}
    classes={{
      spanContainer: 'max-w-min',
    }}
    className="min-w-[428px] overflow-hidden rounded-[8px] p-0"
    hideOnExpandedAreaClick
    template={
      <ul className="max-h-[296px] overflow-y-auto">
        {options.map((o) => (
          <Select.Option
            key={o.value}
            selected={o.value === selected?.value}
            onClick={() => onChange(o.value)}
            label={o.label}
          />
        ))}
      </ul>
    }
  >
    {({ triggered }) => (
      <FilterButton
        size="s"
        classes={{
          container: 'h-auto max-w-full',
        }}
        className="px-2"
        iconName="filtersFilled"
        clicked={triggered}
        filtered={selected != null}
        disabled={disabled}
        onClose={() => {
          onReset();
        }}
      >
        <p className="text-ellipsis">{selected?.name ?? 'Filters'}</p>
      </FilterButton>
    )}
  </Popover>
);
