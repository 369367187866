import {
  useGetApiCoreReportBuilderTemplatesQuery,
  type ReportBuilderTemplate,
} from '@/entities/report/reportBuilder';
import { AvailableTemplatesModal } from '@/entities/report/reportBuilder/ui/AvailableTemplateModal';
import type { DialogProps } from '@/shared/lib/hooks/useModal';

export const ReportBuilderTemplatesModal = (
  props: DialogProps<ReportBuilderTemplate>,
) => {
  const { data, isFetching } = useGetApiCoreReportBuilderTemplatesQuery();

  return (
    <AvailableTemplatesModal
      {...props}
      isFetching={isFetching}
      reportBuilderTemplates={data ?? []}
    />
  );
};
