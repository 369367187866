import { navigate, RouteComponentProps } from '@reach/router';
import { useEffect } from 'react';
import { Button } from 'stories/Button/Button';
import useMediaQuery, {
  LARGE_WIDTH_MEDIA_QUERY,
} from '@/shared/lib/hooks/useMediaQuery';
import { SymmetreLogo } from 'bundles/Shared/components/LeftSidebar/Logo/Logo';

const Svg = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_623_17234)">
      <path
        d="M7.07193 25.9265C12.279 31.1335 20.7211 31.1335 25.9281 25.9265L7.07193 7.07031C1.86494 12.2773 1.86494 20.7195 7.07193 25.9265Z"
        stroke="#848D91"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M23.0996 23.0985L26.6132 8.44141"
        stroke="#848D91"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.5 16.4998L25.2811 7.71875"
        stroke="#848D91"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.90039 9.90033L24.5575 6.38672"
        stroke="#848D91"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M29.8333 5.83464C29.8333 7.30737 28.6394 8.5013 27.1667 8.5013C26.9769 8.5013 26.7918 8.4815 26.6133 8.44377C26.0989 8.33524 25.6393 8.0785 25.2811 7.72023C24.9228 7.36203 24.6661 6.90237 24.5575 6.38805C24.5198 6.20951 24.5 6.02439 24.5 5.83464C24.5 4.36188 25.6939 3.16797 27.1667 3.16797C28.6394 3.16797 29.8333 4.36188 29.8333 5.83464Z"
        stroke="#848D91"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
  </svg>
);

export const UseDesktopVersionScreen = (
  props: RouteComponentProps & {
    onViewDesktopVersionClick: () => void;
  },
) => {
  const isLargeWidth = useMediaQuery(LARGE_WIDTH_MEDIA_QUERY);

  // useEffect(() => {
  //   if (!isLargeWidth) return;

  //   navigate('/');
  // }, []);

  return (
    <div className="relative flex h-screen flex-col items-center justify-center gap-8 bg-light px-12">
      <div className="absolute top-[24px] h-[32px] w-[110px]">
        <SymmetreLogo variant="large" />
      </div>
      <div className="flex h-[56px] w-[56px] items-center justify-center rounded-2xl bg-light-10">
        <Svg />
      </div>
      <div className="flex flex-col gap-4 text-center">
        <p className="header6-bold text-neutral-900">
          Sorry, this section is not available on mobile devices
        </p>
        <p className="inline-regular text-neutral-600">
          Please switch to the desktop version for better experience
        </p>
      </div>
      <Button onClick={props.onViewDesktopVersionClick} fluid variant="success">
        View Desktop Version
      </Button>
    </div>
  );
};
