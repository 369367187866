import { cn } from '@/shared/lib/css/cn';
import { useIsTextTruncated } from '@/shared/lib/hooks/useIsTextTruncated';
import { Draggable, Droppable } from '@hello-pangea/dnd';
import styles from 'bundles/Shared/components/layouts/dashboard/WidgetLayout.module.scss';
import React, { useRef } from 'react';
import { Checkbox } from 'stories/Checkbox/Checkbox';
import { Icon } from 'stories/Icon/Icon';
import { Tooltip } from 'stories/Tooltip/Tooltip';

export const WidgetFlyPanel = ({
  children,
  className,
}: React.PropsWithChildren<PropsWithClassName>) => (
  <div
    className={cn(
      'absolute right-6 top-0 flex w-[300px] flex-col gap-2 rounded-[8px] bg-neutral-000 p-2 shadow-sm',
      className,
    )}
  >
    {children}
  </div>
);

WidgetFlyPanel.Header = ({
  icon,
  title,
  label,
}: {
  icon: React.ReactNode;
  label: string;
  title: string;
}) => {
  const ref = useRef(null);
  const isTextTruncated = useIsTextTruncated(ref, label.concat(' ', title));

  return (
    <div className="flex gap-1">
      {icon}
      <span
        ref={ref}
        className="secondary-semibold text-ellipsis whitespace-nowrap"
      >
        <span className="text-neutral-550">{label} </span>
        <span className="text-neutral-800">{title}</span>
      </span>
      <Tooltip reference={ref} disabled={!isTextTruncated} mainText={title} />
    </div>
  );
};

export const WidgetSettingsListLayout = ({
  children,
}: React.PropsWithChildren) => (
  <div className="flex flex-col gap-2">{children}</div>
);

WidgetSettingsListLayout.Header = ({
  children,
  label,
  checked,
  onChange,
}: React.PropsWithChildren<{
  label: string;
}> &
  Pick<
    React.InputHTMLAttributes<HTMLInputElement>,
    'onChange' | 'checked'
  >) => (
  <div className="flex items-center justify-between">
    <div className="flex items-center gap-2 pl-2">
      {checked != null && onChange != null && (
        <Checkbox onChange={onChange} checked={checked} />
      )}
      <span className="secondary-semibold text-neutral-550">{label}</span>
    </div>
    {children}
  </div>
);

function WidgetSettingsListLayoutList(
  { className, ...props }: JSX.IntrinsicElements['div'],
  ref: React.Ref<HTMLDivElement>,
) {
  return (
    <div ref={ref} className={cn(styles.sortableList, className)} {...props} />
  );
}

WidgetSettingsListLayout.List = React.forwardRef(WidgetSettingsListLayoutList);

const WidgetSettingsListLayoutListItem = React.forwardRef<
  HTMLDivElement,
  JSX.IntrinsicElements['div'] & {
    listIndex?: number;
  } & Pick<React.InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'checked'>
>(
  (
    { className, children, listIndex, onChange, checked, ...props },
    ref: React.Ref<HTMLDivElement>,
  ) => {
    return (
      <div
        ref={ref}
        className={cn(styles.sortableItem, 'group/item min-h-8', className)}
        {...props}
      >
        {checked != null && onChange != null && (
          <Checkbox onChange={onChange} checked={checked} />
        )}
        {listIndex != null && (
          <span
            className={cn(
              styles.sortableItem__listIndex,
              'inline-regular min-w-[16px] shrink-0 whitespace-nowrap text-center text-neutral-400',
            )}
          >
            {listIndex + 1}
          </span>
        )}
        {children}
      </div>
    );
  },
);

const ListItemText = ({
  children,
  className,
}: React.PropsWithChildren<PropsWithClassName>) => {
  const ref = useRef<HTMLSpanElement>(null);
  const isOverflow = useIsTextTruncated(ref, children as string);
  return (
    <>
      <span
        ref={ref}
        className={cn(
          'inline-semibold cursor-pointer overflow-hidden text-ellipsis whitespace-nowrap text-nowrap text-neutral-700',
          className,
        )}
      >
        {children}
      </span>
      <Tooltip
        reference={ref}
        disabled={!isOverflow}
        mainText={children as string}
      />
    </>
  );
};

const ActionsPanel = ({
  children,
  className,
}: React.PropsWithChildren<PropsWithClassName>) => (
  <div className={cn('hidden gap-2 group-hover/item:flex', className)}>
    {children}
  </div>
);

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
WidgetSettingsListLayoutListItem.ActionsPanel = ActionsPanel;
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
WidgetSettingsListLayoutListItem.Text = ListItemText;

WidgetSettingsListLayout.ListItem =
  WidgetSettingsListLayoutListItem as typeof WidgetSettingsListLayoutListItem & {
    ActionsPanel: typeof ActionsPanel;
    Text: typeof ListItemText;
  };

WidgetSettingsListLayout.SortableList = ({
  children,
  droppableId,
  ...props
}: Omit<React.ComponentProps<typeof WidgetSettingsListLayoutList>, 'ref'> & {
  droppableId?: string;
}) => {
  return (
    <Droppable type="group" droppableId={droppableId ?? 'droppable'}>
      {(provided, snapshot) => (
        <WidgetSettingsListLayout.List
          {...provided.droppableProps}
          className={cn(
            snapshot.isDraggingOver && styles.sortableList_activeDragging,
          )}
          ref={provided.innerRef}
          {...props}
        >
          {children}
          {provided.placeholder}
        </WidgetSettingsListLayout.List>
      )}
    </Droppable>
  );
};

const WidgetSettingsListLayoutDragHandle = ({
  ...props
}: Omit<React.ComponentProps<typeof Icon>, 'iconName'>) => (
  <Icon
    className={styles.sortableItem__dragHandle}
    iconName="move"
    {...props}
  />
);

WidgetSettingsListLayout.DragHandle = WidgetSettingsListLayoutDragHandle;

WidgetSettingsListLayout.SortableListItem = ({
  index,
  draggableId,
  children,
  ...props
}: {
  index: number;
  draggableId: string;
} & Omit<
  React.ComponentProps<typeof WidgetSettingsListLayoutListItem>,
  'ref'
> & {
    checked?: boolean;
    onChange?: (id: string) => void;
  }) => (
  <Draggable draggableId={draggableId} index={index}>
    {(provided, _snapshot) => (
      <WidgetSettingsListLayout.ListItem
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...props}
      >
        <WidgetSettingsListLayoutDragHandle {...provided.dragHandleProps} />
        {children}
      </WidgetSettingsListLayout.ListItem>
    )}
  </Draggable>
);

WidgetSettingsListLayout.ListDragHandle = () => (
  <Icon className={styles.sortableItem__dragHandle} iconName="move" />
);
