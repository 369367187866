import { FinancialTableWidgetStateDate } from '@/bundles/Shared/widgets/dashboard/widgets/common/financialTable/state/FinancialTableWidgetStateDate';
import { useContextChangeHandler } from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/reportBuilder';
import { ReportingWidgetIdContext } from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/reportBuilderTemplateContext';
import { ReportBuilderTemplateWidgetContext } from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/reportBuilderTemplateWidgetContext';
import { selectWidgetById } from '@/entities/report/reportBuilder';
import { BaseWidgetConfigCard } from '@/entities/report/reportBuilder/ui/BaseWidgetConfigCard';
import { useAppSelector } from '@/shared/lib/hooks/redux';
import { widgetHasData } from 'bundles/Shared/widgets/dashboard/widgets/financialTableSingeDate/lib';
import { WidgetConfigCardProps } from 'bundles/Shared/widgets/dashboard/widgets/model';

export const KpiTableSingleDateWidgetConfigCard = (
  props: Omit<WidgetConfigCardProps, 'hasData'>,
) => {
  const widget = useAppSelector(selectWidgetById(props.widgetSection.id));
  const handleContextChange = useContextChangeHandler({
    widgetId: widget?.id ?? '',
    widgetGroupId: widget?.group.id ?? '',
  });

  return (
    <BaseWidgetConfigCard
      labelSlot={
        widget && (
          // TODO either adapt this component or create KpiTableSingleDateWidgetStateDate
          <FinancialTableWidgetStateDate
            closeOnMonthUpdate
            state={widget.context}
            onStateChange={handleContextChange}
          />
        )
      }
      hasData={widgetHasData(props.data)}
      {...props}
    >
      <ReportBuilderTemplateWidgetContext.Provider value={widget}>
        <ReportingWidgetIdContext.Provider value={widget.id}>
          {/* TODO resolve where to put widgetObjectsConfig when Design is ready */}
          {props.widgetObjectsConfig}
        </ReportingWidgetIdContext.Provider>
      </ReportBuilderTemplateWidgetContext.Provider>
    </BaseWidgetConfigCard>
  );
};
