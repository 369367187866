import { SymmetreConfig } from '@/types/global';
import { capitalize, get } from 'lodash-es';

export enum TProductNames {
  ASSET_PORTAL = 'asset_portal',
  FUND_PORTAL = 'fund_portal',
  CHANGE_MANAGEMENT = 'change_management',
  OPERATIONAL_BUDGET = 'operational_budget',
  DEVELOPMENT_BUDGET = 'development_budget',
  PIPELINE = 'pipeline',
  REPORT = 'report',
  REPORT_BUILDER = 'report_builder',
  USER_MANAGEMENT = 'user_management',
  RETURN = 'return',
}
export type ProductNameFlat = FlattenEnum<TProductNames>;
export const ASSET_PORTAL_PRODUCT_NAME = TProductNames.ASSET_PORTAL;
export const FUND_PORTAL_PRODUCT_NAME = TProductNames.FUND_PORTAL;
export const PIPELINE_PRODUCT_NAME = TProductNames.PIPELINE;
export const REPORT_PRODUCT_NAME = TProductNames.REPORT;
export const CHANGE_MANAGEMENT_PRODUCT_NAME = TProductNames.CHANGE_MANAGEMENT;
export const USER_MANAGEMENT_PRODUCT_NAME = TProductNames.USER_MANAGEMENT;

type PermissionType = 'manage' | 'view' | 'configure';
export type PermissionTypeKeyName = `can_${PermissionType}`;
// Helper Methods

function currentUserPermissions() {
  return window.symmetreConfig.currentUser.productsPermissions;
}

function currentUserCan(
  permissionLevel: PermissionType,
  productName: ProductNameFlat,
) {
  const permissions = currentUserPermissions();

  return Boolean(get(permissions, `${productName}.can_${permissionLevel}`));
}

function viewableFunds() {
  return window.symmetreConfig.currentUser.viewableFunds;
}

function manageableFunds() {
  return window.symmetreConfig.currentUser.manageableFunds;
}

function viewableAssets() {
  return window.symmetreConfig.currentUser.viewableAssets;
}

function manageableAssets() {
  return window.symmetreConfig.currentUser.manageableAssets;
}

// Main Methods

// eslint-disable-next-line max-params
export function currentUserAllowedTo(
  permission: PermissionType,
  productName: ProductNameFlat,
  objectType?: 'Asset' | 'Fund',
  objectId?: number,
) {
  const hasPermission = currentUserCan(permission, productName);

  // object type might come as lower case and capitalized
  // but access levels have only capitalized keys
  const objectClass = capitalize(objectType);
  let allowedForObject: boolean;

  if (objectClass && objectId) {
    if (permission === 'manage') {
      if (objectClass === 'Fund') {
        allowedForObject = manageableFunds().includes(objectId);
      } else if (objectClass === 'Asset') {
        allowedForObject = manageableAssets().includes(objectId);
      } else {
        allowedForObject = false;
      }
    } else if (permission === 'view') {
      if (objectClass === 'Fund') {
        allowedForObject = viewableFunds().includes(objectId);
      } else if (objectClass === 'Asset') {
        allowedForObject = viewableAssets().includes(objectId);
      } else {
        allowedForObject = false;
      }
    } else {
      allowedForObject = false;
    }
  } else {
    allowedForObject = true;
  }

  return hasPermission && allowedForObject;
}

export function permissionsFor(
  role: Pick<SymmetreConfig['currentUser'], 'productsPermissions'>,
  productName: TProductNames,
  ability: PermissionTypeKeyName,
) {
  return Boolean(get(role.productsPermissions, `${productName}.${ability}`));
}

export function currentUserIsExternal() {
  const { external } = window.symmetreConfig.currentUser;

  return external;
}

export function currentUserIsInternal() {
  const { category } = window.symmetreConfig.currentUser.role!;

  return category === 'internal';
}

export function currentUserIsSreAdmin() {
  const { name } = window.symmetreConfig.currentUser.role!;

  return name === 'symmetRE Admin';
}

export function currentUserIsSuperAdmin() {
  const { name } = window.symmetreConfig.currentUser.role!;

  return name === 'Super Admin';
}

export function currentUserIsAdvisor() {
  const { name } = window.symmetreConfig.currentUser.role!;

  return name === 'Advisor';
}

export function userRoleIsManager(
  userRole: Pick<SymmetreConfig['currentUser'], 'productsPermissions'>,
) {
  return (
    permissionsFor(userRole, ASSET_PORTAL_PRODUCT_NAME, 'can_manage') ||
    permissionsFor(userRole, FUND_PORTAL_PRODUCT_NAME, 'can_manage') ||
    permissionsFor(userRole, CHANGE_MANAGEMENT_PRODUCT_NAME, 'can_manage') ||
    permissionsFor(userRole, REPORT_PRODUCT_NAME, 'can_manage')
  );
}
