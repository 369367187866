import React from 'react';
import { Dropdown } from 'stories/Dropdown/Dropdown';
import { DropdownItem } from 'stories/Dropdown/DropdownItem/DropdownItem';
import { Button } from 'stories/Button/Button';

interface Props {
  onClickPDF: () => void;
  onClickExcel: () => void;
  disabled?: boolean;
}

const ExportDropdownButton = ({
  onClickPDF,
  onClickExcel,
  disabled,
}: Props) => (
  <Dropdown
    items={
      <>
        <DropdownItem onClick={onClickPDF}>As PDF</DropdownItem>
        <DropdownItem onClick={onClickExcel}>As EXCEL</DropdownItem>
      </>
    }
  >
    <Button
      variant="secondary"
      iconPosition="right"
      iconName="arrowBottom"
      disabled={disabled}
    >
      Export
    </Button>
  </Dropdown>
);

export default ExportDropdownButton;
