import React, { useEffect, useMemo } from 'react';
import { directUpload, presignFiles } from 'lib/uploadFiles';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import { navigate, useParams } from '@reach/router';
import {
  fetchCompanies,
  selectCompanies,
} from 'bundles/Construction/reducers/ReconcileSlice';
import { useCreateInvoiceMutation } from 'bundles/Construction/api/invoices';
import { getItemsInvoiceBlock } from '@/bundles/DrawPackage/InvoicesFlow/Invoices/initData';
import SingleForm from 'bundles/Shared/components/GroupForm/SingleForm';
import {
  IInvoiceDetails,
  selectInvoiceDetails,
  setInvoiceDetails,
} from 'bundles/DrawPackage/slices/ReconcileInvoicesSlice';
import { generatePath, ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import { formatToDateStringForRequest } from '@/shared/lib/converters';

const InvoiceCreateBlock = () => {
  const params = useParams();
  const companies = useAppSelector(selectCompanies);

  const dispatch = useAppDispatch();

  const invoiceDetails = useAppSelector(selectInvoiceDetails);
  const setFormData = (details: IInvoiceDetails) => {
    dispatch(setInvoiceDetails(details));
  };

  const legalEntity = useAppSelector(
    (state) => state.developmentBudget.legalEntity,
  );

  const [createInvoice] = useCreateInvoiceMutation();

  useEffect(() => {
    dispatch(fetchCompanies());
  }, []);

  const initGroupFormItems = useMemo(
    () => getItemsInvoiceBlock(invoiceDetails, companies, null),
    [invoiceDetails, setFormData],
  );

  const submit = async () => {
    let presignedFiles = [];
    if (invoiceDetails.document) {
      presignedFiles = await presignFiles([invoiceDetails.document]);
    }
    await Promise.all(presignedFiles.map((file) => directUpload(file)));
    const presFile = presignedFiles[0];

    const res = await createInvoice({
      legalEntityCode: params.legalEntityCode || legalEntity.code,
      body: {
        ...invoiceDetails,
        vendor_name: invoiceDetails.vendor_name.value,
        date: formatToDateStringForRequest(invoiceDetails.date),
        document: {
          file: {
            key: presFile.signedData.fields.key,
            filename: presFile.file.name,
            content_type: presFile.file.type,
            size: presFile.file.size,
            title: '',
          },
        },
      },
    });
    if (res.error) return false;
    navigate(
      generatePath(ROUTES_ROOT.reconcile.development.legalEntity.invoices, {
        legalEntityCode: params.legalEntityCode,
      }),
    );
  };

  return (
    <div>
      <SingleForm
        singleFormItems={initGroupFormItems}
        onSubmit={submit}
        formData={invoiceDetails}
        setFormData={setFormData}
        buttonConfig={{
          withoutButtons: true,
        }}
      />
    </div>
  );
};

export default InvoiceCreateBlock;
