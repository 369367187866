import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Field } from 'stories/Field/Field';
import { Icon } from 'stories/Icon/Icon';
import { Input } from 'stories/FormControls/Inputs/Input/Input';
import {
  SearchData,
  SortableTreeWithoutDndContext as SortableTree,
} from 'react-sortable-tree';
import { cn } from '@/shared/lib/css/cn';
import treeStyles from '@/bundles/Shared/components/GroupForm/FormItems/tree-select/TreeSelect.module.scss';
import { ISettingsForm } from '@/bundles/Shared/components/GroupForm/types/types';
import { IFormItemTreeSelect } from '@/bundles/Shared/components/GroupForm/types/typesFormItem';
import TreeNodeRenderer from 'stories/TreeFolderNavigation/TreeNodeRenderer';
import TreeSelectItem from '@/bundles/Shared/components/GroupForm/FormItems/tree-select/TreeSelectItem';
import { includesInLowerCase } from '@/shared/lib/listHelpers';
import { debounce, isEmpty } from 'lodash-es';
import { treeDFM } from 'lib/treeHelpers';

interface Props extends IFormItemTreeSelect, ISettingsForm {
  styles?: IFormItemTreeSelect['styles'] & { treeWrapper?: string };
  value: string | number;
}

const FormTreeSelect: FC<Props> = ({
  title,
  placeholder,
  value,
  formData,
  setFormData,
  fieldName,
  required,
  hint,
  loading,
  options,
  styles,
  shortList = true,
  disabled = false,
  treeProps,
}) => {
  const [treeData, setTreeData] = useState(options);
  const [prevOptions, setPrevOptions] = useState(options);
  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [treeSearchQuery, setTreeSearchQuery] = useState('');

  const handleTreeSearchQuery = debounce((text: string) => {
    setTreeSearchQuery(text);
  }, 500);

  const flatOptions = useMemo(
    () =>
      treeDFM(options, (o) => ({
        value: o.id,
        label: o.title,
      })),
    [options],
  );

  if (prevOptions !== options) {
    setPrevOptions(options);
    setTreeData(options);
  }

  const syncSearchWithValue = () => {
    setSearchValue(
      flatOptions.find((item) => item.value === value)?.label ?? '',
    );
    setTreeSearchQuery('');
  };

  const searchHandle = (e) => {
    setSearchValue(e.target.value);
    handleTreeSearchQuery(e.target.value);
  };

  const handleClose = () => {
    setOpen(false);
    syncSearchWithValue();
  };

  useEffect(() => {
    if (value) {
      syncSearchWithValue();
    }
  }, [options, value]);

  const onNodeClick = (elem) => {
    setOpen(false);
    setFormData?.({
      ...formData,
      [fieldName]: elem.id,
    });
  };

  const generateNodeProps = useCallback(
    (data) => ({
      ...data,
      selectable:
        !data.node.isCategory &&
        !data.node.isSubCategory &&
        !data.node.children.length,
      isSelected: value === data.node.id,
      onNodeClick,
    }),
    [value, onNodeClick],
  );

  const customSearchMethod = (searchData: SearchData<any>) => {
    const { node, searchQuery } = searchData;

    if (isEmpty(searchQuery)) {
      return true;
    }

    if (treeProps?.searchMethod == null) {
      return includesInLowerCase(node.title, searchQuery);
    }

    return treeProps.searchMethod(searchData);
  };

  return (
    <div
      className={cn(
        'form-item',
        'form-item__select',
        styles?.wrapper,
        styles?.nomb && 'form-item_nomb',
      )}
      style={{
        position: 'relative',
      }}
    >
      <Field
        labelText={title}
        required={required}
        noLabel={!title}
        hint={hint}
        labelFor={fieldName}
      >
        <div className={cn(treeStyles.treeWrapper, styles?.treeWrapper)}>
          <Input
            id={fieldName}
            data-testid={fieldName}
            placeholder={placeholder}
            value={searchValue}
            onChange={searchHandle}
            onClick={() => {
              setOpen(true);
              setSearchValue('');
              setTreeSearchQuery('');
            }}
            disabled={loading || disabled}
            className={styles?.input}
            classes={{
              backLayer: styles?.inputBackLayer,
            }}
            size={styles?.size ?? 'l'}
            type={styles?.type ?? 'text'}
          />
          <div className="form-item-date__icon">
            <Icon iconName="arrowBottom" />
          </div>
        </div>
        <div className={cn('form-item__list-select_wrapper', styles?.input)}>
          <div
            className={cn(
              'text-dark-60 ',
              !open && 'form-item__list-select-tree_hide',
            )}
          >
            {open && (
              <div
                onClick={handleClose}
                className="form-item__list-select_layer"
              />
            )}
            <div className={cn('form-item__list-select')}>
              <div
                className={cn(
                  'form-item__list-select_scrollbar pr-2',
                  shortList && 'tree-short-list',
                )}
              >
                <SortableTree
                  className="w-full"
                  canDrag={false}
                  isVirtualized={false}
                  generateNodeProps={generateNodeProps}
                  theme={{
                    nodeContentRenderer: TreeSelectItem,
                    treeNodeRenderer: TreeNodeRenderer,
                  }}
                  rowHeight="auto"
                  treeData={treeData}
                  searchQuery={treeSearchQuery}
                  searchMethod={customSearchMethod}
                  searchFocusOffset={0}
                  onChange={(newTreeData) => setTreeData(newTreeData)}
                  onlyExpandSearchedNodes
                  {...treeProps}
                />
              </div>
            </div>
          </div>
        </div>
      </Field>
    </div>
  );
};

export default FormTreeSelect;
