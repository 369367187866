import { useUpdateConnection } from '@/features/report/connections/crud/lib';
import { useConnectionById } from '@/bundles/Shared/entities/connection/api';
import { useModal } from '@/shared/lib/hooks/useModal';
import { useParams } from '@reach/router';
import SecondaryDropdown from 'bundles/Shared/components/SecondaryDropdown';
import { ConnectionCrudModal } from 'bundles/Shared/features/connection/crud';
import { IconButton } from 'stories/IconButton/IconButton';

const ConnectionActions = () => {
  const params = useParams();

  const { data: connection } = useConnectionById(params.connectionId);

  const [updateConnection] = useUpdateConnection();

  const { openModal } = useModal();

  const isWorking = connection?.item.connectionStatus !== 'deactivated';

  const ACTIONS = [
    {
      label: 'Edit',
      id: 'edit',
    },
    {
      label: isWorking ? 'Deactivate' : 'Activate',
      id: 'activate',
    },
  ];

  const handleEdit = () => {
    openModal(ConnectionCrudModal, {
      connectionId: params.connectionId,
    });
  };

  const handleDeactivate = () => {
    updateConnection({
      id: params.connectionId,
      body: {
        active: !isWorking,
      },
    });
  };

  return (
    <SecondaryDropdown
      items={ACTIONS}
      onSelectedChange={(key) => {
        if (key === 'edit') handleEdit();
        if (key === 'activate') handleDeactivate();
      }}
      renderButton={() => (
        <IconButton iconName="moreVertical" size="l" variant="secondary" />
      )}
    />
  );
};

export default ConnectionActions;
