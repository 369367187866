import React from 'react';
import { cn } from '@/shared/lib/css/cn';
import { FireIcon } from '@/bundles/FireStation/components/FireIcon/FireIcon';
import '@/bundles/FireStation/components/NavFireButton/NavFireButton.css';

interface Props {
  priority: 'none' | 'low' | 'medium' | 'high';
  count: number;
  fireStationPage?: boolean;
  className?: string;
}

export const NavFireButton = ({
  priority,
  fireStationPage,
  count,
  className,
  ...props
}: Props) => (
  <div
    className={cn(
      'sre-nav-fire secondary-semibold',
      priority && `sre-nav-fire_${priority}`,
      className,
      {
        _onPage: fireStationPage,
      },
    )}
    {...props}
  >
    <FireIcon priority={priority} />
    {count}
  </div>
);

export default NavFireButton;
