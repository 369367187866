import { cn } from '@/shared/lib/css/cn';

type Props = React.PropsWithChildren<PropsWithClassName>;

export const DashboardBoardNavigationContainer = (props: Props) => (
  <div
    className={cn(
      'flex items-center rounded-xl bg-neutral-200',
      props.className,
    )}
  >
    {props.children}
  </div>
);
