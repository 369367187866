import React, { useState } from 'react';
import {
  filterComplainedMessages,
  filterUnsubscribedMessages,
} from '@/bundles/Settings/components/EmailSettings/utils/utils';
import CampaignCardEmailList from '@/bundles/Settings/components/EmailSettings/EmailCampaign/CampaignEmailCard/CampaignCardEmailList';
import EmailListSwitches from '@/bundles/Settings/components/EmailSettings/EmailCampaign/CampaignEmailCard/EmailListSwitches';

const DeliveredEmailsList = ({ deliveredEmails, totalRecipientsCount }) => {
  const switches = ['All'];

  const deliveredUnsubscribedMessages =
    filterUnsubscribedMessages(deliveredEmails);
  const deliveredComplainedMessages = filterComplainedMessages(deliveredEmails);
  const deliveredMessages = deliveredEmails.filter((em) =>
    em.events.every(
      (e) => e.kind !== 'complained' && e.kind !== 'unsubscribed',
    ),
  );

  const tabs = {
    Unsubscribed: deliveredUnsubscribedMessages,
    Complained: deliveredComplainedMessages,
    Delivered: deliveredMessages,
  };

  if (deliveredUnsubscribedMessages.length > 0) switches.push('Unsubscribed');
  if (deliveredComplainedMessages.length > 0) switches.push('Complained');

  const [selectedSwitch, setSelectedSwitch] = useState(switches[0]);

  return (
    <div>
      {switches.length > 1 && (
        <EmailListSwitches
          switchNames={switches}
          isActive={(switchName) => selectedSwitch === switchName}
          onClick={(switchName) => setSelectedSwitch(switchName)}
        />
      )}
      {selectedSwitch === 'All' ? (
        <div>
          {Object.entries(tabs).map(([name, messages]) => (
            <CampaignCardEmailList
              emailMessages={messages}
              title={name}
              totalRecipientsCount={totalRecipientsCount}
            />
          ))}
        </div>
      ) : (
        <CampaignCardEmailList
          emailMessages={tabs[selectedSwitch]}
          title={selectedSwitch}
          totalRecipientsCount={totalRecipientsCount}
        />
      )}
    </div>
  );
};

export default DeliveredEmailsList;
