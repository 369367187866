import { capitalize } from 'lodash-es';
import pluralize from 'pluralize';
import { TAccessItemType } from '@/bundles/Shared/components/Permissions/types';

const ACCESS_ITEM_TYPE_LABELS = {
  entity: 'investment Entity',
} as const;

export const resolveAccessItemLabel = (itemsType: TAccessItemType, count = 2) =>
  pluralize(capitalize(ACCESS_ITEM_TYPE_LABELS[itemsType] ?? itemsType), count);
