import { formatDate } from '@/shared/lib/formatting/dates';
import { Field } from '@/stories/Field/Field';
import { Input } from '@/stories/FormControls/Inputs/Input/Input';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import NumberFormat, {
  NumberFormatValues,
  SourceInfo,
} from 'react-number-format';
import {
  CalendarRangeProps,
  DateRangeType,
} from 'stories/Calendar/Range/CalendarRange';

interface Props
  extends Pick<CalendarRangeProps, 'disableFuture' | 'maxDate' | 'minDate'> {
  value?: DateRangeType | undefined;
  onChange?: (value: [Date, Date]) => void;
}

const DATE_FORMAT = 'MMDDYYYY';
const getValue = (value: DateRangeType | undefined) => {
  if (!value) {
    return ['', ''];
  }
  return [
    value[0] ? formatDate(value[0], DATE_FORMAT) : '',
    value[1] ? formatDate(value[1], DATE_FORMAT) : '',
  ];
};

export function DateRangeInput({
  value,
  disableFuture,
  maxDate,
  minDate,
  ...props
}: Props) {
  const [internalValue, setInternalValue] = useState(getValue(value));
  const [dateFrom, dateTo] = internalValue;

  useEffect(() => {
    setInternalValue(getValue(value));
  }, [value]);

  const handleValueChange =
    (range: 'from' | 'to') =>
    (values: NumberFormatValues, sourceInfo: SourceInfo) => {
      const finalRange = [
        range === 'from' ? values.value : dateFrom,
        range === 'to' ? values.value : dateTo,
      ];
      setInternalValue(finalRange);
      if (sourceInfo.source !== 'event') {
        return;
      }
      const [from, to] = finalRange;

      const fromDate = dayjs(from, DATE_FORMAT).local();
      const toDate = dayjs(to, DATE_FORMAT).local();

      const date = dayjs(values.value, DATE_FORMAT).local();
      const index = range === 'from' ? 0 : 1;
      const isDateValid = date.isValid();
      const isFromValid =
        range !== 'from' ||
        (to && date.isBefore(toDate) && (!minDate || date.isAfter(minDate)));
      const isToValid =
        range !== 'to' ||
        (from &&
          date.isAfter(fromDate) &&
          (!maxDate || date.isBefore(maxDate)));

      if (!isDateValid || !isFromValid || !isToValid) {
        return;
      }

      const newValue = value
        ? [...value]
        : finalRange.map((d) => dayjs(d, DATE_FORMAT).local().toDate());
      newValue[index] = date.toDate();
      props.onChange?.(newValue);
    };

  const handleBlur = () => {
    setInternalValue(getValue(value));
  };

  return (
    <div className="flex flex-wrap gap-2">
      <Field labelFor="from" labelText="From">
        <NumberFormat
          id="from"
          customInput={Input}
          mask="_"
          value={dateFrom}
          placeholder="MM-DD-YYYY"
          size="s"
          onValueChange={handleValueChange('from')}
          format="##-##-####"
          onBlur={handleBlur}
        />
      </Field>
      <Field labelFor="to" labelText="To">
        <NumberFormat
          id="to"
          size="s"
          customInput={Input}
          mask="_"
          value={dateTo}
          format="##-##-####"
          placeholder="MM-DD-YYYY"
          onValueChange={handleValueChange('to')}
          onBlur={handleBlur}
        />
      </Field>
    </div>
  );
}
