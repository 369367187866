import FileExtensionIcon from 'bundles/Shared/components/fileExtensionIcon/FileExtensionIcon';
import { ISharedDocument } from 'types/SharedDocument';

export function FileInfoTitle({ extension, title }: ISharedDocument) {
  return (
    <div className="flex items-center gap-2">
      <FileExtensionIcon extension={extension} />
      <span className="inline-semibold text-dark-60">{title}</span>
    </div>
  );
}
