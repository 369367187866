import { IConnection } from '@/bundles/Shared/entities/connection/model';
import { ExtractParams, ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import { useParams } from '@reach/router';
import { SharedTextArea } from 'bundles/Shared/components/GroupForm/FormItems/new/SharedTextArea';
import { hasMemo } from 'bundles/Shared/entities/connection/lib';
import { formatDate } from '@/shared/lib/formatting/dates';
import { useState } from 'react';
import { Button } from 'stories/Button/Button';
import { IconButton } from 'stories/IconButton/IconButton';
import { convertToMilliseconds } from '@/shared/lib/converters';
import { useUpdateConnection } from '@/features/report/connections/crud/lib';
import { useConnectionById } from '@/bundles/Shared/entities/connection/api';

const { fullPath: ROUTE } = ROUTES_ROOT.settings.report.connections.connection;

export const ConnectionMemoCard = () => {
  const [showTextArea, setShowTextArea] = useState<boolean>(false);
  const params = useParams<ExtractParams<typeof ROUTE>>();

  const { data: connectionData } = useConnectionById(
    Number(params.connectionId),
  );

  const connection = connectionData?.item as IConnection | undefined;

  const [valueTextArea, setValueTextArea] = useState<string>(
    connection?.memo?.message ?? '',
  );

  const [updateConnection, { isLoading }] = useUpdateConnection();

  const handleMemo = async () => {
    const res = await updateConnection({
      id: Number(params.connectionId),
      body: {
        memo_attributes: {
          message: valueTextArea,
        },
      },
    });

    if ('data' in res && res.data) {
      setShowTextArea(false);
    }
  };

  return (
    <div className="flex flex-col gap-4 rounded-2xl bg-white p-4">
      <div className="flex items-center justify-between">
        <div className="font-semibold text-neutral-800">Memo</div>
        {showTextArea && (
          <Button
            variant="secondary"
            size="xs"
            onClick={handleMemo}
            disabled={isLoading}
          >
            {hasMemo(connection) ? 'Edit Memo' : 'Add Memo'}
          </Button>
        )}
        {!showTextArea && (
          <IconButton
            iconName={hasMemo(connection) ? 'editAlt' : 'addSmall'}
            onClick={() => setShowTextArea(true)}
          />
        )}
      </div>
      {showTextArea && (
        <SharedTextArea<string>
          defaultValue={connection?.memo?.message}
          value={valueTextArea}
          onChange={(e) => setValueTextArea(e.target.value)}
          disabled={isLoading}
        />
      )}
      {!showTextArea && hasMemo(connection) && (
        <div className="flex flex-col gap-2">
          <div className="inline-regular text-neutral-900">
            {connection?.memo?.message}
          </div>
          <div className="secondary-regular">
            {connection?.memo?.authorName} •{' '}
            {formatDate(
              convertToMilliseconds(connection?.memo?.writtenAt as UnixTime),
              'MMMM DD, YYYY h:mm A',
            )}
          </div>
        </div>
      )}
      {!showTextArea && !hasMemo(connection) && (
        <div className="inline-regular">No notes</div>
      )}
    </div>
  );
};
