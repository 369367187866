import { emptySplitApi as api } from '@/app/stores/api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiReportBuilderEagleEyeReportsByEagleEyeReportIdPdf: build.query<
      GetApiReportBuilderEagleEyeReportsByEagleEyeReportIdPdfApiResponse,
      GetApiReportBuilderEagleEyeReportsByEagleEyeReportIdPdfApiArg
    >({
      query: (queryArg) => ({
        url: `/api/report_builder/eagle_eye_reports/${queryArg.eagleEyeReportId}/pdf`,
        params: { render_pdf_host: queryArg.renderPdfHost },
      }),
    }),
    postApiReportBuilderEagleEyeReportsByEagleEyeReportIdPdf: build.mutation<
      PostApiReportBuilderEagleEyeReportsByEagleEyeReportIdPdfApiResponse,
      PostApiReportBuilderEagleEyeReportsByEagleEyeReportIdPdfApiArg
    >({
      query: (queryArg) => ({
        url: `/api/report_builder/eagle_eye_reports/${queryArg.eagleEyeReportId}/pdf`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    getApiReportBuilderEagleEyeReportsByEagleEyeReportIdWidgetSectionsAndWidgetSectionIdSnapshot:
      build.query<
        GetApiReportBuilderEagleEyeReportsByEagleEyeReportIdWidgetSectionsAndWidgetSectionIdSnapshotApiResponse,
        GetApiReportBuilderEagleEyeReportsByEagleEyeReportIdWidgetSectionsAndWidgetSectionIdSnapshotApiArg
      >({
        query: (queryArg) => ({
          url: `/api/report_builder/eagle_eye_reports/${queryArg.eagleEyeReportId}/widget_sections/${queryArg.widgetSectionId}/snapshot`,
        }),
      }),
    postApiReportBuilderEagleEyeReportsByEagleEyeReportIdWidgetSectionsAndWidgetSectionIdSnapshot:
      build.query<
        PostApiReportBuilderEagleEyeReportsByEagleEyeReportIdWidgetSectionsAndWidgetSectionIdSnapshotApiResponse,
        PostApiReportBuilderEagleEyeReportsByEagleEyeReportIdWidgetSectionsAndWidgetSectionIdSnapshotApiArg
      >({
        query: (queryArg) => ({
          url: `/api/report_builder/eagle_eye_reports/${queryArg.eagleEyeReportId}/widget_sections/${queryArg.widgetSectionId}/snapshot`,
          method: 'POST',
          body: queryArg.body,
        }),
      }),
    putApiReportBuilderEagleEyeReportsByEagleEyeReportIdWidgetSectionsAndWidgetSectionIdSnapshot:
      build.mutation<
        PutApiReportBuilderEagleEyeReportsByEagleEyeReportIdWidgetSectionsAndWidgetSectionIdSnapshotApiResponse,
        PutApiReportBuilderEagleEyeReportsByEagleEyeReportIdWidgetSectionsAndWidgetSectionIdSnapshotApiArg
      >({
        query: (queryArg) => ({
          url: `/api/report_builder/eagle_eye_reports/${queryArg.eagleEyeReportId}/widget_sections/${queryArg.widgetSectionId}/snapshot`,
          method: 'PUT',
          body: queryArg.body,
        }),
      }),
    getApiReportBuilderEagleEyeReportsById: build.query<
      GetApiReportBuilderEagleEyeReportsByIdApiResponse,
      GetApiReportBuilderEagleEyeReportsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/report_builder/eagle_eye_reports/${queryArg.id}`,
      }),
    }),
    putApiReportBuilderEagleEyeReportsById: build.mutation<
      PutApiReportBuilderEagleEyeReportsByIdApiResponse,
      PutApiReportBuilderEagleEyeReportsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/report_builder/eagle_eye_reports/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    deleteApiReportBuilderEagleEyeReportsById: build.mutation<
      DeleteApiReportBuilderEagleEyeReportsByIdApiResponse,
      DeleteApiReportBuilderEagleEyeReportsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/report_builder/eagle_eye_reports/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    getApiReportBuilderReportGroupsByReportGroupIdEagleEyeReports: build.query<
      GetApiReportBuilderReportGroupsByReportGroupIdEagleEyeReportsApiResponse,
      GetApiReportBuilderReportGroupsByReportGroupIdEagleEyeReportsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/report_builder/report_groups/${queryArg.reportGroupId}/eagle_eye_reports`,
        params: {
          query: queryArg.query,
          status: queryArg.status,
          creator_ids: queryArg.creatorIds,
          last_editor_ids: queryArg.lastEditorIds,
          created_at: queryArg.createdAt,
          updated_at: queryArg.updatedAt,
          report_date: queryArg.reportDate,
          page: queryArg.page,
          per_page: queryArg.perPage,
          sort: queryArg.sort,
        },
      }),
    }),
    postApiReportBuilderReportGroupsByReportGroupIdEagleEyeReports:
      build.mutation<
        PostApiReportBuilderReportGroupsByReportGroupIdEagleEyeReportsApiResponse,
        PostApiReportBuilderReportGroupsByReportGroupIdEagleEyeReportsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/report_builder/report_groups/${queryArg.reportGroupId}/eagle_eye_reports`,
          method: 'POST',
          body: queryArg.body,
        }),
      }),
    getApiReportBuilderReportGroups: build.query<
      GetApiReportBuilderReportGroupsApiResponse,
      GetApiReportBuilderReportGroupsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/report_builder/report_groups`,
        params: {
          query: queryArg.query,
          template_ids: queryArg.templateIds,
          creator_ids: queryArg.creatorIds,
          last_editor_ids: queryArg.lastEditorIds,
          created_at: queryArg.createdAt,
          updated_at: queryArg.updatedAt,
          sort: queryArg.sort,
        },
      }),
    }),
    postApiReportBuilderReportGroups: build.mutation<
      PostApiReportBuilderReportGroupsApiResponse,
      PostApiReportBuilderReportGroupsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/report_builder/report_groups`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    putApiReportBuilderReportGroupsById: build.mutation<
      PutApiReportBuilderReportGroupsByIdApiResponse,
      PutApiReportBuilderReportGroupsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/report_builder/report_groups/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    deleteApiReportBuilderReportGroupsById: build.mutation<
      DeleteApiReportBuilderReportGroupsByIdApiResponse,
      DeleteApiReportBuilderReportGroupsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/report_builder/report_groups/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    getApiReportBuilderTemplates: build.query<
      GetApiReportBuilderTemplatesApiResponse,
      GetApiReportBuilderTemplatesApiArg
    >({
      query: () => ({ url: `/api/report_builder/templates` }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as reportingGeneratedApi };
export type GetApiReportBuilderEagleEyeReportsByEagleEyeReportIdPdfApiResponse =
  /** status 200 success */ string;
export type GetApiReportBuilderEagleEyeReportsByEagleEyeReportIdPdfApiArg = {
  eagleEyeReportId: string;
  /** The host to render the PDF, available only in staging */
  renderPdfHost?: string;
};
export type PostApiReportBuilderEagleEyeReportsByEagleEyeReportIdPdfApiResponse =
  /** status 200 success */ PreviewReportDto;
export type PostApiReportBuilderEagleEyeReportsByEagleEyeReportIdPdfApiArg = {
  eagleEyeReportId: string;
  body: {
    render_pdf_host?: string | null;
  };
};
export type GetApiReportBuilderEagleEyeReportsByEagleEyeReportIdWidgetSectionsAndWidgetSectionIdSnapshotApiResponse =
  /** status 200 success */ {
    id?: string;
    widget?:
      | KpiCardWidgetDto
      | TextAreaWidgetDto
      | MediaWidgetDto
      | KpiTableWidgetDto
      | KpiTableSingleDateWidgetDto
      | XyChartSingleKpiWidgetDto
      | GlobalLeaseTableWidgetDto;
  };
export type GetApiReportBuilderEagleEyeReportsByEagleEyeReportIdWidgetSectionsAndWidgetSectionIdSnapshotApiArg =
  {
    eagleEyeReportId: string;
    widgetSectionId: string;
  };
export type PostApiReportBuilderEagleEyeReportsByEagleEyeReportIdWidgetSectionsAndWidgetSectionIdSnapshotApiResponse =
  /** status 200 success */ {
    id?: string;
    widget?:
      | KpiCardWidgetDto
      | TextAreaWidgetDto
      | MediaWidgetDto
      | KpiTableWidgetDto
      | KpiTableSingleDateWidgetDto
      | XyChartSingleKpiWidgetDto
      | GlobalLeaseTableWidgetDto;
  };
export type PostApiReportBuilderEagleEyeReportsByEagleEyeReportIdWidgetSectionsAndWidgetSectionIdSnapshotApiArg =
  {
    eagleEyeReportId: string;
    widgetSectionId: string;
    body: {
      context: {
        date?: string;
        date_from?: string;
        date_to?: string;
        granularity?: string;
        grouping_type?: 'assets' | 'segments';
        period?: ReportScoreboardPeriodDto;
        asset_ids?: number[];
        segment_ids?: number[];
        excluded_legal_entity_ids?: string[];
      };
    };
  };
export type PutApiReportBuilderEagleEyeReportsByEagleEyeReportIdWidgetSectionsAndWidgetSectionIdSnapshotApiResponse =
  /** status 200 success */ {
    id?: string;
    widget?: TextAreaWidgetDto | MediaWidgetDto;
  };
export type PutApiReportBuilderEagleEyeReportsByEagleEyeReportIdWidgetSectionsAndWidgetSectionIdSnapshotApiArg =
  {
    eagleEyeReportId: string;
    widgetSectionId: string;
    body: {
      snapshot_data:
        | {
            text: string;
          }
        | {
            items: {
              id: string;
              type: 'shared_file';
            }[];
            display_options?: {
              object_fit?: 'contain' | 'cover' | 'fill' | 'none' | 'scale-down';
              object_position?: 'bottom' | 'center' | 'left' | 'right' | 'top';
              height?: number;
              is_full_height?: boolean;
              width_percentage?: number;
            };
          };
    };
  };
export type GetApiReportBuilderEagleEyeReportsByIdApiResponse =
  /** status 200 success */ EagleEyeReportDto;
export type GetApiReportBuilderEagleEyeReportsByIdApiArg = {
  id: string;
};
export type PutApiReportBuilderEagleEyeReportsByIdApiResponse =
  /** status 200 success */ PreviewReportDto;
export type PutApiReportBuilderEagleEyeReportsByIdApiArg = {
  id: string;
  body: {
    name?: string;
    subtitle?: string;
    date?: string;
    date_format?: ('day' | 'quarter') | null;
    asset_ids?: number[];
    segment_ids?: number[];
  };
};
export type DeleteApiReportBuilderEagleEyeReportsByIdApiResponse = unknown;
export type DeleteApiReportBuilderEagleEyeReportsByIdApiArg = {
  id: string;
};
export type GetApiReportBuilderReportGroupsByReportGroupIdEagleEyeReportsApiResponse =
  /** status 200 success */ {
    items: PreviewReportDto[];
    meta: {
      totalSize: number;
      totalPages: number;
      creators: {
        id: string;
        name: string;
      }[];
      editors: {
        id: string;
        name: string;
      }[];
      reportGroup: ReportGroupDto;
    };
  };
export type GetApiReportBuilderReportGroupsByReportGroupIdEagleEyeReportsApiArg =
  {
    reportGroupId: string;
    query?: string;
    status?: 'generated' | 'draft';
    creatorIds?: {
      ''?: string[];
    };
    lastEditorIds?: {
      ''?: string[];
    };
    createdAt?: string;
    updatedAt?: string;
    reportDate?: string;
    page?: number;
    perPage?: number;
    sort?: {
      field?: 'name' | 'report_date' | 'created_at' | 'updated_at';
      order?: 'asc' | 'desc';
    };
  };
export type PostApiReportBuilderReportGroupsByReportGroupIdEagleEyeReportsApiResponse =
  /** status 200 success */ PreviewReportDto;
export type PostApiReportBuilderReportGroupsByReportGroupIdEagleEyeReportsApiArg =
  {
    reportGroupId: string;
    body: {
      name: string;
      subtitle: string;
      date: string;
      date_format: ('day' | 'quarter') | null;
    };
  };
export type GetApiReportBuilderReportGroupsApiResponse =
  /** status 200 success */ {
    items?: ReportGroupDto[];
    meta?: {
      totalSize?: number;
      templates?: {
        id: string;
        name: string;
        kind: 'eagle_eye' | 'object_level';
      }[];
      creators?: {
        id: string;
        name: string;
      }[];
      editors?: {
        id: string;
        name: string;
      }[];
    };
  };
export type GetApiReportBuilderReportGroupsApiArg = {
  query?: string;
  templateIds?: {
    ''?: string[];
  };
  creatorIds?: {
    ''?: string[];
  };
  lastEditorIds?: {
    ''?: string[];
  };
  createdAt?: string;
  updatedAt?: string;
  sort?: {
    field?:
      | 'name'
      | 'template_name'
      | 'reports_count'
      | 'created_at'
      | 'updated_at';
    order?: 'asc' | 'desc';
  };
};
export type PostApiReportBuilderReportGroupsApiResponse =
  /** status 200 success */ ReportGroupDto;
export type PostApiReportBuilderReportGroupsApiArg = {
  body: {
    name: string;
    description?: string;
    template_kind: 'eagle_eye' | 'object_level';
    template_id: string;
  };
};
export type PutApiReportBuilderReportGroupsByIdApiResponse =
  /** status 200 success */ ReportGroupDto;
export type PutApiReportBuilderReportGroupsByIdApiArg = {
  id: string;
  body: {
    name?: string;
    description?: string;
  };
};
export type DeleteApiReportBuilderReportGroupsByIdApiResponse = unknown;
export type DeleteApiReportBuilderReportGroupsByIdApiArg = {
  id: string;
};
export type GetApiReportBuilderTemplatesApiResponse =
  /** status 200 success */ TemplateDto[];
export type GetApiReportBuilderTemplatesApiArg = void;
export type PreviewReportDto = {
  id: string;
  name: string;
  subtitle: string | null;
  status: 'draft' | 'generated';
  date: string;
  dateFormat: ('day' | 'quarter') | null;
  createdAt: number;
  updatedAt: number;
  createdBy: {
    id?: string;
    name?: string;
  };
  updatedBy: {
    id?: string;
    name?: string;
  };
  fileUrl: string | null;
};
export type KpiCardWidgetDto = {
  type: 'kpi_card';
  kpis: {
    key: number;
    label: string;
    value: number | null;
    history: {
      dateFrom?: string | null;
      dateTo?: string | null;
      value?: number;
    }[];
    previousPeriodValue: number | null;
    historySize: number;
    type: 'numeric' | 'text';
  }[];
};
export type TextAreaWidgetDto = {
  type: 'text_area';
  text: string;
};
export type MediaWidgetDto = {
  type: 'media';
  items: {
    id: string;
    type: 'shared_file';
    dataUri: string;
    caption: {
      text: string;
      alignment: 'left' | 'center' | 'right';
    } | null;
  }[];
  displayOptions: {
    objectFit: ('contain' | 'cover' | 'fill' | 'none' | 'scale-down') | null;
    objectPosition: ('bottom' | 'center' | 'left' | 'right' | 'top') | null;
    height: number | null;
    isFullHeight: boolean | null;
    widthPercentage: number | null;
  };
};
export type KpiTableWidgetDto = {
  type: 'kpi_table';
  columns: {
    type?: 'numeric' | 'text';
    key: number;
    label: string;
    historySize: number;
    dateFrom: string;
    dateTo: string;
  }[];
  data?: {
    type?: 'segment' | 'asset' | 'legal_entity' | 'total';
    label?: string;
    path?: string;
    history?: object;
    previousPeriodValues?: object;
  }[];
};
export type KpiTableSingleDateWidgetDto = {
  type: 'kpi_table_single_date';
  columns: {
    key: number;
    label: string;
    dateFrom: string;
    dateTo: string;
  }[];
  data?: {
    type?: 'segment' | 'asset' | 'legal_entity' | 'total';
    label?: string;
    path?: string;
    history?: object;
    previousPeriodValues?: object;
  }[];
};
export type XyChartSingleKpiWidgetDto = {
  type: 'xy_chart_single_kpi';
  items: {
    values: {
      [key: string]: number;
    }[];
    dateFrom: string;
    dateTo: string;
  }[];
  data: {
    objectName: string;
    objectType: 'segment' | 'asset';
    objectId: string;
    kpiKey: string;
    kpiValue: number | null;
    dateFrom: string;
    dateTo: string;
  }[];
};
export type ValueDisplayOptionsDto = {
  type:
    | 'number'
    | 'percentage'
    | 'currency'
    | 'metric'
    | 'sqft'
    | 'bps'
    | 'date'
    | 'text';
  precision: 0 | 1 | 2;
  kiloFormatting: boolean;
  hideCommaSeparator: boolean;
  dateFormat?:
    | 'YYYY'
    | 'MM'
    | 'MMM'
    | 'MM/DD/YYYY'
    | 'MMMM YYYY'
    | 'MMMM D, YYYY'
    | 'MMM D, YYYY'
    | 'Relative';
};
export type VizConfigAutoColumnDto = {
  header?: {
    title?: string;
  };
};
export type GlobalLeaseTableWidgetDto = {
  type: 'global_lease_table';
  columns: {
    key: number;
    vizConfigKey?: string;
    label: string;
    valueDisplayOptions?: ValueDisplayOptionsDto;
  }[];
  rows: {
    key: number;
    unit_id?: string;
    filtered_reasons?: string[];
    values: {
      key: number;
      value: (string | number | boolean) | null;
    }[];
  }[];
  filterOptions: {
    column?: string;
    options?: string[];
  }[];
  config: {
    viz_type?: string;
    viz_config?: {
      columns: {
        col_id: string;
        key:
          | 'tradeout'
          | 'prior_rent'
          | 'current_rent'
          | 'lease_type'
          | 'lease_status'
          | 'unit_type'
          | 'asset_name'
          | 'unit_label'
          | 'unit_sqft'
          | 'lease_signed_date'
          | 'lease_start'
          | 'lease_end'
          | 'resident_name'
          | 'term_months'
          | 'previous_lease_resident_name'
          | 'previous_lease_type'
          | 'previous_lease_start_date'
          | 'previous_lease_end_date'
          | 'previous_lease_term_months'
          | 'filtered_tradeout';
        order: number;
        group_id?: string | null;
        column_group_show?: string;
        initial_sort?: string;
        hidden?: boolean;
        hide_dates?: boolean;
        value_display_options?: ValueDisplayOptionsDto;
        comparison?: {
          rule?:
            | 'negative_red_positive_green'
            | 'negative_red'
            | 'negative_green_positive_red';
          hide_negative_values?: boolean;
        };
        font_weight?: string;
        header?: {
          hide_title?: boolean;
          hide_subtitle?: boolean;
        };
        min_width?: number;
        max_width?: number;
        align?: 'left' | 'center' | 'right';
      }[];
      column_groups: {
        group_id: string;
        header_name: string;
        icon: string | null;
        background: string;
        border_color: string;
        order: number;
      }[];
      auto_column?: VizConfigAutoColumnDto;
      header_background?: string;
    };
  };
  totalSize: number;
};
export type ReportScoreboardPeriodDto =
  | {
      type: 'day';
      date: string;
    }
  | {
      type: 'week';
      date: string;
    }
  | {
      type: 'month';
      date: string;
    }
  | {
      type: 'quarter';
      date: string;
    }
  | {
      type: 'year';
      date: string;
    }
  | {
      type: 'dtd';
      from_date: string;
      to_date: string;
    }
  | {
      type: 'mtm';
      from_date: string;
      to_date: string;
    }
  | {
      type: 'mtd';
      last_date: string;
    }
  | {
      type: 'qtd';
      last_date: string;
    }
  | {
      type: 'ytd';
      last_date: string;
    }
  | {
      type: 'attd';
      last_date: string;
    }
  | {
      type: 'itd';
      last_date: string;
    }
  | {
      type: 'trailing_days';
      date: string;
      count: number;
    }
  | {
      type: 'trailing_months';
      date: string;
      count: number;
    }
  | {
      type: 'trailing_quarters';
      date: string;
      count: number;
    }
  | {
      type: 'trailing_years';
      date: string;
      count: number;
    }
  | {
      type: 't3';
      date: string;
    }
  | {
      type: 't6';
      date: string;
    }
  | {
      type: 't12';
      date: string;
    };
export type EagleEyeReportLegalEntityDto = {
  id: string;
  name: string;
  code: string;
};
export type EagleEyeReportSegmentDto = {
  id: number;
  title: string;
  legalEntities: EagleEyeReportLegalEntityDto[];
};
export type EagleEyeReportAssetDto = {
  id: number;
  name: string;
  slug: string;
  pictureUrl: string | null;
  legalEntities: EagleEyeReportLegalEntityDto[];
};
export type EagleEyeReportExcludedLegalEntityDto = {
  id: string;
  name: string;
  classification: string | null;
};
export type ValueDisplayOptionsConfigDto = {
  type:
    | 'number'
    | 'percentage'
    | 'currency'
    | 'metric'
    | 'sqft'
    | 'bps'
    | 'date'
    | 'text';
  precision: 0 | 1 | 2;
  kilo_formatting?: boolean;
  hide_comma_separator?: boolean;
  date_format?:
    | 'YYYY'
    | 'MM'
    | 'MMM'
    | 'MM/DD/YYYY'
    | 'MMMM YYYY'
    | 'MMMM D, YYYY'
    | 'MMM D, YYYY'
    | 'Relative';
};
export type CellRendererSparklineConfigDto = {
  type: 'sparkline';
  params?: object;
};
export type CellRendererTextConfigDto = {
  type: 'text';
  params: {
    variant: 'text_default' | 'text_current_value' | 'text_full_history';
  };
};
export type CellRendererConfigDto =
  | CellRendererSparklineConfigDto
  | CellRendererTextConfigDto;
export type TrailingPeriodTypeDto = {
  type: 'day' | 'month' | 'quarter';
  amount: number;
};
export type EagleEyeDefaultOptionsObjectDto =
  | {
      id: number;
      type: 'segment';
    }
  | {
      id: number;
      type: 'asset';
    }
  | {
      id: string;
      type: 'excluded_legal_entity';
    };
export type ReportKpiCardWidgetConfigDto = {
  viz_type: 'card';
  viz_config: {
    kpis: {
      key?: string;
      value_display_options?: ValueDisplayOptionsConfigDto;
      cell_renderer?: CellRendererConfigDto;
    }[];
  };
  kpis: {
    key: number;
    label: string;
    expression: string;
    history_size: number;
    type?: 'numeric' | 'text';
    period_type:
      | (
          | 'day'
          | 'week'
          | 'month'
          | 'quarter'
          | 'qtd'
          | 'year'
          | 'ytd'
          | 't3'
          | 't6'
          | 't12'
        )
      | TrailingPeriodTypeDto;
    period_shift?: {
      days?: number;
      weeks?: number;
      months?: number;
      quarters?: number;
      years?: number;
    };
  }[];
  default_options?: {
    objects?: EagleEyeDefaultOptionsObjectDto[] | null;
  };
};
export type WidgetColumnComparisonDto = {
  to_col_id?: string;
  rule?:
    | 'negative_red_positive_green'
    | 'negative_green_positive_red'
    | 'negative_red';
  hide_negative_values?: boolean;
};
export type AdjustmentExpressionDto = {
  name:
    | 'annualized'
    | 'op_budget_annualized'
    | 'uw_budget_annualized'
    | 'financial_t1a'
    | 'financial_t3a'
    | 'financial_beginning_balance'
    | 'financial_ending_balance'
    | 'financial_debit'
    | 'financial_credit'
    | 'op_variance'
    | 'op_variance_percent'
    | 'op_variance_flipped'
    | 'op_variance_flipped_percent'
    | 'op_budget'
    | 'uw_variance'
    | 'uw_variance_percent'
    | 'uw_variance_flipped'
    | 'uw_variance_flipped_percent'
    | 'uw_budget'
    | 'period_to_period_variance'
    | 'period_to_period_variance_percent'
    | 'period_to_period_variance_flipped'
    | 'period_to_period_variance_flipped_percent'
    | 'year_to_year_variance'
    | 'year_to_year_variance_percent'
    | 'year_to_year_variance_flipped'
    | 'year_to_year_variance_flipped_percent';
  source_overrides?: {
    source: 'budget' | 'underwriting_budget';
    override_to: string;
  }[];
};
export type ReportBuilderKpiTableWidgetConfigDto = {
  viz_type?: string;
  viz_config?: {
    column_groups?: {
      group_id: string;
      header_name: string;
      icon: string | null;
      background: string;
      border_color: string;
      order: number;
      child_can_override_period?: boolean;
    }[];
    columns?: {
      col_id: string;
      key: string;
      group_id: string | null;
      column_group_show: string;
      hide_dates?: boolean;
      hidden?: boolean;
      header?: {
        hide_title?: boolean;
        hide_subtitle?: boolean;
      };
      cell_renderer?: CellRendererConfigDto;
      min_width?: number;
      max_width?: number;
      align?: 'left' | 'center' | 'right';
      value_display_options: ValueDisplayOptionsConfigDto;
      order: number;
      comparison?: WidgetColumnComparisonDto;
      border_color?: string | null;
      font_weight?: string | null;
    }[];
  };
  hide_total_row?: boolean;
  columns: {
    key: number;
    label: string;
    expression: string;
    history_size: number;
    type?: 'numeric' | 'text';
    period_type:
      | ('day' | 'week' | 'month' | 'mtd' | 'quarter' | 'qtd' | 'year' | 'ytd')
      | TrailingPeriodTypeDto;
    period_shift?: {
      days?: number;
      weeks?: number;
      months?: number;
      quarters?: number;
      years?: number;
    };
    adjustment_expression?: AdjustmentExpressionDto;
    total_calculation_strategy?: 'evaluate' | 'sum' | 'avg' | 'none';
  }[];
  default_options: {
    grouping_type: 'segments' | 'assets';
    date?: {
      period_shift?: {
        days?: number;
        weeks?: number;
        months?: number;
        quarters?: number;
        years?: number;
      };
    };
    objects?: EagleEyeDefaultOptionsObjectDto[] | null;
  };
};
export type ReportBuilderKpiTableSingleDateWidgetConfigDto = {
  viz_type?: string;
  viz_config?: {
    column_groups?: {
      group_id: string;
      header_name: string;
      icon: string | null;
      background: string;
      border_color: string;
      order: number;
      child_can_override_period?: boolean;
    }[];
    columns?: {
      col_id: string;
      key: string;
      group_id: string | null;
      column_group_show: string;
      hide_dates?: boolean;
      hidden?: boolean;
      cell_renderer?: CellRendererConfigDto;
      header?: {
        hide_title?: boolean;
        hide_subtitle?: boolean;
      };
      min_width?: number;
      max_width?: number;
      align?: 'left' | 'center' | 'right';
      value_display_options: {
        type?:
          | 'number'
          | 'percentage'
          | 'currency'
          | 'metric'
          | 'sqft'
          | 'bps'
          | 'date';
        precision?: 0 | 1 | 2;
        kilo_formatting?: boolean;
        hide_comma_separator?: boolean;
        date_format?:
          | 'YYYY'
          | 'MM'
          | 'MMM'
          | 'MM/DD/YYYY'
          | 'MMMM YYYY'
          | 'MMMM D, YYYY'
          | 'MMM D, YYYY'
          | 'Relative';
      };
      order: number;
      comparison?: WidgetColumnComparisonDto;
      border_color?: string | null;
      font_weight?: string | null;
    }[];
  };
  hide_total_row?: boolean;
  columns: {
    key: number;
    label: string;
    expression: string;
    history_size: number;
    type?: 'numeric' | 'text';
    period_type:
      | ('day' | 'week' | 'month' | 'mtd' | 'quarter' | 'qtd' | 'year' | 'ytd')
      | TrailingPeriodTypeDto;
    period_shift?: {
      days?: number;
      weeks?: number;
      months?: number;
      quarters?: number;
      years?: number;
    };
    adjustment_expression?: AdjustmentExpressionDto;
    total_calculation_strategy?: 'evaluate' | 'sum' | 'avg' | 'none';
  }[];
  default_options: {
    grouping_type: 'segments' | 'assets';
    date?: {
      period_shift?: {
        days?: number;
        weeks?: number;
        months?: number;
        quarters?: number;
        years?: number;
      };
    };
    objects?: EagleEyeDefaultOptionsObjectDto[] | null;
  };
};
export type ReportBuilderXyChartSingleKpiWidgetConfigDto = {
  kpis: {
    key: number;
    expression: string;
  }[];
  granularities: ('day' | 'week' | 'month' | 'quarter' | 'year')[];
  default_options: {
    granularity: 'day' | 'week' | 'month' | 'quarter' | 'year';
    date_from: {
      period_type: 'day' | 'week' | 'month' | 'quarter' | 'year';
      period_shift?: {
        days?: number;
        weeks?: number;
        months?: number;
        quarters?: number;
        years?: number;
      };
    };
    date_to?: {
      period_type: 'day' | 'week' | 'month' | 'quarter' | 'year';
      period_shift?: {
        days?: number;
        weeks?: number;
        months?: number;
        quarters?: number;
        years?: number;
      };
    };
    objects?: EagleEyeDefaultOptionsObjectDto[] | null;
  };
  am_chart_config: object;
};
export type ReportGlobalLeaseTableWidgetConfigDto = {
  viz_type: 'table';
  viz_config: {
    rows?: object[];
    columns: object[];
    column_groups?: object[];
    grid_options?: object;
  };
  default_options?: {
    objects?: EagleEyeDefaultOptionsObjectDto[] | null;
  };
};
export type ReportTextAreaWidgetConfigDto = {
  text: string;
};
export type EagleEyeReportWidgetDto = {
  id: string;
  title: string | null;
  widgetType:
    | 'kpi_card'
    | 'kpi_table'
    | 'kpi_table_single_date'
    | 'xy_chart_single_kpi'
    | 'global_lease_table'
    | 'text_area'
    | 'media';
  widgetConfig:
    | (
        | ReportKpiCardWidgetConfigDto
        | ReportBuilderKpiTableWidgetConfigDto
        | ReportBuilderKpiTableSingleDateWidgetConfigDto
        | ReportBuilderXyChartSingleKpiWidgetConfigDto
        | ReportGlobalLeaseTableWidgetConfigDto
        | ReportTextAreaWidgetConfigDto
      )
    | null;
  defaultOptions: {
    [key: string]: any;
  } | null;
  position: {
    y: number;
    w: number;
  };
  fontSize: number | null;
  snapshotContext: {
    date: string | null;
    dateFrom: string | null;
    dateTo: string | null;
    granularity: string | null;
  } | null;
};
export type EagleEyeReportTemplateGroupDto = {
  id: string;
  position: number;
  displayHeader: boolean | null;
  pageOrientation: 'portrait' | 'landscape';
  widgetSections: EagleEyeReportWidgetDto[];
};
export type EagleEyeReportReportGroupDto = {
  id: string;
  name: string;
};
export type EagleEyeReportTemplateDto = {
  id: string;
  name: string;
};
export type EagleEyeReportDto = {
  id: string;
  name: string;
  subtitle: string | null;
  status: 'draft' | 'published';
  date: string;
  dateFormat?: ('day' | 'quarter') | null;
  displayHeader: boolean;
  fileUrl: string | null;
  segments: EagleEyeReportSegmentDto[];
  assets: EagleEyeReportAssetDto[];
  excludedLegalEntities?: EagleEyeReportExcludedLegalEntityDto[];
  groups: EagleEyeReportTemplateGroupDto[];
  reportGroup: EagleEyeReportReportGroupDto;
  template: EagleEyeReportTemplateDto;
};
export type ReportGroupTemplateDto = {
  id: string;
  name: string;
  tags: string[];
  kind?: 'eagle_eye' | 'object_level';
};
export type ReportGroupDto = {
  id: string;
  name: string;
  description: string | null;
  template: ReportGroupTemplateDto;
  reports: {
    generatedCount: number;
    draftCount: number;
  };
  createdAt: number;
  updatedAt: number;
  createdBy: {
    id?: string;
    name?: string;
  };
  updatedBy: {
    id?: string;
    name?: string;
  };
};
export type UnprocessableentityDto = {
  errors?: string[];
};
export type TemplateDto = {
  id: string;
  name: string;
  kind: 'eagle_eye' | 'object_level';
  tags: string[];
};
