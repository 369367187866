import React, { ReactNode } from 'react';
import { Tooltip } from 'stories/Tooltip/Tooltip';
import { TooltipProps } from 'stories/Tooltip/Tooltip';

interface Props extends TooltipProps {
  title: ReactNode;
  content: ReactNode;
  children: ReactNode;
}

const CoWorkflowTooltip = ({ title, content, children, ...rest }: Props) => {
  return (
    <Tooltip
      theme="light"
      placement="top"
      titleText={title}
      mainText={content}
      {...rest}
    >
      {children}
    </Tooltip>
  );
};

export default CoWorkflowTooltip;
