import React from 'react';
import { CurrencyFormatter } from 'stories/ValueFormatters/CurrencyFormatter';
import { Icon } from 'stories/Icon/Icon';
import { Modal } from 'stories/Modals/Modal/Modal';
import { InvoiceLinkedTransaction } from '@/shared/types/reconcile/Invoice';
import { Card } from 'bundles/DrawPackage/InvoicesFlow/Invoices/layouts/Card';
import { formatDate } from '@/shared/lib/formatting/dates';
import { cn } from '@/shared/lib/css/cn';

interface Props
  extends Omit<
    React.ComponentProps<typeof Modal>,
    'toggle' | 'actions' | 'children'
  > {
  onClose: () => void;
  transaction: InvoiceLinkedTransaction;
}

function LinkedTransactionModal({ onClose, transaction, ...props }: Props) {
  const { value, date, vendorEntity, period, valueType, note, linkedInvoices } =
    transaction;

  return (
    <Modal
      toggle={onClose}
      header="Linked Transaction"
      bodyPadding="0"
      {...props}
    >
      <div className="flex flex-col gap-6 bg-light p-6">
        <div className="flex flex-col items-center gap-2">
          <CurrencyFormatter
            value={value}
            classes={{
              allParts: 'header2-regular text-dark-60',
            }}
          />
          <div className="flex items-center gap-1">
            <div>{formatDate(date, 'MMM DD, YYYY')}</div>
            <div>•</div>
            <div>{vendorEntity?.name}</div>
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <div className="flex gap-2">
            <Card className="flex-1 border-0">
              <div className="flex flex-col gap-1">
                <div className="label-regular">Period</div>
                <div className="header6-regular text-dark-60">
                  {formatDate(period, 'MMM-YYYY')}
                </div>
              </div>
            </Card>
            <Card className="flex-1 border-0">
              <div className="flex flex-col gap-1">
                <div className="label-regular">Type</div>
                <div className="flex items-center gap-2">
                  <div className="header6-regular capitalize text-dark-60">
                    {valueType}
                  </div>
                  <div
                    className={cn(
                      'flex h-[24px] w-[24px] items-center justify-center rounded-lg',
                      {
                        ['bg-green-light-5 text-green']: valueType === 'debit',
                        ['red-light-4 text-red']: valueType === 'credit',
                      },
                    )}
                  >
                    <Icon iconName={valueType} />
                  </div>
                </div>
              </div>
            </Card>
          </div>
          <Card className="flex-1 border-0">
            <div className="flex flex-col gap-1">
              <div className="label-regular">Remark</div>
              <div className="header6-regular text-dark-60">{note}</div>
            </div>
          </Card>
        </div>
        <div className="flex flex-col gap-2">
          <div className="body-semibold text-dark-60">Linked Invoices</div>
          <div className="flex flex-col gap-1">
            {linkedInvoices &&
              linkedInvoices.map((invoice) => (
                <Card key={invoice.id} className="flex-1 border-0">
                  <div className="flex items-start justify-between gap-2">
                    <div className="flex flex-col gap-2">
                      <div className="inline-regular">
                        Invoice {invoice.number}
                      </div>
                      <div className="secondary-regular flex w-fit items-center gap-1 rounded bg-light px-2 py-1">
                        <div>{formatDate(invoice?.date, 'MMM DD, YYYY')}</div>
                        <div>•</div>
                        <div>{invoice?.vendor.name}</div>
                      </div>
                    </div>
                    <CurrencyFormatter value={invoice.amount} />
                  </div>
                </Card>
              ))}
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default LinkedTransactionModal;
