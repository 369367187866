import type { ReportGroupDto } from '@/entities/reporting/api/reportingGeneratedApi';
import { generateUrl, ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import { useNavigate } from '@reach/router';

export const useReportBuilderNavigateToEagleEyeReportGroup = () => {
  const navigate = useNavigate();

  const handleNavigate = (groupId: ReportGroupDto['id'] | undefined) => {
    if (groupId == null) return;

    navigate(
      generateUrl(ROUTES_ROOT.reporting.eagleEye.reportGroup.fullPath, {
        pathParams: {
          reportGroupId: groupId ?? '',
        },
      }),
      {
        replace: true,
      },
    );
  };

  return handleNavigate;
};
