import { WidgetConfiguration } from 'bundles/Shared/widgets/dashboard/widgets/model';
import { MasterUnitTableWidget } from '@/bundles/Shared/widgets/dashboard/widgets/masterUnitTable/widget';
import { GridOptions, ICellRendererParams } from 'ag-grid-community';
import { BasicCellRenderer } from 'bundles/Shared/components/AgGrid/Table/cellComponents/BasicCellRenderer';
import { Label } from '@/stories/Label/Label';

import { formatUnitStatus } from '@/entities/report/unitTypes/lib';
import { RENTROLL_UNIT_STATUS_COLORS } from '@/entities/report/unitTypes/config';
import { createAgGridTableFormattedColDef } from '@/shared/lib/formatting/table';
import { convertCentsToDollars } from '@/shared/lib/converters';
import { TRentrollUnitStatus } from '@/entities/report/unitTypes/model';
import {
  ColDef,
  ValueFormatterParams,
} from 'ag-grid-community/dist/lib/entities/colDef';
import { startCase } from 'lodash-es';

export const MASTER_UNIT_TABLE_WIDGET_CONFIG = {
  layout: {
    w: 2,
    h: 13,
    maxH: 12,
    minH: 6,
    minW: 1,
    maxW: 2,
  },
  title: 'Master Unit Table',
  icon: 'table',
  Component: MasterUnitTableWidget,
} as const satisfies WidgetConfiguration;

const UNIT_STATUS_CELL_RENDERER = (params: ICellRendererParams) => (
  <BasicCellRenderer {...params}>
    <Label
      color={RENTROLL_UNIT_STATUS_COLORS[params.value as TRentrollUnitStatus]}
    >
      {params.valueFormatted}
    </Label>
  </BasicCellRenderer>
);

const unitStatusValueFormatter = (params: ValueFormatterParams) =>
  formatUnitStatus(params.value);

export const LEASE_DETAILS_MODAL_DEFAULT_AG_GRID_CONFIG = {
  columnDefs: [
    {
      colId: 'residentName',
      headerName: 'Resident Name',
      field: 'residentName',
    },
    {
      groupId: 'general',
      headerName: 'General',
      headerGroupComponentParams: {
        style: {
          backgroundColor: 'rgba(11, 23, 48, 1)',
        },
      },
      children: [
        {
          headerName: 'Trade Out',
          colId: 'tradeOut',
          field: 'tradeoutCents',
          valueGetter: ({ data }) => convertCentsToDollars(data.tradeoutCents),
          ...createAgGridTableFormattedColDef({
            type: 'currency',
          }),
        },
        {
          headerName: 'Lease Rent',
          colId: 'actualRent',
          field: 'actualRentCents',
          valueGetter: ({ data }) =>
            convertCentsToDollars(data.actualRentCents),
          ...createAgGridTableFormattedColDef({
            type: 'currency',
          }),
        },
        {
          headerName: 'Lease Type',
          colId: 'leaseType',
          field: 'leaseType',
          valueFormatter: (params) => startCase(params.value),
        },
        {
          headerName: 'Lease Status',
          colId: 'leaseStatus',
          field: 'status',
        },
      ],
    },
    {
      groupId: 'moveDetails',
      headerName: 'Move Date',
      headerGroupComponentParams: {
        style: {
          backgroundColor: 'rgba(8, 80, 128, 1)',
          borderColor: 'rgba(22, 139, 221, 1)',
        },
      },
      children: [
        {
          headerName: 'Move-In',
          colId: 'moveIn',
          field: 'moveInDate',
          ...createAgGridTableFormattedColDef({
            type: 'date',
            date_format: 'MM/DD/YYYY',
          }),
        },
        {
          headerName: 'Move Out',
          colId: 'moveOut',
          field: 'moveOutDate',
          ...createAgGridTableFormattedColDef({
            type: 'date',
            date_format: 'MM/DD/YYYY',
          }),
        },
      ],
    },
    {
      groupId: 'leaseDetails',
      headerName: 'Lease Details',
      headerGroupComponentParams: {
        style: {
          backgroundColor: 'rgba(58, 29, 106, 1)',
          borderColor: 'rgba(96, 64, 169, 1)',
        },
      },
      children: [
        {
          headerName: 'Term',
          colId: 'leaseTerm',
          field: 'termMonths',
        },
        {
          headerName: 'Signed Date',
          colId: 'signDate',
          field: 'signDate',
          ...createAgGridTableFormattedColDef({
            type: 'date',
            date_format: 'MM/DD/YYYY',
          }),
        },
        {
          headerName: 'Start Date',
          colId: 'startDate',
          field: 'startDate',
          initialSort: 'desc',
          ...createAgGridTableFormattedColDef({
            type: 'date',
            date_format: 'MM/DD/YYYY',
          }),
        },
        {
          headerName: 'End Date',
          colId: 'endDate',
          field: 'endDate',
          ...createAgGridTableFormattedColDef({
            type: 'date',
            date_format: 'MM/DD/YYYY',
          }),
        },
      ],
    },
    {
      groupId: 'additional',
      headerName: 'Additional',
      headerGroupComponentParams: {
        style: {
          backgroundColor: 'rgba(11, 23, 48, 1)',
          borderColor: 'rgba(33, 42, 47, 1)',
        },
      },
      // balance, deposit
      children: [
        {
          headerName: 'Balance',
          colId: 'balance',
          field: 'balanceCents',
          valueGetter: ({ data }) => convertCentsToDollars(data.balanceCents),
          ...createAgGridTableFormattedColDef({
            type: 'currency',
          }),
        },
        {
          headerName: 'Deposit',
          colId: 'deposit',
          field: 'totalDepositCents',
          valueGetter: ({ data }) =>
            convertCentsToDollars(data.totalDepositCents),
          ...createAgGridTableFormattedColDef({
            type: 'currency',
          }),
        },
      ],
    },
  ],
} satisfies Partial<GridOptions>;

export const COL_DEF_OVERRIDES: Record<string, Partial<ColDef>> = {
  leased_status: {
    cellRendererSelector: (params) => ({
      component: UNIT_STATUS_CELL_RENDERER,
      params: {
        ...params,
      },
    }),
    valueFormatter: unitStatusValueFormatter,
  },
  occupied_status: {
    cellRendererSelector: (params) => ({
      component: UNIT_STATUS_CELL_RENDERER,
      params: {
        ...params,
      },
    }),
    valueFormatter: unitStatusValueFormatter,
  },
};
