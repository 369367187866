import SortButton from 'bundles/Shared/components/Filters/buttons/sortButton/SortButton';
import React, { useMemo, useState } from 'react';
import { Modal } from 'stories/Modals/Modal/Modal';
import { ModalHeaderWithSubtitle } from 'stories/Modals/ModalHeader/ModalHeaderWithSubtitle/ModalHeaderWithSubtitle';
import { SearchInput } from 'stories/FormControls/Inputs/SearchInput/SearchInput';
import { SortType, TSort } from 'types/Sort';
import type { IRelatedGLState } from '@/bundles/Settings/components/REport/CategoriesList/TreeViewWithNavigation/TreeViewWithNavigation';
import styles from '@/bundles/Settings/components/REport/CategoriesList/RelatedGLModal/RelatedGLModal.module.scss';

interface IProps {
  relatedGLState: IRelatedGLState;
  toggle: (active: boolean) => void;
}

export default function RelatedGLModal({ relatedGLState, toggle }: IProps) {
  const [sort, setSort] = useState<SortType | null>(null);
  const [search, setSearch] = useState('');

  const handleSort = () => {
    if (sort === null) {
      setSort(TSort.ASC);
      return;
    }
    if (sort === TSort.ASC) {
      setSort(TSort.DESC);
      return;
    }
    setSort(null);
  };

  const linkedItems = useMemo(() => {
    const format = (label: string) => label.trim().toLowerCase();
    let items = [...relatedGLState.linkedItems];
    if (search !== '') {
      items = items.filter(({ label }) =>
        format(label).includes(format(search)),
      );
    }

    if (sort !== null) {
      items = items.sort(({ label: a }, { label: b }) => {
        if (format(a) > format(b)) return 1;
        if (format(a) < format(b)) return -1;
        return 0;
      });

      if (sort === 'desc') {
        items = items.reverse();
      }
    }
    return items;
  }, [relatedGLState, sort, search]);

  return (
    <Modal
      contentClassName="h-500p"
      classes={{
        body: 'bg-light',
      }}
      header={
        <ModalHeaderWithSubtitle
          title={`${relatedGLState.linkedItems.length} general ledgers`}
          subtitle="global"
          order="subtitle-title"
        />
      }
      toggle={toggle}
    >
      <ul className={styles.linkedItemsList}>
        <SearchInput
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          resetValue={() => setSearch('')}
        />
        <div className="label-regular flex items-center gap-s px-m py-[0.75rem]">
          {linkedItems.length === 0 ? (
            <span>Not found</span>
          ) : (
            <>
              <span>General ledgers</span>
              <SortButton onClick={handleSort} sort={sort} />
            </>
          )}
        </div>
        {linkedItems.map(({ label }) => (
          <li className="inline-regular bg-white p-m">{label}</li>
        ))}
      </ul>
    </Modal>
  );
}
