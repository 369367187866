import React from 'react';
import { startCase, sum } from 'lodash-es';
import { DefaultTableBlocks, TModalHeader } from 'bundles/Construction/types';
import { BaseTemplateBlock } from '@/bundles/Construction/components/ChangeOrderEvent/HistoryDetailsModalSwither/utils/BaseTemplateBlock';
import styles from '@/bundles/Construction/components/ChangeOrderEvent/HistoryDetailsModalSwither/HistoryDetailsModal/HistoryDetailsModal.module.scss';
import DefaultTableRow from '@/bundles/Construction/components/ChangeOrderEvent/HistoryDetailsModalSwither/utils/DefaultTableRow';
import { formatUnixDate } from '@/shared/lib/formatting/dates';

export const MODAL_HEADERS: TModalHeader = {
  update: {
    iconName: 'edit',
    iconColor: 'light-60',
    title: 'Details of modifying Change Event',
  },
  status_changed: {
    iconName: 'changing',
    iconColor: 'light-60',
    title: 'Status change details',
  },
  revise: {
    iconName: 'sync',
    iconColor: 'red',
    title: 'Revision details',
  },
  reject: {
    iconName: 'nodeOpen',
    iconColor: 'red',
    title: 'Rejection details',
  },
  promote: {
    iconName: 'upgrade',
    iconColor: 'blue',
    title: 'Promotion details',
  },
  verify: {
    iconName: 'checkDouble',
    iconColor: 'green',
    title: 'Verification details',
  },
  cost_breakdown_confirmed: {
    iconName: 'edit',
    iconColor: 'light-60',
    title: 'Details of modifying Cost Breakdown',
  },
  approved_email: {
    iconName: 'email',
    iconColor: 'red',
    title: 'Approval Email Details',
  },
};

const DEFAULT_TABLE_BLOCKS = [
  DefaultTableBlocks.KEY_INFO,
  DefaultTableBlocks.ADDITIONAL_DETAILS,
  DefaultTableBlocks.FINANCIAL_IMPACT,
];

const makePriceFormat = (value: string | number) => `$${value}`;

const SPECIAL_ATTRIBUTES = [
  {
    attribute: 'changeOrderReasons',
    normalizeValue: (value: { name: string; category: string }[]) =>
      value.map((reason) => `${reason.category} - ${reason.name}`).join('; '),
  },
  {
    attribute: 'temporaryReallocations',
    normalizeValue: (value: { name: string; category: string }[]) =>
      value.map((reallocation) => reallocation.from).join('; '),
  },
  {
    attribute: 'contractorDate',
    normalizeValue: (date: Date) => formatUnixDate(date, 'MMM DD, YYYY'),
  },
  {
    attribute: 'receivedDate',
    normalizeValue: (date: Date) => formatUnixDate(date, 'MMM DD, YYYY'),
  },
  {
    attribute: 'sentToDesignerOfRecordDate',
    normalizeValue: (date: Date) => formatUnixDate(date, 'MMM DD, YYYY'),
  },
  {
    attribute: 'weightedAmount',
    normalizeValue: makePriceFormat,
  },
  {
    attribute: 'value',
    normalizeValue: makePriceFormat,
  },
  {
    attribute: 'probability',
    normalizeValue: (value: string | number) => `${value}%`,
  },
];

export function tooManyLetters(words: string[] | number[]) {
  const amountOfLetter = sum(words.map((word) => word.length));
  return amountOfLetter >= 48;
}

export function renderValue({ value, attribute }) {
  const specialValue = SPECIAL_ATTRIBUTES.filter(
    (row) => row.attribute === attribute,
  );
  if (specialValue.length) {
    return specialValue[0].normalizeValue(value);
  }
  return value;
}

const renderOnlyChangedData = (eventDetails, block) => (
  <div className={styles.block} key={block}>
    <div className={styles.blockTitle}>{startCase(block)}</div>
    <div className={styles.table}>
      {eventDetails.historyDetails[block]
        .filter((row) => row.value.old)
        .map((row) => (
          <DefaultTableRow key={row.attribute} row={row} />
        ))}
    </div>
  </div>
);

export const renderAllAttributes = (eventDetails) => (
  <>
    {DEFAULT_TABLE_BLOCKS.map((block) => (
      <BaseTemplateBlock eventDetails={eventDetails} block={block} />
    ))}
  </>
);

export const filterOnlyChangedBlocks = (eventDetails) => (
  <>
    {DEFAULT_TABLE_BLOCKS.filter(
      (block) =>
        eventDetails.historyDetails[block].filter((row) => row.value.old)
          .length,
    ).map((block) => renderOnlyChangedData(eventDetails, block))}
  </>
);
