import { BaseFinancialTableWidgetConfigBaseColumnDto } from '@/bundles/Shared/shared/api/dashboardsSettingsGeneratedApi';
import { FinancialTableWidgetConfigColumnForm } from '@/bundles/Shared/widgets/dashboard/widgets/common/financialTable/config/column.form';
import {
  ADJUSTMENT_EXPRESSION_VALUE_ACTUAL,
  AdjustmentExpressionName,
} from 'bundles/Shared/widgets/dashboard/widgets/common/features/adjustments/config/base';
import {
  ADJUSTMENT_TYPE_VALUE_TOTAL,
  FinancialTableAdjustmentForm,
} from 'bundles/Shared/widgets/dashboard/widgets/common/features/adjustments/config/financial';

export const isUnderwritingAdjustment = (value: AdjustmentExpressionName) => {
  return value.startsWith('uw_');
};

export const isForecastAdjustment = (value: AdjustmentExpressionName) => {
  return value.startsWith('fc_');
};

export const transformFinancialTableWidgetAdjustmentFormToConfig = (
  fields: Pick<
    FinancialTableWidgetConfigColumnForm,
    'adjustment_expression' | 'adjustment_type' | 'forecast_name'
  >,
): Pick<
  BaseFinancialTableWidgetConfigBaseColumnDto,
  'adjustment_average_per' | 'adjustment_expression'
> => ({
  // @ts-expect-error Not all options "adjustment_expression" are opt in
  adjustment_expression:
    fields.adjustment_expression === ADJUSTMENT_EXPRESSION_VALUE_ACTUAL
      ? undefined
      : {
          name: fields.adjustment_expression,
          forecast_name: fields.forecast_name,
        },
  adjustment_average_per:
    fields.adjustment_type === ADJUSTMENT_TYPE_VALUE_TOTAL
      ? undefined
      : fields.adjustment_type ?? undefined,
});

export const transformAdjustmentDtoToForm = (
  dto: BaseFinancialTableWidgetConfigBaseColumnDto,
): FinancialTableAdjustmentForm => {
  return {
    adjustment_expression:
      dto.adjustment_expression?.name ?? ADJUSTMENT_EXPRESSION_VALUE_ACTUAL,
    adjustment_type: dto.adjustment_average_per ?? ADJUSTMENT_TYPE_VALUE_TOTAL,
    forecast_name: dto.adjustment_expression?.forecast_name ?? undefined,
  };
};
