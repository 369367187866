import { ExtractParams, ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import { useParams } from '@reach/router';
import { LineInfoCard } from 'bundles/Settings/components/REport/Connections/widgets/connectionPage/ConnectionInfoCard';
import { ConnectionParseStatusLabel } from 'bundles/Shared/entities/connection/ui/ConnectionParserStatusLabel';
import { formatDate } from '@/shared/lib/formatting/dates';
import { convertToMilliseconds } from '@/shared/lib/converters';
import { useConnectionById } from '@/bundles/Shared/entities/connection/api';

export const ConnectionParserInfoCard = () => {
  const { fullPath: ROUTE } =
    ROUTES_ROOT.settings.report.connections.connection;
  const params = useParams<ExtractParams<typeof ROUTE>>();

  const { data: connection } = useConnectionById(Number(params.connectionId));

  return (
    <div className="rounded-2xl bg-white px-4">
      <div className="flex items-center justify-between pt-4">
        <div className="font-semibold text-neutral-800">Parser</div>
        <ConnectionParseStatusLabel
          parserStatus={connection?.item.parserStatus}
        />
      </div>
      <div className="pt-1">
        <ConnectionParserInfoCard.Line label="Last Launch" lastItem>
          {formatDate(
            convertToMilliseconds(
              connection?.item.lastParserLaunchAt as UnixTime,
            ),
            'MMMM DD, YYYY h:mm A',
          )}
        </ConnectionParserInfoCard.Line>
      </div>
    </div>
  );
};

ConnectionParserInfoCard.Line = LineInfoCard;
