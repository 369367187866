import { coreAssetsReportBuilderReportsEnhancedApi as objectLevelApi } from '@/entities/report/reportBuilder/api/coreAssetsReportBuilderReportsEnhancedApi';
import { reportingEnhancedApi as eagleEyeApi } from '@/entities/reporting/api/reportingEnhancedApi';
import { createSlice, isAnyOf } from '@reduxjs/toolkit';

interface State {
  requestsStarted: boolean;
  pendingRequestIds: string[];
}

/**
 * @property pendingRequestIds - If there are any "snapshot" or "report" requests are pending then "preview" request waits until they are all resolved
 */
const initialState: State = {
  requestsStarted: true,
  pendingRequestIds: [],
};

const {
  getApiCoreAssetsByAssetIdReportBuilderReportsAndId: getObjectLevelReport,
  postApiCoreAssetsByAssetIdReportBuilderReportsAndReportIdWidgetSectionsWidgetSectionIdSnapshot:
    postObjectLevelSnapshot,
} = objectLevelApi.endpoints;

const {
  getApiReportBuilderEagleEyeReportsById: getEagleEyeReport,
  postApiReportBuilderEagleEyeReportsByEagleEyeReportIdWidgetSectionsAndWidgetSectionIdSnapshot:
    postEagleEyeSnapshot,
} = eagleEyeApi.endpoints;

const reportBuilderSlice = createSlice({
  name: 'report-builder',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addMatcher(
      isAnyOf(
        postObjectLevelSnapshot.matchPending,

        postEagleEyeSnapshot.matchPending,
      ),
      (state, { meta }) => {
        state.requestsStarted = true;
        state.pendingRequestIds.push(meta.requestId);
      },
    );
    builder.addMatcher(
      isAnyOf(
        getObjectLevelReport.matchPending,
        getEagleEyeReport.matchPending,
      ),
      (state) => {
        state.requestsStarted = true;
      },
    );
    builder.addMatcher(
      isAnyOf(
        postObjectLevelSnapshot.matchFulfilled,
        postObjectLevelSnapshot.matchRejected,

        postEagleEyeSnapshot.matchFulfilled,
        postEagleEyeSnapshot.matchRejected,
      ),
      (state, { meta }) => {
        const newIds = state.pendingRequestIds.filter(
          (id) => id !== meta.requestId,
        );
        state.pendingRequestIds = newIds;

        if (state.requestsStarted && newIds.length === 0) {
          state.requestsStarted = false;
        }
      },
    );
  },
});

export default reportBuilderSlice.reducer;
