import { useAppSelector } from '@/shared/lib/hooks/redux';
import React, { useState, useEffect } from 'react';
import { Input } from 'stories/FormControls/Inputs/Input/Input';

interface Props {
  onChange: (value: string) => void;
}

function ContractorRetaingleField({ onChange }: Props) {
  const settings = useAppSelector(
    (state) => state.developmentLegalEntity.settings,
  );
  const [value, setValue] = useState(settings?.contractorRetainageValue);

  useEffect(() => {
    setValue(settings?.contractorRetainageValue);
  }, [settings]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (e.target.checkValidity()) {
      onChange(value);
    } else {
      e.target.reportValidity();
    }
  };

  return (
    <Input
      value={value}
      rightIcon="percent"
      type="number"
      onBlur={handleSubmit}
      placeholder="Enter Percentage Value"
      onChange={(e) => setValue(e.target.value)}
      max={100}
      min={0}
    />
  );
}

export default ContractorRetaingleField;
