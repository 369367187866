import { ContextMenuCell } from 'bundles/Shared/components/AgGrid/Table/cellComponents/ContextMenuCell';
import { ContextActionsMenu } from '@/bundles/REconcile/components/development/requisitions/widgets/RequisitionsTable';
import { HeaderGroupComponent } from 'bundles/Shared/components/AgGrid/Table/cellComponents/HeaderGroupComponent';
import { Icon } from 'stories/Icon/Icon';
import {
  BasicHeaderComponent,
  HeaderComponent,
} from 'bundles/Shared/components/AgGrid/Table/cellComponents/HeaderComponent';
import { accountPayableValueGetter } from '@/bundles/REconcile/components/development/requisitions/shared/requisitionsTable/utils';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import {
  selectExpandAccountsPayable,
  setReqTableExpandAccountsPayable,
} from '@/bundles/REconcile/components/development/requisitions/shared/requisitionsTable/slices/RequisitionsTableSlice';
import { CustomCurrencyCellRenderer } from 'bundles/Shared/components/AgGrid/Table/cellComponents/CustomCurrencyCellRenderer';
import CountBadge from '@/bundles/REconcile/components/development/requisitions/headers/CountBadge/CountBadge';
import { navigate, useParams } from '@reach/router';
import VarianceHeader from '@/bundles/REconcile/components/development/requisitions/headers/VarianceHeader';
import {
  generateUrl,
  ROUTES_ROOT,
  useNavigation,
} from '@/shared/lib/hooks/useNavigation';
import { LegalEntity } from '@/entities/core/legalEntity';
import FireIcon from 'bundles/FireStation/components/FireIcon/FireIcon';
import { CornerArrow } from 'bundles/Shared/components/AgGrid/Table/cellComponents/CornerArrow';
import { CurrencyCellRenderer } from 'bundles/Shared/components/AgGrid/Table/cellComponents/CurrencyCellRenderer';
import { RequisitionCellPanel } from '@/bundles/REconcile/components/development/requisitions/shared/requisitionsTable/ui/RequisitionCellPanel/RequisitionCellPanel';
import { ICellRendererParams } from 'ag-grid-community';

const DEFAULT_ACCOUNTS_PAYABLE_COLUMN_CONFIG = {
  headerComponent: HeaderComponent,
  headerComponentParams: {
    classes: {
      inner: '!justify-center',
    },
  },
  aggFunc: 'sum',
  minWidth: 150,
  valueGetter: accountPayableValueGetter,
  pinned: 'right',
};

const AccountsPayableContextActionsMenu = () => {
  const expandAccountsPayable = useAppSelector(selectExpandAccountsPayable);
  const dispatch = useAppDispatch();

  return (
    <ContextActionsMenu
      actions={[
        {
          label: expandAccountsPayable
            ? 'Collapse Accounts Payable'
            : 'Expand Accounts Payable',
          onClick: () =>
            dispatch(setReqTableExpandAccountsPayable(!expandAccountsPayable)),
        },
      ]}
    />
  );
};

/**
* Implemented only Account Payable column because haven't actual data in BE
  If we receive it - we uncomment ContextMenuCell in getCollapseAccountsPayableColumns (FE functionality is ready)
  also we can define name of field in getExpandAccountsPayableColumns
*/

export const getExpandAccountsPayableColumns = () => ({
  headerName: 'Accounts Payable',
  headerGroupComponent: (params) => (
    <ContextMenuCell
      contextMenu={AccountsPayableContextActionsMenu}
      contextMenuParams={{
        placement: 'bottom',
      }}
    >
      <HeaderGroupComponent
        classes={{
          inner: '!justify-center',
        }}
        {...params}
      >
        <Icon iconName="moreVertical" />
      </HeaderGroupComponent>
    </ContextMenuCell>
  ),
  children: [
    {
      ...DEFAULT_ACCOUNTS_PAYABLE_COLUMN_CONFIG,
      headerName: 'Not paid',
      // field: 'budget',
    },
    {
      ...DEFAULT_ACCOUNTS_PAYABLE_COLUMN_CONFIG,
      headerName: 'Mark for payment',
      // field: 'budget',
    },
    {
      ...DEFAULT_ACCOUNTS_PAYABLE_COLUMN_CONFIG,
      headerName: 'Paid',
      // field: 'budget',
    },
  ],
});

const AccountPayableColumnRenderer = ({
  value,
  node,
  ...props
}: ICellRendererParams) => {
  const params = useParams();
  const hasCategory = node.data?.jobCostCode;
  const routeParams: {
    type: 'jcc' | 'sovc' | null;
    id: number | null;
  } = {
    type: hasCategory && node.data.type,
    id: hasCategory && node.data.jobCostCode.id,
  };

  const onLinkClick = () => {
    navigate(
      generateUrl(
        ROUTES_ROOT.reconcile.development.legalEntity.payableInvoices.category
          .fullPath,
        {
          pathParams: {
            legalEntityCode: params.legalEntityCode,
            categoryId: routeParams.id,
            type: routeParams.type,
          },
        },
      ),
    );
  };

  if (hasCategory) {
    return (
      <CornerArrow>
        <div
          className="relative h-full w-full cursor-pointer"
          onClick={onLinkClick}
        >
          <RequisitionCellPanel>
            {value < 0 && <FireIcon priority="high" />}
          </RequisitionCellPanel>
          <CurrencyCellRenderer value={value ?? 0} {...props} />
        </div>
      </CornerArrow>
    );
  }

  return (
    <div className="relative h-full w-full">
      <RequisitionCellPanel>
        {value < 0 && <FireIcon priority="high" />}
      </RequisitionCellPanel>
      <CustomCurrencyCellRenderer value={value} node={node} {...props} />
    </div>
  );
};

export const getCollapseAccountsPayableColumns = (
  legalEntityCode: LegalEntity['code'],
) => {
  const { getUrl } = useNavigation();

  const invoicesWithoutRequisitionCount = useAppSelector(
    (state) =>
      state.developmentBudgetVariance.data.invoicesWithoutRequisitionCount,
  );

  return {
    ...DEFAULT_ACCOUNTS_PAYABLE_COLUMN_CONFIG,
    headerName: 'Accounts Payable',
    headerComponent: (params) => (
      // <ContextMenuCell write comment of the top ^
      //   contextMenu={AccountsPayableContextActionsMenu}
      //   contextMenuParams={{
      //     placement: 'bottom',
      //   }} // Waiting for BE Side
      // >
      <BasicHeaderComponent>
        <VarianceHeader {...params} />
      </BasicHeaderComponent>
      /* <Icon iconName="moreVertical" /> */
      // </HeaderComponent>
      // </ContextMenuCell>
    ),
    minWidth: 200,
    pinned: 'right',
    headerComponentParams: {
      classes: {
        inner: '!justify-between !items-end py-2',
      },
      rightBar: Boolean(invoicesWithoutRequisitionCount) && (
        <CountBadge
          count={invoicesWithoutRequisitionCount}
          iconName="log"
          onClick={(e) => {
            e.stopPropagation();
            navigate(
              getUrl('RECONCILE_DEVELOPMENT_LEGAL_ENTITY_PAYABLE_INVOICES', {
                legalEntityCode,
              }),
            );
          }}
        />
      ),
    },
    cellRenderer: AccountPayableColumnRenderer,
  };
};
