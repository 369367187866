import { RouteComponentProps, useNavigate, useParams } from '@reach/router';
import {
  useGetApiRecapPagesByIdQuery,
  usePostApiRecapPagesMutation,
  usePutApiRecapPagesByIdMutation,
} from '@/shared/api/documentationEnhancedApi';
import { FC, useEffect, useMemo, useState } from 'react';
import { generateUrl, ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import ContainerWithAppBar from 'bundles/Shared/components/Containers/ContainerWithAppBar';
import { AnimationLoader } from '@/stories/AnimationLoader/AnimationLoader';
import { Breadcrumbs } from '@/stories/Breadcrumbs/Breadcrumbs';
import { Button } from '@/stories/Button/Button';
import { IconButton } from '@/stories/IconButton/IconButton';
import { currentUserIsSreAdmin } from 'lib/permissions';
import {
  EDIT_DOCUMENTATION_PAGE_SCHEMA,
  EditDocumentationPage,
  EditDocumentationPageForm,
  useUpdatePagePermissions,
} from '@/features/documentation/editPage';
import { FormProvider, useForm } from 'react-hook-form';
import { clone, reverse } from 'lodash-es';
import { yupResolver } from '@hookform/resolvers/yup';
import SkeletonBlock from 'stories/ProjectCard/SkeletonBlock';
import { IS_UNIFORM } from 'lib/http';
import { PermissionListLine } from 'bundles/Shared/components/Permissions/PermissionListLine';
import { DashboardEmptyPermissions } from 'bundles/Shared/entities/dashboard/ui/DashboardEmptyPermissions';
import { useDeletePage } from '@/features/documentation/deletePage';

const DocumentPage: FC<RouteComponentProps> = () => {
  const params = useParams();
  const navigate = useNavigate();
  const isNew = params.id === 'new';

  const [createPage] = usePostApiRecapPagesMutation();
  const [updatePage] = usePutApiRecapPagesByIdMutation();
  const [deletePage, { isUninitialized }] = useDeletePage();
  const updatePagePermissions = useUpdatePagePermissions();
  const [isEditing, setIsEditing] = useState(params.id === 'new');
  const [formKey, setFormKey] = useState(params.id);
  const { data, isLoading, isFetching } = useGetApiRecapPagesByIdQuery(
    {
      id: params.id,
    },
    {
      skip: isNew || !isUninitialized,
    },
  );

  const methods = useForm<EditDocumentationPageForm>({
    resolver: yupResolver(EDIT_DOCUMENTATION_PAGE_SCHEMA),
    defaultValues: {
      title: '',
      content: {},
    },
    reValidateMode: 'onChange',
    mode: 'all',
  });
  const {
    formState: { isDirty, isValid },
  } = methods;

  const canSave = isValid && isDirty;
  const canEdit = isNew || (!isEditing && (data?.isSystem ? IS_UNIFORM : true));

  useEffect(() => {
    if (isNew) {
      methods.reset({
        title: '',
        content: {},
      });
      setFormKey(params.id);
      return;
    }

    // from new to id and wait for data to update form
    if (data != null && params.id === data.slug) {
      methods.reset({
        title: data.name,
        content: data.content,
        parent_id: data.parentId,
      });
      setFormKey(params.id);
    }
  }, [params.id, data?.id]);

  const breadcrumbs = useMemo(() => {
    if (data == null) return [];
    const crumbs = [{ name: 'sRE Documentation', slug: '/' }];

    return [...crumbs, ...reverse(clone(data.breadcrumbs ?? []))].map(
      (crumb, index) => ({
        label: crumb.name,
        to: generateUrl(ROUTES_ROOT.documentation.doc.fullPath, {
          pathParams: { id: crumb.slug ?? crumb.id },
        }),
        isCurrent: index === data.breadcrumbs?.length,
      }),
    );
  }, [data]);

  const onSave = methods.handleSubmit(async (values) => {
    const generateDocUrl = (slug: string) =>
      generateUrl(ROUTES_ROOT.documentation.doc.fullPath, {
        pathParams: { id: slug },
      });
    if (isNew) {
      const res = await createPage({
        body: {
          name: values.title,
          content: values.content,
          position: 0,
          is_draft: false,
          parent_id: null,
        },
      });

      if (!('error' in res)) {
        navigate(generateDocUrl(res.data.slug!));
      }
    } else {
      const res = await updatePage({
        id: params.id,
        body: {
          name: values.title,
          content: values.content,
          is_draft: false,
          position: 0,
          parent_id: values.parentId,
        },
      });
      if (!('error' in res)) {
        navigate(generateDocUrl(res.data.slug!), { replace: true });
      }
    }
    setIsEditing(false);
  });

  const onEditPermissions = () => {
    updatePagePermissions({
      pageId: data!.id,
    });
  };

  const onEditToggle = () => {
    if (isEditing || isNew) {
      onSave();
      return;
    }
    setIsEditing(!isEditing);
  };

  const onDelete = async () => {
    const res = await deletePage(params.id);
    if (!res) {
      return;
    }
    navigate(ROUTES_ROOT.documentation.fullPath);
  };

  if (isLoading)
    return (
      <ContainerWithAppBar
        className="flex h-full w-full flex-col"
        appBar={<SkeletonBlock className="w-full" />}
      >
        <SkeletonBlock className="w-full grow" />
      </ContainerWithAppBar>
    );

  if (!isNew && data == null) return null;

  return (
    <ContainerWithAppBar
      padding="none"
      classes={{
        body: 'p-4 pt-0',
        appBar:
          'flex items-center max-[720px]:hidden gap-4 border-neutral-200 border-b p-4',
      }}
      appBar={
        <>
          {!isNew && (
            <>
              <Breadcrumbs
                onBackClick={() => navigate(ROUTES_ROOT.documentation.fullPath)}
                breadcrumbs={breadcrumbs}
              />
            </>
          )}
          {canEdit && data && (
            <>
              <IconButton
                disabled={isEditing}
                iconName="trash"
                onClick={onDelete}
                size="m"
              />
              <PermissionListLine
                permissions={data.permitted}
                emptyFallback={
                  <DashboardEmptyPermissions onClick={onEditPermissions} />
                }
                onEdit={onEditPermissions}
                onClick={onEditPermissions}
              />
            </>
          )}

          <div className="grow" />
          {currentUserIsSreAdmin() && (
            <div className="flex gap-4">
              <Button
                onClick={onEditToggle}
                variant="success"
                size="m"
                disabled={isEditing || isNew ? !canSave : !canEdit}
              >
                {isEditing || isNew ? 'Save Changes' : 'Edit'}
              </Button>
              {isEditing && (
                <Button
                  onClick={() => setIsEditing(false)}
                  variant="secondary"
                  size="m"
                >
                  Cancel
                </Button>
              )}
            </div>
          )}
        </>
      }
      className="flex h-full w-full bg-neutral-000"
    >
      <div className="relative pt-4">
        {isFetching && <AnimationLoader />}
        <FormProvider {...methods}>
          <EditDocumentationPage
            // reset the form when we switch between pages, built in reset doesn't work
            key={formKey}
            readOnly={!isEditing && !isNew}
          />
        </FormProvider>
      </div>
    </ContainerWithAppBar>
  );
};
export default DocumentPage;
