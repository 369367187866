import { OverlayScrollbarsComponentRef } from 'overlayscrollbars-react';
import { useCallback, useRef } from 'react';

function isElementInViewport(el: Element) {
  const rect = el.getBoundingClientRect();

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

export const useScrollToElement = (selector: string) => {
  const navigationRef = useRef<OverlayScrollbarsComponentRef>(null);
  const isReady = useRef(false);

  const scrollTo = useCallback(() => {
    if (isReady.current) return;

    if (navigationRef.current) {
      const instance = navigationRef.current?.osInstance();

      if (!instance) return;

      const { content } = instance.elements();
      const selectedItem = content.querySelector(selector);

      if (!selectedItem || isElementInViewport(selectedItem)) {
        return;
      }

      selectedItem.scrollIntoView({
        block: 'start',
        inline: 'nearest',
      });
      isReady.current = true;
    }
  }, [navigationRef.current, selector]);

  const clear = useCallback(() => {
    isReady.current = false;
  }, []);

  return { navigationRef, scrollTo, clear };
};
