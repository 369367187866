import React, { FC, ReactNode } from 'react';
import { IconButton } from 'stories/IconButton/IconButton';
import { Modal } from 'stories/Modals/Modal/Modal';
import { cn } from '@/shared/lib/css/cn';
import styles from '@/bundles/Shared/components/FilePreviews/FilePreview.module.scss';

interface ICustomPreviewProps {
  children: ReactNode;
  leftBlock: ReactNode;
  handleClose?: (status: boolean) => void;
  header?: ReactNode;
  classes?: {
    fileContainer: string;
    contentContainer: string;
  };
  withoutHeader?: boolean;
}

const CustomPreview: FC<ICustomPreviewProps> = ({
  children,
  leftBlock,
  handleClose,
  header,
  classes = {
    fileContainer: '',
    contentContainer: '',
  },
  withoutHeader,
}) => (
  <Modal
    toggle={handleClose}
    size="huge"
    bodyPadding="0"
    classes={{
      body: 'bg-light-10',
    }}
  >
    <div className="flex">
      <div className={classes.fileContainer || styles.fileContainer}>
        {leftBlock}
      </div>
      <div className={classes.contentContainer || styles.contentContainer}>
        {!withoutHeader && (
          <>
            <div className={cn('modal-header', styles.modalHeader)}>
              <div>{header}</div>
              <IconButton
                iconName="close"
                size="l"
                onClick={() => handleClose(false)}
                variant="secondary"
              />
            </div>
            <hr className="m-0" />
          </>
        )}
        {children}
      </div>
    </div>
  </Modal>
);

export default CustomPreview;
