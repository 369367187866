import React, { useLayoutEffect, useState } from 'react';

import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themesAnimated from '@amcharts/amcharts5/themes/Animated';
import { configChartColors, createXYChart } from 'lib/amcharts/utils';
import {
  PIPELINE_CHART_COLOR_SET,
  useChartSeriesData,
  useFormattedChartData,
} from '@/bundles/Scoreboard/Pipeline/components/chartUtils';

export function StackedArea({ employeeUtilization }) {
  const [chartItemsToUpdate, setChartItemsToUpdate] = useState([]);

  const addInChartItemsToUpdate = (item) => {
    setChartItemsToUpdate((prev) => [...prev, item]);
  };
  const seriesData = useChartSeriesData(employeeUtilization, ['total']);
  const chartData = useFormattedChartData(employeeUtilization);

  useLayoutEffect(() => {
    const root = am5.Root.new('employeeUtilizationStackedAreaNew');
    root.numberFormatter.set('numberFormat', "#.0'%'");
    root.dateFormatter.set('dateFormat', 'MMM-d-YY');
    root.setThemes([am5themesAnimated.new(root)]);

    const chart = createXYChart(root);

    configChartColors(chart, PIPELINE_CHART_COLOR_SET);

    // Create Y-axis
    const yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        extraTooltipPrecision: 1,
        renderer: am5xy.AxisRendererY.new(root, {}),
      }),
    );
    yAxis.children.unshift(
      am5.Label.new(root, {
        rotation: -90,
        text: 'Utilization (%)',
        y: am5.p50,
        centerX: am5.p50,
      }),
    );

    // Create X-Axis
    const xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        baseInterval: { timeUnit: 'day', count: 1 },
        renderer: am5xy.AxisRendererX.new(root, {
          minGridDistance: 50,
        }),
      }),
    );

    addInChartItemsToUpdate(xAxis);
    xAxis.data.setAll(chartData);

    // Create series
    seriesData.forEach(({ valueYField }) => {
      let name = '';

      switch (valueYField) {
        case 'sma':
          name = 'SMA';
          break;
        case 'goal':
          name = 'Capacity';
          break;

        default:
          name = valueYField;
          break;
      }

      const series = chart.series.push(
        am5xy.LineSeries.new(root, {
          name,
          xAxis,
          yAxis,
          valueYField,
          valueXField: 'date',
          tooltip: am5.Tooltip.new(root, {}),
        }),
      );

      if (valueYField !== 'sma' && valueYField !== 'goal') {
        series.bullets.push(() =>
          am5.Bullet.new(root, {
            sprite: am5.Circle.new(root, {
              radius: 5,
              fill: series.get('fill'),
            }),
          }),
        );

        series.strokes.template.set('strokeWidth', 2);
      } else {
        series.strokes.template.setAll({
          strokeWidth: 6,
          strokeDasharray: [20, 20],
        });
      }

      if (valueYField === 'goal') {
        series.set('stroke', am5.color('#a32020'));
        series.set('fill', am5.color('#a32020'));
      }
      if (valueYField === 'sma') {
        series.set('stroke', am5.color('#cccccc'));
        series.set('fill', am5.color('#cccccc'));
      }

      series.get('tooltip').label.set('text', '{name}: [bold]{valueY}[/]');
      series.data.setAll(chartData);
      addInChartItemsToUpdate(series);
    });

    // Add cursor
    chart.set(
      'cursor',
      am5xy.XYCursor.new(root, {
        behavior: 'zoomXY',
        xAxis,
      }),
    );

    xAxis.set(
      'tooltip',
      am5.Tooltip.new(root, {
        themeTags: ['axis'],
      }),
    );

    yAxis.set(
      'tooltip',
      am5.Tooltip.new(root, {
        themeTags: ['axis'],
      }),
    );

    // Scrollbar config
    const scrollbarX = am5xy.XYChartScrollbar.new(root, {
      orientation: 'horizontal',
      height: 50,
    });

    chart.set('scrollbarX', scrollbarX);

    const sbxAxis = scrollbarX.chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        groupData: true,
        groupIntervals: [{ timeUnit: 'day', count: 1 }],
        baseInterval: { timeUnit: 'day', count: 1 },
        renderer: am5xy.AxisRendererX.new(root, {
          strokeOpacity: 0,
        }),
      }),
    );
    const sbyAxis = scrollbarX.chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {}),
      }),
    );
    const sbseries = scrollbarX.chart.series.push(
      am5xy.LineSeries.new(root, {
        name: 'Total (Sum)',
        xAxis: sbxAxis,
        yAxis: sbyAxis,
        valueYField: 'sma',
        valueXField: 'date',
      }),
    );

    addInChartItemsToUpdate(sbseries);
    sbseries.data.setAll(chartData);

    // Add legend
    const legend = chart.children.push(
      am5.Legend.new(root, {
        nameField: 'name',
        fillField: 'color',
        strokeField: 'color',
        centerX: am5.percent(50),
        x: am5.percent(50),
      }),
    );

    legend.data.setAll(chart.series.values);

    const data = employeeUtilization;
    const startIndex = data.length > 31 ? data.length - 31 : 0;
    const startDate = new Date(data[startIndex].date);
    const endDate = new Date(data[data.length - 1].date);

    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/date-axis/#pre-zooming
    sbseries.events.on('datavalidated', () => {
      xAxis.zoomToDates(startDate, endDate);
    });
    return () => {
      root.dispose();
    };
  }, []);

  useLayoutEffect(() => {
    chartItemsToUpdate.forEach((item) => {
      item.data.setAll(chartData);
    });
  }, [employeeUtilization]);
  return (
    <div className="panel-body">
      <div
        id="employeeUtilizationStackedAreaNew"
        style={{ width: '100%', height: '800px' }}
      />
    </div>
  );
}

export default StackedArea;
