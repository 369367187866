import React from 'react';
import { Textarea } from 'stories/Textarea/Textarea';
import { cn } from '@/shared/lib/css/cn';

export interface ISharedTextArea<TValue>
  extends React.InputHTMLAttributes<HTMLInputElement> {
  value: TValue;
}

export const SharedTextArea = <TValue,>({
  value,
  onChange,
  ...props
}: ISharedTextArea<TValue>) => (
  <div className={cn('form-item')}>
    <Textarea onChange={onChange} value={value} {...props} />
  </div>
);
