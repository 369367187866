import React, { FC, ReactNode } from 'react';

interface IViewSettingsProps {
  controlSection: ReactNode;
  previewArea: ReactNode;
}

const ViewSettings: FC<IViewSettingsProps> = ({
  controlSection,
  previewArea,
}) => (
  <div className="flex gap-10 rounded-lg bg-light p-4">
    <div className="w-[164px]">{controlSection}</div>
    <div className="flex-1 overflow-hidden rounded-lg border border-light-10">
      {previewArea}
    </div>
  </div>
);

export default ViewSettings;
