import { KeyContactsCard } from '@/bundles/Shared/widgets/propertyManagementCompany/KeyContactsCard';
import {
  ExtractParams,
  ROUTES_ROOT,
  generateUrl,
} from '@/shared/lib/hooks/useNavigation';
import { navigate, useParams } from '@reach/router';
import ConnectionActions from 'bundles/Settings/components/REport/Connections/ui/ConnectionActions';
import { ConnectionInfoCard } from 'bundles/Settings/components/REport/Connections/widgets/connectionPage/ConnectionInfoCard';
import { ConnectionMemoCard } from 'bundles/Settings/components/REport/Connections/widgets/connectionPage/ConnectionMemoCard';
import { ConnectionParserInfoCard } from 'bundles/Settings/components/REport/Connections/widgets/connectionPage/ConnectionParserInfoCard';
import { PMCInfoCard } from 'bundles/Shared/widgets/propertyManagementCompany/PMCInfoCard';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { Icon } from 'stories/Icon/Icon';
import { IconButton } from 'stories/IconButton/IconButton';
import { ConnectionAccessNoteCard } from '@/bundles/Settings/components/REport/Connections/widgets/connectionPage/ConnectionAccessNoteCard';
import { ConnectionUpdatedAtCard } from '@/bundles/Shared/widgets/propertyManagementCompany/ConnectionUpdatedAtCard';
import { useConnectionById } from '@/bundles/Shared/entities/connection/api';
const ConnectionInfo = () => {
  const { fullPath: ROUTE } =
    ROUTES_ROOT.settings.report.connections.connection;
  const params = useParams<ExtractParams<typeof ROUTE>>();

  const { data: connection } = useConnectionById(Number(params.connectionId));

  const handleClose = () => {
    navigate(
      generateUrl(ROUTES_ROOT.settings.report.connections.fullPath, {
        pathParams: {},
      }),
    );
  };

  return (
    <div className="h-screen bg-neutral-100">
      <div className="flex w-full items-center justify-between bg-white px-6 py-3">
        <div className="flex items-center gap-3">
          <Icon
            iconName="link"
            className="flex h-[40px] w-[40px] items-center justify-center rounded-lg bg-neutral-100 text-[24px] text-neutral-450"
          />
          <div>
            <div className="font-bold text-neutral-800">
              {connection?.item.name}
            </div>
            <div className="label-semibold flex items-center gap-1">
              <div>Connections</div>
              <div>•</div>
              <div>{connection?.item.companyName}</div>
            </div>
          </div>
        </div>
        <div className="flex gap-2">
          <ConnectionActions />
          <IconButton
            iconName="close"
            size="l"
            onClick={() => handleClose()}
            variant="secondary"
          />
        </div>
      </div>
      <OverlayScrollbarsComponent>
        <div className="flex h-[calc(100vh-64px)] flex-1 flex-col gap-4 p-6">
          <ConnectionInfoCard />
          <ConnectionParserInfoCard />
          <ConnectionMemoCard />
          <PMCInfoCard />
          <KeyContactsCard />
          <ConnectionAccessNoteCard />
          <ConnectionUpdatedAtCard />
        </div>
      </OverlayScrollbarsComponent>
    </div>
  );
};

export default ConnectionInfo;
