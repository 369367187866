import ReportSettingsScreenLayout from '@/bundles/Settings/shared/ReportSettingsScreenLayout';
import Table from '@/bundles/Shared/components/Table/Table';
import type { IColumn } from '@/bundles/Shared/components/Table/types';
import { syncsEnhancedApi } from '@/entities/report/syncs/api/syncsEnhancedApi';
import type { SyncDto } from '@/entities/report/syncs/api/syncsGeneratedApi';
import { DEFAULT_SYNC_PAGE_POLLING_INTERVAL_MS } from '@/entities/report/syncs/config';
import {
  SyncStatusColum,
  type SyncStatusColumExpandClick,
} from '@/entities/report/syncs/ui/SyncStatusColumn';
import { CreateSync } from '@/features/report/sync/createSync/ui/button';
import { formatUnixDate } from '@/shared/lib/formatting/dates';
import type { RouteComponentProps } from '@reach/router';
import { useEffect, useMemo, useState } from 'react';

export const SyncingKindPage = ({
  syncingKind,
}: {
  syncingKind: {
    id: string;
    label: string;
  };
} & RouteComponentProps) => {
  const [expandedStatusColExpandedRowId, setStatusColExpandedRowId] = useState<
    SyncDto['id'] | null
  >(null);

  useEffect(() => {
    setStatusColExpandedRowId(null);
  }, [syncingKind]);

  const { data, isFetching } =
    syncsEnhancedApi.useGetApiCustomerBridgeSyncsQuery(
      {
        kind: syncingKind.id,
      },
      {
        pollingInterval: DEFAULT_SYNC_PAGE_POLLING_INTERVAL_MS,
        refetchOnMountOrArgChange: true,
      },
    );

  const handleStatusColExpand = (row: SyncDto): SyncStatusColumExpandClick => {
    return (actionType) => {
      switch (actionType) {
        case 'expand': {
          return setStatusColExpandedRowId((prev) =>
            prev === row.id ? null : row.id,
          );
        }
        case 'collapse':
        default: {
          return setStatusColExpandedRowId(null);
        }
      }
    };
  };

  const rowData = useMemo(() => {
    return data ?? [];
  }, [data]);

  const columns = useMemo(
    () =>
      [
        {
          dataField: 'createdAt',
          text: 'Date',
          formatter: ({ row }) => (
            <>
              {formatUnixDate(row.createdAt as UnixTime, 'MMM DD, YYYY h:mm A')}
            </>
          ),
        },
        {
          dataField: 'initiator.fullName',
          text: 'Synced by',
          formatter: ({ row }) => <>{row.initiator.fullName}</>,
        },
        {
          dataField: 'status',
          text: 'Status',
          classes: 'w-[60%]',
          formatter: ({ row }) => (
            <SyncStatusColum
              {...row}
              isExpanded={expandedStatusColExpandedRowId === row.id}
              onClick={handleStatusColExpand(row)}
            />
          ),
        },
      ] satisfies IColumn<SyncDto>[],
    [syncingKind, expandedStatusColExpandedRowId],
  );

  return (
    <ReportSettingsScreenLayout
      actions={<CreateSync />}
      classes={{
        header: 'justify-between items-center sticky top-0 z-10',
      }}
      subTitle="Syncing"
      title={syncingKind.label}
    >
      <div className="flex flex-col gap-4 p-6">
        <Table loading={isFetching} columns={columns} items={rowData} />
      </div>
    </ReportSettingsScreenLayout>
  );
};
