import React, { useMemo } from 'react';
import { DEFAULT_NAME_COLUMN } from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/crud/constants/columns';
import { nameOf } from 'types/utils';
import { IReconcileSettingsChangeOrderReason } from 'bundles/REconcile/actions/changeOrderReasons';
import { Label } from 'stories/Label/Label';
import { CssVar } from '@/shared/config/cssVar';
import QuickFilterCheckList, {
  TQuickFilterCheckListProps,
} from 'bundles/Shared/components/Table/filters/QuickFilterCheckList';
import { IColumn } from 'bundles/Shared/components/Table/types';
import QuickFilterColorDropdown, {
  TQuickFilterColorDropdownProps,
} from 'bundles/Shared/components/Table/filters/QuickFilterColorDropdown';

export type TFilterModel = {
  category?: string[];
  active?: 'true' | 'false';
};

export function useChangeOrderReasonsCrudColumns({
  categories,
}: {
  categories: string[];
}) {
  return useMemo<IColumn<IReconcileSettingsChangeOrderReason>[]>(
    () => [
      DEFAULT_NAME_COLUMN,
      {
        dataField: nameOf<IReconcileSettingsChangeOrderReason>('category'),
        text: 'Category',
        filterComponent: QuickFilterCheckList,
        filterComponentParams: {
          getId: (item) => item,
          getLabel: (item) => item,
          items: categories,
        } as TQuickFilterCheckListProps,
        headerStyle: {
          width: '30%',
        },
      },
      {
        dataField: nameOf<IReconcileSettingsChangeOrderReason>('active'),
        text: 'Status',
        formatter: ({ row: item }) => (
          <Label color={item.active ? CssVar.green : CssVar.light60}>
            {item.active ? '' : 'Not'} Active
          </Label>
        ),
        headerStyle: {
          width: 150,
        },
        filterComponent: QuickFilterColorDropdown,
        filterComponentParams: {
          options: [
            {
              color: CssVar.green,
              key: 'true',
              label: 'ACTIVE',
            },
            {
              color: CssVar.light60,
              key: 'false',
              label: 'NOT ACTIVE',
            },
          ],
        } as TQuickFilterColorDropdownProps,
      },
    ],
    [categories],
  );
}
