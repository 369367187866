import React from 'react';
import { ReportEagleEyeDashboard } from '@/bundles/Shared/entities/dashboard/model/types/types';
import { Icon } from 'stories/Icon/Icon';
import { capitalize } from 'lodash-es';
import { DashboardCardLabel } from '@/bundles/Shared/entities/dashboard/ui/card/DashboardCardLabel';
import { STATUS_OPTIONS_SETTINGS } from 'bundles/Shared/entities/dashboard/config';

interface Props {
  status: ReportEagleEyeDashboard['status'];
}

export function DashboardCardStatusLabel({ status }: Props) {
  return (
    <DashboardCardLabel>
      <Icon
        className={STATUS_OPTIONS_SETTINGS[status].color}
        iconName={STATUS_OPTIONS_SETTINGS[status].icon}
      />
      <span className="secondary-semibold text-neutral-900">
        {capitalize(status)}
      </span>
    </DashboardCardLabel>
  );
}
