import { CssVar } from '@/shared/config/cssVar';
import { CssCustomProperty } from '@/shared/lib/css/cssCustomProperty';
import { Icon } from '@/stories/Icon/Icon';
import type { ComponentProps } from 'react';

const allColor = CssCustomProperty.getRef('selected-color', CssVar.neutral500);
const counterColor = CssCustomProperty.getRef('selected-color', CssVar.info055);

export const ObjectCounter = (
  props: Pick<ComponentProps<typeof Icon>, 'iconName'> & {
    counter: number;
  },
) => (
  <div
    className="flex items-center gap-1"
    style={{
      color: allColor,
    }}
  >
    <Icon iconName={props.iconName} />
    <p
      className="min-w-[2ch] text-center"
      style={{
        color: counterColor,
      }}
    >
      {props.counter}
    </p>
  </div>
);

export const ObjectFilterOptionWithCounters = (option: {
  name: string;
  assetsLen: number;
  segmentsLen: number;
  legalEntitiesLen: number;
}) => (
  <div className="flex w-full items-center justify-between gap-2">
    <p className="overflow-hidden text-ellipsis whitespace-nowrap">
      {option.name}
    </p>

    <div className="flex items-center gap-3">
      <ObjectCounter counter={option.assetsLen} iconName="asset" />
      <ObjectCounter counter={option.segmentsLen} iconName="segments" />
      <ObjectCounter counter={option.legalEntitiesLen} iconName="entities" />
    </div>
  </div>
);
