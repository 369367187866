import ItemFilterList from '@/bundles/Shared/components/Filters/items/ItemFilterList';
import { Popover } from '@/stories/Popover/Popover';
import { Button } from '@/stories/Button/Button';
import type { BaseId, ListOption } from '@/stories/Checkbox/CheckList';
import { DEFAULT_DROPDOWN_OFFSET } from '@/stories/Popover/Popover';
import React from 'react';

type Props<T> = React.ComponentProps<typeof ItemFilterList> & {
  selectedItem: T | undefined;
};

export const KpisDropdown = <T extends ListOption<BaseId>>({
  items,
  selectedItem,
  onSelectedItemChange,
  ...itemFilterListProps
}: Props<T>) => (
  <Popover
    className="p-0"
    offset={DEFAULT_DROPDOWN_OFFSET}
    hiddenArrow
    trigger="click"
    placement={'bottom-start'}
    maxWidth={300}
    template={
      <ItemFilterList
        {...itemFilterListProps}
        items={items}
        selectedItems={selectedItem ? [selectedItem] : []}
        onSelectedItemChange={onSelectedItemChange}
        maxHeight="20rem"
        // @ts-expect-error: fix later
        getMainFieldTitle={(item) => item.label}
      />
    }
  >
    <Button
      size="s"
      variant="secondary"
      iconPosition="right"
      iconName="arrowBottom"
    >
      {selectedItem?.label ?? 'Not selected'}
    </Button>
  </Popover>
);
