import React from 'react';
import { cn } from '@/shared/lib/css/cn';
import { capitalize } from 'lodash-es';
import { ITagProps } from '@/stories/Tags/types/types';
import '@/stories/Tags/Tag/Tag.scss';

export const Tag = ({
  variant,
  label,
  startIcon,
  capitalized,
  offHoverStyles,
  endIcon,
  selected,
  className,
  ...props
}: ITagProps) => (
  <button
    className={cn(
      cn(
        'sre-tag label-regular',
        variant && `sre-tag_${variant}`,
        offHoverStyles && 'without-hover',
        !props.onClick && '!cursor-default',
        className,
        {
          _selected: selected,
        },
        props.disabled && '!bg-neutral-150',
      ),
    )}
    type="button"
    {...props}
  >
    {startIcon}
    {label && (
      <div className="sre-tag__label">
        {capitalized ? capitalize(label) : label}
      </div>
    )}
    {endIcon}
  </button>
);

export default Tag;
