import { getComponentSelector } from '@/stories/TreeView/TreeView';
import type { Selection } from '@/stories/TreeView/types';

export const CHECKBOX_COMPONENT = 'Checkbox';
export const CHECKBOX_GROUP_COMPONENT = 'CheckboxGroup';
export const ICON_GROUP_COMPONENT = 'IconGroup';

const CHECK_ICON_CLASSNAME = 'checkIcon';
const CHECK_ICON_SELECTOR = '.checkIcon';
const LOCK_ICON_CLASSNAME = 'lockIcon';
const LOCK_ICON_SELECTOR = '.lockIcon';

const RIGHT_MARGIN = 4;
const LEFT_MARGIN = 8;
const RADIUS = 8;

export const isNodeLocked = (d: { locked: boolean }) => {
  return d.locked;
};

export function Checkbox(
  selection: Selection<SVGGElement>,
  lifecycle: 'create' | 'update',
  params: {
    icon: XMLDocument;
    lockIcon: XMLDocument;
  },
) {
  if (lifecycle === 'create') {
    const checkboxGroup = selection
      .append('g')
      .attr('cursor', (d) => (isNodeLocked(d) ? 'initial' : 'pointer'))
      .attr('data-component', CHECKBOX_GROUP_COMPONENT);

    checkboxGroup.append('circle').attr('data-component', CHECKBOX_COMPONENT);

    checkboxGroup.nodes().forEach((n) => {
      n?.append(params.icon.documentElement.cloneNode(true));
      n?.append(params.lockIcon.documentElement.cloneNode(true));
    });

    checkboxGroup.select('svg').attr('class', CHECK_ICON_CLASSNAME);
    checkboxGroup
      .select(`svg:not(.${CHECK_ICON_CLASSNAME})`)
      .attr('class', LOCK_ICON_CLASSNAME);
  }

  selection
    .select(getComponentSelector(CHECKBOX_COMPONENT))
    .attr('r', RADIUS)
    .attr('stroke-width', 2)
    .attr('stroke', (d) => (d.selected ? 'var(--bl)' : 'var(--light-60)'))
    .attr('fill', (d) => (d.selected ? 'var(--bl)' : 'var(--white)'))
    .attr('cx', LEFT_MARGIN)
    .attr('opacity', (d) => {
      return isNodeLocked(d) ? '0' : '1';
    })
    .each(function (d) {
      const bound = this.getBBox();
      d.checkBox = {
        ...bound,
        width: bound.width + RIGHT_MARGIN,
      };
    });
  selection
    .select(getComponentSelector(CHECKBOX_GROUP_COMPONENT))
    .select(CHECK_ICON_SELECTOR)
    .attr('width', RADIUS * 2)
    .attr('height', RADIUS * 2)
    .attr('x', 0)
    .attr('y', -RADIUS)
    .select('path')
    .attr('opacity', (d) => {
      return isNodeLocked(d) ? '0' : '1';
    })
    .attr('fill', (d) => (d.selected ? 'var(--white)' : 'transparent'));

  selection
    .select(getComponentSelector(CHECKBOX_GROUP_COMPONENT))
    .select(LOCK_ICON_SELECTOR)
    .attr('x', 0)
    .attr('y', -RADIUS)
    .attr('opacity', (d) => {
      return !isNodeLocked(d) ? '0' : '1';
    });
}
