import { OperationalMappingGroupByValue } from 'bundles/Shared/entities/sreUnitType';
import { ListOption } from 'stories/Checkbox/CheckList';
import { ColDef } from 'ag-grid-community';
import { SourceTypeCell } from 'bundles/Settings/components/REport/GeneralLedgers/Table/useColumnDefs';
import SetFilter from 'bundles/REconcile/components/operational/table/SetFilter';
import { getOptionsValues } from '@/shared/lib/listHelpers';

export function getErpColumn({
  group,
  onChange,
  value,
  options = [],
}: {
  group: OperationalMappingGroupByValue[];
  options: ListOption<string>[];
  onChange: (items: string[]) => void;
  value: string[];
}): ColDef {
  return {
    colId: 'erpSystem',
    headerName: 'ERP',
    field: 'erpSystem',
    enableRowGroup: true,
    flex: 1,
    hide: group.includes('erp'),
    rowGroup: group.includes('erp'),
    cellRenderer: SourceTypeCell,
    headerComponent: options.length ? SetFilter : null,
    headerComponentParams: {
      filterName: 'ERP',
      items: options,
      value: options.filter((option) => value.includes(option.value)),
      onChange: (value) => onChange(getOptionsValues(value)),
      label: 'ERP',
    },
  };
}
