import React from 'react';
import TablePagination from 'bundles/Shared/components/Table/pagination/TablePagination';
import type { PaginationWidgetState } from 'bundles/Shared/widgets/dashboard/widgets/common/model';
import {
  WidgetProps,
  WidgetStateProps,
} from 'bundles/Shared/widgets/dashboard/widgets/model';

export function WidgetStateTablePagination({
  state,
  onStateChange,
  isLoading,
  totalSize,
  ...props
}: WidgetStateProps<PaginationWidgetState> &
  Pick<WidgetProps, 'isLoading'> & {
    totalSize?: number;
  } & Omit<
    React.ComponentProps<typeof TablePagination>,
    | 'currentPage'
    | 'onSizePerPageChange'
    | 'totalSize'
    | 'sizePerPage'
    | 'setCurrentPage'
    | 'loading'
  >) {
  return (
    <TablePagination
      loading={isLoading ?? false}
      currentPage={state.page}
      onSizePerPageChange={(size, page) =>
        onStateChange({ page: 1, perPage: size })
      }
      totalSize={totalSize ?? 0}
      sizePerPage={state.perPage ?? 10}
      setCurrentPage={(page) => onStateChange({ page })}
      {...props}
    />
  );
}
