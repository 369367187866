import React, { FC } from 'react';
import { PAGE_CONTENT } from 'bundles/DrawPackage/DrawPage/shared/consts';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import {
  selectFundingSources,
  setFundingSource,
} from 'bundles/DrawPackage/slices/ReconcileDrawSlice';
import { useModal } from '@/shared/lib/hooks/useModal';
import { ExtractParams, ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import { useParams } from '@reach/router';
import { useGetFundingSourcesQuery } from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/fundingSources/api/reconcileFundingSourcesApi';
import SpecifySourcesModal from 'bundles/Shared/components/SpecifySourcesModal/SpecifySourcesModal';
import { FundingSourceForDraw } from '@/shared/types/reconcile/FundingSource';
import { Button } from '@/stories/Button/Button';

interface ISelectSourceProps {
  totalAmount: number;
  disabled: boolean;
  onFormDataChange: (
    formData: {
      requisitionId: UUID;
    },
    fundingSourcesData:
      | {
          id: number;
          reallocation: number;
        }
      | object,
  ) => Promise<void>;
  requisitionSelectedId: UUID;
}

const SelectSource: FC<ISelectSourceProps> = ({
  totalAmount,
  requisitionSelectedId,
  disabled,
  onFormDataChange,
}) => {
  const { fullPath: ROUTE } =
    ROUTES_ROOT.reconcile.development.legalEntity.draws;
  const params = useParams<ExtractParams<typeof ROUTE>>();

  const selectedFundingSources = useAppSelector(selectFundingSources);

  const dispatch = useAppDispatch();
  const { openModal } = useModal();

  const { data: fundingSources } = useGetFundingSourcesQuery({
    legalEntityCode: params.legalEntityCode,
    pageParams: {},
  });

  const isRequisitionSelected = Boolean(requisitionSelectedId);

  const openSelectSourceModal = async () => {
    const res = await openModal(SpecifySourcesModal<FundingSourceForDraw>, {
      initialEntities: fundingSources?.items ?? [],
      revisedAmount: totalAmount,
      editedEntities: selectedFundingSources ?? [],
      entityName: 'Funding Source',
      entityConfig: {
        columns: {
          nameColumn: {
            dataField: 'name',
            text: 'Name',
          },
          currentAmountColumn: {
            dataField: 'balance',
            text: 'Current',
          },
          amountColumn: {
            dataField: 'reallocation',
            text: 'Amount',
          },
        },
      },
    });
    if (res) {
      const fundingSourcesData = {};
      res.forEach((fs: { id: number; reallocation?: number }) => {
        fundingSourcesData[fs.id] = fs.reallocation;
      });
      await onFormDataChange(
        { requisitionId: requisitionSelectedId },
        fundingSourcesData,
      );
      dispatch(setFundingSource(res));
    }
  };

  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col gap-2">
        <div className="inline-semibold text-dark-60">
          {PAGE_CONTENT.titleFundingSource}
        </div>
        <div className="inline-regular">
          {PAGE_CONTENT.descriptionFundingSource}
        </div>
        <Button
          variant="secondary"
          size="xs"
          className="w-fit"
          disabled={disabled || !isRequisitionSelected}
          onClick={openSelectSourceModal}
        >
          {selectedFundingSources.length ? 'Details' : 'Select Sources'}
        </Button>
      </div>
    </div>
  );
};

export default SelectSource;
