import { Button } from '@/stories/Button/Button';
import { Dropdown } from '@/stories/Dropdown/Dropdown';
import { DropdownItem } from '@/stories/Dropdown/DropdownItem/DropdownItem';
import type { ComponentProps } from 'react';

export const ReportBuilderTemplateWidgetWidthDropdown = (
  props: Pick<ComponentProps<typeof Dropdown>, 'disabled'> & {
    widgetWidth: number;
    onClick: (width: number) => void;
  },
) => {
  return (
    <Dropdown
      {...props}
      items={[100, 75, 66, 50, 33, 25].map((width) => (
        <DropdownItem
          key={width}
          active={props.widgetWidth === width}
          onClick={() => {
            props.onClick(width);
          }}
        >
          {width}%
        </DropdownItem>
      ))}
    >
      <Button iconName="arrowBottom" size="xs" iconPosition="right">
        Width: {props.widgetWidth}%
      </Button>
    </Dropdown>
  );
};
