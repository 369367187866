export const SETTINGS_REPORT_OPERATIONAL_DICTIONARY = {
  ChargeCodes: {
    singularForm: 'Charge Code',
    pluralForm: 'Charge Codes',
  },
  UnitTypes: {
    singularForm: 'Unit Type',
    pluralForm: 'Unit Types',
  },
  UnitStatus: {
    singularForm: 'Unit Status',
    pluralForm: 'Unit Statuses',
  },
} as const;
