import { useModal } from '@/shared/lib/hooks/useModal';
import React from 'react';
import { Button } from 'stories/Button/Button';
import { Dropdown } from 'stories/Dropdown/Dropdown';
import { DropdownItem } from 'stories/Dropdown/DropdownItem/DropdownItem';
import { Icon } from 'stories/Icon/Icon';
import ImportUsersModal from '@/bundles/UserManagement/components/UserTracker/importUsersModal';

interface Props {
  fetchAndSetUsers: () => void;
}

function ImportUsersButton({ fetchAndSetUsers }: Props) {
  const { openModal } = useModal();

  return (
    <Dropdown
      maxWidth={400}
      items={
        <>
          <DropdownItem className="w-full" onClick={() => {}}>
            <a
              href="/import-users-template.xlsx"
              download="import-users-template.xlsx"
            >
              <div className="flex gap-xs">
                <Icon iconName="download" />
                Download a template
              </div>
            </a>
          </DropdownItem>
          <DropdownItem
            onClick={() =>
              openModal(ImportUsersModal, {
                fetchAndSetUsers,
              })
            }
            className="w-full"
          >
            <div className="flex gap-xs">
              <Icon iconName="categoryPlus" />
              Import from xlsx
            </div>
          </DropdownItem>
        </>
      }
    >
      <Button
        variant="secondary"
        size="s"
        iconPosition="right"
        iconName="bottom"
      >
        Import
      </Button>
    </Dropdown>
  );
}

export default ImportUsersButton;
