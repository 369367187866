import * as React from 'react';
import { ComponentProps } from 'react';
import { IconButton } from 'stories/IconButton/IconButton';
import { cn } from '@/shared/lib/css/cn';
import { SortType, TSort } from 'types/Sort';
import { IconsId } from 'types/sre-icons';
import styles from '@/bundles/Shared/components/Filters/buttons/sortButton/SortButton.module.scss';

interface Props
  extends Omit<
    ComponentProps<typeof IconButton>,
    'iconName' | 'variant' | 'offHoverStyles'
  > {
  sort?: SortType | null;
}

function SortButton({ sort, className, ...props }: Props) {
  let sortIcon: IconsId = 'sortOff';
  if (sort != null) {
    sortIcon = sort !== TSort.ASC ? 'sortAsc' : 'sortDesc';
  }

  return (
    <IconButton
      variant="secondary"
      offHoverStyles
      iconName={sortIcon}
      className={cn(
        styles.button,
        {
          [styles.button_selected]: sort != null,
        },
        className,
      )}
      {...props}
    />
  );
}

export default SortButton;
