import {
  useLazyGetApiRecapPagesByIdSubjectablesQuery,
  usePutApiRecapPagesByIdMutation,
} from '@/shared/api/documentationEnhancedApi';
import { mapListToIds } from '@/shared/lib/listHelpers';
import { useModal } from '@/shared/lib/hooks/useModal';
import { EditPermissionsModal } from 'bundles/Shared/components/Permissions/EditPermissionsModal';
import { IPermissionRequestBody } from 'bundles/Settings/components/REport/TableBuilder/types';

export const useUpdatePagePermissions = () => {
  const { openModal } = useModal();
  const [updatePage] = usePutApiRecapPagesByIdMutation();
  const [loadSubjectables] = useLazyGetApiRecapPagesByIdSubjectablesQuery();

  return async ({ pageId }: { pageId: string }) => {
    const res = await openModal(EditPermissionsModal, {
      fetchSubjectables: async () => {
        const { data } = await loadSubjectables({
          id: pageId,
        });
        return {
          ...data!,
        };
      },
      objectableName: 'page',
    });

    if (res) {
      const permissions: IPermissionRequestBody = res.isPublic
        ? {
            directly_permitted_user_ids: [],
            directly_permitted_user_role_ids: [],
            directly_permitted_user_tag_ids: [],
            directly_permitted_investment_entity_ids: [],
            public_permission_attributes: {},
          }
        : {
            directly_permitted_user_ids: mapListToIds(res.directUsers),
            directly_permitted_user_role_ids: mapListToIds(res.directRoles),
            directly_permitted_user_tag_ids: mapListToIds(res.directTags),
            directly_permitted_investment_entity_ids: mapListToIds(
              res.directInvestmentEntities,
            ),
            public_permission_attributes: { _destroy: true },
          };

      updatePage({
        id: pageId,
        body: {
          ...permissions,
        },
      });
    }
  };
};
