import React, { FC } from 'react';
import FormViewCustom from '@/bundles/Shared/components/GroupForm/FormViewItem/FormViewCustom';
import FormViewFiles from '@/bundles/Shared/components/GroupForm/FormViewItem/FormViewFiles';
import FormViewText from '@/bundles/Shared/components/GroupForm/FormViewItem/FormViewText';
import {
  FieldTypeView,
  IFormItem,
} from '@/bundles/Shared/components/GroupForm/types/types';

interface IFormViewSwitcherProps {
  formItem: IFormItem;
  value: any;
  formData: object;
}

const FormViewSwitcher: FC<IFormViewSwitcherProps> = ({
  formItem,
  value,
  formData,
}) => {
  const configFormItem = {
    ...formItem,
    value,
    formData,
  };
  switch (formItem.typeView) {
    case FieldTypeView.VIEW_TEXT:
      return <FormViewText {...configFormItem} />;

    case FieldTypeView.VIEW_FILES:
      return <FormViewFiles {...configFormItem} />;

    case FieldTypeView.VIEW_CUSTOM:
      return <FormViewCustom {...configFormItem} />;

    default:
      return <div>Unknow type of item</div>;
  }
};

export default FormViewSwitcher;
