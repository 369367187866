import React, { FC, ReactNode, useLayoutEffect, useRef, useState } from 'react';
import { cn } from '@/shared/lib/css/cn';
import { Checkbox } from '@/stories/Checkbox/Checkbox';
import { Tooltip } from '@/stories/Tooltip/Tooltip';
import { RadioButton } from '@/stories/RadioButton/RadioButton';
import FileExtensionIcon from 'bundles/Shared/components/fileExtensionIcon/FileExtensionIcon';
import FileInfo from '@/stories/DocumentCard/components/FileInfo';
import styles from '@/stories/DocumentCard/DocumentCard.module.scss';
import { DocumentCardStatusIcon } from '@/stories/DocumentCard/components/DocumentCardStatusIcon';

export type TDocumentStatus = {
  successStatus: string;
  attentionStatus: string;
  attentionTitle: string;
  failedStatus: string;
  failedTitle: string;
  currentStatus: string;
};

interface IDocumentCard {
  actions: ReactNode;
  fileExtension: string;
  background?: string;
  checked?: boolean;
  className?: string;
  classes?: {
    fileName?: string;
    icon?: string;
  };
  date?: string;
  disabled?: boolean;
  fileSize?: number;
  filename?: string;
  link?: string;
  onClick?: () => void;
  onSelect?: () => void;
  permissions?: string;
  radioSelect?: boolean;
  selectable?: boolean;
  documentStatus?: TDocumentStatus;
}

export const DocumentCard: FC<IDocumentCard> = ({
  filename = '',
  fileSize = 0,
  fileExtension,
  date,
  link,
  className,
  classes,
  permissions,
  background = 'white',
  selectable,
  onSelect,
  actions,
  checked,
  disabled,
  radioSelect,
  onClick,
  documentStatus,
}) => {
  const titleRef = useRef<HTMLAnchorElement | HTMLSpanElement>(null);
  const [isTextOverflow, setIsTextOverflow] = useState(true);
  useLayoutEffect(() => {
    if (titleRef.current !== null) {
      setIsTextOverflow(
        titleRef.current.offsetWidth < titleRef.current.scrollWidth,
      );
    }
  }, []);

  return (
    <div
      style={{
        backgroundColor: background,
      }}
      className={cn(
        styles.documentCard,
        {
          [styles.documentCard_background]: background,
        },
        className,
      )}
      onClick={onClick}
      onKeyUp={onClick}
      role="button"
      tabIndex={0}
    >
      <div
        className={cn(
          'grid w-full items-center gap-2',
          selectable || radioSelect
            ? 'grid-cols-[auto_auto_minmax(0,1fr)_auto]'
            : 'grid-cols-[auto_minmax(0,1fr)_auto]',
        )}
      >
        {selectable && (
          <div className="ml-s mr-s flex items-center">
            <Checkbox
              onChange={onSelect}
              checked={checked}
              disabled={disabled}
            />
          </div>
        )}
        {radioSelect && (
          <div className="ml-s mr-s flex items-center">
            <RadioButton
              checked={checked}
              onChange={onSelect}
              disabled={disabled}
            />
          </div>
        )}
        <FileExtensionIcon
          className={cn(styles.icon, classes?.icon)}
          extension={fileExtension}
        />
        <div className="mnw-0 flex flex-col">
          <Tooltip
            disabled={!isTextOverflow}
            mainText={filename}
            maxWidth="none"
            arrowPosition="center"
          >
            {link ? (
              <a
                className={cn(
                  'sre-link inline-semibold text-ellipsis',
                  classes?.fileName,
                )}
                href={link}
                target="_blank"
                rel="noreferrer"
                ref={titleRef}
              >
                {filename}
              </a>
            ) : (
              <span
                ref={titleRef}
                className={cn(
                  'inline-semibold text-ellipsis',
                  styles.filename,
                  classes?.fileName,
                )}
              >
                {filename}
              </span>
            )}
          </Tooltip>
          {documentStatus && (
            <>
              {documentStatus.currentStatus ===
                documentStatus.successStatus && (
                <FileInfo
                  size={fileSize}
                  extension={fileExtension}
                  permissions={permissions}
                  date={date}
                />
              )}
              {documentStatus.currentStatus ===
                documentStatus.attentionStatus && (
                <div className="label-regular text-attention-055">
                  {documentStatus.attentionTitle}
                </div>
              )}
              {documentStatus.currentStatus === documentStatus.failedStatus && (
                <div className="label-regular text-danger-055">
                  {documentStatus.failedTitle}
                </div>
              )}
            </>
          )}
          {!documentStatus && (
            <FileInfo
              size={fileSize}
              extension={fileExtension}
              permissions={permissions}
              date={date}
            />
          )}
        </div>
        <div className="ml-auto flex items-center gap-2">
          {documentStatus && (
            <DocumentCardStatusIcon documentStatus={documentStatus} />
          )}
          {actions && actions}
        </div>
      </div>
    </div>
  );
};

export default DocumentCard;
