import { TOptionTreeItem } from '@/bundles/Shared/components/GroupForm/types/typesFormItem';
import { splitWithDash } from '@/shared/lib/string';
import { TreeProps } from 'rc-tree';

type TTreeFuncParams<
  T extends TOptionTreeItem,
  M,
  K extends keyof T = keyof T,
> = [
  tree: T[],
  callback: (n: T, path?: T[]) => M,
  options?: { visitedNodes: T[]; childrenKey: K },
];

export const treeDF = <T extends TOptionTreeItem, K extends keyof T = keyof T>(
  ...params: TTreeFuncParams<T, boolean | void, K>
) => {
  const [
    tree,
    callback,
    options = { visitedNodes: [], childrenKey: 'children' as K },
  ] = params;

  return tree?.some((node) => {
    const nodePath = [...(options?.visitedNodes || []), node];

    if (callback(node, nodePath)) return true;

    const key = options.childrenKey ?? 'children';

    if (!(key in node)) return false;

    if (
      Array.isArray(node[key]) &&
      treeDF(node[key] as T[], callback, {
        visitedNodes: nodePath,
        childrenKey: options.childrenKey ?? ('children' as K),
      })
    ) {
      return true;
    }

    return false;
  });
};

export class TreeData {
  static deepFirst<
    Node extends Record<string, unknown>,
    Key extends keyof Node,
  >(
    tree: Node[],
    callback: (n: Node, path: Node[]) => boolean,
    options: {
      visitedNodes: Node[];
      childrenKey: Key;
    } = {
      visitedNodes: [],
      childrenKey: 'children' as Key,
    },
  ): boolean {
    return tree.some((node) => {
      const nodePath = [...options.visitedNodes, node];

      if (callback(node, nodePath)) return true;

      const nextTree = node[options.childrenKey];

      if (!TreeData.isTree<Node>(nextTree)) return false;

      return TreeData.deepFirst(nextTree, callback, {
        visitedNodes: nodePath,
        childrenKey: options.childrenKey,
      });
    });
  }

  private static isTree<T>(x: unknown): x is T[] {
    return Array.isArray(x);
  }
}

export const treeDFS = <T extends TOptionTreeItem, K extends keyof T = keyof T>(
  ...params: TTreeFuncParams<T, boolean, K>
): T[] | null => {
  const [tree, matcher, options] = params;

  let result: T[] | null = null;

  treeDF(
    tree,
    (node, nodePath) => {
      if (!matcher(node, nodePath)) return false;

      result = nodePath || null;
      return true;
    },
    options,
  );

  return result;
};

export const treeDFM = <
  T extends TOptionTreeItem,
  M,
  K extends keyof T = keyof T,
>(
  ...params: TTreeFuncParams<T, M, K>
) => {
  const [tree, callback, options] = params;

  const result: M[] = [];

  treeDF(
    tree,
    (...args) => {
      result.push(callback(...args));
    },
    options,
  );

  console.log('dfm', result);

  return result;
};

// eslint-disable-next-line max-params
export function transformTree<
  Node extends {
    children?: Node[];
  },
  MappedNode,
>(
  current: Node | Node[],
  callback: (n: Node, path: Node[], index?: number) => MappedNode,
  path: Node[] = Array.isArray(current) ? [] : [current],
  index: number = 0,
): Node extends Node[] ? MappedNode[] : MappedNode {
  if (Array.isArray(current)) {
    return current.map((item, i) =>
      transformTree(item, callback, [...path, item], i),
    ) as Node extends Node[] ? MappedNode[] : MappedNode;
  }

  const temp = {
    ...current,
    children: current.children?.map((child, childIndex) =>
      transformTree(child, callback, [...path, child], childIndex),
    ),
  };
  return callback(temp, path, index) as Node extends Node[]
    ? MappedNode[]
    : MappedNode;
}

// TODO: FE-3746 merge with transformTree
export const addIndexesPathToTree = <T extends { children?: T[] }>(
  tree: T[],
  path: number[] = [],
): T[] => {
  return tree.map((node, index) => {
    const currentPath = [...path, index];
    return {
      ...node,
      path: currentPath.join('/'),
      children: node.children
        ? addIndexesPathToTree(node.children, currentPath)
        : undefined,
    };
  });
};

export const useTreeStateUpdateDropHandler = <
  T extends TOptionTreeItem & {
    key: string;
  },
>({
  tree,
  setTree,
}: {
  tree: T[];
  setTree: (tree: T[]) => void;
}) => {
  const handleDrop: TreeProps['onDrop'] = (info) => {
    const dropKey = info.node.key;
    const dragKey = info.dragNode.key;
    const dropPos = splitWithDash(info.node.pos);
    const dropPosition =
      info.dropPosition - Number(dropPos[dropPos.length - 1]);

    const data = [...tree];

    // Find dragObject
    const dragObjPath = treeDFS(data, (node) => node.key === dragKey);
    const dragObj = dragObjPath?.at(-1);
    const dragObjArrToUpdate =
      dragObjPath?.at(-2) == null ? data : dragObjPath?.at(-2)!.children;

    if (dragObjPath == null || dragObj == null) {
      return;
    }

    dragObjArrToUpdate?.splice(dragObjArrToUpdate.indexOf(dragObj), 1);

    const dropObjPath = treeDFS(data, (node) => node.id === dropKey);
    const dropObj = dropObjPath?.at(-1);
    if (dropObj == null) {
      return;
    }
    if (dropPosition === 0) {
      // Drop on the content

      dropObj.children = dropObj.children || [];
      dropObj.children.unshift(dragObj);
    } else {
      // Drop on the gap (insert before or insert after)
      const arrayToUpdate =
        dropObjPath?.at(-2) == null ? data : dropObjPath?.at(-2)!.children;

      if (arrayToUpdate == null) {
        return;
      }
      const indexOfDropObj = arrayToUpdate.findIndex(
        (node) => node.key === dropObj.key,
      );
      const index = dropPosition === -1 ? indexOfDropObj : indexOfDropObj + 1;

      arrayToUpdate.splice(index, 0, dragObj);
    }

    setTree(data);
  };

  return handleDrop;
};
