import {
  RECONCILE_FORECASTING_ENTITIES_BUDGETS_TAG,
  reconcileForecastingEntitiesEnhancedApi,
} from '@/entities/reconcile/forecasting/api/reconcileForecastingEntitiesEnchancedApi';
import { importForecastingBudget } from '@/entities/reconcile/forecasting/lib';
import { useAppDispatch } from '@/shared/lib/hooks/redux';
import useFileDialog from '@/shared/lib/hooks/useFileDialog';

export const useImportForecastingBudget = () => {
  const { openFileDialog } = useFileDialog();
  const dispatch = useAppDispatch();

  const invalidateImportedBudget = (budgetId: string) => {
    dispatch(
      reconcileForecastingEntitiesEnhancedApi.util.invalidateTags([
        {
          type: RECONCILE_FORECASTING_ENTITIES_BUDGETS_TAG,
          id: budgetId,
        },
      ]),
    );
  };

  const importFile = async (forecastId: string, budgetId: string) => {
    const fileList = await openFileDialog();
    if (fileList === null || fileList.length < 1) return;
    const [file] = fileList;

    await importForecastingBudget({
      forecastId,
      budgetId,
      file,
    });

    invalidateImportedBudget(budgetId);
  };

  return [importFile];
};
