import { cn } from '@/shared/lib/css/cn';
import { useQueryParams } from '@/shared/lib/hooks/useNavigation';
import { mapListToIds } from '@/shared/lib/listHelpers';
import { FeedMessageModal } from '@/widgets/core/feedMessageModal';
import { navigate, RouteComponentProps } from '@reach/router';
import InvestmentObjectInfo from 'bundles/InvestmentObjects/components/Overview/InvestmentObjectInfo';
import {
  useGetFeedMessagesQuery,
  useGetFeedTypesQuery,
} from 'bundles/InvestmentObjects/components/Overview/Posts/api/feedMessagesApi';
import {
  useHeavyUpdateAssetMutation,
  useSimpleUpdateAssetMutation,
} from 'bundles/Settings/components/Portal/AssetDashboard/api/coreAssetsApi';
import {
  useHeavyUpdateFundMutation,
  useSimpleUpdateFundMutation,
} from 'bundles/Settings/components/Portal/FundDashboard/api/coreFundsApi';
import { useEntityContext } from 'bundles/Shared/EntityContext';
import { BadgeSwitch } from 'bundles/Shared/components/BadgeSwitch';
import {
  ASSET_PORTAL_PRODUCT_NAME,
  currentUserAllowedTo,
  FUND_PORTAL_PRODUCT_NAME,
} from 'lib/permissions';
import { uniqBy } from 'lodash-es';
import React, { useEffect, useState } from 'react';
import { AnimationLoader } from 'stories/AnimationLoader/AnimationLoader';
import { Button } from 'stories/Button/Button';
import { Icon } from 'stories/Icon/Icon';
import { LoadMoreButton } from 'stories/LoadMoreButton/LoadMoreButton';
import { Modal } from 'stories/Modals/Modal/Modal';
import EmailNotificationForm from '@/bundles/InvestmentObjects/components/Overview/FeedMessage/EmailNotificationForm';
import NewFeedMessage from '@/bundles/InvestmentObjects/components/Overview/Posts/NewFeedMessage';
import Post from '@/bundles/InvestmentObjects/components/Overview/Posts/Post';

const Posts = ({
  simpleUpdate,
  heavyUpdate,
  withoutAddress,
  lastColumn,
  showKeyInfo,
}: RouteComponentProps & {
  simpleUpdate:
    | ReturnType<typeof useSimpleUpdateAssetMutation>[0]
    | ReturnType<typeof useSimpleUpdateFundMutation>[0];
  heavyUpdate?:
    | ReturnType<typeof useHeavyUpdateAssetMutation>[0]
    | ReturnType<typeof useHeavyUpdateFundMutation>[0];
  withoutAddress?: boolean;
  lastColumn?: React.ReactNode;
  showKeyInfo?: boolean;
}) => {
  const [page, setPage] = useState(1);
  const investmentEntity = useEntityContext();

  const { data: feedTypesData, isFetching: isFeedTypesDataFetching } =
    useGetFeedTypesQuery({
      feed_messageable_id: investmentEntity.entity.id,
      feed_messageable_type: investmentEntity.type,
    });

  const feedTypes = feedTypesData ?? [];

  // todo: move this to one place
  const productName =
    investmentEntity.type === 'Asset'
      ? ASSET_PORTAL_PRODUCT_NAME
      : FUND_PORTAL_PRODUCT_NAME;
  const canManage = currentUserAllowedTo(
    'manage',
    productName,
    investmentEntity.type,
    investmentEntity.entity.id,
  );
  const [currentFeedMessage, setCurrentFeedMessage] = useState();
  const [sendEmailModalOpened, setSendEmailModalOpened] = useState(false);
  const [currentFeedTypes, setCurrentFeedTypes] = useState<typeof feedTypes>(
    [],
  );

  const {
    data: feedMessagesData,
    isLoading: isFeedMessagesDataLoading,
    isFetching: isFeedMessagesDataFetching,
    refetch,
  } = useGetFeedMessagesQuery({
    feed_messageable_id: investmentEntity.entity.id,
    feed_messageable_type: investmentEntity.type,
    feed_type_ids: mapListToIds(currentFeedTypes),
    page,
  });

  type FeedMessage = NonNullable<typeof feedMessagesData>['items'][number];

  const [currentFeedMessageList, setCurrentFeedMessageList] = useState<
    FeedMessage[]
  >([]);

  const feedMessagesWithMeta = feedMessagesData!;
  const feedMessages = currentFeedMessageList;
  const meta = feedMessagesData?.meta;
  const feedMessageId = useQueryParams().feed_message_id;

  useEffect(() => {
    if (feedMessagesData == null) return;

    setCurrentFeedMessageList((prev) =>
      page !== 1
        ? uniqBy([...prev, ...feedMessagesData.items], 'id')
        : feedMessagesData.items,
    );
  }, [feedMessagesData]);

  useEffect(() => {
    refetch();
  }, [currentFeedTypes]);

  const updateFeedMessagesList = (data: {
    item: FeedMessage;
    meta: {
      totalSize: number;
      replyRecipients: unknown[];
    };
  }) => {
    const index = currentFeedMessageList.findIndex(
      (item) => item.id === data.item.id,
    );
    const newFeedMessages = {
      meta: data.meta || meta,
      items:
        index === -1
          ? [data.item, ...feedMessages]
          : feedMessages.map((item, i) => (i === index ? data.item : item)),
    };

    setCurrentFeedMessageList(newFeedMessages.items);
  };

  const deleteFeedMessageFromList = (id: FeedMessage['id']) => {
    setCurrentFeedMessageList((prev) => prev.filter((item) => item.id !== id));
  };

  if (isFeedMessagesDataLoading || isFeedTypesDataFetching)
    return <AnimationLoader className="static" />;

  const filteredFeedMessagesList = feedMessages.map((feedMessage) => (
    <Post
      key={feedMessage.id}
      feedMessage={feedMessage}
      feedTypes={feedTypes}
      updateFeedMessagesList={updateFeedMessagesList}
      deleteFeedMessageFromList={deleteFeedMessageFromList}
      setCurrentFeedMessage={setCurrentFeedMessage}
      setSendEmailModalOpened={setSendEmailModalOpened}
    />
  ));

  const toggleFeedTypes = (feedType: (typeof currentFeedTypes)[number]) => {
    const newFeedTypes = currentFeedTypes
      .map(({ id }) => id)
      .includes(feedType.id)
      ? [...currentFeedTypes].filter((ft) => ft.id !== feedType.id)
      : [...currentFeedTypes, feedType];

    setCurrentFeedTypes(newFeedTypes);
  };
  return (
    <div
      className={cn(
        'grid lg:gap-6',
        showKeyInfo &&
          'max-w-[initial] grid-cols-[430px_1fr] place-self-stretch',
        'w-full max-w-[1200px] justify-self-center 3xl:max-w-[initial] 3xl:grid-cols-[430px_1fr] 3xl:place-self-stretch',
      )}
    >
      {feedMessageId && (
        <FeedMessageModal
          id={feedMessageId}
          onClose={() => navigate(window.location.pathname)}
        />
      )}
      <div className={cn('hidden 3xl:flex', showKeyInfo && 'flex')}>
        <InvestmentObjectInfo
          object={investmentEntity.entity}
          update={simpleUpdate}
          heavyUpdateAddress={heavyUpdate}
          withoutAddress={withoutAddress}
        />
      </div>
      <div className="flex flex-col gap-4">
        {canManage && (
          <NewFeedMessage
            updateFeedMessagesList={updateFeedMessagesList}
            object={investmentEntity.entity}
            setCurrentFeedMessage={setCurrentFeedMessage}
          />
        )}
        <div className="flex flex-wrap items-center justify-between">
          {feedMessages.length > 0 && (
            <div className="flex flex-wrap items-center gap-2">
              {feedTypes.map((feedType) => (
                <BadgeSwitch
                  key={feedType.title}
                  color={feedType.color}
                  label={feedType.title}
                  onClick={() => toggleFeedTypes(feedType)}
                  active={currentFeedTypes
                    .map(({ id }) => id)
                    .includes(feedType.id)}
                />
              ))}
            </div>
          )}
        </div>
        <div className="user-activity word-break relative">
          {isFeedMessagesDataFetching && (
            <AnimationLoader className="z-10 h-full w-full rounded-2xl bg-neutral-000/50" />
          )}
          {filteredFeedMessagesList.length > 0 && (
            <div id="posts" className="flex flex-col gap-4">
              {filteredFeedMessagesList}
              {feedMessages.length < feedMessagesWithMeta.meta.totalSize && (
                <LoadMoreButton
                  isLoading={isFeedMessagesDataFetching}
                  size="m"
                  fluid
                  onClick={() => setPage((prev) => prev + 1)}
                >
                  Load More
                </LoadMoreButton>
              )}
            </div>
          )}

          {!isFeedMessagesDataFetching &&
            filteredFeedMessagesList.length === 0 && (
              <h4 className="mt-31 light-60 text-center font-light">
                No posts yet...
              </h4>
            )}
        </div>
      </div>

      {lastColumn}

      {currentFeedMessage &&
        !sendEmailModalOpened &&
        investmentEntity.entity.aasmState !== 'draft' && (
          <Modal classes={{ body: 'bg-light' }} size="400">
            <div className="mt-m text-center">
              <p className="green-dark-2 inline-regular mb-m flex items-center justify-center">
                <Icon iconName="check" />
                Feed Message successfully created!
              </p>
              <h6 className="dark-60 header6-bold mb-l">
                Would you like notify users via email?
              </h6>
              <div
                className="bg-light-5"
                style={{
                  margin: '0 -1.5rem -[1rem]',
                  padding: '1rem 1.5rem 1rem',
                }}
              >
                <div className="light-60 secondary-regular mb-m">
                  You can always return and complete this step later.
                </div>
                <div className="flex justify-between">
                  <Button
                    fluid
                    variant="secondary"
                    className="mr-l"
                    onClick={() => setCurrentFeedMessage(undefined)}
                  >
                    Skip
                  </Button>
                  <Button
                    fluid
                    variant="success"
                    onClick={() => setSendEmailModalOpened(true)}
                  >
                    Notify
                  </Button>
                </div>
              </div>
            </div>
          </Modal>
        )}
      {currentFeedMessage && sendEmailModalOpened && (
        <Modal
          size="lg"
          toggle={() => {
            setCurrentFeedMessage(undefined);
            setSendEmailModalOpened(false);
          }}
          header={
            <div>
              <div className="light-60 label-regular">
                {investmentEntity.entity.name}
              </div>
              <h6 className="dark-60 header6-bold">New Email Notification</h6>
            </div>
          }
        >
          <EmailNotificationForm
            allReplyRecipients={feedMessagesWithMeta.meta.replyRecipients}
            feedMessage={currentFeedMessage}
            setSendEmailModalOpened={setSendEmailModalOpened}
            setCurrentFeedMessage={setCurrentFeedMessage}
            updateFeedMessagesList={updateFeedMessagesList}
          />
        </Modal>
      )}
    </div>
  );
};

export default Posts;
