import { DialogProps } from '@/shared/lib/hooks/useModal';
import React, { useState } from 'react';
import { Button } from 'stories/Button/Button';
import { Checkbox } from 'stories/Checkbox/Checkbox';
import { Modal } from 'stories/Modals/Modal/Modal';
import { TEST_IDS } from '@/bundles/Settings/components/REport/DataPeriods/PublishLegalEntitiesModal/PublishLegalEntitiesModal';

type Props = DialogProps<{
  hideNextTime: boolean;
}>;

export function ConfirmModal({ onClose, onSubmit }: Props) {
  const [checked, setChecked] = useState(false);

  const handleSubmit = () => {
    onSubmit?.({
      hideNextTime: checked,
    });
  };

  return (
    <Modal
      toggle={onClose}
      classes={{ body: 'flex flex-col items-center' }}
      additionalActions={
        <Button
          data-testid={TEST_IDS.cancelConfirm}
          onClick={onClose}
          variant="secondary"
          fluid
        >
          Back to selecting Legal Entities
        </Button>
      }
    >
      <p className="dark-60 header6-bold mb-m">Please Note</p>
      <p className="light-60 inline-regular mb-xl text-center">
        Any General Ledgers parsed in the
        <br />
        future that contain data for a published period
        <br />
        will by default be published.
      </p>
      <Button
        data-testid={TEST_IDS.confirmSubmit}
        variant="success"
        className="mb-m"
        onClick={handleSubmit}
      >
        Okay, let’s publish!
      </Button>

      <Checkbox
        data-testid={TEST_IDS.hideNextTime}
        onChange={({ target }) => setChecked(target.checked)}
        checked={checked}
        labelClassName="align-self-center"
      >
        Hide for next time.
      </Checkbox>
    </Modal>
  );
}
