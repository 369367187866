import { Button } from '@/stories/Button/Button';
import { CurrencyFormatter } from '@/stories/ValueFormatters/CurrencyFormatter';
import { Input } from '@/stories/FormControls/Inputs/Input/Input';
import { Modal } from '@/stories/Modals/Modal/Modal';
import { ModalActions } from '@/stories/Modals/Modal/ModalActions';
import { ModalHeaderWithSubtitle } from '@/stories/Modals/ModalHeader/ModalHeaderWithSubtitle/ModalHeaderWithSubtitle';
import { MultilineAlert } from '@/stories/MultilineAlert/MultilineAlert';
import React, { useState } from 'react';
import { AccruedPreferredReturn } from '@/entities/return/model/types/accruedPreferredReturn';
import {
  nextAccrualDate,
  statusClass,
} from '@/bundles/REturn/components/Ownership/modals/manageCapitalInvestmentModal/AccruedCards';
import ReactDatePicker from 'react-datepicker';
import dayjs from 'dayjs';
import { STATIC_DATE_PICKER_PROPS } from '@/bundles/REturn/components/Ownership/modals/consts';
import {
  useDeleteApiCapitalInvestmentAccruedPreferredReturnsByIdMutation,
  usePutApiCapitalInvestmentAccruedPreferredReturnsByIdMutation,
} from '@/entities/return/api/capitalInvestmentObjectsEnhancedApi';
import { useModal } from '@/shared/lib/hooks/useModal';
import { capitalize } from 'lodash-es';
import { formatDate } from '@/shared/lib/formatting/dates';
import {
  convertDollarsToCents,
  formatToDateStringForRequest,
} from '@/shared/lib/converters';

export const EditAccruedModal = ({
  onClose,
  row,
  investmentEntityName,
}: {
  row: AccruedPreferredReturn;
  onClose: () => void;
  investmentEntityName: string;
}) => {
  const [formData, setFormData] = useState({
    id: row.id,
    amount: row.amount,
    start_date: row.startDate,
    end_date: row.endDate,
  });

  const [updateAccruedEntry] =
    usePutApiCapitalInvestmentAccruedPreferredReturnsByIdMutation();

  const { confirm } = useModal();

  const handleStop = async (item: AccruedPreferredReturn) => {
    const res = await confirm({
      title: 'Stop automatic Accrued entries',
      size: '400',
      subtitle: (
        <>
          Are you sure you want to stop the automatic Accrued entries for{' '}
          {investmentEntityName}?
          <div className="pt-m">
            Note will not be possible to re-start it later, you will have&nbsp;
            to create a new accrued
          </div>
        </>
      ),
      actions: {
        primaryButton: {
          text: 'Stop Entries',
          variant: 'danger',
        },
        secondaryButton: {
          text: 'Cancel',
          variant: 'secondary',
        },
      },
    });

    if (!res) return;

    updateAccruedEntry({
      id: item.id,
      body: {
        end_date: formatDate(new Date(), 'YYYY-MM-DD'),
      },
    });
  };

  const handleUpdate = () => {
    updateAccruedEntry({
      id: formData.id,
      body: {
        amount_cents: convertDollarsToCents(formData.amount),
        start_date: formatToDateStringForRequest(formData.start_date),
        end_date: formData.end_date
          ? formatToDateStringForRequest(formData.end_date)
          : null,
      },
    });

    onClose();
  };

  const updateButtonDisabled = formData.amount == '' || !formData.start_date;

  const [deleteAccruedPreferredReturn] =
    useDeleteApiCapitalInvestmentAccruedPreferredReturnsByIdMutation();

  const handleRemove = async (item: AccruedPreferredReturn) => {
    const res = await confirm({
      title: 'Remove Accrued',
      size: '400',
      subtitle: `Are you sure you want to remove Accrued for ${investmentEntityName}?`,
      actions: {
        primaryButton: {
          text: 'Remove',
          variant: 'danger',
        },
        secondaryButton: {
          text: 'Cancel',
          variant: 'secondary',
        },
      },
    });

    if (!res) return;

    await deleteAccruedPreferredReturn({
      id: item.id,
    });

    onClose();
  };

  return (
    <Modal
      toggle={onClose}
      size="500"
      header={
        <ModalHeaderWithSubtitle
          title="Accrued Preferred"
          order="subtitle-title"
          subtitle={investmentEntityName}
        />
      }
      classes={{
        body: 'flex gap-4 flex-col',
      }}
      actions={
        <ModalActions alignItems="space-between">
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="success"
            onClick={handleUpdate}
            disabled={updateButtonDisabled}
          >
            Save Updates
          </Button>
        </ModalActions>
      }
    >
      <>
        <div className="rounded-[12px] border bg-white p-16">
          <div className="flex justify-between">
            <div className="text-sm font-semibold text-neutral-850">
              Details
            </div>
            <div className="flex gap-s">
              <div>
                <Button
                  variant="secondary"
                  size="xs"
                  iconName="trash"
                  onClick={() => handleRemove(row)}
                >
                  Delete
                </Button>
              </div>
              <div>
                {row.status === 'active' && (
                  <Button
                    variant="secondary"
                    size="xs"
                    onClick={() => handleStop(row)}
                  >
                    Stop
                  </Button>
                )}
              </div>
            </div>
          </div>
          <div className="flex justify-between pt-16">
            <div>
              <div className="text-xs font-medium text-neutral-500">AMOUNT</div>
              <div className="pt-2 text-sm">
                <CurrencyFormatter
                  value={row.amount}
                  classes={{ wrapper: 'text-neutral-900' }}
                />
              </div>
            </div>
            <div>
              <div className="text-xs font-medium text-neutral-500">PERIOD</div>
              <div className="pt-2 text-sm text-neutral-900">
                {capitalize(row.periodType)}
              </div>
            </div>
            <div>
              <div className="text-xs font-medium text-neutral-500">
                NEXT ACCRUAL DATE
              </div>
              <div className="pt-2 text-sm text-neutral-900">
                {nextAccrualDate(row.periodType, row.endDate)}
              </div>
            </div>
            <div>
              <div className="text-xs font-medium text-neutral-500">STATUS</div>
              <div className={`pt-2 text-sm ${statusClass(row.status)}`}>
                {capitalize(row.status)}
              </div>
            </div>
          </div>
        </div>

        <div className="rounded-[12px] border bg-white p-16">
          {row.status === 'active' && (
            <div>
              <MultilineAlert
                message="Editing the active accrued will update all existing past entries"
                flexibleWidth
              />
            </div>
          )}
          <div>
            <div className="mt-m flex flex-row gap-m">
              <div>
                <label htmlFor="create-title-input">
                  Start Date <span className="red">*</span>
                </label>
                <ReactDatePicker
                  {...STATIC_DATE_PICKER_PROPS}
                  customInput={<Input size="m" rightIcon="calendar" />}
                  selected={
                    formData.start_date
                      ? dayjs(formData.start_date).toDate()
                      : null
                  }
                  onChange={(newDate) => {
                    setFormData((prev) => ({
                      ...prev,
                      start_date: newDate,
                    }));
                  }}
                />
              </div>
              <div>
                <label htmlFor="create-title-input">End Date</label>
                <ReactDatePicker
                  {...STATIC_DATE_PICKER_PROPS}
                  customInput={<Input size="m" rightIcon="calendar" />}
                  selected={
                    formData.end_date ? dayjs(formData.end_date).toDate() : null
                  }
                  disabled={row.status !== 'active'}
                  onChange={(newDate) => {
                    setFormData((prev) => ({
                      ...prev,
                      end_date: newDate,
                    }));
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    </Modal>
  );
};
