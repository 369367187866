import React from 'react';
import canManageObject from 'lib/InvestmentObject';
import { TConfirmFuncProps, useModal } from '@/shared/lib/hooks/useModal';
import InvestmentObjectDetails from '@/bundles/InvestmentObjects/components/Overview/InvestmentObjectInfo/InvestmentObjectDetails';
import RelatedParties from '@/bundles/InvestmentObjects/components/Overview/InvestmentObjectInfo/RelatedParties';
import KeyDealTeamContacts from '@/bundles/InvestmentObjects/components/Overview/InvestmentObjectInfo/KeyDealTeamContacts';
import { ILegalEntitiable } from 'types/LegalEntitiable';

const getConfirmProps = (objectName: string) =>
  ({
    title: `Remove ${objectName}`,
    subtitle: `Are you sure you want to remove ${objectName}?`,
    actions: {
      primaryButton: {
        text: 'Remove',
        variant: 'danger',
      },
      secondaryButton: {
        text: 'Cancel',
        variant: 'secondary',
      },
    },
  }) as const satisfies TConfirmFuncProps;

const InvestmentObjectInfo = ({
  object,
  update,
  heavyUpdateAddress,
  withoutAddress = false,
  disableEdit = false,
}: {
  object: ILegalEntitiable;
  update: (obj: ILegalEntitiable) => void;
  heavyUpdateAddress: (obj: ILegalEntitiable) => void;
  withoutAddress?: boolean;
  disableEdit?: boolean;
}) => {
  const { info, address, website, relatedParties, keyDealTeamContacts } =
    object;

  const { confirm } = useModal();

  const visibleInfoItems = info.filter((i) => i.visible);
  const canManage = canManageObject(object);

  // TODO: create shared component
  const removeConfirmAlert = async (onRemove, object) => {
    const result = await confirm(getConfirmProps(object));
    if (result) {
      onRemove();
    }
  };

  if (
    (!canManage &&
      visibleInfoItems.length === 0 &&
      !website &&
      !address &&
      relatedParties.length === 0 &&
      keyDealTeamContacts.length === 0) ||
    !object
  )
    return null;

  return (
    <div className="asset-info-block h-min w-full rounded-2xl bg-neutral-000 py-2">
      <InvestmentObjectDetails
        object={object}
        withoutAddress={withoutAddress}
        update={update}
        heavyUpdateAddress={heavyUpdateAddress}
        removeConfirmAlert={removeConfirmAlert}
      />
      <RelatedParties
        object={object}
        update={update}
        removeConfirmAlert={removeConfirmAlert}
      />
      <KeyDealTeamContacts
        object={object}
        update={update}
        removeConfirmAlert={removeConfirmAlert}
      />
    </div>
  );
};

export default InvestmentObjectInfo;
