import { useAppDispatch } from '@/shared/lib/hooks/redux';
import * as React from 'react';
import { Button } from '@/stories/Button/Button';
import { createAsset } from '@/bundles/Assets/actions';
import AssetFormModal from '@/bundles/Assets/components/AssetFormModal';
import { useModal } from '@/shared/lib/hooks/useModal';

function CreateAssetButton({
  refetchAssets,
}: {
  refetchAssets: () => Promise<void>;
}) {
  const dispatch = useAppDispatch();
  const { openModal } = useModal();

  const handleCreateAsset = async () => {
    const res = await openModal(AssetFormModal, {});
    if (res != null) {
      await dispatch(createAsset(res));
      refetchAssets();
    }
  };

  return (
    <Button onClick={handleCreateAsset} variant="primary">
      Create New Asset
    </Button>
  );
}

export default CreateAssetButton;
