import { Tab } from 'bundles/REport/components/financials/hooks/useDynamicTabs';
import MenuItemPopover from 'bundles/Shared/components/LeftSidebar/items/MenuItemPopover';
import { DashboardIcon } from 'bundles/Shared/entities/dashboard/ui/DashboardIcon';
import { LeftSidebarMenu } from 'bundles/Shared/shared/ui';
import { DEFAULT_DROPDOWN_OFFSET } from 'stories/Popover/Popover';
import { Icon } from 'stories/Icon/Icon';
import { cn } from '@/shared/lib/css/cn';

// TODO(styles): variants
const COMMON_CLASSES = 'w-max px-4 py-1.5 inline-semibold text-info-055';

const ACTIVE_CLASSES = 'text-neutral-800 border-info-055';

export function TableTab({
  active,
  children,
  className,
  ...props
}: {
  active: boolean;
} & React.HTMLAttributes<HTMLButtonElement>) {
  return (
    <button
      className={cn(
        'py-1.5 inline-semibold w-max border-b-2 border-transparent bg-transparent px-4 text-info-055',
        active && ACTIVE_CLASSES,
        className,
      )}
      {...props}
    >
      {children}
    </button>
  );
}

export function OtherTabs({
  otherTabActive,
  tabItems,
}: {
  otherTabActive: boolean;
  tabItems: Tab[];
}) {
  if (!tabItems.length) return null;

  const menu = (
    <LeftSidebarMenu title="Tables">
      {tabItems.map((item) => (
        <LeftSidebarMenu.Item to={item.path} key={item.path}>
          <DashboardIcon iconName="eagleEyeDashboard" /> {item.label}
        </LeftSidebarMenu.Item>
      ))}
    </LeftSidebarMenu>
  );

  return (
    <MenuItemPopover
      offset={DEFAULT_DROPDOWN_OFFSET}
      template={menu}
      placement="bottom-start"
      hideOnExpandedAreaClick
      maxWidth="fit-content"
    >
      <span
        className={cn(
          'flex items-center gap-1',
          COMMON_CLASSES,
          otherTabActive && ACTIVE_CLASSES,
        )}
      >
        Other{' '}
        <Icon iconName="arrowBottom" className="font-medium text-neutral-450" />
      </span>
    </MenuItemPopover>
  );
}
