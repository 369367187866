import React, { FC, SetStateAction } from 'react';
import { cn } from '@/shared/lib/css/cn';
import FormSwitcher from '@/bundles/Shared/components/GroupForm/FormSwitcher';
import { IFormItem } from '@/bundles/Shared/components/GroupForm/types/types';

interface IFCStepCoreProps {
  formItem: IFormItem;
  value: any;
  formData: object;
  setFormData: SetStateAction<object>;
  loading: string;
  validate: object;
  setValidate: SetStateAction<object>;
  formItems: IFormItem[];
  styles?: {
    containerClassName?: string;
    transparent?: string;
  };
}

const FCStepCore: FC<IFCStepCoreProps> = ({
  formData,
  setFormData,
  loading,
  formItems,
  styles,
  validate,
  setValidate,
}) => {
  return (
    <div>
      {Boolean(formItems.length) && (
        <div
          className={cn(
            'group-form__step-item',
            styles?.containerClassName,
            styles?.transparent && 'group-form__step-item_transparent',
          )}
        >
          {formItems.map((formItem, idx) => (
            <FormSwitcher
              formItem={formItem}
              key={`formItem${idx}`}
              formData={formData}
              value={formData[formItem.fieldName]}
              setFormData={setFormData}
              loading={loading}
              validate={validate}
              setValidate={setValidate}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default FCStepCore;
