import { useGetApiSettingsReportThirdPartyExtractionsConnectionsByConnectionIdSftpExtractionsQuery } from '@/shared/api/settingsReportConnectionsEnhancedApi';
import { DeleteExtractionIconButton } from '@/features/report/connections/deleteExtraction/ui';
import { DeleteExtractionAttachmentIconButton } from '@/features/report/connections/deleteExtractionAttachment/ui';
import { ExportExtractionAttachmentIconButton } from '@/features/report/connections/exportExtractionAttachment/ui';
import { ExtractParams, ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import { useParams } from '@reach/router';
import NoDataOverlay from 'bundles/Shared/components/NoDataOverlay';
import {
  PageParamsPagination,
  PageParamsProvider,
  PageParamsSearch,
  usePageParamsContext,
} from 'bundles/Shared/components/pageParams';
import { ConnectionExtractionCard } from 'bundles/Shared/entities/connection/ui/ConnectionExtractionCard';
import { AttachmentDocumentStatus } from 'bundles/Shared/entities/extraction/model';
import {
  ConnectionFilterByDate,
  ConnectionFilterByDatePageParams,
} from 'bundles/Shared/features/connection/filter/byDate';
import { ReparseFailedExtractionButton } from 'bundles/Shared/features/connection/reparse';
import { formatDate } from '@/shared/lib/formatting/dates';
import { AnimationLoader } from 'stories/AnimationLoader/AnimationLoader';
import { DocumentCard } from 'stories/DocumentCard/DocumentCard';
import { convertToMilliseconds } from '@/shared/lib/converters';

const { fullPath: ROUTE } = ROUTES_ROOT.settings.report.connections.connection;

const SftpExtractionsTable = () => {
  const { pageParams, setPageParams } =
    usePageParamsContext<ConnectionFilterByDatePageParams>();

  const params = useParams<ExtractParams<typeof ROUTE>>();
  const connectionId = Number(params.connectionId);

  const { data: extractions, isLoading } =
    useGetApiSettingsReportThirdPartyExtractionsConnectionsByConnectionIdSftpExtractionsQuery(
      {
        connectionId: Number(params.connectionId),
        ...pageParams,
      },
    );

  return (
    <PageParamsProvider pageParams={pageParams} setPageParams={setPageParams}>
      <div className="flex items-center justify-between">
        <PageParamsPagination
          sizePerPage={extractions?.meta.perPage ?? 50}
          totalSize={extractions?.meta.totalSize ?? 0}
          loading={isLoading}
        />
        <div className="flex items-center gap-2">
          <ConnectionFilterByDate />
          <PageParamsSearch />
        </div>
      </div>
      <div className="relative flex flex-col gap-2">
        {isLoading && <AnimationLoader />}
        {!isLoading && extractions?.items.length === 0 && (
          <NoDataOverlay
            title={'No extractions found for the selected period'}
          />
        )}
        {extractions?.items.map((item) => (
          <ConnectionExtractionCard key={item.id}>
            <ConnectionExtractionCard.Header>
              <div className="flex items-center gap-2">
                {item.status === 'error' && (
                  <ReparseFailedExtractionButton
                    className="hidden group-hover:block"
                    connectionId={connectionId}
                    attachmentIds={item.attachments.map(
                      (attachment) => attachment.id,
                    )}
                  />
                )}
                <div className="inline-semibold whitespace-nowrap text-neutral-850">
                  {formatDate(
                    convertToMilliseconds((item?.syncedAt ?? 0) as UnixTime),
                    'MMMM DD, YYYY h:mm A',
                  )}
                </div>
              </div>
              {item.canBeDeleted && (
                <DeleteExtractionIconButton
                  connectionId={connectionId}
                  extractionId={item.id}
                  extractionType="sftp"
                />
              )}
            </ConnectionExtractionCard.Header>
            <div className="rounded-2xl bg-white">
              {item.attachments.map((attachment) => (
                <div
                  key={attachment.id}
                  className="border-b border-neutral-100 last:border-none"
                >
                  <DocumentCard
                    filename={attachment.name}
                    fileSize={attachment.size}
                    fileExtension={attachment.extension}
                    className="border-0"
                    documentStatus={{
                      successStatus: AttachmentDocumentStatus.SUCCESS,
                      attentionStatus: AttachmentDocumentStatus.SKIPPED,
                      attentionTitle: attachment.error ?? 'Skipped',
                      failedStatus: AttachmentDocumentStatus.FAILED,
                      failedTitle: attachment.error ?? 'Parsed Error',
                      currentStatus:
                        attachment.status ??
                        AttachmentDocumentStatus.PROCESSING,
                    }}
                    actions={
                      <>
                        <ExportExtractionAttachmentIconButton
                          fileUrl={attachment.fileUrl}
                        />
                        {attachment.canBeDeleted && (
                          <DeleteExtractionAttachmentIconButton
                            connectionId={connectionId}
                            attachmentId={attachment.id}
                          />
                        )}
                      </>
                    }
                  />
                </div>
              ))}
            </div>
          </ConnectionExtractionCard>
        ))}
      </div>
    </PageParamsProvider>
  );
};

export default SftpExtractionsTable;
