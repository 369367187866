import {
  useGetApiReconcileUnderwritingBudgetsByBudgetIdFinancialCategoriesQuery,
  usePutApiReconcileUnderwritingBudgetsByBudgetIdFinancialCategoriesMutation,
} from 'bundles/REconcile/underwritting/api/reconcileUnderwritingBudgetEnhancedApi';
import { LegalEntityUnderwritingBudget } from 'bundles/REconcile/underwritting/api/reconcileUnderwritingBudgetGeneratedApi';
import FlyBottomPanelBar from 'bundles/Settings/components/REport/CategoriesList/PathBar/FlyBottomPanelBar';
import FlyBottomPanelContainer from 'bundles/Settings/components/REport/CategoriesList/PathBar/FlyBottomPanelContainer';
import { SearchableTree } from 'bundles/Settings/components/REport/CategoriesList/SearchableTree/SearchableTree';
import { DialogProps, useModal } from '@/shared/lib/hooks/useModal';
import { mapListToIds } from '@/shared/lib/listHelpers';
import { TreeData, transformTree } from 'lib/treeHelpers';
import { assertsQueryDataLoadedBefore } from 'lib/typeHelpers/redux/rtkApiHelpers';
import pluralize from 'pluralize';
import { useMemo, useState } from 'react';
import { DatumNode, TreeViewProps } from 'stories/TreeView/TreeView';
import { Button } from 'stories/Button/Button';
import { IconButton } from 'stories/IconButton/IconButton';
import { Modal } from 'stories/Modals/Modal/Modal';
import { TreeView } from 'stories/TreeView/TreeView';

const CategoryModal = <N extends DatumNode>({
  onClose,
  onSubmit,
  initialCategories,
  ...treeViewProps
}: DialogProps<number[]> &
  TreeViewProps<N> & {
    initialCategories: { id: number }[];
  }) => {
  const [selected, setSelected] = useState<N[]>(initialCategories);

  return (
    <Modal
      toggle={onClose}
      size="huge"
      classes={{
        body: 'p-0 grid grid-cols-[300px_1fr]',
      }}
      header={
        <div className="flex flex-col">
          <h6 className="header6-bold">Set category</h6>
        </div>
      }
    >
      <SearchableTree
        data={treeViewProps.data}
        selectedListIds={mapListToIds(selected)}
        onSelect={(item) => {
          setSelected((prev) => {
            if (prev.find((i) => i.id === item.id)) {
              return prev.filter((i) => i.id !== item.id);
            }

            return [...prev, item];
          });
        }}
      />
      <TreeView<N>
        showMultipleRootsParent
        multipleRootsParentName="Categories"
        expandOnSelection
        everyNodeWithCheckbox
        selected={selected}
        onSelectedChange={(s) => {
          setSelected((prev) => {
            if (prev.find((v) => v.id === s.id)) {
              return prev.filter((v) => v.id !== s.id);
            }

            return [...prev, s];
          });
        }}
        {...treeViewProps}
      />
      {selected.length > 0 && (
        <FlyBottomPanelContainer>
          <FlyBottomPanelBar
            onClose={() => {
              setSelected([]);
            }}
          >
            <span className="mr-1 text-neutral-400">Selected:</span>
            <span className="text-neutral-000">
              {selected.length} {pluralize('Categories', selected.length)}
            </span>
          </FlyBottomPanelBar>
          <Button
            variant="success"
            size="s"
            onClick={() => onSubmit?.(mapListToIds(selected) as number[])}
          >
            Set Category
          </Button>
        </FlyBottomPanelContainer>
      )}
    </Modal>
  );
};

export function UpdateFinancialRow({
  id: budgetId,
}: Pick<NonNullish<LegalEntityUnderwritingBudget['budget']>, 'id'>) {
  const { data, isFetching } =
    useGetApiReconcileUnderwritingBudgetsByBudgetIdFinancialCategoriesQuery({
      budgetId,
    });
  const { openModal } = useModal();

  const [updateFinancialsCategories] =
    usePutApiReconcileUnderwritingBudgetsByBudgetIdFinancialCategoriesMutation();

  const initialCategoriesSet = useMemo(() => {
    if (data == null) return [];

    const set = new Set<{ id: number }>();

    TreeData.deepFirst(data.tree, (i) => {
      if (i.budgetFinancialCategory) {
        set.add({ id: i.id });
      }
      return false;
    });

    return set;
  }, [data]);

  const handleClick = async () => {
    assertsQueryDataLoadedBefore(data);

    const trees = data.tree.map((tree) =>
      transformTree(tree, (c) => ({
        ...c,
        name: c.code,
        title: c.code,
        locked: c.classificationMismatch,
      })),
    );
    const res = await openModal(CategoryModal, {
      data: trees,
      initialCategories: [...initialCategoriesSet],
    });

    if (res == null) return;

    await updateFinancialsCategories({
      budgetId,
      body: {
        financial_category_ids: res,
      },
    });
  };
  return (
    <div className="flex w-full items-center justify-between gap-4">
      Financials Categories
      <IconButton
        onClick={handleClick}
        isLoading={isFetching}
        iconName="settings"
      />
    </div>
  );
}
