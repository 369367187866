import React, { useEffect, useRef, useState } from 'react';
import { Modal } from 'stories/Modals/Modal/Modal';
import { ModalHeaderWithSubtitle } from 'stories/Modals/ModalHeader/ModalHeaderWithSubtitle/ModalHeaderWithSubtitle';
import DocumentItem from '@/bundles/Shared/components/DocumentItem';
import LoadingPanel from '@/bundles/Shared/components/LoadingPanel';

const EmailMessageSnapshotModal = ({
  emailMessageId,
  fetchSnapshot,
  subject,
  onClose,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [emailBody, setEmailBody] = useState();
  const [attachments, setAttachments] = useState([]);
  const [height, setHeight] = useState('0px');

  const frameRef = useRef();
  const onLoad = () => {
    setHeight(
      `${frameRef.current.contentWindow.document.body.scrollHeight + 20}px`,
    );
  };

  useEffect(() => {
    setIsLoading(true);

    fetchSnapshot()
      .then((res) => res.json())
      .then((json) => {
        setEmailBody(json.body);
        setAttachments(json.attachments);
      })
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <Modal
      size="lg"
      toggle={onClose}
      header={
        <ModalHeaderWithSubtitle
          order="title-subtitle"
          title="Email Preview"
          subtitle={subject}
        />
      }
      classes={{
        body: '!p-0',
      }}
    >
      {isLoading ? (
        <LoadingPanel />
      ) : (
        <iframe
          style={{ pointerEvents: 'none' }}
          ref={frameRef}
          onLoad={onLoad}
          className="w-100"
          seamless="seamless"
          height={height}
          title={emailMessageId}
          srcDoc={emailBody}
        />
      )}
      {attachments.length > 0 && (
        <div className="flex flex-col flex-grow mt-4 p-m">
          <div className="flex items-center">
            <h6 className="header6-regular text-[1rem]">Attached Documents</h6>
            <div className="ml-[0.5rem] bg-light-5 border-radius-[0.5rem] w-[1.5rem] flex items-center justify-center">
              <span className="dark-60 inline-regular">
                {attachments.length}
              </span>
            </div>
          </div>
          <div className="mt-4">
            {attachments.map((attachment) => (
              <div className="card card-light border-radius-[0.5rem] p-[0.5rem]">
                <DocumentItem
                  key={attachment.id}
                  id={attachment.id}
                  filename={attachment.filename}
                  filenameClassName="inline-semibold dark-60"
                  fileSize={attachment.size}
                  fileExtension={attachment.extension}
                />
              </div>
            ))}
          </div>
        </div>
      )}
    </Modal>
  );
};

export default EmailMessageSnapshotModal;
