import { EntityToastrCreator } from '@/shared/lib/toastr/entityToastrCreator';
import { investmentEntityGeneratedApi } from '@/shared/api/investmentEntityGeneratedApi';
const INVESTMENT_ENTITY_TAG = 'InvestmentEntity';

const etc = new EntityToastrCreator('Investment Entity');
const bulkDeleteToastr = new EntityToastrCreator('Investment Entities');

export const investmentEntityEnhancedApi =
  investmentEntityGeneratedApi.enhanceEndpoints({
    addTagTypes: [INVESTMENT_ENTITY_TAG],
    endpoints: {
      getApiInvestmentEntities: {
        providesTags: [INVESTMENT_ENTITY_TAG],
      },
      putApiInvestmentEntitiesById: {
        invalidatesTags: [INVESTMENT_ENTITY_TAG],
        transformResponse: (response) => {
          if (!response.errors) {
            toastr.success(etc.update().toString());
          } else {
            toastr.error(response.errors.join(', '));
          }
          return response;
        },
      },
      postApiInvestmentEntities: {
        invalidatesTags: [INVESTMENT_ENTITY_TAG],
        transformResponse: (response) => {
          if (!response.errors) {
            toastr.success(etc.create().toString());
          } else {
            toastr.error(response.errors.join(', '));
          }
          return response;
        },
      },
      deleteApiInvestmentEntitiesBulkDestroy: {
        invalidatesTags: [INVESTMENT_ENTITY_TAG],
        transformResponse: () => {
          toastr.success(bulkDeleteToastr.delete().toString());
        },
      },
    },
  });

export const {
  useGetApiInvestmentEntitiesQuery,
  usePutApiInvestmentEntitiesByIdMutation,
  useDeleteApiInvestmentEntitiesBulkDestroyMutation,
} = investmentEntityEnhancedApi;
