import { ROUTES_ROOT, generateUrl } from '@/shared/lib/hooks/useNavigation';

import NavigationCardList from '@/bundles/Shared/components/layouts/screenWithListNavigationLayout/NavigationCardList';
import { FormulaCard } from '@/bundles/Shared/entities/formula';
import {
  isThresholdSpecified,
  useReportThresholds,
} from '@/entities/report/threshold/lib';
import { ThresholdGlobalValue } from '@/entities/report/threshold/ui/ThresholdGlobalValue';
import SettingsReportThresholdsThreshold from '@/pages/settings/report/thresholds/[thresholdKind]/page';
import { useNavigateToFirst } from '@/shared/lib/hooks/useNavigateToFirst';
import { ResizablePanel } from '@/shared/ui/Resizable';
import { EntityItemList } from '@/widgets/report/manualVariable/settings/ui/ManualVariablesList';
import { useNavigate } from '@reach/router';
import { useMemo } from 'react';
import { ReportThreshold } from '@/entities/report/threshold/model';
import { Icon } from '@/stories/Icon/Icon';

export const ReportThresholdsPage = () => {
  const navigate = useNavigate();
  const { thresholds, isLoading, isFetching } = useReportThresholds();

  const { idFromURL: thresholdKindFromURL } = useNavigateToFirst({
    items: thresholds,
    idKey: 'kind',
    matchParamKey: 'thresholdKind',
    path: ROUTES_ROOT.settings.report.thresholds.threshold.fullPath,
  });

  const selectedThreshold = useMemo(() => {
    return thresholds.find(({ kind }) => kind === thresholdKindFromURL);
  }, [thresholds, thresholdKindFromURL]);

  const navigateToThreshold = (thresholdKind: string) => {
    navigate(
      generateUrl(ROUTES_ROOT.settings.report.thresholds.threshold.fullPath, {
        pathParams: { thresholdKind },
      }),
    );
  };

  const renderThresholdCard = (t: ReportThreshold) => {
    return (
      <FormulaCard
        className="!cursor-pointer"
        label={t.title}
        labelInfo={
          <Icon
            tooltipProps={{
              mainText: t.description,
            }}
            iconName="info"
            className="text-neutral-500"
          />
        }
        key={t.kind}
        description={
          isThresholdSpecified(t) ? (
            <ThresholdGlobalValue
              value={t.globalValue}
              valueType={t.valueType}
              classes={{
                allParts: 'header5-regular',
                value: 'text-neutral-850',
              }}
            />
          ) : (
            'Not Specified'
          )
        }
        selected={t.kind === thresholdKindFromURL}
        buttonProps={{
          onClick: () => {
            navigateToThreshold(t.kind);
          },
        }}
      />
    );
  };

  return (
    <div className="flex">
      <ResizablePanel.Group
        autoSaveId="report-thresholds-panel"
        direction="horizontal"
      >
        <ResizablePanel defaultSize={0} className="min-w-[320px] max-w-[800px]">
          <EntityItemList.Wrapper>
            <EntityItemList.Header>
              <EntityItemList.Title>Thresholds</EntityItemList.Title>
            </EntityItemList.Header>
            <NavigationCardList>
              <EntityItemList isFetching={isFetching} isLoading={isLoading}>
                {thresholds.map(renderThresholdCard)}
              </EntityItemList>
            </NavigationCardList>
          </EntityItemList.Wrapper>
        </ResizablePanel>
        <ResizablePanel.Handle withHandle />
        <ResizablePanel>
          {selectedThreshold && (
            <SettingsReportThresholdsThreshold threshold={selectedThreshold} />
          )}
        </ResizablePanel>
      </ResizablePanel.Group>
    </div>
  );
};
