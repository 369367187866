import React, { FC, useState } from 'react';
import { cn } from '@/shared/lib/css/cn';
import { Invoice } from '@/shared/types/reconcile/Invoice';
import AutoUploadingBadge from '@/bundles/DrawPackage/InvoicesFlow/Invoices/invoice/kanban/badges/AutoUploadingBadge';
import TotalBadge from '@/bundles/DrawPackage/InvoicesFlow/Invoices/invoice/kanban/badges/TotalBadge';

interface IKanbanColumnBadgeProps {
  badgeType: 'auto-uploading' | 'total';
  invoices: {
    items: Invoice[];
    meta?: string;
  };
  enabledUploading?: boolean;
}

const KanbanColumnBadge: FC<IKanbanColumnBadgeProps> = ({
  badgeType,
  invoices,
  enabledUploading,
}) => {
  const [active, setActive] = useState(false);
  return (
    <div
      className={cn(
        'mb-1.5 flex h-[32px] items-center rounded-xl px-4 text-white',
        {
          'cursor-pointer': badgeType === 'auto-uploading',
          'bg-teal-dark-4': badgeType === 'auto-uploading',
          'bg-teal-dark-5': badgeType === 'auto-uploading' && active,
          'bg-dark': badgeType === 'total',
        },
      )}
      onMouseEnter={() => setActive(true)}
      onMouseLeave={() => setActive(false)}
    >
      {badgeType === 'total' && (
        <TotalBadge totalAmount={invoices?.meta?.totalAmount} />
      )}
      {badgeType === 'auto-uploading' && (
        <AutoUploadingBadge enabledUploading={enabledUploading} />
      )}
    </div>
  );
};

export default KanbanColumnBadge;
