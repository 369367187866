import { useNavigate } from '@reach/router';
import { fetchReportTableConfig } from 'bundles/Settings/actions/report/tableConfigs';
import { useEffect, useReducer, useState } from 'react';
import { IReportTableConfig } from 'types/ReportTableConfig';
import { prettyJsonStringify } from '@/lib/stringify';

interface State
  extends Pick<
    IReportTableConfig,
    | 'periodsLimitations'
    | 'userFinancialCategoryRules'
    | 'userRoleFinancialCategoryRules'
  > {
  tableConfig: IReportTableConfig | null;
  permissions: IReportTableConfig['permitted'];
  columnsConfig: string;
  rowsConfig: string;
  hasChanges: boolean;
}

type Action =
  | { type: ActionType.RefreshState; payload: IReportTableConfig }
  | { type: ActionType.TableConfig; payload: IReportTableConfig }
  | { type: ActionType.Permission; payload: IReportTableConfig['permitted'] }
  | { type: ActionType.PeriodLimitaions; payload: State['periodsLimitations'] }
  | { type: ActionType.ColumnsConfig; payload: State['columnsConfig'] }
  | { type: ActionType.RowsConfig; payload: State['rowsConfig'] }
  | {
      type: ActionType.UserFinancialCategoryRules;
      payload: State['userFinancialCategoryRules'];
    }
  | { type: ActionType.HasChanges; payload: State['hasChanges'] }
  | {
      type: ActionType.UserRoleFinancialCategoryRules;
      payload: State['userRoleFinancialCategoryRules'];
    };

export enum ActionType {
  RefreshState = 'full-refresh',
  TableConfig = 'update-table-config',
  Permission = 'update-permission',
  PeriodLimitaions = 'update-period-limitations',
  ColumnsConfig = 'update-columns-config',
  RowsConfig = 'update-rows-config',
  UserFinancialCategoryRules = 'update-user-financial-category-rules',
  UserRoleFinancialCategoryRules = 'update-user-role-financial-category-rules',
  HasChanges = 'has-changing',
}

function reducer(state: State, { type, payload }: Action): State {
  switch (type) {
    case ActionType.RefreshState: {
      const tableConfig = payload;

      return {
        tableConfig,
        permissions: tableConfig.permitted,
        periodsLimitations: tableConfig.periodsLimitations ?? {},
        columnsConfig: prettyJsonStringify(tableConfig.columnsConfig),
        rowsConfig: prettyJsonStringify(tableConfig.rowsConfig),
        userFinancialCategoryRules: tableConfig.userFinancialCategoryRules,
        userRoleFinancialCategoryRules:
          tableConfig.userRoleFinancialCategoryRules,
        hasChanges: false,
      };
    }

    case ActionType.TableConfig: {
      return {
        ...state,
        tableConfig: payload,
      };
    }

    case ActionType.Permission: {
      return {
        ...state,
        permissions: payload,
      };
    }

    case ActionType.PeriodLimitaions: {
      return {
        ...state,
        periodsLimitations: payload,
      };
    }

    case ActionType.ColumnsConfig: {
      return {
        ...state,
        columnsConfig: payload,
      };
    }

    case ActionType.RowsConfig: {
      return {
        ...state,
        rowsConfig: payload,
      };
    }

    case ActionType.UserFinancialCategoryRules: {
      return {
        ...state,
        userFinancialCategoryRules: payload,
      };
    }

    case ActionType.UserRoleFinancialCategoryRules: {
      return {
        ...state,
        userRoleFinancialCategoryRules: payload,
      };
    }

    case ActionType.HasChanges: {
      return {
        ...state,
        hasChanges: payload,
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
}

export const initialReportTableConfigPermitted: IReportTableConfig['permitted'] =
  {
    allUsers: [],
    directUsers: [],
    directTags: [],
    directRoles: [],
    public: false,
  };

const initialState: State = {
  tableConfig: null,
  permissions: initialReportTableConfigPermitted,
  periodsLimitations: {},
  columnsConfig: '[]',
  rowsConfig: '[]',
  userFinancialCategoryRules: [],
  userRoleFinancialCategoryRules: [],
  hasChanges: false,
};

export function useReportTableConfigReducer(
  tableSlug: IReportTableConfig['slug'],
) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const dispatchRefreshState = (newTableConfig?: IReportTableConfig) => {
    if (!newTableConfig) return;

    dispatch({
      type: ActionType.RefreshState,
      payload: newTableConfig,
    });
  };

  const goBack = () => navigate('..');

  useEffect(() => {
    const get = async () => {
      setIsLoading(true);
      try {
        const reportTableConfig = await fetchReportTableConfig(tableSlug);
        dispatchRefreshState(reportTableConfig);
      } catch (error) {
        console.error(error);
        goBack();
      } finally {
        setIsLoading(false);
      }
    };
    get();
  }, []);

  return {
    state,
    isLoading,
    setIsLoading,
    dispatch,
    dispatchRefreshState,
  };
}
