import React from 'react';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import { IUser } from 'types/User';
import MirrorModeCard from '@/bundles/Shared/components/LeftSidebar/cards/MirrorModeCard';
import { SidePanel } from 'stories/Modals/Modal/Modal';

interface Props extends DialogProps {
  users: IUser[];
}

const MirrorModeModal = ({ onClose, users }: Props) => (
  <SidePanel toggle={onClose} size="md" header="Mirror Mode">
    <MirrorModeCard users={users} maxHeight="none" />
  </SidePanel>
);

export default MirrorModeModal;
