import React, { useEffect, useState } from 'react';
import { navigate, useParams } from '@reach/router';
import FilePreview from 'bundles/Shared/components/FilePreviews/FilePreview';
import {
  useCreateDrawMutation,
  reconcileInvoiceApi,
} from 'bundles/Construction/api/invoices';
import { API_URL } from 'lib/http';
import { directUpload, presignFiles } from 'lib/uploadFiles';
import {
  useNavigation,
  ROUTES_ROOT,
  ExtractParams,
} from '@/shared/lib/hooks/useNavigation';
import PreSelectDraw from '@/bundles/DrawPackage/DrawPage/components/PreSelectDraw';
import DrawPageSummary from '@/bundles/DrawPackage/DrawPage/components/DrawPageSummary';
import { usePDF } from '@react-pdf/renderer';
import { useAppDispatch } from '@/shared/lib/hooks/redux';
import PDFMerger from 'pdf-merger-js';
import { resetFundingSource } from 'bundles/DrawPackage/slices/ReconcileDrawSlice';
import DrawFundingSource from '@/bundles/DrawPackage/DrawPage/widgets/DrawFundingSource';
import {
  FundingSource,
  FundingSourceForDraw,
} from '@/shared/types/reconcile/FundingSource';
import { TSpecifyReallocationAmount } from 'bundles/DrawPage/widgets/SpecifySourcesModal';

const DrawCreateModal = () => {
  const { fullPath: ROUTE } =
    ROUTES_ROOT.reconcile.development.legalEntity.draws;
  const params = useParams<ExtractParams<typeof ROUTE>>();
  const { getUrl } = useNavigation();
  const [pdfDrawLink, setPdfDrawLink] = useState<Buffer | string | null>(null);
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [formData, setFormData] = useState<{
    requisitionId?: UUID;
  }>({});

  const dispatch = useAppDispatch();

  const [drawPageData, setDrawPageData] = useState(null);

  const [instance, updateInstance] = usePDF({
    document: <DrawPageSummary data={drawPageData} />,
  });

  const [createDraw, { isSuccess: isSuccessCreating }] =
    useCreateDrawMutation();

  useEffect(() => {
    dispatch(resetFundingSource());
  }, [formData]);

  useEffect(() => {
    if (instance.url && pdfDrawLink) {
      (async () => {
        const merger = new PDFMerger();
        await merger.add(instance.url ?? '');
        await merger.add(pdfDrawLink ?? '');
        const mergedPdf = await merger.saveAsBuffer();
        setPdfDrawLink(mergedPdf);
        setLoadingPdf(false);
      })();
    }
  }, [instance]);

  const onFormDataChange = async (
    e: { requisitionId: UUID },
    fundingSources?: Pick<FundingSource, 'id' | 'amount'>,
  ) => {
    setLoadingPdf(true);

    // clearing previous pdf
    setDrawPageData(null);
    setPdfDrawLink(null);

    setFormData(e);
    const res = await dispatch(
      reconcileInvoiceApi.endpoints.generateDrawPreview.initiate({
        legalEntityCode: params.legalEntityCode,
        requisitionId: e.requisitionId,
        fundingSources,
      }),
    );
    setPdfDrawLink(
      `${API_URL}/api/reconcile/development/legal_entities/${params.legalEntityCode}/draws/preview?requisition_id=${e.requisitionId}`,
    );
    setDrawPageData(res.data);
  };

  if (drawPageData) {
    updateInstance();
  }

  const createDrawRequest = async (
    selectedFundingSources: (FundingSourceForDraw &
      TSpecifyReallocationAmount)[],
  ) => {
    const merger = new PDFMerger();
    await merger.add(instance.url ?? '');
    await merger.add(pdfDrawLink ?? '');
    const mergedPdf = await merger.saveAsBlob();

    const presignedFiles = await presignFiles([mergedPdf]);

    await Promise.all(presignedFiles.map((file) => directUpload(file)));
    const files = presignedFiles.map((f) => ({
      title: 'Draw Request',
      key: f.signedData.fields.key,
      filename: 'Draw Request',
      content_type: f.file.type,
      size: f.file.size,
    }));
    await createDraw({
      legalEntityCode: params.legalEntityCode,
      body: {
        requisition_id: formData.requisitionId ?? '',
        funding_sources: selectedFundingSources.map((item) => ({
          id: item.id,
          amount: item.reallocation,
        })),
        document: {
          file: files[0],
        },
      },
    });
    return false;
  };

  if (isSuccessCreating) {
    navigate(
      getUrl('RECONCILE_DEVELOPMENT_LEGAL_ENTITY_DRAWS', {
        legalEntityCode: params.legalEntityCode,
      }),
    );
  }

  return (
    <div>
      <FilePreview
        file={{
          url: pdfDrawLink,
          preSelectComponent: <PreSelectDraw />,
        }}
        handleClose={() =>
          navigate(
            getUrl('RECONCILE_DEVELOPMENT_LEGAL_ENTITY_DRAWS', {
              legalEntityCode: params.legalEntityCode,
            }),
          )
        }
        header={
          <div>
            <div className="dark-60 font-weight-700 word-break header6-bold">
              Create Draw Request
            </div>
          </div>
        }
        withRequestHeader
        loadingPdf={loadingPdf}
      >
        <DrawFundingSource
          createDrawRequest={createDrawRequest}
          onFormDataChange={onFormDataChange}
          formData={formData}
          pdfDrawLink={pdfDrawLink}
          loadingPdf={loadingPdf}
        />
      </FilePreview>
    </div>
  );
};

export default DrawCreateModal;
