import InvestmentObjectInfo from 'bundles/InvestmentObjects/components/Overview/InvestmentObjectInfo';
import { HUGE_MODAL_Z_INDEX } from 'bundles/Shared/constants';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import { ModalHeaderWithIcon } from 'stories/Modals/ModalHeader/ModalHeaderWithIcon/ModalHeaderWithIcon';
import { IAsset } from 'types/Asset';
import { IFund } from 'types/Fund';
import { SidePanel } from 'stories/Modals/Modal/Modal';

function KeyInfoModal({
  object: oldObject,
  onClose,
  simpleUpdate,
  heavyUpdate,
}: DialogProps<boolean> & {
  object: IAsset | IFund;
  simpleUpdate: (...params: unknown[]) => void;
  heavyUpdate: (...params: unknown[]) => void;
}) {
  return (
    <SidePanel
      toggle={onClose}
      maxHeight
      classes={{
        body: 'bg-neutral-000 !px-2 !py-0 flex flex-col gap-4',
      }}
      zIndex={HUGE_MODAL_Z_INDEX - 1}
      header={<ModalHeaderWithIcon title="Key Info" />}
    >
      <InvestmentObjectInfo
        object={oldObject}
        update={simpleUpdate}
        heavyUpdateAddress={heavyUpdate}
      />
    </SidePanel>
  );
}

export default KeyInfoModal;
