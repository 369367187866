import type { AssetsReportBuilderReportDto } from '@/entities/report/reportBuilder/api/coreAssetsReportBuilderReportsGeneratedApi';
import { useReportingEntityKindContext } from '@/entities/reporting/context/entityKind';
import { useAssetIdSearchParam } from '@/shared/lib/hooks/navigation/useAssetIdSearchParam';
import { generateUrl, ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import { useNavigate } from '@reach/router';

export const useReportBuilderNavigateToViewPage = () => {
  const reportKind = useReportingEntityKindContext();
  const assetId = useAssetIdSearchParam();
  const navigate = useNavigate();

  const handleNavigate = (reportId: AssetsReportBuilderReportDto['id']) => {
    if (reportKind === 'eagle_eye') {
      return navigate(
        generateUrl(ROUTES_ROOT.reports.eagleEye.report.view.fullPath, {
          pathParams: {
            reportId,
          },
        }),
      );
    }
    return navigate(
      generateUrl(ROUTES_ROOT.reports.report.view.fullPath, {
        pathParams: {
          id: reportId,
        },
        queryParams: {
          assetId,
        },
      }),
      {
        replace: true,
      },
    );
  };

  return handleNavigate;
};
