import { Button } from '@/stories/Button/Button';
import React from 'react';
import { AccruedAmountValuesEditor } from '@/bundles/REturn/components/Ownership/modals/addAccruedModal/AccruedAmountValuesEditor';
import { CapitalInvestment } from '@/entities/return';

export const EnterAccruedAmountValuesStep = ({
  selectedCapitalInvestments,
  setSelectedCapitalInvestments,
}: {
  selectedCapitalInvestments: CapitalInvestment[];
  setSelectedCapitalInvestments: (value: CapitalInvestment[]) => void;
}) => {
  const onReset = () => {
    setSelectedCapitalInvestments(
      selectedCapitalInvestments.map((item) => ({
        ...item,
        entries: item.entries.map((entry) => ({
          ...entry,
          amount: entry.initialAmount,
        })),
      })),
    );
  };

  return (
    <>
      <div className="flex justify-between gap-l">
        <div>
          <span className="inline-semibold text-dark-80">
            Enter your Accrued Preferred below:
          </span>
          <span className="text-danger-055"> *</span>
          <div className="inline-regular mb-24 mt-xs text-sm text-neutral-650">
            Some investment entity has multiple contributions please allocate
            Accrued pref.
          </div>
        </div>
        <div>
          <Button variant="secondary" size="xs" onClick={onReset}>
            Reset
          </Button>
        </div>
      </div>
      <div>
        {selectedCapitalInvestments && (
          <AccruedAmountValuesEditor
            selectedCapitalInvestments={selectedCapitalInvestments}
            setSelectedCapitalInvestments={setSelectedCapitalInvestments}
          />
        )}
      </div>
    </>
  );
};
