import { formatUnixDate } from '@/shared/lib/formatting/dates';

export const AuthorColumnFormatter = ({
  name,
  unixTime,
}: {
  unixTime: number;
  name: string | undefined;
}) => (
  <div className="flex flex-col gap-2">
    <p>{formatUnixDate(unixTime as UnixTime, 'MMM DD, YYYY h:mm A')}</p>

    {name != null && (
      <p className="secondary-regular text-neutral-550">by {name}</p>
    )}
  </div>
);
