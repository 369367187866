import { JSONEditor } from '@/shared/ui/CodeEditor/component';
import { COMPARISON_DASHBOARD_SECTION_TYPE } from '@/bundles/Shared/entities/dashboard/model/types/types';

import { useJsonEditorJsonValue } from '@/shared/ui/CodeEditor/lib';
import { WidgetSettingsListLayout } from '@/bundles/Shared/components/layouts/dashboard/WidgetLayout';
import NoDataOverlay from '@/bundles/Shared/components/NoDataOverlay';
import { useWorkspaceContext } from '@/bundles/Shared/widgets/formula/panel/ui/FormulasAndVariablesWorkspace';
import Button from '@/stories/Button/Button';
import { updateWidgetConfigFromIntermediateRows } from '@/bundles/Shared/widgets/dashboard/widgets/common/financialTable/config/updaters';
import { transformWidgetConfigToIntermediateRows } from '@/bundles/Shared/widgets/dashboard/widgets/common/financialTable/lib';
import { useCloseBottomPanelOnUnmount } from '@/bundles/Shared/widgets/formula/panel/lib';
import {
  useGetApiSettingsReportTableConfigsQuery,
  useLazyGetApiSettingsReportTableConfigsByIdWidgetConfigurationQuery,
} from '@/bundles/Settings/components/REport/TableBuilder/api/reportTableConfigGeneratedApi';
import Dropdown from '@/stories/Dropdown/Dropdown';
import { DropdownItem } from '@/stories/Dropdown/DropdownItem/DropdownItem';
import { produce } from 'immer';
import {
  useWidgetConfig,
  useUpdateWidgetConfig,
} from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/config';

export const FinancialTableRowsTab = ({
  widgetType,
}: {
  widgetType:
    | typeof COMPARISON_DASHBOARD_SECTION_TYPE.FINANCIAL_TABLE_SINGLE_DATE
    | typeof COMPARISON_DASHBOARD_SECTION_TYPE.FINANCIAL_TABLE_SINGLE_PERIOD;
}) => {
  const { data: tableConfigs } = useGetApiSettingsReportTableConfigsQuery();
  const [getTableConfigById] =
    useLazyGetApiSettingsReportTableConfigsByIdWidgetConfigurationQuery();
  const { widget } = useWidgetConfig<typeof widgetType>();
  const hasRows = widget.widgetConfig.rows?.length > 0;
  const [updateConfig] = useUpdateWidgetConfig(widget.widgetType);
  const { editorProps, isDirty, parsedValue } = useJsonEditorJsonValue(
    transformWidgetConfigToIntermediateRows(widget.widgetConfig),
  );
  const { renderBottomPanel, toggleBottomPanel, bottomPanelOpen } =
    useWorkspaceContext();

  const handleEditTree = () => {
    toggleBottomPanel();
  };

  useCloseBottomPanelOnUnmount();

  const handleSave = () => {
    if (parsedValue == null) return;
    updateConfig({
      config: updateWidgetConfigFromIntermediateRows(
        widget.widgetConfig,
        parsedValue,
      ),
    });
  };

  const handleSelectTableConfig = async (id: number) => {
    const res = await getTableConfigById({
      id: id.toString(),
    });
    if (res.data == null || 'error' in res.data) return;
    updateConfig({
      config: produce(widget.widgetConfig, (draft) => {
        draft.rows = res.data!.config.rows;
        draft.viz_config.rows = res.data!.config.viz_config.rows;
      }),
    });
  };

  return (
    <WidgetSettingsListLayout>
      <NoDataOverlay
        title=""
        subTitle={
          !hasRows
            ? "You haven't configured rows yet"
            : 'You have custom configured rows'
        }
        customDocumentsComponent={
          <Button
            disabled={bottomPanelOpen}
            className="mt-2"
            size="xs"
            onClick={handleEditTree}
          >
            {hasRows ? 'Edit Rows' : 'Configure Rows'}
          </Button>
        }
      />
      {renderBottomPanel(
        <div className="flex h-full flex-col gap-2">
          <div className="flex gap-2">
            <Button
              className="self-start"
              disabled={!isDirty}
              onClick={handleSave}
              variant="success"
            >
              Save Updates
            </Button>
            <Dropdown
              items={
                <>
                  {tableConfigs?.items?.map((config) => (
                    <DropdownItem
                      key={config.id}
                      onClick={() => handleSelectTableConfig(config.id)}
                    >
                      {config.name}
                    </DropdownItem>
                  ))}
                </>
              }
            >
              <Button
                iconName="bottom"
                size="s"
                iconPosition="right"
                variant="secondary"
              >
                Select from Existing Tables
              </Button>
            </Dropdown>
          </div>
          <div className="grow">
            <JSONEditor {...editorProps} />
          </div>
        </div>,
      )}
    </WidgetSettingsListLayout>
  );
};
