import { reportingEnhancedApi } from '@/entities/reporting/api/reportingEnhancedApi';
import type { GetApiReportBuilderTemplatesApiResponse } from '@/entities/reporting/api/reportingGeneratedApi';
import { createSelector } from '@reduxjs/toolkit';
import { useMemo } from 'react';

export const useReportBuilderTemplatesQuery = () => {
  const selectTemplateOptionsMap = useMemo(
    () =>
      createSelector(
        (response: GetApiReportBuilderTemplatesApiResponse | undefined) => {
          return response ?? [];
        },
        (templates) => {
          return new Map(templates.map((template) => [template.id, template]));
        },
      ),
    [],
  );
  return reportingEnhancedApi.useGetApiReportBuilderTemplatesQuery(undefined, {
    selectFromResult: (result) => {
      return {
        ...result,
        templateOptionsMap: selectTemplateOptionsMap(result.data),
      };
    },
  });
};
