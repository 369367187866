import { get, orderBy } from 'lodash-es';
import {
  LightUser,
  ViewPermissionsTableSettings,
} from '@/bundles/Shared/components/Permissions/ViewPermissionedUsersModal/types';

export function filterSortUsers<U extends LightUser>(
  allUsers: U[],
  searchValue: string,
  sortSettings: ViewPermissionsTableSettings,
) {
  let filterSortResult = [...allUsers];
  if (searchValue) {
    filterSortResult = filterSortResult.filter((user) =>
      user.fullName.toLowerCase().includes(searchValue.toLowerCase()),
    );
  }
  if (sortSettings.filters.roles.length) {
    filterSortResult = filterSortResult.filter((sortedUser) =>
      sortSettings.filters.roles.find(
        (checkedRole) => sortedUser.role?.id === checkedRole.id,
      ),
    );
  }
  if (sortSettings.filters.tags.length) {
    filterSortResult = filterSortResult.filter((sortedUser) =>
      sortedUser.tags.some((tag) =>
        sortSettings.filters.tags.find(
          (checkedTag) => checkedTag.id === tag.id,
        ),
      ),
    );
  }
  return orderBy(
    filterSortResult,
    (user) => get(user, sortSettings.sortField),
    sortSettings.sortOrder,
  );
}
