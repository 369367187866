import RecapPageButton from '@/bundles/Shared/components/RecapPageButton';
import { useWidgetFlags } from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/useWidgetFlags';
import { TableVizConfig } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/table/model';
import {
  useKpiWidgetColumnVisibilityState,
  useWidgetTableColumnSize,
  useWidgetTableVizConfigUpdateEffect,
} from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/table/tableStateSync';
import { useTableWidgetExportFeature } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/table/useTableWidgetExportFeature';
import { WidgetTablePlaceholder } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/table/WidgetTable';
import {
  useWidgetFullScreen,
  WidgetStateFullScreenIconButton,
} from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/WidgetFullScreen';
import { useAgGridRef, useLoadingOverlayEffect } from '@/lib/ag-grid/utils';
import { GrowDiv } from '@/shared/ui/GrowDiv';
import { ThinTabGroup } from '@/stories/Tabs/ThinTabGroup/ThinTabGroup';

import type {
  ColumSizeWidgetState,
  DisplayedGroupsWidgetContext,
  DisplayedGroupsWidgetState,
} from 'bundles/Shared/widgets/dashboard/widgets/common/model';

import {
  DashboardWidgetCard,
  DashboardWidgetTableCard,
} from 'bundles/Shared/widgets/dashboard/widgets/common/ui/DashboardWidgetCard';
import { WidgetStateColumns } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/state/WidgetStateColumns';
import { WidgetStateDate } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/state/WidgetStateDate';
import {
  KPI_TABLE_WIDGET_EXPORT_PARAMS,
  KpiTableGroupingType,
  useAutoGroupColDef,
} from 'bundles/Shared/widgets/dashboard/widgets/kpiTable';
import { KpiTableWidgetTable } from 'bundles/Shared/widgets/dashboard/widgets/kpiTable/ui/KpiTableWidgetTable';
import {
  KpiTableSingleDateWidgetConfig,
  KpiTableSingleDateWidgetData,
  KpiTableSingleDateWidgetSection,
} from 'bundles/Shared/widgets/dashboard/widgets/kpiTableSingleDate';
import {
  WidgetConfigProps,
  WidgetContextProps,
  WidgetProps,
  WidgetStateProps,
} from 'bundles/Shared/widgets/dashboard/widgets/model';
import { capitalize } from 'lodash-es';
import { useRef } from 'react';

const GROUPING_TYPES = [
  'assets',
  'segments',
] as const satisfies readonly KpiTableGroupingType[];

export type KpiTableSingleDateWidgetState = ColumSizeWidgetState &
  DisplayedGroupsWidgetState & {
    date: string;
    groupingType: KpiTableGroupingType;
  };

export function KpiTableSingleDateWidget(
  props: WidgetProps<
    KpiTableSingleDateWidgetData,
    KpiTableSingleDateWidgetSection
  > &
    WidgetStateProps<KpiTableSingleDateWidgetState> &
    WidgetConfigProps<KpiTableSingleDateWidgetConfig> &
    WidgetContextProps<DisplayedGroupsWidgetContext>,
) {
  const {
    widgetSection,
    data,
    state,
    onStateChange,
    settings,
    onSettingsChange,
    isFetching,
    mode,
    context,
  } = props;
  const { shouldDisplayPlaceholder, shouldDisplayData } = useWidgetFlags(props);

  const gridRef = useAgGridRef();
  const wrapperDivRef = useRef<HTMLDivElement>(null);
  useLoadingOverlayEffect({
    isLoading: isFetching,
    grid: gridRef.current,
  });

  const headerBackground =
    widgetSection.widgetConfig.viz_config?.header_background;

  const exportFeature = useTableWidgetExportFeature(
    {
      gridRef,
      mode,
      widgetTitle: widgetSection.title,
      widgetId: widgetSection.id,
      state,
    },
    KPI_TABLE_WIDGET_EXPORT_PARAMS,
  );

  const columnSizeFeature = useWidgetTableColumnSize({
    gridRef,
    state,
    onStateChange,
  });

  useWidgetTableVizConfigUpdateEffect({
    grid: gridRef.current,
    vizConfig: widgetSection.widgetConfig.viz_config as TableVizConfig,
    mode,
  });

  const columnVisibilityStateFeature = useKpiWidgetColumnVisibilityState({
    grid: gridRef.current,
    state,
    onStateChange,
  });
  const widgetStateFullScreenProps = useWidgetFullScreen(wrapperDivRef);
  const autoGroupColDef = useAutoGroupColDef({
    groupingType: state.groupingType,
    mode,
    ref: gridRef,
    colDef: {
      cellClass: exportFeature.autoGroupColumnDef.cellClass,
    },
    headerBackground,
  });
  return (
    <DashboardWidgetTableCard {...props} ref={wrapperDivRef}>
      <DashboardWidgetCard.Header>
        <div className="flex">
          <DashboardWidgetCard.Header.Title>
            {widgetSection.title}
          </DashboardWidgetCard.Header.Title>
          <RecapPageButton
            name={widgetSection.recapPage?.name}
            slug={widgetSection.recapPage?.slug}
          />
        </div>
        {mode != 'pdf' && (
          <>
            <div className="grow" />
            {!widgetStateFullScreenProps.isFullscreen &&
              context.columnVisibilityEnabled && (
                <WidgetStateColumns
                  onColumnSizeChange={columnSizeFeature.handleColumnSizeChange}
                  state={state}
                  groupsState={{
                    groups: state.displayedGroups ?? [],
                  }}
                  onColumnStateChange={columnVisibilityStateFeature.onChange}
                  vizConfig={
                    widgetSection.widgetConfig.viz_config as TableVizConfig
                  }
                  columns={data?.columns ?? []}
                />
              )}
            <div className="flex gap-2">
              <ThinTabGroup
                selectedItem={state.groupingType}
                onSelectedItemChange={(tab) =>
                  onStateChange({
                    ...state,
                    groupingType: tab.id as KpiTableGroupingType,
                  })
                }
                items={GROUPING_TYPES.map((type) => ({
                  id: type,
                  label: capitalize(type),
                }))}
              />
            </div>
            <WidgetStateFullScreenIconButton {...widgetStateFullScreenProps} />
          </>
        )}
      </DashboardWidgetCard.Header>
      {mode != 'pdf' && (
        <DashboardWidgetCard.Panel>
          <WidgetStateDate state={state} onStateChange={onStateChange} />
          <GrowDiv />
          <exportFeature.ExportButtonComponent />
        </DashboardWidgetCard.Panel>
      )}

      {shouldDisplayPlaceholder && <WidgetTablePlaceholder />}

      {shouldDisplayData && (
        <KpiTableWidgetTable
          ref={gridRef}
          autoGroupColumnDef={autoGroupColDef}
          state={state}
          onStateChange={onStateChange}
          vizConfig={widgetSection.widgetConfig.viz_config}
          data={data}
          columnsConfig={widgetSection.widgetConfig.columns}
          settings={settings}
          onSettingsChange={onSettingsChange}
          mode={mode}
          onRowDataUpdated={columnSizeFeature.handleRowDataUpdated}
          excelStyles={exportFeature.excelStyles}
        />
      )}
    </DashboardWidgetTableCard>
  );
}
