import { formatDate } from '@/shared/lib/formatting/dates';
import { CurrencyFormatter } from 'stories/ValueFormatters/CurrencyFormatter';
import { Tooltip } from 'stories/Tooltip/Tooltip';
import { TRANSACTION_TYPE_FILTER } from '@/entities/accounting/transaction/ui/Filters';
import {
  getSliceNumber,
  getValueTypeTransaction,
  getVendorFormatter,
} from 'bundles/Shared/components/Table/formatters/drawPackage';
import { RemarkCell } from '@/entities/accounting/transaction/ui/RemarkCell';
import QuickFilterCheckList, {
  TQuickFilterCheckListProps,
} from 'bundles/Shared/components/Table/filters/QuickFilterCheckList';
import { Vendor } from '@/shared/types/reconcile/Invoice';
import { useGetCompaniesQuery } from 'bundles/Construction/api/companies';
import { ReportLineItemType } from 'bundles/REturn/actions/types';

export enum TColumnIdsLinkableTransactions {
  DATE = 'date',
  PERIOD = 'period',
  VENDOR = 'vendor_ids',
  AMOUNT = 'value',
  VALUE_TYPE = 'valueType',
  NOTE = 'note',
}

export type TFilterModelLinkableTransactions = {
  [TColumnIdsLinkableTransactions.DATE]?: DateString;
  [TColumnIdsLinkableTransactions.VENDOR]?: string[];
  [TColumnIdsLinkableTransactions.AMOUNT]?: string;
  [TColumnIdsLinkableTransactions.VALUE_TYPE]?: ReportLineItemType;
};

export const useTransactionColumns = () => [
  {
    sortable: true,
    dataField: TColumnIdsLinkableTransactions.DATE,
    text: 'Date',
    formatter: ({ row }) => <span>{formatDate(row.date, 'MMM DD, YYYY')}</span>,
    headerClasses: 'min-w-[110px]',
  },
  {
    dataField: TColumnIdsLinkableTransactions.PERIOD,
    text: 'Period',
    formatter: ({ row }) => <span>{formatDate(row.period, 'MMM-YYYY')}</span>,
    headerClasses: 'min-w-[105px]',
  },
  {
    sortable: true,
    dataField: TColumnIdsLinkableTransactions.AMOUNT,
    text: 'Amount',
    formatter: ({ row }) => (
      <div className="flex items-center justify-between">
        <CurrencyFormatter
          classes={{ value: 'inline-regular text-dark-60' }}
          value={row.value}
        />
      </div>
    ),
    headerClasses: 'min-w-[150px]',
  },
  {
    text: 'Type',
    dataField: TColumnIdsLinkableTransactions.VALUE_TYPE,
    headerStyle: {
      width: 140,
    },
    formatter: ({ row: transaction }) =>
      getValueTypeTransaction(transaction.valueType),
    ...TRANSACTION_TYPE_FILTER,
  },
  {
    dataField: TColumnIdsLinkableTransactions.VENDOR,
    text: 'Vendor',
    formatter: ({ row }) =>
      getVendorFormatter(row.vendorEntity, 'max-w-[100px]'),
    headerClasses: 'min-w-[100px]',
  },
  {
    dataField: TColumnIdsLinkableTransactions.NOTE,
    text: 'Remark',
    formatter: ({ row }) => <RemarkCell text={row.note} maxWidth={100} />,
    headerClasses: 'min-w-[100px]',
  },
];

export enum TColumnIdsNoPaidInvoices {
  NUMBER = 'number',
  DATE = 'date',
  VENDOR = 'vendor_ids',
  AMOUNT = 'amount',
  NOTE = 'note',
}

export type TFilterModelNoPaidInvoices = {
  [TColumnIdsNoPaidInvoices.DATE]?: DateString;
  [TColumnIdsNoPaidInvoices.VENDOR]?: Vendor[];
  [TColumnIdsNoPaidInvoices.AMOUNT]?: {
    from: string;
    to: string;
  };
};

export function useNoPaidInvoicesColumns() {
  const { data: vendors } = useGetCompaniesQuery({});
  return {
    columns: [
      {
        sortable: true,
        text: '#',
        dataField: TColumnIdsNoPaidInvoices.NUMBER,
        formatter: ({ row: object }) => (
          <Tooltip titleText={object.number}>
            <div className="text-light-90">
              {getSliceNumber(object.number, 3)}
            </div>
          </Tooltip>
        ),
        headerClasses: 'min-w-[60px]',
      },
      {
        sortable: true,
        dataField: TColumnIdsNoPaidInvoices.DATE,
        text: 'Invoice Date',
        formatter: ({ row }) => (
          <span>{formatDate(row.date, 'MMM DD, YYYY')}</span>
        ),
        headerClasses: 'min-w-[110px]',
      },
      {
        dataField: TColumnIdsNoPaidInvoices.VENDOR,
        text: 'Vendor',
        formatter: ({ row }) => getVendorFormatter(row.vendor, 'max-w-[100px]'),
        filterComponent: QuickFilterCheckList,
        filterComponentParams: {
          items: vendors ?? [],
          getLabel: (item) => item.name,
          getId: (item) => item.id,
        } satisfies TQuickFilterCheckListProps<Vendor>,
        headerClasses: 'min-w-[100px]',
      },
      {
        sortable: true,
        dataField: TColumnIdsNoPaidInvoices.AMOUNT,
        text: 'Amount',
        formatter: ({ row }) => (
          <div className="flex items-center justify-between">
            <CurrencyFormatter
              classes={{ value: 'inline-regular text-dark-60' }}
              value={row.amount}
            />
          </div>
        ),
        headerClasses: 'min-w-[150px]',
      },
      {
        dataField: TColumnIdsLinkableTransactions.NOTE,
        text: 'Remark',
        formatter: ({ row }) => (
          <RemarkCell text={row.document.name} maxWidth={150} />
        ),
        headerClasses: 'min-w-[100px]',
      },
    ],
  };
}
