import { IconButton } from 'stories/IconButton/IconButton';
import { Tag } from 'stories/Tags/Tag/Tag';
import React from 'react';
import { formatUnixDate } from '@/shared/lib/formatting/dates';

import UpdatedByCell from '@/bundles/UserManagement/components/helpers/UpdatedByCell';
import { IUserTag } from 'types/IUserTag';
import { IColumn } from 'bundles/Shared/components/Table/types';

const tagColumns = (actions: {
  edit: (item: IUserTag) => void;
  destroy: (item: IUserTag) => void;
  onUserClick: (user: IUserTag['users'][number]) => void;
}): IColumn<IUserTag>[] => [
  {
    dataField: 'name',
    text: 'Name',
    headerStyle: { width: '15%' },
    formatter: ({ row: tag }) => <div>{tag.name}</div>,
    sortable: true,
  },
  {
    dataField: 'linkedMembersCount',
    text: 'Linked Members',
    headerStyle: { width: '15%' },
    formatter: ({ row: tag }) => (
      <div>
        {tag.users.length > 0 ? (
          <div className="flex flex-wrap gap-1">
            {tag.users.map((user) => (
              <Tag
                onClick={() => actions.onUserClick(user)}
                key={user.id}
                label={user.fullName}
              />
            ))}
          </div>
        ) : (
          <div className="inline-regular text-neutral-450">No Users Yet</div>
        )}
      </div>
    ),
  },
  {
    dataField: 'user',
    text: 'Created By',
    headerStyle: { width: '20%' },
    formatter: ({ row: tag }) => <UpdatedByCell user={tag.createdBy} />,
  },
  {
    dataField: 'updatedAt',
    text: 'Updated At',
    headerStyle: { width: '15%' },
    formatter: ({ row: tag }) => (
      <div className="light-60">{formatUnixDate(tag.updatedAt, 'LL')}</div>
    ),
    sortable: true,
  },
  {
    dataField: 'reason',
    text: 'Reason',
    headerStyle: { width: '25%' },
    formatter: ({ row: tag }) => <div>{tag.reason}</div>,
    sortable: true,
  },
  {
    dataField: 'actions',
    text: 'Actions',
    headerStyle: { width: '10%' },
    formatter: ({ row: tag }) => (
      <div className="flex items-center gap-1">
        <IconButton onClick={() => actions.edit(tag)} iconName="edit" />
        <IconButton onClick={() => actions.destroy(tag)} iconName="trash" />
      </div>
    ),
  },
];

export default tagColumns;
