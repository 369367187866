import React from 'react';
import { Button } from 'stories/Button/Button';
import { Modal } from 'stories/Modals/Modal/Modal';
import { ModalActions } from 'stories/Modals/Modal/ModalActions';
import { useForm } from 'react-hook-form';
import TagForm from '@/bundles/UserManagement/components/TagForm/TagForm';
import { IUserTag } from 'types/IUserTag';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import { MutateTagParams } from 'bundles/UserManagement/api/settingsCoreTagsApi';

const TagModal = ({
  onSubmit,
  onClose,
  currentTag,
}: {
  currentTag?: IUserTag;
} & DialogProps<MutateTagParams>) => {
  const { watch, setValue } = useForm({
    defaultValues: {
      id: currentTag?.id ?? undefined,
      name: currentTag?.name ?? '',
      reason: currentTag?.reason ?? '',
      user_ids: currentTag?.users.map(({ id }) => id) ?? [],
    },
  });
  const fields = watch();
  const action = currentTag ? 'Edit' : 'Create';
  return (
    <Modal
      toggle={onClose}
      header={`${action} Tag`}
      actions={
        <ModalActions>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="success"
            onClick={() => {
              onSubmit?.(fields);
            }}
          >
            {action}
          </Button>
        </ModalActions>
      }
    >
      <TagForm
        action={action}
        fields={fields}
        setValue={setValue}
        currentTag={currentTag}
      />
    </Modal>
  );
};

export default TagModal;
