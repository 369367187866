import React from 'react';
import {
  FieldType,
  FieldTypeView,
  IGroupFormItem,
} from 'bundles/Shared/components/GroupForm/types/types';
import {
  IChangeOrderEvent,
  ICompany,
  IContact,
  IReason,
} from 'bundles/Construction/types';
import { IJobCostCode } from 'types/JobCostCode';
import { optionsReconcileFor } from 'lib/helpers';
import { corNumberRegEx } from 'lib/dictionaries';
import { categoryTransformer } from '@/bundles/Construction/components/ChangeOrderEvent/FormComponents/transformer';
import CODORDate from '@/bundles/Construction/components/ChangeOrderEvent/FormComponents/CODORDate';
import COReallocation from '@/bundles/Construction/components/ChangeOrderEvent/FormComponents/COReallocation';
import COProbability from '@/bundles/Construction/components/ChangeOrderEvent/FormComponents/COProbability';
import {
  useReconcileSubmittedByCompanyField,
  useReconcileSubmittedByContactField,
} from 'bundles/Construction/components/ChangeOrderEvent/fields/fields';

const getItemsCEPromoteModal = (
  formData: IChangeOrderEvent,
  setFormData: any,
  companies: ICompany[],
  contacts: IContact[],
  filteredReasons: IReason[],
  multiReasons: () => void,
  JCCForReallocations?: IJobCostCode[],
  categoryChange: IChangeOrderEvent['category'] | 'revise',
): IGroupFormItem[] => {
  const { category } = formData;
  const AcceptableStatuses = {
    COR: ['VOID', 'PENDING-PROCEEDING', 'PENDING-NOT PROCEEDING'],
    PCO: ['VOID', 'DRAFT', 'PENDING-PROCEEDING', 'PENDING-NOT PROCEEDING'],
    IPC: ['VOID', 'DRAFT'],
  };
  const companyField = useReconcileSubmittedByCompanyField(companies, {
    fieldNameSnap: 'reconcile_company_id',
    styles: {
      wrapper: 'w-50',
    },
  });
  const contactField = useReconcileSubmittedByContactField(contacts, {
    fieldNameSnap: 'reconcile_contact_id',
    styles: {
      wrapper: 'w-50',
    },
  });
  const companyAndContactDisabled =
    category === 'COR' && categoryChange !== 'revise';
  const COR = {
    details: [
      {
        ...companyField,
        disabled: companyAndContactDisabled,
      },
      { ...contactField, disabled: companyAndContactDisabled },
      {
        fieldName: 'number',
        fieldNameSnap: 'number',
        type: FieldType.INPUT_TEXT,
        title: 'Number',
        placeholder: '0000-A',
        required: true,
        validation: {
          message:
            'Please enter 1 to 4 digits and an optional letter (Examples: 123, 4325-A, 332G)',
          func: (e) => corNumberRegEx.test(e),
        },
        styles: {
          wrapper: 'w-25 form-item_white',
          size: 'm',
        },
      },
      {
        fieldName: 'contractorDate',
        fieldNameSnap: 'contractor_date',
        type: FieldType.INPUT_DATE,
        title: 'Contractor date',
        placeholder: 'Date',
        required: true,
        format: 'MM-DD-YYYY',
        formatView: 'MM-dd-yy',
        leftIcon: 'calendar',
        styles: {
          wrapper: 'w-25 form-item_white',
          size: 'm',
        },
        maxDate: new Date(),
      },
      {
        fieldName: 'receivedDate',
        fieldNameSnap: 'received_date',
        type: FieldType.INPUT_DATE,
        title: 'Received date',
        placeholder: 'Date',
        required: true,
        format: 'MM-DD-YYYY',
        formatView: 'MM-dd-yy',
        leftIcon: 'calendar',
        styles: {
          wrapper: 'w-25 form-item_white',
          size: 'm',
        },
        maxDate: new Date(),
      },
      {
        fieldName: 'status',
        fieldNameSnap: 'status',
        type: FieldType.SELECT,
        typeView: FieldTypeView.VIEW_TEXT,
        title: 'Status',
        titleView: 'Status',
        placeholder: 'Select status',
        required: true,
        optionalView: true,
        options: AcceptableStatuses.COR.map((el) => ({ value: el, label: el })),
        styles: {
          wrapper: 'w-50',
        },
      },
      {
        fieldName: 'sentToDesignerOfRecordDate',
        fieldNameSnap: 'sent_to_designer_of_record_date',
        type: FieldType.OPTIONAL_SECTION,
        title: 'DOR date',
        optional: false,
        content: <CODORDate formData={formData} setFormData={setFormData} />,
        styles: {
          nomb: true,
        },
      },
    ],
  };
  const PCO = {
    details: [
      companyField,
      contactField,
      {
        fieldName: 'number',
        fieldNameSnap: 'number',
        type: FieldType.INPUT_TEXT,
        title: 'Number',
        placeholder: '0000-A',
        required: true,
        validation: {
          message:
            'Please enter 1 to 4 digits and an optional letter (Examples: 123, 4325-A, 332G)',
          func: (e) => corNumberRegEx.test(e),
        },
        styles: {
          wrapper: 'w-25 form-item_white',
          size: 'm',
        },
      },
      {
        fieldName: 'contractorDate',
        fieldNameSnap: 'contractor_date',
        type: FieldType.INPUT_DATE,
        title: 'Contractor date',
        placeholder: 'Date',
        required: true,
        format: 'MM-DD-YYYY',
        formatView: 'MM-dd-yy',
        leftIcon: 'calendar',
        styles: {
          wrapper: 'w-25 form-item_white',
          size: 'm',
        },
        maxDate: new Date(),
      },
      {
        fieldName: 'receivedDate',
        fieldNameSnap: 'received_date',
        type: FieldType.INPUT_DATE,
        title: 'Received date',
        placeholder: 'Date',
        required: true,
        format: 'MM-DD-YYYY',
        formatView: 'MM-dd-yy',
        leftIcon: 'calendar',
        styles: {
          wrapper: 'w-25 form-item_white',
          size: 'm',
        },
        maxDate: new Date(),
      },
      {
        fieldName: 'status',
        fieldNameSnap: 'status',
        type: FieldType.SELECT,
        typeView: FieldTypeView.VIEW_TEXT,
        title: 'Status',
        titleView: 'Status',
        placeholder: 'Select status',
        required: true,
        optionalView: true,
        options: AcceptableStatuses.PCO.map((el) => ({ value: el, label: el })),
        styles: {
          wrapper: 'w-50',
        },
      },
    ],
  };

  const IPC = {
    details: [
      contactField,
      {
        fieldName: 'status',
        fieldNameSnap: 'status',
        type: FieldType.SELECT,
        typeView: FieldTypeView.VIEW_TEXT,
        title: 'Status',
        titleView: 'Status',
        placeholder: 'Select status',
        required: true,
        optionalView: true,
        options: AcceptableStatuses.IPC.map((el) => ({ value: el, label: el })),
        styles: {
          wrapper: 'w-50',
        },
      },
    ],
  };

  const changeData = { COR, PCO, IPC };

  const groupFormItems = [
    {
      customTitle: 'Key Info',
      required: true,
      activated: true,
      open: true,
      formItems: [
        {
          fieldName: 'title',
          fieldNameSnap: 'title',
          type: FieldType.INPUT_TEXT,
          title: 'Name or Title',
          placeholder: 'Enter summary description of change event',
          required: true,

          styles: {
            wrapper: 'w-full form-item_white',
            size: 'm',
            back_layer: 'bg-white',
          },
        },
        {
          fieldName: 'description',
          fieldNameSnap: 'description',
          type: FieldType.INPUT_TEXTAREA,
          title: 'Detailed Description',
          placeholder: 'Enter detailed description or other relevant data',
          required: true,

          styles: {
            wrapper: 'w-full form-item_white',
          },
        },
        {
          fieldName: 'changeOrderReasons',
          fieldNameSnap: 'changeOrderReasons',
          type: FieldType.LIST_SELECT,
          title: 'Reasons',
          placeholder: 'Select reason',
          required: true,
          options: filteredReasons,
          isMulti: multiReasons,
          styles: {
            wrapper: 'w-75 form-item_white',
            input: 'w-60',
            size: 'm',
          },
        },
        {
          fieldName: 'category',
          fieldNameSnap: 'category',
          type: FieldType.SELECTED_BUTTONS,
          title: 'Select Event category',
          transformerView: categoryTransformer,
          placeholder: 'Enter detailed description or other relevant data',
          buttons:
            categoryChange === 'revise'
              ? [
                  {
                    title: 'COR',
                    text: 'Change Order Request',
                    active: formData.category === 'COR',
                    value: 'COR',
                  },
                  {
                    title: 'PCO',
                    text: 'Potential Change Order',
                    active: formData.category === 'PCO',
                    value: 'PCO',
                  },
                  {
                    title: 'IPC',
                    text: 'Internal Risk of Change',
                    active: formData.category === 'IPC',
                    value: 'IPC',
                  },
                ]
              : [
                  {
                    title: categoryChange,
                    text:
                      categoryChange === 'COR'
                        ? 'Change Order Request'
                        : 'Potential Change Order',
                    active: true,
                    value: categoryChange,
                  },
                ],
          required: true,
          styles: {
            wrapper: 'w-full',
            nomb: true,
          },
          disabled: category === 'COR' && categoryChange !== 'revise',
        },
      ],
    },
    {
      customTitle: 'Additional Details',
      required: true,
      activated: true,
      open: false,
      formItems:
        changeData[categoryChange === 'revise' ? category : categoryChange]
          .details,
    },
    {
      customTitle: 'Financial Impact',
      required: true,
      activated: true,
      open: false,
      formItems: [],
    },
  ];
  if (category) {
    const [, , financialImpact] = groupFormItems;
    financialImpact.formItems.push(
      {
        fieldName: 'amount',
        fieldNameSnap: 'amount',
        type: FieldType.INPUT_NUMBER,
        title: 'Amount',
        leftIcon: 'paid',
        placeholder: '0',
        required: true,
        styles: {
          wrapper: 'w-25 form-item_white',
          size: 'm',
        },
        thousandSeparator: true,
      },
      {
        fieldName: 'probability',
        fieldNameSnap: 'probability',
        type: FieldType.OPTIONAL_SECTION,
        title: 'Probability',
        optional: false,
        defaultValue: '100',
        expanded: formData.probability,
        content: (
          <COProbability formData={formData} setFormData={setFormData} />
        ),
        viewComponent: [
          {
            fieldName: 'probability_comment',
          },
        ],
      },
    );
  }
  if (
    (categoryChange === 'PCO' && category === 'IPC') ||
    (categoryChange === 'COR' && category === 'IPC') ||
    category !== 'IPC'
  ) {
    const [, , financialImpact] = groupFormItems;
    financialImpact.formItems.push({
      fieldName: 'reallocationJccId',
      fieldNameSnap: 'reallocation_jcc_id',
      type: FieldType.OPTIONAL_SECTION,
      title: 'REallocation',
      optional: false,
      content: (
        <COReallocation
          formData={formData}
          setFormData={setFormData}
          fieldNameAmount="amount"
          menuPlacement="top"
        />
      ),
      styles: {
        nomb: true,
      },
    });
  }
  return groupFormItems;
};
export default getItemsCEPromoteModal;
