import { SelectedGeneralLedger } from '@/bundles/REturn/components/Ownership/ownershipsHeader/sourcesModal/SourcesModal';
import { TransactionSource } from '@/entities/return/api/capitalInvestmentObjectsGeneratedApi';
import { capitalize } from 'lodash-es';
import pluralize from 'pluralize';
import { ReturnableTag } from 'stories/Tags/ReturnableTag/ReturnableTag';

interface Props {
  type: TransactionSource['kind'];
  generalLedgers: SelectedGeneralLedger[];
  onSelectedChange: (newSelected: SelectedGeneralLedger[]) => void;
}

function GeneralLedgersTagList({
  type,
  generalLedgers,
  onSelectedChange,
}: Props) {
  return (
    <div className="flex flex-col gap-2">
      <span className="dark-60 inline-semibold font-semibold">
        {capitalize(pluralize(type))}
      </span>
      <div className="flex flex-wrap gap-xs">
        {generalLedgers.map((source) => (
          <ReturnableTag
            label={source.accTitle}
            key={source.id}
            onExclude={() => {
              onSelectedChange(
                generalLedgers.filter((s) => s.id !== source.id),
              );
            }}
          />
        ))}
      </div>
    </div>
  );
}

export default GeneralLedgersTagList;
