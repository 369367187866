import { resolveIsDistributionEntry } from '@/bundles/REturn/components/Ownership/modals/manageCapitalInvestmentModal/utils';
import { useLoadedInvestmentObject } from '@/bundles/REturn/hooks/useLoadedInvestmentObject';
import { Contribution, Distribution } from '@/entities/return';
import { TransactionSource } from '@/entities/return/api/capitalInvestmentObjectsGeneratedApi';
import FireIcon from 'bundles/FireStation/components/FireIcon/FireIcon';
import { TFireStationPriority } from 'bundles/FireStation/types';
import { useModal } from '@/shared/lib/hooks/useModal';
import React from 'react';
import { Button } from 'stories/Button/Button';
import { LinkButton } from 'stories/LinkButton/LinkButton';
import { SplitTransactionListModal } from '@/entities/return/ui/SplitTransactionListModal';
import LinkTxToManuallyAddedModal from '@/bundles/REturn/components/Ownership/modals/linkTxModals/linkTxToManuallyAdded/LinkTxToManuallyAddedModal';
import { MANUAL_ENTRY_BADGE_JSX } from '@/bundles/REturn/components/Ownership/modals/manageCapitalInvestmentModal/Columns/dumbJSX';
import { DistributionNoteTooltip } from '@/bundles/REturn/components/Ownership/modals/manageCapitalInvestmentModal/Columns/DistributionNoteTooltip';

interface Props {
  entry: Contribution | Distribution;
  createInfo: React.ReactNode;
  type: TransactionSource['kind'];
  actionDropdown: React.ReactNode;
}

export function NotLinkedColumn({
  createInfo,
  entry,
  type,
  actionDropdown,
}: Props) {
  const { openModal } = useModal();
  const { data: capitalInvestmentObject } = useLoadedInvestmentObject();
  const { fromSourceEnabled } = capitalInvestmentObject;
  return (
    <div className="flex justify-between gap-2">
      <div className="flex flex-col gap-2">
        {fromSourceEnabled ? (
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-1">
              <span className="secondary-regular text-light-60">
                No Tx. yet
              </span>
              <FireIcon priority={TFireStationPriority.LOW} />
              {MANUAL_ENTRY_BADGE_JSX}
              <DistributionNoteTooltip
                note={entry?.note}
                classes="d-flex mr-5"
              />
            </div>
            {actionDropdown}
          </div>
        ) : (
          <div className="flex items-center">
            {MANUAL_ENTRY_BADGE_JSX}
            <DistributionNoteTooltip note={entry?.note} classes="d-flex mr-5" />
          </div>
        )}
        {resolveIsDistributionEntry(entry) && entry.splitItems?.length > 0 && (
          <span className="text-xs leading-4 text-neutral-500">
            Split by{' '}
            <LinkButton
              className="text-xs font-semibold leading-4"
              onClick={() =>
                openModal(SplitTransactionListModal, {
                  investmentEntityName: entry.investmentEntity.name,
                  date: entry.date,
                  splitItems: entry.splitItems,
                })
              }
            >
              {entry.splitItems.length} types
            </LinkButton>
          </span>
        )}
        {fromSourceEnabled && (
          <Button
            variant="secondary"
            size="xs"
            onClick={() => {
              openModal(
                LinkTxToManuallyAddedModal,
                {
                  entry,
                  type,
                },
                {
                  topLevelRender: false,
                },
              );
            }}
            className="self-start"
          >
            Link transaction
          </Button>
        )}
        {createInfo}
      </div>

      {!fromSourceEnabled && actionDropdown}
    </div>
  );
}
