import { arrayMoveImmutable } from 'array-move';
import React, { useEffect, useState } from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { SmallAddingButton } from '@/stories/SmallAddingButton/SmallAddingButton';
import { cn } from '@/shared/lib/css/cn';
import { isEqual, uniqueId } from 'lodash-es';
import { useModal } from '@/shared/lib/hooks/useModal';
import { StaticCardField } from 'bundles/Scoreboard/Pipeline/types';
import FieldCard from '@/stories/FieldsManageable/FieldCard/FieldCard';
import FieldModal from '@/stories/FieldsManageable/FieldModal';

interface Props {
  initFields: StaticCardField[];
  onChange?: (fields: StaticCardField[]) => Promise<void>;
  selectOptions?: object[];
}

export const FieldsManageable = ({
  initFields,
  onChange,
  selectOptions,
}: Props) => {
  const [fields, setFields] = useState(initFields);
  const { openModal, confirm } = useModal();

  useEffect(() => {
    if (isEqual(fields, initFields)) return;

    onChange?.(fields);
  }, [fields]);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const sortedItems = arrayMoveImmutable(fields, oldIndex, newIndex);
    document.body.style.cursor = 'default';
    setFields(sortedItems);
  };

  const handleUpdateFields = (
    id: StaticCardField['id'],
    updatedField: Omit<StaticCardField, 'id'>,
  ) => {
    setFields((prevField) =>
      prevField.map((oldField) => {
        if (oldField.id === id) {
          return {
            id,
            ...updatedField,
          };
        }
        return oldField;
      }),
    );
  };

  const handleUpdateFieldModal = async (field: StaticCardField) => {
    const res = await openModal(FieldModal, {
      field,
      selectOptions,
    });

    if (res) {
      handleUpdateFields(field.id, res as StaticCardField);
    }
  };

  const handleRemoveField = async (field: Partial<StaticCardField>) => {
    const result = await confirm({
      title: 'Remove field',
      subtitle: 'Are you sure you want to remove field?',
      actions: {
        primaryButton: {
          text: 'Remove',
          variant: 'danger',
        },
        secondaryButton: {
          text: 'Cancel',
          variant: 'secondary',
        },
      },
    });
    if (result) {
      setFields((prevFields) => prevFields.filter((i) => i.id !== field.id));
    }
  };

  const handleCreateField = (field: StaticCardField) => {
    setFields((prevFields) => [...prevFields, field]);
  };

  const handleCreateFieldModal = async () => {
    const res = await openModal(FieldModal, { selectOptions });

    if (res) {
      const formData = res;

      handleCreateField({
        id: uniqueId('field-'),
        main: formData.main ?? false,
        value: formData.value,
        label: formData.label,
      });
    }
  };

  const SortableItem = SortableElement(({ value }) => (
    <div style={{ flex: value.main ? '100%' : '1', position: 'relative' }}>
      <FieldCard
        onDelete={handleRemoveField}
        onEdit={() => handleUpdateFieldModal(value)}
        field={value}
      />
    </div>
  ));

  const SortableList = SortableContainer(({ items }) => (
    <div className="flex flex-wrap gap-m">
      {items.map((value, index) => (
        <SortableItem
          index={index}
          value={value}
          key={`item-${value.id}-${value.label}`}
        />
      ))}
    </div>
  ));

  return (
    <div className={cn('p-m rounded-[1rem] bg-light-5')}>
      <div className="flex flex-row-reverse">
        <SmallAddingButton onClick={handleCreateFieldModal}>
          Add New Field
        </SmallAddingButton>
      </div>
      <div className="mt-m">
        <div className="flex flex-col gap-s">
          <SortableList
            axis="xy"
            items={fields}
            onSortStart={() => {
              document.body.style.cursor = 'grabbing';
            }}
            onSortEnd={onSortEnd}
            helperClass="field_drag__active"
            useDragHandle
          />
        </div>
      </div>
    </div>
  );
};
