import {
  WidgetDataHistoryRow,
  WidgetDataRowHistoryEntry,
} from '@/bundles/Shared/widgets/dashboard/widgets/model';
import { formatDate } from '@/shared/lib/formatting/dates';
import { ICellRendererParams } from 'ag-grid-community';
import { orderBy } from 'lodash-es';

export class WidgetDataRowHistoryColumnModel {
  static getHistoryForColumn(row: WidgetDataHistoryRow, columnKey: number) {
    return row.history?.[columnKey] ?? [];
  }

  static hasMoreThanOneNonNullEntry(history: WidgetDataRowHistoryEntry[]) {
    return history.filter((entry) => entry.value !== null).length > 1;
  }

  static hasMoreThanOneEntry(history: WidgetDataRowHistoryEntry[]) {
    return history.length > 1;
  }

  static orderByDateFromAsc(history: WidgetDataRowHistoryEntry[]) {
    return orderBy(history, 'dateFrom', 'asc');
  }

  static orderByDateFromDesc(history: WidgetDataRowHistoryEntry[]) {
    return orderBy(history, 'dateFrom', 'desc');
  }

  static entryFormatDates(history: WidgetDataRowHistoryEntry) {
    return `${formatDate(history.dateFrom, 'MM-DD-YYYY')} - ${formatDate(
      history.dateTo,
      'MM-DD-YYYY',
    )}`;
  }

  static entryFormat(history: WidgetDataRowHistoryEntry) {
    return `${
      history.value
    } (${WidgetDataRowHistoryColumnModel.entryFormatDates(history)})`;
  }
}

export const defaultHistoryValueGetter = (
  params: ICellRendererParams,
): WidgetDataRowHistoryEntry[] =>
  WidgetDataRowHistoryColumnModel.getHistoryForColumn(
    params.data,
    params?.column?.getColDef().field ?? '',
  );
