import { ReportLineItem } from 'bundles/REturn/actions/types';
import { TRANSACTION_TYPE_FILTER } from '@/entities/accounting/transaction/ui/Filters';
import {
  getTransactionTypeFormatter,
  getRemarkFormatter,
} from '@/entities/accounting/transaction/ui/Formatters';
import { IColumn } from 'bundles/Shared/components/Table/types';
import { formatDate } from '@/shared/lib/formatting/dates';
import { useMemo } from 'react';
import { CurrencyFormatter } from 'stories/ValueFormatters/CurrencyFormatter';
import { InlineObject } from 'stories/Objects/InlineObject/InlineObject';
import { DEFAULT_STRING_FALLBACK } from '@/shared/lib/formatting/fallbacks';
import { convertCentsToDollars } from '@/shared/lib/converters';

interface Params {
  transactions: ReportLineItem[];
}

export function useTransactionColumns({ transactions }: Params) {
  return useMemo<IColumn<ReportLineItem>[]>(
    () => [
      {
        sortable: true,
        dataField: 'date',
        text: 'Date',
        formatter: ({ row }) => (
          <span>{formatDate(row.date, 'MMM DD, YYYY')}</span>
        ),
        headerClasses: 'min-w-[120px]',
      },
      {
        dataField: 'vendor',
        text: 'Vendor',
        formatter: ({ row }) => (
          <InlineObject
            fallbackNode={DEFAULT_STRING_FALLBACK}
            iconName="bag"
            object={row.vendorEntity?.name}
          />
        ),
        headerClasses: 'min-w-[120px]',
      },
      {
        dataField: 'amount',
        text: 'Amount',
        formatter: ({ row }) => (
          <div className="flex items-center justify-between">
            <CurrencyFormatter
              classes={{ value: 'inline-regular text-dark-60' }}
              value={convertCentsToDollars(row.amountCents)}
            />
          </div>
        ),
        headerClasses: 'min-w-[180px]',
      },
      {
        dataField: 'type',
        text: 'Type',
        formatter: ({ row }) => getTransactionTypeFormatter(row),
        headerClasses: 'min-w-[124px]',
        ...TRANSACTION_TYPE_FILTER,
      },
      {
        dataField: 'note',
        text: 'Description',
        formatter: getRemarkFormatter((item) => item.description),
        headerClasses: 'min-w-[240px]',
      },
      {
        dataField: 'legalEntity',
        text: 'Legal Entity',
        formatter: ({ row }) => (
          <InlineObject
            iconName="bag"
            object={row.legalEntity.name}
            fallbackNode={DEFAULT_STRING_FALLBACK}
          />
        ),
        headerClasses: 'min-w-[120px]',
      },
    ],
    [transactions],
  );
}
