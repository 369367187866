import { ICellRendererParams } from 'ag-grid-community';
import { getNodeFlags } from '@/bundles/Shared/components/AgGrid/Table/utils/getNodeFlags';
import { CurrencyCellRenderer } from '@/bundles/Shared/components/AgGrid/Table/cellComponents/CurrencyCellRenderer';

export const CustomCurrencyCellRenderer = (
  cellParams: ICellRendererParams & {
    classes?: Record<string, string>;
    formatterParams?: Record<string, any>;
  },
) => {
  const { node } = cellParams;

  let currencyClassName: string | null = null;
  let innerClassName: string | null = null;

  const { isGrandTotal, isFooter } = getNodeFlags(node);

  if (isFooter) {
    currencyClassName = 'font-semibold text-neutral-500';
    innerClassName = 'border-transparent';
  }

  if (isGrandTotal) {
    currencyClassName = 'text-white font-semibold';
    innerClassName = 'bg-green border-transparent';
  }

  return (
    <CurrencyCellRenderer
      formatterParams={{
        classes: {
          allParts: currencyClassName,
        },
        ...cellParams.formatterParams,
      }}
      classes={{
        inner: innerClassName,
        ...cellParams.classes,
      }}
      {...cellParams}
    />
  );
};
