import HistoryDetailsSwitcher from 'bundles/Shared/components/HistoryDetails/HistoryDetailsSwitcher';
import { FC, useMemo } from 'react';
import { getDataHDArrowsCO } from '@/bundles/Construction/components/ChangeOrder/HistoryDetailsModalHeaderContent/initData';

interface IHistoryDetailsBodyCOProps {
  fields: {
    fieldName: string;
    changes: {
      current: object | string | number;
      old: object | string | number;
    };
  }[];
}

const HistoryDetailsBodyCO: FC<IHistoryDetailsBodyCOProps> = ({ fields }) => {
  const historyData = useMemo(() => getDataHDArrowsCO(), []);
  return (
    <div>
      {historyData.map((historyItem) => (
        <div>
          <div className="dark-60 header6-regular mb-s">
            {historyItem.title}
          </div>
          <div className="rounded-[8px] mb-m bg-white">
            {historyItem.data.map((changeItem) => (
              <HistoryDetailsSwitcher
                {...changeItem}
                title={changeItem.titleView}
                changes={
                  fields.find((item) => item.fieldName === changeItem.fieldName)
                    ?.changes ?? {
                    current: false,
                    old: false,
                  }
                }
                transformerView={changeItem.transformerView}
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default HistoryDetailsBodyCO;
