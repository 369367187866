import { formatToDateStringForRequest } from '@/shared/lib/converters';
import { cn } from '@/shared/lib/css/cn';
import {
  findIndexByPeriod,
  slicePossiblePeriodsFromAndTo,
} from 'bundles/REport/components/financials/filter/utils';
import dayjs from 'dayjs';
import { capitalize, groupBy } from 'lodash-es';
import React, { useRef, useState } from 'react';
import { Button } from '@/stories/Button/Button';
import { Popover, PopoverRef } from '@/stories/Popover/Popover';
import {
  ThinTabGroup,
  IThinTabItem,
} from '@/stories/Tabs/ThinTabGroup/ThinTabGroup';
import * as Comp from '@/stories/FlexibleFilterByPeriods/ItemComponents';
import { SliderWithNavigation } from '@/stories/FlexibleFilterByPeriods/ItemComponents';
import * as Const from '@/stories/FlexibleFilterByPeriods/consts';
import * as Hooks from '@/stories/FlexibleFilterByPeriods/hooks';
import '@/stories/FlexibleFilterByPeriods/slick-overrides.css';
import * as Type from '@/stories/FlexibleFilterByPeriods/types';
import { DefaultMonthDateRange } from '@/stories/FlexibleFilterByPeriods/types';
import * as Util from '@/stories/FlexibleFilterByPeriods/utils';

export type NoPossiblePeriodsCase = {
  label: string;
  active: boolean;
  btnDisabled?: boolean;
};

interface Props {
  periodItems: Type.IPeriodItem[];
  filterByPeriodsType: Type.IPeriodItem['type'];
  onUpdatePeriodItems: (items: Type.IPeriodItem[]) => void;
  onUpdateFilterByPeriodsType?: (type: Type.IPeriodItem['type']) => void;
  possiblePeriods?: Type.IPeriodItem['period'][];
  closeOnMonthUpdate?: boolean;
  defaultPeriodItems?: Type.IPeriodItem[];
  isSingleSelection?: boolean;
  customSetDefaultPeriodItemsHandler?: () => void;
  labelPlaceholder?: React.ReactNode;
  updatedStyles?: boolean;
  noPossiblePeriodsCase?: NoPossiblePeriodsCase;
  popoverProps?: Omit<
    React.ComponentProps<typeof Popover>,
    'ref' | 'hiddenArrow' | 'maxWidth' | 'className' | 'template' | 'trigger'
  >;
  buttonProps?: React.ComponentProps<typeof Button>;
  fromToMode?: boolean;

  /**
   * If true, then the user can select the start range without selecting the end.
   * If false, then the user can must select the end range.
   * @default false
   */
  canSelectStartRange?: boolean;
  initialOpenedYear?: string;
  /**
   * If true, then the quick selector will be shown. Quick selector is a list of predefined periods. Works only for MTD.
   * @default false
   */
  showQuickSelector?: boolean;
  // todo: remove this prop
  /**
   * If true, then onUpdatePeriodItems will be called when the start range is selected.
   * If false, no action will be taken when the start range is selected.
   * @default false
   */
  fireOnUpdateWhenStartRangeSelected?: boolean;

  /**
   * Stops updating period items on switching between period type filter
   * @default false
   */
  disableUpdatePeriodItemsOnFilterByPeriodsTypeUpdate?: boolean;
}

export function FlexibleFilterByPeriods({
  periodItems,
  defaultPeriodItems = [],
  filterByPeriodsType,
  onUpdatePeriodItems,
  onUpdateFilterByPeriodsType,
  possiblePeriods = [],
  isSingleSelection = false,
  closeOnMonthUpdate = isSingleSelection,
  customSetDefaultPeriodItemsHandler,
  labelPlaceholder,
  popoverProps,
  noPossiblePeriodsCase,
  buttonProps,
  fromToMode = false,
  canSelectStartRange = false,
  initialOpenedYear,
  showQuickSelector = false,
  fireOnUpdateWhenStartRangeSelected = false,
  disableUpdatePeriodItemsOnFilterByPeriodsTypeUpdate = false,
}: Props) {
  const popoverRef = useRef<PopoverRef>(null);
  const groupedByYearPossiblePeriods = groupBy(possiblePeriods, (period) =>
    dayjs(period).year(),
  ) as Type.PossiblePeriodsGroupedByYear;

  const { yearArrForYearly, yearEntriesForQuarterly, yearEntriesForMonthly } =
    Hooks.useAvailableYears({ groupedByYearPossiblePeriods });

  const resolveOpenedYear = (type: Type.IPeriodItem['type']) => {
    switch (type) {
      case Const.QUARTERLY_THIN_TAB.id: {
        return Util.getLatestYearFromEntries(yearEntriesForQuarterly);
      }
      default: {
        const lastYearFromValue = periodItems.at(-1)
          ? String(dayjs(periodItems.at(-1)!.period).year())
          : null;

        return (
          lastYearFromValue ??
          Util.getLatestYearFromEntries(yearEntriesForMonthly)
        );
      }
    }
  };

  const [startingPeriodItem, setStartingPeriodItem] =
    useState<Type.IPeriodItem | null>(null);

  const [hoveredOverPeriod, setHoveredOverPeriod] = useState<
    Type.IPeriodItem['period'] | null
  >(null);
  const [openedYear, setOpenedYear] = useState(
    initialOpenedYear ?? resolveOpenedYear(filterByPeriodsType),
  );
  const handleOpenYear = (newYear: string) => {
    setOpenedYear(newYear);
  };

  const handleSelectPeriodType = (tab: IThinTabItem) => {
    setOpenedYear(resolveOpenedYear(tab.id as Type.IPeriodItem['type']));

    if (!disableUpdatePeriodItemsOnFilterByPeriodsTypeUpdate) {
      let updatedPeriodItems = defaultPeriodItems;

      switch (tab.id) {
        case Const.QUARTERLY_THIN_TAB.id: {
          updatedPeriodItems =
            Util.createLatestTwoQuartersPeriodItems(possiblePeriods);
          break;
        }
        case Const.YEARLY_THIN_TAB.id: {
          updatedPeriodItems =
            Util.createLatestYearsPeriodItems(yearArrForYearly);
          break;
        }

        default:
          break;
      }

      onUpdatePeriodItems(updatedPeriodItems);
    }
    onUpdateFilterByPeriodsType?.(tab.id as Type.IPeriodItem['type']);
  };

  const handleMonthClick = (monthDigits: string) => {
    const getUpdatedPeriodItems = () => {
      const selectedPeriod = Util.createPeriod({
        year: openedYear,
        monthDigits,
      });

      const newPeriodItem = Util.createMonthPeriodItem(selectedPeriod);

      if (isSingleSelection || fromToMode) {
        return [newPeriodItem];
      }

      const prevPeriodItem = periodItems.find(
        ({ period }) => selectedPeriod === period,
      );

      if (prevPeriodItem) {
        return periodItems.filter(({ period }) => selectedPeriod !== period);
      }

      return [...periodItems, newPeriodItem];
    };

    if (fromToMode) {
      if (startingPeriodItem === null) {
        setStartingPeriodItem(getUpdatedPeriodItems()[0]);
        if (fireOnUpdateWhenStartRangeSelected) {
          onUpdatePeriodItems(getUpdatedPeriodItems());
        }
        return;
      }

      const [endingPeriodItem] = getUpdatedPeriodItems();

      const isStartingPeriodAfterEndingPeriod = dayjs(
        startingPeriodItem.period,
      ).isAfter(dayjs(endingPeriodItem.period));

      const [fromPeriod, toPeriod] = isStartingPeriodAfterEndingPeriod
        ? [endingPeriodItem.period, startingPeriodItem.period]
        : [startingPeriodItem.period, endingPeriodItem.period];

      const idxFrom = findIndexByPeriod(possiblePeriods, fromPeriod);
      const idxTo = findIndexByPeriod(possiblePeriods, toPeriod);

      const updatedPeriods = slicePossiblePeriodsFromAndTo(
        possiblePeriods,
        idxFrom,
        idxTo,
      );
      const updatedPeriodItems = updatedPeriods.map(Util.createMonthPeriodItem);

      onUpdatePeriodItems(updatedPeriodItems);

      setStartingPeriodItem(null);
    } else {
      onUpdatePeriodItems(getUpdatedPeriodItems());
    }

    if (closeOnMonthUpdate) {
      popoverRef.current?.hide();
    }
  };

  const resolveIsMonthSelected = (monthDigits: string) => {
    const selectedPeriod = Util.createPeriod({ year: openedYear, monthDigits });
    const selectedPeriodIsSameMonthAndYear = (period: Type.IPeriodItem) =>
      dayjs(period.period).isSame(selectedPeriod, 'month') &&
      dayjs(period.period).isSame(selectedPeriod, 'year');
    if (fromToMode) {
      if (startingPeriodItem !== null) {
        return (
          selectedPeriodIsSameMonthAndYear(startingPeriodItem) ||
          hoveredOverPeriod === selectedPeriod
        );
      }
    }

    const prevPeriodItem = periodItems.find(selectedPeriodIsSameMonthAndYear);

    return Boolean(prevPeriodItem);
  };

  const resolveHasYearItems = (year: string) => {
    const hasItems = periodItems.some(({ period }) => period.includes(year));
    return hasItems;
  };

  const resolveQuarterSelected = (
    quarterMonthDigits: Type.MonthDigitsOfQuarterList | Type.MonthDigitsList,
  ) => {
    const allPeriodItemsOfQuarter = Util.createPeriodItemList(
      quarterMonthDigits,
      openedYear,
      filterByPeriodsType,
    );

    const allPeriods = Util.getAllPeriods(allPeriodItemsOfQuarter);

    const selectedPeriods = Util.filterSelectedPeriodItems(
      periodItems,
      allPeriods,
    );

    return selectedPeriods.length === allPeriodItemsOfQuarter.length;
  };

  const handleQuarterClick = (
    quarterMonthDigits: Type.MonthDigitsOfQuarterList | Type.MonthDigitsList,
  ) => {
    const getUpdatedPeriodItems = () => {
      const allPeriodItemsOfQuarter = Util.createPeriodItemList(
        quarterMonthDigits,
        openedYear,
        filterByPeriodsType,
      );

      const allPeriods = Util.getAllPeriods(allPeriodItemsOfQuarter);

      const selectedPeriodItems = Util.filterSelectedPeriodItems(
        periodItems,
        allPeriods,
      );

      if (selectedPeriodItems.length === allPeriodItemsOfQuarter.length) {
        return Util.filterOutSelectedPeriodItems(periodItems, allPeriods);
      }

      return Util.uniqByPeriod([...periodItems, ...allPeriodItemsOfQuarter]);
    };
    onUpdatePeriodItems(getUpdatedPeriodItems());
  };

  const handleSelectYear = (year: string) => {
    const getUpdatedPeriodItems = () => {
      const yearWithQuartersEntries =
        filterByPeriodsType === Const.MONTHLY_THIN_TAB.id
          ? yearEntriesForMonthly
          : yearEntriesForQuarterly;

      const availableMonthDigitList = Util.getAvailableMonthDigitList({
        year,
        yearWithQuartersEntries,
      });

      const allPeriodItemsOfYear = Util.createPeriodItemList(
        availableMonthDigitList,
        year,
        filterByPeriodsType,
      );

      const allPeriods = Util.getAllPeriods(allPeriodItemsOfYear);

      const selectedPeriods = Util.filterSelectedPeriodItems(
        periodItems,
        allPeriods,
      );

      if (selectedPeriods.length >= allPeriodItemsOfYear.length) {
        return Util.filterOutSelectedPeriodItems(periodItems, allPeriods);
      }

      return Util.uniqByPeriod([...periodItems, ...allPeriodItemsOfYear]);
    };
    onUpdatePeriodItems(getUpdatedPeriodItems());
  };

  const handleSelectYearYearlyView = (year: string) => {
    const getUpdatedPeriodItems = () => {
      const yearPeriod = Util.createPeriod({
        year,
        monthDigits: '01',
      });

      if (isSingleSelection) {
        return [
          Util.createPeriodItem({
            period: yearPeriod,
            type: Const.PeriodItemType.Ytd,
          }),
        ];
      }

      const allPeriodItemsOfYear = [
        Util.createPeriodItem({
          period: yearPeriod,
          type: Const.PeriodItemType.Ytd,
        }),
      ];

      const allPeriods = [yearPeriod];

      const selectedPeriods = Util.filterSelectedPeriodItems(
        periodItems,
        allPeriods,
      );

      if (selectedPeriods.length >= allPeriodItemsOfYear.length) {
        return Util.filterOutSelectedPeriodItems(periodItems, allPeriods);
      }

      return Util.uniqByPeriod([...periodItems, ...allPeriodItemsOfYear]);
    };
    onUpdatePeriodItems(getUpdatedPeriodItems());
  };

  const resolveYearSelectedYearlyView = (year: string) => {
    const yearPeriod = Util.createPeriod({
      year,
      monthDigits: '01',
    });

    const allPeriodItemsOfYear = [
      Util.createPeriodItem({
        period: yearPeriod,
        type: Const.PeriodItemType.Qtd,
      }),
    ];

    const allPeriods = [yearPeriod];

    const selectedPeriods = Util.filterSelectedPeriodItems(
      periodItems,
      allPeriods,
    );

    return selectedPeriods.length >= allPeriodItemsOfYear.length;
  };

  const resolveYearSelected = (year: string) => {
    const yearWithQuartersEntries =
      filterByPeriodsType === Const.MONTHLY_THIN_TAB.id
        ? yearEntriesForMonthly
        : yearEntriesForQuarterly;

    const availableMonthDigitList = Util.getAvailableMonthDigitList({
      year,
      yearWithQuartersEntries,
    });

    const allPeriodItemsOfYear = Util.createPeriodItemList(
      availableMonthDigitList,
      year,
      filterByPeriodsType,
    );

    const allPeriods = Util.getAllPeriods(allPeriodItemsOfYear);

    const selectedPeriods = Util.filterSelectedPeriodItems(
      periodItems,
      allPeriods,
    );

    return selectedPeriods.length === allPeriodItemsOfYear.length;
  };

  const handleSelectAllYears = () => {
    const getUpdatedPeriodItems = () => {
      const allPeriods = yearArrForYearly.map((year) =>
        Util.createPeriod({
          year,
          monthDigits: '01',
        }),
      );
      const allPeriodItemsOfAllYears = allPeriods.map((period) =>
        Util.createPeriodItem({
          period,
          type: Const.PeriodItemType.Ytd,
        }),
      );
      const selectedPeriods = Util.filterSelectedPeriodItems(
        periodItems,
        allPeriods,
      );

      if (selectedPeriods.length >= allPeriodItemsOfAllYears.length) {
        return [];
      }

      return allPeriodItemsOfAllYears;
    };
    onUpdatePeriodItems(getUpdatedPeriodItems());
  };

  const handleBackToDefault = () => {
    onUpdateFilterByPeriodsType?.(Const.PeriodItemType.Mtd);
    const firstPeriod = defaultPeriodItems[0].period;
    setOpenedYear(String(Util.getYearFromPeriod(firstPeriod)));

    if (customSetDefaultPeriodItemsHandler) {
      customSetDefaultPeriodItemsHandler();
      return;
    }
    onUpdatePeriodItems(defaultPeriodItems);
  };

  const resolveAreYearsSelected = () => {
    const allPeriods = yearArrForYearly.map((year) =>
      Util.createPeriod({
        year,
        monthDigits: '01',
      }),
    );
    const allPeriodItemsOfAllYears = allPeriods.map((period) =>
      Util.createPeriodItem({
        period,
        type: Const.PeriodItemType.Qtd,
      }),
    );
    const selectedPeriods = Util.filterSelectedPeriodItems(
      periodItems,
      allPeriods,
    );

    return selectedPeriods.length >= allPeriodItemsOfAllYears.length;
  };

  const handleSelectAllQuarters = () => {
    const getUpdatedPeriodItems = () => {
      const quarters =
        Object.fromEntries(yearEntriesForQuarterly)[openedYear] ?? [];
      const availableMonthDigitList = quarters.flatMap(([_, monthNumbers]) =>
        monthNumbers[0] ? Util.getMonthDigit(monthNumbers[0]) : [],
      );

      const allPeriodItemsOfYear = Util.createPeriodItemList(
        availableMonthDigitList,
        openedYear,
        filterByPeriodsType,
      );

      const allPeriods = Util.getAllPeriods(allPeriodItemsOfYear);

      const selectedPeriods = Util.filterSelectedPeriodItems(
        periodItems,
        allPeriods,
      );

      if (selectedPeriods.length >= allPeriodItemsOfYear.length) {
        return Util.filterOutSelectedPeriodItems(periodItems, allPeriods);
      }

      return Util.uniqByPeriod([...periodItems, ...allPeriodItemsOfYear]);
    };
    onUpdatePeriodItems(getUpdatedPeriodItems());
  };

  const resolveAllQuartersSelected = () => {
    const quarters =
      Object.fromEntries(yearEntriesForQuarterly)[openedYear] ?? [];
    const availableMonthDigitList = quarters.flatMap(([_, monthNumbers]) =>
      monthNumbers[0] ? Util.getMonthDigit(monthNumbers[0]) : [],
    );

    const allPeriodItemsOfYear = Util.createPeriodItemList(
      availableMonthDigitList,
      openedYear,
      filterByPeriodsType,
    );

    const allPeriods = Util.getAllPeriods(allPeriodItemsOfYear);

    const selectedPeriods = Util.filterSelectedPeriodItems(
      periodItems,
      allPeriods,
    );

    return selectedPeriods.length === allPeriodItemsOfYear.length;
  };

  const renderSelectorHeader = () => {
    const resolveAvailableYearArr = () => {
      switch (filterByPeriodsType) {
        case Const.YEARLY_THIN_TAB.id: {
          return yearArrForYearly;
        }
        case Const.MONTHLY_THIN_TAB.id: {
          return yearEntriesForMonthly.map(([year]) => year);
        }
        default: {
          return yearEntriesForQuarterly.map(([year]) => year);
        }
      }
    };
    const availableYearArr = resolveAvailableYearArr();

    switch (filterByPeriodsType) {
      case Const.YEARLY_THIN_TAB.id: {
        const itemJSXList = availableYearArr.map((year) => (
          <Comp.YearSelector
            key={year}
            selected={resolveYearSelectedYearlyView(year)}
            onClick={() => handleSelectYearYearlyView(year)}
          >
            {year}
          </Comp.YearSelector>
        ));

        return (
          <div className="flex items-center justify-center gap-3">
            {availableYearArr.length > Const.MIN_YEARS_FOR_SLIDER && (
              <SliderWithNavigation
                initialSlide={availableYearArr.indexOf(openedYear)}
              >
                {itemJSXList}
              </SliderWithNavigation>
            )}
            {availableYearArr.length <= Const.MIN_YEARS_FOR_SLIDER && (
              <div className="flex items-center justify-center">
                {itemJSXList}
              </div>
            )}
          </div>
        );
      }

      case Const.QUARTERLY_THIN_TAB.id: {
        const itemJSXList = availableYearArr.map((year) => (
          <Comp.YearItem
            key={year}
            opened={year === openedYear}
            hasItems={resolveHasYearItems(year)}
            onClick={() => handleOpenYear(year)}
          >
            {year}
          </Comp.YearItem>
        ));

        return (
          <div className="flex items-center justify-center gap-3">
            {availableYearArr.length > Const.MAX_YEARS_IN_ROW && (
              <SliderWithNavigation>{itemJSXList}</SliderWithNavigation>
            )}
            {availableYearArr.length <= Const.MAX_YEARS_IN_ROW && (
              <div className="flex items-center justify-center">
                {itemJSXList}
              </div>
            )}
          </div>
        );
      }

      default: {
        const itemJSXList = availableYearArr.map((year) => (
          <Comp.YearItem
            key={year}
            opened={year === openedYear}
            hasItems={resolveHasYearItems(year)}
            onClick={() => handleOpenYear(year)}
          >
            {year}
          </Comp.YearItem>
        ));
        const currentYearIndex = availableYearArr.findIndex(
          (year) => year === dayjs().year().toString(),
        );

        return (
          <div className="flex items-center justify-center gap-3">
            {availableYearArr.length > Const.MAX_YEARS_IN_ROW && (
              <SliderWithNavigation
                initialSlide={
                  currentYearIndex === -1 ? 0 : currentYearIndex - 2
                }
              >
                {itemJSXList}
              </SliderWithNavigation>
            )}
            {availableYearArr.length <= Const.MAX_YEARS_IN_ROW && (
              <div className="flex items-center justify-center">
                {itemJSXList}
              </div>
            )}
          </div>
        );
      }
    }
  };

  const renderQuickSelector = () => {
    if (filterByPeriodsType !== 'mtd' || !showQuickSelector) {
      return null;
    }
    const resolvePeriodItems = (key: DefaultMonthDateRange) => {
      // by default, we shift period to the {shift} + 1 month
      const shiftedStartRange = formatToDateStringForRequest(
        dayjs()
          .subtract(Const.DEFAULT_MONTH_DATE_RANGES[key].shift, 'month')
          .startOf('month'),
      );
      const shiftedEndRange = formatToDateStringForRequest(
        dayjs().subtract(1, 'month').startOf('month'),
      );
      return [
        Util.createMonthPeriodItem(shiftedStartRange),
        Util.createMonthPeriodItem(shiftedEndRange),
      ];
    };
    return (
      <div className="flex justify-center gap-2">
        {Object.entries(Const.DEFAULT_MONTH_DATE_RANGES).map(([key, item]) => (
          <Comp.YearSelector
            className="inline-semibold !rounded-lg border border-neutral-150"
            key={key}
            selected={Util.resolveMonthRangeSelected({
              key: key as DefaultMonthDateRange,
              periodItems,
            })}
            onClick={() => {
              onUpdatePeriodItems(
                resolvePeriodItems(key as DefaultMonthDateRange),
              );
            }}
          >
            {item.label}
          </Comp.YearSelector>
        ))}
      </div>
    );
  };

  const renderMainSelector = () => {
    switch (filterByPeriodsType) {
      case Const.MONTHLY_THIN_TAB.id: {
        const yearsObj = Object.fromEntries(yearEntriesForMonthly);
        const quarterEntries = yearsObj[openedYear] ?? [];

        const includesInFromToRange = (monthDigits: string): boolean => {
          const thisDayjs = dayjs(
            Util.createPeriod({ year: openedYear, monthDigits }),
          );
          const fromPeriodDayjs = dayjs(periodItems[0]?.period) ?? null;
          const toPeriodDayjs = dayjs(periodItems.at(-1)?.period) ?? null;

          if (fromPeriodDayjs == null || toPeriodDayjs == null) return false;

          return Util.resolveFromToRange(
            thisDayjs,
            fromPeriodDayjs,
            toPeriodDayjs,
          );
        };

        const resolveHoveredRange = (monthDigits: string): boolean => {
          const thisDayjs = dayjs(
            Util.createPeriod({ year: openedYear, monthDigits }),
          );
          const firstPeriodDayjs = startingPeriodItem?.period
            ? dayjs(startingPeriodItem?.period)
            : null;

          const hoveredPeriodDayjs = hoveredOverPeriod
            ? dayjs(hoveredOverPeriod)
            : null;

          if (firstPeriodDayjs == null || hoveredPeriodDayjs == null)
            return false;

          return Util.resolveDayjsRange(
            thisDayjs,
            firstPeriodDayjs,
            hoveredPeriodDayjs,
          );
        };

        const includesInRange = (monthDigits: string): boolean => {
          if (!fromToMode) return false;

          const isFromToRangeIncludes =
            startingPeriodItem === null && includesInFromToRange(monthDigits);
          const isHoveredRangeIncludes =
            startingPeriodItem !== null && resolveHoveredRange(monthDigits);

          return isFromToRangeIncludes || isHoveredRangeIncludes;
        };

        const resolveIsMonthItemDisabled = (monthDigits: string) =>
          fromToMode &&
          !canSelectStartRange &&
          Util.createPeriod({
            year: openedYear,
            monthDigits,
          }) === startingPeriodItem?.period;

        return (
          <div className="-mb-2 grid grid-cols-2 gap-1">
            {quarterEntries.map(([quarterKey, monthNumbers]) => (
              <Comp.QuarterWithMonths
                key={quarterKey}
                quarterKey={quarterKey}
                hideBtn={isSingleSelection}
                quarterDisabled={Util.isQuarterDisabled(monthNumbers)}
                isQuarterSelected={resolveQuarterSelected(
                  monthNumbers.map(Util.getMonthDigit),
                )}
                onClick={() =>
                  handleQuarterClick(monthNumbers.map(Util.getMonthDigit))
                }
              >
                {Const.QUARTER_MAP[quarterKey].monthDigitsList.map(
                  (monthDigits: string) => (
                    <Comp.MonthItem
                      onMouseEnter={() => {
                        if (startingPeriodItem === null) return;
                        setHoveredOverPeriod(
                          Util.createPeriod({
                            year: openedYear,
                            monthDigits,
                          }),
                        );
                      }}
                      onMouseLeave={() => {
                        setHoveredOverPeriod(null);
                      }}
                      onClick={() => handleMonthClick(monthDigits)}
                      active={resolveIsMonthSelected(monthDigits)}
                      disabled={
                        !monthNumbers.includes(Number(monthDigits)) ||
                        resolveIsMonthItemDisabled(monthDigits)
                      }
                      key={monthDigits}
                      isIncludesInRange={includesInRange(monthDigits)}
                    >
                      {Util.createMonthName(monthDigits)}
                    </Comp.MonthItem>
                  ),
                )}
              </Comp.QuarterWithMonths>
            ))}
          </div>
        );
      }
      case Const.QUARTERLY_THIN_TAB.id: {
        const yearsObj = Object.fromEntries(yearEntriesForQuarterly);
        const quarterEntries = yearsObj[openedYear] ?? [];
        return (
          <div className="-mb-2 flex items-center gap-1">
            {quarterEntries.map(([quarterKey, monthNumbers]) => (
              <Comp.QuarterItem
                key={quarterKey}
                disabled={Util.isQuarterDisabled(monthNumbers)}
                active={resolveQuarterSelected(
                  [monthNumbers[0]].map(Util.getMonthDigit),
                )}
                onClick={() =>
                  handleQuarterClick([monthNumbers[0]].map(Util.getMonthDigit))
                }
              >
                {capitalize(quarterKey)}
              </Comp.QuarterItem>
            ))}
          </div>
        );
      }
      default: {
        return null;
      }
    }
  };

  const renderSelectorFooter = () => {
    const backToDefaultBtn = defaultPeriodItems.length > 0 && (
      <Button variant="secondary" size="xs" onClick={handleBackToDefault}>
        Back to default
      </Button>
    );

    switch (filterByPeriodsType) {
      case Const.YEARLY_THIN_TAB.id: {
        return (
          <div className="flex justify-between">
            {backToDefaultBtn}
            {!isSingleSelection && (
              <Button
                variant="secondary"
                size="xs"
                onClick={handleSelectAllYears}
              >
                {Util.resolveSelectAllTitle(resolveAreYearsSelected())}
              </Button>
            )}
          </div>
        );
      }
      case Const.QUARTERLY_THIN_TAB.id: {
        return (
          <div className="flex justify-between">
            {backToDefaultBtn}
            {!isSingleSelection && (
              <Button
                variant="secondary"
                size="xs"
                onClick={handleSelectAllQuarters}
              >
                {Util.resolveSelectAllTitle(resolveAllQuartersSelected())}
              </Button>
            )}
          </div>
        );
      }
      default: {
        return (
          <div className="flex justify-between">
            {backToDefaultBtn}
            {!isSingleSelection && (
              <Button
                variant="secondary"
                size="xs"
                onClick={() => handleSelectYear(openedYear)}
              >
                {Util.resolveSelectAllTitle(resolveYearSelected(openedYear))}
              </Button>
            )}
          </div>
        );
      }
    }
  };

  const computedLabel = Hooks.useComputedFilterLabel({
    periodItems,
    filterByPeriodsType,
    disableOnlyLabel: isSingleSelection,
    placeholder: labelPlaceholder,
    noPossiblePeriodsCase,
    fromToMode,
  });

  const template = (
    <div className="flex w-full flex-col gap-4">
      {!isSingleSelection && (
        <div className="flex w-full items-center justify-center">
          <ThinTabGroup
            onSelectedItemChange={handleSelectPeriodType}
            selectedItem={Const.THIN_TABS.find(
              (tab) => tab.id === filterByPeriodsType,
            )}
            items={Const.THIN_TABS}
          />
        </div>
      )}

      {renderSelectorHeader()}
      {renderQuickSelector()}
      {renderMainSelector()}

      {renderSelectorFooter()}
    </div>
  );

  const onClose = () => {
    if (canSelectStartRange && startingPeriodItem !== null) {
      onUpdatePeriodItems([startingPeriodItem, startingPeriodItem]);
      setStartingPeriodItem(null);
    }
  };

  return (
    <Popover
      ref={popoverRef}
      hiddenArrow
      maxWidth="360px"
      className="flex w-[360px] items-center justify-center rounded-b-2xl pt-3"
      template={template}
      trigger="click"
      appendToBody
      placement="bottom-end"
      classes={{
        spanContainer: 'min-w-max',
      }}
      onClickOutside={onClose}
      {...popoverProps}
    >
      {popoverProps?.children ?? (
        <Button
          disabled={noPossiblePeriodsCase?.active}
          variant="secondary"
          size="s"
          {...buttonProps}
          className={cn(
            'font-medium leading-none text-info-055',
            buttonProps?.className,
          )}
        >
          {computedLabel}
        </Button>
      )}
    </Popover>
  );
}

export default FlexibleFilterByPeriods;
