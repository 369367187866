import { AssetWebsiteCard } from '@/stories/AssetWebsiteCard/AssetWebsiteCard';
import { CollapsableSection } from '@/stories/CollapsableSection/CollapsableSection';
import { IconButton } from '@/stories/IconButton/IconButton';

interface Props {
  canManage: boolean;
  website: string | undefined;
  websiteTitle: string | undefined;
  onCreate: () => void;
  onDelete: (website: string) => void;
  onEdit: (website: string) => void;
}

export const WebsitesCollapsableSection = ({
  onCreate,
  onEdit,
  onDelete,
  website,
  websiteTitle,
  canManage,
}: Props) => (
  <CollapsableSection
    singleCount
    canManage={canManage}
    count={website ? 1 : 0}
    content={
      website &&
      websiteTitle && (
        <AssetWebsiteCard
          canManage={canManage}
          onEdit={() => onEdit(website)}
          onDelete={() => onDelete(website)}
          website={website}
          websiteTitle={websiteTitle}
        />
      )
    }
    title="WEBSITE"
    actions={
      !website &&
      canManage && (
        <IconButton
          iconName="addSmall"
          onClick={onCreate}
          variant="secondary"
        />
      )
    }
  />
);

export default WebsitesCollapsableSection;
