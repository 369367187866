import { Monaco } from '@monaco-editor/react';
import { languages } from 'monaco-editor';
import { useReportFormulasQuery } from 'bundles/Shared/entities/formula';
import { useGetApiSettingsReportVariablesQuery } from '@/shared/api/settingsReportFormulasEnhanced';
import { useEffect, useMemo } from 'react';
import { groupBy } from 'lodash-es';
import {
  FORMULA_KEYWORDS,
  FORMULA_LANGUAGE_ID,
} from 'bundles/Shared/entities/formula/config';
import CompletionItemProvider = languages.CompletionItemProvider;

import { providerRegistry } from '@/bundles/Shared/entities/formula/lib/providerRegistry';

export const ShowAutocompletion = (
  obj: Record<string, string>,
  monaco: Monaco,
): CompletionItemProvider['provideCompletionItems'] => {
  // Helper function to return the monaco completion item type of a thing
  function getType(thing: string, isMember: boolean | undefined) {
    const finalIsMember = Boolean(isMember); // Give isMember a default value of false

    switch ((typeof thing).toLowerCase()) {
      case 'object':
        return monaco.languages.CompletionItemKind.Class;

      case 'function':
        return finalIsMember
          ? monaco.languages.CompletionItemKind.Method
          : monaco.languages.CompletionItemKind.Function;

      default:
        return finalIsMember
          ? monaco.languages.CompletionItemKind.Property
          : monaco.languages.CompletionItemKind.Variable;
    }
  }

  return (model, position) => {
    // Split everything the user has typed on the current line up at each space, and only look at the last word
    const last_chars: string = model.getValueInRange({
      startLineNumber: position.lineNumber,
      startColumn: 0,
      endLineNumber: position.lineNumber,
      endColumn: position.column,
    });
    const words = last_chars.replace('\t', '').split(' ');
    const active_typing = words[words.length - 1]; // What the user is currently typing (everything after the last space)

    // If the last character typed is a period then we need to look at member objects of the obj object
    const is_member = active_typing.includes('.');

    // Array of autocompletion results
    const result: {
      label: string;
      kind: number;
      detail: string;
      insertText: string;
    }[] = [];

    // Used for generic handling between member and non-member objects
    let last_token = obj;
    let prefix = '';

    if (is_member) {
      // Is a member, get a list of all members, and the prefix
      const parents = [active_typing.split('.')[0]];
      last_token = obj[parents[0]];
      [prefix] = parents;

      // Loop through all the parents the current one will have (to generate prefix)
      for (let i = 1; i < parents.length; i++) {
        if (Object.prototype.hasOwnProperty.call(last_token, parents[i])) {
          prefix += `.${parents[i]}`;
          last_token = last_token[parents[i]];
        } else {
          // Not valid
          return result;
        }
      }

      prefix += '.';
    }

    // Get all the child properties of the last token
    for (const prop in last_token) {
      // Do not show properites that begin with "__"
      if (
        Object.prototype.hasOwnProperty.call(last_token, prop) &&
        !prop.startsWith('__')
      ) {
        // Get the detail type (try-catch) incase object does not have prototype
        let details = '';
        try {
          details = last_token[prop].__proto__.constructor.name;
        } catch (_) {
          details = typeof last_token[prop];
        }

        // Create completion object
        const to_push = {
          label: prefix + prop,
          kind: getType(last_token[prop], is_member),
          detail: details,
          insertText: prop,
        };

        // Add to final results
        result.push(to_push);
      }
    }

    return result;
  };
};

export const useFormulaSuggestionProviderEffect = (
  codeEditorRef: React.MutableRefObject<Monaco | null>,
) => {
  const { formulas } = useReportFormulasQuery();
  const { data: variablesData = [] } = useGetApiSettingsReportVariablesQuery();

  const suggestions = useMemo(() => {
    return [...formulas, ...variablesData].map(({ reference }) => reference);
  }, [formulas, variablesData]);

  useEffect(() => {
    if (
      providerRegistry.get({
        language: FORMULA_LANGUAGE_ID,
        providerType: 'suggestions',
      }) != null
    ) {
      return;
    }
    const suggestionsNamespaces = (suggestions ?? []).map((suggestion) =>
      suggestion.split('.'),
    );
    const groupedByNamespace = groupBy(
      suggestionsNamespaces,
      (suggestion) => suggestion[0],
    );

    const suggestionsEntries = Object.entries(groupedByNamespace).map(
      ([key, value]) => ({
        [key]: Object.fromEntries(
          value.map((suggestion) => {
            return [suggestion[1], null];
          }),
        ),
      }),
    );
    const suggestionsObject = Object.assign({}, ...suggestionsEntries);

    const completionProviderHandle =
      codeEditorRef.current?.languages.registerCompletionItemProvider(
        FORMULA_LANGUAGE_ID,
        {
          triggerCharacters: ['.'],
          provideCompletionItems: (model, position, token) => ({
            suggestions: [...FORMULA_KEYWORDS]
              .map((keyword) => ({
                label: keyword,
                kind: 17, // monaco.languages.CompletionItemKind.Keyword
                insertText: keyword,
              }))
              .concat(
                // eslint-disable-next-line new-cap
                ShowAutocompletion(suggestionsObject, codeEditorRef.current)(
                  model,
                  position,
                  token,
                ),
              ),
          }),
        },
      );

    if (completionProviderHandle != null) {
      providerRegistry.set(
        {
          language: FORMULA_LANGUAGE_ID,
          providerType: 'suggestions',
        },
        completionProviderHandle,
      );
    }

    return () => {
      completionProviderHandle?.dispose();
      providerRegistry.delete({
        language: FORMULA_LANGUAGE_ID,
        providerType: 'suggestions',
      });
    };
  }, [suggestions]);
};
