import { ThinTabGroup } from '@/stories/Tabs/ThinTabGroup/ThinTabGroup';
import React, { useState, useEffect } from 'react';
import { DashboardBoardNavigationContainer } from '@/bundles/Shared/widgets/dashboard/board/ui/navigation/DashboardBoardNavigationContainer';
import { Props } from '@/bundles/Shared/widgets/dashboard/board/ui/navigation/types';
import { DashboardBoardNavigationItem } from '@/bundles/Shared/widgets/dashboard/board/ui/navigation/DashboardBoardNavigationItem';

export const DashboardBoardNavigation = (props: Props) => {
  const { boards, selectedBoardId, onBoardChange, className } = props;
  const [orderedBoards, setOrderedBoards] = useState(boards);

  useEffect(() => {
    // sync local boards for optimistically updating the UI
    if (orderedBoards !== boards) {
      setOrderedBoards(boards);
    }
  }, [boards]);

  return (
    <DashboardBoardNavigation.Container className={className}>
      <ThinTabGroup.Container>
        {orderedBoards.map((board) => (
          <DashboardBoardNavigation.Item
            key={board.id}
            board={board}
            selectedBoardId={selectedBoardId}
            onBoardChange={onBoardChange}
          />
        ))}
      </ThinTabGroup.Container>
    </DashboardBoardNavigation.Container>
  );
};

DashboardBoardNavigation.Container = DashboardBoardNavigationContainer;
DashboardBoardNavigation.Item = DashboardBoardNavigationItem;
