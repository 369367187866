import AssetFilterItem from 'bundles/Shared/components/Filters/assets/AssetFilterItem';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import pluralize from 'pluralize';
import { Modal } from 'stories/Modals/Modal/Modal';
import { Popover } from 'stories/Popover/Popover';
import {
  IFinancialsTableSegment,
  ILegalEntitiableWithLegalEntities,
} from 'types/ReportTableSegment';

interface Props extends DialogProps {
  segment: IFinancialsTableSegment;
  segmentAssets: ILegalEntitiableWithLegalEntities[];
  segmentFunds: ILegalEntitiableWithLegalEntities[];
}

const LegalEntitiables = ({
  type,
  legalEntitiables,
}: {
  type: string;
  legalEntitiables: ILegalEntitiableWithLegalEntities[];
}) => (
  <>
    <div className="inline-semibold">{`${legalEntitiables.length} ${pluralize(
      type,
      legalEntitiables.length,
    )}`}</div>
    <div className="mt-s flex rounded-[0.5rem] bg-light-15 p-s">
      <div className="flex flex-wrap gap-xs">
        {legalEntitiables.map((entitiable) => (
          <Popover
            key={entitiable.id}
            appendTo={() => document.body}
            className="shadow-m-light rounded-[8px] border border-light-30 bg-white p-0"
            template={
              <div>
                {entitiable.legalEntities.length > 0 && (
                  <div className="p-2">
                    <span className="d-block light-60 label-regular mb-xs">
                      {`${type}'s Entities`}
                    </span>
                    <div>
                      {entitiable.legalEntities.map((le) => (
                        <div
                          key={le.id}
                          className="dark-60 inline-regular mb-xs flex"
                        >
                          {le.name} [{le.code}]
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            }
            placement="top-start"
            trigger="mouseenter"
            arrow={false}
            offset={[0, 2]}
            hiddenArrow
          >
            <div className="d-inline-flex">
              <AssetFilterItem
                label={entitiable.name}
                legalEntitiesCount={entitiable.legalEntities.length}
                selectedLegalEntitiesCount={entitiable.legalEntities.length}
              />
            </div>
          </Popover>
        ))}
      </div>
    </div>
  </>
);

const SegmentModal = ({
  segment,
  segmentAssets,
  segmentFunds,
  onClose,
}: Props) => (
  <Modal
    toggle={onClose}
    header={<h5 className="font-weight-700">{segment.title}</h5>}
    bodyPadding="1.5rem 1.5rem 0 1.5rem"
    bodyClasses="bg-light-5"
    size="md"
    classes={{ body: 'bg-light-5 gap-xs' }}
  >
    {segmentAssets.length > 0 && (
      <div className="pb-[1.5rem]">
        <LegalEntitiables type="Asset" legalEntitiables={segmentAssets} />
      </div>
    )}
    {segmentFunds.length > 0 && (
      <div className="pb-[1.5rem]">
        <LegalEntitiables type="Funds" legalEntitiables={segmentFunds} />
      </div>
    )}
  </Modal>
);

export default SegmentModal;
