/* eslint-disable check-file/no-index */
import {
  useSettingsReportUnitTypesExport,
  useSettingsReportUnitTypesImport,
} from '@/features/report/unitTypes/settingsImport/lib/utils';
import { ImportButton } from '@/shared/ui/ImportButton';
import { DropdownItem } from '@/stories/Dropdown/DropdownItem/DropdownItem';
import { GetApiSettingsReportRentRollSourceUnitTypesExportCsvApiArg } from '@/entities/report/unitTypes/api/settingsReportOperationalUnitTypesGeneratedApi';
import { ExportButton } from '@/shared/ui/ExportButton';
import { SettingsReportRentrollSourceUnitTypeImportExportType } from '@/features/report/unitTypes/settingsImport/model';

export const SettingsReportUnitTypesImportButton = () => {
  const [sendFile, options] = useSettingsReportUnitTypesImport('csv');

  return (
    <ImportButton
      onClick={sendFile}
      loading={options.isLoading}
      disabled={options.isLoading}
    />
  );
};

export const SettingsReportUnitTypesImportDropdownItem = ({
  type,
}: {
  type: SettingsReportRentrollSourceUnitTypeImportExportType;
}) => {
  const [sendFile, options] = useSettingsReportUnitTypesImport(type);

  return (
    <DropdownItem
      iconName="importUpload"
      onClick={sendFile}
      disabled={options.isLoading}
      loading={options.isLoading}
    >
      Import
    </DropdownItem>
  );
};

export const SettingsReportUnitTypesExportDropdownItem = ({
  type,
  params,
}: {
  type: SettingsReportRentrollSourceUnitTypeImportExportType;
  params: GetApiSettingsReportRentRollSourceUnitTypesExportCsvApiArg;
}) => {
  const [getFile, options] = useSettingsReportUnitTypesExport(type);

  return (
    <DropdownItem
      iconName="exportDownload"
      onClick={() => getFile(params)}
      disabled={options.isLoading}
      loading={options.isLoading}
    >
      Export
    </DropdownItem>
  );
};

export const SettingsReportUnitTypesExportButton = ({
  params,
  type,
}: {
  params: GetApiSettingsReportRentRollSourceUnitTypesExportCsvApiArg;
  type: SettingsReportRentrollSourceUnitTypeImportExportType;
}) => {
  const [getFile, options] = useSettingsReportUnitTypesExport(type);

  return (
    <ExportButton
      onClick={() => getFile(params)}
      disabled={options.isLoading}
      loading={options.isLoading}
    />
  );
};
