import { convertToSeconds } from '@/shared/lib/converters';
import { cn } from '@/shared/lib/css/cn';
import useDropWrapperClasses from '@/shared/lib/hooks/useDropWrapperClasses';
import useFileDialog from '@/shared/lib/hooks/useFileDialog';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import { ISharedDocument } from '@/types/SharedDocument';
import { DndWrapper } from 'bundles/Assets/components/SharedFiles/BulkUploaderModal';
import TableUpload from 'bundles/DrawPackage/InvoicesFlow/Invoices/invoice/kanban/upload/TableUploader';
import SharedFileDropzone from 'bundles/Shared/components/SharedFileDropzone';
import { presignFilesWithFolder } from 'lib/presignFilesWithFolder';
import { compareDroppedFiles, directUpload } from 'lib/uploadFiles';
import { useState } from 'react';
import Dropzone from 'react-dropzone';
import { Button } from 'stories/Button/Button';
import { Icon } from 'stories/Icon/Icon';
import { LinkButton } from 'stories/LinkButton/LinkButton';
import { Modal } from 'stories/Modals/Modal/Modal';

export type UploadingStateType = 'notStarted' | 'started' | 'finished';

export type SubmitFile = File;

export type RequestFile = {
  key: string;
  filename: string;
  title: string;
  content_type: string;
  size: number;
};

export type ResponsePresignFile = {
  file: SubmitFile;
  signedData: {
    fields: {
      'Content-Disposition': string;
      'Content-Type': string;
      key: string;
      policy: string;
      success_action_status: string;
      'x-amz-algorithm': string;
      'x-amz-credential': string;
      'x-amz-date': string;
      'x-amz-signature': string;
    };
    method: 'GET' | 'POST';
    url: string;
  };
};

interface Props extends DialogProps {
  onUpload: (files: { documents: RequestFile[] }) => void;
  customPresignFile?: (file: SubmitFile) => Promise<ResponsePresignFile>;
}

type ProgressEvent = { loaded: number; total: number };

const UploadFilesModal = ({ onClose, onUpload, customPresignFile }: Props) => {
  const { files, openFileDialog, setFiles } = useFileDialog({
    fileMode: 'replace',
  });

  const {
    dropWrapperClasses,
    setDropWrapperClasses,
    onDrop,
    onDragOver,
    onDragExit,
  } = useDropWrapperClasses();

  const [uploadingState, setUploadingState] =
    useState<UploadingStateType>('notStarted');

  const [dropMode, setDropMode] = useState(true);

  const onUploadProgress = (
    file: File,
    signedData: ResponsePresignFile['signedData'],
    progressEvent: ProgressEvent,
  ) => {
    setFiles((prevFiles) => {
      const updatedFiles = [...prevFiles];
      const currentFileIndex = updatedFiles.findIndex((indexFile) =>
        compareDroppedFiles(indexFile, file),
      );

      updatedFiles[currentFileIndex] = Object.assign(
        updatedFiles[currentFileIndex],
        {
          key: signedData.fields.key,
          progress: Number(
            Math.round((progressEvent.loaded * 100) / progressEvent.total),
          ),
        },
      );

      return updatedFiles;
    });
  };

  const setInitialProgress = (filesList: File[]) => {
    setFiles(
      filesList.map((file) => {
        return Object.assign(file, {
          progress: 0,
        });
      }),
    );
  };

  const uploadFiles = async (filesToSubmit: SubmitFile[]) => {
    const presignedFiles: ResponsePresignFile[] = await presignFilesWithFolder(
      filesToSubmit,
      // @ts-ignore
      customPresignFile,
    );

    await Promise.all(
      presignedFiles.map((data) =>
        directUpload(
          {
            ...data,
            file: data.file,
          },
          {
            onUploadProgress: (progressEvent: ProgressEvent) =>
              onUploadProgress(
                data.file,
                data.signedData,
                progressEvent,
                // @ts-ignore
                setFiles,
              ),
          },
        ),
      ),
    );

    return presignedFiles;
  };

  const generateRequestData = (
    presignedFiles: ResponsePresignFile[],
  ): {
    documents: RequestFile[];
  } => ({
    documents: presignedFiles.map((f) => ({
      key: f.signedData.fields.key,
      filename: f.file.name,
      title: f.file.name,
      content_type: 'application/pdf',
      size: f.file.size,
    })),
  });

  const onSubmit = async () => {
    setUploadingState('started');
    const filesToSubmit = [...files];
    await Promise.resolve(setInitialProgress(filesToSubmit));

    const presignedFiles = await uploadFiles(
      filesToSubmit as unknown as SubmitFile[],
    );

    const data = generateRequestData(presignedFiles);
    await Promise.resolve(onUpload(data));
    setFiles(
      files.map((file) => {
        return Object.assign(file, {
          uploaded: true,
        });
      }),
    );
    setUploadingState('finished');
  };

  const onDropzoneChange = (filesList: File[]) => {
    setFiles([
      ...files,
      ...filesList.map((file, idx) => {
        return Object.assign(file, {
          droppedAt: convertToSeconds(new Date().getTime() as UnixTime) + idx,
        });
      }),
    ] as unknown as File[]);

    setDropMode(false);
  };

  return (
    <DndWrapper
      setClasses={setDropWrapperClasses}
      uploadingState={uploadingState}
      // @ts-ignore
      files={files}
    >
      <Modal
        toggle={onClose}
        size="xl"
        header="Bulk Uploader"
        classes={{
          header: 'bg-white',
          body: 'bg-light-10 h-80vh p-0',
        }}
        disabledClose={uploadingState === 'started'}
        actions={
          <div className="flex w-full justify-between">
            <div>
              {uploadingState === 'notStarted' && (
                <Button onClick={onClose} variant="secondary">
                  Close
                </Button>
              )}
            </div>
            <div className="flex gap-2">
              {uploadingState !== 'finished' && (
                <Button
                  onClick={onSubmit}
                  variant="success"
                  disabled={files.length < 1 || uploadingState === 'started'}
                >
                  Upload Documents
                  {uploadingState === 'started' && (
                    <Icon
                      className="form-button-loading mr-s"
                      iconName="sync"
                    />
                  )}
                </Button>
              )}
              {uploadingState === 'finished' && (
                <Button
                  onClick={() => {
                    setFiles([]);
                    setUploadingState('notStarted');
                  }}
                  variant="success"
                  className="mr-2"
                >
                  Upload More
                </Button>
              )}
              {uploadingState !== 'notStarted' && (
                <Button
                  onClick={onClose}
                  variant="secondary"
                  disabled={uploadingState === 'started'}
                >
                  Close
                </Button>
              )}
            </div>
          </div>
        }
      >
        {(files.length === 0 || dropMode) && (
          <div className="h-full p-4">
            <SharedFileDropzone
              label="Drag your reports here to start uploading"
              onChange={onDropzoneChange}
            />
          </div>
        )}
        {files.length > 0 && !dropMode && (
          <div className="flex h-full flex-col justify-between p-4">
            <div className="pb-8">
              <div
                className={cn('dropzone-invisible-area', dropWrapperClasses)}
                onDrop={onDrop}
                onDragOver={onDragOver}
                onDragExit={onDragExit}
              >
                <Dropzone onDrop={onDropzoneChange} useFsAccessApi={false}>
                  {({ getRootProps, getInputProps }) => (
                    <div
                      className="h-full opacity-100"
                      id="dragAndDropContainer"
                    >
                      <div {...getRootProps({ className: 'h-full' })}>
                        <input {...getInputProps()} />
                        <div className="h-full" />
                      </div>
                    </div>
                  )}
                </Dropzone>
              </div>
              <TableUpload
                files={files as unknown as ISharedDocument[]}
                setFiles={
                  setFiles as unknown as React.SetStateAction<ISharedDocument[]>
                }
                loading={uploadingState === 'started'}
              />
            </div>

            {uploadingState === 'notStarted' && (
              <div className="continue-drag inline-regular flex justify-center gap-1 bg-light py-2 text-center text-dark-60">
                <Icon iconName="magic" />
                <span>
                  Continue to drag & drop your documents above documents or
                </span>
                <LinkButton fontWeight="bold" onClick={() => openFileDialog()}>
                  Browse documents
                </LinkButton>
              </div>
            )}
          </div>
        )}
      </Modal>
    </DndWrapper>
  );
};
export default UploadFilesModal;
