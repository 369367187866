import type {
  DateRangeWidgetState,
  DateRangeWidgetStateWithGranularity,
} from '@/bundles/Shared/widgets/dashboard/widgets/common/model';
import { WidgetStateGranularity } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/state/WidgetStateGranularity';
import { useContextChangeHandler } from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/reportBuilder';
import { ReportingWidgetIdContext } from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/reportBuilderTemplateContext';
import { ReportBuilderTemplateWidgetContext } from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/reportBuilderTemplateWidgetContext';
import { WidgetStateCalendarRangeSelector } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/state/WidgetStateCalendarRangeSelector';
import { widgetHasData } from '@/bundles/Shared/widgets/dashboard/widgets/financialTableSingeDate/lib';
import { WidgetConfigCardProps } from '@/bundles/Shared/widgets/dashboard/widgets/model';
import { selectWidgetById } from '@/entities/report/reportBuilder';
import {
  BaseWidgetConfigCard,
  ExpandableWidgetConfigCard,
} from '@/entities/report/reportBuilder/ui/BaseWidgetConfigCard';
import { useAppSelector } from '@/shared/lib/hooks/redux';
import React from 'react';

export const WidgetContextCalendarRangeCard = (
  props: WidgetConfigCardProps &
    React.ComponentProps<typeof ExpandableWidgetConfigCard>,
) => {
  const widget = useAppSelector(selectWidgetById(props.widgetSection.id));
  const handleContextChange = useContextChangeHandler<DateRangeWidgetState>({
    widgetId: widget?.id ?? '',
    widgetGroupId: widget?.group.id ?? '',
  });
  return (
    <ExpandableWidgetConfigCard {...props} hasData={widgetHasData(props.data)}>
      {widget && (
        <WidgetStateCalendarRangeSelector
          state={widget.context}
          onStateChange={handleContextChange}
        />
      )}
    </ExpandableWidgetConfigCard>
  );
};

export const WidgetContextCalendarRangeWithGranularityCard = (
  props: WidgetConfigCardProps &
    React.ComponentProps<typeof ExpandableWidgetConfigCard>,
) => {
  const widget = useAppSelector(selectWidgetById(props.widgetSection.id));
  const handleContextChange =
    useContextChangeHandler<DateRangeWidgetStateWithGranularity>({
      widgetId: widget?.id ?? '',
      widgetGroupId: widget?.group.id ?? '',
    });

  return (
    <BaseWidgetConfigCard {...props} hasData={widgetHasData(props.data)}>
      {widget && (
        <div className="flex flex-wrap items-start gap-2">
          <WidgetStateGranularity
            state={widget.context}
            onStateChange={handleContextChange}
          />
          <WidgetStateCalendarRangeSelector
            state={widget.context}
            onStateChange={handleContextChange}
          />
        </div>
      )}
    </BaseWidgetConfigCard>
  );
};

// move to xy_chart_single_kpi/card
export const EagleEyeXYChartSingleKpiWidgetCardConfigCard = (
  props: WidgetConfigCardProps &
    React.ComponentProps<typeof ExpandableWidgetConfigCard>,
) => {
  const widget = useAppSelector(selectWidgetById(props.widgetSection.id));
  const handleContextChange =
    useContextChangeHandler<DateRangeWidgetStateWithGranularity>({
      widgetId: widget?.id ?? '',
      widgetGroupId: widget?.group.id ?? '',
    });

  return (
    <BaseWidgetConfigCard {...props} hasData={widgetHasData(props.data)}>
      {widget && (
        <ReportBuilderTemplateWidgetContext.Provider value={widget}>
          <ReportingWidgetIdContext.Provider value={widget.id}>
            <div className="flex flex-wrap items-center gap-2">
              <WidgetStateGranularity
                state={widget.context}
                onStateChange={handleContextChange}
              />
              {/* TODO resolve where to put widgetObjectsConfig when Design is ready */}
              {props.widgetObjectsConfig}
              <WidgetStateCalendarRangeSelector
                state={widget.context}
                onStateChange={handleContextChange}
              />
            </div>
          </ReportingWidgetIdContext.Provider>
        </ReportBuilderTemplateWidgetContext.Provider>
      )}
    </BaseWidgetConfigCard>
  );
};
