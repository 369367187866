import FilterBar from 'bundles/Shared/components/Filters/common/filterBar/FilterBar';
import React from 'react';
import { cn } from '@/shared/lib/css/cn';
import { useAppSelector } from '@/shared/lib/hooks/redux';
import { sum } from 'lodash-es';
import { formatAmount } from '@/shared/lib/formatting/number';
import styles from '@/bundles/Construction/components/Filters/styles.module.scss';
import { IChangeOrderEvent } from 'bundles/Construction/types';

type TField = 'value' | 'weightedAmount' | 'length';

const renderLengthFunc = (
  changeOrderEvents: IChangeOrderEvent[],
): number | string => changeOrderEvents?.length || 0;

const renderAmountFunc = (
  changeOrderEvents: IChangeOrderEvent[],
): number | string =>
  formatAmount(
    sum(
      changeOrderEvents.map((ce) => {
        const value = Number(ce.value);
        if (ce.verified || !ce.probability || ce.probability === 100) {
          return value;
        }
        return 0;
      }),
    ),
  );

const renderWeightedAmountFunc = (
  changeOrderEvents: IChangeOrderEvent[],
): number | string =>
  formatAmount(
    sum(
      changeOrderEvents.map((ce) => {
        const value = Number(ce.weightedAmount);
        if (!ce.verified && ce.probability && ce.probability !== 100) {
          return value;
        }
        return 0;
      }),
    ),
  );

const INFO_POINTS: {
  title: string;
  field: TField;
  render: (changeOrderEvents: IChangeOrderEvent[]) => number | string;
}[] = [
  {
    title: 'Change Events',
    field: 'length',
    render: renderLengthFunc,
  },
  {
    title: 'Total Amount',
    field: 'value',
    render: renderAmountFunc,
  },
  {
    title: 'Total Weighted Amount',
    field: 'weightedAmount',
    render: renderWeightedAmountFunc,
  },
];

const InfoBarByAmount = () => {
  const changeOrderEvents = useAppSelector(
    (state) => state.changeOrderManagement.changeOrderEvents,
  );

  return (
    <div className="mb-l">
      <FilterBar
        customStyles={{
          title: 'label-semibold',
        }}
      >
        <div className="flex items-center mr-s gap-s">
          {INFO_POINTS.map((point, idx) => (
            <div
              key={point.field}
              className={cn(
                'flex items-center',
                idx === 1 && styles.infoBarItem,
              )}
            >
              <div
                className={cn(
                  'light-70 label-semibold',
                  styles.infoBarItemTitle,
                )}
              >
                {point.title}
              </div>
              <div role={point.field} className="dark-60 inline-semibold">
                {point.render(changeOrderEvents)}
              </div>
            </div>
          ))}
        </div>
      </FilterBar>
    </div>
  );
};

export default InfoBarByAmount;
