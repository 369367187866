import React, { useEffect } from 'react';
import { Button } from '@/stories/Button/Button';
import { Input } from '@/stories/FormControls/Inputs/Input/Input';
import { Tumbler } from '@/stories/Tumbler/Tumbler';
import { usePutApiUserProfileUserMutation } from '@/widgets/userProfile/api/userProfileEnhancedApi';
import { SectionField } from '@/stories/Field/FieldsWrappers';
import { useLoadedUserProfile } from '@/widgets/userProfile/hooks/useLoadedUserProfile';
import { HorizontalEditableField } from '@/stories/Field/HorizontalEditableField';
import { useForm } from 'react-hook-form';
import useBoolean from '@/shared/lib/hooks/useBoolean';
import { PhoneNumberInput } from '@/stories/FormControls/Inputs/PhoneNumberInput/PhoneNumberInput';

export const MyDetailsTab = () => {
  const { data: userProfile } = useLoadedUserProfile();
  const {
    value: isEditing,
    setTrue: startEditing,
    setFalse: stopEditing,
  } = useBoolean(false);
  const {
    value: phoneNumberWithExtension,
    setTrue: addExtension,
    setFalse: removeExtension,
  } = useBoolean(userProfile?.phone?.includes('x'));

  const { register, handleSubmit, reset, watch, setValue } = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      phoneNumber: '',
    },
  });

  useEffect(() => {
    reset({
      firstName: userProfile?.firstName ?? '',
      lastName: userProfile?.lastName ?? '',
      phoneNumber: userProfile?.phone ?? '',
    });
  }, [userProfile, reset]);

  const [updateUser, { isLoading }] = usePutApiUserProfileUserMutation();

  const handleSave = handleSubmit(async (data) => {
    await updateUser({
      body: {
        user: {
          first_name: data.firstName,
          last_name: data.lastName,
          phone: data.phoneNumber,
        },
      },
    });
    stopEditing();
  });

  const handleCancel = () => {
    reset();
    stopEditing();
  };

  const editActions = (
    <Button size="xs" variant="secondary" onClick={startEditing}>
      Edit
    </Button>
  );

  const saveActions = (
    <div className="flex gap-2">
      <Button size="xs" variant="secondary" onClick={handleCancel}>
        Cancel
      </Button>
      <Button
        size="xs"
        variant="success"
        onClick={handleSave}
        loading={isLoading}
      >
        Save Changes
      </Button>
    </div>
  );

  return (
    <div className="mt-4 flex flex-col gap-4">
      <SectionField
        labelText="Key Info"
        button={isEditing ? saveActions : editActions}
        className="pb-0"
      >
        <div className="-mx-4 divide-y divide-solid divide-neutral-200">
          <HorizontalEditableField
            label="First Name"
            value={watch('firstName')}
            editable={isEditing}
            required={isEditing}
            className={isEditing ? 'py-2' : 'py-4'}
          >
            <Input {...register('firstName')} />
          </HorizontalEditableField>

          <HorizontalEditableField
            label="Last Name"
            value={watch('lastName')}
            editable={isEditing}
            required={isEditing}
            className={isEditing ? 'py-2' : 'py-4'}
          >
            <Input {...register('lastName')} />
          </HorizontalEditableField>

          <HorizontalEditableField
            label="Phone Number"
            value={watch('phoneNumber')}
            editable={isEditing}
            className={isEditing ? 'py-2' : 'py-4'}
          >
            <PhoneNumberInput
              value={watch('phoneNumber')}
              onChange={(value) => setValue('phoneNumber', value)}
              format={`+# (###) ###-####${
                phoneNumberWithExtension ? ' x-####' : ''
              }`}
              mask="_"
              placeholder="Enter Phone Number"
            />
            <div className="mt-2">
              <Tumbler
                checked={phoneNumberWithExtension}
                onChange={
                  phoneNumberWithExtension ? removeExtension : addExtension
                }
              >
                Add Extension
              </Tumbler>
            </div>
          </HorizontalEditableField>
        </div>
      </SectionField>
    </div>
  );
};
