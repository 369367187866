import React from 'react';
import { groupBy } from 'lodash-es';
import {
  FromSpan,
  GrayColorSpan,
} from '@/stories/FlexibleFilterByPeriods/SelectedPeriodTypeLabel/Monthly';
import { ConditionalAnd } from '@/stories/FlexibleFilterByPeriods/SelectedPeriodTypeLabel/Quarterly';
import * as Types from '@/stories/FlexibleFilterByPeriods/types';
import * as Util from '@/stories/FlexibleFilterByPeriods/utils';

interface Props {
  periodItems: Types.IPeriodItem[];
}

export function Yearly({ periodItems }: Props) {
  const yearGroups = groupBy(periodItems, ({ period }) =>
    String(Util.getYearFromPeriod(period)),
  );

  const yearsArr = Object.keys(yearGroups).map(Number);
  const yearSeqList = Util.createArrOfSequences(yearsArr);

  return (
    <div>
      {yearSeqList.map((yearSeq, idx, arr) => {
        const renderYear = (seq: number[]) => {
          const firstYear = seq[0];

          if (seq.length > 1) {
            const lastYear = seq.at(-1) ?? 0;

            return (
              <span>
                <FromSpan idx={idx} /> {firstYear} <GrayColorSpan text="to" />{' '}
                {lastYear}
              </span>
            );
          }

          return <span>{firstYear}</span>;
        };
        return (
          <ConditionalAnd key={yearSeq[0]} arrLength={arr.length} currIdx={idx}>
            {renderYear(yearSeq)}
          </ConditionalAnd>
        );
      })}
    </div>
  );
}
