import React from 'react';
import { Button } from 'stories/Button/Button';
import { cn } from '@/shared/lib/css/cn';

function FlyBottomPanelBarButton({
  children,
  className,
  ...props
}: React.ComponentProps<typeof Button>) {
  return (
    <Button
      size="s"
      variant="ghost"
      className={cn(
        'btn btn-outline-dark flex items-center border-0 p-1 px-2 font-semibold !text-light',
        className,
      )}
      iconPosition="left"
      {...props}
    >
      {children}
    </Button>
  );
}

export default FlyBottomPanelBarButton;
