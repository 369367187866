import type { SyncDto } from '@/entities/report/syncs/api/syncsGeneratedApi';
import { SyncStatus } from '@/entities/report/syncs/ui/SyncStatus';
import { cn } from '@/shared/lib/css/cn';
import { IconButton } from '@/stories/IconButton/IconButton';
import { isEmpty } from 'lodash-es';

const LONG_MEMOS_LEN = 10;
const isMemosLenLong = (len: number) => len > LONG_MEMOS_LEN;

const SyncMemos = ({
  memos,
  status,
  onClick: onCollapseClick,
}: Pick<SyncDto, 'memos' | 'status'> & {
  onClick: () => void;
}) => (
  <div className="absolute -left-[16px] -right-[16px] -top-[16px] z-10 flex h-max flex-col justify-between gap-4 rounded-b-[8px] bg-neutral-000 p-4 shadow-sm">
    <div className="flex items-center justify-between">
      <SyncStatus status={status} />
      <IconButton
        iconName="arrowTop"
        onClick={() => {
          onCollapseClick();
        }}
      />
    </div>
    <ol
      className={cn(
        'inline-regular flex max-h-[400px] list-decimal flex-col gap-2 overflow-auto pl-[2ch]',
        isMemosLenLong(memos.length) && 'pl-[4ch]',
      )}
    >
      {memos.map((m) => (
        <li
          className={'list-item list-outside pl-1 marker:text-info-055'}
          key={m}
        >
          {m}
        </li>
      ))}
    </ol>
  </div>
);

export type SyncStatusColumExpandClick = (
  actionType: 'collapse' | 'expand',
) => void;

export const SyncStatusColum = ({
  isExpanded,
  onClick,
  ...sync
}: Pick<SyncDto, 'memos' | 'status'> & {
  isExpanded: boolean;
  onClick: SyncStatusColumExpandClick;
}) => (
  <div className="relative flex items-center justify-between">
    <SyncStatus status={sync.status} />
    {!isEmpty(sync.memos) && (
      <IconButton
        iconName="arrowBottom"
        onClick={() => {
          onClick('expand');
        }}
      />
    )}
    {isExpanded && !isEmpty(sync.memos) && (
      <SyncMemos onClick={() => onClick('collapse')} {...sync} />
    )}
  </div>
);
