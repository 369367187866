import React, { ComponentProps, useMemo } from 'react';
import { cn } from '@/shared/lib/css/cn';

import FilterItem from 'bundles/Shared/components/Filters/common/filterItem/FilterItem';
import { CheckList } from 'stories/Checkbox/CheckList';
import { Icon } from 'stories/Icon/Icon';
import {
  ILegalEntitiableWithLegalEntities,
  ILegalEntity,
  TLegalEntitiableType,
} from 'types/ReportTableSegment';
import styles from '@/bundles/Settings/components/REport/TableSegment/FilterLegalEntitiable/FilterLegalEntitiable.module.scss';
import {
  getLegalEntityOptions,
  getLegalEntityOptionsByLegalEntitiable,
} from '@/bundles/Settings/components/REport/TableSegment/utils/getLegalEntityOptions';
import { mapItemsToListOption } from '@/shared/lib/listHelpers';

interface Props extends ComponentProps<typeof FilterItem> {
  handleSelectLegalEntity: (
    legalEntitiableId: number,
    type: TLegalEntitiableType,
    newLegalEntityIds: string[],
  ) => void;
  legalEntitiable: ILegalEntitiableWithLegalEntities;
  legalEntities: ILegalEntity[];
  isEntitialbeUpdated: (entitiableId: number, currentIds: string[]) => boolean;
}

export function FilterLegalEntitiable({
  handleSelectLegalEntity,
  legalEntitiable,
  legalEntities,
  isEntitialbeUpdated,
  ...props
}: Props) {
  const checkedItems = getLegalEntityOptions(legalEntitiable);

  const allLegalEntitties = useMemo(
    () =>
      getLegalEntityOptionsByLegalEntitiable(legalEntitiable, legalEntities),
    [legalEntities],
  );

  const isUpdated = isEntitialbeUpdated(
    legalEntitiable.id,
    checkedItems.map(({ id }) => id),
  );

  return (
    <FilterItem
      rightIcon={
        <>
          <div
            className={cn(styles.wrapper, {
              [styles.updated]: isUpdated,
            })}
          >
            <Icon iconName="entities" />
            <p className="secondary-regular ml-xs">
              {checkedItems.length}
              {allLegalEntitties.length > 0 && (
                <> of {allLegalEntitties.length}</>
              )}
            </p>
          </div>
          <div className={styles.checkListWrapper}>
            <p className="light-60 label-regular mb-xs">Legal Entities</p>
            <CheckList
              className={styles.checkList}
              items={mapItemsToListOption(allLegalEntitties, 'label')}
              value={checkedItems}
              onChange={(newCheckedItems) => {
                const newIds = newCheckedItems.map(
                  ({ value }) => value as string,
                );
                handleSelectLegalEntity(
                  legalEntitiable.id,
                  legalEntitiable.type,
                  newIds,
                );
              }}
            />
          </div>
        </>
      }
      {...props}
    />
  );
}
