import React, { useState } from 'react';
import { useParams } from '@reach/router';
import { rejectChangeEvent } from 'bundles/Construction/reducers/ReconcileChangeEventSlice';
import { useAppDispatch } from '@/shared/lib/hooks/redux';
import { Button } from 'stories/Button/Button';
import { Textarea } from 'stories/Textarea/Textarea';

const RejectedForm = () => {
  const [comment, setComment] = useState('');

  const params = useParams();
  const dispatch = useAppDispatch();

  const rejectedHandle = async () => {
    await dispatch(
      rejectChangeEvent({
        legalEntityCode: params.legalEntityCode,
        id: params.id,
        data: {
          event_comment: comment,
        },
      }),
    );
  };

  return (
    <div>
      <div className="ce-bb-line px-m py-m">
        <div className="light-90 inline-semibold mb-xs">Leave a comment</div>
        <div className="secondary-regular mb-s">
          To Reject, Leave the Comment to Explain the reason
        </div>
        <Textarea
          placeholder="Enter Comment"
          onChange={(e) => setComment(e.target.value)}
          value={comment}
        />
      </div>
      <div className="px-m py-m">
        <div className="label-regular mb-m text-center">
          Complete each step above to Reject
        </div>
        <Button
          variant="danger"
          disabled={!comment}
          iconName="nodeOpen"
          onClick={rejectedHandle}
          className="w-full"
        >
          Reject
        </Button>
      </div>
    </div>
  );
};

export default RejectedForm;
