import React, { useMemo, useState } from 'react';
import { historyStatusTypes } from 'lib/dictionaries';
import { HistoryItem } from 'stories/HistoryItem/HistoryItem';
import {
  IChangeOrderEvent,
  IChangeOrderEventLog,
} from 'bundles/Construction/types';
import { capitalize } from 'lodash-es';
import LoadingPanel from 'bundles/Shared/components/LoadingPanel';
import { useParams } from '@reach/router';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import {
  fetchHistoryItemChangeEvent,
  selectHistoryItemChangeEvent,
} from 'bundles/Construction/reducers/ReconcileChangeEventSlice';
import HistoryDetailsBody from 'bundles/Shared/components/HistoryDetails/HistoryDetailsBody';
import HistoryDetailsModal from '@/bundles/Construction/components/ChangeOrderEvent/HistoryDetailsModalSwither/HistoryDetailsModal/HistoryDetailsModal';
import {
  getDataHDArrowsCE,
  getHistoryDataCE,
} from '@/bundles/Construction/components/ChangeOrderEvent/initData';

interface Props {
  currentObjectState: IChangeOrderEvent;
  logItem: IChangeOrderEventLog;
  firstItem?: boolean;
}

function HistoryElem({ currentObjectState, logItem, firstItem }: Props) {
  const historyItem = useAppSelector(selectHistoryItemChangeEvent);
  const dispatch = useAppDispatch();
  const params = useParams();
  const { data, createdBy, createdAt, title, documents, eventComment, kind } =
    logItem;
  if (!logItem) return <LoadingPanel />;
  const statusModals = ['update', 'revise', 'status_changed'];
  const [showHistoryModal, setShowHistoryModal] = useState(false);

  const config = historyStatusTypes(data)[kind || 'default'];
  const configCE = useMemo(() => getHistoryDataCE(kind), []);

  const showDocument = (doc: File) => {
    window.open(`/shared-files/${doc.id}?disposition=inline`, '_blank');
  };

  const openHistoryModal = async (historyEvent) => {
    await dispatch(
      fetchHistoryItemChangeEvent({
        legalEntityCode: params.legalEntityCode,
        id: params.id,
        historyEventId: historyEvent.id,
      }),
    );
    setShowHistoryModal(true);
  };

  const generateLabel = () => {
    const label: string =
      kind === 'rollback' ? config?.label : capitalize(title ?? config?.label);
    return `${label ?? 'Created'} 
    ${currentObjectState?.number ? `CE ${currentObjectState?.number}` : ''} 
    ${config?.content ?? ''}`;
  };

  const historyData = useMemo(() => getDataHDArrowsCE(kind), []);
  return (
    <div>
      <HistoryItem
        label={generateLabel()}
        createdAt={createdAt}
        createdBy={createdBy}
        icon={{
          iconName: config?.icon,
          iconColor: config?.color,
        }}
        emailDetails={eventComment}
        documents={documents}
        disabledDocument={config?.label === 'removed documents from'}
        notificationAction={
          statusModals.includes(kind)
            ? {
                action: openHistoryModal,
                sendData: logItem,
                label: 'Show Details',
              }
            : null
        }
        onClickDoc={showDocument}
        firstItem={firstItem}
      />
      {showHistoryModal && (
        <HistoryDetailsModal
          title={configCE?.title}
          eventDetails={historyItem}
          onClose={setShowHistoryModal}
          bodyContent={
            <HistoryDetailsBody
              fields={historyItem.fields}
              historyData={historyData}
            />
          }
        />
      )}
    </div>
  );
}

export default HistoryElem;
