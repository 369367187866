import { LegalEntityFieldOption } from 'bundles/Settings/components/Portal/LegalEntities/UpdateLegalEntityModals';
import PermissionList from 'bundles/Shared/components/PermissionList';
import PermissionsModal from 'bundles/Shared/components/PermissionsModal';
import {
  AssetClassSelect,
  LeClassificationSelectField,
  LeClassification,
  findAssetClassOptionByValue,
} from 'bundles/Shared/entities/leClasssification';
import { legalEntityPermissions } from 'bundles/Shared/legalEntityPermissions';
import {
  ASSET_PORTAL_PRODUCT_NAME,
  FUND_PORTAL_PRODUCT_NAME,
} from 'lib/permissions';
import { useMemo, useState } from 'react';
import { Badge } from '@/stories/Badges/Badge/Badge';
import { Button } from '@/stories/Button/Button';
import { Dropdown } from '@/stories/Dropdown/Dropdown';
import { DropdownItem } from '@/stories/Dropdown/DropdownItem/DropdownItem';
import { Input } from '@/stories/FormControls/Inputs/Input/Input';
import { Modal } from '@/stories/Modals/Modal/Modal';
import { ModalActions } from '@/stories/Modals/Modal/ModalActions';
import { LegalEntity } from '@/entities/core/legalEntity';
import { TInvestmentObjectClass } from '@/types/IInvestmentObject';
import { splitWithUnderscore } from '@/shared/lib/string';
import {
  useGetApiSettingsCoreLegalEntitiesLegalEntitiablesQuery,
  usePostApiSettingsCoreLegalEntitiesMutation,
  usePutApiSettingsCoreLegalEntitiesByIdMutation,
} from '@/entities/core/legalEntity/api/settingsCoreLegalEntitiesEnhancedApi';
import {
  PostApiSettingsCoreLegalEntitiesApiArg,
  PutApiSettingsCoreLegalEntitiesByIdApiArg,
} from '@/entities/core/legalEntity/api/settingsCoreLegalEntitiesGeneratedApi';
import * as Sentry from '@sentry/react';
import { ObjectNullable } from '@/shared/types/utils';
import { DialogProps, useModal } from '@/shared/lib/hooks/useModal';
import { SharedSelect } from '@/bundles/Shared/components/GroupForm/FormItems/new/SharedSelect';
import { useAppDispatch } from '@/shared/lib/hooks/redux';
import FundFormModal from '@/bundles/Funds/components/FundFormModal';
import AssetFormModal from '@/bundles/Assets/components/AssetFormModal';
import { createAsset } from '@/bundles/Assets/actions/assets';
import { createFund } from '@/bundles/Funds/actions/funds';
import {
  legalEntitiesSettingsEnhancedApi,
  TAGS,
} from '@/bundles/Shared/entities/legalEntity/api/settingsCoreLegalEntitiesApiEnhanced';
import { partition } from 'lodash-es';

type Props = DialogProps & {
  currentLegalEntity?: LegalEntity;
};

export type LEFieldsToUpdate<New = false> = New extends true
  ? PostApiSettingsCoreLegalEntitiesApiArg['body']
  : PutApiSettingsCoreLegalEntitiesByIdApiArg['body'];

// TODO: FE-3786 fix object select
// TODO: FE-3782 – react-hook-form
const LegalEntityModal = ({ currentLegalEntity, onClose }: Props) => {
  const dispatch = useAppDispatch();
  const { openModal } = useModal();
  const [legalEntity, setLegalEntity] = useState<LegalEntity>(
    currentLegalEntity ?? ({} as LegalEntity),
  );
  const [createLegalEntity] = usePostApiSettingsCoreLegalEntitiesMutation();
  const [updateLegalEntity] = usePutApiSettingsCoreLegalEntitiesByIdMutation();

  const { data: legalEntitiables, isLoading: isLegalEntitiablesLoading } =
    useGetApiSettingsCoreLegalEntitiesLegalEntitiablesQuery();
  const [assets, funds] = useMemo(
    () => partition(legalEntitiables ?? [], (l) => l.objectType === 'Asset'),
    [legalEntitiables],
  );

  const setLegalEntityAttribute = (
    attribute: 'name' | 'code' | 'permitted',
    value: unknown,
  ) => {
    setLegalEntity({
      ...legalEntity,
      [attribute]: value,
    });
  };

  const [permissionsModalOpened, setPermissionsModalOpened] = useState(false);

  const handleCreate = async (params: LEFieldsToUpdate<true>) => {
    const success = await createLegalEntity({ body: params });

    if (!success) {
      return;
    }

    onClose();
  };

  const handleUpdate = async (params: LEFieldsToUpdate) => {
    const success = await updateLegalEntity({
      id: legalEntity.id,
      body: params,
    });

    if (!success) {
      return;
    }

    onClose();
  };

  const onSubmit = async () => {
    const params = {
      name: legalEntity.name,
      legal_entitiable_id: legalEntity.legalEntitiableId,
      legal_entitiable_type: legalEntity.legalEntitiableType,
      classification: legalEntity.classification,
    } satisfies ObjectNullable<LEFieldsToUpdate>;

    const permitted = legalEntity.permitted
      ? legalEntityPermissions(legalEntity.permitted)
      : {};

    if (!params.name) {
      Sentry.captureMessage(
        `Update LE (${legalEntity.id}) with legalEntity.name not found`,
      );
      return;
    }

    if (!params.classification) {
      Sentry.captureMessage(
        `Update LE (${legalEntity.id}) with legalEntity.classification not found`,
      );
      return;
    }

    if (legalEntity.id) {
      await handleUpdate({ ...(params as LEFieldsToUpdate), ...permitted });
    } else {
      if (!params.classification) {
        Sentry.captureMessage(
          `Create LE (${legalEntity.id}) with legalEntity.legalEntitiableType not found`,
        );
        return;
      }

      await handleCreate({
        ...(params as LEFieldsToUpdate<true>),
        ...permitted,
      });
    }
  };

  const assetOptions = useMemo(
    () =>
      assets.map((asset) => ({
        value: `Asset_${asset.id}`,
        label: asset.name,
      })),
    [assets],
  );

  const fundOptions = useMemo(
    () => funds.map((fund) => ({ value: `Fund_${fund.id}`, label: fund.name })),
    [funds],
  );

  const onInvestmentObjectChange = (option: LegalEntityFieldOption) => {
    if (!option?.value) {
      return;
    }

    const [legalEntitiableType, legalEntitiableId] = splitWithUnderscore(
      option.value,
    );

    setLegalEntity({
      ...legalEntity,
      legalEntitiableId: Number(legalEntitiableId),
      legalEntitiableType: legalEntitiableType as TInvestmentObjectClass,
    });
  };

  const onClassificationChange = (value: LeClassification) => {
    setLegalEntity({
      ...legalEntity,
      classification: value,
    });
  };

  const defaultInvestmentObject = useMemo(
    () =>
      [...assetOptions, ...fundOptions].find(
        (option) =>
          option.value ===
          `${legalEntity.legalEntitiableType ?? ''}_${
            legalEntity.legalEntitiableId ?? ''
          }`,
      ),
    [
      legalEntity.legalEntitiableType,
      legalEntity.legalEntitiableId,
      assetOptions,
      fundOptions,
    ],
  );

  const headerText = legalEntity?.id ? 'Edit Legal Entity' : 'Add Legal Entity';
  const successActionText = legalEntity?.id
    ? 'Edit Legal Entity'
    : 'Add Legal Entity';

  const updateObjects = () => {
    dispatch(
      legalEntitiesSettingsEnhancedApi.util.invalidateTags([
        TAGS.LEGAL_ENTITIABLES,
      ]),
    );
  };

  // TODO: FE-3783 – review createFund fn
  const handleCreateFund = async () => {
    const res = await openModal(FundFormModal, {});

    if (res != null) {
      const fund = await dispatch(createFund(res));
      updateObjects();

      onInvestmentObjectChange({
        value: `Fund_${fund.id}`,
        label: fund.name,
      });
    }
  };

  // TODO: FE-3784 – review createAsset fn
  const handleCreateAsset = async () => {
    const res = await openModal(AssetFormModal, {});

    if (res != null) {
      const asset = await dispatch(createAsset(res));
      updateObjects();

      onInvestmentObjectChange({
        value: `Asset_${asset.id}`,
        label: asset.name,
      });
    }
  };

  return (
    <Modal
      toggle={onClose}
      header={headerText}
      actions={
        <ModalActions>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            disabled={!legalEntity.name || !legalEntity.classification}
            variant="success"
            onClick={onSubmit}
          >
            {successActionText}
          </Button>
        </ModalActions>
      }
    >
      <h6 className="header6-regular mb-m">Basic</h6>
      <label htmlFor="le-name" className="mb-s">
        Name <span className="red">*</span>
      </label>
      <Input
        onChange={(e) => setLegalEntityAttribute('name', e.target.value)}
        placeholder="Enter Entity Name"
        id="le-name"
        defaultValue={legalEntity?.name || ''}
        className="mb-m"
      />
      <div className="mb-s flex items-center justify-between">
        <label htmlFor="object">Object</label>
        <Dropdown
          hideOnExpandedAreaClick={false}
          className="min-w-[180px]"
          items={
            <>
              <DropdownItem iconName="asset" onClick={handleCreateAsset}>
                Asset
              </DropdownItem>
              <DropdownItem iconName="funds" onClick={handleCreateFund}>
                Fund
              </DropdownItem>
            </>
          }
        >
          <Button
            iconName="bottom"
            iconPosition="right"
            variant="secondary"
            size="xs"
          >
            Add Object
          </Button>
        </Dropdown>
      </div>
      <SharedSelect
        menuShouldBlockScroll
        placeholder="Select Asset or Fund"
        className="mb-m"
        isLoading={isLegalEntitiablesLoading}
        options={[
          {
            label: 'Assets',
            options: assetOptions,
          },
          {
            label: 'Funds',
            options: fundOptions,
          },
        ]}
        defaultValue={defaultInvestmentObject}
        // @ts-expect-error: should be undefined value, why not?
        value={defaultInvestmentObject}
        onChange={onInvestmentObjectChange}
      />
      <LeClassificationSelectField className="mb-m">
        <AssetClassSelect
          defaultValue={
            legalEntity.classification
              ? findAssetClassOptionByValue(legalEntity.classification)
              : undefined
          }
          onChange={onClassificationChange}
        />
      </LeClassificationSelectField>

      <label htmlFor="permissions" className="mb-m">
        Permissions
      </label>
      {legalEntity.permitted && (
        <div className="flex">
          <PermissionList
            permissions={legalEntity.permitted}
            productName={
              legalEntity.legalEntitiableType == 'Fund'
                ? FUND_PORTAL_PRODUCT_NAME
                : ASSET_PORTAL_PRODUCT_NAME
            }
            investmentObject={{
              type: legalEntity.legalEntitiableType,
              entity: { id: legalEntity.legalEntitiableId },
            }}
            hideActions={false}
            onSubmit={(permissions) =>
              setLegalEntityAttribute('permitted', permissions)
            }
            objectableId={legalEntity.id}
            objectableType="LegalEntity"
            itemType="Legal Entity"
          />
        </div>
      )}
      {!legalEntity.permitted && (
        <Badge
          backgroundColor="var(--blue-light-4)"
          textColor="var(--bl)"
          className="flex w-max cursor-pointer"
          onClick={() => setPermissionsModalOpened(true)}
        >
          Set Permissions
        </Badge>
      )}
      {permissionsModalOpened && (
        <PermissionsModal
          onClose={() => {
            setPermissionsModalOpened(false);
          }}
          onSubmit={(permissions) =>
            setLegalEntityAttribute('permitted', permissions)
          }
          initialState={legalEntity?.permitted || null}
          initialTab={legalEntity?.permitted?.public ? 'public' : 'restricted'}
          productName={ASSET_PORTAL_PRODUCT_NAME}
          itemType="Legal Entity"
        />
      )}
    </Modal>
  );
};

export default LegalEntityModal;
