import { emptySplitApi as api } from '@/app/stores/api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiUserProfileUser: build.query<
      GetApiUserProfileUserApiResponse,
      GetApiUserProfileUserApiArg
    >({
      query: () => ({ url: `/api/user_profile/user` }),
    }),
    putApiUserProfileUser: build.mutation<
      PutApiUserProfileUserApiResponse,
      PutApiUserProfileUserApiArg
    >({
      query: (queryArg) => ({
        url: `/api/user_profile/user`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as userProfileGeneratedApi };
export type GetApiUserProfileUserApiResponse = /** status 200 success */ User;
export type GetApiUserProfileUserApiArg = void;
export type PutApiUserProfileUserApiResponse = /** status 200 success */ User;
export type PutApiUserProfileUserApiArg = {
  body: {
    user?: {
      first_name?: string;
      last_name?: string;
      email?: string;
      phone?: string;
      password?: string;
      current_password?: string;
    };
  };
};
export type User = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  userRole: {
    name: string;
  };
  fullName: string;
};
export const {
  useGetApiUserProfileUserQuery,
  usePutApiUserProfileUserMutation,
} = injectedRtkApi;
