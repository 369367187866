import React, { useEffect, useMemo, useState } from 'react';
import { ChangeableStatusBadge } from 'stories/ChangeableStatusBadge/ChangeableStatusBadge';
import { IconButton } from 'stories/IconButton/IconButton';
import { cn } from '@/shared/lib/css/cn';
import http from 'lib/http';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import { IOption } from 'types/Option';
import { fetchEmployeeUtilization } from 'bundles/Scoreboard/Pipeline/actions';
import { StackedBarV5 } from 'bundles/Scoreboard/Pipeline/components/Pipeline/EmployeeUtilization/StackedBar';
import { StackedArea } from 'bundles/Scoreboard/Pipeline/components/Pipeline/EmployeeUtilization/StackedArea';
import LoadingPanel from 'bundles/Shared/components/LoadingPanel';
import { colors } from 'bundles/Scoreboard/Pipeline/components/chartUtils';

const EmployeeUtilization = () => {
  const DEFAULT_PROPERTY_TYPE = {
    key: 'all',
    color: 'blue',
    label: 'Consolidated',
  };
  const DEFAULT_TEAM = { key: 'all_teams', color: 'blue', label: 'All teams' };

  const dispatch = useAppDispatch();
  const propertyTypes = useAppSelector((state) => state.deals.propertyTypes);
  const employeeUtilization = useAppSelector(
    (state) => state.deals.employeeUtilization,
  );

  const [propertyType, setPropertyType] = useState(DEFAULT_PROPERTY_TYPE);
  const [selectedTeam, setSelectedTeam] = useState(DEFAULT_TEAM);
  const [syncing, setSyncing] = useState(false);

  useEffect(() => {
    dispatch(
      fetchEmployeeUtilization({
        scope: propertyType.label,
        team: selectedTeam.label,
      }),
    );
  }, [propertyType, selectedTeam]);

  const options = useMemo(
    () =>
      propertyTypes.map((item, index) => ({
        label: item,
        key: item.replace(' ', '_').toLowerCase(),
        color: colors[index % colors.length],
      })),
    [propertyTypes],
  );

  if (!employeeUtilization) return <LoadingPanel />;

  const {
    teams,
    stacked_bar: stackedBar,
    stacked_area: stackedArea,
  } = employeeUtilization;

  const sync = () => {
    setSyncing(true);
    http.put('/pipeline/widgets/employee_utilization', {}).then(() => {
      dispatch(
        fetchEmployeeUtilization({
          scope: propertyType.label,
          team: selectedTeam.label,
        }),
      );
      window.toastr.info('The employee utilization widgets synchronized');
      setSyncing(false);
    });
  };

  const optionsTeams = teams.map((item, index) => ({
    label: item,
    key: item.replace(' ', '_').toLowerCase(),
    color: colors[index % colors.length],
  }));

  return (
    <div className="row mt-0">
      <div className="col-lg-12">
        <div className="card">
          <div className="card-header card-header-action">
            <h5>Employee Utilization</h5>
            <div className="flex items-center card-action-wrap gap-m">
              <IconButton
                iconName="sync"
                onClick={sync}
                className={cn({ syncing })}
              />
              <ChangeableStatusBadge
                handleItemClick={(selected: IOption) => {
                  setSelectedTeam(selected);
                }}
                options={[DEFAULT_TEAM, ...optionsTeams]}
                badgeLabel={null}
                selected={selectedTeam}
              />
              <ChangeableStatusBadge
                handleItemClick={(selected: IOption) => {
                  setPropertyType(selected);
                }}
                options={[DEFAULT_PROPERTY_TYPE, ...options]}
                badgeLabel={null}
                selected={propertyType}
              />
            </div>
          </div>

          <div className="card-body p-0">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <StackedBarV5 employeeUtilization={stackedBar} />
              </div>

              <div className="col-lg-12 col-md-12">
                <StackedArea employeeUtilization={stackedArea} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeUtilization;
