import { settingsReportThresholdsApi } from '@/shared/api/settingsReportThresholdsGeneratedApi';
import { EntityToastrCreator } from '@/shared/lib/toastr/entityToastrCreator';

const t = new EntityToastrCreator('Threshold');

const tags = [
  'SettingsReportThresholds',
  'SettingsReportThresholdsLegalEntityOverrides',
];
export const [
  SETTINGS_REPORT_THRESHOLDS_TAG,
  SETTINGS_REPORT_THRESHOLDS_LEGAL_ENTITY_OVERRIDES_TAG,
] = tags;

export const settingsReportThresholdsEnhancedApi =
  settingsReportThresholdsApi.enhanceEndpoints({
    addTagTypes: tags,
    endpoints: {
      getApiSettingsReportThresholds: {
        providesTags: [SETTINGS_REPORT_THRESHOLDS_TAG],
      },
      putApiSettingsReportThresholdsByKind: {
        invalidatesTags: [SETTINGS_REPORT_THRESHOLDS_TAG],
        transformResponse: (response) => {
          toastr.success(t.update().toString());
          return response;
        },
      },
      deleteApiSettingsReportThresholdsByKind: {
        invalidatesTags: [SETTINGS_REPORT_THRESHOLDS_TAG],
        transformResponse: (response) => {
          toastr.success(t.delete().toString());
          return response;
        },
      },
      postApiSettingsReportThresholdsByThresholdKindLegalEntityOverrides: {
        invalidatesTags: [
          SETTINGS_REPORT_THRESHOLDS_LEGAL_ENTITY_OVERRIDES_TAG,
        ],
        transformResponse: (response) => {
          toastr.success(t.update().toString());
          return response;
        },
      },
      putApiSettingsReportThresholdsByThresholdKindLegalEntityOverridesAndId: {
        invalidatesTags: [
          SETTINGS_REPORT_THRESHOLDS_LEGAL_ENTITY_OVERRIDES_TAG,
        ],
        transformResponse: (response) => {
          toastr.success(t.update().toString());
          return response;
        },
      },
      deleteApiSettingsReportThresholdsByThresholdKindLegalEntityOverridesAndId:
        {
          invalidatesTags: [
            SETTINGS_REPORT_THRESHOLDS_LEGAL_ENTITY_OVERRIDES_TAG,
          ],
          transformResponse: (response) => {
            toastr.success(t.delete().toString());
            return response;
          },
        },
      getApiSettingsReportThresholdsByThresholdKindLegalEntityOverrides: {
        providesTags: [SETTINGS_REPORT_THRESHOLDS_LEGAL_ENTITY_OVERRIDES_TAG],
      },
    },
  });

export const {
  useGetApiSettingsReportThresholdsQuery,
  usePutApiSettingsReportThresholdsByKindMutation,
  useDeleteApiSettingsReportThresholdsByKindMutation,
  useGetApiSettingsReportThresholdsByThresholdKindLegalEntityOverridesQuery,
  usePostApiSettingsReportThresholdsByThresholdKindLegalEntityOverridesMutation,
  usePutApiSettingsReportThresholdsByThresholdKindLegalEntityOverridesAndIdMutation,
  useDeleteApiSettingsReportThresholdsByThresholdKindLegalEntityOverridesAndIdMutation,
  useGetApiSettingsReportThresholdsByKindXlsxQuery,
} = settingsReportThresholdsEnhancedApi;
