import { ContextActionsMenu } from '@/bundles/REconcile/components/development/requisitions/widgets/RequisitionsTable';
import { HeaderGroupComponent } from 'bundles/Shared/components/AgGrid/Table/cellComponents/HeaderGroupComponent';
import { Icon } from 'stories/Icon/Icon';
import { HeaderComponent } from 'bundles/Shared/components/AgGrid/Table/cellComponents/HeaderComponent';
import { budgetValueGetter } from '@/bundles/REconcile/components/development/requisitions/shared/requisitionsTable/utils';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import {
  selectExpandBudget,
  setReqTableExpandBudget,
} from '@/bundles/REconcile/components/development/requisitions/shared/requisitionsTable/slices/RequisitionsTableSlice';
import { ContextMenuCell } from 'bundles/Shared/components/AgGrid/Table/cellComponents/ContextMenuCell';
import { CustomCurrencyCellRenderer } from 'bundles/Shared/components/AgGrid/Table/cellComponents/CustomCurrencyCellRenderer';

const DEFAULT_BUDGET_COLUMN_CONFIG = {
  headerComponent: HeaderComponent,
  headerComponentParams: {
    classes: {
      inner: '!justify-center',
    },
  },
  aggFunc: 'sum',
  minWidth: 160,
  valueGetter: budgetValueGetter,
  cellRenderer: CustomCurrencyCellRenderer,
};

const BudgetContextActionsMenu = () => {
  const expandBudget = useAppSelector(selectExpandBudget);
  const dispatch = useAppDispatch();

  return (
    <ContextActionsMenu
      actions={[
        {
          label: expandBudget
            ? 'Collapse to Revised Budget'
            : 'Expand Revised Budget',
          onClick: () => dispatch(setReqTableExpandBudget(!expandBudget)),
        },
      ]}
    />
  );
};

/**
* Implemented only Revised Budget column because haven't actual data in BE
  If we receive it - we uncomment ContextMenuCell in getCollapseBudgetColumns (FE functionality is ready)
  also we can define name of field in getExpandBudgetColumns
*/

export const getExpandBudgetColumns = () => ({
  headerName: 'Development Budget',
  headerGroupComponent: (params) => (
    <ContextMenuCell
      contextMenu={BudgetContextActionsMenu}
      contextMenuParams={{
        placement: 'bottom',
      }}
    >
      <HeaderGroupComponent
        classes={{
          inner: '!justify-center',
        }}
        {...params}
      >
        <Icon iconName="moreVertical" />
      </HeaderGroupComponent>
    </ContextMenuCell>
  ),
  children: [
    {
      ...DEFAULT_BUDGET_COLUMN_CONFIG,
      headerName: 'Initial',
      field: 'budget',
    },
    {
      ...DEFAULT_BUDGET_COLUMN_CONFIG,
      headerName: 'Budget',
      field: 'budget',
    },
    {
      ...DEFAULT_BUDGET_COLUMN_CONFIG,
      headerName: 'Revised',
      field: 'budget',
    },
  ],
});

export const getCollapseBudgetColumns = () => ({
  ...DEFAULT_BUDGET_COLUMN_CONFIG,
  headerName: 'Revised Budget',
  headerComponent: (params) => (
    // <ContextMenuCell write comment of the top ^
    //   contextMenu={BudgetContextActionsMenu}
    //   contextMenuParams={{
    //     placement: 'bottom',
    //   }}
    // >
    <HeaderComponent {...params} />
    /* <Icon iconName="moreVertical" /> */
    /* </HeaderComponentWithCustom>*/
    // </ContextMenuCell>
  ),
  minWidth: 200,
  headerComponentParams: {
    classes: {
      inner: '!justify-between !items-end py-2',
    },
  },
});
