import React, { useRef } from 'react';
import { useIsTextTruncated } from '@/shared/lib/hooks/useIsTextTruncated';
import { PseudoLink } from 'stories/PseudoLink/PseudoLink';
import { Tooltip } from 'stories/Tooltip/Tooltip';
import { DEFAULT_STRING_FALLBACK } from '@/shared/lib/formatting/fallbacks';

export const RemarkCell = ({
  text,
  maxWidth = 'auto',
}: {
  text?: string | null;
  maxWidth?: number | 'auto';
}) => {
  // todo move in separate component TruncatedText
  if (!text) return <>{DEFAULT_STRING_FALLBACK}</>;
  const container = useRef(null);
  const isOverflowed = useIsTextTruncated(container, text.trim());

  return (
    <div
      style={{
        width: maxWidth,
      }}
      ref={container}
    >
      <Tooltip
        classes={{
          spanContainer: 'inline-flex max-w-full',
        }}
        mainText={<span className="break-all">{text}</span>}
        disabled={!isOverflowed}
        maxWidth={300}
        style={{
          overflow: 'hidden',
        }}
      >
        {isOverflowed ? (
          <PseudoLink className="overflow-hidden text-ellipsis whitespace-nowrap">
            {text}
          </PseudoLink>
        ) : (
          <>{text}</>
        )}
      </Tooltip>
    </div>
  );
};
