import { FinancialTableSinglePeriodWidgetConfigDto } from '@/shared/api/dashboardsSettingsGeneratedApi';
import {
  maxIdGenerator,
  transformPeriodShiftFormToDto,
} from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/config';
import { upsertColumnSettings } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/table/updaters';
import { financialTableUpdaters } from '@/bundles/Shared/widgets/dashboard/widgets/common/financialTable/config/updaters';
import {
  FinancialTableSinglePeriodWidgetConfigColumnForm,
  transformFinancialTableSinglePeriodWidgetAdjustmentFormToConfig,
} from '@/bundles/Shared/widgets/dashboard/widgets/financialTableSingePeriod/config/column.form';
import { FinancialTableSinglePeriodBasicTabForm } from '@/bundles/Shared/widgets/dashboard/widgets/financialTableSingePeriod/config/component.form';
import { FinancialTableSinglePeriodWidgetSection } from '@/bundles/Shared/widgets/dashboard/widgets/financialTableSingePeriod/model';
import { produce } from 'immer';

const upsertColumn = (
  {
    columnId,
    columnForm: column,
    groupId,
  }: {
    groupId: string | null;
    columnForm: FinancialTableSinglePeriodWidgetConfigColumnForm;
    columnId?: string;
  },
  config: FinancialTableSinglePeriodWidgetSection['widgetConfig'],
): FinancialTableSinglePeriodWidgetSection['widgetConfig'] => {
  return produce(config, (draft) => {
    let columnIndex = draft.columns.findIndex(
      (c) => c.key.toString() === columnId,
    );
    if (columnIndex === -1) {
      columnIndex = draft.columns.length;
    }
    const columnKey = String(
      column.key ?? maxIdGenerator(draft.columns, 'key'),
    );

    draft.columns[columnIndex] = {
      key: Number(columnKey),
      label: column.label,
      period_shift: transformPeriodShiftFormToDto(
        column.period_shift ?? undefined,
      ),
      ...transformFinancialTableSinglePeriodWidgetAdjustmentFormToConfig(
        column,
      ),
    };

    upsertColumnSettings(column, draft, {
      key: columnKey,
      groupId,
    });
  });
};

const updateDefaultOptions = (
  values: FinancialTableSinglePeriodBasicTabForm,
  widgetConfig: FinancialTableSinglePeriodWidgetConfigDto,
): FinancialTableSinglePeriodWidgetConfigDto => {
  return produce(widgetConfig, (draft) => {
    draft.period_types = values.availablePeriodTypes!;
    draft.default_options.period_type = values.defaultPeriodType!;
    draft.default_options.date.period_shift = transformPeriodShiftFormToDto(
      values.period_shift,
    );
    draft.viz_config!.header_background = values.header_background ?? undefined;
    draft.enable_drill_down = values.enable_drill_down ?? false;
  });
};

export const financialTableSinglePeriodUpdaters = {
  ...financialTableUpdaters,
  upsertColumn,
  updateDefaultOptions,
};
