import { useGetApiSettingsReportThirdPartyExtractionsConnectionsByConnectionIdEmailExtractionsQuery } from '@/shared/api/settingsReportConnectionsEnhancedApi';
import { DeleteExtractionIconButton } from '@/features/report/connections/deleteExtraction/ui';
import { DeleteExtractionAttachmentIconButton } from '@/features/report/connections/deleteExtractionAttachment/ui';
import { ExportExtractionAttachmentIconButton } from '@/features/report/connections/exportExtractionAttachment/ui';
import { convertToMilliseconds } from '@/shared/lib/converters';
import { formatDate } from '@/shared/lib/formatting/dates';
import { ExtractParams, ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import { useParams } from '@reach/router';
import NoDataOverlay from 'bundles/Shared/components/NoDataOverlay';
import {
  PageParamsPagination,
  PageParamsProvider,
  PageParamsSearch,
  usePageParamsContext,
} from 'bundles/Shared/components/pageParams';
import { ConnectionExtractionCard } from 'bundles/Shared/entities/connection/ui/ConnectionExtractionCard';
import { ConnectionParserDocumentStatus } from 'bundles/Shared/entities/connection/ui/ConnectionParserDocumentStatus';
import { AttachmentDocumentStatus } from 'bundles/Shared/entities/extraction/model';
import {
  ConnectionFilterByDate,
  ConnectionFilterByDatePageParams,
} from 'bundles/Shared/features/connection/filter/byDate';
import { ReparseFailedExtractionButton } from 'bundles/Shared/features/connection/reparse';
import { EmailLink } from 'bundles/Shared/shared/EmailLink';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { AnimationLoader } from 'stories/AnimationLoader/AnimationLoader';
import { DocumentCard } from 'stories/DocumentCard/DocumentCard';
import { Icon } from 'stories/Icon/Icon';
import { Popover } from 'stories/Popover/Popover';

const EmailExtractionsTable = () => {
  const { pageParams, setPageParams } =
    usePageParamsContext<ConnectionFilterByDatePageParams>();

  const { fullPath: ROUTE } =
    ROUTES_ROOT.settings.report.connections.connection;
  const params = useParams<ExtractParams<typeof ROUTE>>();
  const connectionId = Number(params.connectionId);

  const { data: extractions, isLoading } =
    useGetApiSettingsReportThirdPartyExtractionsConnectionsByConnectionIdEmailExtractionsQuery(
      {
        connectionId,
        ...pageParams,
      },
    );

  return (
    <PageParamsProvider pageParams={pageParams} setPageParams={setPageParams}>
      <div className="flex items-center justify-between">
        <PageParamsPagination
          sizePerPage={extractions?.meta.perPage ?? 50}
          totalSize={extractions?.meta.totalSize ?? 0}
          loading={isLoading}
        />
        <div className="flex-grow" />
        <div className="flex items-center gap-2">
          <ConnectionFilterByDate />
          <PageParamsSearch />
        </div>
      </div>
      <div className="relative flex flex-col gap-2">
        {isLoading && <AnimationLoader />}
        {!isLoading && extractions?.items.length === 0 && (
          <NoDataOverlay
            title={'No extractions found for the selected period'}
          />
        )}

        <OverlayScrollbarsComponent>
          <div className="max-h-[calc(100vh-186px)]">
            {extractions?.items.map((item) => (
              <ConnectionExtractionCard key={item.id}>
                <ConnectionExtractionCard.Header>
                  <div className="flex items-center gap-2">
                    <div className="inline-semibold whitespace-nowrap text-neutral-850">
                      {formatDate(
                        convertToMilliseconds(item.receivedAt as UnixTime),
                        'MMMM DD, YYYY h:mm A',
                      )}
                    </div>
                    <Popover
                      trigger="mouseenter"
                      classes={{ spanContainer: 'flex w-full justify-between' }}
                      className="p-0"
                      maxWidth={300}
                      appendToBody
                      hiddenArrow
                      template={
                        <div>
                          <div className="label-semibold flex flex-col gap-4 p-4">
                            <div className="flex flex-col gap-1">
                              <div className="text-neutral-900">From:</div>
                              <EmailLink email={item.emailSender}>
                                {item.emailSender}
                              </EmailLink>
                            </div>
                            <div className="flex flex-col gap-1">
                              <div className="text-neutral-900">Subject:</div>
                              <div className="label-semibold">
                                {item.subject}
                              </div>
                            </div>
                          </div>
                        </div>
                      }
                      placement="bottom-start"
                    >
                      <Icon
                        iconName="info"
                        className="hidden group-hover:flex"
                      />
                    </Popover>
                  </div>
                  <div className="flex h-[24px] items-center gap-2">
                    {item.status === 'error' && (
                      <ReparseFailedExtractionButton
                        className="hidden group-hover:block"
                        connectionId={connectionId}
                        attachmentIds={item.attachments.map(
                          (attachment) => attachment.id,
                        )}
                      />
                    )}
                    <div className="label-regular">
                      {formatDate(
                        convertToMilliseconds(item.parsedAt as UnixTime),
                        'MMMM DD, YYYY h:mm A',
                      )}
                    </div>
                    <ConnectionParserDocumentStatus
                      status={item.status ?? 'empty'}
                    />
                    {item.canBeDeleted && (
                      <DeleteExtractionIconButton
                        connectionId={connectionId}
                        extractionId={item.id}
                        extractionType="email"
                      />
                    )}
                  </div>
                </ConnectionExtractionCard.Header>
                <div className="rounded-2xl bg-white">
                  {item.attachments.map((attachment) => (
                    <div
                      key={attachment.id}
                      className="border-b border-neutral-100 last:border-none"
                    >
                      <DocumentCard
                        filename={attachment.title}
                        fileSize={attachment.size}
                        fileExtension={attachment.extension}
                        className="border-0"
                        documentStatus={{
                          successStatus: AttachmentDocumentStatus.SUCCESS,
                          attentionStatus: AttachmentDocumentStatus.SKIPPED,
                          attentionTitle: attachment.error ?? 'Skipped',
                          failedStatus: AttachmentDocumentStatus.FAILED,
                          failedTitle: attachment.error ?? 'Parsed Error',
                          currentStatus:
                            attachment.status ??
                            AttachmentDocumentStatus.PROCESSING,
                        }}
                        actions={
                          <>
                            <ExportExtractionAttachmentIconButton
                              fileUrl={attachment.fileUrl}
                            />
                            {attachment.canBeDeleted && (
                              <DeleteExtractionAttachmentIconButton
                                connectionId={connectionId}
                                attachmentId={attachment.id}
                              />
                            )}
                          </>
                        }
                      />
                    </div>
                  ))}
                </div>
              </ConnectionExtractionCard>
            ))}
          </div>
        </OverlayScrollbarsComponent>
      </div>
    </PageParamsProvider>
  );
};

export default EmailExtractionsTable;
