import { reportWidgetsSliceActions } from '@/entities/report/reportBuilder';
import { useAppDispatch } from '@/shared/lib/hooks/redux';
import { ReportDashboardSectionPosition } from 'bundles/Shared/entities/dashboard/model/types/types';
import { useCallback } from 'react';
import { UnknownRecord } from 'type-fest';

export const REPORT_BUILDER_GRID_COLS_CLASSNAME = 'grid-cols-12';
export const REPORT_BUILDER_GRID_COLS_FULL_SPAN_CLASSNAME = 'col-span-12';

export const isWidgetSectionPositionHalfSized = (
  p: Pick<ReportDashboardSectionPosition, 'w'>,
): boolean => {
  return p.w === 50;
};

export const isWidgetSectionPositionWidthFullSize = (
  p: Pick<ReportDashboardSectionPosition, 'w'>,
): boolean => {
  return p.w === 100;
};

export const resolveWidgetSectionPositionClassName = (
  p: Pick<ReportDashboardSectionPosition, 'w'>,
): string => {
  switch (Math.floor(p.w)) {
    case 75: {
      return 'col-span-9';
    }
    case 25: {
      return 'col-span-3';
    }
    case 66: {
      return 'col-span-8';
    }
    case 33: {
      return 'col-span-4';
    }
    case 50: {
      return 'col-span-6';
    }
    case 100:
    default: {
      return REPORT_BUILDER_GRID_COLS_FULL_SPAN_CLASSNAME;
    }
  }
};

export const useContextChangeHandler = <Context = UnknownRecord>({
  widgetId,
  widgetGroupId,
}: {
  widgetId: string;
  widgetGroupId: string;
}) => {
  const dispatch = useAppDispatch();
  return useCallback(
    (context: Context) => {
      dispatch(
        reportWidgetsSliceActions.updateWidgetContext({
          context,
          widgetId,
          widgetGroupId,
        }),
      );
    },
    [dispatch, widgetGroupId, widgetId],
  );
};
