import {
  FUNDING_SOURCE_KINDS,
  FundingSource,
} from '@/shared/types/reconcile/FundingSource';
import { FUNDING_SOURCES_KIND_CONFIG } from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/fundingSources/shared/consts';
import { cn } from '@/shared/lib/css/cn';

export const KindFSFormatter = ({
  value,
}: {
  value: FundingSource['kind'];
}) => {
  const kindKey = Object.values(FUNDING_SOURCE_KINDS).find(
    (key) => FUNDING_SOURCES_KIND_CONFIG[key].value === value,
  );
  const kind =
    FUNDING_SOURCES_KIND_CONFIG[
      kindKey ?? FUNDING_SOURCE_KINDS.GENERAL_PARTNER
    ];

  const { bgColor, color, title } = kind;
  return (
    <div className="flex items-center gap-2">
      <div className={cn('min-h-[8px] min-w-[8px] rounded-[4px]', bgColor)} />
      <div className={cn('secondary-semibold uppercase', color)}>{title}</div>
    </div>
  );
};
