import React from 'react';
import { Badge } from 'stories/Badges/Badge/Badge';
import { CssVar } from '@/shared/config/cssVar';
import MediaItem from '@/bundles/Assets/components/SharedFiles/MediaItem';
import FeedMessageAttachment from '@/bundles/InvestmentObjects/components/Overview/FeedMessage/FeedMessageAttachment';

const FeedMessageAttachmentsList = ({
  attachedMedia,
  attachedDocuments,
  onMediaRemove,
  onDocumentRemove,
  openLightbox,
  disableScrollId = '',
}) => (
  <div className="card p-[1rem] attachments-list pb-s" id={disableScrollId}>
    {attachedMedia.length > 0 && (
      <div className="mb-[0.5rem]">
        <div className="flex items-center mb-[0.5rem]">
          <h6 className="d-inline feed-message-step-title inline-regular mr-[0.5rem]">
            Media
          </h6>
          <Badge textColor={CssVar.light60} backgroundColor={CssVar.light10}>
            {attachedMedia.length}
          </Badge>
        </div>
        <div className="flex flex-wrap">
          {attachedMedia.map((media) => (
            <div key={media.id} className="mb-[0.5rem]">
              <MediaItem
                media={media}
                hideActions
                selectedItems={[]}
                onRemove={onMediaRemove ? (id) => onMediaRemove(id) : null}
                openLightbox={() => openLightbox(media.id)}
                size="s"
              />
            </div>
          ))}
        </div>
      </div>
    )}

    {attachedDocuments.length > 0 && (
      <div className="flex flex-col">
        <div className="flex justify-between">
          <div className="flex items-center mb-[0.5rem]">
            <h6 className="d-inline feed-message-step-title inline-regular mr-[0.5rem]">
              Documents
            </h6>
            <Badge textColor={CssVar.light60} backgroundColor={CssVar.light10}>
              {attachedDocuments.length}
            </Badge>
          </div>
        </div>
        <div>
          {attachedDocuments.map((doc) => (
            <FeedMessageAttachment
              key={doc.id}
              id={doc.id}
              permissions={doc.permitted}
              onRemove={onDocumentRemove ? (id) => onDocumentRemove(id) : null}
              filename={doc.filename}
              fileSize={doc.size}
              fileExtension={doc.extension}
              withLink={!doc.confidential}
            />
          ))}
        </div>
      </div>
    )}
  </div>
);

export default FeedMessageAttachmentsList;
