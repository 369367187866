import React, { FC, RefObject, useMemo, useState } from 'react';
import { AnimationLoader } from 'stories/AnimationLoader/AnimationLoader';
import { ApprovalStatus } from 'stories/ApprovalStatus/ApprovalStatus';
import { Button } from 'stories/Button/Button';
import { CheckpointsSection } from 'stories/CheckpointsSection/CheckpointsSection';
import { IconButton } from 'stories/IconButton/IconButton';
import { Input } from 'stories/FormControls/Inputs/Input/Input';
import { MultilineAlert } from 'stories/MultilineAlert/MultilineAlert';
import { SelectionSection } from 'stories/SelectionSection/SelectionSection';
import { SelectionSectionItem } from 'stories/SelectionSection/SelectionSectionItem/SelectionSectionItem';
import { SmallAddingButton } from 'stories/SmallAddingButton/SmallAddingButton';
import { formatAmount } from '@/shared/lib/formatting/number';
import { IChangeOrder } from 'bundles/Construction/types';
import {
  updateChangeOrder,
  verifyChangeOrder,
} from 'bundles/Construction/actions/changeOrders';
import FormDate from 'bundles/Shared/components/GroupForm/FormItems/FormDate';
import { useNavigation } from '@/shared/lib/hooks/useNavigation';
import { navigate, useParams } from '@reach/router';
import { useAppDispatch } from '@/shared/lib/hooks/redux';
import { changeReconcileActiveAction } from 'bundles/Construction/reducers/ReconcileSlice';
import { fetchChangeOrder } from 'bundles/Construction/actions';
import { formatDate, formatUnixDate } from '@/shared/lib/formatting/dates';
import dayjs from 'dayjs';
import {
  IVerifySteps,
  TStepVerifyCO,
} from '@/bundles/Construction/components/ChangeOrder/index';
import pluralize from 'pluralize';
import { sumBy } from 'lodash-es';
import {
  convertToMilliseconds,
  formatToDateStringForRequest,
} from '@/shared/lib/converters';
import { toDate } from '@/shared/lib/date';

const SubstantialDateFields = ({ chooseDate, customDate, delayDays }) => (
  <>
    <div className="dark-60 label-regular mb-s">
      Substantial Completion Date
    </div>
    <div className="flex">
      <FormDate
        title=""
        fieldName="substantialCompletionDate"
        formData={{
          substantialCompletionDate: customDate,
        }}
        setFormData={chooseDate}
        styles={{
          wrapper: 'w-full form-item_white',
          size: 'm',
          nomb: true,
        }}
        placeholder="Date"
        format="MM-DD-YYYY"
        formatView="MM-dd-yy"
        leftIcon="calendar"
      />
      <Input
        placeholder="0 d"
        disabled
        className="co-custom-date__days"
        size="m"
        value={`${delayDays} d`}
      />
    </div>
  </>
);

interface Props {
  changeOrder: IChangeOrder;
  verifyStep: IVerifySteps;
  scrollToFunc: (step: TStepVerifyCO) => void;
  legalEntityCode: string;
}

const VerificationForm: FC<Props> = ({
  changeOrder,
  verifyStep,
  scrollToFunc,
  legalEntityCode,
}) => {
  const [loadingCoDate, setLoadingCoDate] = useState(false);
  const lastVerifyDate = changeOrder?.lastVerifiedSubstantialCompletionDate;
  const [selectableCOR, setSelectableCOR] = useState<number[]>([]);
  const verifyCO = changeOrder?.id === changeOrder?.earliestDraftCo?.id;
  const navigation = useNavigation();
  const dispatch = useAppDispatch();
  const params = useParams();

  const checkVerifyFunc = (
    refBtn: RefObject<HTMLDivElement>,
    value: TStepVerifyCO,
  ) => {
    scrollToFunc(value);
  };

  const haveSubstantialCompletion = changeOrder?.changeOrderEvents?.some(
    (ce) => ce.substantialCompletionDate,
  );

  const [customDate, setCustomDate] = useState<Date | null>(() =>
    haveSubstantialCompletion ? null : toDate(lastVerifyDate),
  );

  const COUNT_STEP_VERIFY = haveSubstantialCompletion ? 5 : 4;

  const dataCheckSection = [
    {
      label: 'Change Order Details:',
      checkpoint: [
        {
          title: 'Complete CO Date',
          value: 'completeCoDate',
          check: verifyStep.completeCoDate,
          onClick: checkVerifyFunc,
        },
      ],
    },
    {
      label: 'Documents:',
      checkpoint: [
        {
          title: 'Add supporting documents',
          value: 'addDocs',
          check: verifyStep.addDocs,
          onClick: checkVerifyFunc,
        },
        {
          title: 'Select what needs to be signed',
          value: 'selectFinal',
          check: verifyStep.selectFinal,
          onClick: checkVerifyFunc,
        },
        {
          title: 'Configure who needs to sign',
          value: 'esignPlacement',
          check: verifyStep.esignPlacement,
          onClick: checkVerifyFunc,
        },
      ],
    },
  ];

  const canVerify = useMemo(() => {
    let can = Object.values(verifyStep).filter((i) => i).length;
    if ((haveSubstantialCompletion && customDate) || selectableCOR.length)
      can++;
    return can === COUNT_STEP_VERIFY;
  }, [verifyStep, selectableCOR, customDate]);

  const changeSelectCOR = (ceItem) => {
    const newSelectableCOR = selectableCOR.some(
      (item) => item.id === ceItem.id,
    );
    if (newSelectableCOR) {
      setSelectableCOR(selectableCOR.filter((item) => item.id !== ceItem.id));
    } else {
      setSelectableCOR([...selectableCOR, ceItem]);
    }
  };

  const ceDelayDays = () =>
    sumBy(selectableCOR, (item) =>
      dayjs.unix(item.substantialCompletionDate).diff(lastVerifyDate, 'days'),
    );

  const chooseDate = ({ substantialCompletionDate }) =>
    setCustomDate(substantialCompletionDate);

  const submitVerification = async () => {
    await dispatch(
      verifyChangeOrder(legalEntityCode, changeOrder.id, {
        substantial_completion_date: formatToDateStringForRequest(
          customDate ?? dayjs(lastVerifyDate).add(ceDelayDays(), 'days'),
        ),
      }),
    );
    await dispatch(fetchChangeOrder(legalEntityCode, changeOrder.id));
  };

  const goToEarliestCO = () => {
    navigate(
      navigation.getUrl('RECONCILE_DEVELOPMENT_LEGAL_ENTITY_CHANGE_ORDER', {
        legalEntityCode: params.legalEntityCode,
        changeOrderId: changeOrder?.earliestDraftCo.id,
      }),
    );
    dispatch(changeReconcileActiveAction(null));
  };

  const delayDays = customDate
    ? dayjs(customDate).startOf('date').diff(lastVerifyDate, 'days')
    : ceDelayDays();
  const impactToDateFormatted = formatDate(
    customDate ?? dayjs(lastVerifyDate).add(ceDelayDays(), 'days'),
    'MMMM DD, YYYY',
  );

  const pluralizedFormattedDays = (date) => {
    const days = dayjs.unix(date).diff(lastVerifyDate, 'days');
    return `${days} ${pluralize('day', days)}`;
  };

  const handleChangingCoDate = async ({ coDate }) => {
    setLoadingCoDate(true);
    await dispatch(
      updateChangeOrder(changeOrder.legalEntityCode, changeOrder?.id, {
        co_date: formatToDateStringForRequest(coDate),
      }),
    );
    setLoadingCoDate(false);
  };

  if (!verifyCO) {
    return (
      <div className="available-action__line px-m py-m">
        <div>
          <MultilineAlert
            title="At least one of earlier draft COs needs to be verified first!"
            message="You could reach and verify the earliest draft CO via button below."
            flexibleWidth
          />
        </div>
        <div className="pt-m">
          <Button variant="success" onClick={goToEarliestCO} className="w-full">
            Complete the earliest COs
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div>
        <div className="flex flex-col gap-2 p-4 available-action__line">
          {loadingCoDate && <AnimationLoader />}
          <div className="flex flex-col gap-1">
            <div className="inline-semibold text-neutral-800">CO Date</div>
            <div className="secondary-regular">Select CO Date</div>
          </div>
          <FormDate
            title=""
            fieldName="coDate"
            formData={{
              coDate: convertToMilliseconds(changeOrder?.date),
            }}
            setFormData={handleChangingCoDate}
            styles={{
              wrapper: 'w-full form-item_white',
              size: 'm',
              nomb: true,
            }}
            placeholder="Date"
            format="MM-DD-YYYY"
            formatView="MM-dd-yy"
            leftIcon="calendar"
            disabled={loadingCoDate}
          />
        </div>
        <div className="available-action__line px-m py-m">
          <CheckpointsSection
            title="Complete the following steps:"
            data={dataCheckSection}
          />
        </div>
        {haveSubstantialCompletion ? (
          <div className="available-action__line px-m py-m">
            <div className="light-90 inline-semibold mb-xs">
              Add Substantial Completion date
            </div>
            <div className="secondary-regular mb-m">
              Select Substantial Completion Date or Combine Timing Impact
            </div>
            <SelectionSection disabled={Boolean(customDate)}>
              {changeOrder?.changeOrderEvents
                .filter((ce) => ce.substantialCompletionDate)
                .map((ceItem, idx) => (
                  <SelectionSectionItem
                    onChange={() => changeSelectCOR(ceItem)}
                    checked={selectableCOR.some(
                      (sItem) => sItem.id === ceItem.id,
                    )}
                    disabled={customDate}
                    key={`ceItem${idx}`}
                  >
                    <div className="flex justify-between w-full primary">
                      <div>
                        <h5 className="dark-60 inline-semibold mb-xs">
                          {ceItem.category} {ceItem.displayNumber}
                        </h5>
                        <p className="light-60 secondary-regular">
                          {formatAmount(ceItem.value)}
                        </p>
                      </div>
                      <ApprovalStatus
                        text={pluralizedFormattedDays(
                          ceItem.substantialCompletionDate,
                        )}
                        iconName="clock"
                        tooltipText={formatUnixDate(
                          ceItem.substantialCompletionDate,
                          'MM-DD-YY',
                        )}
                      />
                    </div>
                  </SelectionSectionItem>
                ))}
            </SelectionSection>
            {Boolean(selectableCOR.length) && !customDate && (
              <div className="text-right secondary-regular mt-s">
                Total Impact:{' '}
                <span className="dark-60 mr-xs">{delayDays}d</span>(
                {impactToDateFormatted})
              </div>
            )}
            {customDate ? (
              <div className="co-custom-date mt-m">
                <div className="flex justify-between mb-m">
                  <div className="co-custom-date__title">Custom Date</div>
                  <IconButton
                    iconName="closeSmall"
                    variant="white"
                    onClick={() => setCustomDate(null)}
                  />
                </div>
                <SubstantialDateFields
                  chooseDate={chooseDate}
                  customDate={customDate}
                  delayDays={delayDays}
                />
              </div>
            ) : (
              <div className="mt-m">
                <SmallAddingButton
                  onClick={() => setCustomDate(new Date(lastVerifyDate))}
                >
                  Add Custom Date
                </SmallAddingButton>
              </div>
            )}
          </div>
        ) : (
          <div className="co-custom-date mt-m">
            <div className="flex justify-between mb-m">
              <div className="co-custom-date__title">Custom Date</div>
            </div>
            <SubstantialDateFields
              chooseDate={chooseDate}
              customDate={customDate}
              delayDays={delayDays}
            />
          </div>
        )}
        <div className="px-m py-m">
          <div className="text-center label-regular mb-m">
            Complete each step above to Verify
          </div>
          <Button
            variant="success"
            iconName="checkDouble"
            onClick={submitVerification}
            disabled={!canVerify}
            className="w-full"
          >
            Verify
          </Button>
        </div>
      </div>
    </div>
  );
};

export default VerificationForm;
