import FileExtensionIcon from 'bundles/Shared/components/fileExtensionIcon/FileExtensionIcon';
import { isEqual } from 'lodash-es';
import pluralize from 'pluralize';
import prettyBytes from 'pretty-bytes';
import React, { FC, SetStateAction } from 'react';
import { AnimationLoader } from 'stories/AnimationLoader/AnimationLoader';
import { Icon } from 'stories/Icon/Icon';
import { IconButton } from 'stories/IconButton/IconButton';
import { ISharedDocument } from 'types/SharedDocument';

interface File extends ISharedDocument {
  size: number;
  droppedAt: number;
  uploaded: boolean;
}

interface ITableUploadProps {
  files: File[];
  setFiles: React.Dispatch<SetStateAction<File[]>>;
  loading: boolean;
}

const TableUpload: FC<ITableUploadProps> = ({ files, setFiles, loading }) => {
  const removeFile = (file: File) => {
    setFiles(files.filter((f) => !isEqual(file, f)));
  };

  return (
    <div className="flex flex-col gap-[10px]">
      <div className="light-60 secondary-regular flex items-center gap-1 px-4">
        <div className="flex gap-1">
          <div>{files.length}</div>
          <div className="font-medium uppercase">
            {pluralize('invoice', files.length)}
          </div>
        </div>
        <div>
          <IconButton
            className="ml-1 border border-light-30 bg-light-10"
            iconName="sortOff"
            offHoverStyles
            disabled
          />
        </div>
      </div>
      <div className="overflow-hidden rounded-2xl">
        {files.map((fileItem) => (
          <div
            key={fileItem.droppedAt}
            className="flex justify-between border-b border-light-10 bg-light px-4"
          >
            <div className="flex flex-[1] gap-[10px] border-r border-light-10 py-4">
              <div className="flex h-[40px] w-[40px] items-center justify-center rounded-lg bg-white">
                <FileExtensionIcon extension={fileItem.extension} />
              </div>
              <div>
                <div className="dark-60 inline-semibold">{fileItem.name}</div>
                <div className="light-60 secondary-regular flex gap-1">
                  <span>{prettyBytes(fileItem.size)}</span>
                  <span>•</span>
                  <span>{fileItem.extension}</span>
                </div>
              </div>
            </div>
            <div className="pl-4 pt-4">
              {!fileItem.uploaded && !loading && (
                <IconButton
                  color="dark-60"
                  iconName="closeSmall"
                  onClick={() => removeFile(fileItem)}
                />
              )}
              {loading && (
                <AnimationLoader
                  className="relative h-[24px] w-[24px]"
                  classNameWrapper="bg-light shadow-none"
                />
              )}
              {fileItem.uploaded && <Icon color="green" iconName="check" />}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TableUpload;
