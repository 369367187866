import { PropsWithChildren } from 'react';
import { cn } from '@/shared/lib/css/cn';

export default function Banner({
  children,
  className,
}: PropsWithChildren & PropsWithClassName) {
  return (
    <div
      className={cn(
        'secondary-regular flex w-full border border-attention-055 bg-attention-020 px-6 py-2 text-neutral-900',
        className,
      )}
    >
      {children}
    </div>
  );
}
