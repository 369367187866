import { EagleEyeDashboardLegalEntitiesField } from '@/bundles/Shared/features/dashboard/create/ui/LegalEntitiesField';
import useBoolean from '@/shared/lib/hooks/useBoolean';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import { Button } from '@/stories/Button/Button';
import { Field } from '@/stories/Field/Field';
import { Input } from '@/stories/FormControls/Inputs/Input/Input';
import { DASHBOARD_ENTITY_NAME } from 'bundles/Shared/entities/dashboard/config';
import { DashboardFilterObjectItem } from 'bundles/Shared/entities/dashboard/ui/DashboardFilterObjectItem';
import type { DashboardFilterObjectTypes } from 'bundles/Shared/entities/dashboard/model/types/types';
import { useReportEagleEyeDashboardAssetsQuery } from 'bundles/Shared/entities/dashboard/api';
import { ObjectSelectionModal } from 'bundles/Shared/features/dashboard/create/ui/ObjectSelectionModal';
import { EagleEyeDashboardUpdateForm } from 'bundles/Shared/features/dashboard/update/eagleEye';
import { capitalize } from 'lodash-es';
import type { ComponentProps } from 'react';
import { Control, FormProvider, useController, useForm } from 'react-hook-form';
import { FieldsContainer } from 'stories/Field/Field';
import { SectionField } from 'stories/Field/FieldsWrappers';
import { SidePanel } from 'stories/Modals/Modal/Modal';

type Props = DialogProps<EagleEyeDashboardUpdateForm> & {
  type: 'create' | 'edit';
  dashboard?: EagleEyeDashboardUpdateForm;
  submitText?: string;
};

const ObjectSelectionItem = ({
  type,
  control,
  sidePanelProps,
}: {
  type: DashboardFilterObjectTypes;
  control: Control<EagleEyeDashboardUpdateForm>;
  sidePanelProps?: Pick<ComponentProps<typeof SidePanel>, 'position' | 'size'>;
}) => {
  const { objects } = useReportEagleEyeDashboardAssetsQuery();
  const { value: isOpen, toggle: toggleIsOpen } = useBoolean();
  const { field } = useController({
    control,
    name: type === 'fund' ? 'asset_ids' : `${type}_ids`,
  });
  const isImplemented = type !== 'fund';

  return (
    <>
      <DashboardFilterObjectItem
        onClick={toggleIsOpen}
        count={isImplemented ? field.value?.length : 0}
        type={type}
        disabled={!isImplemented}
      />
      {isOpen && (
        <ObjectSelectionModal
          sidePanelProps={sidePanelProps}
          objectType={type}
          onClose={toggleIsOpen}
          onChange={(ids) => {
            field.onChange(ids);
          }}
          defaultObjectIds={field.value}
          objects={objects.filter((o) => o.type === type)}
        />
      )}
    </>
  );
};

export function EagleEyeDashboardUpdate({
  onClose,
  onSubmit,
  dashboard,
  type,
  submitText,
}: Props) {
  const methods = useForm<EagleEyeDashboardUpdateForm>({
    mode: 'all',
    defaultValues: {
      ...dashboard,
    },
  });
  const {
    register,
    formState: { isValid, isDirty },
    control,
    handleSubmit,
  } = methods;
  const onSubmitHandler = handleSubmit((data) => {
    onSubmit?.(data);
  });

  return (
    <SidePanel
      toggle={onClose}
      header={`${capitalize(type)} ${DASHBOARD_ENTITY_NAME}`}
      actions={
        <>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="success"
            disabled={!isValid || (type === 'edit' && !isDirty)}
            onClick={onSubmitHandler}
          >
            {(submitText ?? type === 'edit') ? 'Save Updates' : 'Create'}
          </Button>
        </>
      }
    >
      <FormProvider {...methods}>
        <FieldsContainer>
          <SectionField labelText="Basic">
            <Field labelText="Title" required>
              <Input
                {...register('name', {
                  required: true,
                })}
              />
            </Field>
          </SectionField>

          <SectionField
            labelText="Objects"
            classes={{ itemsContainer: 'gap-4' }}
          >
            <div className="grid grid-cols-3 gap-2">
              <ObjectSelectionItem type="asset" control={control} />
              <ObjectSelectionItem type="fund" control={control} />
              <ObjectSelectionItem type="segment" control={control} />
            </div>

            <EagleEyeDashboardLegalEntitiesField />
          </SectionField>
        </FieldsContainer>
      </FormProvider>
    </SidePanel>
  );
}

export default EagleEyeDashboardUpdate;
