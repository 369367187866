import {
  plainPreviewEagleEyeReportPdf,
  plainPreviewObjectLevelReportPdf,
} from '@/entities/report/reportBuilder/lib/plainPreviewReportPdf';
import {
  useFetchPreviewPdf,
  usePdfBlobCreateObjectURL,
} from '@/entities/report/reportBuilder/lib/previewPdf';
import { useReportingEntityKindContext } from '@/entities/reporting/context/entityKind';
import { useAssetIdSearchParam } from '@/shared/lib/hooks/navigation/useAssetIdSearchParam';
import { useAppSelector } from '@/shared/lib/hooks/redux';
import { useHostedUrl } from '@/shared/lib/hooks/useHostedUrl';
import { useParams } from '@reach/router';

export const useGetApiCoreAssetsByAssetIdReportBuilderReportsAndIdPreviewPdfQuery =
  () => {
    const reportKind = useReportingEntityKindContext();
    const params = useParams();
    const assetId = useAssetIdSearchParam();
    const hostedUrl = useHostedUrl();
    const pendingRequestIds = useAppSelector(
      (s) => s.reportBuilder.pendingRequestIds,
    );
    const skipFetch = useAppSelector(
      (s) =>
        s.reportBuilder.pendingRequestIds.length !== 0 ||
        s.reportBuilder.requestsStarted,
    );

    const { isLoading, pdfBlob } = useFetchPreviewPdf({
      skip: () =>
        reportKind === 'eagle_eye'
          ? skipFetch
          : skipFetch || assetId == null || params.id == null,
      effectDeps: [pendingRequestIds],
      fetchCallback: async () => {
        if (reportKind === 'eagle_eye') {
          return plainPreviewEagleEyeReportPdf({
            reportId: params.reportId,
            renderPdfHost: hostedUrl,
          });
        }
        return plainPreviewObjectLevelReportPdf({
          assetId: assetId!,
          renderPdfHost: hostedUrl,
          reportId: params.id,
        });
      },
    });

    const dataObjectURL = usePdfBlobCreateObjectURL(pdfBlob);

    return {
      dataObjectURL,
      data: pdfBlob,
      isLoading,
    };
  };
