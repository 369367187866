/* eslint-disable jsx-a11y/media-has-caption */
import saveFileOnUserDevice from '@/shared/lib/browser/saveFileOnUserDevice';
import useBoolean from '@/shared/lib/hooks/useBoolean';
import { PdfPreview } from '@/shared/ui/PdfPreview';
import { RouteComponentProps } from '@reach/router';
import axios from 'axios';
import DownloadButton from 'bundles/Assets/components/SharedFiles/Table/buttons/DownloadButton';
import { NotFound } from 'bundles/Errors';
import http from 'lib/http';
import { useEffect, useState } from 'react';
import { AnimationLoader } from 'stories/AnimationLoader/AnimationLoader';
import { ISharedDocument } from 'types/SharedDocument';

interface Props extends RouteComponentProps<{ id: string }> {
  showTrash?: boolean;
}

const previewableFormats = ['video/', 'image/', '/pdf'] as const;
const canPreview = (extension: string) =>
  previewableFormats.some((format) => extension.includes(format));

const SharedFiles = ({ id, showTrash }: Props) => {
  const [sharedFile, setSharedFile] = useState<ISharedDocument>();
  const [error, setError] = useState();
  const {
    value: loading,
    setTrue: enableLoading,
    setFalse: disableLoading,
  } = useBoolean();
  const params = new URLSearchParams(window.location.search);

  // TODO: for now it either redirects to the file or downloads it and closes the window.
  // Maybe for preview we even want to show the file within this component + show some metadata additionally
  useEffect(() => {
    const url = showTrash ? '/shared_files/trash' : '/shared_files';
    const getSharedFile = async () => {
      try {
        enableLoading();
        if (id == null) throw new Error('Share file ID not found');

        const res = await http.get(`${url}/${id}${window.location.search}`);
        const json = (await res.json()) as ISharedDocument;

        if (
          canPreview(json.mimeType) &&
          params.get('disposition') === 'inline'
        ) {
          setSharedFile(json);
        } else {
          const urlFile = await axios.get(json.url, { responseType: 'blob' });
          saveFileOnUserDevice(urlFile.data, json.name);
        }
      } catch (err) {
        setError(err);
      } finally {
        disableLoading();
      }
    };
    getSharedFile();
  }, []);

  if (error) return <NotFound />;
  if (loading) return <AnimationLoader />;

  if (sharedFile == null) return;

  if (sharedFile.mimeType.includes('/pdf')) {
    return <PdfPreview fileUrl={sharedFile.url} />;
  }

  if (sharedFile.mimeType.includes('video/')) {
    return <video width="80%" src={sharedFile.url} controls />;
  }

  if (sharedFile.mimeType.includes('image/')) {
    return <img src={sharedFile.url} alt={sharedFile.title} />;
  }

  return (
    <p>
      Sorry, we can not render preview of the file, pleas download it, <br />
      <DownloadButton row={sharedFile} /> {sharedFile.title}
    </p>
  );
};

export default SharedFiles;
