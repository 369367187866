import { useGetApiReconcileForecastingForecastsByForecastIdBudgetsAndIdQuery } from '@/entities/reconcile/forecasting/api/reconcileForecastingEntitiesEnchancedApi';

export const useForecastBudget = (
  forecastId: string | undefined,
  budgetId: string | undefined,
) =>
  useGetApiReconcileForecastingForecastsByForecastIdBudgetsAndIdQuery(
    {
      id: budgetId ?? '',
      forecastId: forecastId ?? '',
    },
    {
      skip: !forecastId || !budgetId,
      selectFromResult: ({ data, isLoading, isFetching }) => ({
        budget: data,
        isLoading,
        isFetching,
      }),
      refetchOnMountOrArgChange: true,
    },
  );
