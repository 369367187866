import { useGetApiSettingsCoreLegalEntitiesMetaQuery } from '@/entities/core/legalEntity/api/settingsCoreLegalEntitiesEnhancedApi';
import {
  useModal,
  useResetAllModalsOnUnmount,
} from '@/shared/lib/hooks/useModal';
import { Button } from '@/stories/Button/Button';
import { ConfirmationModal } from '@/stories/Modals/ConfirmationModal/ConfirmationModal';
import { Icon } from '@/stories/Icon/Icon';
import { Tooltip } from '@/stories/Tooltip/Tooltip';
import pluralize from 'pluralize';
import { LegalEntityManagmentPeriodsMappingModal } from '@/bundles/Settings/components/Portal/LegalEntities/components/LegalEntityManagmentPeriodsMappingModal';
import { useState } from 'react';

export const LegalEntityMappingButton = () => {
  const { data } = useGetApiSettingsCoreLegalEntitiesMetaQuery();
  const count = data?.rawDataPeriodsAvailableForMappingSize;
  const excludedCount = data?.excludedRawDataPeriodsAvailableForMappingSize;
  const { openModal } = useModal();
  const [showConfirmModal, setConfirmModal] = useState(false);

  const handleOpenModal = () => {
    openModal(LegalEntityManagmentPeriodsMappingModal, {
      initialSourceTab: count ? 'included' : 'excluded',
      onAfterClose: (success) => {
        if (success && count && count > 0) {
          setConfirmModal(true);
        }
      },
    });
  };

  const confirmModalHandle = (resolvedResult: boolean) => {
    if (!resolvedResult) {
      setConfirmModal(false);

      return false;
    }

    setConfirmModal(false);
    handleOpenModal();

    return resolvedResult;
  };

  useResetAllModalsOnUnmount();

  if (!count && !excludedCount) {
    return null;
  }

  if (!count) {
    return (
      <Tooltip mainText="Excluded Data Sources">
        <Button size="s" iconName="archived" onClick={handleOpenModal}>
          {excludedCount}
        </Button>
      </Tooltip>
    );
  }

  return (
    <>
      <Button
        variant="warning"
        size="s"
        iconName="fire"
        onClick={handleOpenModal}
      >
        {count} data {pluralize('source', count)} for mapping
      </Button>

      {/* TODO: tmp for friday demo */}
      {showConfirmModal && (
        <ConfirmationModal
          maxWidth="18.75rem"
          title={
            <div className="flex flex-col items-center gap-6">
              <Icon
                iconName="acceptAlt"
                className="text-success-055"
                style={{ fontSize: '2rem' }}
              />
              <div className="text-base font-semibold">Success!</div>
            </div>
          }
          subtitle={
            <div className="flex flex-col text-sm text-center gap-y-2 text-neutral-550">
              <div className="font-semibold">
                You have {count} data {pluralize('source', count)} for mapping
              </div>
              Would you like to continue mapping data sources?
            </div>
          }
          actions={{
            primaryButton: {
              text: 'Yes',
              variant: 'success',
              disabled: false,
            },
            secondaryButton: {
              text: 'No',
              variant: 'secondary',
              disabled: false,
            },
          }}
          handleResolve={confirmModalHandle}
        />
      )}
    </>
  );
};
