import { documentationApi } from '@/shared/api/documentationGeneratedApi';

export const documentationEnhancedApi = documentationApi.enhanceEndpoints({
  addTagTypes: ['Page', 'PageRole'],
  endpoints: {
    getApiRecapPages: {
      providesTags: (result) =>
        result
          ? [
              ...result.items.map(({ id }) => ({ type: 'Page', id })),
              { type: 'Page', id: 'LIST' },
            ]
          : [{ type: 'Page', id: 'LIST' }],
    },
    postApiRecapPages: {
      invalidatesTags: ['Page'],
      transformResponse: (response, meta) => {
        if (meta?.response?.status === 200) {
          toastr.success('Document created successfully');
        } else {
          toastr.error('Document not created');
        }
        return response;
      },
    },
    getApiRecapPagesById: {
      providesTags: (_, __, { id }) => [{ type: 'Page', id }],
    },
    putApiRecapPagesById: {
      invalidatesTags: (result, _, args) => {
        if (args.id !== result.slug!) {
          return [
            {
              type: 'Page',
              id: 'LIST',
            },
          ];
        }
        return [{ type: 'Page', id: args.id }];
      },
      transformResponse: (response, meta) => {
        if (meta?.response?.status === 200) {
          toastr.success('Document updated successfully');
        } else {
          toastr.error('Document not updated');
        }
        return response;
      },
    },
    deleteApiRecapPagesById: {
      invalidatesTags: ['Page'],
      transformResponse: () => {
        toastr.success('Document deleted successfully');
      },
    },
    postApiRecapPagesSync: {
      invalidatesTags: ['Page'],
      transformResponse: (response, meta) => {
        if (meta?.response?.status === 200) {
          toastr.success('Synchronization has been started');
        } else {
          toastr.error('Error occurred while synchronizing');
        }
        return response;
      },
    },
  },
});

export const {
  useGetApiRecapPagesByIdQuery,
  useGetApiRecapPagesQuery,
  useLazyGetApiRecapPagesByIdSubjectablesQuery,
  usePostApiRecapPagesMutation,
  usePostApiRecapPagesSyncMutation,
  usePutApiRecapPagesByIdMutation,
  useDeleteApiRecapPagesByIdMutation,
} = documentationEnhancedApi;
