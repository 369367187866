import { RadioGroupController } from '@/stories/RadioButton/RadioGroup';
import { ResetButton } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/fields/StylingFields';
import { Control } from 'react-hook-form';
import { FieldValues } from 'react-hook-form/dist/types';
import { SectionField } from 'stories/Field/FieldsWrappers';
import * as yup from 'yup';

export const SPARKLINE_TYPE = {
  LINE: 'line',
  COLUMN: 'column',
  AREA: 'area',
  BAR: 'bar',
} as const;

const SPARKLINE_TYPE_OPTIONS = [
  { label: 'Line', value: SPARKLINE_TYPE.LINE },
  { label: 'Column', value: SPARKLINE_TYPE.COLUMN },
  { label: 'Area', value: SPARKLINE_TYPE.AREA },
  // not supported yet due to time series data
  // { label: 'Bar', value: SPARKLINE_TYPE.BAR },
] as const;

export const SPARKLINE_TYPE_SCHEMA = yup
  .string()
  .oneOf(Object.values(SPARKLINE_TYPE))
  .required();

export const DEFAULT_SPARKLINE_TYPE_FIELD_SCHEMA = yup.object().shape({
  sparkline_type: SPARKLINE_TYPE_SCHEMA,
});

export function SparklineTypeField<
  TFieldValues extends FieldValues = FieldValues,
>({
  SparklineSettingsField: control,
  name,
}: {
  name: string;
  SparklineSettingsField: Control<TFieldValues>;
}) {
  return (
    <SectionField
      labelText={'Sparkline Type'}
      button={<ResetButton name={name} />}
      note="Select the sparkline type for the column"
    >
      <RadioGroupController
        control={control}
        name={name}
        options={SPARKLINE_TYPE_OPTIONS}
      />
    </SectionField>
  );
}
