import ReportSettingsScreenLayout from '@/bundles/Settings/shared/ReportSettingsScreenLayout';
import {
  isThresholdOverridable,
  isThresholdSpecified,
} from '@/entities/report/threshold/lib';
import { ReportThreshold } from '@/entities/report/threshold/model';
import { ThresholdGlobalValue } from '@/entities/report/threshold/ui/ThresholdGlobalValue';
import { ImportThresholdWithOverridesButton } from '@/features/report/threshold/export/ui/ImportThresholdWithOverridesButton';
import { ExportThresholdWithOverridesButton } from '@/features/report/threshold/export/ui/ExportThresholdWithOverridesButton';
import { ReportThresholdLegalEntityOverridesTable } from '@/pages/settings/report/thresholds/[thresholdKind]/widgets/ReportThresholdLegalEntityOverridesTable';
import { GrowDiv } from '@/shared/ui/GrowDiv';
import { TitledCard } from '@/stories/TitledCard/TitledCard';
import { CreateReportThresholdPlaceholder } from '@/widgets/report/thresholds/ui/CreateReportThresholdPlaceholder';
import { ReportThresholdsActionsDropdown } from '@/widgets/report/thresholds/ui/ReportThresholdsActionsDropdown';
import NoDataOverlay from '@/bundles/Shared/components/NoDataOverlay';

const Layout = ({
  children,
  title,
}: {
  children: React.ReactNode;
  title: string;
}) => {
  return (
    <ReportSettingsScreenLayout title={title} subTitle="Thresholds">
      <div className="flex grow flex-col gap-6 p-6">{children}</div>
    </ReportSettingsScreenLayout>
  );
};

Layout.CardContainer = ({ children }: { children: React.ReactNode }) => {
  return <div className="flex items-start gap-4">{children}</div>;
};

const SettingsReportThresholdsThreshold = ({
  threshold,
}: {
  threshold: ReportThreshold;
}) => {
  if (!isThresholdSpecified(threshold)) {
    return (
      <Layout title={threshold.title}>
        <CreateReportThresholdPlaceholder threshold={threshold} />
      </Layout>
    );
  }
  return (
    <Layout title={threshold.title}>
      <Layout.CardContainer>
        <TitledCard
          classes={{
            children: 'py-4',
          }}
          className="relative min-w-[300px] self-start justify-self-start"
          title="Threshold"
        >
          <ReportThresholdsActionsDropdown threshold={threshold} />
          <ThresholdGlobalValue
            value={threshold.globalValue}
            valueType={threshold.valueType}
            classes={{
              allParts: 'header5-regular',
              value: 'text-neutral-850',
            }}
          />
        </TitledCard>
        <GrowDiv />
        {threshold.allowedOverrides && (
          <div className="flex items-center gap-4">
            <ExportThresholdWithOverridesButton threshold={threshold} />
            <ImportThresholdWithOverridesButton threshold={threshold} />
          </div>
        )}
      </Layout.CardContainer>
      {!isThresholdOverridable(threshold) && (
        <NoDataOverlay
          title="Legal Entity overrides are not supported for this threshold. "
          subTitle="You can update the global threshold if needed."
        />
      )}
      {isThresholdOverridable(threshold) && (
        <ReportThresholdLegalEntityOverridesTable threshold={threshold} />
      )}
    </Layout>
  );
};

export default SettingsReportThresholdsThreshold;
