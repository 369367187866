import React from 'react';
import Icon from 'stories/Icon/Icon';
import PseudoLink from 'stories/PseudoLink/PseudoLink';

interface Props {
  onClick?: VoidFunction;
  withoutIcon?: boolean;
}

export function DashboardEmptyPermissions({ onClick, withoutIcon }: Props) {
  return (
    <div onClick={onClick} className="flex items-center gap-1">
      {!withoutIcon && <Icon iconName="users" />}
      <PseudoLink className="secondary-semibold text-neutral-500">
        NOBODY
      </PseudoLink>
    </div>
  );
}
