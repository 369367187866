import { useModal } from '@/shared/lib/hooks/useModal';
import { ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import { useAppNavigate } from '@/shared/lib/navigation/useAppNavigate';
import { Link, type RouteComponentProps } from '@reach/router';
import http from 'lib/http';
import pluralize from 'pluralize';
import { FC, useEffect, useState } from 'react';
import { AnimationLoader } from 'stories/AnimationLoader/AnimationLoader';
import { Button } from 'stories/Button/Button';
import { removeInvitationTemplate } from '@/bundles/Settings/actions/invitationTemplates';
import SectionToCreateEmailCard from '@/bundles/Settings/components/EmailSettings/EmailCampaign/SectionToCreateEmailCard/SectionToCreateEmailCard';
import EmailTemplateCard from '@/bundles/Settings/components/EmailSettings/EmailTemplateCard';

const InvitationTemplatesTab: FC<RouteComponentProps> = () => {
  const [invitationTemplates, setInvitationTemplates] = useState([]);
  const { confirm } = useModal();
  const [loading, setLoading] = useState(true);
  const fetchInvitationTemplates = () =>
    http.get('/invitation_templates').then((res) => res.json());

  const navigate = useAppNavigate();

  const handleNavigate = (id: number) => {
    navigate(
      ROUTES_ROOT.settings.emails.templates.invitations.invitation.edit
        .fullPath,
      {
        pathParams: {
          invitationTemplateId: String(id),
        },
      },
    );
  };

  useEffect(() => {
    fetchInvitationTemplates()
      .then((invitationTemplatesJson) => {
        setInvitationTemplates(invitationTemplatesJson);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleInvitationTemplateDelete = async (id) => {
    const bool = await confirm({
      title: 'Delete Invitation Template',
      subtitle: 'Are you sure you want to delete this template?',
    });
    if (!bool) return;
    removeInvitationTemplate({ id }).then((templateJson) => {
      if (templateJson.errors === undefined) {
        setInvitationTemplates((currentTemplates) =>
          currentTemplates.filter((t) => t.id !== id),
        );
      }
    });
  };

  const handleCardToggle = async ({ id, isActive }) => {
    const payload = { template_attributes: { active: !isActive } };
    const res = await http.put(`/invitation_templates/${id}`, payload);
    const responseData = await res.json();
    return responseData;
  };

  const defaultTemplate = invitationTemplates.find((it) => it.default);
  const customTemplates = invitationTemplates.filter(
    (it) => it.default === false,
  );

  if (loading) return <AnimationLoader className="static min-h-[360px]" />;

  return (
    <div>
      <div className="mb-l mt-m flex items-center justify-between">
        <h5 className="header6-regular">
          {`${invitationTemplates.length} ${pluralize(
            'Template',
            invitationTemplates.length,
          )}`}
        </h5>
        <div className="flex items-center justify-between">
          <Link to="/settings/emails/templates/invitations/new">
            <Button size="m" variant="primary">
              New Template
            </Button>
          </Link>
        </div>
      </div>
      <div className="row">
        {defaultTemplate && (
          <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3 pb-30">
            <EmailTemplateCard
              isDefault
              id={defaultTemplate.id}
              name={defaultTemplate.name}
              onNavigate={() => handleNavigate(defaultTemplate.id)}
            />
          </div>
        )}
        {customTemplates.map((template) => (
          <div
            key={template.id}
            className="col-xs-12 col-sm-6 col-md-6 col-lg-3 pb-30"
          >
            <EmailTemplateCard
              isDefault={template.default}
              isActiveDefault={template.active}
              id={template.id}
              name={template.name}
              onNavigate={() => handleNavigate(template.id)}
              onDelete={() => handleInvitationTemplateDelete(template.id)}
              onToggle={handleCardToggle}
              permitted={{
                directRoles: template.selectedRoles ?? [],
                directInvestmentEntities: [],
                directTags: [],
                directUsers: [],
              }}
            />
          </div>
        ))}
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3 pb-30">
          <SectionToCreateEmailCard
            title="Custom Template"
            text="Based on the basic template you can create your own template for a unique set of roles"
            buttonText="New Custom Template"
            clickBtnHandler={() =>
              navigate(
                ROUTES_ROOT.settings.emails.templates.invitations.new.fullPath,
              )
            }
          />
        </div>
      </div>
    </div>
  );
};

export default InvitationTemplatesTab;
