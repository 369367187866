import { useMemo, useState } from 'react';
import { SearchInput } from '@/stories/FormControls/Inputs/SearchInput/SearchInput';
import SplittedCheckList from 'stories/Checkbox/SplittedCheckList';
import { ERPSystem, getNameERPSystem } from '@/entities/erpsystem';
import { ListOption } from '@/stories/Checkbox/CheckList';

export type PropertyManagementCompany = {
  id: number;
  name: string;
};

interface Props {
  erpOptions: ERPSystem[];
  pmcOptions: PropertyManagementCompany[];
  selectedPmcValues: PropertyManagementCompany[] | undefined;
  selectedErpValues: ERPSystem[] | undefined;
  onPmcValuesChange: (
    pmcValues: PropertyManagementCompany[] | undefined,
  ) => void;
  onErpValuesChange: (erpValues: ERPSystem[] | undefined) => void;
  withoutPopover?: boolean;
  maxHeight?: string | number;
}

const DEFAULT_MAX_HEIGHT = 350;

// TODO: FE-3580 [EPIC] replace with proper type, CoreLegalEntityDto is used for now
export function PmcErpSelect({
  pmcOptions,
  erpOptions,
  selectedPmcValues = [],
  selectedErpValues = [],
  onPmcValuesChange,
  onErpValuesChange,
  maxHeight = DEFAULT_MAX_HEIGHT,
}: Props) {
  const [filterText, setFilterText] = useState('');

  const selectedPmcValuesOptions = useMemo(() => {
    return (
      selectedPmcValues?.map((pmc) => {
        return {
          value: pmc.id,
          label: pmc.name,
        };
      }) ?? []
    );
  }, [selectedPmcValues]);

  const selectedErpValuesOptions = useMemo(() => {
    return (
      selectedErpValues?.map((erp) => {
        return {
          value: erp,
          label: getNameERPSystem(erp),
        };
      }) ?? []
    );
  }, [selectedErpValues]);

  const pmcValuesOptions = useMemo(() => {
    return pmcOptions.map((pmc) => {
      return {
        value: pmc.id,
        label: pmc.name,
      };
    });
  }, [pmcOptions]);

  const erpValuesOptions = useMemo(() => {
    return erpOptions.map((erp) => {
      return {
        value: erp,
        label: getNameERPSystem(erp),
      };
    });
  }, [erpOptions]);

  const filteredPmc = useMemo(() => {
    return pmcValuesOptions.filter((o) =>
      filterText
        ? o.label.toLowerCase().includes(filterText.toLowerCase())
        : true,
    );
  }, [pmcValuesOptions, filterText]);

  const filteredErps = useMemo(() => {
    return erpValuesOptions.filter((o) =>
      filterText
        ? o.label.toLowerCase().includes(filterText.toLowerCase())
        : true,
    );
  }, [erpValuesOptions, filterText]);

  const handlePmcValuesChange = (items: ListOption<number>[]) => {
    if (items.length === 0) {
      onPmcValuesChange(undefined);
      return;
    }

    const selected = items.map((item) => {
      return {
        id: item.value as number,
        name: item.label as string,
      };
    });

    onPmcValuesChange(selected);
  };

  const handleErpValuesChange = (items: ListOption[]) => {
    if (items.length === 0) {
      onErpValuesChange(undefined);
      return;
    }

    const selected = items.map((item) => {
      return item.value as ERPSystem;
    });

    onErpValuesChange(selected);
  };

  const lists = (
    <div
      className="flex w-[31.25rem] gap-2 overflow-auto mt-s"
      style={{ maxHeight: `${maxHeight}px` }}
    >
      <div className="w-1/2 border-r border-light-20 pl-s">
        <p className="sticky top-0 z-10 mb-2 bg-white font-semibold">PMC</p>
        <SplittedCheckList
          value={selectedPmcValuesOptions}
          onChange={(items) => handlePmcValuesChange(items)}
          items={filteredPmc}
        />
      </div>
      <div className="w-1/2">
        <p className="sticky top-0 z-10 mb-2 bg-white font-semibold">ERP</p>
        <SplittedCheckList
          value={selectedErpValuesOptions}
          onChange={(items) => handleErpValuesChange(items)}
          items={filteredErps}
        />
      </div>
    </div>
  );

  const input = (
    <SearchInput
      onChange={(e) => setFilterText(e.target.value)}
      placeholder="PMC & ERP"
      value={filterText}
      resetValue={() => setFilterText('')}
      size="s"
    />
  );

  return (
    <div className="flex flex-col overflow-auto gap-2">
      {input}
      {lists}
    </div>
  );
}
