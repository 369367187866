import React from 'react';
import { hideNotification } from 'bundles/Notifications/actions';
import { formatUnixDateFromNow } from '@/shared/lib/formatting/dates';
import { SreAvatar } from 'stories/SreAvatar/SreAvatar';
import notificationsList from 'bundles/Notifications/shared/notificationsMapping';
import { INotification } from 'types/Notification';
import { useAppDispatch } from '@/shared/lib/hooks/redux';
import { cn } from '@/shared/lib/css/cn';
import { CardWrapper } from 'bundles/Shared/components/Wrappers/CardWrapper';

type Props = Pick<
  INotification,
  'viewed' | 'createdAt' | 'text' | 'id' | 'notificationType'
>;

function NotificationCard({
  id,
  notificationType,
  viewed,
  createdAt,
  text,
}: Props) {
  const dispatch = useAppDispatch();
  const notificationMeta = notificationsList[notificationType];

  return (
    <CardWrapper className="shadow">
      <div
        className="notifications__card px-5 py-5"
        onClick={() => dispatch(hideNotification({ id }))}
      >
        <div className="flex">
          {notificationType && (
            <SreAvatar
              className={cn('mr-5', {
                [notificationMeta?.containerClassName]:
                  notificationMeta?.containerClassName,
              })}
              width="2.625rem"
              height="2.625rem"
              iconName={notificationMeta?.innerClassName}
            />
          )}
          <div>
            {text}
            <p className="text-[#9e9e9e]">{formatUnixDateFromNow(createdAt)}</p>
          </div>
          <span
            className={cn(
              'notification ml-auto',
              viewed ? 'notification_viewed' : 'notification_not-viewed',
            )}
          />
        </div>
      </div>
    </CardWrapper>
  );
}

export default NotificationCard;
