import React, { useState } from 'react';
import { cn } from '@/shared/lib/css/cn';
import { CardWrapper } from 'bundles/Shared/components/Wrappers/CardWrapper';
import { Button } from 'stories/Button/Button';
import { IconButton } from 'stories/IconButton/IconButton';
import { Modal } from 'stories/Modals/Modal/Modal';
import { connect } from 'react-redux';
import { useForm } from 'react-hook-form';
import { updateDealPathCategory } from '@/bundles/Settings/actions/dealPathSettings';

const DEFAULT_ODDS_RANGE = [1, 1.3333, 2, 20];

// place in separate file after reviews
function NavItem({ active, children, className, ...props }) {
  return (
    <button
      type="button"
      className={cn(
        'cursor-pointer rounded-[8px] !border px-4 py-2  transition hover:border-light-10',
        {
          'border-transparent bg-transparent': !active,
          '!border-blue bg-blue text-white': active,
        },
        className,
      )}
      {...props}
    >
      {children}
    </button>
  );
}

const DealsProbability = (props) => {
  const { propertyTypes, categories } = props;
  const [selectedCategory, setSelectedCategory] = useState();
  const [selectedPropertyType, setSelectedPropertyType] = useState(
    propertyTypes[0],
  );

  const oddValue = selectedCategory?.odds[selectedPropertyType];
  const defaultFormKey = DEFAULT_ODDS_RANGE.includes(oddValue)
    ? 'value'
    : 'customValue';

  const { handleSubmit, watch, setValue } = useForm({
    defaultValues: { [defaultFormKey]: oddValue },
  });

  const { customValue, value } = watch();

  const submit = async () => {
    await props.update(selectedCategory.id, {
      odds: {
        ...selectedCategory.odds,
        [selectedPropertyType]: customValue || value,
      },
    });
    setSelectedCategory(null);
  };

  const renderProbability = (category) => {
    if (!category.odds || !category.odds[selectedPropertyType]) return '';

    return (
      <span>
        1 of {category.odds[selectedPropertyType].toString().substring(0, 4)}
        {' • '}
        {((1 / category.odds[selectedPropertyType]) * 100).toFixed(2)}%
      </span>
    );
  };

  return (
    <div>
      <CardWrapper className="bg-light">
        <div className="px-5 py-3">
          <h3>Probability of Deals</h3>
        </div>
        <div className="p-3">
          <ul className="flex gap-4">
            {propertyTypes.map((type) => (
              <li key={type}>
                <NavItem
                  active={type === selectedPropertyType}
                  onClick={() => setSelectedPropertyType(type)}
                >
                  {type}
                </NavItem>
              </li>
            ))}
          </ul>
        </div>

        <div className="px-5 py-5">
          <ul className="dealpath-probability-container">
            {categories
              .filter((c) => c.active)
              .map((category, i) => (
                <li
                  key={category.id}
                  className="sre-dealpath-probability-card flex flex-col"
                >
                  <div className="flex justify-between">
                    <div className="font-14">Stage {i + 1}</div>
                    <IconButton
                      iconName="edit"
                      onClick={() => setSelectedCategory(category)}
                    />
                  </div>
                  <strong className="font-16 dark-60 weight-500 mt-auto">
                    {category.name}
                  </strong>
                  <p className="font-12 light-90">
                    {renderProbability(category)}
                  </p>
                </li>
              ))}
          </ul>
        </div>
      </CardWrapper>
      {selectedCategory && (
        <Modal
          header={selectedPropertyType}
          toggle={() => setSelectedCategory(null)}
          actions={
            <Button
              form="dealsProbabilityForm"
              variant="success"
              fluid
              type="submit"
            >
              Submit
            </Button>
          }
          classes={{
            header: 'bg-light',
            body: 'bg-light',
            footer: 'bg-light',
          }}
        >
          <form id="dealsProbabilityForm" onSubmit={handleSubmit(submit)}>
            <h4 className="mb-s">{selectedCategory.name}</h4>
            <ul>
              {DEFAULT_ODDS_RANGE.map((i) => (
                <li key={i}>
                  <label className="flex items-center gap-s">
                    <input
                      type="radio"
                      value={i}
                      checked={!customValue && i === value}
                      onChange={() => {
                        setValue('customValue', '');
                        setValue('value', i);
                      }}
                    />
                    <span>
                      1 of {i} <strong>{((1 / i) * 100).toFixed(2)}%</strong>
                    </span>
                  </label>
                </li>
              ))}
              <li className="mt-s">
                1 of
                <input
                  onChange={(e) => setValue('customValue', +e.target.value)}
                />
                {customValue && (
                  <strong className="ml-4">
                    {((1 / customValue) * 100).toFixed(2)}%
                  </strong>
                )}
              </li>
            </ul>
          </form>
        </Modal>
      )}
    </div>
  );
};

const mapStateToProps = ({ dealPathSettings }) => ({
  categories: dealPathSettings.categories,
  propertyTypes: dealPathSettings.activePropertyTypes,
});

const mapActionCreators = {
  update: updateDealPathCategory,
};
export default connect(mapStateToProps, mapActionCreators)(DealsProbability);
