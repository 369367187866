import React from 'react';
import { Button } from '@/stories/Button/Button';
import { Field } from '@/stories/Field/Field';
import { Input } from '@/stories/FormControls/Inputs/Input/Input';
import { Modal } from '@/stories/Modals/Modal/Modal';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  CREATE_BOARD_SCHEMA,
  CreateBoardSchema,
} from 'bundles/Shared/features/dashboard/boardCRUD/config';
import { FieldsContainer } from 'stories/Field/Field';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import type { EagleEyeBoard } from 'bundles/Shared/entities/dashboard/model/types/types';
import {
  useCreateBoard,
  useUpdateBoard,
} from 'bundles/Shared/features/dashboard/boardCRUD/lib';

type Props = DialogProps<EagleEyeBoard> & {
  board: EagleEyeBoard | null;
} & Pick<React.ComponentProps<typeof Modal>, 'zIndex'>;

export function BoardModal({ board, zIndex, onClose, ...props }: Props) {
  const {
    register,
    handleSubmit,
    formState: { isValid, isDirty, isSubmitting },
  } = useForm<CreateBoardSchema>({
    resolver: yupResolver(CREATE_BOARD_SCHEMA),
    defaultValues: {
      name: board?.name ?? '',
      id: board?.id,
    },
  });

  const [addBoard] = useCreateBoard();
  const [updateBoard] = useUpdateBoard();

  const handleSave = handleSubmit(async (data) => {
    if (board) {
      const res = await updateBoard({
        data,
      });
      if (res) {
        props?.onSubmit?.(res);
      }
    } else {
      const res = await addBoard({
        data,
      });
      if (res) {
        props?.onSubmit?.(res);
      }
    }
  });

  return (
    <Modal
      header={board ? 'Edit Board' : 'Create Board'}
      toggle={onClose}
      zIndex={zIndex}
      actions={
        <>
          <Button onClick={onClose} variant="secondary">
            Cancel
          </Button>
          <Button
            disabled={!isValid || !isDirty || isSubmitting}
            onClick={handleSave}
            variant="success"
          >
            {board ? 'Save Updates' : 'Create'}
          </Button>
        </>
      }
    >
      <FieldsContainer>
        <Field labelText="Board Name" required>
          <Input {...register('name')} />
        </Field>
      </FieldsContainer>
    </Modal>
  );
}
