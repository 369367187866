import React from 'react';
import BadgeWithStatus from '@/bundles/Shared/components/BadgeWithStatus';
import { cn } from '@/shared/lib/css/cn';
interface PasswordError {
  valid: boolean;
  text: string;
}

interface PasswordErrors {
  password?: {
    types?: unknown;
  };
}

export const PasswordValidationBadges = ({
  allRequirements,
  errors,
  value,
  className,
}: {
  allRequirements: Record<string, PasswordError>;
  errors: PasswordErrors;
  value: string;
  className?: string;
}) => {
  return Object.keys(allRequirements).map((errorKey) => (
    <BadgeWithStatus
      key={errorKey}
      className={cn('mr-2 mt-2', className)}
      isSuccessful={
        allRequirements[errorKey].valid ||
        (!errors?.password?.types && value !== undefined && value !== '')
      }
    >
      {allRequirements[errorKey].text}
    </BadgeWithStatus>
  ));
};
