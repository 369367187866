import { EMAIL_RRULE_TIMEZONE_OPTIONS } from '@/entities/emails/customTemplates/config';
import { useRRuleOptionsContext } from '@/entities/emails/customTemplates/lib/context';
import { Button } from '@/stories/Button/Button';
import { Dropdown } from '@/stories/Dropdown/Dropdown';
import { DropdownItem } from '@/stories/Dropdown/DropdownItem/DropdownItem';
import type { ComponentProps } from 'react';

export const TimezoneDropdown = ({
  disabled,
  onClick,
}: {
  onClick: (value: string) => void;
} & Pick<ComponentProps<typeof Dropdown>, 'disabled'>) => {
  const rruleOptions = useRRuleOptionsContext();
  return (
    <Dropdown
      appendTo={document.body}
      disabled={disabled}
      items={
        <>
          {EMAIL_RRULE_TIMEZONE_OPTIONS.map((o) => (
            <DropdownItem
              active={o.value === rruleOptions.tzid}
              key={o.value}
              onClick={() => onClick(o.value)}
            >
              {o.label}
            </DropdownItem>
          ))}
        </>
      }
    >
      <Button
        disabled={disabled}
        iconName="bottom"
        size="m"
        variant="secondary"
        iconPosition="right"
      >
        {EMAIL_RRULE_TIMEZONE_OPTIONS.find((o) => o.value === rruleOptions.tzid)
          ?.label ?? 'Select Timezone'}
      </Button>
    </Dropdown>
  );
};
