import {
  VariableValueType,
  VariableValueTypeValue,
} from '@/entities/valueType/model';
import { getFormatterByDisplayOptions } from '@/shared/lib/formatting/table';

export const ThresholdGlobalValue = <T extends VariableValueType>({
  value,
  valueType,
}: {
  value: VariableValueTypeValue<T> | null;
  valueType: T;
}) => {
  const Component = getFormatterByDisplayOptions({
    type: valueType,
    precision: 0,
  });
  return <Component value={value} />;
};
