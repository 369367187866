import { LinkProps, useLocation, useNavigate } from '@reach/router';
import { selectReportTableConfig } from '@/bundles/REport/reducers/financialsSlice';
import { useAppSelector } from '@/shared/lib/hooks/redux';
import { EmptyObject } from 'type-fest';
import {
  OtherTabs,
  TableTab,
} from '@/bundles/REport/components/financials/TableTab';
import {
  useCurrentTab,
  useDynamicTabs,
} from '@/bundles/REport/components/financials/hooks/useDynamicTabs';

export function FinancialsTabs() {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as LinkProps<EmptyObject>['state'];
  const reportTableConfig = useAppSelector(selectReportTableConfig);
  const dashboardExpanded = useAppSelector(
    (st) => st.financials.dashboardExpanded,
  );

  const { allTabs, shownTabs, restTabs } = useDynamicTabs({
    dashboardExpanded,
  });

  const currentTab = useCurrentTab({
    tabs: allTabs,
    reportTableConfig,
  });

  return (
    <div className="flex">
      {shownTabs.map((tab) => (
        <TableTab
          key={tab.slug}
          active={currentTab?.slug === tab.slug}
          onClick={() => {
            navigate(tab.path, {
              state,
            });
          }}
        >
          {tab.label}
        </TableTab>
      ))}
      <OtherTabs
        otherTabActive={Boolean(
          restTabs.find((t) => t.slug === currentTab?.slug),
        )}
        tabItems={restTabs.map((t) => ({
          path: t.path,
          label: t.label,
        }))}
      />
    </div>
  );
}
