import {
  PageParamsProvider,
  PageParamsSearch,
} from '@/bundles/Shared/components/pageParams';
import { ShownInfoLine } from '@/bundles/Shared/components/Table/pagination/TablePagination';
import Table from '@/bundles/Shared/components/Table/Table';
import type { IColumn } from '@/bundles/Shared/components/Table/types';
import { reportingEnhancedApi } from '@/entities/reporting/api/reportingEnhancedApi';
import type { GetApiReportBuilderReportGroupsApiArg } from '@/entities/reporting/api/reportingGeneratedApi';
import {
  REPORT_GROUP_TEMPLATE_TYPE_TABS,
  REPORT_GROUP_TYPES_TABS,
} from '@/entities/reporting/config';
import { ReportingEntityKindContext } from '@/entities/reporting/context/entityKind';
import { ReportingEntityStageContext } from '@/entities/reporting/context/entityStage';
import { AuthorColumnFormatter } from '@/entities/reporting/ui/AuthorColumnFormatter';
import { ReportBuilderTemplateIconButton } from '@/entities/reporting/ui/ReportBuilderTemplateIconButton';
import { useDeleteReportGroup } from '@/features/report/report/deleteReportGroup/lib';
import { useCreateEagleEyeReport } from '@/features/report/report/eagleEye/createReport/lib';
import { useReportBuilderNavigateToEagleEyeReportGroup } from '@/features/report/report/eagleEye/navigateToReportGroup/lib';
import { useReportBuilderNavigateToEagleEyeReport } from '@/features/report/report/navigateToReport/lib';
import { useUpdateReportGroup } from '@/features/report/report/updateReportGroup/lib';
import {
  currentUserAllowedToConfigureReportingPage,
  currentUserAllowedToManageReportingPage,
} from '@/pages/reporting/permissions';
import { generateUrl, ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import { usePageParamsTableSorting } from '@/shared/lib/hooks/usePageParamsTableSorting';
import { GrowDiv } from '@/shared/ui/GrowDiv';
import { AnimationLoader } from '@/stories/AnimationLoader/AnimationLoader';
import { Button } from '@/stories/Button/Button';
import { IconButton } from '@/stories/IconButton/IconButton';
import { ThinTabGroup } from '@/stories/Tabs/ThinTabGroup/ThinTabGroup';
import { useTabs } from '@/stories/Tabs/useTabs';
import type { IQueryParams } from '@/types/Pagination';
import type { ISortParamsField } from '@/types/Sort';
import { useNavigate, type RouteComponentProps } from '@reach/router';
import { useMemo, useState, type FC } from 'react';

type SortField = NonNullable<
  NonNullable<GetApiReportBuilderReportGroupsApiArg['sort']>['field']
>;

const Component = () => {
  const navigate = useNavigate();
  const reportGroupTypesTabs = useTabs(REPORT_GROUP_TYPES_TABS, 1);
  const reportGroupTemplateTypeTabs = useTabs(
    REPORT_GROUP_TEMPLATE_TYPE_TABS,
    0,
  );

  const [pageParams, setPageParams] = useState<
    IQueryParams & ISortParamsField<SortField>
  >({
    query: '',
    sort: {
      field: 'created_at',
      order: 'desc',
    },
  });

  const [handleDeleteReportGroup, deleteOptions] = useDeleteReportGroup();

  const { settings, setSettings } = usePageParamsTableSorting(
    pageParams,
    setPageParams,
  );

  const handleNavigateToReportGroup =
    useReportBuilderNavigateToEagleEyeReportGroup();

  const navigateToReport = useReportBuilderNavigateToEagleEyeReport();

  const [handleCreateReport, createOptions] =
    useCreateEagleEyeReport(navigateToReport);

  const [handleUpdate, updateOptions] = useUpdateReportGroup();

  const {
    data,
    isFetching: isDataFetching,
    isLoading,
  } = reportingEnhancedApi.useGetApiReportBuilderReportGroupsQuery(pageParams);

  const columnDefs = useMemo<
    IColumn<NonNullable<NonNullable<typeof data>['items']>[number]>[]
  >(
    () => [
      {
        sortable: true,
        dataField: 'name',
        classes: 'max-w-[500px]',
        text: 'Report Group',
        formatter: ({ row }) => (
          <div className="flex flex-col gap-2">
            <div className="flex items-center justify-between">
              <p className="inline-semibold text-ellipsis text-neutral-850">
                {row.name}
              </p>

              <Button
                className="invisible w-max group-hover:visible"
                size="xs"
                onClick={() => handleNavigateToReportGroup(row.id)}
              >
                Open
              </Button>
            </div>
            {row.description && (
              <p className="secondary-regular text-neutral-850">
                {row.description}
              </p>
            )}
          </div>
        ),
      },
      {
        sortable: true,
        hidden: !currentUserAllowedToManageReportingPage(),
        dataField: 'template_name',
        formatter: ({ row }) => (
          <ReportBuilderTemplateIconButton template={row.template} />
        ),
        text: 'Template',
      },
      {
        sortable: true,
        dataField: 'reports_count',
        classes: 'w-[200px]',
        formatter: ({ row }) => (
          <div className="flex flex-col gap-2">
            {/* to component */}
            <div className="flex items-center gap-1">
              <p className="inline-regular text-neutral-850">Generated</p>
              <span className="flex-1 self-end pb-1">
                <span className="border-b-2 border-dotted border-neutral-250" />
              </span>
              <p className="inline-regular text-neutral-850">
                {row.reports?.generatedCount}
              </p>
            </div>

            {/* to component */}
            {currentUserAllowedToManageReportingPage() && (
              <div className="flex items-center gap-1 ">
                <p className="inline-regular text-neutral-850">Draft</p>
                <span className="flex-1 self-end pb-1">
                  <span className="border-b-2 border-dotted border-neutral-250" />
                </span>
                <p className="inline-regular text-neutral-850">
                  {row.reports?.draftCount}
                </p>
              </div>
            )}
          </div>
        ),
        text: 'Reports',
      },
      {
        sortable: true,
        classes: 'w-[200px]',
        dataField: 'created_at',
        text: 'Created',
        formatter: ({ row }) => (
          <AuthorColumnFormatter
            name={row.createdBy.name}
            unixTime={row.createdAt}
          />
        ),
      },
      {
        sortable: true,
        classes: 'w-[200px]',
        dataField: 'updated_at',
        text: 'Modified',
        formatter: ({ row }) => (
          <AuthorColumnFormatter
            name={row.updatedBy?.name}
            unixTime={row.updatedAt}
          />
        ),
      },
      {
        dataField: 'actions',
        classes: 'w-[16px]',
        hidden: !currentUserAllowedToConfigureReportingPage(),
        formatter: ({ row }) => (
          <div className="flex items-center gap-2">
            <IconButton
              onClick={() => handleCreateReport(row)}
              iconName="addSmall"
            />
            <IconButton iconName="edit" onClick={() => handleUpdate(row)} />
            <IconButton
              onClick={() => handleDeleteReportGroup(row.id)}
              iconName="trash"
            />
          </div>
        ),
      },
    ],
    [],
  );

  const isFetching =
    isDataFetching ||
    deleteOptions.isLoading ||
    updateOptions.isLoading ||
    createOptions.isLoading;

  if (isLoading) return <AnimationLoader className="relative min-h-[80vh]" />;

  return (
    <PageParamsProvider pageParams={pageParams} setPageParams={setPageParams}>
      <div className="flex flex-col px-6">
        <div className="flex items-center justify-between py-4">
          <h1 className="header1-bold text-neutral-850">REporting Module</h1>
          <IconButton
            iconName="historical"
            size="l"
            tooltipProps={{
              mainText: 'Coming soon',
              arrowPosition: 'end',
              placement: 'bottom-end',
            }}
            disabled
          />
        </div>

        <div className="flex flex-col gap-4 py-6">
          <div className="flex items-center gap-4">
            <ThinTabGroup {...reportGroupTypesTabs.thinTabGroupProps} />
            <ThinTabGroup {...reportGroupTemplateTypeTabs.thinTabGroupProps} />
          </div>
          <div className="flex items-center gap-2">
            <ShownInfoLine totalSize={data?.meta?.totalSize ?? 0} />
            <GrowDiv />
            <PageParamsSearch
              suggestions={[
                'Report Group Name',
                'Template Name',
                'Report Group Description',
              ]}
            />
            {currentUserAllowedToConfigureReportingPage() && (
              <Button
                variant="primary"
                size="s"
                loading={createOptions.isLoading}
                onClick={() =>
                  navigate(generateUrl(ROUTES_ROOT.reporting.new.fullPath))
                }
              >
                New Report Group
              </Button>
            )}
          </div>
          <Table
            settings={settings}
            setSettings={setSettings}
            loading={isFetching}
            items={data?.items ?? []}
            columns={columnDefs}
          />
          <div className="flex" />
        </div>
      </div>
    </PageParamsProvider>
  );
};

export const ReportingPage: FC<RouteComponentProps> = () => (
  <ReportingEntityKindContext.Provider value="eagle_eye">
    <ReportingEntityStageContext.Provider value="report">
      <Component />
    </ReportingEntityStageContext.Provider>
  </ReportingEntityKindContext.Provider>
);
