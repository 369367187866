import React, { ComponentProps, FC } from 'react';
import { IconButton } from '@/stories/IconButton/IconButton';
import { Button } from '@/stories/Button/Button';
import { cn } from '@/shared/lib/css/cn';
import styles from '@/stories/ActionCard/ActionCard.module.scss';
import { IconsId } from 'types/sre-icons';

interface IActionCardProps {
  title: string;
  description: string;
  icon?: IconsId;
  variant?: ComponentProps<typeof Button>['variant'];
  onClick: () => void;
}

export const ActionCard: FC<IActionCardProps> = ({
  title,
  description,
  icon,
  variant,
  onClick,
}) => (
  <div onClick={onClick} className={styles['sre-action-card']}>
    <IconButton
      size="l"
      iconName={icon ?? 'percent'}
      variant={variant ?? 'primary'}
      className={styles['sre-action-card__icon']}
      offHoverStyles
    />
    <div>
      <div className={cn('inline-semibold', styles['sre-action-card__title'])}>
        {title}
      </div>
      <div
        className={cn(
          'secondary-regular',
          styles['sre-action-card__description'],
        )}
      >
        {description}
      </div>
    </div>
  </div>
);
