import React from 'react';
import { Router } from '@reach/router';
import AuthContainer from 'bundles/Registration/AuthContainer';
import Login from '@/bundles/Registration/Login';
import Registration from '@/bundles/Registration/Registration';
import ForgotPassword from '@/bundles/Registration/ForgotPassword';
import ResetPassword from '@/bundles/Registration/ResetPassword';
import { NotFound } from '@/bundles/Errors';
import { ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';

const UsersPage = () => (
  <AuthContainer>
    <Router>
      <NotFound default />
      <Login path="/users/sign_in" default />
      <Registration path="/users/invitation/accept" />
      <ForgotPassword path="/users/password/new" />
      <ResetPassword path={ROUTES_ROOT.users.password.edit.fullPath} />
    </Router>
  </AuthContainer>
);

export default UsersPage;
