import { useDeleteApiCoreAssetsByAssetIdReportBuilderReportsAndIdMutation } from '@/entities/report/reportBuilder';
import { reportingEnhancedApi } from '@/entities/reporting/api/reportingEnhancedApi';
import { useReportingEntityKindContext } from '@/entities/reporting/context/entityKind';
import { useModal, type TConfirmFuncProps } from '@/shared/lib/hooks/useModal';
import { IAsset } from '@/types/Asset';
import { useMemo } from 'react';

export const DELETE_REPORT_CONFIRM_PROPS = {
  title: 'Are you sure you want to delete this report?',
  subtitle:
    'Deleting the report will remove the file from the reports and documents section of the portal.',
} as const satisfies TConfirmFuncProps;

export const useDeleteReport = (onSuccess?: () => void) => {
  const reportKind = useReportingEntityKindContext();

  const deleteObjectLevelMutation =
    useDeleteApiCoreAssetsByAssetIdReportBuilderReportsAndIdMutation();
  const deleteEagleEyeMutation =
    reportingEnhancedApi.useDeleteApiReportBuilderEagleEyeReportsByIdMutation();

  const [deleteReport, options] = useMemo(() => {
    switch (reportKind) {
      case 'eagle_eye': {
        return deleteEagleEyeMutation;
      }
      case 'object_level': {
        return deleteObjectLevelMutation;
      }
    }
  }, [reportKind]);

  const { confirm } = useModal();

  const handleDelete = async (
    reportId: string,
    assetId?: IAsset['id'] | null,
  ) => {
    const answer = await confirm(DELETE_REPORT_CONFIRM_PROPS);

    if (!answer) return;

    if (reportKind === 'object_level' && assetId == null) return;

    const response = await deleteReport({
      id: reportId,
      assetId,
    });

    if ((response != null && 'error' in response) || response == null) {
      console.error(response.error);
      return;
    }

    return onSuccess?.();
  };

  return [handleDelete, options] as const;
};
