import React from 'react';
import { Icon } from 'stories/Icon/Icon';
import { cn } from '@/shared/lib/css/cn';

interface Props extends React.AllHTMLAttributes<HTMLDivElement> {
  value: number;
}

export const TrendLabel = ({ value, children, className, ...props }: Props) => {
  const positiveTrend = value > 0;

  return (
    <div className={cn('flex items-center gap-1', className)} {...props}>
      <div
        className={cn(
          'flex items-center gap-1 text-danger-055',
          positiveTrend && 'text-success-055',
        )}
      >
        <Icon iconName={positiveTrend ? 'profitAlt' : 'lossAlt'} />
        <div
          className={cn(
            'font-semibold text-danger-070',
            positiveTrend && 'text-success-070',
          )}
        >
          {value?.toFixed(2)}%
        </div>
      </div>
      <div>{children}</div>
    </div>
  );
};
