import { FinancialTableSinglePeriodWidgetConfigDto } from '@/shared/api/dashboardsSettingsGeneratedApi';
import { SUPPORTED_FINANCIAL_TABLE_SINGLE_PERIOD_PERIOD_TYPES } from '@/bundles/Shared/widgets/dashboard/widgets/financialTableSingePeriod/config/options';

export const DEFAULT_FINANCIAL_TABLE_SINGLE_PERIOD_CONFIG = {
  columns: [],
  rows: [],
  default_options: {
    period_type: 't3',
    date: {
      period_shift: {},
    },
  },
  viz_config: {
    column_groups: [],
    rows: [],
    columns: [],
  },
  enable_drill_down: true,
  period_types: SUPPORTED_FINANCIAL_TABLE_SINGLE_PERIOD_PERIOD_TYPES,
} as const satisfies FinancialTableSinglePeriodWidgetConfigDto;
