import {
  useDeleteApiSettingsReportThresholdsByThresholdKindLegalEntityOverridesAndIdMutation,
  useGetApiSettingsReportThresholdsByThresholdKindLegalEntityOverridesQuery,
  usePostApiSettingsReportThresholdsByThresholdKindLegalEntityOverridesMutation,
  usePutApiSettingsReportThresholdsByThresholdKindLegalEntityOverridesAndIdMutation,
} from '@/shared/api/settingsReportThresholdsEnhancedApi';
import {
  GetApiSettingsReportThresholdsByThresholdKindLegalEntityOverridesApiArg,
  GetApiSettingsReportThresholdsByThresholdKindLegalEntityOverridesApiResponse,
} from '@/shared/api/settingsReportThresholdsGeneratedApi';
import { useGetApiSettingsCoreLegalEntitiesQuery } from '@/entities/core/legalEntity/api/settingsCoreLegalEntitiesEnhancedApi';
import {
  ReportThreshold,
  ReportThresholdLegalEntityOverride,
} from '@/entities/report/threshold/model';
import { VariableValueTypePossibleValues } from '@/entities/valueType/model';

export const useAddLegalEntityOverrideForThreshold = (
  threshold: ReportThreshold,
) => {
  const [addLegalEntityOverride, options] =
    usePostApiSettingsReportThresholdsByThresholdKindLegalEntityOverridesMutation();

  const handleAddLegalEntityOverride = ({
    legalEntityId,
    value,
  }: {
    legalEntityId: string;
    value: VariableValueTypePossibleValues;
  }) => {
    return addLegalEntityOverride({
      thresholdKind: threshold.kind,
      body: {
        legal_entity_overrides: [{ legal_entity_id: legalEntityId, value }],
      },
    });
  };

  return [handleAddLegalEntityOverride, options] as const;
};

export const useDeleteLegalEntityOverrideForThreshold = (
  threshold: ReportThreshold,
) => {
  const [deleteLegalEntityOverride, options] =
    useDeleteApiSettingsReportThresholdsByThresholdKindLegalEntityOverridesAndIdMutation();

  const handleDeleteLegalEntityOverride = (legalEntityId: string) => {
    return deleteLegalEntityOverride({
      thresholdKind: threshold.kind,
      id: legalEntityId,
    });
  };

  return [handleDeleteLegalEntityOverride, options] as const;
};

export const useUpdateLegalEntityOverrideForThreshold = (
  threshold: ReportThreshold,
) => {
  const [updateLegalEntityOverride, options] =
    usePutApiSettingsReportThresholdsByThresholdKindLegalEntityOverridesAndIdMutation();

  const handleUpdateLegalEntityOverride = (
    legalEntityId: string,
    value: VariableValueTypePossibleValues,
  ) => {
    return updateLegalEntityOverride({
      thresholdKind: threshold.kind,
      id: legalEntityId,
      body: { value },
    });
  };

  return [handleUpdateLegalEntityOverride, options] as const;
};

const transformLegalEntityOverrideDtoToModel = (
  dto: GetApiSettingsReportThresholdsByThresholdKindLegalEntityOverridesApiResponse,
): ReportThresholdLegalEntityOverride[] => {
  return (
    dto.items?.map((item) => ({
      id: item.id,
      value: item.value,
      valueType: item.valueType,
      legalEntityId: item.legalEntityId,
    })) ?? []
  );
};

export const useLegalEntityOverridesForThreshold = (
  threshold: ReportThreshold,
  args?: Omit<
    GetApiSettingsReportThresholdsByThresholdKindLegalEntityOverridesApiArg,
    'thresholdKind'
  >,
) => {
  return useGetApiSettingsReportThresholdsByThresholdKindLegalEntityOverridesQuery(
    {
      thresholdKind: threshold.kind,
      ...args,
    },
    {
      selectFromResult: ({ data, ...rest }) => ({
        legalEntityOverrides: data
          ? transformLegalEntityOverrideDtoToModel(data)
          : [],
        ...rest,
      }),
    },
  );
};

export const useLegalEntitiesForThreshold = () => {
  return useGetApiSettingsCoreLegalEntitiesQuery({
    perPage: 1000,
  });
};
